import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import MainContent from "../../ui/MainContent";
import axios from "axios";
import Success from "../../ui/success";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const PriceTariff = () => {
  // const [list, setList] = useState();

  const [loader, setLoader] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const para = useParams();
  const temp = para.id;
  const companyId = String(temp).split("-")[0];
  const planId = String(para.id).split("-")[1];
  const [success, setSuccess] = useState(false);
  const uploadfile = useRef();
  const [items, setItems] = useState();
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  // const [listNo, setListNo] = useState();

  const changeAll = () => {
    setSelectAll((current) => !current);
    const newStateconsumable = [...items.consumable];
    const newStatedrug = [...items.drug];
    const newStateservice = [...items.service];
    const newStateroom = [...items.room];
    const newStatefluid = [...items.fluid];
    const newStateprocedure = [...items.procedure];
    const newStatelabtest = [...items.labtest];
    const newStateradtest = [...items.radtest];
    const newStatevaccine = [...items.vaccine];
    if (selectAll == false) {
      for (var i = 0; i < items.consumable.length; i++) {
        newStateconsumable[i].pricing[0].pt_autorization = true;
      }
      for (var i = 0; i < items.drug.length; i++) {
        newStatedrug[i].pricing[0].pt_autorization = true;
      }
      for (var i = 0; i < items.service.length; i++) {
        newStateservice[i].pricing[0].pt_autorization = true;
      }
      for (var i = 0; i < items.room.length; i++) {
        newStateroom[i].pricing[0].pt_autorization = true;
      }
      for (var i = 0; i < items.fluid.length; i++) {
        newStatefluid[i].pricing[0].pt_autorization = true;
      }
      for (var i = 0; i < items.procedure.length; i++) {
        newStateprocedure[i].pricing[0].pt_autorization = true;
      }
      for (var i = 0; i < items.labtest.length; i++) {
        newStatelabtest[i].pricing[0].pt_autorization = true;
      }
      for (var i = 0; i < items.radtest.length; i++) {
        newStateradtest[i].pricing[0].pt_autorization = true;
      }
      for (var i = 0; i < items.vaccine.length; i++) {
        newStatevaccine[i].pricing[0].pt_autorization = true;
      }
    } else {
      for (var i = 0; i < items.consumable.length; i++) {
        newStateconsumable[i].pricing[0].pt_autorization = false;
      }
      for (var i = 0; i < items.drug.length; i++) {
        newStatedrug[i].pricing[0].pt_autorization = false;
      }
      for (var i = 0; i < items.service.length; i++) {
        newStateservice[i].pricing[0].pt_autorization = false;
      }
      for (var i = 0; i < items.room.length; i++) {
        newStateroom[i].pricing[0].pt_autorization = false;
      }
      for (var i = 0; i < items.fluid.length; i++) {
        newStatefluid[i].pricing[0].pt_autorization = false;
      }
      for (var i = 0; i < items.procedure.length; i++) {
        newStateprocedure[i].pricing[0].pt_autorization = false;
      }
      for (var i = 0; i < items.labtest.length; i++) {
        newStatelabtest[i].pricing[0].pt_autorization = false;
      }
      for (var i = 0; i < items.radtest.length; i++) {
        newStateradtest[i].pricing[0].pt_autorization = false;
      }
      for (var i = 0; i < items.vaccine.length; i++) {
        newStatevaccine[i].pricing[0].pt_autorization = false;
      }
    }
    setItems({
      ...items,
      consumable: newStateconsumable,
      drug: newStatedrug,
      fluid: newStatefluid,
      service: newStateservice,
      room: newStateroom,
      procedure: newStateprocedure,
      vaccine: newStatevaccine,
    });
  };

  const handleShow = () => {
    setShow((current) => !current);
  };

  useEffect(() => {
    const url = `${window.baseURL}getalldata/price-tariff/${planId}/${companyId}`;
    axios.get(url).then((res) => {
      setItems(res.data);
      console.log(res.data);
      setLoader(false);
    });
  }, [reload]);

  const handleChange = (e, i, n) => {
    const { value, name } = e.target;

    let newState = [];

    if (n == "service") {
      newState = [...items.service];

      newState[i].pricing[0].pt_actualprice = value;
      newState[i].pricing[0].pt_percentprice = value;
      setItems({ ...items, service: newState });
    } else if (n == "fluid") {
      newState = [...items.fluid];
      newState[i].pricing[0].pt_percentprice = value;
      newState[i].pricing[0].pt_actualprice = value;
      setItems({ ...items, fluid: newState });
    } else if (n == "room") {
      newState = [...items.room];
      newState[i].pricing[0].pt_percentprice = value;
      newState[i].pricing[0].pt_actualprice = value;
      setItems({ ...items, room: newState });
    } else if (n == "procedure") {
      newState = [...items.procedure];
      newState[i].pricing[0].pt_percentprice = value;
      newState[i].pricing[0].pt_actualprice = value;
      setItems({ ...items, procedure: newState });
    } else if (n == "drug") {
      newState = [...items.drug];
      newState[i].pricing[0].pt_percentprice = value;
      newState[i].pricing[0].pt_actualprice = value;
      setItems({ ...items, drug: newState });
    } else if (n == "consumable") {
      newState = [...items.consumable];
      newState[i].pricing[0].pt_percentprice = value;
      newState[i].pricing[0].pt_actualprice = value;
      setItems({ ...items, consumable: newState });
    } else if (n == "labtest") {
      newState = [...items.labtest];
      newState[i].pricing[0].pt_percentprice = value;
      newState[i].pricing[0].pt_actualprice = value;
      setItems({ ...items, labtest: newState });
    } else if (n == "radtest") {
      newState = [...items.radtest];
      newState[i].pricing[0].pt_percentprice = value;
      newState[i].pricing[0].pt_actualprice = value;
      setItems({ ...items, radtest: newState });
    } else if (n == "vaccine") {
      newState = [...items.vaccine];
      newState[i].pricing[0].pt_percentprice = value;
      newState[i].pricing[0].pt_actualprice = value;
      setItems({ ...items, vaccine: newState });
    }
    setSuccess(false);
  };

  const billCategoryChange = (e, i, n) => {

    const { value } = e.target;
    let newState = [];

    if (n == "service") {
      newState = [...items.service];
      newState[i].pricing[0].pt_billcategory.id = value;
      setItems({ ...items, service: newState });
    } else if (n == "fluid") {
      newState = [...items.fluid];
      newState[i].pricing[0].pt_billcategory.id = value;
      setItems({ ...items, fluid: newState });
    } else if (n == "room") {
      newState = [...items.room];
      newState[i].pricing[0].pt_billcategory.id = value;
      setItems({ ...items, room: newState });
    } else if (n == "procedure") {
      newState = [...items.procedure];
      newState[i].pricing[0].pt_billcategory.id = value;
      setItems({ ...items, procedure: newState });
    } else if (n == "drug") {
      newState = [...items.drug];
      newState[i].pricing[0].pt_billcategory.id = value;
      setItems({ ...items, drug: newState });
    } else if (n == "consumable") {
      newState = [...items.consumable];
      newState[i].pricing[0].pt_billcategory.id = value;
      setItems({ ...items, consumable: newState });
    } else if (n == "labtest") {
      newState = [...items.labtest];
      newState[i].pricing[0].pt_billcategory.id = value;
      setItems({ ...items, labtest: newState });
    } else if (n == "radtest") {
      newState = [...items.radtest];
      newState[i].pricing[0].pt_billcategory.id = value;
      setItems({ ...items, radtest: newState });
    } else if (n == "vaccine") {
      newState = [...items.vaccine];
      newState[i].pricing[0].pt_billcategory.id = value;
      setItems({ ...items, vaccine: newState });
    }
    setSuccess(false);
    // console.log(items);
  };

  const handleChangePercent = (e, i, n) => {
    const { value, name } = e.target;

    let newState = [];

    if (n == "service") {
      newState = [...items.service];
      const oldValue = newState[i].pricing[0].pt_actualprice;
      const percent = oldValue - (oldValue * value) / 100;
      newState[i].pricing[0].pt_percentage = value;
      newState[i].pricing[0].pt_percentprice = percent;
      setItems({ ...items, service: newState });
    } else if (n == "fluid") {
      newState = [...items.fluid];
      const oldValue = newState[i].pricing[0].pt_actualprice;
      const percent = oldValue - (oldValue * value) / 100;
      newState[i].pricing[0].pt_percentage = value;
      newState[i].pricing[0].pt_percentprice = percent;
      setItems({ ...items, fluid: newState });
    } else if (n == "room") {
      newState = [...items.room];
      const oldValue = newState[i].pricing[0].pt_actualprice;
      const percent = oldValue - (oldValue * value) / 100;
      newState[i].pricing[0].pt_percentage = value;
      newState[i].pricing[0].pt_percentprice = percent;
      setItems({ ...items, room: newState });
    } else if (n == "procedure") {
      newState = [...items.procedure];
      const oldValue = newState[i].pricing[0].pt_actualprice;
      const percent = oldValue - (oldValue * value) / 100;
      newState[i].pricing[0].pt_percentage = value;
      newState[i].pricing[0].pt_percentprice = percent;
      setItems({ ...items, procedure: newState });
    } else if (n == "drug") {
      newState = [...items.drug];
      const oldValue = newState[i].pricing[0].pt_actualprice;
      const percent = oldValue - (oldValue * value) / 100;
      newState[i].pricing[0].pt_percentage = value;
      newState[i].pricing[0].pt_percentprice = percent;
      setItems({ ...items, drug: newState });
    } else if (n == "consumable") {
      newState = [...items.consumable];
      const oldValue = newState[i].pricing[0].pt_actualprice;
      const percent = oldValue - (oldValue * value) / 100;
      newState[i].pricing[0].pt_percentage = value;
      newState[i].pricing[0].pt_percentprice = percent;
      setItems({ ...items, consumable: newState });
    } else if (n == "radtest") {
      newState = [...items.radtest];
      const oldValue = newState[i].pricing[0].pt_actualprice;
      const percent = oldValue - (oldValue * value) / 100;
      newState[i].pricing[0].pt_percentage = value;
      newState[i].pricing[0].pt_percentprice = percent;
      setItems({ ...items, radtest: newState });
    } else if (n == "labtest") {
      newState = [...items.labtest];
      const oldValue = newState[i].pricing[0].pt_actualprice;
      const percent = oldValue - (oldValue * value) / 100;
      newState[i].pricing[0].pt_percentage = value;
      newState[i].pricing[0].pt_percentprice = percent;
      setItems({ ...items, labtest: newState });
    } else if (n == "vaccine") {
      newState = [...items.vaccine];
      const oldValue = newState[i].pricing[0].pt_actualprice;
      const percent = oldValue - (oldValue * value) / 100;
      newState[i].pricing[0].pt_percentage = value;
      newState[i].pricing[0].pt_percentprice = percent;
      setItems({ ...items, vaccine: newState });
    }
    setSuccess(false);
  };

  const handleChangeAuth = (e, i) => {
    const { value, name } = e.target;
    if (name == "consumable") {
      const newStateconsumable = [...items.consumable];

      const newState = !newStateconsumable[i].pricing[0].pt_autorization;
      newStateconsumable[i].pricing[0].pt_autorization = newState;
      setItems({ ...items, consumable: newStateconsumable });
    } else if (name == "drug") {
      const newStatedrug = [...items.drug];
      const newState = !newStatedrug[i].pricing[0].pt_autorization;
      newStatedrug[i].pricing[0].pt_autorization = newState;
      setItems({ ...items, drug: newStatedrug });
    } else if (name == "service") {
      const newStateservice = [...items.service];
      const newState = !newStateservice[i].pricing[0].pt_autorization;
      newStateservice[i].pricing[0].pt_autorization = newState;
      setItems({ ...items, service: newStateservice });
    } else if (name == "room") {
      const newStateroom = [...items.room];
      const newState = !newStateroom[i].pricing[0].pt_autorization;
      newStateroom[i].pricing[0].pt_autorization = newState;
      setItems({ ...items, room: newStateroom });
    } else if (name == "fluid") {
      const newStatefluid = [...items.fluid];
      const newState = !newStatefluid[i].pricing[0].pt_autorization;
      newStatefluid[i].pricing[0].pt_autorization = newState;
      setItems({ ...items, fluid: newStatefluid });
    } else if (name == "procedure") {
      const newStateprocedure = [...items.procedure];
      const newState = !newStateprocedure[i].pricing[0].pt_autorization;
      newStateprocedure[i].pricing[0].pt_autorization = newState;
      setItems({ ...items, procedure: newStateprocedure });
    } else if (name == "labtest") {
      const newStatetest = [...items.labtest];
      const newState = !newStatetest[i].pricing[0].pt_autorization;
      newStatetest[i].pricing[0].pt_autorization = newState;
      setItems({ ...items, labtest: newStatetest });
    } else if (name == "radtest") {
      const newStatetest = [...items.radtest];
      const newState = !newStatetest[i].pricing[0].pt_autorization;
      newStatetest[i].pricing[0].pt_autorization = newState;
      setItems({ ...items, radtest: newStatetest });
    } else if (name == "vaccine") {
      const newStatevaccine = [...items.vaccine];
      const newState = !newStatevaccine[i].pricing[0].pt_autorization;
      newStatevaccine[i].pricing[0].pt_autorization = newState;
      console.log(newStatevaccine)
      setItems({ ...items, vaccine: newStatevaccine });
    }
    setSuccess(false);
    setSelectAll(false);
  };
  const deleteHandler = (id, pid) => {
    const tempid = String(pid) + "-" + String(id);
    const url = `${window.baseURL}billing/items/${tempid}`;
    axios
      .delete(url, {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      })
      .then((res) => {
        setItems(res.data);
      });
    setSuccess(false);
  };

  const uploadHandler = (e) => {
    setLoader(true);
    e.preventDefault();
    const url = `${window.baseURL}billing/plan/upload`;
    console.log(uploadfile.current.files[0]);

    const formData = new FormData();
    formData.append("cid", companyId);
    formData.append("pid", planId);
    formData.append("file", uploadfile.current.files[0]);

    const options = {
      method: "POST",
      body: formData,
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    };
    fetch(url, options)
      // .then((response) => response.json())
      .then((data) => {
        // console.log("Success:", data);
        setShow(false);
        // setItems(data);
        setLoader(false);
        setReload(!reload);
      });
  };

  const saveHandler = (id, i) => {
    const newState = [...items];
    const item = newState[i];
    console.log(item);
    console.log(item.item);

    const data = {
      id: id,
      item: item.item,
      actualprice: item.actualprice,
      authorization: item.authorization,
      percentage: item.percentage,
      percentprice: item.percentprice,
    };
    const url = `${window.baseURL}billing/items`;
    const requestOptions = {
      method: "POST",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    };
    axios.post(url, data, requestOptions).then((res) => {
      setSuccess(true);
    });
    // fetch(url, requestOptions);
  };

  const saveAllHandler = () => {
    const data = {
      list: items,
      companyId: companyId,
      planId: planId,
    };
    setLoader(true);
    const url = `${window.baseURL}getalldata/price-tariff/${planId}/${companyId}`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setSuccess(true);
        setLoader(false);
      });
  };

  const handleDownload = () => {
    const url = `${window.baseURL}getalldata/item/download-price/${planId}`;
    window.open(url, "_blank");
  };
  return (
    <>
      <MainContent>
        {success && (
          <Success close={() => setSuccess(false)}>
            Item Updated Successfully
          </Success>
        )}

        {loader && (
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-md-8">
              <button
                style={{ fontSize: "13px" }}
                className="btn btn-primary mb-4"
                onClick={handleShow}
              >
                Upload Price
              </button>
              <button
                style={{ fontSize: "13px" }}
                className="btn btn-success mb-4 ml-3"
                onClick={handleDownload}
              >
                Download Price
              </button>
              <button
                style={{ fontSize: "13px" }}
                className="btn btn-primary mb-4 ml-3"
                onClick={saveAllHandler}
              >
                Save
              </button>
            </div>
            <div className="col-md-4">
              <Link
                to={"/company-management/plan/" + companyId}
                className="mt-2 float-end mb-2"
              >
                Go Back
              </Link>
            </div>
          </div>

          {show && (
            <form onSubmit={uploadHandler}>
              <div className="row">
                <div className="col-md-4">
                  <div class="mb-3">
                    <input
                      class="form-control"
                      type="file"
                      id="file"
                      ref={uploadfile}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <button type="submit" class="form-label btn btn-primary">
                    Upload
                  </button>
                </div>
              </div>
            </form>
          )}
          <div className="tablexflow">
            <table className="table responsive table-md">
              <thead>
                <tr>
                  <th scope="col" width="5%">
                    #
                  </th>
                  <th scope="col" width="30%">
                    Items
                  </th>
                  <th scope="col">Bill Category</th>
                  <th scope="col" width="15%">
                    Actual Price
                  </th>
                  <th scope="col" width="5%">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      // style={{marginLeft:'0'}}
                      name="authorization"
                      checked={selectAll}
                      id="flexCheckDefault"
                      onChange={changeAll}
                    />
                    Authorization
                  </th>
                  <th scope="col" width="10%">
                    Percentage
                  </th>
                  {/* <th scope="col">Bill Type</th> */}
                  <th scope="col" width="15%">
                    % Price
                  </th>
                  {/* <th scope="col">Percentage</th> */}
                  <th scope="col" width="20%">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan="7">Consumables</th>
                </tr>
                {items &&
                  items.consumable.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td width="15%">
                          <select
                            className="form-select"
                            value={a.pricing[0].pt_billcategory &&
                                  a.pricing[0].pt_billcategory.id
                            }
                            onChange={(e) =>
                              billCategoryChange(e, index, "consumable")
                            }
                          >
                            {items &&
                              items.billing.map((u) => {
                                return (
                                  <option key={u.id} value={u.id}>
                                    {u.category}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_actualprice}
                            name="pt_actualprice"
                            className="form-control"
                            onChange={(e) =>
                              handleChange(e, index, "consumable")
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="consumable"
                            checked={
                              a.pricing[0] && a.pricing[0].pt_autorization
                            }
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeAuth(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_percentage}
                            max="100"
                            name="percentage"
                            className="form-control"
                            onChange={(e) =>
                              handleChangePercent(e, index, "consumable")
                            }
                          />
                        </td>
                        <td id={"percentprice" + index}>
                          {a.pricing[0] && a.pricing[0].pt_percentprice}
                        </td>
                        <td>
                          {/* <button
                            className="btn btn-sm btn-primary"
                            onClick={() => saveHandler(a.id, index)}
                          >
                            Save
                          </button> */}
                          <button
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteHandler(a.id, a.plan)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <th colSpan="7">Drugs</th>
                </tr>
                {items &&
                  items.drug.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.brandname}</td>
                        <td width="15%">
                          <select
                            className="form-select"
                            value={a.pricing[0].pt_billcategory &&
                                  a.pricing[0].pt_billcategory.id
                            }
                            onChange={(e) =>
                              billCategoryChange(e, index, "drug")
                            }
                          >
                            {items &&
                              items.billing.map((u) => {
                                return (
                                  <option key={u.id} value={u.id}>
                                    {u.category}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_actualprice}
                            name="pt_actualprice"
                            className="form-control"
                            onChange={(e) => handleChange(e, index, "drug")}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="drug"
                            checked={
                              a.pricing[0] && a.pricing[0].pt_autorization
                            }
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeAuth(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_percentage}
                            max="100"
                            name="percentage"
                            className="form-control"
                            onChange={(e) =>
                              handleChangePercent(e, index, "drug")
                            }
                          />
                        </td>
                        <td id={"percentprice" + index}>
                          {a.pricing[0] && a.pricing[0].pt_percentprice}
                        </td>
                        <td>
                          {/* <button
                            className="btn btn-sm btn-primary"
                            onClick={() => saveHandler(a.id, index)}
                          >
                            Save
                          </button> */}
                          <button
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteHandler(a.id, a.plan)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <th colSpan="7">Services</th>
                </tr>
                {items &&
                  items.service.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td width="15%">
                          <select
                            className="form-select"
                            value={a.pricing[0].pt_billcategory &&
                                  a.pricing[0].pt_billcategory.id
                            }
                            onChange={(e) =>
                              billCategoryChange(e, index, "service")
                            }
                          >
                            {items &&
                              items.billing.map((u) => {
                                return (
                                  <option key={u.id} value={u.id}>
                                    {u.category}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_actualprice}
                            name="pt_actualprice"
                            className="form-control"
                            onChange={(e) => handleChange(e, index, "service")}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="service"
                            checked={
                              a.pricing[0] && a.pricing[0].pt_autorization
                            }
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeAuth(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_percentage}
                            max="100"
                            name="percentage"
                            className="form-control"
                            onChange={(e) =>
                              handleChangePercent(e, index, "service")
                            }
                          />
                        </td>
                        <td id={"percentprice" + index}>
                          {a.pricing[0] && a.pricing[0].pt_percentprice}
                        </td>
                        <td>
                          {/* <button
                            className="btn btn-sm btn-primary"
                            onClick={() => saveHandler(a.id, index)}
                          >
                            Save
                          </button> */}
                          <button
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteHandler(a.id, a.plan)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <th colSpan="7">Rooms</th>
                </tr>
                {items &&
                  items.room.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.room}</td>
                        <td width="15%">
                          <select
                            className="form-select"
                            value={a.pricing[0].pt_billcategory &&
                                  a.pricing[0].pt_billcategory.id
                            }
                            onChange={(e) =>
                              billCategoryChange(e, index, "room")
                            }
                          >
                            {items &&
                              items.billing.map((u) => {
                                return (
                                  <option key={u.id} value={u.id}>
                                    {u.category}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_actualprice}
                            name="pt_actualprice"
                            className="form-control"
                            onChange={(e) => handleChange(e, index, "room")}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="room"
                            checked={
                              a.pricing[0] && a.pricing[0].pt_autorization
                            }
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeAuth(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_percentage}
                            max="100"
                            name="percentage"
                            className="form-control"
                            onChange={(e) =>
                              handleChangePercent(e, index, "room")
                            }
                          />
                        </td>
                        <td id={"percentprice" + index}>
                          {a.pricing[0] && a.pricing[0].pt_percentprice}
                        </td>
                        <td>
                          {/* <button
                            className="btn btn-sm btn-primary"
                            onClick={() => saveHandler(a.id, index)}
                          >
                            Save
                          </button> */}
                          <button
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteHandler(a.id, a.plan)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}

                <tr>
                  <th colSpan="7">Vaccines</th>
                </tr>
                {items &&
                  items.vaccine.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td width="15%">
                          <select
                            className="form-select"
                            value={a.pricing[0].pt_billcategory &&
                                  a.pricing[0].pt_billcategory.id
                            }
                            onChange={(e) =>
                              billCategoryChange(e, index, "vaccine")
                            }
                          >
                            {items &&
                              items.billing.map((u) => {
                                return (
                                  <option key={u.id} value={u.id}>
                                    {u.category}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_actualprice}
                            name="pt_actualprice"
                            className="form-control"
                            onChange={(e) => handleChange(e, index, "vaccine")}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="vaccine"
                            checked={
                              a.pricing[0] && a.pricing[0].pt_autorization
                            }
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeAuth(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_percentage}
                            max="100"
                            name="percentage"
                            className="form-control"
                            onChange={(e) =>
                              handleChangePercent(e, index, "vaccine")
                            }
                          />
                        </td>
                        <td id={"percentprice" + index}>
                          {a.pricing[0] && a.pricing[0].pt_percentprice}
                        </td>
                        <td>
                          {/* <button
                            className="btn btn-sm btn-primary"
                            onClick={() => saveHandler(a.id, index)}
                          >
                            Save
                          </button> */}
                          <button
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteHandler(a.id, a.plan)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}


                <tr>
                  <th colSpan="7">Fluids</th>
                </tr>
                {items &&
                  items.fluid.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.fluid}</td>
                        <td width="15%">
                          <select
                            className="form-select"
                            value={a.pricing[0].pt_billcategory &&
                                  a.pricing[0].pt_billcategory.id
                            }
                            onChange={(e) =>
                              billCategoryChange(e, index, "fluid")
                            }
                          >
                            {items &&
                              items.billing.map((u) => {
                                return (
                                  <option key={u.id} value={u.id}>
                                    {u.category}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_actualprice}
                            name="pt_actualprice"
                            className="form-control"
                            onChange={(e) => handleChange(e, index, "fluid")}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="fluid"
                            checked={
                              a.pricing[0] && a.pricing[0].pt_autorization
                            }
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeAuth(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_percentage}
                            max="100"
                            name="percentage"
                            className="form-control"
                            onChange={(e) =>
                              handleChangePercent(e, index, "fluid")
                            }
                          />
                        </td>
                        <td id={"percentprice" + index}>
                          {a.pricing[0] && a.pricing[0].pt_percentprice}
                        </td>
                        <td>
                          {/* <button
                            className="btn btn-sm btn-primary"
                            onClick={() => saveHandler(a.id, index)}
                          >
                            Save
                          </button> */}
                          <button
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteHandler(a.id, a.plan)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <th colSpan="7">Procedures</th>
                </tr>
                {items &&
                  items.procedure.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.procedure}</td>
                        <td width="15%">
                          <select
                            className="form-select"
                            value={a.pricing[0].pt_billcategory &&
                                  a.pricing[0].pt_billcategory.id
                            }
                            onChange={(e) =>
                              billCategoryChange(e, index, "procedure")
                            }
                          >
                            {items &&
                              items.billing.map((u) => {
                                return (
                                  <option key={u.id} value={u.id}>
                                    {u.category}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_actualprice}
                            name="pt_actualprice"
                            className="form-control"
                            onChange={(e) =>
                              handleChange(e, index, "procedure")
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="procedure"
                            checked={
                              a.pricing[0] && a.pricing[0].pt_autorization
                            }
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeAuth(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_percentage}
                            max="100"
                            name="percentage"
                            className="form-control"
                            onChange={(e) =>
                              handleChangePercent(e, index, "procedure")
                            }
                          />
                        </td>
                        <td id={"percentprice" + index}>
                          {a.pricing[0] && a.pricing[0].pt_percentprice}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteHandler(a.id, a.plan)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                  <th colSpan="7">Laboratory Test</th>
                </tr>
                {items &&
                  items.labtest.map((a, index) => {
                    return (
                      <>
                      {a.is_laboratory && 
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.testname}</td>
                        <td width="15%">
                          <select
                            className="form-select"
                            value={a.pricing[0].pt_billcategory &&
                                  a.pricing[0].pt_billcategory.id
                            }
                            onChange={(e) =>
                              billCategoryChange(e, index, "labtest")
                            }
                          >
                            {items &&
                              items.billing.map((u) => {
                                return (
                                  <option key={u.id} value={u.id}>
                                    {u.category}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_actualprice}
                            name="pt_actualprice"
                            className="form-control"
                            onChange={(e) =>
                              handleChange(e, index, "labtest")
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="labtest"
                            checked={
                              a.pricing[0] && a.pricing[0].pt_autorization
                            }
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeAuth(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_percentage}
                            max="100"
                            name="percentage"
                            className="form-control"
                            onChange={(e) =>
                              handleChangePercent(e, index, "labtest")
                            }
                          />
                        </td>
                        <td id={"percentprice" + index}>
                          {a.pricing[0] && a.pricing[0].pt_percentprice}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteHandler(a.id, a.plan)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>}</>
                    );
                  })}
                  <tr>
                  <th colSpan="7">Radiology Test</th>
                </tr>
                {items &&
                  items.radtest.map((a, index) => {
                    return (
                      <>
                      {!a.is_laboratory && 
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.testname}</td>
                        <td width="15%">
                          <select
                            className="form-select"
                            value={a.pricing[0].pt_billcategory &&
                                  a.pricing[0].pt_billcategory.id
                            }
                            onChange={(e) =>
                              billCategoryChange(e, index, "radtest")
                            }
                          >
                            {items &&
                              items.billing.map((u) => {
                                return (
                                  <option key={u.id} value={u.id}>
                                    {u.category}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_actualprice}
                            name="pt_actualprice"
                            className="form-control"
                            onChange={(e) =>
                              handleChange(e, index, "radtest")
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="radtest"
                            checked={
                              a.pricing[0] && a.pricing[0].pt_autorization
                            }
                            id="flexCheckDefault"
                            onChange={(e) => handleChangeAuth(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={a.pricing[0] && a.pricing[0].pt_percentage}
                            max="100"
                            name="percentage"
                            className="form-control"
                            onChange={(e) =>
                              handleChangePercent(e, index, "radtest")
                            }
                          />
                        </td>
                        <td id={"percentprice" + index}>
                          {a.pricing[0] && a.pricing[0].pt_percentprice}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteHandler(a.id, a.plan)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>}</>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default PriceTariff;
