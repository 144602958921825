import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
import UploadFile from "../../modals/FrontDesk/UploadFile";
import Success from "../../ui/success";
import { useNavigate } from "react-router-dom";
// import Cookies from 'js-cookie'
import { putToServer } from "../../global/requests";



const PatientListUpload = (props) => {
  let navigate = useNavigate();
  const [isemptyArray, setIsEmptyArray] = useState(false);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [del, setDel] = useState(false);
  const [tempId, setTempId] = useState();
  const patient = props.data;

  const successHandler = (bool) => setSuccess(bool);
  const handleClose = () => setShow(false);
  const handleShow = (pid) => {
    setShow(true);
    setTempId(pid);
  };

  useEffect(() => {
    if (patient.length <= 0) {
      setIsEmptyArray(true);
    } else {
      setIsEmptyArray(false);
    }
    setSuccess(false);
    setDel(false);
  }, [patient]);

  const viewHandler = (pid) => {
    navigate(`/view-upload/${pid}`);
  };

  const deleteHandler = async (pid) => {
    const result = await putToServer(`main/frontdesk/patient/detail/${pid}/deleteupload`);
    if(result.status) {
      setSuccess(false);
      setDel(true);
    }
  };

  const downloadHandler = (pid) =>{
    const url = `${window.baseURL}main/frontdesk/download/patient/${pid}`;
    window.location.href = url
  }

  return (
    <>
      <div className="col-md-12">
        {success && <Success>Files Uploaded Successfully.</Success>}
        {del && (
          <Success>
            <span style={{ color: "red" }}>Files Deleted Successfully. </span>
          </Success>
        )}
        <div className="card">
          <div className="card-body">
            <h4>Search Result</h4>
            <ul className="list-unstyled user-details list-unstyled-border list-unstyled-noborder pt-4">
              {isemptyArray && <h4>No Data Found</h4>}
              {patient.map((p) => {
                return (
                  <li className="media">
                    <img
                      alt="Image"
                      className="mr-3 rounded-circle"
                      width="50"
                      src={window.baseURLuploaded + p.captureimage}
                    />

                    <div className="media-body">
                      <div className="media-title">
                        {p.sex} | {p.surname} {p.firstname} | {p.hospitalid} |{" "}
                        {p.phonenumber} | {p.company} | {p.billplan}
                      </div>

                      <div className="text-job text-muted">PID: {p.pid}</div>
                    </div>
                    <div className="media-items">
                      <div className="media-item">
                        <button
                          type="button"
                          style={{ color: "white" }}
                          className="btn btn-primary btn-sm mr-2"
                          onClick={() => handleShow(p.pid)}
                        >
                          Upload file
                        </button>

                        <button
                          type="button"
                          style={{ color: "white" }}
                          className="btn btn-success btn-sm mr-2"
                          onClick={() => downloadHandler(p.pid)}
                        >
                          Download file
                        </button>

                        <button
                          className="btn btn-info btn-sm mr-2"
                          style={{ color: "white" }}
                          onClick={() => viewHandler(p.pid)}
                        >
                          View file
                        </button>

                        <button
                          className="btn btn-danger btn-sm"
                          style={{ color: "white" }}
                          onClick={() => deleteHandler(p.pid)}
                        >
                          Delete file
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <UploadFile
        show={show}
        close={handleClose}
        success={successHandler}
        pid={tempId}
      />
    </>
  );
};

export default PatientListUpload;
