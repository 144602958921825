import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import InvestigationLab from "./Investigation/Lab";
import InvestigationXray from "./Investigation/Xray";
import InvestigationUltrasound from "./Investigation/Ultrasound";
import InvestigationECG from "./Investigation/ECG";
import InvestigationCTscan from "./Investigation/CTscan";

const Investigation = (props) => {
  const [p, setData] = useState();
  const [lab, setLab] = useState(false);
  const [xray, setXray] = useState(false);
  const [ultrasound, setUltrasound] = useState(false);
  const [ecg, setEcg] = useState(false);
  const [ct, setCt] = useState(false);
  const [radiology, setRadiology] = useState(false);

  return (
    <>
      <div className="row">
        <div className=" ">
          <div className="card-header">
            <ul
              className="nav nav-tabs card-header-tabs pull-right"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="assessment-tab"
                  data-toggle="tab"
                  href="#assessment"
                  role="tab"
                  aria-selected="true"
                  onClick={() => setLab(true)}
                >
                  LAB
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="care-tab"
                  data-toggle="tab"
                  href="#care"
                  role="tab"
                  aria-selected="true"
                  onClick={() => setXray(true)}
                >
                  XRAY
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="report-tab"
                  data-toggle="tab"
                  href="#report"
                  role="tab"
                  aria-selected="true"
                  onClick={() => setUltrasound(true)}
                >
                  ULTRASOUND
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="observation-tab"
                  data-toggle="tab"
                  href="#observation"
                  role="tab"
                  aria-selected="true"
                  onClick={() => setEcg(true)}
                >
                  ECG
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="note-tab"
                  data-toggle="tab"
                  href="#note"
                  role="tab"
                  aria-selected="true"
                  onClick={() => setCt(true)}
                >
                  CT
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="radiology-tab"
                  data-toggle="tab"
                  href="#radiology"
                  role="tab"
                  aria-selected="true"
                  onClick={() => setRadiology(true)}
                >
                  Radiology Uploads
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="assessment"
              role="tabpanel"
            >
              {lab && (
                <InvestigationLab
                  pid={props.pid}
                  patient_pid={props.patient_pid}
                />
              )}
            </div>
            <div className="tab-pane fade" id="care" role="tabpanel">
              {xray && (
                <InvestigationXray
                  pid={props.pid}
                  patient_pid={props.patient_pid}
                />
              )}
            </div>
            <div className="tab-pane fade" id="report" role="tabpanel">
              {ultrasound && (
                <InvestigationUltrasound
                  pid={props.pid}
                  patient_pid={props.patient_pid}
                />
              )}
            </div>
            <div className="tab-pane fade" id="observation" role="tabpanel">
              {ecg && (
                <InvestigationECG
                  pid={props.pid}
                  patient_pid={props.patient_pid}
                />
              )}
            </div>
            <div className="tab-pane fade" id="note" role="tabpanel">
              {ct && (
                <InvestigationCTscan
                  pid={props.pid}
                  patient_pid={props.patient_pid}
                />
              )}
            </div>
            <div className="tab-pane fade" id="radiology" role="tabpanel">
              ...
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investigation;
