import React, { useState, useEffect, useRef } from "react";
import { render } from "react-dom";
import axios from "axios";
import { suggestions } from "./Suggestions";
import Cookies from "js-cookie";
import AutoComplain from "./Auto/AutoComplain";

const Complain = (props) => {
  const pid = props.encounter_pid;
  const patient_pid = props.patient_pid;
  const [save, setSave] = useState(false);
  const [head, setHead] = useState(false);
  const [diag, setDiag] = useState();
  const [tableData, setTableData] = useState();
  const [tableDataHead, setTableDataHead] = useState([]);
  const [check, setCheck] = useState([]);

  const [tags, setTags] = useState([]);
  const [val, setVal] = useState([]);
  const [drug, setDrug] = useState([]);
  const [period, setPeriod] = useState([]);
  const [none, setNone] = useState([]);

  const handleAdd = () => {
    setHead(true);
    setVal([...val, []]);
    setDrug([...drug, []]);
    setPeriod([...period, 1]);
    setNone([...none, 7]);
  };

  const handleNote = (e, i) => {
    const inputDrug = [...drug];
    inputDrug[i] = e.target.value;
    setDrug(inputDrug);
    console.log(inputDrug);
  };
  const handlePeriod = (e, i) => {
    const input = [...period];
    input[i] = e.target.value;
    setPeriod(input);
  };
  const handleNone = (e, i) => {
    const input = [...none];
    input[i] = e.target.value;
    setNone(input);
  };

  const getTags = (e, i) => {
    const inputDrug = [...tags];
    inputDrug[i] = e;
    setTags(inputDrug);
  };

  useEffect(() => {
    setCheck([]);
    let url = `${window.baseURL}adminmgt/encounter/complain`;
    axios.get(url).then((res) => {
      console.log(res.data)
      setDiag(res.data);
    });

    url = `${window.baseURL}main/doctor/complain/${patient_pid}`;
    axios.get(url).then((res) => {
      console.log("this is data", res.data);
      if (res.data.length != 0) {
        setTableData(res.data);
        console.log(res.data);
      }
    });
  }, []);

  const submitHandler = () => {
    if (window.confirm("Do you want to add complain?")) {
      setSave(!save);
      const d = new Date();
      const date =
        d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
      const data = {
        encounter: props.encounter_pid,
        patient: props.patient_pid,
        date: date,
        tags: tags,
        note: drug,
        period: period,
        none: none,
      };
      const url = `${window.baseURL}main/doctor/complain`;
      axios
        .post(
          url,
          { data },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
          }
        )
        .then((res) => {
          console.log("inside complain", res.data);
          // props.newStateDict(res.data);
          setTableData(res.data);
          setVal([]);
          setDrug([]);
          setPeriod([]);
          setNone([]);
          setTags([]);
          // props.reloading();
        });
    }
  };

  const deleteHandler = (id, complain_id) =>{
    if(window.confirm("Are you sure you want to delete this?")){
      const merge_id = patient_pid + '_' + id + '_' + complain_id
      const url = `${window.baseURL}main/doctor/complain/${merge_id}`;
      axios
        .delete(
          url,
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
          }
        )
        .then((res) => {
          setTableData(res.data);
        });
    }
  }

  return (
    <div>
      <div>
        <div className="row">
          <table className="table responsive table-sm">
            <thead>
              <tr>
                <th>Complains</th>
                <th>Period</th>
                <th></th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {val.map((data, i) => {
                return (
                  <>
                    <tr>
                      <td>
                        <AutoComplain
                          options={diag}
                          tag={(e) => getTags(e, i)}
                          removeTag={save}
                        />
                      <span className="fw-bolder p-1" style={{backgroundColor:"#FFC107"}}>Type, Search or Press Enter to Add your own Complains</span>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          style={{ width: "100px" }}
                          onChange={(e) => handlePeriod(e, i)}
                          id="period"
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          style={{ width: "100px" }}
                          onChange={(e) => handleNone(e, i)}
                          id="none"
                        >
                          <option value="" disabled>
                            Period
                          </option>
                          <option value="7">7</option>
                          <option value="52">52</option>
                          <option value="12">12</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Any Note..."
                          onChange={(e) => handleNote(e, i)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>

        <div class="d-grid gap-2 d-md-block">
          <button class="btn btn-primary" type="button" onClick={handleAdd}>
            Add more
          </button>
          <button
            type="button"
            className="btn btn-primary mt-3 ml-2 mb-3"
            onClick={submitHandler}
          >
            save
          </button>
        </div>

        {tableData && (
          <table className="table responsive">
            {tableData &&
              tableData.map((a, index) => {
                return (
                  <>
                    <thead>
                      <tr style={{ fontSize: "13px" }}>
                        <th>S/N</th>
                        <th>
                          {a.date}
                          {" (" + a.encounter.pid + ")"}
                        </th>
                        {/* <th scope="col"></th> */}
                        <th>Period</th>
                        <th>Note</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {a.ComplainDetails.map((d, ind) => {
                        return (
                          <tr key={ind}>
                            <th scope="row">{ind + 1}</th>
                            <td>
                              {d.ComplainDetailsTag.map((t) => {
                                return <>{t.complain} -</>;
                              })}
                            </td>
                            <td>{d.period} / {d.none}</td>
                            <td>{d.note}</td>
                            <td>
                              <button class="btn btn-danger btn-sm" onClick={()=>deleteHandler(d.id,a.id)}>Delete</button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                );
              })}
          </table>
        )}
      </div>
    </div>
  );
};

export default Complain;
