import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import Success from "../../ui/success";
import AddDrug from "../../modals/Pharmacy/AddDrug";
import DrugCategoryType from "./DrugCategoryType";
import { getFromServer, deleteFromServer } from "../../global/requests";

const Activation = () => {
  const [success, setSuccess] = useState(false);
  const [drugs, setDrugs] = useState();

  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState(false);

  const [drugEditId, setDrugEditId] = useState(null);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/add/drug`);
    if(result.status) setDrugs(result.data);
  }

  const closeHandler = () => setSuccess(false);
  const handleClose = () => {
    setDrugEditId(null);
    setShow(false);
  }
  const handleShow = () => {
    setDrugEditId(null);
    setShow(true);
  }

  const handleShowType = () => setShowType(true);
  const handleCloseType = () => setShowType(false);

  const updateDrugsHandler = (list) => setDrugs(list);
  const successHandler = (bool) => setSuccess(bool);

  const deleteHandler = async (id) => {
    if (window.confirm("Do you want to delete This Type?")) {
      const result = await deleteFromServer(`pharmacy/drugmgt/drug/${id}`);
      if(result.status) setDrugs(result.data);
    }
  };
  const editHandler = (id) => {
    setDrugEditId(id);
    setShow(true);
  }

  return (
    <>
      <MainContent>
        <div className="section-body">
          {!showType && (
            <div>
              <div className="row">
                <div className="col-12">
                  <button className="btn btn-primary mb-4" onClick={handleShow}>
                    Add New Drug
                  </button>
                  <button
                    className="btn btn-warning mb-4 ml-3"
                    onClick={handleShowType}
                  >
                    Add Drug Category
                  </button>
                </div>
              </div>
              {success && (
                <Success close={closeHandler}>
                  Drug Saved Successfully!!!
                </Success>
              )}

              <table className="table responsive table-bordered table-stripped">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    <th scope="col">#</th>
                    <th scope="col">Brand Name</th>
                    <th scope="col">Generic Name</th>
                    <th scope="col">Cost</th>
                    <th scope="col">Category</th>
                    <th scope="col">Type</th>
                    <th scope="col">Strength</th>
                    <th scope="col">Quantity of Dispensable</th>
                    <th scope="col">Packet Quantity</th>
                    <th scope="col">Reorder Level</th>
                    <th scope="col">Unit</th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {drugs &&
                    drugs.map((a, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{a.brandname}</td>
                          <td>{a.genericname}</td>
                          <td>{a.cost}</td>
                          <td>{a.category}</td>
                          <td>{a.type}</td>
                          <td>{a.strength}</td>
                          <td>{a.dispensablequantity}</td>
                          <td>{a.packetquantity}</td>
                          <td>{a.reorder}</td>
                          <td>{a.unit}</td>
                          <td>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => editHandler(a.id)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => deleteHandler(a.id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
          {showType && <DrugCategoryType back={handleCloseType} />}
        </div>
      </MainContent>
      {show && (
        <AddDrug
          show={show}
          close={handleClose}
          drugEditId={drugEditId}
          success={successHandler}
          drugs={updateDrugsHandler}
        />
      )}
    </>
  );
};

export default Activation;
