import React, { useState, useRef, useEffect } from "react";
import MainContent from "../../ui/MainContent";
import { postToServer, getFromServer } from "../../global/requests";
import {fromatToDateTime} from "../../global/common";
const Notifications = () => {
  // const result = await getFromServer(`billing/review-bill/${searchText.current.value}`);
  // if(result.status) setIsSearch(result.data);
  console.log(window.userInfo && window.userInfo.user_id);
  const [allNotifications, setAllNotifications] = useState([]);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    console.log("*****************************************");

    const result = await getFromServer(
      `communication/show-notification/${
        window.userInfo && window.userInfo.user_id
      }`
    );
    console.log("*****************************************");
    // if (result.status) {
    setAllNotifications(result.data);
    console.log(allNotifications);
    // }
  };

  return (
    <>
      <MainContent>
        <div class="card">
          <div class="card-header">
            <h4>All Notification</h4>
          </div>
          <div class="card-body">
            <ul class="list-group" id="all-notification">
              {allNotifications.map((noti) => (
                <li class="list-group-item list-group-item-primary mb-3 ">
                  {/* {noti[1]}  */}
                  <span dangerouslySetInnerHTML={{__html: noti[1]}}  />
                  &nbsp;
                  <span className="font-weight-bold h6">{fromatToDateTime(noti[2])}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default Notifications;
