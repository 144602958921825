// import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import Cookies from "js-cookie";
import { getFromServer, postToServer, putToServer } from "../../global/requests";

const AddDrug = (props) => {
  const [dcat, setDCat] = useState();
  const [drugForEdit, setDrugForEdit] = useState({});

  useEffect(() => {
    loadDrugCategories();
    if(props.drugEditId != null && props.drugEditId > 0) loadDrugForEdit(props.drugEditId);
  }, []);

  const loadDrugCategories = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/add/drugcategory`);
    if(result.status) setDCat(result.data);
  }

  const itemnumber = useRef();
  const brandname = useRef();
  const genericname = useRef();
  const description = useRef();
  const cost = useRef();
  const category = useRef();
  const type = useRef();
  const additionalinfo = useRef();
  const strength = useRef();
  const dispensablequantity = useRef();
  const packetquantity = useRef();
  const reorder = useRef();
  const unit = useRef();

  const loadDrugForEdit = async (id) => {
    const result = await getFromServer(`pharmacy/drugmgt/drug-info/${id}`);
    if(result.status) {
      setDrugForEdit(result.data);
      itemnumber.current.value = result.data.itemnumber;
      brandname.current.value = result.data.brandname;
      genericname.current.value = result.data.genericname;
      description.current.value = result.data.description;
      cost.current.value = result.data.cost;
      type.current.value = result.data.type;
      additionalinfo.current.value = result.data.additionalinfo;
      strength.current.value = result.data.strength;
      dispensablequantity.current.value = result.data.dispensablequantity;
      packetquantity.current.value = result.data.packetquantity;
      reorder.current.value = result.data.reorder;
      unit.current.value = result.data.unit;
    }
  }

  useEffect(() => {
    if(dcat && dcat.length > 0 && drugForEdit && drugForEdit.category) {
      category.current.value = drugForEdit.category;
    }
  }, [dcat, drugForEdit]);

  const formSubmission = async () => {
    const data = {
      itemnumber: itemnumber.current.value,
      brandname: brandname.current.value,
      genericname: genericname.current.value,
      description: description.current.value,
      cost: cost.current.value,
      category: category.current.value,
      type: type.current.value,
      additionalinfo: additionalinfo.current.value,
      strength: strength.current.value,
      dispensablequantity: dispensablequantity.current.value,
      packetquantity: packetquantity.current.value,
      reorder: reorder.current.value,
      unit: unit.current.value,
    };
    let result = {};
    if(drugForEdit && drugForEdit.id) {
      result = await putToServer(`pharmacy/drugmgt/drug-info/${drugForEdit.id}`, { data });
    } else {
      result = await postToServer(`pharmacy/drugmgt/add/drug`, { data });
    }
    if(result.status) {
      props.close();
      props.success(true);
      props.drugs(result.data);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formSubmission();
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Add New Drug</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="mb-2">
                <input
                  type="hidden"
                  name="patient_id"
                  className="form-control form-control-sm"
                />
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">
                      Hospital Item Number
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      required
                      ref={itemnumber}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">Brand Name</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      required
                      ref={brandname}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">Generic Name</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      required
                      ref={genericname}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">Full Description</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      required
                      ref={description}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">Cost</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      ref={cost}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label>Drug Category</label>
                    <select className="form-select" ref={category}>
                      {dcat &&
                        dcat.map((u) => {
                          return (
                            <option key={u.id} value={u.category}>
                              {u.category}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label>Drug Type</label>
                    <select className="form-select" ref={type}>
                      <option value="Tablet">Tablet</option>
                      <option value="Syrup">Syrup</option>
                      <option value="Injection">Injection</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">Additional Info</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      
                      ref={additionalinfo}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">Drug Strength</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      
                      ref={strength}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">
                      Quantity in Dispensable pack
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      
                      ref={dispensablequantity}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">Packet Quantity</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      
                      ref={packetquantity}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">Reorder Level</label>
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      
                      ref={reorder}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-2">
                    <label className="col-form-label">
                      Unit of Measurement
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      required
                      ref={unit}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddDrug;
