import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Success from "../../ui/success";
import Error from "../../ui/error";
import { getFromServer, postToServer } from "../../global/requests";
import PatientDetailCard from "../../ui/PatientDetailCard";



const Encounter = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const p = location.state.patient;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [dept, setDepartment] = useState();
  const [consultation, setconsultation] = useState();
  const [billing, setBilling] = useState();
  const [dates, time] = formatDate(new Date()).split(" ");
  const [registrationServices, setRegistrationServices] = useState([]);

  const type = useRef();
  const profile = useRef();
  const department = useRef();
  const bill = useRef();
  const room = useRef();
  const date = useRef();
  const registration = useRef();

  // 👇️ (Helper functions)
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        // padTo2Digits(date.getSeconds()),  // 👈️ can also add seconds
      ].join(":")
    );
  }

  useEffect(() => {
    loadDepartments();
    loadBillings();
    // var now = new Date();
    // var day = ("0" + now.getDate()).slice(-2);
    // var month = ("0" + (now.getMonth() + 1)).slice(-2);
    // var today = now.getFullYear() + "-" + month + "-" + day;
    document.getElementById("encounter_date").value = dates + "T" + time;
  }, []);

  useEffect(() => {
    loadRegistrationServices();
  }, [billing])

  const loadDepartments = async () => {
    const result = await getFromServer("usermgt/useraccess/add/department");
    if (result.status) setDepartment(result.data);
  };
  const loadBillings = async () => {
    const result = await getFromServer(`main/frontdesk/billing/${p.pid}`);
    if (result.status) setBilling(result.data);
  };
  const loadRegistrationServices = async () => {
    setRegistrationServices([]);
    if(profile.current.value != "") {
      const result = await getFromServer(`billing/get-service-by-billprofile/${profile.current.value}/Registration`);
      if (result.status) setRegistrationServices(result.data);
    }
  }

  const setBillHandler = async (e) => {
    const depts = e.target.value.split("_");
    const result = await getFromServer(`billing/getservice/${depts[0]}`);
    if (result.status) setconsultation(result.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const depts = department.current.value.split("_");
    if (depts[1] == "true") depts[1] = true;
    if (depts[2] == "true") depts[2] = true;
    if (depts[1] == "false") depts[1] = false;
    if (depts[2] == "false") depts[2] = false;
    const data = {
      id: p.pid,
      type: type.current.value,
      department: depts[0],
      isAntenatal: depts[1],
      isImmunization: depts[2],
      profile: profile.current.value,
      consultbill: bill.current.value,
      date: date.current.value,
      registration: registration.current.value,
    };

    const result = await postToServer(`main/frontdesk/encounter`, { data });
    if (result.status) {
      if (result.data == 'none') setError(true)
      else navigate(`/view-encounter/pid=${result.data}/patient=${p.pid}`);
      
    }
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <PatientDetailCard list={p} isEncounter={false} />
          </div>
          {success && <Success>Encounter Added Successfully.</Success>}
          {error && <Error close={()=>setError(false)}>Encounter already exists, which isn't discharged from Discharge Patient page yet.</Error>}
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mb-3">
                  <label className="fw-bold">Encounter Type</label>
                  <select
                    className="form-select"
                    style={{ borderColor: "lightblue" }}
                    ref={type}
                    required
                  >
                    <option value="Walk-in">Walk-in</option>
                    <option value="Emergency">Emergency</option>
                  </select>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="fw-bold">*Billing Profile</label>
                    <select
                      className="form-select"
                      style={{ borderColor: "lightblue" }}
                      ref={profile}
                      required
                      onChange={loadRegistrationServices}
                    >
                      {billing &&
                        billing.map((b) => {
                          return (
                            <option
                              key={b.id}
                              value={b.company + "=>" + b.plan}
                            >
                              {b.company + " => " + b.plan}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="fw-bold">*Department</label>
                    <select
                      className="form-select"
                      style={{ borderColor: "lightblue" }}
                      ref={department}
                      required
                      onChange={setBillHandler}
                    >
                      {dept &&
                        dept.map((b) => {
                          return (
                            <option
                              key={b.id}
                              value={`${b.department}_${b.isAntenatal}_${b.isImmunization}`}
                            >
                              {b.department}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="fw-bold">*Consultaion Bill</label>
                    <select
                      className="form-select"
                      style={{ borderColor: "lightblue" }}
                      ref={bill}
                      // required
                    >
                      {consultation &&
                        consultation.map((b) => {
                          return (
                            <option key={b.id} value={b.name}>
                              {b.name}
                            </option>
                          );
                        })}{" "}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="fw-bold">*Date</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      style={{ borderColor: "lightblue" }}
                      id="encounter_date"
                      ref={date}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="fw-bold">*Registration</label>
                    <select
                      className="form-select"
                      style={{ borderColor: "lightblue" }}
                      ref={registration}
                    >
                      <option value=""></option>
                      {/* <option value="Registration">Registration</option>
                      <option value="ANC Booking">ANC Booking</option>
                      <option value="Card Renewal">Card Renewal</option>
                      <option value="Family">Family</option> */}
                      {registrationServices.map((regSer) => <option value={regSer.id}>{regSer.name}</option>)}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Create
              </button>
            </div>
          </form>
        </div>
      </MainContent>
    </>
  );
};

export default Encounter;
