import React, { useContext, useEffect } from "react";
import { Notifications } from "../../store/Context";
import MainContent from "../../ui/MainContent";
import { chart1, chart2, chart3, chart4 } from "../../js-files/charts";
import banner1 from "../../../assets/img/banner/1.png";
import banner2 from "../../../assets/img/banner/2.png";
import banner3 from "../../../assets/img/banner/3.png";
import banner4 from "../../../assets/img/banner/4.png";

// var firstLoad = true;

const Dashboard = () => {
  const ctx = useContext(Notifications);

  useEffect(() => {
    loadAllCharts();
  }, []);

  const loadAllCharts = () => {
    const dashSectionBody = document.getElementById('dash-section-body');
    if(dashSectionBody.getAttribute('data-loaded') != 1) {
      chart1();
      chart2();
      chart3();
      chart4();
      dashSectionBody.setAttribute('data-loaded', 1);
    }
  }

  return (
    <>
      <MainContent>
        <div className="section-body" id="dash-section-body">
          <div className="row ">
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="card">
                <div className="card-statistic-4">
                  <div className="align-items-center justify-content-between">
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                        <div className="card-content">
                          <h5 className="font-15">New Registration</h5>
                          <h2 className="mb-3 font-18">258</h2>
                          <p className="mb-0">
                            <span className="col-green">10%</span> Increase
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                        <div className="banner-img">
                          <img src={banner1} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="card">
                <div className="card-statistic-4">
                  <div className="align-items-center justify-content-between">
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                        <div className="card-content">
                          <h5 className="font-15"> Patient Visit Today</h5>
                          <h2 className="mb-3 font-18">1,287</h2>
                          <p className="mb-0">
                            <span className="col-orange">09%</span> Decrease
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                        <div className="banner-img">
                          <img src={banner2} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="card">
                <div className="card-statistic-4">
                  <div className="align-items-center justify-content-between">
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                        <div className="card-content">
                          <h5 className="font-15">Appointment</h5>
                          <h2 className="mb-3 font-18">128</h2>
                          <p className="mb-0">
                            <span className="col-green">18%</span>
                            Increase
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                        <div className="banner-img">
                          <img src={banner3} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="card">
                <div className="card-statistic-4">
                  <div className="align-items-center justify-content-between">
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                        <div className="card-content">
                          <h5 className="font-15">Online Consultation</h5>

                          <p className="mb-0">
                            <span className="col-green">42%</span> Increase
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                        <div className="banner-img">
                          <img src={banner4} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-12">
              <div className="card ">
                <div className="card-header">
                  <h4>Revenue chart</h4>
                  <div className="card-header-action">
                    <div className="dropdown">
                      <a
                        href="#"
                        data-toggle="dropdown"
                        className="btn btn-warning dropdown-toggle"
                      >
                        Options
                      </a>
                      <div className="dropdown-menu">
                        <a href="#" className="dropdown-item has-icon">
                          <i className="fas fa-eye"></i>
                          View
                        </a>
                        <a href="#" className="dropdown-item has-icon">
                          <i className="far fa-edit"></i>
                          Edit
                        </a>
                        <div className="dropdown-divider"></div>
                        <a
                          href="#"
                          className="dropdown-item has-icon text-danger"
                        >
                          <i className="far fa-trash-alt"></i>
                          Delete
                        </a>
                      </div>
                    </div>
                    <a href="#" className="btn btn-primary">
                      View All
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-9">
                      <div id="chart1"></div>
                      <div className="row mb-0">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                          <div className="list-inline text-center">
                            <div className="list-inline-item p-r-30">
                              <i
                                data-feather="arrow-up-circle"
                                className="col-green"
                              ></i>
                              <h5 className="m-b-0">#675,000</h5>
                              <p className="text-muted font-14 m-b-0">
                                Weekly Earnings
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                          <div className="list-inline text-center">
                            <div className="list-inline-item p-r-30">
                              <i
                                data-feather="arrow-down-circle"
                                className="col-orange"
                              ></i>
                              <h5 className="m-b-0">#96,587</h5>
                              <p className="text-muted font-14 m-b-0">
                                Monthly Earnings
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                          <div className="list-inline text-center">
                            <div className="list-inline-item p-r-30">
                              <i
                                data-feather="arrow-up-circle"
                                className="col-green"
                              ></i>
                              <h5 className="mb-0 m-b-0">$14,500,965</h5>
                              <p className="text-muted font-14 m-b-0">
                                Yearly Earnings
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="row mt-5">
                        <div className="col-7 col-xl-7 mb-3">
                          Total customers
                        </div>
                        <div className="col-5 col-xl-5 mb-3">
                          <span className="text-big">8,257</span>
                          <sup className="col-green">+09%</sup>
                        </div>
                        <div className="col-7 col-xl-7 mb-3">Total Income</div>
                        <div className="col-5 col-xl-5 mb-3">
                          <span className="text-big">#9,012,857</span>
                          <sup className="text-danger">-18%</sup>
                        </div>
                        <div className="col-7 col-xl-7 mb-3">
                          Project completed
                        </div>
                        <div className="col-5 col-xl-5 mb-3">
                          <span className="text-big">28</span>
                          <sup className="col-green">+16%</sup>
                        </div>
                        <div className="col-7 col-xl-7 mb-3">Total expense</div>
                        <div className="col-5 col-xl-5 mb-3">
                          <span className="text-big">#5,000,287</span>
                          <sup className="col-green">+09%</sup>
                        </div>
                        <div className="col-7 col-xl-7 mb-3">New Customers</div>
                        <div className="col-5 col-xl-5 mb-3">
                          <span className="text-big">684</span>
                          <sup className="col-green">+22%</sup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-4">
              <div className="card">
                <div className="card-header">
                  <h4>Revenue</h4>
                </div>
                <div className="card-body">
                  <div id="chart4" className="chartsh"></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-4">
              <div className="card">
                <div className="card-header">
                  <h4>Patients Report</h4>
                </div>
                <div className="card-body">
                  <div className="summary">
                    <div
                      className="summary-chart active"
                      data-tab-group="summary-tab"
                      id="summary-chart"
                    >
                      <div id="chart3" className="chartsh"></div>
                    </div>
                    <div data-tab-group="summary-tab" id="summary-text"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-4">
              <div className="card">
                <div className="card-header">
                  <h4>Emergency Cases</h4>
                </div>
                <div className="card-body">
                  <div id="chart2" className="chartsh"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Online Consultation</h4>
                  <div className="card-header-form">
                    <form>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                        />
                        <div className="input-group-btn">
                          <button className="btn btn-primary">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <tr>
                        <th className="text-center">
                          <div className="custom-checkbox custom-checkbox-table custom-control">
                            <input
                              type="checkbox"
                              data-checkboxes="mygroup"
                              data-checkbox-role="dad"
                              className="custom-control-input"
                              id="checkbox-all"
                            />
                            <label
                              for="checkbox-all"
                              className="custom-control-label"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </th>
                        <th>Task Name</th>
                        <th>Members</th>
                        <th>Task Status</th>
                        <th>Assigh Date</th>
                        <th>Due Date</th>
                        <th>Priority</th>
                        <th>Action</th>
                      </tr>
                      <tr>
                        <td className="p-0 text-center">
                          <div className="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              data-checkboxes="mygroup"
                              className="custom-control-input"
                              id="checkbox-1"
                            />
                            <label
                              for="checkbox-1"
                              className="custom-control-label"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </td>
                        <td>Create a mobile app</td>
                        <td className="text-truncate">
                          <ul className="list-unstyled order-list m-b-0 m-b-0">
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-8.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Wildan Ahdian"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-9.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="John Deo"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-10.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Sarah Smith"
                              />
                            </li>
                            <li className="avatar avatar-sm">
                              <span className="badge badge-primary">+4</span>
                            </li>
                          </ul>
                        </td>
                        <td className="align-middle">
                          <div className="progress-text">50%</div>
                          <div className="progress" data-height="6">
                            <div
                              className="progress-bar bg-success"
                              data-width="50%"
                            ></div>
                          </div>
                        </td>
                        <td>2018-01-20</td>
                        <td>2019-05-28</td>
                        <td>
                          <div className="badge badge-success">Low</div>
                        </td>
                        <td>
                          <a href="#" className="btn btn-outline-primary">
                            Detail
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0 text-center">
                          <div className="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              data-checkboxes="mygroup"
                              className="custom-control-input"
                              id="checkbox-2"
                            />
                            <label
                              for="checkbox-2"
                              className="custom-control-label"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </td>
                        <td>Redesign homepage</td>
                        <td className="text-truncate">
                          <ul className="list-unstyled order-list m-b-0 m-b-0">
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-1.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Wildan Ahdian"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-2.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="John Deo"
                              />
                            </li>
                            <li className="avatar avatar-sm">
                              <span className="badge badge-primary">+2</span>
                            </li>
                          </ul>
                        </td>
                        <td className="align-middle">
                          <div className="progress-text">40%</div>
                          <div className="progress" data-height="6">
                            <div
                              className="progress-bar bg-danger"
                              data-width="40%"
                            ></div>
                          </div>
                        </td>
                        <td>2017-07-14</td>
                        <td>2018-07-21</td>
                        <td>
                          <div className="badge badge-danger">High</div>
                        </td>
                        <td>
                          <a href="#" className="btn btn-outline-primary">
                            Detail
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0 text-center">
                          <div className="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              data-checkboxes="mygroup"
                              className="custom-control-input"
                              id="checkbox-3"
                            />
                            <label
                              for="checkbox-3"
                              className="custom-control-label"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </td>
                        <td>Backup database</td>
                        <td className="text-truncate">
                          <ul className="list-unstyled order-list m-b-0 m-b-0">
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-3.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Wildan Ahdian"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-4.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="John Deo"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-5.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Sarah Smith"
                              />
                            </li>
                            <li className="avatar avatar-sm">
                              <span className="badge badge-primary">+3</span>
                            </li>
                          </ul>
                        </td>
                        <td className="align-middle">
                          <div className="progress-text">55%</div>
                          <div className="progress" data-height="6">
                            <div
                              className="progress-bar bg-purple"
                              data-width="55%"
                            ></div>
                          </div>
                        </td>
                        <td>2019-07-25</td>
                        <td>2019-08-17</td>
                        <td>
                          <div className="badge badge-info">Average</div>
                        </td>
                        <td>
                          <a href="#" className="btn btn-outline-primary">
                            Detail
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0 text-center">
                          <div className="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              data-checkboxes="mygroup"
                              className="custom-control-input"
                              id="checkbox-4"
                            />
                            <label
                              for="checkbox-4"
                              className="custom-control-label"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </td>
                        <td>Android App</td>
                        <td className="text-truncate">
                          <ul className="list-unstyled order-list m-b-0 m-b-0">
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-7.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="John Deo"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-8.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Sarah Smith"
                              />
                            </li>
                            <li className="avatar avatar-sm">
                              <span className="badge badge-primary">+4</span>
                            </li>
                          </ul>
                        </td>
                        <td className="align-middle">
                          <div className="progress-text">70%</div>
                          <div className="progress" data-height="6">
                            <div
                              className="progress-bar"
                              data-width="70%"
                            ></div>
                          </div>
                        </td>
                        <td>2018-04-15</td>
                        <td>2019-07-19</td>
                        <td>
                          <div className="badge badge-success">Low</div>
                        </td>
                        <td>
                          <a href="#" className="btn btn-outline-primary">
                            Detail
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0 text-center">
                          <div className="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              data-checkboxes="mygroup"
                              className="custom-control-input"
                              id="checkbox-5"
                            />
                            <label
                              for="checkbox-5"
                              className="custom-control-label"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </td>
                        <td>Logo Design</td>
                        <td className="text-truncate">
                          <ul className="list-unstyled order-list m-b-0 m-b-0">
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-9.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Wildan Ahdian"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-10.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="John Deo"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-2.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Sarah Smith"
                              />
                            </li>
                            <li className="avatar avatar-sm">
                              <span className="badge badge-primary">+2</span>
                            </li>
                          </ul>
                        </td>
                        <td className="align-middle">
                          <div className="progress-text">45%</div>
                          <div className="progress" data-height="6">
                            <div
                              className="progress-bar bg-cyan"
                              data-width="45%"
                            ></div>
                          </div>
                        </td>
                        <td>2017-02-24</td>
                        <td>2018-09-06</td>
                        <td>
                          <div className="badge badge-danger">High</div>
                        </td>
                        <td>
                          <a href="#" className="btn btn-outline-primary">
                            Detail
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0 text-center">
                          <div className="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              data-checkboxes="mygroup"
                              className="custom-control-input"
                              id="checkbox-6"
                            />
                            <label
                              for="checkbox-6"
                              className="custom-control-label"
                            >
                              &nbsp;
                            </label>
                          </div>
                        </td>
                        <td>Ecommerce website</td>
                        <td className="text-truncate">
                          <ul className="list-unstyled order-list m-b-0 m-b-0">
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-9.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Wildan Ahdian"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-10.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="John Deo"
                              />
                            </li>
                            <li className="team-member team-member-sm">
                              <img
                                className="rounded-circle"
                                src="assets/img/users/user-2.png"
                                alt="user"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Sarah Smith"
                              />
                            </li>
                            <li className="avatar avatar-sm">
                              <span className="badge badge-primary">+4</span>
                            </li>
                          </ul>
                        </td>
                        <td className="align-middle">
                          <div className="progress-text">30%</div>
                          <div className="progress" data-height="6">
                            <div
                              className="progress-bar bg-orange"
                              data-width="30%"
                            ></div>
                          </div>
                        </td>
                        <td>2018-01-20</td>
                        <td>2019-05-28</td>
                        <td>
                          <div className="badge badge-info">Average</div>
                        </td>
                        <td>
                          <a href="#" className="btn btn-outline-primary">
                            Detail
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default Dashboard;
