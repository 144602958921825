import React, {useState} from "react";
import MainContent from "../../ui/MainContent";
import { getFromServer, downloadFileFromUrl } from "../../global/requests";

const DbBackup = () => {
  // variables
  const [message, setMessage] = useState(null);
  const [btnText, setBtnText] = useState("Generate and download DB Backup");
  const [loading, setLoading] = useState(false);

  const genDownloadBk = async () => {
    setLoading(true);
    setMessage("Generating db backup. Please wait and don't try to navigate on other page...");
    setBtnText("Generating backup...")
    const result = await getFromServer('adminmgt/generate-db-backup');
    if(result.status) {
      if(result.data.status) {
        setMessage("DB backup generated. Download started.");
        setBtnText("Downloading backup...")
        await downloadFileFromUrl('adminmgt/download-db-backup', result.data.filename);
        setMessage("DB backup generated and downloaded.");
      } else {
        setMessage("DB backup not generated. Something went wrong.");
      }
    } else {
      setMessage("Something went wrong.");
    }
    setBtnText("Generate and download DB Backup");
    setLoading(false);
  }
  return (
    <MainContent>
      <div className="section-body">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4>Backup Database</h4>
              </div>
              <div className="card-body" style={{ minHeight: "80vh" }}>
                <div>
                  <button className="btn btn-primary" onClick={genDownloadBk} disabled={loading}>
                  {loading &&
                  <span><i style={{fontSize: '20px'}} className="fas fa-spinner fa-spin"></i>&nbsp;</span>
                  }
                    {btnText}
                  </button>
                  {message &&
                  <p style={{fontSize: '20px', marginTop: '10px'}}>
                    {loading &&
                    <span><i style={{fontSize: '20px'}} className="fas fa-spinner fa-spin"></i>&nbsp;</span>
                    }
                    {message}
                  </p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default DbBackup;
