import React, { useEffect, useState } from "react";
import MainContent from "../../ui/MainContent";
import { getFromServer, downloadFileFromUrl } from "../../global/requests";
import Loading from "../../ui/Loading";
import DateRangeWidget from "../../widgets/reports/DateRangeWidget";
import ReportHead from "../../widgets/reports/ReportHead";

const RadiologyTestCountReport = () => {
  // variables
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [search, setSearch] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    loadListData();
  }, []);

  const loadListData = async () => {
    setIsLoading(true);
    const result = await getFromServer(
      `reports/radiology-test-count?search=${search}&date_from=${dateFrom}&date_to=${dateTo}`
    );
    if (result.status) {
      setRows(result.data.rows);
      setColumns(result.data.columns);
    }
    setIsLoading(false);
  };
  const exportToExcel = async () => {
    setDownloading(true);
    await downloadFileFromUrl(
      `reports/radiology-test-count?download=1&search=${search}&date_from=${dateFrom}&date_to=${dateTo}`,
      "radiology-test-count-report.xlsx"
    );
    setDownloading(false);
  };
  const filterHandler = () => loadListData();

  return (
    <MainContent>
      <div className="section-body">
        <div className="row mb-2">
          <h3>Radiology Test Count Report</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body row">
                <DateRangeWidget dateFrom={dateFrom} dateTo={dateTo} setDateFrom={setDateFrom} setDateTo={setDateTo} />
                <div className="filter-btn-box">
                  <button className="btn btn-primary" onClick={filterHandler}>
                    Filter
                  </button>
                </div>
                <div className="filter-btn-box">
                  {downloading ? (
                    <button className="btn btn-primary" onClick={exportToExcel} disabled>
                      <i className="fas fa-spinner fa-spin"></i>
                      &nbsp;Exporting...
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={exportToExcel}>
                      Export
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <ReportHead search={search} setSearch={setSearch} loadListData={loadListData}  />
              <div className="card-body">
                {isLoading ? (
                  <div style={{ margin: "100px 0" }}>
                    <Loading />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-bordered table-md report">
                      <tr>
                        <th>#</th>
                        <th>{columns[1]}</th>
                        <th>{columns[2]}</th>
                      </tr>
                      {rows.map((row, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{row[1]}</td>
                          <td>{row[2]}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                )}
              </div>
              {/* <div className="card-footer text-right"></div> */}
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  )
}

export default RadiologyTestCountReport;