import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, postToServer } from "../../global/requests";

const AdmissionPatient = (props) => {
  const [nursing, setNursing] = useState();
  const type = useRef();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/nursing-station`);
    if(result.status) {
      console.log(result);
      setNursing(result.data);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      id: props.id,
    };

    const result = await postToServer(`main/frontdesk/encounter-admission/${type.current.value}`, { data });
    if(result.status) {
      props.success(true);
      props.close();
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Patient Admission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label>Nursing Stations</label>
                    <select className="form-select" ref={type}>
                      {nursing &&
                        nursing.map((u) => {
                          return (
                            <option key={u.id} value={u.station}>
                              {u.station}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Admit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdmissionPatient;
