import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import Success from "../../ui/success";
import NurseNoteModel from "../../modals/AdmissionMgt/NurseNoteModel";
import { getFromServer } from "../../global/requests";
import { deleteFromServer } from "../../global/requests";

const Note = (props) => {
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [careplan, setCarePlan] = useState(false);
  const [is_edit, setIsEdit] = useState(false);
  const [tempData, setTempData] = useState({});

  const assessment = useRef();
  const activities = useRef();
  const drugs = useRef();
  const diet = useRef();
  const role = useRef();
  const others = useRef();
  const home = useRef();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/nurse-note/${props.encounter_pid}`);
    if (result.status) {
      setCarePlan(result.data);
      console.log(result.data);
    }
  };

  const updateHandler = (list) => {
    setCarePlan(list);
  };

  const showHandler = (is_edit, data) => {
    setTempData(data);
    setIsEdit(is_edit);
    setShow(true);
  };

  const deleteHandler = async (id) => {
    if (window.confirm("Do you want to delete this?")) {
      const merge_id = props.encounter_pid + "_" + id;
      const result = await deleteFromServer(`usermgt/nurse-note/${merge_id}`);
      if(result.status) setCarePlan(result.data);
    }
  };


  return (
    <>
    <div className="p-3">
    <div className="mt-4  mb-2">
        <button
          className="btn btn-primary"
          onClick={() => showHandler(false, {})}
        >
          Add Record
        </button>
      </div>
      {success && (
        <Success close={() => setSuccess(false)}>
          Data Initialized Successfully
        </Success>
      )}


      <table className="table table-bordered mt-5">
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Assessment</th>
            <th>Activities</th>
            <th>Drugs</th>
            <th>Diet</th>
            <th>Home Assessment</th>
            <th>Others</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {careplan &&
            careplan.map((c, i) => {
              return (
                <tr key={c.id}>
                  <td>{i + 1}</td>
                  <td>{c.date.split(" ")[0]}</td>
                  <td>{c.assessment}</td>
                  <td>{c.activities}</td>
                  <td>{c.drugs}</td>
                  <td>{c.diet}</td>
                  <td>{c.home}</td>
                  <td>{c.others}</td>
                  <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => showHandler(true, c)}
                      >
                        Edit
                      </button>
                      <button className="btn btn-sm btn-danger ml-1" onClick={()=>deleteHandler(c.id)}>
                        Delete
                      </button>
                    </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
    {show && (
      <NurseNoteModel
        show={show}
        close={() => setShow(false)}
        encounter_pid={props.encounter_pid}
        success={() => setSuccess(true)}
        update={updateHandler}
        is_edit={is_edit}
        tempData={tempData}
      />
    )}
    </>
  );
};

export default Note;
