import React, { useState, useEffect, useRef } from "react";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import Success from "../../ui/success";
import { getFromServer, postToServer } from "../../global/requests";
import { checkDateBW } from "../../global/common";

const DrugExpiryReport = () => {
  const [order, setOrder] = useState([]);
  const [search, setSearch] = useState([]);
  const [success, setSuccess] = useState(false);

  const date = useRef();
  const date2 = useRef();

  const dateHandler = (e, i) => {
    const orders = [...order];
    orders[i].date = e.target.value;
    setOrder(orders);
  };

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/purchase-order/confirm`);
    if(result.status) {
      console.log(result.data);
      for (var i = 0; i < result.data.length; i++) {
        var date = result.data[i].date.split(/(\s+)/)[0].split("-");
        const y = date[0];
        const m = date[1];
        const d = date[2];
        var newdate = new Date(y + "-" + m + "-" + d)
          .toISOString()
          .split("T")[0];
        result.data[i].date = newdate;
      }
      setOrder(result.data);
      setSearch(result.data);
    }
  }

  const filterHandler = () => {
    const dateVal = date.current.value;
    const date2Val = date2.current.value;
    if(dateVal != "" && date2Val != "") {
      const newdata = search.filter((a) => {
        // var d = new Date(a.date).toISOString().split("T")[0];
        // return dateVal >= d && date2Val <= d;
        return checkDateBW(dateVal, date2Val, a.date);
      });
      setOrder(newdata);
    } else alert("Please select date range to filter list.")
  };
  const clearFilter = () => {
    date.current.value = "";
    date2.current.value = "";
    setOrder(search);
  }

  const dateSaveHandler = async (id, index) => {
    const data = {
      date: order[index].date,
    };
    console.log("=>", data);

    const result = await postToServer(`pharmacy/purchase-order/${id}`, { data });
    if(result.status) setSuccess(true);
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={() => setSuccess(false)}>
            Expiry Date updated Successfully
          </Success>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <h4>Drug Expiry Report</h4>
            </div>
          </div>
          <div className="mt-2 mb-2 row">
            <div className="col-md-3">
              <input type="date" className="form-control" ref={date} />
            </div>
            <div className="col-md-3">
              <input type="date" className="form-control" ref={date2} />
            </div>
            <div className="col-md-4">
              <button className="btn btn-primary" onClick={filterHandler}>
                Filter
              </button>
              &nbsp;&nbsp;
              <button className="btn btn-primary" onClick={clearFilter}>
                clear Filter
              </button>
            </div>
          </div>

          <div className="tablexflow mt-3">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">S\N</th>
                  <th scope="col">Item Name</th>
                  <th scope="col">Recieved Quantity</th>
                  <th scope="col">Unit Cost</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Store</th>
                  <th scope="col">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {order &&
                  order.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.item}</td>
                        <td>{a.quantity}</td>
                        <td>{a.cost}</td>
                        <td>{a.date}</td>
                        <td>{a.type}</td>
                        <td>{a.status}</td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => dateSaveHandler(a.id, index)}
                          >
                            save
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default DrugExpiryReport;
