import React, { useEffect, useState } from "react";
import MainContent from "../../ui/MainContent";
import { getFromServer, downloadFileFromUrl } from "../../global/requests";
import Loading from "../../ui/Loading";
import { genReportPagination } from "../../helpers/report-helpers";
import DateRangeWidget from "../../widgets/reports/DateRangeWidget";
import ReportHead from "../../widgets/reports/ReportHead";

var oldQueryString = "";
const DispensaryReport = () => {
  // variables
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [search, setSearch] = useState("");
  const [noOfRow, setNoOfRow] = useState(25);
  const [pageNo, setPageNo] = useState(0);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageBtns, setPageBtns] = useState([]);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    loadListData();
  }, [noOfRow, pageNo]);

  const loadListData = async () => {
    const qString = `&search=${search}&date_from=${dateFrom}&date_to=${dateTo}&no_of_row=${noOfRow}`;
    if (oldQueryString != qString) {
      oldQueryString = qString;
      if (pageNo != 0) {
        setPageNo(0);
        return;
      }
    }
    setIsLoading(true);
    const result = await getFromServer(
      `reports/dispensary?offset=${pageNo * noOfRow}${qString}`
    );
    if (result.status) {
      setData(result.data.data);
      setColumns(result.data.columns);
      setCount(result.data.count);
      setPageBtns(genReportPagination(result.data.count, noOfRow, pageNo, setPageNo));
    }
    setIsLoading(false);
  };
  const exportToExcel = async () => {
    setDownloading(true);
    await downloadFileFromUrl(
      `reports/dispensary?download=1&search=${search}&date_from=${dateFrom}&date_to=${dateTo}`,
      "dispensary-report.xlsx"
    );
    setDownloading(false);
  };
  const filterHandler = () => loadListData();

  return (
    <MainContent>
      <div className="section-body">
        <div className="row mb-2">
          <h3>Dispensary Report</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body row">
                <DateRangeWidget dateFrom={dateFrom} dateTo={dateTo} setDateFrom={setDateFrom} setDateTo={setDateTo} name="Appointment" />
                <div className="filter-btn-box">
                  <button className="btn btn-primary" onClick={filterHandler}>
                    Filter
                  </button>
                </div>
                <div className="filter-btn-box">
                  {downloading ? (
                    <button className="btn btn-primary" onClick={exportToExcel} disabled>
                      <i className="fas fa-spinner fa-spin"></i>
                      &nbsp;Exporting...
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={exportToExcel}>
                      Export
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <ReportHead search={search} setSearch={setSearch} noOfRow={noOfRow} setNoOfRow={setNoOfRow} loadListData={loadListData}  />
              <div className="card-body">
                {isLoading ? (
                  <div style={{ margin: "100px 0" }}>
                    <Loading />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-bordered table-md td-nowap report">
                      <tr>
                        <th>#</th>
                        <th>{columns[1]}</th>
                        <th>{columns[2]}</th>
                        <th>{columns[3]}</th>
                        <th>{columns[4]}</th>
                        <th>{columns[5]}</th>
                        <th>{columns[6]}</th>
                        <th>{columns[7]}</th>
                        <th>Complain</th>
                        <th>Diagnosis</th>
                        <th>Investigation</th>
                        <th>Prescription</th>
                        <th>Prescription Qty</th>
                        <th>Prescription Qty Given</th>
                        <th>Name of Doctor that Prescribe Drugs</th>
                        <th>Dispensed By</th>
                      </tr>
                      {data.map((record, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{record.row[1]}</td>
                          <td>{record.row[2]}</td>
                          <td>{record.row[3]}</td>
                          <td>{record.row[4]}</td>
                          <td>{record.row[5]}</td>
                          <td>{record.row[6]}</td>
                          <td>{record.row[7]}</td>
                          <td>{record.complains.map((complain, i) =><p>{i + 1}) {complain[0]}</p>)}</td>
                          <td>{record.diagnosis.map((diagnosisRow, i) =><p>{i + 1}) {diagnosisRow[0]}</p>)}</td>
                          <td>{record.investigations.map((investigation, i) =><p>{i + 1}) {investigation[0]}</p>)}</td>
                          <td>{record.prsecriptions.map((prsecription, i) =><p>{i + 1}) {prsecription[0]}</p>)}</td>
                          <td>{record.prsecriptions.map((prsecription, i) =><p>{i + 1}) {prsecription[1]}</p>)}</td>
                          <td>{record.prsecriptions.map((prsecription, i) =><p>{i + 1}) {prsecription[1]}</p>)}</td>
                          <td>{record.prsecriptions.map((prsecription, i) =><p>{i + 1}) {prsecription[2]}</p>)}</td>
                          <td>{record.prsecriptions.map((prsecription, i) =><p>{i + 1}) {prsecription[3]}</p>)}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                )}
                <div className="table-pagination">
                  <p>
                    Showing {noOfRow * pageNo + 1} to{" "}
                    {noOfRow * pageNo + data.length} of {count} entries
                  </p>
                  <nav className="d-inline-block">
                    <ul className="pagination mb-0">{pageBtns}</ul>
                  </nav>
                </div>
              </div>
              {/* <div className="card-footer text-right"></div> */}
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  )
}

export default DispensaryReport;