import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import Success from "../../ui/success";
import { FcPlus } from "react-icons/fc";
import AddPatienttoBed from "../../modals/AdmissionMgt/AddPatienttoBed";
import { FaBed } from "react-icons/fa";
import { ImBoxRemove } from "react-icons/im";
import { BiTransfer } from "react-icons/bi";
import { AiFillFolderOpen } from "react-icons/ai";
import Loading from "../../ui/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import MainContent from "../../ui/MainContent";
import NursingHistory from "./NursingHistory";
import AttendtoPatient from "../Doctor/AttendtoPatient";
import { getFromServer, deleteFromServer, postToServer } from "../../global/requests";

const AdmssionMgt = () => {
  const param = useLocation();
  const navigate = useNavigate();

  const nursingProps = param.state.nursing;
  const splitting = String(nursingProps).split("_");
  const nursingId = splitting[0];
  const nursingName = splitting[1];

  const [addRoom, setAddRoom] = useState(false);
  const [addBed, setAddBed] = useState(false);
  const [load, setLoad] = useState(true);
  const [reload, setReload] = useState(true);

  const [takeover, setTakeover] = useState();
  const [taken, setTaken] = useState();
  const [attendpatient, setAttendPatient] = useState([]);
  const [tempPatientPID, setTempPatientPID] = useState();
  const [reloading, setReloading] = useState(false);
  const [waitinglist, setWaitinglist] = useState();

  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [success, setSuccess] = useState(false);

  const [tempId, setTempId] = useState();
  const [tempEncounterPid, setTempEncounterPid] = useState();

  const successClose = () => setSuccess(false);
  const handleClose = () => setShow(false);
  const handleCloseHistory = () => setShowHistory(false);
  const handleShow = (id) => {
    setShow(true);
    setTempId(id);
  };
  const handleShowHistory = (id) => {
    setShowHistory(true);
    setTempEncounterPid(id);
  };

  const [roomList, setRoomList] = useState();

  const roomStation = useRef();
  const room = useRef();
  const bedStation = useRef();
  const bed = useRef();

  // const reloadHome = () => {
  //   const url = `${window.baseURL}main/frontdesk/doctor/waitinglist/${dept}`;
  //   axios.get(url).then((res) => {
  //     setWaitinglist(res.data.e);
  //     setTakeover(res.data.takeover);
  //     setTaken(res.data.d);
  //   });
  // };

  const attendPatientHandler = (encounter_pid, patient_pid, list) => {
    const check = attendpatient.filter((a) => {
      return a == encounter_pid;
    });
    if (!check[0]) {
      setAttendPatient([...attendpatient, encounter_pid]);
    }
    setTempPatientPID(patient_pid);
    setTaken([list]);
    setTimeout(() => {
      const element = document.getElementById(`${encounter_pid}-tab`);
      element.click();
    }, 200);
  };

  const attendPatientDeleteHandler = (pid) => {
    const check = attendpatient.filter((a) => {
      return a != pid;
    });
    setAttendPatient(check);
  };

  useEffect(() => {
    doInitialTasks();
  }, [reload]);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/admissionmgt/${nursingId}`);
    if(result.status) {
      setRoomList(result.data.room);
      setLoad(false);
      console.log(result.data.room);
    }
  }

  const deleteBedHandler = async (id) => {
    if (window.confirm("Do you want delete this bed?")) {
      const result = await deleteFromServer(`usermgt/bed/${id}`);
      if(result.status) setReload(!reload);
    }
  };

  const deleteRoomHandler = async (id) => {
    if (window.confirm("Deleting this room will delete all Bed?")) {
      const result = await deleteFromServer(`usermgt/room/${id}`);
      if(result.status) setReload(!reload);
    }
  };

  const addRoomHandler = () => {
    setAddRoom(!addRoom);
    setAddBed(false);
  };

  const nursingReviewBtnHandler = (bid) => {
    console.log(bid);
    document.getElementById(`nursingcare${bid}`).classList.add("d-none");
    document.getElementById(`fluid${bid}`).classList.remove("d-none");
    document.getElementById(`drug${bid}`).classList.remove("d-none");
    document.getElementById(`document${bid}`).classList.remove("d-none");
  };

  const addBedHandler = () => {
    setAddBed(!addBed);
    setAddRoom(false);
  };

  const roomSubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      nsId: nursingId,
      room: room.current.value,
      nid: nursingId,
    };

    const result = await postToServer(`usermgt/room`, { data });
    if(result.status) {
      console.log(result.data);
      setAddRoom(false);
      setSuccess(true);
      setRoomList(result.data.room);
    }
  };

  const bedSubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      rId: bedStation.current.value,
      bed: bed.current.value,
      nid: nursingId,
    };

    const result = await postToServer(`usermgt/bed`, { data });
    if(result.status) {
      console.log(result.data);
      setAddBed(false);
      setSuccess(true);
      setRoomList(result.data.room);
    }
  };

  const modelDataHandler = (list) => {
    setRoomList(list.room);
  };

  const dischargePatient = async (b) => {
    if (window.confirm("Do you want to discharge this patient?")) {
      const data = {
        eId: b.encounter.id,
        bId: b.id,
        nId: nursingId,
      };

      const result = await postToServer(`usermgt/discharge-patient-from-bed`, { data });
      if(result.status) setRoomList(result.data.room);
    }
  };

  const transferPatient = async (b) => {
    if (window.confirm("Do you want to transfer this patient?")) {
      const data = {
        eId: b.encounter.id,
        bId: b.id,
        nId: nursingId,
      };

      const result = await postToServer(`usermgt/transfer-patient`, { data });
      if(result.status) setRoomList(result.data.room);
    }
  };

  const drugHandler = (pid) => {
    navigate("/drug-chart", { state: { pid: pid } });
  };

  const fluidHandler = (pid) => {
    navigate("/fluid-chart", { state: { pid: pid } });
  };

  const nursingCareHandler = (pid) => {
    navigate("/nursing-care", { state: { pid: pid } });
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          {success && (
            <Success close={successClose}>
              Data has been added Successfully
            </Success>
          )}
          <div>
            {/* <button className="btn btn-primary ml-3" onClick={() => props.back()}>
          Go Back
        </button> */}
            <button className="btn btn-primary ml-3" onClick={addRoomHandler}>
              Add Room
            </button>
            <button className="btn btn-primary ml-3" onClick={addBedHandler}>
              Add Bed
            </button>
            {load && <Loading />}
            <div className="row">
              <div className="col-md-6">
                {addRoom && (
                  <form>
                    <div className="mb-3 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Room name"
                        required
                        ref={room}
                      />
                    </div>
                    <div className="mb-3 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Room name"
                        value={nursingName}
                        disabled
                        ref={roomStation}
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={roomSubmitHandler}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                )}

                {addBed && (
                  <form>
                    <div className="mb-3 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Bed name"
                        required
                        ref={bed}
                      />
                    </div>
                    <div className="mb-3 mt-3">
                      <select className="form-select" ref={bedStation}>
                        {roomList &&
                          roomList.map((r) => {
                            return (
                              <>
                                <option key={r.id} value={r.id}>
                                  {r.room}
                                </option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={bedSubmitHandler}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                )}
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
          {/* table */}

          <div className="card mt-4">
            <div className="card-header">
              <ul
                className="nav nav-tabs card-header-tabs pull-right"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    // onClick={reloadHome}
                  >
                    Home
                  </a>
                </li>
                {attendpatient &&
                  attendpatient.map((a) => {
                    return (
                      <li key={a} className="nav-item">
                        <a
                          className="nav-link"
                          id={a + "-tab"}
                          data-toggle="tab"
                          href={"#a" + a + "a"}
                          role="tab"
                          aria-controls={"" + a}
                          aria-selected="false"
                        >
                          {a}{" "}
                          <span
                            style={{
                              position: "relative",
                              top: "-10px",
                              color: "black",
                            }}
                            onClick={() => attendPatientDeleteHandler(a)}
                          >
                            x
                          </span>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="card-body">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <table className="table responsive">
                    <thead>
                      <tr>
                        <th scope="col">Room</th>
                        <th scope="col">Bed</th>
                        <th scope="col">Surname</th>
                        <th scope="col">Firstname</th>
                        <th scope="col">Encounter No.</th>
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                        {/* <th scope="col"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {roomList &&
                        roomList.map((r, i) => {
                          return (
                            <>
                              {r.bedset.map((b, j) => {
                                return (
                                  <tr key={b.id}>
                                    <td>{r.room}</td>
                                    <td>{b.bed}</td>
                                    {b.isPatient == false ? (
                                      <>
                                        <td colSpan="3">
                                          <FcPlus
                                            style={{ fontSize: "20px" }}
                                            onClick={() => handleShow(b.id)}
                                          />
                                        </td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() =>
                                              deleteBedHandler(b.id)
                                            }
                                          >
                                            Delete Bed
                                          </button>
                                          <button
                                            className="btn btn-danger btn-sm ml-1"
                                            onClick={() =>
                                              deleteRoomHandler(r.id)
                                            }
                                          >
                                            Delete Room
                                          </button>
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td>{b.encounter.patient.surname}</td>
                                        <td>{b.encounter.patient.firstname}</td>
                                        <td>{b.encounter.pid}</td>
                                        <td
                                          scope="col"
                                          style={{ fontSize: "20px" }}
                                          className="text-center"
                                        >
                                          <AiFillFolderOpen
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Attend to Patient"
                                            style={{ color: "green" }}
                                            className="mr-1"
                                            onClick={() =>
                                              attendPatientHandler(
                                                b.encounter.pid,
                                                b.encounter.patient.pid,
                                                b.encounter
                                              )
                                            }
                                          />
                                          <BiTransfer
                                            onClick={() => transferPatient(b)}
                                            className="mr-2"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Transfer Patient"
                                          />
                                          <FaBed className="mr-2" />
                                          <ImBoxRemove
                                            onClick={() => dischargePatient(b)}
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Discharge Patient"
                                          />

                                          <button
                                            className="btn btn-primary ml-4 btn-sm"
                                            id={"nursingcare" + b.id}
                                            onClick={() =>
                                              nursingReviewBtnHandler(b.id)
                                            }
                                          >
                                            Nursing Review
                                          </button>

                                          <button
                                            className="btn btn-primary btn-sm ml-2 d-none"
                                            id={"fluid" + b.id}
                                            onClick={() =>
                                              fluidHandler(b.encounter.pid)
                                            }
                                          >
                                            Fluid Chart
                                          </button>
                                          <button
                                            className="btn btn-primary btn-sm ml-1 d-none"
                                            id={"drug" + b.id}
                                            onClick={() =>
                                              drugHandler(b.encounter.pid)
                                            }
                                          >
                                            Drug Chart
                                          </button>
                                          {/* <button
                                    className="btn btn-primary btn-sm ml-1 d-none"
                                    id={"document" + b.id}
                                    onClick={() =>
                                      handleShowHistory(b.encounter.pid)
                                    }
                                  >
                                    Documentation
                                  </button> */}
                                          <button
                                            className="btn btn-primary btn-sm ml-1 d-none"
                                            id={"document" + b.id}
                                            onClick={() =>
                                              nursingCareHandler(
                                                b.encounter.pid
                                              )
                                            }
                                          >
                                            Nursing Care
                                          </button>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              })}
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {attendpatient &&
                  attendpatient.map((a) => {
                    return (
                      <div
                        key={a}
                        className="tab-pane fade"
                        id={"a" + a + "a"}
                        role="tabpanel"
                      >
                        <AttendtoPatient
                          pid={a}
                          patient_pid={tempPatientPID}
                          list={taken}
                          // reloads={refreshHandler}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* <table className="table table-bordered table-md mt-3">
            <thead>
              <tr>
                <th scope="col">Room</th>
                <th scope="col">Bed</th>
                <th scope="col">Surname</th>
                <th scope="col">Firstname</th>
                <th scope="col">Encounter No.</th>
                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {roomList &&
                roomList.map((r, i) => {
                  return (
                    <>
                      {r.bedset.map((b, j) => {
                        return (
                          <tr key={b.id}>
                            <td>{r.room}</td>
                            <td>{b.bed}</td>
                            {b.isPatient == false ? (
                              <>
                                <td colSpan="3">
                                  <FcPlus
                                    style={{ fontSize: "20px" }}
                                    onClick={() => handleShow(b.id)}
                                  />
                                </td>
                              </>
                            ) : (
                              <>
                                <td>{b.encounter.patient.surname}</td>
                                <td>{b.encounter.patient.firstname}</td>
                                <td>{b.encounter.pid}</td>
                                <td
                                  scope="col"
                                  style={{ fontSize: "20px" }}
                                  className="text-center"
                                >
                                  <AiFillFolderOpen
                                    style={{ color: "green" }}
                                    className="mr-1"
                                  />
                                  <BiTransfer
                                    onClick={() => transferPatient(b)}
                                    className="mr-2"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Transfer Patient"
                                  />
                                  <FaBed className="mr-2" />
                                  <ImBoxRemove
                                    onClick={() => dischargePatient(b)}
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Discharge Patient"
                                  />

                                  <button
                                    className="btn btn-primary ml-4 btn-sm"
                                    id={"nursingcare" + b.id}
                                    onClick={() =>
                                      nursingReviewBtnHandler(b.id)
                                    }
                                  >
                                    Nursing Review
                                  </button>

                                  <button
                                    className="btn btn-primary btn-sm ml-2 d-none"
                                    id={"fluid" + b.id}
                                    onClick={() =>
                                      fluidHandler(b.encounter.pid)
                                    }
                                  >
                                    Fluid Chart
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm ml-1 d-none"
                                    id={"drug" + b.id}
                                    onClick={() => drugHandler(b.encounter.pid)}
                                  >
                                    Drug Chart
                                  </button>
                                  <button
                                    className="btn btn-primary btn-sm ml-1 d-none"
                                    id={"document" + b.id}
                                    onClick={() =>
                                      nursingCareHandler(b.encounter.pid)
                                    }
                                  >
                                    Nursing Care
                                  </button>
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
            </tbody>
          </table> */}
          {show && (
            <AddPatienttoBed
              show={show}
              close={handleClose}
              success={() => setSuccess(true)}
              data={modelDataHandler}
              nursingId={nursingId}
              bedId={tempId}
              station={nursingName}
            />
          )}
          {showHistory && (
            <NursingHistory
              show={showHistory}
              close={handleCloseHistory}
              encounter_pid={tempEncounterPid}
            />
          )}
        </div>
      </MainContent>
    </>
  );
};

export default AdmssionMgt;
