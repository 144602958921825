import React, { useState, useEffect, useRef } from "react";
import MainContent from "../../ui/MainContent";
import Loading from "../../ui/Loading";
// import axios from "axios";
// import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getFromServer } from "../../global/requests";

const Vital = () => {
  const navigate = useNavigate();
  const [encounters, setEncounters] = useState();
  const [searchList, setSearchList] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const search = useRef();
  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`nursing/vital`);
    if(result.status) {
      setEncounters(result.data);
      setIsLoading(false);
    }
  }

  const backHandler = () => {
    setIsSearch(false);
    document.getElementById("search").value = "";
  };
  const detailHandler = (list) => {
    navigate("/vital-details", { state: { list: list } });
  };

  const searchHandler = async () => {
    const q = search.current.value;

    const result = await getFromServer(`nursing/vital-search/${q}`)
    if(result.status) {
      setSearchList(result.data);
      setIsSearch(true);
      document.getElementById("search").value = "";
    } else setSearchList([]);
  };

  const clearVitalHandler = async () => {
    const q = search.current.value;
    setIsLoading(true);
    const result = await getFromServer('nursing/clear-vital');
    if(result.status) {
      setEncounters(result.data);
      setIsLoading(false);
      setIsSearch(false);
      document.getElementById("search").value = "";
    }
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <div className="col-md-12">
              <div className="card author-box card-primary">
                <div className="card-body ">
                  <h2 className="py-3">Pending Vitals</h2>
                  <div className="row">
                    <div className="col-md-6 mb-4 d-flex">
                      <input
                        type="text"
                        className="form-control"
                        id="search"
                        placeholder="Search to enter vitals"
                        ref={search}
                      />
                      <button
                        type="button"
                        className="btn btn-primary ml-3"
                        onClick={searchHandler}
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-md-6 text-end">
                    <button
                        type="button"
                        className="btn btn-primary ml-3"
                        onClick={clearVitalHandler}
                      >
                        Clear Pending Vitals
                      </button>
                    </div>
                  </div>
                  {isLoading && <Loading />}
                  {!isSearch && (
                    <div className="tablexflow">
                      <table className="table responsive">
                        <thead>
                          <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Sex</th>
                            <th scope="col">Surname</th>
                            <th scope="col">Firstname</th>
                            <th scope="col">Hospital nr</th>
                            <th scope="col">DOB</th>
                            <th scope="col">Encounter Date</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {encounters &&
                            encounters.map((a, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{a.patient.sex}</td>
                                  <td>{a.patient.surname}</td>
                                  <td>{a.patient.firstname} </td>
                                  <td>{a.patient.hospitalid}</td>
                                  <td>{a.patient.dob}</td>
                                  <td>{a.date}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-sm btn-primary"
                                      style={{ color: "white" }}
                                      onClick={() => detailHandler(a)}
                                    >
                                      Detail
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  
                  {isSearch && (
                    <div>
                      <div>
                        <button
                          className="btn btn-primary mb-3"
                          onClick={backHandler}
                        >
                          Back to Pending Vitals
                        </button>
                      </div>
                      <div className="tablexflow">
                        <table className="table responsive">
                          <thead>
                            <tr style={{ fontSize: "13px" }}>
                              <th scope="col">#</th>
                              <th scope="col">Sex</th>
                              <th scope="col">Surname</th>
                              <th scope="col">Firstname</th>
                              <th scope="col">Hospital nr</th>
                              <th scope="col">DOB</th>
                              <th scope="col">Encounter Date</th>
                              <th scope="col" className="text-center">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {searchList &&
                              searchList.flat().map((a, index) => {
                                return (
                                  <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{a.patient.sex}</td>
                                    <td>{a.patient.surname}</td>
                                    <td>{a.patient.firstname} </td>
                                    <td>{a.patient.hospitalid}</td>
                                    <td>{a.patient.dob}</td>
                                    <td>{a.date}</td>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-sm btn-primary"
                                        style={{ color: "white" }}
                                        onClick={() => detailHandler(a)}
                                      >
                                        Detail
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default Vital;
