import React, { useState, useRef, useEffect, useMemo } from "react";
import JoditEditor from "jodit-react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { postToServer } from "../../global/requests";


const LaboratoryTestReport = () => {
  const navigate = useNavigate();
  const editor = useRef(null);

  const param = useLocation();
  const [value, setValue] = useState(param.state);
  const [p, setData] = useState();

  const statusHandler = (e, i) => {
    const newVal = { ...p };
    newVal.investigationlab[i].status = e.target.value;
    setData(newVal);
  };

  const groupStatusHandler = (e, i) => {
    const newVal = { ...p };
    newVal.investigationlab[i].status = e.target.value;
    setData(newVal);
  };

  const valueContentHandler = (c, i) => {
    const newVal = { ...p };
    newVal.investigationlab[i].value = c;
    setData(newVal);
  };

  const groupValueContentHandler = (c, i, j) => {
    const newVal = { ...p };
    newVal.investigationlab[i].investigationlabtest[j].value = c;
    setData(newVal);
  };

  useEffect(() => {
    setData(param.state);
    console.log(param.state);
  }, []);

  const submitHandler = async () => {
    const data = {
      list: p,
    };
    console.log(data)

    const result = await postToServer(`main/save/laboratory/investigation`, { data });
    if(result.status) {
      console.log(result.data);
      navigate("/view-report", { state: result.data });
    }
  };

  return (
    <>
      <MainContent>
        {p && (
          <div className="section-body mb-5">
           <PatientDetailCard list={p.encounter} isEncounter={true} />
            <div>
              <table className="table table-md table-bordered mt-4">
                <thead>
                  <tr>
                    <th colSpan="2">Test Name</th>
                    <th>Value</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {p.investigationlab.map((t, i) => {
                    return (
                      <>
                        {t.test.is_single_test ? (
                          <tr>
                            <td colSpan="2">{t.test.testname}</td>
                            <td>
                              {(() => {
                                if (t.test.value == "Figure") {
                                  return (
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        valueContentHandler(e.target.value, i)
                                      }
                                    />
                                  );
                                } else {
                                  return (
                                    <JoditEditor
                                      ref={editor}
                                      value={t.test.valuecontent}
                                      // config={config}
                                      tabIndex={1} // tabIndex of textarea
                                      onBlur={(newContent) =>
                                        valueContentHandler(newContent, i)
                                      } // preferred to use only this option to update the content for performance reasons
                                      onChange={(newContent) => {}}
                                    />
                                  );
                                }
                              })()}
                            </td>
                            <td>
                              <div className="">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="Partial"
                                  checked={t.status === 'Partial'} 
                                  name={"flexRadioDefault" + t.id}
                                  id={"flexRadioDefault1" + t.id}
                                  onClick={(e) => statusHandler(e, i)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"flexRadioDefault1" + t.id}
                                >
                                  Partial
                                </label>
                              </div>
                              <div className="">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="Done" 
                                  checked={t.status === 'Done'} 
                                  name={"flexRadioDefault" + t.id}
                                  id={"flexRadioDefault2" + t.id}
                                  onClick={(e) => statusHandler(e, i)}
                                  // checked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"flexRadioDefault2" + t.id}
                                >
                                  Done
                                </label>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            <tr>
                              <td colSpan="3">{t.test.testname}</td>
                              <td>
                                <div className="">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="Partial"
                                    checked={t.status === 'Partial'} 
                                    name={"flexRadioDefault" + t.id}
                                    id={"flexRadioDefault1" + t.id}
                                    onClick={(e) => groupStatusHandler(e, i)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"flexRadioDefault1" + t.id}
                                  >
                                    Partial
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="Done"
                                    checked={t.status === 'Done'} 
                                    name={"flexRadioDefault" + t.id}
                                    id={"flexRadioDefault2" + t.id}
                                    onClick={(e) => groupStatusHandler(e, i)}
                                    // checked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"flexRadioDefault2" + t.id}
                                  >
                                    Done
                                  </label>
                                </div>
                              </td>
                            </tr>
                            {t.investigationlabtest.map((ut, j) => {
                              return (
                                <>
                                  <tr>
                                    <td></td>
                                    <td>{ut.test.testname}</td>
                                    <td>
                                      {(() => {
                                        if (ut.test.value == "Figure") {
                                          return (
                                            <input
                                              type="text"
                                              className="form-control"
                                              onChange={(e) =>
                                                groupValueContentHandler(
                                                  e.target.value,
                                                  i,j
                                                )
                                              }
                                            />
                                          );
                                        } else {
                                          return (
                                            <JoditEditor
                                              ref={editor}
                                              value={ut.test.valuecontent}
                                              // config={config}
                                              tabIndex={1} // tabIndex of textarea
                                              onBlur={(newContent) =>
                                                groupValueContentHandler(
                                                  newContent,
                                                  i,
                                                  j
                                                )
                                              } // preferred to use only this option to update the content for performance reasons
                                              onChange={(newContent) => {}}
                                            />
                                          );
                                        }
                                      })()}
                                    </td>
                                    <td></td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="text-center">
              <button className="btn btn-primary" onClick={submitHandler}>
                Save
              </button>
            </div>
          </div>
        )}
      </MainContent>
    </>
  );
};

export default LaboratoryTestReport;
