import React, { useState, useEffect, useRef } from "react";
import AddLabTests from "../../../modals/Investigation/AddLabTests";

const AutoInvestigationLab = (props) => {
  const dataComing = props.options;
  const emptyTagList = props.removeTag;
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState();
  const [selectTest, setSelectTest] = useState();
  const [filtered, setFiltered] = useState();
  const [tags, setTags] = useState([]);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateHandler = (list) => {
    setTags([...tags, ...list]);
    props.tag([...tags, ...list]);
  };

  useEffect(() => {
    setSelectTest(dataComing)
    const test = []
    for(var i in dataComing){
      for(var j in dataComing[i].labtestcategory){
        test.push(dataComing[i].labtestcategory[j])
      }
    }
    // console.log('test',dataComing)

    setOptions(test);
    setFiltered(test);
    setTags([]);
  }, [emptyTagList]);

  const searchHandler = (e) => {
    setSearch(e);
    if (e.length > 1) {
      const check = options.filter((s) => {
        return String(s.testname).toLowerCase().includes(e.toLowerCase());
      });
      setFiltered(check);
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };

  const keyPressTag = (e) => {
    if (e.keyCode == 13) {
      const val = e.target.value;
      const value = { id: val, text: val };
      setTags([...tags, value]);
      setSearch("");
      setDisplay(false);
      props.tag([...tags, value]);
    }
  };

  const changeHandler = (value) => {
    setTags([...tags, value]);
    setSearch("");
    setDisplay(false);
    props.tag([...tags, value]);
  };

  const removeTags = (id) => {
    const newState = tags.filter((t) => {
      return t.id != id;
    });
    setTags(newState);
    props.tag(newState);
  };

  return (
    <>
      <div className="App1">
        <div className="auto-container1">
          <div className="flex-container1 flex-column1 pos-rel1 diangnosis">
            <input
              id="auto"
              onChange={(event) => searchHandler(event.target.value)}
              placeholder="Select Tests"
              className="form-control"
              onKeyUp={keyPressTag}
              value={search}
            ></input>
            <div className="mt-2 d-grid gap-2 d-md-block">
              {tags &&
                tags.map((t, i) => {
                  return (
                    <button
                      key={i}
                      type="button"
                      className="btn btn-sm btn-primary ml-2 mb-2"
                    >
                      {t.testname}{" "}
                      <span
                        className="closebox"
                        onClick={() => removeTags(t.id)}
                      >
                        X
                      </span>
                    </button>
                  );
                })}
            </div>
            {display && (
              <div className="autoContainer1">
                {filtered.map((v, i) => {
                  return (
                    <>
                      <div
                        onClick={() => changeHandler(v)}
                        className="option1"
                        key={i}
                      >
                        <span>
                          {"=> "}
                          {v.testname}
                        </span>
                        <span class={v.auth_status == 'Authorized' ? "badge badge-success float-end" : "badge badge-danger float-end"}>{v.auth_status}</span>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <button className="btn btn-primary mt-4 mb-3" onClick={handleShow}>
          Add
        </button>
      </div>
      {show && (
        <AddLabTests
          show={show}
          options={selectTest}
          close={handleClose}
          update={updateHandler}
        />
      )}
    </>
  );
};

export default AutoInvestigationLab;
