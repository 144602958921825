import React, { useState, useEffect } from "react";
import { getFromServer } from "../../global/requests";
// import axios from "axios";

const PatientHistoryDetail = (props) => {
  const [list, setList] = useState();
  const [pre, setPre] = useState();
  const [com, setCom] = useState();
  const [pro, setPro] = useState();
  const [med, setMed] = useState();
  const [rev, setRev] = useState();
  const [dia, setDia] = useState();
  const [man, setMan] = useState();
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/doctor/patient-history/${props.patient_pid}/${props.encounter_pid}`);
    if(result.status) {
      setList(result.data);
      if (Object.keys(result.data.pre).length > 0) {
        setPre(result.data.pre);
      }
      if (Object.keys(result.data.pro).length > 0) {
        setPro(result.data.pro);
      }
      if (Object.keys(result.data.com).length > 0) {
        setCom(result.data.com);
      }
      if (Object.keys(result.data.med).length > 0) {
        setMed(result.data.med);
      }
      if (Object.keys(result.data.rev).length > 0) {
        setRev(result.data.rev);
      }
      if (Object.keys(result.data.dia).length > 0) {
        setDia(result.data.dia);
      }
      if (Object.keys(result.data.man).length > 0) {
        setMan(result.data.man);
      }
      setIsLoad(false);
      console.log(result.data);
    }
  }

  return (
    <>
      <div>
        <button className="btn btn-success btn-sm" onClick={props.back}>
          Go Back
        </button>
      </div>
      {/* prescription */}
      <div className="card mt-4">
        <div className="card-body">
          <h4 className="text-center">Prescription</h4>
          <div>
            {!pre && (
              <div className="text-center">
                <h4 style={{ color: "red" }}>No Data Found</h4>
              </div>
            )}
            {pre && (
              <table className="table table-bordered table-stripped table-md">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th scope="col">Prescription</th>
                    <th scope="col">Dosage</th>
                    <th scope="col">Route</th>
                    <th scope="col">Times</th>
                    <th scope="col">Period</th>
                    <th scope="col">2</th>
                    <th scope="col">Total</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Prescribed by</th>
                  </tr>
                </thead>
                <tbody>
                  {pre.prescriptionDetails_set.map((d, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{pre.date}</td>
                        <td>
                          {d.prescription_details_tag_set.map((t) => {
                            return <>{t.drugtags.genericname} -</>;
                          })}
                        </td>
                        <td>{d.dosage}</td>
                        <td>{d.route}</td>
                        <td>{d.frequency}</td>
                        <td>{d.period}</td>
                        <td>{d.none}</td>
                        <td>{d.total}</td>
                        <td>{d.comment}</td>
                        <td>{d.prescribedby}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        {/* procedure */}
        <div className="col-md-6">
          <div className="card mt-4">
            <div className="card-body">
              <h4 className="text-center">Procedure</h4>
              <div>
                {!pro && (
                  <div className="text-center">
                    <h4 style={{ color: "red" }}>No Data Found</h4>
                  </div>
                )}
                {pro && (
                  <table className="table table-bordered table-stripped table-md">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th scope="col">Procedure</th>
                        <th scope="col">Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pro.ProcedureDetails.map((d, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{pro.date}</td>
                            <td>
                              {d.ProcedureDetailsTag.map((t) => {
                                return <>{t.procedure} -</>;
                              })}
                            </td>
                            <td>{d.note}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          {/* Medical History */}
          <div className="card mt-4">
            <div className="card-body">
              <h4 className="text-center">Medical History</h4>
              <div>
                {!med && (
                  <div className="text-center">
                    <h4 style={{ color: "red" }}>No Data Found</h4>
                  </div>
                )}
                {med && (
                  <table className="table table-bordered table-stripped table-md">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Question</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {med.medicalHistory.map((d, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{d.title}</td>
                            <td>{d.question}</td>
                            <td>{d.status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* complain */}
        <div className="col-md-6">
          <div className="card mt-4">
            <div className="card-body">
              <h4 className="text-center">Complain</h4>
              <div>
                {!com && (
                  <div className="text-center">
                    <h4 style={{ color: "red" }}>No Data Found</h4>
                  </div>
                )}
                {com && (
                  <table className="table table-bordered table-stripped table-md">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th scope="col">Complain</th>
                        <th scope="col">Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {com.ComplainDetails.map((d, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{com.date}</td>
                            <td>
                              {d.ComplainDetailsTag.map((t) => {
                                return <>{t.complain} -</>;
                              })}
                            </td>
                            <td>{d.note}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          {/* Review System */}
          <div className="card mt-4">
            <div className="card-body">
              <h4 className="text-center">Review System</h4>
              <div>
                {!rev && (
                  <div className="text-center">
                    <h4 style={{ color: "red" }}>No Data Found</h4>
                  </div>
                )}
                {rev && (
                  <table className="table table-bordered table-stripped table-md">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Question</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rev.reviewSystem.map((d, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{d.title}</td>
                            <td>{d.question}</td>
                            <td>{d.status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Diagnosis */}
      <div className="card mt-4">
        <div className="card-body">
          <h4 className="text-center">Diagnosis</h4>
          <div>
            {!dia && (
              <div className="text-center">
                <h4 style={{ color: "red" }}>No Data Found</h4>
              </div>
            )}
            {dia && (
              <table className="table table-bordered table-stripped table-md">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th scope="col">Disease</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Diagnosed by</th>
                  </tr>
                </thead>
                <tbody>
                  {dia.diagnosisDetail.map((d, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{dia.date}</td>
                        <td>{d.title}</td>
                        <td>{d.comment}</td>
                        <td>{d.addedby}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      {/* Manual Writeup */}
      <div className="card mt-4">
        <div className="card-body">
          <h4 className="text-center">Manual Writeup</h4>
          <div>
            {!man && (
              <div className="text-center">
                <h4 style={{ color: "red" }}>No Data Found</h4>
              </div>
            )}
            {man && (
              <table className="table table-bordered table-stripped table-md">
                <thead>
                  <tr>
                    <th>#</th>
                    <th scope="col">Date</th>
                    <th scope="col">Text</th>
                  </tr>
                </thead>
                <tbody>
                  {man.manualwriteup.map((d, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{man.date}</td>
                        <td>
                          <div dangerouslySetInnerHTML={{ __html: d.text }} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientHistoryDetail;
