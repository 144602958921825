import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, postToServer } from "../../global/requests";

const AddOrder = (props) => {
  const [supplierlist, setSupplierList] = useState();
  const [drugList, setDrugList] = useState([]);
  const [consumableList, setconsumableList] = useState([]);

  const [typelist, setTypelist] = useState();
  const quantity = useRef();
  const receivedQty = useRef();
  const type = useRef();
  const item = useRef();
  const note = useRef();
  const cost = useRef();
  const supplier = useRef();

  useEffect(() => {
    loadSuppliers();
    loadStoremgts();
    loadDrugs();
    loadConsumables();
  }, []);

  const loadSuppliers = async () => {
    const result = await getFromServer(`pharmacy/supplier`);
    if(result.status) setSupplierList(result.data);
  }
  const loadStoremgts = async () => {
    const result = await getFromServer(`pharmacy/storemgt`);
    if(result.status) setTypelist(result.data);
  }
  const loadDrugs = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/add/drug`);
    if(result.status) setDrugList(result.data);
  }
  const loadConsumables = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/consumable`);
    if(result.status) setconsumableList(result.data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      quantity: quantity.current.value,
      received_qty: receivedQty.current.value,
      item: item.current.value,
      note: note.current.value,
      cost: cost.current.value,
      pid: supplier.current.value,
      type: "Store",
      // type: type.current.value,
      date: "date",
    };

    const result = await postToServer(`pharmacy/purchase-order`, { data });
    if(result.status) {
      props.close();
      props.success(true);
      props.orders(result.data);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Place Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Supplier</label>
                    <select className="form-select" ref={supplier}>
                      {supplierlist &&
                        supplierlist.map((u) => {
                          return (
                            <option key={u.id} value={u.pid}>
                              {u.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="mb-3">
                    <label>Store Type</label>
                    <select className="form-select" ref={type}>
                      {typelist &&
                        typelist.map((u) => {
                          return (
                            <option key={u.id} value={u.name}>
                              {u.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Item</label>
                    <select className="form-select" ref={item}>
                      {drugList &&
                        drugList.map((u) => {
                          return (
                            <option
                              key={u.id}
                              value={u.id + "_" + u.brandname + "_drug"}
                            >
                              {u.brandname}
                            </option>
                          );
                        })}
                      {consumableList &&
                        consumableList.map((u) => {
                          return (
                            <option
                              key={u.id}
                              value={u.id + "_" + u.name + "_consumable"}
                            >
                              {u.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Quantity</label>
                    <input
                      type="number"
                      className="form-control"
                      required
                      placeholder="Quantity"
                      ref={quantity}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Received Quantity</label>
                    <input
                      type="number"
                      className="form-control"
                      required
                      placeholder="Received Quantity"
                      ref={receivedQty}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Cost</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="Unit Cost"
                      ref={cost}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="col-form-label">Note</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Note"
                      required
                      ref={note}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Place
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddOrder;
