import React, { useState, useEffect } from "react";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
import { getFromServer } from "../../global/requests";

const Vendors = () => {
  const [supplier, setSupplier] = useState();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/supplier`);
    if(result.status) setSupplier(result.data);
  }

  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row mb-3">
            <div className="col-md-4">
              <h3>Vendors</h3>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">S\N</th>
                  <th scope="col">Supplier Name</th>
                  <th scope="col">Address</th>
                  <th scope="col">Contact</th>
                </tr>
              </thead>
              <tbody>
                {supplier &&
                  supplier.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td>{a.address}</td>
                        <td>{a.contact}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default Vendors;
