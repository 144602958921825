import React from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import user from "../../assets/img/user.png";
import { logoutUser } from "../global/auth";
import { postToServer } from "../global/requests";
import { useNavigate } from "react-router-dom";
// import { useState } from "react";


// const [notiCount, setNotiCount] = useState();
// const notificationNo = document.getElementById('notification-count');
// setNotiCount = notificationNo.getElementsByTagName('a').length;


const Header = (props) => {
  const navigate = useNavigate();

  const logout = async () => {
    const result = await postToServer(`usermgt/logout`);
    // if(result.status) {}
    logoutUser();
  };
  return (
    <>
      <nav
        className="navbar navbar-expand-lg main-navbar sticky"
        style={props.isLoggedIn ? {} : { display: "none" }}
      >
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <a
                href="#"
                data-toggle="sidebar"
                className="nav-link nav-link-lg
									collapse-btn"
              >
                <GiHamburgerMenu style={{ color: "black" }} />
              </a>
            </li>
            {/* <li>
              <a href="#" className="nav-link nav-link-lg fullscreen-btn">
                <i data-feather="maximize"></i>
              </a>
            </li> */}
            {/* <li>
              <form className="form-inline mr-auto">
                <div className="search-element">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    data-width="200"
                  />
                  <button className="btn" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </li> */}
          </ul>
        </div>

        <div className="col-lg-1">
          {/* <div className="text-lg-right top-right-bar mt-2 mt-lg-0">
            <a href="#" data-toggle="dropdown" className="btn btn-warning">
              Website
            </a>
          </div> */}
        </div>
        <div className="col-lg-2">
          <div className="text-lg-right top-right-bar mt-2 mt-lg-0">
            <a href="#" className="btn btn-primary">
              Self Service
            </a>
          </div>
        </div>

        <ul className="navbar-nav navbar-right">
          {/* Bell icon  start */}
          <li className="dropdown dropdown-list-toggle">
            <a
              href="#"
              data-toggle="dropdown"
              className="nav-link nav-link-lg message-toggle"
              aria-expanded="true"
            >
             <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-bell bell"
              >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
              </svg>
              <span className="badge headerBadge1" id="notiCount"></span>{" "}
            </a>
            <div className="dropdown-menu dropdown-list dropdown-menu-right pullDown">
              <div className="dropdown-header">
                Notifications
                <div className="float-right">
                  <a href="#">Mark All As Read</a>
                </div>
              </div>
              <div className="dropdown-list-content dropdown-list-icons notification-flow-control" id="notification-list"  >
               {/* all notification are will here by dynamic code */}
               
              </div>
              <div className="dropdown-footer text-center" >
                <Link to={"/show-notifications"} > <a href="" >
                  View All  <i className="fas fa-chevron-right"></i>
                </a>
                </Link>
              </div>
            </div>
          </li>
          {/* bell icon end */}
          <li className="dropdown">
            <a
              href="#"
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
              {" "}
              <img
                alt="image"
                src={'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o='
                }
                className="user-img-radious-style"
              />{" "}
              <span className="d-sm-none d-lg-inline-block"></span>
            </a>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">
                Hello{" "}
                {window.userInfo && window.userInfo.first_name
                  ? `${window.userInfo.first_name} ${window.userInfo.last_name}`
                  : "User"}
              </div>
              {/* <a href="profile.html" className="dropdown-item has-icon">
                {" "}
                <i
                  className="far
										fa-user"
                ></i>{" "}
                Profile
              </a>{" "}
              <a href="timeline.html" className="dropdown-item has-icon">
                {" "}
                <i className="fas fa-bolt"></i>
                Activities
              </a>{" "} */}
              <a href="#" className="dropdown-item has-icon">
                {" "}
                <i className="fas fa-cog"></i>
                Settings
              </a>
              <div className="dropdown-divider"></div>
              <a
                href="javascript:void(0);"
                className="dropdown-item has-icon text-danger"
                onClick={logout}
              >
                {" "}
                <i className="fas fa-sign-out-alt"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      {/* <Link
        to="/chat-room"
        id="chat-btn"
        style={props.isLoggedIn ? {} : { display: "none" }}
      >
        <i className="fab fa-rocketchat"></i>
        <span style={{ visibility: props.newChat > 0 ? "visible" : "hidden" }}>
          {props.newChat}
        </span>
      </Link> */}



      {/* popup start */}
    
{/* <button type="button" id="popup" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button>

<div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Notifications</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" id="pop-msg">
        
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> */}



      {/* popup end */}
    </>
  );
};

export default Header;
