import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation } from "react-router-dom";
import ViewTextBoxValue from "../../modals/Laboratory/ViewTextBoxValue";
import PrintLabResults from "./PrintLabResults";
import '../../../assets/css/labform.css'
import PatientDetailCard from "../../ui/PatientDetailCard";



const LabViewTestReport = () => {
  const [show, setShow] = useState(false);

  const param = useLocation();
  const [p, setData] = useState();
  const [value, setValue] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setValue(data);
  };
  useEffect(() => {
    setData(param.state);
    console.log(param.state);
  }, []);

  // window.onbeforeprint = () => {
  //   document.getElementById('maincontent').classList.add('hide')
  //   document.getElementById('print-claim-form').classList.remove('hide')
  // };

  // window.onafterprint = () => {
  //   document.getElementById('maincontent').classList.remove('hide')
  //   document.getElementById('print-claim-form').classList.add('hide')
  // };

  const printHandler = () => {
    // document.getElementById('maincontent').classList.add('hide')
    // document.getElementById('print-claim-form').classList.remove('hide')
    // window.print();
    window.open(`${window.baseURL}main/lab-result-print/${param.state.id}`, '_blank')
  };
  return (
    <>
      <div id="print-claim-form" className="claimform-print-container hide"><PrintLabResults list={param.state} /></div>
      <MainContent>
        {p && (
          <div className="section-body mb-5" id="maincontent">
           <PatientDetailCard list={p.encounter} isEncounter={true} />
            <div>
              <table className="table table-md table-bordered mt-4">
                <thead>
                  <tr>
                    <th colSpan="2">Test Name</th>
                    <th>Value</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {p.investigationlab.map((t, i) => {
                    return (
                      <>
                        {t.test.is_single_test ? (
                          <>
                            {t.status == "Done" ? (
                              <tr>
                                <td colSpan="2">{t.test.testname}</td>
                                <td>
                                  {t.test.value == "Figure" ? (
                                    <>{t.value}</>
                                  ) : (
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => handleShow(t.value)}
                                    >
                                      View Results
                                    </button>
                                  )}
                                </td>
                                <td>{t.status}</td>
                              </tr>
                            ) : (
                              ""
                            )}{" "}
                          </>
                        ) : (
                          <>
                            {t.status == "Done" ? (
                              <>
                                <tr>
                                  <td colSpan="3">{t.test.testname}</td>
                                  <td>{t.status}</td>
                                </tr>
                                {t.investigationlabtest.map((ut, j) => {
                                  return (
                                    <>
                                      <tr>
                                        <td></td>
                                        <td>{ut.test.testname}</td>
                                        <td>
                                          {ut.test.value == "Figure" ? (
                                            <>{ut.value}</>
                                          ) : (
                                            <button
                                              className="btn btn-primary btn-sm"
                                              onClick={() =>
                                                handleShow(ut.value)
                                              }
                                            >
                                              View Results
                                            </button>
                                          )}
                                        </td>
                                        <td></td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="text-center">
              <button className="btn btn-primary" onClick={printHandler}>Print</button>
            </div>
          </div>
        )}
        {show && (
          <ViewTextBoxValue show={show} data={value} close={handleClose} />
        )}
      </MainContent>
    </>
  );
};

export default LabViewTestReport;
