import React, { useState } from "react";

// const AuthContext = React.createContext({
//   isLoggedIn: false,
//   isLogout: () => {},
//   isLogin: (email, password) => {},
// });

export const Notifications = React.createContext({
  patientToVitals: true,
  isPatientToVitals: (bool) => {},
});


export const NotificationsContextProvider = (props) => {
  const [patientToVitals, setpatientToVitals] = useState(true);

  const isPatientToVitals = (bool) => {
    setpatientToVitals(bool);
  };
  return (
    <Notifications.Provider
      value={{
        patientToVitals: patientToVitals,
        isPatientToVitals: isPatientToVitals,
      }}
    >
      {props.children}
    </Notifications.Provider>
  );
};

// export const AuthContextProvider = (props) => {
//   const [isLogged, setIsLogged] = useState(false);

//   const isLogout = (any) => {
//     console.log(any);
//     setIsLogged(!isLogged);
//   };
//   return (
//     <AuthContext.Provider
//       value={{
//         isLoggedIn: isLogged,
//         isLogout: isLogout,
//       }}
//     >
//       {props.children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthContext;
