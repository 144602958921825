import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const AddServiceType = (props) => {
  const type = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      type: type.current.value,
    };

    const result = await postToServer(`pharmacy/drugmgt/consumabletype`, { data });
    if(result.status) {
      props.close();
      props.success(true);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Consumable Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Consumable Type
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={type}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddServiceType;
