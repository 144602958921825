import React, { useState, useEffect } from "react";
// import axios from "axios";
import Loading from "../../ui/Loading";
import PatientHistoryDetail from "./PatientHistoryDetail";
import { getFromServer } from "../../global/requests";

const PatientHistory = (props) => {
  const [list, setList] = useState();
  const [temp, setTemp] = useState();
  const [isLoad, setIsLoad] = useState(true);
  const [detail, setDetail] = useState(false);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/doctor/patient-history/${props.patient_pid}`);
    if (result.status) {
      setList(result.data);
      setIsLoad(false);
      console.log(result.data);
    }
  };

  const detailHandler = (singleList) => {
    setDetail(true);
    setTemp(singleList);
  };

  const backHandler = () => {
    setDetail(false);
  };

  return (
    <>
      {isLoad && Loading}
      {!detail && (
        <div>
          <table className="table table-bordered table-stripped table-md">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>First Name</th>
                <th>Department</th>
                <th>Type</th>
                <th>Company Plans</th>
                <th className="text-center">Detail</th>
                {/* <th></th>
              <th></th> */}
              </tr>
            </thead>
            <tbody>
              {list &&
                list.enc.map((l, i) => {
                  return (
                    <tr key={i}>
                      <th>{i + 1}</th>
                      <td>{l.date}</td>
                      <td>{l.patient.firstname}</td>
                      <td>{l.type}</td>
                      <td>{l.department}</td>
                      <td>{l.profile}</td>
                      <td className="text-center">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => detailHandler(l)}
                        >
                          Detail
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {detail && (
        <PatientHistoryDetail
          back={backHandler}
          encounter_pid={temp.pid}
          patient_pid={temp.patient.pid}
        />
      )}
    </>
  );
};

export default PatientHistory;
