import React, { useEffect, useRef, useState, useMemo } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
const mic = new SpeechRecognition()
mic.continuous = true;
mic.interimResults = true;
mic.lang = 'en-US';

const Plan = (props) => {
  const [listening, setIsListening] = useState(false);

  const [value, setValue] = useState("");
  const [list, setList] = useState();


  useEffect(() => {
    handleListen()
  }, [listening]);
  
  const handleListen = () =>{
    let existingText = '';
    let element = '';

    if(listening){
      mic.start()
      mic.onend = () =>{
        mic.start()
      }
      mic.onstart = () => {
        const id = document.getElementById('plan')
        element = id.getElementsByClassName("ql-editor")[0]
        existingText = element.textContent;
      }

      mic.onresult = event =>{
        const transcript = Array.from(event.results).map(result=>result[0]).map(result=>result.transcript).join('')
        element.textContent = existingText + ' ' + transcript
        
      } 
    }else{
      mic.stop()
      mic.onend = () =>{
        mic.stop()
        setIsListening(false)
      }
    }

      
  }
  useEffect(() => {
    const url = `${window.baseURL}main/doctor/plan/${props.patient_pid}`;
    axios.get(url).then((res) => {
      console.log(res.data);
      setList(res.data);
    });
  }, []);

  const saveHandler = () => {
    console.log(value);
    const data = {
      encounter: props.encounter_pid,
      patient: props.patient_pid,
      text: value,
    };
    const url = `${window.baseURL}main/doctor/plan`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setList(res.data);
        setValue("");
      });
  };

  const deleteHandler = (detail_id, plan_id) =>{
    if(window.confirm("Are you sure you want to delete this?")){
      const merge_id = props.patient_pid + '_' + detail_id + '_' + plan_id
      const url = `${window.baseURL}main/doctor/plan/${merge_id}`;
      axios
        .delete(
          url,
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
          }
        )
        .then((res) => {
          setList(res.data);
        });
    }
  }
  

  return (
    <>
      <div className="text-center mb-3">
        <p>
          Microphone:{" "}
          <span className={`badge badge-${listening ? "danger" : "primary"}`}>
            {listening ? "on" : "off"}
          </span>
        </p>
        <button
          className="btn btn-success"
          onClick={()=>setIsListening(!listening)}
        >
          Start/Stop
        </button>
        {/* <button onClick={SpeechRecognition.stopListening}>Stop</button> */}
        {/* <button onClick={resetTranscript}>Reset</button> */}
        {/* <p>{transcript}</p> */}
      </div>
      <div id="plan">

      <ReactQuill theme="snow" value={value} onChange={setValue} />
      </div>

      <div className="text-center mt-3">
        <button type="button" className="btn btn-primary" onClick={saveHandler}>
          Save
        </button>
      </div>
      <div>
        {list && (
          <table className="table table-bordered table-md mt-4">
            {list.map((l) => {
              return (
                <>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        Date{"("}
                        {l.encounter.pid}
                        {")"}
                      </th>
                      <th>Text</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {l.plan.map((t, j) => {
                      return (
                        <tr>
                          <td>{j + 1}</td>
                          <td>{l.date}</td>
                          <td>
                            <div dangerouslySetInnerHTML={{ __html: t.text }} />
                          </td>
                          <td>
                              <button class="btn btn-danger btn-sm ml-2" onClick={()=>deleteHandler(t.id,l.id)}>Delete</button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              );
            })}
          </table>
        )}
      </div>
    </>
  );
};

export default Plan;
