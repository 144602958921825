import React, { useState, useEffect, useRef } from "react";
import Success from "../../ui/success";
import Loading from "../../ui/Loading";
import MainContent from "../../ui/MainContent";
import AddRequisition from "../../modals/Nursing/AddRequest";
import PharmacyAddRequisition from "../../modals/Pharmacy/AddRequest";
import AddRequisitionDispensary from "../../modals/Pharmacy/AddRequestDispensary";
import AddRequestDiagnosis from "../../modals/Laboratory/AddRequestDiagnosis";

// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer } from "../../global/requests";

const DiagnosisRequisition = () => {
  const [service, setNursing] = useState();
  const [pharmacy, setpharmacy] = useState();
  const [dispensary, setDispensary] = useState();
  const [diagnosis, setDiagnosis] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);

  const [show, setShow] = useState(false);
  const [showPharmacy, setShowPharmacy] = useState(false);
  const [showDispensary, setShowDispensary] = useState(false);
  const [showDiagnosis, setShowDiagnosis] = useState(false);

  const [success, setSuccess] = useState(false);
  const [type, setType] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (para) => {
    setShow(true);
    setType(para);
  };
  const fromdate = useRef(null);
  const todate = useRef(null);

  const handleClosePharmacy = () => setShowPharmacy(false);
  const handleShowPharmacy = (para) => {
    setShowPharmacy(true);
    setType(para);
  };
  const handleCloseDispensary = () => setShowDispensary(false);
  const handleShowDispensary = (para) => {
    setShowDispensary(true);
    setType(para);
  };

  const handleCloseDiagnosis = () => setShowDiagnosis(false);
  const handleShowDiagnosis = (para) => {
    setShowDiagnosis(true);
    setType(para);
  };

  const updateServiceHandler = (list) => setNursing(list);
  const updatePharmacyHandler = (list) => setpharmacy(list);
  const updateDispensaryHandler = (list) => setDispensary(list);
  const updateDiagnosisHandler = (list) => setDiagnosis(list);

  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, [load]);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/requisition`);
    if(result.status) {
      setNursing(result.data.nursing);
      setpharmacy(result.data.pharmacy);
      setDispensary(result.data.dispensary);
      setDiagnosis(result.data.diagnosis);
    }
  }

  const issueOutHandler = async (id, page) => {
    const result = await getFromServer(`pharmacy/requisition-issuedout/${id}/${page}`);
    if(result.status) {
      setNursing(result.data.nursing);
      setDispensary(result.data.dispensary);
      setDiagnosis(result.data.diagnosis);
      setpharmacy(result.data.pharmacy);
    }
  };

  const cancelHandler = async (id, page) => {
    if (window.confirm("Do you really want to cancel?")) {
      const result = await getFromServer(`pharmacy/requisition-cancel/${id}/${page}`);
      if(result.status) {
        setNursing(result.data.nursing);
        setDispensary(result.data.dispensary);
        setpharmacy(result.data.pharmacy);
        setDiagnosis(result.data.diagnosis);
      }
    }
  };

  const searchHandler = async (e) => {
    e.preventDefault();
    var date = fromdate.current.value;
    var date2 = todate.current.value;
    // var search = searchText.current.value;

    if (
      date == null ||
      date == "" ||
      date == undefined ||
      date2 == null ||
      date2 == "" ||
      date2 == undefined
    ) {
    } else {
      setIsLoading(true);
      const result = await getFromServer(`pharmacy/requisition-search/${date}/${date2}`);
      if(result.status) {
        setNursing(result.data.nursing);
        setDispensary(result.data.dispensary);
        setDiagnosis(result.data.diagnosis);
        setpharmacy(result.data.pharmacy);
        setIsLoading(false);
      }
    }
  };

  const clearfilterHandler = () => {
    setLoad(!load)
    document.getElementById('from_date').value = ''
    document.getElementById('to_date').value = ''
  }

  return (
    <>
      <MainContent>
        {success && (
          <Success close={() => setSuccess(false)}>
            Requisition has been added Successfully
          </Success>
        )}

<div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4>Search</h4>
                <div className="row">
                  <form
                    onSubmit={searchHandler}
                    method="GET"
                    className="card-header-form"
                    style={{ display: "flex" }}
                  >
                    <div className="col-md-6">
                      <p style={{ color: "gray" }}>From Date Time</p>
                      <input
                        type="datetime-local"
                        ref={fromdate}
                        id="from_date"
                        className="form-control"
                      />
                      <button
                        type="submit"
                        style={{ color: "white" }}
                        className="btn btn-info mt-3"
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        style={{ color: "white" }}
                        className="btn btn-info mt-3 ml-3"
                        onClick={clearfilterHandler}
                      >
                        Clear Filter
                      </button>
                    </div>
                    <div className="col-md-6">
                      <p style={{ color: "gray" }}>To Date Time</p>
                      <input
                        type="datetime-local"
                        id="to_date"
                        ref={todate}
                        className="form-control"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>


        {isLoading && <Loading />}

        <div className="section-body">
          <div className="row">
            <div className="col-md-4">
              <button
                className="btn btn-primary mb-4"
                onClick={() => handleShowDiagnosis("Add")}
              >
                Add New Requisition
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">#</th>
                  <th scope="col">Item</th>
                  <th scope="col">From</th>
                  <th scope="col">To</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Notes</th>
                  <th scope="col">Status</th>
                  <th scope="col">Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan="9">Diagnosis Requisition</th>
                </tr>
                {diagnosis &&
                  diagnosis.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.item}</td>
                        <td>{a.froms}</td>
                        <td>{a.to}</td>
                        <td>{a.quantity}</td>
                        <td>{a.note}</td>
                        <td>{a.status}</td>
                        <td>{a.date}</td>
                        <td>
                          {a.status == "Issued Out" ? (
                            <span class="badge badge-success">Issued Out</span>
                          ) : (
                            <>
                              <button
                                className="btn btn-sm btn-primary mr-1"
                                onClick={() => handleShowDiagnosis(a.id)}
                              >
                                Edit
                              </button>
                              {/* <button
                            className="btn btn-sm btn-primary mr-1"
                            onClick={() => issueOutHandler(a.id, "dispensary")}
                          >
                            Issued Out
                          </button> */}
                              <button
                                className="btn btn-sm btn-danger"
                                onClick={() => cancelHandler(a.id, "diagnosis")}
                              >
                                Cancel
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}

                <tr>
                  <th colSpan="9">Dispensary Requisition</th>
                </tr>
                {dispensary &&
                  dispensary.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.item}</td>
                        <td>{a.froms}</td>
                        <td>{a.to}</td>
                        <td>{a.quantity}</td>
                        <td>{a.note}</td>
                        <td>{a.status}</td>
                        <td>{a.date}</td>
                        <td>
                          {/* {a.status == 'Issued Out' ? 
                          <span class="badge badge-success">Issued Out</span>  : <>
                          <button
                            className="btn btn-sm btn-primary mr-1"
                            onClick={() => handleShowDispensary(a.id)}
                          >
                            Edit
                          </button>
                          <button className="btn btn-sm btn-danger" onClick={() => cancelHandler(a.id, "dispensary")}>
                            Cancel
                          </button>
                          </>
                        } */}
                          {a.status == "Issued Out" ? (
                            <span class="badge badge-success">Issued Out</span>
                          ) : (
                            <span class="badge badge-danger">Pending</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <th colSpan="9">Nursing Requisition</th>
                </tr>
                {service &&
                  service.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.item}</td>
                        <td>{a.froms}</td>
                        <td>{a.to}</td>
                        <td>{a.quantity}</td>
                        <td>{a.note}</td>
                        <td>{a.status}</td>
                        <td>{a.date}</td>
                        <td>
                          {a.status == "Issued Out" ? (
                            <span class="badge badge-success">Issued Out</span>
                          ) : (
                            <span class="badge badge-danger">Pending</span>
                          )}
                          {/* {a.status == 'Issued Out' ? 
                          <span class="badge badge-success">Issued Out</span>  : <>
                          <button
                            className="btn btn-sm btn-primary mr-1"
                            onClick={() => handleShow(a.id)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-sm btn-primary mr-1"
                            onClick={() => issueOutHandler(a.id, "nursing")}
                          >
                            Issued Out
                          </button>
                          <button className="btn btn-sm btn-danger" onClick={() => cancelHandler(a.id, "nursing")}>
                            Cancel
                          </button></>} */}
                        </td>
                      </tr>
                    );
                  })}
                {/* <tr>
                  <th colSpan="7">Pharmacy Requisition</th>
                </tr>
                {pharmacy &&
                  pharmacy.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.froms.name}</td>
                        <td>{a.to.name}</td>
                        <td>{a.quantity}</td>
                        <td>{a.note}</td>
                        <td>{a.status}</td>
                        <td>
                          {a.status == "Issued Out" ? (
                            <span class="badge badge-success">Issued Out</span>
                          ) : (
                            <span class="badge badge-danger">Pending</span>
                          )}
                        </td>
                      </tr>
                    );
                  })} */}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddRequisition
          show={show}
          close={handleClose}
          success={successHandler}
          requisition={updateServiceHandler}
          type={type}
        />
      )}
      {showPharmacy && (
        <PharmacyAddRequisition
          show={showPharmacy}
          close={handleClosePharmacy}
          success={successHandler}
          requisition={updatePharmacyHandler}
          type={type}
        />
      )}
      {showDispensary && (
        <AddRequisitionDispensary
          show={showDispensary}
          close={handleCloseDispensary}
          success={successHandler}
          requisition={updateDispensaryHandler}
          type={type}
        />
      )}
      {showDiagnosis && (
        <AddRequestDiagnosis
          show={showDiagnosis}
          close={handleCloseDiagnosis}
          success={successHandler}
          requisition={updateDiagnosisHandler}
          type={type}
        />
      )}
    </>
  );
};

export default DiagnosisRequisition;
