import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import Loading from "../../ui/Loading";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer, postToServer } from "../../global/requests";


const PregnancyDeliveryRecord = () => {
  const para = useParams();
  const navigate = useNavigate();

  const encounter_pid = para.encounter_pid;
  const pregnancy_id = para.pregnancy_id;

  const [list, setList] = useState();
  const [vaccineList, setVaccineList] = useState();
  const [load, setLoad] = useState(true);
  const [addDelivery, setAddDelivery] = useState(false);
  const [p, setEncounter] = useState();

  const perineum = useRef();
  const sutures = useRef();
  const suturedby = useRef();
  const bloodloss = useRef();
  const estimatedbloodloss = useRef();
  const expelledby = useRef();
  const deliverymode = useRef();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/pregnancy-delivery-record/${encounter_pid}/${pregnancy_id}`);
    if(result.status) {
      if (result.data != "") {
        setList(result.data.list);
        setEncounter(result.data.encounter);
        setLoad(false);
      }
    }
  }

  const saveHandler = async () => {
    const data = {
      perineum: perineum.current.value,
      sutures: sutures.current.value,
      suturedby: suturedby.current.value,
      bloodloss: bloodloss.current.value,
      estimatedbloodloss: estimatedbloodloss.current.value,
      expelledby: expelledby.current.value,
      deliverymode: deliverymode.current.value,
    };

    const result = await postToServer(`main/pregnancy-delivery-record/${encounter_pid}/${pregnancy_id}`, { data });
    if(result.status) {
      setList(result.data);
      setLoad(false);
      setAddDelivery(false);
    }
  };

  return (
    <>
      <MainContent>
        <div className="section-row">
          {p && (
            <div className="section-body">
             <PatientDetailCard list={p} isEncounter={true} />
            </div>
          )}
          <div>
            <h2 className="mt-5 mb-1 ml-2 mb-5">Delivery Record</h2>
            <button
              className="btn btn-primary mb-4"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            <button
              className="btn btn-primary mb-4 pull-right"
              onClick={() => setAddDelivery(!addDelivery)}
            >
              Add new record
            </button>
          </div>
          {addDelivery && (
            <div className="card-body">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <table className="table responsive">
                    <thead>
                      <tr style={{ fontSize: "13px" }}>
                        <th scope="col">Perineum</th>
                        <th scope="col">No. of Sutures</th>
                        <th scope="col">Sutured by</th>
                        <th scope="col">Blood Loss</th>
                        <th scope="col">Estimated Blood Loss</th>
                        <th scope="col">Expelled by</th>
                        <th scope="col">Delivery mode</th>
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            ref={perineum}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            ref={sutures}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            ref={suturedby}
                          ></input>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            ref={bloodloss}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            ref={estimatedbloodloss}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            ref={expelledby}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            ref={deliverymode}
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={saveHandler}
                          >
                            save
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          <div className="card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {load && <Loading />}
                <table className="table responsive">
                  <thead>
                    <tr style={{ fontSize: "13px" }}>
                      <th scope="col">S/N</th>
                      <th scope="col">Date</th>
                      <th scope="col">Perineum</th>
                      <th scope="col">No. of Sutures</th>
                      <th scope="col">Sutured by</th>
                      <th scope="col">Blood Loss</th>
                      <th scope="col">Estimated Blood Loss</th>
                      <th scope="col">Expelled by</th>
                      <th scope="col">Delivery mode</th>
                      {/* <th scope="col" className="text-center">
                        Actions
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.deliveryRecordDetail.map((t, index) => {
                        return (
                          <tr key={index}>
                            <th scope="col">{index + 1}</th>
                            <td scope="col">{t.date}</td>
                            <td scope="col">{t.perineum}</td>
                            <td scope="col">{t.sutures}</td>
                            <td scope="col">{t.suturedby}</td>
                            <td scope="col">{t.bloodloss}</td>
                            <td scope="col">{t.estimatedbloodloss}</td>
                            <td scope="col">{t.expelledby}</td>
                            <td scope="col">{t.deliverymode}</td>
                            {/* <td scope="col"></td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default PregnancyDeliveryRecord;
