import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import Loading from "../../ui/Loading";
import PregnancyForm from "./PregnancyForm";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer } from "../../global/requests";


const Pregnancy = () => {
  const para = useParams();
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [vaccineList, setVaccineList] = useState();
  const [load, setLoad] = useState(true);
  const [p, setEncounter] = useState();

  const [form, setForm] = useState(false);

  const vaccine = useRef();
  const value = useRef();
  const output = useRef();
  const appointment = useRef();
  const remarks = useRef();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/pregnancy/${para.pid}`);
    if(result.status) {
      if (result.data != "") {
        setList(result.data.list);
        setEncounter(result.data.encounter);
        setLoad(false);
      }
    }
  }

  const saveHandler = (list) => {
    setList(list);
  };

  const deliveryRecordHandler = (id) => {
    navigate(`/pregnancy-delivery-record/pid=${para.pid}/${id}`);
  };

  const birthDetailHandler = (id) => {
    navigate(`/pregnancy-birth-detail/pid=${para.pid}/${id}`);
  };

  const antenatalDetailHandler = (id) => {
    navigate(`/pregnancy-antenatal-detail/pid=${para.pid}/${id}`);
  };

  return (
    <>
      <MainContent>
        {!form && (
          <div className="section-row">
            {p && (
              <div className="section-body">
               <PatientDetailCard list={p} isEncounter={true} />
              </div>
            )}
            <div className="card-body">
              <h2 className="mt-5 mb-1 ml-2">Pregnancy</h2>
              {load && <Loading />}
              <div>
                <button
                  className="btn btn-primary mb-4 pull-right"
                  onClick={() => setForm(true)}
                >
                  Add new record
                </button>
              </div>

              <table className="table responsive">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    <th scope="col">S/N</th>
                    <th scope="col">Height</th>
                    <th scope="col">Booking Date</th>
                    <th scope="col">LMP</th>
                    <th scope="col">EDD</th>
                    <th scope="col">No. of Living Children</th>
                    <th scope="col">Induced Abortion</th>
                    <th scope="col">Still Birth</th>
                    <th scope="col">Last Pregnancy</th>
                    <th scope="col">History</th>
                    <th scope="col">Pelvic Assessment</th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.pregnancyDetail.map((t, index) => {
                      return (
                        <tr key={index}>
                          <th scope="col">{index + 1}</th>
                          <td scope="col">{t.height}</td>
                          <td scope="col">{t.date}</td>
                          <td scope="col">{t.lmp}</td>
                          <td scope="col">{t.edd}</td>
                          <td scope="col">{t.children}</td>
                          <td scope="col">{t.inducedabortion}</td>
                          <td scope="col">{t.stillbirth}</td>
                          <td scope="col">{t.lastpregnancymonth}</td>
                          <td scope="col">{t.history}</td>
                          <td scope="col">{t.peivic}</td>
                          <td scope="col">
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => deliveryRecordHandler(t.id)}
                            >
                              Delivery Records
                            </button>
                            <button
                              className="btn btn-sm btn-primary ml-1"
                              onClick={() => birthDetailHandler(t.id)}
                            >
                              Birth Details
                            </button>
                            <button
                              className="btn btn-sm btn-primary ml-1"
                              onClick={() => antenatalDetailHandler(t.id)}
                            >
                              Antenatal Details
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {form && (
          <div className="section-row">
            <PregnancyForm
              back={() => setForm(false)}
              pid={para.pid}
              update={saveHandler}
            />
          </div>
        )}
      </MainContent>
    </>
  );
};

export default Pregnancy;
