import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { getFromServer } from "../../global/requests";
import Loading from "../../ui/Loading";

const LabResultReportDetails = (props) => {
  // variables
  const [testList, setTestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    setIsLoading(true);
    const result = await getFromServer(
      `reports/lab-result/${props.investigationId}/details`
    );
    if (result.status) setTestList(result.data);
    else setTestList([]);
    setIsLoading(false);
  };

  const handleCose = () => {
    props.close();
    setTestList([]);
  }

  return (
    <>
      <Modal show={props.show} onHide={handleCose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Lab report details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div style={{ margin: "100px 0" }}>
              <Loading />
            </div>
          ) : (
            <table className="table table-md table-bordered">
              <thead>
                <tr>
                  <th>Test Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {testList.map((testRow) => (
                  <>
                    <tr>
                      <td>{testRow.test}</td>
                      <td dangerouslySetInnerHTML={{ __html: testRow.value }} />
                    </tr>
                    {testRow.childs &&
                      testRow.childs.map((gTestRow) => (
                        <tr>
                          <td style={{ paddingLeft: "50px" }}>{gTestRow[1]}</td>
                          <td
                            dangerouslySetInnerHTML={{ __html: gTestRow[2] }}
                          />
                        </tr>
                      ))}
                  </>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCose} className="btn btn-primary">Close</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LabResultReportDetails;
