import React, { useState, useEffect } from "react";
import MainContent from "../../ui/MainContent";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getFromServer } from "../../global/requests";

const ViewUploadedFile = () => {
  const params = useParams();
  const [image, setImage] = useState();
  const [video, setVideo] = useState();
  const [file, setFile] = useState();
  const [none, setNone] = useState(false);

  useEffect(() => {
    doInitialTasks()
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/frontdesk/patient/detail/${params.pid}/none`);
    if(result.status) {
      let ext = String(result.data.p[0].upload).split(".")[1];
      if (ext == "jpg" || ext == "jpeg" || ext == "png") {
        setImage(result.data.p[0].upload);
      } else if (ext == "mp4") {
        setVideo(result.data.p[0].upload);
      } else if (ext == "" || ext == null || ext == undefined) {
        setNone(true);
      } else {
        setFile(result.data.p[0].upload);
      }
    }
  }
  return (
    <>
      <MainContent>
        <div className="section-body text-center">
          {image && <img src={window.baseURLuploaded + image} className='w-100' />}
          {video && (
            <video class="w-75" controls>
              <source src={window.baseURLuploaded + video} type="video/mp4" />
            </video>
          )}
          {file && (
            <embed
              src={window.baseURLuploaded + file}
              width="800px"
              height="1100px"
            />
          )}
          {none && <h3 className="text-center">No uploaded files found</h3>}
        </div>
      </MainContent>
    </>
  );
};

export default ViewUploadedFile;
