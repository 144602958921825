import React, { useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const PregnancyForm = (props) => {
  const [selectedOption, setSelectedOption] = useState("Vomitting");

  const formIsNewRegisterHandler = (e) => {
    setSelectedOption(e.target.value);
  };

  const lastpregnancymonth = useRef();
  const lastpregnancyoutcome = useRef();
  const cs = useRef();
  const stillbirth = useRef();
  const spontaneousabortion = useRef();
  const inducedabortion = useRef();
  const previouspragnancy = useRef();
  const children = useRef();
  const edd = useRef();
  const lmp = useRef();
  const height = useRef();
  const date = useRef();
  const peivic = useRef();
  const createby = useRef();
  const tetanus = useRef();
  const obstetric = useRef();
  const history = useRef();

  const formSubmission = async () => {
    const data = {
      lastpregnancymonth: lastpregnancymonth.current.value,
      lastpregnancyoutcome: lastpregnancyoutcome.current.value,
      cs: cs.current.value,
      stillbirth: stillbirth.current.value,
      spontaneousabortion: spontaneousabortion.current.value,
      inducedabortion: inducedabortion.current.value,
      previouspragnancy: previouspragnancy.current.value,
      children: children.current.value,
      edd: edd.current.value,
      lmp: lmp.current.value,
      height: height.current.value,
      date: date.current.value,
      peivic: peivic.current.value,
      createby: createby.current.value,
      tetanus: tetanus.current.value,
      obstetric: obstetric.current.value,
      history: history.current.value,
      presentpregnancy: selectedOption,
    };

    const result = await postToServer(`main/pregnancy/${props.pid}`, { data });
    if(result.status) {
      console.log(result.data);
      props.update(result.data);
      props.back();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    formSubmission();
  };
  return (
    <>
      <button className="btn btn-sm btn-success mb-2" onClick={props.back}>
        Go Back
      </button>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h4>Pregnancy</h4>
              <div className="py-3"></div>
            </div>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={submitHandler}
            >
              <div className="card-body pb-0">
                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Date of Booking</label>
                    <input
                      type="date"
                      className="form-control"
                      required
                      ref={date}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*Height</label>
                    <input
                      type="text"
                      name="hospital_id"
                      className="form-control"
                      required
                      ref={height}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*L.M.P</label>
                    <input
                      type="date"
                      name="surname"
                      className="form-control"
                      required
                      ref={lmp}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*E.D.D</label>
                    <input
                      type="date"
                      className="form-control"
                      required
                      ref={edd}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*No. of Children</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={children}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>*Previous Pragnancy</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={previouspragnancy}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*No. of Induced Abortion</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={inducedabortion}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>
                      No. of Spontaneous Abortion {"(<"}28 weeks{")"}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      ref={spontaneousabortion}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>No. of Previous Still Births</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={stillbirth}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*CS</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={cs}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*Outcome of last Pregnancy</label>
                    <input
                      type="text"
                      name="state_of_origin"
                      className="form-control"
                      required
                      ref={lastpregnancyoutcome}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>No. of month b/w last Pregnancy</label>
                    <input
                      type="text"
                      name="next_of_kin"
                      className="form-control"
                      ref={lastpregnancymonth}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-4">
                    <label>Relevant History</label>
                    <input type="text" className="form-control" ref={history} />
                  </div>
                  <div className="col-md-4">
                    <label>*Obstetric Complication</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={obstetric}
                    />
                  </div>

                  <div className="col-md-4">
                    <div>
                      <label>Present Pregnancy</label>
                      <br />
                      {/* <div className="form-check"> */}
                      <div className="col-md-5">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Vomitting"
                          name="isVomitting"
                          id="flexRadioDefault1"
                          onChange={formIsNewRegisterHandler}
                          checked={selectedOption === "Vomitting"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Vomitting
                        </label>
                        {/* </div> */}
                        {/* <div className="form-check"> */}
                        <br />
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Leg Swelling"
                          name="isLegSwelling"
                          id="flexRadioDefault2"
                          onChange={formIsNewRegisterHandler}
                          checked={selectedOption === "Leg Swelling"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Leg Swelling
                        </label>
                      </div>
                      {/* </div> */}
                      {/* <div className="form-check"> */}
                      <div className="col-md-5">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Headache"
                          name="isHeadache"
                          id="flexRadioDefault3"
                          onChange={formIsNewRegisterHandler}
                          checked={selectedOption === "Headache"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Headache
                        </label>
                        <br />

                        {/* </div> */}
                        {/* <div className="form-check"> */}
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Others"
                          name="isOthers"
                          id="flexRadioDefault4"
                          onChange={formIsNewRegisterHandler}
                          checked={selectedOption === "Others"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault4"
                        >
                          Others
                        </label>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Tetanus</label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      ref={tetanus}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Pelvic Assessment</label>
                    <input type="text" className="form-control" ref={peivic} />
                  </div>

                  <div className="col-md-4">
                    <label>Create by</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      ref={createby}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer pt-0">
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PregnancyForm;
