import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import Success from "../../ui/success";
import Loading from "../../ui/Loading";
import PatientDetailCard from "../../ui/PatientDetailCard";
import {
  getFromServer,
  deleteFromServer,
  postToServer,
} from "../../global/requests";

const VitalDetails = (props) => {
  const param = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [temp, setTemp] = useState();
  const [list, setList] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [p, setData] = useState();
  const [success, setSuccess] = useState(false);

  const weight = useRef();
  const height = useRef();
  const temperature = useRef();
  const pulserate = useRef();
  const bloodpressure = useRef();
  const respiration = useRef();
  const oxygenlevel = useRef();
  const remarks = useRef();

  const handleClose = () => {
    setShow(false);
    setTemp("");
  };
  const handleShow = (data) => {
    setTemp(data);
    setShow(true);
  };
  const closeHandler = () => setSuccess(false);

  const saveHandler = async (e, pid, ppid) => {
    e.preventDefault();
    const data = {
      encounterpid: pid,
      patientpid: ppid,
      weight: weight.current.value,
      height: height.current.value,
      temperature: temperature.current.value,
      pulserate: pulserate.current.value,
      bloodpressure: bloodpressure.current.value,
      respiration: respiration.current.value,
      oxygenlevel: oxygenlevel.current.value,
      remarks: remarks.current.value,
    };
    const result = await postToServer(`nursing/vital`, { data });
    if (result.status) {
      setList(result.data);
      document.getElementById("vitalForm").reset();
      // navigate("/vitals");
    }
  };

  useEffect(() => {
    setData(param.state.list);
    // console.log(param.state.list)
    doInitialTasks();
    setIsLoading(false);
  }, []);
  const doInitialTasks = async () => {
    const result = await getFromServer(
      `nursing/vital-detail/${param.state.list.patient.pid}`
    );
    if (result.status) setList(result.data);
  };

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const result = await deleteFromServer(
        `nursing/vital-detail-delete/${id}/${param.state.list.patient.pid}`
      );
      if (result.status) setList(result.data);
    }
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            {success && (
              <Success close={closeHandler}>Vital Successful</Success>
            )}
            {isLoading && <Loading />}
            {p && <PatientDetailCard list={p.patient} isEncounter={false} />}

            <div className="tablexflow">
              {p && (
                <form id="vitalForm">
                  <table className="table table-bordered">
                    <thead>
                      <tr style={{ fontSize: "13px" }}>
                        <th scope="col">Date</th>
                        <th scope="col">Weight</th>
                        <th scope="col">Height</th>
                        <th scope="col">Body Temperature</th>
                        <th scope="col">Pulse Rate</th>
                        <th scope="col">Blood Pressure</th>
                        <th scope="col">Respiration</th>
                        <th scope="col">Oxygen Level</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Measured by</th>
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{p.date}</td>
                        <td className="p-2">
                          <input
                            type="number"
                            min="1"
                            className="form-control w-75"
                            ref={weight}
                          />{" "}
                          Kg
                        </td>
                        <td className="p-2">
                          <input
                            type="number"
                            min="1"
                            className="form-control w-75"
                            ref={height}
                          />{" "}
                          Meters
                        </td>
                        <td className="p-2">
                          <input
                            type="number"
                            min="1"
                            className="form-control w-75"
                            ref={temperature}
                          />{" "}
                          <sup>o</sup>C
                        </td>
                        <td className="p-2">
                          <input
                            type="number"
                            min="1"
                            className="form-control w-75"
                            ref={pulserate}
                          />{" "}
                          BPM
                        </td>
                        <td className="p-2">
                          <input
                            type="number"
                            min="1"
                            className="form-control w-75"
                            ref={bloodpressure}
                          />{" "}
                          mmHg
                        </td>
                        <td className="p-2">
                          <input
                            type="number"
                            min="1"
                            className="form-control w-75"
                            ref={respiration}
                            required
                          />
                        </td>
                        <td className="p-2">
                          <input
                            type="number"
                            min="1"
                            className="form-control w-75"
                            ref={oxygenlevel}
                          />
                        </td>
                        <td className="p-2">
                          <input
                            type="text"
                            className="form-control w-75"
                            ref={remarks}
                            required
                          />
                        </td>
                        <td></td>
                        <td>
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary pd"
                            onClick={(e) =>
                              saveHandler(e, p.pid, p.patient.pid)
                            }
                          >
                            save
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              )}
            </div>
          </div>
          <div>
            <table className="table table-bordered table-md mt-4">
              {list &&
                list.map((l) => {
                  return (
                    <>
                      <thead>
                        <tr style={{ fontSize: "13px" }}>
                          <th>#</th>
                          <th scope="col">
                            {" "}
                            Date{"("}
                            {l.encounter.pid}
                            {")"}
                          </th>
                          <th scope="col">Weight</th>
                          <th scope="col">Height</th>
                          <th scope="col">Body Temperature</th>
                          <th scope="col">Pulse Rate</th>
                          <th scope="col">Blood Pressure</th>
                          <th scope="col">Respiration</th>
                          <th scope="col">Oxygen Level</th>
                          <th scope="col">Remarks</th>
                          <th scope="col">Measured by</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {l.vitaldata.map((d, j) => {
                          return (
                            <tr key={j} style={{ fontSize: "13px" }}>
                              <th>{j + 1}</th>
                              <td scope="col">{l.encounter.date}</td>
                              <td scope="col">{d.weight} kg</td>
                              <td scope="col">{d.height} m</td>
                              <td scope="col">
                                {d.temperature} <sup>o</sup>C
                              </td>
                              <td scope="col">{d.pulserate} bpm</td>
                              <td scope="col">{d.bloodpressure} mmHg</td>
                              <td scope="col">{d.respiration}</td>
                              <td scope="col">{d.oxygenlevel}</td>
                              <td scope="col">{d.remarks}</td>
                              <td scope="col">{d.measuredby}</td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => deleteHandler(d.id)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  );
                })}
            </table>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default VitalDetails;
