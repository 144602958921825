import React, { useState, useEffect } from "react";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import Success from "../../ui/success";
import { getFromServer, postToServer } from "../../global/requests";

const RecievedItems = () => {
  const [order, setOrder] = useState([]);
  const [success, setSuccess] = useState(false);

  const dateHandler = (e, i) => {
    const orders = [...order];
    orders[i].date = e.target.value;
    setOrder(orders);
  };

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/purchase-order/confirm`);
    if(result.status) {
      console.log(result.data);
      for (var i = 0; i < result.data.length; i++) {
        var date = result.data[i].date.split(/(\s+)/)[0].split("-");
        const y = date[0];
        const m = date[1];
        const d = date[2];
        var newdate = new Date(y + "-" + m + "-" + d)
          .toISOString()
          .split("T")[0];
        result.data[i].date = newdate;
      }
      setOrder(result.data);
    }
  }

  const dateSaveHandler = async (id, index) => {
    const data = {
      date: order[index].date,
    };
    console.log("=>", data);
    const result = await postToServer(`pharmacy/purchase-order/${id}`, { data });
    if(result.status) setSuccess(true);
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={() => setSuccess(false)}>
            Expiry Date updated Successfully
          </Success>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <h4>Recieved Items</h4>
            </div>
          </div>

          <div className="tablexflow mt-3">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">S\N</th>
                  <th scope="col">Item Name</th>
                  <th scope="col">Recieved Quantity</th>
                  <th scope="col">Unit Cost</th>
                  <th scope="col">GRN</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Store</th>
                  <th scope="col">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {order &&
                  order.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.item}</td>
                        <td>{a.quantity}</td>
                        <td>{a.cost}</td>
                        <td></td>
                        <td>
                          <input
                            type="date"
                            id={"date" + index}
                            value={a.date}
                            className="form-control"
                            onChange={(e) => dateHandler(e, index)}
                          />
                        </td>
                        <td>{a.type}</td>
                        <td>{a.status}</td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => dateSaveHandler(a.id, index)}
                          >
                            save
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default RecievedItems;
