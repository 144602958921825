import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import ReactQuill from "react-quill";
import { postToServer } from "../../global/requests";

const NurseReportModel = (props) => {
  const edit = props.is_edit
  const tempData = props.tempData
  const [value, setValue] = useState("");

  const report = useRef();
  const nursename = useRef();
  const operation = useRef();

  useEffect(()=>{
    if(edit && tempData){
      document.getElementById('operation').value = tempData.operation
      document.getElementById('report').value = tempData.report
      document.getElementById('nurse-name').value = tempData.nursename
    }
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();


    let id = null
    if(edit){
      id = tempData.id
    }

    const data = {
      is_edit: edit,
      id:id,
      report: value,
      nursename: nursename.current.value,
      operation: operation.current.value,
    };

    const result = await postToServer(`usermgt/nurse-report/${props.encounter_pid}`, { data });
    if(result.status) {
      props.success(true);
      props.close();
      props.update(result.data);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="xl">
        <Modal.Header closeButton>
        {edit ? 
          <Modal.Title>Edit Nursing Report</Modal.Title> :
          <Modal.Title>Add Nursing Report</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Nurse Name</label>
                    <input
                      id="nurse-name"
                      type="text"
                      className="form-control"
                      required
                      ref={nursename}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Operation</label>
                    <input
                      id="operation"
                      type="text"
                      className="form-control"
                      required
                      ref={operation}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="col-form-label">Report</label>
                    <ReactQuill theme="snow" value={value} onChange={setValue} />

                    {/* <textarea
                      id="report"
                      type="text"
                      rows="3"
                      className="form-control"
                      required
                      ref={report}
                    ></textarea> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                {edit ? <>Update</>:<>Add</>}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NurseReportModel;
