
import React, { useState, useEffect, useRef } from "react";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import Loading from "../../ui/Loading"
import { useNavigate } from "react-router-dom";
import { getFromServer, postToServer } from "../../global/requests";

const Injection = () => {
  const navigate = useNavigate();
  const [disengage, setDisengage] = useState();
  const [searchList, setSearchList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const search = useRef();
  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/nursing-injection`);
    if(result.status) {
      setDisengage(result.data);
      setIsLoading(false);
      // console.log(result.data);
    }
  }

  const backHandler = () => {
    setIsSearch(false);
    document.getElementById("search").value = "";
  };
  const detailHandler = async (id,is_search) => {
    const result = await getFromServer(`pharmacy/nursing-injection-detail/${id}/${is_search}`);
    if(result.status) {
      navigate("/injection-detail", { state: { list: result.data } });
      // setDisengage(result.data);
      console.log(result.data);
    }
    // navigate("/injection-detail", { state: { list: list } });
  };

  const searchHandler = async () => {
    const data = {
      search: search.current.value,
    };
    console.log(data)
    const result = await postToServer(`pharmacy/nursing-injection`, { data });
    if(result.status) {
      // console.log(result.data);
      setSearchList(result.data);
      setIsSearch(true);
      document.getElementById("search").value = "";
    }
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <div className="col-md-12">
              <div className="card author-box card-primary">
                <div className="card-body ">
                  <h2 className="py-3">Injection</h2>
                  <div className="row">
                    <div className="col-md-4 mb-4 d-flex">
                      <input
                        type="text"
                        className="form-control"
                        id="search"
                        placeholder="Search using PID no. or name for all injections"
                        ref={search}
                      />
                      <button
                        type="button"
                        className="btn btn-primary ml-3"
                        id="search"
                        onClick={searchHandler}
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                  </div>
          {isLoading && <Loading />}

                  {!isSearch && (
                    <div className="tablexflow">
                      <table className="table responsive">
                        <thead>
                          <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Company</th>
                            <th scope="col">Has Injection</th>
                            <th scope="col">Sign Bill</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {disengage &&
                            disengage.map((a, index) => {
                              return (
                                <>
                                      <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                          {a.patient.surname}{" "}
                                          {a.patient.firstname}
                                        </td>
                                        <td>
                                          {a.encounter.profile.split('=>')[0]}
                                        </td>
                                        <td>Yes</td>
                                        <td>No</td>
                                        <td className="text-center">
                                          <button
                                            className="btn btn-sm btn-primary"
                                            style={{ color: "white" }}
                                            onClick={() =>
                                              detailHandler(a.id, 0)
                                            }
                                          >
                                            Detail
                                          </button>
                                        </td>
                                      </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {isSearch && (
                    <div>
                      <div>
                        <button
                          className="btn btn-primary mb-3"
                          onClick={backHandler}
                        >
                          Back to Pending List
                        </button>
                      </div>
                      <div className="tablexflow">
                        <table className="table responsive">
                          <thead>
                            <tr style={{ fontSize: "13px" }}>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Company</th>
                              <th scope="col">Has Injection</th>
                              <th scope="col">Sign Bill</th>
                              <th scope="col" className="text-center">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {searchList &&
                              searchList.flat().map((a, index) => {
                                return (
                                  <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      {a.patient.firstname} {a.patient.surname}
                                    </td>
                                    <td>{a.encounter.profile.split('=>')[0]}</td>
                                    <td>Yes</td>
                                    <td>No</td>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-sm btn-primary"
                                        style={{ color: "white" }}
                                        onClick={() => detailHandler(a.id,1)}
                                      >
                                        Detail
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default Injection;
