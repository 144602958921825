import React, { useState, useEffect, useRef } from "react";
import { render } from "react-dom";
import axios from "axios";
import { suggestions } from "./Suggestions";
import Cookies from "js-cookie";
import AutoPrescription from "./Auto/AutoPrescription";
import Error from "../../ui/error";
import AutoFluid from "./Auto/AutoFluid";
import { postToServer } from "../../global/requests";

const Prescription = (props) => {
  const pid = props.encounter_pid;
  const patient_pid = props.patient_pid;
  const [save, setSave] = useState(false);
  const [error, setError] = useState(false);
  const [head, setHead] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const [diag, setDiag] = useState();
  const [fluidList, setFluidList] = useState();
  const [tableData, setTableData] = useState();
  const [tableDataFluid, settableDataFluid] = useState();
  const [tableDataHead, setTableDataHead] = useState([]);
  const [check, setCheck] = useState([]);

  const btnColor = "primary";
  const btnColorActive = "info";

  const [drugbtn, setDrugBtn] = useState(false);
  const [injectionbtn, setInjectionBtn] = useState(false);
  const [fluidbtn, setFluidBtn] = useState(false);

  const drugBtnHandler = () => {
    setDrugBtn(!drugbtn);
    setInjectionBtn(false);
    setFluidBtn(false);
  };
  const injectionBtnHandler = () => {
    setDrugBtn(false);
    setInjectionBtn(!injectionbtn);
    setFluidBtn(false);
  };
  const fluidBtnHandler = () => {
    setDrugBtn(false);
    setInjectionBtn(false);
    setFluidBtn(!fluidbtn);
  };

  const [tags, setTags] = useState([]);
  const [val, setVal] = useState([]);
  const [drug, setDrug] = useState([]);
  const [route, setRoute] = useState([]);
  const [dosage, setDosage] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [period, setPeriod] = useState([]);
  const [none, setNone] = useState([]);
  const [total, setTotal] = useState([]);
  const [comment, setComment] = useState([]);

  const [saveInjection, setSaveInjection] = useState(false);
  const [headInjection, setHeadInjection] = useState(false);
  const [tagsInjection, setTagsInjection] = useState([]);
  const [valInjection, setValInjection] = useState([]);
  const [drugInjection, setDrugInjection] = useState([]);
  const [routeInjection, setRouteInjection] = useState([]);
  const [dosageInjection, setDosageInjection] = useState([]);
  const [frequencyInjection, setFrequencyInjection] = useState([]);
  const [periodInjection, setPeriodInjection] = useState([]);
  const [noneInjection, setNoneInjection] = useState([]);
  const [totalInjection, setTotalInjection] = useState([]);
  const [commentInjection, setCommentInjection] = useState([]);

  const [headFluid, setHeadFluid] = useState(false);
  const [tagsFluid, setTagsFluid] = useState([]);
  const [valFluid, setValFluid] = useState([]);
  const [drugFluid, setDrugFluid] = useState([]);
  const [routeFluid, setRouteFluid] = useState([]);
  const [dosageFluid, setDosageFluid] = useState([]);
  const [frequencyFluid, setFrequencyFluid] = useState([]);
  const [periodFluid, setPeriodFluid] = useState([]);
  const [noneFluid, setNoneFluid] = useState([]);
  const [totalFluid, setTotalFluid] = useState([]);
  const [commentFluid, setCommentFluid] = useState([]);

  const handleAdd = () => {
    setHead(true);
    setVal([...val, []]);
    setDrug([...drug, []]);
    setRoute([...route, []]);
    setDosage([...dosage, 1]);
    setFrequency([...frequency, 'Stat']);
    setPeriod([...period, 1]);
    setNone([...none, 7]);
    setTotal([...total, 1]);
    setComment([...comment, []]);
  };

  const handleAddInjection = () => {
    setHeadInjection(true);
    setValInjection([...valInjection, []]);
    setDrugInjection([...drugInjection, []]);
    setRouteInjection([...routeInjection, []]);
    setDosageInjection([...dosageInjection, 1]);
    setFrequencyInjection([...frequencyInjection, 'Stat']);
    setPeriodInjection([...periodInjection, 1]);
    setNoneInjection([...noneInjection, 7]);
    setTotalInjection([...totalInjection, 1]);
    setCommentInjection([...commentInjection, []]);
  };

  const handleAddFluid = () => {
    setHeadFluid(true);
    setValFluid([...valFluid, []]);
    setDrugFluid([...drugFluid, []]);
    setRouteFluid([...routeFluid, []]);
    setDosageFluid([...dosageFluid, []]);
    setFrequencyFluid([...frequencyFluid, []]);
    setPeriodFluid([...periodFluid, []]);
    setNoneFluid([...noneFluid, []]);
    setTotalFluid([...totalFluid, []]);
    setCommentFluid([...commentFluid, []]);
  };

  const handleDrug = (e, i) => {
    const inputDrug = [...drug];
    inputDrug[i].push();
    setDrug(inputDrug);
  };

  // Drug
  const handleRoute = (e, i) => {
    const inputDrug = [...route];
    inputDrug[i] = e.target.value;
    setRoute(inputDrug);
  };
  const handleDosage = (e, i) => {
    const inputDrug = [...dosage];
    inputDrug[i] = e.target.value;
    setDosage(inputDrug);
    handleTotal(e, i, "dosage");
  };
  const handleFrequency = (e, i) => {
    const inputDrug = [...frequency];
    inputDrug[i] = e.target.value;
    setFrequency(inputDrug);
    handleTotal(e, i, "frequency");
  };
  const handlePeriod = (e, i) => {
    const inputDrug = [...period];
    inputDrug[i] = e.target.value;
    setPeriod(inputDrug);
    handleTotal(e, i, "period");
  };
  const handleNone = (e, i) => {
    const inputDrug = [...none];
    inputDrug[i] = e.target.value;
    setNone(inputDrug);
    handleTotal(e, i, "none");
  };
  const handleTotal = (e, i, c) => {
    let dos;
    let freq;
    let per;
    let non;
    if (c == "dosage") {
      dos = e.target.value;
      freq = frequency[i];
      per = period[i];
      non = none[i];
    } else if (c == "frequency") {
      dos = dosage[i];
      freq = e.target.value;
      per = period[i];
      non = none[i];
    } else if (c == "period") {
      dos = dosage[i];
      freq = frequency[i];
      per = e.target.value;
      non = none[i];
    } else if (c == "none") {
      dos = dosage[i];
      freq = frequency[i];
      per = period[i];
      non = e.target.value;
    }

    if (dos.length <= 0) dos = 1;
    if (freq.length <= 0) freq = 1;
    if (per.length <= 0) per = 1;
    if (non.length <= 0) non = 1;

    if (freq == "BD") freq = 2;
    else if (freq == "TDS") freq = 3;
    else if (freq == "QDS") freq = 4;
    else if (freq == "Nocte") freq = 1;
    else if (freq == "Mane") freq = 1;
    else if (freq == "WKLY") freq = 1;
    else if (freq == "AM") freq = 1;
    else if (freq == "PM") freq = 1;
    else if (freq == "DLY") freq = 1;
    else if (freq == "ALT DLY") freq = 1;
    else if (freq == "PRN") freq = 1;
    else if (freq == "Rectal") freq = 1;
    else if (freq == "Stat") freq = 1;
    if (non == "7") non = 1;
    else if (non == "52") non = 7;
    else if (non == "12") non = 30;

    if (
      dos.length <= 0 ||
      freq.length <= 0 ||
      per.length <= 0 ||
      non.length <= 0
    ) {
      console.log("none");
    } else {
      const newVal =
        parseInt(dos) * parseInt(per) * parseInt(freq) * parseInt(non);
      document.getElementById(`prescription_total${i}`).value = newVal;

      const inputDrug = [...total];
      inputDrug[i] = newVal;
      console.log(inputDrug)
      setTotal(inputDrug);
    }
  };
  const handleComment = (e, i) => {
    const inputDrug = [...comment];
    inputDrug[i] = e.target.value;
    setComment(inputDrug);
  };

  const getTags = (e, i) => {
    const inputDrug = [...tags];
    inputDrug[i] = e;
    setTags(inputDrug);
  };

    // Injection
    const handleRouteInjection = (e, i) => {
      const inputDrug = [...routeInjection];
      inputDrug[i] = e.target.value;
      setRouteInjection(inputDrug);
    };
    const handleDosageInjection = (e, i) => {
      const inputDrug = [...dosageInjection];
      inputDrug[i] = e.target.value;
      setDosageInjection(inputDrug);
      handleTotalInjection(e, i, "dosage");
    };
    const handleFrequencyInjection = (e, i) => {
      const inputDrug = [...frequencyInjection];
      inputDrug[i] = e.target.value;
      setFrequencyInjection(inputDrug);
      handleTotalInjection(e, i, "frequency");
    };
    const handlePeriodInjection = (e, i) => {
      const inputDrug = [...periodInjection];
      inputDrug[i] = e.target.value;
      setPeriodInjection(inputDrug);
      handleTotalInjection(e, i, "period");
    };
    const handleNoneInjection = (e, i) => {
      const inputDrug = [...noneInjection];
      inputDrug[i] = e.target.value;
      setNoneInjection(inputDrug);
      handleTotalInjection(e, i, "none");
    };
    const handleTotalInjection = (e, i, c) => {
      let dos;
      let freq;
      let per;
      let non;
      if (c == "dosage") {
        dos = e.target.value;
        freq = frequencyInjection[i];
        per = periodInjection[i];
        non = noneInjection[i];
      } else if (c == "frequency") {
        dos = dosageInjection[i];
        freq = e.target.value;
        per = periodInjection[i];
        non = noneInjection[i];
      } else if (c == "period") {
        dos = dosageInjection[i];
        freq = frequencyInjection[i];
        per = e.target.value;
        non = noneInjection[i];
      } else if (c == "none") {
        dos = dosageInjection[i];
        freq = frequencyInjection[i];
        per = periodInjection[i];
        non = e.target.value;
      }
  
      if (dos.length <= 0) dos = 1;
      if (freq.length <= 0) freq = 1;
      if (per.length <= 0) per = 1;
      if (non.length <= 0) non = 1;
  
      if (freq == "BD") freq = 2;
      else if (freq == "TDS") freq = 3;
      else if (freq == "QDS") freq = 4;
      else if (freq == "Nocte") freq = 1;
      else if (freq == "Mane") freq = 1;
      else if (freq == "WKLY") freq = 1;
      else if (freq == "AM") freq = 1;
      else if (freq == "PM") freq = 1;
      else if (freq == "DLY") freq = 1;
      else if (freq == "ALT DLY") freq = 1;
      else if (freq == "PRN") freq = 1;
      else if (freq == "Rectal") freq = 1;
      else if (freq == "Stat") freq = 1;
      if (non == "7") non = 1;
      else if (non == "52") non = 7;
      else if (non == "12") non = 30;
  
      if (
        dos.length <= 0 ||
        freq.length <= 0 ||
        per.length <= 0 ||
        non.length <= 0
      ) {
        console.log("none");
      } else {
        const newVal =
          parseInt(dos) * parseInt(per) * parseInt(freq) * parseInt(non);
        document.getElementById(`prescription_total_injection${i}`).value =
          newVal;
          const inputDrug = [...totalInjection];
          inputDrug[i] = newVal;
          setTotalInjection(inputDrug);
      }
  
  
    };
    const handleCommentInjection = (e, i) => {
      const inputDrug = [...commentInjection];
      inputDrug[i] = e.target.value;
      setCommentInjection(inputDrug);
    };
  
    const getTagsInjection = (e, i) => {
      const inputDrug = [...tagsInjection];
      inputDrug[i] = e;
      setTagsInjection(inputDrug);
      console.log(i);
    };


  // Fluid
  const handleRouteFluid = (e, i) => {
    const inputDrug = [...routeFluid];
    inputDrug[i] = e.target.value;
    setRouteFluid(inputDrug);
  };
  const handleDosageFluid = (e, i) => {
    const inputDrug = [...dosageFluid];
    inputDrug[i] = e.target.value;
    setDosageFluid(inputDrug);
    handleTotalFluid(e, i, "dosage");
  };
  const handleFrequencyFluid = (e, i) => {
    const inputDrug = [...frequencyFluid];
    inputDrug[i] = e.target.value;
    setFrequencyFluid(inputDrug);
    handleTotalFluid(e, i, "frequency");
  };
  const handlePeriodFluid = (e, i) => {
    const inputDrug = [...periodFluid];
    inputDrug[i] = e.target.value;
    setPeriodFluid(inputDrug);
    handleTotalFluid(e, i, "period");
  };
  const handleNoneFluid = (e, i) => {
    const inputDrug = [...noneFluid];
    inputDrug[i] = e.target.value;
    setNoneFluid(inputDrug);
    handleTotalFluid(e, i, "none");
  };
  const handleTotalFluid = (e, i, c) => {
    let dos;
    let freq;
    let per;
    let non;
    if (c == "dosage") {
      dos = e.target.value;
      freq = frequencyFluid[i];
      per = periodFluid[i];
      non = noneFluid[i];
    } else if (c == "frequency") {
      dos = dosageFluid[i];
      freq = e.target.value;
      per = periodFluid[i];
      non = noneFluid[i];
    } else if (c == "period") {
      dos = dosageFluid[i];
      freq = frequencyFluid[i];
      per = e.target.value;
      non = noneFluid[i];
    } else if (c == "none") {
      dos = dosageFluid[i];
      freq = frequencyFluid[i];
      per = periodFluid[i];
      non = e.target.value;
    }

    if (dos.length <= 0) dos = 1;
    if (freq.length <= 0) freq = 1;
    if (per.length <= 0) per = 1;
    if (non.length <= 0) non = 1;

    if (freq == "BD") freq = 2;
    else if (freq == "TDS") freq = 3;
    else if (freq == "QDS") freq = 4;
    else if (freq == "Nocte") freq = 1;
    else if (freq == "Mane") freq = 1;
    else if (freq == "WKLY") freq = 1;
    else if (freq == "AM") freq = 1;
    else if (freq == "PM") freq = 1;
    else if (freq == "DLY") freq = 1;
    else if (freq == "ALT DLY") freq = 1;
    else if (freq == "PRN") freq = 1;
    else if (freq == "Rectal") freq = 1;
    else if (freq == "Stat") freq = 1;
    if (non == "7") non = 1;
    else if (non == "52") non = 7;
    else if (non == "12") non = 30;

    if (
      dos.length <= 0 ||
      freq.length <= 0 ||
      per.length <= 0 ||
      non.length <= 0
    ) {
      console.log("none");
    } else {
      const newVal =
        parseInt(dos) * parseInt(per) * parseInt(freq) * parseInt(non);
      document.getElementById(`prescription_total_fluid${i}`).value =
        newVal;
        const inputDrug = [...totalFluid];
        inputDrug[i] = newVal;
        setTotalFluid(inputDrug);
    }


  };
  const handleCommentFluid = (e, i) => {
    const inputDrug = [...commentFluid];
    inputDrug[i] = e.target.value;
    setCommentFluid(inputDrug);
  };

  const getTagsFluid = (e, i) => {
    const inputDrug = [...tagsFluid];
    inputDrug[i] = e;
    setTagsFluid(inputDrug);
    console.log(i);
  };


  useEffect(() => {
    setCheck([]);
    let url = `${window.baseURL}pharmacy/drugmgt/doctor/drug/${pid}`;
    axios.get(url).then((res) => {
      // console.log('drugs ',res.data)
      setDiag(res.data);
    });
    
    url = `${window.baseURL}adminmgt/encounter/doctor/fluid/${pid}`;
    axios.get(url).then((res) => {
      console.log('fluids ',res.data)
      
      setFluidList(res.data);
    });

    url = `${window.baseURL}main/doctor/prescription/${patient_pid}`;
    axios.get(url).then((res) => {
      if (res.data.length != 0) {
        setTableData(res.data);
      }
    });
    
    url = `${window.baseURL}main/doctor/fluid/${patient_pid}`;
    axios.get(url).then((res) => {
      if (res.data.length != 0) {
        settableDataFluid(res.data);
      }
    });

    url = `${window.baseURL}main/doctor/diagnosis/${patient_pid}`;
    axios.get(url).then((res) => {
      if (res.data.length <= 0) {
        setIsSave(false);
      } else {
        setIsSave(true);
      }
    });
  }, []);

  const submitHandler = async (pres) => {
    if (window.confirm("Do you want to disengage this patient?")) {
      setSave(!save);
      setSaveInjection(!saveInjection);
      const d = new Date();
      const date = d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear();
      
      let data;
      let url;
      if (pres == "Injection") {
        if(tagsInjection.length <= 0){
          setError(true)
          return
        }
        data = {
          encounter: props.encounter_pid,
          patient: props.patient_pid,
          date: date,
          tags: tagsInjection,
          route: routeInjection,
          dosage: dosageInjection,
          frequency: frequencyInjection,
          period: periodInjection,
          none: noneInjection,
          total: totalInjection,
          comment: commentInjection,
          type: pres,
        };
        url = `main/doctor/prescription`;
      } else if (pres == "Drug") {
        if(tags.length <= 0){
          setError(true)
          return
        }
        data = {
          encounter: props.encounter_pid,
          patient: props.patient_pid,
          date: date,
          tags: tags,
          route: route,
          dosage: dosage,
          frequency: frequency,
          period: period,
          none: none,
          total: total,
          comment: comment,
          type: pres,
        };
        url = `main/doctor/prescription`;

      } else if (pres == "Fluid") {
        if(tagsFluid.length <= 0){
          setError(true)
          return
        }
        data = {
          encounter: props.encounter_pid,
          patient: props.patient_pid,
          date: date,
          tags: tagsFluid,
          route: routeFluid,
          dosage: dosageFluid,
          frequency: frequencyFluid,
          period: periodFluid,
          none: noneFluid,
          total: totalFluid,
          comment: commentFluid,
          type: pres,
        };
        url = `main/doctor/fluid`;

      }

      const result = await postToServer(url, { data });
      if (result.status) {
        // props.newStateDict(result.data);
        if (pres == "Fluid") settableDataFluid(result.data.fluid);
        else setTableData(result.data.prescription);
        
        setVal([]);
        setDrug([]);
        setRoute([]);
        setDosage([]);
        setFrequency([]);
        setPeriod([]);
        setNone([]);
        setTotal([]);
        setComment([]);

        setValInjection([]);
        setDrugInjection([]);
        setRouteInjection([]);
        setDosageInjection([]);
        setFrequencyInjection([]);
        setPeriodInjection([]);
        setNoneInjection([]);
        setTotalInjection([]);
        setCommentInjection([]);
        
        setValFluid([]);
        setDrugFluid([]);
        setRouteFluid([]);
        setDosageFluid([]);
        setFrequencyFluid([]);
        setPeriodFluid([]);
        setNoneFluid([]);
        setTotalFluid([]);
        setCommentFluid([]);

      }
    }
  };

  const deleteHandler = (detail_id, prescription_id) =>{
    if(window.confirm("Are you sure you want to delete this?")){
      const merge_id = patient_pid + '_' + detail_id + '_' + prescription_id
      const url = `${window.baseURL}main/doctor/prescription/${merge_id}`;
      axios
        .delete(
          url,
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
          }
        )
        .then((res) => {
          setTableData(res.data);
        });
    }
  }
  
  const fluidDeleteHandler = (detail_id, fluid_id) =>{
    if(window.confirm("Are you sure you want to delete this?")){
      const merge_id = patient_pid + '_' + detail_id + '_' + fluid_id
      const url = `${window.baseURL}main/doctor/fluid/${merge_id}`;
      axios
        .delete(
          url,
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
          }
        )
        .then((res) => {
          settableDataFluid(res.data);
        });
    }
  }



  return (
    <>
      <div className="text-center mb-5">
        <button
          className={`btn ml-2 btn-${!drugbtn ? btnColor : btnColorActive}`}
          onClick={drugBtnHandler}
        >
          Drugs
        </button>
        <button
          className={`btn ml-2 btn-${
            !injectionbtn ? btnColor : btnColorActive
          }`}
          onClick={injectionBtnHandler}
        >
          Injections
        </button>
        <button
          className={`btn ml-2 btn-${
            !fluidbtn ? btnColor : btnColorActive
          }`}
          onClick={fluidBtnHandler}
        >
          Fluid
        </button>
        {/* <button
          className={`btn ml-2 btn-${!fluidbtn ? btnColor : btnColorActive}`}
          onClick={fluidBtnHandler}
        >
          Fluids
        </button> */}
      </div>
        {error && <Error close={()=>setError(false)}>Add any Item to save</Error>}
      {drugbtn && (
        <div>
          <div className="row">
            <table>
              <thead>
                {head && (
                  <tr>
                    <th></th>
                    <th>Route</th>
                    <th>Dosage</th>
                    <th>Frequency</th>
                    <th>Period</th>
                    <th></th>
                    <th>Total</th>
                    <th>Comment</th>
                  </tr>
                )}
              </thead>
              <tbody className="table responsive table-sm">
                {val.map((data, i) => {
                  return (
                    <>
                      <tr>
                        <td width="20%">
                          <AutoPrescription
                            options={diag}
                            tag={(e) => getTags(e, i)}
                            removeTag={save}
                            type="Drug"
                          />
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => handleRoute(e, i)}
                          >
                            <option value=""></option>
                            <option value="IM">IM</option>
                            <option value="IV">IV</option>
                            <option value="Sc">Sc</option>
                            <option value="Oral">Oral</option>
                            <option value="Rectal">Rectal</option>
                            <option value="Sublingual">Sublingual</option>
                            <option value="Topical">Topical</option>
                            <option value="Transdermal">Transdermal</option>
                            <option value="Intrathecal">Intrathecal</option>
                            <option value="Virginal">Virginal</option>
                            <option value="Arterial">Arterial</option>
                            <option value="Intranasal">Intranasal</option>
                            <option value="Intradermal">Intradermal</option>
                          </select>
                        </td>
                        <td width="5%">
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "100px" }}
                            id={"prescription_dosage" + i}
                            onChange={(e) => handleDosage(e, i)}
                          />
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            id={"prescription_frequency" + i}
                            onChange={(e) => handleFrequency(e, i)}
                          >
                            <option value="Stat">Stat</option>
                            <option value="DLY">DLY</option>
                            <option value="ALT DLY">ALT DLY</option>
                            <option value="BD">BD</option>
                            <option value="Rectal">Rectal</option>
                            <option value="TDS">TDS</option>
                            <option value="QDS">QDS</option>
                            <option value="WKLY">WKLY</option>
                            <option value="AM">AM</option>
                            <option value="Mane">Mane</option>
                            <option value="PM">PM</option>
                            <option value="Nocte">Nocte</option>
                            <option value="PRN">PRN</option>
                          </select>
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => handlePeriod(e, i)}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                          </select>
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => handleNone(e, i)}
                          >
                            <option value="7">7</option>
                            <option value="52">52</option>
                            <option value="12">12</option>
                          </select>
                        </td>
                        <td width="10%">
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "100px" }}
                            id={"prescription_total" + i}
                            onChange={(e) => handleTotal(e, i)}
                          />
                        </td>
                        <td width="10%">
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "130px" }}
                            onChange={(e) => handleComment(e, i)}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div class="d-grid gap-2 d-md-block">
            <button class="btn btn-primary" type="button" onClick={handleAdd}>
              Add more
            </button>
            {isSave && (
              <button
                type="button"
                className="btn btn-primary mt-3 ml-2 mb-3"
                onClick={() => submitHandler("Drug")}
              >
                save
              </button>
            )}
          </div>

          {tableData && (
            <table className="table responsive">
              {tableData &&
                tableData.map((a, index) => {
                  return (
                    <>
                      <thead>
                        <tr style={{ fontSize: "13px" }}>
                          <th scope="col">S/N</th>
                          <th scope="col">
                            {a.date}
                            {" (" + a.encounter.pid + ")"}
                          </th>
                          {/* <th scope="col"></th> */}
                          <th>Dosage</th>
                          <th>Route</th>
                          <th>Times</th>
                          <th>Period</th>
                          <th>Total</th>
                          <th>Comment</th>
                          <th>Prescribed by</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {a.prescriptionDetails_set.map((d, ind) => {
                          return (
                            <tr key={ind}>
                              <th scope="row">{ind + 1}</th>
                              <td>
                                {d.prescription_details_tag_set.map((t) => {
                                  return (
                                    <>
                                      {t.drugtags.brandname}
                                      {" (" + t.drugtags.type + ")"} -
                                    </>
                                  );
                                })}
                              </td>
                              <td>{d.dosage}</td>
                              <td>{d.route}</td>
                              <td>{d.frequency}</td>
                              <td>
                                {d.period} / {d.none}
                              </td>
                              <td>{d.total}</td>
                              <td>{d.comment}</td>
                              <td>{d.prescribed_by_first_name} {d.prescribed_by_last_name}</td>
                              <td>
                              <button class="btn btn-danger btn-sm ml-2" onClick={()=>deleteHandler(d.id,a.id)}>Delete</button>
                            </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  );
                })}
            </table>
          )}
        </div>
      )}

      {injectionbtn && (
        <div>
          <div className="row">
            <table>
              <thead>
                {headInjection && (
                  <tr>
                    <th></th>
                    <th>Route</th>
                    <th>Dosage</th>
                    <th>Frequency</th>
                    <th>Period</th>
                    <th></th>
                    <th>Total</th>
                    <th>Comment</th>
                  </tr>
                )}
              </thead>
              <tbody className="table responsive table-sm">
                {valInjection.map((data, i) => {
                  return (
                    <>
                      <tr>
                        <td width="20%">
                          <AutoPrescription
                            options={diag}
                            tag={(e) => getTagsInjection(e, i)}
                            removeTag={saveInjection}
                            type="Injection"
                          />
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => handleRouteInjection(e, i)}
                          >
                            <option value=""></option>
                            <option value="IM">IM</option>
                            <option value="IV">IV</option>
                            <option value="Sc">Sc</option>
                            <option value="Oral">Oral</option>
                            <option value="Rectal">Rectal</option>
                            <option value="Sublingual">Sublingual</option>
                            <option value="Topical">Topical</option>
                            <option value="Transdermal">Transdermal</option>
                            <option value="Intrathecal">Intrathecal</option>
                            <option value="Virginal">Virginal</option>
                            <option value="Arterial">Arterial</option>
                            <option value="Intranasal">Intranasal</option>
                            <option value="Intradermal">Intradermal</option>
                          </select>
                        </td>
                        <td width="5%">
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "100px" }}
                            id={"prescription_dosage" + i}
                            onChange={(e) => handleDosageInjection(e, i)}
                          />
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            id={"prescription_frequency" + i}
                            onChange={(e) => handleFrequencyInjection(e, i)}
                          >
                            <option value="Stat">Stat</option>
                            <option value="DLY">DLY</option>
                            <option value="ALT DLY">ALT DLY</option>
                            <option value="BD">BD</option>
                            <option value="Rectal">Rectal</option>
                            <option value="TDS">TDS</option>
                            <option value="QDS">QDS</option>
                            <option value="WKLY">WKLY</option>
                            <option value="AM">AM</option>
                            <option value="Mane">Mane</option>
                            <option value="PM">PM</option>
                            <option value="Nocte">Nocte</option>
                            <option value="PRN">PRN</option>
                          </select>
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => handlePeriodInjection(e, i)}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                          </select>
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => handleNoneInjection(e, i)}
                          >
                            <option value="7">7</option>
                            <option value="52">52</option>
                            <option value="12">12</option>
                          </select>
                        </td>
                        <td width="10%">
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "100px" }}
                            id={"prescription_total_injection" + i}
                            onChange={(e) => handleTotalInjection(e, i)}
                          />
                        </td>
                        <td width="10%">
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "130px" }}
                            onChange={(e) => handleCommentInjection(e, i)}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div class="d-grid gap-2 d-md-block">
            <button
              class="btn btn-primary"
              type="button"
              onClick={handleAddInjection}
            >
              Add more
            </button>
            {isSave && (
              <button
                type="button"
                className="btn btn-primary mt-3 ml-2 mb-3"
                onClick={() => submitHandler("Injection")}
              >
                save
              </button>
            )}
          </div>

          {tableData && (
            <table className="table responsive">
              {tableData &&
                tableData.map((a, index) => {
                  return (
                    <>
                      <thead>
                        <tr style={{ fontSize: "13px" }}>
                          <th scope="col">S/N</th>
                          <th scope="col">
                            {a.date}
                            {" (" + a.encounter.pid + ")"}
                          </th>
                          {/* <th scope="col"></th> */}
                          <th scope="col">Dosage</th>
                          <th scope="col">Route</th>
                          <th scope="col">Times</th>
                          <th scope="col">Period</th>
                          <th scope="col">Total</th>
                          <th scope="col">Comment</th>
                          <th scope="col">Prescribed by</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {a.prescriptionDetails_set.map((d, ind) => {
                          return (
                            <tr key={ind}>
                              <th scope="row">{ind + 1}</th>
                              <td>
                                {d.prescription_details_tag_set.map((t) => {
                                  return (
                                    <>
                                      {t.drugtags.brandname}
                                      {" (" + t.drugtags.type + ")"} -
                                    </>
                                  );
                                })}
                              </td>
                              <td>{d.dosage}</td>
                              <td>{d.route}</td>
                              <td>{d.frequency}</td>
                              <td>
                                {d.period} / {d.none}
                              </td>
                              <td>{d.total}</td>
                              <td>{d.comment}</td>
                              <td>{d.prescribed_by_first_name} {d.prescribed_by_last_name}</td>
                              <td>
                              <button class="btn btn-danger btn-sm ml-2" onClick={()=>deleteHandler(d.id,a.id)}>Delete</button>
                            </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  );
                })}
            </table>
          )}
        </div>
      )}

      {fluidbtn && (
        <div>
          <div className="row">
            <table>
              <thead>
                {headFluid && (
                  <tr>
                    <th></th>
                    <th>Route</th>
                    <th>Dosage</th>
                    <th>Frequency</th>
                    <th>Period</th>
                    <th></th>
                    <th>Total</th>
                    <th>Comment</th>
                  </tr>
                )}
              </thead>
              <tbody className="table responsive table-sm">
                {valFluid.map((data, i) => {
                  return (
                    <>
                      <tr>
                        <td width="20%">
                          <AutoFluid
                            options={fluidList}
                            tag={(e) => getTagsFluid(e, i)}
                            removeTag={save}
                            type="Fluid"
                          />
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => handleRouteFluid(e, i)}
                          >
                            <option value=""></option>
                            <option value="IM">IM</option>
                            <option value="IV">IV</option>
                            <option value="Sc">Sc</option>
                            <option value="Oral">Oral</option>
                            <option value="Rectal">Rectal</option>
                            <option value="Sublingual">Sublingual</option>
                            <option value="Topical">Topical</option>
                            <option value="Transdermal">Transdermal</option>
                            <option value="Intrathecal">Intrathecal</option>
                            <option value="Virginal">Virginal</option>
                            <option value="Arterial">Arterial</option>
                            <option value="Intranasal">Intranasal</option>
                            <option value="Intradermal">Intradermal</option>
                          </select>
                        </td>
                        <td width="5%">
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "100px" }}
                            id={"prescription_dosage" + i}
                            onChange={(e) => handleDosageFluid(e, i)}
                          />
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            id={"prescription_frequency" + i}
                            onChange={(e) => handleFrequencyFluid(e, i)}
                          >
                            <option value=""></option>
                            <option value="Stat">Stat</option>
                            <option value="DLY">DLY</option>
                            <option value="ALT DLY">ALT DLY</option>
                            <option value="BD">BD</option>
                            <option value="Rectal">Rectal</option>
                            <option value="TDS">TDS</option>
                            <option value="QDS">QDS</option>
                            <option value="WKLY">WKLY</option>
                            <option value="AM">AM</option>
                            <option value="Mane">Mane</option>
                            <option value="PM">PM</option>
                            <option value="Nocte">Nocte</option>
                            <option value="PRN">PRN</option>
                          </select>
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => handlePeriodFluid(e, i)}
                          >
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                          </select>
                        </td>
                        <td width="5%">
                          <select
                            className="form-select"
                            style={{ width: "100px" }}
                            onChange={(e) => handleNoneFluid(e, i)}
                          >
                            <option value=""></option>
                            <option value="7">7</option>
                            <option value="52">52</option>
                            <option value="12">12</option>
                          </select>
                        </td>
                        <td width="10%">
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "100px" }}
                            id={"prescription_total_fluid" + i}
                            onChange={(e) => handleTotalFluid(e, i)}
                          />
                        </td>
                        <td width="10%">
                          <input
                            type="text"
                            className="form-control"
                            style={{ width: "130px" }}
                            onChange={(e) => handleCommentFluid(e, i)}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div class="d-grid gap-2 d-md-block">
            <button class="btn btn-primary" type="button" onClick={handleAddFluid}>
              Add more
            </button>
            {isSave && (
              <button
                type="button"
                className="btn btn-primary mt-3 ml-2 mb-3"
                onClick={() => submitHandler("Fluid")}
              >
                save
              </button>
            )}
          </div>

          {tableDataFluid && (
            <table className="table responsive">
              {tableDataFluid &&
                tableDataFluid.map((a, index) => {
                  return (
                    <>
                      <thead>
                        <tr style={{ fontSize: "13px" }}>
                          <th scope="col">S/N</th>
                          <th scope="col">
                            {a.date}
                            {" (" + a.encounter.pid + ")"}
                          </th>
                          {/* <th scope="col"></th> */}
                          <th scope="col">Dosage</th>
                          <th scope="col">Route</th>
                          <th scope="col">Times</th>
                          <th scope="col">Period</th>
                          <th scope="col">Total</th>
                          <th scope="col">Comment</th>
                          <th scope="col">Prescribed by</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {a.doctor_fluid.map((d, ind) => {
                          return (
                            <tr key={ind}>
                              <th scope="row">{ind + 1}</th>
                              <td>
                                {d.fluid.fluid}
                              </td>
                              <td>{d.dosage}</td>
                              <td>{d.route}</td>
                              <td>{d.frequency}</td>
                              <td>
                                {d.period} / {d.none}
                              </td>
                              <td>{d.total}</td>
                              <td>{d.comment}</td>
                              <td>{d.prescribed_by_first_name} {d.prescribed_by_last_name}</td>
                              <td>
                              <button class="btn btn-danger btn-sm ml-2" onClick={()=>fluidDeleteHandler(d.id,a.id)}>Delete</button>
                            </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  );
                })}
            </table>
          )}
        </div>
      )}
    </>
  );
};

export default Prescription;
