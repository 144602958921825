import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import {postToServer, getFromServer, putToServer} from "../../global/requests";

const AddImmunization = (props) => {
  const department = useRef();
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedConsult, setIsCheckedConsult] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [permissionStates, setPermissionStates] = useState({});
  const [imunForEdit, setImunForEdit] = useState({});

  const handleOnChangeCheck = () => {
    setIsChecked((current) => !current);
  };

  const handleOnChangePermission = (event) => {
    setPermissionStates({...permissionStates, [event.target.id]: event.target.checked});
  };

  const selectAllHandler = () => {
    const tempPermStates = {};
    permissions.forEach(perm => {
      tempPermStates[perm.key] = true;
    });
    setPermissionStates(tempPermStates);
  };

  const clearAllHandler = () => {
    const tempPermStates = {};
    permissions.forEach(perm => {
      tempPermStates[perm.key] = false;
    });
    setPermissionStates(tempPermStates);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      department: department.current.value,
      permissions: permissionStates,
      encounterischecked: isChecked,
      isConsulation: isCheckedConsult,
      isAntenatal: false,
      isImmunization: true,
    };

    let result = {};
    if(imunForEdit && imunForEdit.useraccess && imunForEdit.useraccess.id) {
      result = await putToServer(`usermgt/useraccess/department-info/${imunForEdit.useraccess.id}`, { data });
    } else {
      result = await postToServer(`usermgt/useraccess/add/department`, { data });
    }
    if(result.status) {
      props.close();
      props.success(true);
      console.log(result.data);
      props.users(result.data);
    }
  };

  // will run only first load.
  useEffect(() => {
    initialsTasks();
    if(props.imunEditId != null && props.imunEditId > 0) loadImunForEdit(props.imunEditId);
  }, []);

  const initialsTasks = async () => {
    const result = await getFromServer('usermgt/permission-list');
    if (result.status) {
      const permStates = {};
      result.data.forEach(perm => {
        permStates[perm['key']] = false;
      });
      setPermissionStates(permStates);
      setPermissions(result.data);
    }
  }
  const loadImunForEdit = async (id) => {
    const result = await getFromServer(`usermgt/useraccess/department-info/${id}`);
    if(result.status) {
      setImunForEdit(result.data);
      department.current.value = result.data.useraccess.department;
    }
  }
  useEffect(() => {
    if(permissions && permissions.length > 0 && imunForEdit && imunForEdit.permissions) {
      const userPerms = imunForEdit.permissions;
      const tempPermStates = {};
      permissions.forEach(perm => {
        tempPermStates[perm.key] = userPerms[perm.key] ? true : false;
      });
      setPermissionStates(tempPermStates);
    }
  }, [permissions, imunForEdit]);

  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Immunization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-5">
                  <div className="mb-3">
                    <label htmlFor="department" className="col-form-label">
                      Immunization Department Name:
                    </label>
                    <input
                      type="text"
                      name="department"
                      className="form-control"
                      required
                      ref={department}
                    />
                  </div>
                  {/* <div className="ml-4">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      checked={isChecked}
                      onChange={handleOnChangeCheck}
                    />
                    <label class="form" htmlFor="flexCheckChecked">
                      Is Clinical Department?
                    </label>
                  </div>
                  <div className="ml-4">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked2"
                      checked={isCheckedConsult}
                      onChange={() => {
                        setIsCheckedConsult(!isCheckedConsult);
                      }}
                    />
                    <label class="form" htmlFor="flexCheckChecked2">
                      Is Consultation Department?
                    </label>
                  </div> */}
                </div>
                <div className="col-md-6 ml-md-5 ml-4">
                  <label className="col-form-label">
                    <b>Permissions</b>
                  </label>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm mr-2 ml-4"
                    onClick={selectAllHandler}
                  >
                    Select all
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={clearAllHandler}
                  >
                    Clear all
                  </button>
                  {permissions.map(permission => 
                    <div>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id={permission.key}
                        onChange={handleOnChangePermission}
                        checked={permissionStates[permission.key]}
                      />
                      <label class="form" htmlFor={permission.key}>
                        {permission.title}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddImmunization;
