import React from 'react';
import {Navigate} from "react-router-dom";
import {getUserAccessToken, getPermission} from "../global/auth";
import AccessDenied from '../ui/AccessDenied';

const Protect = ({element, permission = null}) => {
  const token = getUserAccessToken();
  if(token) {
    return ((permission && getPermission(permission)) ? element : <AccessDenied />);
  } else return <Navigate to="/login" />
  return (token ? element : <Navigate to="/login" />)
}

export default Protect