import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getFromServer } from "../../global/requests";
import { strMsgToHtmlMsg } from "../../global/common";

const PatientSmsDetail = (props) => {
  const [receivers, setReceivers] = useState([]);
  const [message, setMessage] = useState("");
  const [msgTypeStr, setMsgTypeStr] = useState("");
  const [msgDate, setMsgDate] = useState("");

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`sms/patient-sms/${props.id}/detail`);
    if (result.status) {
      setReceivers(result.data.receivers);
      setMessage(result.data.message);
      setMsgTypeStr(result.data.type_str);
      setMsgDate(result.data.date);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.close}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Patient Sms Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <h5>Message Type | Date</h5>
            <p>{msgTypeStr} | {msgDate}</p>
            <h5>Receivers(Patients)</h5>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone No.</th>
                </tr>
              </thead>
              <tbody>
                {receivers && receivers.map(receiver =>
                  <tr>
                    <th scope="row">1</th>
                    <td>{receiver[0]} {receiver[1]}</td>
                    <td>{receiver[2]}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <h5>Message</h5>
            <p dangerouslySetInnerHTML={{__html: strMsgToHtmlMsg(message)}} style={{border: "2px solid gray", padding: "10px", minHeight: "120px"}}></p>
          </div>
          {/* <div className="col-md-6"></div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PatientSmsDetail;
