import React from "react";
import { currDateYYYYMMDD } from "../../global/common";

const DateRangeWidget = ({dateFrom, dateTo, setDateFrom, setDateTo, name, size=3}) => {
  const changeDateFrom = (e) => {
    setDateFrom(e.target.value);
    if (dateTo == "") setDateTo(currDateYYYYMMDD());
    if (e.target.value == "") setDateTo("");
  };
  const changeDateTo = (e) => {
    setDateTo(e.target.value);
    if (dateFrom == "") setDateFrom(currDateYYYYMMDD());
    if (e.target.value == "") setDateFrom("");
  };
  return (
    <>
      <div className={"col-" + size}>
        <label htmlFor="date_from">{name} Date From</label>
        <input
          type="date"
          className="form-control"
          placeholder="Date From"
          id="date_from"
          value={dateFrom}
          onChange={changeDateFrom}
        />
      </div>
      <div className={"col-" + size}>
        <label htmlFor="date_to">{name} Date To</label>
        <input
          type="date"
          className="form-control"
          placeholder="Date From"
          id="date_to"
          value={dateTo}
          onChange={changeDateTo}
        />
      </div>
    </>
  );
};

export default DateRangeWidget;
