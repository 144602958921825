import React, { useState, useEffect } from "react";
import { parsePath, useParams } from "react-router-dom";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddPLan from "../../modals/Billing/AddPlan";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { getFromServer, deleteFromServer } from "../../global/requests";

const Plans = () => {
  const para = useParams();
  //   const [isemptyArray, setIsEmptyArray] = useState(false);
  const [plan, setPlan] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateCompanyHandler = (list) => setPlan(list);
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/plan/${para.id}`);
    if (result.status) setPlan(result.data);
  };

  const deleteHandler = async (id) => {
    const tempid = String(para.id) + "_" + String(id);
    const result = await deleteFromServer(`billing/plan/${tempid}`);
    if(result.status) setPlan(result.data);
    setSuccess(false);
  };

  return (
    <>
      <MainContent>
        {success && <Success close={()=>setSuccess(false)}>Plan has been added Successfully</Success>}
        <div className="section-body">
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add New Plan
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <Link
                to="/company-management"
                className="mt-2 float-end"
                // style={{ paddingBottom: "5px" }}
              >
                Go Back
              </Link>
            </div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Plan Name</th>
                  <th scope="col">Bill Type</th>
                  <th scope="col">Bill Group</th>
                  <th scope="col">Pricing</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {plan &&
                  plan.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td>{a.billtype}</td>
                        <td>{a.billgroup}</td>
                        <td>
                          <Link
                            to={
                              "/company-management/plan/price/" +
                              a.company +
                              "-" +
                              a.id
                            }
                            className="btn btn-sm btn-primary"
                            style={{ paddingBottom: "5px" }}
                          >
                            Plan pricing
                          </Link>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddPLan
          show={show}
          close={handleClose}
          success={successHandler}
          addplan={updateCompanyHandler}
          id={para.id}
        />
      )}
    </>
  );
};

export default Plans;
