import React, { useRef, useEffect, useState } from "react";
import MainContent from "../../ui/MainContent";
import { sendWsChat, renderChatList } from "../../global/ws";
import userPic from "../../../assets/img/user.png";
import { uploadFileForm } from "../../global/requests";
import FileProgressModal from "../../modals/Apps/FileProgressModal";

const ChatRoom = ({chatLoading, wsConnected, setNewChat, chatUser}) => {
  // variables
  const [attachment, setAttachment] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileData, setFileData] = useState({percent: 0, name: "File"});
  const msgInput = useRef();
  const imgInput = useRef();
  const fileInput = useRef();
  // functions
  const handleSendBtn = () => {
    const text = msgInput.current.value.trim();
    if (text != '') {
      sendWsChat(chatUser.id, 0, text);
      msgInput.current.value = "";
    }
    msgInput.current.focus();
  }
  const handleMsgByEnter = (e) => {
    const x = e.which || e.keycode;
		if (x == 13 && !e.shiftKey) {
      e.preventDefault();
      const text = msgInput.current.value.trim();
			msgInput.current.value = "";
      if (text != '') {
			  sendWsChat(chatUser.id, 0, text);
      }
			return false;
		}
		else return true;
  }
  // chat loading.
  useEffect(() => {
    renderChatList();
    setNewChat(0);
    window.newChat = 0;
  }, [chatLoading]);
  const hideFileProgress = () =>{
    setFileData({...fileData, name: "File", percent: 0})
    setFileUploading(false);
  }
  const uploadFileToServer = (e) => {
    setAttachment(false);
    if (e.target.files && e.target.files[0]) {
      setFileData({...fileData, name: e.target.files[0].name, percent: 0})
      setFileUploading(true);

      const formdata = new FormData();
			formdata.append("file", e.target.files[0]);

      const progressHandler = (event) => {
				// console.log(event);
				const percent = (event.loaded / event.total) * 100;
        setFileData({...fileData, name: e.target.files[0].name, percent: Math.round(percent)})
			}

      const completeHandler = (event) => {
				hideFileProgress();
				const res = JSON.parse(event.currentTarget.response);
				// console.log(res.data.file_path);
        e.target.value = '';
				sendWsChat(chatUser.id, parseInt(e.target.id), res.data.file_path);
			}

      const errorHandler = (event) => {
        hideFileProgress();
        e.target.value = '';
				console.log(event);
			}

			const abortHandler = (event) => {
        hideFileProgress();
        e.target.value = '';
				console.log(event);
			}
      
      uploadFileForm("communication/file-upload", formdata, progressHandler, completeHandler, errorHandler, abortHandler);
    }
  }

  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <div className="card">
                <div className="body">
                  <div id="plist" className="people-list">
                    <div className="chat-search">
                      <input type="text" className="form-control" placeholder="Search..." />
                    </div>
                    <div className="m-b-20">
                      <div id="chat-scroll" style={{minHeight: '82vh'}}>
                        <ul className="chat-list list-unstyled m-b-0" id="chat-user-cont"></ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9" style={chatUser && chatUser.id ? {} : {visibility: 'hidden'}}>
              <div className="card">
                <div className="chat">
                  <div className="chat-header clearfix">
                    <img src={chatUser && chatUser.image ? `${window.baseURLuploaded}/images/${chatUser.image}` : userPic} alt="avatar" />
                    <div className="chat-about">
                      <div className="chat-with">{chatUser && chatUser.first_name} {chatUser && chatUser.last_name}</div>
                      <div className="chat-num-messages">2 new messages</div>
                    </div>
                    <button class="btn btn-icon btn-sm btn-danger" onClick={() => window.changeChatUser(0)} style={{float: 'right'}}>
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="chat-box" id="mychatbox">
                  <div id="chat-cont"></div>
                  {!chatLoading && wsConnected && chatUser && chatUser.id &&
                  <div className="card-footer chat-form">
                    <textarea 
                      ref={msgInput} className="form-control" placeholder="Type a message"
                      rows="3" onKeyDown={handleMsgByEnter}
                    ></textarea>
                    <div style={{display: "none"}}>
                      <input type="file" accept="image/*" id="1" ref={imgInput} onChange={uploadFileToServer} />
                      <input type="file" ref={fileInput} id="2" onChange={uploadFileToServer} />
                    </div>
                    <div className="attach-box" style={{display: attachment ? 'flex' : 'none'}}>
                      <button className="btn btn-icon btn-dark" onClick={() => imgInput.current.click()}>
                        <i className="fas fa-image"></i>
                      </button>
                      <button className="btn btn-icon btn-dark" onClick={() => fileInput.current.click()}>
                        <i className="fas fa-file-alt"></i>
                      </button>
                    </div>
                    <button className="btn attach-icon" onClick={() => setAttachment(!attachment)}>
                      <i className="fas fa-paperclip"></i>
                    </button>
                    <button className="btn btn-primary" onClick={handleSendBtn}>
                      <i className="far fa-paper-plane"></i>
                    </button>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      {fileUploading && <FileProgressModal show={fileUploading} fileData={fileData} />}
    </>
  );
};

export default ChatRoom;
