import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const Edit = (props) => {
  const type = useRef();
  const [name, setName] = useState(props.data.name);
  const [quantity, setQuantity] = useState(props.data.quantity);
  const [reorder, setReorder] = useState(props.data.reorder);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      id: props.data.id,
      name: name,
      quantity: quantity,
      reorder: reorder,
      model: props.data.model,
    };
    console.log('=>',data)
    const result = await postToServer(`pharmacy/drugmgt/dispensary-inventory`, { data });
    if(result.status) {
      props.close();
      props.success(true);
      props.effect()
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                {/* <div className="col-12">
                  <div className="mb-3">
                    <label className="col-form-label">Item Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      required
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="mb-3">
                    <label className="col-form-label">Quantity</label>
                    <input
                      type="text"
                      className="form-control"
                      value={quantity}
                      required
                      onChange={(event) => setQuantity(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="col-form-label">Reorder Level</label>
                    <input
                      type="text"
                      className="form-control"
                      value={reorder}
                      required
                      onChange={(event) => setReorder(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Edit;
