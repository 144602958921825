import React, { useState, useEffect, useRef } from "react";
// import "../../../../assets/css/my.css";

const AutProcedure = (props) => {
  const dataComing = props.options;
  const emptyTagList = props.removeTag;
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState();
  const [filtered, setFiltered] = useState();
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setOptions(dataComing);
    setFiltered(dataComing);
    setTags([]);
    console.log(dataComing);
  }, [emptyTagList]);

  const searchHandler = (e) => {
    setSearch(e);
    if (e.length > 2) {
      const check = options.filter((s) => {
        return String(s.procedure).toLowerCase().includes(e.toLowerCase());
      });
      setFiltered(check);
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };

  const changeHandler = (value) => {
    setTags([...tags, value]);
    setSearch("");
    setDisplay(false);
    props.tag([...tags, value]);
  };

  const removeTags = (id) => {
    const newState = tags.filter((t) => {
      return t.id != id;
    });
    setTags(newState);
    props.tag(newState);
  };
  return (
    <>
      <div className="App1">
        <div className="auto-container1">
          <div className="flex-container1 flex-column1 pos-rel1 prescription">
            <input
              id="auto"
              onChange={(event) => searchHandler(event.target.value)}
              placeholder="Type..."
              className="form-control"
              value={search}
              style={{ width: "350px" }}
            ></input>
            <div className="mt-2 d-grid gap-2 d-md-block">
              {tags &&
                tags.map((t, i) => {
                  return (
                    <button
                      key={i}
                      type="button"
                      className="btn btn-sm btn-primary ml-2 mb-2"
                    >
                      {t.procedure}{" "}
                      <span
                        className="closebox"
                        onClick={() => removeTags(t.id)}
                      >
                        X
                      </span>
                    </button>
                  );
                })}
            </div>
            {display && (
              <div className="autoContainer_p1">
                {filtered.map((v, i) => {
                  return (
                      <div
                        onClick={() => changeHandler(v)}
                        className="option1"
                        key={i}
                      >
                        <span>
                          {"=> "}
                          {v.procedure}
                          <span class={v.auth_status == 'Authorized' ? "badge badge-success float-end" : "badge badge-danger float-end"}>{v.auth_status}</span>
                        </span>
                      </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutProcedure;
