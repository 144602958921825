import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddTestReportingParameter from "../../modals/Laboratory/AddTestReportingParameter";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, deleteFromServer } from "../../global/requests";

const TestReportingParameter = () => {
  const [testParameter, setTestParameter] = useState();
  const [search, setSearch] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const closehandler = () => setSuccess(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateTestCategoryHandler = (list) => {
    setTestParameter(list);
    setSearch(list);
  };
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`diagnosis/rad-test-reporting-parameter`);
    if(result.status) {
      setTestParameter(result.data);
      setSearch(result.data);
    }
  }

  if (success) {
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const result = await deleteFromServer(`diagnosis/rad-test-reporting-parameter/${id}`);
      if(result.status) {
        setTestParameter(result.data);
        setSearch(result.data);
      }
      setSuccess(false);
    }
  };

  const searchHandler = (value) => {
    const newValue = search.filter((v) => {
      return v.testname.testname
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase());
    });
    setTestParameter(newValue);
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={closehandler}>
            Test Reporting Parameter has been added Successfully
          </Success>
        )}
        <div className="section-body">
          <h2 className="mb-4">Radiology Test Reporting Parameter</h2>
          <div className="row">
            <div className="col-md-8">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add New Parameter
              </button>
            </div>
            <div className="col-md-4 mt-2 mb-2 mt-md-0 text-end">
              <input
                type="text"
                placeholder="Type to search"
                onChange={(e) => searchHandler(e.target.value)}
                className="form-control"
              />
            </div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">#</th>
                  <th scope="col">Test Name</th>
                  <th scope="col">Lower Limit</th>
                  <th scope="col">Upper Limit</th>
                  <th scope="col">Unit of Measurement</th>
                  <th scope="col">Value Type</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {testParameter &&
                  testParameter.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.testname.testname}</td>
                        <td>{a.lowerlimit}</td>
                        <td>{a.upperlimit}</td>
                        <td>{a.unit}</td>
                        <td>{a.value}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddTestReportingParameter
          show={show}
          close={handleClose}
          success={successHandler}
          update={updateTestCategoryHandler}
          rad={true}
        />
      )}
    </>
  );
};

export default TestReportingParameter;
