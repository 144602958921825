import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
import MainContent from "../../ui/MainContent";
import OutpatientAppoint from "./OutpatientAppoint";
import { getFromServer } from "../../global/requests";

const Outpatient = () => {
  const [getDepartment, setGetDepartment] = useState();
  const [isSearched, setIsSearched] = useState(false);
  const searchText = useRef();

  const department = useRef();
  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/useraccess-clinical`);
    if(result.status) {
      console.log(result);
      setGetDepartment(result.data);
      setIsSearched(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault();
    setIsSearched(true)
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          {!isSearched && (
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h4>Select Clinic/Department</h4>
                    {getDepartment && (
                      <form
                        method="GET"
                        onSubmit={submitHandler}
                        className="card-header-form"
                        style={{ display: "flex" }}
                      >
                        <select className="form-select" ref={department}>
                          {getDepartment &&
                            getDepartment.map((u) => {
                              return (
                                <option
                                  key={u.id}
                                  id={u.id}
                                  value={u.department}
                                >
                                  {u.department}
                                </option>
                              );
                            })}
                        </select>
                        <button
                          type="submit"
                          style={{ color: "white" }}
                          className="btn btn-info ml-3"
                        >
                          Search
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>{" "}
        {isSearched && <OutpatientAppoint dept={department?.current?.value} back={()=>setIsSearched(false)}/>}
      </MainContent>
    </>
  );
};

export default Outpatient;
