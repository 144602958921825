import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer } from "../global/requests";

const ShowDetail = (props) => {
  const encounter_pid = props.encounter_pid;
  const [list, setList] = useState();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/doctor-page/detail/${encounter_pid}`);
    if (result.status) {
      setList(result.data);
      console.log(result.data);
    }
  };

  return (
    <>
      {list && (
        <table className="table table-borderless table-sm">
          <tbody>
            <tr className="mt-5">
              <th width="70px">Complains</th>
              <td colSpan="6">
                {list.complain.length > 0 ? (
                  list.complain.map((c) => {
                    return (
                      <>
                        {c.ComplainDetails.map((cd) => {
                          return (
                            <>
                              {cd.ComplainDetailsTag.map((d) => {
                                return <>{d.complain + " , "}</>;
                              })}
                            </>
                          );
                        })}
                      </>
                    );
                  })
                ) : (
                  <>Not Found</>
                )}
              </td>
            </tr>
            <tr className="mt-5">
              <th width="70px">Diagnosis</th>
              <td colSpan="6">
                {list.diagnosis.length > 0 ? (
                  list.diagnosis.map((diag) => {
                    return (
                      <>
                        {diag.diagnosisDetail.map((d) => {
                          return <>{d.title + " , "}</>;
                        })}
                      </>
                    );
                  })
                ) : (
                  <>Not Found</>
                )}
              </td>
            </tr>
            <tr className="mt-5">
              <th width="70px">Fluids</th>
              <td colSpan="6">
                {list.fluid.length > 0 ? (
                  list.fluid.map((fluid) => {
                    return (
                      <>
                        {fluid.doctor_fluid.map((d) => {
                          return <>{d.fluid.fluid + " , "}</>;
                        })}
                      </>
                    );
                  })
                ) : (
                  <>Not Found</>
                )}
              </td>
            </tr>
            <tr className="mt-5">
              <th width="70px">Drugs</th>
              <td colSpan="6">
                {list.drug.length > 0 ? (
                  list.drug.map((drug) => {
                    return (
                      <>
                        {drug.prescriptionDetails_set.map((d) => {
                          return (
                            <>
                              {d.prescription_details_tag_set.map((t) => {
                                return (
                                  <>
                                    {t.drugtags.brandname}
                                    {" (" + t.drugtags.type + ") , "}
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    );
                  })
                ) : (
                  <>Not Found</>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default ShowDetail;
