export const suggestions = 
[
    {
        "id": "1002",
        "text": "Harmful effects of or exposure to noxious substances, chiefly nonmedicinal as to source, not elsewhere classified"
    },
    {
        "id": "1006",
        "text": "Sleep-wake disorders"
    },
    {
        "id": "1007",
        "text": "Structural anomalies of chromosome Y"
    },
    {
        "id": "1009",
        "text": "Fused fingers"
    },
    {
        "id": "100B",
        "text": "Balanced rearrangements or structural rearrangements"
    },
    {
        "id": "100C",
        "text": "Conidiobolomycosis"
    },
    {
        "id": "100D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "100E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "100F",
        "text": "Korsakoff syndrome"
    },
    {
        "id": "100G",
        "text": "Renal tubulopathy - encephalopathy - liver failure"
    },
    {
        "id": "100H",
        "text": "Autosomal recessive sideroblastic anaemia, pyridoxine-refractory"
    },
    {
        "id": "100I",
        "text": "16p11.2 deletion"
    },
    {
        "id": "100J",
        "text": "20p duplication"
    },
    {
        "id": "100K",
        "text": "Kleefstra syndrome"
    },
    {
        "id": "100L",
        "text": "3q23 deletion"
    },
    {
        "id": "100M",
        "text": "Glycogen storage disease due to glycogenin deficiency"
    },
    {
        "id": "100N",
        "text": "Spondyloepiphyseal dysplasia, Maroteaux type"
    },
    {
        "id": "100O",
        "text": "Pulmonary capillaritis"
    },
    {
        "id": "100P",
        "text": "L1 syndrome"
    },
    {
        "id": "100Q",
        "text": "Spinocerebellar ataxia type 32"
    },
    {
        "id": "100R",
        "text": "Spinocerebellar ataxia type 35"
    },
    {
        "id": "100S",
        "text": "Spinocerebellar ataxia type 36"
    },
    {
        "id": "100T",
        "text": "Bullous diffuse cutaneous mastocytosis"
    },
    {
        "id": "100U",
        "text": "Annular epidermolytic ichthyosis"
    },
    {
        "id": "100V",
        "text": "8q21.11 deletion"
    },
    {
        "id": "100W",
        "text": "Pre-Descemet corneal dystrophy"
    },
    {
        "id": "100Y",
        "text": "1p21.3 deletion"
    },
    {
        "id": "100Z",
        "text": "Preaxial polydactyly of fingers"
    },
    {
        "id": "1011",
        "text": "Glycogen storage disease due to acid maltase deficiency, juvenile onset"
    },
    {
        "id": "1012",
        "text": "Glycogen storage disease due to acid maltase deficiency, adult onset"
    },
    {
        "id": "1013",
        "text": "SCID - [Severe combined immunodeficiency] due to DNA-PKcs deficiency"
    },
    {
        "id": "1014",
        "text": "Combined immunodeficiency due to ORAI1 deficiency"
    },
    {
        "id": "1015",
        "text": "Combined immunodeficiency due to STIM1 deficiency"
    },
    {
        "id": "1016",
        "text": "Bacterial myelitis"
    },
    {
        "id": "1017",
        "text": "Fungal myelitis"
    },
    {
        "id": "1018",
        "text": "Viral myelitis"
    },
    {
        "id": "1019",
        "text": "Viral encephalitis not elsewhere classified"
    },
    {
        "id": "101A",
        "text": "Syndromes with holoprosencephaly as a major feature"
    },
    {
        "id": "101B",
        "text": "Spina bifida aperta"
    },
    {
        "id": "101C",
        "text": "Occult spinal dysraphism"
    },
    {
        "id": "101D",
        "text": "Laryngeal hypoplasia"
    },
    {
        "id": "101E",
        "text": "Mosaicism, 45, X, 46, XX or XY"
    },
    {
        "id": "101F",
        "text": "Chondromalacia patellae"
    },
    {
        "id": "101H",
        "text": "Male with 46,XX karyotype"
    },
    {
        "id": "101I",
        "text": "Anaesthesia of skin"
    },
    {
        "id": "101J",
        "text": "Craniofacial dysostoses"
    },
    {
        "id": "101K",
        "text": "Primary syringomyelia or hydromyelia"
    },
    {
        "id": "101M",
        "text": "Dermoid cyst"
    },
    {
        "id": "101N",
        "text": "Yunis-Varon disease"
    },
    {
        "id": "101O",
        "text": "Yunis-Varon disease"
    },
    {
        "id": "101P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "101Q",
        "text": "Fronto-otopalatodigital syndromes"
    },
    {
        "id": "101R",
        "text": "Constriction rings"
    },
    {
        "id": "101S",
        "text": "Amoebiasis of penis"
    },
    {
        "id": "101T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "101U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "101W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "101X",
        "text": "Hypothyroidism due to medicaments or other exogenous substances"
    },
    {
        "id": "101Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "101Z",
        "text": "Diabetes mellitus, type unspecified"
    },
    {
        "id": "1020",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1022",
        "text": "Acquired pyridoxine deficiency anaemia"
    },
    {
        "id": "1023",
        "text": "Acquired riboflavin deficiency anaemia"
    },
    {
        "id": "1024",
        "text": "Acquired thiamine deficiency anaemia"
    },
    {
        "id": "1025",
        "text": "Nutritional or toxic disorders of the nervous system"
    },
    {
        "id": "1026",
        "text": "Nutritional disorders"
    },
    {
        "id": "1027",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1028",
        "text": "Other anaemias"
    },
    {
        "id": "102A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "102B",
        "text": "Acquired other vitamin B deficiency anaemia"
    },
    {
        "id": "102C",
        "text": "Mild alpha thalassaemia diseases"
    },
    {
        "id": "102D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "102E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "102F",
        "text": "Primary biliary cholangitis"
    },
    {
        "id": "102I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "102K",
        "text": "Large B-cell lymphoma arising in HHV8-associated multicentric Castleman disease"
    },
    {
        "id": "102L",
        "text": "Castleman disease"
    },
    {
        "id": "102P",
        "text": "Autosomal dominant tubulointerstitial disease Type 2"
    },
    {
        "id": "102Q",
        "text": "Structural developmental anomalies of ear causing hearing impairment"
    },
    {
        "id": "102R",
        "text": "Otocephaly"
    },
    {
        "id": "102S",
        "text": "Accessory auricle"
    },
    {
        "id": "102T",
        "text": "Synotia"
    },
    {
        "id": "102U",
        "text": "Oculo-dento-digital dysplasia"
    },
    {
        "id": "102V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "102Y",
        "text": "Anal polyp"
    },
    {
        "id": "102Z",
        "text": "Haemorrhoids"
    },
    {
        "id": "1030",
        "text": "Infections of the anal region"
    },
    {
        "id": "1031",
        "text": "Neoplasms of uncertain behaviour, except of lymphoid, haematopoietic, central nervous system or related tissues"
    },
    {
        "id": "1032",
        "text": "Certain specified diseases of appendix"
    },
    {
        "id": "1033",
        "text": "Disorder of oesophageal peristalsis"
    },
    {
        "id": "1034",
        "text": "Non-erosive gastro-oesophageal reflux disease"
    },
    {
        "id": "1035",
        "text": "Erosive gastro-oesophageal reflux disease"
    },
    {
        "id": "1036",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1037",
        "text": "Dysplasia of Barrett epithelium"
    },
    {
        "id": "1038",
        "text": "Lower limb superficial venous ectasia"
    },
    {
        "id": "101V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "103A",
        "text": "Lobular capillary haemangioma of oral mucosa"
    },
    {
        "id": "103B",
        "text": "Lobular capillary haemangioma"
    },
    {
        "id": "103D",
        "text": "Chondromalacia patellae"
    },
    {
        "id": "103E",
        "text": "Chronic instability of knee"
    },
    {
        "id": "103F",
        "text": "Idiopathic aseptic osteonecrosis of carpal lunate"
    },
    {
        "id": "103H",
        "text": "Hypertensive diseases"
    },
    {
        "id": "103G",
        "text": "Hypertensive diseases"
    },
    {
        "id": "103J",
        "text": "Chronic bronchitis"
    },
    {
        "id": "103K",
        "text": "Intracranial injury"
    },
    {
        "id": "103L",
        "text": "Injury, poisoning or certain other consequences of external causes"
    },
    {
        "id": "103M",
        "text": "Unintentional causes"
    },
    {
        "id": "103N",
        "text": "Certain disorders involving the immune system"
    },
    {
        "id": "103O",
        "text": "Unintentional causes"
    },
    {
        "id": "103P",
        "text": "Adverse effects, not elsewhere classified"
    },
    {
        "id": "103Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "103R",
        "text": "Disorders with hearing impairment"
    },
    {
        "id": "103I",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "103S",
        "text": "Combined bony and soft tissue deformity of orbit"
    },
    {
        "id": "103T",
        "text": "Conjunctival or subconjunctival haemorrhage"
    },
    {
        "id": "103U",
        "text": "Conjunctival or subconjunctival degenerations or deposits"
    },
    {
        "id": "103V",
        "text": "Anastomosis of retinal or choroidal vessels"
    },
    {
        "id": "103W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "103X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "103Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "103Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1040",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1041",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1042",
        "text": "Retinal detachments or breaks"
    },
    {
        "id": "1043",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1044",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1045",
        "text": "Asymptomatic carriage of Entamoeba in the small intestine"
    },
    {
        "id": "1046",
        "text": "Acute amoebiasis"
    },
    {
        "id": "1047",
        "text": "Amoeboma of large intestine"
    },
    {
        "id": "1048",
        "text": "Chronic amoebiasis"
    },
    {
        "id": "104A",
        "text": "Asymptomatic carriage of Entamoeba in the large intestine"
    },
    {
        "id": "104C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "104D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "104E",
        "text": "Enteritis due to Entamoeba"
    },
    {
        "id": "104F",
        "text": "Amoebic nondysenteric colitis"
    },
    {
        "id": "104G",
        "text": "Secondary dystonia"
    },
    {
        "id": "104H",
        "text": "Dystonia due to homocystinuria"
    },
    {
        "id": "104I",
        "text": "Seizure due to acute causes"
    },
    {
        "id": "104J",
        "text": "Intracranial injury"
    },
    {
        "id": "104K",
        "text": "Progressive muscular atrophy"
    },
    {
        "id": "102O",
        "text": "Autosomal dominant tubulointerstitial disease, Type 1"
    },
    {
        "id": "104L",
        "text": "Genetic disorders of amino acid metabolism or transport affecting the skin"
    },
    {
        "id": "104M",
        "text": "Tanning due to exposure to artificial sources of ultraviolet radiation"
    },
    {
        "id": "104N",
        "text": "Syphilitic alopecia"
    },
    {
        "id": "104O",
        "text": "Syphilitic leukoderma"
    },
    {
        "id": "104P",
        "text": "Alopecia areata"
    },
    {
        "id": "104Q",
        "text": "Diffuse alopecia areata"
    },
    {
        "id": "104R",
        "text": "Scarring alopecia"
    },
    {
        "id": "104S",
        "text": "Male pattern hair loss"
    },
    {
        "id": "104T",
        "text": "Alopecia due to thyroid disorder"
    },
    {
        "id": "104U",
        "text": "Alopecia due to iron deficiency"
    },
    {
        "id": "104V",
        "text": "Alopecia due to deficient nutrition"
    },
    {
        "id": "104W",
        "text": "Anagen effluvium"
    },
    {
        "id": "104X",
        "text": "Anagen effluvium due to toxins or poisons"
    },
    {
        "id": "104Z",
        "text": "Scarring alopecia due to infection"
    },
    {
        "id": "1050",
        "text": "Scarring alopecia due to malignant infiltration"
    },
    {
        "id": "1051",
        "text": "Scarring alopecia due to trauma"
    },
    {
        "id": "1052",
        "text": "Pseudopelade"
    },
    {
        "id": "104Y",
        "text": "Scarring alopecia due to inflammatory disorders"
    },
    {
        "id": "1054",
        "text": "Folliculitis decalvans"
    },
    {
        "id": "1055",
        "text": "Dissecting cellulitis"
    },
    {
        "id": "1056",
        "text": "Traction alopecia"
    },
    {
        "id": "1053",
        "text": "Central centrifugal scarring alopecia"
    },
    {
        "id": "1057",
        "text": "Hot comb alopecia"
    },
    {
        "id": "1058",
        "text": "Rhinophyma"
    },
    {
        "id": "1059",
        "text": "Gender incongruence"
    },
    {
        "id": "105A",
        "text": "Cutaneous cysts"
    },
    {
        "id": "105B",
        "text": "Osteomyelitis or osteitis"
    },
    {
        "id": "105C",
        "text": "Postcoital or contact bleeding"
    },
    {
        "id": "105D",
        "text": "Sexual dysfunction associated with pelvic organ prolapse"
    },
    {
        "id": "105E",
        "text": "Female pelvic floor dysfunction"
    },
    {
        "id": "105F",
        "text": "Pelvic organ prolapse"
    },
    {
        "id": "105G",
        "text": "Urinary incontinence associated with pelvic organ prolapse"
    },
    {
        "id": "105H",
        "text": "Functional bladder disorders associated with pelvic organ prolapse"
    },
    {
        "id": "105I",
        "text": "Anorectal dysfunction associated with pelvic organ prolapse"
    },
    {
        "id": "105J",
        "text": "Menopausal or female climacteric states"
    },
    {
        "id": "105K",
        "text": "Hereditary cystic or dysplastic kidney disease, chromosomal abnormalities"
    },
    {
        "id": "105L",
        "text": "Xeroderma pigmentosum variant"
    },
    {
        "id": "105M",
        "text": "Diabetes mellitus"
    },
    {
        "id": "105N",
        "text": "Structural developmental anomalies of spleen"
    },
    {
        "id": "105O",
        "text": "Encephalocele"
    },
    {
        "id": "105P",
        "text": "Klinefelter syndrome, male with more than two X chromosomes"
    },
    {
        "id": "105R",
        "text": "Microcephaly"
    },
    {
        "id": "105Q",
        "text": "Congenital hydrocephalus"
    },
    {
        "id": "105S",
        "text": "Thoracolumbosacral spina bifida cystica with hydrocephalus"
    },
    {
        "id": "105T",
        "text": "Megalencephaly"
    },
    {
        "id": "105U",
        "text": "Arrhinencephaly"
    },
    {
        "id": "105V",
        "text": "Brain cystic malformations"
    },
    {
        "id": "105W",
        "text": "Arnold-Chiari malformation type II"
    },
    {
        "id": "105X",
        "text": "Occult spinal dysraphism"
    },
    {
        "id": "105Y",
        "text": "Amyelia"
    },
    {
        "id": "105Z",
        "text": "Diastematomyelia"
    },
    {
        "id": "1060",
        "text": "Congenital hydromyelia"
    },
    {
        "id": "1061",
        "text": "Hypohidrotic ectodermal dysplasia"
    },
    {
        "id": "1062",
        "text": "Structural developmental anomalies of ocular globes"
    },
    {
        "id": "1063",
        "text": "Buphthalmos"
    },
    {
        "id": "1064",
        "text": "Congenital macrophthalmos"
    },
    {
        "id": "1065",
        "text": "Structural developmental anomalies of the anterior segment of eye"
    },
    {
        "id": "1066",
        "text": "Congenital corneal opacity"
    },
    {
        "id": "1067",
        "text": "Blue sclera"
    },
    {
        "id": "1068",
        "text": "Developmental glaucoma"
    },
    {
        "id": "1069",
        "text": "Coloboma of iris"
    },
    {
        "id": "106A",
        "text": "Structural developmental anomalies of lens or zonula"
    },
    {
        "id": "106B",
        "text": "Spherophakia"
    },
    {
        "id": "106C",
        "text": "Structural developmental anomalies of the posterior segment of eye"
    },
    {
        "id": "106D",
        "text": "Congenital vitreoretinal dysplasia"
    },
    {
        "id": "106E",
        "text": "Structural developmental anomalies of eyelid, lacrimal apparatus or orbit"
    },
    {
        "id": "106G",
        "text": "Structural developmental anomalies of orbit"
    },
    {
        "id": "106H",
        "text": "Hypertelorism"
    },
    {
        "id": "106I",
        "text": "Protruding ear"
    },
    {
        "id": "106J",
        "text": "Misshapen ear"
    },
    {
        "id": "106K",
        "text": "Accessory auricle"
    },
    {
        "id": "106L",
        "text": "Structural developmental anomalies of ear causing hearing impairment"
    },
    {
        "id": "106M",
        "text": "Structural developmental anomalies of ear ossicles"
    },
    {
        "id": "106N",
        "text": "Structural developmental anomalies of inner ear"
    },
    {
        "id": "106O",
        "text": "Congenital heart block"
    },
    {
        "id": "106P",
        "text": "Structural developmental anomalies of lungs"
    },
    {
        "id": "106Q",
        "text": "Congenital pulmonary airway malformations"
    },
    {
        "id": "106R",
        "text": "Structural developmental anomalies of larynx"
    },
    {
        "id": "106S",
        "text": "Congenital laryngomalacia"
    },
    {
        "id": "106T",
        "text": "Laryngeal hypoplasia"
    },
    {
        "id": "106U",
        "text": "Laryngocele"
    },
    {
        "id": "106V",
        "text": "Congenital subglottic stenosis"
    },
    {
        "id": "106W",
        "text": "Congenital stenosis or atresia of bronchus"
    },
    {
        "id": "106X",
        "text": "Structural developmental anomalies of the nose or cavum"
    },
    {
        "id": "106Y",
        "text": "Choanal atresia"
    },
    {
        "id": "106Z",
        "text": "Congenital perforated nasal septum"
    },
    {
        "id": "1071",
        "text": "Congenital cyst of mediastinum"
    },
    {
        "id": "1072",
        "text": "Congenital macroglossia"
    },
    {
        "id": "1073",
        "text": "Ankyloglossia"
    },
    {
        "id": "1074",
        "text": "Microstomia"
    },
    {
        "id": "1075",
        "text": "Karyotype 45, X"
    },
    {
        "id": "1076",
        "text": "Mosaicism, 45, X or other cell line with abnormal sex chromosome"
    },
    {
        "id": "1077",
        "text": "Karyotype 46, X iso Xq"
    },
    {
        "id": "1078",
        "text": "Karyotype 46, X with abnormal sex chromosome, except iso Xq"
    },
    {
        "id": "1079",
        "text": "Allantoic duct remnants or cysts"
    },
    {
        "id": "107A",
        "text": "Chondromalacia patellae"
    },
    {
        "id": "107B",
        "text": "Macrocephaly"
    },
    {
        "id": "107C",
        "text": "Cervical rib"
    },
    {
        "id": "107D",
        "text": "Reduction defects of upper limb"
    },
    {
        "id": "107E",
        "text": "Amelia of upper limb"
    },
    {
        "id": "107F",
        "text": "Congenital absence of upper arm or forearm with hand present"
    },
    {
        "id": "107G",
        "text": "Congenital absence of both forearm and hand"
    },
    {
        "id": "107H",
        "text": "Acheiria"
    },
    {
        "id": "107I",
        "text": "Split hand"
    },
    {
        "id": "107J",
        "text": "Reduction defects of lower limb"
    },
    {
        "id": "107K",
        "text": "Amelia of lower limb"
    },
    {
        "id": "107L",
        "text": "Congenital absence of thigh or lower leg with foot present"
    },
    {
        "id": "107M",
        "text": "Apodia"
    },
    {
        "id": "107N",
        "text": "Congenital absence of both lower leg and foot"
    },
    {
        "id": "107O",
        "text": "Split foot"
    },
    {
        "id": "107P",
        "text": "Congenital deformities of fingers"
    },
    {
        "id": "107Q",
        "text": "Congenital bowing of femur"
    },
    {
        "id": "107R",
        "text": "Congenital bowing of tibia"
    },
    {
        "id": "107S",
        "text": "Congenital deformities of feet"
    },
    {
        "id": "107T",
        "text": "Congenital pes cavus"
    },
    {
        "id": "107U",
        "text": "Congenital valgus deformities of feet"
    },
    {
        "id": "107V",
        "text": "Talipes calcaneovalgus"
    },
    {
        "id": "107W",
        "text": "Congenital pes planus"
    },
    {
        "id": "107X",
        "text": "Congenital varus deformities of feet"
    },
    {
        "id": "107Y",
        "text": "Talipes equinovarus"
    },
    {
        "id": "1080",
        "text": "Metatarsus varus"
    },
    {
        "id": "1081",
        "text": "Structural developmental anomalies of pelvic girdle"
    },
    {
        "id": "1082",
        "text": "Unstable hip"
    },
    {
        "id": "1083",
        "text": "Congenital knee dislocation"
    },
    {
        "id": "1084",
        "text": "Polydactyly"
    },
    {
        "id": "1085",
        "text": "Polydactyly of toes"
    },
    {
        "id": "1086",
        "text": "Polydactyly of the thumb"
    },
    {
        "id": "1087",
        "text": "Polysyndactyly"
    },
    {
        "id": "1088",
        "text": "Postaxial polydactyly of fingers"
    },
    {
        "id": "1089",
        "text": "Syndactyly"
    },
    {
        "id": "108A",
        "text": "Webbed fingers"
    },
    {
        "id": "108B",
        "text": "Webbed toes"
    },
    {
        "id": "108C",
        "text": "Structural developmental anomalies of spine or bony thorax"
    },
    {
        "id": "108D",
        "text": "Congenital scoliosis due to congenital bony malformation"
    },
    {
        "id": "108E",
        "text": "Congenital spondylolisthesis"
    },
    {
        "id": "108F",
        "text": "Structural developmental anomalies of sternum"
    },
    {
        "id": "108G",
        "text": "Pectus excavatum"
    },
    {
        "id": "108H",
        "text": "Pectus carinatum"
    },
    {
        "id": "108I",
        "text": "Structural developmental anomalies of urinary tract"
    },
    {
        "id": "108J",
        "text": "Fetal lower urinary tract obstruction"
    },
    {
        "id": "108K",
        "text": "Congenital diverticulum of urinary bladder"
    },
    {
        "id": "108L",
        "text": "Atresia or stenosis of ureter"
    },
    {
        "id": "108M",
        "text": "Agenesis of ureter"
    },
    {
        "id": "108N",
        "text": "Duplication of ureter"
    },
    {
        "id": "108O",
        "text": "Malposition of ureter"
    },
    {
        "id": "108P",
        "text": "Congenital absence of bladder or urethra"
    },
    {
        "id": "108Q",
        "text": "Congenital vesico-uretero-renal reflux"
    },
    {
        "id": "108R",
        "text": "Micropenis or penis agenesis"
    },
    {
        "id": "108S",
        "text": "Developmental anomalies"
    },
    {
        "id": "108T",
        "text": "Chromosomal anomalies, excluding gene mutations"
    },
    {
        "id": "108U",
        "text": "Deletions of the autosomes"
    },
    {
        "id": "108V",
        "text": "Deletions with other complex rearrangements"
    },
    {
        "id": "108W",
        "text": "Chromosome replaced with ring or dicentric with normal number of chromosomes"
    },
    {
        "id": "108X",
        "text": "Balanced rearrangements or structural rearrangements"
    },
    {
        "id": "108Y",
        "text": "Autosomal fragile site"
    },
    {
        "id": "108Z",
        "text": "Balanced sex or autosomal rearrangement in abnormal individual"
    },
    {
        "id": "1090",
        "text": "Balanced autosomal rearrangement in abnormal individual"
    },
    {
        "id": "1091",
        "text": "Balanced autosomal rearrangement in abnormal individual"
    },
    {
        "id": "1092",
        "text": "Chromosome inversion in normal individual"
    },
    {
        "id": "1093",
        "text": "Balanced translocation and insertion in normal individual"
    },
    {
        "id": "1094",
        "text": "Polyploidies"
    },
    {
        "id": "1095",
        "text": "Mosaicism, lines with various numbers of X chromosomes"
    },
    {
        "id": "1096",
        "text": "Male with 46,XX karyotype"
    },
    {
        "id": "1097",
        "text": "Male with double or multiple Y"
    },
    {
        "id": "1098",
        "text": "Structural anomalies of chromosome Y"
    },
    {
        "id": "1099",
        "text": "Male with sex chromosome mosaicism"
    },
    {
        "id": "109A",
        "text": "Chimaera 46, XX, 46, XY"
    },
    {
        "id": "109B",
        "text": "Extra marker chromosomes"
    },
    {
        "id": "109C",
        "text": "Duplications with other complex rearrangements"
    },
    {
        "id": "109E",
        "text": "Complete monosomy of autosome"
    },
    {
        "id": "109F",
        "text": "Mosaic monosomy of autosome"
    },
    {
        "id": "109G",
        "text": "Congenital diverticulum of pharynx"
    },
    {
        "id": "109H",
        "text": "Structural developmental anomalies of salivary glands or ducts"
    },
    {
        "id": "109I",
        "text": "Congenital anomalies of intestinal fixation"
    },
    {
        "id": "109J",
        "text": "Structural developmental anomalies of gallbladder, bile ducts or liver"
    },
    {
        "id": "109K",
        "text": "Facial asymmetry"
    },
    {
        "id": "109L",
        "text": "Macrocheilia"
    },
    {
        "id": "109M",
        "text": "Microcheilia"
    },
    {
        "id": "109N",
        "text": "Compression facies"
    },
    {
        "id": "109O",
        "text": "Cleft hard palate"
    },
    {
        "id": "109Q",
        "text": "Cleft lip, bilateral"
    },
    {
        "id": "109R",
        "text": "Clefts of lip, alveolus or palate"
    },
    {
        "id": "109S",
        "text": "Webbed neck"
    },
    {
        "id": "109T",
        "text": "Breast aplasia"
    },
    {
        "id": "109U",
        "text": "Absent nipple"
    },
    {
        "id": "109V",
        "text": "Supernumerary breasts"
    },
    {
        "id": "109W",
        "text": "Accessory nipple"
    },
    {
        "id": "109X",
        "text": "Craniofacial dysostoses"
    },
    {
        "id": "109Y",
        "text": "Syndromes with limb anomalies as a major feature"
    },
    {
        "id": "109Z",
        "text": "Short rib syndromes"
    },
    {
        "id": "1100",
        "text": "Multiple metaphyseal dysplasias"
    },
    {
        "id": "1101",
        "text": "Phakomatoses or hamartoneoplastic syndromes"
    },
    {
        "id": "1102",
        "text": "Overgrowth syndromes"
    },
    {
        "id": "1103",
        "text": "Prune belly syndrome"
    },
    {
        "id": "1105",
        "text": "Syndromes with multiple structural anomalies of environmental origin"
    },
    {
        "id": "1106",
        "text": "Embryofetopathy due to oral anticoagulant therapy"
    },
    {
        "id": "1107",
        "text": "Malformative disorders of sex development"
    },
    {
        "id": "1108",
        "text": "Malignant hyperthermia due to anaesthesia"
    },
    {
        "id": "1109",
        "text": "Infectious embryofetopathies"
    },
    {
        "id": "110A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "110B",
        "text": "Congenital asplenia"
    },
    {
        "id": "110C",
        "text": "Polysplenia"
    },
    {
        "id": "110D",
        "text": "Spina bifida aperta"
    },
    {
        "id": "110E",
        "text": "Spina bifida cystica"
    },
    {
        "id": "110F",
        "text": "Myelomeningocele with hydrocephalus"
    },
    {
        "id": "110G",
        "text": "Myelocystocele"
    },
    {
        "id": "110H",
        "text": "Myelomeningocele without hydrocephalus"
    },
    {
        "id": "110I",
        "text": "Syndromes with obesity as a major feature"
    },
    {
        "id": "110J",
        "text": "Classic lissencephaly"
    },
    {
        "id": "110K",
        "text": "Structural developmental anomalies of the nervous system"
    },
    {
        "id": "110L",
        "text": "Cerebral structural developmental anomalies"
    },
    {
        "id": "110M",
        "text": "Abnormal neuronal migration"
    },
    {
        "id": "110N",
        "text": "Cortical dysplasia"
    },
    {
        "id": "110O",
        "text": "Hydranencephaly"
    },
    {
        "id": "110P",
        "text": "Aprosencephaly"
    },
    {
        "id": "110Q",
        "text": "Cerebellar structural developmental anomalies"
    },
    {
        "id": "110R",
        "text": "Focal cerebellar dysplasia"
    },
    {
        "id": "110S",
        "text": "Cephalocele"
    },
    {
        "id": "110T",
        "text": "Structural developmental anomalies of the neurenteric canal, spinal cord or vertebral column"
    },
    {
        "id": "110U",
        "text": "Primary tethered cord syndrome"
    },
    {
        "id": "110V",
        "text": "Primary syringomyelia or hydromyelia"
    },
    {
        "id": "110W",
        "text": "Arnold-Chiari malformation type I"
    },
    {
        "id": "110X",
        "text": "Syndromes with central nervous system anomalies as a major feature"
    },
    {
        "id": "110Y",
        "text": "Syndromes with cerebellar anomalies as a major feature"
    },
    {
        "id": "1113",
        "text": "Dermatitis and eczema"
    },
    {
        "id": "1112",
        "text": "Chronic obstructive pulmonary disease with acute exacerbation, unspecified, mild airflow limitation"
    },
    {
        "id": "1111",
        "text": "Chronic obstructive pulmonary disease with acute exacerbation, unspecified, moderate airflow limitation"
    },
    {
        "id": "1110",
        "text": "Chronic obstructive pulmonary disease with acute exacerbation, unspecified, severe airflow limitation"
    },
    {
        "id": "110Z",
        "text": "Chronic obstructive pulmonary disease with acute exacerbation, unspecified, very severe airflow limitation"
    },
    {
        "id": "1115",
        "text": "Pontocerebellar hypoplasia"
    },
    {
        "id": "1116",
        "text": "Syndromes with microcephaly as a major feature"
    },
    {
        "id": "1117",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1118",
        "text": "Syndromes with holoprosencephaly as a major feature"
    },
    {
        "id": "1119",
        "text": "Syndromes with brain calcifications as a major feature"
    },
    {
        "id": "111A",
        "text": "Structural developmental anomalies of the eye, eyelid or lacrimal apparatus"
    },
    {
        "id": "111B",
        "text": "Cyclopia"
    },
    {
        "id": "111C",
        "text": "Structural developmental anomalies of cornea"
    },
    {
        "id": "111D",
        "text": "Cornea plana"
    },
    {
        "id": "111E",
        "text": "Megalocornea"
    },
    {
        "id": "111F",
        "text": "Microcornea"
    },
    {
        "id": "111G",
        "text": "Anterior segment dysgenesis"
    },
    {
        "id": "111H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "111I",
        "text": "Coloboma of choroid or retina"
    },
    {
        "id": "111J",
        "text": "Coloboma of macula"
    },
    {
        "id": "111K",
        "text": "Hypotelorism"
    },
    {
        "id": "111L",
        "text": "Dermoid cyst"
    },
    {
        "id": "111M",
        "text": "Structural developmental anomalies of the ear"
    },
    {
        "id": "111N",
        "text": "Minor anomalies of pinnae"
    },
    {
        "id": "111O",
        "text": "Port-wine stain"
    },
    {
        "id": "111P",
        "text": "Macrocystic lymphatic malformation"
    },
    {
        "id": "111Q",
        "text": "Oral-facial-digital syndrome"
    },
    {
        "id": "111R",
        "text": "Facial clefts"
    },
    {
        "id": "111S",
        "text": "Median facial cleft"
    },
    {
        "id": "111T",
        "text": "Lateral facial cleft"
    },
    {
        "id": "111U",
        "text": "Paramedian facial cleft"
    },
    {
        "id": "111V",
        "text": "Yunis-Varon disease"
    },
    {
        "id": "111W",
        "text": "Structural developmental anomalies of cranium"
    },
    {
        "id": "111X",
        "text": "Structural developmental anomalies of facial bones"
    },
    {
        "id": "111Y",
        "text": "Structural developmental anomalies of shoulder girdle"
    },
    {
        "id": "111Z",
        "text": "Sprengel deformity"
    },
    {
        "id": "1120",
        "text": "Limb hypoplasia or limb reduction defects"
    },
    {
        "id": "1121",
        "text": "Adactyly of hands"
    },
    {
        "id": "1122",
        "text": "Congenital absence or hypoplasia of thumb"
    },
    {
        "id": "1123",
        "text": "Congenital absence or hypoplasia of finger excluding thumb"
    },
    {
        "id": "1124",
        "text": "Adactyly of feet"
    },
    {
        "id": "1125",
        "text": "Brachydactyly of fingers"
    },
    {
        "id": "1126",
        "text": "Brachydactyly of toes"
    },
    {
        "id": "1127",
        "text": "Symbrachydactyly of hands or feet"
    },
    {
        "id": "1128",
        "text": "Clinodactyly of fingers"
    },
    {
        "id": "1129",
        "text": "Joint formation defects"
    },
    {
        "id": "112A",
        "text": "Humero-ulnar synostosis"
    },
    {
        "id": "112B",
        "text": "Humero-radio-ulnar synostosis"
    },
    {
        "id": "112C",
        "text": "Radio-ulnar synostosis"
    },
    {
        "id": "112D",
        "text": "Patella aplasia or hypoplasia"
    },
    {
        "id": "112E",
        "text": "Macrodactyly of fingers"
    },
    {
        "id": "112G",
        "text": "Metatarsus valgus"
    },
    {
        "id": "112H",
        "text": "Congenital hallux valgus"
    },
    {
        "id": "112I",
        "text": "Congenital hammer toe"
    },
    {
        "id": "112J",
        "text": "Developmental dysplasia of hip"
    },
    {
        "id": "112K",
        "text": "Congenital coxa vara"
    },
    {
        "id": "112L",
        "text": "Congenital coxa valga"
    },
    {
        "id": "112M",
        "text": "Triphalangeal thumb"
    },
    {
        "id": "112N",
        "text": "Structural developmental anomalies of spine"
    },
    {
        "id": "112O",
        "text": "Occipitalisation of atlas"
    },
    {
        "id": "112P",
        "text": "Atlanto-axial instability or subluxation"
    },
    {
        "id": "112R",
        "text": "Segmentation anomalies of vertebrae"
    },
    {
        "id": "112S",
        "text": "Caudal appendage"
    },
    {
        "id": "112T",
        "text": "Structural developmental anomalies of chest wall"
    },
    {
        "id": "112U",
        "text": "Accessory rib"
    },
    {
        "id": "112V",
        "text": "Structural developmental anomalies of the urinary system"
    },
    {
        "id": "112W",
        "text": "Structural developmental anomalies of kidneys"
    },
    {
        "id": "112X",
        "text": "Fused pelvic kidney"
    },
    {
        "id": "112Y",
        "text": "Lobulated kidney"
    },
    {
        "id": "112Z",
        "text": "Fetal lower urinary tract obstruction due to anterior urethral valve"
    },
    {
        "id": "1130",
        "text": "Duplication of urethra"
    },
    {
        "id": "1131",
        "text": "Congenital megalourethra"
    },
    {
        "id": "1132",
        "text": "Bifid scrotum"
    },
    {
        "id": "1133",
        "text": "Deletions of chromosome 1"
    },
    {
        "id": "1134",
        "text": "Deletions of the short arm of chromosome 1"
    },
    {
        "id": "1135",
        "text": "Deletions of the long arm of chromosome 1"
    },
    {
        "id": "1136",
        "text": "Deletions of chromosome 2"
    },
    {
        "id": "1137",
        "text": "Deletions of the short arm of chromosome 2"
    },
    {
        "id": "1138",
        "text": "Deletions of the long arm of chromosome 2"
    },
    {
        "id": "1139",
        "text": "Deletions of the long arm of chromosome 2"
    },
    {
        "id": "113A",
        "text": "Deletions of chromosome 3"
    },
    {
        "id": "113B",
        "text": "Deletions of the short arm of chromosome 3"
    },
    {
        "id": "113C",
        "text": "Deletions of the long arm of chromosome 3"
    },
    {
        "id": "113D",
        "text": "Deletions of chromosome 4"
    },
    {
        "id": "113E",
        "text": "Deletions of the long arm of chromosome 4"
    },
    {
        "id": "113F",
        "text": "Deletions of chromosome 5"
    },
    {
        "id": "113G",
        "text": "Deletions of the short arm of chromosome 5"
    },
    {
        "id": "113H",
        "text": "Deletions of the long arm of chromosome 5"
    },
    {
        "id": "113I",
        "text": "Deletions of chromosome 6"
    },
    {
        "id": "113J",
        "text": "Deletions of the short arm of chromosome 6"
    },
    {
        "id": "113K",
        "text": "Deletions of the long arm of chromosome 6"
    },
    {
        "id": "113L",
        "text": "Deletions of chromosome 7"
    },
    {
        "id": "113M",
        "text": "Deletions of the short arm of chromosome 7"
    },
    {
        "id": "113N",
        "text": "Deletions of the long arm of chromosome 7"
    },
    {
        "id": "113O",
        "text": "Deletions of chromosome 8"
    },
    {
        "id": "113P",
        "text": "Deletions of the short arm of chromosome 8"
    },
    {
        "id": "113Q",
        "text": "Deletions of the long arm of chromosome 8"
    },
    {
        "id": "113R",
        "text": "Deletions of chromosome 9"
    },
    {
        "id": "113S",
        "text": "Deletions of the short arm of chromosome 9"
    },
    {
        "id": "113T",
        "text": "Deletions of chromosome 10"
    },
    {
        "id": "113U",
        "text": "Deletions of the long arm of chromosome 9"
    },
    {
        "id": "113V",
        "text": "Deletions of the short arm of chromosome 10"
    },
    {
        "id": "113W",
        "text": "Deletions of the long arm of chromosome 10"
    },
    {
        "id": "113X",
        "text": "Deletions of chromosome 11"
    },
    {
        "id": "113Y",
        "text": "Deletions of the long arm of chromosome 11"
    },
    {
        "id": "113Z",
        "text": "Deletions of chromosome 12"
    },
    {
        "id": "1140",
        "text": "Deletions of the long arm of chromosome 12"
    },
    {
        "id": "1141",
        "text": "Deletions of the short arm of chromosome 12"
    },
    {
        "id": "1142",
        "text": "Deletions of chromosome 13"
    },
    {
        "id": "1143",
        "text": "Deletions of chromosome 14"
    },
    {
        "id": "1144",
        "text": "Deletions of chromosome 15"
    },
    {
        "id": "1145",
        "text": "Deletions of chromosome 16"
    },
    {
        "id": "1146",
        "text": "Deletions of the short arm of chromosome 16"
    },
    {
        "id": "1147",
        "text": "Deletions of the long arm of chromosome 16"
    },
    {
        "id": "1148",
        "text": "Deletions of chromosome 17"
    },
    {
        "id": "1149",
        "text": "Deletions of the short arm of chromosome 17"
    },
    {
        "id": "114A",
        "text": "Deletions of the long arm of chromosome 17"
    },
    {
        "id": "114B",
        "text": "Deletions of chromosome 18"
    },
    {
        "id": "114C",
        "text": "Deletions of the short arm of chromosome 18"
    },
    {
        "id": "114D",
        "text": "Deletions of the long arm of chromosome 18"
    },
    {
        "id": "114E",
        "text": "Deletions of chromosome 19"
    },
    {
        "id": "114F",
        "text": "Deletions of the short arm of chromosome 19"
    },
    {
        "id": "114G",
        "text": "Deletions of the long arm of chromosome 19"
    },
    {
        "id": "114H",
        "text": "Deletions of chromosome 20"
    },
    {
        "id": "114I",
        "text": "Deletions of the short arm of chromosome 20"
    },
    {
        "id": "114J",
        "text": "Deletions of the long arm of chromosome 20"
    },
    {
        "id": "114K",
        "text": "Deletions of chromosome 21"
    },
    {
        "id": "114L",
        "text": "Deletions of chromosome 22"
    },
    {
        "id": "114M",
        "text": "Triploidy"
    },
    {
        "id": "114N",
        "text": "Tetraploidy"
    },
    {
        "id": "114O",
        "text": "Complete trisomies of the autosomes"
    },
    {
        "id": "114P",
        "text": "Tetrasomies of the autosomes"
    },
    {
        "id": "114Q",
        "text": "Sex chromosome anomalies"
    },
    {
        "id": "114R",
        "text": "Uniparental disomies"
    },
    {
        "id": "114S",
        "text": "Uniparental disomies of maternal origin"
    },
    {
        "id": "114T",
        "text": "Uniparental disomies of paternal origin"
    },
    {
        "id": "114U",
        "text": "Duplications of the autosomes"
    },
    {
        "id": "114V",
        "text": "Duplications of chromosome 1"
    },
    {
        "id": "114W",
        "text": "Duplications of the short arm of chromosome 1"
    },
    {
        "id": "114X",
        "text": "Duplications of the long arm of chromosome 1"
    },
    {
        "id": "114Y",
        "text": "Duplications of chromosome 2"
    },
    {
        "id": "114Z",
        "text": "Duplications of the short arm of chromosome 2"
    },
    {
        "id": "1150",
        "text": "Duplications of the long arm of chromosome 2"
    },
    {
        "id": "1151",
        "text": "Duplications of chromosome 3"
    },
    {
        "id": "1152",
        "text": "Duplications of the short arm of chromosome 3"
    },
    {
        "id": "1153",
        "text": "Duplications of the long arm of chromosome 3"
    },
    {
        "id": "1154",
        "text": "Duplications of chromosome 4"
    },
    {
        "id": "1155",
        "text": "Duplications of the short arm of chromosome 4"
    },
    {
        "id": "1156",
        "text": "Duplications of the long arm of chromosome 4"
    },
    {
        "id": "1157",
        "text": "Duplications of chromosome 5"
    },
    {
        "id": "1158",
        "text": "Duplications of the short arm of chromosome 5"
    },
    {
        "id": "1159",
        "text": "Duplications of the long arm of chromosome 5"
    },
    {
        "id": "115A",
        "text": "Duplications of chromosome 6"
    },
    {
        "id": "115B",
        "text": "Duplications of the short arm of chromosome 6"
    },
    {
        "id": "115C",
        "text": "Duplications of the long arm of chromosome 6"
    },
    {
        "id": "115D",
        "text": "Duplications of chromosome 7"
    },
    {
        "id": "115E",
        "text": "Duplications of the short arm of chromosome 7"
    },
    {
        "id": "115F",
        "text": "Duplications of the long arm of chromosome 7"
    },
    {
        "id": "115G",
        "text": "Duplications of chromosome 8"
    },
    {
        "id": "115H",
        "text": "Duplications of the long arm of chromosome 8"
    },
    {
        "id": "115I",
        "text": "Duplications of chromosome 9"
    },
    {
        "id": "115J",
        "text": "Duplications of the short arm of chromosome 9"
    },
    {
        "id": "115K",
        "text": "Duplications of the long arm of chromosome 9"
    },
    {
        "id": "115L",
        "text": "Duplications of chromosome 10"
    },
    {
        "id": "115M",
        "text": "Duplications of the short arm of chromosome 10"
    },
    {
        "id": "115N",
        "text": "Duplications of the long arm of chromosome 10"
    },
    {
        "id": "115O",
        "text": "Duplications of chromosome 11"
    },
    {
        "id": "115P",
        "text": "Duplications of chromosome 12"
    },
    {
        "id": "115Q",
        "text": "Duplications of the short arm of chromosome 12"
    },
    {
        "id": "115R",
        "text": "Duplications of the long arm of chromosome 12"
    },
    {
        "id": "115S",
        "text": "Duplications of chromosome 13"
    },
    {
        "id": "115T",
        "text": "Duplications of chromosome 15"
    },
    {
        "id": "115U",
        "text": "Duplications of chromosome 16"
    },
    {
        "id": "115V",
        "text": "Duplications of the short arm of chromosome 16"
    },
    {
        "id": "115W",
        "text": "Duplications of the long arm of chromosome 16"
    },
    {
        "id": "115X",
        "text": "Duplications of chromosome 17"
    },
    {
        "id": "115Y",
        "text": "Duplications of the short arm of chromosome 17"
    },
    {
        "id": "115Z",
        "text": "Duplications of the long arm of chromosome 17"
    },
    {
        "id": "1160",
        "text": "Duplications of chromosome 18"
    },
    {
        "id": "1161",
        "text": "Duplications of the short arm of chromosome 18"
    },
    {
        "id": "1162",
        "text": "Duplications of chromosome 19"
    },
    {
        "id": "1163",
        "text": "Duplications of the short arm of chromosome 19"
    },
    {
        "id": "1164",
        "text": "Duplications of the long arm of chromosome 19"
    },
    {
        "id": "1165",
        "text": "Duplications of chromosome 20"
    },
    {
        "id": "1166",
        "text": "Duplications of the short arm of chromosome 20"
    },
    {
        "id": "1167",
        "text": "Duplications of the long arm of chromosome 20"
    },
    {
        "id": "1168",
        "text": "Duplications of chromosome 21"
    },
    {
        "id": "1169",
        "text": "Duplications of chromosome 22"
    },
    {
        "id": "116A",
        "text": "Duplications of chromosome 14"
    },
    {
        "id": "116B",
        "text": "Structural developmental anomalies of the diaphragm, abdominal wall or umbilical cord"
    },
    {
        "id": "116C",
        "text": "Structural developmental anomalies of umbilical cord"
    },
    {
        "id": "116D",
        "text": "Structural developmental anomalies of the digestive tract"
    },
    {
        "id": "116E",
        "text": "Structural developmental anomalies of the liver, biliary tract, pancreas or spleen"
    },
    {
        "id": "116F",
        "text": "Structural developmental anomalies of the face, mouth or teeth"
    },
    {
        "id": "116G",
        "text": "Congenital syngnathia"
    },
    {
        "id": "116H",
        "text": "Congenital velopharyngeal incompetence"
    },
    {
        "id": "116I",
        "text": "Structural developmental anomalies of the neck"
    },
    {
        "id": "116J",
        "text": "Multiple developmental anomalies or syndromes"
    },
    {
        "id": "116K",
        "text": "Syndromes with eye anomalies as a major feature"
    },
    {
        "id": "116L",
        "text": "Syndromes with microphthalmia as a major feature"
    },
    {
        "id": "116M",
        "text": "Syndromes with face or limb anomalies as a major feature"
    },
    {
        "id": "116N",
        "text": "Oromandibular-limb anomaly syndrome"
    },
    {
        "id": "116P",
        "text": "Acrofacial dysostoses"
    },
    {
        "id": "116Q",
        "text": "Syndromes with limb duplication, polydactyly, syndactyly or triphalangism"
    },
    {
        "id": "116R",
        "text": "Complex brachydactylies"
    },
    {
        "id": "116S",
        "text": "Arthrogryposis syndromes"
    },
    {
        "id": "116T",
        "text": "Syndromes with synostoses of limbs"
    },
    {
        "id": "116V",
        "text": "Constriction rings"
    },
    {
        "id": "116W",
        "text": "Syndromes with skeletal anomalies as a major feature"
    },
    {
        "id": "116X",
        "text": "Syndromes with micromelia"
    },
    {
        "id": "116Y",
        "text": "Acromelic dysplasias"
    },
    {
        "id": "116Z",
        "text": "Acromesomelic dysplasias"
    },
    {
        "id": "1170",
        "text": "Bent bone dysplasias"
    },
    {
        "id": "1171",
        "text": "Slender bone dysplasias"
    },
    {
        "id": "1172",
        "text": "Bone dysplasias with multiple joint dislocations"
    },
    {
        "id": "1173",
        "text": "Syndromic craniosynostoses"
    },
    {
        "id": "1174",
        "text": "Dysostoses with predominant vertebral and costal involvement"
    },
    {
        "id": "1175",
        "text": "Patellar dysostoses"
    },
    {
        "id": "1176",
        "text": "Hypochondrogenesis"
    },
    {
        "id": "1177",
        "text": "Syndromes with skin or mucosal anomalies as a major feature"
    },
    {
        "id": "1178",
        "text": "Syndromes with connective tissue involvement as a major feature"
    },
    {
        "id": "1179",
        "text": "Syndromes with multiple structural anomalies, without predominant body system involvement"
    },
    {
        "id": "117A",
        "text": "Syndromes with multiple structural anomalies, not of environmental origin"
    },
    {
        "id": "117B",
        "text": "Otomandibular dysplasia"
    },
    {
        "id": "117C",
        "text": "Syndromes with dental anomalies as a major feature"
    },
    {
        "id": "117D",
        "text": "Syndromes with structural anomalies due to inborn errors of metabolism"
    },
    {
        "id": "117E",
        "text": "Syndromes with vascular anomalies as a major feature"
    },
    {
        "id": "117F",
        "text": "Postprocedural endocrine or metabolic disorders"
    },
    {
        "id": "117G",
        "text": "Postprocedural adrenocortical hypofunction"
    },
    {
        "id": "117H",
        "text": "Postprocedural testicular hypofunction"
    },
    {
        "id": "117I",
        "text": "Postprocedural ovarian failure"
    },
    {
        "id": "117J",
        "text": "GM2 gangliosidosis"
    },
    {
        "id": "117K",
        "text": "Inborn errors of carbohydrate metabolism"
    },
    {
        "id": "117L",
        "text": "Disorders of amino acid absorption or transport"
    },
    {
        "id": "117M",
        "text": "Disorders of zinc metabolism"
    },
    {
        "id": "117N",
        "text": "Disorders of copper metabolism"
    },
    {
        "id": "117O",
        "text": "Disorders of magnesium metabolism"
    },
    {
        "id": "117P",
        "text": "Disorders of phosphorus metabolism or phosphatases"
    },
    {
        "id": "117Q",
        "text": "Iodine-deficiency-related thyroid disorders or allied conditions"
    },
    {
        "id": "117R",
        "text": "Hypothyroidism due to medicaments or other exogenous substances"
    },
    {
        "id": "117S",
        "text": "Permanent congenital hypothyroidism with diffuse goitre"
    },
    {
        "id": "117T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "117U",
        "text": "Mineral deficiencies"
    },
    {
        "id": "117V",
        "text": "Certain specified nutritional deficiencies"
    },
    {
        "id": "117W",
        "text": "Deficiencies of B group vitamins"
    },
    {
        "id": "117X",
        "text": "Certain specified nutrient excesses"
    },
    {
        "id": "117Y",
        "text": "Megavitamin-B6 syndrome"
    },
    {
        "id": "117Z",
        "text": "Overweight or localised adiposity"
    },
    {
        "id": "1180",
        "text": "Drug-induced obesity"
    },
    {
        "id": "1181",
        "text": "Inborn errors of neurotransmitter metabolism"
    },
    {
        "id": "1182",
        "text": "Disorders of biogenic amine metabolism"
    },
    {
        "id": "1183",
        "text": "Disorders of catecholamine synthesis"
    },
    {
        "id": "1184",
        "text": "Disorders of pterin metabolism"
    },
    {
        "id": "1185",
        "text": "Disorders of gamma aminobutyric acid metabolism"
    },
    {
        "id": "1186",
        "text": "Disorders of pyridoxine metabolism"
    },
    {
        "id": "1187",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1188",
        "text": "Fluorine deficiency"
    },
    {
        "id": "1189",
        "text": "Korsakoff syndrome"
    },
    {
        "id": "118A",
        "text": "Vitamin excesses"
    },
    {
        "id": "118B",
        "text": "Mineral excesses"
    },
    {
        "id": "118C",
        "text": "Overweight or obesity"
    },
    {
        "id": "118D",
        "text": "Overweight"
    },
    {
        "id": "118E",
        "text": "Localised adiposity"
    },
    {
        "id": "118F",
        "text": "Fat pad"
    },
    {
        "id": "118G",
        "text": "Leptin-related genetic obesity"
    },
    {
        "id": "118H",
        "text": "Colorado tick fever"
    },
    {
        "id": "118I",
        "text": "Benign lymphatic neoplasms"
    },
    {
        "id": "118J",
        "text": "Hepatoblastoma"
    },
    {
        "id": "118K",
        "text": "Wiskott-Aldrich syndrome"
    },
    {
        "id": "118L",
        "text": "Tuberous sclerosis"
    },
    {
        "id": "118M",
        "text": "Xeroderma pigmentosum"
    },
    {
        "id": "118N",
        "text": "Acute myelomonocytic leukaemia"
    },
    {
        "id": "118O",
        "text": "Myeloid sarcoma"
    },
    {
        "id": "118P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "118Q",
        "text": "Mycosis fungoides"
    },
    {
        "id": "118R",
        "text": "Hereditary megaloblastic anaemia due to transcobalamin deficiency"
    },
    {
        "id": "118S",
        "text": "Congenital erythropoietic porphyria"
    },
    {
        "id": "118T",
        "text": "Spina bifida"
    },
    {
        "id": "118U",
        "text": "Anencephaly"
    },
    {
        "id": "118V",
        "text": "Craniorachischisis"
    },
    {
        "id": "118W",
        "text": "Polymyositis"
    },
    {
        "id": "118X",
        "text": "Polyarteritis nodosa"
    },
    {
        "id": "118Y",
        "text": "Bullous diffuse cutaneous mastocytosis"
    },
    {
        "id": "118Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1190",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1191",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1192",
        "text": "Chronic obstructive pulmonary disease with acute lower respiratory infection"
    },
    {
        "id": "1193",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1194",
        "text": "Lateral facial cleft"
    },
    {
        "id": "1195",
        "text": "Median facial cleft"
    },
    {
        "id": "1196",
        "text": "Paramedian facial cleft"
    },
    {
        "id": "1197",
        "text": "Facial clefts"
    },
    {
        "id": "1198",
        "text": "Congenital velopharyngeal incompetence"
    },
    {
        "id": "1199",
        "text": "Clefts of lip, alveolus or palate"
    },
    {
        "id": "119A",
        "text": "Cleft lip, bilateral"
    },
    {
        "id": "119B",
        "text": "Cleft hard palate"
    },
    {
        "id": "119C",
        "text": "Keratosis lichenoides chronica"
    },
    {
        "id": "119D",
        "text": "Failed or difficult intubation"
    },
    {
        "id": "119G",
        "text": "Functional nausea or vomiting"
    },
    {
        "id": "119H",
        "text": "Functional digestive disorders of infants, toddlers or children"
    },
    {
        "id": "119J",
        "text": "Vomiting in newborn"
    },
    {
        "id": "119F",
        "text": "Acne or acneform reactions attributable to drugs"
    },
    {
        "id": "1004",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "119O",
        "text": "Sepsis due to Listeria without mention of septic shock"
    },
    {
        "id": "119R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "119T",
        "text": "Acute appendicitis with localised peritonitis"
    },
    {
        "id": "119V",
        "text": "Acute porphyrias"
    },
    {
        "id": "119W",
        "text": "Shigellosis due to Shigella dysenteriae"
    },
    {
        "id": "119X",
        "text": "Pneumonia"
    },
    {
        "id": "119Y",
        "text": "Gastritis"
    },
    {
        "id": "119Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1200",
        "text": "Gastritis of unknown aetiology with specific endoscopic or pathological features"
    },
    {
        "id": "1201",
        "text": "Pneumonitis"
    },
    {
        "id": "1203",
        "text": "Diseases of peritoneum"
    },
    {
        "id": "1204",
        "text": "Gastric ulcer due to diseases classified elsewhere"
    },
    {
        "id": "1207",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1208",
        "text": "Duodenitis due to IgA vasculitis"
    },
    {
        "id": "120B",
        "text": "Localised hyperhidrosis"
    },
    {
        "id": "120D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "120F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "120H",
        "text": "Peptic ulcer, site unspecified"
    },
    {
        "id": "120E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "120I",
        "text": "Dementia"
    },
    {
        "id": "120J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "120L",
        "text": "Acute respiratory failure"
    },
    {
        "id": "120M",
        "text": "Chronic respiratory failure"
    },
    {
        "id": "120T",
        "text": "Paraumbilical hernia"
    },
    {
        "id": "120U",
        "text": "Epigastric hernia"
    },
    {
        "id": "120V",
        "text": "Other primary midline abdominal wall hernia"
    },
    {
        "id": "120W",
        "text": "Certain specified disorders of peritoneum or retroperitoneum"
    },
    {
        "id": "120X",
        "text": "Certain specified disorders of peritoneum or retroperitoneum"
    },
    {
        "id": "120Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "120Z",
        "text": "Benign mesenchymal tumour of oesophagus"
    },
    {
        "id": "1210",
        "text": "Benign mesenchymal tumour of duodenum"
    },
    {
        "id": "1211",
        "text": "Benign mesenchymal tumour of stomach"
    },
    {
        "id": "1212",
        "text": "Benign neoplasm of other or unspecified parts of small intestine"
    },
    {
        "id": "1213",
        "text": "Benign mesenchymal tumour of small intestine"
    },
    {
        "id": "1214",
        "text": "Benign mesenchymal tumour of appendix"
    },
    {
        "id": "1215",
        "text": "Benign mesenchymal tumour of large intestine"
    },
    {
        "id": "1216",
        "text": "Lymphoma of duodenum"
    },
    {
        "id": "1217",
        "text": "Malignant mesenchymal tumour of the duodenum"
    },
    {
        "id": "1218",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1219",
        "text": "Lymphoma of the appendix"
    },
    {
        "id": "121A",
        "text": "Malignant Lymphoma of colon"
    },
    {
        "id": "121B",
        "text": "Malignant mesenchymal tumour of rectum"
    },
    {
        "id": "121C",
        "text": "Malignant mesenchymal tumour of rectum"
    },
    {
        "id": "121D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "121F",
        "text": "Diffuse vascular ectasia of stomach"
    },
    {
        "id": "121G",
        "text": "Brunner gland hyperplasia of duodenum"
    },
    {
        "id": "121H",
        "text": "Brunner gland hamartoma of duodenum"
    },
    {
        "id": "121I",
        "text": "Secondary chronic idiopathic intestinal pseudo-obstruction"
    },
    {
        "id": "121J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "121L",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "121P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "121Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "121R",
        "text": "Viral hepatitis"
    },
    {
        "id": "121S",
        "text": "Viral hepatitis"
    },
    {
        "id": "121T",
        "text": "Symptoms and signs concerning food and fluid intake"
    },
    {
        "id": "121U",
        "text": "Ageing associated decline in intrinsic capacity"
    },
    {
        "id": "121X",
        "text": "Sandfly fever"
    },
    {
        "id": "121Y",
        "text": "Haemorrhoids or perianal venous conditions"
    },
    {
        "id": "121Z",
        "text": "Cholelithiasis"
    },
    {
        "id": "121W",
        "text": "Cutaneous leishmaniasis"
    },
    {
        "id": "1220",
        "text": "Ill-defined and unknown causes of mortality"
    },
    {
        "id": "1221",
        "text": "Assault by exposure to or harmful effects of other or unspecified drug, medicament or biological substance"
    },
    {
        "id": "1222",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1223",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1224",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1225",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1226",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1227",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1228",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1229",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122C",
        "text": "Thrombotic cardiac embolism"
    },
    {
        "id": "122D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122I",
        "text": "Louse-borne relapsing fever"
    },
    {
        "id": "122J",
        "text": "Tick-borne relapsing fever"
    },
    {
        "id": "122H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122K",
        "text": "Diffuse inflammatory erythemas"
    },
    {
        "id": "119E",
        "text": "Other injury or harm from surgical or medical care, not elsewhere classified"
    },
    {
        "id": "119S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "121K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122M",
        "text": "Disorders with hearing impairment"
    },
    {
        "id": "122N",
        "text": "Cutaneous wounds, injuries or scars"
    },
    {
        "id": "1114",
        "text": "Benign dermal fibrous or fibrohistiocytic neoplasms"
    },
    {
        "id": "122O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122Q",
        "text": "Other Vincent infections"
    },
    {
        "id": "122R",
        "text": "Other Vincent infections"
    },
    {
        "id": "122S",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "122T",
        "text": "Non-pyogenic bacterial infections of the skin"
    },
    {
        "id": "121N",
        "text": "Dirofilariasis"
    },
    {
        "id": "122U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122W",
        "text": "Viral infections of the central nervous system"
    },
    {
        "id": "120S",
        "text": "Primary midline abdominal wall hernia"
    },
    {
        "id": "120R",
        "text": "Hernias"
    },
    {
        "id": "120P",
        "text": "Stress ulcer of duodenum"
    },
    {
        "id": "120O",
        "text": "Duodenal ulcer due to IgA vasculitis"
    },
    {
        "id": "120N",
        "text": "Stress ulcer of stomach"
    },
    {
        "id": "1206",
        "text": "Duodenitis"
    },
    {
        "id": "119N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "119L",
        "text": "Functional constipation of infants, toddlers or children"
    },
    {
        "id": "119K",
        "text": "Functional diarrhoea of infants, toddlers or children"
    },
    {
        "id": "119M",
        "text": "Functional vomiting or aerophagia in childhood"
    },
    {
        "id": "104B",
        "text": "Amoeboma of large intestine"
    },
    {
        "id": "1049",
        "text": "Enteritis due to Entamoeba"
    },
    {
        "id": "119I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "102N",
        "text": "Cystic or dysplastic kidney disease"
    },
    {
        "id": "1039",
        "text": "Cystic hygroma in fetus"
    },
    {
        "id": "122Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "102H",
        "text": "Human immunodeficiency virus disease associated with Kaposi sarcoma"
    },
    {
        "id": "102G",
        "text": "Human immunodeficiency virus disease associated with Kaposi sarcoma"
    },
    {
        "id": "102J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122Z",
        "text": "Neuromyelitis optica"
    },
    {
        "id": "1230",
        "text": "Infections of the peripheral nerves"
    },
    {
        "id": "1231",
        "text": "Optic neuritis"
    },
    {
        "id": "1233",
        "text": "Cutaneous cysts"
    },
    {
        "id": "1234",
        "text": "Coccidioidomycosis"
    },
    {
        "id": "1235",
        "text": "Pulmonary coccidioidomycosis"
    },
    {
        "id": "1237",
        "text": "Primary cutaneous coccidioidomycosis"
    },
    {
        "id": "1236",
        "text": "Disseminated coccidioidomycosis"
    },
    {
        "id": "1238",
        "text": "Extrathoracic coccidioidomycosis"
    },
    {
        "id": "1239",
        "text": "Diseases of external ear"
    },
    {
        "id": "123A",
        "text": "Certain specified diseases of stomach"
    },
    {
        "id": "123B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123C",
        "text": "Whipple disease"
    },
    {
        "id": "123D",
        "text": "Duodenitis due to Whipple disease"
    },
    {
        "id": "123E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123F",
        "text": "Primary non-midline abdominal wall hernia"
    },
    {
        "id": "123G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123N",
        "text": "Sequelae of infectious diseases"
    },
    {
        "id": "123O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123T",
        "text": "Localised hyperhidrosis"
    },
    {
        "id": "123U",
        "text": "Pneumonitis"
    },
    {
        "id": "123V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "123W",
        "text": "Ovarian hyperstimulation syndrome"
    },
    {
        "id": "123X",
        "text": "Penile fibromatosis"
    },
    {
        "id": "123Y",
        "text": "Diseases of the genitourinary system"
    },
    {
        "id": "123Z",
        "text": "Other conditions of the genitourinary system"
    },
    {
        "id": "1240",
        "text": "Postprocedural disorders of genitourinary system"
    },
    {
        "id": "1241",
        "text": "Prolapse of vaginal vault after hysterectomy"
    },
    {
        "id": "1242",
        "text": "Postprocedural pelvic peritoneal adhesions"
    },
    {
        "id": "1243",
        "text": "Postprocedural urethral stricture"
    },
    {
        "id": "1244",
        "text": "Postoperative adhesions of vagina"
    },
    {
        "id": "1245",
        "text": "Malfunction or complication of external stoma of urinary tract"
    },
    {
        "id": "1246",
        "text": "Postprocedural renal failure"
    },
    {
        "id": "1247",
        "text": "Stress incontinence"
    },
    {
        "id": "1248",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1249",
        "text": "Cystocele"
    },
    {
        "id": "124A",
        "text": "Female urethrocele"
    },
    {
        "id": "124C",
        "text": "Rectocele"
    },
    {
        "id": "124D",
        "text": "Incomplete uterovaginal prolapse"
    },
    {
        "id": "124B",
        "text": "Vaginal enterocele"
    },
    {
        "id": "124E",
        "text": "Complete uterovaginal prolapse"
    },
    {
        "id": "124F",
        "text": "Glomerular diseases"
    },
    {
        "id": "124G",
        "text": "Nephrotic syndrome"
    },
    {
        "id": "124H",
        "text": "Persistent proteinuria or albuminuria"
    },
    {
        "id": "124I",
        "text": "Kidney failure"
    },
    {
        "id": "124J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "124K",
        "text": "Acute kidney failure"
    },
    {
        "id": "124L",
        "text": "Chronic kidney disease"
    },
    {
        "id": "124M",
        "text": "Chronic kidney disease, stage 1"
    },
    {
        "id": "124N",
        "text": "Chronic kidney disease, stage 4"
    },
    {
        "id": "124O",
        "text": "Chronic kidney disease, stage 5"
    },
    {
        "id": "124P",
        "text": "Chronic kidney disease, stage 2"
    },
    {
        "id": "124Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "124R",
        "text": "Urolithiasis"
    },
    {
        "id": "124S",
        "text": "Calculus of upper urinary tract"
    },
    {
        "id": "124T",
        "text": "Calculus of ureter"
    },
    {
        "id": "124U",
        "text": "Calculus of kidney"
    },
    {
        "id": "124V",
        "text": "Calculus of lower urinary tract"
    },
    {
        "id": "124W",
        "text": "Calculus of bowel segments for urinary diversion"
    },
    {
        "id": "124X",
        "text": "Calculus in urethra"
    },
    {
        "id": "124Y",
        "text": "Calculus in bladder"
    },
    {
        "id": "124Z",
        "text": "Renal tubulo-interstitial diseases"
    },
    {
        "id": "1250",
        "text": "Tubulo-interstitial nephritis, not specified as acute or chronic"
    },
    {
        "id": "1251",
        "text": "Obstructive or reflux nephropathy"
    },
    {
        "id": "1252",
        "text": "Hydronephrosis with ureteropelvic junction obstruction"
    },
    {
        "id": "1253",
        "text": "Hydronephrosis with ureteral orifice obstruction"
    },
    {
        "id": "1254",
        "text": "Malignant neoplasms of gallbladder"
    },
    {
        "id": "1255",
        "text": "Acute tubulo-interstitial nephritis"
    },
    {
        "id": "1256",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1257",
        "text": "Chronic tubulo-interstitial nephritis"
    },
    {
        "id": "1258",
        "text": "Malignant neoplasms of proximal biliary tract, cystic duct"
    },
    {
        "id": "1259",
        "text": "Malignant neoplasms of biliary tract, distal bile duct"
    },
    {
        "id": "125A",
        "text": "Malignant neoplasms of ampulla of Vater"
    },
    {
        "id": "125B",
        "text": "Malignant neoplasms of other or unspecified parts of biliary tract"
    },
    {
        "id": "125C",
        "text": "Carcinoma in situ of gallbladder, biliary tract or ampulla of Vater"
    },
    {
        "id": "125D",
        "text": "Benign neoplasm of gallbladder, extrahepatic bile ducts or ampulla of Vater"
    },
    {
        "id": "125E",
        "text": "Spontaneous bacterial peritonitis"
    },
    {
        "id": "125F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "125H",
        "text": "Balkan nephropathy"
    },
    {
        "id": "125I",
        "text": "Nephropathy induced by heavy metals"
    },
    {
        "id": "125J",
        "text": "Pyonephrosis"
    },
    {
        "id": "125K",
        "text": "Urethral caruncle"
    },
    {
        "id": "125L",
        "text": "Prolapsed urethral mucosa"
    },
    {
        "id": "125M",
        "text": "Urethral diverticulum"
    },
    {
        "id": "125N",
        "text": "Neuromuscular dysfunction of bladder, not elsewhere classified"
    },
    {
        "id": "125O",
        "text": "Cystitis"
    },
    {
        "id": "125P",
        "text": "Trigonitis"
    },
    {
        "id": "125Q",
        "text": "Interstitial cystitis"
    },
    {
        "id": "125R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "125S",
        "text": "Other disorders of bladder"
    },
    {
        "id": "125T",
        "text": "Vesico-cutaneous fistula"
    },
    {
        "id": "125U",
        "text": "Bladder neck obstruction"
    },
    {
        "id": "125V",
        "text": "Rupture of bladder, nontraumatic"
    },
    {
        "id": "125W",
        "text": "Diverticulum of bladder"
    },
    {
        "id": "125X",
        "text": "Vesical fistula, not elsewhere classified"
    },
    {
        "id": "125Y",
        "text": "Urethral stricture"
    },
    {
        "id": "125Z",
        "text": "Urethritis and urethral syndrome"
    },
    {
        "id": "1260",
        "text": "Nonspecific urethritis"
    },
    {
        "id": "1261",
        "text": "Urethral abscess"
    },
    {
        "id": "1262",
        "text": "Urinary incontinence"
    },
    {
        "id": "1263",
        "text": "Vesicovaginal fistula"
    },
    {
        "id": "1264",
        "text": "Urethral fistula"
    },
    {
        "id": "1265",
        "text": "Fistulae involving female genital tract"
    },
    {
        "id": "1266",
        "text": "Female genital tract-skin fistulae"
    },
    {
        "id": "1267",
        "text": "Fistula of large intestine to vagina"
    },
    {
        "id": "1268",
        "text": "Certain specified disorders of kidney or ureter"
    },
    {
        "id": "1269",
        "text": "Ischaemia or infarction of kidney"
    },
    {
        "id": "126A",
        "text": "Hydroureter"
    },
    {
        "id": "126B",
        "text": "Ureteral kinking or deviation without obstruction"
    },
    {
        "id": "126C",
        "text": "Smooth contracted kidney"
    },
    {
        "id": "126D",
        "text": "Small kidney"
    },
    {
        "id": "126E",
        "text": "Disorders with impaired tubular function, not elsewhere classified"
    },
    {
        "id": "126F",
        "text": "Nephrogenic diabetes insipidus"
    },
    {
        "id": "126G",
        "text": "Disorders of breast"
    },
    {
        "id": "126H",
        "text": "Benign breast disease"
    },
    {
        "id": "126I",
        "text": "Fibrocystic change"
    },
    {
        "id": "126J",
        "text": "Certain specified disorders of breast"
    },
    {
        "id": "126K",
        "text": "Other signs or symptoms in breast"
    },
    {
        "id": "126M",
        "text": "Mastodynia"
    },
    {
        "id": "126L",
        "text": "Mastodynia"
    },
    {
        "id": "126N",
        "text": "Mastodynia"
    },
    {
        "id": "126O",
        "text": "Galactorrhoea not associated with childbirth"
    },
    {
        "id": "126P",
        "text": "Galactorrhoea not associated with childbirth"
    },
    {
        "id": "126Q",
        "text": "Fissure or fistula of nipple"
    },
    {
        "id": "126R",
        "text": "Atrophy of breast"
    },
    {
        "id": "126S",
        "text": "Mammary duct ectasia"
    },
    {
        "id": "126T",
        "text": "Fat necrosis of breast"
    },
    {
        "id": "126U",
        "text": "Inflammatory disorders of breast"
    },
    {
        "id": "126V",
        "text": "Hypertrophy of breast"
    },
    {
        "id": "126W",
        "text": "Noninflammatory disorders of female genital tract"
    },
    {
        "id": "126X",
        "text": "Endometriosis"
    },
    {
        "id": "126Y",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "126Z",
        "text": "Deep endometriosis"
    },
    {
        "id": "1271",
        "text": "Maffucci syndrome"
    },
    {
        "id": "1272",
        "text": "Volume depletion"
    },
    {
        "id": "1274",
        "text": "Septate vagina"
    },
    {
        "id": "1275",
        "text": "Congenital rectovaginal fistula"
    },
    {
        "id": "1273",
        "text": "Absence of vagina"
    },
    {
        "id": "1276",
        "text": "Tight hymenal ring"
    },
    {
        "id": "1277",
        "text": "Stricture or atresia of vagina"
    },
    {
        "id": "1278",
        "text": "Haematocolpos"
    },
    {
        "id": "1279",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "127A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "127B",
        "text": "Leukoplakia of vagina"
    },
    {
        "id": "127C",
        "text": "Amenorrhoea"
    },
    {
        "id": "127D",
        "text": "Abnormal uterine or vaginal bleeding"
    },
    {
        "id": "127E",
        "text": "Postcoital or contact bleeding"
    },
    {
        "id": "127F",
        "text": "Postmenopausal uterine bleeding"
    },
    {
        "id": "127G",
        "text": "Ovulation bleeding"
    },
    {
        "id": "127H",
        "text": "Complications associated with medically assisted reproduction"
    },
    {
        "id": "127I",
        "text": "Complications of attempted embryo transfer or medically assisted insemination"
    },
    {
        "id": "127J",
        "text": "Female pelvic pain associated with genital organs or menstrual cycle"
    },
    {
        "id": "127K",
        "text": "Premenstrual tension syndrome"
    },
    {
        "id": "127L",
        "text": "Dysmenorrhoea"
    },
    {
        "id": "127M",
        "text": "Mittelschmerz"
    },
    {
        "id": "127N",
        "text": "Dyspareunia"
    },
    {
        "id": "127O",
        "text": "Menopausal or certain specified perimenopausal disorders"
    },
    {
        "id": "127P",
        "text": "States associated with artificial menopause"
    },
    {
        "id": "127Q",
        "text": "Postmenopausal atrophic vaginitis"
    },
    {
        "id": "127R",
        "text": "Menopausal or female climacteric states"
    },
    {
        "id": "127T",
        "text": "Agenesis or aplasia of uterine body"
    },
    {
        "id": "127U",
        "text": "Agenesis or aplasia of cervix"
    },
    {
        "id": "127V",
        "text": "Bicornuate uterus"
    },
    {
        "id": "127W",
        "text": "Embryonic cyst of cervix"
    },
    {
        "id": "127X",
        "text": "Congenital fistulae between uterus and digestive and urinary tracts"
    },
    {
        "id": "127Y",
        "text": "Embryonic cyst of fallopian tube"
    },
    {
        "id": "127Z",
        "text": "Congenital torsion of ovary"
    },
    {
        "id": "1280",
        "text": "Congenital absence of ovary"
    },
    {
        "id": "1281",
        "text": "Fusion of labia"
    },
    {
        "id": "1284",
        "text": "Erosion or ectropion of cervix uteri"
    },
    {
        "id": "1285",
        "text": "Old laceration of cervix uteri"
    },
    {
        "id": "1287",
        "text": "Hypertrophic elongation of cervix uteri"
    },
    {
        "id": "1288",
        "text": "Hypertrophic elongation of cervix uteri"
    },
    {
        "id": "1289",
        "text": "Stricture or stenosis of cervix uteri"
    },
    {
        "id": "1286",
        "text": "Stricture or stenosis of cervix uteri"
    },
    {
        "id": "128A",
        "text": "Incompetence of cervix uteri"
    },
    {
        "id": "128B",
        "text": "Low grade squamous intraepithelial lesion of cervix uteri"
    },
    {
        "id": "128C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "128D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "128E",
        "text": "Noninflammatory disorders of vulva or perineum"
    },
    {
        "id": "128F",
        "text": "Hypertrophy of vulva"
    },
    {
        "id": "128H",
        "text": "Cyst of Bartholin gland"
    },
    {
        "id": "128I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "128J",
        "text": "Low grade squamous intraepithelial lesion of vulva"
    },
    {
        "id": "128K",
        "text": "Low grade squamous intraepithelial lesion of vulva"
    },
    {
        "id": "128L",
        "text": "Noninflammatory disorders of uterus, except cervix"
    },
    {
        "id": "128M",
        "text": "Intrauterine synechiae"
    },
    {
        "id": "128N",
        "text": "Haematometra"
    },
    {
        "id": "128O",
        "text": "Endometrial glandular hyperplasia"
    },
    {
        "id": "128P",
        "text": "Malposition of uterus"
    },
    {
        "id": "128Q",
        "text": "Follicular cyst of ovary"
    },
    {
        "id": "128R",
        "text": "Corpus luteum cyst"
    },
    {
        "id": "128S",
        "text": "Torsion of ovary, ovarian pedicle or fallopian tube"
    },
    {
        "id": "128T",
        "text": "Acquired atrophy of ovary or fallopian tube"
    },
    {
        "id": "128U",
        "text": "Haematosalpinx"
    },
    {
        "id": "128V",
        "text": "Haematoma of broad ligament"
    },
    {
        "id": "128W",
        "text": "Anorchia or microorchidia"
    },
    {
        "id": "128X",
        "text": "Cryptorchidism"
    },
    {
        "id": "128Y",
        "text": "Ectopic testis"
    },
    {
        "id": "128Z",
        "text": "Hypospadias"
    },
    {
        "id": "1290",
        "text": "Hypospadias, balanic"
    },
    {
        "id": "1292",
        "text": "Hypospadias, penoscrotal"
    },
    {
        "id": "1293",
        "text": "Hypospadias, perineal"
    },
    {
        "id": "1291",
        "text": "Hypospadias, penile"
    },
    {
        "id": "1294",
        "text": "Agenesis of vas deferens"
    },
    {
        "id": "1295",
        "text": "Epispadias"
    },
    {
        "id": "1296",
        "text": "Congenital chordee"
    },
    {
        "id": "1297",
        "text": "Micropenis or penis agenesis"
    },
    {
        "id": "1298",
        "text": "Hypoplasia of testis or scrotum"
    },
    {
        "id": "1299",
        "text": "Diseases of male genital organs"
    },
    {
        "id": "129A",
        "text": "Male infertility"
    },
    {
        "id": "129C",
        "text": "Torsion of testis, epididymis or appendices"
    },
    {
        "id": "129D",
        "text": "Hydrocele or spermatocele"
    },
    {
        "id": "129E",
        "text": "Premenstrual dysphoric disorder"
    },
    {
        "id": "129F",
        "text": "Skin disorders localised to the scalp"
    },
    {
        "id": "129H",
        "text": "Encysted hydrocele"
    },
    {
        "id": "129I",
        "text": "Spermatocele"
    },
    {
        "id": "129J",
        "text": "Low grade intraepithelial lesion of prostate"
    },
    {
        "id": "129K",
        "text": "Atrophy of prostate"
    },
    {
        "id": "129L",
        "text": "Haemorrhage of the prostate"
    },
    {
        "id": "129M",
        "text": "Calculus of prostate"
    },
    {
        "id": "129N",
        "text": "Inflammatory and other diseases of prostate"
    },
    {
        "id": "129O",
        "text": "Chronic prostatitis"
    },
    {
        "id": "129P",
        "text": "Abscess of prostate"
    },
    {
        "id": "129R",
        "text": "Hyperplasia of prostate"
    },
    {
        "id": "129S",
        "text": "Vascular disorders of male genital organs"
    },
    {
        "id": "129T",
        "text": "Atrophy of testis"
    },
    {
        "id": "129U",
        "text": "Redundant prepuce, phimosis or paraphimosis"
    },
    {
        "id": "129V",
        "text": "Male erectile dysfunction"
    },
    {
        "id": "129W",
        "text": "Priapism"
    },
    {
        "id": "129X",
        "text": "Inflammatory disorders of male genital organs, not elsewhere classified"
    },
    {
        "id": "129Y",
        "text": "Inflammatory disorders of spermatic cord, tunica vaginalis or vas deferens"
    },
    {
        "id": "129Z",
        "text": "Inflammatory disorders of seminal vesicle"
    },
    {
        "id": "1300",
        "text": "Inflammatory disorders of scrotum"
    },
    {
        "id": "1301",
        "text": "Denys-Drash syndrome"
    },
    {
        "id": "1302",
        "text": "Heavy chain deposition disease"
    },
    {
        "id": "1303",
        "text": "Light and heavy chain deposition disease"
    },
    {
        "id": "1304",
        "text": "Light chain deposition disease"
    },
    {
        "id": "1305",
        "text": "AL amyloidosis"
    },
    {
        "id": "1306",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1307",
        "text": "Frasier syndrome"
    },
    {
        "id": "1308",
        "text": "Premenstrual dysphoric disorder"
    },
    {
        "id": "1309",
        "text": "Anti-glomerular basement membrane antibody mediated disease"
    },
    {
        "id": "130A",
        "text": "Vulvodynia"
    },
    {
        "id": "130B",
        "text": "Menopausal hot flush"
    },
    {
        "id": "130C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "130D",
        "text": "Female pelvic floor dysfunction"
    },
    {
        "id": "130F",
        "text": "Urge incontinence associated with pelvic organ prolapse"
    },
    {
        "id": "130G",
        "text": "Reflex incontinence"
    },
    {
        "id": "130H",
        "text": "Overflow incontinence associated with pelvic organ prolapse"
    },
    {
        "id": "130I",
        "text": "Urinary incontinence associated with pelvic organ prolapse"
    },
    {
        "id": "130J",
        "text": "Mixed urinary incontinence associated with pelvic organ prolapse"
    },
    {
        "id": "130K",
        "text": "Functional bladder disorders associated with pelvic organ prolapse"
    },
    {
        "id": "130L",
        "text": "Absent or diminished bladder sensation"
    },
    {
        "id": "130M",
        "text": "Overactive bladder associated with pelvic organ prolapse"
    },
    {
        "id": "130N",
        "text": "Sexual dysfunction associated with pelvic organ prolapse"
    },
    {
        "id": "130O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "130P",
        "text": "Obstructed intercourse"
    },
    {
        "id": "130Q",
        "text": "Anorectal dysfunction associated with pelvic organ prolapse"
    },
    {
        "id": "130R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "130S",
        "text": "Incontinence of flatus"
    },
    {
        "id": "130T",
        "text": "Pelvic floor muscle disruption"
    },
    {
        "id": "130U",
        "text": "Pregnancy, childbirth or the puerperium"
    },
    {
        "id": "130V",
        "text": "Extraurethral urinary incontinence"
    },
    {
        "id": "130Y",
        "text": "Levator avulsion from symphysis pubis"
    },
    {
        "id": "130Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1310",
        "text": "Oligospermia"
    },
    {
        "id": "1312",
        "text": "Asthenospermia"
    },
    {
        "id": "1313",
        "text": "Teratospermia"
    },
    {
        "id": "1314",
        "text": "Azoospermia"
    },
    {
        "id": "1315",
        "text": "Diseases of the urinary system"
    },
    {
        "id": "1316",
        "text": "Glomerular disease classified by clinical features or syndromes"
    },
    {
        "id": "1317",
        "text": "Nephritic syndrome"
    },
    {
        "id": "1318",
        "text": "Clinical findings in specimens from the urinary system"
    },
    {
        "id": "1319",
        "text": "Diffuse mesangial sclerosis"
    },
    {
        "id": "131A",
        "text": "Fibronectin glomerulopathy"
    },
    {
        "id": "131B",
        "text": "Lipoprotein glomerulopathy"
    },
    {
        "id": "131C",
        "text": "Pauci-immune proliferative glomerulonephritis"
    },
    {
        "id": "131D",
        "text": "Glomerular disease classified by aetiology"
    },
    {
        "id": "131E",
        "text": "Glomerular disease associated with other major nonrenal features of mono-genetic aetiology"
    },
    {
        "id": "131F",
        "text": "Chronic kidney disease with mineral or bone disease"
    },
    {
        "id": "131G",
        "text": "Staghorn calculus"
    },
    {
        "id": "131H",
        "text": "Hydronephrosis due to bladder obstruction"
    },
    {
        "id": "131I",
        "text": "Hydronephrosis due to bladder obstruction, congenital"
    },
    {
        "id": "131J",
        "text": "Autosomal dominant tubulointerstitial disease Type 2"
    },
    {
        "id": "131K",
        "text": "Juvenile nephronophthisis"
    },
    {
        "id": "131L",
        "text": "Chronic urate nephropathy"
    },
    {
        "id": "131M",
        "text": "Acute tubular necrosis"
    },
    {
        "id": "131N",
        "text": "Acute renal papillary necrosis"
    },
    {
        "id": "131O",
        "text": "Nephrocalcinosis"
    },
    {
        "id": "131P",
        "text": "Cystic or dysplastic kidney disease"
    },
    {
        "id": "131Q",
        "text": "Clinical findings on diagnostic imaging of lung"
    },
    {
        "id": "131R",
        "text": "Abnormalities of breathing"
    },
    {
        "id": "131S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "131T",
        "text": "Haemorrhage from respiratory passages"
    },
    {
        "id": "131U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "131V",
        "text": "Dyspnoea"
    },
    {
        "id": "131W",
        "text": "Pain in throat or chest"
    },
    {
        "id": "131X",
        "text": "Other chest pain"
    },
    {
        "id": "125G",
        "text": "Autosomal dominant tubulointerstitial disease, Type 1"
    },
    {
        "id": "131Y",
        "text": "Axial spondyloarthritis"
    },
    {
        "id": "131Z",
        "text": "Simple renal cyst"
    },
    {
        "id": "1320",
        "text": "Complex renal cyst"
    },
    {
        "id": "1321",
        "text": "Subscapular or perirenal urinoma"
    },
    {
        "id": "1322",
        "text": "Autosomal dominant polycystic kidney disease"
    },
    {
        "id": "1323",
        "text": "Hereditary cystic or dysplastic kidney disease, recessive inheritance"
    },
    {
        "id": "1324",
        "text": "Nephronophthisis"
    },
    {
        "id": "1325",
        "text": "Hereditary cystic or dysplastic kidney disease, chromosomal abnormalities"
    },
    {
        "id": "1326",
        "text": "Noninfectious cystitis"
    },
    {
        "id": "1327",
        "text": "Fistula of the genitourinary tract"
    },
    {
        "id": "1328",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1329",
        "text": "Obstetric Fistula"
    },
    {
        "id": "132A",
        "text": "Vesicovaginal fistula with no involvement of continence mechanism"
    },
    {
        "id": "132B",
        "text": "Vesicovaginal fistula with involvement of continence mechanism"
    },
    {
        "id": "132C",
        "text": "Non-circumferential urethrovaginal fistula without involvement of continence mechanism, proximal urethra or bladder neck"
    },
    {
        "id": "132D",
        "text": "Non-circumferential urethrovaginal fistula with involvement of continence mechanism, proximal urethra or bladder neck"
    },
    {
        "id": "132E",
        "text": "Non-circumferential urethrovaginal fistula with severe scar or extensive tissue loss with or without involvement of continence mechanism"
    },
    {
        "id": "132F",
        "text": "Circumferential urethrovaginal fistula without involvement of continence mechanism"
    },
    {
        "id": "132G",
        "text": "Circumferential urethrovaginal fistula with involvement of continence mechanism"
    },
    {
        "id": "132H",
        "text": "Circumferential urethrovaginal fistula with severe scar with or without involvement of continence mechanism"
    },
    {
        "id": "132I",
        "text": "Rectovaginal fistula"
    },
    {
        "id": "132J",
        "text": "Combined urethrovesicovaginal fistula"
    },
    {
        "id": "132K",
        "text": "Vaginal stenosis or gynatresia related to obstetric fistula"
    },
    {
        "id": "132L",
        "text": "Nephroptosis"
    },
    {
        "id": "132M",
        "text": "Ureteral fistula"
    },
    {
        "id": "132N",
        "text": "Macroscopic changes of size of the kidney"
    },
    {
        "id": "132O",
        "text": "Irregularly contracted kidney"
    },
    {
        "id": "132P",
        "text": "Renal tubular function disorders"
    },
    {
        "id": "132Q",
        "text": "Bartter syndrome"
    },
    {
        "id": "132R",
        "text": "Fanconi syndrome"
    },
    {
        "id": "132S",
        "text": "Pseudohypoaldosteronism type 1"
    },
    {
        "id": "132T",
        "text": "Hypotonia-cystinuria type 1"
    },
    {
        "id": "132U",
        "text": "Renal tubular acidosis"
    },
    {
        "id": "132V",
        "text": "Proximal renal tubular acidosis with ocular abnormalities and intellectual deficit"
    },
    {
        "id": "132W",
        "text": "Tubular disorders of sodium or potassium transport"
    },
    {
        "id": "132X",
        "text": "Aminoaciduria"
    },
    {
        "id": "132Y",
        "text": "Renal hypocalciuria"
    },
    {
        "id": "132Z",
        "text": "Disorders of water excretion"
    },
    {
        "id": "1330",
        "text": "Renal glycosuria"
    },
    {
        "id": "1331",
        "text": "Disorders of calcium or phosphate excretion"
    },
    {
        "id": "1332",
        "text": "Diseases of the female genital system"
    },
    {
        "id": "1333",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1334",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1335",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1336",
        "text": "Breast abscess"
    },
    {
        "id": "1337",
        "text": "Endometriosis-related cul-de-sac adhesions"
    },
    {
        "id": "1338",
        "text": "Salpingitis isthmica nodosa"
    },
    {
        "id": "1339",
        "text": "Congenital abnormalities of vagina"
    },
    {
        "id": "133A",
        "text": "Congenital abnormalities of vagina"
    },
    {
        "id": "133B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "133C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "133D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "133E",
        "text": "Acquired abnormalities of vagina"
    },
    {
        "id": "133F",
        "text": "Vaginal foreign body"
    },
    {
        "id": "133G",
        "text": "Vaginal haematoma"
    },
    {
        "id": "133H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "133I",
        "text": "Lactational amenorrhoea"
    },
    {
        "id": "133J",
        "text": "Abnormal frequency of uterine bleeding"
    },
    {
        "id": "133K",
        "text": "Postponement of menstruation"
    },
    {
        "id": "133L",
        "text": "Abnormal regularity of uterine bleeding"
    },
    {
        "id": "133M",
        "text": "Frequent menstrual bleeding"
    },
    {
        "id": "133N",
        "text": "Abnormal duration of uterine bleeding"
    },
    {
        "id": "133O",
        "text": "Abnormal volume of uterine bleeding"
    },
    {
        "id": "133P",
        "text": "Intermenstrual bleeding"
    },
    {
        "id": "133Q",
        "text": "Premenstrual symptom or complaint"
    },
    {
        "id": "133R",
        "text": "Cyclic pelvic pain"
    },
    {
        "id": "133S",
        "text": "Noncyclic pelvic pain"
    },
    {
        "id": "133U",
        "text": "Pain related to vulva, vagina or pelvic floor"
    },
    {
        "id": "133V",
        "text": "Vulval pain"
    },
    {
        "id": "133W",
        "text": "Perineal pain"
    },
    {
        "id": "133X",
        "text": "Female genital pain"
    },
    {
        "id": "133Y",
        "text": "Menopause"
    },
    {
        "id": "133Z",
        "text": "Menopausal transition"
    },
    {
        "id": "1340",
        "text": "Contact with health services for menopausal counselling"
    },
    {
        "id": "1341",
        "text": "Contact with health services for preconception counselling"
    },
    {
        "id": "1342",
        "text": "Contact with health services for fertility preservation counselling"
    },
    {
        "id": "1343",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1344",
        "text": "Contact with health services by gestational carrier"
    },
    {
        "id": "1345",
        "text": "Contact with health services for preimplantation genetic diagnosis"
    },
    {
        "id": "1346",
        "text": "Contact with health services for preimplantation genetic screening"
    },
    {
        "id": "127S",
        "text": "Female infertility"
    },
    {
        "id": "1347",
        "text": "Female Genital Mutilation"
    },
    {
        "id": "1348",
        "text": "Recurrent pregnancy loss"
    },
    {
        "id": "1349",
        "text": "Adenomyosis"
    },
    {
        "id": "134C",
        "text": "Uterovaginal malformation due to diethylstilbestrol syndrome"
    },
    {
        "id": "134D",
        "text": "Congenital absence of fallopian tube"
    },
    {
        "id": "134E",
        "text": "Atresia of fallopian tube"
    },
    {
        "id": "134F",
        "text": "Accessory fallopian tube"
    },
    {
        "id": "134G",
        "text": "Accessory ovary"
    },
    {
        "id": "134J",
        "text": "Vulvar laceration"
    },
    {
        "id": "134K",
        "text": "Vulvar haematoma"
    },
    {
        "id": "134H",
        "text": "Congenital abnormalities of vulva or perineum"
    },
    {
        "id": "134I",
        "text": "Acquired abnormalities of vulva or perineum"
    },
    {
        "id": "134L",
        "text": "Congenital abnormalities of uterus, except cervix"
    },
    {
        "id": "134M",
        "text": "Acquired abnormalities of uterus, except cervix"
    },
    {
        "id": "134N",
        "text": "Acquired absence of the uterus"
    },
    {
        "id": "134O",
        "text": "Noninflammatory disorders of ovary"
    },
    {
        "id": "1270",
        "text": "Noninflammatory disorders of vagina"
    },
    {
        "id": "1282",
        "text": "Noninflammatory disorders of cervix uteri"
    },
    {
        "id": "134P",
        "text": "Noninflammatory disorders of fallopian tube"
    },
    {
        "id": "134Q",
        "text": "Congenital abnormalities of ovary"
    },
    {
        "id": "134R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "134S",
        "text": "Acquired abnormalities of ovary"
    },
    {
        "id": "134T",
        "text": "Cystic teratoma"
    },
    {
        "id": "134U",
        "text": "Ovarian fibroma"
    },
    {
        "id": "134V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "134W",
        "text": "Noninflammatory disorders of broad ligament"
    },
    {
        "id": "134X",
        "text": "Congenital abnormalities of fallopian tube"
    },
    {
        "id": "134Y",
        "text": "Acquired abnormalities of fallopian tube"
    },
    {
        "id": "134Z",
        "text": "Hydrosalpinx"
    },
    {
        "id": "1350",
        "text": "Pelvic peritoneal adhesions of unknown or combined origin"
    },
    {
        "id": "1351",
        "text": "Diseases of the male genital system"
    },
    {
        "id": "1352",
        "text": "Structural developmental anomalies of the male genital system"
    },
    {
        "id": "1353",
        "text": "Hypospadias, coronal"
    },
    {
        "id": "1354",
        "text": "Hypospadias, glandular"
    },
    {
        "id": "1355",
        "text": "Hypospadias, scrotal"
    },
    {
        "id": "1356",
        "text": "Bifid scrotum"
    },
    {
        "id": "1357",
        "text": "Polyorchidism"
    },
    {
        "id": "1358",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1359",
        "text": "Retrograde ejaculation"
    },
    {
        "id": "135B",
        "text": "Torsion of testis"
    },
    {
        "id": "135C",
        "text": "Torsion of epididymis"
    },
    {
        "id": "135D",
        "text": "Torsion of hydatids"
    },
    {
        "id": "135E",
        "text": "Redundant prepuce"
    },
    {
        "id": "135F",
        "text": "Frenulum breve"
    },
    {
        "id": "135H",
        "text": "Paraphimosis"
    },
    {
        "id": "135I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "135J",
        "text": "Postpartum thyroiditis"
    },
    {
        "id": "135K",
        "text": "Diabetes mellitus in pregnancy"
    },
    {
        "id": "135L",
        "text": "Liver disorders in pregnancy, childbirth or the puerperium"
    },
    {
        "id": "135M",
        "text": "Haemorrhoids in pregnancy"
    },
    {
        "id": "135N",
        "text": "Complications of anaesthesia during pregnancy"
    },
    {
        "id": "135O",
        "text": "Pulmonary complications of anaesthesia during pregnancy"
    },
    {
        "id": "135P",
        "text": "Failed or difficult intubation during pregnancy"
    },
    {
        "id": "135Q",
        "text": "Cardiac complications of anaesthesia during pregnancy"
    },
    {
        "id": "135R",
        "text": "Toxic reaction to local anaesthesia during pregnancy"
    },
    {
        "id": "135S",
        "text": "Central nervous system complications of anaesthesia during pregnancy"
    },
    {
        "id": "135T",
        "text": "Spinal or epidural anaesthesia-induced headache during pregnancy"
    },
    {
        "id": "135V",
        "text": "Diseases of pulp or periapical tissues"
    },
    {
        "id": "135W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "135X",
        "text": "Redundant prepuce, phimosis or paraphimosis"
    },
    {
        "id": "135Y",
        "text": "Redundant prepuce, phimosis or paraphimosis"
    },
    {
        "id": "135Z",
        "text": "Male erectile dysfunction"
    },
    {
        "id": "1360",
        "text": "Male erectile dysfunction"
    },
    {
        "id": "1362",
        "text": "Torsion of testis"
    },
    {
        "id": "1363",
        "text": "Torsion of hydatids"
    },
    {
        "id": "1364",
        "text": "Certain specified diseases of urinary system"
    },
    {
        "id": "1365",
        "text": "Symptoms, signs or clinical findings of the genitourinary system"
    },
    {
        "id": "1366",
        "text": "Symptom or complaint of other male genital organs"
    },
    {
        "id": "1367",
        "text": "Symptom or complaint of male sexual function"
    },
    {
        "id": "1369",
        "text": "Chlamydial female pelvic inflammatory disease"
    },
    {
        "id": "1368",
        "text": "Late syphilitic female pelvic inflammatory disease"
    },
    {
        "id": "136D",
        "text": "Carcinoma in situ of penis"
    },
    {
        "id": "136B",
        "text": "Extramammary Paget disease of skin"
    },
    {
        "id": "136E",
        "text": "Skin tags or polyps"
    },
    {
        "id": "136F",
        "text": "Problems of the prostate"
    },
    {
        "id": "136G",
        "text": "Problems of the testis and epididymis"
    },
    {
        "id": "136H",
        "text": "Pemphigus vulgaris"
    },
    {
        "id": "136I",
        "text": "Malignant neoplasms of male genital organs"
    },
    {
        "id": "129Q",
        "text": "Prostatocystitis"
    },
    {
        "id": "135A",
        "text": "Premature ejaculation disorder (TM1)"
    },
    {
        "id": "135G",
        "text": "Phimosis"
    },
    {
        "id": "136J",
        "text": "Myelitis due to Borrelia burgdorferi"
    },
    {
        "id": "136K",
        "text": "Meningitis due to Borrelia burgdorferi"
    },
    {
        "id": "136L",
        "text": "Lichen planus of penis"
    },
    {
        "id": "136M",
        "text": "Deletions of the short arm of chromosome 4"
    },
    {
        "id": "136N",
        "text": "Clinical findings on antenatal screening of mother"
    },
    {
        "id": "136O",
        "text": "Abnormal chromosomal or genetic finding on antenatal screening of mother"
    },
    {
        "id": "136P",
        "text": "Abnormal radiological finding on antenatal screening of mother"
    },
    {
        "id": "136Q",
        "text": "Abnormal ultrasonic finding on antenatal screening of mother"
    },
    {
        "id": "136R",
        "text": "Abnormal cytological finding on antenatal screening of mother"
    },
    {
        "id": "136T",
        "text": "Abnormal haematological finding on antenatal screening of mother"
    },
    {
        "id": "136S",
        "text": "Abnormal biochemical finding on antenatal screening of mother"
    },
    {
        "id": "136U",
        "text": "Maternal care for other conditions predominantly related to pregnancy"
    },
    {
        "id": "136V",
        "text": "Retained intrauterine contraceptive device in pregnancy"
    },
    {
        "id": "136W",
        "text": "Pregnancy care of habitual aborter"
    },
    {
        "id": "136X",
        "text": "Low weight gain in pregnancy"
    },
    {
        "id": "136Y",
        "text": "Excessive weight gain in pregnancy"
    },
    {
        "id": "136Z",
        "text": "Subluxation of symphysis pubis in pregnancy, childbirth or the puerperium"
    },
    {
        "id": "1370",
        "text": "Maternal hypotension syndrome"
    },
    {
        "id": "1371",
        "text": "Malnutrition in pregnancy"
    },
    {
        "id": "1372",
        "text": "Delivery"
    },
    {
        "id": "1373",
        "text": "Multiple delivery"
    },
    {
        "id": "1374",
        "text": "Multiple delivery, all by forceps or vacuum extractor"
    },
    {
        "id": "1375",
        "text": "Multiple delivery, all spontaneous"
    },
    {
        "id": "1376",
        "text": "Multiple delivery, all by caesarean section"
    },
    {
        "id": "1377",
        "text": "Single delivery by caesarean section"
    },
    {
        "id": "1378",
        "text": "Single delivery by caesarean hysterectomy"
    },
    {
        "id": "1379",
        "text": "Other assisted single delivery"
    },
    {
        "id": "137A",
        "text": "Other assisted breech delivery"
    },
    {
        "id": "137B",
        "text": "Other manipulation-assisted delivery"
    },
    {
        "id": "137C",
        "text": "Delivery of viable fetus in abdominal pregnancy"
    },
    {
        "id": "137D",
        "text": "Destructive operation for delivery"
    },
    {
        "id": "137E",
        "text": "Breech extraction"
    },
    {
        "id": "137F",
        "text": "Single spontaneous delivery"
    },
    {
        "id": "137G",
        "text": "Spontaneous breech delivery"
    },
    {
        "id": "137H",
        "text": "Spontaneous vertex delivery"
    },
    {
        "id": "137I",
        "text": "Single delivery by forceps or vacuum extractor"
    },
    {
        "id": "137J",
        "text": "Oedema, proteinuria, or hypertensive disorders in pregnancy, childbirth, or the puerperium"
    },
    {
        "id": "137K",
        "text": "Pre-eclampsia superimposed on chronic hypertension"
    },
    {
        "id": "137L",
        "text": "Gestational oedema or proteinuria without hypertension"
    },
    {
        "id": "137M",
        "text": "Pre-existing hypertension complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "137N",
        "text": "Pre-existing secondary hypertension complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "137O",
        "text": "Pre-existing hypertensive heart disease complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "137P",
        "text": "Pre-existing hypertensive renal disease complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "137Q",
        "text": "Pre-existing essential hypertension complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "137R",
        "text": "Pre-eclampsia"
    },
    {
        "id": "137S",
        "text": "Mild to moderate pre-eclampsia"
    },
    {
        "id": "137T",
        "text": "Severe pre-eclampsia"
    },
    {
        "id": "137U",
        "text": "Gestational hypertension"
    },
    {
        "id": "137W",
        "text": "Eclampsia in labour"
    },
    {
        "id": "137V",
        "text": "Eclampsia"
    },
    {
        "id": "137X",
        "text": "Eclampsia in pregnancy"
    },
    {
        "id": "137Y",
        "text": "Eclampsia in the puerperium"
    },
    {
        "id": "137Z",
        "text": "Complications of labour or delivery"
    },
    {
        "id": "1380",
        "text": "Intrapartum haemorrhage with coagulation defect"
    },
    {
        "id": "1381",
        "text": "Obstructed labour due to malposition or malpresentation of fetus"
    },
    {
        "id": "1382",
        "text": "Long labour"
    },
    {
        "id": "1383",
        "text": "Prolonged first stage of labour"
    },
    {
        "id": "1384",
        "text": "Delayed delivery of successive neonates"
    },
    {
        "id": "1385",
        "text": "Prolonged second stage of labour"
    },
    {
        "id": "1386",
        "text": "Abnormalities of forces of labour"
    },
    {
        "id": "1387",
        "text": "Precipitate labour"
    },
    {
        "id": "1388",
        "text": "Other uterine inertia"
    },
    {
        "id": "1389",
        "text": "Secondary uterine inertia"
    },
    {
        "id": "138A",
        "text": "Primary uterine inertia"
    },
    {
        "id": "138B",
        "text": "Hypertonic, incoordinate, or prolonged uterine contractions"
    },
    {
        "id": "138C",
        "text": "Failed induction of labour"
    },
    {
        "id": "138D",
        "text": "Failed instrumental induction of labour"
    },
    {
        "id": "138E",
        "text": "Failed medical induction of labour"
    },
    {
        "id": "138G",
        "text": "Preterm labour without delivery"
    },
    {
        "id": "138F",
        "text": "Preterm labour or delivery"
    },
    {
        "id": "138H",
        "text": "Preterm spontaneous labour with preterm delivery"
    },
    {
        "id": "138I",
        "text": "Preterm labour with term delivery"
    },
    {
        "id": "138J",
        "text": "Perineal laceration during delivery"
    },
    {
        "id": "138K",
        "text": "Certain specified complications of labour or delivery, not elsewhere classified"
    },
    {
        "id": "138L",
        "text": "Delayed delivery after spontaneous or unspecified rupture of membranes"
    },
    {
        "id": "138M",
        "text": "Pyrexia during labour, not elsewhere classified"
    },
    {
        "id": "138N",
        "text": "Other complications of obstetric surgery or procedures"
    },
    {
        "id": "138O",
        "text": "Delayed delivery after artificial rupture of membranes"
    },
    {
        "id": "138P",
        "text": "Shock during or following labour or delivery"
    },
    {
        "id": "138Q",
        "text": "Maternal distress during labour or delivery"
    },
    {
        "id": "120A",
        "text": "Homelessness"
    },
    {
        "id": "138R",
        "text": "Burning scrotum"
    },
    {
        "id": "138T",
        "text": "Duplications of the long arm of chromosome 11"
    },
    {
        "id": "138U",
        "text": "Duplications of the short arm of chromosome 11"
    },
    {
        "id": "138V",
        "text": "Duplications of the long arm of chromosome 18"
    },
    {
        "id": "138X",
        "text": "Rickettsialpox"
    },
    {
        "id": "138W",
        "text": "Cutaneous reactions to zoonotic mites"
    },
    {
        "id": "138Y",
        "text": "Herpes simplex infection of vulva"
    },
    {
        "id": "138Z",
        "text": "Non-specific balanoposthitis"
    },
    {
        "id": "1390",
        "text": "Diseases of the blood or blood-forming organs"
    },
    {
        "id": "1391",
        "text": "Hereditary megaloblastic anaemia due to transcobalamin deficiency"
    },
    {
        "id": "1392",
        "text": "Congenital megaloblastic anaemias, vitamin B12- and folate-independent"
    },
    {
        "id": "1393",
        "text": "Acquired iron deficiency anaemia"
    },
    {
        "id": "1394",
        "text": "Acquired iron deficiency anaemia due to blood loss"
    },
    {
        "id": "1395",
        "text": "Acquired vitamin B12 deficiency anaemia"
    },
    {
        "id": "1396",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1397",
        "text": "Acquired megaloblastic anaemias due to folate deficiency"
    },
    {
        "id": "1398",
        "text": "Drug-induced folate deficiency anaemia"
    },
    {
        "id": "1399",
        "text": "Certain specified folate deficiency anaemias"
    },
    {
        "id": "139A",
        "text": "Scorbutic anaemia"
    },
    {
        "id": "139B",
        "text": "Hereditary haemolytic anaemia due to enzyme deficiency"
    },
    {
        "id": "139C",
        "text": "Hereditary spherocytosis"
    },
    {
        "id": "139D",
        "text": "Hereditary elliptocytosis"
    },
    {
        "id": "139E",
        "text": "Acquired haemolytic anaemia"
    },
    {
        "id": "139F",
        "text": "Haemolytic uraemic syndrome"
    },
    {
        "id": "139G",
        "text": "Haemolytic uraemic syndrome"
    },
    {
        "id": "139H",
        "text": "Thalassaemias"
    },
    {
        "id": "139I",
        "text": "Beta thalassaemia"
    },
    {
        "id": "139J",
        "text": "Alpha thalassaemia"
    },
    {
        "id": "139K",
        "text": "Hereditary persistence of fetal haemoglobin"
    },
    {
        "id": "139L",
        "text": "Sickle cell trait"
    },
    {
        "id": "139M",
        "text": "Compound heterozygous sickling disorders without crisis"
    },
    {
        "id": "139N",
        "text": "Hereditary methaemoglobinaemia"
    },
    {
        "id": "139O",
        "text": "Congenital aplastic anaemia"
    },
    {
        "id": "139P",
        "text": "Congenital sideroblastic anaemias"
    },
    {
        "id": "139Q",
        "text": "Congenital dyserythropoietic anaemia"
    },
    {
        "id": "139R",
        "text": "Drug-induced aplastic anaemia"
    },
    {
        "id": "139S",
        "text": "Acute posthaemorrhagic anaemia"
    },
    {
        "id": "139T",
        "text": "Anaemia due to chronic disease"
    },
    {
        "id": "139U",
        "text": "Anaemia in neoplastic disease"
    },
    {
        "id": "139V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "139W",
        "text": "Von Willebrand disease"
    },
    {
        "id": "139X",
        "text": "Haemophilia C"
    },
    {
        "id": "139Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "139Z",
        "text": "Haemophilia A"
    },
    {
        "id": "1400",
        "text": "Haemophilia B"
    },
    {
        "id": "1401",
        "text": "Haemorrhagic diseases due to acquired coagulation factor defects"
    },
    {
        "id": "1402",
        "text": "Haemorrhagic disorder due to circulating anticoagulants and coagulation factors"
    },
    {
        "id": "1403",
        "text": "Disseminated intravascular coagulation"
    },
    {
        "id": "1404",
        "text": "Immune thrombocytopenic purpura"
    },
    {
        "id": "1405",
        "text": "Secondary thrombocytopenic purpura"
    },
    {
        "id": "1406",
        "text": "Hereditary thrombophilia"
    },
    {
        "id": "1407",
        "text": "Non-thrombocytopenic purpura"
    },
    {
        "id": "1408",
        "text": "IgA vasculitis"
    },
    {
        "id": "1409",
        "text": "Diseases of spleen"
    },
    {
        "id": "140A",
        "text": "Infarction of spleen"
    },
    {
        "id": "140B",
        "text": "Hypersplenism"
    },
    {
        "id": "140C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "140D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "140E",
        "text": "Primary haemophagocytic lymphohistiocytosis"
    },
    {
        "id": "140F",
        "text": "Haemolytic anaemias"
    },
    {
        "id": "140G",
        "text": "Other anaemias"
    },
    {
        "id": "140H",
        "text": "Qualitative platelet defects"
    },
    {
        "id": "140I",
        "text": "Other haemoglobinopathies"
    },
    {
        "id": "140J",
        "text": "Anaemia in chronic infectious diseases"
    },
    {
        "id": "140K",
        "text": "Fanconi anaemia"
    },
    {
        "id": "140L",
        "text": "Reactive thrombocytosis"
    },
    {
        "id": "140M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "140N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "140O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "140P",
        "text": "Congenital iron deficiency anaemia"
    },
    {
        "id": "140Q",
        "text": "Atransferrinaemia"
    },
    {
        "id": "140R",
        "text": "Congenital or neonatal vitamin B12 deficiency anaemia"
    },
    {
        "id": "140S",
        "text": "Vitamin B12 deficiency anaemia due to congenital intrinsic factor deficiency"
    },
    {
        "id": "140T",
        "text": "Neonatal vitamin B12 deficiency anaemia"
    },
    {
        "id": "140U",
        "text": "Congenital folate deficiency anaemia"
    },
    {
        "id": "140V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "140W",
        "text": "Acquired iron deficiency anaemia due to low intake"
    },
    {
        "id": "140X",
        "text": "Acquired iron deficiency anaemia due to decreased absorption"
    },
    {
        "id": "140Y",
        "text": "Acquired iron deficiency anaemia due to gastrectomy"
    },
    {
        "id": "140Z",
        "text": "Acquired iron deficiency anaemia due to coeliac disease"
    },
    {
        "id": "1410",
        "text": "Chronic posthaemorrhagic anaemia"
    },
    {
        "id": "1411",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1412",
        "text": "Acquired iron deficiency anaemia due to increased requirement"
    },
    {
        "id": "1413",
        "text": "Congenital nutritional vitamin B12 deficiency due to maternal vitamin B12 deficiency"
    },
    {
        "id": "1414",
        "text": "Nutritional vitamin B12 deficiency due to maternal vitamin B12 deficiency in breast-fed infants"
    },
    {
        "id": "1415",
        "text": "Vitamin B12 deficiency anaemia due to low intake"
    },
    {
        "id": "1416",
        "text": "Vegan anaemia due to Vitamin B12 deficiency"
    },
    {
        "id": "1417",
        "text": "Vitamin B12 deficiency anaemia due to intrinsic factor deficiency"
    },
    {
        "id": "1418",
        "text": "Pernicious anaemia"
    },
    {
        "id": "1419",
        "text": "Pernicious anaemia associated with other autoimmune disorders"
    },
    {
        "id": "141A",
        "text": "Vitamin B12 deficiency anaemia associated with chronic atrophic gastritis"
    },
    {
        "id": "141B",
        "text": "Vitamin B12 deficiency anaemia associated with total gastrectomy"
    },
    {
        "id": "141C",
        "text": "Subacute combined cord degeneration"
    },
    {
        "id": "141D",
        "text": "Vitamin B12 deficiency anaemia due to intestinal disease"
    },
    {
        "id": "141E",
        "text": "Folate deficiency anaemia due to increased requirements"
    },
    {
        "id": "141F",
        "text": "Acquired megaloblastic anaemia due to folate deficiency in pregnancy or lactation"
    },
    {
        "id": "141G",
        "text": "Acquired megaloblastic anaemia due to folate deficiency in prematurity"
    },
    {
        "id": "141H",
        "text": "Acquired megaloblastic anaemia associated with haemolytic anaemia"
    },
    {
        "id": "141I",
        "text": "Acquired megaloblastic anaemia associated with dermatologic disorders"
    },
    {
        "id": "141J",
        "text": "Folate deficiency anaemia due to decreased intestinal absorption"
    },
    {
        "id": "141K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "141L",
        "text": "Copper deficiency anaemia"
    },
    {
        "id": "141M",
        "text": "Acquired vitamin A deficiency anaemia"
    },
    {
        "id": "141P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "141Q",
        "text": "Senile purpura"
    },
    {
        "id": "141S",
        "text": "Non-thrombocytopenic purpura"
    },
    {
        "id": "141T",
        "text": "IgA vasculitis"
    },
    {
        "id": "141U",
        "text": "Congenital hypothyroidism"
    },
    {
        "id": "141V",
        "text": "Postprocedural pelvic peritoneal adhesions"
    },
    {
        "id": "141W",
        "text": "Cystitis"
    },
    {
        "id": "141X",
        "text": "Spermatocele"
    },
    {
        "id": "141Y",
        "text": "Salmon patch"
    },
    {
        "id": "141Z",
        "text": "Port-wine stain"
    },
    {
        "id": "1425",
        "text": "Phakomatosis spilorosea"
    },
    {
        "id": "1426",
        "text": "Phakomatosis caesiomarmorata"
    },
    {
        "id": "1427",
        "text": "Phakomatosis pigmentovascularis, unclassifiable"
    },
    {
        "id": "1428",
        "text": "Unilateral naevoid telangiectasia"
    },
    {
        "id": "1429",
        "text": "Hereditary benign telangiectasia"
    },
    {
        "id": "142A",
        "text": "Developmental capillary vascular malformations of the skin"
    },
    {
        "id": "1420",
        "text": "Sturge-Weber syndrome"
    },
    {
        "id": "1421",
        "text": "Angioma serpiginosum"
    },
    {
        "id": "1422",
        "text": "Naevus anaemicus"
    },
    {
        "id": "1423",
        "text": "Phakomatosis pigmentovascularis"
    },
    {
        "id": "1424",
        "text": "Phakomatosis caesioflammea"
    },
    {
        "id": "142C",
        "text": "Blue rubber bleb naevus syndrome"
    },
    {
        "id": "142D",
        "text": "Mucocutaneous venous malformations"
    },
    {
        "id": "142E",
        "text": "Glomuvenous malformation"
    },
    {
        "id": "142F",
        "text": "Bockenheimer syndrome"
    },
    {
        "id": "142G",
        "text": "Complex or combined developmental vascular malformations involving the skin"
    },
    {
        "id": "142H",
        "text": "Angio-osteohypertrophic syndrome"
    },
    {
        "id": "142I",
        "text": "Cutis marmorata telangiectatica congenita"
    },
    {
        "id": "142J",
        "text": "Cobb syndrome"
    },
    {
        "id": "142K",
        "text": "Maffucci syndrome"
    },
    {
        "id": "142N",
        "text": "Developmental defects of hair or hair growth"
    },
    {
        "id": "142M",
        "text": "Developmental defects of hair or nails"
    },
    {
        "id": "142O",
        "text": "Temporal triangular alopecia"
    },
    {
        "id": "142P",
        "text": "Congenital sporadic alopecia"
    },
    {
        "id": "142Q",
        "text": "Naevoid hypertrichosis"
    },
    {
        "id": "142R",
        "text": "Lumbosacral naevoid hypertrichosis"
    },
    {
        "id": "142T",
        "text": "Congenital nail hypertrophy"
    },
    {
        "id": "142U",
        "text": "Congenital malalignment of the great toenails"
    },
    {
        "id": "142V",
        "text": "Congenital hypertrophy of the lateral fold of the hallux"
    },
    {
        "id": "142W",
        "text": "Frequent menstrual bleeding"
    },
    {
        "id": "142X",
        "text": "Acute respiratory distress syndrome"
    },
    {
        "id": "142Y",
        "text": "Permanent congenital hypothyroidism"
    },
    {
        "id": "142Z",
        "text": "Congenital hypothyroidism due to iodine deficiency"
    },
    {
        "id": "1430",
        "text": "Acquired hypothyroidism"
    },
    {
        "id": "1431",
        "text": "TSH-secreting pituitary adenoma"
    },
    {
        "id": "1432",
        "text": "Familial hyperthyroidism"
    },
    {
        "id": "1433",
        "text": "Selective pituitary resistance to thyroid hormone"
    },
    {
        "id": "1434",
        "text": "Acute thyroiditis"
    },
    {
        "id": "1435",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1436",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1437",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1438",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1439",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143C",
        "text": "Diabetes mellitus, other specified type"
    },
    {
        "id": "143D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143J",
        "text": "Diabetes mellitus due to clinically defined subtypes or syndromes"
    },
    {
        "id": "143K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143L",
        "text": "Diabetes mellitus, type unspecified"
    },
    {
        "id": "143N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "143U",
        "text": "Acquired vitamin E deficiency anaemia"
    },
    {
        "id": "143V",
        "text": "Acquired other vitamin B deficiency anaemia"
    },
    {
        "id": "143W",
        "text": "Acquired pyridoxine deficiency anaemia"
    },
    {
        "id": "143X",
        "text": "Acquired riboflavin deficiency anaemia"
    },
    {
        "id": "143Y",
        "text": "Acquired thiamine deficiency anaemia"
    },
    {
        "id": "143Z",
        "text": "Congenital haemolytic anaemia"
    },
    {
        "id": "1440",
        "text": "Hereditary haemolytic anaemia due to red cell membrane defects"
    },
    {
        "id": "1441",
        "text": "Familial pseudohyperkalaemia"
    },
    {
        "id": "1442",
        "text": "Heinz body anaemia"
    },
    {
        "id": "1443",
        "text": "Acquired haemolytic anaemia, immune"
    },
    {
        "id": "1444",
        "text": "Paroxysmal cold haemoglobinuria"
    },
    {
        "id": "1445",
        "text": "Alloimmune haemolytic anaemia"
    },
    {
        "id": "1446",
        "text": "Evans syndrome"
    },
    {
        "id": "1447",
        "text": "Acquired haemolytic anaemia, non-immune"
    },
    {
        "id": "1448",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1449",
        "text": "Haemoglobin Koya Dora"
    },
    {
        "id": "144A",
        "text": "Mild alpha thalassaemia diseases"
    },
    {
        "id": "144B",
        "text": "Alpha thalassaemia related syndromes"
    },
    {
        "id": "144C",
        "text": "Delta, delta-beta or gamma-delta-beta thalassaemia"
    },
    {
        "id": "144D",
        "text": "Gamma-delta-beta thalassaemia"
    },
    {
        "id": "144E",
        "text": "Sickle cell disorders or other haemoglobinopathies"
    },
    {
        "id": "144F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "144G",
        "text": "Sickle cell disease with crisis with acute chest syndrome"
    },
    {
        "id": "144H",
        "text": "Sickle cell disease with crisis with splenic sequestration"
    },
    {
        "id": "144I",
        "text": "Sickle cell disease with crisis with fever"
    },
    {
        "id": "144J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "144K",
        "text": "Sickle cell Hb-C disease with crisis"
    },
    {
        "id": "144L",
        "text": "Sickle cell Hb-C disease with crisis with acute chest syndrome"
    },
    {
        "id": "144M",
        "text": "Sickle cell Hb-C disease with crisis with splenic sequestration"
    },
    {
        "id": "144N",
        "text": "Sickle cell Hb-C disease with crisis with fever"
    },
    {
        "id": "144O",
        "text": "Sickle cell Hb-C disease without crisis"
    },
    {
        "id": "144P",
        "text": "Sickle cell thalassaemia with crisis"
    },
    {
        "id": "144Q",
        "text": "Sickle cell thalassaemia with crisis with acute chest syndrome"
    },
    {
        "id": "144R",
        "text": "Sickle cell thalassaemia with crisis with splenic sequestration"
    },
    {
        "id": "144S",
        "text": "Sickle cell thalassaemia with crisis with fever"
    },
    {
        "id": "144T",
        "text": "Sickle cell thalassaemia without crisis"
    },
    {
        "id": "144U",
        "text": "Haemoglobin C disease"
    },
    {
        "id": "144V",
        "text": "Haemoglobin E disease"
    },
    {
        "id": "144W",
        "text": "Haemoglobin O disease"
    },
    {
        "id": "144X",
        "text": "Haemoglobin D disease"
    },
    {
        "id": "144Y",
        "text": "Low affinity haemoglobin"
    },
    {
        "id": "144Z",
        "text": "High affinity haemoglobin"
    },
    {
        "id": "1450",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1451",
        "text": "Mitochondrial myopathy with sideroblastic anaemia"
    },
    {
        "id": "1452",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1453",
        "text": "Acquired aplastic anaemias"
    },
    {
        "id": "1454",
        "text": "Acquired sideroblastic anaemias"
    },
    {
        "id": "1455",
        "text": "Anaemia in chronic kidney disease"
    },
    {
        "id": "1456",
        "text": "Anaemia in chronic kidney disease"
    },
    {
        "id": "1457",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1458",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1459",
        "text": "Relative polycythaemia"
    },
    {
        "id": "145A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145D",
        "text": "Hereditary vitamin K-dependent coagulation factors deficiency"
    },
    {
        "id": "145E",
        "text": "Hereditary deficiency of factor I"
    },
    {
        "id": "145F",
        "text": "Symptomatic form of haemophilia A in female carriers"
    },
    {
        "id": "145G",
        "text": "Symptomatic form of haemophilia B in female carriers"
    },
    {
        "id": "145H",
        "text": "Acquired haemophilia"
    },
    {
        "id": "145I",
        "text": "Haemorrhage due to thrombin inhibitor other than heparin"
    },
    {
        "id": "145J",
        "text": "Haemorrhage due to factor Xa inhibitor"
    },
    {
        "id": "145K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145M",
        "text": "Bleeding diathesis due to thromboxane synthesis deficiency"
    },
    {
        "id": "145N",
        "text": "Isolated thrombocytopenia"
    },
    {
        "id": "145O",
        "text": "Inherited giant platelet disorder"
    },
    {
        "id": "145P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145Q",
        "text": "Alpha-granule diseases"
    },
    {
        "id": "145R",
        "text": "Dense granule disease"
    },
    {
        "id": "145S",
        "text": "Alpha-delta dense granule deficiency"
    },
    {
        "id": "145T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "145Y",
        "text": "Hyperhomocysteinaemia"
    },
    {
        "id": "145Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1460",
        "text": "Acquired thrombophilia"
    },
    {
        "id": "1461",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1462",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1463",
        "text": "Congenital thrombocytosis"
    },
    {
        "id": "1464",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1465",
        "text": "Thrombotic thrombocytopenic purpura"
    },
    {
        "id": "1466",
        "text": "Fibrinolytic defects"
    },
    {
        "id": "1467",
        "text": "Inherited fibrinolytic defects"
    },
    {
        "id": "1468",
        "text": "Acquired fibrinolytic defects"
    },
    {
        "id": "1469",
        "text": "Acquired vascular purpura"
    },
    {
        "id": "146A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "146B",
        "text": "Congenital disorders of spleen"
    },
    {
        "id": "146C",
        "text": "Splenomegaly in storage diseases"
    },
    {
        "id": "146D",
        "text": "Acquired disorders of spleen"
    },
    {
        "id": "146E",
        "text": "Atrophy of spleen"
    },
    {
        "id": "146F",
        "text": "Postsurgical asplenia"
    },
    {
        "id": "146G",
        "text": "Nontraumatic laceration or rupture of spleen"
    },
    {
        "id": "146H",
        "text": "Splenosis"
    },
    {
        "id": "146I",
        "text": "Splenic cyst or pseudocyst"
    },
    {
        "id": "146J",
        "text": "Pseudocyst of spleen"
    },
    {
        "id": "146K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "146L",
        "text": "Torsion of spleen"
    },
    {
        "id": "146M",
        "text": "Fibrosis of spleen"
    },
    {
        "id": "146N",
        "text": "Perisplenitis"
    },
    {
        "id": "146O",
        "text": "Tumour-like conditions of spleen"
    },
    {
        "id": "146P",
        "text": "Congenital analbuminaemia"
    },
    {
        "id": "146Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "146R",
        "text": "Iron deficiency anaemia"
    },
    {
        "id": "146S",
        "text": "Megaloblastic anaemia due to vitamin B12 deficiency"
    },
    {
        "id": "146T",
        "text": "Other nutritional or metabolic anaemias"
    },
    {
        "id": "146U",
        "text": "Acquired nutritional or metabolic anaemia"
    },
    {
        "id": "146V",
        "text": "Protein deficiency anaemia"
    },
    {
        "id": "146W",
        "text": "Pure red cell aplasia"
    },
    {
        "id": "146X",
        "text": "Congenital pure red cell aplasia"
    },
    {
        "id": "146Y",
        "text": "Hereditary pure red cell aplasia"
    },
    {
        "id": "146Z",
        "text": "Congenital non-inherited pure red cell aplasia"
    },
    {
        "id": "1470",
        "text": "Aplastic anaemia"
    },
    {
        "id": "1471",
        "text": "Sideroblastic anaemia"
    },
    {
        "id": "1472",
        "text": "Polycythaemia vera"
    },
    {
        "id": "1473",
        "text": "Congenital polycythaemia"
    },
    {
        "id": "1474",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1475",
        "text": "Primary inherited erythrocytosis"
    },
    {
        "id": "1476",
        "text": "Coagulation defects"
    },
    {
        "id": "1477",
        "text": "Congenital or constitutional haemorrhagic condition"
    },
    {
        "id": "1478",
        "text": "Congenital non-inherited haemorrhagic condition"
    },
    {
        "id": "1479",
        "text": "Hereditary haemorrhagic disorder"
    },
    {
        "id": "147A",
        "text": "Hereditary factor VIII deficiency"
    },
    {
        "id": "147B",
        "text": "Hereditary factor VIII deficiency with anti-factor VIII inhibitor"
    },
    {
        "id": "147C",
        "text": "Hereditary factor IX deficiency"
    },
    {
        "id": "147D",
        "text": "Other inherited coagulation factor deficiency with bleeding tendency"
    },
    {
        "id": "147E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "147F",
        "text": "Inherited coagulation factor deficiency without bleeding tendency"
    },
    {
        "id": "147G",
        "text": "Hereditary thrombophilia"
    },
    {
        "id": "147H",
        "text": "Thrombocytopenia"
    },
    {
        "id": "147I",
        "text": "Thrombophilia"
    },
    {
        "id": "147J",
        "text": "Congenital thrombocytopenia"
    },
    {
        "id": "147K",
        "text": "Congenital non-inherited thrombocytopenia"
    },
    {
        "id": "147L",
        "text": "Hereditary thrombocytopenia"
    },
    {
        "id": "147M",
        "text": "Acquired thrombocytopenia"
    },
    {
        "id": "147N",
        "text": "Alloimmune thrombocytopenia"
    },
    {
        "id": "147O",
        "text": "Inherited qualitative platelet defects"
    },
    {
        "id": "147P",
        "text": "Thrombocytosis"
    },
    {
        "id": "147Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "147R",
        "text": "Congenital methaemoglobinaemia"
    },
    {
        "id": "147S",
        "text": "Methaemoglobin reductase deficiency haemoglobinopathy"
    },
    {
        "id": "147T",
        "text": "Other methaemoglobinaemia"
    },
    {
        "id": "147U",
        "text": "Secondary hypertension associated with renal tubular disorders"
    },
    {
        "id": "147V",
        "text": "Wolfram syndrome"
    },
    {
        "id": "147W",
        "text": "MODY syndrome"
    },
    {
        "id": "147X",
        "text": "Neonatal diabetes mellitus"
    },
    {
        "id": "147Y",
        "text": "Transient neonatal diabetes mellitus"
    },
    {
        "id": "147Z",
        "text": "Familial hypocalciuric hypercalcaemia"
    },
    {
        "id": "1480",
        "text": "Hyperfunction of pituitary gland"
    },
    {
        "id": "1481",
        "text": "Arthropathy associated with acromegaly"
    },
    {
        "id": "1482",
        "text": "Functional hyperprolactinoma"
    },
    {
        "id": "1483",
        "text": "Macroprolactinaemia"
    },
    {
        "id": "1484",
        "text": "Central precocious puberty"
    },
    {
        "id": "1485",
        "text": "Septo-optic dysplasia"
    },
    {
        "id": "1486",
        "text": "Central diabetes insipidus"
    },
    {
        "id": "1487",
        "text": "Familial hypercholesterolaemia"
    },
    {
        "id": "1488",
        "text": "Disseminated intravascular coagulation of fetus or newborn"
    },
    {
        "id": "1489",
        "text": "Disseminated intravascular coagulation"
    },
    {
        "id": "148B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "148D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "148F",
        "text": "Ecthyma gangrenosum"
    },
    {
        "id": "148E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "148G",
        "text": "Miscellaneous pyogenic bacterial infections of the skin"
    },
    {
        "id": "148H",
        "text": "Perforating dermatoses"
    },
    {
        "id": "148I",
        "text": "Anogenital streptococcal cellulitis of infancy or childhood"
    },
    {
        "id": "148J",
        "text": "Streptococcal toxin-mediated reactions involving skin"
    },
    {
        "id": "148N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "148O",
        "text": "Sebaceous carcinoma of eyelid"
    },
    {
        "id": "148P",
        "text": "Sebaceous carcinoma of eyelid"
    },
    {
        "id": "148Q",
        "text": "Acquired disorders of eyelashes"
    },
    {
        "id": "148S",
        "text": "Trichoepithelioma of eyelids"
    },
    {
        "id": "148T",
        "text": "Trichoepithelioma of eyelids"
    },
    {
        "id": "148V",
        "text": "Venous thromboembolism originating from other or unspecified sites"
    },
    {
        "id": "148W",
        "text": "Pseudomonas aeruginosa"
    },
    {
        "id": "148Y",
        "text": "Sepsis due to Salmonella without mention of septic shock"
    },
    {
        "id": "148Z",
        "text": "Sepsis due to Anthrax without mention of septic shock"
    },
    {
        "id": "1490",
        "text": "Sepsis due to Erysipelothrix without mention of septic shock"
    },
    {
        "id": "1491",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "148X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "142L",
        "text": "Specified developmental anomalies affecting the skin"
    },
    {
        "id": "148K",
        "text": "Staphylococcal toxin-mediated reactions involving skin"
    },
    {
        "id": "148C",
        "text": "Pneumonia due to Pseudomonas aeruginosa"
    },
    {
        "id": "148A",
        "text": "Acral lentiginous melanoma, primary"
    },
    {
        "id": "142S",
        "text": "Developmental defects of the nail apparatus"
    },
    {
        "id": "142B",
        "text": "Developmental venous malformations involving the skin"
    },
    {
        "id": "149C",
        "text": "Postpartum haemorrhage"
    },
    {
        "id": "149D",
        "text": "Other immediate postpartum haemorrhage"
    },
    {
        "id": "149E",
        "text": "Delayed or secondary postpartum haemorrhage"
    },
    {
        "id": "149F",
        "text": "Postpartum coagulation defects"
    },
    {
        "id": "149G",
        "text": "Third-stage haemorrhage"
    },
    {
        "id": "149H",
        "text": "Certain specified obstetric trauma"
    },
    {
        "id": "149I",
        "text": "Obstetric high vaginal laceration"
    },
    {
        "id": "149J",
        "text": "Other obstetric injury to pelvic organs"
    },
    {
        "id": "149K",
        "text": "Postpartum inversion of uterus"
    },
    {
        "id": "149L",
        "text": "Obstetric laceration of cervix"
    },
    {
        "id": "149M",
        "text": "Rupture of uterus before onset of labour"
    },
    {
        "id": "149N",
        "text": "Rupture of uterus during labour"
    },
    {
        "id": "149O",
        "text": "Obstetric haematoma of pelvis"
    },
    {
        "id": "149P",
        "text": "Obstetric damage to pelvic joints or ligaments"
    },
    {
        "id": "149Q",
        "text": "Complications of anaesthesia during labour or delivery"
    },
    {
        "id": "149S",
        "text": "Retained placenta without haemorrhage"
    },
    {
        "id": "149R",
        "text": "Retained placenta or membranes, without haemorrhage"
    },
    {
        "id": "149T",
        "text": "Retained portions of placenta or membranes, without haemorrhage"
    },
    {
        "id": "149U",
        "text": "Labour or delivery complicated by fetal distress"
    },
    {
        "id": "149X",
        "text": "Labour or delivery complicated by fetal heart rate anomaly"
    },
    {
        "id": "149Y",
        "text": "Labour or delivery complicated by umbilical cord complications"
    },
    {
        "id": "1500",
        "text": "Labour or delivery complicated by short cord"
    },
    {
        "id": "1501",
        "text": "Labour or delivery complicated by vasa praevia"
    },
    {
        "id": "149Z",
        "text": "Labour or delivery complicated by prolapse of cord"
    },
    {
        "id": "149W",
        "text": "Labour or delivery complicated by meconium in amniotic fluid"
    },
    {
        "id": "149V",
        "text": "Labour or delivery complicated by biochemical evidence of fetal stress"
    },
    {
        "id": "1502",
        "text": "Labour or delivery complicated by cord around neck, with compression"
    },
    {
        "id": "1503",
        "text": "Labour or delivery complicated by vascular lesion of cord"
    },
    {
        "id": "1504",
        "text": "Certain obstetric conditions, not elsewhere classified"
    },
    {
        "id": "1505",
        "text": "Sequelae of complication of pregnancy, childbirth or the puerperium"
    },
    {
        "id": "1506",
        "text": "Obstetric death of unspecified cause"
    },
    {
        "id": "1507",
        "text": "Death from any obstetric cause occurring more than 42 days but less than one year after delivery"
    },
    {
        "id": "1508",
        "text": "Death from sequelae of obstetric causes"
    },
    {
        "id": "150A",
        "text": "Diseases of the nervous system complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "1509",
        "text": "Certain maternal diseases classifiable elsewhere but complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "150B",
        "text": "Endocrine, nutritional or metabolic diseases complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "150C",
        "text": "Other diseases of the blood or blood-forming organs or certain disorders involving the immune mechanism complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "150D",
        "text": "Anaemia complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "150E",
        "text": "Diseases of the skin complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "150F",
        "text": "Diseases of the digestive system complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "150G",
        "text": "Diseases of the respiratory system complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "150H",
        "text": "Diseases of the circulatory system complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "150I",
        "text": "Ectopic pregnancy"
    },
    {
        "id": "150J",
        "text": "Tubal pregnancy"
    },
    {
        "id": "150K",
        "text": "Abdominal pregnancy"
    },
    {
        "id": "150L",
        "text": "Ovarian pregnancy"
    },
    {
        "id": "150M",
        "text": "Molar pregnancy"
    },
    {
        "id": "150N",
        "text": "Complete hydatidiform mole"
    },
    {
        "id": "150O",
        "text": "Incomplete or partial hydatidiform mole"
    },
    {
        "id": "150P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "150Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "150R",
        "text": "Missed abortion"
    },
    {
        "id": "150S",
        "text": "Blighted ovum or nonhydatidiform mole"
    },
    {
        "id": "150T",
        "text": "Spontaneous abortion"
    },
    {
        "id": "150U",
        "text": "Spontaneous abortion, complete or unspecified, complicated by embolism"
    },
    {
        "id": "150V",
        "text": "Spontaneous abortion, complete or unspecified, complicated by delayed or excessive haemorrhage"
    },
    {
        "id": "150W",
        "text": "Spontaneous abortion, incomplete, without complication"
    },
    {
        "id": "150X",
        "text": "Spontaneous abortion, incomplete, complicated by embolism"
    },
    {
        "id": "150Y",
        "text": "Spontaneous abortion, incomplete, complicated by delayed or excessive haemorrhage"
    },
    {
        "id": "150Z",
        "text": "Induced abortion"
    },
    {
        "id": "1510",
        "text": "Induced abortion, complete or unspecified, complicated by delayed or excessive haemorrhage"
    },
    {
        "id": "1511",
        "text": "Induced abortion, complete or unspecified, complicated by embolism"
    },
    {
        "id": "1512",
        "text": "Pulpitis"
    },
    {
        "id": "1513",
        "text": "Erosive pustular dermatosis of scalp"
    },
    {
        "id": "1515",
        "text": "Irritant contact dermatitis of scalp"
    },
    {
        "id": "1492",
        "text": "Skin disorders localised to the scalp"
    },
    {
        "id": "1517",
        "text": "Skin disorders localised to the scalp"
    },
    {
        "id": "151B",
        "text": "Disorders of the external ear involving the skin"
    },
    {
        "id": "151E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "151D",
        "text": "Inflammatory disorders of the external ear"
    },
    {
        "id": "151A",
        "text": "Scalp folliculitis"
    },
    {
        "id": "1519",
        "text": "Ischaemic alopecia due to birth injury"
    },
    {
        "id": "1516",
        "text": "Contact dermatitis of scalp"
    },
    {
        "id": "1514",
        "text": "Allergic contact dermatitis of scalp"
    },
    {
        "id": "1518",
        "text": "Birth injury to scalp"
    },
    {
        "id": "149B",
        "text": "Lipoedema of the scalp"
    },
    {
        "id": "149A",
        "text": "Primary cutis verticis gyrata"
    },
    {
        "id": "1499",
        "text": "Cutis verticis gyrata"
    },
    {
        "id": "1498",
        "text": "Aplasia cutis congenita of scalp"
    },
    {
        "id": "1497",
        "text": "Scalp pruritus"
    },
    {
        "id": "1496",
        "text": "Lichen planopilaris of scalp"
    },
    {
        "id": "1495",
        "text": "Scalp psoriasis"
    },
    {
        "id": "1494",
        "text": "Seborrhoeic dermatitis of the scalp"
    },
    {
        "id": "1493",
        "text": "Dermatophytosis of scalp"
    },
    {
        "id": "151C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "136C",
        "text": "Extramammary Paget disease of skin"
    },
    {
        "id": "151K",
        "text": "Chronic seborrhoeic otitis externa"
    },
    {
        "id": "151J",
        "text": "Acute seborrhoeic otitis externa"
    },
    {
        "id": "151I",
        "text": "Seborrhoeic otitis externa"
    },
    {
        "id": "151H",
        "text": "Chronic otitis externa"
    },
    {
        "id": "151G",
        "text": "Acute noninfectious otitis externa"
    },
    {
        "id": "151F",
        "text": "Herpes simplex infection of external ear"
    },
    {
        "id": "151L",
        "text": "Erysipelas of external ear"
    },
    {
        "id": "151M",
        "text": "Malignant otitis externa"
    },
    {
        "id": "151N",
        "text": "Malignant otitis externa"
    },
    {
        "id": "151O",
        "text": "Perichondritis of external ear"
    },
    {
        "id": "151P",
        "text": "Anal abscess disorder (TM1)"
    },
    {
        "id": "151Q",
        "text": "Otomycosis"
    },
    {
        "id": "151R",
        "text": "Aspergillus otomycosis"
    },
    {
        "id": "151S",
        "text": "Candida otomycosis"
    },
    {
        "id": "151U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "151V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "151W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "151X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "151Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "151Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1520",
        "text": "Swimmer's ear"
    },
    {
        "id": "151T",
        "text": "Acute bacterial inflammation of external ear"
    },
    {
        "id": "1522",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1523",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1524",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1521",
        "text": "Bacterial otitis externa"
    },
    {
        "id": "1525",
        "text": "Other paraphilic disorder involving non-consenting individuals"
    },
    {
        "id": "1526",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1527",
        "text": "Postprocedural disorders of the nervous system"
    },
    {
        "id": "1528",
        "text": "Pulmonary thromboembolism"
    },
    {
        "id": "1529",
        "text": "Columnar metaplastic epithelium of the oesophagus"
    },
    {
        "id": "152A",
        "text": "Chronic peptic ulcer, site unspecified, with haemorrhage"
    },
    {
        "id": "152B",
        "text": "Chronic enteritis of uncertain aetiology"
    },
    {
        "id": "152C",
        "text": "Functional constipation"
    },
    {
        "id": "152D",
        "text": "Irritable bowel syndrome or certain specified functional bowel disorders"
    },
    {
        "id": "152E",
        "text": "Functional dyspepsia"
    },
    {
        "id": "152F",
        "text": "Functional oesophageal or gastroduodenal disorders"
    },
    {
        "id": "152G",
        "text": "Neoplasms of the stomach"
    },
    {
        "id": "152H",
        "text": "Infant dyschezia"
    },
    {
        "id": "152I",
        "text": "Dumping syndrome"
    },
    {
        "id": "152J",
        "text": "Disturbances in tooth eruption"
    },
    {
        "id": "152K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "152L",
        "text": "Induced abortion"
    },
    {
        "id": "152M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "152N",
        "text": "Induced abortion, incomplete, complicated by genital tract or pelvic infection"
    },
    {
        "id": "152O",
        "text": "Pre-eclampsia"
    },
    {
        "id": "152P",
        "text": "Infections in the puerperium"
    },
    {
        "id": "152Q",
        "text": "Certain specified complications of labour or delivery, not elsewhere classified"
    },
    {
        "id": "152R",
        "text": "Certain maternal diseases classifiable elsewhere but complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "152S",
        "text": "Injury complicating pregnancy"
    },
    {
        "id": "152T",
        "text": "Biliary atresia"
    },
    {
        "id": "152U",
        "text": "Biliary atresia"
    },
    {
        "id": "152V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "152W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "152X",
        "text": "Meconium plug without ileus"
    },
    {
        "id": "152Y",
        "text": "Meconium ileus with perforation"
    },
    {
        "id": "152Z",
        "text": "Small for gestational age, symmetrical"
    },
    {
        "id": "1530",
        "text": "Small for gestational age, asymmetrical"
    },
    {
        "id": "1531",
        "text": "Intrauterine growth restriction"
    },
    {
        "id": "1532",
        "text": "Asymmetrical intrauterine growth restriction"
    },
    {
        "id": "1533",
        "text": "Symmetrical intrauterine growth restriction"
    },
    {
        "id": "1534",
        "text": "Intrauterine growth restriction associated with small for gestational age"
    },
    {
        "id": "1535",
        "text": "Large newborn for gestational age"
    },
    {
        "id": "1536",
        "text": "Post-term newborn"
    },
    {
        "id": "1537",
        "text": "Postmaturity syndrome"
    },
    {
        "id": "1538",
        "text": "Fracture, dislocation or subluxation of spine due to birth injury"
    },
    {
        "id": "1539",
        "text": "Birth injury to cranial nerves"
    },
    {
        "id": "153A",
        "text": "Occipital osteodiastasis due to birth injury"
    },
    {
        "id": "153B",
        "text": "Respiratory distress syndrome of newborn"
    },
    {
        "id": "153C",
        "text": "Tracheal haemorrhage of newborn due to airway trauma"
    },
    {
        "id": "153D",
        "text": "Apnoea of newborn"
    },
    {
        "id": "153E",
        "text": "Central neonatal apnoea"
    },
    {
        "id": "153F",
        "text": "Obstructive neonatal apnoea"
    },
    {
        "id": "153G",
        "text": "Mixed neonatal apnoea"
    },
    {
        "id": "153H",
        "text": "Diffuse bleeding diathesis due to vitamin K deficient haemorrhagic disease of fetus or newborn"
    },
    {
        "id": "153I",
        "text": "Neonatal kernicterus"
    },
    {
        "id": "153J",
        "text": "Urachal cyst"
    },
    {
        "id": "153K",
        "text": "Neurological disorders specific to the perinatal or neonatal period"
    },
    {
        "id": "153L",
        "text": "Crohn disease of anal region"
    },
    {
        "id": "153M",
        "text": "Cutaneous or mucocutaneous Crohn disease"
    },
    {
        "id": "153N",
        "text": "Crohn disease of lips or oral mucosa"
    },
    {
        "id": "153Q",
        "text": "Granulomatous cheilitis"
    },
    {
        "id": "153R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "153O",
        "text": "Orofacial granulomatosis"
    },
    {
        "id": "153P",
        "text": "Melkersson syndrome"
    },
    {
        "id": "153S",
        "text": "Sarcoidosis of oral cavity"
    },
    {
        "id": "153V",
        "text": "Disorders of lips"
    },
    {
        "id": "153W",
        "text": "Disorders of lips"
    },
    {
        "id": "153X",
        "text": "Disorders of lips"
    },
    {
        "id": "153Y",
        "text": "Disorders of lips"
    },
    {
        "id": "153U",
        "text": "Cheilitis"
    },
    {
        "id": "153T",
        "text": "Diseases or disorders of orofacial complex"
    },
    {
        "id": "1540",
        "text": "Anal pruritus"
    },
    {
        "id": "1541",
        "text": "Perianal lichen simplex"
    },
    {
        "id": "1542",
        "text": "Infections of the anus or perianal skin"
    },
    {
        "id": "1544",
        "text": "Retroperitoneal fibrosis"
    },
    {
        "id": "1545",
        "text": "Retroperitoneal fibrosis"
    },
    {
        "id": "1546",
        "text": "Retroperitoneal fibrosis"
    },
    {
        "id": "1548",
        "text": "Attention to artificial openings of urinary tract other than cystostomy"
    },
    {
        "id": "1549",
        "text": "Attention to artificial openings of digestive tract other than gastrostomy, ileostomy, or colostomy"
    },
    {
        "id": "154A",
        "text": "Presence of other artificial openings of gastrointestinal tract"
    },
    {
        "id": "154B",
        "text": "Contact dermatitis of specified site"
    },
    {
        "id": "1547",
        "text": "Cutaneous complications of stomas or fistulas"
    },
    {
        "id": "154E",
        "text": "Cutaneous complications of stomas or fistulas"
    },
    {
        "id": "154J",
        "text": "Cutaneous complications of stomas or fistulas"
    },
    {
        "id": "154I",
        "text": "Cutaneous complications of stomas or fistulas"
    },
    {
        "id": "154G",
        "text": "Cutaneous complications of stomas or fistulas"
    },
    {
        "id": "154F",
        "text": "Cutaneous complications of stomas or fistulas"
    },
    {
        "id": "154H",
        "text": "Cutaneous complications of stomas or fistulas"
    },
    {
        "id": "154K",
        "text": "Cutaneous complications of stomas or fistulas"
    },
    {
        "id": "154C",
        "text": "Irritant contact dermatitis due to friction, sweating or contact with body fluids"
    },
    {
        "id": "154M",
        "text": "Herpes simplex infection of perianal skin or rectum"
    },
    {
        "id": "153Z",
        "text": "Dermatoses of the anus, perianal area or perineum"
    },
    {
        "id": "154N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "154R",
        "text": "Peritonsillar abscess"
    },
    {
        "id": "154S",
        "text": "Molluscum contagiosum"
    },
    {
        "id": "154T",
        "text": "Idiopathic non-specific interstitial pneumonia"
    },
    {
        "id": "154U",
        "text": "Structural developmental anomaly of heart or great vessels"
    },
    {
        "id": "154V",
        "text": "Congenital anomaly of position or spatial relationships of thoraco-abdominal organs"
    },
    {
        "id": "154W",
        "text": "Anomalous position-orientation of heart"
    },
    {
        "id": "154X",
        "text": "Abnormal atrial arrangement"
    },
    {
        "id": "154Y",
        "text": "Right hand pattern ventricular topology"
    },
    {
        "id": "154Z",
        "text": "Crisscross heart"
    },
    {
        "id": "1550",
        "text": "Superior-inferior ventricular relationship"
    },
    {
        "id": "1551",
        "text": "Congenital anomaly of an atrioventricular or ventriculo-arterial connection"
    },
    {
        "id": "1552",
        "text": "Concordant ventriculo-arterial connections with parallel great arteries"
    },
    {
        "id": "1554",
        "text": "Double outlet right ventricle with subaortic or doubly committed ventricular septal defect without pulmonary stenosis, ventricular septal defect type"
    },
    {
        "id": "1555",
        "text": "Double outlet right ventricle with subaortic ventricular septal defect without pulmonary stenosis"
    },
    {
        "id": "1556",
        "text": "Double outlet right ventricle with doubly committed ventricular septal defect without pulmonary stenosis"
    },
    {
        "id": "1557",
        "text": "Common arterial trunk with aortic dominance"
    },
    {
        "id": "1558",
        "text": "Common arterial trunk with aortic dominance and both pulmonary arteries arising from trunk"
    },
    {
        "id": "1559",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "155A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "155B",
        "text": "Common arterial trunk with aortic dominance and one pulmonary artery absent from the trunk, isolated pulmonary artery"
    },
    {
        "id": "155E",
        "text": "Congenital anomaly of mediastinal vein"
    },
    {
        "id": "155D",
        "text": "Congenital anomaly of mediastinal systemic vein"
    },
    {
        "id": "155F",
        "text": "Left superior caval vein"
    },
    {
        "id": "155G",
        "text": "Fatigue"
    },
    {
        "id": "155H",
        "text": "Ageing associated decline in intrinsic capacity"
    },
    {
        "id": "155L",
        "text": "Anorexia"
    },
    {
        "id": "155M",
        "text": "Sexual dysfunctions"
    },
    {
        "id": "155N",
        "text": "Gender incongruence"
    },
    {
        "id": "155P",
        "text": "Disturbances of smell and taste"
    },
    {
        "id": "155O",
        "text": "Dizziness and giddiness"
    },
    {
        "id": "155R",
        "text": "Left superior caval vein to coronary sinus"
    },
    {
        "id": "155S",
        "text": "Left superior caval vein to left-sided atrium"
    },
    {
        "id": "155T",
        "text": "Interrupted inferior caval vein with absent suprarenal segment and azygos continuation"
    },
    {
        "id": "155U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "155V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "155X",
        "text": "Unroofed coronary sinus"
    },
    {
        "id": "155Y",
        "text": "Anomalous hepatic venous connection to heart"
    },
    {
        "id": "155Z",
        "text": "Anomalous pulmonary venous connection"
    },
    {
        "id": "1560",
        "text": "Scimitar syndrome"
    },
    {
        "id": "1561",
        "text": "Obstructed anomalous pulmonary venous pathway or connection"
    },
    {
        "id": "1562",
        "text": "Congenital pulmonary venous stenosis or hypoplasia"
    },
    {
        "id": "1563",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1564",
        "text": "Congenital anomaly of an atrium or atrial septum"
    },
    {
        "id": "1565",
        "text": "Congenital anomaly of atrial septum"
    },
    {
        "id": "1566",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1567",
        "text": "Restrictive interatrial communication or intact atrial septum when an interatrial shunt is physiologically necessary"
    },
    {
        "id": "1568",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1569",
        "text": "Aneurysm of the atrial septum"
    },
    {
        "id": "156A",
        "text": "Interatrial communication"
    },
    {
        "id": "156B",
        "text": "Interatrial communication through coronary sinus orifice"
    },
    {
        "id": "156C",
        "text": "Left-sided juxtaposition of the atrial appendages"
    },
    {
        "id": "156D",
        "text": "Right-sided juxtaposition of the atrial appendages"
    },
    {
        "id": "156E",
        "text": "Congenital giant right atrium"
    },
    {
        "id": "156G",
        "text": "Disorder of intellectual development, provisional"
    },
    {
        "id": "156H",
        "text": "Disorder of intellectual development, mild"
    },
    {
        "id": "156I",
        "text": "Disorder of intellectual development, moderate"
    },
    {
        "id": "156J",
        "text": "Disorder of intellectual development, severe"
    },
    {
        "id": "156K",
        "text": "Disorder of intellectual development, profound"
    },
    {
        "id": "156N",
        "text": "Developmental learning disorder"
    },
    {
        "id": "156R",
        "text": "Stereotyped movement disorder"
    },
    {
        "id": "156S",
        "text": "Stereotyped movement disorder without self-injury"
    },
    {
        "id": "156T",
        "text": "Stereotyped movement disorder with self-injury"
    },
    {
        "id": "156U",
        "text": "Exhibitionistic disorder"
    },
    {
        "id": "156V",
        "text": "Voyeuristic disorder"
    },
    {
        "id": "156W",
        "text": "Pedophilic disorder"
    },
    {
        "id": "156X",
        "text": "Coercive sexual sadism disorder"
    },
    {
        "id": "156Y",
        "text": "Frotteuristic disorder"
    },
    {
        "id": "156Z",
        "text": "Other paraphilic disorder involving non-consenting individuals"
    },
    {
        "id": "1570",
        "text": "Paraphilic disorder involving solitary behaviour or consenting individuals"
    },
    {
        "id": "1572",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1574",
        "text": "Fatigue"
    },
    {
        "id": "1573",
        "text": "Fatigue"
    },
    {
        "id": "1575",
        "text": "Congenital anomaly of right atrium"
    },
    {
        "id": "1576",
        "text": "Congenital anomaly of pulmonary vein"
    },
    {
        "id": "1577",
        "text": "Congenital anomaly of inferior caval vein"
    },
    {
        "id": "1578",
        "text": "Left hand pattern ventricular topology"
    },
    {
        "id": "1579",
        "text": "Double outlet right ventricle with subaortic or doubly committed ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "157A",
        "text": "Double outlet right ventricle with subaortic ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "1553",
        "text": "Double outlet right ventricle with doubly committed ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "157B",
        "text": "Congenital anomaly of the coronary sinus"
    },
    {
        "id": "157C",
        "text": "Congenital tricuspid valvar stenosis"
    },
    {
        "id": "157D",
        "text": "Tricuspid annular hypoplasia"
    },
    {
        "id": "157E",
        "text": "Dysplasia of tricuspid valve"
    },
    {
        "id": "157F",
        "text": "Straddling tricuspid valve"
    },
    {
        "id": "157G",
        "text": "Congenital mitral valvar stenosis"
    },
    {
        "id": "157H",
        "text": "Mitral annular hypoplasia"
    },
    {
        "id": "157I",
        "text": "Straddling mitral valve"
    },
    {
        "id": "157J",
        "text": "Dysplasia of mitral valve"
    },
    {
        "id": "156O",
        "text": "Developmental learning disorder with impairment in reading"
    },
    {
        "id": "156P",
        "text": "Developmental learning disorder with impairment in written expression"
    },
    {
        "id": "156Q",
        "text": "Developmental learning disorder with impairment in mathematics"
    },
    {
        "id": "156F",
        "text": "Disorders of intellectual development"
    },
    {
        "id": "157N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "157R",
        "text": "Autism spectrum disorder with disorder of intellectual development and with mild or no impairment of functional language with loss of previously acquired skills"
    },
    {
        "id": "157T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "157U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "157W",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language with loss of previously acquired skills"
    },
    {
        "id": "157X",
        "text": "Autism spectrum disorder with disorder of intellectual development and absence of functional language with loss of previously acquired skills"
    },
    {
        "id": "157O",
        "text": "Autism spectrum disorder without disorder of intellectual development and with mild or no impairment of functional language"
    },
    {
        "id": "1582",
        "text": "Atrioventricular septal defect with communication at atrial level and restrictive communication at ventricular level"
    },
    {
        "id": "1586",
        "text": "Atrioventricular septal defect with communication at atrial level and restrictive communication at ventricular level"
    },
    {
        "id": "1587",
        "text": "Atrioventricular septal defect with communication at atrial level and unrestrictive communication at ventricular level"
    },
    {
        "id": "158A",
        "text": "Atrioventricular septal defect and tetralogy of Fallot"
    },
    {
        "id": "158B",
        "text": "Common atrium with common atrioventricular junction"
    },
    {
        "id": "158C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158D",
        "text": "Atypical common atrioventricular valve"
    },
    {
        "id": "158E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158G",
        "text": "Common atrioventricular valve with unbalanced commitment of valve to ventricles"
    },
    {
        "id": "158H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158I",
        "text": "Common atrioventricular valve with unbalanced commitment of valve to right ventricle"
    },
    {
        "id": "158K",
        "text": "Atypical right ventricular component of common atrioventricular valve"
    },
    {
        "id": "1589",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158M",
        "text": "Atypical left ventricular component of common atrioventricular valve"
    },
    {
        "id": "158N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158R",
        "text": "Double orifice of left ventricular component of common atrioventricular valve"
    },
    {
        "id": "158S",
        "text": "Deficient mural leaflet of left ventricular component of common atrioventricular valve"
    },
    {
        "id": "158T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158U",
        "text": "Autism spectrum disorder with disorder of intellectual development and with absence of functional language"
    },
    {
        "id": "158V",
        "text": "Common atrioventricular junction without an atrioventricular septal defect"
    },
    {
        "id": "158W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158Y",
        "text": "Communication between left ventricle and right atrium"
    },
    {
        "id": "158Z",
        "text": "Congenital anomaly of a ventricle or the ventricular septum"
    },
    {
        "id": "1590",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1591",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1593",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1594",
        "text": "Right ventricular myocardial sinusoids"
    },
    {
        "id": "1595",
        "text": "Parchment right ventricle"
    },
    {
        "id": "1596",
        "text": "Tetralogy of Fallot with pulmonary atresia"
    },
    {
        "id": "1597",
        "text": "Congenital subaortic stenosis"
    },
    {
        "id": "1598",
        "text": "Congenital left ventricular aneurysm or diverticulum"
    },
    {
        "id": "1599",
        "text": "Left heart obstruction at multiple sites"
    },
    {
        "id": "159A",
        "text": "Left ventricular myocardial sinusoids"
    },
    {
        "id": "159C",
        "text": "Restrictive interventricular communication when an interventricular shunt is physiologically necessary"
    },
    {
        "id": "159H",
        "text": "Trabecular muscular ventricular septal defect"
    },
    {
        "id": "159I",
        "text": "Inlet ventricular septal defect without a common atrioventricular junction"
    },
    {
        "id": "159B",
        "text": "Congenital anomaly of ventricular septum"
    },
    {
        "id": "159L",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "159N",
        "text": "Perimembranous central ventricular septal defect"
    },
    {
        "id": "159O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "159P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "159Q",
        "text": "Inlet ventricular septal defect without a common atrioventricular junction"
    },
    {
        "id": "159R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "159S",
        "text": "Inlet muscular ventricular septal defect"
    },
    {
        "id": "159T",
        "text": "Inlet ventricular septal defect without a common atrioventricular junction"
    },
    {
        "id": "159V",
        "text": "Trabecular muscular ventricular septal defect apical"
    },
    {
        "id": "159X",
        "text": "Trabecular muscular ventricular septal defect"
    },
    {
        "id": "159Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "159Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1600",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1601",
        "text": "Multiple trabecular muscular ventricular septal defects"
    },
    {
        "id": "1602",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "1604",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1603",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment"
    },
    {
        "id": "1605",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment"
    },
    {
        "id": "160A",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "1609",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "160C",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "160D",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "160B",
        "text": "Outlet muscular ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "160E",
        "text": "Outlet muscular ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "160F",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "160G",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "160H",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "160J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "160V",
        "text": "Secondary mood syndrome, with mixed symptoms"
    },
    {
        "id": "160U",
        "text": "Secondary mood syndrome, with depressive symptoms"
    },
    {
        "id": "160T",
        "text": "Secondary mood syndrome, with manic symptoms"
    },
    {
        "id": "160X",
        "text": "Secondary obsessive-compulsive or related syndrome"
    },
    {
        "id": "160Y",
        "text": "Secondary dissociative syndrome"
    },
    {
        "id": "160Z",
        "text": "Secondary impulse control syndrome"
    },
    {
        "id": "1610",
        "text": "Secondary personality change"
    },
    {
        "id": "1611",
        "text": "Secondary neurocognitive syndrome"
    },
    {
        "id": "1613",
        "text": "Delirium due to disease classified elsewhere"
    },
    {
        "id": "1614",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "155K",
        "text": "Overeating"
    },
    {
        "id": "161A",
        "text": "Insufficient intake of food and water due to self neglect"
    },
    {
        "id": "1619",
        "text": "Feeding problem of adult"
    },
    {
        "id": "161B",
        "text": "Feeding difficulties"
    },
    {
        "id": "1618",
        "text": "Feeding problem of child"
    },
    {
        "id": "161C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "161E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "161G",
        "text": "Double inlet left ventricle"
    },
    {
        "id": "161H",
        "text": "Double inlet right ventricle"
    },
    {
        "id": "161I",
        "text": "Double inlet to solitary ventricle of indeterminate morphology"
    },
    {
        "id": "161J",
        "text": "Tricuspid atresia"
    },
    {
        "id": "161L",
        "text": "Tricuspid atresia with imperforate tricuspid valve"
    },
    {
        "id": "161M",
        "text": "Mitral atresia"
    },
    {
        "id": "161O",
        "text": "Mitral atresia with imperforate mitral valve"
    },
    {
        "id": "161P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "161R",
        "text": "Congenital anomaly of pulmonary valve"
    },
    {
        "id": "161S",
        "text": "Congenital pulmonary valvar stenosis"
    },
    {
        "id": "161T",
        "text": "Congenital pulmonary regurgitation"
    },
    {
        "id": "161U",
        "text": "Dysplasia of pulmonary valve"
    },
    {
        "id": "161V",
        "text": "Bicuspid pulmonary valve"
    },
    {
        "id": "161W",
        "text": "Congenital subpulmonary stenosis"
    },
    {
        "id": "161Y",
        "text": "Congenital supravalvar pulmonary stenosis"
    },
    {
        "id": "161Z",
        "text": "Congenital pulmonary atresia"
    },
    {
        "id": "1621",
        "text": "Pulmonary atresia with intact ventricular septum"
    },
    {
        "id": "1622",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1623",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1624",
        "text": "Congenital anomaly of aortic valve"
    },
    {
        "id": "1626",
        "text": "Congenital aortic regurgitation"
    },
    {
        "id": "1627",
        "text": "Congenital anomaly of aortic valve"
    },
    {
        "id": "162B",
        "text": "Aortic annular hypoplasia"
    },
    {
        "id": "162C",
        "text": "Dysplasia of aortic valve"
    },
    {
        "id": "162E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "162F",
        "text": "Congenital supravalvar aortic stenosis"
    },
    {
        "id": "162G",
        "text": "Aortoventricular tunnel"
    },
    {
        "id": "162H",
        "text": "Common arterial trunk"
    },
    {
        "id": "162I",
        "text": "Common arterial trunk"
    },
    {
        "id": "162J",
        "text": "Common arterial trunk"
    },
    {
        "id": "162K",
        "text": "Atypical truncal valve"
    },
    {
        "id": "162L",
        "text": "Congenital truncal valvar regurgitation"
    },
    {
        "id": "162M",
        "text": "Congenital truncal valvar stenosis"
    },
    {
        "id": "162N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "162O",
        "text": "Congenital aortopulmonary window"
    },
    {
        "id": "162P",
        "text": "Congenital anomaly of pulmonary arterial tree"
    },
    {
        "id": "162R",
        "text": "Congenital pulmonary trunk anomaly"
    },
    {
        "id": "162S",
        "text": "Congenital pulmonary trunk anomaly"
    },
    {
        "id": "162T",
        "text": "Congenital pulmonary arterial branch stenosis"
    },
    {
        "id": "162U",
        "text": "Congenital right pulmonary arterial stenosis"
    },
    {
        "id": "162V",
        "text": "Congenital left pulmonary arterial stenosis"
    },
    {
        "id": "162W",
        "text": "Congenital pulmonary arterial branch hypoplasia"
    },
    {
        "id": "162X",
        "text": "Congenital right pulmonary arterial hypoplasia"
    },
    {
        "id": "162Y",
        "text": "Congenital left pulmonary arterial hypoplasia"
    },
    {
        "id": "1632",
        "text": "Congenital central pulmonary arterial stenosis or hypoplasia proximal to hilar bifurcation"
    },
    {
        "id": "1633",
        "text": "Congenital peripheral pulmonary arterial stenosis or hypoplasia at or beyond hilar bifurcation"
    },
    {
        "id": "1634",
        "text": "Congenitally discontinuous, non-confluent right and left pulmonary arteries"
    },
    {
        "id": "1635",
        "text": "Pulmonary artery origin from ascending aorta"
    },
    {
        "id": "1636",
        "text": "Right pulmonary artery from ascending aorta"
    },
    {
        "id": "1637",
        "text": "Left pulmonary artery from ascending aorta"
    },
    {
        "id": "1638",
        "text": "Pulmonary artery from arterial duct"
    },
    {
        "id": "163A",
        "text": "Left pulmonary artery from arterial duct"
    },
    {
        "id": "163B",
        "text": "Congenital anomaly of aorta or its branches"
    },
    {
        "id": "163C",
        "text": "Congenital anomaly of ascending aorta"
    },
    {
        "id": "163D",
        "text": "Congenital ascending aorta aneurysm or dilation"
    },
    {
        "id": "163E",
        "text": "Hypoplasia of aortic arch"
    },
    {
        "id": "163F",
        "text": "Coarctation of aorta"
    },
    {
        "id": "163G",
        "text": "Interrupted aortic arch"
    },
    {
        "id": "163H",
        "text": "Interrupted aortic arch distal to subclavian artery, type A"
    },
    {
        "id": "163I",
        "text": "Interrupted aortic arch between subclavian and common carotid arteries, type B"
    },
    {
        "id": "163J",
        "text": "Interrupted aortic arch between carotid arteries, type C"
    },
    {
        "id": "163K",
        "text": "Right aortic arch"
    },
    {
        "id": "163L",
        "text": "Left aortic arch"
    },
    {
        "id": "163M",
        "text": "Congenital anomaly of aortic arch branch"
    },
    {
        "id": "163N",
        "text": "Aberrant origin of right subclavian artery"
    },
    {
        "id": "163O",
        "text": "Descending thoracic or abdominal aortic coarctation"
    },
    {
        "id": "163P",
        "text": "Tracheo-oesophageal compressive syndrome"
    },
    {
        "id": "163Q",
        "text": "Innominate artery compression syndrome"
    },
    {
        "id": "163R",
        "text": "Vascular ring"
    },
    {
        "id": "163S",
        "text": "Vascular ring"
    },
    {
        "id": "163T",
        "text": "Double aortic arch"
    },
    {
        "id": "163U",
        "text": "Vascular ring of right aortic arch and left arterial duct or ligament"
    },
    {
        "id": "163V",
        "text": "Vascular ring of left aortic arch and right arterial duct or ligament"
    },
    {
        "id": "163W",
        "text": "Viral hepatitis"
    },
    {
        "id": "163X",
        "text": "Acute viral hepatitis"
    },
    {
        "id": "163Y",
        "text": "Acute hepatitis A"
    },
    {
        "id": "163Z",
        "text": "Acute hepatitis B"
    },
    {
        "id": "1640",
        "text": "Acute hepatitis C"
    },
    {
        "id": "1641",
        "text": "Acute hepatitis D"
    },
    {
        "id": "1643",
        "text": "Chronic viral hepatitis"
    },
    {
        "id": "1644",
        "text": "Chronic hepatitis B"
    },
    {
        "id": "1645",
        "text": "Chronic hepatitis B, with cirrhosis"
    },
    {
        "id": "1647",
        "text": "Chronic hepatitis B, co-infected with hepatitis D virus"
    },
    {
        "id": "1648",
        "text": "Chronic hepatitis B with human immunodeficiency virus co-infection"
    },
    {
        "id": "1649",
        "text": "Chronic hepatitis C"
    },
    {
        "id": "164A",
        "text": "Chronic hepatitis C, with cirrhosis"
    },
    {
        "id": "164B",
        "text": "Chronic hepatitis C, co-infected with human immunodeficiency virus"
    },
    {
        "id": "164C",
        "text": "Chronic hepatitis B, co-infected with hepatitis C virus"
    },
    {
        "id": "164D",
        "text": "Carrier of viral hepatitis (obsolete concept)"
    },
    {
        "id": "164E",
        "text": "Trance, not elsewhere classified"
    },
    {
        "id": "155Q",
        "text": "Symptomatic manifestations of primary psychotic disorders"
    },
    {
        "id": "164F",
        "text": "Symptomatic manifestations of primary psychotic disorders"
    },
    {
        "id": "164G",
        "text": "Positive symptoms in primary psychotic disorders"
    },
    {
        "id": "164H",
        "text": "Negative symptoms in primary psychotic disorders"
    },
    {
        "id": "164I",
        "text": "Depressive mood symptoms in primary psychotic disorders"
    },
    {
        "id": "164K",
        "text": "Psychomotor symptoms in primary psychotic disorders"
    },
    {
        "id": "164O",
        "text": "Anomalous origin of left pulmonary artery from right pulmonary artery"
    },
    {
        "id": "164P",
        "text": "Patent arterial duct"
    },
    {
        "id": "164R",
        "text": "Anomalous origin of left coronary artery from pulmonary artery"
    },
    {
        "id": "164S",
        "text": "Anomalous aortic origin or course of coronary artery"
    },
    {
        "id": "164U",
        "text": "Right coronary artery from left aortic sinus with ventriculo-arterial concordance"
    },
    {
        "id": "164W",
        "text": "Anterior descending from right coronary artery across right ventricular outflow tract"
    },
    {
        "id": "164X",
        "text": "Intramural proximal coronary arterial course"
    },
    {
        "id": "164Y",
        "text": "Myocardial bridging of coronary artery"
    },
    {
        "id": "164Z",
        "text": "Congenital coronary arterial orifice stenosis"
    },
    {
        "id": "1650",
        "text": "Congenital coronary arterial orifice atresia"
    },
    {
        "id": "1651",
        "text": "Congenital coronary arterial fistula"
    },
    {
        "id": "1652",
        "text": "Congenital coronary arterial fistula to right ventricle"
    },
    {
        "id": "1653",
        "text": "Congenital coronary arterial fistula"
    },
    {
        "id": "1654",
        "text": "Congenital coronary arterial aneurysm"
    },
    {
        "id": "157Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165F",
        "text": "Sexual dysfunctions"
    },
    {
        "id": "165G",
        "text": "Sexual dysfunctions"
    },
    {
        "id": "165H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165P",
        "text": "Anorgasmia"
    },
    {
        "id": "165Q",
        "text": "Dyspareunia"
    },
    {
        "id": "165U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "155J",
        "text": "Fear of mental disorder"
    },
    {
        "id": "165W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165X",
        "text": "Hostility"
    },
    {
        "id": "165Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165Z",
        "text": "Somnolence, not elsewhere classified"
    },
    {
        "id": "1661",
        "text": "Developmental language disorder"
    },
    {
        "id": "1664",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1665",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "166C",
        "text": "Stupor"
    },
    {
        "id": "166Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "166Z",
        "text": "Secondary motor or phonic tics, not elsewhere classified"
    },
    {
        "id": "1671",
        "text": "Acute pain"
    },
    {
        "id": "1672",
        "text": "Symptoms or signs involving the digestive system or abdomen"
    },
    {
        "id": "1673",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1674",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1675",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1676",
        "text": "Idiopathic interstitial pneumonitis"
    },
    {
        "id": "1677",
        "text": "Lipoid pneumonitis"
    },
    {
        "id": "1678",
        "text": "Idiopathic pulmonary fibrosis"
    },
    {
        "id": "154L",
        "text": "Cutaneous complications of stomas or fistulas"
    },
    {
        "id": "1679",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "167D",
        "text": "Schizoaffective disorder"
    },
    {
        "id": "167A",
        "text": "Schizophrenia"
    },
    {
        "id": "167B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "167G",
        "text": "Schizotypal disorder"
    },
    {
        "id": "167I",
        "text": "Delusional disorder"
    },
    {
        "id": "167H",
        "text": "Acute and transient psychotic disorder"
    },
    {
        "id": "167J",
        "text": "Depressive disorders"
    },
    {
        "id": "167N",
        "text": "Congenital myasthenic syndromes"
    },
    {
        "id": "167O",
        "text": "Congenital myasthenia with congenital endplate acetylcholinesterase deficiency"
    },
    {
        "id": "167P",
        "text": "Myasthenia gravis or certain specified neuromuscular junction disorders"
    },
    {
        "id": "167Q",
        "text": "Mitochondrial myopathies"
    },
    {
        "id": "167R",
        "text": "Muscular dystrophy"
    },
    {
        "id": "167S",
        "text": "Becker muscular dystrophy"
    },
    {
        "id": "167T",
        "text": "Symptomatic form of Becker muscular dystrophy in female carriers"
    },
    {
        "id": "167U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "167L",
        "text": "Cyclothymic disorder"
    },
    {
        "id": "167V",
        "text": "Bipolar or related disorders"
    },
    {
        "id": "167M",
        "text": "Bipolar type II disorder"
    },
    {
        "id": "164V",
        "text": "Left coronary artery from right aortic sinus with ventriculo-arterial concordance"
    },
    {
        "id": "164T",
        "text": "Anomalous aortic origin of coronary artery with ventriculo-arterial concordance"
    },
    {
        "id": "164Q",
        "text": "Systemic-to-pulmonary collateral arteries"
    },
    {
        "id": "1639",
        "text": "Right pulmonary artery from arterial duct"
    },
    {
        "id": "1631",
        "text": "Absent or atretic left pulmonary artery"
    },
    {
        "id": "162Z",
        "text": "Absent or atretic right pulmonary artery"
    },
    {
        "id": "162Q",
        "text": "Congenital dilation of pulmonary arterial tree"
    },
    {
        "id": "1628",
        "text": "Aortic valvar prolapse"
    },
    {
        "id": "1629",
        "text": "Aortic valvar atresia"
    },
    {
        "id": "1625",
        "text": "Congenital aortic valvar stenosis"
    },
    {
        "id": "161Q",
        "text": "Congenital anomaly of a ventriculo-arterial valve or adjacent regions"
    },
    {
        "id": "161K",
        "text": "Tricuspid atresia with absent valvar annulus"
    },
    {
        "id": "161N",
        "text": "Mitral atresia with absent atrioventricular connection"
    },
    {
        "id": "161F",
        "text": "Double inlet atrioventricular connection"
    },
    {
        "id": "155C",
        "text": "Common arterial trunk with pulmonary dominance and aortic arch obstruction"
    },
    {
        "id": "167X",
        "text": "Other symptom or complaint of breathing"
    },
    {
        "id": "164L",
        "text": "Cognitive symptoms in primary psychotic disorders"
    },
    {
        "id": "167Y",
        "text": "Manic mood symptoms in primary psychotic disorders"
    },
    {
        "id": "167Z",
        "text": "Myasthenia gravis, autoimmune acquired"
    },
    {
        "id": "1680",
        "text": "Neuromuscular junction disorders due to toxicity"
    },
    {
        "id": "1681",
        "text": "Congenital myasthenic syndromes"
    },
    {
        "id": "167K",
        "text": "Bipolar type I disorder"
    },
    {
        "id": "1682",
        "text": "Bipolar type I disorder, current episode manic, without psychotic symptoms"
    },
    {
        "id": "1683",
        "text": "Bipolar type I disorder, current episode manic, with psychotic symptoms"
    },
    {
        "id": "1684",
        "text": "Bipolar type I disorder, current episode hypomanic"
    },
    {
        "id": "1685",
        "text": "Bipolar type I disorder, current episode depressive, mild"
    },
    {
        "id": "1686",
        "text": "Bipolar type I disorder, current episode depressive, moderate without psychotic symptoms"
    },
    {
        "id": "1687",
        "text": "Bipolar type I disorder, current episode depressive, moderate with psychotic symptoms"
    },
    {
        "id": "1688",
        "text": "Bipolar type I disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "1689",
        "text": "Bipolar type I disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "168B",
        "text": "Bipolar type I disorder, current episode mixed, with psychotic symptoms"
    },
    {
        "id": "168C",
        "text": "Bipolar type I disorder, currently in partial remission, most recent episode manic or hypomanic"
    },
    {
        "id": "168D",
        "text": "Bipolar type I disorder, currently in partial remission, most recent episode depressive"
    },
    {
        "id": "168E",
        "text": "Bipolar type I disorder, currently in partial remission, most recent episode mixed"
    },
    {
        "id": "168F",
        "text": "Bipolar type I disorder, currently in full remission"
    },
    {
        "id": "168A",
        "text": "Bipolar type I disorder, current episode mixed, without psychotic symptoms"
    },
    {
        "id": "168G",
        "text": "Barth syndrome"
    },
    {
        "id": "168H",
        "text": "Bipolar type II disorder, current episode hypomanic"
    },
    {
        "id": "168I",
        "text": "Bipolar type II disorder, current episode depressive, mild"
    },
    {
        "id": "168J",
        "text": "Bipolar type II disorder, current episode depressive, moderate without psychotic symptoms"
    },
    {
        "id": "168K",
        "text": "Bipolar type II disorder, current episode depressive, moderate with psychotic symptoms"
    },
    {
        "id": "168L",
        "text": "Bipolar type II disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "168M",
        "text": "Muscular dystrophy affecting extraocular muscle"
    },
    {
        "id": "168N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "168O",
        "text": "Bipolar type II disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "168P",
        "text": "Bipolar type II disorder, currently in partial remission, most recent episode depressive"
    },
    {
        "id": "168Q",
        "text": "Bipolar type II disorder, currently in full remission"
    },
    {
        "id": "168R",
        "text": "Oculopharyngodistal myopathy"
    },
    {
        "id": "168S",
        "text": "Oculopharyngeal muscular dystrophy"
    },
    {
        "id": "168T",
        "text": "Muscular dystrophy affecting extraocular muscle"
    },
    {
        "id": "168V",
        "text": "Single episode depressive disorder, mild"
    },
    {
        "id": "168W",
        "text": "Single episode depressive disorder, moderate, without psychotic symptoms"
    },
    {
        "id": "168X",
        "text": "Single episode depressive disorder, moderate, with psychotic symptoms"
    },
    {
        "id": "1690",
        "text": "Single episode depressive disorder, currently in partial remission"
    },
    {
        "id": "1691",
        "text": "Single episode depressive disorder, currently in full remission"
    },
    {
        "id": "1692",
        "text": "Muscular dystrophy"
    },
    {
        "id": "168Y",
        "text": "Single episode depressive disorder, severe, without psychotic symptoms"
    },
    {
        "id": "168Z",
        "text": "Single episode depressive disorder, severe, with psychotic symptoms"
    },
    {
        "id": "1694",
        "text": "Recurrent depressive disorder, current episode mild"
    },
    {
        "id": "1695",
        "text": "Recurrent depressive disorder, current episode moderate, without psychotic symptoms"
    },
    {
        "id": "1696",
        "text": "Recurrent depressive disorder, current episode moderate, with psychotic symptoms"
    },
    {
        "id": "1697",
        "text": "Recurrent depressive disorder, current episode severe, without psychotic symptoms"
    },
    {
        "id": "1698",
        "text": "Recurrent depressive disorder, current episode severe, with psychotic symptoms"
    },
    {
        "id": "1699",
        "text": "Recurrent depressive disorder, currently in partial remission"
    },
    {
        "id": "169A",
        "text": "Recurrent depressive disorder, currently in full remission"
    },
    {
        "id": "169E",
        "text": "Mixed depressive and anxiety disorder"
    },
    {
        "id": "169F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "169G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "169I",
        "text": "Male early ejaculation"
    },
    {
        "id": "169H",
        "text": "Ejaculatory dysfunctions"
    },
    {
        "id": "165O",
        "text": "Orgasmic dysfunctions"
    },
    {
        "id": "169J",
        "text": "Male delayed ejaculation"
    },
    {
        "id": "169B",
        "text": "Human prion diseases"
    },
    {
        "id": "169K",
        "text": "Human prion diseases"
    },
    {
        "id": "169L",
        "text": "Acquired prion disease"
    },
    {
        "id": "165S",
        "text": "Sexual pain disorders"
    },
    {
        "id": "169M",
        "text": "Sexual pain disorders"
    },
    {
        "id": "169N",
        "text": "Labyrinthine fistula"
    },
    {
        "id": "165R",
        "text": "Sexual pain-penetration disorder"
    },
    {
        "id": "167W",
        "text": "Developmental motor coordination disorder"
    },
    {
        "id": "169P",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "169Q",
        "text": "Prolonged grief disorder"
    },
    {
        "id": "169T",
        "text": "Acute stress reaction"
    },
    {
        "id": "169O",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "169U",
        "text": "Dyslexia and alexia"
    },
    {
        "id": "169W",
        "text": "Agraphia"
    },
    {
        "id": "169V",
        "text": "Acalculia"
    },
    {
        "id": "169X",
        "text": "Dyscalculia"
    },
    {
        "id": "169Z",
        "text": "Idiopathic non-specific interstitial pneumonia"
    },
    {
        "id": "1700",
        "text": "Congenital iodine-deficiency syndrome, myxoedematous type"
    },
    {
        "id": "1701",
        "text": "Hashimoto thyroiditis"
    },
    {
        "id": "1702",
        "text": "Idiopathic Type 1 diabetes mellitus"
    },
    {
        "id": "1703",
        "text": "Type 2 diabetes mellitus"
    },
    {
        "id": "1705",
        "text": "Diabetes mellitus, type unspecified"
    },
    {
        "id": "1704",
        "text": "Diabetes mellitus, other specified type"
    },
    {
        "id": "1706",
        "text": "Wolfram syndrome"
    },
    {
        "id": "1707",
        "text": "Meconium ileus without perforation"
    },
    {
        "id": "1709",
        "text": "Overeating"
    },
    {
        "id": "170A",
        "text": "Excessive weight loss"
    },
    {
        "id": "170B",
        "text": "Excessive weight gain"
    },
    {
        "id": "1616",
        "text": "Excessive weight gain"
    },
    {
        "id": "1615",
        "text": "Excessive weight loss"
    },
    {
        "id": "170D",
        "text": "Fatigue"
    },
    {
        "id": "170C",
        "text": "Echolalia"
    },
    {
        "id": "170E",
        "text": "Echolalia"
    },
    {
        "id": "166A",
        "text": "Symptoms, signs or clinical findings involving consciousness"
    },
    {
        "id": "166E",
        "text": "Symptoms or signs involving perceptual disturbance"
    },
    {
        "id": "166D",
        "text": "Symptoms or signs involving mood or affect"
    },
    {
        "id": "170F",
        "text": "Symptoms or signs involving appearance or behaviour"
    },
    {
        "id": "170H",
        "text": "Visual hallucinations"
    },
    {
        "id": "170I",
        "text": "Auditory hallucinations"
    },
    {
        "id": "170J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "170K",
        "text": "Anxiety"
    },
    {
        "id": "170L",
        "text": "Restlessness"
    },
    {
        "id": "170M",
        "text": "Apathy"
    },
    {
        "id": "170N",
        "text": "Irritability"
    },
    {
        "id": "170O",
        "text": "Violent behaviour"
    },
    {
        "id": "170P",
        "text": "Poor personal hygiene"
    },
    {
        "id": "170Q",
        "text": "Odd or peculiar appearance"
    },
    {
        "id": "170R",
        "text": "Disorganised behaviour"
    },
    {
        "id": "170S",
        "text": "Increased goal-directed activity"
    },
    {
        "id": "170T",
        "text": "Psychomotor retardation"
    },
    {
        "id": "170U",
        "text": "Suspiciousness"
    },
    {
        "id": "170V",
        "text": "Circumstantiality"
    },
    {
        "id": "170W",
        "text": "Age-associated cognitive decline"
    },
    {
        "id": "170X",
        "text": "Amnesia"
    },
    {
        "id": "170Y",
        "text": "Anterograde amnesia"
    },
    {
        "id": "170Z",
        "text": "Retrograde amnesia"
    },
    {
        "id": "1710",
        "text": "Transient global amnesia"
    },
    {
        "id": "1711",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1712",
        "text": "Transient global amnesia"
    },
    {
        "id": "1713",
        "text": "Anosognosia"
    },
    {
        "id": "165E",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "166G",
        "text": "Symptoms or signs involving mood or affect"
    },
    {
        "id": "166H",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "166M",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "166O",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "166N",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "167C",
        "text": "Schizophrenia, multiple episodes"
    },
    {
        "id": "167F",
        "text": "Schizoaffective disorder, multiple episodes"
    },
    {
        "id": "1714",
        "text": "Premenstrual dysphoric disorder"
    },
    {
        "id": "1715",
        "text": "Symptoms, signs or clinical findings involving consciousness"
    },
    {
        "id": "1716",
        "text": "Coma"
    },
    {
        "id": "166F",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "1718",
        "text": "Lack of expected normal physiological development"
    },
    {
        "id": "1719",
        "text": "Acute stress reaction"
    },
    {
        "id": "171A",
        "text": "Dysphasia"
    },
    {
        "id": "171B",
        "text": "Dysarthria"
    },
    {
        "id": "171C",
        "text": "Aphasia"
    },
    {
        "id": "171D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "171E",
        "text": "Lack of expected normal physiological development"
    },
    {
        "id": "171G",
        "text": "Lack of expected normal physiological development"
    },
    {
        "id": "171H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "171J",
        "text": "Personality disorder"
    },
    {
        "id": "171K",
        "text": "Mild personality disorder"
    },
    {
        "id": "171L",
        "text": "Moderate personality disorder"
    },
    {
        "id": "171M",
        "text": "Severe personality disorder"
    },
    {
        "id": "171P",
        "text": "Malfunction or complication of external stoma of digestive organs"
    },
    {
        "id": "171Q",
        "text": "Malfunction or complication of external stoma of digestive organs"
    },
    {
        "id": "171R",
        "text": "Body-focused repetitive behaviour disorders"
    },
    {
        "id": "171S",
        "text": "Obsessive-compulsive or related disorders"
    },
    {
        "id": "171T",
        "text": "Obsessive-compulsive disorder"
    },
    {
        "id": "171V",
        "text": "Olfactory reference disorder"
    },
    {
        "id": "171W",
        "text": "Hypochondriasis"
    },
    {
        "id": "171X",
        "text": "Hoarding disorder"
    },
    {
        "id": "171Y",
        "text": "Excoriation disorder"
    },
    {
        "id": "171Z",
        "text": "Trichotillomania"
    },
    {
        "id": "172F",
        "text": "Conduct-dissocial disorder"
    },
    {
        "id": "172Q",
        "text": "Catatonia"
    },
    {
        "id": "172R",
        "text": "Disorders specifically associated with stress"
    },
    {
        "id": "166I",
        "text": "Symptoms or signs involving appearance or behaviour"
    },
    {
        "id": "172W",
        "text": "Factitious disorders"
    },
    {
        "id": "172X",
        "text": "Factitious disorder imposed on another"
    },
    {
        "id": "172V",
        "text": "Developmental speech fluency disorder"
    },
    {
        "id": "172U",
        "text": "Speech dysfluency"
    },
    {
        "id": "172Y",
        "text": "Generalised anxiety disorder"
    },
    {
        "id": "172Z",
        "text": "Anxiety or fear-related disorders"
    },
    {
        "id": "1730",
        "text": "Panic disorder"
    },
    {
        "id": "1732",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1731",
        "text": "Agoraphobia"
    },
    {
        "id": "1735",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1736",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1734",
        "text": "Specific phobia"
    },
    {
        "id": "1737",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1738",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1739",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "173T",
        "text": "Anorgasmia"
    },
    {
        "id": "173U",
        "text": "Anorgasmia"
    },
    {
        "id": "173V",
        "text": "Anorgasmia"
    },
    {
        "id": "173W",
        "text": "Anorgasmia"
    },
    {
        "id": "173X",
        "text": "Anorgasmia"
    },
    {
        "id": "173Y",
        "text": "Male early ejaculation"
    },
    {
        "id": "173Z",
        "text": "Male early ejaculation"
    },
    {
        "id": "1740",
        "text": "Male early ejaculation"
    },
    {
        "id": "1741",
        "text": "Male early ejaculation"
    },
    {
        "id": "1742",
        "text": "Male early ejaculation"
    },
    {
        "id": "1743",
        "text": "Male delayed ejaculation"
    },
    {
        "id": "1744",
        "text": "Male delayed ejaculation"
    },
    {
        "id": "1745",
        "text": "Male delayed ejaculation"
    },
    {
        "id": "1746",
        "text": "Male delayed ejaculation"
    },
    {
        "id": "1747",
        "text": "Male delayed ejaculation"
    },
    {
        "id": "1748",
        "text": "Sexual pain disorders"
    },
    {
        "id": "1749",
        "text": "Secondary acute vestibular syndrome"
    },
    {
        "id": "174B",
        "text": "Deafness not otherwise specified"
    },
    {
        "id": "174C",
        "text": "Sexual pain disorders"
    },
    {
        "id": "174D",
        "text": "Sexual pain disorders"
    },
    {
        "id": "174E",
        "text": "Sexual pain disorders"
    },
    {
        "id": "174F",
        "text": "Sexual pain disorders"
    },
    {
        "id": "174G",
        "text": "Social anxiety disorder"
    },
    {
        "id": "174H",
        "text": "Separation anxiety disorder"
    },
    {
        "id": "174I",
        "text": "Selective mutism"
    },
    {
        "id": "172T",
        "text": "Dysphonia"
    },
    {
        "id": "174P",
        "text": "Aphonia"
    },
    {
        "id": "174Q",
        "text": "Nasality"
    },
    {
        "id": "174R",
        "text": "Speech dysfluency"
    },
    {
        "id": "174S",
        "text": "Idiopathic inflammatory optic neuropathy"
    },
    {
        "id": "174T",
        "text": "Isolated demyelinating syndromes of the central nervous system"
    },
    {
        "id": "174U",
        "text": "Neuromyelitis optica myelin oligodendrocyte glycoprotein antibody-positive"
    },
    {
        "id": "174V",
        "text": "Certain specified leukodystrophies"
    },
    {
        "id": "174Y",
        "text": "Anti-NMDA receptor encephalitis"
    },
    {
        "id": "1751",
        "text": "Single transverse myelitis aquaporin-4 antibody positive"
    },
    {
        "id": "1750",
        "text": "Recurrent optic neuritis aquaporin-4 antibody positive"
    },
    {
        "id": "174Z",
        "text": "Single optic neuritis aquaporin-4 antibody positive"
    },
    {
        "id": "1752",
        "text": "Recurrent transverse myelitis aquaporin-4 antibody positive"
    },
    {
        "id": "1753",
        "text": "Neuromyelitis optica"
    },
    {
        "id": "1754",
        "text": "Paraneoplastic or autoimmune disorders of the nervous system"
    },
    {
        "id": "1755",
        "text": "Acute disseminated encephalomyelitis"
    },
    {
        "id": "1756",
        "text": "Bickerstaff brainstem encephalitis"
    },
    {
        "id": "1757",
        "text": "Paraneoplastic or autoimmune disorders of the nervous system"
    },
    {
        "id": "1758",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "175A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "175B",
        "text": "Retrograde ejaculation"
    },
    {
        "id": "175C",
        "text": "Retrograde ejaculation"
    },
    {
        "id": "175D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "175E",
        "text": "Male delayed ejaculation"
    },
    {
        "id": "1759",
        "text": "Sexual pain-penetration disorder"
    },
    {
        "id": "175P",
        "text": "Concern about body appearance"
    },
    {
        "id": "175N",
        "text": "Acculturation difficulty"
    },
    {
        "id": "175L",
        "text": "Problems of adjustment to life-cycle transitions"
    },
    {
        "id": "175Q",
        "text": "Concern with appearance of ears"
    },
    {
        "id": "175M",
        "text": "Burnout"
    },
    {
        "id": "175R",
        "text": "Concern about breast appearance"
    },
    {
        "id": "175O",
        "text": "Inadequate social skills"
    },
    {
        "id": "175T",
        "text": "Loss or death of child"
    },
    {
        "id": "175S",
        "text": "Disappearance or death of family member"
    },
    {
        "id": "175V",
        "text": "Simulated skin disease"
    },
    {
        "id": "175W",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "175X",
        "text": "Developmental speech or language disorders"
    },
    {
        "id": "175Y",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "1663",
        "text": "Neurodevelopmental syndrome due to prenatal alcohol exposure"
    },
    {
        "id": "175Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1760",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1761",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1763",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "1767",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1768",
        "text": "Anorexia Nervosa"
    },
    {
        "id": "1769",
        "text": "Anorexia Nervosa"
    },
    {
        "id": "176A",
        "text": "Anorexia Nervosa with dangerously low body weight"
    },
    {
        "id": "176B",
        "text": "Anorexia Nervosa with significantly low body weight"
    },
    {
        "id": "176D",
        "text": "Anorexia Nervosa with dangerously low body weight, binge-purge pattern"
    },
    {
        "id": "176E",
        "text": "Anorexia Nervosa with significantly low body weight, restricting pattern"
    },
    {
        "id": "176C",
        "text": "Anorexia Nervosa with dangerously low body weight, restricting pattern"
    },
    {
        "id": "176F",
        "text": "Anorexia Nervosa with significantly low body weight, binge-purge pattern"
    },
    {
        "id": "176G",
        "text": "Feeding or eating disorders"
    },
    {
        "id": "176H",
        "text": "Bulimia Nervosa"
    },
    {
        "id": "176I",
        "text": "Binge eating disorder"
    },
    {
        "id": "176K",
        "text": "Crohn disease"
    },
    {
        "id": "176L",
        "text": "Inflammatory bowel diseases"
    },
    {
        "id": "176M",
        "text": "Avoidant-restrictive food intake disorder"
    },
    {
        "id": "176P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "176Q",
        "text": "Elimination disorders"
    },
    {
        "id": "174M",
        "text": "Encopresis"
    },
    {
        "id": "174J",
        "text": "Enuresis"
    },
    {
        "id": "175G",
        "text": "Bodily distress disorder"
    },
    {
        "id": "176N",
        "text": "Pica"
    },
    {
        "id": "176R",
        "text": "Gambling disorder"
    },
    {
        "id": "176S",
        "text": "Pyromania"
    },
    {
        "id": "176T",
        "text": "Kleptomania"
    },
    {
        "id": "171I",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "176U",
        "text": "Intermittent explosive disorder"
    },
    {
        "id": "176W",
        "text": "Dissociative disorders"
    },
    {
        "id": "176Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "176X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "176Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1770",
        "text": "Dissociative disorders"
    },
    {
        "id": "1771",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1772",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1775",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1776",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1774",
        "text": "Dissociative amnesia"
    },
    {
        "id": "1777",
        "text": "Dissociative amnesia with dissociative fugue"
    },
    {
        "id": "1778",
        "text": "Dissociative amnesia with dissociative fugue"
    },
    {
        "id": "1779",
        "text": "Depersonalization-derealization disorder"
    },
    {
        "id": "177B",
        "text": "Dissociative identity disorder"
    },
    {
        "id": "177D",
        "text": "Dissociative disorders"
    },
    {
        "id": "177E",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "177F",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "177G",
        "text": "Amnestic disorder due to multiple aetiological factors"
    },
    {
        "id": "177H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "177I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "177J",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "177K",
        "text": "MODY syndrome"
    },
    {
        "id": "177L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "177O",
        "text": "Failed attempted abortion"
    },
    {
        "id": "177P",
        "text": "Molar pregnancy"
    },
    {
        "id": "177Q",
        "text": "Blighted ovum or nonhydatidiform mole"
    },
    {
        "id": "177R",
        "text": "Oedema, proteinuria, or hypertensive disorders in pregnancy, childbirth, or the puerperium"
    },
    {
        "id": "177S",
        "text": "Pre-existing secondary hypertension complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "177T",
        "text": "Pre-eclampsia superimposed on chronic hypertension"
    },
    {
        "id": "177U",
        "text": "Gestational oedema or proteinuria without hypertension"
    },
    {
        "id": "177V",
        "text": "Gestational hypertension"
    },
    {
        "id": "177W",
        "text": "Pre-eclampsia"
    },
    {
        "id": "177X",
        "text": "Mild to moderate pre-eclampsia"
    },
    {
        "id": "177Y",
        "text": "Severe pre-eclampsia"
    },
    {
        "id": "177Z",
        "text": "Eclampsia"
    },
    {
        "id": "1780",
        "text": "Eclampsia in pregnancy"
    },
    {
        "id": "1781",
        "text": "Eclampsia in labour"
    },
    {
        "id": "1782",
        "text": "Eclampsia in the puerperium"
    },
    {
        "id": "175U",
        "text": "Malingering"
    },
    {
        "id": "1783",
        "text": "Artefactual skin disorder"
    },
    {
        "id": "1784",
        "text": "Psychological disturbance resulting in falsely perceived or actual skin damage or disorder in others"
    },
    {
        "id": "1785",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1786",
        "text": "Factitious skin disorder imposed on another"
    },
    {
        "id": "1787",
        "text": "Somatic delusion directed at the skin"
    },
    {
        "id": "1788",
        "text": "Delusion of infestation"
    },
    {
        "id": "1789",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "178A",
        "text": "Delusions of malodour"
    },
    {
        "id": "178C",
        "text": "Simulated skin disease"
    },
    {
        "id": "178D",
        "text": "Simulated skin disease"
    },
    {
        "id": "178F",
        "text": "Primary yaws"
    },
    {
        "id": "178G",
        "text": "Yaws"
    },
    {
        "id": "178H",
        "text": "Multiple papillomata"
    },
    {
        "id": "178I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "178J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "178K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "178L",
        "text": "Gummata and ulcers of yaws"
    },
    {
        "id": "178M",
        "text": "Gangosa"
    },
    {
        "id": "178N",
        "text": "Bone and joint lesions of yaws"
    },
    {
        "id": "178O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "178P",
        "text": "Functional gastrointestinal disorders"
    },
    {
        "id": "178R",
        "text": "Yaws"
    },
    {
        "id": "178U",
        "text": "Nonretentive faecal incontinence in children"
    },
    {
        "id": "178V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "178W",
        "text": "Adolescent rumination syndrome"
    },
    {
        "id": "178Y",
        "text": "Cyclic vomiting syndrome"
    },
    {
        "id": "1790",
        "text": "Functional bladder disorders associated with pelvic organ prolapse"
    },
    {
        "id": "1791",
        "text": "Overactive bladder associated with pelvic organ prolapse"
    },
    {
        "id": "1792",
        "text": "Functional bladder disorders, not otherwise specified"
    },
    {
        "id": "1793",
        "text": "Incontinence of faeces with or without incontinence of flatus"
    },
    {
        "id": "1794",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1795",
        "text": "Primary stress incontinence"
    },
    {
        "id": "1796",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1797",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1798",
        "text": "Urinary incontinence"
    },
    {
        "id": "176O",
        "text": "Rumination-regurgitation disorder"
    },
    {
        "id": "178Z",
        "text": "Cyclic vomiting syndrome"
    },
    {
        "id": "178T",
        "text": "Other functional anorectal disorders"
    },
    {
        "id": "178S",
        "text": "Functional defaecation disorders"
    },
    {
        "id": "178Q",
        "text": "Functional faecal incontinence"
    },
    {
        "id": "1799",
        "text": "Urinary incontinence"
    },
    {
        "id": "179A",
        "text": "Yaws"
    },
    {
        "id": "179B",
        "text": "Yaws"
    },
    {
        "id": "179C",
        "text": "Severity Scale Value"
    },
    {
        "id": "179D",
        "text": "Secondary oesophageal varices with bleeding"
    },
    {
        "id": "179E",
        "text": "Secondary oesophageal varices without bleeding"
    },
    {
        "id": "179F",
        "text": "Gastritis due to diseases classified elsewhere"
    },
    {
        "id": "179G",
        "text": "Duodenitis due to diseases classified elsewhere"
    },
    {
        "id": "179H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "179I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "179J",
        "text": "Helicobacter pylori induced gastritis"
    },
    {
        "id": "179L",
        "text": "Helicobacter-pylori associated duodenitis"
    },
    {
        "id": "179M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "179N",
        "text": "Viral duodenitis"
    },
    {
        "id": "179O",
        "text": "Diverticular disease of intestine"
    },
    {
        "id": "179P",
        "text": "Distal renal tubular acidosis due to familial or genetic disease classified elsewhere"
    },
    {
        "id": "179Q",
        "text": "Small kidney"
    },
    {
        "id": "179R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "179S",
        "text": "Osteopathy in renal osteodystrophy"
    },
    {
        "id": "179T",
        "text": "Aphthoid ulcer of large intestine"
    },
    {
        "id": "179U",
        "text": "Muir-Torre syndrome"
    },
    {
        "id": "179V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "179X",
        "text": "Chronic proteinuria"
    },
    {
        "id": "179Z",
        "text": "Delusions of malodour"
    },
    {
        "id": "1801",
        "text": "Psychological disturbance resulting in falsely perceived or actual skin damage or disorder in others"
    },
    {
        "id": "1802",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1803",
        "text": "Problems associated with absence, loss or death of others"
    },
    {
        "id": "1804",
        "text": "Intrapartum haemorrhage with coagulation defect"
    },
    {
        "id": "1805",
        "text": "Malnutrition in pregnancy"
    },
    {
        "id": "1806",
        "text": "Maternal care for red cell antibodies"
    },
    {
        "id": "1808",
        "text": "Visual release hallucinations"
    },
    {
        "id": "1809",
        "text": "Maternal care for suspected macrosomia"
    },
    {
        "id": "180A",
        "text": "Maternal care related to polyhydramnios"
    },
    {
        "id": "180B",
        "text": "Maternal care related to premature rupture of membranes"
    },
    {
        "id": "180D",
        "text": "Maternal care related to false labour"
    },
    {
        "id": "180E",
        "text": "False labour before 37 completed weeks of gestation"
    },
    {
        "id": "180F",
        "text": "Maternal care related to prolonged pregnancy"
    },
    {
        "id": "180G",
        "text": "Preterm labour or delivery"
    },
    {
        "id": "180H",
        "text": "Preterm delivery following iatrogenic induction of labour or caesarean section"
    },
    {
        "id": "180I",
        "text": "Failed medical induction of labour"
    },
    {
        "id": "180C",
        "text": "Morbidly adherent placenta"
    },
    {
        "id": "1807",
        "text": "Maternal care for fetal growth restriction"
    },
    {
        "id": "180J",
        "text": "Visual release hallucinations"
    },
    {
        "id": "180K",
        "text": "Obsessive-compulsive or related disorders"
    },
    {
        "id": "166K",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "166L",
        "text": "Symptoms or signs involving perceptual disturbance"
    },
    {
        "id": "180X",
        "text": "Chronic kidney disease, stage 5, on dialysis"
    },
    {
        "id": "180W",
        "text": "Chronic kidney disease, stage 5, not on dialysis"
    },
    {
        "id": "180V",
        "text": "Chronic kidney disease, stage 5"
    },
    {
        "id": "180U",
        "text": "Chronic kidney disease, stage 4"
    },
    {
        "id": "180T",
        "text": "Chronic kidney disease, stage 3b"
    },
    {
        "id": "180S",
        "text": "Chronic kidney disease, stage 3a"
    },
    {
        "id": "180R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "180Y",
        "text": "Chronic kidney disease, stage 2"
    },
    {
        "id": "180P",
        "text": "Chronic kidney disease, stage 1"
    },
    {
        "id": "180M",
        "text": "Acute kidney failure, stage 1"
    },
    {
        "id": "180N",
        "text": "Acute kidney failure, stage 2"
    },
    {
        "id": "180O",
        "text": "Acute kidney failure, stage 3"
    },
    {
        "id": "180L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "158X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1810",
        "text": "Diabetic retinopathy"
    },
    {
        "id": "169R",
        "text": "Adjustment disorder"
    },
    {
        "id": "1814",
        "text": "Benign cutaneous neoplasms with follicular differentiation"
    },
    {
        "id": "1815",
        "text": "Eccrine poroma"
    },
    {
        "id": "1816",
        "text": "Eccrine poroma"
    },
    {
        "id": "181B",
        "text": "Benign cutaneous neoplasms with follicular differentiation"
    },
    {
        "id": "181A",
        "text": "Cutaneous cysts"
    },
    {
        "id": "1819",
        "text": "Cutaneous cysts"
    },
    {
        "id": "1818",
        "text": "Epidermoid cyst"
    },
    {
        "id": "1817",
        "text": "Trichilemmal cyst"
    },
    {
        "id": "1800",
        "text": "Delusional disorder"
    },
    {
        "id": "179Y",
        "text": "Factitious skin disorder imposed on another"
    },
    {
        "id": "1660",
        "text": "Miscellaneous disorders involving the hair follicle"
    },
    {
        "id": "164N",
        "text": "Scarring alopecia"
    },
    {
        "id": "168U",
        "text": "Single episode depressive disorder"
    },
    {
        "id": "1693",
        "text": "Recurrent depressive disorder"
    },
    {
        "id": "169C",
        "text": "Dysthymic disorder"
    },
    {
        "id": "177C",
        "text": "Dissociative disorders"
    },
    {
        "id": "172G",
        "text": "Conduct-dissocial disorder"
    },
    {
        "id": "181D",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "172S",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "181F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "181G",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "181H",
        "text": "Gender incongruence"
    },
    {
        "id": "181I",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "177A",
        "text": "Possession trance disorder"
    },
    {
        "id": "181K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "181L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "181M",
        "text": "Autism spectrum disorder with disorder of intellectual development and with mild or no impairment of functional language with loss of previously acquired skills"
    },
    {
        "id": "157S",
        "text": "Autism spectrum disorder with disorder of intellectual development and with mild or no impairment of functional language without loss of previously acquired skills"
    },
    {
        "id": "181P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "157Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "181R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1580",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "181T",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language with loss of previously acquired skills"
    },
    {
        "id": "1583",
        "text": "Autism spectrum disorder with disorder of intellectual development and impaired functional language without loss of previously acquired skills"
    },
    {
        "id": "181V",
        "text": "Autism spectrum disorder with disorder of intellectual development and absence of functional language with loss of previously acquired skills"
    },
    {
        "id": "181W",
        "text": "Autism spectrum disorder with disorder of intellectual development and with mild or no impairment of functional language"
    },
    {
        "id": "181X",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "181Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "181Z",
        "text": "Autism spectrum disorder with disorder of intellectual development and with absence of functional language"
    },
    {
        "id": "1584",
        "text": "Autism spectrum disorder with disorder of intellectual development and absence of functional language without loss of previously acquired skills"
    },
    {
        "id": "1820",
        "text": "Interrupted aortic arch distal to subclavian artery, type A"
    },
    {
        "id": "1821",
        "text": "Interrupted aortic arch between subclavian and common carotid arteries, type B"
    },
    {
        "id": "1822",
        "text": "Interrupted aortic arch between carotid arteries, type C"
    },
    {
        "id": "1824",
        "text": "Adverse cutaneous reactions to medication"
    },
    {
        "id": "1826",
        "text": "Complications of cutaneous cosmetic procedures"
    },
    {
        "id": "1827",
        "text": "Adverse cutaneous reactions to herbal, homoeopathic or other alternative therapies"
    },
    {
        "id": "1828",
        "text": "Pigmentary abnormalities of skin due to drug"
    },
    {
        "id": "1829",
        "text": "Dermatoses precipitated by drug therapy"
    },
    {
        "id": "182A",
        "text": "Drug-induced hair abnormalities"
    },
    {
        "id": "182B",
        "text": "Drug-induced nail abnormalities"
    },
    {
        "id": "182C",
        "text": "Drug-induced hair abnormalities"
    },
    {
        "id": "182E",
        "text": "Dermatoses associated with specific classes of medication"
    },
    {
        "id": "182H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "182G",
        "text": "Localised adverse cutaneous reactions to administration of drug"
    },
    {
        "id": "182F",
        "text": "Certain specified adverse cutaneous reactions to medication"
    },
    {
        "id": "182I",
        "text": "Photosensitivity due to drug"
    },
    {
        "id": "182J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "182K",
        "text": "Drug-associated immune complex vasculitis"
    },
    {
        "id": "182L",
        "text": "Exanthematic drug eruption"
    },
    {
        "id": "182M",
        "text": "Lichenoid drug eruption"
    },
    {
        "id": "182N",
        "text": "Stevens-Johnson syndrome and toxic epidermal necrolysis due to drug"
    },
    {
        "id": "182O",
        "text": "Eczematous drug eruption"
    },
    {
        "id": "182P",
        "text": "Drug-induced erythroderma"
    },
    {
        "id": "182Q",
        "text": "DRESS syndrome"
    },
    {
        "id": "182R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "182S",
        "text": "Fixed drug eruption"
    },
    {
        "id": "182T",
        "text": "Feeding problem of child"
    },
    {
        "id": "181C",
        "text": "Disorders of intellectual development"
    },
    {
        "id": "174X",
        "text": "Multiple sclerosis or other white matter disorders"
    },
    {
        "id": "181J",
        "text": "Dementia"
    },
    {
        "id": "174W",
        "text": "Multiple sclerosis or other white matter disorders"
    },
    {
        "id": "1720",
        "text": "Obsessive-compulsive disorder with fair to good insight"
    },
    {
        "id": "1721",
        "text": "Obsessive-compulsive disorder with poor to absent insight"
    },
    {
        "id": "1722",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1723",
        "text": "Body dysmorphic disorder with fair to good insight"
    },
    {
        "id": "1724",
        "text": "Body dysmorphic disorder with poor to absent insight"
    },
    {
        "id": "1725",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1726",
        "text": "Olfactory reference disorder with fair to good insight"
    },
    {
        "id": "1727",
        "text": "Olfactory reference disorder with poor to absent insight"
    },
    {
        "id": "1728",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1729",
        "text": "Hypochondriasis with fair to good insight"
    },
    {
        "id": "172A",
        "text": "Hypochondriasis with poor to absent insight"
    },
    {
        "id": "172B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "172C",
        "text": "Hoarding disorder with fair to good insight"
    },
    {
        "id": "172D",
        "text": "Hoarding disorder with poor to absent insight"
    },
    {
        "id": "172E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1833",
        "text": "Schizoaffective disorder, first episode, currently symptomatic"
    },
    {
        "id": "1834",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1835",
        "text": "Schizoaffective disorder, first episode, in partial remission"
    },
    {
        "id": "1836",
        "text": "Schizoaffective disorder, first episode, in full remission"
    },
    {
        "id": "1837",
        "text": "Schizoaffective disorder, multiple episodes, currently symptomatic"
    },
    {
        "id": "1838",
        "text": "Schizoaffective disorder, multiple episodes, in partial remission"
    },
    {
        "id": "1839",
        "text": "Schizoaffective disorder, multiple episodes, in full remission"
    },
    {
        "id": "183A",
        "text": "Schizoaffective disorder, continuous, currently symptomatic"
    },
    {
        "id": "183B",
        "text": "Schizoaffective disorder, continuous, in partial remission"
    },
    {
        "id": "183C",
        "text": "Schizoaffective disorder, continuous, in full remission"
    },
    {
        "id": "166X",
        "text": "Tourette syndrome"
    },
    {
        "id": "166W",
        "text": "Primary tics or tic disorders"
    },
    {
        "id": "166T",
        "text": "Chronic motor tic disorder"
    },
    {
        "id": "166V",
        "text": "Chronic phonic tic disorder"
    },
    {
        "id": "175H",
        "text": "Mild bodily distress disorder"
    },
    {
        "id": "175I",
        "text": "Moderate bodily distress disorder"
    },
    {
        "id": "175J",
        "text": "Severe bodily distress disorder"
    },
    {
        "id": "183G",
        "text": "Eating problem in child"
    },
    {
        "id": "174K",
        "text": "Nocturnal enuresis"
    },
    {
        "id": "174L",
        "text": "Nocturnal and diurnal enuresis"
    },
    {
        "id": "174O",
        "text": "Encopresis with constipation or overflow incontinence"
    },
    {
        "id": "172M",
        "text": "Oppositional defiant disorder with chronic irritability-anger with typical prosocial emotions"
    },
    {
        "id": "183I",
        "text": "Oppositional defiant disorder with chronic irritability-anger with limited prosocial emotions"
    },
    {
        "id": "175K",
        "text": "Oppositional defiant disorder with chronic irritability-anger"
    },
    {
        "id": "183J",
        "text": "Oppositional defiant disorder without chronic irritability-anger"
    },
    {
        "id": "172I",
        "text": "Oppositional defiant disorder without chronic irritability-anger with limited prosocial emotions"
    },
    {
        "id": "172N",
        "text": "Oppositional defiant disorder without chronic irritability-anger with typical prosocial emotions"
    },
    {
        "id": "174N",
        "text": "Encopresis without constipation or overflow incontinence"
    },
    {
        "id": "183H",
        "text": "Diurnal enuresis"
    },
    {
        "id": "183K",
        "text": "Conduct-dissocial disorder, childhood onset"
    },
    {
        "id": "183L",
        "text": "Conduct-dissocial disorder, adolescent onset"
    },
    {
        "id": "172J",
        "text": "Conduct-dissocial disorder, childhood onset with limited prosocial emotions"
    },
    {
        "id": "172O",
        "text": "Conduct-dissocial disorder, childhood onset with typical prosocial emotions"
    },
    {
        "id": "172K",
        "text": "Conduct-dissocial disorder, adolescent onset with limited prosocial emotions"
    },
    {
        "id": "172P",
        "text": "Conduct-dissocial disorder, adolescent onset with typical prosocial emotions"
    },
    {
        "id": "183M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "183N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "183O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "183Q",
        "text": "Bromoderma"
    },
    {
        "id": "183R",
        "text": "Halogen eruption"
    },
    {
        "id": "183S",
        "text": "Drug-induced acute generalised exanthematous pustulosis"
    },
    {
        "id": "183V",
        "text": "Drug-induced toxic epidermal necrolysis"
    },
    {
        "id": "183W",
        "text": "Drug-induced Stevens-Johnson and toxic epidermal necrolysis overlap syndrome"
    },
    {
        "id": "183X",
        "text": "Systemic contact dermatitis due to ingested allergen"
    },
    {
        "id": "183Z",
        "text": "Drug-induced urticaria"
    },
    {
        "id": "1840",
        "text": "Drug-induced angioedema"
    },
    {
        "id": "1843",
        "text": "Drug eruptions"
    },
    {
        "id": "1844",
        "text": "Limited fixed drug eruption"
    },
    {
        "id": "1845",
        "text": "Generalised bullous fixed drug eruption"
    },
    {
        "id": "1846",
        "text": "Pseudolymphomatous drug hypersensitivity syndrome"
    },
    {
        "id": "1847",
        "text": "Miscellaneous specified cutaneous eruptions due to drug"
    },
    {
        "id": "1848",
        "text": "Drug-induced annular erythema"
    },
    {
        "id": "1849",
        "text": "Pityriasiform drug eruption"
    },
    {
        "id": "184D",
        "text": "Bipolar or related disorders"
    },
    {
        "id": "171U",
        "text": "Body dysmorphic disorder"
    },
    {
        "id": "184F",
        "text": "Non-melanin pigmentation due to drug"
    },
    {
        "id": "184I",
        "text": "Drug-induced hypermelanosis"
    },
    {
        "id": "184H",
        "text": "Drug-induced hypomelanosis"
    },
    {
        "id": "184J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "184K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "184N",
        "text": "Morphoea"
    },
    {
        "id": "184O",
        "text": "Psychological disorder related to obstetric fistula"
    },
    {
        "id": "184S",
        "text": "Ataxia - deafness - intellectual disability syndrome"
    },
    {
        "id": "184T",
        "text": "Corneal anaesthesia - deafness - intellectual disability"
    },
    {
        "id": "184U",
        "text": "Retinitis pigmentosa - intellectual disability - deafness - hypogenitalism"
    },
    {
        "id": "184V",
        "text": "Schizophrenia - intellectual disability - deafness - retinitis"
    },
    {
        "id": "184R",
        "text": "Microcephaly - deafness - intellectual disability"
    },
    {
        "id": "184Q",
        "text": "Intellectual disability \u2013 enteropathy \u2013 deafness \u2013 neuropathy \u2013 ichthyosis \u2013 keratoderma syndrome"
    },
    {
        "id": "184P",
        "text": "Alopecia \u2013 psychomotor epilepsy \u2013 periodontal pyorrhoea \u2013 intellectual disability syndrome"
    },
    {
        "id": "184W",
        "text": "Peptic ulcer, site unspecified"
    },
    {
        "id": "184Y",
        "text": "Gastric ulcer"
    },
    {
        "id": "184Z",
        "text": "Drug-induced duodenitis"
    },
    {
        "id": "1852",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1853",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "166J",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "1854",
        "text": "Early-onset sarcoidosis"
    },
    {
        "id": "1855",
        "text": "Cutaneous sarcoidosis"
    },
    {
        "id": "1856",
        "text": "Autoinflammatory granulomatosis of childhood"
    },
    {
        "id": "1842",
        "text": "Angioedema due to angiotensin converting enzyme inhibitors"
    },
    {
        "id": "1841",
        "text": "Drug-induced anaphylaxis"
    },
    {
        "id": "183Y",
        "text": "Drug-induced urticaria, angioedema and anaphylaxis"
    },
    {
        "id": "183U",
        "text": "Drug-induced Stevens-Johnson syndrome"
    },
    {
        "id": "183T",
        "text": "Acneform drug eruption"
    },
    {
        "id": "182D",
        "text": "Drug-induced oral conditions"
    },
    {
        "id": "183P",
        "text": "Bromoderma"
    },
    {
        "id": "1857",
        "text": "Iododerma"
    },
    {
        "id": "1858",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "185A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "185B",
        "text": "Male early ejaculation"
    },
    {
        "id": "185C",
        "text": "Positive symptoms in primary psychotic disorders"
    },
    {
        "id": "185D",
        "text": "Negative symptoms in primary psychotic disorders"
    },
    {
        "id": "185E",
        "text": "Depressive mood symptoms in primary psychotic disorders"
    },
    {
        "id": "185F",
        "text": "Manic mood symptoms in primary psychotic disorders"
    },
    {
        "id": "185G",
        "text": "Psychomotor symptoms in primary psychotic disorders"
    },
    {
        "id": "185H",
        "text": "Cognitive symptoms in primary psychotic disorders"
    },
    {
        "id": "165C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165D",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "185I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1656",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1659",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1658",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1657",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "185J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "185K",
        "text": "Annular elastolytic giant cell granuloma"
    },
    {
        "id": "185L",
        "text": "Acute stress reaction"
    },
    {
        "id": "185M",
        "text": "Sexual dysfunctions"
    },
    {
        "id": "185N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "185P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "185O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "185Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "185R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "185S",
        "text": "Ejaculatory dysfunctions"
    },
    {
        "id": "156L",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1813",
        "text": "Autism spectrum disorder without disorder of intellectual development and with mild or no impairment of functional language"
    },
    {
        "id": "158L",
        "text": "Autism spectrum disorder with disorder of intellectual development and with mild or no impairment of functional language"
    },
    {
        "id": "181N",
        "text": "Autism spectrum disorder without disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "181O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "181Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1588",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "181S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "181U",
        "text": "Autism spectrum disorder with disorder of intellectual development and with absence of functional language"
    },
    {
        "id": "156M",
        "text": "Developmental learning disorder"
    },
    {
        "id": "182U",
        "text": "Schizophrenia, first episode, currently symptomatic"
    },
    {
        "id": "182V",
        "text": "Schizophrenia, first episode, in partial remission"
    },
    {
        "id": "182W",
        "text": "Schizophrenia, first episode, in full remission"
    },
    {
        "id": "182X",
        "text": "Schizophrenia, multiple episodes, currently symptomatic"
    },
    {
        "id": "182Y",
        "text": "Schizophrenia, multiple episodes, in partial remission"
    },
    {
        "id": "182Z",
        "text": "Schizophrenia, multiple episodes, in full remission"
    },
    {
        "id": "1830",
        "text": "Schizophrenia, continuous, currently symptomatic"
    },
    {
        "id": "1831",
        "text": "Schizophrenia, continuous, in partial remission"
    },
    {
        "id": "1832",
        "text": "Schizophrenia, continuous, in full remission"
    },
    {
        "id": "183F",
        "text": "Acute and transient psychotic disorder, first episode, in full remission"
    },
    {
        "id": "183D",
        "text": "Acute and transient psychotic disorder, first episode, currently symptomatic"
    },
    {
        "id": "185T",
        "text": "Acute and transient psychotic disorder, first episode, in partial remission"
    },
    {
        "id": "185U",
        "text": "Acute and transient psychotic disorder, first episode"
    },
    {
        "id": "185V",
        "text": "Acute and transient psychotic disorder, multiple episodes"
    },
    {
        "id": "185W",
        "text": "Acute and transient psychotic disorder, multiple episodes, currently symptomatic"
    },
    {
        "id": "185X",
        "text": "Acute and transient psychotic disorder, multiple episodes, in partial remission"
    },
    {
        "id": "185Y",
        "text": "Acute and transient psychotic disorder, multiple episodes, in full remission"
    },
    {
        "id": "1764",
        "text": "Attention deficit hyperactivity disorder, predominantly hyperactive-impulsive presentation"
    },
    {
        "id": "1765",
        "text": "Attention deficit hyperactivity disorder, predominantly inattentive presentation"
    },
    {
        "id": "1766",
        "text": "Attention deficit hyperactivity disorder, combined presentation"
    },
    {
        "id": "1860",
        "text": "Acalculia"
    },
    {
        "id": "185Z",
        "text": "Dyslexia and alexia"
    },
    {
        "id": "1861",
        "text": "Agraphia"
    },
    {
        "id": "1862",
        "text": "Dyscalculia"
    },
    {
        "id": "1863",
        "text": "Apraxia"
    },
    {
        "id": "1864",
        "text": "Infections of the nail or perionychium"
    },
    {
        "id": "1866",
        "text": "Premalignant or malignant disorders of the penis or scrotum"
    },
    {
        "id": "1867",
        "text": "Problems associated with harmful or traumatic events"
    },
    {
        "id": "1868",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1869",
        "text": "Acute obstructive laryngitis"
    },
    {
        "id": "186A",
        "text": "Formula patterns (TM1)"
    },
    {
        "id": "186B",
        "text": "Certain protozoal infections affecting the skin"
    },
    {
        "id": "186C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "186D",
        "text": "Adverse effects of phototherapy"
    },
    {
        "id": "186E",
        "text": "Certain fluke infestations affecting the skin"
    },
    {
        "id": "186F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "186G",
        "text": "Syndromes with structural anomalies due to inborn errors of metabolism"
    },
    {
        "id": "186H",
        "text": "Neoplasms of unknown behaviour of the respiratory system"
    },
    {
        "id": "186I",
        "text": "Neutropaenia"
    },
    {
        "id": "186J",
        "text": "Neoplasms of the endocrine system"
    },
    {
        "id": "186K",
        "text": "Carcinoma in situ of the respiratory system"
    },
    {
        "id": "186L",
        "text": "Hepatic porphyrias affecting the skin"
    },
    {
        "id": "186M",
        "text": "Skin disorders localised to the scalp"
    },
    {
        "id": "186N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "186O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "186P",
        "text": "Contact dermatitis of specified site"
    },
    {
        "id": "186Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "186R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "186S",
        "text": "Neoplasms of the breast"
    },
    {
        "id": "186T",
        "text": "Human papillomavirus infection of skin or mucous membrane"
    },
    {
        "id": "186U",
        "text": "Neoplasms of the female genital organs"
    },
    {
        "id": "186V",
        "text": "Secondary neoplasms of the respiratory system"
    },
    {
        "id": "186W",
        "text": "Influenza due to identified seasonal influenza virus"
    },
    {
        "id": "186X",
        "text": "Certain sexually transmissible bacterial infections affecting skin"
    },
    {
        "id": "186Y",
        "text": "Neonatal fungal infections involving the skin"
    },
    {
        "id": "186Z",
        "text": "Benign neoplasms of the respiratory system"
    },
    {
        "id": "1870",
        "text": "Acquired prion disease"
    },
    {
        "id": "1871",
        "text": "Neoplasms of the appendix"
    },
    {
        "id": "1872",
        "text": "Structural developmental anomalies of the skin"
    },
    {
        "id": "1873",
        "text": "Mucocutaneous or cutaneous pain syndromes"
    },
    {
        "id": "1874",
        "text": "Megaloblastic anaemia due to vitamin B12 deficiency"
    },
    {
        "id": "1875",
        "text": "Congenital abnormalities of uterus, except cervix"
    },
    {
        "id": "1876",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1877",
        "text": "Foreign body reactions to hair"
    },
    {
        "id": "1878",
        "text": "Genetic syndromes with alopecia or hypotrichosis"
    },
    {
        "id": "1879",
        "text": "Lipoblastoma"
    },
    {
        "id": "187A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "187B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "187C",
        "text": "Viral exanthems"
    },
    {
        "id": "187D",
        "text": "Neoplasms of the circulatory system"
    },
    {
        "id": "187E",
        "text": "Neoplasms of uncertain behaviour of the respiratory system"
    },
    {
        "id": "187F",
        "text": "Congenital abnormalities of cervix uteri"
    },
    {
        "id": "187G",
        "text": "Syndromes with obesity as a major feature"
    },
    {
        "id": "187H",
        "text": "Sexually transmissible viral hepatitis"
    },
    {
        "id": "187I",
        "text": "Primary cutaneous B-cell lymphomas"
    },
    {
        "id": "187J",
        "text": "Neonatal viral infections involving the skin"
    },
    {
        "id": "187K",
        "text": "Neoplasms of the male genital organs"
    },
    {
        "id": "187L",
        "text": "Pox virus infections of the skin"
    },
    {
        "id": "187M",
        "text": "Nonorgan specific systemic autoimmune disorders involving the skin"
    },
    {
        "id": "187N",
        "text": "Disorders of lymphocyte function"
    },
    {
        "id": "187O",
        "text": "Malignant neoplasms of the respiratory system"
    },
    {
        "id": "187P",
        "text": "Neoplasms of conjunctiva"
    },
    {
        "id": "187Q",
        "text": "Sexually transmissible infections due to Herpes simplex virus"
    },
    {
        "id": "187R",
        "text": "Neoplasms of the liver"
    },
    {
        "id": "187S",
        "text": "Premalignant or malignant disorders of the vulva"
    },
    {
        "id": "187T",
        "text": "Self-inflicted hair-damaging disorder"
    },
    {
        "id": "187U",
        "text": "Congenital abnormalities of vagina"
    },
    {
        "id": "187V",
        "text": "Chromosomal disorders affecting the skin"
    },
    {
        "id": "187W",
        "text": "Sexually transmissible infections due to chlamydia"
    },
    {
        "id": "187X",
        "text": "Photoaggravated skin disease"
    },
    {
        "id": "187Y",
        "text": "Subcutaneous mycoses"
    },
    {
        "id": "187Z",
        "text": "Neoplasms of orbit"
    },
    {
        "id": "1880",
        "text": "Immune dysregulation syndromes with hypopigmentation"
    },
    {
        "id": "1881",
        "text": "Miscellaneous haematological neoplasms which may arise in the skin"
    },
    {
        "id": "1882",
        "text": "Clinical findings on diagnostic imaging of urinary organs"
    },
    {
        "id": "1883",
        "text": "Neoplasms of the duodenum"
    },
    {
        "id": "1884",
        "text": "Developmental defects of hair or nails"
    },
    {
        "id": "1885",
        "text": "Keratolysis exfoliativa"
    },
    {
        "id": "1886",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1887",
        "text": "Clinical findings on diagnostic imaging of breast"
    },
    {
        "id": "1888",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1889",
        "text": "Infections of the nail or perionychium"
    },
    {
        "id": "188A",
        "text": "Skin injury due to exposure to corrosive substances"
    },
    {
        "id": "188B",
        "text": "Xerostomia"
    },
    {
        "id": "188C",
        "text": "Ascariasis"
    },
    {
        "id": "188D",
        "text": "Injuries of the nervous system"
    },
    {
        "id": "188E",
        "text": "Adenomyosis"
    },
    {
        "id": "188F",
        "text": "Paroxysmal autonomic disorders"
    },
    {
        "id": "188H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "188I",
        "text": "Inflammatory dermatoses of the perianal area"
    },
    {
        "id": "188J",
        "text": "Systemic mycoses affecting skin"
    },
    {
        "id": "188K",
        "text": "Haemolytic anaemias"
    },
    {
        "id": "188L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "188M",
        "text": "Iron deficiency anaemia"
    },
    {
        "id": "188N",
        "text": "Certain zoonotic bacterial infections involving the skin"
    },
    {
        "id": "188O",
        "text": "Intestinal infections due to Cytomegalovirus"
    },
    {
        "id": "188P",
        "text": "Neoplasms of the oesophagus"
    },
    {
        "id": "188Q",
        "text": "Dermatitis or eczema of certain specified sites"
    },
    {
        "id": "188R",
        "text": "Neoplasms of peritoneum or retroperitoneum"
    },
    {
        "id": "188S",
        "text": "Abnormal vascular reactivity to cold"
    },
    {
        "id": "188T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "188U",
        "text": "Syndromic genetically-determined hypermelanosis or lentiginosis"
    },
    {
        "id": "188V",
        "text": "Skin or soft tissue injury due to exposure to cold"
    },
    {
        "id": "188W",
        "text": "Sexually transmissible infections due to Human papillomavirus"
    },
    {
        "id": "188X",
        "text": "Lichen simplex"
    },
    {
        "id": "188Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "188Z",
        "text": "Neoplasms of the anal canal"
    },
    {
        "id": "1890",
        "text": "Paraneoplastic or autoimmune neuromuscular transmission disorders"
    },
    {
        "id": "1891",
        "text": "Erythrodermas of infancy"
    },
    {
        "id": "1892",
        "text": "Dermatoses of the eye, eyelids or eyebrows"
    },
    {
        "id": "1893",
        "text": "Infections of the napkin area"
    },
    {
        "id": "1894",
        "text": "Genetic disorders of the immune system affecting the skin"
    },
    {
        "id": "1895",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1896",
        "text": "In situ neoplasms of skin"
    },
    {
        "id": "1897",
        "text": "Inflammatory dermatoses of the vulva"
    },
    {
        "id": "1898",
        "text": "Genetic or developmental disorders involving lips or oral mucosa"
    },
    {
        "id": "1899",
        "text": "Miscellaneous specified dermatoses provoked by pressure"
    },
    {
        "id": "189A",
        "text": "Adverse cutaneous effects of therapeutic ionizing irradiation"
    },
    {
        "id": "189B",
        "text": "Dermatoses of the lips or oral cavity"
    },
    {
        "id": "189C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "189D",
        "text": "Palmoplantar keratodermas"
    },
    {
        "id": "189E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "189F",
        "text": "Genetic syndromes affecting cutaneous vasculature"
    },
    {
        "id": "189G",
        "text": "Genetic disorders of amino acid metabolism or transport affecting the skin"
    },
    {
        "id": "189H",
        "text": "Abnormal results of function studies of other organs and systems"
    },
    {
        "id": "189I",
        "text": "Neoplasms of the nervous system"
    },
    {
        "id": "189J",
        "text": "Malformations involving cutaneous lymphatic vessels"
    },
    {
        "id": "189K",
        "text": "Viral infections of the central nervous system"
    },
    {
        "id": "189L",
        "text": "Genetic disorders of porphyrin metabolism affecting the skin"
    },
    {
        "id": "189M",
        "text": "Disorders of vitamins or their metabolism which may affect the skin"
    },
    {
        "id": "189N",
        "text": "Human herpes virus infections involving skin or mucous membrane"
    },
    {
        "id": "189O",
        "text": "Inherited bone dysplasia"
    },
    {
        "id": "189P",
        "text": "Prenatally acquired infections with neonatal skin manifestations"
    },
    {
        "id": "189Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "189R",
        "text": "Functional dyspepsia"
    },
    {
        "id": "189S",
        "text": "Diabetes mellitus, type unspecified"
    },
    {
        "id": "189T",
        "text": "Neoplasms of the stomach"
    },
    {
        "id": "189U",
        "text": "Chronic enteritis of uncertain aetiology"
    },
    {
        "id": "189V",
        "text": "Diabetes mellitus"
    },
    {
        "id": "189W",
        "text": "Functional constipation"
    },
    {
        "id": "189X",
        "text": "Sepsis"
    },
    {
        "id": "189Y",
        "text": "Burkitt lymphoma including Burkitt leukaemia"
    },
    {
        "id": "189Z",
        "text": "Malignant neoplasms of breast"
    },
    {
        "id": "1900",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1901",
        "text": "Immunodeficiency with natural-killer cell deficiency"
    },
    {
        "id": "1902",
        "text": "Monogenic autoinflammatory syndromes"
    },
    {
        "id": "1903",
        "text": "Hyperthyroidism due to medicaments or other exogenous substances"
    },
    {
        "id": "1904",
        "text": "Thyrotoxicosis"
    },
    {
        "id": "1905",
        "text": "Female Genital Mutilation"
    },
    {
        "id": "1906",
        "text": "Undescended testicle, unilateral"
    },
    {
        "id": "1907",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1908",
        "text": "Sporadic Parkinson disease"
    },
    {
        "id": "1909",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "190A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "190B",
        "text": "Eyelid trauma"
    },
    {
        "id": "190C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "190D",
        "text": "Disorders of acoustic nerve"
    },
    {
        "id": "190E",
        "text": "Postprocedural disorders of ear or mastoid process"
    },
    {
        "id": "190F",
        "text": "Hypertensive renal disease"
    },
    {
        "id": "190G",
        "text": "Other pericarditis as current complication following acute myocardial infarction"
    },
    {
        "id": "190H",
        "text": "Mitral valve stenosis"
    },
    {
        "id": "190I",
        "text": "Acute sinusitis"
    },
    {
        "id": "190J",
        "text": "Acute laryngitis or tracheitis"
    },
    {
        "id": "190K",
        "text": "Interstitial lung diseases associated with systemic diseases"
    },
    {
        "id": "190L",
        "text": "Cheilitis"
    },
    {
        "id": "190M",
        "text": "Disorders of lips"
    },
    {
        "id": "190N",
        "text": "Stevens-Johnson syndrome"
    },
    {
        "id": "190O",
        "text": "Contact cheilitis"
    },
    {
        "id": "190P",
        "text": "Rheumatoid arthritis with splenomegaly and leukopenia"
    },
    {
        "id": "190Q",
        "text": "Gastrointestinal arthritis"
    },
    {
        "id": "190R",
        "text": "Acquired wrist drop"
    },
    {
        "id": "190S",
        "text": "Acquired unequal limb length"
    },
    {
        "id": "190T",
        "text": "Bladder pain"
    },
    {
        "id": "190U",
        "text": "Acute tubulo-interstitial nephritis"
    },
    {
        "id": "190V",
        "text": "Chronic tubulo-interstitial nephritis"
    },
    {
        "id": "190W",
        "text": "Urethritis and urethral syndrome"
    },
    {
        "id": "190X",
        "text": "Gout"
    },
    {
        "id": "190Y",
        "text": "Postpartum coagulation defects"
    },
    {
        "id": "190Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1910",
        "text": "Undescended testicle, bilateral"
    },
    {
        "id": "1911",
        "text": "Coma"
    },
    {
        "id": "1912",
        "text": "Thyroiditis"
    },
    {
        "id": "1913",
        "text": "Other chronic thyroiditis"
    },
    {
        "id": "1914",
        "text": "Transient congenital hypothyroidism due to monoallelic or biallelic mutations of dual oxidase 2 mutation"
    },
    {
        "id": "1915",
        "text": "Struma ovarii"
    },
    {
        "id": "1916",
        "text": "Familial gestational hyperthyroidism"
    },
    {
        "id": "1917",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1918",
        "text": "Hypotension"
    },
    {
        "id": "1919",
        "text": "Brachial plexus palsy in newborn"
    },
    {
        "id": "191A",
        "text": "Neonatal encephalopathy, severe"
    },
    {
        "id": "191B",
        "text": "Fetus or newborn affected by maternal factors or by complications of pregnancy, labour or delivery"
    },
    {
        "id": "191C",
        "text": "Cardiac arrhythmias in the neonate"
    },
    {
        "id": "191D",
        "text": "Certain disorders originating in the perinatal period"
    },
    {
        "id": "191E",
        "text": "Skin disorders specific to the perinatal or neonatal period"
    },
    {
        "id": "191F",
        "text": "Large newborn for gestational age"
    },
    {
        "id": "191G",
        "text": "Neonatal encephalopathy, mild"
    },
    {
        "id": "191H",
        "text": "Metabolic acidaemia in newborn"
    },
    {
        "id": "191I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "191J",
        "text": "Acute bronchiolitis"
    },
    {
        "id": "191K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "191L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "191M",
        "text": "Sepsis due to Actinomycosis without mention of septic shock"
    },
    {
        "id": "191N",
        "text": "Diseases of the blood or blood-forming organs"
    },
    {
        "id": "191O",
        "text": "Congenital anomaly blood or lymph other"
    },
    {
        "id": "191P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "191Q",
        "text": "Purpura or coagulation defect"
    },
    {
        "id": "191R",
        "text": "Vasculitis"
    },
    {
        "id": "191S",
        "text": "Mycoses"
    },
    {
        "id": "191T",
        "text": "Disorders of the thyroid gland or thyroid hormones system"
    },
    {
        "id": "191U",
        "text": "Congenital hypothyroidism due to iodine deficiency"
    },
    {
        "id": "191V",
        "text": "Myxoedema coma"
    },
    {
        "id": "191W",
        "text": "Thyrotoxicosis"
    },
    {
        "id": "191X",
        "text": "Primary hyperthyroidism"
    },
    {
        "id": "191Y",
        "text": "Familial hyperthyroidism"
    },
    {
        "id": "191Z",
        "text": "Diabetes mellitus"
    },
    {
        "id": "1920",
        "text": "Immune-related Type 1 diabetes mellitus"
    },
    {
        "id": "1921",
        "text": "Type 1 diabetes mellitus"
    },
    {
        "id": "1922",
        "text": "Diabetes mellitus, other specified type"
    },
    {
        "id": "1923",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1924",
        "text": "Hypoglycaemia without associated diabetes"
    },
    {
        "id": "1925",
        "text": "Endocrine tumours"
    },
    {
        "id": "1926",
        "text": "Carcinoid syndrome"
    },
    {
        "id": "1927",
        "text": "Short stature, not elsewhere classified"
    },
    {
        "id": "1928",
        "text": "Metabolic disorders"
    },
    {
        "id": "1929",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "192A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "192B",
        "text": "Diseases of the circulatory system"
    },
    {
        "id": "192C",
        "text": "Neoplasms of the circulatory system"
    },
    {
        "id": "192D",
        "text": "White coat hypertension"
    },
    {
        "id": "192E",
        "text": "Ischaemic heart diseases"
    },
    {
        "id": "192F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "192G",
        "text": "Heart valve diseases"
    },
    {
        "id": "192H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "192I",
        "text": "Rheumatic chorea"
    },
    {
        "id": "192J",
        "text": "Rheumatic chorea with heart involvement"
    },
    {
        "id": "192K",
        "text": "Rheumatic chorea without heart involvement"
    },
    {
        "id": "192L",
        "text": "Chronic rheumatic heart diseases, not elsewhere classified"
    },
    {
        "id": "192M",
        "text": "Rheumatic myocarditis"
    },
    {
        "id": "192N",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "192O",
        "text": "Pacemaker or implantable cardioverter defibrillator dysfunction"
    },
    {
        "id": "192P",
        "text": "Heart failure"
    },
    {
        "id": "192Q",
        "text": "Cardiomegaly"
    },
    {
        "id": "192R",
        "text": "Acute arterial occlusion"
    },
    {
        "id": "192S",
        "text": "Chronic arterial occlusive disease"
    },
    {
        "id": "192T",
        "text": "Chronic arterial occlusive disease classified by functional impact"
    },
    {
        "id": "192U",
        "text": "Cholesterol atheroembolism"
    },
    {
        "id": "192V",
        "text": "Postprocedural true or false aortic aneurysm"
    },
    {
        "id": "192W",
        "text": "Arterial aneurysm or dissection, excluding aorta"
    },
    {
        "id": "192X",
        "text": "Chronic peripheral venous insufficiency of lower extremities"
    },
    {
        "id": "192Y",
        "text": "Venous varicosities of sites other than lower extremity"
    },
    {
        "id": "192Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1930",
        "text": "Secondary lymphoedema"
    },
    {
        "id": "1931",
        "text": "Congenital cyst of mediastinum"
    },
    {
        "id": "1932",
        "text": "Upper respiratory tract disorders"
    },
    {
        "id": "1933",
        "text": "Allergic rhinitis due to pollen"
    },
    {
        "id": "1934",
        "text": "Cystic fibrosis"
    },
    {
        "id": "1935",
        "text": "Diseases or disorders of orofacial complex"
    },
    {
        "id": "1936",
        "text": "Miscellaneous specified disorders of lips or oral mucosa"
    },
    {
        "id": "1937",
        "text": "Aplasia of lacrimal or salivary glands"
    },
    {
        "id": "1938",
        "text": "Pulp abscess"
    },
    {
        "id": "1939",
        "text": "Periapical abscess with sinus"
    },
    {
        "id": "193A",
        "text": "Periapical abscess without sinus"
    },
    {
        "id": "193B",
        "text": "Abscess of periodontium"
    },
    {
        "id": "193C",
        "text": "Dentofacial anomalies"
    },
    {
        "id": "193D",
        "text": "Cysts of oral or facial-neck region"
    },
    {
        "id": "193E",
        "text": "Certain specified diseases of jaws"
    },
    {
        "id": "193F",
        "text": "Sensory disturbances affecting orofacial complex"
    },
    {
        "id": "193G",
        "text": "Acquired anatomical alterations of the oesophagus"
    },
    {
        "id": "193H",
        "text": "Gastro-oesophageal reflux disease"
    },
    {
        "id": "193I",
        "text": "Stress ulcer of stomach"
    },
    {
        "id": "193J",
        "text": "Actinic cheilitis"
    },
    {
        "id": "193K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "193L",
        "text": "Diabetes mellitus"
    },
    {
        "id": "193M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "193N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "193O",
        "text": "Rheumatoid arthritis"
    },
    {
        "id": "193P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "193Q",
        "text": "Renal or perinephric abscess"
    },
    {
        "id": "193U",
        "text": "Chronic kidney disease"
    },
    {
        "id": "193X",
        "text": "Macroscopic changes of size of the kidney"
    },
    {
        "id": "193Y",
        "text": "Other disorders of bladder"
    },
    {
        "id": "193Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1940",
        "text": "Maternal care related to certain specified disorders of amniotic fluid or membranes"
    },
    {
        "id": "1941",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1942",
        "text": "Obstructed labour due to other causes"
    },
    {
        "id": "1943",
        "text": "Complications of anaesthesia during pregnancy"
    },
    {
        "id": "1944",
        "text": "HELLP syndrome"
    },
    {
        "id": "1945",
        "text": "Eclampsia"
    },
    {
        "id": "1946",
        "text": "Antepartum haemorrhage"
    },
    {
        "id": "1947",
        "text": "Abortion"
    },
    {
        "id": "1948",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1949",
        "text": "Pregnancy dermatoses"
    },
    {
        "id": "194A",
        "text": "Pregnancy, childbirth or the puerperium"
    },
    {
        "id": "194B",
        "text": "Haematoma of obstetric wound"
    },
    {
        "id": "194C",
        "text": "Failed attempted abortion"
    },
    {
        "id": "194D",
        "text": "Postpartum haemorrhage"
    },
    {
        "id": "194E",
        "text": "Intrapartum haemorrhage"
    },
    {
        "id": "194F",
        "text": "Respiratory failure"
    },
    {
        "id": "194G",
        "text": "Neonatal hyperbilirubinaemia"
    },
    {
        "id": "194H",
        "text": "Congenital pneumonia"
    },
    {
        "id": "194I",
        "text": "Complications of intrauterine procedures, not elsewhere classified"
    },
    {
        "id": "194J",
        "text": "Fetal death, cause not specified"
    },
    {
        "id": "194K",
        "text": "Digestive system disorders of fetus or newborn"
    },
    {
        "id": "194L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "194M",
        "text": "Mandibular bone fracture due to birth injury"
    },
    {
        "id": "194N",
        "text": "Birth injury to cranial nerves"
    },
    {
        "id": "194O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "194P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "194Q",
        "text": "Disorders of newborn related to length of gestation or fetal growth"
    },
    {
        "id": "194R",
        "text": "Neonatal hyperglycaemia"
    },
    {
        "id": "194S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "194T",
        "text": "Late acquired pneumonia"
    },
    {
        "id": "194U",
        "text": "Inflammatory dermatoses of the newborn"
    },
    {
        "id": "194V",
        "text": "Abnormal findings on neonatal screening"
    },
    {
        "id": "194W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "194X",
        "text": "Disorders of newborn related to short gestation or low birth weight, not elsewhere classified"
    },
    {
        "id": "194Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "194Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1950",
        "text": "Respiratory disorders specific to the perinatal or neonatal period"
    },
    {
        "id": "1951",
        "text": "Meconium staining"
    },
    {
        "id": "1952",
        "text": "Neonatal malabsorption syndromes"
    },
    {
        "id": "1953",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1954",
        "text": "Postmaturity syndrome"
    },
    {
        "id": "1955",
        "text": "Neonatal cardiac failure"
    },
    {
        "id": "1956",
        "text": "Diffuse bleeding diathesis due to vitamin K deficient haemorrhagic disease of fetus or newborn"
    },
    {
        "id": "1957",
        "text": "Neonatal hypoglycaemia due to external factors"
    },
    {
        "id": "1958",
        "text": "Neonatal encephalopathy, moderate"
    },
    {
        "id": "1959",
        "text": "Exceptionally large newborn"
    },
    {
        "id": "195A",
        "text": "Disorders of newborn related to long gestation or high birth weight"
    },
    {
        "id": "195B",
        "text": "Extreme prematurity of newborn, gestational age less than 22 completed weeks"
    },
    {
        "id": "195C",
        "text": "Congenital lung or lobar atelectasis"
    },
    {
        "id": "195D",
        "text": "Birth injury to facial nerve"
    },
    {
        "id": "195E",
        "text": "Certain conditions originating in the perinatal period"
    },
    {
        "id": "195F",
        "text": "Nasal bone fracture due to birth injury"
    },
    {
        "id": "195G",
        "text": "Pre-existing type 2 diabetes mellitus in pregnancy"
    },
    {
        "id": "195H",
        "text": "Artefactual cheilitis"
    },
    {
        "id": "195I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "195J",
        "text": "Stress ulcer of duodenum"
    },
    {
        "id": "195K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "195L",
        "text": "Pneumonitis"
    },
    {
        "id": "195M",
        "text": "Necrotising enterocolitis of newborn"
    },
    {
        "id": "195N",
        "text": "Duration of pregnancy"
    },
    {
        "id": "195O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "195P",
        "text": "Lobular capillary haemangioma"
    },
    {
        "id": "195Q",
        "text": "Benign proliferations or neoplasms of cutaneous blood vessels"
    },
    {
        "id": "195R",
        "text": "Maternal care for other conditions predominantly related to pregnancy"
    },
    {
        "id": "195S",
        "text": "Bronchitis"
    },
    {
        "id": "195T",
        "text": "Aspirin-induced asthma"
    },
    {
        "id": "195U",
        "text": "Inflammatory dermatoses affecting the penis or scrotum"
    },
    {
        "id": "195V",
        "text": "Duchenne muscular dystrophy"
    },
    {
        "id": "195W",
        "text": "Becker muscular dystrophy"
    },
    {
        "id": "195X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "195Y",
        "text": "Mitochondrial myopathies"
    },
    {
        "id": "1964",
        "text": "Myelitis due to systemic lupus erythematosus"
    },
    {
        "id": "1963",
        "text": "Myelitis due to sarcoidosis"
    },
    {
        "id": "1962",
        "text": "Myelitis due to multiple sclerosis"
    },
    {
        "id": "1961",
        "text": "Myelitis due to anti-phospholipid syndrome"
    },
    {
        "id": "1960",
        "text": "Myelitis due to Sj\u00f6gren syndrome"
    },
    {
        "id": "195Z",
        "text": "Myelitis due to Behcet\u2019s disease"
    },
    {
        "id": "1968",
        "text": "Cauda equina compression due to spinal injury"
    },
    {
        "id": "1969",
        "text": "Cauda equina syndrome due to infection"
    },
    {
        "id": "196A",
        "text": "Cauda equina syndrome due to intervertebral disc herniation"
    },
    {
        "id": "196B",
        "text": "Cauda equina syndrome due to tumour"
    },
    {
        "id": "1966",
        "text": "Cauda equina syndrome"
    },
    {
        "id": "196D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "196N",
        "text": "Myelopathy due to metabolic disorders"
    },
    {
        "id": "196O",
        "text": "Myelopathy due to konzo"
    },
    {
        "id": "196P",
        "text": "Myelopathy due to lathyrism"
    },
    {
        "id": "196Q",
        "text": "Myelopathy due to liver disease"
    },
    {
        "id": "196R",
        "text": "Myelopathy due to renal disease"
    },
    {
        "id": "196S",
        "text": "Acute arterial infarction of the spinal cord"
    },
    {
        "id": "196T",
        "text": "Non-compressive vascular myelopathies"
    },
    {
        "id": "196U",
        "text": "Acute venous infarction of the spinal cord"
    },
    {
        "id": "196V",
        "text": "Chronic venous infarction of the spinal cord"
    },
    {
        "id": "196W",
        "text": "Non-degenerative disorders of the spinal column causing spinal cord compression"
    },
    {
        "id": "196X",
        "text": "Abnormalities of vascular origin causing spinal cord compression"
    },
    {
        "id": "196Y",
        "text": "Haemorrhage causing spinal cord compression"
    },
    {
        "id": "196Z",
        "text": "Vascular malformations causing spinal cord compression"
    },
    {
        "id": "1970",
        "text": "Paraneoplastic disorders of the spinal cord"
    },
    {
        "id": "1971",
        "text": "Post infectious autoimmune myelitis"
    },
    {
        "id": "1972",
        "text": "Post vaccination myelitis"
    },
    {
        "id": "1973",
        "text": "Certain specified injuries of wrist or hand"
    },
    {
        "id": "1974",
        "text": "Certain specified injuries of ankle or foot"
    },
    {
        "id": "1975",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1976",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1977",
        "text": "Malignant neoplasm metastasis in retroperitoneum or peritoneum"
    },
    {
        "id": "1978",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "197A",
        "text": "Dyskinesia of sphincter of Oddi"
    },
    {
        "id": "197B",
        "text": "Protothecosis"
    },
    {
        "id": "197C",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "197D",
        "text": "Sporadic Juvenile-onset primary dystonia"
    },
    {
        "id": "197E",
        "text": "Multiple system atrophy"
    },
    {
        "id": "197F",
        "text": "Talaromycosis"
    },
    {
        "id": "197H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "197I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "197K",
        "text": "Calculus of gallbladder or cystic duct with other cholecystitis"
    },
    {
        "id": "197J",
        "text": "Cholelithiasis"
    },
    {
        "id": "197G",
        "text": "Talaromycosis"
    },
    {
        "id": "1762",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "197M",
        "text": "Developmental learning disorder with impairment in reading"
    },
    {
        "id": "197N",
        "text": "Developmental learning disorder with impairment in mathematics"
    },
    {
        "id": "197O",
        "text": "Aura"
    },
    {
        "id": "171N",
        "text": "Personality difficulty"
    },
    {
        "id": "197P",
        "text": "Personality difficulty"
    },
    {
        "id": "197Q",
        "text": "Personality disorder"
    },
    {
        "id": "169S",
        "text": "Acute stress reaction"
    },
    {
        "id": "1571",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "165T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "197R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "160L",
        "text": "Secondary psychotic syndrome"
    },
    {
        "id": "160Q",
        "text": "Secondary psychotic syndrome"
    },
    {
        "id": "160R",
        "text": "Secondary mood syndrome"
    },
    {
        "id": "160S",
        "text": "Secondary mood syndrome"
    },
    {
        "id": "160W",
        "text": "Secondary anxiety syndrome"
    },
    {
        "id": "197S",
        "text": "Secondary obsessive-compulsive or related syndrome"
    },
    {
        "id": "197T",
        "text": "Secondary dissociative syndrome"
    },
    {
        "id": "197U",
        "text": "Secondary impulse control syndrome"
    },
    {
        "id": "197V",
        "text": "Secondary personality change"
    },
    {
        "id": "197W",
        "text": "Secondary neurocognitive syndrome"
    },
    {
        "id": "197X",
        "text": "Gambling disorder"
    },
    {
        "id": "197Z",
        "text": "Pyromania"
    },
    {
        "id": "1980",
        "text": "Nocturnal enuresis"
    },
    {
        "id": "1981",
        "text": "Diurnal enuresis"
    },
    {
        "id": "1982",
        "text": "Nocturnal and diurnal enuresis"
    },
    {
        "id": "1984",
        "text": "Congenital anomaly of the hepatic vein"
    },
    {
        "id": "1985",
        "text": "Interatrial communication through coronary sinus orifice"
    },
    {
        "id": "1986",
        "text": "Systemic-to-pulmonary collateral arteries"
    },
    {
        "id": "1987",
        "text": "Systemic-to-pulmonary collateral arteries"
    },
    {
        "id": "1988",
        "text": "Congenital pericardial anomaly"
    },
    {
        "id": "1989",
        "text": "Pulmonary arteriovenous fistula"
    },
    {
        "id": "198A",
        "text": "Aortic root posterior and rightward to pulmonary root"
    },
    {
        "id": "198B",
        "text": "Abnormal intrapericardial course of great arteries"
    },
    {
        "id": "198C",
        "text": "Spiralling course of great arteries"
    },
    {
        "id": "198E",
        "text": "Concordant atrioventricular connections"
    },
    {
        "id": "198F",
        "text": "Concordant ventriculo-arterial connections"
    },
    {
        "id": "198G",
        "text": "Unroofed coronary sinus"
    },
    {
        "id": "198H",
        "text": "Unroofed coronary sinus"
    },
    {
        "id": "198I",
        "text": "Sinus venosus defect"
    },
    {
        "id": "198J",
        "text": "Divided left atrium"
    },
    {
        "id": "198K",
        "text": "Ebstein malformation of tricuspid valve"
    },
    {
        "id": "198L",
        "text": "Supravalvar or intravalvar mitral ring"
    },
    {
        "id": "157K",
        "text": "Congenital anomaly of an atrioventricular valve or atrioventricular septum"
    },
    {
        "id": "198O",
        "text": "Atrioventricular septal defect with communication at atrial level and restrictive communication at ventricular level"
    },
    {
        "id": "198P",
        "text": "Atrioventricular septal defect with communication at atrial level and unrestrictive communication at ventricular level"
    },
    {
        "id": "198N",
        "text": "Common atrioventricular valve with unbalanced commitment of valve to ventricles"
    },
    {
        "id": "198M",
        "text": "Atrioventricular septal defect with ventricular imbalance"
    },
    {
        "id": "198Q",
        "text": "Common atrioventricular junction without an atrioventricular septal defect"
    },
    {
        "id": "198R",
        "text": "Communication between left ventricle and right atrium"
    },
    {
        "id": "198S",
        "text": "Right ventricular hypoplasia"
    },
    {
        "id": "198T",
        "text": "Double chambered right ventricle"
    },
    {
        "id": "198V",
        "text": "Parchment right ventricle"
    },
    {
        "id": "198W",
        "text": "Tetralogy of Fallot with absent pulmonary valve syndrome"
    },
    {
        "id": "198X",
        "text": "Tetralogy of Fallot with pulmonary atresia"
    },
    {
        "id": "198Y",
        "text": "Tetralogy of Fallot with pulmonary atresia and systemic-to-pulmonary collateral artery"
    },
    {
        "id": "198Z",
        "text": "Left ventricular hypoplasia"
    },
    {
        "id": "1990",
        "text": "Congenital subaortic stenosis"
    },
    {
        "id": "1991",
        "text": "Left heart obstruction at multiple sites"
    },
    {
        "id": "1993",
        "text": "Congenital coronary arterial fistula to right ventricle"
    },
    {
        "id": "159D",
        "text": "Congenital anomaly of ventricular septum"
    },
    {
        "id": "1994",
        "text": "Congenital anomaly of ventricular septum"
    },
    {
        "id": "159F",
        "text": "Inlet ventricular septal defect without a common atrioventricular junction"
    },
    {
        "id": "159E",
        "text": "Perimembranous central ventricular septal defect"
    },
    {
        "id": "1995",
        "text": "Inlet ventricular septal defect without a common atrioventricular junction"
    },
    {
        "id": "159G",
        "text": "Trabecular muscular ventricular septal defect"
    },
    {
        "id": "159U",
        "text": "Trabecular muscular ventricular septal defect"
    },
    {
        "id": "159W",
        "text": "Trabecular muscular ventricular septal defect"
    },
    {
        "id": "159K",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "1996",
        "text": "Functionally univentricular heart"
    },
    {
        "id": "1997",
        "text": "Tricuspid atresia"
    },
    {
        "id": "1998",
        "text": "Mitral atresia"
    },
    {
        "id": "1999",
        "text": "Congenital pulmonary valvar stenosis"
    },
    {
        "id": "199A",
        "text": "Congenital pulmonary regurgitation"
    },
    {
        "id": "161X",
        "text": "Congenital anomaly of pulmonary valve"
    },
    {
        "id": "199B",
        "text": "Pulmonary atresia with intact ventricular septum"
    },
    {
        "id": "199C",
        "text": "Congenital aortic valvar stenosis"
    },
    {
        "id": "199D",
        "text": "Congenital aortic regurgitation"
    },
    {
        "id": "199E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "199F",
        "text": "Congenital supravalvar aortic stenosis"
    },
    {
        "id": "199G",
        "text": "Aneurysm of aortic sinus of Valsalva"
    },
    {
        "id": "199H",
        "text": "Aortoventricular tunnel"
    },
    {
        "id": "199I",
        "text": "Congenital anomaly of great arteries including arterial duct"
    },
    {
        "id": "199J",
        "text": "Congenital aortopulmonary window"
    },
    {
        "id": "199K",
        "text": "Congenital peripheral pulmonary arterial stenosis or hypoplasia at or beyond hilar bifurcation"
    },
    {
        "id": "199L",
        "text": "Congenitally discontinuous, non-confluent right and left pulmonary arteries"
    },
    {
        "id": "199M",
        "text": "Pulmonary artery origin from ascending aorta"
    },
    {
        "id": "199N",
        "text": "Pulmonary artery from arterial duct"
    },
    {
        "id": "199O",
        "text": "Hypoplasia of aortic arch"
    },
    {
        "id": "199P",
        "text": "Coarctation of aorta"
    },
    {
        "id": "199Q",
        "text": "Innominate artery compression syndrome"
    },
    {
        "id": "199R",
        "text": "Vascular ring"
    },
    {
        "id": "199T",
        "text": "Vascular ring of left aortic arch and right arterial duct or ligament"
    },
    {
        "id": "199S",
        "text": "Vascular ring of right aortic arch and left arterial duct or ligament"
    },
    {
        "id": "199U",
        "text": "Congenital arterial duct anomaly"
    },
    {
        "id": "199V",
        "text": "Patent arterial duct"
    },
    {
        "id": "199W",
        "text": "Anomalous origin of coronary artery from pulmonary arterial tree"
    },
    {
        "id": "199X",
        "text": "Anomalous aortic origin of coronary artery with ventriculo-arterial concordance"
    },
    {
        "id": "199Z",
        "text": "Congenital coronary arterial fistula"
    },
    {
        "id": "1A00",
        "text": "Congenital coronary arterial aneurysm"
    },
    {
        "id": "1A01",
        "text": "Certain disorders affecting the nails or perionychium"
    },
    {
        "id": "1A02",
        "text": "Digital myxoid pseudocyst"
    },
    {
        "id": "1669",
        "text": "Developmental language disorder"
    },
    {
        "id": "1662",
        "text": "Developmental speech sound disorder"
    },
    {
        "id": "1A03",
        "text": "Developmental language disorder"
    },
    {
        "id": "1667",
        "text": "Developmental language disorder with impairment of mainly expressive language"
    },
    {
        "id": "1666",
        "text": "Developmental language disorder with impairment of receptive and expressive language"
    },
    {
        "id": "1668",
        "text": "Developmental language disorder with impairment of mainly pragmatic language"
    },
    {
        "id": "1A04",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "184A",
        "text": "Secondary mood syndrome, with manic symptoms"
    },
    {
        "id": "184B",
        "text": "Secondary mood syndrome, with depressive symptoms"
    },
    {
        "id": "184C",
        "text": "Secondary mood syndrome, with mixed symptoms"
    },
    {
        "id": "160N",
        "text": "Secondary psychotic syndrome, with hallucinations"
    },
    {
        "id": "160O",
        "text": "Secondary psychotic syndrome, with delusions"
    },
    {
        "id": "160P",
        "text": "Secondary psychotic syndrome, with hallucinations and delusions"
    },
    {
        "id": "1A05",
        "text": "Conditions with disorders of intellectual development as a relevant clinical feature"
    },
    {
        "id": "1A06",
        "text": "Developmental learning disorder"
    },
    {
        "id": "1A07",
        "text": "Neoplasms of the respiratory system"
    },
    {
        "id": "1A08",
        "text": "Neoplasms"
    },
    {
        "id": "1A09",
        "text": "Reasons for contact with the health services"
    },
    {
        "id": "1A0C",
        "text": "General examination or investigation of persons without complaint or reported diagnosis"
    },
    {
        "id": "1A0B",
        "text": "Dysesthesia"
    },
    {
        "id": "1A0E",
        "text": "Cholangitis"
    },
    {
        "id": "1A0F",
        "text": "Adenomas, benign"
    },
    {
        "id": "1A0H",
        "text": "Diverticulitis of large intestine"
    },
    {
        "id": "1A0I",
        "text": "Oral-facial-digital syndrome type 1"
    },
    {
        "id": "1A0K",
        "text": "Phakomatoses or hamartoneoplastic syndromes"
    },
    {
        "id": "1A0M",
        "text": "Allergic or hypersensitivity conditions"
    },
    {
        "id": "1A0O",
        "text": "Meconium ileus in classical cystic fibrosis"
    },
    {
        "id": "1A0P",
        "text": "Meconium ileus in cystic fibrosis, with perforation"
    },
    {
        "id": "1A0Q",
        "text": "Meconium ileus in classical cystic fibrosis"
    },
    {
        "id": "1A0R",
        "text": "Meconium ileus in cystic fibrosis, with perforation"
    },
    {
        "id": "1A0S",
        "text": "Meconium ileus with perforation"
    },
    {
        "id": "1A0T",
        "text": "Meconium ileus without perforation"
    },
    {
        "id": "1A0U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A0V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A0W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A0X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A0Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A0Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A10",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A11",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A12",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A13",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A14",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A15",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A16",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A17",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A18",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A19",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A1A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A1B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A1C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A1G",
        "text": "Necrotising enterocolitis of newborn"
    },
    {
        "id": "1A1F",
        "text": "Necrotising enterocolitis of newborn"
    },
    {
        "id": "1A1E",
        "text": "Necrotising enterocolitis of newborn"
    },
    {
        "id": "1A1D",
        "text": "Necrotising enterocolitis of newborn"
    },
    {
        "id": "1A1H",
        "text": "Congenital intestinal disease due to an enzymatic defect"
    },
    {
        "id": "1A1I",
        "text": "Amyloidosis"
    },
    {
        "id": "1A1K",
        "text": "Burns"
    },
    {
        "id": "1A1M",
        "text": "Hypersensitivity reactions of unspecified nature"
    },
    {
        "id": "1A1N",
        "text": "Allergy or allergic reactions of unspecified nature"
    },
    {
        "id": "1A1O",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A1P",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A1Q",
        "text": "Allergic rhinitis due to pollen"
    },
    {
        "id": "1A1R",
        "text": "Allergic rhinitis due to other seasonal allergens"
    },
    {
        "id": "1A1U",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A1V",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A1W",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A1X",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A1Y",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A1Z",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A20",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A21",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A22",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A23",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A24",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1A26",
        "text": "Congenital intestinal transport defect"
    },
    {
        "id": "1A28",
        "text": "Postnatal intestinal perforation"
    },
    {
        "id": "1A29",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A2A",
        "text": "Fetus or newborn affected by maternal factors or by complications of pregnancy, labour or delivery"
    },
    {
        "id": "1A2B",
        "text": "Fetus or newborn affected by maternal complications of pregnancy"
    },
    {
        "id": "1A2C",
        "text": "Fetus or newborn affected by placenta praevia"
    },
    {
        "id": "1A2D",
        "text": "Fetus or newborn affected by placental oedema or large placenta"
    },
    {
        "id": "1A2E",
        "text": "Fetus or newborn affected by placental dysfunction, not otherwise specified"
    },
    {
        "id": "1A2F",
        "text": "Fetus or newborn affected by placental infarction"
    },
    {
        "id": "1A2G",
        "text": "Fetus or newborn affected by placental insufficiency or small placenta"
    },
    {
        "id": "1A2H",
        "text": "Fetus or newborn affected by placental transfusion syndromes"
    },
    {
        "id": "1A2I",
        "text": "Fetus or newborn affected by other or unspecified morphological or functional abnormalities of placenta"
    },
    {
        "id": "1A2J",
        "text": "Fetus or newborn affected by placental infarction"
    },
    {
        "id": "1A2K",
        "text": "Fetus or newborn affected by vasa praevia"
    },
    {
        "id": "1A2M",
        "text": "Fetus or newborn affected by complications of umbilical cord"
    },
    {
        "id": "1A2N",
        "text": "Meconium passage during delivery"
    },
    {
        "id": "1A2O",
        "text": "Small for gestational age"
    },
    {
        "id": "1A2P",
        "text": "Disorders of newborn related to short gestation or low birth weight, not elsewhere classified"
    },
    {
        "id": "1A2Q",
        "text": "Extremely low birth weight of newborn"
    },
    {
        "id": "1A2R",
        "text": "Extremely low birth weight of newborn, 499g or less"
    },
    {
        "id": "1A2S",
        "text": "Extremely low birth weight of newborn, 500-749g"
    },
    {
        "id": "1A2T",
        "text": "Very low birth weight of newborn"
    },
    {
        "id": "1A2U",
        "text": "Very low birth weight of newborn, 1000-1249g"
    },
    {
        "id": "1A2V",
        "text": "Very low birth weight of newborn, 1250-1499g"
    },
    {
        "id": "1A2W",
        "text": "Low birth weight of newborn"
    },
    {
        "id": "1A2X",
        "text": "Low birth weight of newborn"
    },
    {
        "id": "1A2Y",
        "text": "Low birth weight of newborn"
    },
    {
        "id": "1A2Z",
        "text": "Extreme prematurity of newborn"
    },
    {
        "id": "1A30",
        "text": "Extreme prematurity of newborn, gestational age less than 22 completed weeks"
    },
    {
        "id": "1A31",
        "text": "Extreme prematurity of newborn, gestational age 23 completed weeks"
    },
    {
        "id": "1A32",
        "text": "Extreme prematurity of newborn, gestational age 24 completed weeks"
    },
    {
        "id": "1A33",
        "text": "Extreme prematurity of newborn, gestational age 25 completed weeks"
    },
    {
        "id": "1A34",
        "text": "Extreme prematurity of newborn, gestational age 26 completed weeks"
    },
    {
        "id": "1A35",
        "text": "Extreme prematurity of newborn, gestational age 27 completed weeks"
    },
    {
        "id": "1A36",
        "text": "Exceptionally large newborn"
    },
    {
        "id": "1A37",
        "text": "Large newborn for gestational age"
    },
    {
        "id": "1A38",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A39",
        "text": "Postmaturity syndrome"
    },
    {
        "id": "1A3A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A3B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A3C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A3D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A3E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A3F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A3G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A25",
        "text": "Chronic rhinosinusitis"
    },
    {
        "id": "1A3I",
        "text": "Aspergillus-induced allergic or hypersensitivity conditions"
    },
    {
        "id": "1A3J",
        "text": "Chronic rhinosinusitis"
    },
    {
        "id": "1A3K",
        "text": "Hypersensitivity pneumonitis due to organic dust"
    },
    {
        "id": "1A3L",
        "text": "Symptoms, signs or clinical findings of blood, blood-forming organs, or the immune system"
    },
    {
        "id": "1A3M",
        "text": "Idiopathic Type 1 diabetes mellitus"
    },
    {
        "id": "1A3O",
        "text": "Acute periodontitis"
    },
    {
        "id": "1A3Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A3R",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A3S",
        "text": "Anaphylaxis due to allergic reaction to food"
    },
    {
        "id": "1A3U",
        "text": "Food-dependent exercise-induced anaphylaxis"
    },
    {
        "id": "1A3V",
        "text": "Food-dependent exercise-induced anaphylaxis"
    },
    {
        "id": "1A3W",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A3X",
        "text": "Drug-induced anaphylaxis"
    },
    {
        "id": "1A3Y",
        "text": "Drug-induced anaphylaxis"
    },
    {
        "id": "1A3Z",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A40",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A41",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A42",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A43",
        "text": "Exercise-induced anaphylaxis"
    },
    {
        "id": "1A44",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A45",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A46",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A47",
        "text": "Allergic or hypersensitivity conditions"
    },
    {
        "id": "1A48",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A49",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1A4A",
        "text": "Allergic or hypersensitivity disorders involving the eye"
    },
    {
        "id": "1A4B",
        "text": "Allergic or hypersensitivity disorders involving skin or mucous membranes"
    },
    {
        "id": "1A4C",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A4E",
        "text": "Allergic or hypersensitivity conditions"
    },
    {
        "id": "1A4F",
        "text": "Allergic gastritis"
    },
    {
        "id": "1A4G",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A4H",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A4J",
        "text": "Periodontal disease"
    },
    {
        "id": "1A4D",
        "text": "Allergic or hypersensitivity reactions to arthropods"
    },
    {
        "id": "1A4M",
        "text": "Allergic or hypersensitivity reactions to arthropods"
    },
    {
        "id": "1A4N",
        "text": "Cholecystitis"
    },
    {
        "id": "1A4O",
        "text": "Neonatal malabsorption syndromes"
    },
    {
        "id": "1A4P",
        "text": "Neonatal malabsorption due to endocrine secreting tumour"
    },
    {
        "id": "1A4Q",
        "text": "Fetus or newborn affected by other forms of placental separation"
    },
    {
        "id": "1A4R",
        "text": "Fetus or newborn affected by complications of placenta"
    },
    {
        "id": "1A4S",
        "text": "Fetus or newborn affected by maternal exposure to environmental chemical substances"
    },
    {
        "id": "1A4T",
        "text": "Birth injury to brainstem"
    },
    {
        "id": "1A4U",
        "text": "Cerebral oedema due to birth injury"
    },
    {
        "id": "1A4V",
        "text": "Birth injury to facial nerve"
    },
    {
        "id": "1A0J",
        "text": "Benign tumours of cutaneous smooth muscle"
    },
    {
        "id": "1A4X",
        "text": "Social anxiety disorder"
    },
    {
        "id": "1A4Y",
        "text": "Brachial plexus palsy in newborn"
    },
    {
        "id": "1A4Z",
        "text": "Anaphylaxis due to allergic reaction to food"
    },
    {
        "id": "1A50",
        "text": "Iatrogenic injuries involving the skin of the neonate"
    },
    {
        "id": "1A52",
        "text": "Nasal bone fracture due to birth injury"
    },
    {
        "id": "1A53",
        "text": "Birth injury to skeleton"
    },
    {
        "id": "1A54",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A55",
        "text": "Birth injury to long bones"
    },
    {
        "id": "1A56",
        "text": "Birth injury to skin or soft tissues"
    },
    {
        "id": "1A51",
        "text": "Allergic or hypersensitivity disorders involving the eye"
    },
    {
        "id": "1A57",
        "text": "Allergic or hypersensitivity disorders involving the eye"
    },
    {
        "id": "1A58",
        "text": "Acute atopic conjunctivitis"
    },
    {
        "id": "1A59",
        "text": "Allergic or hypersensitivity disorders involving the eye"
    },
    {
        "id": "1A5A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A5B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A5C",
        "text": "Allergic or hypersensitivity disorders involving the eye"
    },
    {
        "id": "1A5D",
        "text": "Allergic or hypersensitivity reactions to arthropods"
    },
    {
        "id": "1A5F",
        "text": "Cutaneous reactions to arthropods"
    },
    {
        "id": "1A5E",
        "text": "Allergic or hypersensitivity reactions to arthropods"
    },
    {
        "id": "1A5G",
        "text": "Atopic eczema"
    },
    {
        "id": "1A5I",
        "text": "Spontaneous urticaria"
    },
    {
        "id": "1A5M",
        "text": "Metabolic acidaemia in newborn first noted before onset of labour"
    },
    {
        "id": "1A5N",
        "text": "Metabolic acidaemia in newborn first noted during labour"
    },
    {
        "id": "1A5O",
        "text": "Metabolic acidaemia in newborn at birth"
    },
    {
        "id": "1A5K",
        "text": "Metabolic acidaemia in newborn"
    },
    {
        "id": "1A5P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A5Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A5R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A5S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A5T",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1A5U",
        "text": "Massive pulmonary haemorrhage originating in the perinatal period"
    },
    {
        "id": "1A5W",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1A5X",
        "text": "Aspirin-induced asthma"
    },
    {
        "id": "1A5Z",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1A60",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1A5Y",
        "text": "Exercise-induced bronchospasm"
    },
    {
        "id": "1A61",
        "text": "Allergy or allergic reactions of unspecified nature"
    },
    {
        "id": "1A62",
        "text": "Chronic kidney disease"
    },
    {
        "id": "1A5L",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1A5V",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1A63",
        "text": "Aspirin-induced asthma"
    },
    {
        "id": "1A64",
        "text": "Allergic or hypersensitivity reactions to arthropods"
    },
    {
        "id": "1A65",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A66",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A67",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A68",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A69",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6A",
        "text": "Anaphylaxis due to allergic reaction to food"
    },
    {
        "id": "1A6B",
        "text": "Food-dependent exercise-induced anaphylaxis"
    },
    {
        "id": "1A6C",
        "text": "Oral allergy syndrome"
    },
    {
        "id": "1A6D",
        "text": "Oral allergy syndrome"
    },
    {
        "id": "1A6E",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6H",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6J",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6L",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6K",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6I",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6F",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6N",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6P",
        "text": "Allergic or dietetic colitis"
    },
    {
        "id": "1A6Q",
        "text": "Allergic or dietetic colitis"
    },
    {
        "id": "1A6R",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6U",
        "text": "Allergic contact dermatitis organised by site"
    },
    {
        "id": "1A6V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A6W",
        "text": "Photo-allergic contact dermatitis"
    },
    {
        "id": "1A70",
        "text": "Adverse effects, not elsewhere classified"
    },
    {
        "id": "1A6S",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6M",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A6O",
        "text": "Complex allergic or hypersensitivity conditions"
    },
    {
        "id": "1A72",
        "text": "Allergic or hypersensitivity disorders involving skin or mucous membranes"
    },
    {
        "id": "1A73",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A5J",
        "text": "Tracheal haemorrhage of newborn due to airway trauma"
    },
    {
        "id": "1A75",
        "text": "Congenital macroglossia"
    },
    {
        "id": "1A76",
        "text": "Congenital micrognathia"
    },
    {
        "id": "1A7D",
        "text": "Anaphylaxis due to allergic reaction to food"
    },
    {
        "id": "1A7C",
        "text": "Adverse effects, not elsewhere classified"
    },
    {
        "id": "1A7E",
        "text": "Giant papillary conjunctivitis"
    },
    {
        "id": "1A7G",
        "text": "Very low birth weight of newborn"
    },
    {
        "id": "1A7H",
        "text": "Intestinal obstruction of newborn"
    },
    {
        "id": "1A7I",
        "text": "Congenital pneumonia"
    },
    {
        "id": "1A7J",
        "text": "Mismatched blood used in transfusion"
    },
    {
        "id": "1A7L",
        "text": "Problem associated with clinical documentation"
    },
    {
        "id": "1A7K",
        "text": "Mode of injury or harm associated with other health care related causes"
    },
    {
        "id": "1A7M",
        "text": "Endocrine procedure associated with injury or harm in therapeutic use"
    },
    {
        "id": "1A7N",
        "text": "Embolisation, as mode of injury or harm"
    },
    {
        "id": "1A7O",
        "text": "Causes of healthcare related harm or injury"
    },
    {
        "id": "1A7P",
        "text": "Injury or harm arising following infusion, transfusion or therapeutic injection, not elsewhere classified"
    },
    {
        "id": "1A7Q",
        "text": "Air embolism following infusion, transfusion or therapeutic injection"
    },
    {
        "id": "1A7R",
        "text": "Haemorrhage or haematoma complicating a procedure, not elsewhere classified"
    },
    {
        "id": "1A7S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A7T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A7U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A7V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A7W",
        "text": "Other or unspecified medical devices associated with injury or harm, surgical instruments, materials or devices"
    },
    {
        "id": "1A7X",
        "text": "Other or unspecified medical devices associated with injury or harm, miscellaneous devices, not elsewhere classified"
    },
    {
        "id": "1A7Y",
        "text": "Mode of injury or harm associated with other health care related causes"
    },
    {
        "id": "1A7Z",
        "text": "Pulmonary haemorrhage originating in the perinatal period"
    },
    {
        "id": "1A80",
        "text": "Certain medical procedures associated with injury or harm in therapeutic use"
    },
    {
        "id": "1A81",
        "text": "Pulmonary haemorrhage originating in the perinatal period"
    },
    {
        "id": "1A82",
        "text": "Pulmonary haemorrhage originating in the perinatal period"
    },
    {
        "id": "1A83",
        "text": "General symptoms"
    },
    {
        "id": "1A85",
        "text": "Mode of injury or harm associated with a surgical or other medical device, implant or graft"
    },
    {
        "id": "1A86",
        "text": "Late acquired pneumonia"
    },
    {
        "id": "1A87",
        "text": "Mode of injury or harm associated with a surgical or other medical device, implant or graft"
    },
    {
        "id": "1A88",
        "text": "Structural device failure, as mode of injury or harm"
    },
    {
        "id": "1A89",
        "text": "Mode of injury or harm associated with a surgical or other medical procedure"
    },
    {
        "id": "1A8A",
        "text": "Mode of injury or harm associated with a surgical or other medical procedure"
    },
    {
        "id": "1A8B",
        "text": "Mode of injury or harm associated with other health care related causes"
    },
    {
        "id": "1A8C",
        "text": "Mode of injury or harm associated with other health care related causes"
    },
    {
        "id": "1A8D",
        "text": "Mode of injury or harm associated with other health care related causes"
    },
    {
        "id": "1A8E",
        "text": "Certain medical procedures associated with injury or harm in therapeutic use"
    },
    {
        "id": "1A8F",
        "text": "Mode of injury or harm associated with exposure to a drug, medicament or biological substance"
    },
    {
        "id": "1A8G",
        "text": "Diffuse bleeding diathesis due to vitamin K deficient haemorrhagic disease of fetus or newborn"
    },
    {
        "id": "1A8H",
        "text": "Diffuse bleeding diathesis due to vitamin K deficient haemorrhagic disease of fetus or newborn, early"
    },
    {
        "id": "1A8I",
        "text": "Diffuse bleeding diathesis due to vitamin K deficient haemorrhagic disease of fetus or newborn, classic"
    },
    {
        "id": "1A8J",
        "text": "Diffuse bleeding diathesis due to vitamin K deficient haemorrhagic disease of fetus or newborn, late"
    },
    {
        "id": "1A6T",
        "text": "Allergic contact dermatitis"
    },
    {
        "id": "1A8K",
        "text": "Neonatal bleeding originating in the oesophagus, stomach, small or large intestine"
    },
    {
        "id": "1A8L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A8M",
        "text": "Neonatal hyperbilirubinemia due to infectious processes, viral"
    },
    {
        "id": "1A8N",
        "text": "Neonatal hyperbilirubinemia due to infectious processes, bacterial"
    },
    {
        "id": "1A8O",
        "text": "Neonatal hyperbilirubinaemia due to red cell haemolysis with infection"
    },
    {
        "id": "1A8P",
        "text": "Neonatal hyperbilirubinaemia due to skin bruising"
    },
    {
        "id": "1A8Q",
        "text": "Neonatal hyperbilirubinaemia due to internal bleeding"
    },
    {
        "id": "1A8R",
        "text": "Neonatal hyperbilirubinaemia due to polycythaemia"
    },
    {
        "id": "1A8S",
        "text": "Neonatal hyperbilirubinaemia due to infectious processes"
    },
    {
        "id": "1A8T",
        "text": "Neonatal hyperbilirubinaemia due to prematurity"
    },
    {
        "id": "1A8U",
        "text": "Neonatal hyperbilirubinaemia following hepatocellular damage"
    },
    {
        "id": "1A8V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A8W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A8Y",
        "text": "Urticaria, angioedema and other urticarial disorders"
    },
    {
        "id": "1A8Z",
        "text": "Transient neonatal thrombocytopaenia"
    },
    {
        "id": "1A90",
        "text": "Drug eruptions"
    },
    {
        "id": "1A91",
        "text": "Drug eruptions"
    },
    {
        "id": "1A96",
        "text": "Drug-induced angioedema"
    },
    {
        "id": "1A98",
        "text": "Lymphoedema due to chronic inflammation"
    },
    {
        "id": "1A99",
        "text": "Lymphoedematous rosacea"
    },
    {
        "id": "1A97",
        "text": "Drug-induced Stevens-Johnson syndrome"
    },
    {
        "id": "1A9F",
        "text": "Sleep-wake disorders"
    },
    {
        "id": "1A9G",
        "text": "Certain disorders affecting the nails or perionychium"
    },
    {
        "id": "1A9H",
        "text": "Erythema nodosum"
    },
    {
        "id": "1A9J",
        "text": "Neonatal hypoglycaemia due to external factors"
    },
    {
        "id": "1A9K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A9I",
        "text": "Erythema nodosum"
    },
    {
        "id": "1A9L",
        "text": "Transitory disorders of carbohydrate metabolism specific to fetus or newborn"
    },
    {
        "id": "1A9N",
        "text": "Neonatal hypocalcaemia"
    },
    {
        "id": "1A9M",
        "text": "Erythema nodosum"
    },
    {
        "id": "1A9O",
        "text": "Erythema nodosum"
    },
    {
        "id": "1A9P",
        "text": "Transitory neonatal disorders of thyroid function"
    },
    {
        "id": "1A9Q",
        "text": "Transitory neonatal hypoparathyroidism"
    },
    {
        "id": "1A9S",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1A9T",
        "text": "Diseases of the musculoskeletal system or connective tissue"
    },
    {
        "id": "1A9U",
        "text": "Anaphylaxis"
    },
    {
        "id": "1A0L",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1A9V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A9W",
        "text": "Disorders of muscle tone of newborn"
    },
    {
        "id": "1A9X",
        "text": "Meconium staining"
    },
    {
        "id": "1A9Z",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B00",
        "text": "Drug eruptions"
    },
    {
        "id": "1B01",
        "text": "Drug eruptions"
    },
    {
        "id": "1B02",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B03",
        "text": "Allergic or hypersensitivity disorders involving the eye"
    },
    {
        "id": "1B04",
        "text": "Samter syndrome"
    },
    {
        "id": "1B05",
        "text": "Allergic aspergillus rhinosinusitis"
    },
    {
        "id": "1B06",
        "text": "Aspergillus-induced allergic or hypersensitivity conditions"
    },
    {
        "id": "1B07",
        "text": "Aspergillus-induced allergic or hypersensitivity conditions"
    },
    {
        "id": "1B08",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B0A",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B0B",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B09",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B0C",
        "text": "Drug-induced aplastic anaemia"
    },
    {
        "id": "1B0D",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B0E",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B0F",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B0G",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B0H",
        "text": "Drug-induced rhinitis"
    },
    {
        "id": "1B0I",
        "text": "Allergic conjunctivitis due to drugs or medicaments"
    },
    {
        "id": "1B0J",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B0K",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1B0L",
        "text": "Chronic rhinosinusitis"
    },
    {
        "id": "1B0N",
        "text": "Problems associated with inadequate drinking-water"
    },
    {
        "id": "1B0O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B0R",
        "text": "Eating problem in child"
    },
    {
        "id": "1B0V",
        "text": "Routine child health examination"
    },
    {
        "id": "1B0W",
        "text": "Disorders specifically associated with stress"
    },
    {
        "id": "1B0X",
        "text": "Routine child health examination"
    },
    {
        "id": "1B0Y",
        "text": "General examination or investigation of persons without complaint or reported diagnosis"
    },
    {
        "id": "1B0Z",
        "text": "Examination of eyes or vision"
    },
    {
        "id": "1B13",
        "text": "General examination or investigation of persons without complaint or reported diagnosis"
    },
    {
        "id": "1B14",
        "text": "General examination or investigation of persons without complaint or reported diagnosis"
    },
    {
        "id": "1B16",
        "text": "Issue of medical certificate"
    },
    {
        "id": "1B17",
        "text": "Examination or encounter for administrative purposes"
    },
    {
        "id": "1B18",
        "text": "Photo-allergic contact dermatitis"
    },
    {
        "id": "1B19",
        "text": "Chronic rhinosinusitis"
    },
    {
        "id": "1B1A",
        "text": "Asthma"
    },
    {
        "id": "1B1C",
        "text": "Problems with inappropriate diet or eating habits"
    },
    {
        "id": "1B1B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B1D",
        "text": "Hazardous gambling or betting"
    },
    {
        "id": "1B1G",
        "text": "Tobacco use"
    },
    {
        "id": "1B1I",
        "text": "Food-induced gastrointestinal hypersensitivity"
    },
    {
        "id": "1B1J",
        "text": "Spontaneous urticaria"
    },
    {
        "id": "1B1K",
        "text": "Drug-induced immune-complex arthritis"
    },
    {
        "id": "1B1L",
        "text": "Anaphylaxis due to insect venom"
    },
    {
        "id": "1B1M",
        "text": "Cutaneous allergic or hypersensitivity reactions to Hymenoptera venom"
    },
    {
        "id": "1B1P",
        "text": "Outcome of delivery"
    },
    {
        "id": "1B1Q",
        "text": "Other multiple births, all liveborn"
    },
    {
        "id": "1B1R",
        "text": "Other multiple births, some liveborn"
    },
    {
        "id": "1B1S",
        "text": "Other multiple births, all stillborn"
    },
    {
        "id": "1B1T",
        "text": "Singleton, born in hospital"
    },
    {
        "id": "1B1U",
        "text": "Liveborn infants according to place of birth"
    },
    {
        "id": "1B1V",
        "text": "Adjustment or management of implanted hearing device"
    },
    {
        "id": "1B1W",
        "text": "Adjustment or management of implanted hearing device"
    },
    {
        "id": "1B1X",
        "text": "Adjustment or management of implanted devices"
    },
    {
        "id": "1B1Z",
        "text": "Contact with health services for routine or ritual circumcision"
    },
    {
        "id": "1B20",
        "text": "Attention to surgical dressings, drains or sutures"
    },
    {
        "id": "1B21",
        "text": "Contact with health services for nonsurgical interventions not involving devices"
    },
    {
        "id": "1B22",
        "text": "Contact with health services for counselling"
    },
    {
        "id": "1B23",
        "text": "Immunization not carried out"
    },
    {
        "id": "1B24",
        "text": "Immunization not carried out because of contraindication, not otherwise specified"
    },
    {
        "id": "1B25",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B26",
        "text": "Contact with health services related to immunizations or certain other prophylactic measures"
    },
    {
        "id": "1B27",
        "text": "Problems associated with upbringing"
    },
    {
        "id": "1B28",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B29",
        "text": "Personal history of noncompliance with medical treatment or regimen"
    },
    {
        "id": "1B2A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B2B",
        "text": "Drug-induced immune-complex arthritis"
    },
    {
        "id": "1B2C",
        "text": "Acquired melanotic macules or lentigines"
    },
    {
        "id": "1B2D",
        "text": "Contact with health services for counselling"
    },
    {
        "id": "1B2E",
        "text": "Contact with health services for alcohol use counselling or surveillance"
    },
    {
        "id": "1B2F",
        "text": "Contact with health services for drug use counselling or surveillance"
    },
    {
        "id": "1B2G",
        "text": "Contact with health services for tobacco use counselling"
    },
    {
        "id": "1B2H",
        "text": "Counselling related to sexuality"
    },
    {
        "id": "1B2J",
        "text": "Personal history of psychoactive substance abuse"
    },
    {
        "id": "1B2I",
        "text": "Personal or family history or late effect of prior health problems"
    },
    {
        "id": "1B2K",
        "text": "Personal history of unhealthy sleep-wake schedule"
    },
    {
        "id": "1B2L",
        "text": "Photo-allergic contact dermatitis"
    },
    {
        "id": "1B2M",
        "text": "Large newborn for gestational age"
    },
    {
        "id": "1B2N",
        "text": "Contact with health services for purposes of examination or investigation"
    },
    {
        "id": "1B0T",
        "text": "General adult medical examination"
    },
    {
        "id": "1B2O",
        "text": "Routine newborn health examination"
    },
    {
        "id": "1B2P",
        "text": "Routine child health examination"
    },
    {
        "id": "1B2R",
        "text": "Examination or observation for reasons other than suspected diseases or conditions or administrative purposes"
    },
    {
        "id": "1B2V",
        "text": "Diseases of the immune system"
    },
    {
        "id": "1B2W",
        "text": "Monogenic autoinflammatory syndromes"
    },
    {
        "id": "1B2U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B2X",
        "text": "Viral infection in the fetus or newborn"
    },
    {
        "id": "1B2Y",
        "text": "Parasitic diseases in the fetus or newborn"
    },
    {
        "id": "1B30",
        "text": "Neonatal meningitis"
    },
    {
        "id": "1B31",
        "text": "Neonatal conjunctivitis or dacryocystitis"
    },
    {
        "id": "1B32",
        "text": "Neonatal tracheitis"
    },
    {
        "id": "1B33",
        "text": "Neonatal urinary tract infection"
    },
    {
        "id": "1B34",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B35",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B36",
        "text": "Infections of the fetus or newborn"
    },
    {
        "id": "1B37",
        "text": "Neonatal skin infection"
    },
    {
        "id": "1B38",
        "text": "Neonatal pyogenic skin infections"
    },
    {
        "id": "1B39",
        "text": "Prenatally acquired infections with neonatal skin manifestations"
    },
    {
        "id": "1B3A",
        "text": "Congenital pneumonia"
    },
    {
        "id": "1B3B",
        "text": "Late acquired pneumonia"
    },
    {
        "id": "1B3C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B3D",
        "text": "Fetal death, cause not specified"
    },
    {
        "id": "1B3E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B3F",
        "text": "Infant regurgitation"
    },
    {
        "id": "1B3G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B3H",
        "text": "Congenital lung or lobar atelectasis"
    },
    {
        "id": "1B3J",
        "text": "Personal or family history or late effect of prior health problems"
    },
    {
        "id": "1B3L",
        "text": "Anaphylaxis due to allergic reaction to food"
    },
    {
        "id": "1B3M",
        "text": "Superficial fibromatoses"
    },
    {
        "id": "1B3N",
        "text": "Epithelioid haemangioma"
    },
    {
        "id": "1B3O",
        "text": "Epithelioid haemangioma"
    },
    {
        "id": "1B3Q",
        "text": "Symptoms or signs involving the respiratory system"
    },
    {
        "id": "1B3R",
        "text": "Benign cutaneous melanocytic neoplasms"
    },
    {
        "id": "1B3T",
        "text": "Cavernous haemangioma causing spinal cord compression"
    },
    {
        "id": "1B3U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B3V",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1B3W",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1B3X",
        "text": "Allergic rhinitis due to specified allergens"
    },
    {
        "id": "1B3Y",
        "text": "Allergic rhinitis due to specified allergens"
    },
    {
        "id": "1B3Z",
        "text": "Allergic rhinitis due to other seasonal allergens"
    },
    {
        "id": "1B40",
        "text": "Other allergic rhinitis"
    },
    {
        "id": "1B41",
        "text": "Non-allergic rhinitis"
    },
    {
        "id": "1B43",
        "text": "Hormonally-mediated rhinitis"
    },
    {
        "id": "1B44",
        "text": "Rhinitis related to pregnancy"
    },
    {
        "id": "1B45",
        "text": "Rhinitis related to hypothyroidism"
    },
    {
        "id": "1B46",
        "text": "Rhinitis related to pregnancy"
    },
    {
        "id": "1B47",
        "text": "Rhinitis related to hypothyroidism"
    },
    {
        "id": "1B48",
        "text": "Drug-induced rhinitis"
    },
    {
        "id": "1B49",
        "text": "Reactive upper airways dysfunction syndrome"
    },
    {
        "id": "1B4A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B4B",
        "text": "Hypersensitivity pneumonitis due to organic dust"
    },
    {
        "id": "1B4D",
        "text": "Samter syndrome"
    },
    {
        "id": "1B4E",
        "text": "Allergic asthma"
    },
    {
        "id": "1B4F",
        "text": "Non-allergic asthma"
    },
    {
        "id": "1B4G",
        "text": "Aspirin-induced asthma"
    },
    {
        "id": "1B4H",
        "text": "Allergic or hypersensitivity disorders involving the eye"
    },
    {
        "id": "1B4I",
        "text": "Giant papillary conjunctivitis"
    },
    {
        "id": "1B4J",
        "text": "Allergic or hypersensitivity disorders involving skin or mucous membranes"
    },
    {
        "id": "1B4K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B4L",
        "text": "Allergic contact dermatitis due to food flavours or additives"
    },
    {
        "id": "1B4M",
        "text": "Allergic contact dermatitis due to systemic medicaments"
    },
    {
        "id": "1B4N",
        "text": "Allergic contact blepharoconjunctivitis"
    },
    {
        "id": "1B4Q",
        "text": "Aspirin-induced asthma"
    },
    {
        "id": "1B4R",
        "text": "Exercise-induced bronchospasm"
    },
    {
        "id": "1B4S",
        "text": "Anaphylaxis due to allergic reaction to food"
    },
    {
        "id": "1B4T",
        "text": "Allergic contact sensitisation to systemic medicaments"
    },
    {
        "id": "1B4U",
        "text": "Anaphylaxis due to allergic reaction to food"
    },
    {
        "id": "1B4W",
        "text": "Drug-induced bronchospasm"
    },
    {
        "id": "1B4Y",
        "text": "Drug-induced bronchospasm"
    },
    {
        "id": "1B4X",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1B4V",
        "text": "Cutaneous reactions to arthropods"
    },
    {
        "id": "1B50",
        "text": "Allergic or hypersensitivity conditions"
    },
    {
        "id": "1B51",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1B52",
        "text": "Food-dependent exercise-induced anaphylaxis"
    },
    {
        "id": "1B53",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "135U",
        "text": "Dental caries"
    },
    {
        "id": "1B54",
        "text": "Allergic or hypersensitivity disorders involving skin or mucous membranes"
    },
    {
        "id": "1B55",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B56",
        "text": "Headache or facial or neck pain due to cervical, carotid or vertebral artery dissection"
    },
    {
        "id": "1B57",
        "text": "Contact blepharoconjunctivitis"
    },
    {
        "id": "1B58",
        "text": "Allergic contact blepharoconjunctivitis"
    },
    {
        "id": "1B59",
        "text": "Irritant contact blepharoconjunctivitis"
    },
    {
        "id": "1B5A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B5B",
        "text": "Adult rumination syndrome"
    },
    {
        "id": "184E",
        "text": "Idiopathic interstitial pneumonitis"
    },
    {
        "id": "1B5D",
        "text": "Adverse effects, not elsewhere classified"
    },
    {
        "id": "1B5H",
        "text": "Relapsing polychondritis"
    },
    {
        "id": "1B5I",
        "text": "Allergic gastritis"
    },
    {
        "id": "1B5J",
        "text": "Allergic gastritis"
    },
    {
        "id": "1B5K",
        "text": "Eosinophilic oesophagitis"
    },
    {
        "id": "1B5M",
        "text": "Food-induced eosinophilic oesophagitis"
    },
    {
        "id": "1B5N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B5O",
        "text": "Food-induced eosinophilic gastroenteritis"
    },
    {
        "id": "1B5P",
        "text": "Food-induced eosinophilic gastroenteritis"
    },
    {
        "id": "1B5Q",
        "text": "Allergic or dietetic enteritis of small intestine"
    },
    {
        "id": "1B5R",
        "text": "Eosinophilic enteritis"
    },
    {
        "id": "1B5S",
        "text": "Allergic or dietetic enteritis of small intestine"
    },
    {
        "id": "1B5T",
        "text": "Food protein-induced enterocolitis syndrome"
    },
    {
        "id": "1B5U",
        "text": "Food protein-induced enterocolitis syndrome"
    },
    {
        "id": "1B5V",
        "text": "IgE mediated allergic enteritis of small intestine"
    },
    {
        "id": "1B5W",
        "text": "Allergic or dietetic colitis"
    },
    {
        "id": "1B5X",
        "text": "Allergic or dietetic colitis"
    },
    {
        "id": "1B5Y",
        "text": "Allergic duodenitis"
    },
    {
        "id": "1B60",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B5L",
        "text": "Food-induced eosinophilic oesophagitis"
    },
    {
        "id": "1B64",
        "text": "Food protein-induced enterocolitis syndrome"
    },
    {
        "id": "1B65",
        "text": "Food-induced gastrointestinal hypersensitivity"
    },
    {
        "id": "1B66",
        "text": "Allergy or allergic reactions of unspecified nature"
    },
    {
        "id": "1B67",
        "text": "Hypersensitivity reactions of unspecified nature"
    },
    {
        "id": "1B0S",
        "text": "Reasons for contact with the health services"
    },
    {
        "id": "1B68",
        "text": "Reasons for contact with the health services"
    },
    {
        "id": "1B69",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B6A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B6B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B6C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B6D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B6E",
        "text": "Vascular disorders of the liver"
    },
    {
        "id": "1B6F",
        "text": "Infarction of liver"
    },
    {
        "id": "1B6G",
        "text": "Peliosis hepatis"
    },
    {
        "id": "1B6H",
        "text": "Portal vein thrombosis"
    },
    {
        "id": "1B6I",
        "text": "Splenic vein thrombosis"
    },
    {
        "id": "1B6J",
        "text": "Portal hypertension"
    },
    {
        "id": "1B6K",
        "text": "Passive congestion of liver"
    },
    {
        "id": "1B6L",
        "text": "Hepatic artery aneurysm"
    },
    {
        "id": "1B6M",
        "text": "Hepatic haemorrhage"
    },
    {
        "id": "1B6N",
        "text": "Ischaemia reperfusion injury of liver"
    },
    {
        "id": "1B6O",
        "text": "Congenital supravalvar pulmonary stenosis"
    },
    {
        "id": "1B6P",
        "text": "Left ventricular myocardial sinusoids"
    },
    {
        "id": "1B63",
        "text": "Adverse effects, not elsewhere classified"
    },
    {
        "id": "1B6Q",
        "text": "Allergy or allergic reactions of unspecified nature"
    },
    {
        "id": "1B6R",
        "text": "Hypersensitivity reactions of unspecified nature"
    },
    {
        "id": "1B6S",
        "text": "Adverse effects, not elsewhere classified"
    },
    {
        "id": "1B6T",
        "text": "Diabetes mellitus"
    },
    {
        "id": "1B6V",
        "text": "Insufficient intake of food and water due to self neglect"
    },
    {
        "id": "1B6W",
        "text": "Appendicitis"
    },
    {
        "id": "1B6X",
        "text": "Allergic contact blepharoconjunctivitis"
    },
    {
        "id": "1B6Y",
        "text": "Irritant contact blepharoconjunctivitis"
    },
    {
        "id": "1B6Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B70",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B72",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B71",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B73",
        "text": "Meningitis due to Streptococcus pneumoniae"
    },
    {
        "id": "1B74",
        "text": "Meningitis due to Streptococcus agalactiae"
    },
    {
        "id": "1B75",
        "text": "Effects of heat"
    },
    {
        "id": "1B76",
        "text": "Heat stroke"
    },
    {
        "id": "1B78",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B79",
        "text": "Heat exhaustion due to fluid depletion"
    },
    {
        "id": "1B7A",
        "text": "Heat syncope"
    },
    {
        "id": "1B7C",
        "text": "Heat oedema"
    },
    {
        "id": "1B7D",
        "text": "Polyuria"
    },
    {
        "id": "1B7E",
        "text": "Symptoms, signs or clinical findings involving the urinary system"
    },
    {
        "id": "1B7G",
        "text": "Macroscopic haematuria"
    },
    {
        "id": "1B7H",
        "text": "Other difficulties with micturition"
    },
    {
        "id": "1B7I",
        "text": "Other difficulties with micturition"
    },
    {
        "id": "1B7K",
        "text": "Urinary incontinence"
    },
    {
        "id": "1B7L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B7M",
        "text": "Influenza due to identified seasonal influenza virus"
    },
    {
        "id": "1B7N",
        "text": "Influenza"
    },
    {
        "id": "1B7O",
        "text": "Food-induced non-IgE-mediated gastrointestinal hypersensitivity"
    },
    {
        "id": "1B7P",
        "text": "Food protein-induced enterocolitis syndrome"
    },
    {
        "id": "1B7Q",
        "text": "Food protein-induced enterocolitis syndrome"
    },
    {
        "id": "1B7R",
        "text": "Food-induced eosinophilic oesophagitis"
    },
    {
        "id": "1B7S",
        "text": "Allergic gastritis due to IgE-mediated hypersensitivity"
    },
    {
        "id": "1B7T",
        "text": "Allergic gastritis due to IgE-mediated hypersensitivity"
    },
    {
        "id": "1B7V",
        "text": "Benign neoplasm of gallbladder and bile duct"
    },
    {
        "id": "1B7W",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "1B7X",
        "text": "Motility disorders of oesophagus"
    },
    {
        "id": "1B7Y",
        "text": "Achalasia"
    },
    {
        "id": "1B7Z",
        "text": "Hypertensive peristalsis"
    },
    {
        "id": "1B80",
        "text": "Drug-induced oesophagitis"
    },
    {
        "id": "1B81",
        "text": "Viral oesophagitis"
    },
    {
        "id": "1B82",
        "text": "Oesophageal candidosis"
    },
    {
        "id": "1B83",
        "text": "Supplementary Chapter Traditional Medicine Conditions - Module I"
    },
    {
        "id": "1B84",
        "text": "Vascular disorders of the oesophagus"
    },
    {
        "id": "1B85",
        "text": "Other diseases of oesophagus"
    },
    {
        "id": "1B86",
        "text": "Heterotopic gastric mucosa"
    },
    {
        "id": "1B87",
        "text": "Acquired anatomical alterations of the duodenum"
    },
    {
        "id": "1B88",
        "text": "Obstruction of duodenum"
    },
    {
        "id": "1B89",
        "text": "Duodenitis due to diseases classified elsewhere"
    },
    {
        "id": "1B8A",
        "text": "Duodenitis due to vasculitis"
    },
    {
        "id": "1B8B",
        "text": "Bacterial duodenitis"
    },
    {
        "id": "1B8C",
        "text": "Viral duodenitis"
    },
    {
        "id": "184X",
        "text": "Duodenal ulcer"
    },
    {
        "id": "1B8D",
        "text": "Infectious duodenal ulcer"
    },
    {
        "id": "1B8E",
        "text": "Vascular disorders of the duodenum"
    },
    {
        "id": "1B8F",
        "text": "Acquired anatomical alterations of the stomach"
    },
    {
        "id": "1B8G",
        "text": "Gastric outlet obstruction"
    },
    {
        "id": "1B8H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B8I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B8J",
        "text": "Gastritis due to duodenogastric reflux"
    },
    {
        "id": "1B8K",
        "text": "Menetrier disease"
    },
    {
        "id": "1B8L",
        "text": "Gastritis due to diseases classified elsewhere"
    },
    {
        "id": "1B8M",
        "text": "Gastritis due to vasculitis"
    },
    {
        "id": "1B8N",
        "text": "Alcoholic gastritis"
    },
    {
        "id": "179K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B8O",
        "text": "Gastric candidosis"
    },
    {
        "id": "1B8P",
        "text": "Gastric erosion"
    },
    {
        "id": "1B8Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B8R",
        "text": "Infectious secondary gastric ulcer"
    },
    {
        "id": "1B8S",
        "text": "Anastomotic ulcer due to diseases classified elsewhere"
    },
    {
        "id": "1B8T",
        "text": "Vascular disorders of the stomach"
    },
    {
        "id": "1B8U",
        "text": "Contusion of small intestine"
    },
    {
        "id": "1B8V",
        "text": "Laceration of small intestine"
    },
    {
        "id": "1B8W",
        "text": "Primary blast injury of small intestine"
    },
    {
        "id": "1B8X",
        "text": "Crohn disease of oesophagus"
    },
    {
        "id": "1B8Y",
        "text": "Crohn disease of stomach"
    },
    {
        "id": "1B8Z",
        "text": "Crohn disease of duodenum"
    },
    {
        "id": "1B90",
        "text": "Crohn disease of small intestine"
    },
    {
        "id": "1B91",
        "text": "Crohn disease of appendix"
    },
    {
        "id": "1B92",
        "text": "Crohn disease of anastomotic sites"
    },
    {
        "id": "1B93",
        "text": "Adenocarcinoma of small intestine, site unspecified"
    },
    {
        "id": "1B94",
        "text": "Other acquired anatomical alterations of large intestine"
    },
    {
        "id": "1B95",
        "text": "Contusion of colon"
    },
    {
        "id": "1B96",
        "text": "Laceration of colon"
    },
    {
        "id": "1B97",
        "text": "Primary blast injury of colon"
    },
    {
        "id": "1B98",
        "text": "Megacolon due to infectious colitis"
    },
    {
        "id": "1B99",
        "text": "Inflammatory bowel diseases"
    },
    {
        "id": "1B9A",
        "text": "Inflammatory bowel diseases"
    },
    {
        "id": "1B9B",
        "text": "Ulcerative colitis"
    },
    {
        "id": "1B9C",
        "text": "Ulcerative pancolitis"
    },
    {
        "id": "1B9D",
        "text": "Secondary noninfectious colitis or proctitis due to other diseases, classified elsewhere"
    },
    {
        "id": "1B9E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B9F",
        "text": "Drug-induced colitis"
    },
    {
        "id": "1B9G",
        "text": "Colitis or proctitis due to external causes"
    },
    {
        "id": "1B9H",
        "text": "Rectal abscess"
    },
    {
        "id": "1B9K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B9M",
        "text": "Pseudomembranous colitis"
    },
    {
        "id": "1B9L",
        "text": "Impaired glucose tolerance"
    },
    {
        "id": "1B9I",
        "text": "Intermediate hyperglycaemia"
    },
    {
        "id": "1B9J",
        "text": "Impaired fasting glucose"
    },
    {
        "id": "1B9N",
        "text": "Inflammatory polyp of large intestine"
    },
    {
        "id": "1B9O",
        "text": "Adenocarcinoma of ascending colon or right flexure of colon"
    },
    {
        "id": "1B9P",
        "text": "Abdominal wall hernia"
    },
    {
        "id": "1B9Q",
        "text": "Adenocarcinoma of transverse colon"
    },
    {
        "id": "1B9R",
        "text": "Adenocarcinoma of descending colon or splenic flexure of colon"
    },
    {
        "id": "1B9S",
        "text": "Mucinous adenocarcinoma of appendix"
    },
    {
        "id": "1B9T",
        "text": "Adenocarcinoma of sigmoid colon"
    },
    {
        "id": "1B9U",
        "text": "Adenocarcinoma of rectosigmoid junction"
    },
    {
        "id": "1B9V",
        "text": "Adenocarcinomas of rectum"
    },
    {
        "id": "1B9W",
        "text": "Anal fissure"
    },
    {
        "id": "1B9X",
        "text": "Familial nonpolyposis colorectal cancer"
    },
    {
        "id": "1B9Y",
        "text": "Anorectal fistula"
    },
    {
        "id": "1B9Z",
        "text": "Adenomatous polyp of the colon"
    },
    {
        "id": "1C00",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C01",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C02",
        "text": "Melanoma of anus or anal canal"
    },
    {
        "id": "1C03",
        "text": "Neuroendocrine neoplasm of anus or anal canal"
    },
    {
        "id": "1C04",
        "text": "Ischiorectal abscess"
    },
    {
        "id": "1C05",
        "text": "Postcholecystectomy syndrome"
    },
    {
        "id": "1C06",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C07",
        "text": "Functional diarrhoea of infants, toddlers or children"
    },
    {
        "id": "1C08",
        "text": "Functional constipation of infants, toddlers or children"
    },
    {
        "id": "1C09",
        "text": "Infant regurgitation"
    },
    {
        "id": "1C0A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C0B",
        "text": "Generalised abdominal pain"
    },
    {
        "id": "1C0C",
        "text": "Abdominal distension"
    },
    {
        "id": "1C0D",
        "text": "Hepatomegaly, not elsewhere classified"
    },
    {
        "id": "1C0E",
        "text": "Fear of digestive disease"
    },
    {
        "id": "1C0F",
        "text": "Fear of cancer of digestive system"
    },
    {
        "id": "1C0G",
        "text": "Other digestive symptom or complaint, not otherwise specified"
    },
    {
        "id": "1C0H",
        "text": "Splenomegaly, not elsewhere classified"
    },
    {
        "id": "1C0I",
        "text": "Hepatomegaly with splenomegaly"
    },
    {
        "id": "1C0L",
        "text": "Oesophageal obstruction"
    },
    {
        "id": "1C0M",
        "text": "Digestive system perforation"
    },
    {
        "id": "1C0N",
        "text": "Spontaneous rupture of oesophagus"
    },
    {
        "id": "1C0O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C0P",
        "text": "Digestive system stenosis"
    },
    {
        "id": "1C0Q",
        "text": "Digestive system abscess"
    },
    {
        "id": "1C0R",
        "text": "Digestive system fistula"
    },
    {
        "id": "1C0S",
        "text": "Clinical manifestations of the digestive system"
    },
    {
        "id": "1C0J",
        "text": "Digestive system haemorrhage"
    },
    {
        "id": "1C0K",
        "text": "Digestive system obstruction"
    },
    {
        "id": "1C0W",
        "text": "Changes in male genital anatomy"
    },
    {
        "id": "1C0X",
        "text": "Changes in female genital anatomy"
    },
    {
        "id": "1C0Y",
        "text": "Certain disorders originating in the perinatal period"
    },
    {
        "id": "1C0Z",
        "text": "Abdominal or pelvic pain"
    },
    {
        "id": "1C10",
        "text": "Other and unspecified abdominal pain"
    },
    {
        "id": "1C11",
        "text": "Diarrhoea"
    },
    {
        "id": "1C13",
        "text": "Faecal incontinence"
    },
    {
        "id": "1C14",
        "text": "Faecal incontinence"
    },
    {
        "id": "1C15",
        "text": "Faecal incontinence"
    },
    {
        "id": "1C16",
        "text": "Faecal incontinence"
    },
    {
        "id": "1C18",
        "text": "Abdominal distension"
    },
    {
        "id": "1C17",
        "text": "Flatulence and related conditions"
    },
    {
        "id": "1C19",
        "text": "Flatulence and related conditions"
    },
    {
        "id": "1C1A",
        "text": "Flatulence and related conditions"
    },
    {
        "id": "1C1B",
        "text": "Flatulence and related conditions"
    },
    {
        "id": "1C1C",
        "text": "Abnormal bowel sounds"
    },
    {
        "id": "1C1D",
        "text": "Abnormal bowel sounds"
    },
    {
        "id": "1C1E",
        "text": "Abnormal bowel sounds"
    },
    {
        "id": "1C1F",
        "text": "Symptoms related to the lower gastrointestinal tract or abdomen"
    },
    {
        "id": "1C1G",
        "text": "Nausea or vomiting"
    },
    {
        "id": "1C1H",
        "text": "Vomiting"
    },
    {
        "id": "1C1I",
        "text": "Vomiting"
    },
    {
        "id": "1C1J",
        "text": "Vomiting"
    },
    {
        "id": "1C1K",
        "text": "Dysphagia"
    },
    {
        "id": "1C1L",
        "text": "Dysphagia"
    },
    {
        "id": "1C1M",
        "text": "Clinical findings on diagnostic imaging of heart or coronary circulation"
    },
    {
        "id": "1C1N",
        "text": "Clinical findings on diagnostic imaging of heart or coronary circulation"
    },
    {
        "id": "1C1P",
        "text": "Special screening examination for cardiovascular disorders"
    },
    {
        "id": "1C1Q",
        "text": "Reactive arthropathies"
    },
    {
        "id": "1C1R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C1S",
        "text": "Results of function studies of the circulatory system"
    },
    {
        "id": "1C1T",
        "text": "Acute ST elevation myocardial infarction"
    },
    {
        "id": "1C1U",
        "text": "Gender incongruence"
    },
    {
        "id": "1C1V",
        "text": "Gender incongruence"
    },
    {
        "id": "1C1W",
        "text": "Hormonally-mediated rhinitis"
    },
    {
        "id": "1C1X",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "1C1Y",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "1C1Z",
        "text": "Observation for suspected suicide ideation or attempt, ruled out"
    },
    {
        "id": "1C20",
        "text": "Observation for suspected nervous system disorder, ruled out"
    },
    {
        "id": "1C21",
        "text": "Observation for suspected myocardial infarction, ruled out"
    },
    {
        "id": "1C22",
        "text": "Observation for suspected cardiovascular diseases other than suspected myocardial infarction, ruled out"
    },
    {
        "id": "1C23",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "1C24",
        "text": "Special screening examination for infectious diseases"
    },
    {
        "id": "1C25",
        "text": "Special screening examination for neoplasms"
    },
    {
        "id": "1C26",
        "text": "Special screening examination for other diseases or disorders"
    },
    {
        "id": "1C27",
        "text": "Special screening examination for diabetes mellitus"
    },
    {
        "id": "1C28",
        "text": "Personal history of risk-factors"
    },
    {
        "id": "1C29",
        "text": "Personal history of medical treatment"
    },
    {
        "id": "1C2A",
        "text": "Personal history of long-term use of anticoagulants"
    },
    {
        "id": "1C2B",
        "text": "Personal history of long-term use of medicaments other than anticoagulants"
    },
    {
        "id": "1C2D",
        "text": "Glomerular disease with IgA disease"
    },
    {
        "id": "1C2F",
        "text": "Neonatal disorders of subcutaneous fat"
    },
    {
        "id": "1C2H",
        "text": "Acculturation difficulty"
    },
    {
        "id": "1C2I",
        "text": "Social exclusion or rejection"
    },
    {
        "id": "1C2J",
        "text": "Target of perceived adverse discrimination or persecution"
    },
    {
        "id": "1C2K",
        "text": "Personal frightening experience in childhood"
    },
    {
        "id": "1C2L",
        "text": "Emotional neglect of child"
    },
    {
        "id": "1C2G",
        "text": "Cold panniculitis of the newborn"
    },
    {
        "id": "1C2E",
        "text": "Panniculitis"
    },
    {
        "id": "1C2N",
        "text": "Diseases of the circulatory system"
    },
    {
        "id": "1C2O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C2P",
        "text": "Neuroendocrine tumour of jejunum or ileum"
    },
    {
        "id": "1C2Q",
        "text": "Neuroendocrine tumour of appendix"
    },
    {
        "id": "1C2R",
        "text": "Neoplasms of uncertain behaviour of appendix"
    },
    {
        "id": "1C2S",
        "text": "Odd or peculiar appearance"
    },
    {
        "id": "1B0M",
        "text": "Hand and arm vibration syndrome"
    },
    {
        "id": "1C2T",
        "text": "Effects of vibration"
    },
    {
        "id": "1C2U",
        "text": "Hand and arm vibration syndrome"
    },
    {
        "id": "1C2W",
        "text": "Idiopathic Type 1 diabetes mellitus"
    },
    {
        "id": "1C2X",
        "text": "Ageing associated decline in intrinsic capacity"
    },
    {
        "id": "1C2Y",
        "text": "Disorders of mitochondrial oxidative phosphorylation"
    },
    {
        "id": "1C2Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C30",
        "text": "Strain or sprain of sacroiliac joint"
    },
    {
        "id": "1C32",
        "text": "Cervicogenic headache"
    },
    {
        "id": "1C33",
        "text": "Strain or sprain of sternoclavicular joint"
    },
    {
        "id": "1C34",
        "text": "Sacroiliitis, not elsewhere classified"
    },
    {
        "id": "1C35",
        "text": "Disorders of patella"
    },
    {
        "id": "1C36",
        "text": "Puckering of macula"
    },
    {
        "id": "1C39",
        "text": "Obesity in adults"
    },
    {
        "id": "1C38",
        "text": "Overweight or obesity"
    },
    {
        "id": "1C37",
        "text": "Overweight"
    },
    {
        "id": "1C3A",
        "text": "Overweight or localised adiposity"
    },
    {
        "id": "1C3B",
        "text": "Leptin-related genetic obesity"
    },
    {
        "id": "1C3C",
        "text": "Fluorine deficiency"
    },
    {
        "id": "1C3D",
        "text": "Fluorine deficiency"
    },
    {
        "id": "1C3E",
        "text": "Drug-induced obesity"
    },
    {
        "id": "1C3F",
        "text": "Undernutrition based on anthropometric or clinical criteria"
    },
    {
        "id": "1C3G",
        "text": "Undernutrition based on anthropometric or clinical criteria in infants, children or adolescents"
    },
    {
        "id": "1C3H",
        "text": "Undernutrition based on anthropometric or clinical criteria in adults"
    },
    {
        "id": "1C3I",
        "text": "Overweight in infants, children or adolescents"
    },
    {
        "id": "1C3J",
        "text": "Hypokalaemia"
    },
    {
        "id": "1C3K",
        "text": "Vitamin D deficiency rickets"
    },
    {
        "id": "1C3L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C3M",
        "text": "Severe wasting in infants, children or adolescents"
    },
    {
        "id": "1C3N",
        "text": "Vitamin A deficiency"
    },
    {
        "id": "1C3O",
        "text": "Vitamin E deficiency"
    },
    {
        "id": "1C3P",
        "text": "Folate deficiency"
    },
    {
        "id": "1C3Q",
        "text": "Molybdenum deficiency"
    },
    {
        "id": "1C3R",
        "text": "Vitamin A deficiency with corneal xerosis"
    },
    {
        "id": "1C3S",
        "text": "Obesity in infants or children up to 5 years of age"
    },
    {
        "id": "1C31",
        "text": "Other chest pain"
    },
    {
        "id": "1C3T",
        "text": "Strain or sprain of chondro-sternal joint"
    },
    {
        "id": "1C3U",
        "text": "Costovertebral joint"
    },
    {
        "id": "1C3V",
        "text": "Facet joint"
    },
    {
        "id": "1C3W",
        "text": "Transient ischaemic attack"
    },
    {
        "id": "1C3X",
        "text": "External intercostal muscle"
    },
    {
        "id": "1C3Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C3Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C40",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C41",
        "text": "Coccyx"
    },
    {
        "id": "1C42",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C43",
        "text": "Neck pain myofascial"
    },
    {
        "id": "1C44",
        "text": "Thoracic pain myofascial"
    },
    {
        "id": "1C46",
        "text": "Biomechanical lesions, not elsewhere classified"
    },
    {
        "id": "1C49",
        "text": "Vitamin D deficiency"
    },
    {
        "id": "1C4A",
        "text": "Causes of healthcare related harm or injury"
    },
    {
        "id": "1C4C",
        "text": "Parkinsonism"
    },
    {
        "id": "1B5G",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "1C4D",
        "text": "Chronic postsurgical or post traumatic pain"
    },
    {
        "id": "1C4E",
        "text": "Folliculitis keloidalis"
    },
    {
        "id": "1C4F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C4H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C4I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C4K",
        "text": "Bacterial infection of certain specified cranial nerve"
    },
    {
        "id": "1C4L",
        "text": "Viral infection of certain specified cranial nerve"
    },
    {
        "id": "1C4M",
        "text": "Fungal infection of certain specified cranial nerve"
    },
    {
        "id": "1C4N",
        "text": "Parasitic infection of certain specified cranial nerve"
    },
    {
        "id": "1C4O",
        "text": "Bacterial infection of certain specified peripheral nerve"
    },
    {
        "id": "1C4P",
        "text": "Infections of the peripheral nerves"
    },
    {
        "id": "1C4Q",
        "text": "Viral infection of certain specified peripheral nerve"
    },
    {
        "id": "1C4R",
        "text": "Fungal infection of certain specified peripheral nerve"
    },
    {
        "id": "1C4S",
        "text": "Parasitic infection of certain specified peripheral nerve"
    },
    {
        "id": "1C4T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C4U",
        "text": "Encephalopathy due to vitamin B12 deficiency"
    },
    {
        "id": "1C4V",
        "text": "Encephalopathy due to folate deficiency"
    },
    {
        "id": "1C4W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C4X",
        "text": "Myelopathy due to vitamin B12 deficiency"
    },
    {
        "id": "1C4Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C4Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C50",
        "text": "Peripheral neuropathy due to vitamin B12 deficiency"
    },
    {
        "id": "1C51",
        "text": "Peripheral neuropathy due to vitamin E deficiency"
    },
    {
        "id": "1C52",
        "text": "Peripheral neuropathy due to folate deficiency"
    },
    {
        "id": "1C53",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C4G",
        "text": "Autoimmune or other photodermatoses"
    },
    {
        "id": "1C54",
        "text": "Miscellaneous disorders involving the hair follicle"
    },
    {
        "id": "1C55",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C56",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C57",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C58",
        "text": "Miscellaneous disorders involving the hair follicle"
    },
    {
        "id": "1C59",
        "text": "Miscellaneous disorders involving the hair follicle"
    },
    {
        "id": "1C5A",
        "text": "Miscellaneous disorders involving the hair follicle"
    },
    {
        "id": "1C5C",
        "text": "Pyuria, unknown cause"
    },
    {
        "id": "1C5D",
        "text": "Epidemic vertigo"
    },
    {
        "id": "1C5E",
        "text": "Large plaque parapsoriasis"
    },
    {
        "id": "1C5F",
        "text": "Poikiloderma vasculare atrophicans"
    },
    {
        "id": "1C5G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C5H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C5I",
        "text": "Peripheral neuropathy due to vitamin B1 deficiency"
    },
    {
        "id": "1C5J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C5K",
        "text": "Spinocerebellar ataxia due to vitamin E deficiency"
    },
    {
        "id": "1C5L",
        "text": "Dementia due to vitamin B12 deficiency"
    },
    {
        "id": "1C5M",
        "text": "Dementia due to vitamin E deficiency"
    },
    {
        "id": "1C5N",
        "text": "Dementia due to folate deficiency"
    },
    {
        "id": "1C5O",
        "text": "Dementia due to iron deficiency"
    },
    {
        "id": "1C5P",
        "text": "Dementia due to other nutritional deficiency"
    },
    {
        "id": "1C5Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C5R",
        "text": "Myelopathy due to excess of zinc"
    },
    {
        "id": "1C5S",
        "text": "Encephalopathy due to lead"
    },
    {
        "id": "1C5T",
        "text": "Myelopathy due to toxicity"
    },
    {
        "id": "1C5U",
        "text": "Myelopathy due to intrathecal chemotherapy"
    },
    {
        "id": "1C5V",
        "text": "Myelopathy due to drugs or medications"
    },
    {
        "id": "1C5W",
        "text": "Myelopathy due to food toxicity"
    },
    {
        "id": "1C5X",
        "text": "Myelopathy due to lathyrism"
    },
    {
        "id": "1C5Y",
        "text": "Motor neuron disease in lead intoxication"
    },
    {
        "id": "1C5Z",
        "text": "Dementia due to carbon monoxide poisoning"
    },
    {
        "id": "1C60",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C61",
        "text": "Panniculitis"
    },
    {
        "id": "199Y",
        "text": "Anterior descending from right coronary artery across right ventricular outflow tract"
    },
    {
        "id": "198U",
        "text": "Right ventricular myocardial sinusoids"
    },
    {
        "id": "198D",
        "text": "Visceral heterotaxy"
    },
    {
        "id": "1C62",
        "text": "Multiple system atrophy, Parkinsonism"
    },
    {
        "id": "1C63",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1B7U",
        "text": "Symptoms, signs or clinical findings of blood, blood-forming organs, or the immune system"
    },
    {
        "id": "1C66",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C67",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C68",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C69",
        "text": "Diabetic skin lesions"
    },
    {
        "id": "1C6A",
        "text": "Late congenital syphilitic encephalitis"
    },
    {
        "id": "1C6J",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "1C6K",
        "text": "Latent tuberculosis"
    },
    {
        "id": "1C6L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C6M",
        "text": "Herpes simplex virus duodenitis"
    },
    {
        "id": "1C6N",
        "text": "Encephalitis due to Human herpesvirus"
    },
    {
        "id": "1C6O",
        "text": "Myelitis due to Human herpes virus"
    },
    {
        "id": "1C6P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C6H",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "1C6G",
        "text": "Chronic secondary visceral pain"
    },
    {
        "id": "1C6F",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1C6D",
        "text": "Chronic postsurgical or post traumatic pain"
    },
    {
        "id": "1C6C",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "1C6Q",
        "text": "Hypovolaemic shock"
    },
    {
        "id": "1C6R",
        "text": "Acute laryngitis or tracheitis"
    },
    {
        "id": "1C6S",
        "text": "Other diseases of upper respiratory tract"
    },
    {
        "id": "1C6T",
        "text": "Tracheal bronchus"
    },
    {
        "id": "1C6U",
        "text": "Airway obstruction"
    },
    {
        "id": "1C6V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C6W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C6Z",
        "text": "Abnormalities of breathing"
    },
    {
        "id": "1C72",
        "text": "Clinical findings in blood, blood-forming organs, or the immune system"
    },
    {
        "id": "1C73",
        "text": "Acquired immunodeficiencies"
    },
    {
        "id": "1C74",
        "text": "Dermatoses precipitated by drug therapy"
    },
    {
        "id": "1C75",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C76",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C77",
        "text": "Mycoses"
    },
    {
        "id": "1C78",
        "text": "Parkinson disease"
    },
    {
        "id": "1C7A",
        "text": "Abnormal reaction to tuberculin test"
    },
    {
        "id": "1C7B",
        "text": "Abnormal reaction to tuberculin test"
    },
    {
        "id": "1C7C",
        "text": "Microbiological findings in blood, blood-forming organs, or the immune system"
    },
    {
        "id": "1C7D",
        "text": "Sepsis"
    },
    {
        "id": "1C7F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C7G",
        "text": "Malignant neoplasm metastasis in brain"
    },
    {
        "id": "1C7I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C7J",
        "text": "Sequelae of infectious diseases"
    },
    {
        "id": "1C7K",
        "text": "Retroperitoneum"
    },
    {
        "id": "1C71",
        "text": "Miscellaneous disorders involving the hair follicle"
    },
    {
        "id": "1C7L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C79",
        "text": "Finding of abnormal level of heavy metals in blood"
    },
    {
        "id": "1C7M",
        "text": "Diffuse bleeding diathesis due to vitamin K deficient haemorrhagic disease of fetus or newborn"
    },
    {
        "id": "1C7N",
        "text": "Physeal fracture of upper end of humerus"
    },
    {
        "id": "1C7O",
        "text": "Physeal fracture of lower end of humerus"
    },
    {
        "id": "1C7P",
        "text": "Physeal fracture of upper end of radius"
    },
    {
        "id": "1C7Q",
        "text": "Physeal fracture of lower end of ulna"
    },
    {
        "id": "1C7R",
        "text": "Physeal fracture of lower end of femur"
    },
    {
        "id": "1C7S",
        "text": "Physeal fracture of proximal femur"
    },
    {
        "id": "1C7T",
        "text": "Physeal fracture of upper end of tibia"
    },
    {
        "id": "1C7U",
        "text": "Physeal fracture of lower end of tibia"
    },
    {
        "id": "1C7V",
        "text": "Physeal fracture of fibular head"
    },
    {
        "id": "1C7W",
        "text": "Physeal fracture of lower end of fibula"
    },
    {
        "id": "1C7X",
        "text": "Injury or harm arising from surgical or medical care, not elsewhere classified"
    },
    {
        "id": "1C80",
        "text": "Abnormality of white blood cells"
    },
    {
        "id": "1C7Z",
        "text": "Abnormality of white blood cells"
    },
    {
        "id": "1C84",
        "text": "Tattoos or tattoo reactions"
    },
    {
        "id": "1C86",
        "text": "Skin or other sensitisation tests"
    },
    {
        "id": "1C87",
        "text": "Medication or substance unknown to be an allergen, as mode of injury or harm"
    },
    {
        "id": "1C85",
        "text": "Special screening examination for other diseases or disorders"
    },
    {
        "id": "1C88",
        "text": "Developmental defects of the nail apparatus"
    },
    {
        "id": "1C89",
        "text": "Onycholysis"
    },
    {
        "id": "1C8A",
        "text": "Nail pterygium"
    },
    {
        "id": "1C8B",
        "text": "Ventral pterygium of nail"
    },
    {
        "id": "1C8C",
        "text": "Incessant infant ventricular tachycardia"
    },
    {
        "id": "1C8D",
        "text": "Diffuse bleeding diathesis due to vitamin K deficient haemorrhagic disease of fetus or newborn"
    },
    {
        "id": "1C8E",
        "text": "Special screening examination for other diseases or disorders"
    },
    {
        "id": "1C8F",
        "text": "Necrotising polymyositis"
    },
    {
        "id": "1C8J",
        "text": "Ewing sarcoma, primary site"
    },
    {
        "id": "1B1H",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "1C8L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C8M",
        "text": "Certain clinical findings of blood chemistry"
    },
    {
        "id": "1C8N",
        "text": "Histological findings in blood, blood-forming organs, or the immune system"
    },
    {
        "id": "1C8O",
        "text": "Certain specified immunological findings"
    },
    {
        "id": "1C8P",
        "text": "Clinical findings in blood, blood-forming organs, or the immune system"
    },
    {
        "id": "1C8R",
        "text": "Malignant neoplasm metastasis in lymph nodes"
    },
    {
        "id": "1C8U",
        "text": "Acquired pulmonary cysts in newborn"
    },
    {
        "id": "1C8V",
        "text": "Excoriation disorder"
    },
    {
        "id": "1C8W",
        "text": "Excoriation disorder"
    },
    {
        "id": "1C8X",
        "text": "Descending aorta dissection and distal propagation"
    },
    {
        "id": "1C8Y",
        "text": "Allergic asthma"
    },
    {
        "id": "1C8Z",
        "text": "Non-allergic asthma"
    },
    {
        "id": "1C90",
        "text": "Unspecified asthma, uncomplicated"
    },
    {
        "id": "1C91",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C95",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1C96",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1C93",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1C97",
        "text": "Meconium passage during delivery"
    },
    {
        "id": "1C98",
        "text": "Fetus or newborn affected by certain complications of labour or delivery"
    },
    {
        "id": "1C9B",
        "text": "Dementia due to Alzheimer disease"
    },
    {
        "id": "1C9C",
        "text": "Autosomal dominant Alzheimer disease dementia, mutation of presenilin 1"
    },
    {
        "id": "1C9D",
        "text": "Autosomal dominant Alzheimer disease dementia, mutation of presenilin 2"
    },
    {
        "id": "1C9E",
        "text": "Autosomal dominant Alzheimer disease dementia, mutation of amyloid precursor protein"
    },
    {
        "id": "1C9F",
        "text": "Dementia due to Alzheimer disease with late onset"
    },
    {
        "id": "1C9G",
        "text": "Alzheimer disease dementia with depression"
    },
    {
        "id": "1C9H",
        "text": "Alzheimer disease dementia with psychosis"
    },
    {
        "id": "1C9I",
        "text": "Non-amnestic Alzheimer disease dementia subtypes"
    },
    {
        "id": "1C9J",
        "text": "Non-amnestic Alzheimer disease dementia, frontal variant"
    },
    {
        "id": "1C9K",
        "text": "Non-amnestic Alzheimer disease dementia, visuospatial variant"
    },
    {
        "id": "1C9L",
        "text": "Non-amnestic Alzheimer disease dementia, logopenic variant"
    },
    {
        "id": "1C9O",
        "text": "Neurofibrillary tangle dementia"
    },
    {
        "id": "1C9M",
        "text": "Argyrophilic grain disease"
    },
    {
        "id": "1C9N",
        "text": "Familial multiple system tauopathy"
    },
    {
        "id": "1C9P",
        "text": "Dementia due to Down syndrome"
    },
    {
        "id": "1C9Q",
        "text": "Dementia due to Fragile X Syndrome"
    },
    {
        "id": "1C9R",
        "text": "Dementia due to corticobasal degeneration"
    },
    {
        "id": "1C9S",
        "text": "Dementia due to Huntington disease"
    },
    {
        "id": "1C9T",
        "text": "Dementia due to multiple system atrophy"
    },
    {
        "id": "1C9U",
        "text": "Dementia due to neurofilament inclusion body disease"
    },
    {
        "id": "1C9V",
        "text": "Dementia due to Parkinsonism-dementia complex of Guam"
    },
    {
        "id": "1C9X",
        "text": "Dementia due to progressive subcortical gliosis"
    },
    {
        "id": "1C9Y",
        "text": "Dementia due to spinocerebellar ataxia"
    },
    {
        "id": "1C9Z",
        "text": "Dementia due to leukodystrophy"
    },
    {
        "id": "1D00",
        "text": "Dementia due to neurodegeneration with brain iron accumulation"
    },
    {
        "id": "1D01",
        "text": "Dementia due to acute demyelinating encephalomyelitis"
    },
    {
        "id": "1D02",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D03",
        "text": "Dementia due to chronic subdural haematoma"
    },
    {
        "id": "1D04",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D05",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D06",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D07",
        "text": "Dementia due to epilepsy"
    },
    {
        "id": "1D08",
        "text": "Dementia due to Hashimoto encephalopathy"
    },
    {
        "id": "1D09",
        "text": "Dementia due to metastatic brain tumour"
    },
    {
        "id": "1D0A",
        "text": "Dementia due to multiple sclerosis"
    },
    {
        "id": "1D0B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D0C",
        "text": "Dementia due to normal pressure hydrocephalus"
    },
    {
        "id": "1D0E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D0F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D0G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D0H",
        "text": "Dementia due to paraneoplastic encephalitis"
    },
    {
        "id": "1D0I",
        "text": "Dementia due to prion disease"
    },
    {
        "id": "1D0J",
        "text": "Dementia due to sporadic Creutzfeldt-Jakob disease"
    },
    {
        "id": "1D0L",
        "text": "Dementia due to fatal familial insomnia"
    },
    {
        "id": "1D0K",
        "text": "Dementia due to familial Creutzfeldt-Jakob disease"
    },
    {
        "id": "1D0M",
        "text": "Dementia due to Gerstmann-Staussler-Sheinker syndrome"
    },
    {
        "id": "1D0N",
        "text": "Dementia due to iatrogenic Creutzfeldt-Jakob disease"
    },
    {
        "id": "1D0O",
        "text": "Dementia due to Kuru"
    },
    {
        "id": "1D0P",
        "text": "Dementia due to sporadic fatal insomnia"
    },
    {
        "id": "1D0R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D0S",
        "text": "Dementia due to subacute sclerosing panencephalitis"
    },
    {
        "id": "1D0T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D0U",
        "text": "Dementia due to autoimmune encephalitis"
    },
    {
        "id": "1D0V",
        "text": "Accelerated junctional rhythm"
    },
    {
        "id": "1D0W",
        "text": "Atrial fibrillation"
    },
    {
        "id": "1D0X",
        "text": "Dementia due to injury to the head"
    },
    {
        "id": "1D0Y",
        "text": "Junctional ectopic tachycardia"
    },
    {
        "id": "1D0Z",
        "text": "Macro reentrant atrial tachycardia"
    },
    {
        "id": "1D10",
        "text": "Dementia due to metabolic disorders involving the brain"
    },
    {
        "id": "1D11",
        "text": "Supraventricular tachycardia utilizing the atrioventricular junction"
    },
    {
        "id": "1D12",
        "text": "Dementia due to primary central nervous system neoplasm"
    },
    {
        "id": "1D13",
        "text": "Vomiting in newborn"
    },
    {
        "id": "1D14",
        "text": "Aminoglycoside toxicity in the newborn"
    },
    {
        "id": "1D16",
        "text": "Jittery baby, not elsewhere classified"
    },
    {
        "id": "1D17",
        "text": "Certain conditions originating in the perinatal period"
    },
    {
        "id": "1D18",
        "text": "Abnormal findings on neonatal screening"
    },
    {
        "id": "1D19",
        "text": "Neonatal bleeding originating in the oesophagus, stomach, small or large intestine"
    },
    {
        "id": "1D1A",
        "text": "Neonatal melaena"
    },
    {
        "id": "1D1B",
        "text": "Neonatal bleeding originating in the oesophagus, stomach, small or large intestine"
    },
    {
        "id": "1D1C",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1D1E",
        "text": "Extreme prematurity of newborn"
    },
    {
        "id": "1D1I",
        "text": "Extreme prematurity of newborn, gestational age 26 completed weeks"
    },
    {
        "id": "1D1H",
        "text": "Extreme prematurity of newborn, gestational age 25 completed weeks"
    },
    {
        "id": "1D1G",
        "text": "Extreme prematurity of newborn, gestational age 24 completed weeks"
    },
    {
        "id": "1D1F",
        "text": "Extreme prematurity of newborn, gestational age 23 completed weeks"
    },
    {
        "id": "1D1D",
        "text": "Extreme prematurity of newborn, gestational age less than 22 completed weeks"
    },
    {
        "id": "1D1M",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Sedatives, hypnotics or antianxiety drugs, Benzodiazepines"
    },
    {
        "id": "1D1N",
        "text": "Clinical findings on examination of urine, without diagnosis"
    },
    {
        "id": "1D1O",
        "text": "Proteinuria"
    },
    {
        "id": "1D1P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D1Q",
        "text": "Persistent proteinuria or albuminuria"
    },
    {
        "id": "1D1S",
        "text": "Persistent proteinuria or albuminuria"
    },
    {
        "id": "1D1T",
        "text": "Persistent proteinuria or albuminuria"
    },
    {
        "id": "1D1U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1021",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1D1V",
        "text": "Undernutrition based on anthropometric or clinical criteria in infants, children or adolescents"
    },
    {
        "id": "1D1W",
        "text": "Severe acute malnutrition in infants, children or adolescents"
    },
    {
        "id": "1D1X",
        "text": "Moderate stunting in infants, children or adolescents"
    },
    {
        "id": "1D1Y",
        "text": "Pregnancy, childbirth or the puerperium"
    },
    {
        "id": "1D20",
        "text": "Disorders of newborn related to long gestation or high birth weight"
    },
    {
        "id": "1D21",
        "text": "Fetal death, cause not specified"
    },
    {
        "id": "1D22",
        "text": "Multiple minute digitate keratoses"
    },
    {
        "id": "1D23",
        "text": "Postinflammatory hypermelanosis"
    },
    {
        "id": "1D24",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1D25",
        "text": "Hypermelanosis due to endocrine disorder"
    },
    {
        "id": "1D26",
        "text": "Acquired hypermelanosis"
    },
    {
        "id": "1D27",
        "text": "Syndromic acquired hypomelanosis"
    },
    {
        "id": "1D28",
        "text": "Acquired hypomelanotic disorders"
    },
    {
        "id": "1D29",
        "text": "Photoaging of the skin"
    },
    {
        "id": "1D2A",
        "text": "Actinic elastosis"
    },
    {
        "id": "1D2B",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1D2C",
        "text": "Dementia due to neurosyphilis"
    },
    {
        "id": "1D2D",
        "text": "Dementia due to Lyme disease"
    },
    {
        "id": "1D2E",
        "text": "Dementia due to progressive multifocal leukoencephalopathy"
    },
    {
        "id": "1D2F",
        "text": "Dementia due to Whipple disease"
    },
    {
        "id": "1D2G",
        "text": "Dementia due to herpes encephalitis"
    },
    {
        "id": "1D2H",
        "text": "Dementia due to trypanosomiasis"
    },
    {
        "id": "1D2I",
        "text": "Dementia due to neurocysticercosis"
    },
    {
        "id": "1D2J",
        "text": "Diverticular disease of large intestine"
    },
    {
        "id": "1D2K",
        "text": "Allergen immunotherapy"
    },
    {
        "id": "1D2L",
        "text": "Holoprosencephaly"
    },
    {
        "id": "1D2M",
        "text": "Familial reactive perforating collagenosis"
    },
    {
        "id": "1D2N",
        "text": "Rosacea and related disorders"
    },
    {
        "id": "1D2O",
        "text": "Dermatoses attributable to corticosteroid therapy"
    },
    {
        "id": "1D2P",
        "text": "Periorificial dermatitis"
    },
    {
        "id": "1D2R",
        "text": "Periocular dermatitis"
    },
    {
        "id": "1D2Q",
        "text": "Perioral dermatitis"
    },
    {
        "id": "1D2S",
        "text": "Periorificial dermatitis"
    },
    {
        "id": "1D2T",
        "text": "Congenital great vessel related acquired abnormality"
    },
    {
        "id": "1D2U",
        "text": "Acquired systemic vein abnormality"
    },
    {
        "id": "1D2V",
        "text": "Systemic vein obstruction"
    },
    {
        "id": "1D2W",
        "text": "Obstruction of peripheral vein"
    },
    {
        "id": "1D2X",
        "text": "Obstruction of visceral vein"
    },
    {
        "id": "1D30",
        "text": "Acquired superior caval vein abnormality"
    },
    {
        "id": "1D31",
        "text": "Acquired superior caval vein abnormality"
    },
    {
        "id": "1D2Z",
        "text": "Acquired superior caval vein abnormality"
    },
    {
        "id": "1D32",
        "text": "Superior caval vein obstruction due to tumour infiltration"
    },
    {
        "id": "1D33",
        "text": "Superior caval vein obstruction due to tumour infiltration"
    },
    {
        "id": "1D34",
        "text": "Superior caval vein syndrome"
    },
    {
        "id": "1D36",
        "text": "Superior caval vein obstruction due to foreign body"
    },
    {
        "id": "1D39",
        "text": "Acquired inferior caval vein abnormality"
    },
    {
        "id": "1D3C",
        "text": "Inferior caval vein obstruction due to tumour infiltration"
    },
    {
        "id": "1D3E",
        "text": "Acquired systemic vein abnormality"
    },
    {
        "id": "1D3B",
        "text": "Acquired inferior caval vein abnormality"
    },
    {
        "id": "1D3A",
        "text": "Acquired inferior caval vein abnormality"
    },
    {
        "id": "1D37",
        "text": "Superior caval vein syndrome"
    },
    {
        "id": "1D35",
        "text": "Superior caval vein syndrome"
    },
    {
        "id": "1D3F",
        "text": "Acquired pulmonary venous abnormality"
    },
    {
        "id": "1D3G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D3J",
        "text": "Postprocedural pulmonary venous disorder"
    },
    {
        "id": "1D3K",
        "text": "Postprocedural obstructed pulmonary venous pathway"
    },
    {
        "id": "1D3L",
        "text": "Postprocedural obstructed pulmonary vein"
    },
    {
        "id": "1D3M",
        "text": "Acquired pulmonary venous abnormality"
    },
    {
        "id": "1D3O",
        "text": "Acquired common atrioventricular valvar abnormality in biventricular connections"
    },
    {
        "id": "1D3P",
        "text": "Acquired truncal valvar abnormality"
    },
    {
        "id": "1D3Q",
        "text": "Acquired truncal valve regurgitation"
    },
    {
        "id": "1D3R",
        "text": "Acquired truncal valvar stenosis"
    },
    {
        "id": "1D3T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D3U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D3V",
        "text": "Acquired pulmonary atresia"
    },
    {
        "id": "1D3W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D3X",
        "text": "Acquired subaortic stenosis"
    },
    {
        "id": "1D3Y",
        "text": "Acquired pulmonary arterial tree abnormality"
    },
    {
        "id": "1D3Z",
        "text": "Acquired pulmonary trunk stenosis"
    },
    {
        "id": "1D40",
        "text": "Postprocedural pulmonary trunk stenosis"
    },
    {
        "id": "1D41",
        "text": "Acquired right pulmonary artery stenosis"
    },
    {
        "id": "1D42",
        "text": "Postprocedural right pulmonary artery stenosis"
    },
    {
        "id": "1D43",
        "text": "Postprocedural left pulmonary artery stenosis"
    },
    {
        "id": "1D44",
        "text": "Acquired left pulmonary artery stenosis"
    },
    {
        "id": "1D45",
        "text": "Acquired discontinuity of pulmonary arteries"
    },
    {
        "id": "1D46",
        "text": "Postprocedural pulmonary arterial tree disorder"
    },
    {
        "id": "1D47",
        "text": "Acquired pulmonary arterial aneurysm"
    },
    {
        "id": "1D48",
        "text": "Acquired pulmonary arterial dissection"
    },
    {
        "id": "1D49",
        "text": "Certain acquired abnormalities of aorta"
    },
    {
        "id": "1D4A",
        "text": "Ruptured aortic sinus of Valsalva aneurysm"
    },
    {
        "id": "1D4B",
        "text": "Acquired supravalvar aortic stenosis"
    },
    {
        "id": "1D4C",
        "text": "Recoarctation of the aorta"
    },
    {
        "id": "1D4D",
        "text": "Postprocedural recoarctation of the aorta"
    },
    {
        "id": "1D4E",
        "text": "Acquired ascending aorta or root dilation"
    },
    {
        "id": "1D4F",
        "text": "Ascending aortopathy associated with conotruncal malformations"
    },
    {
        "id": "1D4G",
        "text": "Ascending aortopathy associated with bicuspid aortic valve"
    },
    {
        "id": "1D4H",
        "text": "Postprocedural ascending aorta dilation"
    },
    {
        "id": "1D4I",
        "text": "Postprocedural neo-ascending aorta dilation"
    },
    {
        "id": "1D4J",
        "text": "Postprocedural aortic disorder related to congenital heart anomaly"
    },
    {
        "id": "1D4K",
        "text": "Postprocedural stenosis of the thoracic aorta"
    },
    {
        "id": "1D4L",
        "text": "Postprocedural dissection of aorta at coarctation site"
    },
    {
        "id": "1D4M",
        "text": "Postprocedural aneurysm or pseudoaneurysm of aorta at coarctation site"
    },
    {
        "id": "1D4N",
        "text": "Endarteritis at site of aortic coarctation"
    },
    {
        "id": "1D4O",
        "text": "Acquired abnormality of aortic arch branch"
    },
    {
        "id": "1D4P",
        "text": "Acquired arterial duct abnormality"
    },
    {
        "id": "1D4Q",
        "text": "Endarteritis at site of arterial duct"
    },
    {
        "id": "1D4R",
        "text": "Postprocedural arterial duct disorder"
    },
    {
        "id": "1D4S",
        "text": "Postprocedural arterial duct perforation"
    },
    {
        "id": "1D4U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D4V",
        "text": "Acquired abnormality of common arterial trunk"
    },
    {
        "id": "1D4W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D4X",
        "text": "Acquired stenosis of common arterial trunk"
    },
    {
        "id": "1D58",
        "text": "Congenital great vessel related acquired abnormality"
    },
    {
        "id": "1D5A",
        "text": "Adjustment disorder"
    },
    {
        "id": "1C47",
        "text": "Phaeochromocytoma"
    },
    {
        "id": "1C48",
        "text": "Malignant phaeochromocytoma of adrenal gland"
    },
    {
        "id": "1D3I",
        "text": "Acquired pulmonary venous obstruction"
    },
    {
        "id": "1D5B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D5F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D5H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D5I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D5K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D5L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D5M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D5P",
        "text": "Laevocardia"
    },
    {
        "id": "1D5T",
        "text": "Usual atrial arrangement"
    },
    {
        "id": "1D5U",
        "text": "Right hand pattern ventricular topology"
    },
    {
        "id": "1D5V",
        "text": "Aortic root anterior and rightward to pulmonary root"
    },
    {
        "id": "1D5W",
        "text": "Aortic root anterior and rightward to pulmonary root"
    },
    {
        "id": "1D5X",
        "text": "Spiralling course of great arteries"
    },
    {
        "id": "1D2Y",
        "text": "Postprocedural obstructed systemic venous pathway"
    },
    {
        "id": "1D5Y",
        "text": "Postprocedural obstructed systemic venous pathway"
    },
    {
        "id": "1D38",
        "text": "Postprocedural superior caval vein complication"
    },
    {
        "id": "1D5Z",
        "text": "Postprocedural superior caval vein complication"
    },
    {
        "id": "1D3D",
        "text": "Postprocedural inferior caval vein complication"
    },
    {
        "id": "1D60",
        "text": "Postprocedural inferior caval vein complication"
    },
    {
        "id": "1D64",
        "text": "Right-sided juxtaposition of the atrial appendages"
    },
    {
        "id": "1D65",
        "text": "Atrioventricular septal defect with communication at atrial level and restrictive communication at ventricular level"
    },
    {
        "id": "1D67",
        "text": "Congenital anomaly of a ventricle or the ventricular septum"
    },
    {
        "id": "1D68",
        "text": "Right ventricular myocardial sinusoids"
    },
    {
        "id": "1D6M",
        "text": "Ventricular septal defect haemodynamically insignificant"
    },
    {
        "id": "1D6N",
        "text": "Tricuspid atresia"
    },
    {
        "id": "1D6O",
        "text": "Mitral atresia"
    },
    {
        "id": "1D6P",
        "text": "Hypoplastic left heart syndrome"
    },
    {
        "id": "1D6R",
        "text": "Aortic valvar atresia"
    },
    {
        "id": "1D6S",
        "text": "Congenital pulmonary trunk hypoplasia"
    },
    {
        "id": "1D6U",
        "text": "Congenital pulmonary arterial branch stenosis"
    },
    {
        "id": "1D6V",
        "text": "Interrupted aortic arch distal to subclavian artery, type A"
    },
    {
        "id": "1D6W",
        "text": "Interrupted aortic arch between subclavian and common carotid arteries, type B"
    },
    {
        "id": "1D6X",
        "text": "Interrupted aortic arch between carotid arteries, type C"
    },
    {
        "id": "1D6Y",
        "text": "Left aortic arch"
    },
    {
        "id": "1D6Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D70",
        "text": "Aberrant origin of left subclavian artery"
    },
    {
        "id": "1D72",
        "text": "Abnormal origin of arterial duct"
    },
    {
        "id": "1D61",
        "text": "Common arterial trunk with pulmonary dominance and aortic arch obstruction"
    },
    {
        "id": "1D74",
        "text": "Dysplasia of tricuspid valve"
    },
    {
        "id": "1D75",
        "text": "Ventricular septal defect haemodynamically insignificant"
    },
    {
        "id": "1D77",
        "text": "Congenital supravalvar pulmonary stenosis"
    },
    {
        "id": "1D78",
        "text": "Absent or atretic right pulmonary artery"
    },
    {
        "id": "1D79",
        "text": "Absent or atretic left pulmonary artery"
    },
    {
        "id": "1D7A",
        "text": "Care involving use of rehabilitation procedures"
    },
    {
        "id": "1D7B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D7C",
        "text": "Myasthenia gravis or certain specified neuromuscular junction disorders"
    },
    {
        "id": "1D7E",
        "text": "Juvenile idiopathic polyarthritis"
    },
    {
        "id": "1D7D",
        "text": "Juvenile idiopathic arthritis"
    },
    {
        "id": "1D7F",
        "text": "Certain specified neuromuscular junction disorder"
    },
    {
        "id": "1D7G",
        "text": "Juvenile idiopathic arthritis"
    },
    {
        "id": "1D7H",
        "text": "Certain specified congenital or developmental myasthenia"
    },
    {
        "id": "1D7I",
        "text": "Congenital endplate acetylcholine receptor deficiency"
    },
    {
        "id": "1D7J",
        "text": "Limb-girdle myasthenia, nonfamilial"
    },
    {
        "id": "1D7K",
        "text": "SOX-associated Lambert-Eaton myasthenic syndrome"
    },
    {
        "id": "1D7L",
        "text": "Voltage-gated calcium channel-associated Lambert-Eaton myasthenic syndrome"
    },
    {
        "id": "1D7M",
        "text": "Myasthenia gravis, autoimmune acquired"
    },
    {
        "id": "1D7N",
        "text": "Neuromuscular junction disorder due to certain specified toxin"
    },
    {
        "id": "1D7O",
        "text": "Myasthenia gravis or certain specified neuromuscular junction disorders"
    },
    {
        "id": "1D7Q",
        "text": "Disorders of the neuromuscular junction in botulism"
    },
    {
        "id": "1D73",
        "text": "Congenital arterial duct anomaly"
    },
    {
        "id": "1D6F",
        "text": "Inlet muscular ventricular septal defect"
    },
    {
        "id": "1D7R",
        "text": "Diseases of the myocardium or cardiac chambers"
    },
    {
        "id": "1D7U",
        "text": "Rupture of chordae tendineae, not elsewhere classified"
    },
    {
        "id": "1D7V",
        "text": "Rupture of papillary muscle, not elsewhere classified"
    },
    {
        "id": "1D7W",
        "text": "Intracardiac thrombosis"
    },
    {
        "id": "1D7X",
        "text": "Cardiomegaly"
    },
    {
        "id": "1D7Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D7Z",
        "text": "Ventricular septal defect as current complication following acute myocardial infarction"
    },
    {
        "id": "1D5O",
        "text": "Diseases of the myocardium or cardiac chambers"
    },
    {
        "id": "1D80",
        "text": "Beh\u00e7et disease"
    },
    {
        "id": "1D81",
        "text": "Tophaceous gout"
    },
    {
        "id": "1D82",
        "text": "Microscopic polyangiitis"
    },
    {
        "id": "1D83",
        "text": "Delayed muscle maturation"
    },
    {
        "id": "1D84",
        "text": "Generalised congenital lipodystrophy with myopathy"
    },
    {
        "id": "1D85",
        "text": "Hereditary continuous muscle fibre activity"
    },
    {
        "id": "1D86",
        "text": "Hereditary myopathy, not elsewhere classified"
    },
    {
        "id": "1D87",
        "text": "Hereditary myopathy, not elsewhere classified"
    },
    {
        "id": "1D88",
        "text": "Hypertrophic brachial myopathy"
    },
    {
        "id": "1D89",
        "text": "Idiopathic rhabdomyolysis"
    },
    {
        "id": "1D8A",
        "text": "Monomelic hypertrophic myopathy"
    },
    {
        "id": "1D8B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D8C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1C6B",
        "text": "Chronic pain"
    },
    {
        "id": "1D1Z",
        "text": "Post-term newborn"
    },
    {
        "id": "1D1L",
        "text": "Complications of intrauterine procedures, not elsewhere classified"
    },
    {
        "id": "1D1J",
        "text": "Extreme prematurity of newborn, gestational age 27 completed weeks"
    },
    {
        "id": "1C9A",
        "text": "Meconium staining"
    },
    {
        "id": "1C8G",
        "text": "Olfactory neuroblastoma"
    },
    {
        "id": "1C6X",
        "text": "Abnormalities of breathing"
    },
    {
        "id": "1C2M",
        "text": "Personal history of psychological abuse"
    },
    {
        "id": "1B7J",
        "text": "Urinary incontinence"
    },
    {
        "id": "1B7F",
        "text": "Microscopic haematuria"
    },
    {
        "id": "1B2Q",
        "text": "Pulmonary haemorrhage originating in the perinatal period"
    },
    {
        "id": "1D15",
        "text": "Chloramphenicol toxicity in the newborn"
    },
    {
        "id": "1B1Y",
        "text": "Fitting or adjustment of devices related to nervous system or special senses"
    },
    {
        "id": "1B0P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1A2L",
        "text": "Fetus or newborn affected by vasa praevia"
    },
    {
        "id": "1A27",
        "text": "Postnatal intestinal perforation"
    },
    {
        "id": "1717",
        "text": "Delayed milestone"
    },
    {
        "id": "1D8D",
        "text": "External causes of morbidity or mortality"
    },
    {
        "id": "1D8E",
        "text": "Pheochromocytoma, malignant"
    },
    {
        "id": "1C7E",
        "text": "Aetiology"
    },
    {
        "id": "1D8G",
        "text": "Inherited cancer-predisposing syndromes"
    },
    {
        "id": "1D8I",
        "text": "Birt-Hogg-Dub\u00e9 syndrome"
    },
    {
        "id": "1D8J",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1D8K",
        "text": "Allergic rhinitis"
    },
    {
        "id": "1D8L",
        "text": "Allergic rhinitis due to specified allergens"
    },
    {
        "id": "1D8M",
        "text": "Nasal polyp"
    },
    {
        "id": "1D8O",
        "text": "Acute kidney failure"
    },
    {
        "id": "1D8P",
        "text": "Acute appendicitis"
    },
    {
        "id": "1D8Q",
        "text": "Allergic rhinitis due to specified allergens"
    },
    {
        "id": "1D8R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D8T",
        "text": "External causes of morbidity or mortality"
    },
    {
        "id": "1D8U",
        "text": "Malignant neoplasms, except primary neoplasms of lymphoid, haematopoietic, central nervous system or related tissues"
    },
    {
        "id": "1D8V",
        "text": "Hepatic fibrosis or cirrhosis"
    },
    {
        "id": "1D8W",
        "text": "Allergic rhinitis due to specified allergens"
    },
    {
        "id": "1D8X",
        "text": "Other allergic rhinitis"
    },
    {
        "id": "1D8Y",
        "text": "Allergic rhinitis due to specified allergens"
    },
    {
        "id": "1D8Z",
        "text": "Allergic rhinitis due to other seasonal allergens"
    },
    {
        "id": "1D90",
        "text": "Dementia due to hypercalcaemia"
    },
    {
        "id": "1D91",
        "text": "Dementia due to dialysis"
    },
    {
        "id": "1D92",
        "text": "Dementia due to hepatic failure"
    },
    {
        "id": "1D93",
        "text": "Dementia due to renal failure"
    },
    {
        "id": "1D94",
        "text": "Dementia due to Wilson disease"
    },
    {
        "id": "1D95",
        "text": "Dementia due to acquired hypothyroidism"
    },
    {
        "id": "1D96",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D97",
        "text": "Dementia due to Behcet disease"
    },
    {
        "id": "1D98",
        "text": "Dementia due to polyarteritis nodosa"
    },
    {
        "id": "1D99",
        "text": "Dementia due to systemic lupus erythematosus"
    },
    {
        "id": "1D9A",
        "text": "Dementia due to drug intoxication"
    },
    {
        "id": "1D9B",
        "text": "Dementia due to exposure to heavy metals and other toxins"
    },
    {
        "id": "1D9C",
        "text": "Post radiation dementia"
    },
    {
        "id": "1D9D",
        "text": "Alzheimer disease dementia, mixed type, with cerebrovascular disease"
    },
    {
        "id": "1D9E",
        "text": "Alzheimer disease dementia, mixed type, with other nonvascular aetiologies"
    },
    {
        "id": "1D9F",
        "text": "Frontotemporal dementia"
    },
    {
        "id": "1D9G",
        "text": "Frontotemporal dementia due to other or new mutations"
    },
    {
        "id": "1D9H",
        "text": "Frontotemporal dementia with familial inclusion body myopathy with Paget\u2019s disease of bone"
    },
    {
        "id": "1D9I",
        "text": "Frontotemporal dementia with motor neuron disease"
    },
    {
        "id": "1D9J",
        "text": "Frontotemporal dementia, language variant"
    },
    {
        "id": "1D9K",
        "text": "Frontotemporal dementia, non-fluent or agrammatic variant"
    },
    {
        "id": "1D9L",
        "text": "Frontotemporal dementia, semantic variant"
    },
    {
        "id": "1D9M",
        "text": "Frontotemporal dementia, logopenic variant"
    },
    {
        "id": "1D9N",
        "text": "Dementia due to Parkinson disease"
    },
    {
        "id": "1D9O",
        "text": "Primary progressive apraxia"
    },
    {
        "id": "1D9P",
        "text": "Primary progressive visual-spatial impairment"
    },
    {
        "id": "1D9Q",
        "text": "Dementia due to cerebrovascular disease"
    },
    {
        "id": "1D9R",
        "text": "Dementia due to central nervous system vasculitides"
    },
    {
        "id": "1D9S",
        "text": "Dementia due to cerebral amyloid angiopathy"
    },
    {
        "id": "1D9T",
        "text": "Dementia due to genetic causes"
    },
    {
        "id": "1D9U",
        "text": "Dementia due to hypertensive encephalopathy"
    },
    {
        "id": "1D9V",
        "text": "Dementia due to intracerebral hypertensive haemorrhage"
    },
    {
        "id": "1D9W",
        "text": "Dementia due to subcortical vascular encephalopathy"
    },
    {
        "id": "1D9X",
        "text": "Vascular dementia haemorrhagic subtype"
    },
    {
        "id": "1D9Y",
        "text": "Vascular dementia ischaemic subtype"
    },
    {
        "id": "1D9Z",
        "text": "Dementia due to hypoxic encephalopathy"
    },
    {
        "id": "1E00",
        "text": "Multi-infarct dementia"
    },
    {
        "id": "1E01",
        "text": "Single strategic infarct dementia"
    },
    {
        "id": "1E02",
        "text": "Acaeruloplasminaemia"
    },
    {
        "id": "1E03",
        "text": "Hereditary iron deficiency anaemia"
    },
    {
        "id": "1E04",
        "text": "Acquired iron deficiency anaemia"
    },
    {
        "id": "1E05",
        "text": "Acquired iron deficiency anaemia due to decreased absorption"
    },
    {
        "id": "1E06",
        "text": "Acquired iron deficiency anaemia due to increased requirement"
    },
    {
        "id": "1E08",
        "text": "Congenital megaloblastic anaemias, vitamin B12- and folate-independent"
    },
    {
        "id": "1E0A",
        "text": "Scorbutic anaemia"
    },
    {
        "id": "1E0C",
        "text": "Hereditary elliptocytosis"
    },
    {
        "id": "1E0D",
        "text": "Hereditary spherocytosis"
    },
    {
        "id": "1E0E",
        "text": "Haemoglobinuria from marching"
    },
    {
        "id": "1E0F",
        "text": "Acquired haemolytic anaemia"
    },
    {
        "id": "1E0G",
        "text": "Autoimmune haemolytic anaemia, warm type"
    },
    {
        "id": "1E0H",
        "text": "Autoimmune haemolytic anaemia, mixed type, cold and warm"
    },
    {
        "id": "1E0I",
        "text": "Autoimmune haemolytic anaemia, cold type"
    },
    {
        "id": "1E0J",
        "text": "Microangiopathic haemolytic anaemia"
    },
    {
        "id": "1E0K",
        "text": "Hereditary persistence of fetal haemoglobin, deletional"
    },
    {
        "id": "1E0L",
        "text": "Hereditary persistence of fetal haemoglobin, non-deletional"
    },
    {
        "id": "1E0M",
        "text": "Sickle cell trait"
    },
    {
        "id": "1E0N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E0O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E0P",
        "text": "Haemoglobin D disease"
    },
    {
        "id": "1E0Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E0R",
        "text": "Sickle cell disorders or other haemoglobinopathies"
    },
    {
        "id": "1E0S",
        "text": "Polycythaemia due to inappropriate erythropoietin production"
    },
    {
        "id": "1E0V",
        "text": "Hepatic failure without mention whether acute or chronic"
    },
    {
        "id": "1E0W",
        "text": "Certain specified inflammatory liver diseases"
    },
    {
        "id": "1E0Z",
        "text": "Barrett adenocarcinoma"
    },
    {
        "id": "1E0T",
        "text": "Secondary motility disorders of oesophagus"
    },
    {
        "id": "1E10",
        "text": "Infectious oesophagitis"
    },
    {
        "id": "1E12",
        "text": "Helicobacter pylori induced gastritis"
    },
    {
        "id": "1E13",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E14",
        "text": "Gastric ulcer due to diseases classified elsewhere"
    },
    {
        "id": "1E16",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E17",
        "text": "Duodenitis due to Whipple disease"
    },
    {
        "id": "1E19",
        "text": "Duodenitis due to Cytomegalovirus"
    },
    {
        "id": "1E18",
        "text": "Ancylostomiasis duodenitis"
    },
    {
        "id": "1E1A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E1B",
        "text": "Gastritis due to Cytomegalovirus"
    },
    {
        "id": "1E1C",
        "text": "Gastric candidosis"
    },
    {
        "id": "1E1D",
        "text": "Gastric anisakiasis"
    },
    {
        "id": "1E1E",
        "text": "Duodenal ulcer due to external causes"
    },
    {
        "id": "1E1G",
        "text": "Obstructive ileus of small intestine due to impaction"
    },
    {
        "id": "1E1H",
        "text": "Eosinophilic enteritis"
    },
    {
        "id": "1E1J",
        "text": "Human immunodeficiency virus disease enteritis"
    },
    {
        "id": "1E1I",
        "text": "Enteritis due to Cytomegalovirus"
    },
    {
        "id": "1E1K",
        "text": "Enteritis due to Giardia lamblia"
    },
    {
        "id": "1E1L",
        "text": "Cryptosporidial enteritis"
    },
    {
        "id": "1E1N",
        "text": "Intestinal strongyloidiasis"
    },
    {
        "id": "1E1O",
        "text": "Oesophagostomiasis"
    },
    {
        "id": "1E1P",
        "text": "Intestinal angiostrongyliasis"
    },
    {
        "id": "1E1Q",
        "text": "Megacolon"
    },
    {
        "id": "1E1R",
        "text": "Megacolon in Chagas disease"
    },
    {
        "id": "1E1S",
        "text": "Megacolon"
    },
    {
        "id": "1E1T",
        "text": "Megaoesophagus"
    },
    {
        "id": "1E1U",
        "text": "Certain noninfectious colitis or proctitis"
    },
    {
        "id": "1E1W",
        "text": "Colitis due to Cytomegalovirus"
    },
    {
        "id": "1E1X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E1Y",
        "text": "Amoeboma of large intestine"
    },
    {
        "id": "1E1Z",
        "text": "Intestinal trichomoniasis"
    },
    {
        "id": "1E20",
        "text": "Colitis due to Balantidium coli"
    },
    {
        "id": "1E21",
        "text": "Cryptosporidial colitis"
    },
    {
        "id": "1E22",
        "text": "Cystoisosporiasis of colon"
    },
    {
        "id": "1E23",
        "text": "Colonic strongyloidiasis"
    },
    {
        "id": "1E24",
        "text": "Diverticulum of appendix, acquired"
    },
    {
        "id": "1E25",
        "text": "Crohn disease of anal region"
    },
    {
        "id": "1E26",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E27",
        "text": "Crohn disease of appendix"
    },
    {
        "id": "1E0Y",
        "text": "Certain specified diseases of liver"
    },
    {
        "id": "1E1V",
        "text": "Secondary noninfectious colitis or proctitis due to other diseases, classified elsewhere"
    },
    {
        "id": "1E0X",
        "text": "Hepatic failure without mention whether acute or chronic"
    },
    {
        "id": "1E28",
        "text": "Congenital or neonatal vitamin B12 deficiency anaemia"
    },
    {
        "id": "1E2B",
        "text": "Vitamin B12 deficiency anaemia due to congenital intrinsic factor deficiency"
    },
    {
        "id": "1E2C",
        "text": "Hereditary Vitamin B12 deficiency anaemia"
    },
    {
        "id": "1E2D",
        "text": "Vitamin B12 deficiency anaemia due to selective vitamin B12 malabsorption with proteinuria"
    },
    {
        "id": "1E2E",
        "text": "Vitamin B12 deficiency anaemia due to intestinal disease"
    },
    {
        "id": "1E2F",
        "text": "Atypical haemolytic uraemic syndrome with anti-factor H antibodies"
    },
    {
        "id": "1E2G",
        "text": "Alpha thalassaemia myelodysplastic syndrome"
    },
    {
        "id": "1E2I",
        "text": "Compound HbC or beta thalassaemia heterozygotes"
    },
    {
        "id": "1E2J",
        "text": "Haemoglobin C disease"
    },
    {
        "id": "1E2K",
        "text": "Haemoglobin C trait"
    },
    {
        "id": "1E2L",
        "text": "Haemoglobin E trait"
    },
    {
        "id": "1E2M",
        "text": "Compound HbE beta thalassaemia heterozygotes"
    },
    {
        "id": "1E2P",
        "text": "Acute acquired pure red cell aplasia"
    },
    {
        "id": "1E2Q",
        "text": "Aplastic anaemia"
    },
    {
        "id": "1E2R",
        "text": "Congenital aplastic anaemia"
    },
    {
        "id": "1E2O",
        "text": "Chronic acquired pure red cell aplasia"
    },
    {
        "id": "1E2S",
        "text": "Congenital pure red cell aplasia"
    },
    {
        "id": "1E2T",
        "text": "Congenital hypoplastic anaemia"
    },
    {
        "id": "1E2U",
        "text": "Anaemia due to chronic disease"
    },
    {
        "id": "1E2V",
        "text": "Shwachman-Diamond syndrome"
    },
    {
        "id": "1E2X",
        "text": "Dyskeratosis congenita"
    },
    {
        "id": "1E2Y",
        "text": "Subluxation complex"
    },
    {
        "id": "1E2Z",
        "text": "Segmental and somatic dysfunction"
    },
    {
        "id": "1E2A",
        "text": "Congenital or neonatal vitamin B12 deficiency anaemia"
    },
    {
        "id": "1E30",
        "text": "Hereditary megaloblastic anaemia due to transcobalamin deficiency"
    },
    {
        "id": "1E31",
        "text": "Heterozygous alpha+ thalassaemia (\u2013 \u03b1/\u03b1\u03b1) (thalassaemia trait)"
    },
    {
        "id": "1E32",
        "text": "Heterozygous alpha thalassaemia (\u2013\u2013/\u03b1\u03b1\u03b1) (thalassaemia trait)"
    },
    {
        "id": "1E33",
        "text": "Heterozygous non-deletional alpha thalassaemia (\u03b1T\u03b1/\u03b1\u03b1 and \u03b1\u03b1T/\u03b1\u03b1 included)"
    },
    {
        "id": "1E34",
        "text": "Homozygous alpha+ thalassaemia (\u2013 \u03b1/\u2013 \u03b1) (thalassaemia trait)"
    },
    {
        "id": "1E35",
        "text": "Haemoglobin H disease (\u2013 \u03b1/\u2013 \u2013 included)"
    },
    {
        "id": "1E2H",
        "text": "Delta, delta-beta or gamma-delta-beta thalassaemia"
    },
    {
        "id": "1E36",
        "text": "Other haemoglobinopathies"
    },
    {
        "id": "1E37",
        "text": "Von Willebrand disease"
    },
    {
        "id": "1E38",
        "text": "Hereditary thrombophilia"
    },
    {
        "id": "1E3B",
        "text": "fetal or neonatal thrombocytopenia due to maternal idiopathic thrombocytopenic purpura"
    },
    {
        "id": "1E3C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E3D",
        "text": "Secondary thrombocytopenic purpura"
    },
    {
        "id": "1E3E",
        "text": "Heparin-induced thrombocytopenia"
    },
    {
        "id": "1E3F",
        "text": "Congenital alpha-2 antiplasmin deficiency"
    },
    {
        "id": "1E3G",
        "text": "Congenital plasminogen activator inhibitor type 1 deficiency"
    },
    {
        "id": "1E3H",
        "text": "Hernias"
    },
    {
        "id": "1E3J",
        "text": "Haemoglobin Constant Spring"
    },
    {
        "id": "1E3K",
        "text": "Thermal injury of oesophagus"
    },
    {
        "id": "1E3L",
        "text": "Erythema ab igne"
    },
    {
        "id": "1E3M",
        "text": "Drug-induced oral lichenoid reaction"
    },
    {
        "id": "1E3N",
        "text": "Epidermolysis bullosa simplex, localised"
    },
    {
        "id": "1E3O",
        "text": "Localised hyperhidrosis due to focal neurological disorder"
    },
    {
        "id": "1E3P",
        "text": "Localised hyperhidrosis of other specified site"
    },
    {
        "id": "1E3Q",
        "text": "Oesophagitis due to external causes"
    },
    {
        "id": "1E3R",
        "text": "Haemorrhoids"
    },
    {
        "id": "1E3S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E3T",
        "text": "Microscopic colitis"
    },
    {
        "id": "1E3W",
        "text": "Epithelial neoplasms, benign"
    },
    {
        "id": "1E3X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E3Y",
        "text": "Functional gallbladder disorder"
    },
    {
        "id": "1E40",
        "text": "Functional gastrointestinal disorders"
    },
    {
        "id": "1E41",
        "text": "Pancreas divisum"
    },
    {
        "id": "1E42",
        "text": "Cystic, mucinous and serous neoplasms, malignant"
    },
    {
        "id": "1E43",
        "text": "Ductal and lobular neoplasms, malignant"
    },
    {
        "id": "1E44",
        "text": "Ductal and lobular neoplasms, benign"
    },
    {
        "id": "1E45",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E46",
        "text": "Acute drug-induced pancreatitis"
    },
    {
        "id": "1E47",
        "text": "Acute pancreatitis"
    },
    {
        "id": "1E48",
        "text": "Other specified forms of asthma or bronchospasm"
    },
    {
        "id": "1E49",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E4A",
        "text": "Thoracic aortic aneurysm"
    },
    {
        "id": "1E4E",
        "text": "Pheochromocytoma, malignant"
    },
    {
        "id": "1E4F",
        "text": "Malignant phaeochromocytoma of adrenal gland"
    },
    {
        "id": "1E4H",
        "text": "Samter syndrome"
    },
    {
        "id": "1E4I",
        "text": "Allergic or hypersensitivity reactions to arthropods"
    },
    {
        "id": "1E4J",
        "text": "Systemic allergic reaction due to Hymenoptera venom"
    },
    {
        "id": "1E4G",
        "text": "Digestive system disorders of fetus or newborn"
    },
    {
        "id": "1E4K",
        "text": "Meconium plug without ileus"
    },
    {
        "id": "1D8H",
        "text": "Familial renal cell carcinoma"
    },
    {
        "id": "1E4M",
        "text": "Symptom or complaint of other male genital organs"
    },
    {
        "id": "1E4N",
        "text": "Borrelia"
    },
    {
        "id": "1E4O",
        "text": "Von Willebrand disease"
    },
    {
        "id": "1E4P",
        "text": "Inherited qualitative platelet defects"
    },
    {
        "id": "1E4Q",
        "text": "Acquired factor V deficiency"
    },
    {
        "id": "1E4R",
        "text": "Lupus anticoagulant-hypoprothrombinaemia syndrome"
    },
    {
        "id": "1E4S",
        "text": "Hereditary thrombophilia"
    },
    {
        "id": "1E4T",
        "text": "Hereditary thrombophilia due to prothrombin gene"
    },
    {
        "id": "1E4U",
        "text": "Gaisbock syndrome"
    },
    {
        "id": "1E4W",
        "text": "Secondary haemophagocytic lymphohistiocytosis"
    },
    {
        "id": "1E4X",
        "text": "Primary haemophagocytic lymphohistiocytosis"
    },
    {
        "id": "1E4Y",
        "text": "Acquired thrombocytosis"
    },
    {
        "id": "1E51",
        "text": "Dermatomyositis"
    },
    {
        "id": "1E52",
        "text": "Uraemic pruritus"
    },
    {
        "id": "1E53",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E54",
        "text": "Cutaneous markers of musculoskeletal disorders"
    },
    {
        "id": "1E55",
        "text": "Right isomerism"
    },
    {
        "id": "1E56",
        "text": "Left isomerism"
    },
    {
        "id": "1E57",
        "text": "Abortive outcome of pregnancy"
    },
    {
        "id": "1E58",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E59",
        "text": "Nontoxic goitre"
    },
    {
        "id": "1E5A",
        "text": "Acquired hearing impairment"
    },
    {
        "id": "1E5B",
        "text": "Acquired hearing impairment"
    },
    {
        "id": "1E5C",
        "text": "Acquired conductive hearing loss"
    },
    {
        "id": "1E5D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E5E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E5F",
        "text": "Acquired sensorineural hearing loss"
    },
    {
        "id": "1E5H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E5K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "122X",
        "text": "Disorders with hearing impairment"
    },
    {
        "id": "1E5L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E5M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E5N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E5O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E5P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E5Q",
        "text": "Tumours of cranial or paraspinal nerves"
    },
    {
        "id": "1E5R",
        "text": "Malignant neoplasms of adrenal gland"
    },
    {
        "id": "1E5S",
        "text": "Central primitive neuroectodermal tumour"
    },
    {
        "id": "1E5T",
        "text": "Brain neuroblastoma"
    },
    {
        "id": "1E5U",
        "text": "Central primitive neuroectodermal tumour"
    },
    {
        "id": "1E5V",
        "text": "Gliomas, malignant"
    },
    {
        "id": "1E5X",
        "text": "Monocytopenia with susceptibility to infections"
    },
    {
        "id": "1E5Z",
        "text": "neutropaenia associated with T-cell large granular lymphocyte leukaemia"
    },
    {
        "id": "1E60",
        "text": "Acquired neutropaenia"
    },
    {
        "id": "1E61",
        "text": "Acquired neutrophilia"
    },
    {
        "id": "1E62",
        "text": "Cryoglobulinaemia"
    },
    {
        "id": "1E63",
        "text": "Idiopathic hypereosinophilic syndrome"
    },
    {
        "id": "1E64",
        "text": "Other specified lymphocytosis"
    },
    {
        "id": "1E65",
        "text": "Lymphocytosis"
    },
    {
        "id": "1E69",
        "text": "Abnormalities of breathing"
    },
    {
        "id": "1E6A",
        "text": "Substances"
    },
    {
        "id": "1E5Y",
        "text": "Familial haemophagocytic lymphohistiocytosis"
    },
    {
        "id": "1E6B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E6C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E6D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E6E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E5W",
        "text": "Atrophy of spleen"
    },
    {
        "id": "1E6F",
        "text": "Immunodeficiency with a late component of complement deficiency"
    },
    {
        "id": "1E6G",
        "text": "Defects in the complement system"
    },
    {
        "id": "1E6H",
        "text": "Defects in the complement system"
    },
    {
        "id": "1E6I",
        "text": "Nonfamilial nongenetic cystic kidney disease"
    },
    {
        "id": "1E6J",
        "text": "Solitary simple renal cyst"
    },
    {
        "id": "1E6K",
        "text": "Multiple simple renal cysts"
    },
    {
        "id": "1E6L",
        "text": "Solitary loculated renal cyst"
    },
    {
        "id": "1E6M",
        "text": "Multiple loculated renal cysts"
    },
    {
        "id": "1E6N",
        "text": "Solitary complex renal cyst"
    },
    {
        "id": "1E6O",
        "text": "Multiple complex renal cysts"
    },
    {
        "id": "1E6P",
        "text": "Cyst adjacent to renal pelvis"
    },
    {
        "id": "1E6Q",
        "text": "Renal parapelvic cyst"
    },
    {
        "id": "1E6R",
        "text": "Renal peripelvic cyst"
    },
    {
        "id": "1E6S",
        "text": "Hereditary cystic or dysplastic kidney disease, dominant inheritance"
    },
    {
        "id": "1E6T",
        "text": "Familial hyperuricaemic nephropathy"
    },
    {
        "id": "1E6U",
        "text": "Autosomal dominant polycystic kidney disease, Type 2"
    },
    {
        "id": "1E6W",
        "text": "Autosomal dominant tubulointerstitial disease, Type 1"
    },
    {
        "id": "1E6Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E70",
        "text": "Familial juvenile hyperuricaemic nephropathy"
    },
    {
        "id": "1E6Y",
        "text": "Autosomal dominant tubulointerstitial disease Type 2"
    },
    {
        "id": "1E6V",
        "text": "Autosomal dominant tubulointerstitial disease"
    },
    {
        "id": "1E71",
        "text": "MODY 5 syndrome"
    },
    {
        "id": "1E72",
        "text": "MODY 5 syndrome"
    },
    {
        "id": "1E73",
        "text": "Food hypersensitivity"
    },
    {
        "id": "1E75",
        "text": "Vasculitis associated with systemic disease"
    },
    {
        "id": "1E76",
        "text": "Thrombotic microangiopathy, not elsewhere classified"
    },
    {
        "id": "1E77",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E78",
        "text": "Postprocedural aortic valve insufficiency"
    },
    {
        "id": "1E79",
        "text": "Familial-genetic dilated cardiomyopathy"
    },
    {
        "id": "1E7A",
        "text": "Nonfamilial dilated cardiomyopathy"
    },
    {
        "id": "1E7C",
        "text": "Arrhythmogenic ventricular cardiomyopathy"
    },
    {
        "id": "1E7D",
        "text": "Nonfamilial hypertrophic cardiomyopathy"
    },
    {
        "id": "1E7E",
        "text": "Pulmonary arterial hypertension induced by drugs or toxins"
    },
    {
        "id": "1E7F",
        "text": "Congenital anomaly of position or spatial relationships of thoraco-abdominal organs"
    },
    {
        "id": "1E7G",
        "text": "Abnormal ventricular relationships"
    },
    {
        "id": "1E7H",
        "text": "Congenital anomaly of an atrioventricular or ventriculo-arterial connection"
    },
    {
        "id": "1E7I",
        "text": "Congenitally corrected transposition of great arteries"
    },
    {
        "id": "1E7J",
        "text": "Discordant atrioventricular connections"
    },
    {
        "id": "1E7K",
        "text": "Double outlet right ventricle with subaortic or doubly committed ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "1E7L",
        "text": "Double outlet right ventricle with subpulmonary ventricular septal defect, transposition type"
    },
    {
        "id": "1E7M",
        "text": "Double outlet right ventricle with non-committed ventricular septal defect"
    },
    {
        "id": "1E7N",
        "text": "Double outlet right ventricle with subaortic or doubly committed ventricular septal defect without pulmonary stenosis, ventricular septal defect type"
    },
    {
        "id": "1E7O",
        "text": "Common arterial trunk"
    },
    {
        "id": "1E7P",
        "text": "Common arterial trunk with aortic dominance"
    },
    {
        "id": "1E7Q",
        "text": "Common arterial trunk with pulmonary dominance and interrupted aortic arch"
    },
    {
        "id": "1E7R",
        "text": "Interrupted inferior caval vein with absent suprarenal segment and azygos continuation"
    },
    {
        "id": "1E7S",
        "text": "Unroofed coronary sinus"
    },
    {
        "id": "1E7U",
        "text": "Partial anomalous pulmonary venous connection"
    },
    {
        "id": "1E7T",
        "text": "Total anomalous pulmonary venous connection"
    },
    {
        "id": "1E7V",
        "text": "Scimitar syndrome"
    },
    {
        "id": "1E7W",
        "text": "Congenital atresia of pulmonary vein"
    },
    {
        "id": "1E7X",
        "text": "Atrial septal defect within oval fossa"
    },
    {
        "id": "1E7Y",
        "text": "Sinus venosus defect"
    },
    {
        "id": "1E7Z",
        "text": "Divided left atrium"
    },
    {
        "id": "1E80",
        "text": "Congenital tricuspid regurgitation"
    },
    {
        "id": "1E81",
        "text": "Dysplasia of tricuspid valve"
    },
    {
        "id": "1E82",
        "text": "Congenital anomaly of mitral subvalvar apparatus"
    },
    {
        "id": "1E83",
        "text": "Atrioventricular septal defect with ventricular imbalance"
    },
    {
        "id": "1E84",
        "text": "Atrioventricular septal defect with communication at the atrial level only"
    },
    {
        "id": "1E85",
        "text": "Atrioventricular septal defect with communication at the ventricular level only"
    },
    {
        "id": "1E86",
        "text": "Atrioventricular septal defect with communication at atrial level and unrestrictive communication at ventricular level"
    },
    {
        "id": "1E87",
        "text": "Atrioventricular septal defect and tetralogy of Fallot"
    },
    {
        "id": "1E88",
        "text": "Atypical common atrioventricular valve"
    },
    {
        "id": "1E89",
        "text": "Congenital right ventricular outflow tract obstruction"
    },
    {
        "id": "1E8A",
        "text": "Double chambered right ventricle"
    },
    {
        "id": "1E8B",
        "text": "Tetralogy of Fallot with absent pulmonary valve syndrome"
    },
    {
        "id": "1E8C",
        "text": "Tetralogy of Fallot with pulmonary atresia and systemic-to-pulmonary collateral artery"
    },
    {
        "id": "1E8D",
        "text": "Tetralogy of Fallot with pulmonary atresia"
    },
    {
        "id": "1E8E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D6C",
        "text": "Perimembranous central ventricular septal defect"
    },
    {
        "id": "1E8F",
        "text": "Inlet ventricular septal defect without a common atrioventricular junction"
    },
    {
        "id": "1E8G",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment"
    },
    {
        "id": "1D76",
        "text": "Multiple ventricular septal defects"
    },
    {
        "id": "1E8H",
        "text": "Functionally univentricular heart"
    },
    {
        "id": "1E8I",
        "text": "Congenital subpulmonary stenosis"
    },
    {
        "id": "1E8J",
        "text": "Pulmonary atresia with intact ventricular septum"
    },
    {
        "id": "1E8K",
        "text": "Congenital aortic regurgitation"
    },
    {
        "id": "1E8L",
        "text": "Bicuspid aortic valve"
    },
    {
        "id": "1E8M",
        "text": "Bicuspid aortic valve"
    },
    {
        "id": "1D6Q",
        "text": "Aortic valvar prolapse"
    },
    {
        "id": "1E8N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E8O",
        "text": "Aneurysm of aortic sinus of Valsalva"
    },
    {
        "id": "1E8P",
        "text": "Congenital central pulmonary arterial stenosis or hypoplasia proximal to hilar bifurcation"
    },
    {
        "id": "1E8Q",
        "text": "Congenital peripheral pulmonary arterial stenosis or hypoplasia at or beyond hilar bifurcation"
    },
    {
        "id": "1E8R",
        "text": "Congenital peripheral pulmonary arterial stenosis or hypoplasia at or beyond hilar bifurcation"
    },
    {
        "id": "1E8S",
        "text": "Congenital ascending aorta aneurysm or dilation"
    },
    {
        "id": "1E8T",
        "text": "Interrupted aortic arch"
    },
    {
        "id": "1E8U",
        "text": "Aberrant origin of left subclavian artery"
    },
    {
        "id": "1E8V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D71",
        "text": "Vascular ring"
    },
    {
        "id": "1E8W",
        "text": "Anomalous origin of coronary artery from pulmonary arterial tree"
    },
    {
        "id": "1E8Y",
        "text": "Anomalous aortic origin or course of coronary artery"
    },
    {
        "id": "1E8Z",
        "text": "Congenital coronary arterial fistula"
    },
    {
        "id": "1E90",
        "text": "Developmental capillary vascular malformations of the skin"
    },
    {
        "id": "1E91",
        "text": "Complex or combined developmental vascular malformations involving the skin"
    },
    {
        "id": "1E95",
        "text": "Acute tubulo-interstitial nephritis"
    },
    {
        "id": "1E96",
        "text": "Main resource condition"
    },
    {
        "id": "1E07",
        "text": "Hereditary megaloblastic anaemia due to transcobalamin deficiency"
    },
    {
        "id": "1F0O",
        "text": "Orf"
    },
    {
        "id": "1F0Q",
        "text": "Flavivirus"
    },
    {
        "id": "1F0P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E92",
        "text": "Histiocytic or dendritic cell neoplasms"
    },
    {
        "id": "1F0R",
        "text": "Acute appendicitis with generalised peritonitis"
    },
    {
        "id": "1F0S",
        "text": "Acute appendicitis with localised peritonitis"
    },
    {
        "id": "1F0V",
        "text": "Congenital erythropoietic porphyria"
    },
    {
        "id": "1F0W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F0X",
        "text": "Pseudoporphyria due to excessive exposure to UV radiation"
    },
    {
        "id": "1F0Y",
        "text": "PUVA-associated pseudoporphyria"
    },
    {
        "id": "1F0Z",
        "text": "Malignant neuroendocrine neoplasm of stomach"
    },
    {
        "id": "1F10",
        "text": "Tuberculosis complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "1F11",
        "text": "Syphilis complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "1F12",
        "text": "Maternal infectious diseases classifiable elsewhere but complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "1F13",
        "text": "Asthmatic pulmonary eosinophilia"
    },
    {
        "id": "1F14",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F15",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F16",
        "text": "Diseases of the visual system"
    },
    {
        "id": "1F17",
        "text": "Disorders of the visual organs"
    },
    {
        "id": "1F18",
        "text": "Disorders of vision or visual functioning"
    },
    {
        "id": "1F19",
        "text": "Visual disorientation syndrome"
    },
    {
        "id": "1F1A",
        "text": "Bradyopsia"
    },
    {
        "id": "1F1B",
        "text": "Polyopia"
    },
    {
        "id": "1F1D",
        "text": "Impairment of visual field"
    },
    {
        "id": "1F1C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F1E",
        "text": "Patterns of visual field impairment"
    },
    {
        "id": "1F1F",
        "text": "Visual field loss, pattern not specified"
    },
    {
        "id": "1F1G",
        "text": "Peripheral field deficit"
    },
    {
        "id": "1F1H",
        "text": "Enlarged blind spot"
    },
    {
        "id": "1F1I",
        "text": "Arcuate scotoma"
    },
    {
        "id": "1F1J",
        "text": "Nasal step"
    },
    {
        "id": "1F1K",
        "text": "Ring scotoma"
    },
    {
        "id": "1F1L",
        "text": "Isolated peripheral scotoma"
    },
    {
        "id": "1F1M",
        "text": "Hemianopic or quadrantic loss"
    },
    {
        "id": "1F1N",
        "text": "Central scotoma"
    },
    {
        "id": "1F1O",
        "text": "Para-central scotoma"
    },
    {
        "id": "1F1P",
        "text": "Homonymous hemianopia or quadrant anopia"
    },
    {
        "id": "1F1Q",
        "text": "Heteronymous hemianopia or quadrant anopia"
    },
    {
        "id": "1F1R",
        "text": "Acute atopic conjunctivitis"
    },
    {
        "id": "1F1S",
        "text": "Photoallergic drug reaction"
    },
    {
        "id": "1F1T",
        "text": "Impairment of contrast vision"
    },
    {
        "id": "1F1U",
        "text": "Impairment of colour vision"
    },
    {
        "id": "1F1V",
        "text": "Protanomaly"
    },
    {
        "id": "1F1W",
        "text": "Deuteranomaly"
    },
    {
        "id": "1F1X",
        "text": "Tritanomaly"
    },
    {
        "id": "1F1Y",
        "text": "Protanopia"
    },
    {
        "id": "1F1Z",
        "text": "Deuteranopia"
    },
    {
        "id": "1F20",
        "text": "Tritanopia"
    },
    {
        "id": "1F21",
        "text": "Impairment of colour vision"
    },
    {
        "id": "1E8X",
        "text": "Anomalous origin of coronary artery from pulmonary arterial tree"
    },
    {
        "id": "1F22",
        "text": "Impairment of colour vision"
    },
    {
        "id": "1F23",
        "text": "Impairment of dark adaptation"
    },
    {
        "id": "1F24",
        "text": "Congenital night blindness"
    },
    {
        "id": "1F25",
        "text": "Photophobia"
    },
    {
        "id": "1F26",
        "text": "Day blindness"
    },
    {
        "id": "1F27",
        "text": "Glare sensitivity"
    },
    {
        "id": "1F28",
        "text": "Impairment of stereopsis, degree not specified"
    },
    {
        "id": "1F29",
        "text": "Impairment of fusion, degree not specified"
    },
    {
        "id": "1F2A",
        "text": "Abnormal retinal correspondence"
    },
    {
        "id": "1F2B",
        "text": "Subjective visual experiences"
    },
    {
        "id": "1F2C",
        "text": "Entoptic phenomena"
    },
    {
        "id": "1F2D",
        "text": "Photopsia"
    },
    {
        "id": "1F2E",
        "text": "Visual floaters"
    },
    {
        "id": "1F2F",
        "text": "Halos"
    },
    {
        "id": "1F2G",
        "text": "Metamorphopsia, distortions"
    },
    {
        "id": "1F2H",
        "text": "Monocular diplopia"
    },
    {
        "id": "1F2I",
        "text": "Dyschromatopsia"
    },
    {
        "id": "1F2J",
        "text": "Visual illusions"
    },
    {
        "id": "1F2K",
        "text": "Micropsia"
    },
    {
        "id": "1F2L",
        "text": "Macropsia"
    },
    {
        "id": "1F2M",
        "text": "Oscillopsia"
    },
    {
        "id": "1F2N",
        "text": "Pulfrich stereo illusion"
    },
    {
        "id": "1F2O",
        "text": "Normal electrooculogram"
    },
    {
        "id": "1F2P",
        "text": "Normal electroretinogram"
    },
    {
        "id": "1F2Q",
        "text": "Normal multifocal electroretinogram"
    },
    {
        "id": "1F2R",
        "text": "Normal Visually Evoked Potential"
    },
    {
        "id": "1F2S",
        "text": "Normal Electromyogram"
    },
    {
        "id": "1F2T",
        "text": "Histopathology"
    },
    {
        "id": "1F2U",
        "text": "Bowen disease"
    },
    {
        "id": "1F2V",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1F2W",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1F2X",
        "text": "Personal history of health problems"
    },
    {
        "id": "1F2Y",
        "text": "Vision impairment"
    },
    {
        "id": "1F2Z",
        "text": "Vision impairment including blindness"
    },
    {
        "id": "1F30",
        "text": "No vision impairment"
    },
    {
        "id": "1F32",
        "text": "Low vision"
    },
    {
        "id": "1F31",
        "text": "Blindness"
    },
    {
        "id": "1F33",
        "text": "Vision impairment"
    },
    {
        "id": "1F35",
        "text": "Specific vision dysfunctions"
    },
    {
        "id": "1F34",
        "text": "Visual spatial neglect"
    },
    {
        "id": "1F36",
        "text": "Prosopagnosia"
    },
    {
        "id": "1F37",
        "text": "Simultanagnosia"
    },
    {
        "id": "1F38",
        "text": "Alexia"
    },
    {
        "id": "1F39",
        "text": "Complex vision-related dysfunctions"
    },
    {
        "id": "1F3A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3K",
        "text": "Presence of epiretinal implant"
    },
    {
        "id": "1F3L",
        "text": "Presence of subretinal implant"
    },
    {
        "id": "1F3J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F3O",
        "text": "Dysfunction of visual reading ability"
    },
    {
        "id": "1F3P",
        "text": "Dysfunctions of orientation or mobility"
    },
    {
        "id": "1F3C",
        "text": "Vision impairment"
    },
    {
        "id": "1F3B",
        "text": "Vision impairment"
    },
    {
        "id": "1F3Q",
        "text": "Sickle cell disease without crisis"
    },
    {
        "id": "1F3R",
        "text": "Sickle cell disease with crisis"
    },
    {
        "id": "1F3T",
        "text": "Sickle cell thalassaemia with crisis"
    },
    {
        "id": "1F3U",
        "text": "Sickle cell Hb-C disease with crisis"
    },
    {
        "id": "1F3S",
        "text": "Compound heterozygous sickling disorders without crisis"
    },
    {
        "id": "1F3V",
        "text": "Congenital methaemoglobinaemia"
    },
    {
        "id": "1F3W",
        "text": "Hereditary methaemoglobinaemia"
    },
    {
        "id": "1E3I",
        "text": "Sickle cell disorders or other haemoglobinopathies"
    },
    {
        "id": "1F3X",
        "text": "Hereditary angioedema type III"
    },
    {
        "id": "1F3Y",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "1F3Z",
        "text": "Polymyositis"
    },
    {
        "id": "1F40",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F41",
        "text": "Exostosis of jaw"
    },
    {
        "id": "1F42",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F43",
        "text": "Certain specified diseases of jaws"
    },
    {
        "id": "1F45",
        "text": "Osteonecrosis"
    },
    {
        "id": "1F46",
        "text": "Alveolitis of jaw"
    },
    {
        "id": "1F47",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F48",
        "text": "Septic osteonecrosis"
    },
    {
        "id": "1F49",
        "text": "Intractable diarrhoea - choanal atresia - eye anomalies"
    },
    {
        "id": "1F4B",
        "text": "Hypertensive diseases"
    },
    {
        "id": "1F4C",
        "text": "White coat hypertension"
    },
    {
        "id": "1F4D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F4E",
        "text": "Verrucous haemangioma"
    },
    {
        "id": "1F4F",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1F4H",
        "text": "Paraneoplastic dermatomyositis"
    },
    {
        "id": "1F4J",
        "text": "Bruise or contusion of skin"
    },
    {
        "id": "1F4K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F4L",
        "text": "Traumatic ulceration of skin"
    },
    {
        "id": "1F4M",
        "text": "Crush injury of skin"
    },
    {
        "id": "1F4N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F4O",
        "text": "Traumatic scar of skin"
    },
    {
        "id": "1F4P",
        "text": "Surgical wound of skin"
    },
    {
        "id": "1F4S",
        "text": "Haematoma of surgical wound of skin"
    },
    {
        "id": "1F4T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F4U",
        "text": "Unsatisfactory surgical scar of skin"
    },
    {
        "id": "1F4V",
        "text": "Keloidal surgical scar"
    },
    {
        "id": "1F4W",
        "text": "Hypertrophic surgical scar"
    },
    {
        "id": "1F4X",
        "text": "Atrophic surgical scar"
    },
    {
        "id": "1F4Y",
        "text": "Expanded surgical scar"
    },
    {
        "id": "1F4Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F50",
        "text": "Complications of cutaneous flaps or grafts"
    },
    {
        "id": "1F51",
        "text": "Cutaneous flap necrosis"
    },
    {
        "id": "1F52",
        "text": "Cutaneous flap necrosis, partial"
    },
    {
        "id": "1F53",
        "text": "Cutaneous flap necrosis, total"
    },
    {
        "id": "1F54",
        "text": "Myocutaneous flap necrosis"
    },
    {
        "id": "1F55",
        "text": "Myocutaneous flap necrosis, partial"
    },
    {
        "id": "1F56",
        "text": "Myocutaneous flap necrosis, total"
    },
    {
        "id": "1F57",
        "text": "Skin graft failure"
    },
    {
        "id": "1F58",
        "text": "Composite graft failure"
    },
    {
        "id": "1F59",
        "text": "Full thickness skin graft failure"
    },
    {
        "id": "1F5A",
        "text": "Miscellaneous complications of cutaneous surgical procedures"
    },
    {
        "id": "1F5B",
        "text": "Anatomical distortion of skin or soft tissues following cutaneous surgical procedure"
    },
    {
        "id": "1F5D",
        "text": "Temporary cutaneous sensory disturbance resulting from cutaneous surgical procedure"
    },
    {
        "id": "1F5E",
        "text": "Permanent cutaneous sensory impairment resulting from cutaneous surgical procedure"
    },
    {
        "id": "1F5F",
        "text": "Impairment of motor nerve function resulting from cutaneous surgical procedure"
    },
    {
        "id": "1F5M",
        "text": "Parastomal Crohn disease"
    },
    {
        "id": "1F5O",
        "text": "Stoma-related chronic papillomatous dermatitis"
    },
    {
        "id": "1F5S",
        "text": "Neonatal phototherapy burn"
    },
    {
        "id": "1F5T",
        "text": "Phototherapy burn"
    },
    {
        "id": "1F61",
        "text": "Adverse effects of UVB phototherapy"
    },
    {
        "id": "1F62",
        "text": "Adverse cutaneous effects of therapeutic ionizing irradiation"
    },
    {
        "id": "1F5R",
        "text": "Adverse effects of psoralen photochemotherapy"
    },
    {
        "id": "1F5Q",
        "text": "Adverse effects of UVB phototherapy"
    },
    {
        "id": "1F66",
        "text": "Phototherapy burn"
    },
    {
        "id": "1F67",
        "text": "PUVA burn"
    },
    {
        "id": "1F5W",
        "text": "PUVA lentiginosis"
    },
    {
        "id": "1F5X",
        "text": "PUVA keratoses"
    },
    {
        "id": "1F5Y",
        "text": "PUVA-associated malignancy classified elsewhere"
    },
    {
        "id": "1F5P",
        "text": "Adverse effects of phototherapy"
    },
    {
        "id": "1F5N",
        "text": "Irritant contact dermatitis related to stoma or fistula"
    },
    {
        "id": "1F5I",
        "text": "Thermal burns resulting from cutaneous laser surgery"
    },
    {
        "id": "1F5J",
        "text": "Infection specified elsewhere following cutaneous laser surgery"
    },
    {
        "id": "1F5K",
        "text": "Dyspigmentation following cutaneous laser surgery"
    },
    {
        "id": "1F68",
        "text": "Cutaneous complications of surgical, laser or other interventional procedures"
    },
    {
        "id": "1F5H",
        "text": "Cutaneous complications of laser surgery"
    },
    {
        "id": "1F5G",
        "text": "Cutaneous infection specified elsewhere resulting from surgical procedure"
    },
    {
        "id": "1F5C",
        "text": "Cicatricial contracture of skin or soft tissues following cutaneous surgical procedure"
    },
    {
        "id": "1F4R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F4Q",
        "text": "Surgical site infection"
    },
    {
        "id": "1F4I",
        "text": "Anti-synthetase syndrome with dermatomyositis"
    },
    {
        "id": "1F69",
        "text": "Hypertensive ischaemic leg ulcer"
    },
    {
        "id": "1F6A",
        "text": "Non-atherosclerotic chronic arterial occlusive disease"
    },
    {
        "id": "1F6B",
        "text": "Arterial fibromuscular dysplasia"
    },
    {
        "id": "1F6D",
        "text": "Haemodialysis-associated pseudoporphyria"
    },
    {
        "id": "1F6E",
        "text": "Haemodialysis-associated pruritus"
    },
    {
        "id": "1F6C",
        "text": "Dermatoses associated with haemodialysis"
    },
    {
        "id": "1F6G",
        "text": "Nephrogenic systemic fibrosis"
    },
    {
        "id": "1F6H",
        "text": "Tuberculous peripheral lymphadenopathy"
    },
    {
        "id": "1F6I",
        "text": "Tuberculosis of eye"
    },
    {
        "id": "1F6J",
        "text": "Lupus vulgaris"
    },
    {
        "id": "1F6K",
        "text": "Cutaneous tuberculosis"
    },
    {
        "id": "1F6L",
        "text": "Tuberculous chancre"
    },
    {
        "id": "1F6M",
        "text": "Warty tuberculosis"
    },
    {
        "id": "1F6O",
        "text": "Periorificial tuberculosis"
    },
    {
        "id": "1F6P",
        "text": "Tuberculosis of male urethra or penis"
    },
    {
        "id": "1F6N",
        "text": "Scrofuloderma"
    },
    {
        "id": "1E5I",
        "text": "Acquired mixed conductive and sensorineural hearing loss"
    },
    {
        "id": "1F6R",
        "text": "Tuberculous ulceration of vulva"
    },
    {
        "id": "1F6S",
        "text": "Tuberculosis of anal canal"
    },
    {
        "id": "1F6T",
        "text": "Metastatic tuberculous abscess"
    },
    {
        "id": "1F6U",
        "text": "Acute miliary cutaneous tuberculosis"
    },
    {
        "id": "1F6W",
        "text": "Lichen scrofulosorum"
    },
    {
        "id": "1F6X",
        "text": "Papulonecrotic tuberculid"
    },
    {
        "id": "1F6Y",
        "text": "Erythema induratum of Bazin"
    },
    {
        "id": "1F6V",
        "text": "Tuberculids"
    },
    {
        "id": "1F6Z",
        "text": "Cutaneous myiasis"
    },
    {
        "id": "1F70",
        "text": "Cutaneous myiasis"
    },
    {
        "id": "1F72",
        "text": "Personal history of allergy, other than to drugs or biological substances"
    },
    {
        "id": "1F71",
        "text": "Genital ulcer of vulva"
    },
    {
        "id": "1F73",
        "text": "Personal history of allergy, other than to drugs or biological substances"
    },
    {
        "id": "1F76",
        "text": "Personal history of allergy, other than to drugs or biological substances"
    },
    {
        "id": "1F75",
        "text": "Perianal threadworm dermatosis"
    },
    {
        "id": "1F77",
        "text": "Enterobiasis"
    },
    {
        "id": "1825",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F7A",
        "text": "Anaphylaxis due to radiocontrast media"
    },
    {
        "id": "1F7C",
        "text": "Adverse cutaneous reactions to parenteral administration of proteins"
    },
    {
        "id": "1F7H",
        "text": "Grade 4"
    },
    {
        "id": "1F7E",
        "text": "Grade 1"
    },
    {
        "id": "1F7F",
        "text": "Grade 2"
    },
    {
        "id": "1F7G",
        "text": "Grade 3"
    },
    {
        "id": "1F7J",
        "text": "Anaphylaxis due to radiocontrast media"
    },
    {
        "id": "1F7K",
        "text": "Cutaneous larva migrans"
    },
    {
        "id": "1F7D",
        "text": "Serum sickness vasculitis"
    },
    {
        "id": "1F7L",
        "text": "Necrosis or ulceration due to extravasation of drug into the skin"
    },
    {
        "id": "1F7O",
        "text": "Allergic contact sensitisation to topical medicaments"
    },
    {
        "id": "1F7P",
        "text": "Dermatitis or eczema of female genitalia"
    },
    {
        "id": "1823",
        "text": "Postprocedural disorders of the skin"
    },
    {
        "id": "1F7Q",
        "text": "Drug eruptions"
    },
    {
        "id": "1F7R",
        "text": "Drug-associated immune complex vasculitis"
    },
    {
        "id": "1F7S",
        "text": "Adverse reaction to dermal or deep fillers"
    },
    {
        "id": "1F7T",
        "text": "Cutaneous necrosis following injection of filler"
    },
    {
        "id": "1F7W",
        "text": "Adverse reaction to dermal or deep fillers"
    },
    {
        "id": "1F7U",
        "text": "Foreign body granulomatous reaction to injected filler"
    },
    {
        "id": "1F7V",
        "text": "Serious adverse reaction to injection of filler"
    },
    {
        "id": "1F7Y",
        "text": "Adverse reaction to chemical peel"
    },
    {
        "id": "1F80",
        "text": "Unsatisfactory outcome from cutaneous cosmetic surgical procedure"
    },
    {
        "id": "1F5L",
        "text": "Unsatisfactory outcome from cosmetic laser surgery"
    },
    {
        "id": "1F81",
        "text": "Specified cutaneous complications of cosmetic procedures"
    },
    {
        "id": "1F82",
        "text": "Cutaneous infection specified elsewhere following cosmetic procedure"
    },
    {
        "id": "1F83",
        "text": "Hypomelanosis resulting from cosmetic procedure"
    },
    {
        "id": "1F84",
        "text": "Dyspigmentation resulting from cosmetic procedure"
    },
    {
        "id": "1F85",
        "text": "Fibrosis or scarring following cosmetic procedure"
    },
    {
        "id": "1F87",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F88",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F89",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F8A",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F8B",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F8C",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F8D",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F8E",
        "text": "Personal history of allergy, other than to drugs or biological substances"
    },
    {
        "id": "1F8G",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F8H",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F8I",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1F8J",
        "text": "Personal history of allergy, other than to drugs or biological substances"
    },
    {
        "id": "1F8K",
        "text": "Personal history of allergy, other than to drugs or biological substances"
    },
    {
        "id": "1F8L",
        "text": "Localised adverse cutaneous reactions to administration of drug"
    },
    {
        "id": "1F7M",
        "text": "Allergic contact dermatitis due to topical medicaments"
    },
    {
        "id": "1F7N",
        "text": "Allergic contact dermatitis due to systemic medicaments"
    },
    {
        "id": "1F4G",
        "text": "Choledochal cyst"
    },
    {
        "id": "1F8M",
        "text": "Injury or harm arising from surgical or medical care, not elsewhere classified"
    },
    {
        "id": "1F8N",
        "text": "Injury or harm arising following infusion, transfusion or therapeutic injection, not elsewhere classified"
    },
    {
        "id": "1F8O",
        "text": "Injury or harm arising from a procedure, not elsewhere classified"
    },
    {
        "id": "1F8P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F8Q",
        "text": "Other injury or harm from surgical or medical care, not elsewhere classified"
    },
    {
        "id": "1F8R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F8S",
        "text": "Mechanical complication of internal joint prosthesis"
    },
    {
        "id": "1F8T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F8U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F8V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F8W",
        "text": "Injury or harm arising from surgical or medical care, not elsewhere classified"
    },
    {
        "id": "1F8X",
        "text": "Surgical or other medical procedures associated with injury or harm in diagnostic or therapeutic use"
    },
    {
        "id": "1F8Y",
        "text": "Surgical or other medical devices, implants or grafts associated with injury or harm in therapeutic use"
    },
    {
        "id": "1F8Z",
        "text": "Dislodgement, misconnection or de-attachment, as mode of injury or harm"
    },
    {
        "id": "1F90",
        "text": "Obstruction of device, as mode of injury or harm"
    },
    {
        "id": "1F91",
        "text": "Substances associated with injury or harm in therapeutic use"
    },
    {
        "id": "1F92",
        "text": "Incorrect diagnosis"
    },
    {
        "id": "1F93",
        "text": "Diagnosis code descriptors"
    },
    {
        "id": "1F94",
        "text": "Diagnosis code descriptors"
    },
    {
        "id": "1F95",
        "text": "Surgical or other medical devices, implants or grafts associated with injury or harm in therapeutic use"
    },
    {
        "id": "1F96",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Systemic antibiotics"
    },
    {
        "id": "1F97",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Other systemic anti-infectives or antiparasitics"
    },
    {
        "id": "1F98",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Hormones or their synthetic substitutes or antagonists, not elsewhere classified"
    },
    {
        "id": "1F99",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Primarily systemic agents"
    },
    {
        "id": "1F9A",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Agents primarily affecting blood constituents"
    },
    {
        "id": "1F9B",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Analgesics, antipyretics or anti-inflammatory drugs"
    },
    {
        "id": "1F9C",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Antiepileptics or antiparkinsonism drugs"
    },
    {
        "id": "1F9D",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Sedatives, hypnotics or antianxiety drugs"
    },
    {
        "id": "1F9E",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Anaesthetics or therapeutic gases"
    },
    {
        "id": "1F9F",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Psychotropic drugs, not elsewhere classified"
    },
    {
        "id": "1F9G",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Central nervous system stimulants, not elsewhere classified"
    },
    {
        "id": "1F9H",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Drugs primarily affecting the autonomic nervous system"
    },
    {
        "id": "1F9I",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Agents primarily affecting the cardiovascular system"
    },
    {
        "id": "1F9J",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Agents primarily affecting the gastrointestinal system"
    },
    {
        "id": "1F9K",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Agents primarily affecting water-balance or mineral or uric acid metabolism"
    },
    {
        "id": "1F9L",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Agents primarily acting on smooth or skeletal muscles or the respiratory system"
    },
    {
        "id": "1F9M",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Topical agents primarily affecting skin or mucous membrane or ophthalmological, otorhinolaryngological or dental drugs"
    },
    {
        "id": "1F9N",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Other or unspecified drugs or medicaments"
    },
    {
        "id": "1F9O",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Bacterial vaccines"
    },
    {
        "id": "1F9P",
        "text": "Drugs medicaments or biological substances associated with injury or harm in therapeutic use, Other or unspecified vaccines or biological substances"
    },
    {
        "id": "1F9Q",
        "text": "Complementary or traditional medicines associated with injury or harm in therapeutic use"
    },
    {
        "id": "1F9R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F9S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1F9T",
        "text": "Mode of injury or harm associated with a surgical or other medical device, implant or graft"
    },
    {
        "id": "1F9U",
        "text": "Mode of injury or harm associated with a surgical or other medical device, implant or graft"
    },
    {
        "id": "1F9V",
        "text": "Mode of injury or harm associated with a surgical or other medical device, implant or graft"
    },
    {
        "id": "1F9W",
        "text": "Mode of injury or harm associated with a surgical or other medical device, implant or graft"
    },
    {
        "id": "1F9X",
        "text": "Mode of injury or harm associated with other health care related causes"
    },
    {
        "id": "1F9Y",
        "text": "Diagnosis code descriptors"
    },
    {
        "id": "1F9Z",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "1G00",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "1G01",
        "text": "Mode of injury or harm associated with other health care related causes"
    },
    {
        "id": "1G02",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1D7T",
        "text": "Cardiac septal defect, acquired"
    },
    {
        "id": "1G03",
        "text": "Disorders of ear, not elsewhere classified"
    },
    {
        "id": "1G04",
        "text": "Acute appendicitis without localised or generalised peritonitis"
    },
    {
        "id": "1G05",
        "text": "Allergens"
    },
    {
        "id": "1G08",
        "text": "Crohn disease of upper gastrointestinal tract"
    },
    {
        "id": "1G09",
        "text": "Functional faecal incontinence"
    },
    {
        "id": "1G0A",
        "text": "Nonretentive faecal incontinence in children"
    },
    {
        "id": "1G0B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G0C",
        "text": "Myopathy with cytoplasmic bodies"
    },
    {
        "id": "1G0D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G0E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G0F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G0G",
        "text": "Neonatal hepatic haemorrhage"
    },
    {
        "id": "1G0H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G0I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G0J",
        "text": "Myopathy with deficiency of sarcotubular calcium binding"
    },
    {
        "id": "1G0L",
        "text": "Ocular myopathy"
    },
    {
        "id": "1G0M",
        "text": "Oculocraniosomatic myopathy"
    },
    {
        "id": "1G0N",
        "text": "Quadriceps myopathy"
    },
    {
        "id": "1G0O",
        "text": "Certain specified congenital myopathy"
    },
    {
        "id": "1G0P",
        "text": "King Denborough syndrome"
    },
    {
        "id": "1G0Q",
        "text": "Myopathy with tubular aggregates"
    },
    {
        "id": "1G0R",
        "text": "Sarcotubular myopathy"
    },
    {
        "id": "1G0S",
        "text": "Congenital myopathy with no structural abnormalities"
    },
    {
        "id": "1G0T",
        "text": "Photo-allergic contact dermatitis"
    },
    {
        "id": "1G0U",
        "text": "Certain specified adverse cutaneous reactions to medication"
    },
    {
        "id": "1G0V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G07",
        "text": "Allergens"
    },
    {
        "id": "1G0W",
        "text": "Drug-induced immune haemolytic anaemia"
    },
    {
        "id": "1G0X",
        "text": "Drug-induced immune thrombocytopenia"
    },
    {
        "id": "1G0Y",
        "text": "Drug-induced secondary agranulocytosis"
    },
    {
        "id": "1G0Z",
        "text": "Eosinophilia"
    },
    {
        "id": "1G10",
        "text": "Vernal conjunctivitis"
    },
    {
        "id": "1G11",
        "text": "Otalgia or effusion of ear"
    },
    {
        "id": "1G12",
        "text": "Diseases of the ear or mastoid process"
    },
    {
        "id": "1G13",
        "text": "Arsenical keratosis"
    },
    {
        "id": "1G14",
        "text": "Arsenical keratosis"
    },
    {
        "id": "1G15",
        "text": "Toxic porphyria cutanea tarda"
    },
    {
        "id": "1G16",
        "text": "Toxic porphyria cutanea tarda"
    },
    {
        "id": "1G1E",
        "text": "Callosities of hands or fingers"
    },
    {
        "id": "1G1I",
        "text": "Hindfoot callosities"
    },
    {
        "id": "1G1J",
        "text": "Talar callosity"
    },
    {
        "id": "1G1K",
        "text": "Callosities of other sites"
    },
    {
        "id": "1G1H",
        "text": "Soft corn"
    },
    {
        "id": "1G1G",
        "text": "Hard corn"
    },
    {
        "id": "1G1F",
        "text": "Forefoot callosities"
    },
    {
        "id": "1G1A",
        "text": "Piezogenic pedal papules"
    },
    {
        "id": "1G19",
        "text": "Juvenile spring eruption"
    },
    {
        "id": "1F7Z",
        "text": "Adverse reaction to injection of neurotoxin"
    },
    {
        "id": "1G1L",
        "text": "Black heel"
    },
    {
        "id": "1G18",
        "text": "Acanthoma fissuratum"
    },
    {
        "id": "1G17",
        "text": "Chondrodermatitis nodularis"
    },
    {
        "id": "1G1C",
        "text": "Corns or callosities"
    },
    {
        "id": "1G1M",
        "text": "Juvenile plantar dermatosis"
    },
    {
        "id": "1G1N",
        "text": "Friction blister"
    },
    {
        "id": "1G1P",
        "text": "Contact dermatitis due to skin damage from friction or micro-trauma"
    },
    {
        "id": "1G1B",
        "text": "Dermatoses provoked by friction or mechanical stress"
    },
    {
        "id": "1F7B",
        "text": "Other elsewhere classified cutaneous complication of diagnostic procedure"
    },
    {
        "id": "1G1Q",
        "text": "First degree haemorrhoids"
    },
    {
        "id": "1G1R",
        "text": "Second degree haemorrhoids"
    },
    {
        "id": "1G1S",
        "text": "Haemorrhoids or perianal venous conditions"
    },
    {
        "id": "1G1T",
        "text": "Clinical manifestations of the digestive system"
    },
    {
        "id": "1G1U",
        "text": "Clinical manifestations of the digestive system"
    },
    {
        "id": "1G1V",
        "text": "Dermatoses due to foreign bodies"
    },
    {
        "id": "1G1X",
        "text": "Foreign body reactions to hair"
    },
    {
        "id": "1G1Y",
        "text": "Pseudofolliculitis barbae"
    },
    {
        "id": "1G1Z",
        "text": "Sacrococcygeal pilonidal disease"
    },
    {
        "id": "1G20",
        "text": "Sacrococcygeal pilonidal sinus"
    },
    {
        "id": "1G21",
        "text": "Sacrococcygeal pilonidal cyst"
    },
    {
        "id": "1G22",
        "text": "Sacrococcygeal pilonidal abscess"
    },
    {
        "id": "1G23",
        "text": "Interdigital pilonidal sinus"
    },
    {
        "id": "1G24",
        "text": "Foreign body reaction to inorganic matter in the skin"
    },
    {
        "id": "1G25",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G26",
        "text": "Silica granuloma"
    },
    {
        "id": "1G27",
        "text": "Talc granuloma"
    },
    {
        "id": "1G28",
        "text": "Cutaneous suture granuloma"
    },
    {
        "id": "1G29",
        "text": "Oleogranuloma"
    },
    {
        "id": "1G2A",
        "text": "Foreign body reaction to organic matter in the skin"
    },
    {
        "id": "1G2B",
        "text": "Sea-urchin granuloma"
    },
    {
        "id": "1G2C",
        "text": "Foreign body reaction to cactus spines"
    },
    {
        "id": "1G2D",
        "text": "Foreign body granuloma of skin"
    },
    {
        "id": "1G1W",
        "text": "Foreign body reactions to hair"
    },
    {
        "id": "1G2F",
        "text": "Skin or soft tissue injury due to exposure to cold"
    },
    {
        "id": "1G2G",
        "text": "Chilblains"
    },
    {
        "id": "1G2H",
        "text": "Immersion hand or foot"
    },
    {
        "id": "1G2I",
        "text": "Neonatal cold injury syndrome"
    },
    {
        "id": "1G2K",
        "text": "Superficial frostbite"
    },
    {
        "id": "1G2M",
        "text": "Superficial frostbite"
    },
    {
        "id": "1G2N",
        "text": "Frostbite with tissue necrosis"
    },
    {
        "id": "1G2J",
        "text": "Frostbite"
    },
    {
        "id": "1G2O",
        "text": "Frostbite involving multiple body regions"
    },
    {
        "id": "1G2P",
        "text": "Cold panniculitis"
    },
    {
        "id": "1G2Q",
        "text": "Cold panniculitis of the newborn"
    },
    {
        "id": "1G2R",
        "text": "Abnormal vascular reactivity to cold"
    },
    {
        "id": "1G2S",
        "text": "Cold urticaria"
    },
    {
        "id": "1G2T",
        "text": "Acrocyanosis"
    },
    {
        "id": "1G2V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G2W",
        "text": "Physiological cutis marmorata"
    },
    {
        "id": "1G2X",
        "text": "Raynaud phenomenon"
    },
    {
        "id": "1G2Y",
        "text": "Secondary Raynaud phenomenon"
    },
    {
        "id": "1G2Z",
        "text": "Primary Raynaud disease"
    },
    {
        "id": "1G30",
        "text": "Miscellaneous dermatoses exacerbated by cold"
    },
    {
        "id": "1G2U",
        "text": "Erythrocyanosis"
    },
    {
        "id": "1G2L",
        "text": "Frostbite with tissue necrosis"
    },
    {
        "id": "1G31",
        "text": "Congenital myopathy with muscle fibre immaturity"
    },
    {
        "id": "1G33",
        "text": "Congenital myopathy with no specific features or minimal change"
    },
    {
        "id": "1G34",
        "text": "Congenital myopathy with type 1 fibre predominance"
    },
    {
        "id": "1G35",
        "text": "Congenital neuromuscular disorder with uniform type 1 fibre"
    },
    {
        "id": "1G36",
        "text": "Congenital myopathy with structural abnormalities"
    },
    {
        "id": "1G37",
        "text": "Antenatal multi-minicore disease with arthrogryposis multiplex congenital"
    },
    {
        "id": "1G38",
        "text": "Congenital multi-minicore disease with external ophthalmoplegia"
    },
    {
        "id": "1G39",
        "text": "Moderate multi-minicore disease with hand involvement"
    },
    {
        "id": "1G3A",
        "text": "Congenital myopathy with excess of thin filaments"
    },
    {
        "id": "1G3B",
        "text": "Hyaline body myopathy"
    },
    {
        "id": "1G3C",
        "text": "Myopathy with hexagonally cross-linked tubular arrays"
    },
    {
        "id": "1G3D",
        "text": "Spheroid body myopathy"
    },
    {
        "id": "1G3E",
        "text": "Certain specified rare distal myopathy"
    },
    {
        "id": "1G3F",
        "text": "Distal myopathy with anterior tibial onset"
    },
    {
        "id": "1G3G",
        "text": "Hereditary inclusion body myopathy"
    },
    {
        "id": "1G3H",
        "text": "Desmin-related myopathy with Mallory body-like inclusions"
    },
    {
        "id": "1G3I",
        "text": "Inclusion body myopathy with Paget disease of bone and frontotemporal dementia"
    },
    {
        "id": "1G2E",
        "text": "Dermatoses provoked or exacerbated by exposure to cold"
    },
    {
        "id": "1G3J",
        "text": "Dermatoses provoked by heat or electricity"
    },
    {
        "id": "1G3K",
        "text": "Heat contact urticaria"
    },
    {
        "id": "1G3L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G3M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G3P",
        "text": "Leishmaniasis"
    },
    {
        "id": "1G3O",
        "text": "Food-dependent exercise-induced anaphylaxis"
    },
    {
        "id": "1G3R",
        "text": "Cutaneous leishmaniasis"
    },
    {
        "id": "1G3Q",
        "text": "Post-kala-azar dermal leishmaniasis"
    },
    {
        "id": "1G3S",
        "text": "Cutaneous leishmaniasis"
    },
    {
        "id": "1G3T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G3U",
        "text": "Lymphocutaneous leishmaniasis"
    },
    {
        "id": "1G3W",
        "text": "Mucocutaneous leishmaniasis"
    },
    {
        "id": "1G3X",
        "text": "Disseminated cutaneous leishmaniasis"
    },
    {
        "id": "1G3V",
        "text": "Diffuse cutaneous leishmaniasis"
    },
    {
        "id": "1G3N",
        "text": "Thermal keratosis"
    },
    {
        "id": "1G3Y",
        "text": "Dermatoses provoked by light or UV radiation"
    },
    {
        "id": "1G3Z",
        "text": "Chronic effects of ultraviolet radiation on the skin"
    },
    {
        "id": "1G40",
        "text": "Autoimmune or other photodermatoses"
    },
    {
        "id": "1G41",
        "text": "Photoaggravated skin disease"
    },
    {
        "id": "1G42",
        "text": "Acute effects of ultraviolet radiation on normal skin"
    },
    {
        "id": "1G43",
        "text": "Phototoxic reactions to skin contact with photoactive agents"
    },
    {
        "id": "1G44",
        "text": "Abnormal sensitivity to light or UV radiation of uncertain or unspecified nature"
    },
    {
        "id": "1G45",
        "text": "Photo-allergic contact dermatitis"
    },
    {
        "id": "1G46",
        "text": "Hepatic porphyrias affecting the skin"
    },
    {
        "id": "1G47",
        "text": "Porphyria cutanea tarda"
    },
    {
        "id": "1G48",
        "text": "Drug or pharmacological agents hypersensitivity"
    },
    {
        "id": "1G4A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G4B",
        "text": "Autosomal recessive cardiomyopathy or ophthalmoplegia"
    },
    {
        "id": "1G49",
        "text": "X-linked myopathy with excess autophagy"
    },
    {
        "id": "1G4C",
        "text": "Sporadic Leigh syndrome"
    },
    {
        "id": "1G4D",
        "text": "Luft disease"
    },
    {
        "id": "1G4E",
        "text": "Mitochondrial myopathy with coenzyme Q deficiency"
    },
    {
        "id": "1G4F",
        "text": "Mitochondrial myopathy with complex I deficiency"
    },
    {
        "id": "1G4G",
        "text": "Mitochondrial myopathy with cytochrome C oxidase deficiency"
    },
    {
        "id": "1G4H",
        "text": "Mitochondrial myopathy with cytochrome C oxidase deficiency, severe infantile form"
    },
    {
        "id": "1G4I",
        "text": "Maternally inherited Leigh syndrome"
    },
    {
        "id": "1G4J",
        "text": "Progressive external ophthalmoplegia, autosomal dominant"
    },
    {
        "id": "1G4K",
        "text": "Progressive external ophthalmoplegia, autosomal dominant, type 1"
    },
    {
        "id": "1G4O",
        "text": "Progressive external ophthalmoplegia, autosomal dominant, type 2"
    },
    {
        "id": "1G4M",
        "text": "Progressive external ophthalmoplegia, autosomal dominant, type 3"
    },
    {
        "id": "1G4P",
        "text": "Progressive external ophthalmoplegia, autosomal dominant, type 5"
    },
    {
        "id": "1G4Q",
        "text": "Progressive external ophthalmoplegia, autosomal recessive"
    },
    {
        "id": "1G4R",
        "text": "Myoclonic epilepsy, ragged red fibres"
    },
    {
        "id": "1G4T",
        "text": "Acute effects of ionizing radiation on the skin"
    },
    {
        "id": "1G4X",
        "text": "Chronic radiation keratosis"
    },
    {
        "id": "1G4Y",
        "text": "Radiation keratosis following radiotherapy"
    },
    {
        "id": "1G4W",
        "text": "Chronic radiodermatitis, not elsewhere classified"
    },
    {
        "id": "1G4U",
        "text": "Acute radiodermatitis, not elsewhere classified"
    },
    {
        "id": "1F63",
        "text": "Acute radiodermatitis following radiotherapy"
    },
    {
        "id": "1G4S",
        "text": "Dermatoses due to ionizing radiation"
    },
    {
        "id": "1G4V",
        "text": "Chronic effects of ionizing radiation on the skin"
    },
    {
        "id": "1F79",
        "text": "Radionecrosis of skin attributable to diagnostic procedure"
    },
    {
        "id": "1G4Z",
        "text": "Radionecrosis of skin due to therapeutic ionizing irradiation"
    },
    {
        "id": "1G50",
        "text": "Radiotherapy-induced xerostomia"
    },
    {
        "id": "1G51",
        "text": "Scarring alopecia following radiotherapy"
    },
    {
        "id": "1F65",
        "text": "Radiotherapy-induced skin malignancy"
    },
    {
        "id": "1G53",
        "text": "Poikiloderma following radiotherapy"
    },
    {
        "id": "1G54",
        "text": "Dermatoses resulting from exposure to water"
    },
    {
        "id": "1G55",
        "text": "Aquagenic pruritus"
    },
    {
        "id": "1G56",
        "text": "Aquagenic wrinkling of the fingers or palms"
    },
    {
        "id": "1G57",
        "text": "Miscellaneous cutaneous reactions to mechanical trauma"
    },
    {
        "id": "1G58",
        "text": "Sexually transmissible viral hepatitis"
    },
    {
        "id": "1G59",
        "text": "Tuberculous cerebral arteritis"
    },
    {
        "id": "1G5A",
        "text": "Cutaneous tuberculosis"
    },
    {
        "id": "1G5B",
        "text": "Tuberculosis of other systems and organs"
    },
    {
        "id": "1G5E",
        "text": "Tuberculosis of the musculoskeletal system"
    },
    {
        "id": "1G5F",
        "text": "Tuberculous gastritis"
    },
    {
        "id": "1G5G",
        "text": "Tuberculosis of myocardium"
    },
    {
        "id": "1G5H",
        "text": "Tuberculous Addison disease"
    },
    {
        "id": "1G5I",
        "text": "Acquired adrenocortical insufficiency"
    },
    {
        "id": "1G5J",
        "text": "Tuberculous Addison disease"
    },
    {
        "id": "1G5C",
        "text": "Tuberculosis of other systems and organs"
    },
    {
        "id": "1G5K",
        "text": "Tuberculosis of other systems and organs"
    },
    {
        "id": "1G5M",
        "text": "Certain zoonotic bacterial diseases"
    },
    {
        "id": "1G5N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1G5D",
        "text": "Tuberculosis of other systems and organs"
    },
    {
        "id": "1H6O",
        "text": "Allergic rhinitis due to specified allergens"
    },
    {
        "id": "1H6P",
        "text": "Drug-induced bronchospasm"
    },
    {
        "id": "1H6Q",
        "text": "Neoplasms"
    },
    {
        "id": "1H6R",
        "text": "In situ neoplasms, except of lymphoid, haematopoietic, central nervous system or related tissues"
    },
    {
        "id": "1F86",
        "text": "Intestinal epithelial dysplasia"
    },
    {
        "id": "1H6S",
        "text": "Diseases of the digestive system"
    },
    {
        "id": "1H6T",
        "text": "Influenza A virus"
    },
    {
        "id": "1H6V",
        "text": "Autosomal recessive muscular dystrophy, childhood type, resembling Becker muscular dystrophy"
    },
    {
        "id": "1H6U",
        "text": "Autosomal recessive muscular dystrophy, childhood type, resembling Duchenne muscular dystrophy"
    },
    {
        "id": "1H6W",
        "text": "Congenital muscular dystrophy with central nervous system abnormalities"
    },
    {
        "id": "1H6X",
        "text": "Congenital muscular dystrophy type 1C, fukutin-related protein gene mutation"
    },
    {
        "id": "1H6Y",
        "text": "Congenital muscular dystrophy type 1D large gene mutation"
    },
    {
        "id": "1H6Z",
        "text": "Congenital muscular dystrophy with central nervous system abnormalities, not elsewhere classified"
    },
    {
        "id": "1H70",
        "text": "Muscle Eye Brain disease"
    },
    {
        "id": "1H71",
        "text": "Congenital muscular dystrophy without central nervous system symptoms"
    },
    {
        "id": "1H72",
        "text": "Collagen VI deficiency"
    },
    {
        "id": "1H73",
        "text": "Congenital muscular dystrophy due to lamin A/C deficiency"
    },
    {
        "id": "1H74",
        "text": "Congenital muscular dystrophy due to phosphatidylcholine biosynthesis defect"
    },
    {
        "id": "1H75",
        "text": "Congenital muscular dystrophy type 1B"
    },
    {
        "id": "1H76",
        "text": "Congenital muscular dystrophy with integrin deficiency"
    },
    {
        "id": "1H77",
        "text": "Congenital muscular dystrophy without central nervous system abnormalities, not elsewhere classified"
    },
    {
        "id": "1H78",
        "text": "Emery-Dreifuss muscular dystrophy, X-linked"
    },
    {
        "id": "1H79",
        "text": "Emery-Dreifuss muscular dystrophy, autosomal dominant"
    },
    {
        "id": "1H7A",
        "text": "Emery-Dreifuss muscular dystrophy, autosomal recessive"
    },
    {
        "id": "1H7B",
        "text": "Hereditary myopathy with early respiratory failure"
    },
    {
        "id": "1H7D",
        "text": "Dominant limb-girdle muscular dystrophy"
    },
    {
        "id": "1H7E",
        "text": "Limb-girdle muscular dystrophy 1H, gene mapped to 3p25.1p23"
    },
    {
        "id": "1H7F",
        "text": "Recessive limb-girdle muscular dystrophy"
    },
    {
        "id": "1H7G",
        "text": "Limb-girdle muscular dystrophy 2J, Titin gene mutation"
    },
    {
        "id": "1H7H",
        "text": "Limb-girdle muscular dystrophy 2L, AN05 gene mutation"
    },
    {
        "id": "1H7I",
        "text": "Limb-girdle muscular dystrophy 2M, POMGnT1 gene mutation"
    },
    {
        "id": "1H7J",
        "text": "Limb-girdle muscular dystrophy 2N, POMT2 gene mutation"
    },
    {
        "id": "1H7K",
        "text": "Limb-girdle muscular dystrophy 2O, POMGNT1 gene mutation"
    },
    {
        "id": "1H7L",
        "text": "Limb-girdle muscular dystrophy 2Q, plectin deficiency"
    },
    {
        "id": "1H7M",
        "text": "Limb-girdle muscular dystrophy-dystroglycanopathy C7"
    },
    {
        "id": "1H7N",
        "text": "Scapuloperoneal muscular dystrophy"
    },
    {
        "id": "1H7O",
        "text": "X-linked myopathy with postural muscle atrophy"
    },
    {
        "id": "1H7P",
        "text": "Alpha B crystalline myopathy"
    },
    {
        "id": "1H7R",
        "text": "Certain specified myofibrillar myopathy"
    },
    {
        "id": "1H7S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H7U",
        "text": "Tuberculosis of the nervous system"
    },
    {
        "id": "1H7V",
        "text": "Tuberculosis of the genitourinary system"
    },
    {
        "id": "1H7W",
        "text": "Tuberculosis of the digestive system"
    },
    {
        "id": "1H7X",
        "text": "Tuberculosis of the urinary system"
    },
    {
        "id": "1H7Z",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "1H80",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H81",
        "text": "HIV - [human immunodeficiency virus] disease associated with Burkitt lymphoma"
    },
    {
        "id": "1H82",
        "text": "HIV - [human immunodeficiency virus] disease associated with Burkitt lymphoma"
    },
    {
        "id": "1H84",
        "text": "Extrathoracic coccidioidomycosis"
    },
    {
        "id": "1H85",
        "text": "Myelitis associated with Coccidioides infection"
    },
    {
        "id": "1H86",
        "text": "Coccidioides meningitis"
    },
    {
        "id": "1H87",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1E93",
        "text": "Diseases of immune dysregulation"
    },
    {
        "id": "1H88",
        "text": "Wart virus proliferation in immune-deficient states"
    },
    {
        "id": "1H8A",
        "text": "Human papillomavirus infection of skin or mucous membrane"
    },
    {
        "id": "1H8B",
        "text": "Fibrosing mediastinitis"
    },
    {
        "id": "1H8C",
        "text": "Fibrosing mediastinitis"
    },
    {
        "id": "1H8E",
        "text": "Histoplasmosis due to Histoplasma duboisii"
    },
    {
        "id": "1H8F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H8G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H8H",
        "text": "Schistosomiasis"
    },
    {
        "id": "1H8I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H8J",
        "text": "Neuroschistosomiasis"
    },
    {
        "id": "1H8K",
        "text": "Schistosomiasis of bladder"
    },
    {
        "id": "1H8L",
        "text": "Schistosomiasis involving the skin"
    },
    {
        "id": "1H8M",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "1H8Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H8U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H8W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H96",
        "text": "Personal history of health problems"
    },
    {
        "id": "1H97",
        "text": "External causes of morbidity or mortality"
    },
    {
        "id": "1H98",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H99",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9C",
        "text": "Personal history of physical abuse"
    },
    {
        "id": "1H9E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9D",
        "text": "Personal history of maltreatment"
    },
    {
        "id": "1H94",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H92",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H8Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H90",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H8V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H9T",
        "text": "Nephrogenic systemic fibrosis"
    },
    {
        "id": "1H9V",
        "text": "Nail-patella syndrome"
    },
    {
        "id": "1H9W",
        "text": "Viral warts due to iatrogenic immunosuppression"
    },
    {
        "id": "1H9X",
        "text": "Viral warts due to acquired immunodeficiency"
    },
    {
        "id": "1H9Y",
        "text": "Yellow nail syndrome"
    },
    {
        "id": "1H9Z",
        "text": "Secondary hypertrophic osteoarthropathy"
    },
    {
        "id": "1I01",
        "text": "Cutaneous markers of disorders of the respiratory system"
    },
    {
        "id": "1H9S",
        "text": "Cutaneous markers of disorders of the kidney or urinary tract"
    },
    {
        "id": "1I03",
        "text": "Cutaneous markers of disorders of the digestive system"
    },
    {
        "id": "1I05",
        "text": "Pretibial myxoedema"
    },
    {
        "id": "1I06",
        "text": "Acromegaly or pituitary gigantism"
    },
    {
        "id": "1I07",
        "text": "Tophaceous gout"
    },
    {
        "id": "1I09",
        "text": "Pyoderma gangrenosum associated with inflammatory polyarthropathy"
    },
    {
        "id": "1I08",
        "text": "Rheumatoid nodule"
    },
    {
        "id": "1I0A",
        "text": "Pyoderma gangrenosum associated with inflammatory polyarthropathy"
    },
    {
        "id": "1I0B",
        "text": "Pyoderma gangrenosum associated with inflammatory bowel disease"
    },
    {
        "id": "1I0C",
        "text": "Pyoderma gangrenosum associated with inflammatory bowel disease"
    },
    {
        "id": "1I0D",
        "text": "Pyoderma gangrenosum linked to haematological disorder"
    },
    {
        "id": "1I0E",
        "text": "Pyoderma gangrenosum linked to haematological disorder"
    },
    {
        "id": "1I0G",
        "text": "Bowel-associated dermatosis-arthritis syndrome"
    },
    {
        "id": "1I0F",
        "text": "Bowel-associated dermatosis-arthritis syndrome"
    },
    {
        "id": "1I0I",
        "text": "Noninfectious erosive or ulcerative disorders of oral mucosa"
    },
    {
        "id": "1I0J",
        "text": "Miscellaneous inflammatory disorders of oral mucosa"
    },
    {
        "id": "1I0K",
        "text": "Pigmentary disturbances of oral mucosa"
    },
    {
        "id": "1I0L",
        "text": "Miscellaneous specified disorders of lips or oral mucosa"
    },
    {
        "id": "1I0M",
        "text": "Genetic or developmental disorders involving lips or oral mucosa"
    },
    {
        "id": "1I0N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I0O",
        "text": "Diseases of tongue"
    },
    {
        "id": "1I0P",
        "text": "Stevens-Johnson syndrome"
    },
    {
        "id": "1I0Q",
        "text": "Cellulitis or abscess of soft tissues of the mouth"
    },
    {
        "id": "1I0R",
        "text": "Erythema nodosum provoked by inflammatory bowel disease"
    },
    {
        "id": "1I0T",
        "text": "Cutaneous markers of disorders of the digestive system"
    },
    {
        "id": "1I0U",
        "text": "Dermatoses resulting from defective nutrition"
    },
    {
        "id": "1I0W",
        "text": "Candidosis"
    },
    {
        "id": "1H9U",
        "text": "Angiokeratoma corporis diffusum"
    },
    {
        "id": "1I0X",
        "text": "Mucocutaneous lymph node syndrome"
    },
    {
        "id": "1I0Z",
        "text": "Cutaneous markers of internal disorders"
    },
    {
        "id": "1I10",
        "text": "Erythema marginatum rheumaticum"
    },
    {
        "id": "1I11",
        "text": "Carcinoid syndrome"
    },
    {
        "id": "1I12",
        "text": "Desmin myopathy"
    },
    {
        "id": "1I13",
        "text": "Filamin C myopathy"
    },
    {
        "id": "1I14",
        "text": "Myotilin myopathy"
    },
    {
        "id": "1I15",
        "text": "Z-band alternatively spliced PDZ motif containing-protein myopathy"
    },
    {
        "id": "1I16",
        "text": "Drug-induced myotonia"
    },
    {
        "id": "1I17",
        "text": "Myotonia congenita, dominant"
    },
    {
        "id": "1I18",
        "text": "Myotonia congenita, recessive"
    },
    {
        "id": "1I19",
        "text": "Infectious myositis"
    },
    {
        "id": "1I1A",
        "text": "Brain death"
    },
    {
        "id": "1I1B",
        "text": "Abulia"
    },
    {
        "id": "1I1C",
        "text": "Mycobacterial myositis"
    },
    {
        "id": "1I1D",
        "text": "Bacterial myositis"
    },
    {
        "id": "1I1E",
        "text": "Genitocrural dermatophytosis"
    },
    {
        "id": "1I1F",
        "text": "Dermatophytosis of foot"
    },
    {
        "id": "1I00",
        "text": "Digital clubbing"
    },
    {
        "id": "1I1M",
        "text": "Neuropathic skin ulceration"
    },
    {
        "id": "1I1N",
        "text": "Symptoms or signs involving the visual system"
    },
    {
        "id": "1I1O",
        "text": "Myotonic dystrophy"
    },
    {
        "id": "1I1P",
        "text": "Congenital myotonic dystrophy"
    },
    {
        "id": "1I1Q",
        "text": "Juvenile to adult onset myotonic dystrophy"
    },
    {
        "id": "1I1R",
        "text": "Rippling muscle disease"
    },
    {
        "id": "1I1S",
        "text": "Pseudomyotonia"
    },
    {
        "id": "1I1T",
        "text": "Chloride channel disorders"
    },
    {
        "id": "1I1U",
        "text": "Normokalaemic periodic paralysis"
    },
    {
        "id": "1I1V",
        "text": "Acute alcoholic myopathy"
    },
    {
        "id": "1I1W",
        "text": "Chronic alcoholic myopathy"
    },
    {
        "id": "1I1X",
        "text": "Autoimmune myopathy"
    },
    {
        "id": "1I1Y",
        "text": "Inflammatory inclusion body myositis"
    },
    {
        "id": "1I1Z",
        "text": "Hereditary inclusion body myositis"
    },
    {
        "id": "1I20",
        "text": "Noninflammatory inclusion body myopathy"
    },
    {
        "id": "1I22",
        "text": "Desmin-related myopathy with Mallory body-like inclusions"
    },
    {
        "id": "1I21",
        "text": "Hereditary inclusion body myopathy"
    },
    {
        "id": "1I25",
        "text": "Hereditary inclusion body myopathy - joint contractures - ophthalmoplegia"
    },
    {
        "id": "1I26",
        "text": "Inclusion body myopathy with Paget disease of bone and frontotemporal dementia"
    },
    {
        "id": "1I27",
        "text": "X-linked myopathy with excess autophagy"
    },
    {
        "id": "1I28",
        "text": "Sporadic inclusion body myopathy"
    },
    {
        "id": "1I29",
        "text": "Adult dermatomyositis"
    },
    {
        "id": "1I2A",
        "text": "Adult dermatomyositis with calcinosis"
    },
    {
        "id": "1I2C",
        "text": "Amyopathic dermatomyositis"
    },
    {
        "id": "1I2D",
        "text": "Hypomyopathic dermatomyositis"
    },
    {
        "id": "1I2E",
        "text": "Amyopathic juvenile dermatomyositis"
    },
    {
        "id": "1I2F",
        "text": "Hypomyopathic juvenile dermatomyositis"
    },
    {
        "id": "1I2H",
        "text": "Juvenile dermatomyositis with calcinosis"
    },
    {
        "id": "1I2G",
        "text": "Syphilitic Charcot arthropathy"
    },
    {
        "id": "1I2I",
        "text": "Juvenile dermatomyositis with cutaneous vasculitis"
    },
    {
        "id": "1I2J",
        "text": "Syphilitic Charcot arthropathy"
    },
    {
        "id": "1I2L",
        "text": "Juvenile dermatomyositis with lipodystrophy"
    },
    {
        "id": "1I2M",
        "text": "Juvenile dermatomyositis with myocarditis"
    },
    {
        "id": "1I2N",
        "text": "Diabetic cheiroarthropathy"
    },
    {
        "id": "1I2P",
        "text": "Juvenile dermatomyositis with overlap to nonorgan specific systemic autoimmune disorders"
    },
    {
        "id": "1C7H",
        "text": "Diabetic arthropathy"
    },
    {
        "id": "1I2Q",
        "text": "Anti-synthetase syndrome with polymyositis"
    },
    {
        "id": "1I2K",
        "text": "Diabetic arthropathy"
    },
    {
        "id": "1I2R",
        "text": "Diabetic arthropathy"
    },
    {
        "id": "1I2S",
        "text": "Juvenile polymyositis"
    },
    {
        "id": "1I2U",
        "text": "Juvenile polymyositis with PM-Scl auto-antibody"
    },
    {
        "id": "1I2V",
        "text": "Juvenile polymyositis with anti-synthetase auto-antibody"
    },
    {
        "id": "1I2W",
        "text": "Juvenile polymyositis with interstitial lung disease"
    },
    {
        "id": "1I2X",
        "text": "Juvenile polymyositis with overlap to non-organ specific systemic autoimmune disorder"
    },
    {
        "id": "1I2Z",
        "text": "Juvenile polymyositis, paraneoplastic"
    },
    {
        "id": "1I30",
        "text": "Necrotising polymyositis"
    },
    {
        "id": "1I2Y",
        "text": "Juvenile polymyositis, necrotising"
    },
    {
        "id": "1I31",
        "text": "Polymyositis with overlap to nonorgan specific systemic autoimmune disorders"
    },
    {
        "id": "1I32",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I33",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I34",
        "text": "Myopathy in certain specified endocrine disease"
    },
    {
        "id": "1I35",
        "text": "Myopathy in certain specified infectious or parasitic disease"
    },
    {
        "id": "1I36",
        "text": "Myopathy in certain specified metabolic disease"
    },
    {
        "id": "1I37",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I38",
        "text": "Secondary muscle wasting"
    },
    {
        "id": "1I39",
        "text": "Secondary rhabdomyolysis"
    },
    {
        "id": "1I1J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I1I",
        "text": "Morton metatarsalgia"
    },
    {
        "id": "1I2T",
        "text": "Diabetic cheiroarthropathy"
    },
    {
        "id": "1I1K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I3B",
        "text": "Stereotyped movement disorder with self-injury"
    },
    {
        "id": "1I3C",
        "text": "Symbolic dysfunctions"
    },
    {
        "id": "1I3D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I3E",
        "text": "Poverty of speech"
    },
    {
        "id": "1I3F",
        "text": "Pressured speech"
    },
    {
        "id": "1I3G",
        "text": "Poverty of speech"
    },
    {
        "id": "1I3H",
        "text": "Pressured speech"
    },
    {
        "id": "1I3I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I3K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I3M",
        "text": "Skin disorders associated with diabetes mellitus"
    },
    {
        "id": "1I3N",
        "text": "Changes in skin texture"
    },
    {
        "id": "1I3O",
        "text": "Excess and redundant skin"
    },
    {
        "id": "1I3P",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "1I3Q",
        "text": "Disorders with hearing impairment"
    },
    {
        "id": "1C6I",
        "text": "Chronic primary pain"
    },
    {
        "id": "1I3S",
        "text": "Oral-facial-digital syndrome type 6"
    },
    {
        "id": "1I3T",
        "text": "Congenital anomaly or multiple anomalies, without further specification"
    },
    {
        "id": "1I3U",
        "text": "Necrotising fasciitis of the scrotum, penis or perineum"
    },
    {
        "id": "1I3V",
        "text": "Codes for special purposes"
    },
    {
        "id": "1I3X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I40",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I41",
        "text": "Symptoms or signs involving form of thought"
    },
    {
        "id": "1I42",
        "text": "Symptoms or signs involving content of thought"
    },
    {
        "id": "1I44",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I45",
        "text": "Chagas' disease with myocarditis"
    },
    {
        "id": "1I46",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I43",
        "text": "Diabetic skin lesions"
    },
    {
        "id": "1I47",
        "text": "Infestation by Demodex"
    },
    {
        "id": "1I48",
        "text": "International provisional assignment of new diseases of uncertain aetiology and emergency use"
    },
    {
        "id": "1I49",
        "text": "Juvenile enthesitis related arthritis"
    },
    {
        "id": "1I4C",
        "text": "Multiple Joints"
    },
    {
        "id": "1I4B",
        "text": "Joints and ligaments"
    },
    {
        "id": "1I4A",
        "text": "Bones"
    },
    {
        "id": "1I4F",
        "text": "Solitary circumscribed neuroma"
    },
    {
        "id": "1I4E",
        "text": "Benign tumours of cutaneous smooth muscle"
    },
    {
        "id": "1I4G",
        "text": "Hereditary anonychia"
    },
    {
        "id": "1I4J",
        "text": "Gout"
    },
    {
        "id": "1I4I",
        "text": "Gout"
    },
    {
        "id": "1I4L",
        "text": "Pityriasis rubra pilaris type I, classical adult"
    },
    {
        "id": "1I4K",
        "text": "Pityriasis rubra pilaris, adult"
    },
    {
        "id": "1I4M",
        "text": "Pityriasis rubra pilaris type II, atypical adult"
    },
    {
        "id": "1I4R",
        "text": "Pityriasis rubra pilaris type IV, circumscribed juvenile"
    },
    {
        "id": "1I4P",
        "text": "Pityriasis rubra pilaris type III, classical juvenile"
    },
    {
        "id": "1I4O",
        "text": "Pityriasis rubra pilaris type IV, circumscribed juvenile"
    },
    {
        "id": "1I4Q",
        "text": "Pityriasis rubra pilaris type V, atypical juvenile"
    },
    {
        "id": "1I4S",
        "text": "Ulcerative lichen planus"
    },
    {
        "id": "1I4N",
        "text": "Pityriasis rubra pilaris, juvenile"
    },
    {
        "id": "1I4T",
        "text": "Acquired deformity of pinna"
    },
    {
        "id": "1I4V",
        "text": "Auricular cartilage calcification"
    },
    {
        "id": "1I4W",
        "text": "Auricular cartilage ossification"
    },
    {
        "id": "1I4U",
        "text": "Petrified ear"
    },
    {
        "id": "1I4X",
        "text": "Cholesteatoma of external auditory canal"
    },
    {
        "id": "1I4Y",
        "text": "Cholesteatoma of external auditory canal"
    },
    {
        "id": "1I4Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I50",
        "text": "Acquired deformity of pinna"
    },
    {
        "id": "1I52",
        "text": "Hordeolum"
    },
    {
        "id": "1I53",
        "text": "Hordeolum externum"
    },
    {
        "id": "1I51",
        "text": "Hordeolum internum"
    },
    {
        "id": "1I54",
        "text": "Other inflammatory disorders of penis"
    },
    {
        "id": "1I56",
        "text": "Specific organ or system reactions due to drug hypersensitivity"
    },
    {
        "id": "1I55",
        "text": "Certain specified disorders of penis"
    },
    {
        "id": "1I58",
        "text": "Genitocrural seborrhoeic dermatitis"
    },
    {
        "id": "1I5A",
        "text": "Seborrhoeic dermatitis of the flexures"
    },
    {
        "id": "1I5B",
        "text": "Generalised seborrhoeic dermatitis"
    },
    {
        "id": "1I5D",
        "text": "Acute noninfectious otitis externa"
    },
    {
        "id": "1I5E",
        "text": "Chronic otitis externa"
    },
    {
        "id": "1I5F",
        "text": "Penile fibromatosis"
    },
    {
        "id": "1I5G",
        "text": "Certain specified disorders of penis"
    },
    {
        "id": "1I5H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I5I",
        "text": "Structural developmental anomalies of the male genital system"
    },
    {
        "id": "1I5J",
        "text": "Extramammary Paget disease of penis"
    },
    {
        "id": "1I5K",
        "text": "Cutaneous disorders attributable to acquired or iatrogenic immunodeficiency"
    },
    {
        "id": "1I5M",
        "text": "Chorioangioma"
    },
    {
        "id": "1I5N",
        "text": "Disseminated intravascular coagulation of fetus or newborn"
    },
    {
        "id": "1I5O",
        "text": "Neonatal staphylococcal pyoderma"
    },
    {
        "id": "1I5P",
        "text": "Neonatal listeriosis"
    },
    {
        "id": "1I5Q",
        "text": "Neonatal cephalic pustulosis"
    },
    {
        "id": "1I5R",
        "text": "Pustular psoriasis"
    },
    {
        "id": "1I5S",
        "text": "Bullous impetigo"
    },
    {
        "id": "1I5T",
        "text": "Acute haemorrhagic oedema of infancy"
    },
    {
        "id": "1I5U",
        "text": "Harmful effects of or exposure to noxious substances, substances chiefly nonmedicinal as to source, pesticides"
    },
    {
        "id": "1I5W",
        "text": "Acute rheumatic fever without mention of heart involvement"
    },
    {
        "id": "1I5V",
        "text": "Acute rheumatic fever with heart involvement"
    },
    {
        "id": "1I5Y",
        "text": "Merkel cell carcinoma of skin"
    },
    {
        "id": "1I5Z",
        "text": "Candidosis of gastrointestinal tract"
    },
    {
        "id": "1I60",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I61",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I62",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I63",
        "text": "Primary herpetic vulvovaginitis"
    },
    {
        "id": "1I64",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I5X",
        "text": "Certain staphylococcal or streptococcal diseases"
    },
    {
        "id": "1I65",
        "text": "Lichen simplex"
    },
    {
        "id": "1I66",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I67",
        "text": "Streptococcal necrotising fasciitis"
    },
    {
        "id": "1I69",
        "text": "Polymicrobial necrotising fasciitis"
    },
    {
        "id": "1I68",
        "text": "Streptococcal necrotising fasciitis"
    },
    {
        "id": "1I6A",
        "text": "Chalazion"
    },
    {
        "id": "1I6B",
        "text": "Acquired malposition of eyelid"
    },
    {
        "id": "1I6C",
        "text": "Cicatricial lagophthalmos"
    },
    {
        "id": "1I6D",
        "text": "Mechanical lagophthalmos"
    },
    {
        "id": "1I6E",
        "text": "Madarosis of eyelid or periocular area"
    },
    {
        "id": "1I6F",
        "text": "Hyperlacrimation"
    },
    {
        "id": "1I6G",
        "text": "Acquired orbital cyst"
    },
    {
        "id": "1I6H",
        "text": "Developmental anomalies of anterior segment"
    },
    {
        "id": "1I6I",
        "text": "Iridogoniodysgenesis"
    },
    {
        "id": "1I6J",
        "text": "Vernal keratoconjunctivitis"
    },
    {
        "id": "1I6L",
        "text": "Pyogenic bacterial infections of the skin or subcutaneous tissues"
    },
    {
        "id": "1I6M",
        "text": "Disorders of vitamins or their metabolism which may affect the skin"
    },
    {
        "id": "1I6N",
        "text": "Inducible urticaria and angioedema"
    },
    {
        "id": "1I6O",
        "text": "Contact urticaria"
    },
    {
        "id": "1I6Q",
        "text": "Non-venereal treponematoses"
    },
    {
        "id": "1H89",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I6S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I6T",
        "text": "Neoscytalidium dermatomycosis"
    },
    {
        "id": "1I6U",
        "text": "Onychomycosis due to non-dermatophyte mould"
    },
    {
        "id": "1I6X",
        "text": "Subcutaneous mycoses"
    },
    {
        "id": "1I6Z",
        "text": "Mycetoma of unknown or unspecified type"
    },
    {
        "id": "1I70",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I6W",
        "text": "Pulmonary pneumocystosis"
    },
    {
        "id": "1I71",
        "text": "Extrapulmonary Pneumocystis infection"
    },
    {
        "id": "1I72",
        "text": "Post-kala-azar dermal leishmaniasis"
    },
    {
        "id": "1I73",
        "text": "Cutaneous reactions to food mites"
    },
    {
        "id": "1I75",
        "text": "Lichen planus pemphigoides"
    },
    {
        "id": "1C6E",
        "text": "Chronic neuropathic pain"
    },
    {
        "id": "1I78",
        "text": "Aortic arch syndrome"
    },
    {
        "id": "1I79",
        "text": "Glomerular disease associated with systemic autoimmune or immunological diseases"
    },
    {
        "id": "1I7A",
        "text": "Glomerular disease associated with haematological disease"
    },
    {
        "id": "1I76",
        "text": "Morphoea profunda"
    },
    {
        "id": "1I57",
        "text": "Certain specified disorders of penis"
    },
    {
        "id": "1I7B",
        "text": "Superficial thrombophlebitis"
    },
    {
        "id": "1I7C",
        "text": "Oedema due to disturbed vasomotor control"
    },
    {
        "id": "1I7D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I7F",
        "text": "Cervicofacial lymphatic malformation"
    },
    {
        "id": "1I7G",
        "text": "Lymphoedema due to obesity"
    },
    {
        "id": "1I7H",
        "text": "Secondary lymphoedema"
    },
    {
        "id": "1I7I",
        "text": "Inclusion body fibromatosis"
    },
    {
        "id": "1I7J",
        "text": "Dementia"
    },
    {
        "id": "1I7K",
        "text": "Acute disseminated encephalomyelitis"
    },
    {
        "id": "1I7L",
        "text": "Autosomal recessive severe congenital neutropaenia due to G6PC3 deficiency"
    },
    {
        "id": "1I7M",
        "text": "IgG4 related disease"
    },
    {
        "id": "1I7N",
        "text": "IgG4 related disease"
    },
    {
        "id": "1I7O",
        "text": "Cardiac arrhythmia associated with genetic disorder"
    },
    {
        "id": "1I7P",
        "text": "Malignant neoplasms of lip, oral cavity or pharynx"
    },
    {
        "id": "1I7Q",
        "text": "Malignant neoplasms of digestive organs"
    },
    {
        "id": "1I7R",
        "text": "Malignant neoplasms of digestive organs"
    },
    {
        "id": "1I7S",
        "text": "Malignant neoplasms of middle ear, respiratory or intrathoracic organs"
    },
    {
        "id": "1I7T",
        "text": "Malignant neoplasms of skin"
    },
    {
        "id": "1I7U",
        "text": "Malignant neoplasms of female genital organs"
    },
    {
        "id": "1I7V",
        "text": "Malignant neoplasms of male genital organs"
    },
    {
        "id": "1I7W",
        "text": "Malignant neoplasms of urinary tract"
    },
    {
        "id": "1I7X",
        "text": "Malignant neoplasms of eye or ocular adnexa"
    },
    {
        "id": "1I7Y",
        "text": "Malignant neoplasms of endocrine glands"
    },
    {
        "id": "1I7Z",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1I3Y",
        "text": "Gender incongruence"
    },
    {
        "id": "1I80",
        "text": "Complete trisomy 21"
    },
    {
        "id": "1I81",
        "text": "Subacute nodular migratory panniculitis"
    },
    {
        "id": "1I83",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I84",
        "text": "Cold panniculitis"
    },
    {
        "id": "1I85",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1I8A",
        "text": "Panniculitis"
    },
    {
        "id": "1I8B",
        "text": "Erythema induratum"
    },
    {
        "id": "1I8C",
        "text": "Alcohol intoxication"
    },
    {
        "id": "1I8D",
        "text": "Opioid intoxication"
    },
    {
        "id": "1I8F",
        "text": "Sedative, hypnotic or anxiolytic intoxication"
    },
    {
        "id": "1I8G",
        "text": "Cocaine intoxication"
    },
    {
        "id": "1I8J",
        "text": "Hallucinogen intoxication"
    },
    {
        "id": "1I8K",
        "text": "Nicotine intoxication"
    },
    {
        "id": "1I8L",
        "text": "Volatile inhalant intoxication"
    },
    {
        "id": "1I8M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I90",
        "text": "Episode of harmful use of alcohol"
    },
    {
        "id": "1I91",
        "text": "Episode of harmful use of opioids"
    },
    {
        "id": "1I93",
        "text": "Episode of harmful use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1I94",
        "text": "Episode of harmful use of cocaine"
    },
    {
        "id": "1I97",
        "text": "Episode of harmful use of hallucinogens"
    },
    {
        "id": "1I98",
        "text": "Episode of harmful use of nicotine"
    },
    {
        "id": "1I99",
        "text": "Episode of harmful use of volatile inhalants"
    },
    {
        "id": "1I9C",
        "text": "Episode of harmful use of other specified psychoactive substance"
    },
    {
        "id": "1I9D",
        "text": "Episode of harmful use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "1I9F",
        "text": "Chronic alcohol abuse"
    },
    {
        "id": "1I9K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I9G",
        "text": "Chronic pain"
    },
    {
        "id": "1I9H",
        "text": "Chronic pain"
    },
    {
        "id": "1I9L",
        "text": "Chronic primary pain"
    },
    {
        "id": "1I9M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I9N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I9O",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "1I9P",
        "text": "Chronic secondary visceral pain"
    },
    {
        "id": "1I9Q",
        "text": "Chronic neuropathic pain"
    },
    {
        "id": "1I9R",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1I9S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I9T",
        "text": "Chronic pain"
    },
    {
        "id": "1I9U",
        "text": "Reasons for contact with the health services"
    },
    {
        "id": "1I9V",
        "text": "Psoriasis of specified site or distribution"
    },
    {
        "id": "1I9W",
        "text": "Hypercoagulability syndrome due to glycosylphosphatidylinositol deficiency"
    },
    {
        "id": "1I9X",
        "text": "Consciousness"
    },
    {
        "id": "1J00",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "1I9Z",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "1I9Y",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "1J01",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "1J02",
        "text": "Chronic neuropathic pain"
    },
    {
        "id": "1J03",
        "text": "Chronic neuropathic pain"
    },
    {
        "id": "1J06",
        "text": "Chronic primary pain"
    },
    {
        "id": "1J08",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1J09",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1J0A",
        "text": "Chronic secondary visceral pain"
    },
    {
        "id": "1J0B",
        "text": "Chronic secondary visceral pain"
    },
    {
        "id": "1J0F",
        "text": "Chronic primary pain"
    },
    {
        "id": "1I82",
        "text": "Erythema nodosum"
    },
    {
        "id": "1I7E",
        "text": "Lymphangitis"
    },
    {
        "id": "1I74",
        "text": "Smouldering systemic mastocytosis"
    },
    {
        "id": "1I5C",
        "text": "Seborrhoeic dermatitis of unspecified location"
    },
    {
        "id": "1I3R",
        "text": "Dystrophic calcification of the skin of uncertain or unspecified aetiology"
    },
    {
        "id": "1J0G",
        "text": "Cranial neuropathy associated with Petrous infection"
    },
    {
        "id": "1J0I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J0J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J0K",
        "text": "Reasons for contact with the health care system in relation to eyes or vision"
    },
    {
        "id": "1H7C",
        "text": "Herpes simplex infection of genitalia or urogenital tract"
    },
    {
        "id": "1F64",
        "text": "Chronic radiodermatitis following radiotherapy"
    },
    {
        "id": "1F78",
        "text": "Adverse cutaneous effects of diagnostic procedures"
    },
    {
        "id": "1J0X",
        "text": "Herpes simplex infection of skin or mucous membrane"
    },
    {
        "id": "1J0Z",
        "text": "Venous varicosities of sites other than lower extremity"
    },
    {
        "id": "1J10",
        "text": "Episode of harmful use of alcohol"
    },
    {
        "id": "1J1F",
        "text": "Episode of harmful use of opioids"
    },
    {
        "id": "1J1I",
        "text": "Chronic primary pain"
    },
    {
        "id": "1J1K",
        "text": "Chronic primary pain"
    },
    {
        "id": "1J1L",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "1J1M",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "1J1N",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "1J1O",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1J1P",
        "text": "Episode of harmful use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1J1Q",
        "text": "Episode of harmful use of non-psychoactive substances"
    },
    {
        "id": "1J1R",
        "text": "Disorders due to use of non-psychoactive substances"
    },
    {
        "id": "1J1T",
        "text": "Gender incongruence"
    },
    {
        "id": "1J1U",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "1J1S",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "1J1V",
        "text": "Gender incongruence"
    },
    {
        "id": "1J1W",
        "text": "Episode of harmful use of cocaine"
    },
    {
        "id": "1J1X",
        "text": "Episode of harmful use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J1Y",
        "text": "Episode of harmful use of caffeine"
    },
    {
        "id": "1J1Z",
        "text": "Episode of harmful use of hallucinogens"
    },
    {
        "id": "1J1H",
        "text": "Episode of harmful use of cannabis"
    },
    {
        "id": "1J20",
        "text": "Episode of harmful use of nicotine"
    },
    {
        "id": "1J21",
        "text": "Episode of harmful use of volatile inhalants"
    },
    {
        "id": "1J22",
        "text": "Episode of harmful use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1J23",
        "text": "Episode of harmful use of dissociative drugs including ketamine or PCP"
    },
    {
        "id": "1J24",
        "text": "Episode of harmful use of other specified psychoactive substance"
    },
    {
        "id": "1J25",
        "text": "Episode of harmful use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "1I9B",
        "text": "Episode of harmful use of dissociative drugs including ketamine or PCP"
    },
    {
        "id": "1I9E",
        "text": "Episode of harmful use of non-psychoactive substances"
    },
    {
        "id": "1I96",
        "text": "Episode of harmful use of caffeine"
    },
    {
        "id": "1J3H",
        "text": "Tobacco use"
    },
    {
        "id": "1B1E",
        "text": "Hazardous drug use"
    },
    {
        "id": "1J3I",
        "text": "Hazardous gambling or betting"
    },
    {
        "id": "1J3J",
        "text": "Problems associated with health behaviours"
    },
    {
        "id": "1J3K",
        "text": "Hazardous drug use"
    },
    {
        "id": "1B1F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J3M",
        "text": "Problems associated with health behaviours"
    },
    {
        "id": "1J3L",
        "text": "Problems associated with health behaviours"
    },
    {
        "id": "1J3N",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1J3O",
        "text": "Secondary yaws"
    },
    {
        "id": "1J3P",
        "text": "Colonic spirochaetosis"
    },
    {
        "id": "1J3R",
        "text": "Lyme borreliosis"
    },
    {
        "id": "1J3S",
        "text": "Myelitis due to Borrelia burgdorferi"
    },
    {
        "id": "1J3U",
        "text": "Erythema migrans"
    },
    {
        "id": "1J3Y",
        "text": "Early cutaneous Lyme borreliosis"
    },
    {
        "id": "1J3Q",
        "text": "Lyme borreliosis"
    },
    {
        "id": "1I6K",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "1J3Z",
        "text": "Diseases of the ear or mastoid process"
    },
    {
        "id": "1J41",
        "text": "Erythema migrans"
    },
    {
        "id": "1J42",
        "text": "Symptoms or signs related to personality features"
    },
    {
        "id": "1I87",
        "text": "Gambling disorder"
    },
    {
        "id": "1I88",
        "text": "Gambling disorder"
    },
    {
        "id": "1J43",
        "text": "Gambling disorder"
    },
    {
        "id": "1J44",
        "text": "Gambling disorder"
    },
    {
        "id": "1I86",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1J45",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1J47",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1J46",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1J49",
        "text": "Epilepsy due to central nervous system infections or infestations"
    },
    {
        "id": "1J4A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J48",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1I77",
        "text": "Gender incongruence"
    },
    {
        "id": "1J11",
        "text": "Harmful pattern of use of alcohol"
    },
    {
        "id": "1J12",
        "text": "Harmful pattern of use of opioids"
    },
    {
        "id": "1J14",
        "text": "Harmful pattern of use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1J15",
        "text": "Harmful pattern of use of cocaine"
    },
    {
        "id": "1J17",
        "text": "Harmful pattern of use of caffeine"
    },
    {
        "id": "1J18",
        "text": "Harmful pattern of use of hallucinogens"
    },
    {
        "id": "1J19",
        "text": "Harmful pattern of use of nicotine"
    },
    {
        "id": "1J1A",
        "text": "Harmful pattern of use of volatile inhalants"
    },
    {
        "id": "1J1C",
        "text": "Harmful pattern of use of dissociative drugs, including ketamine or PCP"
    },
    {
        "id": "1J1D",
        "text": "Harmful pattern of use of other specified psychoactive substance"
    },
    {
        "id": "1J1E",
        "text": "Harmful pattern of use of unknown or unspecified psychoactive substance"
    },
    {
        "id": "1J1G",
        "text": "Harmful pattern of use of non-psychoactive substances"
    },
    {
        "id": "1J26",
        "text": "Harmful pattern of use of alcohol, episodic"
    },
    {
        "id": "1J27",
        "text": "Harmful pattern of use of opioids, episodic"
    },
    {
        "id": "1J2A",
        "text": "Harmful pattern of use of sedatives, hypnotics or anxiolytics, episodic"
    },
    {
        "id": "1J29",
        "text": "Harmful pattern of use of cocaine, episodic"
    },
    {
        "id": "1J2C",
        "text": "Harmful pattern of use of caffeine, episodic"
    },
    {
        "id": "1J2D",
        "text": "Harmful pattern of use of hallucinogens, episodic"
    },
    {
        "id": "1J2E",
        "text": "Harmful pattern of use of nicotine, episodic"
    },
    {
        "id": "1J2F",
        "text": "Harmful pattern of use of volatile inhalants, episodic"
    },
    {
        "id": "1J2H",
        "text": "Harmful pattern of use of dissociative drugs including ketamine or PCP, episodic"
    },
    {
        "id": "1J2I",
        "text": "Harmful pattern of use of other specified psychoactive substance, episodic"
    },
    {
        "id": "1J2J",
        "text": "Harmful pattern of use of unknown or unspecified psychoactive substance, episodic"
    },
    {
        "id": "1J2K",
        "text": "Harmful pattern of use of non-psychoactive substances, episodic"
    },
    {
        "id": "1J2L",
        "text": "Harmful pattern of use of alcohol, continuous"
    },
    {
        "id": "1J2M",
        "text": "Harmful pattern of use of opioids, continuous"
    },
    {
        "id": "1J2O",
        "text": "Harmful pattern of use of sedatives, hypnotics or anxiolytics, continuous"
    },
    {
        "id": "1J2P",
        "text": "Harmful pattern of use of cocaine, continuous"
    },
    {
        "id": "1J2R",
        "text": "Harmful pattern of use of caffeine, continuous"
    },
    {
        "id": "1J2S",
        "text": "Harmful pattern of use of hallucinogens, continuous"
    },
    {
        "id": "1J2T",
        "text": "Harmful pattern of use of nicotine, continuous"
    },
    {
        "id": "1J2U",
        "text": "Harmful pattern of use of volatile inhalants, continuous"
    },
    {
        "id": "1J2X",
        "text": "Harmful pattern of use of dissociative drugs including ketamine or PCP, continuous"
    },
    {
        "id": "1J2Y",
        "text": "Harmful pattern of use of other specified psychoactive substance, continuous"
    },
    {
        "id": "1J2Z",
        "text": "Harmful pattern of use of unknown or unspecified psychoactive substance, continuous"
    },
    {
        "id": "1J30",
        "text": "Harmful pattern of use of non-psychoactive substances, continuous"
    },
    {
        "id": "1J4B",
        "text": "Disorders due to use of opioids"
    },
    {
        "id": "1I8E",
        "text": "Cannabis intoxication"
    },
    {
        "id": "1J13",
        "text": "Harmful pattern of use of cannabis"
    },
    {
        "id": "1I92",
        "text": "Episode of harmful use of cannabis"
    },
    {
        "id": "1J28",
        "text": "Harmful pattern of use of cannabis, episodic"
    },
    {
        "id": "1J2N",
        "text": "Harmful pattern of use of cannabis, continuous"
    },
    {
        "id": "1J4E",
        "text": "Cannabis dependence, current use"
    },
    {
        "id": "1J4D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J4F",
        "text": "Cannabis dependence, early full remission"
    },
    {
        "id": "1J4G",
        "text": "Cannabis dependence, sustained partial remission"
    },
    {
        "id": "1J4H",
        "text": "Cannabis dependence, sustained full remission"
    },
    {
        "id": "1J4I",
        "text": "Cannabis withdrawal"
    },
    {
        "id": "1J4J",
        "text": "Cannabis-induced delirium"
    },
    {
        "id": "1J4K",
        "text": "Cannabis-induced psychotic disorder"
    },
    {
        "id": "1J4L",
        "text": "Benign focal seizures of adolescence"
    },
    {
        "id": "1J4M",
        "text": "Cannabis-induced anxiety disorder"
    },
    {
        "id": "1J4N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1I8I",
        "text": "Stimulant intoxication including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J16",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1I95",
        "text": "Episode of harmful use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J2B",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone, episodic"
    },
    {
        "id": "1J2Q",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone, continuous"
    },
    {
        "id": "1J37",
        "text": "Stimulant dependence including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J4U",
        "text": "Stimulant withdrawal including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J4V",
        "text": "Stimulant-induced delirium including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J4W",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J4X",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone with hallucinations"
    },
    {
        "id": "1J4Y",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone with delusions"
    },
    {
        "id": "1J4Z",
        "text": "Stimulant-induced psychotic disorder including amphetamines but excluding caffeine or cocaine with mixed psychotic symptoms"
    },
    {
        "id": "1J50",
        "text": "Stimulant-induced mood disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J51",
        "text": "Stimulant-induced mood disorder including amphetamines, methamphetamine or methcathinone with depressive symptoms"
    },
    {
        "id": "1J54",
        "text": "Stimulant-induced anxiety disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J55",
        "text": "Stimulant-induced obsessive-compulsive or related disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J56",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J57",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J31",
        "text": "Alcohol dependence"
    },
    {
        "id": "1J32",
        "text": "Alcohol dependence, current use, continuous"
    },
    {
        "id": "1J33",
        "text": "Opioid dependence"
    },
    {
        "id": "1J34",
        "text": "Cannabis dependence"
    },
    {
        "id": "1J35",
        "text": "Sedative, hypnotic or anxiolytic dependence"
    },
    {
        "id": "1J36",
        "text": "Cocaine dependence"
    },
    {
        "id": "1J38",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J39",
        "text": "Hallucinogen dependence"
    },
    {
        "id": "1J3A",
        "text": "Nicotine dependence"
    },
    {
        "id": "1J3B",
        "text": "Volatile inhalant dependence"
    },
    {
        "id": "1J3D",
        "text": "Dissociative drug dependence including ketamine or PCP"
    },
    {
        "id": "1J3F",
        "text": "Other specified psychoactive substance dependence"
    },
    {
        "id": "1J3G",
        "text": "Unknown or unspecified psychoactive substance dependence"
    },
    {
        "id": "1J58",
        "text": "Disorders due to use of hallucinogens"
    },
    {
        "id": "1J1B",
        "text": "Harmful pattern of use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1I9A",
        "text": "Episode of harmful use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1J3C",
        "text": "MDMA or related drug dependence, including MDA"
    },
    {
        "id": "1J5C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J5A",
        "text": "Disorders due to use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1J5B",
        "text": "MDMA or related drug intoxication, including MDA"
    },
    {
        "id": "1J2G",
        "text": "Harmful use of MDMA or related drugs, including MDA, episodic"
    },
    {
        "id": "1J2W",
        "text": "Harmful use of MDMA or related drugs, including MDA, continuous"
    },
    {
        "id": "1J5D",
        "text": "MDMA or related drug dependence, including MDA, current use"
    },
    {
        "id": "1J5E",
        "text": "MDMA or related drug dependence, including MDA, early full remission"
    },
    {
        "id": "1J5F",
        "text": "MDMA or related drug dependence, including MDA, sustained partial remission"
    },
    {
        "id": "1J5G",
        "text": "MDMA or related drug dependence, including MDA, sustained full remission"
    },
    {
        "id": "1J5H",
        "text": "MDMA or related drug-induced delirium, including MDA"
    },
    {
        "id": "1J5I",
        "text": "MDMA or related drug-induced psychotic disorder, including MDA"
    },
    {
        "id": "1J5J",
        "text": "MDMA or related drug-induced mood disorder, including MDA"
    },
    {
        "id": "1J5L",
        "text": "Progressive myoclonic epilepsy"
    },
    {
        "id": "1J52",
        "text": "Stimulant-induced mood disorder including amphetamines but excluding caffeine or cocaine with manic symptoms"
    },
    {
        "id": "1J5M",
        "text": "Progressive myoclonic epilepsy type 1"
    },
    {
        "id": "1J5N",
        "text": "Progressive myoclonic epilepsy type 2"
    },
    {
        "id": "1J5O",
        "text": "Progressive myoclonic epilepsy type 3"
    },
    {
        "id": "1J5P",
        "text": "Progressive myoclonic epilepsy type 6"
    },
    {
        "id": "1J5Q",
        "text": "MDMA or related drug-induced mood disorder, with manic symptoms"
    },
    {
        "id": "1J5K",
        "text": "MDMA or related drug-induced mood disorder, with manic symptoms"
    },
    {
        "id": "1J5R",
        "text": "MDMA or related drug-induced mood disorder, with depressive symptoms"
    },
    {
        "id": "1J53",
        "text": "Stimulant-induced mood disorder including amphetamines, methamphetamine or methcathinone with mixed depressive and manic symptoms"
    },
    {
        "id": "1J5S",
        "text": "MDMA or related drug-induced mood disorder, with mixed depressive and manic symptoms"
    },
    {
        "id": "1J5T",
        "text": "MDMA or related drug-induced anxiety disorder"
    },
    {
        "id": "1J5U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J5V",
        "text": "Disorders due to use of dissociative drugs including ketamine and phencyclidine [PCP]"
    },
    {
        "id": "1J59",
        "text": "Disorders due to use of volatile inhalants"
    },
    {
        "id": "1J4T",
        "text": "Stimulant dependence including amphetamines, methamphetamine or methcathinone, sustained full remission"
    },
    {
        "id": "1J4S",
        "text": "Stimulant dependence including amphetamines, methamphetamine or methcathinone, sustained partial remission"
    },
    {
        "id": "1J4R",
        "text": "Stimulant dependence including amphetamines, methamphetamine or methcathinone, early full remission"
    },
    {
        "id": "1J4Q",
        "text": "Stimulant dependence including amphetamines, methamphetamine or methcathinone, current use"
    },
    {
        "id": "1J4P",
        "text": "Disorders due to use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1J4O",
        "text": "Disorders due to use of cocaine"
    },
    {
        "id": "1J5W",
        "text": "Disorders due to use of caffeine"
    },
    {
        "id": "1J4C",
        "text": "Disorders due to use of cannabis"
    },
    {
        "id": "1J5X",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1J5Y",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1J5Z",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1J60",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1J61",
        "text": "Conditions with disorders of intellectual development as a relevant clinical feature"
    },
    {
        "id": "1J62",
        "text": "Developmental speech fluency disorder"
    },
    {
        "id": "1J63",
        "text": "Autism spectrum disorder without disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "1J64",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J67",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J68",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language with loss of previously acquired skills"
    },
    {
        "id": "1J69",
        "text": "Autism spectrum disorder with disorder of intellectual development and impaired functional language without loss of previously acquired skills"
    },
    {
        "id": "1J6A",
        "text": "Developmental learning disorder with impairment in reading"
    },
    {
        "id": "1J6B",
        "text": "Developmental learning disorder with impairment in written expression"
    },
    {
        "id": "1J6C",
        "text": "Developmental learning disorder with impairment in mathematics"
    },
    {
        "id": "1J65",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J66",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J6D",
        "text": "Secondary motor or phonic tics, not elsewhere classified"
    },
    {
        "id": "1J6F",
        "text": "Attention deficit hyperactivity disorder, predominantly hyperactive-impulsive presentation"
    },
    {
        "id": "1J6G",
        "text": "Attention deficit hyperactivity disorder, predominantly inattentive presentation"
    },
    {
        "id": "1J6H",
        "text": "Attention deficit hyperactivity disorder, combined presentation"
    },
    {
        "id": "1J6E",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "1J6I",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "1J6J",
        "text": "Tourette syndrome"
    },
    {
        "id": "1J6K",
        "text": "Obsessive-compulsive or related disorders"
    },
    {
        "id": "1J6M",
        "text": "Obsessive-compulsive disorder with fair to good insight"
    },
    {
        "id": "1J6N",
        "text": "Body dysmorphic disorder with fair to good insight"
    },
    {
        "id": "1J6O",
        "text": "Olfactory reference disorder with fair to good insight"
    },
    {
        "id": "1J6P",
        "text": "Hypochondriasis with fair to good insight"
    },
    {
        "id": "1J6Q",
        "text": "Hoarding disorder with fair to good insight"
    },
    {
        "id": "1J6R",
        "text": "Obsessive-compulsive disorder with poor to absent insight"
    },
    {
        "id": "1J6S",
        "text": "Body dysmorphic disorder with poor to absent insight"
    },
    {
        "id": "1J6T",
        "text": "Olfactory reference disorder with poor to absent insight"
    },
    {
        "id": "1J6U",
        "text": "Hypochondriasis with poor to absent insight"
    },
    {
        "id": "1J6V",
        "text": "Hoarding disorder with poor to absent insight"
    },
    {
        "id": "1J6W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J6X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J6Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J6Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J70",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J6L",
        "text": "Obsessive-compulsive disorder"
    },
    {
        "id": "1J72",
        "text": "Olfactory reference disorder"
    },
    {
        "id": "1J71",
        "text": "Body dysmorphic disorder"
    },
    {
        "id": "1J73",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J74",
        "text": "Hypochondriasis"
    },
    {
        "id": "1J75",
        "text": "Hoarding disorder"
    },
    {
        "id": "1J76",
        "text": "Trichotillomania"
    },
    {
        "id": "1J77",
        "text": "Excoriation disorder"
    },
    {
        "id": "1J79",
        "text": "Schizophrenia, first episode, currently symptomatic"
    },
    {
        "id": "1J78",
        "text": "Schizophrenia, first episode, in partial remission"
    },
    {
        "id": "1J7A",
        "text": "Schizophrenia, first episode, in full remission"
    },
    {
        "id": "1J7B",
        "text": "Schizophrenia, multiple episodes, currently symptomatic"
    },
    {
        "id": "1J7C",
        "text": "Schizophrenia, multiple episodes, in partial remission"
    },
    {
        "id": "1J7D",
        "text": "Schizophrenia, multiple episodes, in full remission"
    },
    {
        "id": "1J7E",
        "text": "Schizophrenia, continuous"
    },
    {
        "id": "1J7F",
        "text": "Schizophrenia, continuous, currently symptomatic"
    },
    {
        "id": "1J7G",
        "text": "Schizophrenia, continuous, in partial remission"
    },
    {
        "id": "1J7H",
        "text": "Schizophrenia, continuous, in full remission"
    },
    {
        "id": "1J7I",
        "text": "Schizoaffective disorder, first episode, currently symptomatic"
    },
    {
        "id": "1J7J",
        "text": "Schizoaffective disorder, first episode, in partial remission"
    },
    {
        "id": "1J7K",
        "text": "Schizoaffective disorder, first episode, in full remission"
    },
    {
        "id": "1J7L",
        "text": "Schizoaffective disorder, multiple episodes, currently symptomatic"
    },
    {
        "id": "1J7M",
        "text": "Schizoaffective disorder, multiple episodes, in partial remission"
    },
    {
        "id": "1J7N",
        "text": "Schizoaffective disorder, multiple episodes, in full remission"
    },
    {
        "id": "1J7O",
        "text": "Schizoaffective disorder, continuous"
    },
    {
        "id": "1J7P",
        "text": "Schizoaffective disorder, continuous, currently symptomatic"
    },
    {
        "id": "1J7Q",
        "text": "Schizoaffective disorder, continuous, in partial remission"
    },
    {
        "id": "1J7R",
        "text": "Schizoaffective disorder, continuous, in full remission"
    },
    {
        "id": "1J7S",
        "text": "Acute and transient psychotic disorder, first episode, currently symptomatic"
    },
    {
        "id": "1J7T",
        "text": "Acute and transient psychotic disorder, first episode, in partial remission"
    },
    {
        "id": "1J7U",
        "text": "Acute and transient psychotic disorder, first episode, in full remission"
    },
    {
        "id": "1J7V",
        "text": "Acute and transient psychotic disorder, multiple episodes, currently symptomatic"
    },
    {
        "id": "1J7W",
        "text": "Acute and transient psychotic disorder, multiple episodes, in partial remission"
    },
    {
        "id": "1J7X",
        "text": "Acute and transient psychotic disorder, multiple episodes, in full remission"
    },
    {
        "id": "1J7Y",
        "text": "Delusional disorder, currently symptomatic"
    },
    {
        "id": "1J7Z",
        "text": "Delusional disorder, in partial remission"
    },
    {
        "id": "1J80",
        "text": "Delusional disorder, in full remission"
    },
    {
        "id": "1J81",
        "text": "Bipolar type II disorder"
    },
    {
        "id": "1J82",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "1J83",
        "text": "Oppositional defiant disorder with chronic irritability-anger"
    },
    {
        "id": "1J84",
        "text": "Oppositional defiant disorder without chronic irritability-anger"
    },
    {
        "id": "1J85",
        "text": "Oppositional defiant disorder with chronic irritability-anger with limited prosocial emotions"
    },
    {
        "id": "1J86",
        "text": "Oppositional defiant disorder with chronic irritability-anger with typical prosocial emotions"
    },
    {
        "id": "1J87",
        "text": "Oppositional defiant disorder without chronic irritability-anger with limited prosocial emotions"
    },
    {
        "id": "1J88",
        "text": "Oppositional defiant disorder without chronic irritability-anger with typical prosocial emotions"
    },
    {
        "id": "1J8A",
        "text": "Conduct-dissocial disorder, childhood onset with typical prosocial emotions"
    },
    {
        "id": "1J8B",
        "text": "Conduct-dissocial disorder, adolescent onset with limited prosocial emotions"
    },
    {
        "id": "1J8C",
        "text": "Conduct-dissocial disorder, adolescent onset with typical prosocial emotions"
    },
    {
        "id": "1J8D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J8E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J8G",
        "text": "Diverticular disease of large intestine"
    },
    {
        "id": "1J8H",
        "text": "Fear of disease"
    },
    {
        "id": "1J8I",
        "text": "Mild bodily distress disorder"
    },
    {
        "id": "1J8J",
        "text": "Moderate bodily distress disorder"
    },
    {
        "id": "1J8K",
        "text": "Severe bodily distress disorder"
    },
    {
        "id": "1J8M",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1J8L",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1J8N",
        "text": "Intermittent explosive disorder"
    },
    {
        "id": "1J8O",
        "text": "Impulse control disorders"
    },
    {
        "id": "1J8R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J8S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J8T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J8U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J8V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J8W",
        "text": "Dissociative amnesia"
    },
    {
        "id": "1J8X",
        "text": "Depersonalization-derealization disorder"
    },
    {
        "id": "1J8Y",
        "text": "Partial dissociative identity disorder"
    },
    {
        "id": "1J8Z",
        "text": "Trance disorder"
    },
    {
        "id": "1J90",
        "text": "Possession trance disorder"
    },
    {
        "id": "1J91",
        "text": "Dissociative disorders"
    },
    {
        "id": "1J92",
        "text": "Dissociative disorders"
    },
    {
        "id": "1J93",
        "text": "Dissociative disorders"
    },
    {
        "id": "1J94",
        "text": "Secondary dissociative syndrome"
    },
    {
        "id": "1J95",
        "text": "Personality disorder"
    },
    {
        "id": "1J96",
        "text": "Mild personality disorder"
    },
    {
        "id": "1J97",
        "text": "Moderate personality disorder"
    },
    {
        "id": "1J98",
        "text": "Severe personality disorder"
    },
    {
        "id": "1J99",
        "text": "Fear of disease"
    },
    {
        "id": "1J9A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9B",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1J9D",
        "text": "Panic disorder"
    },
    {
        "id": "1J9E",
        "text": "Depersonalization-derealization disorder"
    },
    {
        "id": "1J9F",
        "text": "Conduct-dissocial disorder"
    },
    {
        "id": "1J9G",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1J9J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1J9H",
        "text": "Dementia"
    },
    {
        "id": "1J9R",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "1J9S",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "1J9U",
        "text": "Delirium"
    },
    {
        "id": "1J9W",
        "text": "Delirium due to psychoactive substances including medications"
    },
    {
        "id": "1J9Y",
        "text": "Opioid-induced delirium"
    },
    {
        "id": "1K00",
        "text": "Sedative, hypnotic or anxiolytic-induced delirium"
    },
    {
        "id": "1K01",
        "text": "Cocaine-induced delirium"
    },
    {
        "id": "1J9X",
        "text": "Alcohol-induced delirium"
    },
    {
        "id": "1K02",
        "text": "Hallucinogen-induced delirium"
    },
    {
        "id": "1K03",
        "text": "Volatile inhalant-induced delirium"
    },
    {
        "id": "1K04",
        "text": "MDMA or related drug-induced delirium, including MDA"
    },
    {
        "id": "1K05",
        "text": "Dissociative drug-induced delirium including ketamine or PCP"
    },
    {
        "id": "1K06",
        "text": "Delirium induced by other specified psychoactive substance including medications"
    },
    {
        "id": "1K07",
        "text": "Delirium induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1K08",
        "text": "Stimulant-induced delirium including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1K0A",
        "text": "Delirium due to multiple etiological factors"
    },
    {
        "id": "1K0B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K0C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K0D",
        "text": "Amnestic disorder"
    },
    {
        "id": "1K0F",
        "text": "Amnestic disorder due to diseases classified elsewhere"
    },
    {
        "id": "1K0G",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "1K0H",
        "text": "Amnestic disorder due to use of alcohol"
    },
    {
        "id": "1K0I",
        "text": "Amnestic disorder due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1K0J",
        "text": "Amnestic disorder due to multiple aetiological factors"
    },
    {
        "id": "1K0K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K0L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K0N",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "1K0O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K0P",
        "text": "Dissociative amnesia"
    },
    {
        "id": "1K0T",
        "text": "Dissociative neurological symptom disorder, with non-epileptic seizures"
    },
    {
        "id": "1K0U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K0V",
        "text": "Dissociative amnesia"
    },
    {
        "id": "1K0W",
        "text": "Synthetic cannabinoid intoxication"
    },
    {
        "id": "1K0X",
        "text": "Episode of harmful use of synthetic cannabinoids"
    },
    {
        "id": "1K0Y",
        "text": "Episode of harmful use of synthetic cannabinoids"
    },
    {
        "id": "1K0Z",
        "text": "Harmful pattern of use of synthetic cannabinoids"
    },
    {
        "id": "1K11",
        "text": "Harmful pattern of use of synthetic cannabinoids, continuous"
    },
    {
        "id": "1K12",
        "text": "Mild personality disorder"
    },
    {
        "id": "1K13",
        "text": "Moderate personality disorder"
    },
    {
        "id": "1K14",
        "text": "Severe personality disorder"
    },
    {
        "id": "1K15",
        "text": "Synthetic cannabinoid dependence"
    },
    {
        "id": "1K16",
        "text": "Synthetic cannabinoid dependence, current use"
    },
    {
        "id": "1K17",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K18",
        "text": "Synthetic cannabinoid dependence, early full remission"
    },
    {
        "id": "1K19",
        "text": "Synthetic cannabinoid dependence, sustained partial remission"
    },
    {
        "id": "1K1A",
        "text": "Synthetic cannabinoid dependence, sustained full remission"
    },
    {
        "id": "1K1B",
        "text": "Synthetic cannabinoid withdrawal"
    },
    {
        "id": "1J9Z",
        "text": "Cannabis-induced delirium"
    },
    {
        "id": "1K1C",
        "text": "Synthetic cannabinoid-induced delirium"
    },
    {
        "id": "1K1D",
        "text": "Synthetic cannabinoid-induced psychotic disorder"
    },
    {
        "id": "1K1E",
        "text": "Synthetic cannabinoid-induced anxiety disorder"
    },
    {
        "id": "1K1F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K1G",
        "text": "Disorders due to use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1K1H",
        "text": "Stimulant intoxication including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1K1I",
        "text": "Synthetic cathinone intoxication"
    },
    {
        "id": "1K1J",
        "text": "Harmful pattern of use of synthetic cathinones"
    },
    {
        "id": "1K1K",
        "text": "Episode of harmful use of synthetic cathinones"
    },
    {
        "id": "1K1L",
        "text": "Episode of harmful use of synthetic cathinones"
    },
    {
        "id": "1K1M",
        "text": "Harmful pattern of use of synthetic cathinones, episodic"
    },
    {
        "id": "1K10",
        "text": "Harmful pattern of use of synthetic cannabinoids, episodic"
    },
    {
        "id": "1K1N",
        "text": "Harmful use of synthetic cathinones, continuous"
    },
    {
        "id": "1K1O",
        "text": "Synthetic cathinone dependence"
    },
    {
        "id": "1K1P",
        "text": "Synthetic cathinone dependence, current use"
    },
    {
        "id": "1K1Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K1R",
        "text": "Synthetic cathinone dependence, early full remission"
    },
    {
        "id": "1K1S",
        "text": "Synthetic cathinone dependence, sustained partial remission"
    },
    {
        "id": "1K1T",
        "text": "Synthetic cathinone dependence, sustained full remission"
    },
    {
        "id": "1K09",
        "text": "Stimulant-induced delirium including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1K1U",
        "text": "Synthetic cathinone withdrawal"
    },
    {
        "id": "1K1V",
        "text": "Synthetic cathinone-induced delirium"
    },
    {
        "id": "1K1W",
        "text": "Synthetic cathinone-induced psychotic disorder"
    },
    {
        "id": "1K1X",
        "text": "Synthetic cathinone-induced psychotic disorder with hallucinations"
    },
    {
        "id": "1K1Y",
        "text": "Synthetic cathinone-induced psychotic disorder with delusions"
    },
    {
        "id": "1K1Z",
        "text": "Synthetic cathinone-induced psychotic disorder with mixed psychotic symptoms"
    },
    {
        "id": "1K20",
        "text": "Synthetic cathinone-induced mood disorder"
    },
    {
        "id": "1K21",
        "text": "Synthetic cathinone-induced mood disorder with depressive symptoms"
    },
    {
        "id": "1K22",
        "text": "Synthetic cathinone-induced mood disorder with manic symptoms"
    },
    {
        "id": "1K23",
        "text": "Synthetic cathinone-induced mood disorder with mixed depressive and manic symptoms"
    },
    {
        "id": "1K24",
        "text": "Synthetic cathinone-induced anxiety disorder"
    },
    {
        "id": "1K25",
        "text": "Synthetic cathinone-induced obsessive-compulsive or related syndrome"
    },
    {
        "id": "1K26",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K27",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K28",
        "text": "Cutaneous sarcoma"
    },
    {
        "id": "1K29",
        "text": "Synthetic cannabinoid-induced delirium"
    },
    {
        "id": "1K2A",
        "text": "Synthetic cathinone-induced delirium"
    },
    {
        "id": "1K2B",
        "text": "Delirium due to psychoactive substances including medications"
    },
    {
        "id": "1H7Y",
        "text": "Pemphigus vulgaris"
    },
    {
        "id": "1K2C",
        "text": "Inflammatory bowel diseases"
    },
    {
        "id": "1K2D",
        "text": "Disorders of intellectual development"
    },
    {
        "id": "1K2E",
        "text": "Disorder of intellectual development, mild"
    },
    {
        "id": "1K2F",
        "text": "Disorder of intellectual development, moderate"
    },
    {
        "id": "1K2G",
        "text": "Disorder of intellectual development, severe"
    },
    {
        "id": "1K2H",
        "text": "Disorder of intellectual development, profound"
    },
    {
        "id": "1K2I",
        "text": "Disorder of intellectual development, provisional"
    },
    {
        "id": "1K2K",
        "text": "Developmental language disorder"
    },
    {
        "id": "1K2L",
        "text": "Developmental language disorder with impairment of receptive and expressive language"
    },
    {
        "id": "1K2M",
        "text": "Developmental language disorder with impairment of mainly expressive language"
    },
    {
        "id": "1K2N",
        "text": "Selective mutism"
    },
    {
        "id": "1K2O",
        "text": "Selective mutism"
    },
    {
        "id": "1K2Q",
        "text": "Autism spectrum disorder without disorder of intellectual development and with mild or no impairment of functional language"
    },
    {
        "id": "1K2R",
        "text": "Autism spectrum disorder with disorder of intellectual development and with mild or no impairment of functional language"
    },
    {
        "id": "1K2S",
        "text": "Autism spectrum disorder without disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "1K2T",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "1K2P",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1K2Y",
        "text": "Autism spectrum disorder with disorder of intellectual development and with mild or no impairment of functional language with loss of previously acquired skills"
    },
    {
        "id": "1K2Z",
        "text": "Autism spectrum disorder with disorder of intellectual development and with mild or no impairment of functional language without loss of previously acquired skills"
    },
    {
        "id": "1K2W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K2X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K30",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K32",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K33",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K34",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K35",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K31",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K36",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K37",
        "text": "Autism spectrum disorder with disorder of intellectual development and with absence of functional language"
    },
    {
        "id": "1K38",
        "text": "Autism spectrum disorder with disorder of intellectual development and absence of functional language with loss of previously acquired skills"
    },
    {
        "id": "1K39",
        "text": "Autism spectrum disorder with disorder of intellectual development and absence of functional language without loss of previously acquired skills"
    },
    {
        "id": "1K3A",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language with loss of previously acquired skills"
    },
    {
        "id": "1K3B",
        "text": "Autism spectrum disorder with disorder of intellectual development and impaired functional language without loss of previously acquired skills"
    },
    {
        "id": "1K2V",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1K2U",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1K2J",
        "text": "Developmental speech or language disorders"
    },
    {
        "id": "1K3C",
        "text": "Developmental learning disorder with impairment in reading"
    },
    {
        "id": "1K3E",
        "text": "Developmental learning disorder with impairment in mathematics"
    },
    {
        "id": "1K3F",
        "text": "Developmental learning disorder with other specified impairment of learning"
    },
    {
        "id": "1K3G",
        "text": "Developmental motor coordination disorder"
    },
    {
        "id": "1K3I",
        "text": "Attention deficit hyperactivity disorder, predominantly inattentive presentation"
    },
    {
        "id": "1K3H",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "1K3J",
        "text": "Attention deficit hyperactivity disorder, predominantly hyperactive-impulsive presentation"
    },
    {
        "id": "1K3K",
        "text": "Attention deficit hyperactivity disorder, combined presentation"
    },
    {
        "id": "1K3L",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "1K3M",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1K3N",
        "text": "Mood disorders"
    },
    {
        "id": "1K3O",
        "text": "Bipolar or related disorders"
    },
    {
        "id": "1K3D",
        "text": "Developmental learning disorder with impairment in written expression"
    },
    {
        "id": "1K3P",
        "text": "Panic disorder"
    },
    {
        "id": "1K3S",
        "text": "Body dysmorphic disorder"
    },
    {
        "id": "1K3T",
        "text": "Hypochondriasis"
    },
    {
        "id": "1K3U",
        "text": "Hoarding disorder"
    },
    {
        "id": "1K3W",
        "text": "Reactive attachment disorder"
    },
    {
        "id": "1K3X",
        "text": "Disinhibited social engagement disorder"
    },
    {
        "id": "1K3V",
        "text": "Adjustment disorder"
    },
    {
        "id": "1K41",
        "text": "Severe bodily distress disorder"
    },
    {
        "id": "1K43",
        "text": "Bipolar type II disorder, current episode hypomanic"
    },
    {
        "id": "1K44",
        "text": "Bipolar type II disorder, current episode depressive, mild"
    },
    {
        "id": "1K45",
        "text": "Bipolar type II disorder, current episode depressive, moderate without psychotic symptoms"
    },
    {
        "id": "1K42",
        "text": "Bipolar type I disorder"
    },
    {
        "id": "1K46",
        "text": "Bipolar type II disorder, current episode depressive, moderate with psychotic symptoms"
    },
    {
        "id": "1K47",
        "text": "Bipolar type II disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "1K48",
        "text": "Bipolar type II disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "1K49",
        "text": "Bipolar type I disorder, current episode hypomanic"
    },
    {
        "id": "1K4A",
        "text": "Bipolar type I disorder, current episode depressive, mild"
    },
    {
        "id": "1K4B",
        "text": "Bipolar type I disorder, current episode depressive, moderate without psychotic symptoms"
    },
    {
        "id": "1K4C",
        "text": "Bipolar type I disorder, current episode depressive, moderate with psychotic symptoms"
    },
    {
        "id": "1K4D",
        "text": "Bipolar type I disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "1K4E",
        "text": "Bipolar type I disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "1K4F",
        "text": "Bipolar type I disorder, current episode mixed, without psychotic symptoms"
    },
    {
        "id": "1K4G",
        "text": "Bipolar type I disorder, current episode mixed, with psychotic symptoms"
    },
    {
        "id": "1K4H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K4I",
        "text": "Bipolar type II disorder, currently in full remission"
    },
    {
        "id": "1K4J",
        "text": "Single episode depressive disorder"
    },
    {
        "id": "1K4K",
        "text": "Single episode depressive disorder, moderate, without psychotic symptoms"
    },
    {
        "id": "1K4L",
        "text": "Single episode depressive disorder, moderate, with psychotic symptoms"
    },
    {
        "id": "1K4M",
        "text": "Single episode depressive disorder, severe, without psychotic symptoms"
    },
    {
        "id": "1K4N",
        "text": "Single episode depressive disorder, severe, with psychotic symptoms"
    },
    {
        "id": "1K4O",
        "text": "Recurrent depressive disorder"
    },
    {
        "id": "1K4P",
        "text": "Recurrent depressive disorder, current episode mild"
    },
    {
        "id": "1K4Q",
        "text": "Recurrent depressive disorder, current episode moderate, without psychotic symptoms"
    },
    {
        "id": "1K4R",
        "text": "Recurrent depressive disorder, current episode moderate, with psychotic symptoms"
    },
    {
        "id": "1K4S",
        "text": "Recurrent depressive disorder, current episode severe, without psychotic symptoms"
    },
    {
        "id": "1K4T",
        "text": "Recurrent depressive disorder, current episode severe, with psychotic symptoms"
    },
    {
        "id": "1K3Q",
        "text": "Agoraphobia"
    },
    {
        "id": "1K4U",
        "text": "Obsessive-compulsive disorder"
    },
    {
        "id": "1K4V",
        "text": "Olfactory reference disorder"
    },
    {
        "id": "1K4W",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "1K4X",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1K4Y",
        "text": "Mild bodily distress disorder"
    },
    {
        "id": "1K3Z",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1K40",
        "text": "Moderate bodily distress disorder"
    },
    {
        "id": "1K51",
        "text": "Developmental language disorder with impairment of mainly pragmatic language"
    },
    {
        "id": "1K53",
        "text": "Dementia"
    },
    {
        "id": "1K55",
        "text": "Dissociative neurological symptom disorder, with paresis or weakness"
    },
    {
        "id": "1K56",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1K57",
        "text": "Dissociative neurological symptom disorder, with gait disturbance"
    },
    {
        "id": "1K58",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K59",
        "text": "Dissociative neurological symptom disorder, with visual disturbance"
    },
    {
        "id": "1K5A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K5C",
        "text": "Dissociative neurological symptom disorder, with speech disturbance"
    },
    {
        "id": "1K50",
        "text": "Dissociative neurological symptom disorder, with vertigo or dizziness"
    },
    {
        "id": "1K5B",
        "text": "Dissociative neurological symptom disorder, with auditory disturbance"
    },
    {
        "id": "1K3Y",
        "text": "Dissociative neurological symptom disorder, with cognitive symptoms"
    },
    {
        "id": "1K4Z",
        "text": "Dissociative neurological symptom disorder, with other sensory disturbance"
    },
    {
        "id": "1K54",
        "text": "Dissociative neurological symptom disorder, with non-epileptic seizures"
    },
    {
        "id": "1K3R",
        "text": "Specific phobia"
    },
    {
        "id": "1K5D",
        "text": "Possession trance disorder"
    },
    {
        "id": "1K5E",
        "text": "Anorexia Nervosa with significantly low body weight"
    },
    {
        "id": "1K5F",
        "text": "Anorexia Nervosa with dangerously low body weight"
    },
    {
        "id": "1K5G",
        "text": "Anorexia Nervosa with dangerously low body weight, binge-purge pattern"
    },
    {
        "id": "1K5H",
        "text": "Bulimia Nervosa"
    },
    {
        "id": "1K5I",
        "text": "Rumination-regurgitation disorder"
    },
    {
        "id": "1K5J",
        "text": "Elimination disorders"
    },
    {
        "id": "1K5K",
        "text": "Encopresis"
    },
    {
        "id": "1K5L",
        "text": "Alcohol-induced psychotic disorder"
    },
    {
        "id": "1K5M",
        "text": "Alcohol-induced psychotic disorder with hallucinations"
    },
    {
        "id": "1K5N",
        "text": "Alcohol-induced psychotic disorder with delusions"
    },
    {
        "id": "1K5O",
        "text": "Alcohol-induced psychotic disorder with mixed psychotic symptoms"
    },
    {
        "id": "1K5P",
        "text": "Alcohol-induced mood disorder with manic symptoms"
    },
    {
        "id": "1K5Q",
        "text": "Episode of harmful use of alcohol"
    },
    {
        "id": "1K5R",
        "text": "Synthetic cannabinoid intoxication"
    },
    {
        "id": "1K5S",
        "text": "Synthetic cannabinoid-induced delirium"
    },
    {
        "id": "1K5T",
        "text": "Synthetic cannabinoid-induced psychotic disorder"
    },
    {
        "id": "1K5U",
        "text": "Synthetic cannabinoid-induced anxiety disorder"
    },
    {
        "id": "1K5V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K5W",
        "text": "Opioid-induced mood disorder"
    },
    {
        "id": "1K5X",
        "text": "Sedative, hypnotic or anxiolytic intoxication"
    },
    {
        "id": "1K5Y",
        "text": "Harmful pattern of use of sedatives, hypnotics or anxiolytics, episodic"
    },
    {
        "id": "1K5Z",
        "text": "Sedative, hypnotic or anxiolytic-induced mood disorder with manic symptoms"
    },
    {
        "id": "1K60",
        "text": "Sedative, hypnotic or anxiolytic-induced mood disorder"
    },
    {
        "id": "1K61",
        "text": "Opioid-induced mood disorder, with manic symptoms"
    },
    {
        "id": "1K62",
        "text": "Opioid-induced mood disorder, with depressive symptoms"
    },
    {
        "id": "1K63",
        "text": "Opioid-induced mood disorder, with mixed depressive and manic symptoms"
    },
    {
        "id": "1K64",
        "text": "Opioid-induced mood disorder, with depressive symptoms"
    },
    {
        "id": "1K65",
        "text": "Cocaine-induced psychotic disorder with mixed psychotic symptoms"
    },
    {
        "id": "1K66",
        "text": "Cocaine-induced mood disorder with manic symptoms"
    },
    {
        "id": "1K67",
        "text": "Cocaine-induced mood disorder with mixed depressive and manic symptoms"
    },
    {
        "id": "1K68",
        "text": "Factitious disorder imposed on self"
    },
    {
        "id": "1K6A",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1K6B",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone, episodic"
    },
    {
        "id": "1K6C",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone, continuous"
    },
    {
        "id": "1K6D",
        "text": "Episode of harmful use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1K6E",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone with hallucinations"
    },
    {
        "id": "1K6F",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone with delusions"
    },
    {
        "id": "1K6G",
        "text": "Stimulant-induced psychotic disorder including amphetamines but excluding caffeine or cocaine with mixed psychotic symptoms"
    },
    {
        "id": "1K6H",
        "text": "Stimulant-induced mood disorder including amphetamines but excluding caffeine or cocaine with manic symptoms"
    },
    {
        "id": "1K6I",
        "text": "Synthetic cathinone-induced psychotic disorder with hallucinations"
    },
    {
        "id": "1K6J",
        "text": "Synthetic cathinone-induced psychotic disorder with delusions"
    },
    {
        "id": "1K6K",
        "text": "Synthetic cathinone-induced psychotic disorder with mixed psychotic symptoms"
    },
    {
        "id": "1K6L",
        "text": "Synthetic cathinone-induced mood disorder with manic symptoms"
    },
    {
        "id": "1K6M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K6N",
        "text": "Disorders due to use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1K6O",
        "text": "MDMA or related drug-induced delirium, including MDA"
    },
    {
        "id": "1K6P",
        "text": "MDMA or related drug-induced mood disorder, including MDA"
    },
    {
        "id": "1K6Q",
        "text": "Disorders due to use of dissociative drugs including ketamine and phencyclidine [PCP]"
    },
    {
        "id": "1K6R",
        "text": "Episode of harmful use of dissociative drugs including ketamine or PCP"
    },
    {
        "id": "1K6S",
        "text": "Harmful pattern of use of dissociative drugs, including ketamine or PCP"
    },
    {
        "id": "1K6T",
        "text": "Harmful pattern of use of dissociative drugs including ketamine or PCP, episodic"
    },
    {
        "id": "1K6U",
        "text": "Harmful pattern of use of dissociative drugs including ketamine or PCP, continuous"
    },
    {
        "id": "1K6V",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1K6W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K52",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1K6Y",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1K6Z",
        "text": "Schistosomiasis"
    },
    {
        "id": "1K71",
        "text": "Structural developmental anomalies of the peripheral vascular system"
    },
    {
        "id": "1K72",
        "text": "Pseudohypoparathyroidism"
    },
    {
        "id": "1K73",
        "text": "Nonorgan specific systemic autoimmune disorders"
    },
    {
        "id": "1K74",
        "text": "Amyloidosis"
    },
    {
        "id": "1K76",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K77",
        "text": "Conduct-dissocial disorder"
    },
    {
        "id": "1K78",
        "text": "Conduct-dissocial disorder, childhood onset"
    },
    {
        "id": "1K79",
        "text": "Conduct-dissocial disorder, adolescent onset"
    },
    {
        "id": "1K7F",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "1K7G",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "1K7H",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "1K7I",
        "text": "Paraneoplastic or autoimmune disorders of the nervous system"
    },
    {
        "id": "1K7O",
        "text": "Paraneoplastic or autoimmune neuromuscular transmission disorders"
    },
    {
        "id": "1K7M",
        "text": "Anti-NMDA receptor encephalitis"
    },
    {
        "id": "1K7L",
        "text": "Paraneoplastic limbic encephalitis"
    },
    {
        "id": "1K7K",
        "text": "Autoimmune limbic encephalitis"
    },
    {
        "id": "1K7J",
        "text": "Paraneoplastic or autoimmune disorders of the central nervous system, brain or spinal cord"
    },
    {
        "id": "1K7D",
        "text": "Locked-in syndrome"
    },
    {
        "id": "1K7C",
        "text": "Chemical meningitis"
    },
    {
        "id": "1K7B",
        "text": "Gerstmann syndrome"
    },
    {
        "id": "1K7A",
        "text": "Gerstmann syndrome"
    },
    {
        "id": "1K7P",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "1K7Q",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "1K7R",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "1K7S",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "1K7T",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "1K7N",
        "text": "Paraneoplastic or autoimmune disorders of the peripheral or autonomic nervous system"
    },
    {
        "id": "1K7X",
        "text": "Myasthenia gravis or certain specified neuromuscular junction disorders"
    },
    {
        "id": "1K7Y",
        "text": "Myasthenia gravis or certain specified neuromuscular junction disorders"
    },
    {
        "id": "1K7Z",
        "text": "Emery-Dreifuss muscular dystrophy"
    },
    {
        "id": "1K80",
        "text": "Facioscapulohumeral muscular dystrophy"
    },
    {
        "id": "1K81",
        "text": "Dominant limb-girdle muscular dystrophy"
    },
    {
        "id": "1K82",
        "text": "Recessive limb-girdle muscular dystrophy"
    },
    {
        "id": "1K83",
        "text": "Scapuloperoneal muscular dystrophy"
    },
    {
        "id": "1K84",
        "text": "Congenital muscular dystrophy"
    },
    {
        "id": "1K85",
        "text": "Congenital muscular dystrophy without central nervous system symptoms"
    },
    {
        "id": "1K86",
        "text": "Duchenne muscular dystrophy"
    },
    {
        "id": "1K87",
        "text": "Myotonic dystrophy"
    },
    {
        "id": "1K89",
        "text": "Myotonia congenita"
    },
    {
        "id": "1K8A",
        "text": "Drug-induced myotonia"
    },
    {
        "id": "1K8B",
        "text": "Neuromyotonia"
    },
    {
        "id": "1K8C",
        "text": "Pseudomyotonia"
    },
    {
        "id": "1K8D",
        "text": "Congenital myopathies"
    },
    {
        "id": "1K8E",
        "text": "Congenital myopathy with no structural abnormalities"
    },
    {
        "id": "1K8F",
        "text": "Mitochondrial myopathies"
    },
    {
        "id": "1K8G",
        "text": "Mitochondrial myopathy with cytochrome C oxidase deficiency"
    },
    {
        "id": "1K8H",
        "text": "Autosomal recessive cardiomyopathy or ophthalmoplegia"
    },
    {
        "id": "1K8I",
        "text": "Ocular myopathy"
    },
    {
        "id": "1K8J",
        "text": "Idiopathic rhabdomyolysis"
    },
    {
        "id": "1K8K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K8L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K8M",
        "text": "Malignant hyperthermia or hyperpyrexia"
    },
    {
        "id": "1K8N",
        "text": "Autoimmune myopathy"
    },
    {
        "id": "1K8O",
        "text": "Secondary rhabdomyolysis"
    },
    {
        "id": "1J05",
        "text": "Chronic postsurgical or post traumatic pain"
    },
    {
        "id": "1J04",
        "text": "Chronic postsurgical or post traumatic pain"
    },
    {
        "id": "1K8P",
        "text": "Chronic postsurgical or post traumatic pain"
    },
    {
        "id": "1K8R",
        "text": "Intracerebral haemorrhage"
    },
    {
        "id": "1K8S",
        "text": "Subarachnoid haemorrhage"
    },
    {
        "id": "1K8T",
        "text": "Subarachnoid haemorrhage not known if aneurysmal or non-aneurysmal"
    },
    {
        "id": "1K8U",
        "text": "Transient ischaemic attack"
    },
    {
        "id": "1K92",
        "text": "Cerebral ischaemic stroke due to small artery occlusion"
    },
    {
        "id": "1K93",
        "text": "Cerebral ischaemic stroke due to unspecified occlusion or stenosis of extracranial large artery"
    },
    {
        "id": "1K94",
        "text": "Cerebral ischaemic stroke due to unspecified occlusion or stenosis of intracranial large artery"
    },
    {
        "id": "1K95",
        "text": "Cerebral ischaemic stroke due to multiple potential causes"
    },
    {
        "id": "1K96",
        "text": "Cerebrovascular disease with no acute cerebral symptom"
    },
    {
        "id": "1K97",
        "text": "Certain specified cerebrovascular diseases"
    },
    {
        "id": "1K9A",
        "text": "Cerebral cavernous malformation"
    },
    {
        "id": "1K9B",
        "text": "Cerebral aneurysm, nonruptured"
    },
    {
        "id": "1K9C",
        "text": "Familial cerebral saccular aneurysm"
    },
    {
        "id": "1K9G",
        "text": "Personal history of diseases of the blood or blood-forming organs"
    },
    {
        "id": "1K9F",
        "text": "Personal history of diseases of the blood or blood-forming organs"
    },
    {
        "id": "1K8W",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "1K8V",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "1K8X",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "1K8Y",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "1K8Z",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "1K90",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "1K91",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "1K9I",
        "text": "Idiopathic intracranial hypertension"
    },
    {
        "id": "1K9H",
        "text": "Increased intracranial pressure"
    },
    {
        "id": "1K9J",
        "text": "Secondary intracranial hypertension"
    },
    {
        "id": "1K9K",
        "text": "Intracranial hypertension associated with intracranial venous thrombosis"
    },
    {
        "id": "1K9L",
        "text": "Brain herniation syndromes"
    },
    {
        "id": "1K9M",
        "text": "Hydrocephalus"
    },
    {
        "id": "1K9N",
        "text": "Communicating hydrocephalus"
    },
    {
        "id": "1K9O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K9P",
        "text": "Hydrocephalus due to structural malformations"
    },
    {
        "id": "1K9Q",
        "text": "Intracranial arachnoid cyst"
    },
    {
        "id": "1K9T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K9U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K9V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K9X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1K9Y",
        "text": "Idiopathic recurrent stupor"
    },
    {
        "id": "1L00",
        "text": "Microcystic lymphatic malformation"
    },
    {
        "id": "1L01",
        "text": "Microcystic lymphatic malformation"
    },
    {
        "id": "1L0N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L0O",
        "text": "Genetic syndromes with alopecia or hypotrichosis"
    },
    {
        "id": "1L0P",
        "text": "Congenital generalised hypertrichosis"
    },
    {
        "id": "1L0Q",
        "text": "Hirsutism and syndromes with hirsutism"
    },
    {
        "id": "1L0R",
        "text": "Grading Scale Value"
    },
    {
        "id": "1K0S",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "1L0T",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "1L0Y",
        "text": "Functional nausea or vomiting"
    },
    {
        "id": "1L0Z",
        "text": "Functional bloating"
    },
    {
        "id": "1L10",
        "text": "Functional abdominal pain syndrome"
    },
    {
        "id": "1L12",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L13",
        "text": "Examination or observation for suspected physical maltreatment"
    },
    {
        "id": "1L14",
        "text": "Examination or observation for suspected psychological maltreatment"
    },
    {
        "id": "1L15",
        "text": "Examination or observation for suspected neglect or abandonment"
    },
    {
        "id": "1H8N",
        "text": "Examination or observation for suspected sexual maltreatment"
    },
    {
        "id": "1H8T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H8R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H8O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L16",
        "text": "Personal or family history or late effect of prior health problems"
    },
    {
        "id": "1L17",
        "text": "Pattern, Activity, or Clinical Status"
    },
    {
        "id": "1L18",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L1A",
        "text": "Functional digestive disorders of infants, toddlers or children"
    },
    {
        "id": "1L1B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L1C",
        "text": "Infant regurgitation"
    },
    {
        "id": "1L1D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L1E",
        "text": "Functional diarrhoea of infants, toddlers or children"
    },
    {
        "id": "1L1F",
        "text": "Functional constipation of infants, toddlers or children"
    },
    {
        "id": "1L1G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L1H",
        "text": "Abdominal pain-related functional GI disorders in children"
    },
    {
        "id": "1L1I",
        "text": "Functional abdominal pain in children"
    },
    {
        "id": "1L11",
        "text": "Cyclic vomiting syndrome in children"
    },
    {
        "id": "1L1J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L1K",
        "text": "Functional vomiting or aerophagia in childhood"
    },
    {
        "id": "1L1L",
        "text": "Irritant contact dermatitis"
    },
    {
        "id": "1L0X",
        "text": "Human prion diseases"
    },
    {
        "id": "1L1M",
        "text": "Dementia due to Alzheimer disease with early onset"
    },
    {
        "id": "1L1N",
        "text": "Dementia due to Alzheimer disease with late onset"
    },
    {
        "id": "1L1O",
        "text": "Non-amnestic Alzheimer disease dementia subtypes"
    },
    {
        "id": "1L1P",
        "text": "Vascular dementia haemorrhagic subtype"
    },
    {
        "id": "1L1Q",
        "text": "Other paraphilic disorder involving non-consenting individuals"
    },
    {
        "id": "1L0S",
        "text": "Allergic or hypersensitivity conditions"
    },
    {
        "id": "1L19",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "1L1U",
        "text": "Dementia due to cerebrovascular disease"
    },
    {
        "id": "1L1V",
        "text": "Dementia due to Parkinson disease"
    },
    {
        "id": "1L1W",
        "text": "Frontotemporal dementia"
    },
    {
        "id": "1L1Y",
        "text": "Liver distension disorder (TM1)"
    },
    {
        "id": "1K8Q",
        "text": "Cerebrovascular diseases"
    },
    {
        "id": "1L20",
        "text": "Tympanites disorder (TM1)"
    },
    {
        "id": "1L21",
        "text": "Early cutaneous Lyme borreliosis"
    },
    {
        "id": "1L22",
        "text": "Inflammatory disorders of eyelid"
    },
    {
        "id": "1L23",
        "text": "Seborrhoeic otitis externa"
    },
    {
        "id": "1L24",
        "text": "Non-specific balanoposthitis"
    },
    {
        "id": "1L27",
        "text": "Necrotising fasciitis of the scrotum, penis or perineum"
    },
    {
        "id": "1L25",
        "text": "Balanoposthitis"
    },
    {
        "id": "1L2A",
        "text": "Ulcer of penis of uncertain nature"
    },
    {
        "id": "1L2B",
        "text": "Herpes simplex infection of vulva"
    },
    {
        "id": "1L2C",
        "text": "Non-specific balanoposthitis"
    },
    {
        "id": "1L2D",
        "text": "Epilepsy or seizures"
    },
    {
        "id": "1L2E",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "1L2F",
        "text": "Chronic cancer pain"
    },
    {
        "id": "1J1J",
        "text": "Chronic primary pain"
    },
    {
        "id": "1L2G",
        "text": "Meningitis due to Cryptococcus neoformans"
    },
    {
        "id": "1L2H",
        "text": "Infectious abscess of the central nervous system"
    },
    {
        "id": "1L2I",
        "text": "Multiple system atrophy"
    },
    {
        "id": "1L2J",
        "text": "Autonomic neuropathy due to sodium channelopathies"
    },
    {
        "id": "1L2L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L2N",
        "text": "Episodic anisocoria"
    },
    {
        "id": "1L2O",
        "text": "Hyperhidrosis"
    },
    {
        "id": "1L2P",
        "text": "Miscellaneous specified conditions of integument specific to fetus or newborn"
    },
    {
        "id": "1J07",
        "text": "Chronic primary pain"
    },
    {
        "id": "1L2Q",
        "text": "Chronic primary pain"
    },
    {
        "id": "1L2R",
        "text": "Photoaggravated skin disease"
    },
    {
        "id": "1L2S",
        "text": "Photoaggravated skin disease"
    },
    {
        "id": "1L2T",
        "text": "Irritant contact dermatitis of vulva"
    },
    {
        "id": "1L2U",
        "text": "Phototoxic reaction to skin contact with plant matter"
    },
    {
        "id": "1L2V",
        "text": "Cutaneous reactions to arthropods"
    },
    {
        "id": "1L2W",
        "text": "Infestations by ectoparasites"
    },
    {
        "id": "1L2Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L2Z",
        "text": "Neoplasms of uncertain behaviour of skin"
    },
    {
        "id": "1L30",
        "text": "Tufted angioma"
    },
    {
        "id": "1L31",
        "text": "Melanoma in situ of skin"
    },
    {
        "id": "1L32",
        "text": "Mature T-cell or NK-cell lymphomas and lymphoproliferative disorders, primary cutaneous specified types"
    },
    {
        "id": "1L33",
        "text": "Malignant neoplasms of skin"
    },
    {
        "id": "1L34",
        "text": "Cutaneous markers of internal malignancy"
    },
    {
        "id": "1L35",
        "text": "Tophaceous gout"
    },
    {
        "id": "1L36",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L37",
        "text": "Diabetic skin lesions"
    },
    {
        "id": "1L38",
        "text": "Rheumatoid nodule"
    },
    {
        "id": "1L3A",
        "text": "Immune reconstitution inflammatory syndrome"
    },
    {
        "id": "1L3C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L3B",
        "text": "Diabetic foot ulcer"
    },
    {
        "id": "1L3D",
        "text": "Skin disorders associated with diabetes mellitus"
    },
    {
        "id": "1L39",
        "text": "Cutaneous disorders attributable to acquired or iatrogenic immunodeficiency"
    },
    {
        "id": "1L3G",
        "text": "Adverse cutaneous reactions to medication"
    },
    {
        "id": "1L3H",
        "text": "Varicella without complication"
    },
    {
        "id": "1L3J",
        "text": "Secondary impetiginisation of the skin"
    },
    {
        "id": "1L3K",
        "text": "Impetigo"
    },
    {
        "id": "1L3M",
        "text": "Relapsing fever"
    },
    {
        "id": "1L3N",
        "text": "Skin complications of BCG immunisation"
    },
    {
        "id": "1L3P",
        "text": "Asymptomatic colonization of the skin by virulent or therapy resistant bacteria"
    },
    {
        "id": "1L3Q",
        "text": "Skin infection classified elsewhere due to abnormally virulent or therapy resistant bacteria"
    },
    {
        "id": "1L3R",
        "text": "Skin infection classified elsewhere due to abnormally virulent or therapy resistant bacteria"
    },
    {
        "id": "1L3S",
        "text": "Basidiobolomycosis"
    },
    {
        "id": "1L3T",
        "text": "Conidiobolomycosis"
    },
    {
        "id": "1L3U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L3V",
        "text": "Dracunculiasis"
    },
    {
        "id": "1L41",
        "text": "Acral skin peeling syndrome"
    },
    {
        "id": "1L42",
        "text": "Acral skin peeling syndrome"
    },
    {
        "id": "1L40",
        "text": "Epidermolysis bullosa simplex"
    },
    {
        "id": "1L43",
        "text": "Acantholytic epidermolysis bullosa"
    },
    {
        "id": "1L44",
        "text": "Skin fragility-woolly hair syndrome"
    },
    {
        "id": "1L46",
        "text": "Ectodermal dysplasia \u2013 skin fragility syndrome"
    },
    {
        "id": "1L47",
        "text": "Ectodermal dysplasia \u2013 skin fragility syndrome"
    },
    {
        "id": "1L48",
        "text": "Skin fragility-woolly hair syndrome"
    },
    {
        "id": "1L49",
        "text": "Chronic secondary visceral pain"
    },
    {
        "id": "1L4E",
        "text": "Demyelination due to Sj\u00f6gren disease"
    },
    {
        "id": "1L4F",
        "text": "Demyelination due to Behcet disease"
    },
    {
        "id": "1L4A",
        "text": "Suprabasal epidermolysis bullosa simplex"
    },
    {
        "id": "1L4I",
        "text": "Autosomal dominant basal epidermolysis bullosa simplex"
    },
    {
        "id": "1L4K",
        "text": "Epidermolysis bullosa simplex, Ogna"
    },
    {
        "id": "1L4M",
        "text": "Epidermolysis bullosa simplex, generalised intermediate"
    },
    {
        "id": "1L4L",
        "text": "Epidermolysis bullosa simplex, generalised severe"
    },
    {
        "id": "1L4H",
        "text": "Epidermolysis bullosa simplex with mottled pigmentation"
    },
    {
        "id": "1L4G",
        "text": "Epidermolysis bullosa simplex, localised"
    },
    {
        "id": "1L3Y",
        "text": "Epidermolysis bullosa simplex with muscular dystrophy"
    },
    {
        "id": "1L4P",
        "text": "Autosomal recessive epidermolysis bullosa simplex due to BP230 deficiency"
    },
    {
        "id": "1L4Q",
        "text": "Epidermolysis bullosa simplex, autosomal recessive due to exophilin 5 deficiency"
    },
    {
        "id": "1L4R",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1L4T",
        "text": "Acquired pulmonary venous abnormality"
    },
    {
        "id": "1L4S",
        "text": "Acquired superior caval vein abnormality"
    },
    {
        "id": "1L4U",
        "text": "Postprocedural obstructed pulmonary venous pathway"
    },
    {
        "id": "1L4V",
        "text": "Acquired right ventricular abnormality"
    },
    {
        "id": "1L4W",
        "text": "Acquired left ventricular abnormality"
    },
    {
        "id": "1L4X",
        "text": "Acquired interventricular communication"
    },
    {
        "id": "1L4Y",
        "text": "Acquired pulmonary trunk stenosis"
    },
    {
        "id": "1L4Z",
        "text": "Postprocedural pulmonary trunk stenosis"
    },
    {
        "id": "1L50",
        "text": "Acquired right pulmonary artery stenosis"
    },
    {
        "id": "1L51",
        "text": "Postprocedural right pulmonary artery stenosis"
    },
    {
        "id": "1L52",
        "text": "Acquired left pulmonary artery stenosis"
    },
    {
        "id": "1L53",
        "text": "Postprocedural left pulmonary artery stenosis"
    },
    {
        "id": "1L54",
        "text": "Acquired pulmonary arterial aneurysm"
    },
    {
        "id": "1L55",
        "text": "Acquired pulmonary arterial dissection"
    },
    {
        "id": "1L56",
        "text": "Ruptured aortic sinus of Valsalva aneurysm"
    },
    {
        "id": "1L57",
        "text": "Acquired ascending aorta or root dilation"
    },
    {
        "id": "1L58",
        "text": "Recoarctation of the aorta"
    },
    {
        "id": "1L59",
        "text": "Endarteritis at site of aortic coarctation"
    },
    {
        "id": "1L5A",
        "text": "Postprocedural dissection of aorta at coarctation site"
    },
    {
        "id": "1L5B",
        "text": "Acquired abnormality of aortic arch branch"
    },
    {
        "id": "1L5C",
        "text": "Postprocedural arterial duct perforation"
    },
    {
        "id": "1D4T",
        "text": "Postprocedural residual arterial duct patency"
    },
    {
        "id": "1L5D",
        "text": "Acquired abnormality of common arterial trunk"
    },
    {
        "id": "1D4Y",
        "text": "Cardiac conduit related disorder"
    },
    {
        "id": "1L5E",
        "text": "Cardiac conduit related disorder"
    },
    {
        "id": "1D4Z",
        "text": "Cardiac conduit failure"
    },
    {
        "id": "1D50",
        "text": "Pulmonary autograft failure"
    },
    {
        "id": "1D51",
        "text": "Systemic-to-pulmonary arterial shunt related disorder"
    },
    {
        "id": "1D55",
        "text": "Superior cavopulmonary anastomosis related disorder"
    },
    {
        "id": "1L5F",
        "text": "Failed Fontan type circulation"
    },
    {
        "id": "1D54",
        "text": "Systemic-to-pulmonary arterial shunt failure"
    },
    {
        "id": "1L5G",
        "text": "Systemic-to-pulmonary arterial shunt failure"
    },
    {
        "id": "1D52",
        "text": "Systemic-to-pulmonary arterial shunt obstruction"
    },
    {
        "id": "1L5H",
        "text": "Systemic-to-pulmonary arterial shunt obstruction"
    },
    {
        "id": "1D53",
        "text": "Postprocedural systemic-to-pulmonary arterial shunt acquired obstruction"
    },
    {
        "id": "1L4O",
        "text": "Epidermolysis bullosa simplex with pyloric atresia"
    },
    {
        "id": "1L4N",
        "text": "Autosomal recessive basal epidermolysis bullosa simplex"
    },
    {
        "id": "1L5I",
        "text": "Cardiac postprocedural protein losing enteropathy"
    },
    {
        "id": "1L3Z",
        "text": "Epidermolysis bullosa simplex, migratory circinate"
    },
    {
        "id": "1L3O",
        "text": "Cutaneous tuberculosis"
    },
    {
        "id": "1L5J",
        "text": "Cardiac postprocedural plastic bronchitis"
    },
    {
        "id": "1L5L",
        "text": "Cardiac postprocedural cirrhosis of liver"
    },
    {
        "id": "1D57",
        "text": "Acquired narrowing of constructed cardiac intraventricular tunnel"
    },
    {
        "id": "1L5O",
        "text": "Postprocedural right atrial perforation"
    },
    {
        "id": "1L5Q",
        "text": "Right atrial erosion due to implanted device"
    },
    {
        "id": "1L5R",
        "text": "Postprocedural ventricular septal defect disorder"
    },
    {
        "id": "1L5N",
        "text": "Postprocedural right atrial complication"
    },
    {
        "id": "1L5S",
        "text": "Acquired pulmonary atresia"
    },
    {
        "id": "1L5T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L4B",
        "text": "Isolated demyelinating syndromes of the central nervous system"
    },
    {
        "id": "1L5U",
        "text": "Other primary headache disorder"
    },
    {
        "id": "1L5V",
        "text": "Other primary headache disorder"
    },
    {
        "id": "1L5X",
        "text": "Cerebrovascular diseases"
    },
    {
        "id": "1L5Y",
        "text": "Allergic or hypersensitivity disorders involving the respiratory tract"
    },
    {
        "id": "1L5Z",
        "text": "Parkinson disease"
    },
    {
        "id": "1L64",
        "text": "Dopa-responsive dystonia"
    },
    {
        "id": "1L65",
        "text": "Dystonia due to Wilson disease"
    },
    {
        "id": "1L6D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L6E",
        "text": "Secondary tics"
    },
    {
        "id": "1L6F",
        "text": "Myoclonic disorders"
    },
    {
        "id": "1L6G",
        "text": "Palatal myoclonus"
    },
    {
        "id": "1L6H",
        "text": "Primary stereotypy"
    },
    {
        "id": "1L6M",
        "text": "Migraine without aura"
    },
    {
        "id": "1L6N",
        "text": "Migraine with aura"
    },
    {
        "id": "1L6O",
        "text": "Migraine with aura"
    },
    {
        "id": "1L6P",
        "text": "Medication-overuse headache"
    },
    {
        "id": "1L6K",
        "text": "Other secondary choreas"
    },
    {
        "id": "1L6R",
        "text": "Episodic syndromes that may be associated with migraine"
    },
    {
        "id": "1L6X",
        "text": "Retinal migraine"
    },
    {
        "id": "1L6Y",
        "text": "Autonomic dysreflexia"
    },
    {
        "id": "1L6Z",
        "text": "Cluster headache"
    },
    {
        "id": "1L70",
        "text": "Paroxysmal hemicrania"
    },
    {
        "id": "1L72",
        "text": "Viral myelitis"
    },
    {
        "id": "1L74",
        "text": "Parasitic myelitis"
    },
    {
        "id": "1L7E",
        "text": "Second degree atrioventricular block with constant PR"
    },
    {
        "id": "1L7D",
        "text": "Atrioventricular block, second degree"
    },
    {
        "id": "1L7C",
        "text": "Atrioventricular block, first degree"
    },
    {
        "id": "1L7B",
        "text": "Monomorphic ventricular premature depolarization"
    },
    {
        "id": "1L7A",
        "text": "Ventricular premature depolarization"
    },
    {
        "id": "1L78",
        "text": "Atrial premature depolarization without conduction"
    },
    {
        "id": "1L75",
        "text": "Wolff-Parkinson-White syndrome"
    },
    {
        "id": "1L7F",
        "text": "Second degree atrioventricular block with progressive PR prolongation"
    },
    {
        "id": "1L7G",
        "text": "Complete atrioventricular block"
    },
    {
        "id": "1L7H",
        "text": "Acquired complete atrioventricular block"
    },
    {
        "id": "1L7I",
        "text": "Postprocedural complete atrioventricular block"
    },
    {
        "id": "1L7J",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1L7O",
        "text": "Accessory pathway"
    },
    {
        "id": "1L7P",
        "text": "Atrioventricular accessory pathway with bidirectional conduction"
    },
    {
        "id": "1L7R",
        "text": "Atrioventricular accessory pathway with antegrade conduction only"
    },
    {
        "id": "1L7S",
        "text": "Atriofascicular accessory pathway"
    },
    {
        "id": "1L7T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L7U",
        "text": "Fasciculoventricular accessory pathway"
    },
    {
        "id": "1L7V",
        "text": "Polymorphic ventricular premature depolarization"
    },
    {
        "id": "1L7X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L7Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1H6N",
        "text": "Multiple sclerosis"
    },
    {
        "id": "1L82",
        "text": "Multiple sclerosis or other white matter disorders"
    },
    {
        "id": "1L83",
        "text": "Fibrosing mediastinitis"
    },
    {
        "id": "1L84",
        "text": "Miscellaneous specified eczematous dermatoses"
    },
    {
        "id": "1L85",
        "text": "Miscellaneous specified eczematous dermatoses"
    },
    {
        "id": "1L86",
        "text": "Dermatitis and eczema"
    },
    {
        "id": "1L87",
        "text": "Acrodermatitis enteropathica"
    },
    {
        "id": "1L88",
        "text": "Beckwith-Wiedemann syndrome"
    },
    {
        "id": "1L89",
        "text": "Postural orthostatic tachycardia syndrome"
    },
    {
        "id": "1L8C",
        "text": "Problem associated with interpersonal interactions in childhood"
    },
    {
        "id": "1L8E",
        "text": "Allergic asthma"
    },
    {
        "id": "1L8F",
        "text": "Benign tumours of cutaneous smooth muscle"
    },
    {
        "id": "1L8G",
        "text": "Pilar leiomyoma"
    },
    {
        "id": "1L8H",
        "text": "Allergic or hypersensitivity reactions to arthropods"
    },
    {
        "id": "1L8J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L8K",
        "text": "Infestation of eyelid"
    },
    {
        "id": "1L8M",
        "text": "Developmental anomalies of cutaneous vasculature"
    },
    {
        "id": "1L8N",
        "text": "Blepharochalasis"
    },
    {
        "id": "1L8O",
        "text": "Developmental venous malformations involving the skin"
    },
    {
        "id": "1L8P",
        "text": "Cutaneous allergic or hypersensitivity reactions to Hymenoptera venom"
    },
    {
        "id": "1L8I",
        "text": "Cutaneous allergic or hypersensitivity reactions to Hymenoptera venom"
    },
    {
        "id": "1L8S",
        "text": "Traumatic injury to eyeball"
    },
    {
        "id": "1L8W",
        "text": "Benign lymphatic neoplasms"
    },
    {
        "id": "1L8T",
        "text": "Benign lymphatic neoplasms"
    },
    {
        "id": "1L8Y",
        "text": "Benign lymphatic neoplasms"
    },
    {
        "id": "1L8Z",
        "text": "Benign lymphatic neoplasms"
    },
    {
        "id": "1L91",
        "text": "Hereditary corneal dystrophies"
    },
    {
        "id": "1L92",
        "text": "Hereditary corneal dystrophies"
    },
    {
        "id": "1L93",
        "text": "Hereditary corneal dystrophies"
    },
    {
        "id": "1L96",
        "text": "Hereditary corneal dystrophies"
    },
    {
        "id": "1L94",
        "text": "Anaphylaxis"
    },
    {
        "id": "1L95",
        "text": "Posterior corneal dystrophy"
    },
    {
        "id": "1L97",
        "text": "Hereditary neurocutaneous angioma"
    },
    {
        "id": "1L9D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L9B",
        "text": "Infectious keratitis"
    },
    {
        "id": "1L9A",
        "text": "Infectious keratitis"
    },
    {
        "id": "1L98",
        "text": "Infectious keratitis"
    },
    {
        "id": "1L8D",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "1L8A",
        "text": "Inappropriate parental pressure or other abnormal qualities of upbringing"
    },
    {
        "id": "1L9E",
        "text": "Infectious keratitis"
    },
    {
        "id": "1L9I",
        "text": "Benign cutaneous neoplasms"
    },
    {
        "id": "1L9K",
        "text": "Haemangioma of orbit"
    },
    {
        "id": "1L9L",
        "text": "Haemangioma of orbit"
    },
    {
        "id": "1L9N",
        "text": "Craniofrontonasal dysplasia"
    },
    {
        "id": "1L9O",
        "text": "Pfeiffer syndrome"
    },
    {
        "id": "1L9P",
        "text": "Apert syndrome"
    },
    {
        "id": "1L9Q",
        "text": "Saethre-Chotzen syndrome"
    },
    {
        "id": "1L9R",
        "text": "Muenke syndrome"
    },
    {
        "id": "1L9S",
        "text": "Wormian bones"
    },
    {
        "id": "1L9U",
        "text": "Oxycephaly"
    },
    {
        "id": "1L9V",
        "text": "Brachycephaly"
    },
    {
        "id": "1L9Y",
        "text": "Trigonocephaly"
    },
    {
        "id": "1L9Z",
        "text": "Scaphocephaly"
    },
    {
        "id": "1L9M",
        "text": "Benign vascular neoplasms"
    },
    {
        "id": "1L9F",
        "text": "Extracutaneous infantile haemangioma"
    },
    {
        "id": "1L9C",
        "text": "Extracutaneous infantile haemangioma"
    },
    {
        "id": "1L9X",
        "text": "Craniosynostosis"
    },
    {
        "id": "1L9W",
        "text": "Craniosynostosis"
    },
    {
        "id": "1M00",
        "text": "Craniosynostosis"
    },
    {
        "id": "1L9T",
        "text": "Craniosynostosis"
    },
    {
        "id": "1M01",
        "text": "Malignant neoplasms of lip"
    },
    {
        "id": "1M04",
        "text": "Junctional epidermolysis bullosa, generalised intermediate type"
    },
    {
        "id": "1M03",
        "text": "Junctional epidermolysis bullosa, generalised severe"
    },
    {
        "id": "1M05",
        "text": "Epidermolysis bullosa simplex, localised"
    },
    {
        "id": "1M08",
        "text": "Epidermolysis bullosa simplex, generalised intermediate"
    },
    {
        "id": "1M09",
        "text": "Autosomal recessive basal epidermolysis bullosa simplex"
    },
    {
        "id": "1M0E",
        "text": "Junctional epidermolysis bullosa, localised"
    },
    {
        "id": "1M0D",
        "text": "Junctional epidermolysis bullosa"
    },
    {
        "id": "1M0C",
        "text": "Junctional epidermolysis bullosa"
    },
    {
        "id": "1M0F",
        "text": "Junctional epidermolysis bullosa, LOC"
    },
    {
        "id": "1M0B",
        "text": "Junctional epidermolysis bullosa"
    },
    {
        "id": "1M0A",
        "text": "Junctional epidermolysis bullosa, late onset"
    },
    {
        "id": "1M0G",
        "text": "Junctional epidermolysis bullosa inversa"
    },
    {
        "id": "1M0H",
        "text": "Dystrophic epidermolysis bullosa"
    },
    {
        "id": "1M0I",
        "text": "Dominant dystrophic epidermolysis bullosa, generalised"
    },
    {
        "id": "1M0J",
        "text": "Dominant dystrophic epidermolysis bullosa, acral"
    },
    {
        "id": "1M0L",
        "text": "Dominant dystrophic epidermolysis bullosa pruriginosa"
    },
    {
        "id": "1M0M",
        "text": "Dominant bullous dermolysis of the newborn"
    },
    {
        "id": "1M0O",
        "text": "Autosomal dominant dystrophic epidermolysis bullosa"
    },
    {
        "id": "1M0N",
        "text": "Autosomal recessive dystrophic epidermolysis bullosa"
    },
    {
        "id": "1M0P",
        "text": "Recessive dystrophic epidermolysis bullosa, generalised severe"
    },
    {
        "id": "1M0S",
        "text": "Recessive dystrophic epidermolysis bullosa inversa"
    },
    {
        "id": "1M0T",
        "text": "Recessive dystrophic epidermolysis bullosa, pretibial"
    },
    {
        "id": "1M0U",
        "text": "Recessive dystrophic epidermolysis bullosa pruriginosa"
    },
    {
        "id": "1M0W",
        "text": "Recessive bullous dermolysis of the newborn"
    },
    {
        "id": "1M0V",
        "text": "Recessive dystrophic epidermolysis bullosa centripetalis"
    },
    {
        "id": "1M0R",
        "text": "Recessive dystrophic epidermolysis bullosa, generalised intermediate"
    },
    {
        "id": "1M0K",
        "text": "Dominant dystrophic epidermolysis bullosa, pretibial"
    },
    {
        "id": "1M06",
        "text": "Epidermolysis bullosa simplex, generalised severe"
    },
    {
        "id": "1L4J",
        "text": "Basal epidermolysis bullosa simplex"
    },
    {
        "id": "1L5K",
        "text": "Junctional epidermolysis bullosa"
    },
    {
        "id": "1L3X",
        "text": "Suprabasal epidermolysis bullosa simplex"
    },
    {
        "id": "1M0Y",
        "text": "Infantile haemangioma"
    },
    {
        "id": "1M02",
        "text": "Malignant neoplasms of lip, oral cavity or pharynx"
    },
    {
        "id": "1M12",
        "text": "Malignant neoplasms of palate"
    },
    {
        "id": "1M13",
        "text": "Adenocarcinoma of parotid gland"
    },
    {
        "id": "1M14",
        "text": "Squamous cell carcinoma of parotid gland"
    },
    {
        "id": "1M15",
        "text": "Adenocarcinoma of submandibular or sublingual glands"
    },
    {
        "id": "1M16",
        "text": "Squamous cell carcinoma of submandibular or sublingual glands"
    },
    {
        "id": "1M17",
        "text": "Other specified malignant neoplasms of submandibular or sublingual glands"
    },
    {
        "id": "1M18",
        "text": "Benign neoplasm of lip, oral cavity or pharynx"
    },
    {
        "id": "1M1C",
        "text": "Personal history of physical abuse"
    },
    {
        "id": "1M1J",
        "text": "Anogenital warts"
    },
    {
        "id": "1M1I",
        "text": "Derangement of temporomandibular joint"
    },
    {
        "id": "1M1H",
        "text": "Undifferentiated inflammatory polyarthritis of temporomandibular joint"
    },
    {
        "id": "1M1G",
        "text": "Synovitis of temporomandibular joint"
    },
    {
        "id": "1M1K",
        "text": "Submandibular region"
    },
    {
        "id": "1M1L",
        "text": "Submental region"
    },
    {
        "id": "1M1M",
        "text": "Temporomandibular ligament"
    },
    {
        "id": "1M1N",
        "text": "Dentofacial parafunctional disorders"
    },
    {
        "id": "1K88",
        "text": "Chondrodystrophic myotonia"
    },
    {
        "id": "1M1P",
        "text": "Secondary myopathies"
    },
    {
        "id": "1M1O",
        "text": "Dislocation of jaw"
    },
    {
        "id": "1M1T",
        "text": "Dentofacial functional abnormalities"
    },
    {
        "id": "1M1R",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "1M25",
        "text": "Corneal abscess"
    },
    {
        "id": "1M24",
        "text": "Corneal ulcer"
    },
    {
        "id": "1M21",
        "text": "Corneal ulcer"
    },
    {
        "id": "1M20",
        "text": "Corneal ulcer"
    },
    {
        "id": "1M1Z",
        "text": "Corneal ulcer"
    },
    {
        "id": "1M1Y",
        "text": "Corneal ulcer"
    },
    {
        "id": "1M1X",
        "text": "Exposure keratitis"
    },
    {
        "id": "1M26",
        "text": "Corneal pigmentations or deposits"
    },
    {
        "id": "1M28",
        "text": "Bullous keratopathy"
    },
    {
        "id": "1M29",
        "text": "Secondary corneal oedema"
    },
    {
        "id": "1M22",
        "text": "Corneal ulcer"
    },
    {
        "id": "1M2A",
        "text": "Corneal deformities"
    },
    {
        "id": "1M2B",
        "text": "Corneal deformities"
    },
    {
        "id": "1M2D",
        "text": "Corneal deformities"
    },
    {
        "id": "1M2F",
        "text": "Corneal staphyloma"
    },
    {
        "id": "1M2G",
        "text": "Anaesthesia of cornea"
    },
    {
        "id": "1M2H",
        "text": "Recurrent erosion of cornea"
    },
    {
        "id": "1M2I",
        "text": "Hyphaema"
    },
    {
        "id": "1M2J",
        "text": "Primary anterior uveitis"
    },
    {
        "id": "1M2L",
        "text": "Iris atrophy"
    },
    {
        "id": "1M2M",
        "text": "Adhesions or disruptions of iris or ciliary body"
    },
    {
        "id": "1M2N",
        "text": "Anomalies of pupillary function"
    },
    {
        "id": "1M2O",
        "text": "Structural disorders of the pupil"
    },
    {
        "id": "1M2P",
        "text": "Infantile or juvenile cataract"
    },
    {
        "id": "1M2Q",
        "text": "Certain specified cataracts"
    },
    {
        "id": "1M2R",
        "text": "Presence of cataract surgery"
    },
    {
        "id": "1M2S",
        "text": "Aphakia"
    },
    {
        "id": "1M2T",
        "text": "Scleritis"
    },
    {
        "id": "1M2U",
        "text": "Choroiditis"
    },
    {
        "id": "1M2V",
        "text": "Choroiditis"
    },
    {
        "id": "1M2W",
        "text": "Choroiditis"
    },
    {
        "id": "1M2X",
        "text": "Choroiditis"
    },
    {
        "id": "1M2Z",
        "text": "Disorders of the choroid"
    },
    {
        "id": "1M30",
        "text": "Disorders of the choroid"
    },
    {
        "id": "1M31",
        "text": "Inherited retinal dystrophies"
    },
    {
        "id": "1M32",
        "text": "Inherited retinal dystrophies"
    },
    {
        "id": "1M33",
        "text": "Optic atrophy"
    },
    {
        "id": "1M34",
        "text": "Headache or facial pain associated with disorder of the face or neck"
    },
    {
        "id": "1M3M",
        "text": "Diverticular disease of small and large intestine"
    },
    {
        "id": "1M3N",
        "text": "Circumscribed brain atrophy"
    },
    {
        "id": "1M3O",
        "text": "Pachymeningitis"
    },
    {
        "id": "1M3P",
        "text": "Pachymeningitis"
    },
    {
        "id": "1M3Q",
        "text": "Pachymeningitis"
    },
    {
        "id": "1M3R",
        "text": "Pachymeningitis"
    },
    {
        "id": "1M3S",
        "text": "Pachymeningitis"
    },
    {
        "id": "1M3T",
        "text": "Superficial siderosis of the nervous system"
    },
    {
        "id": "1M3U",
        "text": "Personal history of psychological abuse"
    },
    {
        "id": "1M40",
        "text": "Certain specified joint derangements"
    },
    {
        "id": "1M41",
        "text": "Certain specified joint derangements"
    },
    {
        "id": "1M42",
        "text": "Certain specified joint derangements"
    },
    {
        "id": "1L8R",
        "text": "Ectropion of eyelid"
    },
    {
        "id": "1M43",
        "text": "Certain specified joint derangements"
    },
    {
        "id": "1M44",
        "text": "Certain specified joint derangements"
    },
    {
        "id": "1M45",
        "text": "Certain specified joint derangements"
    },
    {
        "id": "1M2K",
        "text": "Disorders of the anterior uvea"
    },
    {
        "id": "1M49",
        "text": "Anterior uveitis"
    },
    {
        "id": "1M4A",
        "text": "Anterior uveitis not associated with systemic conditions"
    },
    {
        "id": "1M4B",
        "text": "Hypopyon"
    },
    {
        "id": "1M4C",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "1M4D",
        "text": "Argyll Robertson pupil"
    },
    {
        "id": "1M4E",
        "text": "Light-near dissociations"
    },
    {
        "id": "1M4F",
        "text": "Inherited retinal dystrophies"
    },
    {
        "id": "1M4G",
        "text": "Preproliferative diabetic retinopathy"
    },
    {
        "id": "1M4H",
        "text": "Anterior uveitis associated with systemic conditions"
    },
    {
        "id": "1M4I",
        "text": "Retinal detachment with retinal break"
    },
    {
        "id": "1M4J",
        "text": "Serous retinal detachment"
    },
    {
        "id": "1M4K",
        "text": "Retinal breaks without detachment"
    },
    {
        "id": "1M4L",
        "text": "Retinal vascular occlusions"
    },
    {
        "id": "1M4N",
        "text": "Retinal venous occlusions"
    },
    {
        "id": "1L9J",
        "text": "Neoplastic haemangioma"
    },
    {
        "id": "1M3V",
        "text": "Progressive focal atrophies"
    },
    {
        "id": "1M3X",
        "text": "Dementia due to nutritional deficiency"
    },
    {
        "id": "1M3Y",
        "text": "Dementia due to psychoactive substances including medications"
    },
    {
        "id": "1M3Z",
        "text": "Dementia due to metabolic abnormalities"
    },
    {
        "id": "1M3W",
        "text": "Dementia due to certain specified infectious diseases"
    },
    {
        "id": "1M4O",
        "text": "Postprocedural superior caval vein complication"
    },
    {
        "id": "1M4P",
        "text": "Postprocedural inferior caval vein complication"
    },
    {
        "id": "1M4Q",
        "text": "Acquired pulmonary venous obstruction"
    },
    {
        "id": "1M4R",
        "text": "Postprocedural obstructed pulmonary vein"
    },
    {
        "id": "1M4T",
        "text": "Purulent endophthalmitis"
    },
    {
        "id": "1M4U",
        "text": "Acquired atrial abnormality"
    },
    {
        "id": "1M4V",
        "text": "Panuveitis"
    },
    {
        "id": "1M4W",
        "text": "Ocular albinism"
    },
    {
        "id": "1M4Y",
        "text": "Postprocedural right atrial complication"
    },
    {
        "id": "1D5C",
        "text": "Acquired abnormality of congenitally malformed valve"
    },
    {
        "id": "1M4Z",
        "text": "Ventricular septal defect as current complication following acute myocardial infarction"
    },
    {
        "id": "1M51",
        "text": "Postprocedural residual or recurrent ventricular septal defect"
    },
    {
        "id": "1M52",
        "text": "Postprocedural residual arterial duct patency"
    },
    {
        "id": "1M53",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M54",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M55",
        "text": "Acquired stenosis of common arterial trunk"
    },
    {
        "id": "1M56",
        "text": "Acquired abnormality of common arterial trunk"
    },
    {
        "id": "1M57",
        "text": "Inflammatory diseases of the retina"
    },
    {
        "id": "1M58",
        "text": "Cardiac conduit failure"
    },
    {
        "id": "1M59",
        "text": "Congenital great vessel related acquired abnormality"
    },
    {
        "id": "1M5A",
        "text": "Structural developmental anomalies of cornea"
    },
    {
        "id": "1M5B",
        "text": "Aniridia"
    },
    {
        "id": "1M5C",
        "text": "Congenital great vessel related acquired abnormality"
    },
    {
        "id": "1M5D",
        "text": "Acquired systemic vein abnormality"
    },
    {
        "id": "1D3N",
        "text": "Congenital great vessel related acquired abnormality"
    },
    {
        "id": "1M5G",
        "text": "Certain acquired abnormalities of aorta"
    },
    {
        "id": "1M5H",
        "text": "Postprocedural stenosis of the thoracic aorta"
    },
    {
        "id": "1M5I",
        "text": "Developmental language disorder with impairment of receptive and expressive language"
    },
    {
        "id": "1M5L",
        "text": "Special screening examination for eye or ear disorders"
    },
    {
        "id": "1M5M",
        "text": "Injury or harm arising following infusion, transfusion or therapeutic injection, not elsewhere classified"
    },
    {
        "id": "1M5Q",
        "text": "Alport syndrome"
    },
    {
        "id": "1M5S",
        "text": "Multiple developmental anomalies or syndromes"
    },
    {
        "id": "1M5W",
        "text": "Orbital fracture"
    },
    {
        "id": "1M5X",
        "text": "Fracture of maxilla"
    },
    {
        "id": "1M5Y",
        "text": "Fracture of skull or facial bones"
    },
    {
        "id": "1M5Z",
        "text": "Traumatic amputation of part of head"
    },
    {
        "id": "1M60",
        "text": "Injury, poisoning or certain other consequences of external causes"
    },
    {
        "id": "1M5V",
        "text": "Naso-orbital-ethmoidal fracture"
    },
    {
        "id": "1M5N",
        "text": "Fracture of condylar process of mandible"
    },
    {
        "id": "1M5T",
        "text": "Fracture of subcondylar process of mandible"
    },
    {
        "id": "1M4M",
        "text": "Retinal artery occlusions"
    },
    {
        "id": "1M50",
        "text": "Age-related macular degeneration"
    },
    {
        "id": "1M63",
        "text": "Non-traumatic macular hole"
    },
    {
        "id": "1M64",
        "text": "Central serous chorioretinopathy"
    },
    {
        "id": "1M65",
        "text": "Central serous chorioretinopathy"
    },
    {
        "id": "1M66",
        "text": "Eales disease"
    },
    {
        "id": "1M69",
        "text": "Retinal vasculitis"
    },
    {
        "id": "1M6B",
        "text": "Degeneration of macula or posterior pole"
    },
    {
        "id": "1M6C",
        "text": "Degeneration of macula or posterior pole"
    },
    {
        "id": "1M6D",
        "text": "Degeneration of macula or posterior pole"
    },
    {
        "id": "1M6E",
        "text": "Peripheral retinal degeneration"
    },
    {
        "id": "1M6F",
        "text": "Separation of retinal layers"
    },
    {
        "id": "1M6G",
        "text": "Serous detachment of retinal pigment epithelium"
    },
    {
        "id": "1M6H",
        "text": "Haemorrhagic detachment of retinal pigment epithelium"
    },
    {
        "id": "1M6I",
        "text": "Retinal atrophy"
    },
    {
        "id": "1M6J",
        "text": "Retinopathy"
    },
    {
        "id": "1M6K",
        "text": "Retinopathy"
    },
    {
        "id": "1M6L",
        "text": "Posterior vitreous detachment"
    },
    {
        "id": "1M6M",
        "text": "Vitreous haemorrhage"
    },
    {
        "id": "1M6N",
        "text": "Vitreous opacities, membranes or strands"
    },
    {
        "id": "1M6O",
        "text": "Neuroretinitis"
    },
    {
        "id": "1M6P",
        "text": "Secondary angle closure glaucoma"
    },
    {
        "id": "1M6Q",
        "text": "Non paralytic strabismus"
    },
    {
        "id": "1M6R",
        "text": "Non paralytic strabismus"
    },
    {
        "id": "1M6S",
        "text": "Esotropia"
    },
    {
        "id": "1M6T",
        "text": "Exotropia"
    },
    {
        "id": "1M6U",
        "text": "Hypertropia"
    },
    {
        "id": "1M6V",
        "text": "Hypotropia"
    },
    {
        "id": "1M6W",
        "text": "Cyclotropia"
    },
    {
        "id": "1M6X",
        "text": "Dissociated vertical deviation"
    },
    {
        "id": "1M6Y",
        "text": "Dissociated horizontal deviation"
    },
    {
        "id": "1M6Z",
        "text": "Intermittent strabismus"
    },
    {
        "id": "1M70",
        "text": "Intermittent strabismus"
    },
    {
        "id": "1M71",
        "text": "Intermittent strabismus"
    },
    {
        "id": "1M72",
        "text": "Impairment of binocular functions"
    },
    {
        "id": "1M74",
        "text": "Vision impairment"
    },
    {
        "id": "1M75",
        "text": "Diabetic macular oedema"
    },
    {
        "id": "1M76",
        "text": "Malignant neoplasm of conjunctiva"
    },
    {
        "id": "1M77",
        "text": "Malignant neoplasm metastases"
    },
    {
        "id": "1M78",
        "text": "Carcinoma in situ of the eye or ocular adnexa"
    },
    {
        "id": "1M79",
        "text": "Benign neoplasm of eye or ocular adnexa"
    },
    {
        "id": "1M7A",
        "text": "Benign neoplasm of eye or ocular adnexa"
    },
    {
        "id": "1M7B",
        "text": "Benign neoplasm of iris"
    },
    {
        "id": "1M7C",
        "text": "Benign neoplasm of ciliary body"
    },
    {
        "id": "1M7D",
        "text": "Benign neoplasm of ciliary body"
    },
    {
        "id": "1M7F",
        "text": "Benign neoplasm of eye or ocular adnexa"
    },
    {
        "id": "1M7G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M61",
        "text": "Fracture of condylar process of mandible"
    },
    {
        "id": "1M73",
        "text": "Fracture of condylar process of mandible"
    },
    {
        "id": "1M7H",
        "text": "Fracture of condylar process of mandible"
    },
    {
        "id": "1M23",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "1M0Q",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "1M0X",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "1M11",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "1M19",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "1M1A",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "1M1B",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "1M1W",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "1M7I",
        "text": "Benign cutaneous neoplasm or cyst of eyelid"
    },
    {
        "id": "1M7J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M7K",
        "text": "Sepsis"
    },
    {
        "id": "1M7M",
        "text": "Movement disorders due to toxicity"
    },
    {
        "id": "1M7O",
        "text": "Neuromuscular junction disorders due to toxicity"
    },
    {
        "id": "1M7Q",
        "text": "Cassava poisoning"
    },
    {
        "id": "1M7R",
        "text": "Tropical ataxic neuropathy"
    },
    {
        "id": "1L5M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M7T",
        "text": "Huntington disease"
    },
    {
        "id": "1D56",
        "text": "Formation of pulmonary arteriovenous fistulas or malformations due to superior cavopulmonary anastomosis"
    },
    {
        "id": "1M7V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M7U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M7W",
        "text": "Extension Codes"
    },
    {
        "id": "1M5P",
        "text": "Inherited retinal dystrophies"
    },
    {
        "id": "1M7X",
        "text": "Inherited retinal dystrophies"
    },
    {
        "id": "1M7Y",
        "text": "Inherited retinal dystrophies"
    },
    {
        "id": "1M7Z",
        "text": "Inflammatory diseases of the retina"
    },
    {
        "id": "1M80",
        "text": "Retinal artery occlusions"
    },
    {
        "id": "1M81",
        "text": "Retinal artery occlusions"
    },
    {
        "id": "1M82",
        "text": "Retinal venous occlusions"
    },
    {
        "id": "1M83",
        "text": "Retinal venous occlusions"
    },
    {
        "id": "1M84",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M7E",
        "text": "Cysts of eyelid"
    },
    {
        "id": "1M2Y",
        "text": "Chorioretinal scars"
    },
    {
        "id": "1M4S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M85",
        "text": "Retinal detachments or breaks"
    },
    {
        "id": "1M86",
        "text": "Retinal detachments or breaks"
    },
    {
        "id": "1M87",
        "text": "Retinal detachments or breaks"
    },
    {
        "id": "1M88",
        "text": "Benign neoplasm of eye or ocular adnexa"
    },
    {
        "id": "1M89",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M8A",
        "text": "Coloboma of choroid or retina"
    },
    {
        "id": "1M8B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M8C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M8D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M8E",
        "text": "Relationships with teachers or classmates"
    },
    {
        "id": "1M8H",
        "text": "Mixed depressive and anxiety disorder"
    },
    {
        "id": "1M8I",
        "text": "Non-abdominal wall hernia"
    },
    {
        "id": "1M8G",
        "text": "Gaming disorder"
    },
    {
        "id": "1M8J",
        "text": "Acute appendicitis with generalised peritonitis"
    },
    {
        "id": "1M8K",
        "text": "Melanoma of skin"
    },
    {
        "id": "1M8L",
        "text": "Allergens"
    },
    {
        "id": "1L3W",
        "text": "Certain nematode infestations affecting the skin"
    },
    {
        "id": "1M8N",
        "text": "Onychomycosis due to non-dermatophyte mould"
    },
    {
        "id": "1M8O",
        "text": "Neoscytalidium dermatomycosis"
    },
    {
        "id": "1M8P",
        "text": "Candidosis"
    },
    {
        "id": "1M8Q",
        "text": "Otomycosis"
    },
    {
        "id": "1M8S",
        "text": "Toxin-mediated cutaneous reactions to distant or systemic bacterial infection"
    },
    {
        "id": "1M8T",
        "text": "Cutaneous tuberculosis"
    },
    {
        "id": "1M8U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L6T",
        "text": "Certain mycobacterial infections affecting the skin"
    },
    {
        "id": "1M8V",
        "text": "Cutaneous erysipeloid"
    },
    {
        "id": "1M8W",
        "text": "Plague"
    },
    {
        "id": "1M8X",
        "text": "Certain sexually transmissible bacterial infections affecting skin"
    },
    {
        "id": "1M8Y",
        "text": "Other Vincent infections"
    },
    {
        "id": "1M8Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M90",
        "text": "Acute necrotising ulcerative gingivitis"
    },
    {
        "id": "1M91",
        "text": "Onchodermatitis"
    },
    {
        "id": "1M92",
        "text": "Anogenital streptococcal cellulitis of infancy or childhood"
    },
    {
        "id": "1L3I",
        "text": "Bacterial cellulitis, erysipelas or lymphangitis"
    },
    {
        "id": "1L03",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L04",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L7Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L0I",
        "text": "Anorgasmia"
    },
    {
        "id": "1M93",
        "text": "Orgasmic dysfunctions"
    },
    {
        "id": "1M94",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M96",
        "text": "Ejaculatory dysfunctions"
    },
    {
        "id": "1M97",
        "text": "Male early ejaculation"
    },
    {
        "id": "1M98",
        "text": "Male delayed ejaculation"
    },
    {
        "id": "1L0D",
        "text": "Sexual pain-penetration disorder"
    },
    {
        "id": "1M99",
        "text": "Ecthyma"
    },
    {
        "id": "1M9A",
        "text": "Sexual pain disorders"
    },
    {
        "id": "1L0J",
        "text": "Anorgasmia, acquired, generalised"
    },
    {
        "id": "1M9B",
        "text": "Ecthyma"
    },
    {
        "id": "1L0L",
        "text": "Anorgasmia, lifelong, generalised"
    },
    {
        "id": "1L09",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L05",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L06",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L07",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L0B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L0C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L08",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L0A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M9C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M9E",
        "text": "Melioidosis"
    },
    {
        "id": "1M9G",
        "text": "Cutaneous nocardiosis"
    },
    {
        "id": "1M9H",
        "text": "Dermatoses due to certain filamentous bacteria"
    },
    {
        "id": "1M9F",
        "text": "Nocardiosis"
    },
    {
        "id": "1M9I",
        "text": "Actinomycosis"
    },
    {
        "id": "1M9D",
        "text": "Melioidosis"
    },
    {
        "id": "1M9J",
        "text": "Subacute or chronic melioidosis"
    },
    {
        "id": "1M9K",
        "text": "Human herpes virus infections involving skin or mucous membrane"
    },
    {
        "id": "1M9N",
        "text": "Encephalitis due to Epstein-Barr virus"
    },
    {
        "id": "1M9O",
        "text": "Encephalitis due to Human herpesvirus"
    },
    {
        "id": "1M9P",
        "text": "Encephalitis due to Human herpesvirus 6"
    },
    {
        "id": "1M9Q",
        "text": "Encephalitis due to Human herpesvirus 7"
    },
    {
        "id": "1M9T",
        "text": "Viral infections of the central nervous system"
    },
    {
        "id": "1M9U",
        "text": "Varicella encephalitis"
    },
    {
        "id": "1M9S",
        "text": "Encephalitis associated with herpes zoster"
    },
    {
        "id": "1M9V",
        "text": "Varicella zoster infection of skin"
    },
    {
        "id": "1M9W",
        "text": "Anogenital herpes simplex infection"
    },
    {
        "id": "1M9X",
        "text": "Herpes simplex infection of skin or mucous membrane"
    },
    {
        "id": "1M9Y",
        "text": "Predominantly tropical or subtropical bacterial infections affecting skin"
    },
    {
        "id": "1M9Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N00",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N01",
        "text": "Viral exanthems"
    },
    {
        "id": "1N02",
        "text": "Certain dermatoses with suspected viral aetiology"
    },
    {
        "id": "1N03",
        "text": "Lichen simplex"
    },
    {
        "id": "1N04",
        "text": "Angioedema due to disordered complement activation or kinin metabolism"
    },
    {
        "id": "1M95",
        "text": "Sexual dysfunctions"
    },
    {
        "id": "1N06",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N08",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N09",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N0M",
        "text": "Dissociative drug intoxication including Ketamine or PCP"
    },
    {
        "id": "1N05",
        "text": "Oral pemphigus"
    },
    {
        "id": "1N0P",
        "text": "Preterm newborn, gestational age 28 completed weeks"
    },
    {
        "id": "1N0Q",
        "text": "Preterm newborn, gestational age 29 completed weeks"
    },
    {
        "id": "1N0R",
        "text": "Preterm newborn, gestational age 31 completed weeks"
    },
    {
        "id": "1N0S",
        "text": "Preterm newborn, gestational age 32 completed weeks"
    },
    {
        "id": "1N0T",
        "text": "Preterm newborn, gestational age 33 completed weeks"
    },
    {
        "id": "1N0U",
        "text": "Preterm newborn, gestational age 34 completed weeks"
    },
    {
        "id": "1N0V",
        "text": "Preterm newborn, gestational age 35 completed weeks"
    },
    {
        "id": "1N0W",
        "text": "Preterm newborn, gestational age 36 completed weeks"
    },
    {
        "id": "1N0X",
        "text": "Oesophagitis in newborn"
    },
    {
        "id": "1N0Y",
        "text": "Neonatal eosinophilic oesophagitis"
    },
    {
        "id": "1N0Z",
        "text": "Prenatal gastric perforation"
    },
    {
        "id": "1N10",
        "text": "Postnatal gastric perforation"
    },
    {
        "id": "1N11",
        "text": "Postnatal isolated ileal perforation"
    },
    {
        "id": "1N12",
        "text": "Prenatal intrauterine intestinal perforation"
    },
    {
        "id": "1N13",
        "text": "Postnatal intestinal perforation"
    },
    {
        "id": "1N14",
        "text": "Postnatal intestinal perforation due to drugs"
    },
    {
        "id": "1N15",
        "text": "Respiratory instability of prematurity"
    },
    {
        "id": "1N16",
        "text": "Traumatic pulmonary haemorrhage originating in the perinatal period"
    },
    {
        "id": "1N17",
        "text": "Apnoea of newborn, due to neurologic injury"
    },
    {
        "id": "1N19",
        "text": "Respiratory arrest of newborn"
    },
    {
        "id": "1N1A",
        "text": "Hypotonia of hypopharynx in neonate"
    },
    {
        "id": "1N1B",
        "text": "Hypotonia of hypopharynx in neonate"
    },
    {
        "id": "1N1C",
        "text": "Tracheo-bronchial malacia in neonate"
    },
    {
        "id": "1N1D",
        "text": "Cardiac arrhythmias in the neonate"
    },
    {
        "id": "1N1E",
        "text": "Intraventricular nontraumatic haemorrhage, grade 4, of fetus or newborn"
    },
    {
        "id": "1N1F",
        "text": "Neonatal bleeding originating in the mouth, nose or pharynx"
    },
    {
        "id": "1N1G",
        "text": "Neonatal bleeding originating in the oesophagus, stomach, small or large intestine"
    },
    {
        "id": "1N1H",
        "text": "Neonatal haemorrhage originating in kidney or bladder"
    },
    {
        "id": "1N1I",
        "text": "Neonatal haemorrhage originating in trachea or pulmonary parenchyma"
    },
    {
        "id": "1N1J",
        "text": "Neonatal cutaneous haemorrhage"
    },
    {
        "id": "1N1K",
        "text": "ABO isoimmunization of fetus or newborn"
    },
    {
        "id": "1N1L",
        "text": "Neonatal hyperglycaemia"
    },
    {
        "id": "1N1M",
        "text": "Neonatal hypoglycaemia"
    },
    {
        "id": "1N1N",
        "text": "Transitory iatrogenic neonatal hypoglycaemia"
    },
    {
        "id": "1N1O",
        "text": "Delayed separation of umbilical cord"
    },
    {
        "id": "1N1P",
        "text": "Umbilical granuloma of newborn"
    },
    {
        "id": "1N1Q",
        "text": "Congenital sucking blisters"
    },
    {
        "id": "1N1R",
        "text": "Neonatal milia"
    },
    {
        "id": "1N1T",
        "text": "Perinatal arterial stroke"
    },
    {
        "id": "1N1U",
        "text": "Neonatal cerebral sinovenous thrombosis"
    },
    {
        "id": "1N1V",
        "text": "Birth depression"
    },
    {
        "id": "1N1W",
        "text": "Failure to thrive in newborn"
    },
    {
        "id": "1N1X",
        "text": "Postnatal iatrogenic skin injury"
    },
    {
        "id": "1N1Y",
        "text": "Hypochondriasis"
    },
    {
        "id": "1N20",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium, without psychotic symptoms"
    },
    {
        "id": "1N21",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium, with psychotic symptoms"
    },
    {
        "id": "1N22",
        "text": "Problems associated with harmful or traumatic events"
    },
    {
        "id": "1N1Z",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium"
    },
    {
        "id": "1M9R",
        "text": "Encephalitis due to Cytomegalovirus"
    },
    {
        "id": "1N2B",
        "text": "Home for the elderly"
    },
    {
        "id": "1N2D",
        "text": "Home"
    },
    {
        "id": "1N2C",
        "text": "Home"
    },
    {
        "id": "1N2E",
        "text": "Medical service area"
    },
    {
        "id": "1N2F",
        "text": "Home"
    },
    {
        "id": "1N2G",
        "text": "Home"
    },
    {
        "id": "1N2H",
        "text": "Home"
    },
    {
        "id": "1N2I",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "1N2K",
        "text": "Paralytic symptoms"
    },
    {
        "id": "1N2L",
        "text": "Lower limb varicose veins"
    },
    {
        "id": "1N2M",
        "text": "Venous leg ulcer"
    },
    {
        "id": "1N0K",
        "text": "Sedative, hypnotic or anxiolytic intoxication"
    },
    {
        "id": "1N0J",
        "text": "Opioid intoxication"
    },
    {
        "id": "1N0L",
        "text": "Volatile inhalant intoxication"
    },
    {
        "id": "1N2N",
        "text": "Meningitis due to Streptococcus agalactiae"
    },
    {
        "id": "1N2P",
        "text": "Tuberculous meningoencephalitis"
    },
    {
        "id": "1N2Q",
        "text": "Motor neuron disease"
    },
    {
        "id": "1N2R",
        "text": "Madras type motor neuron disease"
    },
    {
        "id": "1N2T",
        "text": "Latent tuberculosis"
    },
    {
        "id": "1N2J",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "1N2U",
        "text": "Malignant neoplasm metastasis in spinal cord, cranial nerves or remaining parts of central nervous system"
    },
    {
        "id": "1N2V",
        "text": "Bones"
    },
    {
        "id": "1N2W",
        "text": "Malignant mesenchymal neoplasms"
    },
    {
        "id": "1N2X",
        "text": "Malignant neoplasms of stomach"
    },
    {
        "id": "1L7M",
        "text": "White matter disorders due to nutritional deficiency"
    },
    {
        "id": "1L7L",
        "text": "White matter disorders due to nutritional deficiency"
    },
    {
        "id": "1M7P",
        "text": "White matter disorders due to nutritional deficiency"
    },
    {
        "id": "1L7N",
        "text": "White matter disorders due to certain specified systemic disease"
    },
    {
        "id": "1N2Y",
        "text": "White matter disorders due to certain specified systemic disease"
    },
    {
        "id": "1N2Z",
        "text": "White matter disorders due to toxicity"
    },
    {
        "id": "1N2S",
        "text": "Adrenogenital disorders"
    },
    {
        "id": "1N30",
        "text": "Headache associated with injury to the head or neck"
    },
    {
        "id": "1N32",
        "text": "Headache due to cranial or cervical vascular intracranial disorder"
    },
    {
        "id": "1N34",
        "text": "Headache due to cranial or cervical vascular intracranial disorder"
    },
    {
        "id": "1N33",
        "text": "Headache due to cranial or cervical vascular intracranial disorder"
    },
    {
        "id": "1N31",
        "text": "Headache due to cranial or cervical vascular intracranial disorder"
    },
    {
        "id": "1N35",
        "text": "Headache due to nonvascular intracranial disorder"
    },
    {
        "id": "1N36",
        "text": "Headache due to nonvascular intracranial disorder"
    },
    {
        "id": "1N37",
        "text": "Diseases of the blood or blood-forming organs"
    },
    {
        "id": "1N38",
        "text": "Dementia"
    },
    {
        "id": "1N39",
        "text": "Non-amnestic Alzheimer disease dementia subtypes"
    },
    {
        "id": "1N3A",
        "text": "Dementia due to Parkinson disease"
    },
    {
        "id": "1L5W",
        "text": "Headache associated with injury to the head or neck"
    },
    {
        "id": "1L6W",
        "text": "Headache due to nonvascular intracranial disorder"
    },
    {
        "id": "1L6L",
        "text": "Headache due to nonvascular intracranial disorder"
    },
    {
        "id": "1L6Q",
        "text": "Trigeminal neuralgia"
    },
    {
        "id": "1N3B",
        "text": "Amnestic disorder"
    },
    {
        "id": "1N3E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N3C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N3D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N3F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N3G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N3H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N3I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N3J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N3K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N1S",
        "text": "Fever of newborn"
    },
    {
        "id": "1N3L",
        "text": "Dementia"
    },
    {
        "id": "1N3N",
        "text": "Diseases of the blood or blood-forming organs"
    },
    {
        "id": "1N3Q",
        "text": "Rh isoimmunization of fetus or newborn"
    },
    {
        "id": "1N3R",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1N3M",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "1N3T",
        "text": "Atopic eczema"
    },
    {
        "id": "1N3U",
        "text": "Seborrhoeic dermatitis and related conditions"
    },
    {
        "id": "1N3W",
        "text": "Seborrhoeic dermatitis of unspecified location"
    },
    {
        "id": "1N3X",
        "text": "Benign neoplasm of cranial nerves"
    },
    {
        "id": "1N3Y",
        "text": "Enlarged lymph nodes"
    },
    {
        "id": "1N3Z",
        "text": "Enlarged lymph nodes"
    },
    {
        "id": "1N40",
        "text": "Symptoms, signs or clinical findings of blood, blood-forming organs, or the immune system"
    },
    {
        "id": "1N41",
        "text": "Iodine deficiency"
    },
    {
        "id": "1N42",
        "text": "Struma ovarii"
    },
    {
        "id": "1N43",
        "text": "Problem associated with interpersonal interactions in childhood"
    },
    {
        "id": "1N45",
        "text": "Onset"
    },
    {
        "id": "1N46",
        "text": "Onset"
    },
    {
        "id": "1N4A",
        "text": "Vernal keratoconjunctivitis"
    },
    {
        "id": "1N4B",
        "text": "Vernal conjunctivitis"
    },
    {
        "id": "1H7T",
        "text": "Benign cutaneous neoplasm or cyst of eyelid"
    },
    {
        "id": "1N4C",
        "text": "Inflammatory dermatoses of the vulva"
    },
    {
        "id": "1N48",
        "text": "Diseases of the circulatory system"
    },
    {
        "id": "1N4D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N4E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N4F",
        "text": "Congenital laryngomalacia"
    },
    {
        "id": "1N4H",
        "text": "Shock"
    },
    {
        "id": "1N4I",
        "text": "Impaired fasting glucose"
    },
    {
        "id": "1N4J",
        "text": "Cause of late effect"
    },
    {
        "id": "1N4K",
        "text": "Endometriosis"
    },
    {
        "id": "1N4M",
        "text": "Personal history of allergy, other than to drugs or biological substances"
    },
    {
        "id": "1N4N",
        "text": "Asthma"
    },
    {
        "id": "1N4O",
        "text": "Persistent atrial fibrillation"
    },
    {
        "id": "1N4P",
        "text": "Permanent atrial fibrillation"
    },
    {
        "id": "1N4Q",
        "text": "Supraventricular tachyarrhythmia"
    },
    {
        "id": "1N4R",
        "text": "Accessory pathway"
    },
    {
        "id": "1L7Q",
        "text": "Atrioventricular accessory pathway with retrograde conduction only"
    },
    {
        "id": "1N4S",
        "text": "Accessory pathway"
    },
    {
        "id": "1N4U",
        "text": "Sinus node reentrant tachycardia"
    },
    {
        "id": "1N4T",
        "text": "Inappropriate sinus tachycardia"
    },
    {
        "id": "1N4V",
        "text": "Sinus node reentrant tachycardia"
    },
    {
        "id": "1N4W",
        "text": "Accelerated junctional rhythm"
    },
    {
        "id": "1N4X",
        "text": "Atrial fibrillation"
    },
    {
        "id": "1N4Y",
        "text": "Preexcited atrial fibrillation"
    },
    {
        "id": "1N4Z",
        "text": "Preexcited atrial fibrillation"
    },
    {
        "id": "1N51",
        "text": "Idiopathic junctional ectopic tachycardia"
    },
    {
        "id": "1N52",
        "text": "Postoperative junctional ectopic tachycardia"
    },
    {
        "id": "1N53",
        "text": "Cavotricuspid isthmus dependent macroreentry tachycardia"
    },
    {
        "id": "1N54",
        "text": "Cavotricuspid isthmus dependent macroreentry tachycardia, clockwise"
    },
    {
        "id": "1N55",
        "text": "Cavotricuspid isthmus dependent macroreentry tachycardia, counterclockwise"
    },
    {
        "id": "1N59",
        "text": "Atrioventricular nodal reentry tachycardia"
    },
    {
        "id": "1N5A",
        "text": "Atrioventricular nodal reentry tachycardia, typical"
    },
    {
        "id": "1N5B",
        "text": "Atrioventricular nodal reentry tachycardia, atypical"
    },
    {
        "id": "1N5C",
        "text": "Atrioventricular nodal reentry tachycardia, atypical Fast-Slow"
    },
    {
        "id": "1N5D",
        "text": "Atrioventricular nodal reentry tachycardia, atypical Slow-Slow"
    },
    {
        "id": "1N5E",
        "text": "Atrioventricular nodal reentry tachycardia utilising twin atrioventricular nodes"
    },
    {
        "id": "1N5F",
        "text": "Atrioventricular reciprocating tachycardia"
    },
    {
        "id": "1N5G",
        "text": "Atrioventricular reciprocating tachycardia, antidromic"
    },
    {
        "id": "1N5H",
        "text": "Atrioventricular reciprocating tachycardia, orthodromic"
    },
    {
        "id": "1N5I",
        "text": "Supplementary Chapter Traditional Medicine Conditions - Module I"
    },
    {
        "id": "1N5L",
        "text": "Traditional medicine disorders (TM1)"
    },
    {
        "id": "1N5M",
        "text": "Traditional medicine patterns (TM1)"
    },
    {
        "id": "1N5N",
        "text": "Hypochondrium pain disorder (TM1)"
    },
    {
        "id": "1N5O",
        "text": "Jaundice disorder (TM1)"
    },
    {
        "id": "1N5P",
        "text": "Liver distension disorder (TM1)"
    },
    {
        "id": "1N5Q",
        "text": "Liver distension disorder (TM1)"
    },
    {
        "id": "1N5R",
        "text": "Tympanites disorder (TM1)"
    },
    {
        "id": "1N5S",
        "text": "Liver abscess disorder (TM1)"
    },
    {
        "id": "1N5T",
        "text": "Gallbladder distension disorder (TM1)"
    },
    {
        "id": "1N5U",
        "text": "Heart system disorders (TM1)"
    },
    {
        "id": "1N5V",
        "text": "Palpitation disorders (TM1)"
    },
    {
        "id": "1N5W",
        "text": "Inducible palpitation disorder (TM1)"
    },
    {
        "id": "1N5X",
        "text": "Chest impediment disorders (TM1)"
    },
    {
        "id": "1N5Y",
        "text": "True heart pain disorder (TM1)"
    },
    {
        "id": "1N5Z",
        "text": "True heart pain disorder (TM1)"
    },
    {
        "id": "1N60",
        "text": "Spleen system disorders (TM1)"
    },
    {
        "id": "1N61",
        "text": "Dysphagia disorder (TM1)"
    },
    {
        "id": "1N62",
        "text": "Dysphagia disorder (TM1)"
    },
    {
        "id": "1N63",
        "text": "Dysphagia disorder (TM1)"
    },
    {
        "id": "1N64",
        "text": "Stomach ache disorder (TM1)"
    },
    {
        "id": "1N65",
        "text": "Stomach ache disorder (TM1)"
    },
    {
        "id": "1N66",
        "text": "Epigastric distension disorder (TM1)"
    },
    {
        "id": "1N67",
        "text": "Epigastric distension disorder (TM1)"
    },
    {
        "id": "1N68",
        "text": "Epigastric upset disorder (TM1)"
    },
    {
        "id": "1N69",
        "text": "Epigastric upset disorder (TM1)"
    },
    {
        "id": "1N6A",
        "text": "Food reverse flow disorder (TM1)"
    },
    {
        "id": "1N6B",
        "text": "Food reverse flow disorder (TM1)"
    },
    {
        "id": "1N6C",
        "text": "Diarrhea disorder (TM1)"
    },
    {
        "id": "1N6D",
        "text": "Dysentery disorder (TM1)"
    },
    {
        "id": "1N6E",
        "text": "Constipation disorder (TM1)"
    },
    {
        "id": "1N6F",
        "text": "Intestinal abscess disorder (TM1)"
    },
    {
        "id": "1N6G",
        "text": "Intestinal toxin disorder (TM1)"
    },
    {
        "id": "1N6H",
        "text": "Intestinal toxin disorder (TM1)"
    },
    {
        "id": "1N6I",
        "text": "Crapulent syncope disorder (TM1)"
    },
    {
        "id": "1N6J",
        "text": "Crapulent syncope disorder (TM1)"
    },
    {
        "id": "1N6K",
        "text": "Epigastric fullness disorder (TM1)"
    },
    {
        "id": "1N6L",
        "text": "Epigastric fullness disorder (TM1)"
    },
    {
        "id": "1N6M",
        "text": "Fistula disorder (TM1)"
    },
    {
        "id": "1N6N",
        "text": "Lung system disorders (TM1)"
    },
    {
        "id": "1N6O",
        "text": "Common cold disorder (TM1)"
    },
    {
        "id": "1N6P",
        "text": "Cough disorders (TM1)"
    },
    {
        "id": "1N6Q",
        "text": "Dyspnea disorder (TM1)"
    },
    {
        "id": "1N6R",
        "text": "Dyspnea disorder (TM1)"
    },
    {
        "id": "1N6S",
        "text": "Wheezing disorder (TM1)"
    },
    {
        "id": "1N6T",
        "text": "Wheezing disorder (TM1)"
    },
    {
        "id": "1N6U",
        "text": "Lung distension disorder (TM1)"
    },
    {
        "id": "1N6V",
        "text": "Pleural fluid retention disorder (TM1)"
    },
    {
        "id": "1N6W",
        "text": "Lung heat disorder (TM1)"
    },
    {
        "id": "1N6X",
        "text": "Chest bind disorder (TM1)"
    },
    {
        "id": "1N6Y",
        "text": "Kidney system disorders (TM1)"
    },
    {
        "id": "1N70",
        "text": "Stony stranguria disorder (TM1)"
    },
    {
        "id": "1N71",
        "text": "Kidney heat disorder (TM1)"
    },
    {
        "id": "1N72",
        "text": "Kidney stagnation disorder (TM1)"
    },
    {
        "id": "1N73",
        "text": "Enuresis disorder (TM1)"
    },
    {
        "id": "1N74",
        "text": "Cardiac arrhythmia"
    },
    {
        "id": "1N75",
        "text": "Sinus pause"
    },
    {
        "id": "1N76",
        "text": "Sinus arrest or pause with atrial escape"
    },
    {
        "id": "1N77",
        "text": "Sinus arrest or pause with junctional escape"
    },
    {
        "id": "1N78",
        "text": "Sinus arrest or pause with ventricular escape"
    },
    {
        "id": "1N7A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N7B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N7C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N7D",
        "text": "Sinus node dysfunction"
    },
    {
        "id": "1N7E",
        "text": "Sinoatrial block"
    },
    {
        "id": "1N7F",
        "text": "Tachycardia-bradycardia syndrome"
    },
    {
        "id": "1N7G",
        "text": "Sick sinus syndrome"
    },
    {
        "id": "1N7I",
        "text": "Ventricular tachycardia"
    },
    {
        "id": "1N7J",
        "text": "Ventricular tachycardia"
    },
    {
        "id": "1N7H",
        "text": "Ventricular tachycardia"
    },
    {
        "id": "1N7K",
        "text": "Monomorphic ventricular tachycardia"
    },
    {
        "id": "1N7L",
        "text": "Left outflow tract ventricular tachycardia"
    },
    {
        "id": "1N7M",
        "text": "Right outflow tract ventricular tachycardia"
    },
    {
        "id": "1N7N",
        "text": "Bundle branch reentry tachycardia"
    },
    {
        "id": "1N7O",
        "text": "Idiopathic fascicular ventricular tachycardia"
    },
    {
        "id": "1N7P",
        "text": "Secondary monomorphic ventricular tachycardia associated with diseases classified elsewhere"
    },
    {
        "id": "1N7Q",
        "text": "Polymorphic ventricular tachycardia"
    },
    {
        "id": "1N7R",
        "text": "Bidirectional ventricular tachycardia"
    },
    {
        "id": "1N7S",
        "text": "Torsade de pointes"
    },
    {
        "id": "1N7T",
        "text": "Atrioventricular block, first degree"
    },
    {
        "id": "1N7U",
        "text": "Atrioventricular block, first degree"
    },
    {
        "id": "1N7V",
        "text": "Atrioventricular block, second degree"
    },
    {
        "id": "1L7W",
        "text": "Atrioventricular block, second degree"
    },
    {
        "id": "1N7W",
        "text": "Postprocedural complete atrioventricular block"
    },
    {
        "id": "1N7X",
        "text": "Congenital heart block"
    },
    {
        "id": "1N7Y",
        "text": "Congenital heart block"
    },
    {
        "id": "1N7Z",
        "text": "Congenital heart block"
    },
    {
        "id": "1N80",
        "text": "Familial progressive cardiac conduction defect"
    },
    {
        "id": "1N81",
        "text": "Right bundle branch block"
    },
    {
        "id": "1N82",
        "text": "Incomplete right bundle branch block"
    },
    {
        "id": "1N83",
        "text": "Left bundle branch block"
    },
    {
        "id": "1N84",
        "text": "Incomplete left bundle branch block"
    },
    {
        "id": "1N85",
        "text": "Left anterior fascicular block"
    },
    {
        "id": "1N86",
        "text": "Left posterior fascicular block"
    },
    {
        "id": "1N87",
        "text": "Nonspecific intraventricular conduction delay"
    },
    {
        "id": "1L76",
        "text": "Atrial premature depolarization"
    },
    {
        "id": "1L77",
        "text": "Atrial premature depolarization with aberrant ventricular conduction"
    },
    {
        "id": "1N88",
        "text": "Atrial premature depolarization without conduction"
    },
    {
        "id": "1L79",
        "text": "Junctional premature depolarization"
    },
    {
        "id": "1N89",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N8A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N8B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N8C",
        "text": "Ion channelopathy"
    },
    {
        "id": "1N8D",
        "text": "Familial sick sinus syndrome"
    },
    {
        "id": "1N8E",
        "text": "Brugada pattern electrocardiograph"
    },
    {
        "id": "1N8F",
        "text": "Spontaneous Brugada pattern electrocardiograph"
    },
    {
        "id": "1N8G",
        "text": "Provoked Brugada pattern electrocardiograph"
    },
    {
        "id": "1N8H",
        "text": "Brugada syndrome"
    },
    {
        "id": "1N8I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N8J",
        "text": "Catecholaminergic polymorphic ventricular tachycardia due to Calsequestrin mutation"
    },
    {
        "id": "1N8K",
        "text": "Catecholaminergic polymorphic ventricular tachycardia due to RYR2 mutation"
    },
    {
        "id": "1N8L",
        "text": "Early repolarisation syndrome"
    },
    {
        "id": "1N8M",
        "text": "Familial atrial fibrillation"
    },
    {
        "id": "1N8N",
        "text": "Idiopathic ventricular fibrillation"
    },
    {
        "id": "1N8P",
        "text": "Pacemaker or implantable cardioverter defibrillator atrial lead fracture"
    },
    {
        "id": "1N8O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N8Q",
        "text": "Pacemaker or implantable cardioverter defibrillator atrial lead dislodgement"
    },
    {
        "id": "1N8R",
        "text": "Pacemaker or implantable cardioverter defibrillator atrial insulation break"
    },
    {
        "id": "1N8S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N8T",
        "text": "Pacemaker or implantable cardioverter defibrillator coronary sinus lead fracture"
    },
    {
        "id": "1N8U",
        "text": "Pacemaker or implantable cardioverter defibrillator coronary sinus lead dislodgement"
    },
    {
        "id": "1N8V",
        "text": "Pacemaker or implantable cardioverter defibrillator coronary sinus lead insulation break"
    },
    {
        "id": "1N8W",
        "text": "Cardiac arrhythmia"
    },
    {
        "id": "1N8X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N8Z",
        "text": "Wolff-Parkinson-White syndrome"
    },
    {
        "id": "1N90",
        "text": "Turbid urine disorder (TM1)"
    },
    {
        "id": "1N91",
        "text": "Dribbling urinary block disorder (TM1)"
    },
    {
        "id": "1N92",
        "text": "Dribbling urinary block disorder (TM1)"
    },
    {
        "id": "1N93",
        "text": "Block and repulsion disorder (TM1)"
    },
    {
        "id": "1N94",
        "text": "Block and repulsion disorder (TM1)"
    },
    {
        "id": "1N95",
        "text": "Wind edema disorder (TM1)"
    },
    {
        "id": "1N96",
        "text": "Premature ejaculation disorder (TM1)"
    },
    {
        "id": "1N97",
        "text": "Involuntary ejaculation disorder (TM1)"
    },
    {
        "id": "1N98",
        "text": "Persistent erection disorder (TM1)"
    },
    {
        "id": "1N99",
        "text": "Impotence disorder (TM1)"
    },
    {
        "id": "1N9A",
        "text": "Male Infertility disorder (TM1)"
    },
    {
        "id": "1N9B",
        "text": "Male Infertility disorder (TM1)"
    },
    {
        "id": "1N9C",
        "text": "Impetigo disorder (TM1)"
    },
    {
        "id": "1N9D",
        "text": "Furuncle disorders (TM1)"
    },
    {
        "id": "1N9E",
        "text": "Septicemic furunculosis disorder (TM1)"
    },
    {
        "id": "1N9F",
        "text": "Bed sore disorder (TM1)"
    },
    {
        "id": "1N9G",
        "text": "Abscess disorders (TM1)"
    },
    {
        "id": "1N9H",
        "text": "Deep multiple abscess disorder (TM1)"
    },
    {
        "id": "1N9I",
        "text": "Headed carbuncle disorder (TM1)"
    },
    {
        "id": "1N9J",
        "text": "Foot dampness itch disorder (TM1)"
    },
    {
        "id": "1N9K",
        "text": "Gangrene disorder (TM1)"
    },
    {
        "id": "1N9L",
        "text": "Erysipelas disorder (TM1)"
    },
    {
        "id": "1N9M",
        "text": "Cellulitis disorder (TM1)"
    },
    {
        "id": "1N9N",
        "text": "Cellulitis disorder (TM1)"
    },
    {
        "id": "1N9O",
        "text": "Thrush disorder (TM1)"
    },
    {
        "id": "1N9P",
        "text": "Herpes zoster disorder (TM1)"
    },
    {
        "id": "1N9Q",
        "text": "Interior haemorroid disorder (TM1)"
    },
    {
        "id": "1N9R",
        "text": "Female reproductive system disorders (TM1) (including childbirth)"
    },
    {
        "id": "1N9S",
        "text": "Menstruation associated disorders (TM1)"
    },
    {
        "id": "1N9T",
        "text": "Menstruation cycle disorders (TM1)"
    },
    {
        "id": "1N9U",
        "text": "Advanced menstruation disorder (TM1)"
    },
    {
        "id": "1N9V",
        "text": "Menorrhagia disorder (TM1)"
    },
    {
        "id": "1N9W",
        "text": "Decreased menstruation disorder (TM1)"
    },
    {
        "id": "1N9X",
        "text": "Decreased menstruation disorder (TM1)"
    },
    {
        "id": "1N9Y",
        "text": "Prolonged menstruation disorder (TM1)"
    },
    {
        "id": "1N9Z",
        "text": "Metrorrhagia disorder (TM1)"
    },
    {
        "id": "1O00",
        "text": "Metrorrhagia disorder (TM1)"
    },
    {
        "id": "1O01",
        "text": "Amenorrhea disorder (TM1)"
    },
    {
        "id": "1O02",
        "text": "Amenorrhea disorder (TM1)"
    },
    {
        "id": "1O03",
        "text": "Dysmenorrhea disorder (TM1)"
    },
    {
        "id": "1O04",
        "text": "Dysmenorrhea disorder (TM1)"
    },
    {
        "id": "1O05",
        "text": "Morning sickness disorder (TM1)"
    },
    {
        "id": "1O06",
        "text": "Unstable fetus disorder (TM1)"
    },
    {
        "id": "1O07",
        "text": "Unstable fetus disorder (TM1)"
    },
    {
        "id": "1O08",
        "text": "Puerperal abdominal pain disorder (TM1)"
    },
    {
        "id": "1O09",
        "text": "Puerperal wind disorder (TM1)"
    },
    {
        "id": "1O0A",
        "text": "Hypogalactia disorder (TM1)"
    },
    {
        "id": "1O0B",
        "text": "Hypogalactia disorder (TM1)"
    },
    {
        "id": "1O0C",
        "text": "Postpartum lochiorrhea disorder (TM1)"
    },
    {
        "id": "1O0D",
        "text": "Postpartum lochiorrhea disorder (TM1)"
    },
    {
        "id": "1O0E",
        "text": "Leukorrhea disorder (TM1)"
    },
    {
        "id": "1O0F",
        "text": "Infertility disorder (TM1)"
    },
    {
        "id": "1O0G",
        "text": "Uterine mass disorder (TM1)"
    },
    {
        "id": "1O0H",
        "text": "Breast lump disorder (TM1)"
    },
    {
        "id": "1O0I",
        "text": "Bone, joint and muscle system disorders (TM1)"
    },
    {
        "id": "1O0J",
        "text": "Cold impediment disorder (TM1)"
    },
    {
        "id": "1O0K",
        "text": "Cold impediment disorder (TM1)"
    },
    {
        "id": "1O0L",
        "text": "Wind impediment disorder (TM1)"
    },
    {
        "id": "1O0M",
        "text": "Wind impediment disorder (TM1)"
    },
    {
        "id": "1O0N",
        "text": "Dampness impediment disorder (TM1)"
    },
    {
        "id": "1O0O",
        "text": "Muscle spasm disorder (TM1)"
    },
    {
        "id": "1O0P",
        "text": "Muscle spasm disorder (TM1)"
    },
    {
        "id": "1O0Q",
        "text": "Lumbago disorder (TM1)"
    },
    {
        "id": "1O0R",
        "text": "Numbness disorder (TM1)"
    },
    {
        "id": "1O0S",
        "text": "Flaccidity disorder (TM1)"
    },
    {
        "id": "1O0T",
        "text": "Flaccidity disorder (TM1)"
    },
    {
        "id": "1O0U",
        "text": "Wilting disorder (TM1)"
    },
    {
        "id": "1O0V",
        "text": "Wilting disorder (TM1)"
    },
    {
        "id": "1O0W",
        "text": "Wilting disorder (TM1)"
    },
    {
        "id": "1O0Y",
        "text": "Pityriasis lichenoides chronica"
    },
    {
        "id": "1O0Z",
        "text": "Pityriasis lichenoides et varioliformis acuta"
    },
    {
        "id": "1O10",
        "text": "Diseases of the skin"
    },
    {
        "id": "1O11",
        "text": "Abnormal atrial arrangement"
    },
    {
        "id": "1O12",
        "text": "Dextrocardia"
    },
    {
        "id": "1O13",
        "text": "Right hand pattern ventricular topology"
    },
    {
        "id": "1O14",
        "text": "Left hand pattern ventricular topology"
    },
    {
        "id": "1O15",
        "text": "Superior-inferior ventricular relationship"
    },
    {
        "id": "1O16",
        "text": "Aortic root anterior and rightward to pulmonary root"
    },
    {
        "id": "1O17",
        "text": "Aortic root side by side and directly rightward to pulmonary root"
    },
    {
        "id": "1O18",
        "text": "Aortic root side by side and directly leftward to pulmonary root"
    },
    {
        "id": "1O19",
        "text": "Aortic root posterior and rightward to pulmonary root"
    },
    {
        "id": "1O1B",
        "text": "Parallel course of great arteries"
    },
    {
        "id": "1O1C",
        "text": "Total mirror imagery"
    },
    {
        "id": "1O1D",
        "text": "Congenital anomaly of an atrioventricular or ventriculo-arterial connection"
    },
    {
        "id": "1O1E",
        "text": "Concordant atrioventricular connections"
    },
    {
        "id": "1O1F",
        "text": "Discordant atrioventricular connections"
    },
    {
        "id": "1O1G",
        "text": "Congenitally corrected transposition of great arteries"
    },
    {
        "id": "1O1H",
        "text": "Transposition of the great arteries"
    },
    {
        "id": "1O1I",
        "text": "Transposition of the great arteries with concordant atrioventricular connections and intact ventricular septum"
    },
    {
        "id": "1O1J",
        "text": "Transposition of the great arteries with concordant atrioventricular connections and ventricular septal defect"
    },
    {
        "id": "1O1K",
        "text": "Transposition of the great arteries with concordant atrioventricular connections and ventricular septal defect and left ventricular outflow tract obstruction"
    },
    {
        "id": "1O1L",
        "text": "Concordant ventriculo-arterial connections"
    },
    {
        "id": "1O1M",
        "text": "Double outlet right ventricle"
    },
    {
        "id": "1O1N",
        "text": "Double outlet right ventricle with subpulmonary ventricular septal defect, transposition type"
    },
    {
        "id": "1O1O",
        "text": "Double outlet right ventricle with non-committed ventricular septal defect"
    },
    {
        "id": "1O1P",
        "text": "Double outlet left ventricle"
    },
    {
        "id": "1O1Q",
        "text": "Common arterial trunk"
    },
    {
        "id": "1O1R",
        "text": "Common arterial trunk with aortic dominance"
    },
    {
        "id": "1O1S",
        "text": "Common arterial trunk with pulmonary dominance and aortic arch obstruction"
    },
    {
        "id": "1O1T",
        "text": "Congenital truncal valvar regurgitation"
    },
    {
        "id": "1O1U",
        "text": "Dysplasia of truncal valve"
    },
    {
        "id": "1O1V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O1W",
        "text": "Congenital anomaly of mediastinal vein"
    },
    {
        "id": "1O1X",
        "text": "Congenital anomaly of mediastinal systemic vein"
    },
    {
        "id": "1O1Y",
        "text": "Left superior caval vein"
    },
    {
        "id": "1O1Z",
        "text": "Interrupted inferior caval vein with absent suprarenal segment and azygos continuation"
    },
    {
        "id": "1O20",
        "text": "Total anomalous pulmonary venous connection"
    },
    {
        "id": "1O21",
        "text": "Partial anomalous pulmonary venous connection"
    },
    {
        "id": "1D63",
        "text": "Restrictive interatrial communication or intact atrial septum when an interatrial shunt is physiologically necessary"
    },
    {
        "id": "1O22",
        "text": "Patent oval foramen"
    },
    {
        "id": "1O23",
        "text": "Interatrial communication through coronary sinus orifice"
    },
    {
        "id": "1O24",
        "text": "Divided right atrium"
    },
    {
        "id": "1O25",
        "text": "Left-sided juxtaposition of the atrial appendages"
    },
    {
        "id": "1O26",
        "text": "Congenital giant right atrium"
    },
    {
        "id": "1O27",
        "text": "Divided left atrium"
    },
    {
        "id": "1O28",
        "text": "Congenital anomaly of an atrioventricular valve or atrioventricular septum"
    },
    {
        "id": "1O29",
        "text": "Congenital tricuspid regurgitation"
    },
    {
        "id": "1O2A",
        "text": "Congenital tricuspid valvar stenosis"
    },
    {
        "id": "1O2B",
        "text": "Congenital anomaly of tricuspid valve"
    },
    {
        "id": "1O2C",
        "text": "Tricuspid annular hypoplasia"
    },
    {
        "id": "1O2D",
        "text": "Dysplasia of tricuspid valve"
    },
    {
        "id": "1O2E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O2F",
        "text": "Mitral annular hypoplasia"
    },
    {
        "id": "1O2G",
        "text": "Straddling mitral valve"
    },
    {
        "id": "1O2H",
        "text": "Dysplasia of mitral valve"
    },
    {
        "id": "1O2I",
        "text": "Supravalvar or intravalvar mitral ring"
    },
    {
        "id": "1O2J",
        "text": "Congenital mitral subvalvar stenosis"
    },
    {
        "id": "1O2K",
        "text": "Congenital anomaly of left-sided atrioventricular valve in double inlet ventricle"
    },
    {
        "id": "1O2L",
        "text": "Congenital anomaly of right-sided atrioventricular valve in double inlet ventricle"
    },
    {
        "id": "1O2M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O2N",
        "text": "Atrioventricular septal defect with balanced ventricles"
    },
    {
        "id": "1O2O",
        "text": "Atrioventricular septal defect with ventricular imbalance"
    },
    {
        "id": "1O2Q",
        "text": "Atrioventricular septal defect with communication at the ventricular level only"
    },
    {
        "id": "1O2P",
        "text": "Atrioventricular septal defect with communication at the atrial level only"
    },
    {
        "id": "1O2R",
        "text": "Atrioventricular septal defect with communication at atrial level and unrestrictive communication at ventricular level"
    },
    {
        "id": "1O2S",
        "text": "Atrioventricular septal defect with communication at atrial level and restrictive communication at ventricular level"
    },
    {
        "id": "1O2T",
        "text": "Common atrioventricular valve with unbalanced commitment of valve to ventricles"
    },
    {
        "id": "1O2U",
        "text": "Common atrioventricular junction without an atrioventricular septal defect"
    },
    {
        "id": "1D66",
        "text": "Communication between left ventricle and right atrium"
    },
    {
        "id": "1O2V",
        "text": "Congenital anomaly of a ventricle or the ventricular septum"
    },
    {
        "id": "1O2W",
        "text": "Right ventricular myocardial sinusoids"
    },
    {
        "id": "1O2X",
        "text": "Tetralogy of Fallot"
    },
    {
        "id": "1O2Y",
        "text": "Tetralogy of Fallot with absent pulmonary valve syndrome"
    },
    {
        "id": "1O2Z",
        "text": "Tetralogy of Fallot with absent pulmonary valve syndrome"
    },
    {
        "id": "1O30",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O31",
        "text": "Tetralogy of Fallot with pulmonary atresia"
    },
    {
        "id": "1O32",
        "text": "Tetralogy of Fallot with pulmonary atresia and systemic-to-pulmonary collateral artery"
    },
    {
        "id": "1O33",
        "text": "Left ventricular hypoplasia"
    },
    {
        "id": "1O35",
        "text": "Neonatal infections of certain specified sites"
    },
    {
        "id": "1O36",
        "text": "Pyodermatitis\u2013pyostomatitis vegetans"
    },
    {
        "id": "1O37",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1811",
        "text": "Pilomatrical carcinoma"
    },
    {
        "id": "1812",
        "text": "Trichilemmocarcinoma"
    },
    {
        "id": "148R",
        "text": "Benign cutaneous neoplasms with eccrine differentiation"
    },
    {
        "id": "1B5E",
        "text": "Malignant melanoma of soft parts"
    },
    {
        "id": "1C8Q",
        "text": "Malignant neoplasm metastases"
    },
    {
        "id": "1C8T",
        "text": "Malignant neoplasm metastasis in other sites"
    },
    {
        "id": "1O38",
        "text": "Malignant melanoma of soft parts"
    },
    {
        "id": "1F44",
        "text": "Inflammatory conditions of jaws"
    },
    {
        "id": "1O39",
        "text": "Left ventricular myocardial sinusoids"
    },
    {
        "id": "1D6A",
        "text": "Left ventricular myocardial sinusoids"
    },
    {
        "id": "1O3A",
        "text": "Congenital anomaly of ventricular septum"
    },
    {
        "id": "1O3B",
        "text": "Polyneuropathy"
    },
    {
        "id": "1O3C",
        "text": "Restrictive interventricular communication when an interventricular shunt is physiologically necessary"
    },
    {
        "id": "1O3D",
        "text": "Ventricular septal defect"
    },
    {
        "id": "1O3E",
        "text": "Ventricular septal defect"
    },
    {
        "id": "1O3F",
        "text": "Perimembranous central ventricular septal defect"
    },
    {
        "id": "1O3G",
        "text": "Perimembranous central ventricular septal defect"
    },
    {
        "id": "1O3I",
        "text": "Inlet perimembranous ventricular septal defect with atrioventricular septal malalignment and without a common atrioventricular junction"
    },
    {
        "id": "1O3J",
        "text": "Inlet perimembranous ventricular septal defect with atrioventricular septal malalignment and without a common atrioventricular junction"
    },
    {
        "id": "1O3K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O3L",
        "text": "Inlet perimembranous ventricular septal defect without atrioventricular septal malalignment without a common atrioventricular junction"
    },
    {
        "id": "1O3M",
        "text": "Inlet muscular ventricular septal defect"
    },
    {
        "id": "1O3N",
        "text": "Trabecular muscular ventricular septal defect"
    },
    {
        "id": "1O3O",
        "text": "Trabecular muscular ventricular septal defect midseptal"
    },
    {
        "id": "1O3P",
        "text": "Trabecular muscular ventricular septal defect apical"
    },
    {
        "id": "1O3Q",
        "text": "Trabecular muscular ventricular septal defect postero-inferior"
    },
    {
        "id": "1O3R",
        "text": "Trabecular muscular ventricular septal defect anterosuperior"
    },
    {
        "id": "1O3T",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "1O3U",
        "text": "Outlet ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "1O3V",
        "text": "Outlet muscular ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "1D6G",
        "text": "Outlet perimembranous ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "1O3W",
        "text": "Outlet perimembranous ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "1D6H",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum"
    },
    {
        "id": "1O3X",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum"
    },
    {
        "id": "1D6I",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "1O3Y",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "1O3Z",
        "text": "Outlet ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1O40",
        "text": "Outlet muscular ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1D6J",
        "text": "Outlet perimembranous ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1O41",
        "text": "Outlet perimembranous ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1D6K",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum"
    },
    {
        "id": "1O42",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum"
    },
    {
        "id": "1D6L",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "1O43",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "1O44",
        "text": "Ventricular septal defect haemodynamically insignificant"
    },
    {
        "id": "1O45",
        "text": "Multiple ventricular septal defects"
    },
    {
        "id": "1O3S",
        "text": "Multiple trabecular muscular ventricular septal defects"
    },
    {
        "id": "1O46",
        "text": "Functionally univentricular heart"
    },
    {
        "id": "1O47",
        "text": "Double inlet atrioventricular connection"
    },
    {
        "id": "1O48",
        "text": "Tricuspid atresia with absent valvar annulus"
    },
    {
        "id": "1O4A",
        "text": "Dysplasia of pulmonary valve"
    },
    {
        "id": "1O4B",
        "text": "Bicuspid pulmonary valve"
    },
    {
        "id": "1O4C",
        "text": "Congenital subpulmonary stenosis"
    },
    {
        "id": "1O4D",
        "text": "Congenital subpulmonary stenosis"
    },
    {
        "id": "1O49",
        "text": "Congenital anomaly of a ventriculo-arterial valve or adjacent regions"
    },
    {
        "id": "1O4E",
        "text": "Congenital supravalvar pulmonary stenosis"
    },
    {
        "id": "1O4F",
        "text": "Congenital pulmonary atresia"
    },
    {
        "id": "1O4G",
        "text": "Congenital aortic valvar stenosis"
    },
    {
        "id": "1O4J",
        "text": "Bicuspid aortic valve"
    },
    {
        "id": "1O4K",
        "text": "Aortic valvar prolapse"
    },
    {
        "id": "1O4L",
        "text": "Aortic valvar prolapse"
    },
    {
        "id": "1O4H",
        "text": "Aortic valvar atresia"
    },
    {
        "id": "1O4M",
        "text": "Aortic annular hypoplasia"
    },
    {
        "id": "1O4N",
        "text": "Dysplasia of aortic valve"
    },
    {
        "id": "1O4O",
        "text": "Dysplasia of aortic valve"
    },
    {
        "id": "1O4P",
        "text": "Congenital supravalvar aortic stenosis"
    },
    {
        "id": "1O4Q",
        "text": "Aortoventricular tunnel"
    },
    {
        "id": "1O4R",
        "text": "Congenital aortopulmonary window"
    },
    {
        "id": "1O4S",
        "text": "Congenital anomaly of pulmonary arterial tree"
    },
    {
        "id": "1O4T",
        "text": "Congenital dilation of pulmonary arterial tree"
    },
    {
        "id": "1O4U",
        "text": "Congenital pulmonary arterial branch anomaly"
    },
    {
        "id": "1O4V",
        "text": "Congenital right pulmonary arterial stenosis"
    },
    {
        "id": "1O4W",
        "text": "Congenital left pulmonary arterial stenosis"
    },
    {
        "id": "1O4X",
        "text": "Congenital pulmonary arterial branch hypoplasia"
    },
    {
        "id": "1O4Y",
        "text": "Congenital right pulmonary arterial hypoplasia"
    },
    {
        "id": "1O4Z",
        "text": "Congenital left pulmonary arterial hypoplasia"
    },
    {
        "id": "1O50",
        "text": "Pulmonary artery origin from ascending aorta"
    },
    {
        "id": "1O51",
        "text": "Pulmonary artery from arterial duct"
    },
    {
        "id": "1O52",
        "text": "Right pulmonary artery from arterial duct"
    },
    {
        "id": "1O53",
        "text": "Left pulmonary artery from arterial duct"
    },
    {
        "id": "1O54",
        "text": "Congenital ascending aorta aneurysm or dilation"
    },
    {
        "id": "1O55",
        "text": "Hypoplasia of aortic arch"
    },
    {
        "id": "1O56",
        "text": "Coarctation of aorta"
    },
    {
        "id": "1O5B",
        "text": "Right aortic arch"
    },
    {
        "id": "1O5C",
        "text": "Left aortic arch"
    },
    {
        "id": "1O5D",
        "text": "Left aortic arch"
    },
    {
        "id": "1O5E",
        "text": "Cervical aortic arch"
    },
    {
        "id": "1O58",
        "text": "Interrupted aortic arch distal to subclavian artery, type A"
    },
    {
        "id": "1O57",
        "text": "Interrupted aortic arch"
    },
    {
        "id": "1O59",
        "text": "Interrupted aortic arch between subclavian and common carotid arteries, type B"
    },
    {
        "id": "1O5A",
        "text": "Interrupted aortic arch between carotid arteries, type C"
    },
    {
        "id": "1O5F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O5H",
        "text": "Isolation of an aortic arch branch"
    },
    {
        "id": "1O5I",
        "text": "Descending thoracic or abdominal aortic coarctation"
    },
    {
        "id": "1O5J",
        "text": "Innominate artery compression syndrome"
    },
    {
        "id": "1O5K",
        "text": "Vascular ring of right aortic arch and left arterial duct or ligament"
    },
    {
        "id": "1O5L",
        "text": "Patent arterial duct"
    },
    {
        "id": "1O5M",
        "text": "Systemic-to-pulmonary collateral arteries"
    },
    {
        "id": "1O5N",
        "text": "Congenital anomaly of coronary artery"
    },
    {
        "id": "1O5O",
        "text": "Anomalous origin of coronary artery from pulmonary arterial tree"
    },
    {
        "id": "1O5P",
        "text": "Anomalous origin of left coronary artery from pulmonary artery"
    },
    {
        "id": "1O5Q",
        "text": "Anomalous aortic origin or course of coronary artery"
    },
    {
        "id": "1O5R",
        "text": "Myocardial bridging of coronary artery"
    },
    {
        "id": "1O5S",
        "text": "Congenital coronary arterial fistula"
    },
    {
        "id": "1O5T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O5U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1L7K",
        "text": "Diseases of external ear"
    },
    {
        "id": "1I6V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O5V",
        "text": "Certain conditions originating in the perinatal period"
    },
    {
        "id": "1O5W",
        "text": "Congenital anomaly of position or spatial relationships of thoraco-abdominal organs"
    },
    {
        "id": "1O5X",
        "text": "Common arterial trunk with aortic dominance and one pulmonary artery absent from the trunk, isolated pulmonary artery"
    },
    {
        "id": "1O5Y",
        "text": "Left superior caval vein to coronary sinus"
    },
    {
        "id": "1O5Z",
        "text": "Left superior caval vein to left-sided atrium"
    },
    {
        "id": "1O60",
        "text": "Common atrioventricular valve with unbalanced commitment of valve to right ventricle"
    },
    {
        "id": "1O61",
        "text": "Eye, ear, nose and throat system disorders (TM1)"
    },
    {
        "id": "1O62",
        "text": "Night blindness disorder (TM1)"
    },
    {
        "id": "1O63",
        "text": "Wind glaucoma disorder (TM1)"
    },
    {
        "id": "1O64",
        "text": "Wind glaucoma disorder (TM1)"
    },
    {
        "id": "1O65",
        "text": "Inflammatory eyelid disorder (TM1)"
    },
    {
        "id": "1O66",
        "text": "Inflammatory eyelid disorder (TM1)"
    },
    {
        "id": "1O67",
        "text": "Non-inflammatory eyelid disorder (TM1)"
    },
    {
        "id": "1O68",
        "text": "Non-inflammatory eyelid disorder (TM1)"
    },
    {
        "id": "1O69",
        "text": "Corneal opacity disorder (TM1)"
    },
    {
        "id": "1O6A",
        "text": "Tinnitus disorder (TM1)"
    },
    {
        "id": "1O6B",
        "text": "Sudden deafness disorder (TM1)"
    },
    {
        "id": "1O6C",
        "text": "Gradual deafness disorder (TM1)"
    },
    {
        "id": "1O6D",
        "text": "Nasal sinusitis disorder (TM1)"
    },
    {
        "id": "1O6E",
        "text": "Brain system disorders (TM1)"
    },
    {
        "id": "1O6F",
        "text": "Facial paralysis disorder (TM1)"
    },
    {
        "id": "1O6G",
        "text": "Facial paralysis disorder (TM1)"
    },
    {
        "id": "1O6H",
        "text": "Facial paralysis disorder (TM1)"
    },
    {
        "id": "1O6I",
        "text": "Headache disorders (TM1)"
    },
    {
        "id": "1O6J",
        "text": "Headache disorders (TM1)"
    },
    {
        "id": "1O6K",
        "text": "Migraine disorder (TM1)"
    },
    {
        "id": "1O6L",
        "text": "Head wind disorder (TM1)"
    },
    {
        "id": "1O6M",
        "text": "Convulsion disorder (TM1)"
    },
    {
        "id": "1O6N",
        "text": "Convulsion disorder (TM1)"
    },
    {
        "id": "1O6O",
        "text": "Vertigo disorder (TM1)"
    },
    {
        "id": "1O6P",
        "text": "Wind stroke disorders (TM1)"
    },
    {
        "id": "1O6Q",
        "text": "Wind stroke disorders (TM1)"
    },
    {
        "id": "1O6R",
        "text": "Prodrome of wind stroke disorder (TM1)"
    },
    {
        "id": "1O6S",
        "text": "Sequela of wind stroke disorder (TM1)"
    },
    {
        "id": "1O6T",
        "text": "Sequela of wind stroke disorder (TM1)"
    },
    {
        "id": "1O6U",
        "text": "Syncope disorder (TM1)"
    },
    {
        "id": "1O6V",
        "text": "Tremor disorder (TM1)"
    },
    {
        "id": "1O6W",
        "text": "Qi, blood and fluid disorders (TM1)"
    },
    {
        "id": "1O6X",
        "text": "Qi, blood and fluid disorders (TM1)"
    },
    {
        "id": "1O6Y",
        "text": "Qi goiter disorder (TM1)"
    },
    {
        "id": "1O6Z",
        "text": "Qi goiter disorder (TM1)"
    },
    {
        "id": "1O70",
        "text": "Lily disorder (TM1)"
    },
    {
        "id": "1O71",
        "text": "Lily disorder (TM1)"
    },
    {
        "id": "1O72",
        "text": "Lily disorder (TM1)"
    },
    {
        "id": "1O73",
        "text": "Manic disorder (TM1)"
    },
    {
        "id": "1O74",
        "text": "Manic disorder (TM1)"
    },
    {
        "id": "1O75",
        "text": "Depression disorder (TM1)"
    },
    {
        "id": "1O76",
        "text": "Depression disorder (TM1)"
    },
    {
        "id": "1O77",
        "text": "Uneasiness disorder (TM1)"
    },
    {
        "id": "1O78",
        "text": "Uneasiness disorder (TM1)"
    },
    {
        "id": "1O79",
        "text": "Somnolence disorder (TM1)"
    },
    {
        "id": "1O7A",
        "text": "Dementia disorder (TM1)"
    },
    {
        "id": "1O7B",
        "text": "Repressed fire disorder (TM1)"
    },
    {
        "id": "1O7C",
        "text": "Repressed fire disorder (TM1)"
    },
    {
        "id": "1O7D",
        "text": "Repressed fire disorder (TM1)"
    },
    {
        "id": "1O7E",
        "text": "Growth fever disorder (TM1)"
    },
    {
        "id": "1O7F",
        "text": "Acute convulsion disorder (TM1)"
    },
    {
        "id": "1O7G",
        "text": "Infantile malnutrition disorder (TM1)"
    },
    {
        "id": "1O7H",
        "text": "Diaper dermatitis disorder (TM1)"
    },
    {
        "id": "1O7I",
        "text": "Infant stiffness disorder (TM1)"
    },
    {
        "id": "1O7J",
        "text": "Infant limpness disorder (TM1)"
    },
    {
        "id": "1O7K",
        "text": "Yang pattern (TM1)"
    },
    {
        "id": "1O7L",
        "text": "Yin pattern (TM1)"
    },
    {
        "id": "1O7M",
        "text": "Excess pattern (TM1)"
    },
    {
        "id": "1O7N",
        "text": "Body constituents patterns (TM1)"
    },
    {
        "id": "1O7O",
        "text": "Qi patterns (TM1)"
    },
    {
        "id": "1O7P",
        "text": "Qi patterns (TM1)"
    },
    {
        "id": "1O7Q",
        "text": "Qi deficiency pattern (TM1)"
    },
    {
        "id": "1O7R",
        "text": "Qi stagnation pattern (TM1)"
    },
    {
        "id": "1O7S",
        "text": "Qi uprising pattern (TM1)"
    },
    {
        "id": "1O7T",
        "text": "Blood patterns (TM1)"
    },
    {
        "id": "1O7U",
        "text": "Blood patterns (TM1)"
    },
    {
        "id": "1O7V",
        "text": "Blood deficiency pattern (TM1)"
    },
    {
        "id": "1O7W",
        "text": "Blood stasis pattern (TM1)"
    },
    {
        "id": "1O7X",
        "text": "Blood cold pattern (TM1)"
    },
    {
        "id": "1O7Y",
        "text": "Blood dryness pattern (TM1)"
    },
    {
        "id": "1O7Z",
        "text": "Fluid patterns (TM1)"
    },
    {
        "id": "1O80",
        "text": "Fluid deficiency pattern (TM1)"
    },
    {
        "id": "1O81",
        "text": "Fluid disturbance pattern (TM1)"
    },
    {
        "id": "1O82",
        "text": "Dry-phlegm pattern (TM1)"
    },
    {
        "id": "1O83",
        "text": "Damp phlegm pattern (TM1)"
    },
    {
        "id": "1O84",
        "text": "Phlegm-fire harassing the heart system pattern (TM1)"
    },
    {
        "id": "1O85",
        "text": "Wind-phlegm pattern (TM1)"
    },
    {
        "id": "1O86",
        "text": "Liver yin deficiency pattern (TM1)"
    },
    {
        "id": "1O87",
        "text": "Liver yang deficiency pattern (TM1)"
    },
    {
        "id": "1O88",
        "text": "Liver yang ascendant hyperactivity pattern (TM1)"
    },
    {
        "id": "1O89",
        "text": "Liver qi deficiency pattern (TM1)"
    },
    {
        "id": "1O8A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O8B",
        "text": "Liver blood deficiency pattern (TM1)"
    },
    {
        "id": "1O8C",
        "text": "Liver depression and blood stasis pattern (TM1)"
    },
    {
        "id": "1O8D",
        "text": "Liver wind stirring the interior pattern (TM1)"
    },
    {
        "id": "1O8E",
        "text": "Liver yang transforming into wind pattern (TM1)"
    },
    {
        "id": "1O8F",
        "text": "Liver qi stagnation pattern (TM1)"
    },
    {
        "id": "1O8G",
        "text": "Liver fire flaming upward pattern (TM1)"
    },
    {
        "id": "1O8H",
        "text": "Oleogranuloma"
    },
    {
        "id": "1O8I",
        "text": "Liver heat stirring wind pattern (TM1)"
    },
    {
        "id": "1O8J",
        "text": "Liver-gallbladder dampness-heat pattern (TM1)"
    },
    {
        "id": "1O8K",
        "text": "Liver meridian dampness-heat pattern (TM1)"
    },
    {
        "id": "1O8L",
        "text": "Liver meridian dampness-heat pattern (TM1)"
    },
    {
        "id": "1O8M",
        "text": "Liver meridian cold stagnation pattern (TM1)"
    },
    {
        "id": "1O8N",
        "text": "Liver meridian cold stagnation pattern (TM1)"
    },
    {
        "id": "1O8O",
        "text": "Gallbladder qi deficiency pattern (TM1)"
    },
    {
        "id": "1O8P",
        "text": "Gallbladder depression with phlegm harassment pattern (TM1)"
    },
    {
        "id": "1O8Q",
        "text": "Gallbladder heat pattern (TM1)"
    },
    {
        "id": "1O8R",
        "text": "Worm harassing the gallbladder pattern (TM1)"
    },
    {
        "id": "1O8S",
        "text": "Liver and kidney yin deficiency pattern (TM1)"
    },
    {
        "id": "1O8T",
        "text": "Disharmony of liver and spleen systems pattern (TM1)"
    },
    {
        "id": "1O8U",
        "text": "Tendinous xanthoma"
    },
    {
        "id": "1O8W",
        "text": "Abnormal vascular reactivity to cold"
    },
    {
        "id": "1O8X",
        "text": "Disharmony of liver and stomach systems pattern (TM1)"
    },
    {
        "id": "1O8Y",
        "text": "Liver fire invading the lung system pattern (TM1)"
    },
    {
        "id": "1O8Z",
        "text": "Liver fire invading the lung system pattern (TM1)"
    },
    {
        "id": "1O90",
        "text": "Heart system patterns (TM1)"
    },
    {
        "id": "1O91",
        "text": "Heart spirit restlessness pattern (TM1)"
    },
    {
        "id": "1O92",
        "text": "Small intestine qi stagnation pattern (TM1)"
    },
    {
        "id": "1O93",
        "text": "Small intestine deficiency cold pattern (TM1)"
    },
    {
        "id": "1O94",
        "text": "Heart and liver blood deficiency pattern (TM1)"
    },
    {
        "id": "1O95",
        "text": "Heart and spleen systems deficiency pattern (TM1)"
    },
    {
        "id": "1O96",
        "text": "Spleen system patterns (TM1)"
    },
    {
        "id": "1O97",
        "text": "Spleen qi sinking pattern (TM1)"
    },
    {
        "id": "1O98",
        "text": "Spleen qi insecurity pattern (TM1)"
    },
    {
        "id": "1O99",
        "text": "Spleen deficiency with qi stagnation pattern (TM1)"
    },
    {
        "id": "1O9A",
        "text": "Spleen failing to control the blood pattern (TM1)"
    },
    {
        "id": "1O9B",
        "text": "Spleen deficiency and blood depletion pattern (TM1)"
    },
    {
        "id": "1O9C",
        "text": "Spleen yin deficiency pattern (TM1)"
    },
    {
        "id": "1O9D",
        "text": "Spleen yin deficiency pattern (TM1)"
    },
    {
        "id": "1O9E",
        "text": "Spleen yang deficiency pattern (TM1)"
    },
    {
        "id": "1O9F",
        "text": "Spleen yang deficiency with water retention pattern (TM1)"
    },
    {
        "id": "1O9G",
        "text": "Spleen yang deficiency with stomach external cold pattern (TM1)"
    },
    {
        "id": "1O9H",
        "text": "Spleen yang deficiency with stomach external cold pattern (TM1)"
    },
    {
        "id": "1O9I",
        "text": "Dampness-heat encumbering the spleen system pattern (TM1)"
    },
    {
        "id": "1O9J",
        "text": "Supplementary Chapter Traditional Medicine Conditions - Module I"
    },
    {
        "id": "1L9H",
        "text": "Benign non-mesenchymal neoplasms"
    },
    {
        "id": "1O9K",
        "text": "Superficial bacterial folliculitis"
    },
    {
        "id": "1O9L",
        "text": "Spleen deficiency with dampness accumulation pattern (TM1)"
    },
    {
        "id": "1O9M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O9N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1O9O",
        "text": "Cold-dampness encumbering the spleen system pattern (TM1)"
    },
    {
        "id": "1O9P",
        "text": "Stomach qi deficiency pattern (TM1)"
    },
    {
        "id": "1O9Q",
        "text": "Stomach qi uprising pattern (TM1)"
    },
    {
        "id": "1O9R",
        "text": "Stomach yin deficiency pattern (TM1)"
    },
    {
        "id": "1O9S",
        "text": "Stomach heat pattern (TM1)"
    },
    {
        "id": "1O9T",
        "text": "Stomach heat with fluid deficiency pattern (TM1)"
    },
    {
        "id": "1O9V",
        "text": "Stomach heat with fluid deficiency pattern (TM1)"
    },
    {
        "id": "1O9W",
        "text": "Dampness in the intestines pattern (TM1)"
    },
    {
        "id": "1O9X",
        "text": "Cold invading the stomach system pattern (TM1)"
    },
    {
        "id": "1O9Y",
        "text": "Anxiety damaging the spleen system pattern (TM1)"
    },
    {
        "id": "1O9Z",
        "text": "Lung and spleen deficiency pattern (TM1)"
    },
    {
        "id": "1P00",
        "text": "Spleen and kidney yang deficiency pattern (TM1)"
    },
    {
        "id": "1P01",
        "text": "Spleen and kidney yang deficiency pattern (TM1)"
    },
    {
        "id": "1P02",
        "text": "Lung system patterns (TM1)"
    },
    {
        "id": "1P03",
        "text": "Lung qi deficiency pattern (TM1)"
    },
    {
        "id": "1P04",
        "text": "Lung yin deficiency pattern (TM1)"
    },
    {
        "id": "1P05",
        "text": "Lung yang deficiency pattern (TM1)"
    },
    {
        "id": "1P06",
        "text": "Exterior cold with lung heat pattern (TM1)"
    },
    {
        "id": "1P07",
        "text": "Phlegm heat obstructing the lung pattern (TM1)"
    },
    {
        "id": "1P08",
        "text": "Wind-heat invading the lung pattern (TM1)"
    },
    {
        "id": "1P09",
        "text": "Wind-cold fettering the lung pattern (TM1)"
    },
    {
        "id": "1P0A",
        "text": "Dryness invading the lung pattern (TM1)"
    },
    {
        "id": "1P0B",
        "text": "Lung dryness with intestinal obstruction pattern (TM1)"
    },
    {
        "id": "1P0C",
        "text": "Large intestine excess heat pattern (TM1)"
    },
    {
        "id": "1P0D",
        "text": "Large intestine fluid deficiency pattern (TM1)"
    },
    {
        "id": "1P0E",
        "text": "Kidney qi deficiency pattern (TM1)"
    },
    {
        "id": "1P0F",
        "text": "Kidney failing to receive qi pattern (TM1)"
    },
    {
        "id": "1P0G",
        "text": "Kidney qi deficiency with water retention pattern (TM1)"
    },
    {
        "id": "1P0H",
        "text": "Kidney qi insecurity pattern (TM1)"
    },
    {
        "id": "1P0I",
        "text": "Kidney yin deficiency pattern (TM1)"
    },
    {
        "id": "1P0J",
        "text": "Kidney yin and yang deficiency pattern (TM1)"
    },
    {
        "id": "1P0K",
        "text": "Kidney deficiency with marrow depletion pattern (TM1)"
    },
    {
        "id": "1P0L",
        "text": "Kidney yang deficiency pattern (TM1)"
    },
    {
        "id": "1P0M",
        "text": "Fear damaging the kidney system pattern (TM1)"
    },
    {
        "id": "1P0N",
        "text": "Kidney yang deficiency with water flooding pattern (TM1)"
    },
    {
        "id": "1P0O",
        "text": "Kidney yang deficiency with water flooding pattern (TM1)"
    },
    {
        "id": "1P0P",
        "text": "Phlegm obstructing the uterus pattern (TM1)"
    },
    {
        "id": "1P0Q",
        "text": "Uterine deficiency cold pattern (TM1)"
    },
    {
        "id": "1P0R",
        "text": "Bladder deficiency cold pattern (TM1)"
    },
    {
        "id": "1P0S",
        "text": "Dampness factor pattern (TM1)"
    },
    {
        "id": "1P0T",
        "text": "Meridian and collateral patterns (TM1)"
    },
    {
        "id": "1P0U",
        "text": "Lung meridian pattern (TM1)"
    },
    {
        "id": "1P0V",
        "text": "Large intestine meridian pattern (TM1)"
    },
    {
        "id": "1P0X",
        "text": "Stomach meridian pattern (TM1)"
    },
    {
        "id": "1P0Y",
        "text": "Heart meridian pattern (TM1)"
    },
    {
        "id": "1P0Z",
        "text": "Small intestine meridian pattern (TM1)"
    },
    {
        "id": "1P10",
        "text": "Bladder meridian pattern (TM1)"
    },
    {
        "id": "1P11",
        "text": "Kidney meridian pattern (TM1)"
    },
    {
        "id": "1P12",
        "text": "Pericardium meridian pattern (TM1)"
    },
    {
        "id": "1P13",
        "text": "Triple energizer meridian pattern (TM1)"
    },
    {
        "id": "1P14",
        "text": "Gallbladder meridian pattern (TM1)"
    },
    {
        "id": "1P15",
        "text": "Liver meridian pattern (TM1)"
    },
    {
        "id": "1P16",
        "text": "Spleen meridian pattern (TM1)"
    },
    {
        "id": "1P17",
        "text": "Pericardium meridian pattern (TM1)"
    },
    {
        "id": "1P18",
        "text": "Triple energizer meridian pattern (TM1)"
    },
    {
        "id": "1P19",
        "text": "Conception vessel pattern (TM1)"
    },
    {
        "id": "1P1A",
        "text": "Six stage patterns (TM1)"
    },
    {
        "id": "1P1B",
        "text": "Early yang stage pattern (TM1)"
    },
    {
        "id": "1P1C",
        "text": "Early yang stage pattern (TM1)"
    },
    {
        "id": "1P1D",
        "text": "Middle yang stage pattern (TM1)"
    },
    {
        "id": "1P1E",
        "text": "Middle yang stage pattern (TM1)"
    },
    {
        "id": "1P1F",
        "text": "Late yang stage pattern (TM1)"
    },
    {
        "id": "1P1G",
        "text": "Late yang stage pattern (TM1)"
    },
    {
        "id": "1P1H",
        "text": "Early yin stage pattern (TM1)"
    },
    {
        "id": "1P1I",
        "text": "Early yin stage pattern (TM1)"
    },
    {
        "id": "1P1J",
        "text": "Middle yin stage Pattern (TM), Middle yin stage pattern (TM1)"
    },
    {
        "id": "1P1K",
        "text": "Middle yin stage Pattern (TM), Middle yin stage pattern (TM1)"
    },
    {
        "id": "1P1L",
        "text": "Late Yin stage Patterns (TM1)"
    },
    {
        "id": "1P1M",
        "text": "Late Yin stage Patterns (TM1)"
    },
    {
        "id": "1P1N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P1O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P1P",
        "text": "Ectopic atrial tachycardia"
    },
    {
        "id": "1P1Q",
        "text": "Ectopic atrial tachycardia"
    },
    {
        "id": "1N50",
        "text": "Junctional ectopic tachycardia"
    },
    {
        "id": "1P1R",
        "text": "Atrioventricular reciprocating tachycardia, orthodromic"
    },
    {
        "id": "1P1S",
        "text": "Atrioventricular reciprocating tachycardia, antidromic"
    },
    {
        "id": "1N79",
        "text": "Sinus bradycardia"
    },
    {
        "id": "1P1T",
        "text": "Macro reentrant atrial tachycardia"
    },
    {
        "id": "1P1W",
        "text": "Idiopathic neonatal atrial flutter"
    },
    {
        "id": "1P1Z",
        "text": "Macro reentrant atrial tachycardia"
    },
    {
        "id": "1P21",
        "text": "Implantable cardioverter defibrillator high voltage component dysfunction"
    },
    {
        "id": "1P22",
        "text": "Pacemaker or implantable cardioverter defibrillator dysfunction"
    },
    {
        "id": "1P23",
        "text": "Pacemaker or implantable cardioverter defibrillator battery at elective replacement indicator"
    },
    {
        "id": "1P24",
        "text": "Pacemaker or implantable cardioverter defibrillator battery at end of battery life"
    },
    {
        "id": "1P25",
        "text": "Inappropriate implantable cardioverter defibrillator shock"
    },
    {
        "id": "1P26",
        "text": "Inappropriate withholding of implantable cardioverter defibrillator therapy"
    },
    {
        "id": "1P2B",
        "text": "Pacemaker syndrome"
    },
    {
        "id": "1P2C",
        "text": "Pacemaker syndrome"
    },
    {
        "id": "1P2D",
        "text": "Pacemaker mediated tachycardia"
    },
    {
        "id": "1P2G",
        "text": "Infection due to pacemaker or implantable cardioverter defibrillator"
    },
    {
        "id": "1P2F",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2H",
        "text": "Pacemaker or implantable cardioverter defibrillator pocket erosion"
    },
    {
        "id": "1P2I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P2J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P2K",
        "text": "Pacemaker generator dysfunction"
    },
    {
        "id": "1P2P",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2O",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2N",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2M",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2L",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2Q",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2R",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2S",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2T",
        "text": "Pacemaker or implantable cardioverter defibrillator complication"
    },
    {
        "id": "1P2A",
        "text": "Pacemaker or implantable cardioverter defibrillator dysfunction"
    },
    {
        "id": "1P29",
        "text": "Pacemaker or implantable cardioverter defibrillator dysfunction"
    },
    {
        "id": "1P28",
        "text": "Pacemaker or implantable cardioverter defibrillator dysfunction"
    },
    {
        "id": "1P27",
        "text": "Pacemaker or implantable cardioverter defibrillator dysfunction"
    },
    {
        "id": "1P2U",
        "text": "Triple energizer stage patterns (TM1)"
    },
    {
        "id": "1P2V",
        "text": "Triple energizer stage patterns (TM1)"
    },
    {
        "id": "1P2W",
        "text": "Upper energizer stage patterns (TM1)"
    },
    {
        "id": "1P2X",
        "text": "Middle energizer stage patterns (TM1)"
    },
    {
        "id": "1P2Y",
        "text": "Lower energizer stage patterns (TM1)"
    },
    {
        "id": "1P2Z",
        "text": "Upper energizer stage patterns (TM1)"
    },
    {
        "id": "1P30",
        "text": "Upper energizer stage patterns (TM1)"
    },
    {
        "id": "1P31",
        "text": "Lower energizer stage patterns (TM1)"
    },
    {
        "id": "1P32",
        "text": "Dampness and heat in the upper energizer pattern (TM1)"
    },
    {
        "id": "1P33",
        "text": "Lower energizer stage patterns (TM1)"
    },
    {
        "id": "1P34",
        "text": "Dampness and heat in the lower energizer pattern (TM1)"
    },
    {
        "id": "1P35",
        "text": "Dampness and heat in the lower energizer pattern (TM1)"
    },
    {
        "id": "1P36",
        "text": "Stasis obstructing the lower energizer pattern (TM1)"
    },
    {
        "id": "1P37",
        "text": "Deficiency of lower energizer pattern (TM1)"
    },
    {
        "id": "1P38",
        "text": "Small yang type chest-binding pattern (TM1)"
    },
    {
        "id": "1O8V",
        "text": "Hypertriglyceridaemia"
    },
    {
        "id": "1P3A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P3B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P3C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P3D",
        "text": "Non-scar, non-isthmus dependent macro reentrant atrial tachycardia"
    },
    {
        "id": "1P3E",
        "text": "Non-scar, non-isthmus dependent right atrial macro reentrant atrial tachycardia"
    },
    {
        "id": "1P3F",
        "text": "Non-scar, non-isthmus dependent left atrial macro reentrant atrial tachycardia"
    },
    {
        "id": "1P3G",
        "text": "Non-scar, non-isthmus dependent macro reentrant atrial tachycardia"
    },
    {
        "id": "1P3H",
        "text": "Neonatal malabsorption syndromes"
    },
    {
        "id": "1P3I",
        "text": "Neonatal disorders of carbohydrate metabolism"
    },
    {
        "id": "1P3J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1N18",
        "text": "Respiratory failure of newborn"
    },
    {
        "id": "1P3K",
        "text": "Congenital aortic regurgitation"
    },
    {
        "id": "1O5G",
        "text": "Aberrant origin of left subclavian artery"
    },
    {
        "id": "1P3M",
        "text": "Osteonecrosis"
    },
    {
        "id": "1P3N",
        "text": "Cardiac arrhythmias in the neonate"
    },
    {
        "id": "1P3O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P3P",
        "text": "Cardiovascular disorders present in the perinatal or neonatal period"
    },
    {
        "id": "1P3Q",
        "text": "Infections of the fetus or newborn"
    },
    {
        "id": "1P3R",
        "text": "Infection of amniotic sac or membranes"
    },
    {
        "id": "1P3T",
        "text": "Multiple benign melanocytic naevi"
    },
    {
        "id": "1P3L",
        "text": "Inborn errors of metabolism"
    },
    {
        "id": "1P3U",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1P3W",
        "text": "Contact with health services for purposes of examination or investigation"
    },
    {
        "id": "1P3X",
        "text": "Mittelschmerz"
    },
    {
        "id": "1P3Y",
        "text": "Fusion of teeth"
    },
    {
        "id": "1P3Z",
        "text": "Fused mandibular incisors"
    },
    {
        "id": "1P40",
        "text": "Molar-incisor hypomineralisation"
    },
    {
        "id": "1P42",
        "text": "Anodontia"
    },
    {
        "id": "1P43",
        "text": "Oligodontia"
    },
    {
        "id": "1P46",
        "text": "Abnormalities of size or form of teeth"
    },
    {
        "id": "1P47",
        "text": "Abnormal number of teeth"
    },
    {
        "id": "1P48",
        "text": "Abnormal number of teeth"
    },
    {
        "id": "1P49",
        "text": "Root anomaly"
    },
    {
        "id": "1P4A",
        "text": "Odontogenesis imperfecta"
    },
    {
        "id": "1P4B",
        "text": "Hereditary disturbances in tooth structure"
    },
    {
        "id": "1P4C",
        "text": "Enamel hypoplasia"
    },
    {
        "id": "1P4F",
        "text": "Disturbances in tooth formation"
    },
    {
        "id": "1P4G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P4H",
        "text": "Localised hypoplasia of dental enamel"
    },
    {
        "id": "1P4I",
        "text": "Diastema of teeth"
    },
    {
        "id": "1P4J",
        "text": "Anomalies in tooth resorption or loss"
    },
    {
        "id": "1P4K",
        "text": "Chronic dental injuries"
    },
    {
        "id": "1P4L",
        "text": "Caries of cementum"
    },
    {
        "id": "1P4M",
        "text": "Caries of infancy associated with breast feeding"
    },
    {
        "id": "1P4N",
        "text": "Caries of infancy associated with bottle feeding"
    },
    {
        "id": "1P4O",
        "text": "Dental caries"
    },
    {
        "id": "1P4P",
        "text": "Odontoclasia"
    },
    {
        "id": "1P4Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P4U",
        "text": "Fitting or adjustment of devices related to nervous system or special senses"
    },
    {
        "id": "1P4V",
        "text": "Personal frightening experience in childhood"
    },
    {
        "id": "1L9G",
        "text": "Personal history of psychological abuse"
    },
    {
        "id": "1P4Y",
        "text": "Bacteraemia"
    },
    {
        "id": "1P50",
        "text": "Sepsis with septic shock"
    },
    {
        "id": "1P52",
        "text": "Sepsis"
    },
    {
        "id": "1P4Z",
        "text": "Sepsis"
    },
    {
        "id": "1P53",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1P54",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1P55",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1P56",
        "text": "Sepsis"
    },
    {
        "id": "1N0O",
        "text": "Viral exanthems"
    },
    {
        "id": "1P57",
        "text": "Certain skin disorders attributable to infection or infestation"
    },
    {
        "id": "1P58",
        "text": "Spastic peristalsis"
    },
    {
        "id": "1P59",
        "text": "Eosinophilic oesophagitis"
    },
    {
        "id": "1P5A",
        "text": "Oesophageal ulcer due to allergic or immunologic disorder"
    },
    {
        "id": "1P5B",
        "text": "Chemical oesophageal ulcer"
    },
    {
        "id": "1P5C",
        "text": "Infectious oesophagitis"
    },
    {
        "id": "1P5D",
        "text": "Infectious oesophagitis"
    },
    {
        "id": "1P5E",
        "text": "Duodenitis"
    },
    {
        "id": "1P5F",
        "text": "Certain specified inflammatory liver diseases"
    },
    {
        "id": "1P5G",
        "text": "Indeterminate colitis"
    },
    {
        "id": "1P5H",
        "text": "Disorders of mineral absorption or transport"
    },
    {
        "id": "1P5I",
        "text": "Acquired monosaccharide malabsorption"
    },
    {
        "id": "1P5J",
        "text": "Zinc deficiency syndromes"
    },
    {
        "id": "1P5K",
        "text": "Fructose malabsorption"
    },
    {
        "id": "1P5L",
        "text": "Hereditary fructose intolerance"
    },
    {
        "id": "1P5N",
        "text": "Autoimmune liver disease"
    },
    {
        "id": "1P5O",
        "text": "Vascular disorders of the liver"
    },
    {
        "id": "1P5Q",
        "text": "Functional nausea or vomiting"
    },
    {
        "id": "1P5R",
        "text": "Ulcerative colitis"
    },
    {
        "id": "1P5T",
        "text": "Hypoglossal nerve"
    },
    {
        "id": "1P5U",
        "text": "Nephrogenic diabetes insipidus"
    },
    {
        "id": "1P5S",
        "text": "Zoster"
    },
    {
        "id": "1P61",
        "text": "Hypofunction or certain other specified disorders of pituitary gland"
    },
    {
        "id": "1P63",
        "text": "Hypofunction or certain other specified disorders of pituitary gland"
    },
    {
        "id": "1P64",
        "text": "Hypofunction or certain other specified disorders of pituitary gland"
    },
    {
        "id": "1P65",
        "text": "Hypofunction or certain other specified disorders of pituitary gland"
    },
    {
        "id": "1P66",
        "text": "Hypofunction or certain other specified disorders of pituitary gland"
    },
    {
        "id": "1P62",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P67",
        "text": "Hypofunction or certain other specified disorders of pituitary gland"
    },
    {
        "id": "1P68",
        "text": "Hypofunction or certain other specified disorders of pituitary gland"
    },
    {
        "id": "1P5X",
        "text": "Hypofunction or certain other specified disorders of pituitary gland"
    },
    {
        "id": "1P6A",
        "text": "Peripheral precocious puberty"
    },
    {
        "id": "1P6B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P6C",
        "text": "Certain conditions originating in the perinatal period"
    },
    {
        "id": "1P6E",
        "text": "Zoster with central nervous system involvement"
    },
    {
        "id": "1P6F",
        "text": "Extreme prematurity of newborn"
    },
    {
        "id": "1P6G",
        "text": "Varicella zoster virus infections"
    },
    {
        "id": "1P6H",
        "text": "Infections due to poxvirus"
    },
    {
        "id": "1P6D",
        "text": "Acute neuropathy of cranial nerve due to zoster"
    },
    {
        "id": "1P6I",
        "text": "Intraventricular nontraumatic haemorrhage, grade 3, of fetus or newborn"
    },
    {
        "id": "1P6J",
        "text": "Digestive system disorders of fetus or newborn"
    },
    {
        "id": "1P6K",
        "text": "Genitourinary system disorders specific to the perinatal or neonatal period"
    },
    {
        "id": "1P6M",
        "text": "Human herpes virus infections involving skin or mucous membrane"
    },
    {
        "id": "1P6O",
        "text": "Acquired immunodeficiencies"
    },
    {
        "id": "1P6Q",
        "text": "Structural developmental anomalies of the female genital system"
    },
    {
        "id": "1P6P",
        "text": "Structural developmental anomalies of urinary tract"
    },
    {
        "id": "1P6N",
        "text": "Assault"
    },
    {
        "id": "1P6R",
        "text": "Infections of the anal region"
    },
    {
        "id": "1P6S",
        "text": "Other injury or harm from surgical or medical care, not elsewhere classified"
    },
    {
        "id": "1P6T",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1P6L",
        "text": "Human herpes virus infections involving skin or mucous membrane"
    },
    {
        "id": "1P3V",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "1I6R",
        "text": "Viral infections characterised by skin or mucous membrane lesions"
    },
    {
        "id": "1P70",
        "text": "Erythema multiforme provoked by Herpes simplex"
    },
    {
        "id": "1P71",
        "text": "Necrolytic acral erythema"
    },
    {
        "id": "1P72",
        "text": "Bacterial cellulitis, erysipelas or lymphangitis"
    },
    {
        "id": "1P73",
        "text": "Bacterial cellulitis, erysipelas or lymphangitis"
    },
    {
        "id": "1P74",
        "text": "Bacterial cellulitis, erysipelas or lymphangitis"
    },
    {
        "id": "1P75",
        "text": "Symptomatic late syphilis of other sites"
    },
    {
        "id": "1P76",
        "text": "Tuberculids"
    },
    {
        "id": "1P6V",
        "text": "Certain skin disorders attributable to viral infection"
    },
    {
        "id": "1P77",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P78",
        "text": "Certain skin disorders attributable to bacterial infection"
    },
    {
        "id": "1P79",
        "text": "Certain skin disorders attributable to infection or infestation"
    },
    {
        "id": "1P7A",
        "text": "Certain dermatoses with suspected viral aetiology"
    },
    {
        "id": "1P7B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1M8R",
        "text": "Non-pyogenic bacterial infections of the skin"
    },
    {
        "id": "1P7C",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "1P7E",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "1P60",
        "text": "Non-secreting pituitary adenoma"
    },
    {
        "id": "1P5Z",
        "text": "Central diabetes insipidus"
    },
    {
        "id": "1P5Y",
        "text": "Hypopituitarism"
    },
    {
        "id": "1P7D",
        "text": "Lack of expected normal physiological development"
    },
    {
        "id": "1P7G",
        "text": "Epidermolysis bullosa acquisita"
    },
    {
        "id": "1P7H",
        "text": "Irritant contact dermatitis due to incontinence"
    },
    {
        "id": "1P7J",
        "text": "Paraneoplastic pemphigus"
    },
    {
        "id": "1P7F",
        "text": "Lichen sclerosus"
    },
    {
        "id": "1P6U",
        "text": "Enteroviral exanthematous fever"
    },
    {
        "id": "1P7K",
        "text": "Melanoma of skin"
    },
    {
        "id": "1P7L",
        "text": "Verrucous squamous cell carcinoma of skin"
    },
    {
        "id": "1P7N",
        "text": "Anaphylaxis"
    },
    {
        "id": "1P7Q",
        "text": "Diabetes mellitus"
    },
    {
        "id": "1P7R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P7S",
        "text": "Upper respiratory tract disorders"
    },
    {
        "id": "1P7T",
        "text": "Examination or encounter for administrative purposes"
    },
    {
        "id": "1P7U",
        "text": "Contact with health services associated with the health of others"
    },
    {
        "id": "1P7V",
        "text": "Acute life threatening episode"
    },
    {
        "id": "1P7W",
        "text": "General symptoms"
    },
    {
        "id": "1P7X",
        "text": "Disturbances in tooth eruption"
    },
    {
        "id": "1P80",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1P81",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P82",
        "text": "Short bowel syndrome in neonate"
    },
    {
        "id": "1P83",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P84",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P85",
        "text": "Neonatal infectious mastitis"
    },
    {
        "id": "1P87",
        "text": "Pressure ulceration grade 1"
    },
    {
        "id": "1P89",
        "text": "Pressure ulceration grade 2"
    },
    {
        "id": "1P8A",
        "text": "Pressure ulceration grade 3"
    },
    {
        "id": "1P8C",
        "text": "Suspected deep pressure-induced tissue damage, depth unknown"
    },
    {
        "id": "1P8D",
        "text": "Pressure ulceration, ungradable"
    },
    {
        "id": "1P88",
        "text": "Pressure ulceration"
    },
    {
        "id": "1P8E",
        "text": "Fetus or newborn affected by maternal factors or by complications of pregnancy, labour or delivery"
    },
    {
        "id": "1P8F",
        "text": "Pressure ulceration grade 4"
    },
    {
        "id": "1P8B",
        "text": "Pressure ulceration grade 4"
    },
    {
        "id": "1P7P",
        "text": "Cutaneous wounds, injuries or scars"
    },
    {
        "id": "1N3S",
        "text": "Atopic eczema, special forms"
    },
    {
        "id": "1P8H",
        "text": "Post traumatic eczema"
    },
    {
        "id": "1N3V",
        "text": "Seborrhoeic dermatitis and related conditions"
    },
    {
        "id": "1P8I",
        "text": "Dermatitis or eczema of hands and feet"
    },
    {
        "id": "1P8J",
        "text": "Vesicular dermatitis of hands and feet"
    },
    {
        "id": "1P8K",
        "text": "Hyperkeratotic dermatitis of hands and feet"
    },
    {
        "id": "1P8L",
        "text": "Dermatitis of hands"
    },
    {
        "id": "1P8M",
        "text": "Atopic eczema of hands"
    },
    {
        "id": "1P8N",
        "text": "Dermatitis of feet"
    },
    {
        "id": "1P8P",
        "text": "Dermatitis or eczema of anogenital region"
    },
    {
        "id": "1P8Q",
        "text": "Dermatitis or eczema of male genitalia"
    },
    {
        "id": "1P8S",
        "text": "Dermatitis or eczema of female genitalia"
    },
    {
        "id": "1P8T",
        "text": "Dermatitis or eczema of perianal area"
    },
    {
        "id": "1P8U",
        "text": "Lichen planus of genital skin and mucous membranes"
    },
    {
        "id": "1P8V",
        "text": "Lichen planus and lichenoid reactions of oral mucosa"
    },
    {
        "id": "1P8W",
        "text": "Pityriasis lichenoides"
    },
    {
        "id": "1P8X",
        "text": "Inducible urticaria and angioedema"
    },
    {
        "id": "1P8Y",
        "text": "Urticaria, angioedema and other urticarial disorders"
    },
    {
        "id": "1P8Z",
        "text": "Cholinergic urticaria and related conditions"
    },
    {
        "id": "1P90",
        "text": "Drug-induced urticaria, angioedema and anaphylaxis"
    },
    {
        "id": "1P91",
        "text": "Diseases of the skin"
    },
    {
        "id": "1P92",
        "text": "Picornavirus infections presenting in the skin or mucous membranes"
    },
    {
        "id": "1P93",
        "text": "Dermatoses from distant or systemic viral infection"
    },
    {
        "id": "1P94",
        "text": "Sphingolipidoses with skin manifestations"
    },
    {
        "id": "1P95",
        "text": "Sphingolipidoses with skin manifestations"
    },
    {
        "id": "1P96",
        "text": "Trichodynia"
    },
    {
        "id": "1P97",
        "text": "Diffuse epidermal hyperkeratosis and acanthosis"
    },
    {
        "id": "1P98",
        "text": "Acanthosis nigricans"
    },
    {
        "id": "1P99",
        "text": "Acanthosis nigricans"
    },
    {
        "id": "1P9B",
        "text": "Poikiloderma"
    },
    {
        "id": "1P9C",
        "text": "Certain specified disorders of adrenal gland"
    },
    {
        "id": "1P9D",
        "text": "Endocrine disorders, not elsewhere classified"
    },
    {
        "id": "1P9E",
        "text": "Lack of expected normal physiological development"
    },
    {
        "id": "1P9F",
        "text": "Antineutrophil cytoplasmic antibody-associated vasculitis"
    },
    {
        "id": "1P9G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P9H",
        "text": "Preterm newborn"
    },
    {
        "id": "1P9I",
        "text": "Maternal care related to the fetus, amniotic cavity or possible delivery problems"
    },
    {
        "id": "1P9J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P9K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1P9L",
        "text": "Secondary lichenification"
    },
    {
        "id": "1P9M",
        "text": "Syndromes with urticarial reactions or angioedema"
    },
    {
        "id": "1P9N",
        "text": "Inflammatory erythemas and other reactive inflammatory dermatoses"
    },
    {
        "id": "1P9P",
        "text": "Dysphonia"
    },
    {
        "id": "1P9Q",
        "text": "Paralysis of vocal cords or larynx"
    },
    {
        "id": "1P9R",
        "text": "Developmental motor coordination disorder"
    },
    {
        "id": "1P9T",
        "text": "Inflammatory dermatoses"
    },
    {
        "id": "1P9U",
        "text": "Autoinflammatory disorders"
    },
    {
        "id": "1P9S",
        "text": "Sick-euthyroid syndrome"
    },
    {
        "id": "1P9V",
        "text": "Localised hyperhidrosis"
    },
    {
        "id": "1P9W",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1P9X",
        "text": "Hereditary haemorrhagic telangiectasia"
    },
    {
        "id": "1P9Y",
        "text": "Primary lymphoedema"
    },
    {
        "id": "1P9Z",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Q00",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Q01",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1Q02",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1Q03",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1Q04",
        "text": "Hypoglycaemia in the context of diabetes mellitus"
    },
    {
        "id": "1P9O",
        "text": "Erythema multiforme with oral ulceration"
    },
    {
        "id": "1Q05",
        "text": "Flushing"
    },
    {
        "id": "1Q06",
        "text": "Birth injury to facial bones"
    },
    {
        "id": "1Q07",
        "text": "Birth injury to central nervous system"
    },
    {
        "id": "1Q08",
        "text": "Birth injury to other organs"
    },
    {
        "id": "1Q09",
        "text": "Adrenal haemorrhage due to birth injury"
    },
    {
        "id": "1Q0A",
        "text": "Digestive system disorders of fetus or newborn"
    },
    {
        "id": "1Q0B",
        "text": "Prenatal intrauterine intestinal perforation due to in utero volvulus"
    },
    {
        "id": "1Q0C",
        "text": "Prenatal intrauterine intestinal perforation due to intestinal atresia or stenosis"
    },
    {
        "id": "1Q0D",
        "text": "Prenatal intrauterine intestinal perforation due to intraluminal obstruction"
    },
    {
        "id": "1Q0E",
        "text": "Postnatal intestinal perforation due to in utero volvulus"
    },
    {
        "id": "1Q0F",
        "text": "Postnatal intestinal perforation due to intestinal atresia or stenosis"
    },
    {
        "id": "1Q0G",
        "text": "Postnatal intestinal perforation due to intraluminal obstruction"
    },
    {
        "id": "1Q0H",
        "text": "Glucose or galactose intolerance of newborn"
    },
    {
        "id": "1Q0I",
        "text": "Acquired vocal cord paralysis in newborn"
    },
    {
        "id": "1Q0J",
        "text": "Airway obstruction in the neonate due to airway abnormality"
    },
    {
        "id": "1Q0K",
        "text": "Hypopharyngeal mass in neonate"
    },
    {
        "id": "1Q0L",
        "text": "Neonatal cardiac failure due to pulmonary overperfusion"
    },
    {
        "id": "1Q0M",
        "text": "Neonatal cardiac failure due to decreased left ventricular output"
    },
    {
        "id": "1Q0N",
        "text": "Bilious vomiting of newborn"
    },
    {
        "id": "1Q0O",
        "text": "Genitourinary system disorders specific to the perinatal or neonatal period"
    },
    {
        "id": "1Q0P",
        "text": "Compression of brain in neonate"
    },
    {
        "id": "1Q0R",
        "text": "Neonatal encephalopathy"
    },
    {
        "id": "1Q0S",
        "text": "Disturbances of temperature regulation of newborn"
    },
    {
        "id": "1Q0T",
        "text": "Conditions involving the umbilical cord"
    },
    {
        "id": "1Q0U",
        "text": "Disorders involving the integument of fetus or newborn"
    },
    {
        "id": "1Q0V",
        "text": "Neonatal hyperglycaemia due to iatrogenic intravenous therapy"
    },
    {
        "id": "1Q0W",
        "text": "Alloimmune neonatal neutropaenia"
    },
    {
        "id": "1Q0X",
        "text": "Polycythaemia neonatorum following umbilical cord transfusion or stripping at delivery"
    },
    {
        "id": "1Q0Y",
        "text": "Polycythaemia neonatorum following blood transfusion"
    },
    {
        "id": "1Q0Z",
        "text": "Polycythaemia neonatorum due to inherited disorder of erythropoietin production"
    },
    {
        "id": "1Q10",
        "text": "Polycythaemia neonatorum due to twin to twin transfusion"
    },
    {
        "id": "1Q11",
        "text": "Polycythaemia neonatorum due to placental insufficiency or fetal intrauterine growth restriction"
    },
    {
        "id": "1Q12",
        "text": "Subdural nontraumatic haemorrhage of fetus or newborn"
    },
    {
        "id": "1Q14",
        "text": "Drug-induced erythroderma"
    },
    {
        "id": "1Q15",
        "text": "Fixed drug eruption"
    },
    {
        "id": "1Q16",
        "text": "Exanthematic drug eruption"
    },
    {
        "id": "1Q17",
        "text": "Drug-induced erythroderma"
    },
    {
        "id": "1Q19",
        "text": "Genitourinary system disorders specific to the perinatal or neonatal period"
    },
    {
        "id": "1Q1A",
        "text": "Acquired vocal cord paralysis in newborn"
    },
    {
        "id": "1Q1B",
        "text": "Neonatal cardiac failure due to pulmonary overperfusion"
    },
    {
        "id": "1Q1C",
        "text": "Conditions involving the umbilical cord"
    },
    {
        "id": "1Q1D",
        "text": "Digestive system disorders of fetus or newborn"
    },
    {
        "id": "1Q1E",
        "text": "Disorders involving the integument of fetus or newborn"
    },
    {
        "id": "1Q1F",
        "text": "Prenatal intrauterine intestinal perforation due to intestinal atresia or stenosis"
    },
    {
        "id": "1Q1G",
        "text": "Neonatal hyperglycaemia due to iatrogenic intravenous therapy"
    },
    {
        "id": "1Q1H",
        "text": "Preterm newborn"
    },
    {
        "id": "1Q1I",
        "text": "Extreme prematurity of newborn"
    },
    {
        "id": "1Q18",
        "text": "Drug-associated immune complex vasculitis"
    },
    {
        "id": "1Q1J",
        "text": "DRESS syndrome"
    },
    {
        "id": "1Q1K",
        "text": "Drug-induced anal ulceration"
    },
    {
        "id": "1Q1L",
        "text": "Drug-induced anal ulceration"
    },
    {
        "id": "1Q1M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Q1N",
        "text": "Chronic lymphadenitis"
    },
    {
        "id": "1Q1O",
        "text": "Lymphadenitis"
    },
    {
        "id": "1Q1P",
        "text": "Chronic lymphadenitis"
    },
    {
        "id": "1Q1Q",
        "text": "Cauliflower ear"
    },
    {
        "id": "1Q1R",
        "text": "Erysipelas"
    },
    {
        "id": "1Q1S",
        "text": "Perichondritis of external ear"
    },
    {
        "id": "1Q1T",
        "text": "Secondary disorders of external ear"
    },
    {
        "id": "1Q1U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Q1W",
        "text": "Diseases of external ear"
    },
    {
        "id": "1Q1X",
        "text": "Involvement of eyelid by dermatosis classified elsewhere"
    },
    {
        "id": "1Q1Y",
        "text": "Self-induced lip trauma"
    },
    {
        "id": "1Q1Z",
        "text": "Self-induced lip trauma"
    },
    {
        "id": "1Q20",
        "text": "Acute haemorrhagic oedema of infancy"
    },
    {
        "id": "1Q21",
        "text": "Paraphilic disorders"
    },
    {
        "id": "1Q22",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Q25",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Q26",
        "text": "Erythema multiforme"
    },
    {
        "id": "1Q27",
        "text": "Cutaneous erythema multiforme"
    },
    {
        "id": "1Q28",
        "text": "Mucocutaneous erythema multiforme"
    },
    {
        "id": "1Q2A",
        "text": "Contact dermatitis of specified site"
    },
    {
        "id": "1Q2C",
        "text": "Dermatitis of hands"
    },
    {
        "id": "1Q2D",
        "text": "Vasomotor or allergic rhinitis"
    },
    {
        "id": "1Q2F",
        "text": "Dermatitis and eczema of lower legs"
    },
    {
        "id": "1Q2I",
        "text": "Irritant contact dermatitis related to stoma or fistula"
    },
    {
        "id": "1Q2B",
        "text": "Contact dermatitis of specified site"
    },
    {
        "id": "1Q2J",
        "text": "Allergic contact sensitisation"
    },
    {
        "id": "1Q2K",
        "text": "Skin injury due to exposure to corrosive substances"
    },
    {
        "id": "1Q2L",
        "text": "Dermatoses provoked by contact with irritant or noxious substance"
    },
    {
        "id": "1Q2N",
        "text": "Loxoscelism"
    },
    {
        "id": "1Q2O",
        "text": "Latrodectism"
    },
    {
        "id": "1Q2P",
        "text": "Funnel-web spider envenomation"
    },
    {
        "id": "1Q2Q",
        "text": "Spider bites"
    },
    {
        "id": "1Q2R",
        "text": "Arachnid bites or stings"
    },
    {
        "id": "1Q2S",
        "text": "Tick bites"
    },
    {
        "id": "1Q2T",
        "text": "Scorpion stings"
    },
    {
        "id": "1Q2U",
        "text": "Cutaneous reactions to zoonotic mites"
    },
    {
        "id": "1Q2V",
        "text": "Trombiculosis"
    },
    {
        "id": "1Q2W",
        "text": "Cheyletiellosis"
    },
    {
        "id": "1Q2Y",
        "text": "Bee stings"
    },
    {
        "id": "1Q30",
        "text": "Cutaneous reactions to venomous or noxious aquatic invertebrates"
    },
    {
        "id": "1Q31",
        "text": "Cutaneous reactions to Cnidaria"
    },
    {
        "id": "1Q32",
        "text": "Cutaneous reactions to Bryozoa"
    },
    {
        "id": "1Q33",
        "text": "Sea mat dermatitis"
    },
    {
        "id": "1Q36",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Q38",
        "text": "Jellyfish, Scyphozoa sting"
    },
    {
        "id": "1Q39",
        "text": "Jellyfish, Cubozoa sting"
    },
    {
        "id": "1Q37",
        "text": "Physalia sting"
    },
    {
        "id": "1Q35",
        "text": "Fire coral sting"
    },
    {
        "id": "1Q34",
        "text": "Sea-bather's eruption"
    },
    {
        "id": "1Q2X",
        "text": "Wasp stings"
    },
    {
        "id": "1Q3A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Q3B",
        "text": "Spider bites"
    },
    {
        "id": "1Q3D",
        "text": "Anthozoa coral injury"
    },
    {
        "id": "1Q3E",
        "text": "Cutaneous reactions to Echinoidea"
    },
    {
        "id": "1Q3F",
        "text": "Sea-urchin granuloma"
    },
    {
        "id": "1Q3G",
        "text": "Sea-urchin injury"
    },
    {
        "id": "1Q3I",
        "text": "Fire sponge dermatitis"
    },
    {
        "id": "1Q2Z",
        "text": "Sea sponge dermatitis"
    },
    {
        "id": "1Q3J",
        "text": "Cutaneous reactions to marine Mollusca"
    },
    {
        "id": "1Q3K",
        "text": "Cone shell envenomation"
    },
    {
        "id": "1Q3L",
        "text": "Octopus bite"
    },
    {
        "id": "1Q3M",
        "text": "Cutaneous reactions to venomous or noxious vertebrates"
    },
    {
        "id": "1Q3N",
        "text": "Fish sting"
    },
    {
        "id": "1Q3O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Q3P",
        "text": "Skin injury due to bite by mammal"
    },
    {
        "id": "1Q2G",
        "text": "Irritant contact dermatitis"
    },
    {
        "id": "1Q3H",
        "text": "Cutaneous reactions to Porifera"
    },
    {
        "id": "1Q3C",
        "text": "Sea anemone dermatitis"
    },
    {
        "id": "1Q29",
        "text": "Contact dermatitis of external ear"
    },
    {
        "id": "1Q3S",
        "text": "Basal cell carcinoma of skin"
    },
    {
        "id": "1Q3U",
        "text": "Disruption of operation wound, not elsewhere classified"
    },
    {
        "id": "1Q3X",
        "text": "Unsatisfactory surgical scar of skin"
    },
    {
        "id": "1Q40",
        "text": "Postprocedural disorders of the skin"
    },
    {
        "id": "1Q41",
        "text": "Trichoepithelioma of eyelids"
    },
    {
        "id": "1Q42",
        "text": "Extension Codes"
    },
    {
        "id": "1Q43",
        "text": "Meningitis due to Borrelia burgdorferi"
    },
    {
        "id": "1Q44",
        "text": "Meningitis due to Borrelia burgdorferi"
    },
    {
        "id": "1Q45",
        "text": "Carcinoma in situ of penis"
    },
    {
        "id": "1Q47",
        "text": "Asymptomatic enteric carriage of Entamoeba"
    },
    {
        "id": "1Q48",
        "text": "Localised hyperhidrosis of other specified site"
    },
    {
        "id": "1Q4A",
        "text": "Congenital anomaly or multiple anomalies, without further specification"
    },
    {
        "id": "1Q4B",
        "text": "Conidiobolomycosis"
    },
    {
        "id": "1Q4D",
        "text": "Hereditary agammaglobulinaemia with profoundly reduced or absent B cells"
    },
    {
        "id": "1Q4E",
        "text": "Isolated agammaglobulinaemia"
    },
    {
        "id": "1Q4F",
        "text": "Syndromic agammaglobulinaemia"
    },
    {
        "id": "1Q4I",
        "text": "Immune dysregulation syndromes with hypopigmentation"
    },
    {
        "id": "1Q4H",
        "text": "Immunodeficiencies with severe reduction in serum IgG or IgA with normal or elevated IgM and normal numbers of B-cells"
    },
    {
        "id": "1Q4G",
        "text": "Immunodeficiencies with severe reduction in at least two serum immunoglobulin isotypes with normal or low numbers of B cells"
    },
    {
        "id": "1Q4J",
        "text": "Immune dysregulation syndromes presenting primarily with autoimmunity"
    },
    {
        "id": "1Q4K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Q4L",
        "text": "Immunodeficiency due to defects of the thymus"
    },
    {
        "id": "1Q4O",
        "text": "Frontotemporal dementia due to MAPT mutation"
    },
    {
        "id": "1Q4N",
        "text": "Frontotemporal dementia due to C9orf72 mutation"
    },
    {
        "id": "1Q4P",
        "text": "Frontotemporal dementia due to VCP mutation"
    },
    {
        "id": "1Q4R",
        "text": "Frontotemporal dementia due to CHMP2B mutation"
    },
    {
        "id": "1Q4U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Q4V",
        "text": "Dementia due to progressive supranuclear palsy"
    },
    {
        "id": "1Q4W",
        "text": "Dementia due to obstructive hydrocephalus"
    },
    {
        "id": "1Q4Y",
        "text": "Early Adolescence"
    },
    {
        "id": "1Q4Z",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1N4G",
        "text": "Sepsis with septic shock"
    },
    {
        "id": "1Q52",
        "text": "Idiopathic Type 1 diabetes mellitus"
    },
    {
        "id": "1Q54",
        "text": "Idiopathic Type 1 diabetes mellitus"
    },
    {
        "id": "1Q53",
        "text": "Idiopathic Type 1 diabetes mellitus"
    },
    {
        "id": "1Q55",
        "text": "Type 2 diabetes mellitus"
    },
    {
        "id": "1Q57",
        "text": "Neonatal haemochromatosis"
    },
    {
        "id": "1Q50",
        "text": "Allergic or hypersensitivity reactions to arthropods"
    },
    {
        "id": "1Q5B",
        "text": "Certain specified hearing impairment"
    },
    {
        "id": "1Q5A",
        "text": "Certain specified hearing impairment"
    },
    {
        "id": "1Q59",
        "text": "Developmental language disorder, with other specified language impairment"
    },
    {
        "id": "1Q5D",
        "text": "Deafness not otherwise specified"
    },
    {
        "id": "1Q5E",
        "text": "Certain specified hearing impairment"
    },
    {
        "id": "1Q5G",
        "text": "Certain specified hearing impairment"
    },
    {
        "id": "1Q5H",
        "text": "Congenital sensorineural hearing loss"
    },
    {
        "id": "1Q5J",
        "text": "Congenital sensorineural hearing loss"
    },
    {
        "id": "1Q5K",
        "text": "Viral warts due to acquired immunodeficiency"
    },
    {
        "id": "1L3E",
        "text": "Viral warts due to iatrogenic immunosuppression"
    },
    {
        "id": "1Q5L",
        "text": "Cutaneous disorders attributable to acquired or iatrogenic immunodeficiency"
    },
    {
        "id": "1Q5N",
        "text": "Hypoglycaemia in the context of diabetes mellitus"
    },
    {
        "id": "1Q5M",
        "text": "Diabetic hyperosmolar hyperglycaemic state"
    },
    {
        "id": "1Q5O",
        "text": "Diabetic hyperosmolar hyperglycaemic state"
    },
    {
        "id": "1Q5P",
        "text": "Diabetic hyperosmolar hyperglycaemic state"
    },
    {
        "id": "1Q5Q",
        "text": "Hypoglycaemia in the context of diabetes mellitus"
    },
    {
        "id": "1Q5R",
        "text": "Hypoglycaemia in the context of diabetes mellitus"
    },
    {
        "id": "1Q5S",
        "text": "Hypoglycaemia without associated diabetes"
    },
    {
        "id": "1Q5T",
        "text": "Sexual dysfunction associated with pelvic organ prolapse"
    },
    {
        "id": "1Q4S",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "1Q4T",
        "text": "Frontotemporal dementia due to TARDBP mutation"
    },
    {
        "id": "1Q5V",
        "text": "Frontotemporal dementia due to FUS mutation"
    },
    {
        "id": "1Q4X",
        "text": "Dementia due to variant Creutzfeldt-Jakob disease"
    },
    {
        "id": "1M5J",
        "text": "Endocarditis of right atrioventricular valve"
    },
    {
        "id": "1M5K",
        "text": "Endocarditis of left atrioventricular valve"
    },
    {
        "id": "1M5F",
        "text": "Acquired truncal valvar abnormality"
    },
    {
        "id": "1Q5X",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "1Q5W",
        "text": "Acquired abnormality of congenitally malformed valve"
    },
    {
        "id": "1Q5Y",
        "text": "Neopulmonary valve stenosis"
    },
    {
        "id": "1Q60",
        "text": "Endocarditis of neopulmonary valve"
    },
    {
        "id": "1Q62",
        "text": "Insufficiency of the neoaortic valve of pulmonary origin"
    },
    {
        "id": "1Q61",
        "text": "Stenosis of the neoaortic valve of pulmonary origin"
    },
    {
        "id": "1Q63",
        "text": "Endocarditis of the neoaortic valve of pulmonary origin"
    },
    {
        "id": "1Q64",
        "text": "Laevocardia"
    },
    {
        "id": "1D5R",
        "text": "Laevocardia"
    },
    {
        "id": "1Q65",
        "text": "Dextrocardia"
    },
    {
        "id": "1Q67",
        "text": "Isomerism of left atrial appendages"
    },
    {
        "id": "1Q66",
        "text": "Isomerism of right atrial appendages"
    },
    {
        "id": "1Q68",
        "text": "Crisscross heart"
    },
    {
        "id": "1Q69",
        "text": "Aortic root posterior and rightward to pulmonary root"
    },
    {
        "id": "1Q6A",
        "text": "Abnormal intrapericardial course of great arteries"
    },
    {
        "id": "1O1A",
        "text": "Spiralling course of great arteries"
    },
    {
        "id": "1Q6B",
        "text": "Congenital anomaly of an atrioventricular or ventriculo-arterial connection"
    },
    {
        "id": "1Q6C",
        "text": "Ventricular septal defect"
    },
    {
        "id": "1Q6D",
        "text": "Perimembranous central ventricular septal defect"
    },
    {
        "id": "1Q6E",
        "text": "Trabecular muscular ventricular septal defect"
    },
    {
        "id": "1Q6F",
        "text": "Trabecular muscular ventricular septal defect midseptal"
    },
    {
        "id": "1Q6H",
        "text": "Trabecular muscular ventricular septal defect postero-inferior"
    },
    {
        "id": "1Q6I",
        "text": "Trabecular muscular ventricular septal defect anterosuperior"
    },
    {
        "id": "1Q6G",
        "text": "Trabecular muscular ventricular septal defect apical"
    },
    {
        "id": "1Q6J",
        "text": "Multiple trabecular muscular ventricular septal defects"
    },
    {
        "id": "1Q6K",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "1Q6L",
        "text": "Outlet muscular ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "1Q6M",
        "text": "Outlet ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "1Q6N",
        "text": "Outlet perimembranous ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "1Q6P",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "1Q6Q",
        "text": "Outlet ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1Q6R",
        "text": "Outlet muscular ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1Q6T",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum"
    },
    {
        "id": "1Q6V",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "1Q6W",
        "text": "Outlet perimembranous ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1Q6X",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum"
    },
    {
        "id": "1Q6S",
        "text": "Outlet perimembranous ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1Q70",
        "text": "Other diseases of larynx"
    },
    {
        "id": "1Q6Z",
        "text": "Paralysis of vocal cords or larynx"
    },
    {
        "id": "1Q71",
        "text": "Other diseases of larynx"
    },
    {
        "id": "1Q72",
        "text": "Other diseases of larynx"
    },
    {
        "id": "1Q73",
        "text": "Intrinsic larynx"
    },
    {
        "id": "1Q74",
        "text": "Laryngeal cartilage"
    },
    {
        "id": "1Q75",
        "text": "Other diseases of larynx"
    },
    {
        "id": "1Q76",
        "text": "Other diseases of larynx"
    },
    {
        "id": "1Q77",
        "text": "Other diseases of larynx"
    },
    {
        "id": "1Q78",
        "text": "Other diseases of larynx"
    },
    {
        "id": "1Q79",
        "text": "Other diseases of larynx"
    },
    {
        "id": "1Q7B",
        "text": "Symptoms or signs related to personality features"
    },
    {
        "id": "1Q7D",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1Q7E",
        "text": "Anxiety or fear-related disorders"
    },
    {
        "id": "1Q7F",
        "text": "Acute and transient psychotic disorder"
    },
    {
        "id": "1Q7G",
        "text": "Acute and transient psychotic disorder, first episode"
    },
    {
        "id": "1Q7H",
        "text": "Acute and transient psychotic disorder, first episode, currently symptomatic"
    },
    {
        "id": "1Q7I",
        "text": "Acute and transient psychotic disorder, first episode, in partial remission"
    },
    {
        "id": "1Q7J",
        "text": "Acute and transient psychotic disorder, first episode, in full remission"
    },
    {
        "id": "1Q7K",
        "text": "Acute and transient psychotic disorder, multiple episodes"
    },
    {
        "id": "1Q7L",
        "text": "Acute and transient psychotic disorder, multiple episodes, currently symptomatic"
    },
    {
        "id": "1Q7M",
        "text": "Acute and transient psychotic disorder, multiple episodes, in partial remission"
    },
    {
        "id": "1Q7N",
        "text": "Acute and transient psychotic disorder, multiple episodes, in full remission"
    },
    {
        "id": "1Q7C",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1O3H",
        "text": "Inlet ventricular septal defect without a common atrioventricular junction"
    },
    {
        "id": "1Q7O",
        "text": "Inlet perimembranous ventricular septal defect without atrioventricular septal malalignment without a common atrioventricular junction"
    },
    {
        "id": "1Q7P",
        "text": "Inlet perimembranous ventricular septal defect with atrioventricular septal malalignment and without a common atrioventricular junction"
    },
    {
        "id": "1Q7R",
        "text": "Inlet perimembranous ventricular septal defect with atrioventricular septal malalignment and without a common atrioventricular junction"
    },
    {
        "id": "1Q7S",
        "text": "Inlet muscular ventricular septal defect"
    },
    {
        "id": "1Q7T",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "1Q7U",
        "text": "Outlet muscular ventricular septal defect without malalignment"
    },
    {
        "id": "1Q7V",
        "text": "Outlet muscular ventricular septal defect without malalignment"
    },
    {
        "id": "1Q7W",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment"
    },
    {
        "id": "1Q7X",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment"
    },
    {
        "id": "1Q7Y",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment and with perimembranous extension"
    },
    {
        "id": "1Q7Z",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment"
    },
    {
        "id": "1Q6O",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum"
    },
    {
        "id": "1Q80",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "1Q81",
        "text": "Outlet ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1Q82",
        "text": "Acquired common atrioventricular valvar abnormality in biventricular connections"
    },
    {
        "id": "1Q84",
        "text": "Acquired common atrioventricular valvar abnormality in biventricular connections"
    },
    {
        "id": "1Q85",
        "text": "Acquired common atrioventricular valvar abnormality in biventricular connections"
    },
    {
        "id": "1Q86",
        "text": "Acquired common atrioventricular valvar abnormality in biventricular connections"
    },
    {
        "id": "1Q87",
        "text": "Acquired common atrioventricular valvar abnormality in biventricular connections"
    },
    {
        "id": "1Q88",
        "text": "Acquired common atrioventricular valvar abnormality in biventricular connections"
    },
    {
        "id": "1Q89",
        "text": "Acquired abnormality of congenitally malformed valve"
    },
    {
        "id": "1Q8A",
        "text": "Acquired abnormality of congenitally malformed valve"
    },
    {
        "id": "1Q8B",
        "text": "Acquired truncal valve regurgitation"
    },
    {
        "id": "1D3S",
        "text": "Acquired abnormality of congenitally malformed valve"
    },
    {
        "id": "1Q8C",
        "text": "Postprocedural disorder of circulatory system following repair of congenital heart or great vessel anomaly"
    },
    {
        "id": "1Q5Z",
        "text": "Neopulmonary valve regurgitation"
    },
    {
        "id": "1Q8D",
        "text": "Postprocedural aneurysm or pseudoaneurysm of aorta at coarctation site"
    },
    {
        "id": "1Q8E",
        "text": "Mycotic corneal ulcer"
    },
    {
        "id": "1Q8G",
        "text": "Mental and emotional disorders (TM1)"
    },
    {
        "id": "1Q8H",
        "text": "Liver system disorders (TM1)"
    },
    {
        "id": "1Q8I",
        "text": "Liver system disorders (TM1)"
    },
    {
        "id": "1Q8K",
        "text": "Liver system disorders (TM1)"
    },
    {
        "id": "1Q8J",
        "text": "Liver system disorders (TM1)"
    },
    {
        "id": "1Q8L",
        "text": "Borrelial lymphocytoma cutis"
    },
    {
        "id": "1Q8M",
        "text": "Acrodermatitis chronica atrophicans"
    },
    {
        "id": "1Q8O",
        "text": "Single spontaneous delivery"
    },
    {
        "id": "1Q8P",
        "text": "Occupational contact dermatitis, contact urticaria or allergy"
    },
    {
        "id": "1Q2E",
        "text": "Allergic contact blepharoconjunctivitis"
    },
    {
        "id": "1Q8Q",
        "text": "Reticular pseudodrusen"
    },
    {
        "id": "1Q91",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q93",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q92",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q96",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q94",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q97",
        "text": "Tick-borne relapsing fever"
    },
    {
        "id": "1Q90",
        "text": "Lyme neuroborreliosis"
    },
    {
        "id": "1Q98",
        "text": "Lyme arthritis"
    },
    {
        "id": "1Q99",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9B",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9C",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9D",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9A",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9E",
        "text": "Meningitis due to Borrelia burgdorferi"
    },
    {
        "id": "1Q9F",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9G",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9H",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9I",
        "text": "Lyme borreliosis"
    },
    {
        "id": "1Q9J",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9K",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9L",
        "text": "Lyme carditis"
    },
    {
        "id": "1Q8U",
        "text": "Early cutaneous Lyme borreliosis"
    },
    {
        "id": "1Q9M",
        "text": "Lyme borreliosis"
    },
    {
        "id": "1Q8S",
        "text": "Borrelial lymphocytoma cutis"
    },
    {
        "id": "1Q8T",
        "text": "Acrodermatitis chronica atrophicans"
    },
    {
        "id": "1Q8R",
        "text": "Erythema migrans"
    },
    {
        "id": "1Q8X",
        "text": "Lyme carditis"
    },
    {
        "id": "1Q9N",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9O",
        "text": "Disseminated Lyme borreliosis"
    },
    {
        "id": "1Q9P",
        "text": "Schistosomiasis"
    },
    {
        "id": "1Q9Q",
        "text": "Spleen system disorders (TM1)"
    },
    {
        "id": "1Q8W",
        "text": "Lyme neuroborreliosis"
    },
    {
        "id": "1Q9R",
        "text": "Lyme arthritis"
    },
    {
        "id": "1Q9S",
        "text": "Allergic asthma with exacerbation"
    },
    {
        "id": "1Q9U",
        "text": "Primary dental caries"
    },
    {
        "id": "1Q9W",
        "text": "Orgasmic dysfunctions"
    },
    {
        "id": "1Q9V",
        "text": "Orgasmic dysfunctions"
    },
    {
        "id": "1Q9X",
        "text": "Orgasmic dysfunctions"
    },
    {
        "id": "1Q9Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Q9Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R00",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R01",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R02",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R04",
        "text": "Disorder of intellectual development, severe"
    },
    {
        "id": "1R06",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1R07",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R08",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R09",
        "text": "Autism spectrum disorder with disorder of intellectual development and with mild or no impairment of functional language"
    },
    {
        "id": "1R0A",
        "text": "Autism spectrum disorder without disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "1R0B",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "1R0C",
        "text": "Autism spectrum disorder without disorder of intellectual development and with absence of functional language"
    },
    {
        "id": "1R0D",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "1R0E",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language"
    },
    {
        "id": "1R0F",
        "text": "Developmental learning disorder with impairment in mathematics"
    },
    {
        "id": "1R0H",
        "text": "Attention deficit hyperactivity disorder, predominantly inattentive presentation"
    },
    {
        "id": "1R0I",
        "text": "Attention deficit hyperactivity disorder, predominantly hyperactive-impulsive presentation"
    },
    {
        "id": "1R0K",
        "text": "Negative symptoms in primary psychotic disorders"
    },
    {
        "id": "1R05",
        "text": "Developmental language disorder with impairment of receptive and expressive language"
    },
    {
        "id": "1R0L",
        "text": "Generalised anxiety disorder"
    },
    {
        "id": "1R0M",
        "text": "Agoraphobia"
    },
    {
        "id": "1R0N",
        "text": "Specific phobia"
    },
    {
        "id": "1R0O",
        "text": "Social anxiety disorder"
    },
    {
        "id": "1R0P",
        "text": "Separation anxiety disorder"
    },
    {
        "id": "1R0Q",
        "text": "Reactive attachment disorder"
    },
    {
        "id": "1R0R",
        "text": "Disinhibited social engagement disorder"
    },
    {
        "id": "1R0S",
        "text": "Dissociative disorders"
    },
    {
        "id": "1R0T",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1R0U",
        "text": "Dissociative neurological symptom disorder, with non-epileptic seizures"
    },
    {
        "id": "1R0V",
        "text": "Dissociative neurological symptom disorder, with paresis or weakness"
    },
    {
        "id": "1R0W",
        "text": "Dissociative neurological symptom disorder, with other sensory disturbance"
    },
    {
        "id": "1R0X",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1R0Y",
        "text": "Dissociative neurological symptom disorder, with gait disturbance"
    },
    {
        "id": "1R0Z",
        "text": "Dissociative neurological symptom disorder, with cognitive symptoms"
    },
    {
        "id": "1R10",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R11",
        "text": "Dissociative neurological symptom disorder, with visual disturbance"
    },
    {
        "id": "1R12",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R13",
        "text": "Dissociative neurological symptom disorder, with auditory disturbance"
    },
    {
        "id": "1R14",
        "text": "Dissociative neurological symptom disorder, with vertigo or dizziness"
    },
    {
        "id": "1R15",
        "text": "Dissociative neurological symptom disorder, with speech disturbance"
    },
    {
        "id": "1R16",
        "text": "Monoparesis"
    },
    {
        "id": "1R17",
        "text": "Paraparesis"
    },
    {
        "id": "1R18",
        "text": "Hemiparesis"
    },
    {
        "id": "1R19",
        "text": "Dissociative amnesia"
    },
    {
        "id": "1R1A",
        "text": "Depersonalization-derealization disorder"
    },
    {
        "id": "1R1B",
        "text": "Trance disorder"
    },
    {
        "id": "1R1C",
        "text": "Possession trance disorder"
    },
    {
        "id": "1R1D",
        "text": "Partial dissociative identity disorder"
    },
    {
        "id": "1R1E",
        "text": "Dissociative identity disorder"
    },
    {
        "id": "1R1F",
        "text": "Dissociative amnesia"
    },
    {
        "id": "1R1G",
        "text": "Bulimia Nervosa"
    },
    {
        "id": "1R1H",
        "text": "Disorders of bodily distress or bodily experience"
    },
    {
        "id": "1R1I",
        "text": "Enuresis"
    },
    {
        "id": "1R1J",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1R0J",
        "text": "Somatic delusion directed at the skin"
    },
    {
        "id": "1R1K",
        "text": "Gambling disorder"
    },
    {
        "id": "1R1L",
        "text": "Harmful pattern of use of alcohol"
    },
    {
        "id": "1R1M",
        "text": "Dementia due to certain specified disorders of the central nervous system"
    },
    {
        "id": "1R1O",
        "text": "Certain specified primary degenerative dementias"
    },
    {
        "id": "1R1P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R1Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R1R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R1S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R1W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R1N",
        "text": "Disorders due to substance use"
    },
    {
        "id": "1R1X",
        "text": "Alcohol-induced delirium"
    },
    {
        "id": "1R1Y",
        "text": "Cannabis-induced delirium"
    },
    {
        "id": "1R1Z",
        "text": "Synthetic cannabinoid-induced delirium"
    },
    {
        "id": "1R21",
        "text": "Sedative, hypnotic or anxiolytic-induced delirium"
    },
    {
        "id": "1R22",
        "text": "Cocaine-induced delirium"
    },
    {
        "id": "1R23",
        "text": "Stimulant-induced delirium including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1R24",
        "text": "Synthetic cathinone-induced delirium"
    },
    {
        "id": "1R25",
        "text": "Hallucinogen-induced delirium"
    },
    {
        "id": "1R26",
        "text": "Volatile inhalant-induced delirium"
    },
    {
        "id": "1R27",
        "text": "Volatile inhalant-induced delirium"
    },
    {
        "id": "1R28",
        "text": "MDMA or related drug-induced delirium, including MDA"
    },
    {
        "id": "1R29",
        "text": "Dissociative drug-induced delirium including ketamine or PCP"
    },
    {
        "id": "1R2A",
        "text": "Delirium induced by other specified psychoactive substance including medications"
    },
    {
        "id": "1R2B",
        "text": "Delirium induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1R2C",
        "text": "Alcohol-induced mood disorder"
    },
    {
        "id": "1R2D",
        "text": "Opioid intoxication"
    },
    {
        "id": "1R20",
        "text": "Opioid-induced delirium"
    },
    {
        "id": "1R2F",
        "text": "Cocaine dependence, early full remission"
    },
    {
        "id": "1R2G",
        "text": "Cocaine-induced mood disorder"
    },
    {
        "id": "1R2H",
        "text": "Hallucinogen-induced mood disorder"
    },
    {
        "id": "1R2I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2J",
        "text": "Harmful pattern of use of volatile inhalants"
    },
    {
        "id": "1R2K",
        "text": "Disorders due to use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1R2L",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1R2N",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1R2E",
        "text": "Harmful pattern of use of sedatives, hypnotics or anxiolytics, episodic"
    },
    {
        "id": "1R03",
        "text": "Immune-related Type 1 diabetes mellitus"
    },
    {
        "id": "1R2O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R2X",
        "text": "Parkinson disease"
    },
    {
        "id": "1R2M",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1R2Y",
        "text": "Other body system disorders (TM1)"
    },
    {
        "id": "1R1V",
        "text": "Dementia due to certain specified cause"
    },
    {
        "id": "1R1U",
        "text": "Dementia due to rheumatological diseases"
    },
    {
        "id": "1R1T",
        "text": "Dementia due to chromosomal anomalies"
    },
    {
        "id": "1R30",
        "text": "Primary biliary cholangitis with overlap syndrome"
    },
    {
        "id": "1R32",
        "text": "Chronic peripheral neuropathic pain"
    },
    {
        "id": "1R33",
        "text": "Chronic peripheral neuropathic pain"
    },
    {
        "id": "1R35",
        "text": "Chronic peripheral neuropathic pain"
    },
    {
        "id": "1R36",
        "text": "Chronic central neuropathic pain"
    },
    {
        "id": "1R37",
        "text": "Chronic central neuropathic pain"
    },
    {
        "id": "1R38",
        "text": "Chronic central neuropathic pain"
    },
    {
        "id": "1R39",
        "text": "Chronic central neuropathic pain"
    },
    {
        "id": "1R3A",
        "text": "Chronic primary headache or orofacial pain"
    },
    {
        "id": "1R3F",
        "text": "Chronic primary headache or orofacial pain"
    },
    {
        "id": "1R3G",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "1R3H",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "1R3K",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "1R3L",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "1R3M",
        "text": "Chronic primary musculoskeletal pain"
    },
    {
        "id": "1R3N",
        "text": "Chronic primary musculoskeletal pain"
    },
    {
        "id": "1R3O",
        "text": "Chronic primary musculoskeletal pain"
    },
    {
        "id": "1R3P",
        "text": "Chronic primary musculoskeletal pain"
    },
    {
        "id": "1R3Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R3R",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "1R3T",
        "text": "Chronic cancer pain"
    },
    {
        "id": "1R3U",
        "text": "Chronic cancer pain"
    },
    {
        "id": "1R3S",
        "text": "Chronic cancer pain"
    },
    {
        "id": "1R3V",
        "text": "Chronic painful chemotherapy-induced polyneuropathy"
    },
    {
        "id": "1R3W",
        "text": "Chronic post radiotherapy pain"
    },
    {
        "id": "1R3Y",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "1R3Z",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "1R40",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "1R41",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "1R42",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "1R43",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1R3X",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "1R44",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1R45",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1R46",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1R47",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1R48",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1R49",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1R4A",
        "text": "Chronic secondary musculoskeletal pain from persistent inflammation"
    },
    {
        "id": "1R4B",
        "text": "Chronic secondary musculoskeletal pain from persistent inflammation"
    },
    {
        "id": "1R4C",
        "text": "Chronic secondary musculoskeletal pain from persistent inflammation"
    },
    {
        "id": "1R4D",
        "text": "Chronic secondary musculoskeletal pain associated with structural changes"
    },
    {
        "id": "1R4E",
        "text": "Chronic secondary musculoskeletal pain associated with structural changes"
    },
    {
        "id": "1R4F",
        "text": "Chronic visceral pain from mechanical factors"
    },
    {
        "id": "1R4G",
        "text": "Chronic visceral pain from mechanical factors"
    },
    {
        "id": "1R4H",
        "text": "Chronic visceral pain from mechanical factors"
    },
    {
        "id": "1R4I",
        "text": "Chronic visceral pain from mechanical factors"
    },
    {
        "id": "1R4J",
        "text": "Chronic visceral pain from vascular mechanisms"
    },
    {
        "id": "1R4K",
        "text": "Chronic visceral pain from vascular mechanisms"
    },
    {
        "id": "1R4L",
        "text": "Chronic visceral pain from vascular mechanisms"
    },
    {
        "id": "1R4M",
        "text": "Chronic visceral pain from vascular mechanisms"
    },
    {
        "id": "1R4N",
        "text": "Chronic visceral pain from persistent inflammation"
    },
    {
        "id": "1R4O",
        "text": "Chronic visceral pain from persistent inflammation"
    },
    {
        "id": "1R4P",
        "text": "Chronic visceral pain from persistent inflammation"
    },
    {
        "id": "1R4Q",
        "text": "Chronic visceral pain from persistent inflammation"
    },
    {
        "id": "1R4R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R4S",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1R4T",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1R4U",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1R2Z",
        "text": "Postviral fatigue syndrome"
    },
    {
        "id": "1R4V",
        "text": "Certain conditions originating in the perinatal period"
    },
    {
        "id": "1R3E",
        "text": "Chronic primary headache or orofacial pain"
    },
    {
        "id": "1R3B",
        "text": "Chronic primary headache or orofacial pain"
    },
    {
        "id": "1R3I",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "1R4W",
        "text": "Disorders associated with tremor"
    },
    {
        "id": "1R4X",
        "text": "Tic disorders"
    },
    {
        "id": "1R4Y",
        "text": "Disorders associated with tremor"
    },
    {
        "id": "1R4Z",
        "text": "Body Tissues"
    },
    {
        "id": "1R50",
        "text": "Scalp"
    },
    {
        "id": "1R58",
        "text": "Apex of helix"
    },
    {
        "id": "1R57",
        "text": "Ceruminal gland"
    },
    {
        "id": "1R59",
        "text": "Pinna"
    },
    {
        "id": "1R5C",
        "text": "Anaphylaxis"
    },
    {
        "id": "1R5E",
        "text": "Eyebrow"
    },
    {
        "id": "1R5F",
        "text": "Eyelids"
    },
    {
        "id": "1R5G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R5H",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "1R5J",
        "text": "Dementia due to Alzheimer disease with early onset"
    },
    {
        "id": "1R5K",
        "text": "Dementia due to Alzheimer disease with late onset"
    },
    {
        "id": "1R5L",
        "text": "Dementia due to Alzheimer disease"
    },
    {
        "id": "1R5M",
        "text": "Dementia due to diseases classified elsewhere"
    },
    {
        "id": "1R5N",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "1R5O",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "1R5Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R5I",
        "text": "Dementia due to Alzheimer disease"
    },
    {
        "id": "1R61",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "1R63",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R64",
        "text": "Thoracic cavity"
    },
    {
        "id": "1N3O",
        "text": "Extension Codes"
    },
    {
        "id": "1R68",
        "text": "Diseases of the nervous system"
    },
    {
        "id": "1R6C",
        "text": "Diseases of the nervous system"
    },
    {
        "id": "1R6A",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1R60",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "1R5P",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "1Q8N",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "1Q5C",
        "text": "Developmental speech fluency disorder"
    },
    {
        "id": "1Q5F",
        "text": "Developmental speech fluency disorder"
    },
    {
        "id": "1R6E",
        "text": "Speech dysfluency"
    },
    {
        "id": "1R6F",
        "text": "Speech dysfluency"
    },
    {
        "id": "1K0Q",
        "text": "Disorders due to addictive behaviours"
    },
    {
        "id": "1R6H",
        "text": "Dysphagia"
    },
    {
        "id": "1R6G",
        "text": "Developmental speech or language disorders"
    },
    {
        "id": "1R6I",
        "text": "Developmental speech sound disorder"
    },
    {
        "id": "1R6K",
        "text": "Dysphagia"
    },
    {
        "id": "1R6J",
        "text": "Developmental language disorder"
    },
    {
        "id": "1R6L",
        "text": "Developmental speech or language disorders"
    },
    {
        "id": "1R6M",
        "text": "Dysphagia"
    },
    {
        "id": "1R6N",
        "text": "Dysphagia"
    },
    {
        "id": "1R6O",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "1R6P",
        "text": "Episode of harmful use of alcohol"
    },
    {
        "id": "1R62",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "1R6Q",
        "text": "Malignant neoplasm metastasis in axillary lymph nodes"
    },
    {
        "id": "1R6S",
        "text": "Cerebrovascular diseases"
    },
    {
        "id": "1Q7A",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1R6U",
        "text": "Partial dissociative identity disorder"
    },
    {
        "id": "1R6W",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1R6X",
        "text": "Amoebic infection of other sites"
    },
    {
        "id": "1R6Y",
        "text": "Infantile haemangioma of liver"
    },
    {
        "id": "1R6Z",
        "text": "Cavernous haemangioma of liver"
    },
    {
        "id": "1R70",
        "text": "Benign cutaneous neoplasms with follicular differentiation"
    },
    {
        "id": "1R72",
        "text": "Acquired vascular purpura"
    },
    {
        "id": "1R73",
        "text": "Acquired vascular purpura"
    },
    {
        "id": "1R74",
        "text": "Benign proliferations or neoplasms of cutaneous blood vessels"
    },
    {
        "id": "1R75",
        "text": "Benign proliferations or neoplasms of cutaneous blood vessels"
    },
    {
        "id": "1R76",
        "text": "Non-thrombocytopenic purpura"
    },
    {
        "id": "1R77",
        "text": "Antitragus of pinna"
    },
    {
        "id": "1R78",
        "text": "Antihelix"
    },
    {
        "id": "1R79",
        "text": "Helix of pinna"
    },
    {
        "id": "1R7A",
        "text": "Retroauricular sulcus"
    },
    {
        "id": "1R7B",
        "text": "Intertragic notch of pinna"
    },
    {
        "id": "1R7C",
        "text": "Skin of auricle"
    },
    {
        "id": "1R7D",
        "text": "Tragus of pinna"
    },
    {
        "id": "1R5B",
        "text": "Triangular fossa of pinna"
    },
    {
        "id": "1R7F",
        "text": "External auditory canal"
    },
    {
        "id": "1R7G",
        "text": "Earlobe"
    },
    {
        "id": "1R7H",
        "text": "Concha of pinna"
    },
    {
        "id": "1R7I",
        "text": "Cymba conchae"
    },
    {
        "id": "1R7J",
        "text": "Pinna"
    },
    {
        "id": "1R7K",
        "text": "Scaphoid fossa of pinna"
    },
    {
        "id": "1R7L",
        "text": "Crura of antihelix"
    },
    {
        "id": "1R7M",
        "text": "Posterior surface of pinna"
    },
    {
        "id": "1R7N",
        "text": "Tail of helix"
    },
    {
        "id": "1R7O",
        "text": "Spine of helix"
    },
    {
        "id": "1R7P",
        "text": "Crus of helix"
    },
    {
        "id": "1R7Q",
        "text": "Apex of helix"
    },
    {
        "id": "1R7R",
        "text": "External Ear"
    },
    {
        "id": "1P8O",
        "text": "Dermatitis and eczema of lower legs"
    },
    {
        "id": "1R55",
        "text": "Conchal bowl of pinna"
    },
    {
        "id": "1M9M",
        "text": "Viral meningitis due to Epstein-Barr virus"
    },
    {
        "id": "1R7S",
        "text": "Zoster without complications"
    },
    {
        "id": "1R7T",
        "text": "Trigeminal nerve"
    },
    {
        "id": "1R7U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R7V",
        "text": "Peripheral nerve"
    },
    {
        "id": "1R7Y",
        "text": "Congenital haemangioma"
    },
    {
        "id": "1R7Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R80",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R81",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R82",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R83",
        "text": "Infantile laryngotracheal haemangioma"
    },
    {
        "id": "1R7X",
        "text": "Benign vascular neoplasms of infancy and childhood"
    },
    {
        "id": "1R84",
        "text": "Infantile subglottic haemangioma"
    },
    {
        "id": "1R85",
        "text": "Haemangioma of orbit"
    },
    {
        "id": "1R86",
        "text": "Extracutaneous infantile haemangioma"
    },
    {
        "id": "1R89",
        "text": "Postviral fatigue syndrome"
    },
    {
        "id": "1R8A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R8B",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1R8D",
        "text": "Lymphocutaneous leishmaniasis"
    },
    {
        "id": "1R8E",
        "text": "Cutaneous leishmaniasis"
    },
    {
        "id": "1R8F",
        "text": "Lymphocutaneous leishmaniasis"
    },
    {
        "id": "1R8G",
        "text": "Leishmaniasis"
    },
    {
        "id": "1R8H",
        "text": "Vaginal discharge"
    },
    {
        "id": "1R8I",
        "text": "Loss of love relationship in childhood"
    },
    {
        "id": "1R8J",
        "text": "Leishmania donovani infantum"
    },
    {
        "id": "1R8K",
        "text": "Cutaneous leishmaniasis"
    },
    {
        "id": "1R8L",
        "text": "Mucocutaneous leishmaniasis"
    },
    {
        "id": "1R8O",
        "text": "Hyperkeratotic dermatitis of hands and feet"
    },
    {
        "id": "1R8N",
        "text": "Vesicular dermatitis of hands and feet"
    },
    {
        "id": "1R8Q",
        "text": "Hyperkeratotic fissured palmar dermatitis"
    },
    {
        "id": "1R8R",
        "text": "Fingertip dermatitis"
    },
    {
        "id": "1R8S",
        "text": "Foot eczema"
    },
    {
        "id": "1R8T",
        "text": "Irritant contact dermatitis of male genitalia"
    },
    {
        "id": "1R8U",
        "text": "Allergic contact dermatitis of female genitalia"
    },
    {
        "id": "1R8V",
        "text": "Dermatitis or eczema of certain specified sites"
    },
    {
        "id": "1R8W",
        "text": "Generalised eczematous dermatitis of unspecified type"
    },
    {
        "id": "1R8X",
        "text": "Cutaneous amyloidosis"
    },
    {
        "id": "1R8Y",
        "text": "Cutaneous mucinosis"
    },
    {
        "id": "1R90",
        "text": "Diffuse dyslipidaemic plane xanthoma"
    },
    {
        "id": "1R8Z",
        "text": "Palmar xanthoma"
    },
    {
        "id": "1R91",
        "text": "Xanthoma in association with primary hypertriglyceridaemia"
    },
    {
        "id": "1R92",
        "text": "Xanthoma in association with primary hypercholesterolaemia"
    },
    {
        "id": "1R94",
        "text": "Xanthoma in association with primary chylomicronaemia"
    },
    {
        "id": "1R95",
        "text": "Xanthoma in association with secondary hyperlipidaemia"
    },
    {
        "id": "1R96",
        "text": "Xanthoma in association with lipid storage diseases"
    },
    {
        "id": "1Q46",
        "text": "Squamous cell carcinoma in situ of skin of penis"
    },
    {
        "id": "1R9B",
        "text": "Warts of lips or oral cavity"
    },
    {
        "id": "1R9A",
        "text": "Warts of lips or oral cavity"
    },
    {
        "id": "1R9C",
        "text": "Warts of lips or oral cavity"
    },
    {
        "id": "1R93",
        "text": "Xanthoma in association with primary combined hyperlipidaemia"
    },
    {
        "id": "1R9E",
        "text": "Anogenital psoriasis"
    },
    {
        "id": "1R9F",
        "text": "Hereditary alopecia or hypotrichosis"
    },
    {
        "id": "1R9G",
        "text": "Inherited deformities of nails"
    },
    {
        "id": "1R9H",
        "text": "Developmental venous malformations involving the skin"
    },
    {
        "id": "1R9I",
        "text": "Complex or combined developmental vascular malformations involving the skin"
    },
    {
        "id": "1R9J",
        "text": "Developmental anomalies of the umbilicus"
    },
    {
        "id": "1R9K",
        "text": "Drug-induced pruritus"
    },
    {
        "id": "1R9L",
        "text": "Pruritus of unknown cause"
    },
    {
        "id": "1R9O",
        "text": "Atopic prurigo"
    },
    {
        "id": "1R9P",
        "text": "Childhood atopic eczema, prurigo pattern"
    },
    {
        "id": "1R9Q",
        "text": "Adult atopic eczema, prurigo pattern"
    },
    {
        "id": "1R9R",
        "text": "Prurigo of undetermined type"
    },
    {
        "id": "1R9T",
        "text": "Pemphigus foliaceus"
    },
    {
        "id": "1R9U",
        "text": "Allergic tattoo reaction"
    },
    {
        "id": "1R9V",
        "text": "Non-allergic tattoo reaction"
    },
    {
        "id": "1R9X",
        "text": "Heart failure"
    },
    {
        "id": "1R9Y",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1R9Z",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1S00",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "1S01",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "1S02",
        "text": "Radial styloid tenosynovitis"
    },
    {
        "id": "1S05",
        "text": "Papulosquamous dermatoses"
    },
    {
        "id": "1S06",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R9M",
        "text": "Nodular prurigo"
    },
    {
        "id": "1S07",
        "text": "Papular amyloidosis"
    },
    {
        "id": "1S08",
        "text": "Palmoplantar keratoderma with scleroatrophy of the extremities"
    },
    {
        "id": "1S0A",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1S0B",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "1S0C",
        "text": "Puerperal sepsis"
    },
    {
        "id": "1S0D",
        "text": "Infection of obstetric surgical wound"
    },
    {
        "id": "1S0E",
        "text": "Perimembranous central ventricular septal defect"
    },
    {
        "id": "1S0G",
        "text": "Inlet perimembranous ventricular septal defect with atrioventricular septal malalignment and without a common atrioventricular junction"
    },
    {
        "id": "1S0F",
        "text": "Inlet perimembranous ventricular septal defect without atrioventricular septal malalignment without a common atrioventricular junction"
    },
    {
        "id": "1S0H",
        "text": "Outlet perimembranous ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "1S0J",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "1S0K",
        "text": "Outlet perimembranous ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1S0L",
        "text": "Outlet muscular ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "1S0M",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "1S0N",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and muscular postero-inferior rim"
    },
    {
        "id": "1S0O",
        "text": "Outlet muscular ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "1S0Q",
        "text": "Transient neonatal neutropaenia due to neonatal bacterial sepsis"
    },
    {
        "id": "1S0R",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "1S0S",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "1S0T",
        "text": "Parkinson disease"
    },
    {
        "id": "1S0U",
        "text": "Non-hereditary degenerative ataxia"
    },
    {
        "id": "1S0P",
        "text": "Bullous pemphigoid variants"
    },
    {
        "id": "1S0W",
        "text": "Occupational acne or folliculitis"
    },
    {
        "id": "1S0X",
        "text": "Secondary neurocognitive syndrome"
    },
    {
        "id": "1S0Z",
        "text": "Autism spectrum disorder with disorder of intellectual development and with impaired functional language with loss of previously acquired skills"
    },
    {
        "id": "1S10",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1S11",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S13",
        "text": "Acquired hypomelanotic disorders"
    },
    {
        "id": "1S14",
        "text": "Acute urticarial skin eruption"
    },
    {
        "id": "1S15",
        "text": "Chronic skin disorder of uncertain or unspecified nature"
    },
    {
        "id": "1S17",
        "text": "Generalised eruptive lentiginosis"
    },
    {
        "id": "1S19",
        "text": "Dowling-Degos disease"
    },
    {
        "id": "1S1A",
        "text": "Scarring alopecia due to malignant infiltration"
    },
    {
        "id": "1S1C",
        "text": "Developmental defects of hair or hair growth"
    },
    {
        "id": "1S1D",
        "text": "Certain disorders involving the immune system"
    },
    {
        "id": "1S1E",
        "text": "Certain disorders involving the immune system"
    },
    {
        "id": "1S1F",
        "text": "Certain disorders involving the immune system"
    },
    {
        "id": "1S1G",
        "text": "Castleman disease"
    },
    {
        "id": "1S1H",
        "text": "Certain disorders involving the immune system"
    },
    {
        "id": "1S1I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S1L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S1N",
        "text": "Pyromania"
    },
    {
        "id": "1S1O",
        "text": "Pyromania"
    },
    {
        "id": "1S1P",
        "text": "Kleptomania"
    },
    {
        "id": "1S1Q",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1S1S",
        "text": "Repetitive excoriation"
    },
    {
        "id": "1S1T",
        "text": "Acne excoriee"
    },
    {
        "id": "1S1U",
        "text": "Repetitive scratching behaviour"
    },
    {
        "id": "1S1V",
        "text": "Repetitive self-biting behaviour"
    },
    {
        "id": "1S1W",
        "text": "Trichoteiromania"
    },
    {
        "id": "1S1X",
        "text": "Trichotemnomania"
    },
    {
        "id": "1S1Y",
        "text": "Trichophagia"
    },
    {
        "id": "1S1Z",
        "text": "Psychological or psychiatric conditions affecting the skin"
    },
    {
        "id": "1S1R",
        "text": "Impulse control disorders"
    },
    {
        "id": "1R9W",
        "text": "Impulse control disorders"
    },
    {
        "id": "1S20",
        "text": "Excoriation disorder"
    },
    {
        "id": "1S1M",
        "text": "Atopic eczema"
    },
    {
        "id": "1S0V",
        "text": "Aetiology"
    },
    {
        "id": "1S21",
        "text": "Certain specified disorders of arteries or arterioles"
    },
    {
        "id": "1S22",
        "text": "Raynaud phenomenon"
    },
    {
        "id": "1S23",
        "text": "Allergic contact dermatitis"
    },
    {
        "id": "1S24",
        "text": "Photo-allergic contact dermatitis"
    },
    {
        "id": "1S28",
        "text": "46,XY disorder of sex development due to androgen resistance"
    },
    {
        "id": "1S29",
        "text": "46,XY disorder of sex development due to a defect in testosterone metabolism"
    },
    {
        "id": "1S2A",
        "text": "Chimaera 46, XX, 46, XY"
    },
    {
        "id": "1S2B",
        "text": "Structural developmental anomalies of clitoris"
    },
    {
        "id": "1S2C",
        "text": "Clitoromegaly"
    },
    {
        "id": "1S2D",
        "text": "46,XX disorders of sex development induced by androgens of fetal origin"
    },
    {
        "id": "1S2E",
        "text": "Congenital adrenal hyperplasia"
    },
    {
        "id": "1S2F",
        "text": "46,XX disorders of sex development induced by androgens of maternal origin"
    },
    {
        "id": "1S2H",
        "text": "46,XX gonadal dysgenesis"
    },
    {
        "id": "1S2G",
        "text": "46,XY gonadal dysgenesis"
    },
    {
        "id": "1S2I",
        "text": "Structural developmental anomalies of the male genital system"
    },
    {
        "id": "1S2L",
        "text": "Hypospadias, penoscrotal"
    },
    {
        "id": "1S2K",
        "text": "Hypospadias, scrotal"
    },
    {
        "id": "1S2M",
        "text": "Hypospadias, penile"
    },
    {
        "id": "1S2N",
        "text": "Hypospadias, balanic"
    },
    {
        "id": "1S2J",
        "text": "Hypospadias, perineal"
    },
    {
        "id": "1S2O",
        "text": "Klinefelter syndrome"
    },
    {
        "id": "1S2P",
        "text": "Klinefelter syndrome with karyotype 47,XXY, regular"
    },
    {
        "id": "1S2Q",
        "text": "Klinefelter syndrome, male with more than two X chromosomes"
    },
    {
        "id": "1S2S",
        "text": "Structural developmental anomalies of uterus, except cervix"
    },
    {
        "id": "1S2T",
        "text": "MURCS association"
    },
    {
        "id": "1S2U",
        "text": "Testicular dysfunction or testosterone-related disorders"
    },
    {
        "id": "1S2V",
        "text": "Testicular hypofunction"
    },
    {
        "id": "1S27",
        "text": "Malformative disorders of sex development"
    },
    {
        "id": "1S2W",
        "text": "Postprocedural disorders of genitourinary system"
    },
    {
        "id": "1S2X",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "1S2Y",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "1S2Z",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1S30",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1S31",
        "text": "Gender incongruence"
    },
    {
        "id": "1S33",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "1S34",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "1S2R",
        "text": "Ovotesticular disorder of sex development"
    },
    {
        "id": "1S36",
        "text": "Burnout"
    },
    {
        "id": "1S37",
        "text": "Disorders specifically associated with stress"
    },
    {
        "id": "1S38",
        "text": "Adjustment disorder"
    },
    {
        "id": "1S39",
        "text": "Palmoplantar keratodermas"
    },
    {
        "id": "1S3A",
        "text": "Lower limb venous telangiectases"
    },
    {
        "id": "1S3B",
        "text": "Discrete acquired superficial venous or capillary ectasias"
    },
    {
        "id": "1S3C",
        "text": "Tuberculoid leprosy"
    },
    {
        "id": "1S3D",
        "text": "Indeterminate leprosy"
    },
    {
        "id": "1S3L",
        "text": "Dermatophytosis of face"
    },
    {
        "id": "1S3M",
        "text": "Common warts"
    },
    {
        "id": "1S3O",
        "text": "Dermographism"
    },
    {
        "id": "1S3P",
        "text": "Phototoxic reactions to skin contact with photoactive agents"
    },
    {
        "id": "1S3Q",
        "text": "Cutaneous wounds, injuries or scars"
    },
    {
        "id": "1S3R",
        "text": "Chromoblastomycosis"
    },
    {
        "id": "1S3S",
        "text": "Seborrhoeic psoriasis"
    },
    {
        "id": "1S09",
        "text": "Capillaritis"
    },
    {
        "id": "1R9N",
        "text": "Psychogenic pruritus"
    },
    {
        "id": "1S3T",
        "text": "Secondary lymphoedema"
    },
    {
        "id": "1S3W",
        "text": "Deep bacterial folliculitis or pyogenic abscess of the skin"
    },
    {
        "id": "1S3U",
        "text": "Stretch marks"
    },
    {
        "id": "1S25",
        "text": "Dermatitis due to exogenous factors"
    },
    {
        "id": "1S3X",
        "text": "Pruritus due to skin disorder"
    },
    {
        "id": "1S3Y",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "1S41",
        "text": "Anogenital warts"
    },
    {
        "id": "1S43",
        "text": "Keratinocytic epidermal hamartoma"
    },
    {
        "id": "1S44",
        "text": "Keratinocytic epidermal hamartoma"
    },
    {
        "id": "1S45",
        "text": "Developmental hamartomata of the epidermis and epidermal appendages"
    },
    {
        "id": "1S46",
        "text": "Non-dermatophyte superficial dermatomycoses"
    },
    {
        "id": "1S47",
        "text": "Common warts"
    },
    {
        "id": "1S12",
        "text": "Idiopathic guttate hypomelanosis"
    },
    {
        "id": "1S48",
        "text": "Acquired hypermelanosis"
    },
    {
        "id": "1S4B",
        "text": "Actinic lentigo"
    },
    {
        "id": "1S4D",
        "text": "Labial melanotic macule"
    },
    {
        "id": "1S4E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S4F",
        "text": "Stasis dermatitis of the lower legs"
    },
    {
        "id": "1S4G",
        "text": "Herpes simplex infection of genitalia or urogenital tract"
    },
    {
        "id": "1S4H",
        "text": "Benign cutaneous melanocytic neoplasms"
    },
    {
        "id": "1S4I",
        "text": "Diffuse non-epidermolytic palmoplantar keratoderma"
    },
    {
        "id": "1S4J",
        "text": "Allergic contact sensitisation to metals or metal salts"
    },
    {
        "id": "1S4K",
        "text": "Koilonychia"
    },
    {
        "id": "1S4L",
        "text": "Mycosis fungoides"
    },
    {
        "id": "1S3K",
        "text": "Lucio type diffuse lepromatous leprosy"
    },
    {
        "id": "1S3J",
        "text": "Lepromatous leprosy"
    },
    {
        "id": "1S3I",
        "text": "Borderline lepromatous leprosy"
    },
    {
        "id": "1S3H",
        "text": "Borderline leprosy"
    },
    {
        "id": "1S3G",
        "text": "Multibacillary leprosy"
    },
    {
        "id": "1S3E",
        "text": "Borderline tuberculoid leprosy"
    },
    {
        "id": "1S3F",
        "text": "Paucibacillary leprosy"
    },
    {
        "id": "1S1B",
        "text": "Acquired changes in hair colour"
    },
    {
        "id": "1R9S",
        "text": "Darier disease"
    },
    {
        "id": "1R8P",
        "text": "Hyperkeratotic fissured plantar dermatitis"
    },
    {
        "id": "1R8M",
        "text": "Anaphylaxis provoked by physical factors"
    },
    {
        "id": "1S4R",
        "text": "Upper respiratory tract disorders"
    },
    {
        "id": "1S4S",
        "text": "Acute purpuric skin eruption"
    },
    {
        "id": "1S4V",
        "text": "Pregnancy examination or test"
    },
    {
        "id": "1S4U",
        "text": "Marginal cyst of eyelid"
    },
    {
        "id": "1S4W",
        "text": "Contact with health services for specific surgical interventions"
    },
    {
        "id": "1S4X",
        "text": "Stress, not elsewhere classified"
    },
    {
        "id": "1S4Y",
        "text": "Mucous membrane pemphigoid"
    },
    {
        "id": "1S4Z",
        "text": "Obstetric haemorrhage"
    },
    {
        "id": "1S51",
        "text": "Epidermolysis bullosa acquisita, Brunsting-Perry type"
    },
    {
        "id": "1S50",
        "text": "Miscellaneous pemphigoid variants"
    },
    {
        "id": "1S52",
        "text": "Spitzoid tumour of uncertain malignant potential"
    },
    {
        "id": "1S53",
        "text": "Atopic eczema, special forms"
    },
    {
        "id": "1S55",
        "text": "Personal history of malignant neoplasm"
    },
    {
        "id": "1S56",
        "text": "Family history of malignant neoplasm"
    },
    {
        "id": "1S57",
        "text": "Family history of malignant neoplasm"
    },
    {
        "id": "1S58",
        "text": "Sepsis of fetus or newborn"
    },
    {
        "id": "1S5B",
        "text": "Drug-induced palmoplantar pustulosis"
    },
    {
        "id": "1S5C",
        "text": "Vaccines"
    },
    {
        "id": "1S5D",
        "text": "Birth injury to cranial nerves"
    },
    {
        "id": "1S5E",
        "text": "Birth injury to skeleton"
    },
    {
        "id": "1S5F",
        "text": "Disturbances of temperature regulation of newborn"
    },
    {
        "id": "1S5G",
        "text": "Environmental hyperthermia of newborn"
    },
    {
        "id": "1S5H",
        "text": "Fever of newborn"
    },
    {
        "id": "1S5J",
        "text": "Cleft uvula"
    },
    {
        "id": "1S5L",
        "text": "Cryptorchidism"
    },
    {
        "id": "1S5K",
        "text": "Structural developmental anomalies of the male genital system"
    },
    {
        "id": "1S5M",
        "text": "Perichondritis of external ear"
    },
    {
        "id": "1S5N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S5O",
        "text": "Infectious diseases of external ear"
    },
    {
        "id": "1S5Q",
        "text": "Female pelvic pain"
    },
    {
        "id": "1S5R",
        "text": "Pelvic or perineal pain"
    },
    {
        "id": "1S5S",
        "text": "Pyogenic bacterial infections of the skin or subcutaneous tissues"
    },
    {
        "id": "1L2X",
        "text": "Insect bites or stings"
    },
    {
        "id": "1S5U",
        "text": "Routine newborn health examination"
    },
    {
        "id": "1S5V",
        "text": "Adjustment or management of implanted devices"
    },
    {
        "id": "1S5W",
        "text": "Contact with health services for insertion of contraceptive device"
    },
    {
        "id": "1S5X",
        "text": "Presence of artificial opening"
    },
    {
        "id": "1S5Y",
        "text": "Lack of physical exercise"
    },
    {
        "id": "1S5Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S61",
        "text": "Problems with hygiene behaviours"
    },
    {
        "id": "1S62",
        "text": "Problems with oral health behaviours"
    },
    {
        "id": "1S60",
        "text": "Problems with inappropriate diet or eating habits"
    },
    {
        "id": "1S63",
        "text": "Problem with sun exposure behaviour"
    },
    {
        "id": "1S64",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S65",
        "text": "Problem with behaviours related to psychological health or wellbeing"
    },
    {
        "id": "1S66",
        "text": "Problem with health literacy"
    },
    {
        "id": "1S6C",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "1S6B",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "1S6A",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "1S69",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "1S68",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "1S67",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "1S6D",
        "text": "Congenital hearing impairment"
    },
    {
        "id": "1S6E",
        "text": "Otosclerosis"
    },
    {
        "id": "1S6F",
        "text": "Congenital conductive hearing loss"
    },
    {
        "id": "1S6G",
        "text": "Congenital sensorineural hearing loss"
    },
    {
        "id": "1S6H",
        "text": "Congenital mixed conductive and sensorineural hearing loss"
    },
    {
        "id": "1S6I",
        "text": "Otitis media"
    },
    {
        "id": "1S6J",
        "text": "Otalgia or effusion of ear"
    },
    {
        "id": "1S6K",
        "text": "Headache, not elsewhere classified"
    },
    {
        "id": "1S6L",
        "text": "Other primary headache disorder"
    },
    {
        "id": "1S6M",
        "text": "Certain specified mononeuropathies"
    },
    {
        "id": "1S6N",
        "text": "Mononeuropathy"
    },
    {
        "id": "1S6O",
        "text": "Pain disorders"
    },
    {
        "id": "1S6P",
        "text": "Pain"
    },
    {
        "id": "1S6Q",
        "text": "Subarachnoid haemorrhage"
    },
    {
        "id": "1S6R",
        "text": "Seborrhoea"
    },
    {
        "id": "1S6S",
        "text": "Seborrhoeic dermatitis and related conditions"
    },
    {
        "id": "1S6T",
        "text": "Subungual haematoma"
    },
    {
        "id": "1S6U",
        "text": "Chronic arterial occlusive disease"
    },
    {
        "id": "1S6V",
        "text": "Asymptomatic colonization of the skin by virulent or therapy resistant bacteria"
    },
    {
        "id": "1S6W",
        "text": "Certain skin disorders attributable to bacterial infection"
    },
    {
        "id": "1S5I",
        "text": "Hypothermia of newborn"
    },
    {
        "id": "1S6X",
        "text": "Cold urticaria"
    },
    {
        "id": "1S6Y",
        "text": "Agoraphobia"
    },
    {
        "id": "1S6Z",
        "text": "Hoarding disorder"
    },
    {
        "id": "1S70",
        "text": "Olfactory reference disorder"
    },
    {
        "id": "1S71",
        "text": "Generalised anxiety disorder"
    },
    {
        "id": "1S72",
        "text": "Failure to thrive in infant or child"
    },
    {
        "id": "1S75",
        "text": "Feeding problems of newborn"
    },
    {
        "id": "1S74",
        "text": "Failure to thrive in newborn"
    },
    {
        "id": "1S76",
        "text": "Feeding problem of infant"
    },
    {
        "id": "1S73",
        "text": "Avoidant-restrictive food intake disorder"
    },
    {
        "id": "1S78",
        "text": "Avoidant-restrictive food intake disorder"
    },
    {
        "id": "1S79",
        "text": "Other paraphilic disorder involving non-consenting individuals"
    },
    {
        "id": "1S7A",
        "text": "Urticaria, angioedema and other urticarial disorders"
    },
    {
        "id": "1S7B",
        "text": "Viral infections characterised by skin or mucous membrane lesions"
    },
    {
        "id": "1S7C",
        "text": "Viral exanthems"
    },
    {
        "id": "1S7D",
        "text": "Viral exanthem due to unknown or unspecified agent"
    },
    {
        "id": "1S7E",
        "text": "Harmful effects of drugs, medicaments or biological substances, not elsewhere classified"
    },
    {
        "id": "1S7F",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1S7G",
        "text": "Fungal duodenitis"
    },
    {
        "id": "1S7I",
        "text": "Disseminated adiaspiromycosis"
    },
    {
        "id": "1S7J",
        "text": "Disseminated adiaspiromycosis"
    },
    {
        "id": "1S7K",
        "text": "Sex chromosome anomalies"
    },
    {
        "id": "1S7H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S7P",
        "text": "Oppositional defiant disorder with chronic irritability-anger"
    },
    {
        "id": "1S7Q",
        "text": "Oppositional defiant disorder without chronic irritability-anger with limited prosocial emotions"
    },
    {
        "id": "1S7R",
        "text": "Personality disorder"
    },
    {
        "id": "1S7S",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1S7T",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1S7U",
        "text": "Injury or harm arising from a procedure, not elsewhere classified"
    },
    {
        "id": "1S4A",
        "text": "Polymorphic light eruption"
    },
    {
        "id": "1S7V",
        "text": "Gambling disorder"
    },
    {
        "id": "1S7W",
        "text": "Gaming disorder"
    },
    {
        "id": "1P4W",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1S7X",
        "text": "Urticaria, angioedema and other urticarial disorders"
    },
    {
        "id": "1S7Y",
        "text": "Examination or observation for suspected maltreatment"
    },
    {
        "id": "1S7Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S82",
        "text": "Family history of skin disease"
    },
    {
        "id": "1S83",
        "text": "Personal history of skin disease"
    },
    {
        "id": "1S84",
        "text": "Absence of skin condition"
    },
    {
        "id": "1S85",
        "text": "Cutaneous insect bite reactions"
    },
    {
        "id": "1S86",
        "text": "Cutaneous insect bite reactions"
    },
    {
        "id": "1S54",
        "text": "Personal history of malignant neoplasm"
    },
    {
        "id": "1S89",
        "text": "Neonatal viral infections involving the skin"
    },
    {
        "id": "1S8A",
        "text": "Neonatal phototherapy burn"
    },
    {
        "id": "1S8B",
        "text": "Infantile napkin dermatoses"
    },
    {
        "id": "1S8C",
        "text": "Harmful effects of or exposure to noxious substances, Substances chiefly nonmedicinal as to source, Venoms or toxins"
    },
    {
        "id": "1S8D",
        "text": "Food-induced urticaria or angioedema"
    },
    {
        "id": "1S7N",
        "text": "Idiopathic angioedema"
    },
    {
        "id": "1S8H",
        "text": "Elimination disorders"
    },
    {
        "id": "1S8I",
        "text": "Nocturnal and diurnal enuresis"
    },
    {
        "id": "1S8J",
        "text": "Hazardous gambling or betting"
    },
    {
        "id": "1S8K",
        "text": "Hazardous gaming"
    },
    {
        "id": "1S8L",
        "text": "Keratoacanthoma"
    },
    {
        "id": "1S8M",
        "text": "Subcutaneous panniculitis-like T-cell lymphoma"
    },
    {
        "id": "1S8N",
        "text": "Mycosis fungoides"
    },
    {
        "id": "1S8P",
        "text": "Burkitt lymphoma including Burkitt leukaemia"
    },
    {
        "id": "1S8Q",
        "text": "Primary cutaneous aggressive epidermotropic CD8 positive T-cell lymphoma"
    },
    {
        "id": "1S8R",
        "text": "Primary cutaneous aggressive epidermotropic CD8 positive T-cell lymphoma"
    },
    {
        "id": "1S8S",
        "text": "Primary cutaneous CD4+ small or intermediate pleomorphic T-cell lymphoproliferative disorder"
    },
    {
        "id": "1S8T",
        "text": "Mature T-cell or NK-cell lymphomas and lymphoproliferative disorders, primary cutaneous specified types"
    },
    {
        "id": "1S8U",
        "text": "Mature T-cell or NK-cell lymphomas and lymphoproliferative disorders, primary cutaneous specified types"
    },
    {
        "id": "1S8W",
        "text": "Mature T-cell or NK-cell lymphomas and lymphoproliferative disorders, primary cutaneous specified types"
    },
    {
        "id": "1S8X",
        "text": "Primary cutaneous diffuse large B-cell lymphoma, leg type"
    },
    {
        "id": "1S8Z",
        "text": "Mycosis fungoides"
    },
    {
        "id": "1S93",
        "text": "Mycosis fungoides"
    },
    {
        "id": "1S92",
        "text": "Mycosis fungoides"
    },
    {
        "id": "1S8G",
        "text": "Thalassaemias"
    },
    {
        "id": "1S90",
        "text": "Mycosis fungoides"
    },
    {
        "id": "1S91",
        "text": "Mycosis fungoides"
    },
    {
        "id": "1S95",
        "text": "Childhood atopic eczema"
    },
    {
        "id": "1S97",
        "text": "Bowen disease of skin"
    },
    {
        "id": "1S98",
        "text": "Diseases of the nervous system"
    },
    {
        "id": "1S96",
        "text": "Intraepidermal squamous cell carcinoma"
    },
    {
        "id": "1S99",
        "text": "Alcohol-induced psychotic disorder"
    },
    {
        "id": "1S9A",
        "text": "Alcohol-induced mood disorder"
    },
    {
        "id": "1S9B",
        "text": "Episode of harmful use of alcohol"
    },
    {
        "id": "1S9C",
        "text": "Cyclic vomiting syndrome"
    },
    {
        "id": "1S9D",
        "text": "Mild alcohol intoxication"
    },
    {
        "id": "1S9F",
        "text": "Moderate alcohol intoxication"
    },
    {
        "id": "1S9G",
        "text": "Severe alcohol intoxication"
    },
    {
        "id": "1S8E",
        "text": "Gaming disorder"
    },
    {
        "id": "1S9H",
        "text": "Cyclic vomiting syndrome"
    },
    {
        "id": "1S9J",
        "text": "Neonatal cardiac failure"
    },
    {
        "id": "1S9K",
        "text": "Left isomerism"
    },
    {
        "id": "1S9M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S9O",
        "text": "Squamous cell carcinoma in situ of skin of penis"
    },
    {
        "id": "1S9N",
        "text": "Penile warts"
    },
    {
        "id": "1S9P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S9S",
        "text": "Diseases of the immune system"
    },
    {
        "id": "1S9R",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1S8O",
        "text": "S\u00e9zary syndrome"
    },
    {
        "id": "1S9U",
        "text": "Non-pyogenic bacterial infections of the skin"
    },
    {
        "id": "1S9W",
        "text": "Skin complications of BCG immunisation"
    },
    {
        "id": "1S9X",
        "text": "Tetanus"
    },
    {
        "id": "1S9Y",
        "text": "Plague involving skin"
    },
    {
        "id": "1S9Z",
        "text": "Bubonic plague"
    },
    {
        "id": "1T00",
        "text": "Cellulocutaneous plague"
    },
    {
        "id": "1S3Z",
        "text": "Genital warts"
    },
    {
        "id": "1S9L",
        "text": "Genital warts of vulva"
    },
    {
        "id": "1R97",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T02",
        "text": "Picornavirus infections presenting in the skin or mucous membranes"
    },
    {
        "id": "1T03",
        "text": "Xeroderma pigmentosum"
    },
    {
        "id": "1T04",
        "text": "H syndrome"
    },
    {
        "id": "1T06",
        "text": "Cheek-biting"
    },
    {
        "id": "1T07",
        "text": "Body-focused repetitive behaviour disorders"
    },
    {
        "id": "1T08",
        "text": "Nail-biting"
    },
    {
        "id": "1T0A",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "1T0B",
        "text": "Examination or observation for suspected maltreatment"
    },
    {
        "id": "1T0D",
        "text": "Examination or observation for suspected physical maltreatment"
    },
    {
        "id": "1T0E",
        "text": "Examination or observation for suspected sexual maltreatment"
    },
    {
        "id": "1T0F",
        "text": "Examination or observation for suspected psychological maltreatment"
    },
    {
        "id": "1S8F",
        "text": "Gambling disorder"
    },
    {
        "id": "1T0H",
        "text": "Gambling disorder, predominantly online"
    },
    {
        "id": "1T0I",
        "text": "Gambling disorder, predominantly offline"
    },
    {
        "id": "1T0J",
        "text": "Digital gaming disorder"
    },
    {
        "id": "1T0K",
        "text": "Internet gaming disorder"
    },
    {
        "id": "1T0L",
        "text": "Video gaming disorder"
    },
    {
        "id": "1T0M",
        "text": "Gaming disorder"
    },
    {
        "id": "1T0O",
        "text": "Gaming disorder, predominantly online"
    },
    {
        "id": "1T0P",
        "text": "Gaming disorder, predominantly offline"
    },
    {
        "id": "1T0Q",
        "text": "Substances"
    },
    {
        "id": "1T0R",
        "text": "Dementia"
    },
    {
        "id": "1T0S",
        "text": "Strangury disorders (TM1)"
    },
    {
        "id": "1T0T",
        "text": "Cocaine intoxication"
    },
    {
        "id": "1T0U",
        "text": "Dissociative drug intoxication including Ketamine or PCP"
    },
    {
        "id": "1T0V",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "1T0Z",
        "text": "Open fracture"
    },
    {
        "id": "1T0Y",
        "text": "Injury of spinal cord at neck level"
    },
    {
        "id": "1T10",
        "text": "Systemic inflammatory response syndrome of infectious origin"
    },
    {
        "id": "1T11",
        "text": "Factitious disorders"
    },
    {
        "id": "1T13",
        "text": "Factitious disorder imposed on another"
    },
    {
        "id": "1T12",
        "text": "Factitious disorder imposed on self"
    },
    {
        "id": "1T14",
        "text": "Eye, ear, nose and throat system disorders (TM1)"
    },
    {
        "id": "1T16",
        "text": "Adenocarcinoma of pancreas"
    },
    {
        "id": "1T17",
        "text": "Liver diseases due to disorders of mineral metabolism"
    },
    {
        "id": "1T18",
        "text": "Disorders of bilirubin metabolism or excretion"
    },
    {
        "id": "1T15",
        "text": "Hepatic fibrosis or cirrhosis"
    },
    {
        "id": "1T1A",
        "text": "Hepatic cirrhosis"
    },
    {
        "id": "1T1B",
        "text": "Sleep-wake disorders"
    },
    {
        "id": "1T1C",
        "text": "Indigestion disorder (TM1)"
    },
    {
        "id": "1T1D",
        "text": "Indigestion disorder (TM1)"
    },
    {
        "id": "1T1E",
        "text": "Food retention disorder (TM1)"
    },
    {
        "id": "1T1F",
        "text": "Food reverse flow disorder (TM1)"
    },
    {
        "id": "1T1G",
        "text": "Intestinal abscess disorder (TM1)"
    },
    {
        "id": "1T1H",
        "text": "Intestinal toxin disorder (TM1)"
    },
    {
        "id": "1T1I",
        "text": "Fistula disorder (TM1)"
    },
    {
        "id": "1T1J",
        "text": "Cough with dyspnea disorder (TM1)"
    },
    {
        "id": "1T1K",
        "text": "Pleural fluid retention disorder (TM1)"
    },
    {
        "id": "1T1L",
        "text": "Chest bind disorder (TM1)"
    },
    {
        "id": "1T1M",
        "text": "Kidney heat disorder (TM1)"
    },
    {
        "id": "1T1N",
        "text": "Wind edema disorder (TM1)"
    },
    {
        "id": "1T1O",
        "text": "Kidney edema disorder (TM1)"
    },
    {
        "id": "1T1P",
        "text": "Kidney system disorders (TM1)"
    },
    {
        "id": "1T1Q",
        "text": "Joint impediment disorders (TM1)"
    },
    {
        "id": "1T1R",
        "text": "Allergic rhinitis disorder (TM1)"
    },
    {
        "id": "1T1S",
        "text": "Qi, blood and fluid disorders (TM1)"
    },
    {
        "id": "1T1T",
        "text": "Wasting thirst disorder (TM1)"
    },
    {
        "id": "1T1U",
        "text": "Consumptive disorder (TM1)"
    },
    {
        "id": "1T1V",
        "text": "Flowing phlegm disorder (TM1)"
    },
    {
        "id": "1T1W",
        "text": "Childhood and adolescence associated disorders (TM1)"
    },
    {
        "id": "1T1X",
        "text": "Developmental delay disorder (TM1)"
    },
    {
        "id": "1T1Y",
        "text": "Growth fever disorder (TM1)"
    },
    {
        "id": "1T1Z",
        "text": "Childhood and adolescence associated disorders (TM1)"
    },
    {
        "id": "1T21",
        "text": "Acute convulsion disorder (TM1)"
    },
    {
        "id": "1T22",
        "text": "Blood heat pattern (TM1)"
    },
    {
        "id": "1T23",
        "text": "Dry-phlegm pattern (TM1)"
    },
    {
        "id": "1T24",
        "text": "Phlegm-fire harassing the heart system pattern (TM1)"
    },
    {
        "id": "1T25",
        "text": "Essence patterns (TM1)"
    },
    {
        "id": "1T26",
        "text": "Essence deficiency pattern (TM1)"
    },
    {
        "id": "1T27",
        "text": "Liver depression and blood stasis pattern (TM1)"
    },
    {
        "id": "1T28",
        "text": "Liver yang transforming into wind pattern (TM1)"
    },
    {
        "id": "1T29",
        "text": "Liver qi stagnation pattern (TM1)"
    },
    {
        "id": "1T2A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T2B",
        "text": "Wilson disease"
    },
    {
        "id": "1T2D",
        "text": "Amelo-cerebro-hypohidrotic syndrome"
    },
    {
        "id": "1T2E",
        "text": "Nail dystrophy, not otherwise specified"
    },
    {
        "id": "1T2F",
        "text": "Acquired deformities of the nail plate"
    },
    {
        "id": "1T2G",
        "text": "Acquired deformities of the nail plate"
    },
    {
        "id": "1T2H",
        "text": "Nail fragility"
    },
    {
        "id": "1T2I",
        "text": "Infections of the nail or perionychium"
    },
    {
        "id": "1T2J",
        "text": "Onychomycosis"
    },
    {
        "id": "1T2L",
        "text": "Dilated cardiomyopathy due to laminopathy"
    },
    {
        "id": "1T2M",
        "text": "Dilated cardiomyopathy due to mitochondrial myopathy"
    },
    {
        "id": "1T2N",
        "text": "Candidosis of nail or paronychium"
    },
    {
        "id": "1T2O",
        "text": "Candida onychomycosis"
    },
    {
        "id": "1T2P",
        "text": "Palmar fascial fibromatosis"
    },
    {
        "id": "1T2Q",
        "text": "Fibro-osseous pseudotumour of the digit"
    },
    {
        "id": "1T2R",
        "text": "Ear-lobe keloid"
    },
    {
        "id": "1T2S",
        "text": "Ear-lobe keloid"
    },
    {
        "id": "1T2T",
        "text": "Histiocytic-granulomatous disorders of the skin"
    },
    {
        "id": "1T2V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T2U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T2W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T2X",
        "text": "Hypertensive ischaemic leg ulcer"
    },
    {
        "id": "1T2Y",
        "text": "Cellulitis of upper limb"
    },
    {
        "id": "1R99",
        "text": "Bacterial cellulitis, erysipelas or lymphangitis"
    },
    {
        "id": "1R98",
        "text": "Bacterial cellulitis, erysipelas or lymphangitis"
    },
    {
        "id": "1T2Z",
        "text": "Skin disorders localised to the scalp"
    },
    {
        "id": "1T30",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T31",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T33",
        "text": "Benign neoplasms of epidermal appendages"
    },
    {
        "id": "1T32",
        "text": "Benign cutaneous neoplasms with apocrine differentiation"
    },
    {
        "id": "1T34",
        "text": "Benign cutaneous neoplasms with eccrine differentiation"
    },
    {
        "id": "1T35",
        "text": "Complications of cutaneous cosmetic procedures"
    },
    {
        "id": "1S81",
        "text": "Diabetic skin lesions"
    },
    {
        "id": "1T36",
        "text": "Skin disorders associated with Human immunodeficiency virus infection"
    },
    {
        "id": "1T37",
        "text": "Liver heat stirring wind pattern (TM1)"
    },
    {
        "id": "1T38",
        "text": "Liver meridian dampness-heat pattern (TM1)"
    },
    {
        "id": "1T39",
        "text": "Gallbladder qi deficiency pattern (TM1)"
    },
    {
        "id": "1T3A",
        "text": "Gallbladder cold pattern (TM1)"
    },
    {
        "id": "1T3B",
        "text": "Liver fire invading the stomach system pattern (TM1)"
    },
    {
        "id": "1T3D",
        "text": "Severe heat pattern (TM1)"
    },
    {
        "id": "1T3E",
        "text": "Heart meridian obstruction pattern (TM1)"
    },
    {
        "id": "1T3F",
        "text": "Spleen qi deficiency pattern (TM1)"
    },
    {
        "id": "1T3G",
        "text": "Dampness-heat encumbering the spleen system pattern (TM1)"
    },
    {
        "id": "1T3H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T3I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T3J",
        "text": "Dampness in the intestines pattern (TM1)"
    },
    {
        "id": "1T3K",
        "text": "Anxiety damaging the spleen system pattern (TM1)"
    },
    {
        "id": "1T3L",
        "text": "Defense qi deficiency pattern (TM1)"
    },
    {
        "id": "1T3M",
        "text": "Defense qi deficiency pattern (TM1)"
    },
    {
        "id": "1T3N",
        "text": "Uterine deficiency cold pattern (TM1)"
    },
    {
        "id": "1T3O",
        "text": "Middle energizer stage patterns (TM1)"
    },
    {
        "id": "1T3P",
        "text": "Dampness and heat in the middle energizer pattern(TM1)"
    },
    {
        "id": "1T3Q",
        "text": "Uneasiness disorder (TM1)"
    },
    {
        "id": "1T3R",
        "text": "Depression disorder (TM1)"
    },
    {
        "id": "1T3S",
        "text": "Severe vomiting and diarrhoea disorder (TM1)"
    },
    {
        "id": "1T3T",
        "text": "Alternating fever and chills disorder (TM1)"
    },
    {
        "id": "1T3V",
        "text": "Pyromania"
    },
    {
        "id": "1T3W",
        "text": "Kleptomania"
    },
    {
        "id": "1T3X",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1T3Y",
        "text": "Moderate bodily distress disorder"
    },
    {
        "id": "1T3Z",
        "text": "Severe bodily distress disorder"
    },
    {
        "id": "1T09",
        "text": "Body-focused repetitive behaviour disorders"
    },
    {
        "id": "1T40",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "1S5P",
        "text": "Chronic peripheral venous insufficiency of lower extremities"
    },
    {
        "id": "1T42",
        "text": "Lower limb haemosiderosis"
    },
    {
        "id": "1T44",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T45",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T46",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1T48",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "1T49",
        "text": "Malingering"
    },
    {
        "id": "1T4A",
        "text": "Malingering"
    },
    {
        "id": "1T4B",
        "text": "Harmful pattern of use of alcohol"
    },
    {
        "id": "1T4C",
        "text": "Alcohol dependence"
    },
    {
        "id": "1T4D",
        "text": "Dermatoses associated with specific classes of medication"
    },
    {
        "id": "1T4G",
        "text": "Coumarin necrosis"
    },
    {
        "id": "1T4H",
        "text": "Coumarin necrosis"
    },
    {
        "id": "1T4I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T4J",
        "text": "Drug-induced erythromelalgia"
    },
    {
        "id": "1T4K",
        "text": "Abnormal vascular reactivity to cold"
    },
    {
        "id": "1T4M",
        "text": "Haemodialysis-associated pseudoporphyria"
    },
    {
        "id": "1T4N",
        "text": "Pruritus due to diabetes mellitus"
    },
    {
        "id": "1T4O",
        "text": "Pruritus due to diabetes mellitus"
    },
    {
        "id": "1T4V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T4W",
        "text": "Amnestic disorder due to use of alcohol"
    },
    {
        "id": "1T4X",
        "text": "Unsatisfactory outcome from cutaneous cosmetic surgical procedure"
    },
    {
        "id": "1T4Z",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1T50",
        "text": "Disorders due to use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "1T4T",
        "text": "Amnestic disorder"
    },
    {
        "id": "1T51",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T52",
        "text": "Adverse effects of psoralen photochemotherapy"
    },
    {
        "id": "1T53",
        "text": "Adverse effects of psoralen photochemotherapy"
    },
    {
        "id": "1T4R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T4Y",
        "text": "Dementia due to use of alcohol"
    },
    {
        "id": "1T55",
        "text": "Dementia due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1T5B",
        "text": "Acne excoriee"
    },
    {
        "id": "1T5D",
        "text": "Chikungunya virus disease"
    },
    {
        "id": "1T5I",
        "text": "Colloid milium"
    },
    {
        "id": "1T5J",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1T5L",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1T5M",
        "text": "Bowel-associated dermatosis-arthritis syndrome"
    },
    {
        "id": "1T5N",
        "text": "Nonspecific urethritis"
    },
    {
        "id": "1T5O",
        "text": "Predominantly sexually transmitted infections"
    },
    {
        "id": "1T5P",
        "text": "Keloidal surgical scar"
    },
    {
        "id": "1T5Q",
        "text": "Hypertrophic surgical scar"
    },
    {
        "id": "1S94",
        "text": "Surgical wound of skin"
    },
    {
        "id": "1T5S",
        "text": "Disruption of operation wound, not elsewhere classified"
    },
    {
        "id": "1T5U",
        "text": "Erythema nodosum provoked by bacterial infection"
    },
    {
        "id": "1T5W",
        "text": "Chronic primary pelvic pain syndrome"
    },
    {
        "id": "1T5X",
        "text": "Drug eruptions"
    },
    {
        "id": "1T62",
        "text": "Neonatal"
    },
    {
        "id": "1T63",
        "text": "Early Neonatal"
    },
    {
        "id": "1T64",
        "text": "Late Neonatal"
    },
    {
        "id": "1T65",
        "text": "Infancy"
    },
    {
        "id": "1T66",
        "text": "Child under 5"
    },
    {
        "id": "1T67",
        "text": "Child over 5"
    },
    {
        "id": "1T68",
        "text": "Early Adolescence"
    },
    {
        "id": "1T69",
        "text": "Middle Childhood"
    },
    {
        "id": "1T6A",
        "text": "Adolescent"
    },
    {
        "id": "1T6B",
        "text": "Late Adolescence"
    },
    {
        "id": "1T6C",
        "text": "Adult"
    },
    {
        "id": "1T6D",
        "text": "Cholelithiasis"
    },
    {
        "id": "1T6E",
        "text": "Calculus of gallbladder or cystic duct with acute cholecystitis"
    },
    {
        "id": "1T6F",
        "text": "Calculus of gallbladder or cystic duct with other cholecystitis"
    },
    {
        "id": "1T6G",
        "text": "Calculus of gallbladder or cystic duct with cholangitis"
    },
    {
        "id": "1T6H",
        "text": "Calculus of gallbladder or cystic duct without cholecystitis or cholangitis"
    },
    {
        "id": "1T6I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T6J",
        "text": "Provisional diagnosis"
    },
    {
        "id": "1T6K",
        "text": "Spitz naevus"
    },
    {
        "id": "1T6L",
        "text": "Persistent melanocytic naevus"
    },
    {
        "id": "1T6R",
        "text": "Malignant neoplasms of breast"
    },
    {
        "id": "1T6O",
        "text": "Malignant neoplasms of breast"
    },
    {
        "id": "1T6S",
        "text": "Malignant neoplasms of breast"
    },
    {
        "id": "1T6U",
        "text": "Skin disorders localised to the scalp"
    },
    {
        "id": "1T6V",
        "text": "Dementia"
    },
    {
        "id": "1T6X",
        "text": "Polyarteritis nodosa involving the skin"
    },
    {
        "id": "1T6Y",
        "text": "Neutrophilic dermatoses"
    },
    {
        "id": "1T6Z",
        "text": "Common warts"
    },
    {
        "id": "1S4C",
        "text": "Nodular basal cell carcinoma of skin"
    },
    {
        "id": "1T72",
        "text": "Erythromelalgia"
    },
    {
        "id": "1T71",
        "text": "Primary erythromelalgia"
    },
    {
        "id": "1T70",
        "text": "Secondary erythromelalgia"
    },
    {
        "id": "1T73",
        "text": "Guttate psoriasis"
    },
    {
        "id": "1T74",
        "text": "Vitiligo"
    },
    {
        "id": "1T75",
        "text": "Trichoepithelioma of eyelids"
    },
    {
        "id": "1T76",
        "text": "Distal and lateral subungual onychomycosis"
    },
    {
        "id": "1T77",
        "text": "Subacute lichen planus"
    },
    {
        "id": "1T6W",
        "text": "Hepatic encephalopathy"
    },
    {
        "id": "1T78",
        "text": "Neoplasms"
    },
    {
        "id": "1T79",
        "text": "Neoplasms"
    },
    {
        "id": "1T7A",
        "text": "Histopathology"
    },
    {
        "id": "1T5C",
        "text": "Carcinoma in situ of anal margin or perianal skin"
    },
    {
        "id": "1T7B",
        "text": "Exercise-induced anaphylaxis"
    },
    {
        "id": "1T7C",
        "text": "Food-dependent exercise-induced anaphylaxis"
    },
    {
        "id": "1T7D",
        "text": "Cholinergic urticaria"
    },
    {
        "id": "1T7F",
        "text": "Collagenoma"
    },
    {
        "id": "1T7G",
        "text": "Familial cutaneous collagenoma"
    },
    {
        "id": "1T7H",
        "text": "Plantar cerebriform collagenoma"
    },
    {
        "id": "1T7E",
        "text": "Shagreen patch"
    },
    {
        "id": "1T7I",
        "text": "Porokeratoses"
    },
    {
        "id": "1T7J",
        "text": "Localised porokeratosis"
    },
    {
        "id": "1T7K",
        "text": "Porokeratoses"
    },
    {
        "id": "1T7M",
        "text": "Benign dermal lymphocytic or lymphoplasmacytic infiltrations or proliferations"
    },
    {
        "id": "1T7N",
        "text": "Benign dermal lymphocytic or lymphoplasmacytic infiltrations or proliferations"
    },
    {
        "id": "1T7O",
        "text": "Lobular capillary haemangioma"
    },
    {
        "id": "1T7P",
        "text": "Lobular capillary haemangioma"
    },
    {
        "id": "1T7Q",
        "text": "Borrelial lymphocytoma cutis"
    },
    {
        "id": "1T7R",
        "text": "Lymphocytoma cutis"
    },
    {
        "id": "1T61",
        "text": "Cutaneous pseudolymphoma"
    },
    {
        "id": "1T7S",
        "text": "Cutaneous pseudolymphoma"
    },
    {
        "id": "1T7T",
        "text": "Benign vascular neoplasms"
    },
    {
        "id": "1S03",
        "text": "Overlap or undifferentiated nonorgan specific systemic autoimmune disease"
    },
    {
        "id": "1T7U",
        "text": "Lichenoid dermatoses"
    },
    {
        "id": "1T7X",
        "text": "Spider telangiectasis"
    },
    {
        "id": "1T7Y",
        "text": "Atypical melanocytic naevus"
    },
    {
        "id": "1T7Z",
        "text": "Malassezia folliculitis"
    },
    {
        "id": "1S8Y",
        "text": "Mycosis fungoides"
    },
    {
        "id": "1T80",
        "text": "Chronic deep bacterial folliculitis"
    },
    {
        "id": "1T82",
        "text": "Special forms of acne"
    },
    {
        "id": "1S59",
        "text": "Miscellaneous non-specific skin-related symptoms and signs"
    },
    {
        "id": "1T84",
        "text": "Follow-up examination after treatment for malignant neoplasms"
    },
    {
        "id": "1T85",
        "text": "Special screening examination for neoplasms"
    },
    {
        "id": "1T86",
        "text": "Mild plaque psoriasis"
    },
    {
        "id": "1T87",
        "text": "Moderate plaque psoriasis"
    },
    {
        "id": "1T89",
        "text": "Severe plaque psoriasis"
    },
    {
        "id": "1S5A",
        "text": "Discoid lupus erythematosus"
    },
    {
        "id": "1T8A",
        "text": "Infantile papular acrodermatitis"
    },
    {
        "id": "1T8B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T8C",
        "text": "Alcohol dependence, current use, continuous"
    },
    {
        "id": "1T8D",
        "text": "Alcohol dependence, current use, episodic"
    },
    {
        "id": "1T8E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T8F",
        "text": "Incorrect administration of drug or medicament, as mode of injury"
    },
    {
        "id": "1T8G",
        "text": "Incorrect administration of drug or medicament, as mode of injury"
    },
    {
        "id": "1T8H",
        "text": "Incorrect administration of drug or medicament, as mode of injury"
    },
    {
        "id": "1T8I",
        "text": "Incorrect administration of drug or medicament, as mode of injury"
    },
    {
        "id": "1T8J",
        "text": "Incorrect administration of drug or medicament, as mode of injury"
    },
    {
        "id": "1T8K",
        "text": "Delayed treatment"
    },
    {
        "id": "1T8L",
        "text": "Acquired hypothyroidism"
    },
    {
        "id": "1T8M",
        "text": "Isolated TSH deficiency"
    },
    {
        "id": "1T8N",
        "text": "Thyrotropin-releasing hormone deficiency"
    },
    {
        "id": "1T8O",
        "text": "Resistance to thyrotropin-releasing hormone syndrome"
    },
    {
        "id": "1T8P",
        "text": "Hypothyroidism due to deficient transcription factors involved in pituitary development or function"
    },
    {
        "id": "1T8Q",
        "text": "Congenital hypothyroidism"
    },
    {
        "id": "1T8R",
        "text": "Congenital hypothyroidism due to iodine deficiency"
    },
    {
        "id": "1Q3W",
        "text": "Surgical wound of skin"
    },
    {
        "id": "1T8T",
        "text": "Miscellaneous complications of cutaneous surgical procedures"
    },
    {
        "id": "1T8U",
        "text": "Surgical wound of skin"
    },
    {
        "id": "1T8V",
        "text": "Staphylococcal toxin-mediated recalcitrant erythema and desquamation"
    },
    {
        "id": "1T8Y",
        "text": "Melioidosis"
    },
    {
        "id": "1T8W",
        "text": "Acute or fulminant melioidosis"
    },
    {
        "id": "1T8Z",
        "text": "Subacute or chronic melioidosis"
    },
    {
        "id": "1T90",
        "text": "Cellulitis of toe"
    },
    {
        "id": "1T91",
        "text": "Cellulitis of finger"
    },
    {
        "id": "1T92",
        "text": "Cellulitis of upper limb"
    },
    {
        "id": "1T93",
        "text": "Cellulitis of foot"
    },
    {
        "id": "1T94",
        "text": "Genital warts"
    },
    {
        "id": "1T95",
        "text": "Lower limb atherosclerosis with rest pain"
    },
    {
        "id": "1T96",
        "text": "Lower limb atherosclerosis with intermittent claudication"
    },
    {
        "id": "1T97",
        "text": "Lower limb atherosclerosis with rest pain"
    },
    {
        "id": "1T98",
        "text": "Lower limb atherosclerosis with intermittent claudication"
    },
    {
        "id": "1T99",
        "text": "Injuries to the head"
    },
    {
        "id": "1T9A",
        "text": "Melioidosis"
    },
    {
        "id": "1T9B",
        "text": "Fetus or newborn affected by maternal anaemia"
    },
    {
        "id": "1T9C",
        "text": "Condition of the fetus and newborn reported in the context of the mother"
    },
    {
        "id": "1T9E",
        "text": "Acute bronchiolitis due to respiratory syncytial virus"
    },
    {
        "id": "1T9F",
        "text": "Diabetic foot ulcer"
    },
    {
        "id": "1T9D",
        "text": "Other secondary polyneuropathy"
    },
    {
        "id": "1T9I",
        "text": "Early cutaneous Lyme borreliosis"
    },
    {
        "id": "1T9J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1T9H",
        "text": "Cholesterol atheroembolism"
    },
    {
        "id": "1T9L",
        "text": "Cholesterol atheroembolism"
    },
    {
        "id": "1T9N",
        "text": "Necrotising fasciitis"
    },
    {
        "id": "1T9O",
        "text": "Chronic arterial occlusive disease classified by functional impact"
    },
    {
        "id": "1T9P",
        "text": "Lower limb atherosclerosis"
    },
    {
        "id": "1T9Q",
        "text": "Onchocerciasis of the skin"
    },
    {
        "id": "1T9R",
        "text": "Erythema multiforme with oral ulceration"
    },
    {
        "id": "1T9V",
        "text": "Syndromes with skin or mucosal anomalies as a major feature"
    },
    {
        "id": "1T9W",
        "text": "Supplementary section for functioning assessment"
    },
    {
        "id": "1T05",
        "text": "Ascher syndrome"
    },
    {
        "id": "1T9T",
        "text": "Genetic syndromes affecting nails"
    },
    {
        "id": "1T9U",
        "text": "Syndromic genetically-determined hypermelanosis or lentiginosis"
    },
    {
        "id": "1T9S",
        "text": "Genetic syndromes with abnormalities of the hair shaft"
    },
    {
        "id": "1T9X",
        "text": "Certain genetically-determined metabolic disorders with skin involvement"
    },
    {
        "id": "1T9Y",
        "text": "Certain genetically-determined metabolic disorders with skin involvement"
    },
    {
        "id": "1T9Z",
        "text": "Genetic disorders of the immune system affecting the skin"
    },
    {
        "id": "1U02",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1U00",
        "text": "Self-inflicted hair-damaging disorder"
    },
    {
        "id": "1U03",
        "text": "Scarring alopecia"
    },
    {
        "id": "1U05",
        "text": "Apocrine sweat disorders"
    },
    {
        "id": "1R9D",
        "text": "Nail psoriasis"
    },
    {
        "id": "1U08",
        "text": "Eczematous nail dystrophy"
    },
    {
        "id": "1U09",
        "text": "Papular xanthoma"
    },
    {
        "id": "1S0Y",
        "text": "Intraepidermal squamous cell carcinoma"
    },
    {
        "id": "1T43",
        "text": "Drug-related adverse cutaneous reactions to epidermal growth factor receptor inhibitor therapy"
    },
    {
        "id": "1U07",
        "text": "Nail disorder associated with specified dermatosis"
    },
    {
        "id": "1U0A",
        "text": "Drug-induced oral ulcer"
    },
    {
        "id": "1U0B",
        "text": "Balanoposthitis"
    },
    {
        "id": "1U0C",
        "text": "Infections of the anus or perianal skin"
    },
    {
        "id": "1U0D",
        "text": "Dermatoses precipitated by drug therapy"
    },
    {
        "id": "1U0E",
        "text": "Drug-induced oral conditions"
    },
    {
        "id": "1U0F",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1U0G",
        "text": "Personal history of sexual abuse"
    },
    {
        "id": "1U0I",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1U0K",
        "text": "Capillaritis"
    },
    {
        "id": "1U0L",
        "text": "Acquired ichthyosis"
    },
    {
        "id": "1U0P",
        "text": "Balanoposthitis"
    },
    {
        "id": "1U0M",
        "text": "Schizoaffective disorder"
    },
    {
        "id": "1U0J",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1U0Q",
        "text": "Superficial injury of unspecified body region"
    },
    {
        "id": "1U0S",
        "text": "Opioid-induced mood disorder"
    },
    {
        "id": "1U0U",
        "text": "Synthetic cathinone-induced psychotic disorder"
    },
    {
        "id": "1U0V",
        "text": "Synthetic cathinone-induced mood disorder"
    },
    {
        "id": "1U0Y",
        "text": "Volatile inhalant-induced delirium"
    },
    {
        "id": "1U0Z",
        "text": "MDMA or related drug-induced mood disorder, including MDA"
    },
    {
        "id": "1U11",
        "text": "Disorders due to use of cannabis"
    },
    {
        "id": "1U12",
        "text": "Disorders due to use of synthetic cannabinoids"
    },
    {
        "id": "1U14",
        "text": "Disorders due to use of cocaine"
    },
    {
        "id": "1U13",
        "text": "Disorders due to use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1U15",
        "text": "Synthetic cathinone-induced obsessive-compulsive or related syndrome"
    },
    {
        "id": "1U16",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1U17",
        "text": "Disorders due to use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "1U18",
        "text": "Opioid-induced psychotic disorder"
    },
    {
        "id": "1U0N",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1U1A",
        "text": "Alcohol-induced mood disorder with mixed depressive and manic symptoms"
    },
    {
        "id": "1U1B",
        "text": "Opioid-induced mood disorder, with mixed depressive and manic symptoms"
    },
    {
        "id": "1U1C",
        "text": "Synthetic cathinone-induced mood disorder with mixed depressive and manic symptoms"
    },
    {
        "id": "1U1D",
        "text": "Hallucinogen-induced mood disorder, with mixed depressive and manic symptoms"
    },
    {
        "id": "1U1E",
        "text": "Hallucinogen-induced mood disorder"
    },
    {
        "id": "1U0X",
        "text": "Volatile inhalant-induced mood disorder"
    },
    {
        "id": "1U1F",
        "text": "Volatile inhalant-induced mood disorder, with mixed depressive and manic symptoms"
    },
    {
        "id": "1U10",
        "text": "Dissociative drug-induced mood disorder including Ketamine or PCP"
    },
    {
        "id": "1U19",
        "text": "Mood disorders"
    },
    {
        "id": "1U1G",
        "text": "Anxiety or fear-related disorders"
    },
    {
        "id": "1U1I",
        "text": "Impulse control disorders"
    },
    {
        "id": "1T5V",
        "text": "Diseases of the nervous system"
    },
    {
        "id": "1U0O",
        "text": "Catatonia"
    },
    {
        "id": "1U1N",
        "text": "Dissociative neurological symptom disorder, with paresis or weakness"
    },
    {
        "id": "1U1R",
        "text": "Dissociative neurological symptom disorder, with gait disturbance"
    },
    {
        "id": "1U1J",
        "text": "Dissociative disorders"
    },
    {
        "id": "1U1K",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1U1L",
        "text": "Dissociative neurological symptom disorder, with non-epileptic seizures"
    },
    {
        "id": "1U1M",
        "text": "Dissociative neurological symptom disorder, with paresis or weakness"
    },
    {
        "id": "1U1O",
        "text": "Dissociative neurological symptom disorder, with other sensory disturbance"
    },
    {
        "id": "1U1P",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1U1Q",
        "text": "Dissociative neurological symptom disorder, with gait disturbance"
    },
    {
        "id": "1U1S",
        "text": "Dissociative neurological symptom disorder, with cognitive symptoms"
    },
    {
        "id": "1U1T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1U1U",
        "text": "Dissociative neurological symptom disorder, with visual disturbance"
    },
    {
        "id": "1U1V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1U1W",
        "text": "Dissociative neurological symptom disorder, with auditory disturbance"
    },
    {
        "id": "1U1X",
        "text": "Dissociative neurological symptom disorder, with vertigo or dizziness"
    },
    {
        "id": "1U1Y",
        "text": "Dissociative neurological symptom disorder, with speech disturbance"
    },
    {
        "id": "1U1Z",
        "text": "Dissociative amnesia"
    },
    {
        "id": "1U20",
        "text": "Depersonalization-derealization disorder"
    },
    {
        "id": "1U22",
        "text": "Trance disorder"
    },
    {
        "id": "1U23",
        "text": "Possession trance disorder"
    },
    {
        "id": "1U24",
        "text": "Dissociative identity disorder"
    },
    {
        "id": "1U25",
        "text": "Partial dissociative identity disorder"
    },
    {
        "id": "1U26",
        "text": "Partial dissociative identity disorder"
    },
    {
        "id": "1U27",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1U29",
        "text": "Secondary neurodevelopmental syndrome"
    },
    {
        "id": "1U2A",
        "text": "Pica"
    },
    {
        "id": "1U2B",
        "text": "Prominent personality traits or patterns"
    },
    {
        "id": "1U2C",
        "text": "Paraphilic disorders"
    },
    {
        "id": "1U2D",
        "text": "Artefactual skin disorder"
    },
    {
        "id": "1U2F",
        "text": "Delirium induced by other specified psychoactive substance including medications"
    },
    {
        "id": "1U2G",
        "text": "Delirium induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1U2I",
        "text": "Impulse control disorders"
    },
    {
        "id": "1U2J",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "1U2K",
        "text": "Amnestic disorder due to diseases classified elsewhere"
    },
    {
        "id": "1U2E",
        "text": "Delirium due to disease classified elsewhere"
    },
    {
        "id": "1U0R",
        "text": "Amnestic disorder"
    },
    {
        "id": "1U2M",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "1U2N",
        "text": "Amnestic disorder due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1T4U",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "1U2O",
        "text": "Delirium due to psychoactive substances including medications"
    },
    {
        "id": "1U2P",
        "text": "Dementia due to psychoactive substances including medications"
    },
    {
        "id": "1U2H",
        "text": "Delirium induced by other specified psychoactive substance including medications"
    },
    {
        "id": "1U2L",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "1T0N",
        "text": "Gambling disorder"
    },
    {
        "id": "1T57",
        "text": "Dementia due to use of volatile inhalants"
    },
    {
        "id": "1T3U",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1U2Q",
        "text": "Dementia due to psychoactive substances including medications"
    },
    {
        "id": "1U2U",
        "text": "Disorders due to substance use or addictive behaviours"
    },
    {
        "id": "1U2V",
        "text": "Cheyletiellosis"
    },
    {
        "id": "1U2W",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1U2X",
        "text": "Self-inflicted hair-damaging disorder"
    },
    {
        "id": "1U2Z",
        "text": "Repetitive self-biting behaviour"
    },
    {
        "id": "1U2Y",
        "text": "Repetitive scratching behaviour"
    },
    {
        "id": "1U31",
        "text": "Nail-biting"
    },
    {
        "id": "1U30",
        "text": "Cheek-biting"
    },
    {
        "id": "1U32",
        "text": "Schizoaffective disorder"
    },
    {
        "id": "1U33",
        "text": "Dementia due to diseases classified elsewhere"
    },
    {
        "id": "1U34",
        "text": "Single episode depressive disorder"
    },
    {
        "id": "1U35",
        "text": "Recurrent depressive disorder"
    },
    {
        "id": "1U36",
        "text": "Bipolar type I disorder"
    },
    {
        "id": "1U37",
        "text": "Bipolar type II disorder"
    },
    {
        "id": "1U38",
        "text": "Bipolar type I disorder"
    },
    {
        "id": "1U39",
        "text": "Bipolar type II disorder"
    },
    {
        "id": "1U28",
        "text": "Secondary neurodevelopmental syndrome"
    },
    {
        "id": "1U3A",
        "text": "Disorders due to use of synthetic cathinones"
    },
    {
        "id": "1U3B",
        "text": "Bipolar type II disorder"
    },
    {
        "id": "1U3C",
        "text": "Bipolar type I disorder, currently in partial remission, most recent episode manic or hypomanic"
    },
    {
        "id": "1T5E",
        "text": "Prominent personality traits or patterns"
    },
    {
        "id": "1U3D",
        "text": "Mood disorders"
    },
    {
        "id": "1U3F",
        "text": "Advanced Alzheimer disease"
    },
    {
        "id": "1U3M",
        "text": "Episode of harmful use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1U3T",
        "text": "Episode of harmful use of volatile inhalants"
    },
    {
        "id": "1U3U",
        "text": "Episode of harmful use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1U3V",
        "text": "Episode of harmful use of dissociative drugs including ketamine or PCP"
    },
    {
        "id": "1U3X",
        "text": "Episode of harmful use of non-psychoactive substances"
    },
    {
        "id": "1U3Z",
        "text": "Episode of harmful use of other specified psychoactive substance"
    },
    {
        "id": "1U3W",
        "text": "Episode of harmful use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "1U3Y",
        "text": "Episode of harmful use of other specified psychoactive substance"
    },
    {
        "id": "1U40",
        "text": "Personality difficulty"
    },
    {
        "id": "1U41",
        "text": "Anankastia in personality disorder or personality difficulty"
    },
    {
        "id": "1U42",
        "text": "Detachment in personality disorder or personality difficulty"
    },
    {
        "id": "1U43",
        "text": "Disinhibition in personality disorder or personality difficulty"
    },
    {
        "id": "1U44",
        "text": "Dissociality in personality disorder or personality difficulty"
    },
    {
        "id": "1U45",
        "text": "Negative affectivity in personality disorder or personality difficulty"
    },
    {
        "id": "1U46",
        "text": "Disorders due to substance use"
    },
    {
        "id": "1U48",
        "text": "Hepatic encephalopathy"
    },
    {
        "id": "1U49",
        "text": "Certain specified diseases of liver"
    },
    {
        "id": "1U4A",
        "text": "Dementia"
    },
    {
        "id": "1U4B",
        "text": "Dementia due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1U4C",
        "text": "Dementia due to diseases classified elsewhere"
    },
    {
        "id": "1U4D",
        "text": "Catatonia associated with another mental disorder"
    },
    {
        "id": "1U4E",
        "text": "Pachydermodactyly"
    },
    {
        "id": "1U4F",
        "text": "Atopic eczema"
    },
    {
        "id": "1U4J",
        "text": "Infantile atopic eczema"
    },
    {
        "id": "1U4I",
        "text": "Childhood atopic eczema"
    },
    {
        "id": "1U4H",
        "text": "Adult atopic eczema"
    },
    {
        "id": "1U4K",
        "text": "Postviral fatigue syndrome"
    },
    {
        "id": "1U4L",
        "text": "Dementia due to use of alcohol"
    },
    {
        "id": "1U4M",
        "text": "Dementia due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1U4N",
        "text": "Dementia due to use of volatile inhalants"
    },
    {
        "id": "1U4O",
        "text": "Dementia due to exposure to heavy metals and other toxins"
    },
    {
        "id": "1U4Q",
        "text": "Dementia due to psychoactive substances including medications"
    },
    {
        "id": "1U1H",
        "text": "Obsessive-compulsive or related disorders"
    },
    {
        "id": "1U4S",
        "text": "Cocaine-induced obsessive-compulsive or related disorder"
    },
    {
        "id": "1U4T",
        "text": "Cocaine-induced impulse control disorder"
    },
    {
        "id": "1U4U",
        "text": "Disorders due to use of cocaine"
    },
    {
        "id": "1U4V",
        "text": "Stimulant-induced obsessive-compulsive or related disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1U4W",
        "text": "Stimulant-induced impulse control disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1U3E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1U4X",
        "text": "Obsessive-compulsive or related disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1U4Y",
        "text": "Impulse control disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1U4Z",
        "text": "Obsessive-compulsive or related disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1U50",
        "text": "Impulse control disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1U51",
        "text": "Fibroepithelial polyp of skin"
    },
    {
        "id": "1U52",
        "text": "Epidermoid cyst"
    },
    {
        "id": "1U53",
        "text": "Dermoid cyst"
    },
    {
        "id": "1U55",
        "text": "Syndromes with microcephaly as a major feature"
    },
    {
        "id": "1U56",
        "text": "Syndromes with brain calcifications as a major feature"
    },
    {
        "id": "1U57",
        "text": "Syndromes with microphthalmia as a major feature"
    },
    {
        "id": "1U58",
        "text": "Syndromes with micromelia"
    },
    {
        "id": "1U59",
        "text": "Osteopetrosis"
    },
    {
        "id": "1U5A",
        "text": "Multiple osteochondromas"
    },
    {
        "id": "1U5C",
        "text": "Acromesomelic dysplasias"
    },
    {
        "id": "1U5Q",
        "text": "Obstructive sleep apnoea"
    },
    {
        "id": "1U5R",
        "text": "Central sleep apnoeas"
    },
    {
        "id": "1U5S",
        "text": "Sleep-related hypoventilation or hypoxemia disorders"
    },
    {
        "id": "1U5T",
        "text": "Parasomnia disorders"
    },
    {
        "id": "1U5W",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "1U54",
        "text": "Multiple developmental anomalies or syndromes"
    },
    {
        "id": "1U5B",
        "text": "Acromelic dysplasias"
    },
    {
        "id": "1U5Y",
        "text": "Craniofacial dysostoses"
    },
    {
        "id": "1U5Z",
        "text": "Complete monosomy of autosome"
    },
    {
        "id": "1U60",
        "text": "Deletions of the short arm of chromosome 11"
    },
    {
        "id": "1U61",
        "text": "Incontinentia pigmenti"
    },
    {
        "id": "1U62",
        "text": "Fraser syndrome"
    },
    {
        "id": "1U64",
        "text": "Chronic primary pain"
    },
    {
        "id": "1U6J",
        "text": "Diagnosis certainty"
    },
    {
        "id": "1U6K",
        "text": "Dementia due to pellagra"
    },
    {
        "id": "1U4R",
        "text": "Substance-induced impulse control disorders"
    },
    {
        "id": "1U3I",
        "text": "Episode of harmful use of alcohol"
    },
    {
        "id": "1U3J",
        "text": "Episode of harmful use of cannabis"
    },
    {
        "id": "1U3K",
        "text": "Episode of harmful use of synthetic cannabinoids"
    },
    {
        "id": "1U3L",
        "text": "Episode of harmful use of opioids"
    },
    {
        "id": "1U6L",
        "text": "Episode of harmful use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1U3N",
        "text": "Episode of harmful use of cocaine"
    },
    {
        "id": "1U3O",
        "text": "Episode of harmful use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1U6M",
        "text": "Episode of harmful use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1U3P",
        "text": "Episode of harmful use of synthetic cathinones"
    },
    {
        "id": "1U3Q",
        "text": "Episode of harmful use of caffeine"
    },
    {
        "id": "1U3R",
        "text": "Episode of harmful use of hallucinogens"
    },
    {
        "id": "1U3S",
        "text": "Episode of harmful use of nicotine"
    },
    {
        "id": "1U6N",
        "text": "Episode of harmful use of volatile inhalants"
    },
    {
        "id": "1U6O",
        "text": "Episode of harmful use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1U6P",
        "text": "Episode of harmful use of dissociative drugs including ketamine or PCP"
    },
    {
        "id": "1U6Q",
        "text": "Episode of harmful use of other specified psychoactive substance"
    },
    {
        "id": "1U6R",
        "text": "Episode of harmful use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "1U6S",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1U6T",
        "text": "Structural developmental anomaly of heart or great vessels"
    },
    {
        "id": "1U6U",
        "text": "Congenital anomaly of position or spatial relationships of thoraco-abdominal organs"
    },
    {
        "id": "1U6V",
        "text": "Dextrocardia"
    },
    {
        "id": "1U6W",
        "text": "Congenital anomaly of position or spatial relationships of thoraco-abdominal organs"
    },
    {
        "id": "1U6X",
        "text": "Right hand pattern ventricular topology"
    },
    {
        "id": "1U6Y",
        "text": "Usual atrial arrangement"
    },
    {
        "id": "1U6Z",
        "text": "Aortic root posterior and rightward to pulmonary root"
    },
    {
        "id": "1U70",
        "text": "Aortic root posterior and leftward to pulmonary root"
    },
    {
        "id": "1U71",
        "text": "Spiralling course of great arteries"
    },
    {
        "id": "1U72",
        "text": "Congenitally corrected transposition of great arteries"
    },
    {
        "id": "1U73",
        "text": "Transposition of the great arteries with concordant atrioventricular connections and intact ventricular septum"
    },
    {
        "id": "1U74",
        "text": "Transposition of the great arteries with concordant atrioventricular connections and ventricular septal defect and left ventricular outflow tract obstruction"
    },
    {
        "id": "1U76",
        "text": "Double outlet right ventricle with doubly committed ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "1U77",
        "text": "Congenital anomaly of mediastinal vein"
    },
    {
        "id": "1U78",
        "text": "Congenital anomaly of mediastinal systemic vein"
    },
    {
        "id": "1U7A",
        "text": "Left superior caval vein"
    },
    {
        "id": "1U7B",
        "text": "Left superior caval vein"
    },
    {
        "id": "1U7C",
        "text": "Interrupted inferior caval vein with absent suprarenal segment and azygos continuation"
    },
    {
        "id": "1U7D",
        "text": "Coronary sinus orifice atresia or stenosis"
    },
    {
        "id": "1U7E",
        "text": "Congenital anomaly of pulmonary vein"
    },
    {
        "id": "1U7F",
        "text": "Anomalous pulmonary venous connection"
    },
    {
        "id": "1U7G",
        "text": "Total anomalous pulmonary venous connection"
    },
    {
        "id": "1U7H",
        "text": "Total anomalous pulmonary venous connection of the cardiac type"
    },
    {
        "id": "1U7I",
        "text": "Partial anomalous pulmonary venous connection"
    },
    {
        "id": "1U7J",
        "text": "Partial anomalous pulmonary venous connection of Scimitar type"
    },
    {
        "id": "1U7K",
        "text": "Scimitar syndrome"
    },
    {
        "id": "1U7L",
        "text": "Congenital pulmonary venous stenosis or hypoplasia"
    },
    {
        "id": "1U7M",
        "text": "Congenital anomaly of an atrium or atrial septum"
    },
    {
        "id": "1U7N",
        "text": "Congenital anomaly of atrial septum"
    },
    {
        "id": "1U7O",
        "text": "Restrictive interatrial communication or intact atrial septum when an interatrial shunt is physiologically necessary"
    },
    {
        "id": "1U7P",
        "text": "Aneurysm of the atrial septum"
    },
    {
        "id": "1U7Q",
        "text": "Interatrial communication"
    },
    {
        "id": "1U7R",
        "text": "Patent oval foramen"
    },
    {
        "id": "1U7S",
        "text": "Common atrium with separate atrioventricular junctions"
    },
    {
        "id": "1U7T",
        "text": "Congenital anomaly of an atrioventricular valve or atrioventricular septum"
    },
    {
        "id": "1U7U",
        "text": "Congenital tricuspid regurgitation"
    },
    {
        "id": "1U7V",
        "text": "Congenital tricuspid valvar stenosis"
    },
    {
        "id": "1U7W",
        "text": "Congenital mitral regurgitation"
    },
    {
        "id": "1U7X",
        "text": "Congenital mitral valvar stenosis"
    },
    {
        "id": "1U7Y",
        "text": "True cleft of anterior mitral leaflet"
    },
    {
        "id": "1U80",
        "text": "Atrioventricular septal defect with balanced ventricles"
    },
    {
        "id": "1U81",
        "text": "Atrioventricular septal defect with ventricular imbalance"
    },
    {
        "id": "1U82",
        "text": "Atrioventricular septal defect with ventricular imbalance with dominant right ventricle and hypoplastic left ventricle"
    },
    {
        "id": "1U83",
        "text": "Atrioventricular septal defect with ventricular imbalance with dominant left ventricle and hypoplastic right ventricle"
    },
    {
        "id": "1U84",
        "text": "Atrioventricular septal defect with communication at the atrial level only"
    },
    {
        "id": "1U85",
        "text": "Atrioventricular septal defect with communication at the ventricular level only"
    },
    {
        "id": "1U86",
        "text": "Atrioventricular septal defect and tetralogy of Fallot"
    },
    {
        "id": "1U87",
        "text": "Common atrioventricular junction without an atrioventricular septal defect"
    },
    {
        "id": "1U88",
        "text": "Congenital anomaly of a ventricle or the ventricular septum"
    },
    {
        "id": "1U89",
        "text": "Tetralogy of Fallot with pulmonary atresia and systemic-to-pulmonary collateral artery"
    },
    {
        "id": "1U8A",
        "text": "Left ventricular hypoplasia"
    },
    {
        "id": "1U8B",
        "text": "Congenital left ventricular aneurysm or diverticulum"
    },
    {
        "id": "1U8C",
        "text": "Congenital left ventricular outflow tract obstruction"
    },
    {
        "id": "1U8D",
        "text": "Left ventricular myocardial sinusoids"
    },
    {
        "id": "1U8E",
        "text": "Congenital anomaly of ventricular septum"
    },
    {
        "id": "1U8F",
        "text": "Congenital anomaly of a ventriculo-arterial valve or adjacent regions"
    },
    {
        "id": "1U8G",
        "text": "Aortoventricular tunnel"
    },
    {
        "id": "1U8H",
        "text": "Congenital anomaly of aorta or its branches"
    },
    {
        "id": "1U8I",
        "text": "Coarctation of aorta"
    },
    {
        "id": "1U8J",
        "text": "Vascular ring"
    },
    {
        "id": "1U8K",
        "text": "Vascular ring"
    },
    {
        "id": "1U8L",
        "text": "Anomalous origin of left pulmonary artery from right pulmonary artery"
    },
    {
        "id": "1U7Z",
        "text": "Parachute malformation of mitral valve"
    },
    {
        "id": "1U8M",
        "text": "Asthmatic pulmonary eosinophilia"
    },
    {
        "id": "1U8N",
        "text": "Endemic non-venereal syphilis"
    },
    {
        "id": "1U8O",
        "text": "Symptom or complaint relating to hair or scalp"
    },
    {
        "id": "1U8P",
        "text": "Symptom or complaint relating to nails"
    },
    {
        "id": "1U65",
        "text": "Chronic primary pain"
    },
    {
        "id": "1U6E",
        "text": "Chronic primary headache or orofacial pain"
    },
    {
        "id": "1U6D",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "1U6C",
        "text": "Chronic primary pain"
    },
    {
        "id": "1U66",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "1U6F",
        "text": "Chronic cancer pain"
    },
    {
        "id": "1U6G",
        "text": "Chronic post cancer treatment pain"
    },
    {
        "id": "1U8R",
        "text": "Malignant neoplasms of skin"
    },
    {
        "id": "1U8T",
        "text": "Other specified psychoactive substance withdrawal"
    },
    {
        "id": "1U8U",
        "text": "Withdrawal due to unknown or unspecified psychoactive substance"
    },
    {
        "id": "1U8S",
        "text": "Occupational relevance"
    },
    {
        "id": "1U8V",
        "text": "Causality"
    },
    {
        "id": "1U8W",
        "text": "Rotator cuff capsule"
    },
    {
        "id": "1U8X",
        "text": "Endometriosis"
    },
    {
        "id": "1U8Y",
        "text": "Non-viral and unspecified infections of the central nervous system"
    },
    {
        "id": "1U8Z",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "1U67",
        "text": "Chronic postsurgical or post traumatic pain"
    },
    {
        "id": "1U90",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1U69",
        "text": "Chronic secondary visceral pain"
    },
    {
        "id": "1U93",
        "text": "Harmful pattern of use of alcohol"
    },
    {
        "id": "1U94",
        "text": "Harmful pattern of use of alcohol, episodic"
    },
    {
        "id": "1U95",
        "text": "Harmful pattern of use of alcohol, continuous"
    },
    {
        "id": "1U96",
        "text": "Harmful pattern of use of cannabis"
    },
    {
        "id": "1U97",
        "text": "Harmful pattern of use of cannabis, episodic"
    },
    {
        "id": "1U98",
        "text": "Harmful pattern of use of cannabis, continuous"
    },
    {
        "id": "1U99",
        "text": "Harmful pattern of use of synthetic cannabinoids"
    },
    {
        "id": "1U9A",
        "text": "Harmful pattern of use of synthetic cannabinoids, episodic"
    },
    {
        "id": "1U9B",
        "text": "Harmful pattern of use of synthetic cannabinoids, continuous"
    },
    {
        "id": "1U9C",
        "text": "Harmful pattern of use of opioids"
    },
    {
        "id": "1U9D",
        "text": "Harmful pattern of use of opioids, episodic"
    },
    {
        "id": "1U9E",
        "text": "Harmful pattern of use of opioids, continuous"
    },
    {
        "id": "1U9G",
        "text": "Harmful pattern of use of cocaine, episodic"
    },
    {
        "id": "1U9H",
        "text": "Harmful pattern of use of cocaine, continuous"
    },
    {
        "id": "1U9I",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1U9J",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone, episodic"
    },
    {
        "id": "1U9K",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone, continuous"
    },
    {
        "id": "1U9L",
        "text": "Harmful pattern of use of synthetic cathinones"
    },
    {
        "id": "1U9M",
        "text": "Harmful pattern of use of synthetic cathinones, episodic"
    },
    {
        "id": "1U9N",
        "text": "Harmful use of synthetic cathinones, continuous"
    },
    {
        "id": "1U9O",
        "text": "Harmful pattern of use of hallucinogens"
    },
    {
        "id": "1U9P",
        "text": "Harmful pattern of use of hallucinogens, episodic"
    },
    {
        "id": "1U9Q",
        "text": "Harmful pattern of use of hallucinogens, continuous"
    },
    {
        "id": "1U9F",
        "text": "Harmful pattern of use of cocaine"
    },
    {
        "id": "1U9U",
        "text": "Harmful pattern of use of volatile inhalants"
    },
    {
        "id": "1U9V",
        "text": "Harmful pattern of use of volatile inhalants, episodic"
    },
    {
        "id": "1U9X",
        "text": "Harmful pattern of use of volatile inhalants, continuous"
    },
    {
        "id": "1U9R",
        "text": "Harmful pattern of use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1U9S",
        "text": "Harmful use of MDMA or related drugs, including MDA, episodic"
    },
    {
        "id": "1U9T",
        "text": "Harmful use of MDMA or related drugs, including MDA, continuous"
    },
    {
        "id": "1U9Y",
        "text": "Harmful pattern of use of dissociative drugs, including ketamine or PCP"
    },
    {
        "id": "1U9Z",
        "text": "Harmful pattern of use of dissociative drugs including ketamine or PCP, episodic"
    },
    {
        "id": "1V00",
        "text": "Harmful pattern of use of dissociative drugs including ketamine or PCP, continuous"
    },
    {
        "id": "1V01",
        "text": "Harmful pattern of use of unknown or unspecified psychoactive substance"
    },
    {
        "id": "1V02",
        "text": "Harmful pattern of use of unknown or unspecified psychoactive substance, episodic"
    },
    {
        "id": "1V03",
        "text": "Harmful pattern of use of unknown or unspecified psychoactive substance, continuous"
    },
    {
        "id": "1V04",
        "text": "Harmful pattern of use of non-psychoactive substances"
    },
    {
        "id": "1V05",
        "text": "Harmful pattern of use of non-psychoactive substances, episodic"
    },
    {
        "id": "1V06",
        "text": "Harmful pattern of use of non-psychoactive substances, continuous"
    },
    {
        "id": "1V07",
        "text": "Peritoneal adhesions"
    },
    {
        "id": "1V08",
        "text": "Disorders due to use of non-psychoactive substances"
    },
    {
        "id": "1V0A",
        "text": "Episode of harmful use of other specified psychoactive substance"
    },
    {
        "id": "1V09",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1V0B",
        "text": "Harmful pattern of use of other specified psychoactive substance"
    },
    {
        "id": "1V0C",
        "text": "Harmful pattern of use of other specified psychoactive substance, episodic"
    },
    {
        "id": "1V0D",
        "text": "Harmful pattern of use of other specified psychoactive substance, continuous"
    },
    {
        "id": "1V0E",
        "text": "Harmful pattern of use of caffeine"
    },
    {
        "id": "1V0F",
        "text": "Harmful pattern of use of caffeine, episodic"
    },
    {
        "id": "1V0G",
        "text": "Harmful pattern of use of caffeine, continuous"
    },
    {
        "id": "1V0I",
        "text": "Episode of harmful use of nicotine"
    },
    {
        "id": "1V0H",
        "text": "Episode of harmful use of caffeine"
    },
    {
        "id": "1V0J",
        "text": "Harmful pattern of use of nicotine"
    },
    {
        "id": "1V0K",
        "text": "Harmful pattern of use of nicotine, episodic"
    },
    {
        "id": "1V0L",
        "text": "Harmful pattern of use of nicotine, continuous"
    },
    {
        "id": "1V0M",
        "text": "Problems associated with harmful or traumatic events"
    },
    {
        "id": "1V0T",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1V0U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V0V",
        "text": "Diabetes mellitus"
    },
    {
        "id": "1U63",
        "text": "Chronic visceral pain from persistent inflammation in the pelvic region"
    },
    {
        "id": "1V15",
        "text": "Non-specific cutaneous vascular signs"
    },
    {
        "id": "1V16",
        "text": "Cherry angioma"
    },
    {
        "id": "1V17",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V18",
        "text": "Venous leg ulcer"
    },
    {
        "id": "1V19",
        "text": "Lower limb varicose veins"
    },
    {
        "id": "1V1A",
        "text": "Melanoma of eye or ocular adnexa"
    },
    {
        "id": "1V1D",
        "text": "Malignant neoplasms, stated or presumed to be primary, of specified sites, except of lymphoid, haematopoietic, central nervous system or related tissues"
    },
    {
        "id": "1V1E",
        "text": "Melanoma of skin"
    },
    {
        "id": "1V1F",
        "text": "Actinic intraepidermal squamous cell carcinoma"
    },
    {
        "id": "1V1K",
        "text": "Episode of harmful use of other specified psychoactive substance"
    },
    {
        "id": "1V1L",
        "text": "Harmful pattern of use of other specified psychoactive substance"
    },
    {
        "id": "1V1M",
        "text": "Harmful pattern of use of other specified psychoactive substance, episodic"
    },
    {
        "id": "1V1N",
        "text": "Harmful pattern of use of other specified psychoactive substance, continuous"
    },
    {
        "id": "1V1O",
        "text": "Harmful pattern of use of multiple specified psychoactive substances"
    },
    {
        "id": "1V1P",
        "text": "Multiple specified psychoactive substances dependence"
    },
    {
        "id": "1V1Q",
        "text": "Multiple specified psychoactive substances withdrawal, uncomplicated"
    },
    {
        "id": "1V1R",
        "text": "Episode of harmful use of multiple specified psychoactive substances"
    },
    {
        "id": "1V1S",
        "text": "Harmful pattern of use of multiple specified psychoactive substances"
    },
    {
        "id": "1V1T",
        "text": "Harmful pattern of use of multiple specified psychoactive substances, episodic"
    },
    {
        "id": "1V1U",
        "text": "Harmful pattern of use of multiple specified psychoactive substances, continuous"
    },
    {
        "id": "1V1V",
        "text": "Alcohol intoxication"
    },
    {
        "id": "1V1W",
        "text": "Cannabis intoxication"
    },
    {
        "id": "1V1X",
        "text": "Synthetic cannabinoid intoxication"
    },
    {
        "id": "1V1Y",
        "text": "Opioid intoxication"
    },
    {
        "id": "1V1Z",
        "text": "Sedative, hypnotic or anxiolytic intoxication"
    },
    {
        "id": "1V20",
        "text": "Cocaine intoxication"
    },
    {
        "id": "1V22",
        "text": "Synthetic cathinone intoxication"
    },
    {
        "id": "1V21",
        "text": "Stimulant intoxication including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1V23",
        "text": "Caffeine intoxication"
    },
    {
        "id": "1V24",
        "text": "Hallucinogen intoxication"
    },
    {
        "id": "1V25",
        "text": "Nicotine intoxication"
    },
    {
        "id": "1V26",
        "text": "Volatile inhalant intoxication"
    },
    {
        "id": "1V27",
        "text": "MDMA or related drug intoxication, including MDA"
    },
    {
        "id": "1V28",
        "text": "Dissociative drug intoxication including Ketamine or PCP"
    },
    {
        "id": "1V29",
        "text": "Other specified psychoactive substance intoxication"
    },
    {
        "id": "1V2A",
        "text": "Intoxication due to multiple specified psychoactive substances"
    },
    {
        "id": "1V2C",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1V2B",
        "text": "Disorders due to use of multiple specified psychoactive substances, including medications"
    },
    {
        "id": "1V2D",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "1V2E",
        "text": "Catatonia"
    },
    {
        "id": "1V2F",
        "text": "Impulse control disorders"
    },
    {
        "id": "1V2H",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "1V2I",
        "text": "Personality disorders and related traits"
    },
    {
        "id": "1V2J",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1V2K",
        "text": "Melanoma arising from melanocytic naevus, primary"
    },
    {
        "id": "1V1B",
        "text": "Melanoma involving overlapping sites of eye or ocular adnexa"
    },
    {
        "id": "1V2L",
        "text": "Melanoma of eye or ocular adnexa"
    },
    {
        "id": "1V2M",
        "text": "Alcohol dependence"
    },
    {
        "id": "1V2N",
        "text": "Personal history of noncompliance with medical treatment or regimen"
    },
    {
        "id": "1V2O",
        "text": "Depressed mood"
    },
    {
        "id": "1V2P",
        "text": "Demoralization"
    },
    {
        "id": "1V2Q",
        "text": "Alcohol dependence"
    },
    {
        "id": "1V2R",
        "text": "Anorexia Nervosa"
    },
    {
        "id": "1V2S",
        "text": "Oppositional defiant disorder with chronic irritability-anger with typical prosocial emotions"
    },
    {
        "id": "1V2T",
        "text": "Oppositional defiant disorder without chronic irritability-anger with typical prosocial emotions"
    },
    {
        "id": "1V2U",
        "text": "Conduct-dissocial disorder, childhood onset with typical prosocial emotions"
    },
    {
        "id": "1V2V",
        "text": "Conduct-dissocial disorder, adolescent onset with typical prosocial emotions"
    },
    {
        "id": "1V2W",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1V1I",
        "text": "Adnexal carcinoma of skin"
    },
    {
        "id": "1V2X",
        "text": "Pruritus due to neurological disorder"
    },
    {
        "id": "1V2Y",
        "text": "Blighted ovum or nonhydatidiform mole"
    },
    {
        "id": "1V2Z",
        "text": "Presence of intestinal bypass or anastomosis"
    },
    {
        "id": "1V31",
        "text": "Synthetic cannabinoid-induced mood disorder"
    },
    {
        "id": "1V30",
        "text": "Cannabis-induced mood disorder"
    },
    {
        "id": "1V33",
        "text": "Somatic delusion"
    },
    {
        "id": "1U92",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "1V13",
        "text": "Chronic visceral pain from mechanical factors"
    },
    {
        "id": "1V0Z",
        "text": "Chronic visceral pain from persistent inflammation"
    },
    {
        "id": "1V11",
        "text": "Chronic visceral pain from vascular mechanisms"
    },
    {
        "id": "1V10",
        "text": "Chronic visceral pain from persistent inflammation in the abdominal region"
    },
    {
        "id": "1V12",
        "text": "Chronic visceral pain from vascular mechanisms in the abdominal region"
    },
    {
        "id": "1V0W",
        "text": "Chronic visceral cancer pain"
    },
    {
        "id": "1V0X",
        "text": "Chronic bone cancer pain"
    },
    {
        "id": "1V0Y",
        "text": "Chronic neuropathic cancer pain"
    },
    {
        "id": "1V36",
        "text": "Chronic painful chemotherapy-induced polyneuropathy"
    },
    {
        "id": "1V37",
        "text": "Delusion of infestation"
    },
    {
        "id": "1V38",
        "text": "Delusion of infestation"
    },
    {
        "id": "1V39",
        "text": "Dysphasia"
    },
    {
        "id": "1S18",
        "text": "Neutrophilic dermatoses"
    },
    {
        "id": "1V3A",
        "text": "Prurigo"
    },
    {
        "id": "1U75",
        "text": "Double outlet right ventricle with subaortic ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "1V3B",
        "text": "Congenital anomaly of position or spatial relationships of thoraco-abdominal organs"
    },
    {
        "id": "1V3C",
        "text": "Congenital anomaly of position or spatial relationships of thoraco-abdominal organs"
    },
    {
        "id": "1V3D",
        "text": "Atrioventricular septal defect with communication at atrial level and restrictive communication at ventricular level"
    },
    {
        "id": "1V3E",
        "text": "Dilated cardiomyopathy"
    },
    {
        "id": "1V3F",
        "text": "Familial-genetic dilated cardiomyopathy"
    },
    {
        "id": "1V3G",
        "text": "Familial isolated dilated cardiomyopathy"
    },
    {
        "id": "1V3H",
        "text": "Familial-genetic dilated cardiomyopathy"
    },
    {
        "id": "1V3I",
        "text": "Dilated cardiomyopathy due to mitochondrial myopathy"
    },
    {
        "id": "1V3K",
        "text": "Histiocytoid cardiomyopathy"
    },
    {
        "id": "1V3L",
        "text": "Dilated cardiomyopathy due to neuromuscular disease"
    },
    {
        "id": "1V3R",
        "text": "Nonfamilial dilated cardiomyopathy"
    },
    {
        "id": "1V3S",
        "text": "Nonfamilial dilated cardiomyopathy"
    },
    {
        "id": "1V3U",
        "text": "Myocarditis"
    },
    {
        "id": "1V3W",
        "text": "Gonococcal myocarditis"
    },
    {
        "id": "1V3X",
        "text": "Fungal myocarditis"
    },
    {
        "id": "1V43",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V45",
        "text": "Sarcoid myocarditis"
    },
    {
        "id": "1V47",
        "text": "Dilated cardiomyopathy due to systemic autoimmune disorder"
    },
    {
        "id": "1V48",
        "text": "Myocarditis"
    },
    {
        "id": "1V4A",
        "text": "Familial-genetic hypertrophic cardiomyopathy"
    },
    {
        "id": "1V4B",
        "text": "Hypertrophic cardiomyopathy due to disorder of fatty acid metabolism"
    },
    {
        "id": "1V4C",
        "text": "Hypertrophic cardiomyopathy due to disorder of fatty acid metabolism"
    },
    {
        "id": "1V4D",
        "text": "Hypertrophic cardiomyopathy due to lysosomal storage disease"
    },
    {
        "id": "1V4F",
        "text": "Anomalous hepatic venous connection to heart"
    },
    {
        "id": "1V4E",
        "text": "Hypertrophic cardiomyopathy due to lysosomal storage disease"
    },
    {
        "id": "1V46",
        "text": "Invasive cardiac aspergillosis"
    },
    {
        "id": "1V42",
        "text": "Viral myocarditis"
    },
    {
        "id": "1V41",
        "text": "Spirochaetal myocarditis"
    },
    {
        "id": "1V40",
        "text": "Rickettsial myocarditis"
    },
    {
        "id": "1V3Z",
        "text": "Protozoal myocarditis"
    },
    {
        "id": "1V3V",
        "text": "Infectious myocarditis"
    },
    {
        "id": "1V3T",
        "text": "Cardiomyopathy"
    },
    {
        "id": "1V4G",
        "text": "Hypertrophic cardiomyopathy due to glycogen storage disease"
    },
    {
        "id": "1V4H",
        "text": "Hypertrophic cardiomyopathy due to glycogen storage disease"
    },
    {
        "id": "1V49",
        "text": "Familial-genetic hypertrophic cardiomyopathy"
    },
    {
        "id": "1V4I",
        "text": "Hypertrophic cardiomyopathy due to mitochondrial disorder"
    },
    {
        "id": "1V4J",
        "text": "Hypertrophic cardiomyopathy due to mitochondrial disorder"
    },
    {
        "id": "1V4K",
        "text": "Hypertrophic cardiomyopathy due to Kearns\u2013Sayre syndrome"
    },
    {
        "id": "1V4L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V4M",
        "text": "Nonfamilial hypertrophic cardiomyopathy"
    },
    {
        "id": "1V4N",
        "text": "Syndrome of infant of a diabetic mother, type 1 or 2, nongestational, insulin dependent"
    },
    {
        "id": "1V4P",
        "text": "Idiopathic restrictive cardiomyopathy"
    },
    {
        "id": "1V4Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V4T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V4U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V4S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V4W",
        "text": "Transthyretin-related familial amyloid cardiomyopathy"
    },
    {
        "id": "1V4V",
        "text": "Familial-genetic restrictive cardiomyopathy"
    },
    {
        "id": "1V4R",
        "text": "Idiopathic restrictive cardiomyopathy"
    },
    {
        "id": "1V4X",
        "text": "Cardiomyopathy"
    },
    {
        "id": "1V4Z",
        "text": "Cardiomyopathy"
    },
    {
        "id": "1V51",
        "text": "Endomyocardial fibrosis"
    },
    {
        "id": "1V52",
        "text": "Hypereosinophilic syndrome"
    },
    {
        "id": "1V50",
        "text": "Restrictive cardiomyopathy due to endomyocardial abnormality"
    },
    {
        "id": "1V53",
        "text": "Noncompaction cardiomyopathy"
    },
    {
        "id": "1V55",
        "text": "Familial isolated arrhythmogenic right ventricular dysplasia"
    },
    {
        "id": "1V56",
        "text": "Naxos disease"
    },
    {
        "id": "1V54",
        "text": "Arrhythmogenic ventricular cardiomyopathy"
    },
    {
        "id": "1V57",
        "text": "Woolly hair \u2013 palmoplantar keratoderma \u2013 dilated cardiomyopathy"
    },
    {
        "id": "1V58",
        "text": "Cirrhotic cardiomyopathy"
    },
    {
        "id": "1V4Y",
        "text": "Idiopathic restrictive cardiomyopathy"
    },
    {
        "id": "1V59",
        "text": "Bodily distress disorder"
    },
    {
        "id": "1V5A",
        "text": "Photosensitivity due to drug"
    },
    {
        "id": "1V4O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5D",
        "text": "Dilated cardiomyopathy due to glycogen branching enzyme deficiency"
    },
    {
        "id": "1V3Q",
        "text": "Familial-genetic dilated cardiomyopathy"
    },
    {
        "id": "1V3P",
        "text": "Dilated cardiomyopathy due to fatty acid oxidation disorder"
    },
    {
        "id": "1V3O",
        "text": "Dilated cardiomyopathy due to fatty acid oxidation disorder"
    },
    {
        "id": "1V3M",
        "text": "Dilated cardiomyopathy due to neuromuscular disease"
    },
    {
        "id": "1V3N",
        "text": "Dilated cardiomyopathy due to laminopathy"
    },
    {
        "id": "1V3Y",
        "text": "Parasitic myocarditis"
    },
    {
        "id": "1V5E",
        "text": "Protozoal myocarditis"
    },
    {
        "id": "1V5G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5H",
        "text": "Dilated cardiomyopathy due to alcoholism"
    },
    {
        "id": "1V5F",
        "text": "Dilated cardiomyopathy due to toxin exposure"
    },
    {
        "id": "1V5I",
        "text": "Tuberculosis of heart"
    },
    {
        "id": "1V5J",
        "text": "Dilated cardiomyopathy due to mitochondrial myopathy"
    },
    {
        "id": "1V5K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V5X",
        "text": "Essential hypertension"
    },
    {
        "id": "1V5Y",
        "text": "Coronary atherosclerosis"
    },
    {
        "id": "1V5Z",
        "text": "Ischaemic cardiomyopathy"
    },
    {
        "id": "1V60",
        "text": "Heart failure"
    },
    {
        "id": "1V62",
        "text": "Diseases of arteries or arterioles"
    },
    {
        "id": "1V61",
        "text": "Diseases of arteries or arterioles"
    },
    {
        "id": "1V63",
        "text": "Acute myocardial infarction"
    },
    {
        "id": "1V64",
        "text": "Disorders due to substance use"
    },
    {
        "id": "1V65",
        "text": "Angina pectoris"
    },
    {
        "id": "1V66",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V67",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V68",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V6A",
        "text": "Counselling related to combined sexual attitudes, sexual behaviour and sexual relationships"
    },
    {
        "id": "1V6B",
        "text": "Visual release hallucinations"
    },
    {
        "id": "1V6F",
        "text": "Unintentional land transport road traffic injury event"
    },
    {
        "id": "1V6E",
        "text": "Unintentional land transport road traffic injury event"
    },
    {
        "id": "1V6C",
        "text": "Unintentional land transport road traffic injury event"
    },
    {
        "id": "1V6G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V6H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V6I",
        "text": "Male erectile dysfunction"
    },
    {
        "id": "1V6J",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1V6K",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium, with psychotic symptoms"
    },
    {
        "id": "1V6L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V6T",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1V6U",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "1V6V",
        "text": "Secondary mood syndrome"
    },
    {
        "id": "1V6W",
        "text": "Gaming disorder"
    },
    {
        "id": "1V6X",
        "text": "Atrophoderma of Pasini and Pierini"
    },
    {
        "id": "1V6Y",
        "text": "Disorders of cutaneous blood and lymphatic vessels"
    },
    {
        "id": "1V6Z",
        "text": "Disorders of eccrine sweat glands or sweating"
    },
    {
        "id": "1V70",
        "text": "Disorders of eccrine sweat glands or sweating"
    },
    {
        "id": "1V71",
        "text": "Miscellaneous non-specific skin-related symptoms and signs"
    },
    {
        "id": "1V72",
        "text": "Skin disorders involving specific cutaneous structures"
    },
    {
        "id": "1V73",
        "text": "Skin disorders involving certain specific body regions"
    },
    {
        "id": "1V74",
        "text": "Dermatoses of male genitalia"
    },
    {
        "id": "1V75",
        "text": "Alcohol withdrawal"
    },
    {
        "id": "1V76",
        "text": "Cannabis withdrawal"
    },
    {
        "id": "1V77",
        "text": "Synthetic cannabinoid withdrawal"
    },
    {
        "id": "1V78",
        "text": "Opioid withdrawal"
    },
    {
        "id": "1V79",
        "text": "Sedative, hypnotic or anxiolytic withdrawal"
    },
    {
        "id": "1V7A",
        "text": "Cocaine withdrawal"
    },
    {
        "id": "1V7B",
        "text": "Stimulant withdrawal including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1V7C",
        "text": "Synthetic cathinone withdrawal"
    },
    {
        "id": "1V7D",
        "text": "Caffeine withdrawal"
    },
    {
        "id": "1V7E",
        "text": "Nicotine withdrawal"
    },
    {
        "id": "1V7F",
        "text": "Volatile inhalant withdrawal"
    },
    {
        "id": "1V7G",
        "text": "Other specified psychoactive substance withdrawal"
    },
    {
        "id": "1V7H",
        "text": "Multiple specified psychoactive substances withdrawal"
    },
    {
        "id": "1V7I",
        "text": "Withdrawal due to unknown or unspecified psychoactive substance"
    },
    {
        "id": "1V7J",
        "text": "Alcohol withdrawal, uncomplicated"
    },
    {
        "id": "1V7K",
        "text": "Alcohol withdrawal with perceptual disturbances"
    },
    {
        "id": "1V7L",
        "text": "Alcohol withdrawal with seizures"
    },
    {
        "id": "1V7N",
        "text": "Sedative, hypnotic or anxiolytic withdrawal, uncomplicated"
    },
    {
        "id": "1V7M",
        "text": "Alcohol withdrawal with perceptual disturbances and seizures"
    },
    {
        "id": "1V7O",
        "text": "Sedative, hypnotic or anxiolytic withdrawal, with perceptual disturbances"
    },
    {
        "id": "1V7P",
        "text": "Sedative, hypnotic or anxiolytic withdrawal, with seizures"
    },
    {
        "id": "1V7Q",
        "text": "Sedative, hypnotic or anxiolytic withdrawal, with perceptual disturbances and seizures"
    },
    {
        "id": "1V7R",
        "text": "Underweight in adults"
    },
    {
        "id": "1V7S",
        "text": "Overweight or localised adiposity"
    },
    {
        "id": "1V7T",
        "text": "Obesity in adults"
    },
    {
        "id": "1V7U",
        "text": "Obesity in adults"
    },
    {
        "id": "1V7V",
        "text": "Obesity in adults"
    },
    {
        "id": "1V7W",
        "text": "Postviral fatigue syndrome"
    },
    {
        "id": "1V7X",
        "text": "Catatonia induced by substances or medications"
    },
    {
        "id": "1V7Y",
        "text": "Body-focused repetitive behaviour disorders"
    },
    {
        "id": "1V7Z",
        "text": "Obsessive-compulsive or related disorders"
    },
    {
        "id": "1V84",
        "text": "Oculopharyngeal muscular dystrophy"
    },
    {
        "id": "1V85",
        "text": "Oculopharyngodistal myopathy"
    },
    {
        "id": "1V83",
        "text": "Muscular dystrophy affecting extraocular muscle"
    },
    {
        "id": "1V86",
        "text": "Ocular muscular dystrophy"
    },
    {
        "id": "1V87",
        "text": "Congenital fibrosis of extraocular muscles"
    },
    {
        "id": "1V88",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V89",
        "text": "Tick-borne relapsing fever"
    },
    {
        "id": "1V8B",
        "text": "Factitious disorders"
    },
    {
        "id": "1V8C",
        "text": "Factitious disorder imposed on self"
    },
    {
        "id": "1V8D",
        "text": "Factitious disorder imposed on another"
    },
    {
        "id": "1V8E",
        "text": "Factitious disorder imposed on another"
    },
    {
        "id": "1V8G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V8H",
        "text": "Alcohol-induced psychotic disorder with mixed psychotic symptoms"
    },
    {
        "id": "1V8I",
        "text": "Cocaine-induced psychotic disorder with mixed psychotic symptoms"
    },
    {
        "id": "1V8J",
        "text": "Stimulant-induced psychotic disorder including amphetamines but excluding caffeine or cocaine with mixed psychotic symptoms"
    },
    {
        "id": "1V8K",
        "text": "Synthetic cathinone-induced psychotic disorder with mixed psychotic symptoms"
    },
    {
        "id": "1V8L",
        "text": "Alcohol-induced psychotic disorder with hallucinations"
    },
    {
        "id": "1V8M",
        "text": "Alcohol-induced psychotic disorder with delusions"
    },
    {
        "id": "1V8N",
        "text": "Cocaine-induced psychotic disorder with hallucinations"
    },
    {
        "id": "1V8O",
        "text": "Cocaine-induced psychotic disorder with delusions"
    },
    {
        "id": "1V8P",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone with hallucinations"
    },
    {
        "id": "1V8Q",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone with delusions"
    },
    {
        "id": "1V8R",
        "text": "Synthetic cathinone-induced psychotic disorder with hallucinations"
    },
    {
        "id": "1V8S",
        "text": "Synthetic cathinone-induced psychotic disorder with delusions"
    },
    {
        "id": "1V8T",
        "text": "Harmful pattern of use of non-psychoactive substances"
    },
    {
        "id": "1V8U",
        "text": "Disorders due to use of non-psychoactive substances"
    },
    {
        "id": "1V8X",
        "text": "Certain specified disorders of arteries or arterioles"
    },
    {
        "id": "1V8Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1V8Y",
        "text": "Candida otomycosis"
    },
    {
        "id": "1V90",
        "text": "Candida otomycosis"
    },
    {
        "id": "1V14",
        "text": "Chronic visceral pain from mechanical factors in the pelvic region"
    },
    {
        "id": "1V97",
        "text": "Seasonal pattern of mood episode onset"
    },
    {
        "id": "1V98",
        "text": "Anorexia Nervosa with significantly low body weight, restricting pattern"
    },
    {
        "id": "1V99",
        "text": "Anorexia Nervosa with significantly low body weight, binge-purge pattern"
    },
    {
        "id": "1V9A",
        "text": "Anorexia Nervosa with dangerously low body weight, restricting pattern"
    },
    {
        "id": "1V9B",
        "text": "Anorexia Nervosa with dangerously low body weight, binge-purge pattern"
    },
    {
        "id": "1V9C",
        "text": "Trichobezoar"
    },
    {
        "id": "1V9D",
        "text": "Alcohol dependence, current use, episodic"
    },
    {
        "id": "1V9E",
        "text": "Secondary impulse control syndrome"
    },
    {
        "id": "1V9F",
        "text": "Body-focused repetitive behaviour disorders"
    },
    {
        "id": "1W0B",
        "text": "Louse-borne relapsing fever"
    },
    {
        "id": "1W0X",
        "text": "Sepsis due to other Gram-negative organisms without mention of septic shock"
    },
    {
        "id": "1W0Y",
        "text": "Encephalitis due to Herpes B virus"
    },
    {
        "id": "1W0Z",
        "text": "Myelitis due to Varicella zoster virus"
    },
    {
        "id": "1W10",
        "text": "Myelitis due to Coxsackievirus group A or B"
    },
    {
        "id": "1V9G",
        "text": "Clouding of consciousness"
    },
    {
        "id": "1V9I",
        "text": "Anosognosia"
    },
    {
        "id": "1V9J",
        "text": "Disorientation"
    },
    {
        "id": "1V9K",
        "text": "Distractibility"
    },
    {
        "id": "1V9N",
        "text": "Poor concentration"
    },
    {
        "id": "1V9O",
        "text": "Anomia"
    },
    {
        "id": "1V9Q",
        "text": "Apathy"
    },
    {
        "id": "1V9R",
        "text": "Labile affect"
    },
    {
        "id": "1V9S",
        "text": "Dysphoria"
    },
    {
        "id": "1V9T",
        "text": "Euphoria"
    },
    {
        "id": "1V9U",
        "text": "Tantrum"
    },
    {
        "id": "1V9V",
        "text": "Symptoms or signs involving mood or affect"
    },
    {
        "id": "1V9X",
        "text": "Symptoms or signs involving mood or affect"
    },
    {
        "id": "1V9W",
        "text": "Symptoms or signs involving mood or affect"
    },
    {
        "id": "1V9M",
        "text": "Symptoms, signs or clinical findings involving cognition"
    },
    {
        "id": "1V9L",
        "text": "Symptoms, signs or clinical findings involving cognition"
    },
    {
        "id": "1V9H",
        "text": "Symptoms, signs or clinical findings involving cognition"
    },
    {
        "id": "1V9Z",
        "text": "Increased energy"
    },
    {
        "id": "1V9Y",
        "text": "Symptoms or signs involving motivation or energy"
    },
    {
        "id": "1W00",
        "text": "Symptoms or signs involving motivation or energy"
    },
    {
        "id": "1W02",
        "text": "Coprolalia"
    },
    {
        "id": "1W03",
        "text": "Panic attack"
    },
    {
        "id": "1W04",
        "text": "Poverty of speech"
    },
    {
        "id": "1W06",
        "text": "Pressured speech"
    },
    {
        "id": "1W07",
        "text": "Suicide attempt"
    },
    {
        "id": "1W01",
        "text": "Symptoms or signs involving appearance or behaviour"
    },
    {
        "id": "1W05",
        "text": "Symptoms or signs involving appearance or behaviour"
    },
    {
        "id": "1W09",
        "text": "Flight of ideas"
    },
    {
        "id": "1W0A",
        "text": "Neologisms"
    },
    {
        "id": "1W0C",
        "text": "Delusion of guilt"
    },
    {
        "id": "1W08",
        "text": "Symptoms or signs involving appearance or behaviour"
    },
    {
        "id": "1W0D",
        "text": "Persecutory delusion"
    },
    {
        "id": "1W0E",
        "text": "Delusion"
    },
    {
        "id": "1W0F",
        "text": "Delusion"
    },
    {
        "id": "1W0G",
        "text": "Delusion"
    },
    {
        "id": "1W0H",
        "text": "Experiences of influence, passivity, and control"
    },
    {
        "id": "1W0I",
        "text": "Thought broadcasting"
    },
    {
        "id": "1W0J",
        "text": "Thought insertion"
    },
    {
        "id": "1W0K",
        "text": "Grandiosity"
    },
    {
        "id": "1W0L",
        "text": "Overvalued ideas"
    },
    {
        "id": "1W0M",
        "text": "Referential thinking"
    },
    {
        "id": "1W0N",
        "text": "Suspiciousness"
    },
    {
        "id": "1W0O",
        "text": "Depersonalisation"
    },
    {
        "id": "1W0P",
        "text": "Derealisation"
    },
    {
        "id": "1W0Q",
        "text": "Symptoms or signs involving perceptual disturbance"
    },
    {
        "id": "1W0R",
        "text": "Symptoms or signs involving perceptual disturbance"
    },
    {
        "id": "1W0S",
        "text": "Gustatory hallucinations"
    },
    {
        "id": "1W0T",
        "text": "Somatic hallucinations"
    },
    {
        "id": "1W0U",
        "text": "Negativism"
    },
    {
        "id": "1W0V",
        "text": "Decreased appetite"
    },
    {
        "id": "1W0W",
        "text": "Increased appetite"
    },
    {
        "id": "1W11",
        "text": "Mutism"
    },
    {
        "id": "1W13",
        "text": "Reactive attachment disorder"
    },
    {
        "id": "1W12",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1W14",
        "text": "Dissociative neurological symptom disorder, with non-epileptic seizures"
    },
    {
        "id": "1V96",
        "text": "Panic attacks in mood episodes"
    },
    {
        "id": "1W15",
        "text": "Symptomatic manifestations of primary psychotic disorders"
    },
    {
        "id": "1W16",
        "text": "Overeating"
    },
    {
        "id": "1W17",
        "text": "Increased appetite"
    },
    {
        "id": "1W18",
        "text": "Acute and transient psychotic disorder, first episode"
    },
    {
        "id": "1W19",
        "text": "Acute and transient psychotic disorder, first episode, currently symptomatic"
    },
    {
        "id": "1W1A",
        "text": "Acute and transient psychotic disorder, first episode, in partial remission"
    },
    {
        "id": "1W1B",
        "text": "Acute and transient psychotic disorder, first episode, in full remission"
    },
    {
        "id": "1W1C",
        "text": "Personality difficulty"
    },
    {
        "id": "1W1D",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1W1E",
        "text": "Enuresis"
    },
    {
        "id": "1W1F",
        "text": "Encopresis"
    },
    {
        "id": "1W1G",
        "text": "Selective mutism"
    },
    {
        "id": "1W1H",
        "text": "Generalised anxiety disorder"
    },
    {
        "id": "1W1I",
        "text": "Agoraphobia"
    },
    {
        "id": "1W1J",
        "text": "Specific phobia"
    },
    {
        "id": "1W1K",
        "text": "Social anxiety disorder"
    },
    {
        "id": "1W1L",
        "text": "Separation anxiety disorder"
    },
    {
        "id": "1W1M",
        "text": "Depersonalization-derealization disorder"
    },
    {
        "id": "1W1N",
        "text": "Concern about breast appearance"
    },
    {
        "id": "1W1O",
        "text": "Contact with health services for concerns about body image related to pregnancy"
    },
    {
        "id": "1W1R",
        "text": "Detachment in personality disorder or personality difficulty"
    },
    {
        "id": "1W1S",
        "text": "Disinhibition in personality disorder or personality difficulty"
    },
    {
        "id": "1W1Q",
        "text": "Negative affectivity in personality disorder or personality difficulty"
    },
    {
        "id": "1W1P",
        "text": "Anankastia in personality disorder or personality difficulty"
    },
    {
        "id": "1W1T",
        "text": "Dissociality in personality disorder or personality difficulty"
    },
    {
        "id": "1W1U",
        "text": "Prominent personality traits or patterns"
    },
    {
        "id": "1W1V",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1W1W",
        "text": "Anankastia in personality disorder or personality difficulty"
    },
    {
        "id": "1W1X",
        "text": "Detachment in personality disorder or personality difficulty"
    },
    {
        "id": "1W1Y",
        "text": "Disinhibition in personality disorder or personality difficulty"
    },
    {
        "id": "1W1Z",
        "text": "Dissociality in personality disorder or personality difficulty"
    },
    {
        "id": "1W20",
        "text": "Negative affectivity in personality disorder or personality difficulty"
    },
    {
        "id": "1W21",
        "text": "Borderline pattern"
    },
    {
        "id": "1W22",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium"
    },
    {
        "id": "1V94",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium, without psychotic symptoms"
    },
    {
        "id": "1V95",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium, with psychotic symptoms"
    },
    {
        "id": "1W23",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium, without psychotic symptoms"
    },
    {
        "id": "1W24",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium, with psychotic symptoms"
    },
    {
        "id": "1W25",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W26",
        "text": "Bipolar type I disorder, current episode manic, without psychotic symptoms"
    },
    {
        "id": "1W27",
        "text": "Bipolar type I disorder, current episode manic, with psychotic symptoms"
    },
    {
        "id": "1W28",
        "text": "Bipolar type I disorder, current episode hypomanic"
    },
    {
        "id": "1W29",
        "text": "Bipolar type I disorder, current episode depressive, mild"
    },
    {
        "id": "1W2A",
        "text": "Bipolar type I disorder, current episode depressive, moderate without psychotic symptoms"
    },
    {
        "id": "1W2B",
        "text": "Bipolar type I disorder, current episode depressive, moderate with psychotic symptoms"
    },
    {
        "id": "1W2C",
        "text": "Bipolar type I disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "1W2D",
        "text": "Bipolar type I disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "1W2E",
        "text": "Bipolar type I disorder, current episode depressive, unspecified severity"
    },
    {
        "id": "1W2F",
        "text": "Bipolar type I disorder, current episode mixed, without psychotic symptoms"
    },
    {
        "id": "1W2G",
        "text": "Bipolar type I disorder, current episode mixed, with psychotic symptoms"
    },
    {
        "id": "1W2H",
        "text": "Bipolar type II disorder, current episode hypomanic"
    },
    {
        "id": "1W2I",
        "text": "Bipolar type II disorder, current episode depressive, mild"
    },
    {
        "id": "1W2J",
        "text": "Bipolar type II disorder, current episode depressive, moderate without psychotic symptoms"
    },
    {
        "id": "1W2K",
        "text": "Bipolar type II disorder, current episode depressive, moderate with psychotic symptoms"
    },
    {
        "id": "1W2L",
        "text": "Bipolar type II disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "1W2M",
        "text": "Bipolar type II disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "1W2N",
        "text": "Bipolar type II disorder, current episode depressive, unspecified severity"
    },
    {
        "id": "1W2O",
        "text": "Single episode depressive disorder, mild"
    },
    {
        "id": "1W2P",
        "text": "Single episode depressive disorder, moderate, without psychotic symptoms"
    },
    {
        "id": "1W2Q",
        "text": "Single episode depressive disorder, moderate, with psychotic symptoms"
    },
    {
        "id": "1W2R",
        "text": "Single episode depressive disorder, severe, without psychotic symptoms"
    },
    {
        "id": "1W2S",
        "text": "Single episode depressive disorder, severe, with psychotic symptoms"
    },
    {
        "id": "1W2T",
        "text": "Single episode depressive disorder, unspecified severity"
    },
    {
        "id": "1W2U",
        "text": "Recurrent depressive disorder, current episode mild"
    },
    {
        "id": "1W2V",
        "text": "Recurrent depressive disorder, current episode moderate, without psychotic symptoms"
    },
    {
        "id": "1W2W",
        "text": "Recurrent depressive disorder, current episode moderate, with psychotic symptoms"
    },
    {
        "id": "1W2X",
        "text": "Recurrent depressive disorder, current episode severe, without psychotic symptoms"
    },
    {
        "id": "1W2Y",
        "text": "Recurrent depressive disorder, current episode severe, with psychotic symptoms"
    },
    {
        "id": "1W2Z",
        "text": "Recurrent depressive disorder, current episode, unspecified severity"
    },
    {
        "id": "1W30",
        "text": "Single episode depressive disorder, currently in partial remission"
    },
    {
        "id": "1W31",
        "text": "Single episode depressive disorder, currently in full remission"
    },
    {
        "id": "1W32",
        "text": "Recurrent depressive disorder, currently in partial remission"
    },
    {
        "id": "1W33",
        "text": "Recurrent depressive disorder, currently in full remission"
    },
    {
        "id": "1W3A",
        "text": "Melanoma of eye or ocular adnexa"
    },
    {
        "id": "1W34",
        "text": "Positive symptoms in primary psychotic disorders"
    },
    {
        "id": "1W35",
        "text": "Negative symptoms in primary psychotic disorders"
    },
    {
        "id": "1W36",
        "text": "Depressive mood symptoms in primary psychotic disorders"
    },
    {
        "id": "1W37",
        "text": "Manic mood symptoms in primary psychotic disorders"
    },
    {
        "id": "1W39",
        "text": "Cognitive symptoms in primary psychotic disorders"
    },
    {
        "id": "1W3B",
        "text": "Primary tics or tic disorders"
    },
    {
        "id": "1W3C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W3D",
        "text": "Obsessive-compulsive or related disorders"
    },
    {
        "id": "1W3E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W3F",
        "text": "Acute noninfectious otitis externa"
    },
    {
        "id": "1W3H",
        "text": "Abscess of external ear"
    },
    {
        "id": "1W3I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W3K",
        "text": "Cellulitis of external ear"
    },
    {
        "id": "1W3L",
        "text": "Juvenile spring eruption"
    },
    {
        "id": "1W3M",
        "text": "Keratosis obturans"
    },
    {
        "id": "1W3N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W3O",
        "text": "Noninflammatory disorders of the external ear"
    },
    {
        "id": "1W38",
        "text": "Psychomotor symptoms in primary psychotic disorders"
    },
    {
        "id": "1V34",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1W3Z",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1W40",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1W41",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1W42",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1W43",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1W44",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1W45",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1V8F",
        "text": "Disorders due to use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1W47",
        "text": "Female pelvic pain associated with genital organs or menstrual cycle"
    },
    {
        "id": "1W48",
        "text": "Premenstrual tension syndrome"
    },
    {
        "id": "1W46",
        "text": "Premenstrual dysphoric disorder"
    },
    {
        "id": "1W49",
        "text": "Premenstrual dysphoric disorder"
    },
    {
        "id": "1T9M",
        "text": "Cellulitis of lower limb"
    },
    {
        "id": "1W4A",
        "text": "Human immunodeficiency virus disease"
    },
    {
        "id": "1W4C",
        "text": "Diseases of the skin"
    },
    {
        "id": "1W4D",
        "text": "Cutaneous markers of internal disorders"
    },
    {
        "id": "1W4E",
        "text": "Cutaneous markers of internal disorders"
    },
    {
        "id": "1R7E",
        "text": "External auditory meatus"
    },
    {
        "id": "1R53",
        "text": "Supraorbital region"
    },
    {
        "id": "1W4G",
        "text": "Infraorbital region"
    },
    {
        "id": "1W4H",
        "text": "Superior palpebral sulcus"
    },
    {
        "id": "1W4J",
        "text": "Upper cheek"
    },
    {
        "id": "1W4M",
        "text": "Freeman-Sheldon syndrome"
    },
    {
        "id": "1W4N",
        "text": "Alcohol dependence"
    },
    {
        "id": "1W4O",
        "text": "Cannabis dependence"
    },
    {
        "id": "1W4P",
        "text": "Synthetic cannabinoid dependence"
    },
    {
        "id": "1W4Q",
        "text": "Opioid dependence"
    },
    {
        "id": "1W4R",
        "text": "Sedative, hypnotic or anxiolytic dependence"
    },
    {
        "id": "1W4S",
        "text": "Cocaine dependence"
    },
    {
        "id": "1W4T",
        "text": "Stimulant dependence including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1W4U",
        "text": "Synthetic cathinone dependence"
    },
    {
        "id": "1W4V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W4W",
        "text": "Hallucinogen dependence"
    },
    {
        "id": "1W4X",
        "text": "Nicotine dependence"
    },
    {
        "id": "1W4Y",
        "text": "Volatile inhalant dependence"
    },
    {
        "id": "1W4Z",
        "text": "MDMA or related drug dependence, including MDA"
    },
    {
        "id": "1W50",
        "text": "Dissociative drug dependence including ketamine or PCP"
    },
    {
        "id": "1W51",
        "text": "Other specified psychoactive substance dependence"
    },
    {
        "id": "1W52",
        "text": "Multiple specified psychoactive substances dependence"
    },
    {
        "id": "1W53",
        "text": "Unknown or unspecified psychoactive substance dependence"
    },
    {
        "id": "1W54",
        "text": "Inframammary flexure"
    },
    {
        "id": "1W55",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W56",
        "text": "Thorax"
    },
    {
        "id": "1W57",
        "text": "Chest wall"
    },
    {
        "id": "1W58",
        "text": "Nipple"
    },
    {
        "id": "1W59",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W5A",
        "text": "Lactiferous ducts"
    },
    {
        "id": "1W5B",
        "text": "Inguinal canal"
    },
    {
        "id": "1W3T",
        "text": "Chronic primary pelvic pain syndrome"
    },
    {
        "id": "1W3S",
        "text": "Chronic primary pelvic pain syndrome"
    },
    {
        "id": "1V35",
        "text": "Chronic post cancer medicine pain"
    },
    {
        "id": "1W5C",
        "text": "Chronic neuropathic pain"
    },
    {
        "id": "1W5D",
        "text": "Chronic peripheral neuropathic pain"
    },
    {
        "id": "1R31",
        "text": "Chronic peripheral neuropathic pain"
    },
    {
        "id": "1W5E",
        "text": "Chronic neuropathic pain after peripheral nerve injury"
    },
    {
        "id": "1W5F",
        "text": "Chronic painful polyneuropathy"
    },
    {
        "id": "1R34",
        "text": "Chronic peripheral neuropathic pain"
    },
    {
        "id": "1W5G",
        "text": "Chronic painful radiculopathy"
    },
    {
        "id": "1W5H",
        "text": "Chronic central neuropathic pain"
    },
    {
        "id": "1W5I",
        "text": "Chronic central neuropathic pain associated with spinal cord injury"
    },
    {
        "id": "1W5J",
        "text": "Chronic central neuropathic pain associated with brain injury"
    },
    {
        "id": "1W5K",
        "text": "Chronic central post stroke pain"
    },
    {
        "id": "1W5L",
        "text": "Chronic central neuropathic pain associated with multiple sclerosis"
    },
    {
        "id": "1W5N",
        "text": "Miscellaneous non-specific skin-related symptoms and signs"
    },
    {
        "id": "1W5O",
        "text": "Miscellaneous non-specific skin-related symptoms and signs"
    },
    {
        "id": "1W5P",
        "text": "Skin disorder of uncertain or unspecified nature"
    },
    {
        "id": "1W5R",
        "text": "Histoplasmosis"
    },
    {
        "id": "1W5S",
        "text": "Mucormycosis"
    },
    {
        "id": "1W5T",
        "text": "Talaromycosis"
    },
    {
        "id": "1W5V",
        "text": "Scedosporiosis"
    },
    {
        "id": "1W5U",
        "text": "Mycoses"
    },
    {
        "id": "1W5W",
        "text": "Chronic post radiotherapy pain"
    },
    {
        "id": "1W5X",
        "text": "Chronic post radiotherapy pain"
    },
    {
        "id": "1U6A",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "1W5Y",
        "text": "Chronic secondary musculoskeletal pain from persistent inflammation"
    },
    {
        "id": "1W5Z",
        "text": "Chronic secondary musculoskeletal pain associated with structural changes"
    },
    {
        "id": "1W60",
        "text": "Chronic secondary musculoskeletal pain due to disease of the nervous system"
    },
    {
        "id": "1W61",
        "text": "Chronic secondary musculoskeletal pain from persistent inflammation due to infection"
    },
    {
        "id": "1W62",
        "text": "Chronic secondary musculoskeletal pain from persistent inflammation due to crystal deposition"
    },
    {
        "id": "1W63",
        "text": "Chronic secondary musculoskeletal pain from persistent inflammation due to autoimmune and auto-inflammatory disorders"
    },
    {
        "id": "1W64",
        "text": "Chronic secondary musculoskeletal pain associated with osteoarthritis"
    },
    {
        "id": "1W65",
        "text": "Chronic secondary musculoskeletal pain associated with spondylosis"
    },
    {
        "id": "1W68",
        "text": "Eccrine gland"
    },
    {
        "id": "1W69",
        "text": "Epidermis"
    },
    {
        "id": "1W6D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W6E",
        "text": "Nail"
    },
    {
        "id": "1W6F",
        "text": "Hair follicle"
    },
    {
        "id": "1W6B",
        "text": "Epidermis"
    },
    {
        "id": "1W6C",
        "text": "Epidermis"
    },
    {
        "id": "1W6G",
        "text": "Nail"
    },
    {
        "id": "1W6H",
        "text": "Nail"
    },
    {
        "id": "1W6I",
        "text": "Hair"
    },
    {
        "id": "1W6K",
        "text": "Mycoses"
    },
    {
        "id": "1W6N",
        "text": "Head and neck"
    },
    {
        "id": "1W6Q",
        "text": "Glabella"
    },
    {
        "id": "1W6S",
        "text": "Scalp"
    },
    {
        "id": "1W6T",
        "text": "Chronic secondary musculoskeletal pain due to disease of the nervous system"
    },
    {
        "id": "1W6U",
        "text": "Chronic secondary musculoskeletal pain due to disease of the nervous system"
    },
    {
        "id": "1W6V",
        "text": "Chronic secondary musculoskeletal pain due to disease of the nervous system"
    },
    {
        "id": "1W6Y",
        "text": "Chronic pain after amputation"
    },
    {
        "id": "1W6Z",
        "text": "Chronic pain after spinal surgery"
    },
    {
        "id": "1W3U",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1W3V",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1W70",
        "text": "Chronic pain after herniotomy"
    },
    {
        "id": "1W71",
        "text": "Chronic pain after hysterectomy"
    },
    {
        "id": "1W3W",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "1W72",
        "text": "Chronic pain after burns injury"
    },
    {
        "id": "1W73",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "1W74",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "1W75",
        "text": "Chronic secondary visceral pain"
    },
    {
        "id": "1W76",
        "text": "Chronic visceral pain from persistent inflammation in the head or neck region"
    },
    {
        "id": "1W77",
        "text": "Chronic visceral pain from persistent inflammation in the thoracic region"
    },
    {
        "id": "1W78",
        "text": "Chronic visceral pain from persistent inflammation in the abdominal region"
    },
    {
        "id": "1W79",
        "text": "Chronic visceral pain from mechanical factors in the head or neck region"
    },
    {
        "id": "1W7A",
        "text": "Chronic visceral pain from mechanical factors in the thoracic region"
    },
    {
        "id": "1W7B",
        "text": "Chronic visceral pain from mechanical factors in the abdominal region"
    },
    {
        "id": "1W7C",
        "text": "Chronic visceral pain from vascular mechanisms in the head or neck region"
    },
    {
        "id": "1W7D",
        "text": "Chronic visceral pain from vascular mechanisms in the thoracic region"
    },
    {
        "id": "1W7E",
        "text": "Chronic visceral pain from vascular mechanisms in the abdominal region"
    },
    {
        "id": "1W7F",
        "text": "Chronic visceral pain from vascular mechanisms in the pelvic region"
    },
    {
        "id": "1W66",
        "text": "Hypodermis"
    },
    {
        "id": "1O4I",
        "text": "Congenital aortic regurgitation"
    },
    {
        "id": "1W7G",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "1W7H",
        "text": "Chronic primary low back pain"
    },
    {
        "id": "1W7I",
        "text": "Chronic primary cervical pain"
    },
    {
        "id": "1W7J",
        "text": "Chronic primary thoracic pain"
    },
    {
        "id": "1W7K",
        "text": "Chronic primary limb pain"
    },
    {
        "id": "1W7L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W7M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W7Q",
        "text": "Epithelium"
    },
    {
        "id": "1W7R",
        "text": "Conjunctiva"
    },
    {
        "id": "1W7S",
        "text": "Cornea"
    },
    {
        "id": "1W7T",
        "text": "Sclera"
    },
    {
        "id": "1W7U",
        "text": "Suprasternal notch"
    },
    {
        "id": "1W7V",
        "text": "Neck"
    },
    {
        "id": "1W7X",
        "text": "Axilla"
    },
    {
        "id": "1W7Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W7Y",
        "text": "Upper back"
    },
    {
        "id": "1W80",
        "text": "Trunk"
    },
    {
        "id": "1W82",
        "text": "Periumbilical region"
    },
    {
        "id": "1W85",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W86",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W87",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W88",
        "text": "Palm of hand"
    },
    {
        "id": "1W89",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W8C",
        "text": "Tips of fingers"
    },
    {
        "id": "1W8D",
        "text": "Tips of fingers"
    },
    {
        "id": "1W8E",
        "text": "Fingernails"
    },
    {
        "id": "1W8G",
        "text": "Distal phalanx of thumb"
    },
    {
        "id": "1W8K",
        "text": "Distal phalanx of ring finger"
    },
    {
        "id": "1W8J",
        "text": "Distal phalanx of middle finger"
    },
    {
        "id": "1W8I",
        "text": "Distal phalanx of little finger"
    },
    {
        "id": "1W8H",
        "text": "Distal phalanx of index finger"
    },
    {
        "id": "1W8L",
        "text": "Pad of thumb"
    },
    {
        "id": "1W8M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W8N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W8O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W8P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W8Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W8R",
        "text": "Knuckles"
    },
    {
        "id": "1W8S",
        "text": "Dorsum of hand"
    },
    {
        "id": "1W8T",
        "text": "Trochanteric region"
    },
    {
        "id": "1W8U",
        "text": "Distal lower leg"
    },
    {
        "id": "1W8V",
        "text": "Proximal lower leg"
    },
    {
        "id": "1W8W",
        "text": "Arch of foot"
    },
    {
        "id": "1W8X",
        "text": "Midfoot"
    },
    {
        "id": "1W8Y",
        "text": "Arch of foot"
    },
    {
        "id": "1W8Z",
        "text": "Side of finger"
    },
    {
        "id": "1W94",
        "text": "Clitoral hood"
    },
    {
        "id": "1W96",
        "text": "Posterior fourchette of vulva"
    },
    {
        "id": "1W93",
        "text": "Labia of vulva"
    },
    {
        "id": "1W97",
        "text": "Bartholin gland"
    },
    {
        "id": "1W98",
        "text": "Vulva"
    },
    {
        "id": "1W9B",
        "text": "Mucosal surface of prepuce"
    },
    {
        "id": "1W99",
        "text": "Penis"
    },
    {
        "id": "1W9C",
        "text": "Male genital organs"
    },
    {
        "id": "1W9E",
        "text": "Anogenital region"
    },
    {
        "id": "1W9F",
        "text": "Genital region"
    },
    {
        "id": "1W9G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W6M",
        "text": "Hair"
    },
    {
        "id": "1W9J",
        "text": "Myasthenic syndromes in endocrine diseases"
    },
    {
        "id": "1W9P",
        "text": "Certain specified primary disorders of muscles"
    },
    {
        "id": "1W9U",
        "text": "Epilepsy due to prenatal or perinatal brain insults"
    },
    {
        "id": "1W9V",
        "text": "Epilepsy due to neonatal hypoxic ischemic encephalopathy"
    },
    {
        "id": "1W9W",
        "text": "Epilepsy due to multiple sclerosis or other demyelinating disorders"
    },
    {
        "id": "1W9Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X01",
        "text": "Acute repetitive seizures"
    },
    {
        "id": "1X02",
        "text": "Audiogenic epilepsy"
    },
    {
        "id": "1X03",
        "text": "Eating epilepsy"
    },
    {
        "id": "1X04",
        "text": "Thinking epilepsy"
    },
    {
        "id": "1X05",
        "text": "Orgasm-induced epilepsy"
    },
    {
        "id": "1X06",
        "text": "Micturition-induced epilepsy"
    },
    {
        "id": "1X07",
        "text": "Familial encephalopathy with neuroserpin inclusion bodies"
    },
    {
        "id": "1X08",
        "text": "Focal unaware seizure"
    },
    {
        "id": "1X09",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X0A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X0B",
        "text": "Absence episode"
    },
    {
        "id": "1X0C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X0D",
        "text": "Head"
    },
    {
        "id": "1M1U",
        "text": "Head"
    },
    {
        "id": "1X0F",
        "text": "Artefactual cheilitis"
    },
    {
        "id": "1X0G",
        "text": "Artefactual panniculitis"
    },
    {
        "id": "1X0E",
        "text": "Artefactual skin disorder"
    },
    {
        "id": "1X0H",
        "text": "Artefactual skin disorder"
    },
    {
        "id": "1X0J",
        "text": "Asymptomatic occlusion of intracranial or extracranial artery"
    },
    {
        "id": "1X0I",
        "text": "Altered pattern of family relationships in childhood"
    },
    {
        "id": "1X0K",
        "text": "Malignant neoplasm metastases"
    },
    {
        "id": "1X0L",
        "text": "Metastatic malignant neoplasm involving skin"
    },
    {
        "id": "1X0M",
        "text": "Castleman disease"
    },
    {
        "id": "1T5Z",
        "text": "Personal history of psychological abuse"
    },
    {
        "id": "1W7P",
        "text": "Examination or observation for suspected psychological maltreatment"
    },
    {
        "id": "1X0O",
        "text": "Simulated skin disease"
    },
    {
        "id": "1X0N",
        "text": "Artefactual skin disorder"
    },
    {
        "id": "1X0R",
        "text": "Tremor due to anxiety, stress, or fatigue"
    },
    {
        "id": "1X0T",
        "text": "Autosomal recessive spastic paraplegia type 24"
    },
    {
        "id": "1X0U",
        "text": "Ataxia due to certain specified toxins"
    },
    {
        "id": "1X0W",
        "text": "Chorea due to mitochondriopathy"
    },
    {
        "id": "1X0Y",
        "text": "Dystonia due to certain specified lesions of the peripheral nervous system"
    },
    {
        "id": "1X10",
        "text": "Chorea due to acquired hepatocerebral degeneration"
    },
    {
        "id": "1X13",
        "text": "Paroxysmal dystonic choreoathetosis with episodic ataxia or spasticity"
    },
    {
        "id": "1X16",
        "text": "Tremor due to drug withdrawal"
    },
    {
        "id": "1X18",
        "text": "Chorea due to certain specified drugs"
    },
    {
        "id": "1X1A",
        "text": "Autosomal recessive spastic paraplegia type 18"
    },
    {
        "id": "1X1G",
        "text": "Tics associated with certain specified intellectual development disorder"
    },
    {
        "id": "1X1H",
        "text": "Dystonia due to GM2 gangliosidosis"
    },
    {
        "id": "1X1N",
        "text": "Dystonia due to Metachromatic leukodystrophy"
    },
    {
        "id": "1X1O",
        "text": "Spinocerebellar ataxia type 7"
    },
    {
        "id": "1X1P",
        "text": "X-linked spinocerebellar ataxia type 1"
    },
    {
        "id": "1X1S",
        "text": "Tics due to encephalitis"
    },
    {
        "id": "1X1U",
        "text": "Hereditary optic neuropathy associated with hereditary ataxias"
    },
    {
        "id": "1X20",
        "text": "Other movement disorders, not elsewhere classified"
    },
    {
        "id": "1X27",
        "text": "Dystonia associated with familial basal ganglia calcifications"
    },
    {
        "id": "1X2J",
        "text": "Tics due to Sydenham\u2019s chorea"
    },
    {
        "id": "1X2K",
        "text": "Primary tics or tic disorders"
    },
    {
        "id": "1X2M",
        "text": "Hemichorea due to cerebral haemorrhage"
    },
    {
        "id": "1X2N",
        "text": "Ataxia in chronic central nervous system infection"
    },
    {
        "id": "1X2O",
        "text": "Hemichorea due to tumour"
    },
    {
        "id": "1X2Q",
        "text": "Dystonia of variable inheritance"
    },
    {
        "id": "1X2Z",
        "text": "Hemichorea due to injuries to the head"
    },
    {
        "id": "1X30",
        "text": "Hemichorea due to arteriovenous malformation"
    },
    {
        "id": "1X32",
        "text": "Tics due to prion disease"
    },
    {
        "id": "1X37",
        "text": "Hemichorea due to cerebral infarct"
    },
    {
        "id": "1X39",
        "text": "Other genetic Parkinsonism"
    },
    {
        "id": "1X3A",
        "text": "Rheumatic chorea without heart involvement"
    },
    {
        "id": "1X3B",
        "text": "Chorea due to certain neoplastic or para-neoplastic syndrome"
    },
    {
        "id": "1X3C",
        "text": "Dystonia due to ataxia-telangiectasia"
    },
    {
        "id": "1X3D",
        "text": "Sleep-related movement disorder due to a medication or substance"
    },
    {
        "id": "1X3G",
        "text": "Dystonia due to certain specified inherited disorders"
    },
    {
        "id": "1X3H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X3I",
        "text": "X-linked spastic paraplegia type 16"
    },
    {
        "id": "1X3P",
        "text": "Other infectious choreas"
    },
    {
        "id": "1X3Q",
        "text": "Chorea due to ataxia-telangiectasia"
    },
    {
        "id": "1X3R",
        "text": "Parkinson disease ATP13A2 mutations"
    },
    {
        "id": "1X3S",
        "text": "Autosomal recessive spastic paraplegia type 21"
    },
    {
        "id": "1X3V",
        "text": "Chorea due to Wilson disease"
    },
    {
        "id": "1X3X",
        "text": "Ataxia due to mitochondrial mutations"
    },
    {
        "id": "1X3Y",
        "text": "Secondary tremor"
    },
    {
        "id": "1X41",
        "text": "Chorea due to acquired or inherited metabolic disorders"
    },
    {
        "id": "1X42",
        "text": "Dystonia lenticularis"
    },
    {
        "id": "1X43",
        "text": "Autosomal dominant spastic paraplegia type 9"
    },
    {
        "id": "1X45",
        "text": "Autosomal dominant spastic paraplegia type 13"
    },
    {
        "id": "1X48",
        "text": "Autosomal recessive spastic paraplegia type 15"
    },
    {
        "id": "1X4F",
        "text": "Dystonia due to Familial basal ganglia calcifications"
    },
    {
        "id": "1X4L",
        "text": "Autosomal recessive spastic paraplegia type 25"
    },
    {
        "id": "1X4N",
        "text": "Tremor due to certain specified central nervous system diseases"
    },
    {
        "id": "1X4S",
        "text": "Sporadic adult-onset dystonia, not elsewhere classified"
    },
    {
        "id": "1X4U",
        "text": "Ataxia due to other immune-mediated problems"
    },
    {
        "id": "1X4W",
        "text": "Other autosomal recessive ataxia"
    },
    {
        "id": "1X4Y",
        "text": "Autosomal dominant spastic paraplegia type 4"
    },
    {
        "id": "1X4Z",
        "text": "X-linked ataxia"
    },
    {
        "id": "1X51",
        "text": "Stereotypy due to tumour"
    },
    {
        "id": "1X52",
        "text": "Chorea due to inherited metabolic disorders"
    },
    {
        "id": "1X53",
        "text": "Acute dystonic reaction"
    },
    {
        "id": "1X54",
        "text": "Myoclonus-dystonia"
    },
    {
        "id": "1X55",
        "text": "Spinocerebellar ataxia type 6"
    },
    {
        "id": "1X56",
        "text": "Postinfectious Parkinsonism"
    },
    {
        "id": "1X57",
        "text": "Machado-Joseph disease type 1"
    },
    {
        "id": "1X58",
        "text": "Marinesco-Sj\u00f6gren syndrome"
    },
    {
        "id": "1X59",
        "text": "Chorea gravidarum"
    },
    {
        "id": "1X5A",
        "text": "Autosomal dominant dopa-responsive dystonia"
    },
    {
        "id": "1X5B",
        "text": "Tremor due to hyperthyroidism"
    },
    {
        "id": "1X5C",
        "text": "Tremor due to Fragile X permutation"
    },
    {
        "id": "1X5D",
        "text": "Autosomal recessive dopa-responsive dystonia"
    },
    {
        "id": "1X5E",
        "text": "Orthostatic tremor"
    },
    {
        "id": "1X5F",
        "text": "Parkinsonism in Huntington disease"
    },
    {
        "id": "1X5G",
        "text": "Post traumatic Parkinsonism"
    },
    {
        "id": "1X5H",
        "text": "Bobbing head doll"
    },
    {
        "id": "1X5I",
        "text": "Drug-induced tics"
    },
    {
        "id": "1X5J",
        "text": "Tremor due to chronic or acute substance use"
    },
    {
        "id": "1X5K",
        "text": "Perry syndrome"
    },
    {
        "id": "1X5L",
        "text": "Truncal dystonia"
    },
    {
        "id": "1X5M",
        "text": "Propriospinal myoclonus"
    },
    {
        "id": "1X5N",
        "text": "Parkinson disease parkin mutations"
    },
    {
        "id": "1X5O",
        "text": "Progressive supranuclear palsy"
    },
    {
        "id": "1X5P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X5Q",
        "text": "Rest tremor"
    },
    {
        "id": "1X5R",
        "text": "Chorea due to hyperglycaemia"
    },
    {
        "id": "1X5S",
        "text": "Progressive pallidal degeneration"
    },
    {
        "id": "1X5T",
        "text": "Paroxysmal non-kinesigenic dystonia"
    },
    {
        "id": "1X5U",
        "text": "Drug-induced parkinsonism"
    },
    {
        "id": "1X5V",
        "text": "Dystonia due to X-linked recessive disorders"
    },
    {
        "id": "1X5W",
        "text": "Parkinson disease synuclein gene mutation 4q21.23"
    },
    {
        "id": "1X5X",
        "text": "Secondary paroxysmal dyskinesia"
    },
    {
        "id": "1X5Y",
        "text": "Essential myoclonus"
    },
    {
        "id": "1X5Z",
        "text": "Rheumatic chorea"
    },
    {
        "id": "1X60",
        "text": "Post pump chorea"
    },
    {
        "id": "1X61",
        "text": "Tremor due to stroke"
    },
    {
        "id": "1X62",
        "text": "Myoclonus due to Sialidosis"
    },
    {
        "id": "1X63",
        "text": "Dystonia due to dopamine receptor blocking agents"
    },
    {
        "id": "1X64",
        "text": "Periodic limb movement disorder"
    },
    {
        "id": "1X65",
        "text": "Vascular parkinsonism"
    },
    {
        "id": "1X66",
        "text": "Dystonia associated with peripheral injury"
    },
    {
        "id": "1X67",
        "text": "Spasmus nutans"
    },
    {
        "id": "1X68",
        "text": "Chorea due to Huntington disease-like 2"
    },
    {
        "id": "1X69",
        "text": "Deafness-dystonia optic atrophy syndrome"
    },
    {
        "id": "1X6A",
        "text": "Chorea due to toxins"
    },
    {
        "id": "1X6B",
        "text": "Carbon disulphide-induced Parkinsonism"
    },
    {
        "id": "1X6C",
        "text": "Paroxysmal dyskinesia due to vascular lesions"
    },
    {
        "id": "1X6D",
        "text": "Myoclonus due to hypoxia"
    },
    {
        "id": "1X6E",
        "text": "Secondary dystonia"
    },
    {
        "id": "1X6F",
        "text": "Huntington disease"
    },
    {
        "id": "1X6G",
        "text": "Episodic ataxia type 1 - KCNA1 mutation"
    },
    {
        "id": "1L63",
        "text": "Dystonic disorders"
    },
    {
        "id": "1X6H",
        "text": "Myoclonus due to dementia"
    },
    {
        "id": "1X6I",
        "text": "Chorea due to Neuroacanthocytosis"
    },
    {
        "id": "1X6J",
        "text": "Tyrosine hydroxylase deficiency"
    },
    {
        "id": "1X6K",
        "text": "Dystonia due to Leigh syndrome"
    },
    {
        "id": "1X6L",
        "text": "Sporadic adult-onset ataxia of unknown origin"
    },
    {
        "id": "1X6M",
        "text": "Choreoacanthocytosis"
    },
    {
        "id": "1X6N",
        "text": "Chorea due to systemic lupus erythematosus"
    },
    {
        "id": "1X6O",
        "text": "Corticobasal degeneration"
    },
    {
        "id": "1X6P",
        "text": "Hemifacial spasm"
    },
    {
        "id": "1X6Q",
        "text": "Other secondary paroxysmal dyskinesia"
    },
    {
        "id": "1X6R",
        "text": "Spinocerebellar ataxia type 29"
    },
    {
        "id": "1X6S",
        "text": "Laryngeal dystonia"
    },
    {
        "id": "1X6T",
        "text": "Dystonia-plus"
    },
    {
        "id": "1L61",
        "text": "Toxin-induced parkinsonism"
    },
    {
        "id": "1X6U",
        "text": "Fragile X\u2013associated tremor or ataxia syndrome"
    },
    {
        "id": "1X6V",
        "text": "Chorea due to dopamine receptor blocking agents"
    },
    {
        "id": "1X6W",
        "text": "Neuroleptic malignant syndrome"
    },
    {
        "id": "1X6X",
        "text": "Painful or painless legs and moving toes or fingers"
    },
    {
        "id": "1X6Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X6Z",
        "text": "Dystonia due to toxins"
    },
    {
        "id": "1X70",
        "text": "Hereditary essential tremor"
    },
    {
        "id": "1X71",
        "text": "X-linked dystonia parkinsonism"
    },
    {
        "id": "1X72",
        "text": "Benign paroxysmal torticollis of infancy"
    },
    {
        "id": "1X73",
        "text": "Chorea due to neuroferritinopathy"
    },
    {
        "id": "1X74",
        "text": "Primary dystonia"
    },
    {
        "id": "1X75",
        "text": "Chorea due to Huntington disease-like conditions"
    },
    {
        "id": "1X76",
        "text": "Atypical parkinsonism"
    },
    {
        "id": "1X77",
        "text": "Paroxysmal dyskinesia due to demyelinating disease"
    },
    {
        "id": "1X78",
        "text": "Hereditary hyperekplexia due to Glycine receptor subunit mutations"
    },
    {
        "id": "1X79",
        "text": "Hyperekplexia due to certain specified neuropsychiatric disorder"
    },
    {
        "id": "1X7B",
        "text": "Rapid-onset dystonia-parkinsonism"
    },
    {
        "id": "1X7C",
        "text": "Autosomal recessive ataxia with oculomotor apraxia type 2"
    },
    {
        "id": "1X7D",
        "text": "Parkinson disease LRRK2 gene mutation 12p11.23-q13.11"
    },
    {
        "id": "1X7E",
        "text": "Chorea due to antiphospholipid syndrome"
    },
    {
        "id": "1X7F",
        "text": "Friedreich ataxia"
    },
    {
        "id": "1X7H",
        "text": "Multiple system atrophy, Parkinsonism"
    },
    {
        "id": "1X7I",
        "text": "Paroxysmal kinesigenic dyskinesia"
    },
    {
        "id": "1X7J",
        "text": "Autosomal recessive spastic paraplegia type 28"
    },
    {
        "id": "1X7R",
        "text": "Somatic delusion directed at the skin"
    },
    {
        "id": "1X7S",
        "text": "Irritant contact dermatitis of specified site"
    },
    {
        "id": "1X7T",
        "text": "Irritant contact dermatitis from specified external agents"
    },
    {
        "id": "1X7U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X7V",
        "text": "Irritant contact dermatitis from specified external agents"
    },
    {
        "id": "1X7W",
        "text": "Hypersomnolence disorders"
    },
    {
        "id": "1X7X",
        "text": "Contact gingivostomatitis"
    },
    {
        "id": "1X7Y",
        "text": "Contact dermatitis of external ear"
    },
    {
        "id": "1X7Z",
        "text": "Skin disorders involving the head and neck"
    },
    {
        "id": "1X80",
        "text": "Nasopharyngeal leishmaniasis"
    },
    {
        "id": "1X81",
        "text": "Lichen simplex"
    },
    {
        "id": "1X82",
        "text": "Papulosquamous dermatoses"
    },
    {
        "id": "1X83",
        "text": "Degenerative high myopia"
    },
    {
        "id": "1S7M",
        "text": "Mycetoma of unknown or unspecified type"
    },
    {
        "id": "1X84",
        "text": "Neurofibromatoses"
    },
    {
        "id": "1X85",
        "text": "Alcohol intoxication"
    },
    {
        "id": "1X86",
        "text": "Phakomatoses or hamartoneoplastic syndromes"
    },
    {
        "id": "1X87",
        "text": "Necrobiotic granulomatous skin disorders"
    },
    {
        "id": "1X88",
        "text": "Acute effects of ultraviolet radiation on normal skin"
    },
    {
        "id": "1X89",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8A",
        "text": "Acute skin eruption of uncertain or unspecified nature"
    },
    {
        "id": "1X8C",
        "text": "Chronic urticarial skin disorder"
    },
    {
        "id": "1X8D",
        "text": "Chronic skin disorder of uncertain or unspecified nature"
    },
    {
        "id": "1X8E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8J",
        "text": "Impairment of visual field"
    },
    {
        "id": "1X8K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8L",
        "text": "Mild Impairment of Visual Field"
    },
    {
        "id": "1X8M",
        "text": "Moderate Impairment of Visual Field"
    },
    {
        "id": "1X8N",
        "text": "Severe Impairment of Visual Field"
    },
    {
        "id": "1X8O",
        "text": "Profound Impairment of Visual Field"
    },
    {
        "id": "1X8P",
        "text": "Near-total Impairment of Visual Field"
    },
    {
        "id": "1X8Q",
        "text": "Total Impairment of Visual Field"
    },
    {
        "id": "1X8R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X8Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X90",
        "text": "Concentric peripheral loss"
    },
    {
        "id": "1X91",
        "text": "Contraction of visual field"
    },
    {
        "id": "1X92",
        "text": "Depressed visual field"
    },
    {
        "id": "1X93",
        "text": "Visual field constriction"
    },
    {
        "id": "1X95",
        "text": "Trichromatism anomaly"
    },
    {
        "id": "1X96",
        "text": "No vision impairment"
    },
    {
        "id": "1X99",
        "text": "Moderate vision impairment"
    },
    {
        "id": "1X9B",
        "text": "Profound vision disability"
    },
    {
        "id": "1X9C",
        "text": "Near-total vision impairment"
    },
    {
        "id": "1X9D",
        "text": "Total vision impairment"
    },
    {
        "id": "1X9E",
        "text": "Total vision impairment"
    },
    {
        "id": "1X9F",
        "text": "Blindness"
    },
    {
        "id": "1X9G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X9H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X9I",
        "text": "Low vision"
    },
    {
        "id": "1X9J",
        "text": "Near vision impairment"
    },
    {
        "id": "1X9K",
        "text": "Near vision impairment"
    },
    {
        "id": "1X9L",
        "text": "Near vision impairment"
    },
    {
        "id": "1X9M",
        "text": "Near vision impairment"
    },
    {
        "id": "1X9N",
        "text": "Near vision impairment"
    },
    {
        "id": "1X9O",
        "text": "Near vision impairment"
    },
    {
        "id": "1X9P",
        "text": "Chronic primary musculoskeletal pain"
    },
    {
        "id": "1L28",
        "text": "Congenital hearing impairment"
    },
    {
        "id": "1X9Q",
        "text": "Focal unaware seizure"
    },
    {
        "id": "1X9R",
        "text": "Types of seizures"
    },
    {
        "id": "1X9S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X9T",
        "text": "Types of seizures"
    },
    {
        "id": "1X9U",
        "text": "Types of seizures"
    },
    {
        "id": "1X9V",
        "text": "Status epilepticus"
    },
    {
        "id": "1X9Y",
        "text": "Generalised tonic seizure"
    },
    {
        "id": "1X9Z",
        "text": "Generalised atonic seizure"
    },
    {
        "id": "1Y00",
        "text": "Sleep-wake disorders"
    },
    {
        "id": "1Y01",
        "text": "Sleep-wake disorders"
    },
    {
        "id": "1Y02",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y03",
        "text": "Sleep-related hypoventilation or hypoxemia disorders"
    },
    {
        "id": "1Y04",
        "text": "Idiopathic central alveolar hypoventilation"
    },
    {
        "id": "1Y07",
        "text": "Varicella"
    },
    {
        "id": "1Y08",
        "text": "Zoster"
    },
    {
        "id": "1Y09",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "1Y0A",
        "text": "Surfers knots or nodules"
    },
    {
        "id": "1Y0B",
        "text": "Cutaneous wounds, injuries or scars"
    },
    {
        "id": "1Y06",
        "text": "Acute urticarial skin eruption"
    },
    {
        "id": "1Y0C",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1Y0D",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "1Y0E",
        "text": "Cutaneous and subcutaneous melioidosis"
    },
    {
        "id": "1Y0F",
        "text": "Cutaneous and subcutaneous melioidosis"
    },
    {
        "id": "1Y0G",
        "text": "Certain zoonotic bacterial infections involving the skin"
    },
    {
        "id": "1Y0H",
        "text": "Certain skin disorders attributable to infection or infestation"
    },
    {
        "id": "1Y0I",
        "text": "Primary sclerosing cholangitis"
    },
    {
        "id": "1U6B",
        "text": "Chronic widespread pain"
    },
    {
        "id": "1Y0K",
        "text": "Harmful pattern of use of cocaine"
    },
    {
        "id": "1Y0L",
        "text": "Neoplasms of uncertain behaviour of skin"
    },
    {
        "id": "1Y0M",
        "text": "Burn from exposure to therapeutic ultraviolet radiation"
    },
    {
        "id": "1Y0N",
        "text": "Photoaggravated skin disease"
    },
    {
        "id": "1Y0Q",
        "text": "Benign lymphatic neoplasms"
    },
    {
        "id": "1Y0S",
        "text": "Cutaneous insect bite reactions"
    },
    {
        "id": "1Y0R",
        "text": "Animal toxin, venom, or poison"
    },
    {
        "id": "1Y0T",
        "text": "Harmful effects of or exposure to noxious substances, Substances chiefly nonmedicinal as to source, Venoms or toxins"
    },
    {
        "id": "1Y0U",
        "text": "Plague involving skin"
    },
    {
        "id": "1Y0V",
        "text": "Alcohol-induced delirium"
    },
    {
        "id": "1Y0W",
        "text": "Cannabis-induced delirium"
    },
    {
        "id": "1Y0X",
        "text": "Synthetic cannabinoid-induced delirium"
    },
    {
        "id": "1Y0Y",
        "text": "Opioid-induced delirium"
    },
    {
        "id": "1Y0Z",
        "text": "Sedative, hypnotic or anxiolytic-induced delirium"
    },
    {
        "id": "1Y10",
        "text": "Cocaine-induced delirium"
    },
    {
        "id": "1Y11",
        "text": "Stimulant-induced delirium including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y12",
        "text": "Synthetic cathinone-induced delirium"
    },
    {
        "id": "1Y13",
        "text": "Hallucinogen-induced delirium"
    },
    {
        "id": "1Y14",
        "text": "Volatile inhalant-induced delirium"
    },
    {
        "id": "1Y15",
        "text": "MDMA or related drug-induced delirium, including MDA"
    },
    {
        "id": "1Y16",
        "text": "Dissociative drug-induced delirium including ketamine or PCP"
    },
    {
        "id": "1Y17",
        "text": "Delirium induced by other specified psychoactive substance including medications"
    },
    {
        "id": "1Y18",
        "text": "Delirium induced by multiple specified psychoactive substances including medications"
    },
    {
        "id": "1Y19",
        "text": "Delirium induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y1A",
        "text": "Alcohol-induced psychotic disorder"
    },
    {
        "id": "1Y1B",
        "text": "Cannabis-induced psychotic disorder"
    },
    {
        "id": "1Y1C",
        "text": "Synthetic cannabinoid-induced psychotic disorder"
    },
    {
        "id": "1Y1D",
        "text": "Opioid-induced psychotic disorder"
    },
    {
        "id": "1Y1E",
        "text": "Sedative, hypnotic or anxiolytic-induced psychotic disorder"
    },
    {
        "id": "1Y1F",
        "text": "Cocaine-induced psychotic disorder"
    },
    {
        "id": "1Y1G",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y1H",
        "text": "Synthetic cathinone-induced psychotic disorder"
    },
    {
        "id": "1Y1I",
        "text": "Hallucinogen-induced psychotic disorder"
    },
    {
        "id": "1Y1J",
        "text": "Volatile inhalant-induced psychotic disorder"
    },
    {
        "id": "1Y1K",
        "text": "MDMA or related drug-induced psychotic disorder, including MDA"
    },
    {
        "id": "1Y1L",
        "text": "Dissociative drug-induced psychotic disorder including Ketamine or PCP"
    },
    {
        "id": "1Y1M",
        "text": "Psychotic disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y1N",
        "text": "Psychotic disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y1O",
        "text": "Psychotic disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y1P",
        "text": "Alcohol-induced mood disorder"
    },
    {
        "id": "1Y1Q",
        "text": "Cannabis-induced mood disorder"
    },
    {
        "id": "1Y1R",
        "text": "Synthetic cannabinoid-induced mood disorder"
    },
    {
        "id": "1Y1S",
        "text": "Opioid-induced mood disorder"
    },
    {
        "id": "1Y1T",
        "text": "Sedative, hypnotic or anxiolytic-induced mood disorder"
    },
    {
        "id": "1Y1U",
        "text": "Cocaine-induced mood disorder"
    },
    {
        "id": "1Y1V",
        "text": "Stimulant-induced mood disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y1W",
        "text": "Synthetic cathinone-induced mood disorder"
    },
    {
        "id": "1Y1X",
        "text": "Hallucinogen-induced mood disorder"
    },
    {
        "id": "1Y1Y",
        "text": "Volatile inhalant-induced mood disorder"
    },
    {
        "id": "1Y1Z",
        "text": "MDMA or related drug-induced mood disorder, including MDA"
    },
    {
        "id": "1Y20",
        "text": "Dissociative drug-induced mood disorder including Ketamine or PCP"
    },
    {
        "id": "1Y21",
        "text": "Mood disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y22",
        "text": "Mood disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y23",
        "text": "Mood disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y24",
        "text": "Alcohol-induced anxiety disorder"
    },
    {
        "id": "1Y25",
        "text": "Cannabis-induced anxiety disorder"
    },
    {
        "id": "1Y26",
        "text": "Synthetic cannabinoid-induced anxiety disorder"
    },
    {
        "id": "1Y27",
        "text": "Opioid-induced anxiety disorder"
    },
    {
        "id": "1Y28",
        "text": "Sedative, hypnotic or anxiolytic-induced anxiety disorder"
    },
    {
        "id": "1Y29",
        "text": "Cocaine-induced anxiety disorder"
    },
    {
        "id": "1Y2A",
        "text": "Stimulant-induced anxiety disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y2B",
        "text": "Synthetic cathinone-induced anxiety disorder"
    },
    {
        "id": "1Y2C",
        "text": "Caffeine-induced anxiety disorder"
    },
    {
        "id": "1Y2D",
        "text": "Hallucinogen-induced anxiety disorder"
    },
    {
        "id": "1Y2E",
        "text": "Volatile inhalant-induced anxiety disorder"
    },
    {
        "id": "1Y2F",
        "text": "MDMA or related drug-induced anxiety disorder"
    },
    {
        "id": "1Y2G",
        "text": "Dissociative drug-induced anxiety disorder including Ketamine or PCP"
    },
    {
        "id": "1Y2H",
        "text": "Anxiety disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y2I",
        "text": "Anxiety disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y2J",
        "text": "Anxiety disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y2K",
        "text": "Cocaine-induced impulse control disorder"
    },
    {
        "id": "1Y2L",
        "text": "Synthetic cathinone-induced impulse control disorder"
    },
    {
        "id": "1Y2M",
        "text": "Synthetic cathinone-induced impulse control disorder"
    },
    {
        "id": "1Y2N",
        "text": "Impulse control disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y2O",
        "text": "Impulse control syndrome induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y2P",
        "text": "Impulse control disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y2Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y2R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y2S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y2T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y2U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y2V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y2W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y2X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y2Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y2Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y30",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y31",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y32",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y33",
        "text": "Harmful pattern of use of alcohol"
    },
    {
        "id": "1Y34",
        "text": "Harmful pattern of use of cannabis"
    },
    {
        "id": "1Y35",
        "text": "Harmful pattern of use of synthetic cannabinoids"
    },
    {
        "id": "1Y36",
        "text": "Harmful pattern of use of opioids"
    },
    {
        "id": "1Y37",
        "text": "Harmful pattern of use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1Y38",
        "text": "Harmful pattern of use of cocaine"
    },
    {
        "id": "1Y39",
        "text": "Harmful pattern of use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y3A",
        "text": "Harmful pattern of use of synthetic cathinones"
    },
    {
        "id": "1Y3B",
        "text": "Harmful pattern of use of caffeine"
    },
    {
        "id": "1Y3C",
        "text": "Harmful pattern of use of hallucinogens"
    },
    {
        "id": "1Y3D",
        "text": "Harmful pattern of use of nicotine"
    },
    {
        "id": "1Y3E",
        "text": "Harmful pattern of use of volatile inhalants"
    },
    {
        "id": "1Y3F",
        "text": "Harmful pattern of use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1Y3G",
        "text": "Harmful pattern of use of dissociative drugs, including ketamine or PCP"
    },
    {
        "id": "1Y3H",
        "text": "Harmful pattern of use of other specified psychoactive substance"
    },
    {
        "id": "1Y3I",
        "text": "Harmful pattern of use of multiple specified psychoactive substances"
    },
    {
        "id": "1Y3J",
        "text": "Harmful pattern of use of unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y3K",
        "text": "Harmful pattern of use of non-psychoactive substances"
    },
    {
        "id": "1Y3L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y3M",
        "text": "Male erectile dysfunction"
    },
    {
        "id": "1Y3N",
        "text": "Sexual pain disorders"
    },
    {
        "id": "1Y3O",
        "text": "Cocaine-induced obsessive-compulsive or related disorder"
    },
    {
        "id": "1Y3P",
        "text": "Stimulant-induced obsessive-compulsive or related disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y3Q",
        "text": "Synthetic cathinone-induced obsessive-compulsive or related syndrome"
    },
    {
        "id": "1Y3R",
        "text": "Obsessive-compulsive or related disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y3S",
        "text": "Obsessive-compulsive or related disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y3T",
        "text": "Obsessive-compulsive or related disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y3U",
        "text": "Amnestic disorder due to use of alcohol"
    },
    {
        "id": "1Y3V",
        "text": "Amnestic disorder due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1Y3W",
        "text": "Amnestic disorder due to other specified psychoactive substance including medications"
    },
    {
        "id": "1Y3X",
        "text": "Sexual arousal dysfunctions"
    },
    {
        "id": "1Y3Y",
        "text": "Ejaculatory dysfunctions"
    },
    {
        "id": "1Y3Z",
        "text": "Disorders of amino acid absorption or transport"
    },
    {
        "id": "1Y40",
        "text": "Cerebral palsy"
    },
    {
        "id": "1Y41",
        "text": "Benign neoplasm of middle ear or respiratory system"
    },
    {
        "id": "1Y42",
        "text": "Withdrawal due to unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y43",
        "text": "Withdrawal due to unknown or unspecified psychoactive substance, uncomplicated"
    },
    {
        "id": "1Y44",
        "text": "Withdrawal due to unknown or unspecified psychoactive substance, with perceptual disturbances"
    },
    {
        "id": "1Y45",
        "text": "Withdrawal due to unknown or unspecified psychoactive substance, with seizures"
    },
    {
        "id": "1Y46",
        "text": "Withdrawal due to unknown or unspecified psychoactive, with perceptual disturbances and seizures"
    },
    {
        "id": "1Y47",
        "text": "Lipoatrophy or lipodystrophy"
    },
    {
        "id": "1Y48",
        "text": "Holoprosencephaly"
    },
    {
        "id": "1Y4D",
        "text": "Disorders of consciousness"
    },
    {
        "id": "1Y4E",
        "text": "Genetic lipodystrophy"
    },
    {
        "id": "1Y4F",
        "text": "Chronic primary pelvic pain syndrome"
    },
    {
        "id": "1Y4G",
        "text": "Chronic painful radiation-induced neuropathy"
    },
    {
        "id": "1Y4H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y4I",
        "text": "Acute whiplash injury associated with pain disorders"
    },
    {
        "id": "1Y4J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y4K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y49",
        "text": "Burning mouth syndrome"
    },
    {
        "id": "1W3P",
        "text": "Chronic primary headache or orofacial pain"
    },
    {
        "id": "1W9A",
        "text": "Skin of penis"
    },
    {
        "id": "1Y4L",
        "text": "Ciliary dyskinesia"
    },
    {
        "id": "1U3G",
        "text": "Gender incongruence"
    },
    {
        "id": "1Y4A",
        "text": "Chronic primary chest pain syndrome"
    },
    {
        "id": "1Y4B",
        "text": "Chronic primary epigastric pain syndrome"
    },
    {
        "id": "1W3Q",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "1Y4C",
        "text": "Chronic primary bladder pain syndrome"
    },
    {
        "id": "1Y4N",
        "text": "Chronic primary pelvic pain syndrome"
    },
    {
        "id": "1Y4O",
        "text": "Chronic painful polyneuropathy"
    },
    {
        "id": "1Y4P",
        "text": "Chronic painful radiculopathy"
    },
    {
        "id": "1Y4Q",
        "text": "Chronic central neuropathic pain associated with spinal cord injury"
    },
    {
        "id": "1Y4R",
        "text": "Chronic central neuropathic pain associated with brain injury"
    },
    {
        "id": "1Y4S",
        "text": "Chronic central post stroke pain"
    },
    {
        "id": "1Y4T",
        "text": "Chronic central neuropathic pain associated with multiple sclerosis"
    },
    {
        "id": "1U68",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1Y4U",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1Y4V",
        "text": "Headache or orofacial pain associated with chronic secondary temporomandibular disorders"
    },
    {
        "id": "1Y4W",
        "text": "Ebola disease"
    },
    {
        "id": "1Y4X",
        "text": "Dermatophyte fungi"
    },
    {
        "id": "1Y4Y",
        "text": "Dermatophytosis"
    },
    {
        "id": "1Y4Z",
        "text": "Dermatophyte fungi"
    },
    {
        "id": "1Y51",
        "text": "Marburg disease"
    },
    {
        "id": "1Y52",
        "text": "Ebola disease"
    },
    {
        "id": "1Y53",
        "text": "Chronic primary orofacial pain"
    },
    {
        "id": "1Y54",
        "text": "Chronic dental pain"
    },
    {
        "id": "1Y55",
        "text": "Dermatophytosis"
    },
    {
        "id": "1Y56",
        "text": "Infectious diseases of external ear"
    },
    {
        "id": "1Y57",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y59",
        "text": "Diseases of external ear"
    },
    {
        "id": "1Y5A",
        "text": "Cocaine-induced delirium"
    },
    {
        "id": "1Y5B",
        "text": "Cocaine-induced anxiety disorder"
    },
    {
        "id": "1Y5C",
        "text": "Cocaine-induced obsessive-compulsive or related disorder"
    },
    {
        "id": "1Y5D",
        "text": "Cocaine-induced impulse control disorder"
    },
    {
        "id": "1Y5E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y5F",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y5G",
        "text": "Stimulant-induced anxiety disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y5H",
        "text": "Stimulant-induced obsessive-compulsive or related disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y5I",
        "text": "Stimulant-induced impulse control disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y5J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y5K",
        "text": "Synthetic cathinone-induced mood disorder with depressive symptoms"
    },
    {
        "id": "1Y5L",
        "text": "Synthetic cathinone-induced mood disorder with manic symptoms"
    },
    {
        "id": "1Y5M",
        "text": "Synthetic cathinone-induced mood disorder with mixed depressive and manic symptoms"
    },
    {
        "id": "1Y5N",
        "text": "Synthetic cathinone-induced anxiety disorder"
    },
    {
        "id": "1Y5O",
        "text": "Synthetic cathinone-induced obsessive-compulsive or related syndrome"
    },
    {
        "id": "1Y5P",
        "text": "Synthetic cathinone-induced impulse control disorder"
    },
    {
        "id": "1Y5Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y5R",
        "text": "Caffeine-induced anxiety disorder"
    },
    {
        "id": "1Y5S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y5U",
        "text": "Hallucinogen intoxication"
    },
    {
        "id": "1Y5V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y5W",
        "text": "MDMA or related drug-induced mood disorder, with depressive symptoms"
    },
    {
        "id": "1Y5X",
        "text": "MDMA or related drug-induced mood disorder, with manic symptoms"
    },
    {
        "id": "1Y5Y",
        "text": "MDMA or related drug-induced mood disorder, with mixed depressive and manic symptoms"
    },
    {
        "id": "1Y5Z",
        "text": "MDMA or related drug-induced anxiety disorder"
    },
    {
        "id": "1Y60",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1Y61",
        "text": "Anxiety disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y62",
        "text": "Obsessive-compulsive or related disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y63",
        "text": "Impulse control disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y64",
        "text": "Disorders due to use of multiple specified psychoactive substances, including medications"
    },
    {
        "id": "1Y65",
        "text": "Delirium induced by multiple specified psychoactive substances including medications"
    },
    {
        "id": "1Y66",
        "text": "Anxiety disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y67",
        "text": "Obsessive-compulsive or related disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y68",
        "text": "Impulse control syndrome induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y69",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6A",
        "text": "Anxiety disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y6B",
        "text": "Obsessive-compulsive or related disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y6C",
        "text": "Impulse control disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y6D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y6R",
        "text": "Skin disorders involving the head and neck"
    },
    {
        "id": "1Y6T",
        "text": "Personal history of health problems"
    },
    {
        "id": "1Y6U",
        "text": "Dermatoses which may presage cutaneous lymphoma"
    },
    {
        "id": "1Y6V",
        "text": "Primary cutaneous plasmacytosis"
    },
    {
        "id": "1T7L",
        "text": "Primary cutaneous plasmacytosis"
    },
    {
        "id": "1Y6W",
        "text": "Disorders of the nail or perionychium"
    },
    {
        "id": "1Y6X",
        "text": "Alcohol withdrawal, uncomplicated"
    },
    {
        "id": "1Y6Y",
        "text": "Alcohol withdrawal with perceptual disturbances"
    },
    {
        "id": "1Y6Z",
        "text": "Alcohol withdrawal with seizures"
    },
    {
        "id": "1Y70",
        "text": "Alcohol withdrawal with perceptual disturbances and seizures"
    },
    {
        "id": "1Y71",
        "text": "Sedative, hypnotic or anxiolytic withdrawal, uncomplicated"
    },
    {
        "id": "1Y72",
        "text": "Sedative, hypnotic or anxiolytic withdrawal, with perceptual disturbances"
    },
    {
        "id": "1Y73",
        "text": "Sedative, hypnotic or anxiolytic withdrawal, with seizures"
    },
    {
        "id": "1Y74",
        "text": "Sedative, hypnotic or anxiolytic withdrawal, with perceptual disturbances and seizures"
    },
    {
        "id": "1Y75",
        "text": "Other specified psychoactive substance withdrawal, uncomplicated"
    },
    {
        "id": "1Y76",
        "text": "Other specified psychoactive substance withdrawal, with perceptual disturbances"
    },
    {
        "id": "1Y77",
        "text": "Other specified psychoactive substance withdrawal, with seizures"
    },
    {
        "id": "1Y78",
        "text": "Other specified psychoactive substance withdrawal, with perceptual disturbances and seizures"
    },
    {
        "id": "1Y79",
        "text": "Anaemia due to chronic disease"
    },
    {
        "id": "1Y7G",
        "text": "Abortive outcome of pregnancy"
    },
    {
        "id": "1Y7H",
        "text": "Abortion"
    },
    {
        "id": "1Y7E",
        "text": "Chronic kidney disease"
    },
    {
        "id": "1Y7F",
        "text": "Diabetic nephropathy"
    },
    {
        "id": "1Y7I",
        "text": "Dermatophytosis of scalp"
    },
    {
        "id": "1Y7J",
        "text": "Dermatophytosis of nail"
    },
    {
        "id": "1Y7K",
        "text": "Dermatophytosis"
    },
    {
        "id": "1Y7L",
        "text": "Systemic or invasive candidosis"
    },
    {
        "id": "1Y7M",
        "text": "Neoplasms of brain or central nervous system"
    },
    {
        "id": "1Y7N",
        "text": "Hallucinogen-induced mood disorder, with manic symptoms"
    },
    {
        "id": "1Y7O",
        "text": "Hallucinogen-induced mood disorder, with depressive symptoms"
    },
    {
        "id": "1Y7P",
        "text": "Volatile inhalant-induced mood disorder, with manic symptoms"
    },
    {
        "id": "1Y7Q",
        "text": "Volatile inhalant-induced mood disorder, with depressive symptoms"
    },
    {
        "id": "1Y7R",
        "text": "Supplementary Chapter Traditional Medicine Conditions - Module I"
    },
    {
        "id": "1Y7S",
        "text": "Intestinal fungal infections"
    },
    {
        "id": "1Y7T",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "1Y7V",
        "text": "Spontaneous abortion, incomplete, with other or unspecified complications"
    },
    {
        "id": "1Y7W",
        "text": "Spontaneous abortion, complete or unspecified, complicated by genital tract or pelvic infection"
    },
    {
        "id": "1Y7X",
        "text": "Spontaneous abortion, complete or unspecified, with other or unspecified complications"
    },
    {
        "id": "1Y7Y",
        "text": "Spontaneous abortion, complete or unspecified, without complication"
    },
    {
        "id": "1Y7Z",
        "text": "Induced abortion, incomplete, complicated by genital tract or pelvic infection"
    },
    {
        "id": "1Y80",
        "text": "Induced abortion, incomplete, complicated by delayed or excessive haemorrhage"
    },
    {
        "id": "1Y81",
        "text": "Induced abortion, incomplete, complicated by embolism"
    },
    {
        "id": "1Y82",
        "text": "Induced abortion, incomplete, with other or unspecified complications"
    },
    {
        "id": "1Y83",
        "text": "Induced abortion, incomplete, without complication"
    },
    {
        "id": "1Y84",
        "text": "Induced abortion, complete or unspecified, complicated by genital tract or pelvic infection"
    },
    {
        "id": "1Y85",
        "text": "Unspecified abortion, incomplete, complicated by genital tract or pelvic infection"
    },
    {
        "id": "1Y86",
        "text": "Unspecified abortion, incomplete, complicated by delayed or excessive haemorrhage"
    },
    {
        "id": "1Y87",
        "text": "Unspecified abortion, incomplete, complicated by embolism"
    },
    {
        "id": "1Y88",
        "text": "Unspecified abortion, incomplete, with other or unspecified complications"
    },
    {
        "id": "1Y89",
        "text": "Unspecified abortion, incomplete, without complication"
    },
    {
        "id": "1Y8A",
        "text": "Unspecified abortion, complete or unspecified, complicated by genital tract or pelvic infection"
    },
    {
        "id": "1Y8B",
        "text": "Unspecified abortion, complete or unspecified, complicated by delayed or excessive haemorrhage"
    },
    {
        "id": "1Y8C",
        "text": "Unspecified abortion, complete or unspecified, complicated by embolism"
    },
    {
        "id": "1Y8D",
        "text": "Unspecified abortion, complete or unspecified, with other or unspecified complications"
    },
    {
        "id": "1Y8E",
        "text": "Unspecified abortion, complete or unspecified, without complication"
    },
    {
        "id": "1Y8F",
        "text": "Other or unspecified failed attempted abortion, complicated by genital tract or pelvic infection"
    },
    {
        "id": "1Y8G",
        "text": "Other or unspecified failed attempted abortion, complicated by delayed or excessive haemorrhage"
    },
    {
        "id": "1Y8H",
        "text": "Other or unspecified failed attempted abortion, complicated by embolism"
    },
    {
        "id": "1Y8I",
        "text": "Other or unspecified failed attempted abortion, with other or unspecified complications"
    },
    {
        "id": "1Y8J",
        "text": "Other or unspecified failed attempted abortion, without complication"
    },
    {
        "id": "1Y8K",
        "text": "Cutaneous amoebiasis"
    },
    {
        "id": "1Y8L",
        "text": "Gestational proteinuria without hypertension"
    },
    {
        "id": "1Y8M",
        "text": "Gestational oedema without hypertension"
    },
    {
        "id": "1Y8N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y8P",
        "text": "Corticotropin"
    },
    {
        "id": "1Y8Q",
        "text": "Adrenocorticotrophic hormone"
    },
    {
        "id": "1Y8R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y8U",
        "text": "Caffeine withdrawal"
    },
    {
        "id": "1Y8V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Y8W",
        "text": "Traumatic subdural haemorrhage"
    },
    {
        "id": "1Y90",
        "text": "Nontraumatic subdural haemorrhage"
    },
    {
        "id": "1Y8Y",
        "text": "Chronic traumatic subdural haemorrhage"
    },
    {
        "id": "1Y91",
        "text": "Nontraumatic subdural haemorrhage"
    },
    {
        "id": "1Y8X",
        "text": "Nontraumatic subdural haemorrhage"
    },
    {
        "id": "1Y92",
        "text": "Disorders due to use of alcohol"
    },
    {
        "id": "1Y93",
        "text": "Disorders due to use of cannabis"
    },
    {
        "id": "1Y94",
        "text": "Disorders due to use of synthetic cannabinoids"
    },
    {
        "id": "1Y95",
        "text": "Disorders due to use of opioids"
    },
    {
        "id": "1Y96",
        "text": "Disorders due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1Y97",
        "text": "Disorders due to use of cocaine"
    },
    {
        "id": "1Y98",
        "text": "Disorders due to use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Y99",
        "text": "Disorders due to use of synthetic cathinones"
    },
    {
        "id": "1Y9A",
        "text": "Disorders due to use of caffeine"
    },
    {
        "id": "1Y9B",
        "text": "Disorders due to use of hallucinogens"
    },
    {
        "id": "1Y9C",
        "text": "Disorders due to use of nicotine"
    },
    {
        "id": "1Y9D",
        "text": "Disorders due to use of volatile inhalants"
    },
    {
        "id": "1Y9E",
        "text": "Disorders due to use of MDMA or related drugs, including MDA"
    },
    {
        "id": "1Y9F",
        "text": "Disorders due to use of dissociative drugs including ketamine and phencyclidine [PCP]"
    },
    {
        "id": "1Y9G",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "1Y9H",
        "text": "Disorders due to use of multiple specified psychoactive substances, including medications"
    },
    {
        "id": "1Y9I",
        "text": "Disorders due to use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "1Y9J",
        "text": "Disorders due to use of non-psychoactive substances"
    },
    {
        "id": "1Y8Z",
        "text": "Acute traumatic subdural haemorrhage"
    },
    {
        "id": "1Y9K",
        "text": "Mood disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y9L",
        "text": "Mood disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y9M",
        "text": "Mood disorder induced by other specified psychoactive substance"
    },
    {
        "id": "1Y9N",
        "text": "Mood disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y9O",
        "text": "Mood disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y9P",
        "text": "Mood disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "1Y9Q",
        "text": "Mood disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y9R",
        "text": "Mood disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1Y9S",
        "text": "Mood disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "1S4M",
        "text": "Acne and related disorders"
    },
    {
        "id": "1S4N",
        "text": "Acne and related disorders"
    },
    {
        "id": "1Y9U",
        "text": "Pyuria"
    },
    {
        "id": "1Y9V",
        "text": "Acne"
    },
    {
        "id": "1Y9W",
        "text": "Comedonal acne"
    },
    {
        "id": "1Y9X",
        "text": "Superficial mixed comedonal and papulopustular acne"
    },
    {
        "id": "1Y9Y",
        "text": "Papulopustular acne"
    },
    {
        "id": "1Z00",
        "text": "Severe inflammatory acne"
    },
    {
        "id": "1Z01",
        "text": "Acne fulminans"
    },
    {
        "id": "1Y9Z",
        "text": "Nodular acne"
    },
    {
        "id": "1Z02",
        "text": "Acne conglobata"
    },
    {
        "id": "1Z03",
        "text": "Acne scarring"
    },
    {
        "id": "1Z04",
        "text": "Acneform inflammatory disorders"
    },
    {
        "id": "1Z05",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z07",
        "text": "Acneform inflammatory disorders"
    },
    {
        "id": "1Z09",
        "text": "Infections due to non-tuberculous mycobacteria"
    },
    {
        "id": "1Z08",
        "text": "Infections due to non-tuberculous mycobacteria"
    },
    {
        "id": "1Z0A",
        "text": "Bacterial cellulitis, erysipelas or lymphangitis"
    },
    {
        "id": "1Z0B",
        "text": "Impulse control disorders"
    },
    {
        "id": "1Z0C",
        "text": "Symptoms or signs involving motivation or energy"
    },
    {
        "id": "1Z0D",
        "text": "Symptoms or signs involving motivation or energy"
    },
    {
        "id": "1Z0E",
        "text": "Symptoms or signs involving motivation or energy"
    },
    {
        "id": "1Z0F",
        "text": "Symptoms or signs involving mood or affect"
    },
    {
        "id": "1Z0G",
        "text": "Disorder of intellectual development, provisional"
    },
    {
        "id": "1Z0H",
        "text": "Developmental language disorder with impairment of mainly pragmatic language"
    },
    {
        "id": "1Z0I",
        "text": "Developmental learning disorder with impairment in reading"
    },
    {
        "id": "1Z0J",
        "text": "Developmental learning disorder with impairment in written expression"
    },
    {
        "id": "1Z0K",
        "text": "Developmental learning disorder with impairment in mathematics"
    },
    {
        "id": "1Z0L",
        "text": "Developmental learning disorder with other specified impairment of learning"
    },
    {
        "id": "1Z0M",
        "text": "Developmental motor coordination disorder"
    },
    {
        "id": "1Z0N",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1Z0O",
        "text": "Schizophrenia"
    },
    {
        "id": "1Z0P",
        "text": "Schizoaffective disorder"
    },
    {
        "id": "1Z0Q",
        "text": "Acute and transient psychotic disorder"
    },
    {
        "id": "1Z0R",
        "text": "Schizotypal disorder"
    },
    {
        "id": "1Z0S",
        "text": "Delusional disorder"
    },
    {
        "id": "1Z0T",
        "text": "Delusional disorder, in partial remission"
    },
    {
        "id": "1Z0U",
        "text": "Delusional disorder, in full remission"
    },
    {
        "id": "1Z0V",
        "text": "Negative symptoms in primary psychotic disorders"
    },
    {
        "id": "1Z0W",
        "text": "Tympanic membrane"
    },
    {
        "id": "1Z0Y",
        "text": "Nasal turbinate"
    },
    {
        "id": "1Z12",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z13",
        "text": "Mouth"
    },
    {
        "id": "1Z14",
        "text": "Nontraumatic subdural haemorrhage"
    },
    {
        "id": "1Z15",
        "text": "Neonatal dermatoses due to maternal antibodies"
    },
    {
        "id": "1Z17",
        "text": "Lower eyelid margin"
    },
    {
        "id": "1Z18",
        "text": "Upper eyelid margin"
    },
    {
        "id": "1Z19",
        "text": "Eye"
    },
    {
        "id": "1Z1A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z1B",
        "text": "Surface topography"
    },
    {
        "id": "1Z1C",
        "text": "Fetal or neonatal alloimmune thrombocytopenia"
    },
    {
        "id": "1Z1D",
        "text": "Bipolar type I disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "1Z1E",
        "text": "Bipolar type I disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "1Z1H",
        "text": "Single episode depressive disorder, severe, without psychotic symptoms"
    },
    {
        "id": "1Z1I",
        "text": "Single episode depressive disorder, severe, with psychotic symptoms"
    },
    {
        "id": "1Z1G",
        "text": "Bipolar type II disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "1Z1F",
        "text": "Bipolar type II disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "1Z1J",
        "text": "Recurrent depressive disorder, current episode severe, without psychotic symptoms"
    },
    {
        "id": "1Z1K",
        "text": "Recurrent depressive disorder, current episode severe, with psychotic symptoms"
    },
    {
        "id": "1Z1L",
        "text": "Recurrent depressive disorder, currently in partial remission"
    },
    {
        "id": "1Z1M",
        "text": "Dysthymic disorder"
    },
    {
        "id": "1Z1N",
        "text": "Bipolar type I disorder, current episode hypomanic"
    },
    {
        "id": "1Z1O",
        "text": "Bipolar type II disorder, current episode hypomanic"
    },
    {
        "id": "1Z1Q",
        "text": "Bipolar type I disorder, current episode mixed, with psychotic symptoms"
    },
    {
        "id": "1Z1P",
        "text": "Bipolar type I disorder, current episode mixed, without psychotic symptoms"
    },
    {
        "id": "1Z1R",
        "text": "Anxiety or fear-related disorders"
    },
    {
        "id": "1Z1S",
        "text": "Generalised anxiety disorder"
    },
    {
        "id": "1Z1T",
        "text": "Panic disorder"
    },
    {
        "id": "1Z1U",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "1Z1V",
        "text": "Intermittent explosive disorder"
    },
    {
        "id": "1Z1W",
        "text": "Oppositional defiant disorder with chronic irritability-anger"
    },
    {
        "id": "1Z1X",
        "text": "Oppositional defiant disorder with chronic irritability-anger with limited prosocial emotions"
    },
    {
        "id": "1Z1Y",
        "text": "Oppositional defiant disorder without chronic irritability-anger with limited prosocial emotions"
    },
    {
        "id": "1Z1Z",
        "text": "Conduct-dissocial disorder"
    },
    {
        "id": "1Z21",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1Z22",
        "text": "Dissociative neurological symptom disorder, with non-epileptic seizures"
    },
    {
        "id": "1Z23",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1W9H",
        "text": "Skin"
    },
    {
        "id": "1Z26",
        "text": "Sleep-wake disorders"
    },
    {
        "id": "1Z27",
        "text": "Alcohol dependence, current use, episodic"
    },
    {
        "id": "1Z28",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Z29",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Z2A",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Z2B",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Z2C",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Z2D",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Z2E",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Z2F",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "1Z2G",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "1Z2H",
        "text": "Mood disorders"
    },
    {
        "id": "1Z2I",
        "text": "Personality disorders and related traits"
    },
    {
        "id": "1Z2J",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "1Z2K",
        "text": "Cannabis dependence, current use"
    },
    {
        "id": "1Z2L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z2M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z2O",
        "text": "Crystalline lens"
    },
    {
        "id": "1Z2P",
        "text": "Ciliary body"
    },
    {
        "id": "1Z2Q",
        "text": "Eyeball"
    },
    {
        "id": "1Z31",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z2Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z2T",
        "text": "Dissociative neurological symptom disorder, with non-epileptic seizures"
    },
    {
        "id": "1Z2U",
        "text": "Dissociative neurological symptom disorder, with paresis or weakness"
    },
    {
        "id": "1Z2V",
        "text": "Dissociative neurological symptom disorder, with other sensory disturbance"
    },
    {
        "id": "1Z2W",
        "text": "Dissociative neurological symptom disorder, with gait disturbance"
    },
    {
        "id": "1Z30",
        "text": "Dissociative neurological symptom disorder, with visual disturbance"
    },
    {
        "id": "1Z32",
        "text": "Dissociative neurological symptom disorder, with auditory disturbance"
    },
    {
        "id": "1Z3B",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1Z33",
        "text": "Dissociative neurological symptom disorder, with vertigo or dizziness"
    },
    {
        "id": "1Z34",
        "text": "Dissociative neurological symptom disorder, with speech disturbance"
    },
    {
        "id": "1Z2X",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1Z35",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1Z36",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1Z37",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1Z38",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1Z39",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1Z3A",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1Z3C",
        "text": "Dissociative neurological symptom disorder, with cognitive symptoms"
    },
    {
        "id": "1Z3D",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "1Z3E",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "1Z3G",
        "text": "External upper lip"
    },
    {
        "id": "1Z3F",
        "text": "External lower lip"
    },
    {
        "id": "1Z3H",
        "text": "External lip"
    },
    {
        "id": "1Z3I",
        "text": "Alveololingual sulcus"
    },
    {
        "id": "1Z3J",
        "text": "Tongue"
    },
    {
        "id": "1Z3K",
        "text": "Hypoactive sexual desire dysfunction"
    },
    {
        "id": "1Z3L",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1Z3N",
        "text": "Sexual arousal dysfunctions"
    },
    {
        "id": "1Z3Y",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "1Z3X",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "1Z3W",
        "text": "Sexual pain-penetration disorder"
    },
    {
        "id": "1Z3V",
        "text": "Sexual pain disorders"
    },
    {
        "id": "1Z3U",
        "text": "Male delayed ejaculation"
    },
    {
        "id": "1Z3T",
        "text": "Male early ejaculation"
    },
    {
        "id": "1Z3S",
        "text": "Ejaculatory dysfunctions"
    },
    {
        "id": "1Z3R",
        "text": "Anorgasmia"
    },
    {
        "id": "1Z3Q",
        "text": "Orgasmic dysfunctions"
    },
    {
        "id": "1Z3P",
        "text": "Male erectile dysfunction"
    },
    {
        "id": "1Z3M",
        "text": "Hypoactive sexual desire dysfunction"
    },
    {
        "id": "1Z3O",
        "text": "Female sexual arousal dysfunction"
    },
    {
        "id": "1Z3Z",
        "text": "Primary tics or tic disorders"
    },
    {
        "id": "1Z40",
        "text": "Tourette syndrome"
    },
    {
        "id": "1Z41",
        "text": "Scalp margin"
    },
    {
        "id": "1Z42",
        "text": "Face"
    },
    {
        "id": "1W6R",
        "text": "Temple"
    },
    {
        "id": "1Z45",
        "text": "Ala nasi"
    },
    {
        "id": "1Z46",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z48",
        "text": "Nostril"
    },
    {
        "id": "1Z49",
        "text": "Fright seizure disorder (TM1)"
    },
    {
        "id": "1Z4A",
        "text": "Alzheimer disease dementia, mixed type, with cerebrovascular disease"
    },
    {
        "id": "1Z4B",
        "text": "Alzheimer disease dementia, mixed type, with other nonvascular aetiologies"
    },
    {
        "id": "1Z4C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z4D",
        "text": "Dementia due to psychoactive substances including medications"
    },
    {
        "id": "1Z4E",
        "text": "Certain specified stimulant-induced mental or behavioural disorders including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "1Z4G",
        "text": "Auditory vestibule"
    },
    {
        "id": "1Z4I",
        "text": "Internal auditory vein"
    },
    {
        "id": "1Z4H",
        "text": "Connective and other soft tissues of middle ear"
    },
    {
        "id": "1Z4K",
        "text": "Genitourinary system"
    },
    {
        "id": "1Z4M",
        "text": "Urinary system"
    },
    {
        "id": "1Z4N",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "1Z4O",
        "text": "Delirium induced by multiple specified psychoactive substances including medications"
    },
    {
        "id": "1Z4P",
        "text": "Delirium due to psychoactive substances including medications"
    },
    {
        "id": "1Z4Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z4R",
        "text": "Overweight or obesity"
    },
    {
        "id": "1Z4S",
        "text": "Skin of trunk"
    },
    {
        "id": "1Z4U",
        "text": "Functional anatomy"
    },
    {
        "id": "1Z4V",
        "text": "Organs of the Pelvis"
    },
    {
        "id": "1Z4W",
        "text": "Organs of the Abdomen"
    },
    {
        "id": "1Z4X",
        "text": "Organs of the Thorax"
    },
    {
        "id": "1Z4Y",
        "text": "Glands of the Head and Neck"
    },
    {
        "id": "1Z4Z",
        "text": "Organs of the Head and Neck"
    },
    {
        "id": "1Z4J",
        "text": "Body Organ"
    },
    {
        "id": "1Z50",
        "text": "Walls in the Body"
    },
    {
        "id": "1Z4F",
        "text": "Certain specified hallucinogen-induced mental or behavioural disorders"
    },
    {
        "id": "1Z51",
        "text": "Dissociative disorders"
    },
    {
        "id": "1Z52",
        "text": "Joints and ligaments of the thorax"
    },
    {
        "id": "1Z54",
        "text": "Behavioural or psychological disturbances in dementia"
    },
    {
        "id": "1Z53",
        "text": "Joints of the hand"
    },
    {
        "id": "1Z56",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z55",
        "text": "Radiocarpal joint"
    },
    {
        "id": "1Z58",
        "text": "Interosseous membrane of forearm"
    },
    {
        "id": "1Z59",
        "text": "Bones of the thorax"
    },
    {
        "id": "1Z5A",
        "text": "Rib"
    },
    {
        "id": "1Z5C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5D",
        "text": "Condyle of the humerus"
    },
    {
        "id": "1Z5F",
        "text": "Femoral epiphysis"
    },
    {
        "id": "1Z5G",
        "text": "Pertrochanter"
    },
    {
        "id": "1Z5H",
        "text": "Subtrochanteric line of femur"
    },
    {
        "id": "1Z5I",
        "text": "Tibial condyle"
    },
    {
        "id": "1Z5J",
        "text": "Bone of ankle"
    },
    {
        "id": "1Z5K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5U",
        "text": "Amnestic disorder due to other specified psychoactive substance including medications"
    },
    {
        "id": "1Z5W",
        "text": "Histopathology"
    },
    {
        "id": "1Z5X",
        "text": "Personality disorder"
    },
    {
        "id": "1Z5Y",
        "text": "Mild personality disorder"
    },
    {
        "id": "1Z5Z",
        "text": "Moderate personality disorder"
    },
    {
        "id": "1Z60",
        "text": "Severe personality disorder"
    },
    {
        "id": "1Z61",
        "text": "Prominent personality traits or patterns"
    },
    {
        "id": "1Z62",
        "text": "Negative affectivity in personality disorder or personality difficulty"
    },
    {
        "id": "1Z63",
        "text": "Disinhibition in personality disorder or personality difficulty"
    },
    {
        "id": "1Z64",
        "text": "Detachment in personality disorder or personality difficulty"
    },
    {
        "id": "1Z66",
        "text": "Dissociality in personality disorder or personality difficulty"
    },
    {
        "id": "1Z67",
        "text": "Anankastia in personality disorder or personality difficulty"
    },
    {
        "id": "1Z68",
        "text": "Borderline pattern"
    },
    {
        "id": "1Z69",
        "text": "Prominent personality traits or patterns"
    },
    {
        "id": "1Z6A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z6P",
        "text": "Dementia due to Alzheimer disease"
    },
    {
        "id": "1Z6Q",
        "text": "Dementia due to Alzheimer disease with early onset"
    },
    {
        "id": "1Z6R",
        "text": "Dementia due to Alzheimer disease with late onset"
    },
    {
        "id": "1Z6S",
        "text": "Alzheimer disease dementia, mixed type, with cerebrovascular disease"
    },
    {
        "id": "1Z6T",
        "text": "Dementia due to cerebrovascular disease"
    },
    {
        "id": "1Z6V",
        "text": "Frontotemporal dementia"
    },
    {
        "id": "1Z6U",
        "text": "Dementia due to Lewy body disease"
    },
    {
        "id": "1Z6W",
        "text": "Dementia due to psychoactive substances including medications"
    },
    {
        "id": "1Z6X",
        "text": "Dementia due to use of alcohol"
    },
    {
        "id": "1Z6Y",
        "text": "Dementia due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "1Z6Z",
        "text": "Dementia due to use of volatile inhalants"
    },
    {
        "id": "1Z70",
        "text": "Dementia due to Parkinson disease"
    },
    {
        "id": "1Z71",
        "text": "Dementia due to Huntington disease"
    },
    {
        "id": "1Z72",
        "text": "Dementia due to exposure to heavy metals and other toxins"
    },
    {
        "id": "1Z73",
        "text": "Dementia due to human immunodeficiency virus"
    },
    {
        "id": "1Z74",
        "text": "Dementia due to multiple sclerosis"
    },
    {
        "id": "1Z75",
        "text": "Dementia due to prion disease"
    },
    {
        "id": "1Z76",
        "text": "Dementia due to normal pressure hydrocephalus"
    },
    {
        "id": "1Z77",
        "text": "Dementia due to injury to the head"
    },
    {
        "id": "1Z78",
        "text": "Dementia due to pellagra"
    },
    {
        "id": "1Z79",
        "text": "Dementia due to Down syndrome"
    },
    {
        "id": "1Z7A",
        "text": "Developmental speech or language disorders"
    },
    {
        "id": "1Z7B",
        "text": "Developmental speech sound disorder"
    },
    {
        "id": "1Z7C",
        "text": "Primary tics or tic disorders"
    },
    {
        "id": "1Z7D",
        "text": "Tic disorders"
    },
    {
        "id": "1Z7E",
        "text": "Delusional disorder"
    },
    {
        "id": "1Z7F",
        "text": "Symptomatic manifestations of primary psychotic disorders"
    },
    {
        "id": "1Z7G",
        "text": "Psychomotor symptoms in primary psychotic disorders"
    },
    {
        "id": "1Z7H",
        "text": "Hoarding disorder with fair to good insight"
    },
    {
        "id": "1Z7I",
        "text": "Hoarding disorder with poor to absent insight"
    },
    {
        "id": "1Z7J",
        "text": "Dissociative disorders"
    },
    {
        "id": "1Z7K",
        "text": "Anorexia Nervosa with significantly low body weight, binge-purge pattern"
    },
    {
        "id": "1Z7L",
        "text": "Pyromania"
    },
    {
        "id": "1Z7M",
        "text": "Kleptomania"
    },
    {
        "id": "1Z7N",
        "text": "Insomnia disorders"
    },
    {
        "id": "1Z7Q",
        "text": "Hypersomnia due to a medication or substance"
    },
    {
        "id": "1Z7O",
        "text": "Idiopathic hypersomnia"
    },
    {
        "id": "1Z7P",
        "text": "Insufficient sleep syndrome"
    },
    {
        "id": "1Z7S",
        "text": "Hypersomnia due to a medical condition"
    },
    {
        "id": "1Z7T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z7U",
        "text": "Hypersomnolence disorders"
    },
    {
        "id": "1Z7V",
        "text": "Hypersomnolence disorders"
    },
    {
        "id": "1Z7W",
        "text": "Primary central sleep apnoea"
    },
    {
        "id": "1Z7X",
        "text": "Primary central sleep apnoea"
    },
    {
        "id": "1Z80",
        "text": "Central sleep apnoea due to a medication or substance"
    },
    {
        "id": "1Z7Z",
        "text": "Primary central sleep apnoea of prematurity"
    },
    {
        "id": "1Z7Y",
        "text": "Primary central sleep apnoea of infancy"
    },
    {
        "id": "1Z81",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z82",
        "text": "Central sleep apnoea due to a medical condition with Cheyne-Stokes breathing"
    },
    {
        "id": "1Z83",
        "text": "Central sleep apnoea due to a medical condition without Cheyne-Stokes breathing"
    },
    {
        "id": "1Z84",
        "text": "Central sleep apnoeas"
    },
    {
        "id": "1Z85",
        "text": "Central sleep apnoeas"
    },
    {
        "id": "1Z86",
        "text": "Sleep related Cheyne-Stokes respiration"
    },
    {
        "id": "1Z87",
        "text": "Insomnia disorders"
    },
    {
        "id": "1Z88",
        "text": "Chronic insomnia"
    },
    {
        "id": "1Z89",
        "text": "Short-term insomnia"
    },
    {
        "id": "1Z8A",
        "text": "Cardiovascular system"
    },
    {
        "id": "1Z8B",
        "text": "Submandibular gland duct"
    },
    {
        "id": "1Z8C",
        "text": "Parotid gland duct"
    },
    {
        "id": "1Z8F",
        "text": "Upper airway resistance syndrome"
    },
    {
        "id": "1Z8G",
        "text": "Obstructive sleep apnoea"
    },
    {
        "id": "1Z8H",
        "text": "Sleep-related hypoventilation due to a medication or substance"
    },
    {
        "id": "1Z8J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z8K",
        "text": "Abnormalities of breathing"
    },
    {
        "id": "1Z8R",
        "text": "Sleep-related movement disorder due to a medical condition"
    },
    {
        "id": "1Z8S",
        "text": "Periodic limb movement disorder"
    },
    {
        "id": "1Z8T",
        "text": "Sleep-related movement disorders"
    },
    {
        "id": "1Z8U",
        "text": "Sleep-related movement disorders"
    },
    {
        "id": "1Z8V",
        "text": "Salivary gland apparatus"
    },
    {
        "id": "1Z8W",
        "text": "Waldeyer ring"
    },
    {
        "id": "1Z8X",
        "text": "Sleep-related movement disorders"
    },
    {
        "id": "1Z8Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z8Z",
        "text": "Sleeptalking"
    },
    {
        "id": "1Z90",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z91",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z8I",
        "text": "Sleep-related hypoventilation due to medical condition"
    },
    {
        "id": "1Z8D",
        "text": "Sleep related Cheyne-Stokes respiration"
    },
    {
        "id": "1Z8E",
        "text": "Sleep related Cheyne-Stokes respiration"
    },
    {
        "id": "1Z92",
        "text": "Behavioural or psychological disturbances in dementia"
    },
    {
        "id": "1Z93",
        "text": "Psychotic symptoms in dementia"
    },
    {
        "id": "1Z94",
        "text": "Mood symptoms in dementia"
    },
    {
        "id": "1Z95",
        "text": "Anxiety symptoms in dementia"
    },
    {
        "id": "1Z96",
        "text": "Apathy in dementia"
    },
    {
        "id": "1Z97",
        "text": "Agitation or aggression in dementia"
    },
    {
        "id": "1Z98",
        "text": "Disinhibition in dementia"
    },
    {
        "id": "1Z99",
        "text": "Wandering in dementia"
    },
    {
        "id": "1Z9A",
        "text": "Sleep-related eating disorder"
    },
    {
        "id": "1Z9B",
        "text": "Disorders of arousal from non-REM sleep"
    },
    {
        "id": "1Z9D",
        "text": "REM sleep behaviour disorder"
    },
    {
        "id": "1Z9E",
        "text": "Circadian rhythm sleep-wake disorders"
    },
    {
        "id": "1Z9F",
        "text": "Recurrent isolated sleep paralysis"
    },
    {
        "id": "1Z9G",
        "text": "Nightmare disorder"
    },
    {
        "id": "1Z9C",
        "text": "Parasomnia disorders"
    },
    {
        "id": "1Z9I",
        "text": "Hypnagogic exploding head syndrome"
    },
    {
        "id": "1Z9J",
        "text": "Sleep-related hallucinations"
    },
    {
        "id": "1Z9K",
        "text": "Sleep-related hallucinations"
    },
    {
        "id": "1Z9L",
        "text": "Nocturnal enuresis"
    },
    {
        "id": "1Z9M",
        "text": "Parasomnia disorder due to a medication or substance"
    },
    {
        "id": "1Z9N",
        "text": "Parasomnia disorder due to a medication or substance"
    },
    {
        "id": "1Z9H",
        "text": "Parasomnia disorders"
    },
    {
        "id": "1Z9O",
        "text": "Parasomnia disorders"
    },
    {
        "id": "1Z9Q",
        "text": "Sleep-related hypoventilation or hypoxemia disorders"
    },
    {
        "id": "1Z9P",
        "text": "Parasomnia disorders"
    },
    {
        "id": "1Z9R",
        "text": "Chronic insomnia"
    },
    {
        "id": "1Z8L",
        "text": "Circadian rhythm sleep-wake disorders"
    },
    {
        "id": "1Z8M",
        "text": "Delayed sleep-wake phase disorder"
    },
    {
        "id": "1Z8N",
        "text": "Advanced sleep-wake phase disorder"
    },
    {
        "id": "1Z8P",
        "text": "Non-24 hour sleep-wake rhythm disorder"
    },
    {
        "id": "1Z8O",
        "text": "Irregular sleep-wake rhythm disorder"
    },
    {
        "id": "1Z9S",
        "text": "Circadian rhythm sleep-wake disorder, shift work type"
    },
    {
        "id": "1Z9T",
        "text": "Circadian rhythm sleep-wake disorder, jet lag type"
    },
    {
        "id": "1Z9U",
        "text": "Sphincter ani muscle"
    },
    {
        "id": "1Z9V",
        "text": "Dyspareunia"
    },
    {
        "id": "1Z9W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z9X",
        "text": "Ageing-related"
    },
    {
        "id": "1Z9Y",
        "text": "Causality"
    },
    {
        "id": "1Z9Z",
        "text": "Histopathology by behaviour"
    },
    {
        "id": "2000",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "2001",
        "text": "Histopathology by behaviour"
    },
    {
        "id": "2002",
        "text": "Histopathology by behaviour"
    },
    {
        "id": "2003",
        "text": "Histopathology by behaviour"
    },
    {
        "id": "2004",
        "text": "Anorexia Nervosa"
    },
    {
        "id": "2006",
        "text": "Anorexia Nervosa with dangerously low body weight, restricting pattern"
    },
    {
        "id": "2007",
        "text": "Anorexia Nervosa with dangerously low body weight, restricting pattern"
    },
    {
        "id": "2005",
        "text": "Anorexia Nervosa with significantly low body weight"
    },
    {
        "id": "2009",
        "text": "Anorexia Nervosa with significantly low body weight, binge-purge pattern"
    },
    {
        "id": "200E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "200F",
        "text": "Chronic bone cancer pain"
    },
    {
        "id": "200L",
        "text": "Multi-drug resistant Mycobacterium tuberculosis"
    },
    {
        "id": "200M",
        "text": "Waldeyer ring"
    },
    {
        "id": "200O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "200N",
        "text": "Immune system"
    },
    {
        "id": "200P",
        "text": "Immune system"
    },
    {
        "id": "200Q",
        "text": "Lingual tonsil"
    },
    {
        "id": "200R",
        "text": "Haematopoietic system"
    },
    {
        "id": "200S",
        "text": "Axillary lymph node"
    },
    {
        "id": "200T",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "200U",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "200V",
        "text": "Supraclavicular region"
    },
    {
        "id": "200Z",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "2010",
        "text": "Dissociative disorders"
    },
    {
        "id": "2011",
        "text": "Tonsillar aspergillosis"
    },
    {
        "id": "2013",
        "text": "Fibromyalgia syndrome"
    },
    {
        "id": "2014",
        "text": "Endometriosis"
    },
    {
        "id": "2015",
        "text": "Superficial ovarian endometriosis"
    },
    {
        "id": "2017",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "2018",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "2019",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "201A",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "201B",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "201C",
        "text": "Peritoneal pockets due to endometriosis"
    },
    {
        "id": "201D",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "201E",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "201F",
        "text": "Endometriosis of fallopian tube"
    },
    {
        "id": "201G",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "201H",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "201I",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "201K",
        "text": "Deep ovarian endometriosis"
    },
    {
        "id": "201J",
        "text": "Endometriosis of intestine"
    },
    {
        "id": "201O",
        "text": "Superficial ovarian endometriosis"
    },
    {
        "id": "201Q",
        "text": "External causes of morbidity or mortality"
    },
    {
        "id": "201R",
        "text": "Endometriosis in cutaneous scar"
    },
    {
        "id": "201S",
        "text": "Endometriosis in cutaneous scar"
    },
    {
        "id": "201T",
        "text": "Endometriosis in cutaneous scar"
    },
    {
        "id": "201U",
        "text": "Endometriosis in cutaneous scar"
    },
    {
        "id": "201V",
        "text": "Endometriosis in cutaneous scar"
    },
    {
        "id": "201W",
        "text": "Endometriosis"
    },
    {
        "id": "201X",
        "text": "Endometriosis"
    },
    {
        "id": "201Y",
        "text": "Endometriosis"
    },
    {
        "id": "201Z",
        "text": "Endometriosis"
    },
    {
        "id": "2020",
        "text": "Endometriosis"
    },
    {
        "id": "2021",
        "text": "Endometriosis"
    },
    {
        "id": "2022",
        "text": "Deep endometriosis"
    },
    {
        "id": "2023",
        "text": "Deep endometriosis"
    },
    {
        "id": "2024",
        "text": "Deep endometriosis"
    },
    {
        "id": "2025",
        "text": "Deep endometriosis"
    },
    {
        "id": "2026",
        "text": "Deep endometriosis"
    },
    {
        "id": "2027",
        "text": "Deep endometriosis"
    },
    {
        "id": "2028",
        "text": "Deep endometriosis"
    },
    {
        "id": "2029",
        "text": "Deep endometriosis"
    },
    {
        "id": "202A",
        "text": "Deep endometriosis"
    },
    {
        "id": "1Z0X",
        "text": "Malar region"
    },
    {
        "id": "1W6P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1R5D",
        "text": "Forehead"
    },
    {
        "id": "1Z43",
        "text": "Orbital region"
    },
    {
        "id": "1W4I",
        "text": "Cheek"
    },
    {
        "id": "1Z44",
        "text": "Oral region"
    },
    {
        "id": "202C",
        "text": "Palatine tonsil"
    },
    {
        "id": "202D",
        "text": "Mouth"
    },
    {
        "id": "202E",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "202F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1Z5B",
        "text": "Pelvis"
    },
    {
        "id": "202G",
        "text": "Congenital anomaly of mediastinal vein"
    },
    {
        "id": "202H",
        "text": "Patent oval foramen"
    },
    {
        "id": "202I",
        "text": "Congenital aortopulmonary window"
    },
    {
        "id": "202J",
        "text": "Anomalous origin of coronary artery from pulmonary arterial tree"
    },
    {
        "id": "202K",
        "text": "Congenital aortic valvar stenosis"
    },
    {
        "id": "202L",
        "text": "Congenital pulmonary valvar stenosis"
    },
    {
        "id": "202M",
        "text": "Aneurysm of aortic sinus of Valsalva"
    },
    {
        "id": "1Z57",
        "text": "Distal radioulnar joint"
    },
    {
        "id": "202O",
        "text": "Interphalangeal joint of the hand"
    },
    {
        "id": "202N",
        "text": "Metacarpophalangeal joint"
    },
    {
        "id": "202P",
        "text": "Substance-induced psychotic disorders"
    },
    {
        "id": "202Q",
        "text": "Alcohol-induced psychotic disorder"
    },
    {
        "id": "202R",
        "text": "Cannabis-induced psychotic disorder"
    },
    {
        "id": "202S",
        "text": "Synthetic cannabinoid-induced psychotic disorder"
    },
    {
        "id": "202T",
        "text": "Opioid-induced psychotic disorder"
    },
    {
        "id": "202U",
        "text": "Sedative, hypnotic or anxiolytic-induced psychotic disorder"
    },
    {
        "id": "202V",
        "text": "Cocaine-induced psychotic disorder"
    },
    {
        "id": "202W",
        "text": "Stimulant-induced psychotic disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "202X",
        "text": "Synthetic cathinone-induced psychotic disorder"
    },
    {
        "id": "202Y",
        "text": "Hallucinogen-induced psychotic disorder"
    },
    {
        "id": "202Z",
        "text": "Volatile inhalant-induced psychotic disorder"
    },
    {
        "id": "2030",
        "text": "MDMA or related drug-induced psychotic disorder, including MDA"
    },
    {
        "id": "2031",
        "text": "Dissociative drug-induced psychotic disorder including Ketamine or PCP"
    },
    {
        "id": "2032",
        "text": "Psychotic disorder induced by other specified psychoactive substance"
    },
    {
        "id": "2033",
        "text": "Psychotic disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "2034",
        "text": "Unspecified nonorganic psychosis"
    },
    {
        "id": "2035",
        "text": "Exhaustion psychosis"
    },
    {
        "id": "2036",
        "text": "Acute stress reaction"
    },
    {
        "id": "2037",
        "text": "Symptomatic manifestations of primary psychotic disorders"
    },
    {
        "id": "2038",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2039",
        "text": "Medial canthus"
    },
    {
        "id": "203A",
        "text": "Lateral canthus"
    },
    {
        "id": "203B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "203C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "203E",
        "text": "Secondary psychotic syndrome"
    },
    {
        "id": "203F",
        "text": "Secondary mood syndrome"
    },
    {
        "id": "203D",
        "text": "Secondary hypertension"
    },
    {
        "id": "1W67",
        "text": "Meibomian gland"
    },
    {
        "id": "1Z2R",
        "text": "Lacrimal\u00a0gland"
    },
    {
        "id": "203G",
        "text": "Labial commissure"
    },
    {
        "id": "1Z47",
        "text": "Gingivae"
    },
    {
        "id": "200X",
        "text": "Chest wall"
    },
    {
        "id": "200W",
        "text": "Lower thoracic paraspinal region"
    },
    {
        "id": "203I",
        "text": "Secondary mental or behavioural syndromes associated with disorders or diseases classified elsewhere"
    },
    {
        "id": "203J",
        "text": "Secondary mental or behavioural syndromes associated with disorders or diseases classified elsewhere"
    },
    {
        "id": "203K",
        "text": "Secondary mental or behavioural syndromes associated with disorders or diseases classified elsewhere"
    },
    {
        "id": "203L",
        "text": "Secondary mental or behavioural syndromes associated with disorders or diseases classified elsewhere"
    },
    {
        "id": "203M",
        "text": "Substance-induced mood disorders"
    },
    {
        "id": "203N",
        "text": "Alcohol-induced mood disorder"
    },
    {
        "id": "203O",
        "text": "Cannabis-induced mood disorder"
    },
    {
        "id": "203P",
        "text": "Synthetic cannabinoid-induced mood disorder"
    },
    {
        "id": "203Q",
        "text": "Opioid-induced mood disorder"
    },
    {
        "id": "203R",
        "text": "Sedative, hypnotic or anxiolytic-induced mood disorder"
    },
    {
        "id": "203S",
        "text": "Cocaine-induced mood disorder"
    },
    {
        "id": "203T",
        "text": "Stimulant-induced mood disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "203U",
        "text": "Synthetic cathinone-induced mood disorder"
    },
    {
        "id": "203V",
        "text": "Hallucinogen-induced mood disorder"
    },
    {
        "id": "203W",
        "text": "Volatile inhalant-induced mood disorder"
    },
    {
        "id": "203X",
        "text": "MDMA or related drug-induced mood disorder, including MDA"
    },
    {
        "id": "203Y",
        "text": "Dissociative drug-induced mood disorder including Ketamine or PCP"
    },
    {
        "id": "203Z",
        "text": "Schizophrenia"
    },
    {
        "id": "2040",
        "text": "Schizoaffective disorder"
    },
    {
        "id": "2041",
        "text": "Schizotypal disorder"
    },
    {
        "id": "2042",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2043",
        "text": "Disorders due to use of alcohol"
    },
    {
        "id": "2044",
        "text": "Disorders due to use of cannabis"
    },
    {
        "id": "2045",
        "text": "Disorders due to use of synthetic cannabinoids"
    },
    {
        "id": "2046",
        "text": "Disorders due to use of opioids"
    },
    {
        "id": "2047",
        "text": "Disorders due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "2048",
        "text": "Disorders due to use of cocaine"
    },
    {
        "id": "2049",
        "text": "Disorders due to use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "204A",
        "text": "Disorders due to use of synthetic cathinones"
    },
    {
        "id": "204B",
        "text": "Disorders due to use of hallucinogens"
    },
    {
        "id": "204C",
        "text": "Disorders due to use of volatile inhalants"
    },
    {
        "id": "204D",
        "text": "Disorders due to use of MDMA or related drugs, including MDA"
    },
    {
        "id": "204E",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "204F",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "204G",
        "text": "Disorders due to use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "204H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "204I",
        "text": "Certain specified alcohol-induced mental or behavioural disorders"
    },
    {
        "id": "204J",
        "text": "Certain specified cannabis-induced mental or behavioural disorders"
    },
    {
        "id": "204K",
        "text": "Certain specified synthetic cannabinoids-induced mental or behavioural disorders"
    },
    {
        "id": "204L",
        "text": "Certain specified opioid-induced mental or behavioural disorders"
    },
    {
        "id": "204M",
        "text": "Certain specified sedatives, hypnotics or anxiolytic-induced mental or behavioural disorders"
    },
    {
        "id": "204N",
        "text": "Certain specified cocaine-induced mental or behavioural disorders"
    },
    {
        "id": "204O",
        "text": "Certain specified stimulant-induced mental or behavioural disorders including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "204P",
        "text": "Certain specified synthetic cathinone-induced mental or behavioural disorders"
    },
    {
        "id": "204Q",
        "text": "Certain specified hallucinogen-induced mental or behavioural disorders"
    },
    {
        "id": "204R",
        "text": "Certain specified volatile inhalants-induced mental or behavioural disorders"
    },
    {
        "id": "204S",
        "text": "Certain specified MDMA or related drug-induced mental or behavioural disorders, including MDA"
    },
    {
        "id": "204T",
        "text": "Certain specified dissociative drugs -induced mental or behavioural disorders, including ketamine and phencyclidine [PCP]"
    },
    {
        "id": "204U",
        "text": "Delusional disorder"
    },
    {
        "id": "204V",
        "text": "Acute and transient psychotic disorder"
    },
    {
        "id": "204W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "204X",
        "text": "Symptomatic and course presentations for mood episodes in mood disorders"
    },
    {
        "id": "204Y",
        "text": "Depressive disorders"
    },
    {
        "id": "204Z",
        "text": "Bipolar or related disorders"
    },
    {
        "id": "203H",
        "text": "Iliac region"
    },
    {
        "id": "1Z16",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "202B",
        "text": "Jaw, unspecified"
    },
    {
        "id": "1Z4T",
        "text": "Ear"
    },
    {
        "id": "1Z2N",
        "text": "Iris"
    },
    {
        "id": "1P7Y",
        "text": "Atherosclerotic chronic arterial occlusive disease"
    },
    {
        "id": "2050",
        "text": "Single episode depressive disorder"
    },
    {
        "id": "2051",
        "text": "Recurrent depressive disorder"
    },
    {
        "id": "2052",
        "text": "Dysthymic disorder"
    },
    {
        "id": "2053",
        "text": "Mixed depressive and anxiety disorder"
    },
    {
        "id": "2054",
        "text": "Premenstrual dysphoric disorder"
    },
    {
        "id": "2055",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2056",
        "text": "Endometriosis"
    },
    {
        "id": "205A",
        "text": "Catatonia associated with another mental disorder"
    },
    {
        "id": "205B",
        "text": "Generalised anxiety disorder"
    },
    {
        "id": "205C",
        "text": "Separation anxiety disorder"
    },
    {
        "id": "205D",
        "text": "Selective mutism"
    },
    {
        "id": "205E",
        "text": "Obsessive-compulsive disorder"
    },
    {
        "id": "205F",
        "text": "Obsessive-compulsive disorder with fair to good insight"
    },
    {
        "id": "205G",
        "text": "Obsessive-compulsive disorder with poor to absent insight"
    },
    {
        "id": "205H",
        "text": "Hoarding disorder with fair to good insight"
    },
    {
        "id": "205I",
        "text": "Hoarding disorder with poor to absent insight"
    },
    {
        "id": "205J",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "205K",
        "text": "Adjustment disorder"
    },
    {
        "id": "205L",
        "text": "Dissociative amnesia"
    },
    {
        "id": "205M",
        "text": "Possession trance disorder"
    },
    {
        "id": "205N",
        "text": "Depersonalization-derealization disorder"
    },
    {
        "id": "205O",
        "text": "Pyromania"
    },
    {
        "id": "205P",
        "text": "Personality disorder"
    },
    {
        "id": "205Q",
        "text": "Prominent personality traits or patterns"
    },
    {
        "id": "205R",
        "text": "Secondary personality change"
    },
    {
        "id": "205S",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium, without psychotic symptoms"
    },
    {
        "id": "205T",
        "text": "Mental or behavioural disorders associated with pregnancy, childbirth or the puerperium, with psychotic symptoms"
    },
    {
        "id": "205U",
        "text": "Postpartum depression NOS"
    },
    {
        "id": "205V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "205W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "205X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "205Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "205Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2060",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2061",
        "text": "Allergic or hypersensitivity conditions"
    },
    {
        "id": "2062",
        "text": "Body dysmorphic disorder with fair to good insight"
    },
    {
        "id": "2063",
        "text": "Body dysmorphic disorder with poor to absent insight"
    },
    {
        "id": "2064",
        "text": "Olfactory reference disorder with fair to good insight"
    },
    {
        "id": "2065",
        "text": "Olfactory reference disorder with poor to absent insight"
    },
    {
        "id": "2066",
        "text": "Hypochondriasis"
    },
    {
        "id": "2067",
        "text": "Hypochondriasis with fair to good insight"
    },
    {
        "id": "2068",
        "text": "Hypochondriasis with poor to absent insight"
    },
    {
        "id": "1A4K",
        "text": "Allergic or hypersensitivity conditions"
    },
    {
        "id": "2069",
        "text": "Prominent anxiety symptoms in mood episodes"
    },
    {
        "id": "206A",
        "text": "Panic attacks in mood episodes"
    },
    {
        "id": "206B",
        "text": "Current depressive episode persistent"
    },
    {
        "id": "206C",
        "text": "Current depressive episode with melancholia"
    },
    {
        "id": "206D",
        "text": "Seasonal pattern of mood episode onset"
    },
    {
        "id": "206E",
        "text": "Rapid cycling"
    },
    {
        "id": "206F",
        "text": "Negative affectivity"
    },
    {
        "id": "206G",
        "text": "Menopausal or female climacteric states"
    },
    {
        "id": "206H",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "206I",
        "text": "Social anxiety disorder"
    },
    {
        "id": "206J",
        "text": "Personality traits or coping style affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "206K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1X7P",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "206L",
        "text": "Mental or behavioural symptoms, signs or clinical findings"
    },
    {
        "id": "206M",
        "text": "Symptoms or signs related to personality features"
    },
    {
        "id": "206N",
        "text": "Multi-infarct dementia"
    },
    {
        "id": "206P",
        "text": "Psychotic symptoms in dementia"
    },
    {
        "id": "206Q",
        "text": "Behavioural or psychological disturbances in dementia"
    },
    {
        "id": "206R",
        "text": "Mood symptoms in dementia"
    },
    {
        "id": "206S",
        "text": "Apathy in dementia"
    },
    {
        "id": "206T",
        "text": "Ageing associated decline in intrinsic capacity"
    },
    {
        "id": "206U",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "1Y7C",
        "text": "Diabetic nephropathy"
    },
    {
        "id": "206V",
        "text": "Diabetic kidney disease"
    },
    {
        "id": "206W",
        "text": "Alcohol-induced anxiety disorder"
    },
    {
        "id": "206X",
        "text": "Cannabis-induced anxiety disorder"
    },
    {
        "id": "206Y",
        "text": "Synthetic cannabinoid-induced anxiety disorder"
    },
    {
        "id": "206Z",
        "text": "Opioid-induced anxiety disorder"
    },
    {
        "id": "2070",
        "text": "Opioid-induced anxiety disorder"
    },
    {
        "id": "2071",
        "text": "Sedative, hypnotic or anxiolytic-induced anxiety disorder"
    },
    {
        "id": "2072",
        "text": "Cocaine-induced anxiety disorder"
    },
    {
        "id": "2073",
        "text": "Stimulant-induced anxiety disorder including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "2074",
        "text": "Synthetic cathinone-induced anxiety disorder"
    },
    {
        "id": "2075",
        "text": "Caffeine-induced anxiety disorder"
    },
    {
        "id": "2076",
        "text": "Hallucinogen-induced anxiety disorder"
    },
    {
        "id": "2077",
        "text": "Volatile inhalant-induced anxiety disorder"
    },
    {
        "id": "2078",
        "text": "MDMA or related drug-induced anxiety disorder"
    },
    {
        "id": "2079",
        "text": "Dissociative drug-induced anxiety disorder including Ketamine or PCP"
    },
    {
        "id": "207A",
        "text": "Anxiety disorder induced by other specified psychoactive substance"
    },
    {
        "id": "207B",
        "text": "Anxiety disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "207C",
        "text": "Dissociative identity disorder"
    },
    {
        "id": "206O",
        "text": "Hereditary sensory or autonomic neuropathy"
    },
    {
        "id": "207E",
        "text": "Alcohol-induced delirium"
    },
    {
        "id": "207F",
        "text": "Psychotic disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "207G",
        "text": "Disorders due to use of multiple specified psychoactive substances, including medications"
    },
    {
        "id": "207H",
        "text": "Mood disorder induced by other specified psychoactive substance"
    },
    {
        "id": "207I",
        "text": "Mood disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "207J",
        "text": "Mood disorder induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "207K",
        "text": "Certain other specified psychoactive substance-induced mental or behavioural disorders"
    },
    {
        "id": "207L",
        "text": "Certain multiple specified psychoactive substances-induced mental or behavioural disorders"
    },
    {
        "id": "207M",
        "text": "Certain unknown or unspecified psychoactive substance-induced mental or behavioural disorders"
    },
    {
        "id": "207N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "207O",
        "text": "Disorders due to use of alcohol"
    },
    {
        "id": "207P",
        "text": "Disorders due to use of cannabis"
    },
    {
        "id": "207Q",
        "text": "Disorders due to use of synthetic cannabinoids"
    },
    {
        "id": "207R",
        "text": "Disorders due to use of opioids"
    },
    {
        "id": "207S",
        "text": "Disorders due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "207T",
        "text": "Disorders due to use of cocaine"
    },
    {
        "id": "207U",
        "text": "Disorders due to use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "207V",
        "text": "Disorders due to use of synthetic cathinones"
    },
    {
        "id": "207W",
        "text": "Disorders due to use of hallucinogens"
    },
    {
        "id": "207X",
        "text": "Disorders due to use of volatile inhalants"
    },
    {
        "id": "207Y",
        "text": "Disorders due to use of MDMA or related drugs, including MDA"
    },
    {
        "id": "207Z",
        "text": "Disorders due to use of dissociative drugs including ketamine and phencyclidine [PCP]"
    },
    {
        "id": "2080",
        "text": "Disorders due to use of dissociative drugs including ketamine and phencyclidine [PCP]"
    },
    {
        "id": "2081",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "2082",
        "text": "Disorders due to use of multiple specified psychoactive substances, including medications"
    },
    {
        "id": "2083",
        "text": "Disorders due to use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "2084",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2085",
        "text": "Certain specified alcohol-induced mental or behavioural disorders"
    },
    {
        "id": "2086",
        "text": "Certain specified cannabis-induced mental or behavioural disorders"
    },
    {
        "id": "2087",
        "text": "Certain specified synthetic cannabinoids-induced mental or behavioural disorders"
    },
    {
        "id": "2088",
        "text": "Certain specified opioid-induced mental or behavioural disorders"
    },
    {
        "id": "2089",
        "text": "Certain specified sedatives, hypnotics or anxiolytic-induced mental or behavioural disorders"
    },
    {
        "id": "208A",
        "text": "Certain specified cocaine-induced mental or behavioural disorders"
    },
    {
        "id": "208B",
        "text": "Certain specified stimulant-induced mental or behavioural disorders including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "208C",
        "text": "Certain specified synthetic cathinone-induced mental or behavioural disorders"
    },
    {
        "id": "208D",
        "text": "Certain specified hallucinogen-induced mental or behavioural disorders"
    },
    {
        "id": "208E",
        "text": "Certain specified volatile inhalants-induced mental or behavioural disorders"
    },
    {
        "id": "208F",
        "text": "Certain specified MDMA or related drug-induced mental or behavioural disorders, including MDA"
    },
    {
        "id": "208G",
        "text": "Certain specified dissociative drugs -induced mental or behavioural disorders, including ketamine and phencyclidine [PCP]"
    },
    {
        "id": "208H",
        "text": "Certain other specified psychoactive substance-induced mental or behavioural disorders"
    },
    {
        "id": "208I",
        "text": "Certain multiple specified psychoactive substances-induced mental or behavioural disorders"
    },
    {
        "id": "208J",
        "text": "Certain unknown or unspecified psychoactive substance-induced mental or behavioural disorders"
    },
    {
        "id": "208K",
        "text": "Anxiety disorder induced by multiple specified psychoactive substances"
    },
    {
        "id": "208L",
        "text": "Substance-induced anxiety disorders"
    },
    {
        "id": "208M",
        "text": "Dementia due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "208N",
        "text": "Dementia due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "208O",
        "text": "Hallucinogen-induced delirium"
    },
    {
        "id": "208P",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "208Q",
        "text": "Retina"
    },
    {
        "id": "208R",
        "text": "Disruptive behaviour or dissocial disorders"
    },
    {
        "id": "208S",
        "text": "Anatomy and topography"
    },
    {
        "id": "208T",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "208U",
        "text": "Dementia due to Alzheimer disease"
    },
    {
        "id": "208V",
        "text": "Alzheimer disease dementia with depression"
    },
    {
        "id": "208W",
        "text": "Dementia due to Alzheimer disease"
    },
    {
        "id": "208X",
        "text": "Alzheimer disease dementia with psychosis"
    },
    {
        "id": "208Y",
        "text": "Dementia due to Alzheimer disease"
    },
    {
        "id": "208Z",
        "text": "Dementia due to carbon monoxide poisoning"
    },
    {
        "id": "2091",
        "text": "Relational"
    },
    {
        "id": "1W4K",
        "text": "Nose"
    },
    {
        "id": "1W6L",
        "text": "Lip"
    },
    {
        "id": "2092",
        "text": "Wrist"
    },
    {
        "id": "2093",
        "text": "Forearm"
    },
    {
        "id": "1W8B",
        "text": "Fingers and thumb"
    },
    {
        "id": "1W8A",
        "text": "Fingers and thumb"
    },
    {
        "id": "2094",
        "text": "Anterior surface of lower leg"
    },
    {
        "id": "2095",
        "text": "Lower leg"
    },
    {
        "id": "2096",
        "text": "Ankle"
    },
    {
        "id": "2097",
        "text": "Heel"
    },
    {
        "id": "2098",
        "text": "Foot"
    },
    {
        "id": "1W90",
        "text": "Toes"
    },
    {
        "id": "1W92",
        "text": "Toenail"
    },
    {
        "id": "1W91",
        "text": "Toes"
    },
    {
        "id": "2099",
        "text": "Dissociative amnesia with dissociative fugue"
    },
    {
        "id": "209A",
        "text": "Dissociative amnesia with dissociative fugue"
    },
    {
        "id": "209B",
        "text": "Dissociative amnesia without dissociative fugue"
    },
    {
        "id": "209C",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "209D",
        "text": "Tics associated with developmental disorders"
    },
    {
        "id": "209E",
        "text": "Vaccines"
    },
    {
        "id": "209G",
        "text": "Dementia due to cerebrovascular disease"
    },
    {
        "id": "1S7O",
        "text": "Acquired angioedema"
    },
    {
        "id": "209H",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "209I",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "209J",
        "text": "Catatonia"
    },
    {
        "id": "209K",
        "text": "Catatonia associated with another mental disorder"
    },
    {
        "id": "209L",
        "text": "Catatonia induced by substances or medications"
    },
    {
        "id": "209M",
        "text": "Secondary catatonia syndrome"
    },
    {
        "id": "209O",
        "text": "Catatonia induced by unknown or unspecified psychoactive substance"
    },
    {
        "id": "209N",
        "text": "Catatonia induced by specified psychoactive substance or medication"
    },
    {
        "id": "209R",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "209S",
        "text": "Disorders specifically associated with stress"
    },
    {
        "id": "209T",
        "text": "Developmental speech fluency disorder"
    },
    {
        "id": "209U",
        "text": "Developmental motor coordination disorder"
    },
    {
        "id": "209V",
        "text": "Primary tics or tic disorders"
    },
    {
        "id": "209W",
        "text": "Separation anxiety disorder"
    },
    {
        "id": "210P",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "210R",
        "text": "Secondary mental or behavioural syndromes associated with disorders or diseases classified elsewhere"
    },
    {
        "id": "210Q",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "210S",
        "text": "Catatonia"
    },
    {
        "id": "210T",
        "text": "Catatonia associated with another mental disorder"
    },
    {
        "id": "210U",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "1Y4M",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "210X",
        "text": "Ganser syndrome"
    },
    {
        "id": "210Y",
        "text": "Hypersomnia associated with a mental disorder"
    },
    {
        "id": "2113",
        "text": "Autism spectrum disorder without disorder of intellectual development and with absence of functional language"
    },
    {
        "id": "2114",
        "text": "Developmental language disorder with impairment of mainly pragmatic language"
    },
    {
        "id": "2115",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "2116",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "2117",
        "text": "Adjustment disorder"
    },
    {
        "id": "2118",
        "text": "Adjustment disorder"
    },
    {
        "id": "2119",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "210Z",
        "text": "Urinary tract infection, site not specified"
    },
    {
        "id": "211A",
        "text": "Organ system disorders (TM1)"
    },
    {
        "id": "211B",
        "text": "Pregnancy associated disorders (TM1)"
    },
    {
        "id": "211C",
        "text": "Puerperium associated disorders (TM1)"
    },
    {
        "id": "211D",
        "text": "Human immunodeficiency virus disease"
    },
    {
        "id": "211H",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "211I",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "211J",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "211K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "211M",
        "text": "Gender incongruence of adolescence or adulthood"
    },
    {
        "id": "211N",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "1Y9T",
        "text": "Thoracic endometriosis"
    },
    {
        "id": "211O",
        "text": "Chronic neuropathic orofacial pain"
    },
    {
        "id": "1Z25",
        "text": "Endometriosis of intestine"
    },
    {
        "id": "201P",
        "text": "Endometriosis"
    },
    {
        "id": "211V",
        "text": "Organ system disorders (TM1)"
    },
    {
        "id": "211X",
        "text": "Postviral fatigue syndrome"
    },
    {
        "id": "211Y",
        "text": "Foreign body in multiple parts of external eye"
    },
    {
        "id": "211Z",
        "text": "Caregiver-child relationship problem with current caregiver"
    },
    {
        "id": "2120",
        "text": "Caregiver-child relationship problem with former caregiver"
    },
    {
        "id": "2121",
        "text": "Borderline pattern"
    },
    {
        "id": "2122",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "1S04",
        "text": "Lichenoid dermatoses"
    },
    {
        "id": "212A",
        "text": "Paediatric onset systemic sclerosis"
    },
    {
        "id": "212B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "212E",
        "text": "Aplasia cutis congenita of limbs"
    },
    {
        "id": "212F",
        "text": "Certain specified immunological findings"
    },
    {
        "id": "212G",
        "text": "Certain specified immunological findings"
    },
    {
        "id": "212H",
        "text": "Causality"
    },
    {
        "id": "212L",
        "text": "Sudden unexpected death in epilepsy"
    },
    {
        "id": "212M",
        "text": "Postinflammatory hypomelanosis"
    },
    {
        "id": "212P",
        "text": "Madarosis of eyelid or periocular area"
    },
    {
        "id": "212Q",
        "text": "Internal iliac lymph node"
    },
    {
        "id": "201M",
        "text": "Endometriosis"
    },
    {
        "id": "201N",
        "text": "Endometriosis of pelvic peritoneum"
    },
    {
        "id": "212U",
        "text": "Sialoadenitis"
    },
    {
        "id": "212V",
        "text": "Diseases of neuromuscular junction or muscle"
    },
    {
        "id": "212W",
        "text": "Donors of organs or tissues"
    },
    {
        "id": "212X",
        "text": "Body Tissues"
    },
    {
        "id": "212Y",
        "text": "Liver system disorders (TM1)"
    },
    {
        "id": "212Z",
        "text": "Protein allergens"
    },
    {
        "id": "2131",
        "text": "Ramipril"
    },
    {
        "id": "2133",
        "text": "Short bowel syndrome in neonate"
    },
    {
        "id": "2134",
        "text": "Malingering"
    },
    {
        "id": "2135",
        "text": "Amnestic disorder due to other specified psychoactive substance including medications"
    },
    {
        "id": "2136",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "2137",
        "text": "Dementia"
    },
    {
        "id": "2105",
        "text": "Hypersomnia associated with a mental disorder"
    },
    {
        "id": "2139",
        "text": "Sleep-related movement disorders"
    },
    {
        "id": "213F",
        "text": "Benign sleep myoclonus of infancy"
    },
    {
        "id": "213G",
        "text": "Propriospinal myoclonus at sleep onset"
    },
    {
        "id": "213H",
        "text": "Sleep-related movement disorder due to a medical condition"
    },
    {
        "id": "213D",
        "text": "Sleep-related bruxism"
    },
    {
        "id": "213L",
        "text": "Confusional arousals"
    },
    {
        "id": "213P",
        "text": "Parasomnias related to REM sleep"
    },
    {
        "id": "213V",
        "text": "Parasomnia disorder due to a medical condition"
    },
    {
        "id": "213W",
        "text": "Parasomnia disorder due to a medication or substance"
    },
    {
        "id": "213X",
        "text": "Disorders due to use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "213Y",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "213Z",
        "text": "Disorders due to use of multiple specified psychoactive substances, including medications"
    },
    {
        "id": "2140",
        "text": "Disorders due to use of non-psychoactive substances"
    },
    {
        "id": "2141",
        "text": "Episode of harmful use of alcohol"
    },
    {
        "id": "2142",
        "text": "Episode of harmful use of cannabis"
    },
    {
        "id": "2143",
        "text": "Episode of harmful use of opioids"
    },
    {
        "id": "2144",
        "text": "Episode of harmful use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "2145",
        "text": "Episode of harmful use of cocaine"
    },
    {
        "id": "2146",
        "text": "Episode of harmful use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "2147",
        "text": "Episode of harmful use of synthetic cathinones"
    },
    {
        "id": "2148",
        "text": "Episode of harmful use of caffeine"
    },
    {
        "id": "2149",
        "text": "Episode of harmful use of hallucinogens"
    },
    {
        "id": "214A",
        "text": "Episode of harmful use of nicotine"
    },
    {
        "id": "214B",
        "text": "Episode of harmful use of volatile inhalants"
    },
    {
        "id": "214C",
        "text": "Episode of harmful use of MDMA or related drugs, including MDA"
    },
    {
        "id": "214D",
        "text": "Episode of harmful use of dissociative drugs including ketamine or PCP"
    },
    {
        "id": "214E",
        "text": "Episode of harmful use of other specified psychoactive substance"
    },
    {
        "id": "214F",
        "text": "Episode of harmful use of multiple specified psychoactive substances"
    },
    {
        "id": "214G",
        "text": "Episode of harmful use of unknown or unspecified psychoactive substances"
    },
    {
        "id": "214H",
        "text": "Episode of harmful use of non-psychoactive substances"
    },
    {
        "id": "214I",
        "text": "Episode of harmful use of synthetic cannabinoids"
    },
    {
        "id": "214J",
        "text": "Diseases of the circulatory system"
    },
    {
        "id": "215Y",
        "text": "Crisscross heart"
    },
    {
        "id": "210O",
        "text": "Sleep-related hypoxemia due to a medical condition"
    },
    {
        "id": "2166",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2167",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2168",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2169",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "216A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "216B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "216J",
        "text": "Congenitally corrected transposition of great arteries"
    },
    {
        "id": "217E",
        "text": "Congenital truncal valvar regurgitation"
    },
    {
        "id": "217F",
        "text": "Congenital truncal valvar stenosis"
    },
    {
        "id": "217G",
        "text": "Dysplasia of truncal valve"
    },
    {
        "id": "217H",
        "text": "Congenital anomaly of mediastinal vein"
    },
    {
        "id": "216F",
        "text": "Congenital anomaly of an atrioventricular or ventriculo-arterial connection"
    },
    {
        "id": "216I",
        "text": "Congenitally corrected transposition of great arteries"
    },
    {
        "id": "216L",
        "text": "Transposition of the great arteries with concordant atrioventricular connections and intact ventricular septum"
    },
    {
        "id": "216M",
        "text": "Transposition of the great arteries with concordant atrioventricular connections and ventricular septal defect"
    },
    {
        "id": "216N",
        "text": "Transposition of the great arteries with concordant atrioventricular connections and ventricular septal defect and left ventricular outflow tract obstruction"
    },
    {
        "id": "216S",
        "text": "Double outlet right ventricle with subaortic or doubly committed ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "216T",
        "text": "Double outlet right ventricle with subaortic ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "216U",
        "text": "Double outlet right ventricle with doubly committed ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "216V",
        "text": "Double outlet right ventricle with subpulmonary ventricular septal defect, transposition type"
    },
    {
        "id": "216X",
        "text": "Double outlet right ventricle with subaortic or doubly committed ventricular septal defect without pulmonary stenosis, ventricular septal defect type"
    },
    {
        "id": "216Z",
        "text": "Double outlet right ventricle with doubly committed ventricular septal defect without pulmonary stenosis"
    },
    {
        "id": "2171",
        "text": "Double outlet left ventricle"
    },
    {
        "id": "2173",
        "text": "Common arterial trunk"
    },
    {
        "id": "2174",
        "text": "Common arterial trunk with aortic dominance"
    },
    {
        "id": "2175",
        "text": "Common arterial trunk with aortic dominance and both pulmonary arteries arising from trunk"
    },
    {
        "id": "2177",
        "text": "Common arterial trunk with pulmonary dominance and aortic arch obstruction"
    },
    {
        "id": "2179",
        "text": "Common arterial trunk with pulmonary dominance and aortic coarctation"
    },
    {
        "id": "217B",
        "text": "Congenital truncal valvar regurgitation"
    },
    {
        "id": "217C",
        "text": "Congenital truncal valvar stenosis"
    },
    {
        "id": "2155",
        "text": "Anomalous position-orientation of heart"
    },
    {
        "id": "215H",
        "text": "Dextrocardia"
    },
    {
        "id": "215I",
        "text": "Mesocardia"
    },
    {
        "id": "215J",
        "text": "Extrathoracic heart"
    },
    {
        "id": "2154",
        "text": "Structural developmental anomaly of heart or great vessels"
    },
    {
        "id": "2157",
        "text": "Abnormal atrial arrangement"
    },
    {
        "id": "2158",
        "text": "Atrial situs inversus"
    },
    {
        "id": "2159",
        "text": "Isomerism of right atrial appendages"
    },
    {
        "id": "215A",
        "text": "Isomerism of left atrial appendages"
    },
    {
        "id": "215B",
        "text": "Abnormal ventricular relationships"
    },
    {
        "id": "215E",
        "text": "Crisscross heart"
    },
    {
        "id": "215F",
        "text": "Superior-inferior ventricular relationship"
    },
    {
        "id": "215K",
        "text": "Abnormal relationship of great arterial roots"
    },
    {
        "id": "215O",
        "text": "Aortic root directly posterior to pulmonary root"
    },
    {
        "id": "215T",
        "text": "Aortic root posterior and leftward to pulmonary root"
    },
    {
        "id": "215U",
        "text": "Abnormal intrapericardial course of great arteries"
    },
    {
        "id": "215V",
        "text": "Spiralling course of great arteries"
    },
    {
        "id": "215W",
        "text": "Parallel course of great arteries"
    },
    {
        "id": "216E",
        "text": "Total mirror imagery"
    },
    {
        "id": "215Z",
        "text": "Right isomerism"
    },
    {
        "id": "2160",
        "text": "Left isomerism"
    },
    {
        "id": "2183",
        "text": "Partial anomalous pulmonary venous connection"
    },
    {
        "id": "2185",
        "text": "Obstructed anomalous pulmonary venous pathway or connection"
    },
    {
        "id": "2186",
        "text": "Congenital pulmonary venous stenosis or hypoplasia"
    },
    {
        "id": "2187",
        "text": "Congenital atresia of pulmonary vein"
    },
    {
        "id": "2188",
        "text": "Obsessive-compulsive disorder"
    },
    {
        "id": "218B",
        "text": "Foot"
    },
    {
        "id": "218C",
        "text": "Alcohol intoxication"
    },
    {
        "id": "218D",
        "text": "Opioid intoxication"
    },
    {
        "id": "218E",
        "text": "Sedative, hypnotic or anxiolytic intoxication"
    },
    {
        "id": "218G",
        "text": "Stimulant intoxication including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "218F",
        "text": "Stimulant intoxication including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "218H",
        "text": "Synthetic cathinone intoxication"
    },
    {
        "id": "218I",
        "text": "Caffeine intoxication"
    },
    {
        "id": "218J",
        "text": "Obsessive-compulsive disorder"
    },
    {
        "id": "218L",
        "text": "Nicotine intoxication"
    },
    {
        "id": "218M",
        "text": "Volatile inhalant intoxication"
    },
    {
        "id": "218N",
        "text": "Alcohol withdrawal"
    },
    {
        "id": "218K",
        "text": "Hallucinogen intoxication"
    },
    {
        "id": "218O",
        "text": "Cannabis withdrawal"
    },
    {
        "id": "218P",
        "text": "Opioid withdrawal"
    },
    {
        "id": "218Q",
        "text": "Sedative, hypnotic or anxiolytic withdrawal"
    },
    {
        "id": "218R",
        "text": "Cocaine withdrawal"
    },
    {
        "id": "218S",
        "text": "Stimulant withdrawal including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "218T",
        "text": "Synthetic cathinone withdrawal"
    },
    {
        "id": "218U",
        "text": "Caffeine withdrawal"
    },
    {
        "id": "218V",
        "text": "Nicotine withdrawal"
    },
    {
        "id": "2130",
        "text": "Injuries to the head"
    },
    {
        "id": "218X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "218Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "218Z",
        "text": "Breast"
    },
    {
        "id": "2190",
        "text": "Anterior surface of ankle"
    },
    {
        "id": "2192",
        "text": "Vision impairment including blindness"
    },
    {
        "id": "211W",
        "text": "Drug toxicity associated with harm in surgical or medical care, not elsewhere classified"
    },
    {
        "id": "2193",
        "text": "Vision impairment including blindness"
    },
    {
        "id": "2194",
        "text": "Hoarding disorder"
    },
    {
        "id": "2198",
        "text": "Common fibular nerve"
    },
    {
        "id": "2196",
        "text": "Diseases of the musculoskeletal system or connective tissue"
    },
    {
        "id": "219A",
        "text": "Disorders due to use of synthetic cannabinoids"
    },
    {
        "id": "219B",
        "text": "Dissociative disorders"
    },
    {
        "id": "219D",
        "text": "Medical or surgical procedure associated with injury or harm in therapeutic use"
    },
    {
        "id": "219E",
        "text": "Tobacco use"
    },
    {
        "id": "219F",
        "text": "Delirium induced by multiple specified psychoactive substances including medications"
    },
    {
        "id": "219G",
        "text": "Hazardous drug use"
    },
    {
        "id": "219H",
        "text": "Hazardous substance use"
    },
    {
        "id": "219I",
        "text": "Multiple specified psychoactive substances dependence"
    },
    {
        "id": "219J",
        "text": "Stimulant-induced delirium including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "219M",
        "text": "Dermatoses precipitated by drug therapy"
    },
    {
        "id": "219U",
        "text": "Drug-induced obesity"
    },
    {
        "id": "219X",
        "text": "Obesity due to energy imbalance"
    },
    {
        "id": "2200",
        "text": "Obesity"
    },
    {
        "id": "2202",
        "text": "Overweight or localised adiposity"
    },
    {
        "id": "2201",
        "text": "Overweight in infants, children or adolescents"
    },
    {
        "id": "219Z",
        "text": "Overweight"
    },
    {
        "id": "2205",
        "text": "Obesity in infants or children up to 5 years of age"
    },
    {
        "id": "2206",
        "text": "Obesity in school-aged children or adolescents from 5 to 19 years"
    },
    {
        "id": "219Y",
        "text": "Obesity in children or adolescents"
    },
    {
        "id": "2203",
        "text": "Overweight in adults"
    },
    {
        "id": "219V",
        "text": "Obesity in adults"
    },
    {
        "id": "2207",
        "text": "Complex regional pain syndrome type II"
    },
    {
        "id": "2209",
        "text": "Polymorphic ventricular premature depolarization"
    },
    {
        "id": "220A",
        "text": "Torsade de pointes"
    },
    {
        "id": "220B",
        "text": "Second degree atrioventricular block with progressive PR prolongation"
    },
    {
        "id": "220C",
        "text": "Second degree atrioventricular block with constant PR"
    },
    {
        "id": "220D",
        "text": "Anomalous hepatic venous connection to heart"
    },
    {
        "id": "221B",
        "text": "Bulimia Nervosa"
    },
    {
        "id": "221A",
        "text": "Binge eating disorder"
    },
    {
        "id": "212S",
        "text": "Avoidant-restrictive food intake disorder"
    },
    {
        "id": "221E",
        "text": "Congenital mitral valvar prolapse"
    },
    {
        "id": "2219",
        "text": "Overriding mitral valve"
    },
    {
        "id": "2217",
        "text": "Mitral annular hypoplasia"
    },
    {
        "id": "2216",
        "text": "Congenital mitral valvar stenosis"
    },
    {
        "id": "2215",
        "text": "Congenital mitral regurgitation"
    },
    {
        "id": "220W",
        "text": "Congenital anomaly of tricuspid valve"
    },
    {
        "id": "220X",
        "text": "Congenital tricuspid regurgitation"
    },
    {
        "id": "220Y",
        "text": "Congenital tricuspid valvar stenosis"
    },
    {
        "id": "220Z",
        "text": "Tricuspid annular hypoplasia"
    },
    {
        "id": "2210",
        "text": "Dysplasia of tricuspid valve"
    },
    {
        "id": "2211",
        "text": "Straddling tricuspid valve"
    },
    {
        "id": "2212",
        "text": "Overriding tricuspid valve"
    },
    {
        "id": "220U",
        "text": "Congenital anomaly of an atrioventricular valve or atrioventricular septum"
    },
    {
        "id": "220Q",
        "text": "Congenital anomaly of left atrium"
    },
    {
        "id": "220P",
        "text": "Congenital giant right atrium"
    },
    {
        "id": "220N",
        "text": "Divided right atrium"
    },
    {
        "id": "220M",
        "text": "Congenital anomaly of right atrium"
    },
    {
        "id": "220I",
        "text": "Patent oval foramen"
    },
    {
        "id": "220G",
        "text": "Aneurysm of the atrial septum"
    },
    {
        "id": "220F",
        "text": "Congenital anomaly of atrial septum"
    },
    {
        "id": "2220",
        "text": "Common atrium with common atrioventricular junction"
    },
    {
        "id": "2221",
        "text": "Atypical common atrioventricular valve"
    },
    {
        "id": "2223",
        "text": "Common atrioventricular valve with unbalanced commitment of valve to right ventricle"
    },
    {
        "id": "2225",
        "text": "Atypical right ventricular component of common atrioventricular valve"
    },
    {
        "id": "2226",
        "text": "Atypical left ventricular component of common atrioventricular valve"
    },
    {
        "id": "2227",
        "text": "Double orifice of left ventricular component of common atrioventricular valve"
    },
    {
        "id": "2228",
        "text": "Deficient mural leaflet of left ventricular component of common atrioventricular valve"
    },
    {
        "id": "221J",
        "text": "Congenital anomaly of left-sided atrioventricular valve in double inlet ventricle"
    },
    {
        "id": "221K",
        "text": "Congenital anomaly of right-sided atrioventricular valve in double inlet ventricle"
    },
    {
        "id": "217L",
        "text": "Absent right superior caval vein"
    },
    {
        "id": "217Q",
        "text": "Congenital anomaly of inferior caval vein"
    },
    {
        "id": "217R",
        "text": "Interrupted inferior caval vein with absent suprarenal segment and azygos continuation"
    },
    {
        "id": "217S",
        "text": "Congenital stenosis of inferior caval vein"
    },
    {
        "id": "217T",
        "text": "Congenital anomaly of the coronary sinus"
    },
    {
        "id": "217W",
        "text": "Congenital anomaly of pulmonary vein"
    },
    {
        "id": "217X",
        "text": "Anomalous pulmonary venous connection"
    },
    {
        "id": "217Y",
        "text": "Total anomalous pulmonary venous connection"
    },
    {
        "id": "217Z",
        "text": "Total anomalous pulmonary venous connection of the supracardiac type"
    },
    {
        "id": "2180",
        "text": "Total anomalous pulmonary venous connection of the cardiac type"
    },
    {
        "id": "2181",
        "text": "Total anomalous pulmonary venous connection of the infracardiac type"
    },
    {
        "id": "2182",
        "text": "Total anomalous pulmonary venous connection of the mixed type"
    },
    {
        "id": "2184",
        "text": "Scimitar syndrome"
    },
    {
        "id": "216H",
        "text": "Discordant atrioventricular connections"
    },
    {
        "id": "216D",
        "text": "Right isomerism"
    },
    {
        "id": "216C",
        "text": "Left isomerism"
    },
    {
        "id": "222B",
        "text": "Atrioventricular septal defect with communication at the atrial level only"
    },
    {
        "id": "222A",
        "text": "Atrioventricular septal defect with ventricular imbalance"
    },
    {
        "id": "221Y",
        "text": "Atrioventricular septal defect and tetralogy of Fallot"
    },
    {
        "id": "222C",
        "text": "Atrioventricular septal defect with communication at atrial level and unrestrictive communication at ventricular level"
    },
    {
        "id": "222D",
        "text": "Atrioventricular septal defect with communication at atrial level and restrictive communication at ventricular level"
    },
    {
        "id": "222E",
        "text": "Atrioventricular septal defect with communication at the ventricular level only"
    },
    {
        "id": "222F",
        "text": "Interrupted inferior caval vein with absent suprarenal segment and azygos continuation"
    },
    {
        "id": "2172",
        "text": "Double outlet right ventricle with subaortic or doubly committed ventricular septal defect and pulmonary stenosis, Fallot type"
    },
    {
        "id": "222O",
        "text": "Tetralogy of Fallot with pulmonary atresia"
    },
    {
        "id": "222P",
        "text": "Tetralogy of Fallot with pulmonary atresia and systemic-to-pulmonary collateral artery"
    },
    {
        "id": "222Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "222R",
        "text": "Tetralogy of Fallot"
    },
    {
        "id": "222V",
        "text": "Congenital left ventricular aneurysm or diverticulum"
    },
    {
        "id": "222W",
        "text": "Congenital left ventricular aneurysm"
    },
    {
        "id": "222Y",
        "text": "Congenital left ventricular diverticulum"
    },
    {
        "id": "2235",
        "text": "Double inlet atrioventricular connection"
    },
    {
        "id": "2236",
        "text": "Double inlet left ventricle"
    },
    {
        "id": "2237",
        "text": "Double inlet right ventricle"
    },
    {
        "id": "2238",
        "text": "Double inlet to solitary ventricle of indeterminate morphology"
    },
    {
        "id": "2239",
        "text": "Tricuspid atresia"
    },
    {
        "id": "223B",
        "text": "Mitral atresia with absent atrioventricular connection"
    },
    {
        "id": "223A",
        "text": "Tricuspid atresia with absent valvar annulus"
    },
    {
        "id": "223E",
        "text": "Mitral atresia with imperforate mitral valve"
    },
    {
        "id": "223C",
        "text": "Mitral atresia"
    },
    {
        "id": "223F",
        "text": "Hypoplastic left heart syndrome"
    },
    {
        "id": "223H",
        "text": "Prominent anxiety symptoms in mood episodes"
    },
    {
        "id": "223I",
        "text": "Premenstrual dysphoric disorder"
    },
    {
        "id": "223K",
        "text": "Congenital anomaly of pulmonary valve"
    },
    {
        "id": "223M",
        "text": "Pulmonary annular hypoplasia"
    },
    {
        "id": "223N",
        "text": "Congenital pulmonary regurgitation"
    },
    {
        "id": "223O",
        "text": "Dysplasia of pulmonary valve"
    },
    {
        "id": "223P",
        "text": "Bicuspid pulmonary valve"
    },
    {
        "id": "223Q",
        "text": "Congenital supravalvar pulmonary stenosis"
    },
    {
        "id": "223T",
        "text": "Congenital anomaly of aortic valve"
    },
    {
        "id": "223W",
        "text": "Bicuspid aortic valve"
    },
    {
        "id": "223X",
        "text": "Unicuspid aortic valve"
    },
    {
        "id": "223Y",
        "text": "Dysplasia of aortic valve"
    },
    {
        "id": "2240",
        "text": "Congenital pulmonary atresia"
    },
    {
        "id": "2241",
        "text": "Aortic annular hypoplasia"
    },
    {
        "id": "2249",
        "text": "Congenital anomaly of great arteries including arterial duct"
    },
    {
        "id": "224B",
        "text": "Congenital anomaly of pulmonary arterial tree"
    },
    {
        "id": "224C",
        "text": "Congenital dilation of pulmonary arterial tree"
    },
    {
        "id": "224D",
        "text": "Congenital pulmonary trunk anomaly"
    },
    {
        "id": "224E",
        "text": "Congenital pulmonary trunk hypoplasia"
    },
    {
        "id": "224F",
        "text": "Congenital dilation of pulmonary arterial tree"
    },
    {
        "id": "224G",
        "text": "Congenital pulmonary arterial branch anomaly"
    },
    {
        "id": "224H",
        "text": "Congenital pulmonary arterial branch stenosis"
    },
    {
        "id": "224I",
        "text": "Congenital right pulmonary arterial stenosis"
    },
    {
        "id": "224J",
        "text": "Congenital left pulmonary arterial stenosis"
    },
    {
        "id": "224K",
        "text": "Congenital pulmonary arterial branch hypoplasia"
    },
    {
        "id": "224L",
        "text": "Congenital right pulmonary arterial hypoplasia"
    },
    {
        "id": "224M",
        "text": "Congenital left pulmonary arterial hypoplasia"
    },
    {
        "id": "224N",
        "text": "Absent or atretic right pulmonary artery"
    },
    {
        "id": "224O",
        "text": "Absent or atretic left pulmonary artery"
    },
    {
        "id": "224R",
        "text": "Congenitally discontinuous, non-confluent right and left pulmonary arteries"
    },
    {
        "id": "224T",
        "text": "Right pulmonary artery from ascending aorta"
    },
    {
        "id": "224U",
        "text": "Left pulmonary artery from ascending aorta"
    },
    {
        "id": "224V",
        "text": "Pulmonary artery from arterial duct"
    },
    {
        "id": "224W",
        "text": "Right pulmonary artery from arterial duct"
    },
    {
        "id": "224X",
        "text": "Left pulmonary artery from arterial duct"
    },
    {
        "id": "224Y",
        "text": "Congenital anomaly of aorta or its branches"
    },
    {
        "id": "224Z",
        "text": "Congenital anomaly of ascending aorta"
    },
    {
        "id": "2250",
        "text": "Hypoplasia of ascending aorta"
    },
    {
        "id": "2251",
        "text": "Congenital ascending aorta aneurysm or dilation"
    },
    {
        "id": "2256",
        "text": "Interrupted aortic arch distal to subclavian artery, type A"
    },
    {
        "id": "2257",
        "text": "Interrupted aortic arch between subclavian and common carotid arteries, type B"
    },
    {
        "id": "2258",
        "text": "Interrupted aortic arch between carotid arteries, type C"
    },
    {
        "id": "2259",
        "text": "Right aortic arch"
    },
    {
        "id": "225B",
        "text": "Cervical aortic arch"
    },
    {
        "id": "225D",
        "text": "Aberrant origin of left subclavian artery"
    },
    {
        "id": "225E",
        "text": "Aberrant origin of right subclavian artery"
    },
    {
        "id": "225F",
        "text": "Aberrant origin of right subclavian artery"
    },
    {
        "id": "225G",
        "text": "Isolation of an aortic arch branch"
    },
    {
        "id": "225H",
        "text": "Descending thoracic or abdominal aortic coarctation"
    },
    {
        "id": "225I",
        "text": "Tracheo-oesophageal compressive syndrome"
    },
    {
        "id": "225C",
        "text": "Congenital anomaly of aortic arch branch"
    },
    {
        "id": "225O",
        "text": "Double aortic arch"
    },
    {
        "id": "225S",
        "text": "Anomalous origin of left pulmonary artery from right pulmonary artery"
    },
    {
        "id": "225T",
        "text": "Anomalous origin of coronary artery from pulmonary arterial tree"
    },
    {
        "id": "225U",
        "text": "Anomalous origin of left coronary artery from pulmonary artery"
    },
    {
        "id": "225V",
        "text": "Anomalous aortic origin or course of coronary artery"
    },
    {
        "id": "225X",
        "text": "Right coronary artery from left aortic sinus with ventriculo-arterial concordance"
    },
    {
        "id": "225Y",
        "text": "Left coronary artery from right aortic sinus with ventriculo-arterial concordance"
    },
    {
        "id": "225Z",
        "text": "Anterior descending from right coronary artery across right ventricular outflow tract"
    },
    {
        "id": "2260",
        "text": "Intramural proximal coronary arterial course"
    },
    {
        "id": "2261",
        "text": "Single coronary artery supplying all of heart"
    },
    {
        "id": "2262",
        "text": "Congenital coronary arterial orifice stenosis"
    },
    {
        "id": "2263",
        "text": "Congenital coronary arterial orifice atresia"
    },
    {
        "id": "2264",
        "text": "Myocardial bridging of coronary artery"
    },
    {
        "id": "2267",
        "text": "Congenital coronary arterial fistula to right ventricle"
    },
    {
        "id": "2268",
        "text": "Congenital coronary arterial fistula to the left ventricle"
    },
    {
        "id": "226E",
        "text": "Pulmonary arteriovenous fistula"
    },
    {
        "id": "226F",
        "text": "Malformative disorders of sex development"
    },
    {
        "id": "220T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "226K",
        "text": "Ventricular premature depolarization"
    },
    {
        "id": "226L",
        "text": "Catatonia induced by substances or medications"
    },
    {
        "id": "226N",
        "text": "Gender incongruence of childhood"
    },
    {
        "id": "226O",
        "text": "Specific phobia"
    },
    {
        "id": "219T",
        "text": "Chronic pelvic pain in males"
    },
    {
        "id": "226Q",
        "text": "Candida enteritis"
    },
    {
        "id": "226R",
        "text": "Candida colitis"
    },
    {
        "id": "226U",
        "text": "Atrioventricular reciprocating tachycardia"
    },
    {
        "id": "226S",
        "text": "Ascending aorta dissection not beyond arch"
    },
    {
        "id": "226V",
        "text": "Drug-induced hair abnormalities"
    },
    {
        "id": "226Z",
        "text": "Streptococcal cellulitis of skin"
    },
    {
        "id": "226Y",
        "text": "Bacterial cellulitis, erysipelas or lymphangitis"
    },
    {
        "id": "226W",
        "text": "Gastroenteritis or colitis without specification of infectious agent"
    },
    {
        "id": "2272",
        "text": "Multi-drug resistant Mycobacterium tuberculosis"
    },
    {
        "id": "2276",
        "text": "Legionellosis"
    },
    {
        "id": "2277",
        "text": "Influenza due to identified seasonal influenza virus"
    },
    {
        "id": "2278",
        "text": "Right hand pattern ventricular topology"
    },
    {
        "id": "217K",
        "text": "Left hand pattern ventricular topology"
    },
    {
        "id": "215G",
        "text": "Laevocardia"
    },
    {
        "id": "2279",
        "text": "Aortic root posterior and rightward to pulmonary root"
    },
    {
        "id": "215M",
        "text": "Aortic root anterior and rightward to pulmonary root"
    },
    {
        "id": "215P",
        "text": "Aortic root anterior and leftward to pulmonary root"
    },
    {
        "id": "215L",
        "text": "Aortic root directly anterior to pulmonary root"
    },
    {
        "id": "215Q",
        "text": "Aortic root side by side and directly rightward to pulmonary root"
    },
    {
        "id": "215R",
        "text": "Aortic root side by side and directly leftward to pulmonary root"
    },
    {
        "id": "215X",
        "text": "Visceral heterotaxy"
    },
    {
        "id": "216G",
        "text": "Concordant atrioventricular connections"
    },
    {
        "id": "216K",
        "text": "Transposition of the great arteries"
    },
    {
        "id": "215N",
        "text": "Aortic root directly anterior to pulmonary root"
    },
    {
        "id": "216O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "216P",
        "text": "Concordant ventriculo-arterial connections"
    },
    {
        "id": "216Q",
        "text": "Concordant ventriculo-arterial connections with parallel great arteries"
    },
    {
        "id": "216R",
        "text": "Double outlet right ventricle"
    },
    {
        "id": "216W",
        "text": "Double outlet right ventricle with non-committed ventricular septal defect"
    },
    {
        "id": "216Y",
        "text": "Double outlet right ventricle with subaortic ventricular septal defect without pulmonary stenosis"
    },
    {
        "id": "2170",
        "text": "Double outlet right ventricle with intact ventricular septum"
    },
    {
        "id": "2176",
        "text": "Common arterial trunk with aortic dominance and one pulmonary artery absent from the trunk, isolated pulmonary artery"
    },
    {
        "id": "2178",
        "text": "Common arterial trunk with pulmonary dominance and interrupted aortic arch"
    },
    {
        "id": "227A",
        "text": "Dysplasia of truncal valve"
    },
    {
        "id": "217A",
        "text": "Atypical truncal valve"
    },
    {
        "id": "227B",
        "text": "Atypical truncal valve"
    },
    {
        "id": "217I",
        "text": "Congenital anomaly of mediastinal systemic vein"
    },
    {
        "id": "217M",
        "text": "Left superior caval vein"
    },
    {
        "id": "217N",
        "text": "Left superior caval vein to coronary sinus"
    },
    {
        "id": "217O",
        "text": "Left superior caval vein to left-sided atrium"
    },
    {
        "id": "217P",
        "text": "Congenital stenosis of superior caval vein"
    },
    {
        "id": "227C",
        "text": "Congenital anomaly of coronary artery"
    },
    {
        "id": "217U",
        "text": "Unroofed coronary sinus"
    },
    {
        "id": "217V",
        "text": "Coronary sinus orifice atresia or stenosis"
    },
    {
        "id": "220L",
        "text": "Restrictive interatrial communication or intact atrial septum when an interatrial shunt is physiologically necessary"
    },
    {
        "id": "220H",
        "text": "Interatrial communication"
    },
    {
        "id": "220J",
        "text": "Sinus venosus defect"
    },
    {
        "id": "220K",
        "text": "Interatrial communication through coronary sinus orifice"
    },
    {
        "id": "220E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "220O",
        "text": "Left-sided juxtaposition of the atrial appendages"
    },
    {
        "id": "220R",
        "text": "Divided left atrium"
    },
    {
        "id": "220S",
        "text": "Right-sided juxtaposition of the atrial appendages"
    },
    {
        "id": "221Z",
        "text": "Congenital anomaly of an atrium or atrial septum"
    },
    {
        "id": "2213",
        "text": "Ebstein malformation of tricuspid valve"
    },
    {
        "id": "2214",
        "text": "Congenital anomaly of mitral valve"
    },
    {
        "id": "2218",
        "text": "Straddling mitral valve"
    },
    {
        "id": "221C",
        "text": "Dysplasia of mitral valve"
    },
    {
        "id": "221D",
        "text": "Supravalvar or intravalvar mitral ring"
    },
    {
        "id": "221F",
        "text": "True cleft of anterior mitral leaflet"
    },
    {
        "id": "221G",
        "text": "Congenital anomaly of mitral subvalvar apparatus"
    },
    {
        "id": "221H",
        "text": "Congenital mitral subvalvar stenosis"
    },
    {
        "id": "221I",
        "text": "Parachute malformation of mitral valve"
    },
    {
        "id": "221L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "221M",
        "text": "Common atrioventricular junction without an atrioventricular septal defect"
    },
    {
        "id": "221X",
        "text": "Atrioventricular septal defect and tetralogy of Fallot"
    },
    {
        "id": "221N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "221O",
        "text": "Atrioventricular septal defect with balanced ventricles"
    },
    {
        "id": "221P",
        "text": "Atrioventricular septal defect with ventricular imbalance"
    },
    {
        "id": "221Q",
        "text": "Atrioventricular septal defect with ventricular imbalance with dominant right ventricle and hypoplastic left ventricle"
    },
    {
        "id": "221S",
        "text": "Atrioventricular septal defect with ventricular imbalance with dominant left ventricle and hypoplastic right ventricle"
    },
    {
        "id": "221T",
        "text": "Atrioventricular septal defect with communication at the atrial level only"
    },
    {
        "id": "221U",
        "text": "Atrioventricular septal defect with communication at the ventricular level only"
    },
    {
        "id": "221V",
        "text": "Atrioventricular septal defect with communication at atrial level and restrictive communication at ventricular level"
    },
    {
        "id": "221W",
        "text": "Atrioventricular septal defect with communication at atrial level and unrestrictive communication at ventricular level"
    },
    {
        "id": "227E",
        "text": "Common atrioventricular valvar regurgitation"
    },
    {
        "id": "2222",
        "text": "Common atrioventricular valve with unbalanced commitment of valve to ventricles"
    },
    {
        "id": "2224",
        "text": "Common atrioventricular valve with unbalanced commitment of valve to left ventricle"
    },
    {
        "id": "222H",
        "text": "Congenital right ventricular anomaly"
    },
    {
        "id": "222I",
        "text": "Right ventricular hypoplasia"
    },
    {
        "id": "222J",
        "text": "Right ventricular myocardial sinusoids"
    },
    {
        "id": "222L",
        "text": "Parchment right ventricle"
    },
    {
        "id": "222K",
        "text": "Double chambered right ventricle"
    },
    {
        "id": "222M",
        "text": "Tetralogy of Fallot"
    },
    {
        "id": "222N",
        "text": "Tetralogy of Fallot with absent pulmonary valve syndrome"
    },
    {
        "id": "222S",
        "text": "Congenital left ventricular anomaly"
    },
    {
        "id": "227F",
        "text": "Left ventricular hypoplasia"
    },
    {
        "id": "2233",
        "text": "Congenital anomaly of ventricular septum"
    },
    {
        "id": "227G",
        "text": "Communication between left ventricle and right atrium"
    },
    {
        "id": "222Z",
        "text": "Left heart obstruction at multiple sites"
    },
    {
        "id": "2230",
        "text": "Left ventricular myocardial sinusoids"
    },
    {
        "id": "227I",
        "text": "Subaortic stenosis due to fibromuscular shelf"
    },
    {
        "id": "227J",
        "text": "Subaortic stenosis due to fibromuscular tunnel"
    },
    {
        "id": "227M",
        "text": "Subaortic stenosis due to fibromuscular tunnel"
    },
    {
        "id": "2244",
        "text": "Congenital subaortic stenosis"
    },
    {
        "id": "2232",
        "text": "Congenital left ventricular outflow tract obstruction due to atrioventricular valve"
    },
    {
        "id": "227O",
        "text": "Congenital right ventricular outflow tract obstruction"
    },
    {
        "id": "227P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "227R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "227Q",
        "text": "Obstructive hypertrophic cardiomyopathy"
    },
    {
        "id": "227S",
        "text": "Acquired subaortic stenosis"
    },
    {
        "id": "227T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "227U",
        "text": "Ventricular septal defect"
    },
    {
        "id": "227W",
        "text": "Digital or periungual warts"
    },
    {
        "id": "227Y",
        "text": "Wart virus proliferation in immune-deficient states"
    },
    {
        "id": "2281",
        "text": "Inlet ventricular septal defect without a common atrioventricular junction"
    },
    {
        "id": "2283",
        "text": "Inlet perimembranous ventricular septal defect without atrioventricular septal malalignment without a common atrioventricular junction"
    },
    {
        "id": "2284",
        "text": "Inlet perimembranous ventricular septal defect with atrioventricular septal malalignment and without a common atrioventricular junction"
    },
    {
        "id": "2282",
        "text": "Inlet muscular ventricular septal defect"
    },
    {
        "id": "2285",
        "text": "Trabecular muscular ventricular septal defect midseptal"
    },
    {
        "id": "2286",
        "text": "Trabecular muscular ventricular septal defect apical"
    },
    {
        "id": "2287",
        "text": "Trabecular muscular ventricular septal defect postero-inferior"
    },
    {
        "id": "2288",
        "text": "Multiple trabecular muscular ventricular septal defects"
    },
    {
        "id": "2289",
        "text": "Trabecular muscular ventricular septal defect anterosuperior"
    },
    {
        "id": "227V",
        "text": "Perimembranous central ventricular septal defect"
    },
    {
        "id": "228A",
        "text": "Outlet ventricular septal defect"
    },
    {
        "id": "228B",
        "text": "Outlet ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "228C",
        "text": "Outlet muscular ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "228D",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum and muscular postero-inferior rim"
    },
    {
        "id": "228E",
        "text": "Outlet perimembranous ventricular septal defect with anteriorly malaligned outlet septum"
    },
    {
        "id": "228F",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum"
    },
    {
        "id": "228G",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "228H",
        "text": "Outlet ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "228I",
        "text": "Outlet muscular ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "228J",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum"
    },
    {
        "id": "228K",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and muscular postero-inferior rim"
    },
    {
        "id": "228L",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "228M",
        "text": "Outlet perimembranous ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "228N",
        "text": "Outlet ventricular septal defect without malalignment"
    },
    {
        "id": "228O",
        "text": "Outlet muscular ventricular septal defect without malalignment"
    },
    {
        "id": "228P",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment"
    },
    {
        "id": "228Q",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment and with perimembranous extension"
    },
    {
        "id": "228R",
        "text": "Doubly committed juxta-arterial ventricular septal defect without malalignment and with muscular postero-inferior rim"
    },
    {
        "id": "228S",
        "text": "Multiple ventricular septal defects"
    },
    {
        "id": "228T",
        "text": "Ventricular septal defect haemodynamically insignificant"
    },
    {
        "id": "228U",
        "text": "Restrictive interventricular communication when an interventricular shunt is physiologically necessary"
    },
    {
        "id": "2234",
        "text": "Functionally univentricular heart"
    },
    {
        "id": "228V",
        "text": "Tricuspid atresia with imperforate tricuspid valve"
    },
    {
        "id": "223R",
        "text": "Congenital pulmonary atresia"
    },
    {
        "id": "228W",
        "text": "Trabecular muscular ventricular septal defect"
    },
    {
        "id": "223L",
        "text": "Congenital pulmonary valvar stenosis"
    },
    {
        "id": "223J",
        "text": "Congenital anomaly of a ventriculo-arterial valve or adjacent regions"
    },
    {
        "id": "223S",
        "text": "Pulmonary atresia with intact ventricular septum"
    },
    {
        "id": "227N",
        "text": "Congenital subpulmonary stenosis"
    },
    {
        "id": "223Z",
        "text": "Aortic valvar atresia"
    },
    {
        "id": "228X",
        "text": "Congenital aortic valvar stenosis"
    },
    {
        "id": "223V",
        "text": "Congenital aortic regurgitation"
    },
    {
        "id": "228Y",
        "text": "Congenital supravalvar aortic stenosis"
    },
    {
        "id": "2243",
        "text": "Aneurysm of aortic sinus of Valsalva"
    },
    {
        "id": "228Z",
        "text": "Aortoventricular tunnel"
    },
    {
        "id": "2290",
        "text": "Congenital aortopulmonary window"
    },
    {
        "id": "2291",
        "text": "Absent or atretic pulmonary trunk"
    },
    {
        "id": "224Q",
        "text": "Congenital peripheral pulmonary arterial stenosis or hypoplasia at or beyond hilar bifurcation"
    },
    {
        "id": "2252",
        "text": "Congenital anomaly of aortic arch"
    },
    {
        "id": "2253",
        "text": "Hypoplasia of aortic arch"
    },
    {
        "id": "224P",
        "text": "Congenital central pulmonary arterial stenosis or hypoplasia proximal to hilar bifurcation"
    },
    {
        "id": "224S",
        "text": "Pulmonary artery origin from ascending aorta"
    },
    {
        "id": "2255",
        "text": "Interrupted aortic arch"
    },
    {
        "id": "2292",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2254",
        "text": "Coarctation of aorta"
    },
    {
        "id": "225K",
        "text": "Congenital arterial duct anomaly"
    },
    {
        "id": "225L",
        "text": "Patent arterial duct"
    },
    {
        "id": "225A",
        "text": "Left aortic arch"
    },
    {
        "id": "225J",
        "text": "Innominate artery compression syndrome"
    },
    {
        "id": "225N",
        "text": "Vascular ring"
    },
    {
        "id": "225Q",
        "text": "Vascular ring of right aortic arch and left arterial duct or ligament"
    },
    {
        "id": "225R",
        "text": "Vascular ring of left aortic arch and right arterial duct or ligament"
    },
    {
        "id": "225M",
        "text": "Systemic-to-pulmonary collateral arteries"
    },
    {
        "id": "2293",
        "text": "Anomalous aortic origin of coronary artery with ventriculo-arterial concordance"
    },
    {
        "id": "2266",
        "text": "Congenital coronary arterial fistula"
    },
    {
        "id": "2265",
        "text": "Congenital coronary arterial aneurysm"
    },
    {
        "id": "226D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2269",
        "text": "Congenital pericardial anomaly"
    },
    {
        "id": "226A",
        "text": "Complete agenesis of pericardium"
    },
    {
        "id": "226B",
        "text": "Partial agenesis of pericardium"
    },
    {
        "id": "226C",
        "text": "Pleuropericardial cyst"
    },
    {
        "id": "222G",
        "text": "Congenital anomaly of a ventricle or the ventricular septum"
    },
    {
        "id": "2295",
        "text": "Heart failure"
    },
    {
        "id": "2296",
        "text": "Heart failure"
    },
    {
        "id": "2294",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2152",
        "text": "Delusional disorder"
    },
    {
        "id": "2153",
        "text": "Symptomatic manifestations of primary psychotic disorders"
    },
    {
        "id": "2280",
        "text": "Herpes simplex Kaposi varicelliform eruption complicating other dermatosis except atopic eczema"
    },
    {
        "id": "227Z",
        "text": "Disseminated cutaneous herpes simplex infection complicating other skin diseases"
    },
    {
        "id": "227X",
        "text": "Digital or periungual warts"
    },
    {
        "id": "214K",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "229B",
        "text": "Endometrial stromal sarcoma, primary site"
    },
    {
        "id": "229C",
        "text": "Choroid plexus tumours"
    },
    {
        "id": "229E",
        "text": "Meningiomas"
    },
    {
        "id": "229F",
        "text": "Malignant neoplasms of other endocrine glands or related structures"
    },
    {
        "id": "229J",
        "text": "Rett syndrome"
    },
    {
        "id": "229L",
        "text": "Anogenital warts"
    },
    {
        "id": "229O",
        "text": "Diseases of the genitourinary system"
    },
    {
        "id": "229U",
        "text": "Other late congenital syphilis, symptomatic"
    },
    {
        "id": "229V",
        "text": "Biopsy procedure, not elsewhere classified, associated with injury or harm in therapeutic use"
    },
    {
        "id": "2299",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2303",
        "text": "Epidemic louse-borne typhus fever due to Rickettsia prowazekii"
    },
    {
        "id": "2306",
        "text": "Sepsis of fetus or newborn"
    },
    {
        "id": "214O",
        "text": "Schizophrenia"
    },
    {
        "id": "229A",
        "text": "Schizotypal disorder"
    },
    {
        "id": "214Q",
        "text": "Schizotypal disorder"
    },
    {
        "id": "214N",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "214P",
        "text": "Schizoaffective disorder"
    },
    {
        "id": "230D",
        "text": "Cerebral ischaemic stroke due to paradoxical embolism"
    },
    {
        "id": "230E",
        "text": "Left ventricular failure with preserved ejection fraction"
    },
    {
        "id": "230J",
        "text": "Gastroenteritis due to Campylobacter"
    },
    {
        "id": "229S",
        "text": "Candidosis"
    },
    {
        "id": "230M",
        "text": "Health Devices, Equipment and Supplies"
    },
    {
        "id": "230H",
        "text": "Health Devices, Equipment and Supplies"
    },
    {
        "id": "230N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "230O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "230P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "230Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "230R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "230T",
        "text": "Schizophrenia"
    },
    {
        "id": "230U",
        "text": "Delusional disorder"
    },
    {
        "id": "220V",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "217J",
        "text": "Congenital anomaly of position or spatial relationships of thoraco-abdominal organs"
    },
    {
        "id": "2156",
        "text": "Usual atrial arrangement"
    },
    {
        "id": "230W",
        "text": "Right common carotid artery"
    },
    {
        "id": "230X",
        "text": "Anterior vesical lymphatic vessel of the bladder"
    },
    {
        "id": "2312",
        "text": "Infection of vertebra"
    },
    {
        "id": "2311",
        "text": "Hereditary fructose intolerance"
    },
    {
        "id": "230Y",
        "text": "Primary intra-abdominal hernia"
    },
    {
        "id": "2310",
        "text": "Hydrocephalus with stenosis of the aqueduct of Sylvius"
    },
    {
        "id": "230Z",
        "text": "Contralateral"
    },
    {
        "id": "2313",
        "text": "Phase of activity, other specified phase of activity"
    },
    {
        "id": "2314",
        "text": "Unspecified phase of activity"
    },
    {
        "id": "2315",
        "text": "Rupture of Achilles tendon"
    },
    {
        "id": "230G",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "2316",
        "text": "Macro reentrant atrial tachycardia"
    },
    {
        "id": "230F",
        "text": "Seborrhoea"
    },
    {
        "id": "219L",
        "text": "Schizophrenia or other primary psychotic disorders"
    },
    {
        "id": "231A",
        "text": "Ophthalmic Lyme borreliosis"
    },
    {
        "id": "231B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "231C",
        "text": "Borrelia burgdorferi dacryoadenitis"
    },
    {
        "id": "231D",
        "text": "Borrelia burgdorferi orbital myositis"
    },
    {
        "id": "231E",
        "text": "Dementia due to Lyme disease"
    },
    {
        "id": "231F",
        "text": "Myelitis associated with mycoplasma pneumoniae infection"
    },
    {
        "id": "231G",
        "text": "Central nervous system demyelination due to Lyme borreliosis"
    },
    {
        "id": "231H",
        "text": "Infectious panuveitis in Lyme disease"
    },
    {
        "id": "2318",
        "text": "Lyme borreliosis"
    },
    {
        "id": "231I",
        "text": "Bacterial sepsis with septic shock"
    },
    {
        "id": "231J",
        "text": "Septicaemia"
    },
    {
        "id": "231K",
        "text": "Sepsis due to other Gram-negative organisms without mention of septic shock"
    },
    {
        "id": "231M",
        "text": "Acute arterial occlusion"
    },
    {
        "id": "231P",
        "text": "Surface topography"
    },
    {
        "id": "231T",
        "text": "Syndrome of infant of a diabetic mother, type 1 or 2, nongestational, insulin dependent"
    },
    {
        "id": "230C",
        "text": "Nonclassical phenylketonuria"
    },
    {
        "id": "231U",
        "text": "Benign neoplasm of ill-defined site within the digestive system"
    },
    {
        "id": "231W",
        "text": "Malignant neoplasm metastases in lymph nodes of multiple regions"
    },
    {
        "id": "2124",
        "text": "Staphylococcal scarlatina"
    },
    {
        "id": "2123",
        "text": "Erythema marginatum rheumaticum"
    },
    {
        "id": "231Z",
        "text": "Thymus"
    },
    {
        "id": "2320",
        "text": "Cheek-biting"
    },
    {
        "id": "2321",
        "text": "Volatile inhalant-induced mood disorder, with mixed depressive and manic symptoms"
    },
    {
        "id": "2327",
        "text": "Disorder of intellectual development, severe"
    },
    {
        "id": "2328",
        "text": "Hydronephrosis with ureteropelvic junction obstruction due to cause classified elsewhere"
    },
    {
        "id": "2329",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2325",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2324",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2326",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "232A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "232B",
        "text": "Caregiver-child relationship problem with current caregiver"
    },
    {
        "id": "232C",
        "text": "Caregiver-child relationship problem with former caregiver"
    },
    {
        "id": "232D",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "232I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "232J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "231V",
        "text": "Hypoactive sexual desire dysfunction"
    },
    {
        "id": "232K",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "232M",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "232N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "232O",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "232H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "232P",
        "text": "Neoplasms of unknown behaviour of endocrine glands"
    },
    {
        "id": "232G",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "232Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "232U",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "231Y",
        "text": "Human immunodeficiency disease complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "231X",
        "text": "Pulmonary infection due to non-tuberculous mycobacterium"
    },
    {
        "id": "2309",
        "text": "Encephalitis due to cysticercosis"
    },
    {
        "id": "2308",
        "text": "Meningitis due to Cysticercosis"
    },
    {
        "id": "2307",
        "text": "Congenital viral hepatitis"
    },
    {
        "id": "2305",
        "text": "Pneumonia due to Human metapneumovirus"
    },
    {
        "id": "2304",
        "text": "Recrudescent typhus"
    },
    {
        "id": "2302",
        "text": "Renal tubulo-interstitial disorders due to toxoplasmoa gondii"
    },
    {
        "id": "2301",
        "text": "Myositis due to toxoplasmosis"
    },
    {
        "id": "2300",
        "text": "Myocarditis due to toxoplasmosis"
    },
    {
        "id": "229Y",
        "text": "Congenital toxoplasma encephalitis"
    },
    {
        "id": "229T",
        "text": "Parasitic diseases"
    },
    {
        "id": "232V",
        "text": "Allergens"
    },
    {
        "id": "2317",
        "text": "Developmental anomalies"
    },
    {
        "id": "2332",
        "text": "Cause of late effect"
    },
    {
        "id": "2334",
        "text": "Pregnancy"
    },
    {
        "id": "2335",
        "text": "Postpartum"
    },
    {
        "id": "2336",
        "text": "Puerperium"
    },
    {
        "id": "2337",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2338",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2339",
        "text": "Caregiver-child relationship problem with former caregiver"
    },
    {
        "id": "233A",
        "text": "Caregiver-child relationship problem with current caregiver"
    },
    {
        "id": "233B",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "233C",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "233D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "233E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "233F",
        "text": "Caregiver-child relationship problem with former caregiver"
    },
    {
        "id": "233G",
        "text": "Caregiver-child relationship problem with current caregiver"
    },
    {
        "id": "2271",
        "text": "Diseases of the digestive system"
    },
    {
        "id": "233J",
        "text": "Bipolar type II disorder"
    },
    {
        "id": "233I",
        "text": "Autosomal dominant tubulointerstitial disease, Type 1"
    },
    {
        "id": "233K",
        "text": "Gaming disorder"
    },
    {
        "id": "233H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "233O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "233P",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "233Q",
        "text": "Caregiver-child relationship problem with current caregiver"
    },
    {
        "id": "233R",
        "text": "Caregiver-child relationship problem with former caregiver"
    },
    {
        "id": "233S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "233T",
        "text": "Mononuclear phagocyte system"
    },
    {
        "id": "2322",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "233U",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "223G",
        "text": "Anorexia Nervosa"
    },
    {
        "id": "233V",
        "text": "Problems associated with social or cultural environment"
    },
    {
        "id": "233W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "233Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "233Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2340",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2341",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2342",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "234A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "234B",
        "text": "Gaming disorder"
    },
    {
        "id": "234C",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "234E",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "234J",
        "text": "Diseases of the skin complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "234O",
        "text": "Complex vision-related dysfunctions"
    },
    {
        "id": "234N",
        "text": "Specific vision dysfunctions"
    },
    {
        "id": "234M",
        "text": "Near vision impairment"
    },
    {
        "id": "234P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "234Q",
        "text": "Patterns of visual field impairment"
    },
    {
        "id": "234R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "234S",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "234I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "234T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "234U",
        "text": "Nontoxic single thyroid nodule"
    },
    {
        "id": "232S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "234V",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "234W",
        "text": "Dissociated intelligence"
    },
    {
        "id": "234Z",
        "text": "Lymphoedema due to surgery or radiotherapy"
    },
    {
        "id": "2350",
        "text": "Postprocedural right atrial complication"
    },
    {
        "id": "2351",
        "text": "Postprocedural left atrial complication"
    },
    {
        "id": "2352",
        "text": "Fibromatoses and keloids"
    },
    {
        "id": "2353",
        "text": "Fibromatoses and keloids"
    },
    {
        "id": "2354",
        "text": "Keloidal surgical scar"
    },
    {
        "id": "2355",
        "text": "Hypertrophic surgical scar"
    },
    {
        "id": "2356",
        "text": "Unsatisfactory surgical scar of skin"
    },
    {
        "id": "2357",
        "text": "Postprocedural disorders of the skin"
    },
    {
        "id": "2358",
        "text": "Postprocedural disorders of the skin"
    },
    {
        "id": "2359",
        "text": "Adverse cutaneous effects of therapeutic ionizing irradiation"
    },
    {
        "id": "235A",
        "text": "Adverse cutaneous effects of diagnostic procedures"
    },
    {
        "id": "235B",
        "text": "Superficial injury of unspecified body region"
    },
    {
        "id": "235C",
        "text": "Induced abortion"
    },
    {
        "id": "235D",
        "text": "Induced abortion"
    },
    {
        "id": "235E",
        "text": "Acute upper respiratory infections of multiple and unspecified sites"
    },
    {
        "id": "235F",
        "text": "Maternal care related to multiple gestation"
    },
    {
        "id": "235G",
        "text": "Abortive outcome of pregnancy"
    },
    {
        "id": "235H",
        "text": "Perineal laceration during delivery"
    },
    {
        "id": "235I",
        "text": "Obstetric high vaginal laceration"
    },
    {
        "id": "235J",
        "text": "Labour or delivery complicated by umbilical cord complications"
    },
    {
        "id": "235K",
        "text": "Benign mesenchymal neoplasms"
    },
    {
        "id": "235N",
        "text": "Galactokinase deficiency"
    },
    {
        "id": "235P",
        "text": "Presence of coronary angioplasty implant or graft"
    },
    {
        "id": "235O",
        "text": "Personality disorders and related traits"
    },
    {
        "id": "235Q",
        "text": "Personality disorder"
    },
    {
        "id": "235R",
        "text": "Nonpneumonic Legionnaires' disease"
    },
    {
        "id": "235T",
        "text": "Food challenge"
    },
    {
        "id": "235U",
        "text": "Drug challenge"
    },
    {
        "id": "235V",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "235W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "235X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "235Y",
        "text": "Caregiver-child relationship problem with current caregiver"
    },
    {
        "id": "235Z",
        "text": "Caregiver-child relationship problem with former caregiver"
    },
    {
        "id": "2360",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2361",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2362",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2363",
        "text": "Caregiver-child relationship problem with former caregiver"
    },
    {
        "id": "2365",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2366",
        "text": "Benign non-mesenchymal neoplasms"
    },
    {
        "id": "2367",
        "text": "Borderline pattern"
    },
    {
        "id": "2368",
        "text": "Myokymia"
    },
    {
        "id": "234X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "236A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "236D",
        "text": "Certain specified caffeine-induced mental or behavioural disorders"
    },
    {
        "id": "236F",
        "text": "Burnout"
    },
    {
        "id": "236C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "236H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "236I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2323",
        "text": "Hereditary"
    },
    {
        "id": "236J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "236K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "236M",
        "text": "Counselling related to sexual attitudes"
    },
    {
        "id": "236O",
        "text": "Staphylococcal toxin-mediated recalcitrant erythema and desquamation"
    },
    {
        "id": "236U",
        "text": "Impaction of large intestine"
    },
    {
        "id": "236V",
        "text": "Impaction of large intestine"
    },
    {
        "id": "236W",
        "text": "Functional constipation"
    },
    {
        "id": "236X",
        "text": "Functional constipation"
    },
    {
        "id": "236Y",
        "text": "Constipation"
    },
    {
        "id": "236Z",
        "text": "Constipation"
    },
    {
        "id": "2370",
        "text": "Perpetrator-victim relationship, step-parent"
    },
    {
        "id": "2375",
        "text": "Late-onset isolated ACTH deficiency"
    },
    {
        "id": "2376",
        "text": "Psychological maltreatment"
    },
    {
        "id": "2374",
        "text": "Liver diseases due to porphyria"
    },
    {
        "id": "2377",
        "text": "Autoimmune polyendocrinopathy type 1"
    },
    {
        "id": "2379",
        "text": "Chronic pain after musculoskeletal injury"
    },
    {
        "id": "237G",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "237I",
        "text": "Bundle branch reentry tachycardia"
    },
    {
        "id": "237H",
        "text": "Automatic idioventricular rhythm"
    },
    {
        "id": "237J",
        "text": "Molybdenum cofactor deficiency, complementation group A"
    },
    {
        "id": "237L",
        "text": "Propionic aciduria"
    },
    {
        "id": "237M",
        "text": "3-methylglutaconic aciduria type 5"
    },
    {
        "id": "237B",
        "text": "Homocystinuria without methylmalonic aciduria"
    },
    {
        "id": "237A",
        "text": "Brain demyelination due to methionine adenosyltransferase deficiency"
    },
    {
        "id": "237N",
        "text": "Dilated cardiomyopathy due to glycogen branching enzyme deficiency"
    },
    {
        "id": "237O",
        "text": "Congenital bile acid synthesis defect type 2"
    },
    {
        "id": "237P",
        "text": "Multi-infarct dementia"
    },
    {
        "id": "2298",
        "text": "Infections of the anus or perianal skin"
    },
    {
        "id": "237Q",
        "text": "Diagnosis certainty"
    },
    {
        "id": "237S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "236G",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2138",
        "text": "Sleep-wake disorders"
    },
    {
        "id": "237T",
        "text": "Insomnia disorders"
    },
    {
        "id": "2163",
        "text": "Short-term insomnia"
    },
    {
        "id": "209X",
        "text": "Hypersomnolence disorders"
    },
    {
        "id": "209Y",
        "text": "Narcolepsy"
    },
    {
        "id": "209Z",
        "text": "Narcolepsy, Type 1"
    },
    {
        "id": "2100",
        "text": "Narcolepsy, Type 2"
    },
    {
        "id": "237U",
        "text": "Narcolepsy"
    },
    {
        "id": "237V",
        "text": "Narcolepsy"
    },
    {
        "id": "2101",
        "text": "Idiopathic hypersomnia"
    },
    {
        "id": "2102",
        "text": "Kleine-Levin syndrome"
    },
    {
        "id": "2103",
        "text": "Hypersomnia due to a medical condition"
    },
    {
        "id": "2104",
        "text": "Hypersomnia due to a medication or substance"
    },
    {
        "id": "237W",
        "text": "Hypersomnia associated with a mental disorder"
    },
    {
        "id": "2106",
        "text": "Insufficient sleep syndrome"
    },
    {
        "id": "2164",
        "text": "Sleep-related breathing disorders"
    },
    {
        "id": "2165",
        "text": "Central sleep apnoeas"
    },
    {
        "id": "2109",
        "text": "Primary central sleep apnoea"
    },
    {
        "id": "210A",
        "text": "Primary central sleep apnoea of infancy"
    },
    {
        "id": "210B",
        "text": "Primary central sleep apnoea of prematurity"
    },
    {
        "id": "210C",
        "text": "Central sleep apnoea due to a medical condition with Cheyne-Stokes breathing"
    },
    {
        "id": "210D",
        "text": "Central sleep apnoea due to a medical condition without Cheyne-Stokes breathing"
    },
    {
        "id": "210E",
        "text": "Central sleep apnoea due to high-altitude periodic breathing"
    },
    {
        "id": "210F",
        "text": "Central sleep apnoea due to a medication or substance"
    },
    {
        "id": "210G",
        "text": "Treatment-emergent central sleep apnoea"
    },
    {
        "id": "210H",
        "text": "Obstructive sleep apnoea"
    },
    {
        "id": "210I",
        "text": "Sleep-related hypoventilation or hypoxemia disorders"
    },
    {
        "id": "210J",
        "text": "Obesity hypoventilation syndrome"
    },
    {
        "id": "210K",
        "text": "Congenital central alveolar sleep-related hypoventilation"
    },
    {
        "id": "237X",
        "text": "Non-congenital central hypoventilation with hypothalamic abnormalities"
    },
    {
        "id": "210L",
        "text": "Idiopathic central alveolar hypoventilation"
    },
    {
        "id": "210M",
        "text": "Sleep-related hypoventilation due to a medication or substance"
    },
    {
        "id": "210N",
        "text": "Sleep-related hypoventilation due to medical condition"
    },
    {
        "id": "2380",
        "text": "Central sleep apnoea due to a medication or substance"
    },
    {
        "id": "237Y",
        "text": "Sleep-related hypoxemia due to a medical condition"
    },
    {
        "id": "2382",
        "text": "Circadian rhythm sleep-wake disorders"
    },
    {
        "id": "2383",
        "text": "Delayed sleep-wake phase disorder"
    },
    {
        "id": "2384",
        "text": "Advanced sleep-wake phase disorder"
    },
    {
        "id": "2385",
        "text": "Irregular sleep-wake rhythm disorder"
    },
    {
        "id": "2386",
        "text": "Non-24 hour sleep-wake rhythm disorder"
    },
    {
        "id": "2387",
        "text": "Circadian rhythm sleep-wake disorder, shift work type"
    },
    {
        "id": "2388",
        "text": "Circadian rhythm sleep-wake disorder, jet lag type"
    },
    {
        "id": "213A",
        "text": "Restless legs syndrome"
    },
    {
        "id": "213B",
        "text": "Periodic limb movement disorder"
    },
    {
        "id": "213C",
        "text": "Sleep-related leg cramps"
    },
    {
        "id": "2389",
        "text": "Sleep-related bruxism"
    },
    {
        "id": "213E",
        "text": "Sleep-related rhythmic movement disorder"
    },
    {
        "id": "213I",
        "text": "Sleep-related movement disorder due to a medication or substance"
    },
    {
        "id": "213J",
        "text": "Parasomnia disorders"
    },
    {
        "id": "213K",
        "text": "Disorders of arousal from non-REM sleep"
    },
    {
        "id": "238B",
        "text": "Confusional arousals"
    },
    {
        "id": "213M",
        "text": "Sleepwalking disorder"
    },
    {
        "id": "213N",
        "text": "Sleep terrors"
    },
    {
        "id": "213O",
        "text": "Sleep-related eating disorder"
    },
    {
        "id": "213Q",
        "text": "REM sleep behaviour disorder"
    },
    {
        "id": "213R",
        "text": "Recurrent isolated sleep paralysis"
    },
    {
        "id": "213S",
        "text": "Nightmare disorder"
    },
    {
        "id": "238C",
        "text": "Other parasomnias"
    },
    {
        "id": "213T",
        "text": "Hypnagogic exploding head syndrome"
    },
    {
        "id": "213U",
        "text": "Sleep-related hallucinations"
    },
    {
        "id": "238D",
        "text": "Abortive outcome of pregnancy"
    },
    {
        "id": "238E",
        "text": "Chronic central post stroke pain"
    },
    {
        "id": "200G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "238K",
        "text": "Disturbances in tooth formation"
    },
    {
        "id": "238L",
        "text": "Hyperdontia"
    },
    {
        "id": "238M",
        "text": "Dyspnoea"
    },
    {
        "id": "238N",
        "text": "Dyspnoea"
    },
    {
        "id": "237R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "238O",
        "text": "Pedophilic disorder"
    },
    {
        "id": "2378",
        "text": "Fibromyalgia syndrome"
    },
    {
        "id": "2344",
        "text": "Developmental learning disorder"
    },
    {
        "id": "2345",
        "text": "Developmental learning disorder with impairment in written expression"
    },
    {
        "id": "2346",
        "text": "Developmental learning disorder with impairment in mathematics"
    },
    {
        "id": "2347",
        "text": "Developmental learning disorder with other specified impairment of learning"
    },
    {
        "id": "238G",
        "text": "Chronic visceral cancer pain"
    },
    {
        "id": "238H",
        "text": "Chronic neuropathic cancer pain"
    },
    {
        "id": "238I",
        "text": "Chronic whiplash injury associated pain"
    },
    {
        "id": "238J",
        "text": "Chronic neuropathic pain after peripheral nerve injury"
    },
    {
        "id": "238S",
        "text": "Chronic pain after amputation"
    },
    {
        "id": "233N",
        "text": "Extensive adenomatosis of nipple"
    },
    {
        "id": "233L",
        "text": "Orofacial motor coordination disorder"
    },
    {
        "id": "237F",
        "text": "Post traumatic stress disorder"
    },
    {
        "id": "238T",
        "text": "Complex post traumatic stress disorder"
    },
    {
        "id": "235S",
        "text": "Malignant medulloepithelioma of ciliary body"
    },
    {
        "id": "230I",
        "text": "Intestinal infections due to Clostridioides difficile"
    },
    {
        "id": "230K",
        "text": "Megacolon due to Clostridioides difficile"
    },
    {
        "id": "238U",
        "text": "Other antibiotic resistant Enterobacterales"
    },
    {
        "id": "238V",
        "text": "Endocrine, nutritional or metabolic diseases"
    },
    {
        "id": "238W",
        "text": "Diseases of the blood or blood-forming organs"
    },
    {
        "id": "238X",
        "text": "Lentivirus"
    },
    {
        "id": "238Z",
        "text": "Anaemia due to acute disease"
    },
    {
        "id": "2390",
        "text": "Gram Negative Bacteria"
    },
    {
        "id": "238Y",
        "text": "Certain specified inflammatory arthropathies"
    },
    {
        "id": "2391",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2392",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2393",
        "text": "Blackfan-Diamond anaemia"
    },
    {
        "id": "2395",
        "text": "Immune thrombocytopenic purpura"
    },
    {
        "id": "2396",
        "text": "Chronic neuropathic cancer pain"
    },
    {
        "id": "2397",
        "text": "Chronic post cancer treatment pain"
    },
    {
        "id": "2398",
        "text": "Chronic post cancer medicine pain"
    },
    {
        "id": "2399",
        "text": "Chronic post radiotherapy pain"
    },
    {
        "id": "239C",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "239B",
        "text": "Chronic primary pain"
    },
    {
        "id": "239D",
        "text": "Chronic widespread pain"
    },
    {
        "id": "239E",
        "text": "Chronic primary musculoskeletal pain"
    },
    {
        "id": "200H",
        "text": "Chronic primary headache or orofacial pain"
    },
    {
        "id": "1Z20",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "239F",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "239G",
        "text": "Chronic cancer pain"
    },
    {
        "id": "239H",
        "text": "Chronic postsurgical or post traumatic pain"
    },
    {
        "id": "239I",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "238Q",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "239J",
        "text": "Chronic neuropathic pain"
    },
    {
        "id": "239K",
        "text": "Chronic peripheral neuropathic pain"
    },
    {
        "id": "239L",
        "text": "Chronic central neuropathic pain"
    },
    {
        "id": "200I",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "239M",
        "text": "Chronic dental pain"
    },
    {
        "id": "200K",
        "text": "Headache or orofacial pain associated with chronic secondary temporomandibular disorders"
    },
    {
        "id": "200J",
        "text": "Chronic headache or orofacial pain associated with infection"
    },
    {
        "id": "239N",
        "text": "Chronic secondary visceral pain"
    },
    {
        "id": "239O",
        "text": "Chronic visceral pain from persistent inflammation"
    },
    {
        "id": "239P",
        "text": "Chronic visceral pain from vascular mechanisms"
    },
    {
        "id": "239Q",
        "text": "Chronic visceral pain from mechanical factors"
    },
    {
        "id": "239R",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "239S",
        "text": "Chronic secondary musculoskeletal pain from persistent inflammation"
    },
    {
        "id": "239T",
        "text": "Chronic secondary musculoskeletal pain associated with structural changes"
    },
    {
        "id": "239U",
        "text": "Chronic secondary musculoskeletal pain due to disease of the nervous system"
    },
    {
        "id": "239V",
        "text": "Time in Life"
    },
    {
        "id": "239W",
        "text": "Familial Alzheimer-like prion disease"
    },
    {
        "id": "239X",
        "text": "Multiple mononeuropathy vasculitis with perinuclear antineutrophil cytoplasmic antibody-associated vasculitis"
    },
    {
        "id": "2404",
        "text": "Anatomy and topography"
    },
    {
        "id": "239Z",
        "text": "Diseases of veins"
    },
    {
        "id": "2407",
        "text": "Diseases of the digestive system"
    },
    {
        "id": "240G",
        "text": "Oedema"
    },
    {
        "id": "240H",
        "text": "Brainstem"
    },
    {
        "id": "240L",
        "text": "Cartilage"
    },
    {
        "id": "240Q",
        "text": "Brain"
    },
    {
        "id": "240P",
        "text": "Cerebral white matter"
    },
    {
        "id": "240M",
        "text": "Joints and ligaments"
    },
    {
        "id": "240K",
        "text": "Kidney"
    },
    {
        "id": "240J",
        "text": "Lung"
    },
    {
        "id": "2405",
        "text": "Cerebrovascular diseases"
    },
    {
        "id": "240E",
        "text": "Blood Vessels"
    },
    {
        "id": "2400",
        "text": "Diseases of the blood or blood-forming organs"
    },
    {
        "id": "240R",
        "text": "Gastrointestinal tract"
    },
    {
        "id": "240S",
        "text": "Veins"
    },
    {
        "id": "240T",
        "text": "Arteries"
    },
    {
        "id": "240D",
        "text": "Choroid plexus"
    },
    {
        "id": "2403",
        "text": "Dementia"
    },
    {
        "id": "240A",
        "text": "Lymphatic vessel of the face"
    },
    {
        "id": "240B",
        "text": "Pineal\u00a0gland"
    },
    {
        "id": "240F",
        "text": "Heart"
    },
    {
        "id": "240C",
        "text": "Hypothalamus"
    },
    {
        "id": "2409",
        "text": "Bone tissue"
    },
    {
        "id": "2408",
        "text": "Diseases of spleen"
    },
    {
        "id": "2401",
        "text": "Diseases of the immune system"
    },
    {
        "id": "240U",
        "text": "Disorders of the hair follicle"
    },
    {
        "id": "240V",
        "text": "Prostate gland"
    },
    {
        "id": "240W",
        "text": "Spinal cord"
    },
    {
        "id": "240X",
        "text": "Lymphatic tissue"
    },
    {
        "id": "240Y",
        "text": "Adrenal gland"
    },
    {
        "id": "240Z",
        "text": "Pancreas"
    },
    {
        "id": "2412",
        "text": "Testis"
    },
    {
        "id": "2414",
        "text": "Ovary"
    },
    {
        "id": "2415",
        "text": "Tendons"
    },
    {
        "id": "2416",
        "text": "Pituitary\u00a0gland"
    },
    {
        "id": "2417",
        "text": "Thyroid\u00a0gland"
    },
    {
        "id": "2418",
        "text": "Parathyroid\u00a0gland"
    },
    {
        "id": "2419",
        "text": "Urinary bladder"
    },
    {
        "id": "241A",
        "text": "Uterus"
    },
    {
        "id": "241B",
        "text": "Breast"
    },
    {
        "id": "241C",
        "text": "Tonsillar region"
    },
    {
        "id": "241D",
        "text": "Digestive system"
    },
    {
        "id": "241E",
        "text": "Ear"
    },
    {
        "id": "241H",
        "text": "Codes for special purposes"
    },
    {
        "id": "241I",
        "text": "Age-related macular degeneration"
    },
    {
        "id": "2406",
        "text": "Metabolic disorders"
    },
    {
        "id": "241F",
        "text": "Aetiology"
    },
    {
        "id": "241L",
        "text": "Hereditary haemolytic uraemic syndrome"
    },
    {
        "id": "241M",
        "text": "Haemolytic uraemic syndrome"
    },
    {
        "id": "241N",
        "text": "Major hypertriglyceridaemia"
    },
    {
        "id": "241Q",
        "text": "Diseases of spleen"
    },
    {
        "id": "241P",
        "text": "Integumentary system"
    },
    {
        "id": "241O",
        "text": "Kidney"
    },
    {
        "id": "241R",
        "text": "Lung"
    },
    {
        "id": "241S",
        "text": "Rickettsia"
    },
    {
        "id": "241U",
        "text": "Muscles"
    },
    {
        "id": "241V",
        "text": "Disorders of the hair follicle"
    },
    {
        "id": "241W",
        "text": "Heart"
    },
    {
        "id": "241X",
        "text": "Eyeball"
    },
    {
        "id": "241Y",
        "text": "Eyeball"
    },
    {
        "id": "241Z",
        "text": "Disorder of the optic nerve"
    },
    {
        "id": "2420",
        "text": "Eyeball"
    },
    {
        "id": "2421",
        "text": "Eyeball"
    },
    {
        "id": "2402",
        "text": "Diseases of the digestive system"
    },
    {
        "id": "2422",
        "text": "Skin"
    },
    {
        "id": "2423",
        "text": "Eyeball"
    },
    {
        "id": "2424",
        "text": "Brain"
    },
    {
        "id": "2426",
        "text": "Mouth"
    },
    {
        "id": "2427",
        "text": "Infestation by Demodex"
    },
    {
        "id": "2428",
        "text": "Extension Codes"
    },
    {
        "id": "2429",
        "text": "Extension Codes"
    },
    {
        "id": "242B",
        "text": "Temporality"
    },
    {
        "id": "242C",
        "text": "Extension Codes"
    },
    {
        "id": "242D",
        "text": "Consciousness"
    },
    {
        "id": "242K",
        "text": "Extension Codes"
    },
    {
        "id": "242L",
        "text": "Monosutural craniosynostosis"
    },
    {
        "id": "242M",
        "text": "Severity Scale Value"
    },
    {
        "id": "242N",
        "text": "Apnoea"
    },
    {
        "id": "242O",
        "text": "Cerebrum"
    },
    {
        "id": "242Q",
        "text": "Iatrogenic"
    },
    {
        "id": "242P",
        "text": "Causality"
    },
    {
        "id": "242T",
        "text": "Infectious Agents"
    },
    {
        "id": "242Z",
        "text": "Lice and Mites"
    },
    {
        "id": "242X",
        "text": "Protozoa"
    },
    {
        "id": "242W",
        "text": "Helminths"
    },
    {
        "id": "242V",
        "text": "Fungi"
    },
    {
        "id": "242S",
        "text": "Virus"
    },
    {
        "id": "242R",
        "text": "Bacteria"
    },
    {
        "id": "242U",
        "text": "Infectious Agents"
    },
    {
        "id": "2430",
        "text": "Environmental"
    },
    {
        "id": "242G",
        "text": "Skin"
    },
    {
        "id": "242F",
        "text": "Mouth"
    },
    {
        "id": "242I",
        "text": "Brain"
    },
    {
        "id": "242J",
        "text": "Eye"
    },
    {
        "id": "242E",
        "text": "Functional gastrointestinal disorders"
    },
    {
        "id": "242H",
        "text": "Morphology"
    },
    {
        "id": "2431",
        "text": "Circulatory system"
    },
    {
        "id": "2432",
        "text": "Cerebrum"
    },
    {
        "id": "2433",
        "text": "Lung"
    },
    {
        "id": "2434",
        "text": "Heart"
    },
    {
        "id": "2435",
        "text": "Brainstem"
    },
    {
        "id": "2436",
        "text": "Personal history of health problems"
    },
    {
        "id": "2437",
        "text": "Personal history of health problems"
    },
    {
        "id": "2438",
        "text": "Personal history of health problems"
    },
    {
        "id": "284I",
        "text": "Disorders of nerve root, plexus or peripheral nerves"
    },
    {
        "id": "284J",
        "text": "Environmental"
    },
    {
        "id": "284K",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "284L",
        "text": "Unintentional exposure to explosion"
    },
    {
        "id": "284N",
        "text": "Explosion of torpedo during armed conflict"
    },
    {
        "id": "284O",
        "text": "Explosion of torpedo during armed conflict"
    },
    {
        "id": "284P",
        "text": "Explosion of sea-based artillery shell during armed conflict"
    },
    {
        "id": "284M",
        "text": "Explosion of torpedo during armed conflict"
    },
    {
        "id": "284Q",
        "text": "Explosion of sea-based artillery shell during armed conflict"
    },
    {
        "id": "284R",
        "text": "Attack on or destruction of aircraft during armed conflict due to enemy fire or explosives"
    },
    {
        "id": "284S",
        "text": "Attack on or destruction of aircraft during armed conflict due to enemy fire or explosives"
    },
    {
        "id": "284T",
        "text": "Attack on or destruction of aircraft during armed conflict due to enemy fire or explosives"
    },
    {
        "id": "284U",
        "text": "Explosion of missile during armed conflict"
    },
    {
        "id": "284V",
        "text": "Explosion of missile during armed conflict"
    },
    {
        "id": "284W",
        "text": "Explosion of missile during armed conflict"
    },
    {
        "id": "284X",
        "text": "Explosion of aerial bomb during armed conflict"
    },
    {
        "id": "284Y",
        "text": "Explosion of aerial bomb during armed conflict"
    },
    {
        "id": "284Z",
        "text": "Armed conflict"
    },
    {
        "id": "2850",
        "text": "Use of biological weapons during armed conflict"
    },
    {
        "id": "2851",
        "text": "Weaponised anthrax, influenza or other virus"
    },
    {
        "id": "2852",
        "text": "Weaponised anthrax, influenza or other virus"
    },
    {
        "id": "2853",
        "text": "Use of biological weapons during armed conflict"
    },
    {
        "id": "2854",
        "text": "Chemical weapons or other forms of unconventional warfare during armed conflict"
    },
    {
        "id": "2855",
        "text": "Chemical weapons or other forms of unconventional warfare during armed conflict"
    },
    {
        "id": "2856",
        "text": "Armed conflict"
    },
    {
        "id": "2859",
        "text": "Undetermined intent"
    },
    {
        "id": "2858",
        "text": "Unintentional causes"
    },
    {
        "id": "285A",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "285B",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "285C",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "285D",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "285F",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "285G",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "285H",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "285I",
        "text": "Occupation"
    },
    {
        "id": "285J",
        "text": "Type of place"
    },
    {
        "id": "285K",
        "text": "Type of place"
    },
    {
        "id": "285E",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "285L",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "285M",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "285N",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "285O",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "285R",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "285S",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "2857",
        "text": "Causality"
    },
    {
        "id": "285U",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "285W",
        "text": "Victim of volcanic eruption"
    },
    {
        "id": "285X",
        "text": "Victim of volcanic eruption"
    },
    {
        "id": "285Z",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "2860",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "2861",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "2862",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "2863",
        "text": "Causality"
    },
    {
        "id": "285Y",
        "text": "Victim of volcanic eruption"
    },
    {
        "id": "2864",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "2865",
        "text": "Unintentional exposure to or harmful effects of substances chiefly nonmedicinal as to source"
    },
    {
        "id": "2866",
        "text": "Assault by exposure to or harmful effects of substances chiefly nonmedicinal as to source"
    },
    {
        "id": "2867",
        "text": "Assault by exposure to other mechanism"
    },
    {
        "id": "2868",
        "text": "Exposure to or harmful effects of undetermined intent of substances chiefly nonmedicinal as to source"
    },
    {
        "id": "2869",
        "text": "Unintentional exposure to other mechanism"
    },
    {
        "id": "286A",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "286B",
        "text": "Assault by exposure to other mechanism"
    },
    {
        "id": "286C",
        "text": "Exposure to other mechanism with undetermined intent"
    },
    {
        "id": "285Q",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "285P",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "286D",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "286F",
        "text": "Armed conflict"
    },
    {
        "id": "286G",
        "text": "Armed conflict"
    },
    {
        "id": "286H",
        "text": "Respiratory system"
    },
    {
        "id": "286I",
        "text": "Respiratory system"
    },
    {
        "id": "286J",
        "text": "Assault by exposure to other mechanism"
    },
    {
        "id": "286K",
        "text": "Causality"
    },
    {
        "id": "286L",
        "text": "Causality"
    },
    {
        "id": "286M",
        "text": "Environmental"
    },
    {
        "id": "286O",
        "text": "Occupation"
    },
    {
        "id": "286Q",
        "text": "Occupation"
    },
    {
        "id": "286N",
        "text": "Occupation - armed forces"
    },
    {
        "id": "286R",
        "text": "Occupation - professionals"
    },
    {
        "id": "286S",
        "text": "Occupation - technicians or associate professionals"
    },
    {
        "id": "286T",
        "text": "Occupation - professionals"
    },
    {
        "id": "286U",
        "text": "Occupation - elementary occupations"
    },
    {
        "id": "286V",
        "text": "Occupation - professionals"
    },
    {
        "id": "286P",
        "text": "Occupation"
    },
    {
        "id": "286W",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "286X",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "286Y",
        "text": "Unintentional exposure to other mechanism"
    },
    {
        "id": "286E",
        "text": "Other Pathogens"
    },
    {
        "id": "239Y",
        "text": "Diseases of arteries or arterioles"
    },
    {
        "id": "286Z",
        "text": "Diseases of veins"
    },
    {
        "id": "2870",
        "text": "Diseases of arteries or arterioles"
    },
    {
        "id": "2871",
        "text": "Diseases of arteries or arterioles"
    },
    {
        "id": "2872",
        "text": "Diseases of veins"
    },
    {
        "id": "2873",
        "text": "Syndromes with brain calcifications as a major feature"
    },
    {
        "id": "2874",
        "text": "Syndromes with brain calcifications as a major feature"
    },
    {
        "id": "2875",
        "text": "Syndromes with brain calcifications as a major feature"
    },
    {
        "id": "2876",
        "text": "Cerebrovascular diseases"
    },
    {
        "id": "2877",
        "text": "Diseases of the male genital system"
    },
    {
        "id": "2878",
        "text": "Other and unspecified female genital organs"
    },
    {
        "id": "2879",
        "text": "Diseases of the male genital system"
    },
    {
        "id": "287A",
        "text": "Diseases of the female genital system"
    },
    {
        "id": "287B",
        "text": "Occupational exposure to risk-factors"
    },
    {
        "id": "287C",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "287D",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "287E",
        "text": "Intracranial injury"
    },
    {
        "id": "287F",
        "text": "Unintentional exposure to object, not elsewhere classified"
    },
    {
        "id": "287H",
        "text": "Assault by exposure to other mechanism"
    },
    {
        "id": "287G",
        "text": "Assault by exposure to object not elsewhere classified"
    },
    {
        "id": "287M",
        "text": "Exposure to other mechanism with undetermined intent"
    },
    {
        "id": "287L",
        "text": "Contact with object, not elsewhere classified with undetermined intent"
    },
    {
        "id": "287N",
        "text": "Unintentionally struck by moving object"
    },
    {
        "id": "287O",
        "text": "Assault by being struck by moving object, not elsewhere classified"
    },
    {
        "id": "287P",
        "text": "Struck by moving object, not elsewhere classified of undetermined intent"
    },
    {
        "id": "287Q",
        "text": "Other explosions or fragments during armed conflict"
    },
    {
        "id": "287R",
        "text": "Diseases of the digestive system"
    },
    {
        "id": "287S",
        "text": "Mode of injury or harm associated with other health care related causes"
    },
    {
        "id": "287T",
        "text": "Occupation - professionals"
    },
    {
        "id": "287U",
        "text": "Occupation - technicians or associate professionals"
    },
    {
        "id": "287V",
        "text": "Occupation - professionals"
    },
    {
        "id": "287W",
        "text": "Chemical weapons or other forms of unconventional warfare during armed conflict"
    },
    {
        "id": "287X",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "287Y",
        "text": "Liver"
    },
    {
        "id": "287Z",
        "text": "Urinary bladder"
    },
    {
        "id": "2881",
        "text": "Histopathology by behaviour"
    },
    {
        "id": "2882",
        "text": "Histopathology by behaviour"
    },
    {
        "id": "2883",
        "text": "General symptoms"
    },
    {
        "id": "2884",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2885",
        "text": "Victim of cataclysmic storm"
    },
    {
        "id": "2886",
        "text": "Infection of spleen"
    },
    {
        "id": "2887",
        "text": "Diseases of the urinary system"
    },
    {
        "id": "2888",
        "text": "Diseases of the nervous system"
    },
    {
        "id": "288A",
        "text": "Uterus"
    },
    {
        "id": "288B",
        "text": "Diseases of the female genital system"
    },
    {
        "id": "288C",
        "text": "Ovary"
    },
    {
        "id": "288D",
        "text": "Diseases of the female genital system"
    },
    {
        "id": "288E",
        "text": "Diseases of the circulatory system"
    },
    {
        "id": "288F",
        "text": "Diseases of the circulatory system"
    },
    {
        "id": "288H",
        "text": "Secondary lichenification"
    },
    {
        "id": "288I",
        "text": "Functional anatomy"
    },
    {
        "id": "288J",
        "text": "Functional anatomy"
    },
    {
        "id": "288K",
        "text": "Diseases of peritoneum"
    },
    {
        "id": "2889",
        "text": "Diseases of the male genital system"
    },
    {
        "id": "1Z5V",
        "text": "ICD-O"
    },
    {
        "id": "288M",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "288N",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "288O",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "288G",
        "text": "Soft tissue disorders"
    },
    {
        "id": "288P",
        "text": "Soft tissue disorders"
    },
    {
        "id": "288Q",
        "text": "Soft tissue disorders"
    },
    {
        "id": "288R",
        "text": "Soft tissue disorders"
    },
    {
        "id": "288S",
        "text": "Idiopathic"
    },
    {
        "id": "288T",
        "text": "Environmental"
    },
    {
        "id": "288U",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "288V",
        "text": "Diseases of the female genital system"
    },
    {
        "id": "288W",
        "text": "Diseases of the female genital system"
    },
    {
        "id": "288X",
        "text": "Diseases of the female genital system"
    },
    {
        "id": "288Y",
        "text": "Diseases of the female genital system"
    },
    {
        "id": "288Z",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "2890",
        "text": "Occupation"
    },
    {
        "id": "2891",
        "text": "Occupation - craft or related trades workers"
    },
    {
        "id": "2894",
        "text": "Veins"
    },
    {
        "id": "2893",
        "text": "Arteries"
    },
    {
        "id": "285T",
        "text": "Dilated cardiomyopathy"
    },
    {
        "id": "2897",
        "text": "Familial-genetic dilated cardiomyopathy"
    },
    {
        "id": "2895",
        "text": "Cerebrovascular diseases"
    },
    {
        "id": "2896",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "2899",
        "text": "Dilated cardiomyopathy due to fatty acid oxidation disorder"
    },
    {
        "id": "289F",
        "text": "Other bacterial infections of the fetus or newborn"
    },
    {
        "id": "289G",
        "text": "Histiocytoid cardiomyopathy"
    },
    {
        "id": "289H",
        "text": "Dilated cardiomyopathy due to Cytochrome C oxidase deficiency"
    },
    {
        "id": "289Y",
        "text": "Dilated cardiomyopathy due to alcoholism"
    },
    {
        "id": "289Z",
        "text": "Dilated cardiomyopathy due to venom"
    },
    {
        "id": "240O",
        "text": "Hypothalamus"
    },
    {
        "id": "240N",
        "text": "Pineal\u00a0gland"
    },
    {
        "id": "2892",
        "text": "Hypopharynx"
    },
    {
        "id": "2880",
        "text": "Syndromes with connective tissue involvement as a major feature"
    },
    {
        "id": "2906",
        "text": "Diseases of the skin"
    },
    {
        "id": "2907",
        "text": "Endocrine, nutritional or metabolic diseases"
    },
    {
        "id": "290A",
        "text": "Endocrine, nutritional or metabolic diseases"
    },
    {
        "id": "290B",
        "text": "Amyloidosis"
    },
    {
        "id": "290C",
        "text": "Other Pathogens"
    },
    {
        "id": "290D",
        "text": "Other Pathogens"
    },
    {
        "id": "290E",
        "text": "Other Pathogens"
    },
    {
        "id": "290F",
        "text": "Other Pathogens"
    },
    {
        "id": "290G",
        "text": "Other Pathogens"
    },
    {
        "id": "290H",
        "text": "Other Pathogens"
    },
    {
        "id": "290J",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "290K",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "290I",
        "text": "Unspecified mitochondrial disorders"
    },
    {
        "id": "290L",
        "text": "Unspecified mitochondrial disorders"
    },
    {
        "id": "290M",
        "text": "Unspecified mitochondrial disorders"
    },
    {
        "id": "290N",
        "text": "Other Pathogens"
    },
    {
        "id": "290P",
        "text": "Other Pathogens"
    },
    {
        "id": "284G",
        "text": "Diseases of the immune system"
    },
    {
        "id": "290Q",
        "text": "Unspecified mitochondrial disorders"
    },
    {
        "id": "290R",
        "text": "Immunodeficiencies"
    },
    {
        "id": "290S",
        "text": "Coagulation defects"
    },
    {
        "id": "290T",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "290U",
        "text": "Disorders with neurocognitive impairment as a major feature"
    },
    {
        "id": "240I",
        "text": "Liver"
    },
    {
        "id": "2909",
        "text": "Other metabolic disorders"
    },
    {
        "id": "290Y",
        "text": "Hypertrophic cardiomyopathy due to primary amyloidosis"
    },
    {
        "id": "290Z",
        "text": "Hypertrophic cardiomyopathy due to Kearns\u2013Sayre syndrome"
    },
    {
        "id": "2912",
        "text": "Idiopathic isolated hypertrophic cardiomyopathy"
    },
    {
        "id": "2915",
        "text": "Apical hypertrophic cardiomyopathy"
    },
    {
        "id": "2914",
        "text": "Non-obstructive hypertrophic cardiomyopathy"
    },
    {
        "id": "241G",
        "text": "Ageing associated decline in intrinsic capacity"
    },
    {
        "id": "2918",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2919",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "291A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "291B",
        "text": "Endocardial fibroelastosis"
    },
    {
        "id": "291C",
        "text": "Functional anatomy"
    },
    {
        "id": "291D",
        "text": "Functional anatomy"
    },
    {
        "id": "291E",
        "text": "Infections of the circulatory system"
    },
    {
        "id": "291F",
        "text": "Functional anatomy"
    },
    {
        "id": "291G",
        "text": "Functional anatomy"
    },
    {
        "id": "291H",
        "text": "Functional anatomy"
    },
    {
        "id": "291I",
        "text": "Diseases of the digestive system"
    },
    {
        "id": "291J",
        "text": "Diseases of the nervous system"
    },
    {
        "id": "291K",
        "text": "Aetiology"
    },
    {
        "id": "291M",
        "text": "Idiopathic restrictive cardiomyopathy"
    },
    {
        "id": "291O",
        "text": "Sarcomeric restrictive cardiomyopathy"
    },
    {
        "id": "291U",
        "text": "Wild type transthyretin-related amyloid cardiomyopathy"
    },
    {
        "id": "291W",
        "text": "Wild type transthyretin-related amyloid cardiomyopathy"
    },
    {
        "id": "2920",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2922",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "2923",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "2924",
        "text": "Partonomic view"
    },
    {
        "id": "2925",
        "text": "Functional anatomy"
    },
    {
        "id": "2926",
        "text": "Dementia due to Alzheimer disease"
    },
    {
        "id": "2162",
        "text": "Chronic insomnia"
    },
    {
        "id": "2927",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2928",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2921",
        "text": "Hypereosinophilic syndrome"
    },
    {
        "id": "292A",
        "text": "Lymphocytic hypereosinophilic syndrome"
    },
    {
        "id": "2929",
        "text": "Lymphocytic hypereosinophilic syndrome"
    },
    {
        "id": "292B",
        "text": "Myeloproliferative hypereosinophilic syndrome"
    },
    {
        "id": "292C",
        "text": "Loeffler endocarditis"
    },
    {
        "id": "292D",
        "text": "Restrictive cardiomyopathy due to radiation toxicity"
    },
    {
        "id": "292G",
        "text": "Sarcomeric noncompaction cardiomyopathy"
    },
    {
        "id": "292I",
        "text": "Noncompaction cardiomyopathy due to laminopathy"
    },
    {
        "id": "292J",
        "text": "Fracture of trochanteric section of femur"
    },
    {
        "id": "292L",
        "text": "Stress-induced cardiomyopathy"
    },
    {
        "id": "292N",
        "text": "Arrhythmogenic ventricular cardiomyopathy due to desmosomal mutation"
    },
    {
        "id": "292O",
        "text": "Arrhythmogenic ventricular cardiomyopathy"
    },
    {
        "id": "292P",
        "text": "Familial isolated arrhythmogenic right ventricular dysplasia"
    },
    {
        "id": "292Q",
        "text": "Arrhythmogenic ventricular cardiomyopathy with left ventricular dominance"
    },
    {
        "id": "292T",
        "text": "Woolly hair \u2013 palmoplantar keratoderma \u2013 dilated cardiomyopathy"
    },
    {
        "id": "292M",
        "text": "Arrhythmogenic ventricular cardiomyopathy with right ventricular dominance"
    },
    {
        "id": "292S",
        "text": "Cirrhotic cardiomyopathy"
    },
    {
        "id": "292U",
        "text": "Diabetic cardiomyopathy"
    },
    {
        "id": "292V",
        "text": "Digestive system perforation"
    },
    {
        "id": "292W",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "292X",
        "text": "Conditions of uncertain aetiology and emergency use"
    },
    {
        "id": "292Y",
        "text": "Sudden infant death syndrome with mention of autopsy"
    },
    {
        "id": "292Z",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2930",
        "text": "Dermatochalasis of eyelid with fat prolapse"
    },
    {
        "id": "2932",
        "text": "Perforation of gallbladder or bile ducts"
    },
    {
        "id": "2933",
        "text": "Perforation of cystic duct"
    },
    {
        "id": "2934",
        "text": "Perforation of gallbladder"
    },
    {
        "id": "2935",
        "text": "Perforation of bile duct"
    },
    {
        "id": "2936",
        "text": "Respiratory cyst of orbit"
    },
    {
        "id": "2898",
        "text": "Familial isolated dilated cardiomyopathy"
    },
    {
        "id": "2937",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "289D",
        "text": "Dilated cardiomyopathy due to malonyl-CoA decarboxylase deficiency"
    },
    {
        "id": "289C",
        "text": "Dilated cardiomyopathy due to malonyl-CoA decarboxylase deficiency"
    },
    {
        "id": "2938",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "289A",
        "text": "Dilated cardiomyopathy due to primary carnitine deficiency"
    },
    {
        "id": "2939",
        "text": "Sarcomeric dilated cardiomyopathy"
    },
    {
        "id": "289E",
        "text": "Dilated cardiomyopathy due to mitochondrial myopathy"
    },
    {
        "id": "289I",
        "text": "Dilated cardiomyopathy due to neuromuscular disease"
    },
    {
        "id": "293A",
        "text": "Dilated cardiomyopathy due to dystrophinopathy"
    },
    {
        "id": "293B",
        "text": "Dilated cardiomyopathy due to laminopathy"
    },
    {
        "id": "293D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "293E",
        "text": "Dilated cardiomyopathy due to glycogen branching enzyme deficiency"
    },
    {
        "id": "293F",
        "text": "X-linked infantile dilated cardiomyopathy"
    },
    {
        "id": "289P",
        "text": "Dilated cardiomyopathy due to lysosomal storage disorder"
    },
    {
        "id": "289Q",
        "text": "Dilated cardiomyopathy due to mucopolysaccharidoses"
    },
    {
        "id": "289R",
        "text": "Dilated cardiomyopathy due to sphingolipidoses"
    },
    {
        "id": "289S",
        "text": "Nonfamilial dilated cardiomyopathy"
    },
    {
        "id": "289T",
        "text": "Idiopathic isolated dilated cardiomyopathy"
    },
    {
        "id": "289U",
        "text": "Dilated cardiomyopathy due to toxin exposure"
    },
    {
        "id": "289V",
        "text": "Dilated cardiomyopathy due to chemicals"
    },
    {
        "id": "289W",
        "text": "Dilated cardiomyopathy due to drugs"
    },
    {
        "id": "289X",
        "text": "Dilated cardiomyopathy due to anthracycline toxicity"
    },
    {
        "id": "2900",
        "text": "Dilated cardiomyopathy due to iron overload"
    },
    {
        "id": "293G",
        "text": "Pacing-induced cardiomyopathy"
    },
    {
        "id": "293H",
        "text": "Ischaemic cardiomyopathy"
    },
    {
        "id": "293I",
        "text": "Hypertrophic cardiomyopathy"
    },
    {
        "id": "2902",
        "text": "Familial-genetic hypertrophic cardiomyopathy"
    },
    {
        "id": "2904",
        "text": "Hypertrophic cardiomyopathy due to disorder of fatty acid metabolism"
    },
    {
        "id": "2903",
        "text": "Sarcomeric hypertrophic cardiomyopathy"
    },
    {
        "id": "290V",
        "text": "Hypertrophic cardiomyopathy due to glycogen storage disease"
    },
    {
        "id": "2905",
        "text": "Hypertrophic cardiomyopathy due to lysosomal storage disease"
    },
    {
        "id": "290X",
        "text": "Hypertrophic cardiomyopathy due to mitochondrial disorder"
    },
    {
        "id": "2910",
        "text": "Hypertrophic cardiomyopathy due to Kearns\u2013Sayre syndrome"
    },
    {
        "id": "290W",
        "text": "Syndromic hypertrophic cardiomyopathy"
    },
    {
        "id": "2911",
        "text": "Nonfamilial hypertrophic cardiomyopathy"
    },
    {
        "id": "2916",
        "text": "Obstructive hypertrophic cardiomyopathy"
    },
    {
        "id": "2913",
        "text": "Syndrome of infant of a diabetic mother, type 1 or 2, nongestational, insulin dependent"
    },
    {
        "id": "291L",
        "text": "Restrictive cardiomyopathy"
    },
    {
        "id": "291N",
        "text": "Familial-genetic restrictive cardiomyopathy"
    },
    {
        "id": "291Q",
        "text": "Familial restrictive cardiomyopathy due to MYBPC3 gene mutation"
    },
    {
        "id": "291P",
        "text": "Familial restrictive cardiomyopathy due to MYBPC3 gene mutation"
    },
    {
        "id": "293J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "291R",
        "text": "Restrictive cardiomyopathy due to storage disease"
    },
    {
        "id": "291S",
        "text": "Restrictive cardiomyopathy due to desminopathy"
    },
    {
        "id": "291T",
        "text": "Transthyretin-related familial amyloid cardiomyopathy"
    },
    {
        "id": "291X",
        "text": "Nonfamilial restrictive cardiomyopathy"
    },
    {
        "id": "291Y",
        "text": "Restrictive cardiomyopathy due to endomyocardial abnormality"
    },
    {
        "id": "291Z",
        "text": "Endomyocardial fibrosis"
    },
    {
        "id": "293K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "293L",
        "text": "Congenital endocarditis"
    },
    {
        "id": "292E",
        "text": "Noncompaction cardiomyopathy"
    },
    {
        "id": "292F",
        "text": "Familial-genetic noncompaction cardiomyopathy"
    },
    {
        "id": "293M",
        "text": "X-linked noncompaction cardiomyopathy"
    },
    {
        "id": "293N",
        "text": "Myocarditis due to Kawasaki disease"
    },
    {
        "id": "293O",
        "text": "Sarcoid myocarditis"
    },
    {
        "id": "293P",
        "text": "Rheumatic myocarditis"
    },
    {
        "id": "293Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "293R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "293S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "293T",
        "text": "Acute rheumatic myocarditis"
    },
    {
        "id": "293U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "293V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "293W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "293X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "293Y",
        "text": "Hypersensitivity myocarditis"
    },
    {
        "id": "2940",
        "text": "Myocarditis"
    },
    {
        "id": "2944",
        "text": "Tuberculosis of myocardium"
    },
    {
        "id": "2947",
        "text": "Aspergillus myocarditis"
    },
    {
        "id": "2948",
        "text": "Aspergillus endocarditis"
    },
    {
        "id": "2949",
        "text": "Aspergillus pericarditis"
    },
    {
        "id": "294A",
        "text": "Invasive cardiac aspergillosis"
    },
    {
        "id": "294G",
        "text": "Rickettsial myocarditis"
    },
    {
        "id": "294F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "294E",
        "text": "Chagas' disease with myocarditis"
    },
    {
        "id": "294D",
        "text": "Protozoal myocarditis"
    },
    {
        "id": "294H",
        "text": "Helminthic myocarditis"
    },
    {
        "id": "294B",
        "text": "Parasitic myocarditis"
    },
    {
        "id": "2946",
        "text": "Aspergillus myocarditis"
    },
    {
        "id": "2945",
        "text": "Fungal myocarditis"
    },
    {
        "id": "2943",
        "text": "Gonococcal myocarditis"
    },
    {
        "id": "2942",
        "text": "Bacterial myocarditis"
    },
    {
        "id": "2941",
        "text": "Infectious myocarditis"
    },
    {
        "id": "293Z",
        "text": "Giant cell myocarditis"
    },
    {
        "id": "294I",
        "text": "Spirochaetal myocarditis"
    },
    {
        "id": "294J",
        "text": "Viral myocarditis"
    },
    {
        "id": "294L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "294K",
        "text": "Dilated cardiomyopathy due to systemic autoimmune disorder"
    },
    {
        "id": "294M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "294O",
        "text": "Neuropathy due to toxicity"
    },
    {
        "id": "294S",
        "text": "Peripheral neuropathy due to toxicity"
    },
    {
        "id": "294T",
        "text": "Polyneuropathy due to other toxic agents"
    },
    {
        "id": "294P",
        "text": "Mild cognitive impairment"
    },
    {
        "id": "294U",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "294N",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "294V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "294X",
        "text": "Heart muscle disease in nutritional diseases"
    },
    {
        "id": "294W",
        "text": "Nonfamilial dilated cardiomyopathy"
    },
    {
        "id": "294Y",
        "text": "Nonfamilial dilated cardiomyopathy"
    },
    {
        "id": "294Z",
        "text": "Nonfamilial dilated cardiomyopathy"
    },
    {
        "id": "294R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2953",
        "text": "Nonfamilial dilated cardiomyopathy"
    },
    {
        "id": "2954",
        "text": "Ischaemic cardiomyopathy"
    },
    {
        "id": "2956",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2957",
        "text": "Unintentional threat to breathing"
    },
    {
        "id": "219N",
        "text": "Sudden infant death syndrome"
    },
    {
        "id": "219Q",
        "text": "Other sudden death, cause unknown"
    },
    {
        "id": "219R",
        "text": "Other ill-defined and unspecified causes of mortality"
    },
    {
        "id": "2959",
        "text": "Deep ovarian endometriosis"
    },
    {
        "id": "2958",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "295B",
        "text": "Penoscrotal transposition"
    },
    {
        "id": "295C",
        "text": "Acute posthaemorrhagic anaemia"
    },
    {
        "id": "295F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "295H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "295I",
        "text": "Walled-eye bilateral internuclear ophthalmoplegia"
    },
    {
        "id": "295D",
        "text": "Secondary angle closure glaucoma by neovascular membrane due to diabetic retinopathy"
    },
    {
        "id": "2952",
        "text": "Immunodeficiency with decay accelerating factor deficiency"
    },
    {
        "id": "295J",
        "text": "Moderate impairment of stereopsis"
    },
    {
        "id": "295L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "295M",
        "text": "Extended spectrum beta-lactamase producing Escherichia coli"
    },
    {
        "id": "295N",
        "text": "Alcohol dependence"
    },
    {
        "id": "295O",
        "text": "Sudden infant death syndrome"
    },
    {
        "id": "295P",
        "text": "Trigeminal neuralgia"
    },
    {
        "id": "295Q",
        "text": "Acute headache associated with traumatic injury to the head"
    },
    {
        "id": "295R",
        "text": "Acute headache associated with whiplash injury"
    },
    {
        "id": "295S",
        "text": "High-altitude headache"
    },
    {
        "id": "295T",
        "text": "Dialysis headache"
    },
    {
        "id": "295U",
        "text": "Headache due to acute glaucoma"
    },
    {
        "id": "295V",
        "text": "Carbon monoxide-induced headache"
    },
    {
        "id": "295W",
        "text": "Monosodium glutamate-induced headache"
    },
    {
        "id": "295X",
        "text": "Headache associated with caffeine withdrawal"
    },
    {
        "id": "295Y",
        "text": "Headache associated with estrogen withdrawal"
    },
    {
        "id": "295Z",
        "text": "Headache associated with withdrawal of other substance"
    },
    {
        "id": "2960",
        "text": "Acute headache associated with craniotomy"
    },
    {
        "id": "2961",
        "text": "Post dural puncture headache"
    },
    {
        "id": "2962",
        "text": "Irritable bowel syndrome"
    },
    {
        "id": "2967",
        "text": "Pantothenate-kinase-associated neurodegeneration"
    },
    {
        "id": "2965",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2969",
        "text": "Diseases of the nervous system"
    },
    {
        "id": "296B",
        "text": "Acaeruloplasminaemia"
    },
    {
        "id": "296C",
        "text": "Failed induction of labour"
    },
    {
        "id": "296D",
        "text": "Failed induction of labour"
    },
    {
        "id": "296E",
        "text": "Delivery"
    },
    {
        "id": "296F",
        "text": "Sprain or strain of nose"
    },
    {
        "id": "296H",
        "text": "Plagiocephaly"
    },
    {
        "id": "296I",
        "text": "Bodily distress disorder"
    },
    {
        "id": "296J",
        "text": "Genu valgum, not elsewhere classified"
    },
    {
        "id": "296K",
        "text": "Varus deformity, not elsewhere classified, lower leg"
    },
    {
        "id": "296L",
        "text": "Destructive spondylopathy related to haemodialysis"
    },
    {
        "id": "296M",
        "text": "Ankylosis of spinal joint"
    },
    {
        "id": "234K",
        "text": "Vision impairment including blindness"
    },
    {
        "id": "234L",
        "text": "Vision impairment including blindness"
    },
    {
        "id": "296R",
        "text": "Chronic neuropathic orofacial pain"
    },
    {
        "id": "211R",
        "text": "Chronic neuropathic orofacial pain"
    },
    {
        "id": "296S",
        "text": "Chronic painful chemotherapy-induced polyneuropathy"
    },
    {
        "id": "296T",
        "text": "Stable angina"
    },
    {
        "id": "296U",
        "text": "Infrequent episodic tension-type headache"
    },
    {
        "id": "296V",
        "text": "Infrequent episodic tension-type headache associated with pericranial tenderness"
    },
    {
        "id": "296W",
        "text": "Infrequent episodic tension-type headache not associated with pericranial tenderness"
    },
    {
        "id": "296X",
        "text": "Frequent episodic tension-type headache"
    },
    {
        "id": "296Y",
        "text": "Frequent episodic tension-type headache associated with pericranial tenderness"
    },
    {
        "id": "296Z",
        "text": "Frequent episodic tension-type headache not associated with pericranial tenderness"
    },
    {
        "id": "2970",
        "text": "Episodic cluster headache"
    },
    {
        "id": "2971",
        "text": "Episodic paroxysmal hemicrania"
    },
    {
        "id": "2972",
        "text": "Obstructive tracheobronchitis, not elsewhere classified"
    },
    {
        "id": "2973",
        "text": "Absent or atretic pulmonary trunk"
    },
    {
        "id": "2974",
        "text": "Early-onset pneumonia due to Group B Streptococcus"
    },
    {
        "id": "2975",
        "text": "Structural developmental anomalies of ovaries, fallopian tubes or broad ligaments"
    },
    {
        "id": "297A",
        "text": "Pseudo-unicornuate uterus"
    },
    {
        "id": "297I",
        "text": "4p duplication"
    },
    {
        "id": "297L",
        "text": "Gynandroblastoma of unknown behaviour of unspecified site, female"
    },
    {
        "id": "297M",
        "text": "Schistosomiasis due to Schistosoma japonicum"
    },
    {
        "id": "297N",
        "text": "Lyme borreliosis"
    },
    {
        "id": "231L",
        "text": "Tuberculosis"
    },
    {
        "id": "229P",
        "text": "Encephalitis in African trypanosomiasis"
    },
    {
        "id": "229K",
        "text": "Plantar warts"
    },
    {
        "id": "229M",
        "text": "Perianal warts"
    },
    {
        "id": "296G",
        "text": "Hereditary motor and sensory neuropathy"
    },
    {
        "id": "2951",
        "text": "Substances, chiefly nonmedicinal"
    },
    {
        "id": "2950",
        "text": "Substances, chiefly nonmedicinal"
    },
    {
        "id": "2369",
        "text": "Diseases of the skin complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "212O",
        "text": "Abnormal skin pigmentation"
    },
    {
        "id": "212J",
        "text": "Neuropathic skin ulceration"
    },
    {
        "id": "212I",
        "text": "Anogenital pruritus"
    },
    {
        "id": "212N",
        "text": "Postinfective hypomelanosis"
    },
    {
        "id": "212D",
        "text": "Hereditary palmoplantar keratodermas"
    },
    {
        "id": "212C",
        "text": "Keratinopathic ichthyoses"
    },
    {
        "id": "2129",
        "text": "Urticarial vasculitis"
    },
    {
        "id": "2128",
        "text": "Secondary lichenification"
    },
    {
        "id": "2127",
        "text": "Perianal lichen simplex"
    },
    {
        "id": "2126",
        "text": "Lichen simplex of vulva"
    },
    {
        "id": "2125",
        "text": "Syphilis"
    },
    {
        "id": "229R",
        "text": "Meningitis in African trypanosomiasis"
    },
    {
        "id": "297Q",
        "text": "Maternal care for known or suspected chromosomal abnormality in fetus"
    },
    {
        "id": "297P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "236P",
        "text": "Disseminated lymphangiomatosis"
    },
    {
        "id": "2270",
        "text": "Syphilis"
    },
    {
        "id": "297T",
        "text": "Toxic or drug-related embryofetopathies"
    },
    {
        "id": "297U",
        "text": "HIV disease clinical stage 4 without mention of tuberculosis or malaria"
    },
    {
        "id": "297K",
        "text": "Persistent urogenital sinus"
    },
    {
        "id": "297V",
        "text": "Intestinal infections due to Clostridioides difficile"
    },
    {
        "id": "297Z",
        "text": "Hereditary persistence of fetal haemoglobin"
    },
    {
        "id": "2981",
        "text": "Disorders of the external ear involving the skin"
    },
    {
        "id": "2980",
        "text": "Diseases of external ear"
    },
    {
        "id": "2982",
        "text": "Movement disorders"
    },
    {
        "id": "2931",
        "text": "Certain specified immunodeficiencies with predominantly antibody defects"
    },
    {
        "id": "2983",
        "text": "Trigeminal neuralgia"
    },
    {
        "id": "2984",
        "text": "Chronic painful chemotherapy-induced polyneuropathy"
    },
    {
        "id": "2985",
        "text": "Chronic painful radiation-induced neuropathy"
    },
    {
        "id": "2986",
        "text": "Postherpetic neuralgia"
    },
    {
        "id": "2987",
        "text": "Cutaneous nocardiosis"
    },
    {
        "id": "2988",
        "text": "Acute whiplash injury associated with pain disorders"
    },
    {
        "id": "2989",
        "text": "Chronic whiplash injury associated pain"
    },
    {
        "id": "298A",
        "text": "Acute whiplash injury associated with pain disorders"
    },
    {
        "id": "298B",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "298C",
        "text": "Complex regional pain syndrome type II"
    },
    {
        "id": "298D",
        "text": "Persistent headache associated with traumatic injury to the head"
    },
    {
        "id": "298E",
        "text": "Medication-overuse headache"
    },
    {
        "id": "298F",
        "text": "Aggressive fibromatosis"
    },
    {
        "id": "214M",
        "text": "Cerebral structural developmental anomalies"
    },
    {
        "id": "298G",
        "text": "Fasciitis and fibromatosis"
    },
    {
        "id": "298H",
        "text": "Feeding or eating disorders"
    },
    {
        "id": "298I",
        "text": "Coronavirus"
    },
    {
        "id": "298K",
        "text": "Coronavirus"
    },
    {
        "id": "298L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "298O",
        "text": "Cutaneous insect bite reactions"
    },
    {
        "id": "298Q",
        "text": "Chronic neuropathic orofacial pain"
    },
    {
        "id": "298R",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "298S",
        "text": "Acute bronchitis"
    },
    {
        "id": "298T",
        "text": "Dystonia associated with heredodegenerative disorders"
    },
    {
        "id": "298X",
        "text": "Chronic cluster headache"
    },
    {
        "id": "298Y",
        "text": "Hemicrania continua"
    },
    {
        "id": "298W",
        "text": "Chronic paroxysmal hemicrania"
    },
    {
        "id": "298Z",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "2991",
        "text": "Land transport road traffic injury event of undetermined intent"
    },
    {
        "id": "2997",
        "text": "Combined hyperactive dysfunction syndrome of the cranial nerves"
    },
    {
        "id": "2998",
        "text": "Supraorbital neuralgia"
    },
    {
        "id": "2999",
        "text": "Occipital neuralgia"
    },
    {
        "id": "299A",
        "text": "Tolosa-Hunt syndrome"
    },
    {
        "id": "299B",
        "text": "Other cranial neuralgia or other centrally mediated facial pain"
    },
    {
        "id": "298U",
        "text": "Postviral fatigue syndrome"
    },
    {
        "id": "299C",
        "text": "Intentional self-harm, person intended to die"
    },
    {
        "id": "288L",
        "text": "Malfunction or complication of external stoma of digestive organs"
    },
    {
        "id": "299D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "299E",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "299G",
        "text": "Dermatoses associated with other specific medications"
    },
    {
        "id": "299F",
        "text": "COVID-19, virus identified"
    },
    {
        "id": "299J",
        "text": "Fetal death, cause not specified"
    },
    {
        "id": "2A05",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2A07",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A08",
        "text": "Keratitis or keratoconjunctivitis in tuberculosis"
    },
    {
        "id": "2A0A",
        "text": "Bipolar type II disorder"
    },
    {
        "id": "2A0B",
        "text": "Mixed depressive and anxiety disorder"
    },
    {
        "id": "2A0C",
        "text": "Gambling disorder"
    },
    {
        "id": "2A0D",
        "text": "Gaming disorder"
    },
    {
        "id": "2A0E",
        "text": "Avoidant-restrictive food intake disorder"
    },
    {
        "id": "2A0H",
        "text": "Schizoaffective disorder"
    },
    {
        "id": "2A0F",
        "text": "Dissociative neurological symptom disorder"
    },
    {
        "id": "2A0I",
        "text": "Dissociative neurological symptom disorder, with cognitive symptoms"
    },
    {
        "id": "2A0J",
        "text": "Dissociative neurological symptom disorder, with visual disturbance"
    },
    {
        "id": "2A0K",
        "text": "Dissociative neurological symptom disorder, with auditory disturbance"
    },
    {
        "id": "2A0L",
        "text": "Dissociative neurological symptom disorder, with vertigo or dizziness"
    },
    {
        "id": "2A0M",
        "text": "Dissociative neurological symptom disorder, with other sensory disturbance"
    },
    {
        "id": "2A0N",
        "text": "Dissociative neurological symptom disorder, with non-epileptic seizures"
    },
    {
        "id": "2A0O",
        "text": "Dissociative neurological symptom disorder, with speech disturbance"
    },
    {
        "id": "2A0P",
        "text": "Dissociative neurological symptom disorder, with paresis or weakness"
    },
    {
        "id": "2A0Q",
        "text": "Dissociative neurological symptom disorder, with gait disturbance"
    },
    {
        "id": "2A0R",
        "text": "Dissociative neurological symptom disorder, with movement disturbance"
    },
    {
        "id": "2A0S",
        "text": "Dissociative neurological symptom disorder, with chorea"
    },
    {
        "id": "2A0T",
        "text": "Dissociative neurological symptom disorder, with myoclonus"
    },
    {
        "id": "2A0U",
        "text": "Dissociative neurological symptom disorder, with tremor"
    },
    {
        "id": "2A0V",
        "text": "Dissociative neurological symptom disorder, with dystonia"
    },
    {
        "id": "2A0W",
        "text": "Dissociative neurological symptom disorder, with facial spasm"
    },
    {
        "id": "2A0X",
        "text": "Dissociative neurological symptom disorder, with Parkinsonism"
    },
    {
        "id": "2A0Y",
        "text": "Hypochondriasis"
    },
    {
        "id": "2A10",
        "text": "Manic mood symptoms in primary psychotic disorders"
    },
    {
        "id": "2A0Z",
        "text": "Depressive mood symptoms in primary psychotic disorders"
    },
    {
        "id": "2A11",
        "text": "Schizotypal disorder"
    },
    {
        "id": "2A12",
        "text": "Neurodevelopmental disorders"
    },
    {
        "id": "2A14",
        "text": "Disorder of intellectual development, provisional"
    },
    {
        "id": "2A15",
        "text": "Developmental speech fluency disorder"
    },
    {
        "id": "2A16",
        "text": "Developmental language disorder"
    },
    {
        "id": "2A17",
        "text": "Developmental speech or language disorders"
    },
    {
        "id": "2A18",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "2A19",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "2A1A",
        "text": "Stereotyped movement disorder"
    },
    {
        "id": "2A1B",
        "text": "Acute and transient psychotic disorder"
    },
    {
        "id": "2A1C",
        "text": "Delusional disorder"
    },
    {
        "id": "2A1D",
        "text": "Social anxiety disorder"
    },
    {
        "id": "2A1E",
        "text": "Selective mutism"
    },
    {
        "id": "2A1G",
        "text": "Rumination-regurgitation disorder"
    },
    {
        "id": "2A1H",
        "text": "Bodily distress disorder"
    },
    {
        "id": "2A1I",
        "text": "Body integrity dysphoria"
    },
    {
        "id": "2A1J",
        "text": "Panic disorder"
    },
    {
        "id": "2A1K",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2A1M",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2A1L",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2A1O",
        "text": "Acaeruloplasminaemia"
    },
    {
        "id": "297G",
        "text": "Structural developmental anomalies of uterus, except cervix"
    },
    {
        "id": "297E",
        "text": "Subtotal septate uterus"
    },
    {
        "id": "297D",
        "text": "Total septate uterus"
    },
    {
        "id": "297F",
        "text": "Agenesis or aplasia of uterine body"
    },
    {
        "id": "297H",
        "text": "Absence of vulva"
    },
    {
        "id": "297B",
        "text": "True unicornuate uterus"
    },
    {
        "id": "297C",
        "text": "Bicervical bicornuate uterus"
    },
    {
        "id": "2977",
        "text": "Mullerian agenesis"
    },
    {
        "id": "2976",
        "text": "Anomaly of Bartholin duct"
    },
    {
        "id": "297J",
        "text": "Fusion of labia"
    },
    {
        "id": "2979",
        "text": "Structural developmental anomalies of uterus, except cervix"
    },
    {
        "id": "2A1T",
        "text": "Pierre Robin syndrome"
    },
    {
        "id": "2A1V",
        "text": "Congenital glaucoma associated with chromosomal abnormalities"
    },
    {
        "id": "2A1U",
        "text": "Congenital glaucoma associated with homocystinuria"
    },
    {
        "id": "2A1R",
        "text": "Congenital glaucoma associated with Sturge Weber syndrome"
    },
    {
        "id": "2A1W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A1X",
        "text": "Keratoacanthoma"
    },
    {
        "id": "2A1Q",
        "text": "Structural developmental anomalies of uterus, except cervix"
    },
    {
        "id": "2A20",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2A22",
        "text": "Lymphangiomatous cyst of unspecified site"
    },
    {
        "id": "2A23",
        "text": "Conjunctival haemangioma or haemolymphangioma"
    },
    {
        "id": "2A1P",
        "text": "Congenital rectovaginal fistula"
    },
    {
        "id": "2A25",
        "text": "COVID-19, virus identified"
    },
    {
        "id": "2A26",
        "text": "COVID-19"
    },
    {
        "id": "2A1N",
        "text": "Problem associated with interpersonal interactions in childhood"
    },
    {
        "id": "2A24",
        "text": "Autosomal recessive hyperimmunoglobulin E syndrome"
    },
    {
        "id": "297S",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2A28",
        "text": "Postprocedural residual arterial duct patency"
    },
    {
        "id": "2A27",
        "text": "Median arcuate ligament syndrome"
    },
    {
        "id": "2A2B",
        "text": "Alcohol intoxication"
    },
    {
        "id": "2A2A",
        "text": "Acute rheumatic pericarditis"
    },
    {
        "id": "2A2F",
        "text": "Cushing syndrome"
    },
    {
        "id": "2A2G",
        "text": "Chronic postsurgical or post traumatic pain"
    },
    {
        "id": "2A2H",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "2A2I",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "2A2J",
        "text": "Chronic neuropathic cancer pain"
    },
    {
        "id": "2A2K",
        "text": "Chronic painful chemotherapy-induced polyneuropathy"
    },
    {
        "id": "2A2L",
        "text": "Chronic painful radiation-induced neuropathy"
    },
    {
        "id": "2A2M",
        "text": "Developmental anomalies of cutaneous vasculature"
    },
    {
        "id": "2A2N",
        "text": "Developmental anomalies of cutaneous vasculature"
    },
    {
        "id": "2A2C",
        "text": "Agenesis of clitoris"
    },
    {
        "id": "2A2P",
        "text": "Chronic pain"
    },
    {
        "id": "2A2S",
        "text": "Hypertensive retinopathy"
    },
    {
        "id": "2A2T",
        "text": "Retinopathy of prematurity"
    },
    {
        "id": "2A2V",
        "text": "Chronic pain after herniotomy"
    },
    {
        "id": "2A2W",
        "text": "Chronic pain after spinal surgery"
    },
    {
        "id": "2A2X",
        "text": "Chronic pain after hysterectomy"
    },
    {
        "id": "2A2Y",
        "text": "Chronic pain after amputation"
    },
    {
        "id": "2A2Z",
        "text": "Chronic pain after thoracotomy"
    },
    {
        "id": "2A30",
        "text": "Chronic pain after breast surgery"
    },
    {
        "id": "2A31",
        "text": "Chronic pain after arthroplasty"
    },
    {
        "id": "2A32",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "2A33",
        "text": "Disease Specific Severity Scale Value"
    },
    {
        "id": "2A34",
        "text": "Intermittent-Persistent Scale Value"
    },
    {
        "id": "2A35",
        "text": "Disease Specific Severity Scale Value"
    },
    {
        "id": "2A36",
        "text": "Chronic pain after burns injury"
    },
    {
        "id": "2A37",
        "text": "Chronic whiplash injury associated pain"
    },
    {
        "id": "2A38",
        "text": "Chronic pain after musculoskeletal injury"
    },
    {
        "id": "2A3A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A3C",
        "text": "Neoplastic haemangioma"
    },
    {
        "id": "2A39",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "1Y0J",
        "text": "Complex regional pain syndrome type I"
    },
    {
        "id": "2990",
        "text": "Complex regional pain syndrome type II"
    },
    {
        "id": "2A3D",
        "text": "Burnout"
    },
    {
        "id": "2A3E",
        "text": "Enterococcal peritonitis"
    },
    {
        "id": "2A3F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A3G",
        "text": "Sepsis of fetus or newborn"
    },
    {
        "id": "2A3J",
        "text": "Disruption of operation wound, not elsewhere classified"
    },
    {
        "id": "299Q",
        "text": "Intrapartum fetal death"
    },
    {
        "id": "299P",
        "text": "Intrapartum fetal death"
    },
    {
        "id": "299R",
        "text": "Intrapartum fetal death"
    },
    {
        "id": "2A03",
        "text": "Spontaneous abortion"
    },
    {
        "id": "2A02",
        "text": "Spontaneous abortion"
    },
    {
        "id": "2A04",
        "text": "Induced abortion"
    },
    {
        "id": "2A01",
        "text": "Exceptionally large newborn"
    },
    {
        "id": "2A00",
        "text": "Preterm newborn"
    },
    {
        "id": "299Z",
        "text": "Other multiple births, all stillborn"
    },
    {
        "id": "299Y",
        "text": "Sextuplets, all stillborn"
    },
    {
        "id": "299X",
        "text": "Quintuplets, all stillborn"
    },
    {
        "id": "299W",
        "text": "Quadruplets, all stillborn"
    },
    {
        "id": "299V",
        "text": "Triplets, all stillborn"
    },
    {
        "id": "299U",
        "text": "Twins, both stillborn"
    },
    {
        "id": "299T",
        "text": "Twins, one liveborn and one stillborn"
    },
    {
        "id": "299S",
        "text": "Single stillbirth"
    },
    {
        "id": "299K",
        "text": "Fetal death, cause not specified"
    },
    {
        "id": "299M",
        "text": "Antepartum fetal death"
    },
    {
        "id": "299O",
        "text": "Antepartum fetal death"
    },
    {
        "id": "2A3K",
        "text": "Caregiver-child relationship problem"
    },
    {
        "id": "2A3L",
        "text": "Caregiver-child relationship problem with current caregiver"
    },
    {
        "id": "2A3M",
        "text": "Caregiver-child relationship problem with former caregiver"
    },
    {
        "id": "2A3N",
        "text": "Drug-induced osteonecrosis"
    },
    {
        "id": "2A3O",
        "text": "Dermatophytosis of perianal region"
    },
    {
        "id": "2A3P",
        "text": "Caregiver-child relationship problem with former caregiver"
    },
    {
        "id": "2A3Q",
        "text": "Impaction of large intestine"
    },
    {
        "id": "2A3S",
        "text": "Loss of parent in childhood"
    },
    {
        "id": "2A3T",
        "text": "Rheumatic heart disease, unspecified"
    },
    {
        "id": "2A2Q",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "2A40",
        "text": "Diabetic scleroedema"
    },
    {
        "id": "2A41",
        "text": "Food-induced urticaria or angioedema"
    },
    {
        "id": "2A42",
        "text": "Acanthamoebiasis"
    },
    {
        "id": "2A43",
        "text": "Acanthamoebiasis"
    },
    {
        "id": "2A44",
        "text": "Pubic infestation by Pthirus pubis"
    },
    {
        "id": "2A46",
        "text": "Pthiriasis of eyelashes"
    },
    {
        "id": "2A4J",
        "text": "Disseminated pthiriasis"
    },
    {
        "id": "2A4K",
        "text": "Parasitic infestation of eyelashes by Pthirus pubis"
    },
    {
        "id": "2A4L",
        "text": "Disseminated pthiriasis"
    },
    {
        "id": "2A4M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A4N",
        "text": "Tuberculosis of the nervous system"
    },
    {
        "id": "2A4O",
        "text": "Schistosomiasis"
    },
    {
        "id": "2A4P",
        "text": "Schistosomal granuloma of central nervous system"
    },
    {
        "id": "2A4Q",
        "text": "Acute human immunodeficiency virus infection syndrome"
    },
    {
        "id": "2A4R",
        "text": "Granuloma of brain due to Schistosoma haematobium"
    },
    {
        "id": "2A4S",
        "text": "Granuloma of brain due to schistosoma japonicum"
    },
    {
        "id": "2A4T",
        "text": "Granuloma of brain due to Schistosoma mansoni"
    },
    {
        "id": "2A4U",
        "text": "Liver diseases due to disorders of carbohydrate metabolism"
    },
    {
        "id": "2A4V",
        "text": "Erosive balanitis"
    },
    {
        "id": "2A4W",
        "text": "Bockenheimer syndrome"
    },
    {
        "id": "2A4X",
        "text": "Late cutaneous Lyme borreliosis"
    },
    {
        "id": "2A4Y",
        "text": "Koebner psoriasis"
    },
    {
        "id": "2A50",
        "text": "Vision impairment"
    },
    {
        "id": "2A51",
        "text": "Primary myelofibrosis"
    },
    {
        "id": "2A52",
        "text": "Diseases of the skin"
    },
    {
        "id": "2A54",
        "text": "Irritant balanitis"
    },
    {
        "id": "2A55",
        "text": "Aurocephalosyndactyly"
    },
    {
        "id": "2A56",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A57",
        "text": "Hilum of lung"
    },
    {
        "id": "2A58",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2968",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2A59",
        "text": "Infantile papular acrodermatitis"
    },
    {
        "id": "2A5A",
        "text": "Mucocutaneous lymph node syndrome"
    },
    {
        "id": "2A5B",
        "text": "Diseases of the skin"
    },
    {
        "id": "2A5D",
        "text": "Current depressive episode with melancholia"
    },
    {
        "id": "2A5E",
        "text": "Headache due to external application of a cold stimulus"
    },
    {
        "id": "2A5F",
        "text": "Headache due to ingestion or inhalation of a cold stimulus"
    },
    {
        "id": "2A5G",
        "text": "Headache associated with injury to the head or neck"
    },
    {
        "id": "2A5H",
        "text": "Acute headache associated with traumatic injury to the head"
    },
    {
        "id": "2A5I",
        "text": "Acute headache associated with moderate or severe injury to the head"
    },
    {
        "id": "2A5J",
        "text": "Acute headache associated with mild traumatic injury to the head"
    },
    {
        "id": "2A5K",
        "text": "Persistent headache associated with traumatic injury to the head"
    },
    {
        "id": "2A5L",
        "text": "Persistent headache associated with moderate or severe traumatic injury to the head"
    },
    {
        "id": "2A5M",
        "text": "Persistent headache associated with mild traumatic injury to the head"
    },
    {
        "id": "2A5N",
        "text": "Headache associated with craniotomy"
    },
    {
        "id": "2A5O",
        "text": "Acute headache associated with craniotomy"
    },
    {
        "id": "2A5P",
        "text": "Persistent headache associated with craniotomy"
    },
    {
        "id": "2A5Q",
        "text": "Acute headache associated with whiplash injury"
    },
    {
        "id": "2A5R",
        "text": "Headache due to cranial or cervical vascular intracranial disorder"
    },
    {
        "id": "2A5S",
        "text": "Headache due to giant cell arteritis"
    },
    {
        "id": "2A5T",
        "text": "Headache due to non-traumatic subarachnoid haemorrhage"
    },
    {
        "id": "2A5U",
        "text": "Headache or facial or neck pain due to cervical, carotid or vertebral artery dissection"
    },
    {
        "id": "2A5V",
        "text": "Headache due to cerebral venous thrombosis"
    },
    {
        "id": "2A5W",
        "text": "Headache due to other cranial or cervical vascular disorder"
    },
    {
        "id": "2A5X",
        "text": "Headache due to reversible cerebral vasoconstriction syndrome"
    },
    {
        "id": "2A5Y",
        "text": "Headache due to nonvascular intracranial disorder"
    },
    {
        "id": "2A5Z",
        "text": "Headache due to other causes of increased cerebrospinal fluid pressure"
    },
    {
        "id": "2A60",
        "text": "Headache due to idiopathic intracranial hypertension"
    },
    {
        "id": "2A61",
        "text": "Headache due to other causes of low cerebrospinal fluid pressure"
    },
    {
        "id": "2A62",
        "text": "Skin disorders associated with Human immunodeficiency virus infection"
    },
    {
        "id": "2A63",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A64",
        "text": "Familial isolated trichomegaly"
    },
    {
        "id": "2A65",
        "text": "Familial isolated trichomegaly"
    },
    {
        "id": "2A66",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A67",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A68",
        "text": "Headache associated with intracranial neoplasm"
    },
    {
        "id": "2A69",
        "text": "Headache associated with disorder of homeostasis"
    },
    {
        "id": "2A6A",
        "text": "Headache associated with arterial hypertension"
    },
    {
        "id": "2A6B",
        "text": "Headache associated with other disorders of homeostasis"
    },
    {
        "id": "2A6C",
        "text": "Headache or facial pain associated with disorder of the face or neck"
    },
    {
        "id": "2A6D",
        "text": "Headache due to acute glaucoma"
    },
    {
        "id": "2A6E",
        "text": "Headache or facial pain associated with temporomandibular disorder"
    },
    {
        "id": "2A6F",
        "text": "Headache or facial pain associated with other disorders of face or neck"
    },
    {
        "id": "2A6G",
        "text": "Headache associated with a substance"
    },
    {
        "id": "2A6H",
        "text": "Headache as an adverse event associated with chronic medication"
    },
    {
        "id": "2A6I",
        "text": "Headache associated with substance withdrawal"
    },
    {
        "id": "2A6J",
        "text": "Headache associated with caffeine withdrawal"
    },
    {
        "id": "2A6K",
        "text": "Headache associated with estrogen withdrawal"
    },
    {
        "id": "2A6L",
        "text": "Headache associated with withdrawal of other substance"
    },
    {
        "id": "2A6M",
        "text": "Medication-overuse headache associated with multiple drug classes not individually overused"
    },
    {
        "id": "2A6N",
        "text": "Medication headache associated with other medication overuse"
    },
    {
        "id": "2A6O",
        "text": "Headache or orofacial pain associated with chronic secondary temporomandibular disorders"
    },
    {
        "id": "2A6P",
        "text": "Chronic headache or orofacial pain associated with infection"
    },
    {
        "id": "2A6Q",
        "text": "Necrolytic acral erythema"
    },
    {
        "id": "2A6S",
        "text": "COVID-19, virus identified"
    },
    {
        "id": "2A6T",
        "text": "Human papillomavirus infection of skin or mucous membrane"
    },
    {
        "id": "2A6U",
        "text": "Infectious dermatitis"
    },
    {
        "id": "2A6V",
        "text": "Infectious dermatitis"
    },
    {
        "id": "2A6W",
        "text": "Talaromyces"
    },
    {
        "id": "2A6X",
        "text": "Talaromyces marneffei"
    },
    {
        "id": "2A6Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A6Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A70",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A71",
        "text": "Scedosporiosis"
    },
    {
        "id": "2A73",
        "text": "Congenital anomaly of position or spatial relationships of thoraco-abdominal organs"
    },
    {
        "id": "2A74",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A75",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A76",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A77",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A78",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A79",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A7A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A7E",
        "text": "Cardiac arrest"
    },
    {
        "id": "2A7F",
        "text": "Skin complications of BCG immunisation"
    },
    {
        "id": "2A7G",
        "text": "Indeterminate cell histiocytosis"
    },
    {
        "id": "2A7H",
        "text": "Indeterminate cell histiocytosis"
    },
    {
        "id": "2A7I",
        "text": "Low grade squamous intraepithelial lesion of vulva"
    },
    {
        "id": "2A7L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A7N",
        "text": "Postpartum depression NOS"
    },
    {
        "id": "2A72",
        "text": "Neonatal mucocutaneous candidosis"
    },
    {
        "id": "2A21",
        "text": "Pituitary-dependent Cushing disease"
    },
    {
        "id": "2A7R",
        "text": "Bacterial vaginosis"
    },
    {
        "id": "2A7Q",
        "text": "Skin injury due to bite by mammal"
    },
    {
        "id": "2A7S",
        "text": "Intermediate colic lymph node"
    },
    {
        "id": "2A7T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A7U",
        "text": "Burn of axilla, full thickness burn"
    },
    {
        "id": "2A7Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A7W",
        "text": "Disseminated lymphangiomatosis"
    },
    {
        "id": "2A7J",
        "text": "Carcinoma in situ of vulva"
    },
    {
        "id": "2A7M",
        "text": "Carcinoma in situ of vulva"
    },
    {
        "id": "2A7K",
        "text": "Carcinoma in situ of vulva"
    },
    {
        "id": "2A45",
        "text": "Pthiriasis"
    },
    {
        "id": "2A3Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A3U",
        "text": "Collagenous and elastotic marginal plaques of the hands"
    },
    {
        "id": "2A7Z",
        "text": "Consciousness"
    },
    {
        "id": "2A81",
        "text": "Certain conditions originating in the perinatal period"
    },
    {
        "id": "2A83",
        "text": "Congenital great vessel related acquired abnormality"
    },
    {
        "id": "2A82",
        "text": "Structural developmental anomalies of the circulatory system"
    },
    {
        "id": "2A80",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "2A84",
        "text": "Acquired absence of organs"
    },
    {
        "id": "2A7V",
        "text": "Structural developmental anomalies of the neurenteric canal, spinal cord or vertebral column"
    },
    {
        "id": "2A86",
        "text": "Chronic cancer pain"
    },
    {
        "id": "2A87",
        "text": "Extension Codes"
    },
    {
        "id": "2A88",
        "text": "Miliary tuberculosis"
    },
    {
        "id": "2A8A",
        "text": "Carcinoma in situ of larynx"
    },
    {
        "id": "2A8B",
        "text": "Carcinoma in situ of trachea"
    },
    {
        "id": "2A8C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A8D",
        "text": "Birth depression with 5 minute Apgar score 0-3"
    },
    {
        "id": "2A8E",
        "text": "Birth depression with 5 minute Apgar score 4-6"
    },
    {
        "id": "229D",
        "text": "Malignant neoplasms of testis"
    },
    {
        "id": "2A8F",
        "text": "Acute or subacute serous otitis media"
    },
    {
        "id": "2A8J",
        "text": "Junctional ectopic tachycardia"
    },
    {
        "id": "2A8K",
        "text": "Congenital junctional ectopic tachycardia"
    },
    {
        "id": "2A8L",
        "text": "Postoperative junctional ectopic tachycardia"
    },
    {
        "id": "2A8M",
        "text": "Idiopathic junctional ectopic tachycardia"
    },
    {
        "id": "2A8N",
        "text": "Accelerated junctional rhythm"
    },
    {
        "id": "2A8O",
        "text": "Malignant neoplasm of eyelid NOS"
    },
    {
        "id": "2A8P",
        "text": "Cherry angioma"
    },
    {
        "id": "2A53",
        "text": "Capacity or context"
    },
    {
        "id": "2A8R",
        "text": "Intermittent angle-closure"
    },
    {
        "id": "2A8S",
        "text": "Subacute closed-angle glaucoma"
    },
    {
        "id": "2A8T",
        "text": "Subacute closed-angle glaucoma"
    },
    {
        "id": "2A8U",
        "text": "Common acquired melanocytic naevus"
    },
    {
        "id": "2A8V",
        "text": "Positive symptoms in primary psychotic disorders"
    },
    {
        "id": "2A8W",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "2A8Y",
        "text": "Chronic secondary musculoskeletal pain associated with Parkinson\u2019s disease"
    },
    {
        "id": "2A8X",
        "text": "Chronic secondary musculoskeletal pain due to disease of the nervous system"
    },
    {
        "id": "2A8Z",
        "text": "Chronic secondary musculoskeletal pain associated with multiple sclerosis"
    },
    {
        "id": "2A90",
        "text": "Chronic secondary musculoskeletal pain associated with peripheral neurologic disease"
    },
    {
        "id": "2A94",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A95",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A96",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A97",
        "text": "Cylindrical cell papilloma"
    },
    {
        "id": "2A98",
        "text": "Gamma-benzene hexachloride (medicinal)"
    },
    {
        "id": "2A99",
        "text": "Intervertebral disc or space"
    },
    {
        "id": "2A9B",
        "text": "Endometriosis"
    },
    {
        "id": "2A9C",
        "text": "Omphalitis of newborn"
    },
    {
        "id": "2A9E",
        "text": "Combined haematuria with proteinuria and albuminuria"
    },
    {
        "id": "2A9F",
        "text": "Late congenital syphilitic encephalitis"
    },
    {
        "id": "2A9G",
        "text": "Late congenital syphilitic polyneuropathy"
    },
    {
        "id": "2A9H",
        "text": "Redundant prepuce, phimosis or paraphimosis"
    },
    {
        "id": "2A9I",
        "text": "Diseases of the myocardium or cardiac chambers"
    },
    {
        "id": "2A9L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A9M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A9N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A9O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A9Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A9R",
        "text": "Angiostrongylus cantonensis"
    },
    {
        "id": "2A9P",
        "text": "Angiostrongylus"
    },
    {
        "id": "2A9S",
        "text": "Measles vaccines"
    },
    {
        "id": "2A9T",
        "text": "Fibular artery"
    },
    {
        "id": "2A9U",
        "text": "Alverine"
    },
    {
        "id": "2A9K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A9V",
        "text": "Acrisorcin"
    },
    {
        "id": "2A9W",
        "text": "Dystonia"
    },
    {
        "id": "2A9X",
        "text": "Dystonic disorders"
    },
    {
        "id": "2A9Y",
        "text": "Malignant neoplasms of tonsil"
    },
    {
        "id": "2A9Z",
        "text": "Squamous cell carcinoma of tonsil"
    },
    {
        "id": "2B00",
        "text": "Other specified malignant neoplasms of tonsil"
    },
    {
        "id": "296P",
        "text": "Chronic painful polyneuropathy"
    },
    {
        "id": "2B01",
        "text": "Person getting into or out of a vehicle injured in transport related event"
    },
    {
        "id": "2A7B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2A7C",
        "text": "Transposition of abdominal organs"
    },
    {
        "id": "2A7D",
        "text": "Transposition of thoracic organs"
    },
    {
        "id": "2B02",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B03",
        "text": "Completely unroofed coronary sinus"
    },
    {
        "id": "2B04",
        "text": "Partially unroofed coronary sinus"
    },
    {
        "id": "2B05",
        "text": "Ascending aorta dissection not beyond arch"
    },
    {
        "id": "2B07",
        "text": "Descending aorta dissection and distal propagation"
    },
    {
        "id": "2B06",
        "text": "Thoracic aortic dissection, ascending aorta dissection and propagation beyond arch"
    },
    {
        "id": "2B08",
        "text": "Malignant neoplasms of stomach"
    },
    {
        "id": "2B0A",
        "text": "Adenocarcinomas of hepatic flexure"
    },
    {
        "id": "2B0B",
        "text": "Malignant neoplasms of rectosigmoid junction"
    },
    {
        "id": "2B0C",
        "text": "Adenocarcinoma of rectosigmoid junction"
    },
    {
        "id": "238P",
        "text": "Disorders of lipoprotein metabolism or certain specified lipidaemias"
    },
    {
        "id": "2B0F",
        "text": "Intrahepatic cholangiocarcinoma"
    },
    {
        "id": "2B0G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B0H",
        "text": "Feeding or eating disorders"
    },
    {
        "id": "2B0I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B0J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B0K",
        "text": "Fibroleiomyoma of uterus"
    },
    {
        "id": "2B0N",
        "text": "Methoxamine"
    },
    {
        "id": "2B0O",
        "text": "Norepinephrine"
    },
    {
        "id": "2B0P",
        "text": "Congenital pes planus"
    },
    {
        "id": "2B0Q",
        "text": "Urethral stricture"
    },
    {
        "id": "2B0R",
        "text": "Fistula between intestinal segments used for urinary diversion, not elsewhere classified"
    },
    {
        "id": "2B0S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B0T",
        "text": "Chronic cancer related pain"
    },
    {
        "id": "2B0U",
        "text": "Chronic post cancer treatment pain"
    },
    {
        "id": "2B0Z",
        "text": "Chronic post traumatic pain"
    },
    {
        "id": "2B0Y",
        "text": "Chronic postsurgical or post traumatic pain"
    },
    {
        "id": "2B10",
        "text": "Chronic postsurgical pain"
    },
    {
        "id": "2B15",
        "text": "Chronic secondary musculoskeletal pain"
    },
    {
        "id": "2B16",
        "text": "Chronic secondary musculoskeletal pain from persistent inflammation"
    },
    {
        "id": "2B18",
        "text": "Chronic secondary musculoskeletal pain associated with structural changes"
    },
    {
        "id": "2B19",
        "text": "Chronic secondary musculoskeletal pain associated with structural changes"
    },
    {
        "id": "2B1A",
        "text": "Chronic neuropathic pain"
    },
    {
        "id": "2B1B",
        "text": "Chronic central neuropathic pain"
    },
    {
        "id": "2B1C",
        "text": "Chronic peripheral neuropathic pain"
    },
    {
        "id": "2B1D",
        "text": "Chronic secondary visceral pain"
    },
    {
        "id": "2B1F",
        "text": "Chronic visceral pain from mechanical factors"
    },
    {
        "id": "2B1E",
        "text": "Chronic visceral pain from mechanical factors"
    },
    {
        "id": "2B1H",
        "text": "Chronic visceral pain from vascular mechanisms"
    },
    {
        "id": "2B1I",
        "text": "Chronic visceral pain from persistent inflammation"
    },
    {
        "id": "2B1J",
        "text": "Chronic dental pain"
    },
    {
        "id": "2B1K",
        "text": "Chronic neuropathic orofacial pain"
    },
    {
        "id": "2B1L",
        "text": "Headache or orofacial pain associated with chronic secondary temporomandibular disorders"
    },
    {
        "id": "2B1M",
        "text": "Neoplasms"
    },
    {
        "id": "2B1N",
        "text": "Polyneuropathy in neoplastic disease"
    },
    {
        "id": "2B1O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B1P",
        "text": "Post radiation polyneuropathy"
    },
    {
        "id": "2B1Q",
        "text": "Post radiation injury of the nervous system"
    },
    {
        "id": "2B1R",
        "text": "Post radiation lumbosacral plexopathy"
    },
    {
        "id": "2B1S",
        "text": "Post radiation scoliosis"
    },
    {
        "id": "2B1T",
        "text": "Post radiation kyphosis"
    },
    {
        "id": "2B1U",
        "text": "Multiple sclerosis"
    },
    {
        "id": "2B1V",
        "text": "Cauda equina syndrome"
    },
    {
        "id": "2B1W",
        "text": "Myelitis"
    },
    {
        "id": "2B1X",
        "text": "Myelopathy"
    },
    {
        "id": "2B1Y",
        "text": "Non-compressive vascular myelopathies"
    },
    {
        "id": "2B1Z",
        "text": "Chronic venous infarction of the spinal cord"
    },
    {
        "id": "2B21",
        "text": "Degenerative myelopathic disorders"
    },
    {
        "id": "2B22",
        "text": "Injury of cranial nerves"
    },
    {
        "id": "2B23",
        "text": "Diffuse brain injury"
    },
    {
        "id": "2B24",
        "text": "Injury of nerves or spinal cord at neck level"
    },
    {
        "id": "2B25",
        "text": "Injury of nerves or spinal cord at thorax level"
    },
    {
        "id": "2B26",
        "text": "Injury of nerves at shoulder or upper arm level"
    },
    {
        "id": "2B27",
        "text": "Injury of nerves at forearm level"
    },
    {
        "id": "2B28",
        "text": "Injury of nerves at wrist or hand level"
    },
    {
        "id": "2B29",
        "text": "Injury of nerves at hip or thigh level"
    },
    {
        "id": "2B2A",
        "text": "Injury of nerves at lower leg level"
    },
    {
        "id": "2B2B",
        "text": "Injury of nerves at ankle or foot level"
    },
    {
        "id": "2B2C",
        "text": "Radiculopathy"
    },
    {
        "id": "2B2D",
        "text": "Polyneuropathy"
    },
    {
        "id": "2B2E",
        "text": "Late effects of cerebrovascular disease"
    },
    {
        "id": "2B2F",
        "text": "Injury of unspecified nerve, spinal nerve root or plexus of trunk"
    },
    {
        "id": "2B2G",
        "text": "Injury of spinal cord, level unspecified"
    },
    {
        "id": "2B2H",
        "text": "Diabetic radiculoplexoneuropathy"
    },
    {
        "id": "2B2I",
        "text": "Mononeuropathy"
    },
    {
        "id": "2B2J",
        "text": "Nerve root or plexus disorders"
    },
    {
        "id": "2B2K",
        "text": "Alcoholic polyneuropathy"
    },
    {
        "id": "2B2L",
        "text": "Syphilitic Charcot arthropathy"
    },
    {
        "id": "2B2M",
        "text": "Human immunodeficiency virus disease"
    },
    {
        "id": "2B2N",
        "text": "Beh\u00e7et disease"
    },
    {
        "id": "2B2O",
        "text": "Bronchiectasis"
    },
    {
        "id": "2B2P",
        "text": "Chronic appendicitis"
    },
    {
        "id": "2B2Q",
        "text": "Chronic bronchitis"
    },
    {
        "id": "2B2R",
        "text": "Chronic pancreatitis"
    },
    {
        "id": "2B2S",
        "text": "Calcific pancreatitis"
    },
    {
        "id": "2B2T",
        "text": "Groove pancreatitis"
    },
    {
        "id": "2B2U",
        "text": "Hereditary chronic pancreatitis"
    },
    {
        "id": "2B2V",
        "text": "Chronic alcohol-induced pancreatitis"
    },
    {
        "id": "2B2W",
        "text": "Tropical pancreatitis"
    },
    {
        "id": "2B2X",
        "text": "Autoimmune pancreatitis"
    },
    {
        "id": "2B2Y",
        "text": "Obstructive pancreatitis"
    },
    {
        "id": "2B2Z",
        "text": "Chronic rheumatic pericarditis"
    },
    {
        "id": "2B30",
        "text": "Constrictive pericarditis"
    },
    {
        "id": "2B31",
        "text": "Chronic pharyngitis"
    },
    {
        "id": "2B32",
        "text": "Chronic prostatitis"
    },
    {
        "id": "2B33",
        "text": "Chronic salpingitis and oophoritis"
    },
    {
        "id": "2B34",
        "text": "Subacute or chronic vaginitis"
    },
    {
        "id": "2B35",
        "text": "Crohn disease"
    },
    {
        "id": "2B36",
        "text": "Ulcerative colitis"
    },
    {
        "id": "2B37",
        "text": "Indeterminate colitis"
    },
    {
        "id": "2B38",
        "text": "Endometriosis"
    },
    {
        "id": "2B39",
        "text": "Chronic inflammatory disease of uterus"
    },
    {
        "id": "2B3A",
        "text": "Gastritis"
    },
    {
        "id": "2B3B",
        "text": "Lupus erythematosus"
    },
    {
        "id": "2B3C",
        "text": "Polyarteritis nodosa"
    },
    {
        "id": "2B3D",
        "text": "Sickle cell disease with crisis"
    },
    {
        "id": "2B3E",
        "text": "Granulomatosis with polyangiitis"
    },
    {
        "id": "2B3F",
        "text": "Coronary atherosclerosis"
    },
    {
        "id": "2B3G",
        "text": "Lupus glomerulonephritis"
    },
    {
        "id": "2B3H",
        "text": "Lyme arthritis"
    },
    {
        "id": "2B3I",
        "text": "Infectious spondyloarthritis"
    },
    {
        "id": "2B3J",
        "text": "Chronic hepatitis B"
    },
    {
        "id": "2B3K",
        "text": "Chronic hepatitis C"
    },
    {
        "id": "2B3L",
        "text": "Tuberculosis of the musculoskeletal system"
    },
    {
        "id": "2B3M",
        "text": "Leprosy"
    },
    {
        "id": "2A85",
        "text": "Psoriatic arthritis"
    },
    {
        "id": "2B3O",
        "text": "Sj\u00f6gren syndrome"
    },
    {
        "id": "2B3N",
        "text": "Inflammatory arthropathies"
    },
    {
        "id": "2B3P",
        "text": "Inflammatory spondyloarthritis"
    },
    {
        "id": "2B3R",
        "text": "Primary osteoarthritis of hip"
    },
    {
        "id": "2B3Q",
        "text": "Osteoarthritis of hip"
    },
    {
        "id": "2B3S",
        "text": "Post traumatic osteoarthritis of hip"
    },
    {
        "id": "2B3T",
        "text": "Other secondary osteoarthritis of hip"
    },
    {
        "id": "2B3U",
        "text": "Osteoarthritis of knee"
    },
    {
        "id": "2B3V",
        "text": "Primary osteoarthritis of knee"
    },
    {
        "id": "2B3W",
        "text": "Post traumatic osteoarthritis of knee"
    },
    {
        "id": "2B3X",
        "text": "Other secondary osteoarthritis of knee"
    },
    {
        "id": "2B3Y",
        "text": "Osteoarthritis of wrist or hand"
    },
    {
        "id": "2B3Z",
        "text": "Primary osteoarthritis of wrist or hand"
    },
    {
        "id": "2B40",
        "text": "Post traumatic osteoarthritis of wrist or hand"
    },
    {
        "id": "2B41",
        "text": "Other secondary osteoarthritis of wrist or hand"
    },
    {
        "id": "2B42",
        "text": "Osteoarthritis of other specified joint"
    },
    {
        "id": "2B43",
        "text": "Primary osteoarthritis of other specified joint"
    },
    {
        "id": "2B44",
        "text": "Post traumatic osteoarthritis of other specified joint"
    },
    {
        "id": "2B45",
        "text": "Other secondary osteoarthritis of other specified joint"
    },
    {
        "id": "2B46",
        "text": "Oligoosteoarthritis"
    },
    {
        "id": "2B47",
        "text": "Polyosteoarthritis"
    },
    {
        "id": "2B48",
        "text": "Postinfectious arthropathies"
    },
    {
        "id": "2B49",
        "text": "Internal derangement of knee"
    },
    {
        "id": "2B4A",
        "text": "Certain specified joint derangements"
    },
    {
        "id": "2B4B",
        "text": "Arthropathy in diseases classified elsewhere"
    },
    {
        "id": "2B4C",
        "text": "Traumatic spondylopathy"
    },
    {
        "id": "2B4D",
        "text": "Plantar fasciitis"
    },
    {
        "id": "2B4E",
        "text": "Calcific tendinitis"
    },
    {
        "id": "2B4F",
        "text": "Trigger finger"
    },
    {
        "id": "2B4G",
        "text": "Spondylolysis"
    },
    {
        "id": "2B4H",
        "text": "Spondylolisthesis"
    },
    {
        "id": "2B4I",
        "text": "Ankylosing hyperostosis"
    },
    {
        "id": "2B4J",
        "text": "Bursitis"
    },
    {
        "id": "2B4K",
        "text": "Fibrous dysplasia of bone"
    },
    {
        "id": "2B4L",
        "text": "Osteosclerosis"
    },
    {
        "id": "2B4M",
        "text": "Ehlers-Danlos syndrome"
    },
    {
        "id": "2B4N",
        "text": "Chondromalacia"
    },
    {
        "id": "2B4O",
        "text": "Late syphilis involving the musculoskeletal system"
    },
    {
        "id": "2B4P",
        "text": "Parkinson disease"
    },
    {
        "id": "2B4Q",
        "text": "Motor neuron disease"
    },
    {
        "id": "2B4R",
        "text": "Spinal muscular atrophy"
    },
    {
        "id": "2B4S",
        "text": "Post polio progressive muscular atrophy"
    },
    {
        "id": "2B4T",
        "text": "Autosomal recessive epidermolytic ichthyosis"
    },
    {
        "id": "2B4V",
        "text": "Late effects of cerebrovascular disease"
    },
    {
        "id": "2B4W",
        "text": "Sequelae of poliomyelitis"
    },
    {
        "id": "2B4X",
        "text": "Sequelae of viral encephalitis"
    },
    {
        "id": "2B4Y",
        "text": "Cerebrovascular abnormalities"
    },
    {
        "id": "2B4Z",
        "text": "Cerebral arteritis, not elsewhere classified"
    },
    {
        "id": "2B50",
        "text": "Primary cerebral arteritis"
    },
    {
        "id": "2B51",
        "text": "Intracranial vascular malformation"
    },
    {
        "id": "2B52",
        "text": "Aneurysm or dissection of vertebral artery"
    },
    {
        "id": "2B53",
        "text": "Certain specified cerebrovascular diseases"
    },
    {
        "id": "2B54",
        "text": "Increased intracranial pressure"
    },
    {
        "id": "2B55",
        "text": "Intracranial hypotension"
    },
    {
        "id": "2B56",
        "text": "Epilepsy due to structural or metabolic conditions or diseases"
    },
    {
        "id": "2B57",
        "text": "Genetic or presumed genetic syndromes primarily expressed as epilepsy"
    },
    {
        "id": "2B58",
        "text": "Epileptic encephalopathies"
    },
    {
        "id": "2B59",
        "text": "Arnold-Chiari malformation type I"
    },
    {
        "id": "2B5A",
        "text": "Chronic rhinosinusitis"
    },
    {
        "id": "2B5B",
        "text": "Samter syndrome"
    },
    {
        "id": "2B5C",
        "text": "Disorders of refraction"
    },
    {
        "id": "2B5D",
        "text": "Disorders of olfactory nerve"
    },
    {
        "id": "2B5E",
        "text": "Disorders of vestibulocochlear nerve"
    },
    {
        "id": "2B5F",
        "text": "Disorders of spinal accessory nerve"
    },
    {
        "id": "2B5G",
        "text": "Disorders of hypoglossal nerve"
    },
    {
        "id": "2B5H",
        "text": "Disorders of multiple cranial nerves"
    },
    {
        "id": "2B5I",
        "text": "Disorders of vagus nerve"
    },
    {
        "id": "2B5J",
        "text": "Disorders of glossopharyngeal nerve"
    },
    {
        "id": "2B5K",
        "text": "Disorders of facial nerve"
    },
    {
        "id": "2B5L",
        "text": "Chronic dental injuries"
    },
    {
        "id": "2B5M",
        "text": "Diseases of pulp or periapical tissues"
    },
    {
        "id": "2B5N",
        "text": "Periodontal disease"
    },
    {
        "id": "2B5O",
        "text": "Aggressive periodontitis"
    },
    {
        "id": "2B5P",
        "text": "Periodontosis"
    },
    {
        "id": "2B5Q",
        "text": "Necrotising periodontal diseases"
    },
    {
        "id": "2B4U",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "2B5R",
        "text": "Malignant neoplasms of ampulla of Vater"
    },
    {
        "id": "2B5S",
        "text": "Adenocarcinoma of ampulla of Vater"
    },
    {
        "id": "2B5T",
        "text": "Neuroendocrine neoplasms of ampulla of Vater"
    },
    {
        "id": "2B5V",
        "text": "Adenocarcinoma of biliary tract, distal bile duct"
    },
    {
        "id": "2B5U",
        "text": "Malignant neoplasms of biliary tract, distal bile duct"
    },
    {
        "id": "2B5W",
        "text": "Mucinous cystic neoplasm with associated invasive carcinoma of distal bile duct"
    },
    {
        "id": "2B5X",
        "text": "Neuroendocrine neoplasms of distal bile duct"
    },
    {
        "id": "2B5Y",
        "text": "Malignant neoplasms of proximal biliary tract, cystic duct"
    },
    {
        "id": "2B60",
        "text": "Mucinous cystic neoplasm with associated invasive carcinoma of cystic duct"
    },
    {
        "id": "2B5Z",
        "text": "Adenocarcinoma of proximal biliary tract, cystic duct"
    },
    {
        "id": "2B61",
        "text": "Neuroendocrine neoplasms of cystic duct"
    },
    {
        "id": "2B62",
        "text": "Anoxic-ischaemic encephalopathy"
    },
    {
        "id": "2B64",
        "text": "Malignant neoplasm of pancreas"
    },
    {
        "id": "2B65",
        "text": "Neuroendocrine neoplasms of pancreas"
    },
    {
        "id": "2B66",
        "text": "Familial pancreatic carcinoma"
    },
    {
        "id": "2B67",
        "text": "Diseases of the skin complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "2B68",
        "text": "Malignant neoplasms of middle ear"
    },
    {
        "id": "2B6D",
        "text": "Specific phobia"
    },
    {
        "id": "211S",
        "text": "Headache or orofacial pain associated with chronic secondary temporomandibular disorders"
    },
    {
        "id": "211T",
        "text": "Headache or orofacial pain associated with chronic secondary temporomandibular disorders"
    },
    {
        "id": "211P",
        "text": "Chronic dental pain"
    },
    {
        "id": "211Q",
        "text": "Chronic dental pain"
    },
    {
        "id": "2B6F",
        "text": "Ectopic pancreatic tissue in stomach"
    },
    {
        "id": "2B6G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B6K",
        "text": "Accessory pancreas"
    },
    {
        "id": "2B6L",
        "text": "Salpingitis and oophoritis"
    },
    {
        "id": "2B6H",
        "text": "Ectopic pancreatic tissue in stomach"
    },
    {
        "id": "2B6M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B6N",
        "text": "Chronic pain"
    },
    {
        "id": "2B6O",
        "text": "Malignant neoplasm metastasis in supraclavicular region"
    },
    {
        "id": "2B6P",
        "text": "Short bowel syndrome in neonate"
    },
    {
        "id": "2B6Q",
        "text": "Restrictive interatrial communication or intact atrial septum when an interatrial shunt is physiologically necessary"
    },
    {
        "id": "2B6S",
        "text": "Spontaneous closure of ventricular septal defect"
    },
    {
        "id": "2B6T",
        "text": "Multiple trabecular muscular ventricular septal defects"
    },
    {
        "id": "2B6U",
        "text": "Mitral atresia with absent atrioventricular connection"
    },
    {
        "id": "2B6X",
        "text": "Aortoventricular tunnel"
    },
    {
        "id": "2B6W",
        "text": "Aorto-left ventricular tunnel"
    },
    {
        "id": "2B6V",
        "text": "Aorto-right ventricular tunnel"
    },
    {
        "id": "2B6R",
        "text": "Double orifice of mitral valve"
    },
    {
        "id": "2B6Z",
        "text": "Interrupted aortic arch between subclavian and common carotid arteries, type B"
    },
    {
        "id": "2B6Y",
        "text": "Interrupted aortic arch distal to subclavian artery, type A"
    },
    {
        "id": "2B70",
        "text": "Interrupted aortic arch between carotid arteries, type C"
    },
    {
        "id": "298J",
        "text": "Salmonella enteritis"
    },
    {
        "id": "2B72",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B73",
        "text": "Acute vascular disorders of intestine"
    },
    {
        "id": "2B74",
        "text": "Acute bowel infarction"
    },
    {
        "id": "2B75",
        "text": "Acute haemorrhagic enterocolitis"
    },
    {
        "id": "2B76",
        "text": "Ischaemic colitis"
    },
    {
        "id": "2B77",
        "text": "Vascular disorder of intestine, unspecified"
    },
    {
        "id": "2B78",
        "text": "Rectal prolapse"
    },
    {
        "id": "2B79",
        "text": "Anal spasm"
    },
    {
        "id": "2B7D",
        "text": "Proctalgia fugax"
    },
    {
        "id": "2B7G",
        "text": "Localised hypoplasia of dental enamel"
    },
    {
        "id": "2B7F",
        "text": "Hypocalcified amelogenesis imperfecta"
    },
    {
        "id": "2B7E",
        "text": "Amelogenesis imperfecta"
    },
    {
        "id": "2B7H",
        "text": "Hypoplastic amelogenesis imperfecta"
    },
    {
        "id": "2B7C",
        "text": "Levator ani syndrome"
    },
    {
        "id": "2B7I",
        "text": "Anal prolapse"
    },
    {
        "id": "2B7J",
        "text": "Injury of long flexor muscle, fascia or tendon of thumb at wrist or hand level"
    },
    {
        "id": "2B7K",
        "text": "Gonadotroph adenoma"
    },
    {
        "id": "2B7L",
        "text": "Fetus or newborn affected by liver cell necrosis complicating pregnancy, childbirth or puerperium"
    },
    {
        "id": "2B7N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B7M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B7O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B7P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B7R",
        "text": "Malignant neoplasm metastasis in male genital organs"
    },
    {
        "id": "2B7S",
        "text": "Bipolar type I disorder, currently in partial remission, most recent episode unspecified"
    },
    {
        "id": "2B7T",
        "text": "Bipolar type I disorder, currently in partial remission, most recent episode unspecified"
    },
    {
        "id": "2B7U",
        "text": "Common bile duct"
    },
    {
        "id": "2B7Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B7Z",
        "text": "Angioblastic meningioma of unspecified site"
    },
    {
        "id": "2B80",
        "text": "Angiomatous meningioma of unspecified site"
    },
    {
        "id": "2B81",
        "text": "Endotheliomatous meningioma of unspecified site"
    },
    {
        "id": "2B82",
        "text": "Fibroblastic meningioma of unspecified site"
    },
    {
        "id": "2B83",
        "text": "Hemangioblastic meningioma of unspecified site"
    },
    {
        "id": "2B84",
        "text": "Hemangiopericytic meningioma of unspecified site"
    },
    {
        "id": "2B85",
        "text": "Meningotheliomatous meningioma of unspecified site"
    },
    {
        "id": "2B87",
        "text": "Mixed meningioma of unspecified site"
    },
    {
        "id": "2B88",
        "text": "Psammomatous meningioma of unspecified site"
    },
    {
        "id": "2B89",
        "text": "Syncytial meningioma of unspecified site"
    },
    {
        "id": "2B8A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B8B",
        "text": "Neoplasms of uncertain behaviour of rectum"
    },
    {
        "id": "2B8C",
        "text": "Meningitis due to Treponema pallidum"
    },
    {
        "id": "2B8D",
        "text": "Neoplasms of unknown behaviour of rectum"
    },
    {
        "id": "2B8E",
        "text": "Malignant neoplasm of liver"
    },
    {
        "id": "2B8F",
        "text": "Thymic tumours"
    },
    {
        "id": "2B8G",
        "text": "Neoplasms of unknown behaviour of middle ear, respiratory or intrathoracic organs"
    },
    {
        "id": "2B7Q",
        "text": "Connective and other soft tissues of thymus"
    },
    {
        "id": "296A",
        "text": "Extended-spectrum beta-lactamase producing Klebsiella pneumoniae"
    },
    {
        "id": "2B8H",
        "text": "Invasive mole"
    },
    {
        "id": "2B8I",
        "text": "Invasive mole"
    },
    {
        "id": "2B8J",
        "text": "Myelodysplastic syndrome, unclassifiable"
    },
    {
        "id": "2B8K",
        "text": "Monoclonal gammopathy of undetermined significance"
    },
    {
        "id": "2B8L",
        "text": "Non-allergic asthma"
    },
    {
        "id": "2B8M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B8O",
        "text": "Underdosing, as mode of injury or harm"
    },
    {
        "id": "2B8P",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B8Q",
        "text": "Autosomal dominant polycystic liver disease"
    },
    {
        "id": "2B8R",
        "text": "Injury of long flexor muscle of thumb at wrist or hand level"
    },
    {
        "id": "2B8S",
        "text": "Fibropolycystic liver disease"
    },
    {
        "id": "2B8T",
        "text": "Polycystic liver disease"
    },
    {
        "id": "2B8N",
        "text": "Underdosing, as mode of injury or harm"
    },
    {
        "id": "2B8U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2B8W",
        "text": "Mastoiditis or related conditions"
    },
    {
        "id": "2B8X",
        "text": "Topical sunscreen"
    },
    {
        "id": "2B8Z",
        "text": "Neuropathy due to nutritional deficiency"
    },
    {
        "id": "2B90",
        "text": "Neuropathy due to toxicity"
    },
    {
        "id": "2B91",
        "text": "Fabry disease"
    },
    {
        "id": "2B92",
        "text": "Primary angiitis of the central nervous system"
    },
    {
        "id": "2B93",
        "text": "Paraneoplastic or autoimmune disorders of the peripheral or autonomic nervous system"
    },
    {
        "id": "2B94",
        "text": "Symptomatic late neurosyphilis"
    },
    {
        "id": "2B95",
        "text": "Lyme neuroborreliosis"
    },
    {
        "id": "2B96",
        "text": "Spinal stenosis"
    },
    {
        "id": "2B97",
        "text": "Intervertebral disc stenosis of neural canal"
    },
    {
        "id": "2B98",
        "text": "Neurosarcoidosis"
    },
    {
        "id": "2B99",
        "text": "Syringomyelia or syringobulbia"
    },
    {
        "id": "2B9A",
        "text": "Dry beriberi"
    },
    {
        "id": "2B9D",
        "text": "Brainstem stroke syndrome"
    },
    {
        "id": "2B9E",
        "text": "Cerebellar stroke syndrome"
    },
    {
        "id": "2B9F",
        "text": "Acute miliary tuberculosis of multiple sites"
    },
    {
        "id": "2B9G",
        "text": "Miliary tuberculosis"
    },
    {
        "id": "2B9H",
        "text": "Shoulder lesions"
    },
    {
        "id": "2B9I",
        "text": "Drug-induced lupus erythematosus"
    },
    {
        "id": "2B9J",
        "text": "Polymyositis"
    },
    {
        "id": "2B9K",
        "text": "Iliotibial band syndrome"
    },
    {
        "id": "2B9B",
        "text": "Primary tethered cord syndrome"
    },
    {
        "id": "2B9C",
        "text": "Diastematomyelia"
    },
    {
        "id": "2B9L",
        "text": "Congenital spondylolisthesis"
    },
    {
        "id": "2B9M",
        "text": "Multiple epiphyseal dysplasias"
    },
    {
        "id": "2B9N",
        "text": "Kyphosis"
    },
    {
        "id": "2B9Q",
        "text": "Upper extremity"
    },
    {
        "id": "2B9R",
        "text": "Lower extremity"
    },
    {
        "id": "2B9V",
        "text": "Perionychium of index finger"
    },
    {
        "id": "2B9W",
        "text": "Upper arm"
    },
    {
        "id": "2B9Z",
        "text": "Inguinocrural fold"
    },
    {
        "id": "2C00",
        "text": "Lower cheek"
    },
    {
        "id": "2C01",
        "text": "Superficial injury of head"
    },
    {
        "id": "2C02",
        "text": "Dislocation of jaw"
    },
    {
        "id": "2C03",
        "text": "Strain or sprain of jaw"
    },
    {
        "id": "2C04",
        "text": "Orbital inflammatory syndrome"
    },
    {
        "id": "2C05",
        "text": "Periostitis of orbit"
    },
    {
        "id": "2A2E",
        "text": "Structural developmental anomalies of vagina"
    },
    {
        "id": "2A2O",
        "text": "Structural developmental anomalies of cervix uteri"
    },
    {
        "id": "2A2D",
        "text": "Septate vagina"
    },
    {
        "id": "2C08",
        "text": "Systemic contact dermatitis due to ingested allergen"
    },
    {
        "id": "2C07",
        "text": "Systemic contact dermatitis due to ingested allergen"
    },
    {
        "id": "2C09",
        "text": "Erosive lichen planus of vulva or vagina"
    },
    {
        "id": "2C0A",
        "text": "Chronic seborrhoeic otitis externa"
    },
    {
        "id": "2C0B",
        "text": "Eczematous nail dystrophy"
    },
    {
        "id": "2C0C",
        "text": "Irritant contact dermatitis due to exposure to acids, alkalis or other specified chemical irritants"
    },
    {
        "id": "2C0E",
        "text": "Lymphocutaneous sporotrichosis"
    },
    {
        "id": "2C0F",
        "text": "Ascending bacterial lymphangitis due to Staphylococcus aureus"
    },
    {
        "id": "2C0G",
        "text": "Syphilis, stage unspecified, of bone"
    },
    {
        "id": "2C0H",
        "text": "Syphilis, stage unspecified, of muscle"
    },
    {
        "id": "2C0I",
        "text": "Hereditary disseminated or invasive dermatophytosis"
    },
    {
        "id": "2C0J",
        "text": "Dermatophytosis due to Trichophyton gourvilii"
    },
    {
        "id": "2C0K",
        "text": "Tertiary yaws"
    },
    {
        "id": "2C0L",
        "text": "Ulnar border of wrist"
    },
    {
        "id": "2C0M",
        "text": "Radial border of wrist"
    },
    {
        "id": "2C0N",
        "text": "Adrenomedullary hyperfunction"
    },
    {
        "id": "2C0O",
        "text": "Cushing syndrome"
    },
    {
        "id": "2C0P",
        "text": "Congenital deformities of feet"
    },
    {
        "id": "2C0Q",
        "text": "Malignant neoplasms of peritoneum"
    },
    {
        "id": "2C0R",
        "text": "Middle lobe of lung"
    },
    {
        "id": "2C0S",
        "text": "Cystitis"
    },
    {
        "id": "2C0T",
        "text": "Trigonitis"
    },
    {
        "id": "2C0U",
        "text": "Infectious cystitis"
    },
    {
        "id": "2C0V",
        "text": "Duodenitis"
    },
    {
        "id": "2C0W",
        "text": "Helicobacter-pylori associated duodenitis"
    },
    {
        "id": "2C0X",
        "text": "Eosinophilic duodenitis"
    },
    {
        "id": "2C0Y",
        "text": "Lymphocytic duodenitis"
    },
    {
        "id": "2C0Z",
        "text": "Allergic duodenitis"
    },
    {
        "id": "2C10",
        "text": "Duodenitis of unknown aetiology with specific endoscopic or pathologic features"
    },
    {
        "id": "2C11",
        "text": "Granulomatous duodenitis of unknown aetiology"
    },
    {
        "id": "2C12",
        "text": "Duodenitis due to external causes"
    },
    {
        "id": "2C13",
        "text": "Alcoholic duodenitis"
    },
    {
        "id": "2C14",
        "text": "Drug-induced duodenitis"
    },
    {
        "id": "2C15",
        "text": "Chemical duodenitis"
    },
    {
        "id": "2C16",
        "text": "Infectious duodenitis"
    },
    {
        "id": "2C17",
        "text": "Eosinophilic oesophagitis"
    },
    {
        "id": "2C18",
        "text": "Gastro-oesophageal reflux disease"
    },
    {
        "id": "2C19",
        "text": "Oesophageal ulcer"
    },
    {
        "id": "2C1A",
        "text": "Oesophageal erosion"
    },
    {
        "id": "2C1B",
        "text": "Infectious oesophageal ulcer"
    },
    {
        "id": "2C1C",
        "text": "Oesophageal ulcer due to allergic or immunologic disorder"
    },
    {
        "id": "2C1D",
        "text": "Oesophageal ulcer due to external causes"
    },
    {
        "id": "2C1E",
        "text": "Chemical oesophageal ulcer"
    },
    {
        "id": "2C1F",
        "text": "Drug-induced oesophageal ulcer"
    },
    {
        "id": "2C1G",
        "text": "Urethritis and urethral syndrome"
    },
    {
        "id": "2C1H",
        "text": "Non-occlusive mesenteric ischaemia"
    },
    {
        "id": "2C1I",
        "text": "Vascular disorders of the duodenum"
    },
    {
        "id": "2C1J",
        "text": "Congenital intestinal motility disorders"
    },
    {
        "id": "2C1K",
        "text": "Calculus of gallbladder or cystic duct without cholecystitis or cholangitis"
    },
    {
        "id": "2C1L",
        "text": "Ureteral kinking or deviation without obstruction"
    },
    {
        "id": "2C1M",
        "text": "Cardiomyopathy"
    },
    {
        "id": "2C1N",
        "text": "Coeliac artery compression syndrome"
    },
    {
        "id": "2C1O",
        "text": "Pelvic varices"
    },
    {
        "id": "2C1P",
        "text": "Gastric outlet obstruction"
    },
    {
        "id": "2C1Q",
        "text": "Lymphocytic colitis"
    },
    {
        "id": "2C1R",
        "text": "Perirectal abscess"
    },
    {
        "id": "2C1S",
        "text": "Ischiorectal abscess"
    },
    {
        "id": "2C1T",
        "text": "Anal abscess"
    },
    {
        "id": "2C1U",
        "text": "Abscess of liver"
    },
    {
        "id": "2C1V",
        "text": "Chronic pelvic inflammatory disease"
    },
    {
        "id": "2C1X",
        "text": "Pelvic peritoneal adhesions of unknown or combined origin"
    },
    {
        "id": "2C1Y",
        "text": "Inflammatory dermatoses of the vulva"
    },
    {
        "id": "2C1Z",
        "text": "Obstructive or reflux nephropathy"
    },
    {
        "id": "2C20",
        "text": "Calculus of upper urinary tract"
    },
    {
        "id": "2C21",
        "text": "Calculus of lower urinary tract"
    },
    {
        "id": "2C22",
        "text": "Bladder neck obstruction"
    },
    {
        "id": "2C23",
        "text": "Urethral caruncle"
    },
    {
        "id": "2C24",
        "text": "Obstruction of large intestine"
    },
    {
        "id": "2C25",
        "text": "Obstruction of small intestine"
    },
    {
        "id": "2C26",
        "text": "Anorexia"
    },
    {
        "id": "2C27",
        "text": "Neoplasms of unknown behaviour of oral cavity or digestive organs"
    },
    {
        "id": "2C28",
        "text": "Malignant neoplasms of uterine ligament, parametrium, or uterine adnexa"
    },
    {
        "id": "2C29",
        "text": "Adenocarcinoma of uterine ligament, parametrium, or uterine adnexa"
    },
    {
        "id": "2C2A",
        "text": "Mucinous or serous carcinoma of uterine ligament, parametrium, or uterine adnexa"
    },
    {
        "id": "2C2B",
        "text": "Carcinosarcomas of uterine ligament, parametrium, or uterine adnexa"
    },
    {
        "id": "2C2C",
        "text": "Malignant neoplasm involving overlapping sites of female genital organs"
    },
    {
        "id": "2C2D",
        "text": "Malignant neoplasms of placenta"
    },
    {
        "id": "2C2E",
        "text": "Acute kidney failure, stage 1"
    },
    {
        "id": "2C2F",
        "text": "Acute kidney failure, stage 2"
    },
    {
        "id": "2C2G",
        "text": "Acute kidney failure, stage 3"
    },
    {
        "id": "2C2H",
        "text": "Infectious liver disease"
    },
    {
        "id": "2C2I",
        "text": "Primary neoplasms of meninges"
    },
    {
        "id": "2C2J",
        "text": "Primary neoplasms of brain"
    },
    {
        "id": "2C2M",
        "text": "Acute myocardial infarction, without specification of ST elevation, inferior wall"
    },
    {
        "id": "2C2L",
        "text": "Acute periprocedural myocardial infarction"
    },
    {
        "id": "2C2N",
        "text": "Parietal scalp margin"
    },
    {
        "id": "2C2K",
        "text": "Lateral lumbar region"
    },
    {
        "id": "2C2O",
        "text": "Sickle cell disease with crisis"
    },
    {
        "id": "2C2P",
        "text": "Noninfectious neonatal diarrhoea"
    },
    {
        "id": "2C2Q",
        "text": "Chronic primary chest pain syndrome"
    },
    {
        "id": "2C2R",
        "text": "Chronic primary abdominal pain syndrome"
    },
    {
        "id": "2C2S",
        "text": "Coronary atherosclerosis of native coronary artery"
    },
    {
        "id": "2C2T",
        "text": "Malignant neoplasm metastasis in thoracic or respiratory organs"
    },
    {
        "id": "2C2U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C2V",
        "text": "Peripheral T-cell lymphoma, not otherwise specified"
    },
    {
        "id": "2C2W",
        "text": "Pyromania"
    },
    {
        "id": "2C2X",
        "text": "Kleptomania"
    },
    {
        "id": "2C2Y",
        "text": "Disease Specific Severity Scale Value"
    },
    {
        "id": "2C2Z",
        "text": "Phantom limb syndrome with pain"
    },
    {
        "id": "2C30",
        "text": "Disorders of plasma-protein metabolism, not elsewhere classified"
    },
    {
        "id": "2C31",
        "text": "Myeloid leukaemia"
    },
    {
        "id": "2C32",
        "text": "Acute myeloid leukaemia, not elsewhere classified by criteria of other types"
    },
    {
        "id": "2C33",
        "text": "Amyotrophy"
    },
    {
        "id": "2C34",
        "text": "Mononeuropathy"
    },
    {
        "id": "2C36",
        "text": "Concha of pinna"
    },
    {
        "id": "2C35",
        "text": "Posterior surface of pinna"
    },
    {
        "id": "2C38",
        "text": "Fetus or newborn affected by oligohydramnios"
    },
    {
        "id": "2C37",
        "text": "Fetus or newborn affected by oligohydramnios"
    },
    {
        "id": "2C3Q",
        "text": "Chronic myelogenous leukaemia, BCR-ABL1-positive"
    },
    {
        "id": "2C3R",
        "text": "Brief Model Disability Survey"
    },
    {
        "id": "2C3U",
        "text": "Chronic rhinitis, nasopharyngitis or pharyngitis"
    },
    {
        "id": "2C3V",
        "text": "Idiopathic progressive neuropathy"
    },
    {
        "id": "2C3W",
        "text": "Chronic inflammatory demyelinating polyneuropathy"
    },
    {
        "id": "2C3X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C3Y",
        "text": "Drug-induced polyneuropathy"
    },
    {
        "id": "2C3Z",
        "text": "Other secondary polyneuropathy"
    },
    {
        "id": "2C40",
        "text": "Atypical parkinsonism"
    },
    {
        "id": "2C41",
        "text": "Secondary parkinsonism"
    },
    {
        "id": "2C42",
        "text": "Dystonic disorders"
    },
    {
        "id": "2C43",
        "text": "Disorders associated with tremor"
    },
    {
        "id": "2C44",
        "text": "Intervertebral disc degeneration"
    },
    {
        "id": "2C45",
        "text": "Spondylolisthesis"
    },
    {
        "id": "2C46",
        "text": "Sj\u00f6gren syndrome"
    },
    {
        "id": "2C47",
        "text": "White matter disorders due to certain specified systemic disease"
    },
    {
        "id": "2C48",
        "text": "Neuroma of amputation stump"
    },
    {
        "id": "2C49",
        "text": "Amyloidosis"
    },
    {
        "id": "2C4A",
        "text": "Spondylolysis"
    },
    {
        "id": "2C4B",
        "text": "Structural developmental anomalies of spine"
    },
    {
        "id": "2C4C",
        "text": "Sequelae of leprosy"
    },
    {
        "id": "2C4D",
        "text": "Brainstem lesion"
    },
    {
        "id": "2C4E",
        "text": "Arnold-Chiari malformation type II"
    },
    {
        "id": "2C4F",
        "text": "Paraneoplastic or autoimmune disorders of the peripheral or autonomic nervous system"
    },
    {
        "id": "2C4G",
        "text": "Chronic temporomandibular disorder pain"
    },
    {
        "id": "2C4I",
        "text": "Chronic back pain, not otherwise specified"
    },
    {
        "id": "2C4K",
        "text": "Short-term insomnia"
    },
    {
        "id": "2C4L",
        "text": "Chronic insomnia"
    },
    {
        "id": "2C4M",
        "text": "Primary neoplasm of spinal cord of unknown or unspecified type"
    },
    {
        "id": "2C4Q",
        "text": "Mycoses"
    },
    {
        "id": "2C3S",
        "text": "Temporomandibular joint disorders"
    },
    {
        "id": "2C4R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C4S",
        "text": "Ciclosporin"
    },
    {
        "id": "2C4T",
        "text": "Desogestrel"
    },
    {
        "id": "2C4U",
        "text": "Diiodohydroxypropane"
    },
    {
        "id": "2C4W",
        "text": "Potassium bicarbonate"
    },
    {
        "id": "2C4Z",
        "text": "Calcium bromolactobionate"
    },
    {
        "id": "2C51",
        "text": "Calcium salts"
    },
    {
        "id": "2C52",
        "text": "Magnesium silicofluoride"
    },
    {
        "id": "2C53",
        "text": "Sodium"
    },
    {
        "id": "2C54",
        "text": "Potassium salts"
    },
    {
        "id": "2C55",
        "text": "Mineral salts and supplements, not elsewhere classified"
    },
    {
        "id": "2C4V",
        "text": "Potassium bitartrate"
    },
    {
        "id": "2C50",
        "text": "Calcium bromolactobionate"
    },
    {
        "id": "2C56",
        "text": "Silver nitrate"
    },
    {
        "id": "2C57",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C58",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C5A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C5B",
        "text": "Uncontrolled or unstable diabetes mellitus"
    },
    {
        "id": "2C5C",
        "text": "Maternal care for other conditions predominantly related to pregnancy"
    },
    {
        "id": "2C5D",
        "text": "Amlodipine"
    },
    {
        "id": "2C5F",
        "text": "Phenylalkylamine derivatives - selective"
    },
    {
        "id": "2C5G",
        "text": "Autosomal dominant optic atrophy and cataract"
    },
    {
        "id": "2C5H",
        "text": "Burn of eye or ocular adnexa"
    },
    {
        "id": "2C5I",
        "text": "Disorders of lipoprotein metabolism or certain specified lipidaemias"
    },
    {
        "id": "2C5J",
        "text": "Tixocortol pivalate"
    },
    {
        "id": "2C5L",
        "text": "Cetomacrogol"
    },
    {
        "id": "2C5K",
        "text": "Hyaluronic acid topical"
    },
    {
        "id": "2C5M",
        "text": "Nicotine insecticide"
    },
    {
        "id": "2C5P",
        "text": "Other metabolic disorders"
    },
    {
        "id": "2C5R",
        "text": "Duration of pregnancy"
    },
    {
        "id": "2C5S",
        "text": "Transient hyperthyrotropinaemia"
    },
    {
        "id": "2C5W",
        "text": "Acquired interventricular communication"
    },
    {
        "id": "2C5X",
        "text": "Cysticercosis of the intestine"
    },
    {
        "id": "2C5Y",
        "text": "Cysticercosis"
    },
    {
        "id": "2C5Z",
        "text": "Echinococcosis"
    },
    {
        "id": "2C60",
        "text": "Echinococcosis"
    },
    {
        "id": "2C61",
        "text": "Flexion deformity"
    },
    {
        "id": "2C62",
        "text": "Endometriosis in cutaneous scar"
    },
    {
        "id": "2C63",
        "text": "Systemic lupus erythematosus"
    },
    {
        "id": "2C64",
        "text": "Sj\u00f6gren syndrome with myopathy"
    },
    {
        "id": "2C66",
        "text": "Autoimmune myopathy"
    },
    {
        "id": "2C67",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C68",
        "text": "Hepatitis B surface antigen [HBsAg] carrier"
    },
    {
        "id": "2C65",
        "text": "Systemic sclerosis with myopathy"
    },
    {
        "id": "2C6B",
        "text": "Adenocarcinoma of bronchus or lung"
    },
    {
        "id": "2C6C",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "2C6D",
        "text": "Undernutrition based on anthropometric or clinical criteria in adults"
    },
    {
        "id": "2C6E",
        "text": "Cutaneous schwannoma"
    },
    {
        "id": "2C6G",
        "text": "Benign peripheral nerve sheath tumour"
    },
    {
        "id": "2C6I",
        "text": "Hydrosalpinx"
    },
    {
        "id": "2C5V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C6J",
        "text": "Functional abdominal pain syndrome"
    },
    {
        "id": "2C6K",
        "text": "Functional anorectal pain"
    },
    {
        "id": "2C6L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C6M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C6S",
        "text": "Viral vaccines"
    },
    {
        "id": "2C6T",
        "text": "Viral vaccines"
    },
    {
        "id": "2C6U",
        "text": "Viral vaccines"
    },
    {
        "id": "2C6R",
        "text": "Viral vaccines"
    },
    {
        "id": "2C6V",
        "text": "Viral vaccines"
    },
    {
        "id": "2C6W",
        "text": "Viral vaccines"
    },
    {
        "id": "2C6X",
        "text": "Viral vaccines"
    },
    {
        "id": "2C6Y",
        "text": "Viral vaccines"
    },
    {
        "id": "2C6Z",
        "text": "Viral vaccines"
    },
    {
        "id": "2C70",
        "text": "Prostatitis due to Trichomonas vaginalis"
    },
    {
        "id": "2C71",
        "text": "Cystic teratoma"
    },
    {
        "id": "2C72",
        "text": "Ovarian fibroma"
    },
    {
        "id": "2C73",
        "text": "Benign neoplasm of ovary"
    },
    {
        "id": "2C75",
        "text": "Acute hepatitis D"
    },
    {
        "id": "2A8Q",
        "text": "Aberrant origin of right subclavian artery"
    },
    {
        "id": "2C76",
        "text": "Restrictive interatrial communication or intact atrial septum when an interatrial shunt is physiologically necessary"
    },
    {
        "id": "2C78",
        "text": "Common atrium with common atrioventricular junction"
    },
    {
        "id": "2C79",
        "text": "Atypical right ventricular component of common atrioventricular valve"
    },
    {
        "id": "2C7A",
        "text": "Atypical left ventricular component of common atrioventricular valve"
    },
    {
        "id": "2C7B",
        "text": "Atypical left ventricular component of common atrioventricular valve"
    },
    {
        "id": "2C7D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C7E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C7F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C7G",
        "text": "Retro-oesophageal origin of aberrant innominate artery"
    },
    {
        "id": "2C7H",
        "text": "Coarctation of aorta"
    },
    {
        "id": "2C77",
        "text": "Restrictive interatrial communication or intact atrial septum when an interatrial shunt is physiologically necessary"
    },
    {
        "id": "2C7C",
        "text": "Absent or atretic pulmonary trunk"
    },
    {
        "id": "2C7I",
        "text": "Fungal endophthalmitis"
    },
    {
        "id": "2C7J",
        "text": "Certain obstetric conditions, not elsewhere classified"
    },
    {
        "id": "2C7L",
        "text": "Intracapsular fracture of femur"
    },
    {
        "id": "2C7K",
        "text": "Intracapsular fracture of femur"
    },
    {
        "id": "2C7M",
        "text": "International provisional assignment of new diseases of uncertain aetiology and emergency use"
    },
    {
        "id": "2C7N",
        "text": "International provisional assignment of new diseases of uncertain aetiology and emergency use"
    },
    {
        "id": "2C7P",
        "text": "Need for immunization against certain single viral diseases"
    },
    {
        "id": "2C7Q",
        "text": "Drugs, medicaments or biological substances associated with injury or harm in therapeutic use"
    },
    {
        "id": "2C7R",
        "text": "Personal history of infectious or parasitic diseases"
    },
    {
        "id": "2C7S",
        "text": "Double outlet left ventricle"
    },
    {
        "id": "2C7U",
        "text": "Congenital anomaly of ventricular septum"
    },
    {
        "id": "2C7V",
        "text": "Doubly committed juxta-arterial ventricular septal defect with anteriorly malaligned fibrous outlet septum and muscular postero-inferior rim"
    },
    {
        "id": "2C7W",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and perimembranous extension"
    },
    {
        "id": "2C7X",
        "text": "Ventricular septal defect haemodynamically insignificant"
    },
    {
        "id": "2C7Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C7Z",
        "text": "Tricuspid atresia with absent valvar annulus"
    },
    {
        "id": "2C80",
        "text": "Congenital pulmonary valve atresia"
    },
    {
        "id": "2C82",
        "text": "Ductus arteriosus agenesis"
    },
    {
        "id": "2C83",
        "text": "Congenital ductus arteriosus aneurysm"
    },
    {
        "id": "2C84",
        "text": "Coronary artery hypoplasia"
    },
    {
        "id": "2C85",
        "text": "Chiari network"
    },
    {
        "id": "2C86",
        "text": "Castleman disease"
    },
    {
        "id": "2C87",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C88",
        "text": "Legius syndrome"
    },
    {
        "id": "2C89",
        "text": "Lymphatic malformation of liver"
    },
    {
        "id": "2C8A",
        "text": "Ichthyosis follicularis \u2013 atrichia \u2013 photophobia"
    },
    {
        "id": "2C8B",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "2C8C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C8G",
        "text": "Malignant neoplasm of ciliary body"
    },
    {
        "id": "2C8H",
        "text": "Malignant neoplasm of choroid"
    },
    {
        "id": "2C8I",
        "text": "Malignant neoplasm of iris"
    },
    {
        "id": "2C8J",
        "text": "Toxic shock syndrome"
    },
    {
        "id": "2C4N",
        "text": "Intentional self-harm, person intended to die"
    },
    {
        "id": "2C8K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C8L",
        "text": "Remunerative employment [WHODAS]"
    },
    {
        "id": "2C8O",
        "text": "Recreation and leisure [WHODAS]"
    },
    {
        "id": "2C8P",
        "text": "Meningitis due to Cryptococcus neoformans"
    },
    {
        "id": "2C8Q",
        "text": "Cutaneous reactions to systemic exposure to contact allergens"
    },
    {
        "id": "2C5N",
        "text": "Antinauseants, antiemetics and emetics"
    },
    {
        "id": "2C74",
        "text": "Disorders specifically associated with stress"
    },
    {
        "id": "2C8R",
        "text": "Meningitis due to Haemophilus influenzae"
    },
    {
        "id": "2C8S",
        "text": "Sudden unexpected death in epilepsy"
    },
    {
        "id": "2C8U",
        "text": "Congenital stenosis of superior caval vein"
    },
    {
        "id": "2C8V",
        "text": "Congenital stenosis of inferior caval vein"
    },
    {
        "id": "2C8W",
        "text": "Completely unroofed coronary sinus"
    },
    {
        "id": "2C8X",
        "text": "Partially unroofed coronary sinus"
    },
    {
        "id": "2C8Y",
        "text": "Chiari network"
    },
    {
        "id": "2C8Z",
        "text": "Absent tricuspid valve leaflet"
    },
    {
        "id": "2C8T",
        "text": "Atypical truncal valve"
    },
    {
        "id": "2C90",
        "text": "True cleft of tricuspid valve leaflet"
    },
    {
        "id": "2C91",
        "text": "Congenital intravalvar mitral ring"
    },
    {
        "id": "2C92",
        "text": "Congenital supravalvar mitral ring"
    },
    {
        "id": "2C93",
        "text": "Accessory tissue on mitral valve leaflet"
    },
    {
        "id": "2C94",
        "text": "Congenital unguarded mitral orifice"
    },
    {
        "id": "2C95",
        "text": "Double orifice of mitral valve"
    },
    {
        "id": "2C96",
        "text": "Congenital left ventricular aneurysm"
    },
    {
        "id": "2C97",
        "text": "Congenital left ventricular diverticulum"
    },
    {
        "id": "2C99",
        "text": "Congenital pulmonary valve atresia"
    },
    {
        "id": "2C9A",
        "text": "Aorto-left ventricular tunnel"
    },
    {
        "id": "2C9B",
        "text": "Aorto-right ventricular tunnel"
    },
    {
        "id": "2C9C",
        "text": "Agenesis of pulmonary artery"
    },
    {
        "id": "2C9E",
        "text": "Preductal coarctation of aorta"
    },
    {
        "id": "2C9F",
        "text": "Postductal coarctation of aorta"
    },
    {
        "id": "2C9H",
        "text": "Diverticulum of Kommerell"
    },
    {
        "id": "2C9I",
        "text": "Anomalous ventricular bands"
    },
    {
        "id": "2C9J",
        "text": "Persistent fifth aortic arch"
    },
    {
        "id": "2C9K",
        "text": "Isolation of innominate artery"
    },
    {
        "id": "2C9L",
        "text": "Isolation of left subclavian artery"
    },
    {
        "id": "2C9M",
        "text": "Isolation of right subclavian artery"
    },
    {
        "id": "2C9N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C9O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C9P",
        "text": "Isolation of left common carotid artery"
    },
    {
        "id": "2C9Q",
        "text": "Isolation of right common carotid artery"
    },
    {
        "id": "2C9R",
        "text": "Aberrant innominate artery"
    },
    {
        "id": "2C9S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2C9T",
        "text": "Common origin of the innominate artery and left common carotid artery"
    },
    {
        "id": "2C9U",
        "text": "Separate origins of internal and external carotid arteries"
    },
    {
        "id": "2C9V",
        "text": "Coarctation of the descending thoracic aorta"
    },
    {
        "id": "2C9W",
        "text": "Coarctation of the abdominal aorta"
    },
    {
        "id": "2C9X",
        "text": "Retro-oesophageal origin of aberrant innominate artery"
    },
    {
        "id": "2C9Y",
        "text": "Ductus arteriosus agenesis"
    },
    {
        "id": "2C9Z",
        "text": "Congenital ductus arteriosus aneurysm"
    },
    {
        "id": "2D00",
        "text": "Anomalous origin of arterial ligament"
    },
    {
        "id": "2D01",
        "text": "Fetal arterial duct narrowing-closure"
    },
    {
        "id": "2D02",
        "text": "Accessory coronary artery"
    },
    {
        "id": "2D03",
        "text": "Congenital absence of coronary artery"
    },
    {
        "id": "2D04",
        "text": "Coronary artery hypoplasia"
    },
    {
        "id": "2D05",
        "text": "Congenital pericardial anomaly"
    },
    {
        "id": "2D06",
        "text": "Complete agenesis of pericardium"
    },
    {
        "id": "2D07",
        "text": "Partial agenesis of pericardium"
    },
    {
        "id": "2D08",
        "text": "Pleuropericardial cyst"
    },
    {
        "id": "2D09",
        "text": "Structural developmental anomaly of heart or great vessels"
    },
    {
        "id": "2D0A",
        "text": "Bifid apex of heart"
    },
    {
        "id": "2D0G",
        "text": "Left superior caval vein"
    },
    {
        "id": "2D0H",
        "text": "Interatrial communication"
    },
    {
        "id": "2D0I",
        "text": "Restrictive interventricular communication when an interventricular shunt is physiologically necessary"
    },
    {
        "id": "2D0J",
        "text": "Congenital ascending aorta aneurysm or dilation"
    },
    {
        "id": "2D0K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2D0L",
        "text": "Agenesis of pulmonary artery"
    },
    {
        "id": "2D0O",
        "text": "Encephalitis due to Listeria monocytogenes"
    },
    {
        "id": "2A29",
        "text": "Neuromyelitis optica myelin oligodendrocyte glycoprotein antibody-positive"
    },
    {
        "id": "2B7V",
        "text": "Meningiomas"
    },
    {
        "id": "2D0R",
        "text": "Intentional self-harm"
    },
    {
        "id": "2D0S",
        "text": "Intentional self-harm"
    },
    {
        "id": "2D0T",
        "text": "Intentional self-harm"
    },
    {
        "id": "2D0U",
        "text": "Intentional self-harm"
    },
    {
        "id": "2D0V",
        "text": "Intentional self-harm"
    },
    {
        "id": "2D0W",
        "text": "Abortion"
    },
    {
        "id": "2C4H",
        "text": "Atypical facial pain"
    },
    {
        "id": "297W",
        "text": "Problems related to primary support group, including family circumstances"
    },
    {
        "id": "2D0Y",
        "text": "Gangliocytic paraganglioma of duodenum"
    },
    {
        "id": "2D11",
        "text": "Neoplasm of paraganglion"
    },
    {
        "id": "2D12",
        "text": "Inflammatory disorders of the external ear"
    },
    {
        "id": "2D16",
        "text": "Disorders due to substance use"
    },
    {
        "id": "1X4B",
        "text": "1-methyl-4-phenyl-1,2,3,6-tetrahydropyridine induced Parkinsonism"
    },
    {
        "id": "2D1C",
        "text": "Disorders due to addictive behaviours"
    },
    {
        "id": "1K98",
        "text": "Cerebral venous thrombosis"
    },
    {
        "id": "1K9E",
        "text": "Moyamoya syndrome"
    },
    {
        "id": "1K9D",
        "text": "Subclavian steal syndrome"
    },
    {
        "id": "1K99",
        "text": "Cerebral atherosclerosis"
    },
    {
        "id": "1K9R",
        "text": "Porencephalic cyst"
    },
    {
        "id": "1K9S",
        "text": "Syringobulbia"
    },
    {
        "id": "1L2K",
        "text": "Autonomic neuropathy due to diabetes mellitus"
    },
    {
        "id": "1L2M",
        "text": "Disorders of orthostatic tolerance"
    },
    {
        "id": "1L4C",
        "text": "Leukodystrophies"
    },
    {
        "id": "1L4D",
        "text": "Adrenoleukodystrophy"
    },
    {
        "id": "1L60",
        "text": "Familial Parkinson disease"
    },
    {
        "id": "1L66",
        "text": "Ataxia due to Cerebrotendinous xanthomatosis"
    },
    {
        "id": "1L67",
        "text": "Ataxia due to abetalipoproteinemia"
    },
    {
        "id": "1L68",
        "text": "Ataxia due to Refsum disease"
    },
    {
        "id": "1X0P",
        "text": "Ataxia due to POLG mutations MIRAS"
    },
    {
        "id": "1L69",
        "text": "Hereditary episodic ataxia"
    },
    {
        "id": "1L6A",
        "text": "Spinocerebellar ataxia"
    },
    {
        "id": "1L6B",
        "text": "Non-hereditary degenerative ataxia"
    },
    {
        "id": "1L6C",
        "text": "Acquired ataxia"
    },
    {
        "id": "1L6I",
        "text": "Secondary stereotypy"
    },
    {
        "id": "1L6J",
        "text": "Excessive startle reflex"
    },
    {
        "id": "1L6U",
        "text": "Acute venous infarction of the spinal cord"
    },
    {
        "id": "1L6V",
        "text": "Chronic venous infarction of the spinal cord"
    },
    {
        "id": "1L71",
        "text": "Bacterial myelitis"
    },
    {
        "id": "1L73",
        "text": "Fungal myelitis"
    },
    {
        "id": "1M7L",
        "text": "Neurological disorders due to overweight or obesity in adults or children"
    },
    {
        "id": "1M7S",
        "text": "Hemichorea due to a focal brain lesion"
    },
    {
        "id": "1Q4Q",
        "text": "Frontotemporal dementia due to GRN mutation"
    },
    {
        "id": "1W9I",
        "text": "Diseases of neuromuscular junction or muscle"
    },
    {
        "id": "1W9K",
        "text": "Primary disorders of muscles"
    },
    {
        "id": "1W9L",
        "text": "Collagen VI deficiency"
    },
    {
        "id": "1W9M",
        "text": "Pure mitochondrial myopathy"
    },
    {
        "id": "1W9N",
        "text": "Lethal infantile mitochondrial myopathy"
    },
    {
        "id": "1W9O",
        "text": "Periodic paralysis"
    },
    {
        "id": "1W9Q",
        "text": "Myopathy"
    },
    {
        "id": "1W9R",
        "text": "Myopathy associated with other paraneoplastic conditions"
    },
    {
        "id": "1W9S",
        "text": "Necrotic myopathy"
    },
    {
        "id": "1W9T",
        "text": "Juvenile dermatomyositis with necrotising cutaneous vasculitis"
    },
    {
        "id": "1X0Q",
        "text": "Autosomal recessive spastic paraplegia type 27"
    },
    {
        "id": "1X0S",
        "text": "Autosomal dominant spastic paraplegia type 10"
    },
    {
        "id": "1X0V",
        "text": "Autosomal recessive ataxia with oculomotor apraxia type 1"
    },
    {
        "id": "1X0X",
        "text": "Ramsay Hunt cerebellar syndrome"
    },
    {
        "id": "1X0Z",
        "text": "Chorea due to Neurodegeneration with brain iron accumulation type 1"
    },
    {
        "id": "1X11",
        "text": "Autosomal recessive spastic paraplegia type 32"
    },
    {
        "id": "1X12",
        "text": "Hemiballism due to injuries to the head"
    },
    {
        "id": "1X14",
        "text": "Autosomal dominant cerebellar ataxia type 3"
    },
    {
        "id": "1X15",
        "text": "Autosomal dominant spastic paraplegia type 3"
    },
    {
        "id": "1X17",
        "text": "Hemiballism due to multiple sclerosis plaque"
    },
    {
        "id": "1X19",
        "text": "Stereotypy due to atypical Parkinsonism"
    },
    {
        "id": "1X1B",
        "text": "Primary progressive freezing gait"
    },
    {
        "id": "1X1C",
        "text": "Autosomal dominant spastic paraplegia type 6"
    },
    {
        "id": "1X1D",
        "text": "Parkinsonism in hereditary spastic paraplegia"
    },
    {
        "id": "1X1E",
        "text": "McLeod syndrome"
    },
    {
        "id": "1X1F",
        "text": "Stereotypy due to neuroacanthocytosis"
    },
    {
        "id": "1X1I",
        "text": "Autosomal dominant spastic paraplegia type 31"
    },
    {
        "id": "1X1J",
        "text": "Parkinsonism due to prion diseases"
    },
    {
        "id": "1X1K",
        "text": "Hemichorea due to surgical lesion"
    },
    {
        "id": "1X1L",
        "text": "Autosomal dominant spastic paraplegia type 12"
    },
    {
        "id": "1X1M",
        "text": "Hemiballism due to hyperosmolar nonketotic state"
    },
    {
        "id": "1X1Q",
        "text": "Stereotypy due to Huntington Disease"
    },
    {
        "id": "1X1R",
        "text": "Autosomal dominant spastic paraplegia type 42"
    },
    {
        "id": "1X1T",
        "text": "Hemiballism due to certain specified causes"
    },
    {
        "id": "1X1V",
        "text": "Autosomal dominant cerebellar ataxia type 4"
    },
    {
        "id": "1X1W",
        "text": "Ataxia due to acquired vitamin deficiency"
    },
    {
        "id": "1X1X",
        "text": "Autosomal dominant spastic paraplegia type 8"
    },
    {
        "id": "1X1Y",
        "text": "Paroxysmal dyskinesia due to neuromyelitis optica"
    },
    {
        "id": "1X1Z",
        "text": "Autosomal recessive spastic paraplegia type 35"
    },
    {
        "id": "1X21",
        "text": "Hemiballism due to cerebral infarct"
    },
    {
        "id": "1X22",
        "text": "Autosomal dominant spastic paraplegia type 19"
    },
    {
        "id": "1X23",
        "text": "Tics associated with certain specified pervasive developmental disorder"
    },
    {
        "id": "1X24",
        "text": "Hemiballism due to cerebral haemorrhage"
    },
    {
        "id": "1X25",
        "text": "Palatal tremor"
    },
    {
        "id": "1X26",
        "text": "Autosomal recessive ataxia"
    },
    {
        "id": "1X28",
        "text": "Chorea due to prion diseases"
    },
    {
        "id": "1X29",
        "text": "Parkinson disease UCHL-1 gene mutations 4p14"
    },
    {
        "id": "1X2A",
        "text": "X-linked spastic paraplegia type 34"
    },
    {
        "id": "1X2B",
        "text": "Dominant ataxia with unknown mutation"
    },
    {
        "id": "1X2C",
        "text": "Restless leg syndrome in uraemia"
    },
    {
        "id": "1X2D",
        "text": "Autosomal recessive spastic paraplegia type 30"
    },
    {
        "id": "1X2E",
        "text": "Autosomal dominant spastic paraplegia type 29"
    },
    {
        "id": "1X2F",
        "text": "Chorea due to polycythaemia rubra vera"
    },
    {
        "id": "1X2G",
        "text": "Autosomal recessive ataxias due to POLG mutations SANDO"
    },
    {
        "id": "1X2H",
        "text": "Autosomal recessive spastic paraplegia type 26"
    },
    {
        "id": "1X2I",
        "text": "Chorea due to dissemination of cancer"
    },
    {
        "id": "1X2L",
        "text": "Hemiballism due to surgical lesion"
    },
    {
        "id": "1X2P",
        "text": "Autosomal recessive spastic paraplegia type 20"
    },
    {
        "id": "1X2R",
        "text": "Hemiballism due to abscess"
    },
    {
        "id": "1X2S",
        "text": "Autosomal recessive spastic paraplegia type 14"
    },
    {
        "id": "1X2T",
        "text": "Dystonia due to Hartnup disease"
    },
    {
        "id": "1X2U",
        "text": "Autosomal dominant spastic ataxia"
    },
    {
        "id": "1X2V",
        "text": "Primary dystonia DYT1 gene mutation"
    },
    {
        "id": "1X2W",
        "text": "Dystonia due to Leber's disease"
    },
    {
        "id": "1X2Y",
        "text": "Pesticides-induced Parkinsonism"
    },
    {
        "id": "1X2X",
        "text": "Pure X-linked spastic paraplegia type 2"
    },
    {
        "id": "1X31",
        "text": "Restless leg syndrome in iron deficiency"
    },
    {
        "id": "1X33",
        "text": "Cervical dystonia or spasmodic torticollis"
    },
    {
        "id": "1X35",
        "text": "Hemichorea due to hyperosmolar nonketotic state"
    },
    {
        "id": "1X34",
        "text": "Tremor due to injuries to the head"
    },
    {
        "id": "1X36",
        "text": "Spinocerebellar ataxia type 31"
    },
    {
        "id": "1X38",
        "text": "Autosomal dominant spastic paraplegia type 38"
    },
    {
        "id": "1X3E",
        "text": "Autosomal recessive spastic paraplegia type 23"
    },
    {
        "id": "1X3F",
        "text": "Progressive Ataxia palatal tremor syndrome-GFAP gene mutation"
    },
    {
        "id": "1X3K",
        "text": "Dystonia due to Lesch-Nyhan syndrome"
    },
    {
        "id": "1X3J",
        "text": "Restless leg syndrome in pregnancy"
    },
    {
        "id": "1X3L",
        "text": "Parkinsonism associated with hydrocephalus"
    },
    {
        "id": "1X3M",
        "text": "Stereotypy associated with certain specified neurodevelopmental disorders"
    },
    {
        "id": "1X3N",
        "text": "Cyanide-induced Parkinsonism"
    },
    {
        "id": "1X3O",
        "text": "Tremor due to multiple sclerosis"
    },
    {
        "id": "1X3T",
        "text": "Spinocerebellar ataxia type 19"
    },
    {
        "id": "1X3W",
        "text": "Autosomal dominant cerebellar ataxia type 2"
    },
    {
        "id": "1X3Z",
        "text": "Stereotypy due to Parkinson\u2019s Disease"
    },
    {
        "id": "1X40",
        "text": "Ataxia due to Marinesco-Sj\u00f6gren syndrome"
    },
    {
        "id": "1X44",
        "text": "Tics due to neurosyphilis"
    },
    {
        "id": "1X46",
        "text": "Tic disorders"
    },
    {
        "id": "1X47",
        "text": "Dystonia due to Pelizaeus-Merzbacher disease"
    },
    {
        "id": "1X49",
        "text": "Parkinsonism in the setting of chronic infection"
    },
    {
        "id": "1X4A",
        "text": "Stereotypy due to certain specified conditions"
    },
    {
        "id": "1X4C",
        "text": "Hemichorea due to multiple sclerosis plaque"
    },
    {
        "id": "1X4D",
        "text": "Postencephalitic parkinsonism"
    },
    {
        "id": "1X4E",
        "text": "Hemichorea due to certain specified causes"
    },
    {
        "id": "1X4G",
        "text": "Hemiballism due to a focal brain lesion"
    },
    {
        "id": "1X4H",
        "text": "Other secondary choreas"
    },
    {
        "id": "1X4I",
        "text": "Familial congenital mirror movements"
    },
    {
        "id": "1X4J",
        "text": "Tics due to postencephalitic disorders"
    },
    {
        "id": "2D1D",
        "text": "Disorders due to use of caffeine"
    },
    {
        "id": "1X4K",
        "text": "Hemiballism due to arteriovenous malformation"
    },
    {
        "id": "1X4M",
        "text": "Hemiballism due to tumour"
    },
    {
        "id": "1X4O",
        "text": "Autosomal recessive spastic paraplegia type 11"
    },
    {
        "id": "1X4P",
        "text": "Hemichorea due to abscess"
    },
    {
        "id": "1X4Q",
        "text": "Parkinsonism due to spinocerebellar ataxias"
    },
    {
        "id": "2D1G",
        "text": "Catatonia associated with another mental disorder"
    },
    {
        "id": "2D1E",
        "text": "Catatonia induced by substances or medications"
    },
    {
        "id": "1X4R",
        "text": "Certain specified movement disorder"
    },
    {
        "id": "2D1H",
        "text": "Secondary catatonia syndrome"
    },
    {
        "id": "1X4T",
        "text": "Tremor, unspecified"
    },
    {
        "id": "2D1F",
        "text": "Catatonia"
    },
    {
        "id": "1X4V",
        "text": "Certain specified stereotypic disorders"
    },
    {
        "id": "1X4X",
        "text": "Myoclonus due to certain specified cause"
    },
    {
        "id": "2D1I",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "2D1J",
        "text": "Psychological or behavioural factors affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1X7A",
        "text": "Autosomal recessive complex hereditary spastic paraplegia due to mutations in Spatacsin gene"
    },
    {
        "id": "2D1K",
        "text": "Personality traits or coping style affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "1X7K",
        "text": "Autosomal dominant spastic paraplegia type 37"
    },
    {
        "id": "1X7L",
        "text": "Parkinsonism in heredodegenerative disorder, not elsewhere classified"
    },
    {
        "id": "2D1L",
        "text": "Posthallucinogen perception disorder"
    },
    {
        "id": "1X9W",
        "text": "Generalised tonic-clonic seizure"
    },
    {
        "id": "2D1M",
        "text": "Secondary obsessive-compulsive or related syndrome"
    },
    {
        "id": "2D1N",
        "text": "Opioid intoxication"
    },
    {
        "id": "1X9X",
        "text": "Generalised myoclonic seizure"
    },
    {
        "id": "2D1O",
        "text": "Hydrocephalus due to structural malformations"
    },
    {
        "id": "2D1P",
        "text": "Chorea due to acquired hepatocerebral degeneration"
    },
    {
        "id": "2D1Q",
        "text": "Other genetic Parkinsonism"
    },
    {
        "id": "2D1S",
        "text": "Secondary impulse control syndrome"
    },
    {
        "id": "2D1T",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "2D24",
        "text": "Bipolar type I disorder, current episode mixed, without psychotic symptoms"
    },
    {
        "id": "2D25",
        "text": "Bipolar type I disorder, current episode mixed, with psychotic symptoms"
    },
    {
        "id": "2D1U",
        "text": "Single episode depressive disorder, mild"
    },
    {
        "id": "2D1V",
        "text": "Recurrent depressive disorder, current episode mild"
    },
    {
        "id": "2D1W",
        "text": "Single episode depressive disorder, moderate, without psychotic symptoms"
    },
    {
        "id": "2D1Y",
        "text": "Single episode depressive disorder, moderate, with psychotic symptoms"
    },
    {
        "id": "2D20",
        "text": "Single episode depressive disorder, severe, without psychotic symptoms"
    },
    {
        "id": "2D22",
        "text": "Single episode depressive disorder, severe, with psychotic symptoms"
    },
    {
        "id": "2D1X",
        "text": "Recurrent depressive disorder, current episode moderate, without psychotic symptoms"
    },
    {
        "id": "2D1Z",
        "text": "Recurrent depressive disorder, current episode moderate, with psychotic symptoms"
    },
    {
        "id": "2D21",
        "text": "Recurrent depressive disorder, current episode severe, without psychotic symptoms"
    },
    {
        "id": "2D23",
        "text": "Recurrent depressive disorder, current episode severe, with psychotic symptoms"
    },
    {
        "id": "2D27",
        "text": "Recurrent depressive disorder, current episode, unspecified severity"
    },
    {
        "id": "2D28",
        "text": "Bipolar type I disorder, current episode depressive, mild"
    },
    {
        "id": "2D29",
        "text": "Bipolar type I disorder, current episode depressive, moderate without psychotic symptoms"
    },
    {
        "id": "2D2A",
        "text": "Bipolar type I disorder, current episode depressive, moderate with psychotic symptoms"
    },
    {
        "id": "2D2B",
        "text": "Bipolar type I disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "2D2C",
        "text": "Bipolar type I disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "2D2D",
        "text": "Bipolar type I disorder, current episode depressive, unspecified severity"
    },
    {
        "id": "2D2E",
        "text": "Bipolar type II disorder, current episode depressive, mild"
    },
    {
        "id": "2D2F",
        "text": "Bipolar type II disorder, current episode depressive, moderate without psychotic symptoms"
    },
    {
        "id": "2D2H",
        "text": "Single episode depressive disorder"
    },
    {
        "id": "2D2I",
        "text": "Recurrent depressive disorder"
    },
    {
        "id": "2D2J",
        "text": "Bipolar type I disorder"
    },
    {
        "id": "2D2K",
        "text": "Bipolar type II disorder, current episode depressive, moderate with psychotic symptoms"
    },
    {
        "id": "2D2L",
        "text": "Bipolar type II disorder, current episode depressive, severe without psychotic symptoms"
    },
    {
        "id": "2D2M",
        "text": "Bipolar type II disorder, current episode depressive, severe with psychotic symptoms"
    },
    {
        "id": "2D2G",
        "text": "Bipolar type II disorder"
    },
    {
        "id": "2D2N",
        "text": "Bipolar type II disorder, current episode depressive, unspecified severity"
    },
    {
        "id": "2D2O",
        "text": "Amnestic disorder"
    },
    {
        "id": "2D2R",
        "text": "Amnestic disorder due to psychoactive substances including medications"
    },
    {
        "id": "2D2Q",
        "text": "Amnestic disorder due to use of alcohol"
    },
    {
        "id": "2D2T",
        "text": "Amnestic disorder due to use of volatile inhalants"
    },
    {
        "id": "2D2S",
        "text": "Amnestic disorder due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "2D2U",
        "text": "Amnestic disorder due to other specified psychoactive substance including medications"
    },
    {
        "id": "2D2X",
        "text": "Dementia due to Alzheimer disease"
    },
    {
        "id": "2D2Y",
        "text": "Dementia due to Alzheimer disease with early onset"
    },
    {
        "id": "2D2Z",
        "text": "Dementia due to cerebrovascular disease"
    },
    {
        "id": "2D30",
        "text": "Frontotemporal dementia"
    },
    {
        "id": "2D31",
        "text": "Dementia due to Parkinson disease"
    },
    {
        "id": "2D32",
        "text": "Dementia due to Huntington disease"
    },
    {
        "id": "2D33",
        "text": "Dementia due to exposure to heavy metals and other toxins"
    },
    {
        "id": "2D34",
        "text": "Dementia due to multiple sclerosis"
    },
    {
        "id": "2D36",
        "text": "Dementia due to normal pressure hydrocephalus"
    },
    {
        "id": "2D37",
        "text": "Dementia due to Down syndrome"
    },
    {
        "id": "2D38",
        "text": "Dementia due to Lewy body disease"
    },
    {
        "id": "2D39",
        "text": "Dementia"
    },
    {
        "id": "2D3A",
        "text": "Schizophrenia, multiple episodes"
    },
    {
        "id": "2D3B",
        "text": "Schizoaffective disorder, multiple episodes"
    },
    {
        "id": "2D3C",
        "text": "Insomnia disorders"
    },
    {
        "id": "2D26",
        "text": "Single episode depressive disorder, unspecified severity"
    },
    {
        "id": "2D2P",
        "text": "Amnestic disorder due to diseases classified elsewhere"
    },
    {
        "id": "2D3D",
        "text": "Secondary neurodevelopmental syndrome"
    },
    {
        "id": "2D3E",
        "text": "Mental disorder affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "2D3F",
        "text": "Psychological symptoms affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "2D3G",
        "text": "Personality traits or coping style affecting disorders or diseases classified elsewhere"
    },
    {
        "id": "2D1R",
        "text": "Secondary mental or behavioural syndromes associated with disorders or diseases classified elsewhere"
    },
    {
        "id": "2D3H",
        "text": "Disorders due to substance use"
    },
    {
        "id": "2D3K",
        "text": "Psychological maltreatment"
    },
    {
        "id": "2D3J",
        "text": "Sexual maltreatment"
    },
    {
        "id": "2D3I",
        "text": "Physical maltreatment"
    },
    {
        "id": "2D3L",
        "text": "Maltreatment"
    },
    {
        "id": "2D3Q",
        "text": "History of spouse or partner violence, neglect"
    },
    {
        "id": "2D3U",
        "text": "Personal history of maltreatment"
    },
    {
        "id": "2D3R",
        "text": "Personal history of physical abuse"
    },
    {
        "id": "2D3S",
        "text": "Personal history of sexual abuse"
    },
    {
        "id": "2D3T",
        "text": "Personal history of psychological abuse"
    },
    {
        "id": "2D3V",
        "text": "Personal history of neglect"
    },
    {
        "id": "2C81",
        "text": "Agenesis of pulmonary artery"
    },
    {
        "id": "2A3H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2D3Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2D40",
        "text": "Amnestic disorder due to diseases classified elsewhere"
    },
    {
        "id": "296Q",
        "text": "Chronic painful polyneuropathy"
    },
    {
        "id": "2D3W",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "2D41",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "296N",
        "text": "Chronic primary temporomandibular disorder pains"
    },
    {
        "id": "296O",
        "text": "Chronic primary temporomandibular disorder pains"
    },
    {
        "id": "2992",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "2994",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "2996",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "2D43",
        "text": "Developmental language disorder"
    },
    {
        "id": "2D44",
        "text": "Developmental language disorder with impairment of receptive and expressive language"
    },
    {
        "id": "2D45",
        "text": "Developmental language disorder with impairment of mainly pragmatic language"
    },
    {
        "id": "2D46",
        "text": "Autism spectrum disorder"
    },
    {
        "id": "2D4E",
        "text": "Posterior blepharitis"
    },
    {
        "id": "2D4F",
        "text": "Developmental learning disorder with impairment in reading"
    },
    {
        "id": "2D4H",
        "text": "Developmental learning disorder with impairment in mathematics"
    },
    {
        "id": "2D4K",
        "text": "Catatonia"
    },
    {
        "id": "2D4I",
        "text": "Primary tics or tic disorders"
    },
    {
        "id": "2D4J",
        "text": "Stereotyped movement disorder"
    },
    {
        "id": "2D4M",
        "text": "Chorea due to Neurodegeneration with brain iron accumulation type 1"
    },
    {
        "id": "2D0Q",
        "text": "Stomach function disorder"
    },
    {
        "id": "2995",
        "text": "Chronic secondary headache or orofacial pain"
    },
    {
        "id": "2D49",
        "text": "Catatonia associated with another mental disorder"
    },
    {
        "id": "2D4Q",
        "text": "Assault by neglect"
    },
    {
        "id": "2D4P",
        "text": "Psychological maltreatment"
    },
    {
        "id": "2D4O",
        "text": "Sexual maltreatment"
    },
    {
        "id": "2D4S",
        "text": "Catatonia associated with another mental disorder"
    },
    {
        "id": "2D4T",
        "text": "Catatonia induced by substances or medications"
    },
    {
        "id": "2D4U",
        "text": "Secondary catatonia syndrome"
    },
    {
        "id": "2D4V",
        "text": "Malignant neoplasms of lip, oral cavity or pharynx"
    },
    {
        "id": "2D4W",
        "text": "Malignant neoplasms of other or ill-defined sites in the respiratory system or intrathoracic organs"
    },
    {
        "id": "2D4X",
        "text": "Malignant neoplasms of ill-defined or unspecified primary sites"
    },
    {
        "id": "2D1A",
        "text": "Coronary atherosclerosis"
    },
    {
        "id": "2D4Z",
        "text": "Alcohol dependence"
    },
    {
        "id": "2D50",
        "text": "Disorders due to use of other specified psychoactive substances, including medications"
    },
    {
        "id": "2D51",
        "text": "Cervical discs or space"
    },
    {
        "id": "2D54",
        "text": "Disorders due to use of alcohol"
    },
    {
        "id": "2D55",
        "text": "Disorders due to use of cannabis"
    },
    {
        "id": "2D56",
        "text": "Disorders due to use of synthetic cannabinoids"
    },
    {
        "id": "2D57",
        "text": "Disorders due to use of opioids"
    },
    {
        "id": "2D58",
        "text": "Disorders due to use of sedatives, hypnotics or anxiolytics"
    },
    {
        "id": "2D59",
        "text": "Disorders due to use of cocaine"
    },
    {
        "id": "2D5A",
        "text": "Disorders due to use of stimulants including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "2D5B",
        "text": "Disorders due to use of synthetic cathinones"
    },
    {
        "id": "2D5C",
        "text": "Disorders due to use of caffeine"
    },
    {
        "id": "2D5D",
        "text": "Disorders due to use of hallucinogens"
    },
    {
        "id": "2D5E",
        "text": "Disorders due to use of nicotine"
    },
    {
        "id": "2D5F",
        "text": "Disorders due to use of volatile inhalants"
    },
    {
        "id": "2D5G",
        "text": "Disorders due to use of MDMA or related drugs, including MDA"
    },
    {
        "id": "2D5H",
        "text": "Disorders due to use of dissociative drugs including ketamine and phencyclidine [PCP]"
    },
    {
        "id": "2D52",
        "text": "Disorders due to substance use"
    },
    {
        "id": "2D53",
        "text": "Disorders due to use of non-psychoactive substances"
    },
    {
        "id": "2D5K",
        "text": "Cannabis dependence"
    },
    {
        "id": "2D5L",
        "text": "Synthetic cannabinoid dependence"
    },
    {
        "id": "2D5M",
        "text": "Opioid dependence"
    },
    {
        "id": "2D5N",
        "text": "Sedative, hypnotic or anxiolytic dependence"
    },
    {
        "id": "2D5O",
        "text": "Cocaine dependence"
    },
    {
        "id": "2D5P",
        "text": "Stimulant dependence including amphetamines, methamphetamine or methcathinone"
    },
    {
        "id": "2D5Q",
        "text": "Synthetic cathinone dependence"
    },
    {
        "id": "2D5R",
        "text": "Hallucinogen dependence"
    },
    {
        "id": "2D5S",
        "text": "Nicotine dependence"
    },
    {
        "id": "2D5T",
        "text": "Volatile inhalant dependence"
    },
    {
        "id": "2D5U",
        "text": "MDMA or related drug dependence, including MDA"
    },
    {
        "id": "2D5V",
        "text": "Dissociative drug dependence including ketamine or PCP"
    },
    {
        "id": "2D5W",
        "text": "Other specified psychoactive substance dependence"
    },
    {
        "id": "2D5X",
        "text": "Multiple specified psychoactive substances dependence"
    },
    {
        "id": "2D5Y",
        "text": "Unknown or unspecified psychoactive substance dependence"
    },
    {
        "id": "2D5Z",
        "text": "Disorders due to use of hallucinogens"
    },
    {
        "id": "2D6C",
        "text": "Congenital club finger"
    },
    {
        "id": "2D6E",
        "text": "Amnestic disorder"
    },
    {
        "id": "2D6F",
        "text": "Delirium"
    },
    {
        "id": "2D6G",
        "text": "Impulse control disorders"
    },
    {
        "id": "2D6H",
        "text": "Disorders due to addictive behaviours"
    },
    {
        "id": "2D6I",
        "text": "Upper airway resistance syndrome"
    },
    {
        "id": "2D6J",
        "text": "Sleep-related hypoxemia due to a medical condition"
    },
    {
        "id": "2D6K",
        "text": "Bipolar type I disorder, current episode manic, without psychotic symptoms"
    },
    {
        "id": "2D6L",
        "text": "Bipolar type I disorder, current episode manic, with psychotic symptoms"
    },
    {
        "id": "2D6M",
        "text": "Bipolar type I disorder, current episode mixed, without psychotic symptoms"
    },
    {
        "id": "2D6N",
        "text": "Bipolar type I disorder, current episode mixed, with psychotic symptoms"
    },
    {
        "id": "2C6F",
        "text": "Neurofibroma"
    },
    {
        "id": "2D6O",
        "text": "Bipolar type I disorder, current episode manic, without psychotic symptoms"
    },
    {
        "id": "2D6P",
        "text": "Bipolar type I disorder, current episode manic, with psychotic symptoms"
    },
    {
        "id": "2D6Q",
        "text": "Bipolar type I disorder, current episode mixed, without psychotic symptoms"
    },
    {
        "id": "2D6R",
        "text": "Bipolar type I disorder, current episode mixed, with psychotic symptoms"
    },
    {
        "id": "2D6S",
        "text": "Neoplastic haemangioma"
    },
    {
        "id": "2D70",
        "text": "Intracerebral haemorrhage"
    },
    {
        "id": "2D6Z",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "2D6Y",
        "text": "Cholelithiasis with chronic cholecystitis"
    },
    {
        "id": "2D71",
        "text": "Procedure not carried out because of patient's decision for reasons of belief or group pressure"
    },
    {
        "id": "2D6X",
        "text": "Procedure not carried out because of contraindication"
    },
    {
        "id": "2D72",
        "text": "Procedure not carried out because of patient's decision for reasons other than belief or group pressure"
    },
    {
        "id": "2D74",
        "text": "Mucormycosis"
    },
    {
        "id": "2D75",
        "text": "Mucormycosis"
    },
    {
        "id": "2D7B",
        "text": "Delirium"
    },
    {
        "id": "2D7C",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "2D7D",
        "text": "Amnestic disorder"
    },
    {
        "id": "2D7E",
        "text": "Dementia"
    },
    {
        "id": "2D7F",
        "text": "Psychological maltreatment"
    },
    {
        "id": "2D7H",
        "text": "Endometriosis"
    },
    {
        "id": "2D7J",
        "text": "Polyposis syndrome"
    },
    {
        "id": "2D7K",
        "text": "Nupercaine topical"
    },
    {
        "id": "2D7L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2D7N",
        "text": "Ademethionine"
    },
    {
        "id": "2D7M",
        "text": "Antikaluretic"
    },
    {
        "id": "2D7R",
        "text": "Benzylhydrochlorothiazide"
    },
    {
        "id": "2D7S",
        "text": "Clofibric acid"
    },
    {
        "id": "2D7T",
        "text": "Moxonidine hydrochloride"
    },
    {
        "id": "2D7V",
        "text": "Benazepril hydrochloride"
    },
    {
        "id": "2D7W",
        "text": "Dihydroergokryptine"
    },
    {
        "id": "2D7X",
        "text": "Pronetalol"
    },
    {
        "id": "2D7Y",
        "text": "Stramonium"
    },
    {
        "id": "2D7Z",
        "text": "Glyceryl guaiacolate"
    },
    {
        "id": "2D80",
        "text": "Virus"
    },
    {
        "id": "2D84",
        "text": "Popliteal entrapment syndrome"
    },
    {
        "id": "2D81",
        "text": "Congenital complete atrioventricular block"
    },
    {
        "id": "2D82",
        "text": "Diseases of the circulatory system"
    },
    {
        "id": "2D88",
        "text": "Malignant neoplasms of large intestine"
    },
    {
        "id": "2D89",
        "text": "Cicatricial nasolacrimal canalicular stenosis"
    },
    {
        "id": "2D8A",
        "text": "Nasolacrimal canalicular stenosis secondary to drug toxicity"
    },
    {
        "id": "2D8D",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "2D8E",
        "text": "Amnestic disorder"
    },
    {
        "id": "2D8F",
        "text": "Dementia"
    },
    {
        "id": "2D8L",
        "text": "Meniere disease"
    },
    {
        "id": "2D8H",
        "text": "Episodic vestibular syndrome"
    },
    {
        "id": "2D8M",
        "text": "Meniere disease"
    },
    {
        "id": "2D8P",
        "text": "Positive symptoms in primary psychotic disorders"
    },
    {
        "id": "2D8Q",
        "text": "Negative symptoms in primary psychotic disorders"
    },
    {
        "id": "2D8R",
        "text": "Depressive mood symptoms in primary psychotic disorders"
    },
    {
        "id": "2D8S",
        "text": "Manic mood symptoms in primary psychotic disorders"
    },
    {
        "id": "2D8T",
        "text": "Psychomotor symptoms in primary psychotic disorders"
    },
    {
        "id": "2D8U",
        "text": "Cognitive symptoms in primary psychotic disorders"
    },
    {
        "id": "2D8V",
        "text": "Secondary Sj\u00f6gren syndrome"
    },
    {
        "id": "2D8Y",
        "text": "Acute laryngitis or tracheitis"
    },
    {
        "id": "2D8Z",
        "text": "Acute obstructive laryngitis or epiglottitis"
    },
    {
        "id": "2D90",
        "text": "Chronic rhinitis, nasopharyngitis or pharyngitis"
    },
    {
        "id": "2D91",
        "text": "Mycoses"
    },
    {
        "id": "2D8X",
        "text": "Acute graft-versus-host disease"
    },
    {
        "id": "2D95",
        "text": "Viral pneumonia"
    },
    {
        "id": "2D92",
        "text": "Allergic or hypersensitivity conditions"
    },
    {
        "id": "2D97",
        "text": "Nonpurulent chronic otitis media with effusion"
    },
    {
        "id": "2D98",
        "text": "Observation for suspected tuberculosis, ruled out"
    },
    {
        "id": "2D99",
        "text": "Polyosteoarthritis"
    },
    {
        "id": "2D9B",
        "text": "Developmental speech or language disorders"
    },
    {
        "id": "2D9K",
        "text": "Rett syndrome"
    },
    {
        "id": "2D9L",
        "text": "Medicaments"
    },
    {
        "id": "2D9M",
        "text": "Posthallucinogen perception disorder"
    },
    {
        "id": "2D9O",
        "text": "Symptomatic and course presentations for mood episodes in mood disorders"
    },
    {
        "id": "2D9P",
        "text": "Fire brigade pump vehicle as mode of transport of person injured in transport related event"
    },
    {
        "id": "2D9Q",
        "text": "Fire brigade pump vehicle as mode of transport of person injured in transport related event"
    },
    {
        "id": "2D9R",
        "text": "Type of sport or exercise activity, equestrian eventing"
    },
    {
        "id": "2D9S",
        "text": "Levodropropizine"
    },
    {
        "id": "2D9T",
        "text": "Tranquilizer phenothiazine"
    },
    {
        "id": "2D9V",
        "text": "Dihydroxyaluminum sodium carbonate"
    },
    {
        "id": "2D9W",
        "text": "Elotuzumab"
    },
    {
        "id": "2D9X",
        "text": "Disorders of the retina"
    },
    {
        "id": "2B7X",
        "text": "Meningiomas"
    },
    {
        "id": "2E0A",
        "text": "Wandering in dementia"
    },
    {
        "id": "2E0B",
        "text": "Sequelae of tuberculosis"
    },
    {
        "id": "2E09",
        "text": "Conditions with disorders of intellectual development as a relevant clinical feature"
    },
    {
        "id": "2E0C",
        "text": "Respiratory tuberculosis, confirmed"
    },
    {
        "id": "2E0D",
        "text": "Respiratory tuberculosis, not confirmed"
    },
    {
        "id": "2E0E",
        "text": "Tuberculosis of the respiratory system"
    },
    {
        "id": "2E0F",
        "text": "Bacterial colitis due to Shigella"
    },
    {
        "id": "2E0H",
        "text": "Vitamin B12 deficiency anaemia due to intestinal disease"
    },
    {
        "id": "2E0G",
        "text": "Vitamin B12 deficiency anaemia due to intestinal disease"
    },
    {
        "id": "2E0I",
        "text": "Folate deficiency anaemia due to decreased intestinal absorption"
    },
    {
        "id": "2E0J",
        "text": "Folate deficiency anaemia due to decreased intestinal absorption"
    },
    {
        "id": "2E0K",
        "text": "Chorea due to infectious or para-infectious causes"
    },
    {
        "id": "2E0L",
        "text": "Immunodeficiency-associated lymphoproliferative disorders"
    },
    {
        "id": "2E0M",
        "text": "Nasal polyp"
    },
    {
        "id": "2E0O",
        "text": "Localised lymph node enlargement"
    },
    {
        "id": "2E0P",
        "text": "COVID-19, virus identified"
    },
    {
        "id": "2E0Q",
        "text": "Gastroenteritis or colitis without specification of infectious agent"
    },
    {
        "id": "2C3P",
        "text": "Diseases of the nervous system"
    },
    {
        "id": "2E0R",
        "text": "Hypertensive renal disease"
    },
    {
        "id": "2E0S",
        "text": "Organ system disorders (TM1)"
    },
    {
        "id": "2E0U",
        "text": "Acute non-ST elevation myocardial infarction"
    },
    {
        "id": "2E0T",
        "text": "Other or unspecified ovarian cysts"
    },
    {
        "id": "2E0V",
        "text": "Substances associated with injury or harm in therapeutic use"
    },
    {
        "id": "2E0W",
        "text": "Acute kidney failure, stage 1"
    },
    {
        "id": "2E10",
        "text": "Dementia due to Parkinson disease"
    },
    {
        "id": "2E0X",
        "text": "Endometriosis"
    },
    {
        "id": "2E0Z",
        "text": "Adenomyosis"
    },
    {
        "id": "2E11",
        "text": "Excessive weight gain in pregnancy"
    },
    {
        "id": "2E12",
        "text": "Cerebral venous thrombosis in pregnancy"
    },
    {
        "id": "2E13",
        "text": "Pre-existing hypertensive heart disease complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "2E14",
        "text": "Pre-existing hypertensive renal disease complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "2E15",
        "text": "Pre-existing hypertensive heart and renal disease complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "2E16",
        "text": "Excessive vomiting in pregnancy"
    },
    {
        "id": "2E17",
        "text": "Pyrexia during labour, not elsewhere classified"
    },
    {
        "id": "2E18",
        "text": "Pyrexia of unknown origin following delivery"
    },
    {
        "id": "2E19",
        "text": "Abscess of breast associated with childbirth"
    },
    {
        "id": "2E1A",
        "text": "Sequelae of complication of pregnancy, childbirth or the puerperium"
    },
    {
        "id": "2E1B",
        "text": "Diseases of the visual system"
    },
    {
        "id": "2E1D",
        "text": "Need for immunization against COVID-19"
    },
    {
        "id": "2E1F",
        "text": "Median sacral artery"
    },
    {
        "id": "2E1E",
        "text": "Deep branch of dorsal scapular artery"
    },
    {
        "id": "2E1G",
        "text": "Obesity"
    },
    {
        "id": "2E1I",
        "text": "Symptoms or signs involving the skin"
    },
    {
        "id": "2E1J",
        "text": "Symptoms or signs involving the skin"
    },
    {
        "id": "2E1L",
        "text": "Branch of the medial superior genicular artery to surface of the femur and the knee-joint"
    },
    {
        "id": "2E1K",
        "text": "Branch of the medial superior genicular artery to\u00a0vastus medialis"
    },
    {
        "id": "2E1N",
        "text": "Somatotroph adenoma"
    },
    {
        "id": "2E1P",
        "text": "Benign neoplasm of pituitary gland"
    },
    {
        "id": "2E1Q",
        "text": "Carcinoma in situ of bronchus or lung"
    },
    {
        "id": "2E1R",
        "text": "Malignant neoplasms of small intestine"
    },
    {
        "id": "2E1S",
        "text": "Graft-versus-host disease"
    },
    {
        "id": "2E1T",
        "text": "Pachymeningitis due to infection"
    },
    {
        "id": "2E1U",
        "text": "Infections of the lacrimal gland"
    },
    {
        "id": "2E1V",
        "text": "Gastric volvulus"
    },
    {
        "id": "2E1W",
        "text": "Stenosis of anal canal"
    },
    {
        "id": "2E1X",
        "text": "Adult rumination syndrome"
    },
    {
        "id": "2E1Y",
        "text": "Idiopathic aseptic osteonecrosis"
    },
    {
        "id": "2E1Z",
        "text": "Other incomplete cord syndrome of thoracic spinal cord"
    },
    {
        "id": "2E20",
        "text": "Injury of multiple flexor muscles or tendons at wrist or hand level"
    },
    {
        "id": "2E21",
        "text": "Burn of hip or leg, except ankle or foot, deep partial thickness burn"
    },
    {
        "id": "2E22",
        "text": "Asymptomatic enteric carriage of Entamoeba"
    },
    {
        "id": "2E23",
        "text": "Attention deficit hyperactivity disorder"
    },
    {
        "id": "2E25",
        "text": "Malignant neoplasm metastasis in lymph node of a single region"
    },
    {
        "id": "2E27",
        "text": "Malignant neoplasm metastasis in lymph node of a single region"
    },
    {
        "id": "2E26",
        "text": "Malignant neoplasm metastasis in lymph node of a single region"
    },
    {
        "id": "2E28",
        "text": "Malignant neoplasm metastasis in lymph node of a single region"
    },
    {
        "id": "2E24",
        "text": "Malignant neoplasm metastasis in lymph node of a single region"
    },
    {
        "id": "2E29",
        "text": "Drug-induced folate deficiency anaemia"
    },
    {
        "id": "2E2A",
        "text": "Cerebral ischaemic stroke"
    },
    {
        "id": "2E2B",
        "text": "Infectious myocarditis"
    },
    {
        "id": "2E2C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2E2D",
        "text": "Infection or inflammatory reaction of heart valve prosthesis NOS"
    },
    {
        "id": "2E2G",
        "text": "Clinical findings in specimens from the urinary system"
    },
    {
        "id": "2E2H",
        "text": "Persistent proteinuria or albuminuria"
    },
    {
        "id": "2E2J",
        "text": "Very low birth weight of newborn"
    },
    {
        "id": "2E2K",
        "text": "Very low birth weight of newborn, 1000-1249g"
    },
    {
        "id": "2E2R",
        "text": "Osteoporosis in unspecified cystic fibrosis"
    },
    {
        "id": "2E2S",
        "text": "Osteoporosis"
    },
    {
        "id": "2E2T",
        "text": "Benign proliferations, neoplasms and cysts of the skin"
    },
    {
        "id": "2E2U",
        "text": "Skin infection classified elsewhere due to methicillin resistant Staphylococcus aureus"
    },
    {
        "id": "2E2V",
        "text": "Large newborn for gestational age"
    },
    {
        "id": "2E2W",
        "text": "Osteoarthritis of wrist or hand"
    },
    {
        "id": "2E2X",
        "text": "Primary osteoarthritis of wrist or hand"
    },
    {
        "id": "2E2Y",
        "text": "Post traumatic osteoarthritis of wrist or hand"
    },
    {
        "id": "2E2Z",
        "text": "Other secondary osteoarthritis of wrist or hand"
    },
    {
        "id": "2E30",
        "text": "Bacterial infection of joint, acromioclavicular joint"
    },
    {
        "id": "2E31",
        "text": "Bacterial infection of joint, sternoclavicular joint"
    },
    {
        "id": "2E32",
        "text": "Pneumococcal arthritis or polyarthritis, acromioclavicular joint"
    },
    {
        "id": "2E33",
        "text": "Pneumococcal arthritis or polyarthritis, glenohumeral joint"
    },
    {
        "id": "2E34",
        "text": "Pneumococcal arthritis or polyarthritis, sternoclavicular joint"
    },
    {
        "id": "2E35",
        "text": "Viral infection of joint, acromioclavicular joint"
    },
    {
        "id": "2E36",
        "text": "Viral infection of joint, glenohumeral joint"
    },
    {
        "id": "2E37",
        "text": "Viral infection of joint, sternoclavicular joint"
    },
    {
        "id": "2E38",
        "text": "Viral infection of joint, pelvic region or thigh"
    },
    {
        "id": "2E39",
        "text": "Viral infection of joint, ankle or foot"
    },
    {
        "id": "2E3A",
        "text": "Fungal infection of joint, acromioclavicular joint"
    },
    {
        "id": "2E3B",
        "text": "Fungal infection of joint, glenohumeral joint"
    },
    {
        "id": "2E3C",
        "text": "Fungal infection of joint, sternoclavicular joint"
    },
    {
        "id": "2E3D",
        "text": "Fungal infection of joint, pelvic region or thigh"
    },
    {
        "id": "2E3E",
        "text": "Fungal infection of joint, ankle or foot"
    },
    {
        "id": "2E3F",
        "text": "Arthropathy following intestinal bypass, acromioclavicular joint"
    },
    {
        "id": "2E3G",
        "text": "Arthropathy following intestinal bypass, glenohumeral joint"
    },
    {
        "id": "2E3H",
        "text": "Arthropathy following intestinal bypass, sternoclavicular joint"
    },
    {
        "id": "2E3I",
        "text": "Arthropathy following vaccination, acromioclavicular joint"
    },
    {
        "id": "2E3J",
        "text": "Arthropathy following vaccination, glenohumeral joint"
    },
    {
        "id": "2E3K",
        "text": "Arthropathy following vaccination, sternoclavicular joint"
    },
    {
        "id": "2E3L",
        "text": "Arthropathy following vaccination, pelvic region or thigh"
    },
    {
        "id": "2E3M",
        "text": "Arthropathy following vaccination, ankle or foot"
    },
    {
        "id": "2E3N",
        "text": "Arthropathy following genitourinary infection, ankle or foot"
    },
    {
        "id": "2E3O",
        "text": "Arthropathy following genitourinary infection, pelvic region or thigh"
    },
    {
        "id": "2E3P",
        "text": "Arthropathy in Crohn disease, ankle or foot"
    },
    {
        "id": "2E3Q",
        "text": "Arthropathy in ulcerative colitis, pelvic region or thigh"
    },
    {
        "id": "2E3R",
        "text": "Arthropathy in ulcerative colitis, ankle or foot"
    },
    {
        "id": "2E3S",
        "text": "Arthropathy in ulcerative colitis, acromioclavicular joint"
    },
    {
        "id": "2E3T",
        "text": "Arthropathy in ulcerative colitis, glenohumeral joint"
    },
    {
        "id": "2E3U",
        "text": "Arthropathy in ulcerative colitis, sternoclavicular joint"
    },
    {
        "id": "2E3V",
        "text": "Juvenile arthritis in Crohn disease, acromioclavicular joint"
    },
    {
        "id": "2E3W",
        "text": "Juvenile arthritis in Crohn disease, glenohumeral joint"
    },
    {
        "id": "2E3X",
        "text": "Juvenile arthritis in Crohn disease, sternoclavicular joint"
    },
    {
        "id": "2E3Y",
        "text": "Juvenile arthritis in Crohn disease, pelvic region or thigh"
    },
    {
        "id": "2E3Z",
        "text": "Juvenile arthritis in Crohn disease, ankle or foot"
    },
    {
        "id": "2E40",
        "text": "Juvenile arthritis in ulcerative colitis, ankle or foot"
    },
    {
        "id": "2E41",
        "text": "Juvenile arthritis in ulcerative colitis, pelvic region or thigh"
    },
    {
        "id": "2E42",
        "text": "Juvenile arthritis in ulcerative colitis, acromioclavicular joint"
    },
    {
        "id": "2E43",
        "text": "Juvenile arthritis in ulcerative colitis, glenohumeral joint"
    },
    {
        "id": "2E44",
        "text": "Juvenile arthritis in ulcerative colitis, sternoclavicular joint"
    },
    {
        "id": "2E45",
        "text": "Arthritis mutilans, acromioclavicular joint"
    },
    {
        "id": "2E46",
        "text": "Arthritis mutilans, glenohumeral joint"
    },
    {
        "id": "2E47",
        "text": "Arthritis mutilans, sternoclavicular joint"
    },
    {
        "id": "2E48",
        "text": "Reactive arthropathy in other diseases classified elsewhere of the acromioclavicular joint"
    },
    {
        "id": "2E49",
        "text": "Reactive arthropathy in other diseases classified elsewhere of the glenohumeral joint"
    },
    {
        "id": "2E4A",
        "text": "Reactive arthropathy, unspecified, acromioclavicular joint"
    },
    {
        "id": "2E4B",
        "text": "Reactive arthropathy, unspecified, glenohumeral joint"
    },
    {
        "id": "2E4C",
        "text": "Reactive arthropathy, unspecified, sternoclavicular joint"
    },
    {
        "id": "2E4D",
        "text": "Arthropathy following gastrointestinal infection, acromioclavicular joint"
    },
    {
        "id": "2E4E",
        "text": "Arthropathy following gastrointestinal infection, glenohumeral joint"
    },
    {
        "id": "2E4F",
        "text": "Arthropathy following gastrointestinal infection, sternoclavicular joint"
    },
    {
        "id": "2E4G",
        "text": "Arthropathy following gastrointestinal infection, ankle or foot"
    },
    {
        "id": "2E4H",
        "text": "Arthropathy following gastrointestinal infection, pelvic region or thigh"
    },
    {
        "id": "2E4I",
        "text": "Psoriatic arthritis, ankle or foot"
    },
    {
        "id": "2E4J",
        "text": "Adult-onset Still disease, acromioclavicular joint"
    },
    {
        "id": "2E4K",
        "text": "Adult-onset Still disease, glenohumeral joint"
    },
    {
        "id": "2E4L",
        "text": "Adult-onset Still disease, sternoclavicular joint"
    },
    {
        "id": "2E4M",
        "text": "Juvenile psoriatic arthritis, acromioclavicular joint"
    },
    {
        "id": "2E4N",
        "text": "Juvenile psoriatic arthritis, glenohumeral joint"
    },
    {
        "id": "2E4O",
        "text": "Juvenile psoriatic arthritis, sternoclavicular joint"
    },
    {
        "id": "2E4P",
        "text": "Juvenile psoriatic arthritis, pelvic region or thigh"
    },
    {
        "id": "2E4Q",
        "text": "Juvenile psoriatic arthritis, ankle or foot"
    },
    {
        "id": "2E4R",
        "text": "Juvenile systemic arthritis, ankle or foot"
    },
    {
        "id": "2E4S",
        "text": "Juvenile systemic arthritis, pelvic region or thigh"
    },
    {
        "id": "2E4T",
        "text": "Juvenile systemic arthritis, acromioclavicular joint"
    },
    {
        "id": "2E4U",
        "text": "Juvenile systemic arthritis, glenohumeral joint"
    },
    {
        "id": "2E4V",
        "text": "Juvenile systemic arthritis, sternoclavicular joint"
    },
    {
        "id": "2E4W",
        "text": "Primary gout, acromioclavicular joint"
    },
    {
        "id": "2E4X",
        "text": "Primary gout, glenohumeral joint"
    },
    {
        "id": "2E4Y",
        "text": "Primary gout, sternoclavicular joint"
    },
    {
        "id": "2E4Z",
        "text": "Primary gout, pelvic region or thigh"
    },
    {
        "id": "2E50",
        "text": "Primary gout, ankle or foot"
    },
    {
        "id": "2E51",
        "text": "Lead-induced gout, ankle or foot"
    },
    {
        "id": "2E52",
        "text": "Lead-induced gout, pelvic region or thigh"
    },
    {
        "id": "2E53",
        "text": "Lead-induced gout, acromioclavicular joint"
    },
    {
        "id": "2E54",
        "text": "Lead-induced gout, glenohumeral joint"
    },
    {
        "id": "2E55",
        "text": "Lead-induced gout, sternoclavicular joint"
    },
    {
        "id": "2E56",
        "text": "Drug-induced gout, acromioclavicular joint"
    },
    {
        "id": "2E57",
        "text": "Drug-induced gout, glenohumeral joint"
    },
    {
        "id": "2E58",
        "text": "Drug-induced gout, sternoclavicular joint"
    },
    {
        "id": "2E59",
        "text": "Drug-induced gout, pelvic region or thigh"
    },
    {
        "id": "2E5A",
        "text": "Drug-induced gout, ankle or foot"
    },
    {
        "id": "2E5B",
        "text": "Gout due to impairment of renal function, ankle or foot"
    },
    {
        "id": "2E5C",
        "text": "Gout due to impairment of renal function, pelvic region or thigh"
    },
    {
        "id": "2E5D",
        "text": "Gout due to impairment of renal function, acromioclavicular joint"
    },
    {
        "id": "2E5E",
        "text": "Gout due to impairment of renal function, glenohumeral joint"
    },
    {
        "id": "2E5F",
        "text": "Gout due to impairment of renal function, sternoclavicular joint"
    },
    {
        "id": "2E5G",
        "text": "Hydroxyapatite deposition disease, acromioclavicular joint"
    },
    {
        "id": "2E5H",
        "text": "Hydroxyapatite deposition disease, glenohumeral joint"
    },
    {
        "id": "2E5I",
        "text": "Hydroxyapatite deposition disease, sternoclavicular joint"
    },
    {
        "id": "2E5J",
        "text": "Hydroxyapatite deposition disease, pelvic region or thigh"
    },
    {
        "id": "2E5K",
        "text": "Hydroxyapatite deposition disease, ankle or foot"
    },
    {
        "id": "2E5L",
        "text": "Chondrocalcinosis, ankle or foot"
    },
    {
        "id": "2E5M",
        "text": "Chondrocalcinosis, pelvic region or thigh"
    },
    {
        "id": "2E5N",
        "text": "Chondrocalcinosis, acromioclavicular joint"
    },
    {
        "id": "2E5O",
        "text": "Chondrocalcinosis, glenohumeral joint"
    },
    {
        "id": "2E5P",
        "text": "Chondrocalcinosis, sternoclavicular joint"
    },
    {
        "id": "2E5Q",
        "text": "Chronic postrheumatic arthropathy, acromioclavicular joint"
    },
    {
        "id": "2E5R",
        "text": "Chronic postrheumatic arthropathy, glenohumeral joint"
    },
    {
        "id": "2E5S",
        "text": "Chronic postrheumatic arthropathy, sternoclavicular joint"
    },
    {
        "id": "2E5T",
        "text": "Chronic postrheumatic arthropathy, pelvic region or thigh"
    },
    {
        "id": "2E5U",
        "text": "Chronic postrheumatic arthropathy, ankle or foot"
    },
    {
        "id": "2E5V",
        "text": "Kaschin-Beck disease, ankle or foot"
    },
    {
        "id": "2E5W",
        "text": "Kaschin-Beck disease, pelvic region or thigh"
    },
    {
        "id": "2E5X",
        "text": "Kaschin-Beck disease, acromioclavicular joint"
    },
    {
        "id": "2E5Y",
        "text": "Kaschin-Beck disease, glenohumeral joint"
    },
    {
        "id": "2E5Z",
        "text": "Kaschin-Beck disease, sternoclavicular joint"
    },
    {
        "id": "2E60",
        "text": "Pigmented villonodular synovitis, acromioclavicular joint"
    },
    {
        "id": "2E61",
        "text": "Pigmented villonodular synovitis, glenohumeral joint"
    },
    {
        "id": "2E62",
        "text": "Pigmented villonodular synovitis, sternoclavicular joint"
    },
    {
        "id": "2E63",
        "text": "Pigmented villonodular synovitis, pelvic region or thigh"
    },
    {
        "id": "2E64",
        "text": "Pigmented villonodular synovitis, ankle or foot"
    },
    {
        "id": "2E65",
        "text": "Palindromic rheumatism, ankle or foot"
    },
    {
        "id": "2E66",
        "text": "Palindromic rheumatism, pelvic region or thigh"
    },
    {
        "id": "2E67",
        "text": "Palindromic rheumatism, acromioclavicular joint"
    },
    {
        "id": "2E68",
        "text": "Palindromic rheumatism, glenohumeral joint"
    },
    {
        "id": "2E69",
        "text": "Palindromic rheumatism, sternoclavicular joint"
    },
    {
        "id": "2E6A",
        "text": "Transient synovitis, pelvic region or thigh"
    },
    {
        "id": "2E6B",
        "text": "Transient synovitis, ankle or foot"
    },
    {
        "id": "2E6C",
        "text": "Intermittent hydrarthrosis, ankle or foot"
    },
    {
        "id": "2E6D",
        "text": "Intermittent hydrarthrosis, pelvic region or thigh"
    },
    {
        "id": "2E6E",
        "text": "Intermittent hydrarthrosis, acromioclavicular joint"
    },
    {
        "id": "2E6F",
        "text": "Intermittent hydrarthrosis, glenohumeral joint"
    },
    {
        "id": "2E6G",
        "text": "Intermittent hydrarthrosis, sternoclavicular joint"
    },
    {
        "id": "2E6H",
        "text": "Loose body in joint, acromioclavicular joint"
    },
    {
        "id": "2E6I",
        "text": "Loose body in joint, glenohumeral joint"
    },
    {
        "id": "2E6J",
        "text": "Loose body in joint, sternoclavicular joint"
    },
    {
        "id": "2E6K",
        "text": "Loose body in joint, pelvic region or thigh"
    },
    {
        "id": "2E6L",
        "text": "Loose body in joint, ankle or foot"
    },
    {
        "id": "2E6M",
        "text": "Other articular cartilage disorders, ankle or foot"
    },
    {
        "id": "2E6N",
        "text": "Other articular cartilage disorders, pelvic region or thigh"
    },
    {
        "id": "2E6O",
        "text": "Other articular cartilage disorders, acromioclavicular joint"
    },
    {
        "id": "2E6P",
        "text": "Other articular cartilage disorders, glenohumeral joint"
    },
    {
        "id": "2E6Q",
        "text": "Other articular cartilage disorders, sternoclavicular joint"
    },
    {
        "id": "2E6R",
        "text": "Disorder of ligament, acromioclavicular joint"
    },
    {
        "id": "2E6S",
        "text": "Disorder of ligament, glenohumeral joint"
    },
    {
        "id": "2E6T",
        "text": "Disorder of ligament, sternoclavicular joint"
    },
    {
        "id": "2E6U",
        "text": "Disorder of ligament, pelvic region or thigh"
    },
    {
        "id": "2E6V",
        "text": "Disorder of ligament, ankle or foot"
    },
    {
        "id": "2E6W",
        "text": "Recurrent instability of joint, ankle or foot"
    },
    {
        "id": "2E6X",
        "text": "Recurrent instability of joint, acromioclavicular joint"
    },
    {
        "id": "2E6Y",
        "text": "Recurrent instability of joint, glenohumeral joint"
    },
    {
        "id": "2E6Z",
        "text": "Recurrent instability of joint, sternoclavicular joint"
    },
    {
        "id": "2E70",
        "text": "Contracture of joint, acromioclavicular joint"
    },
    {
        "id": "2E71",
        "text": "Contracture of joint, glenohumeral joint"
    },
    {
        "id": "2E72",
        "text": "Contracture of joint, sternoclavicular joint"
    },
    {
        "id": "2E73",
        "text": "Contracture of joint, pelvic region or thigh"
    },
    {
        "id": "2E74",
        "text": "Contracture of joint, ankle or foot"
    },
    {
        "id": "2E75",
        "text": "Ankylosis of joint, ankle or foot"
    },
    {
        "id": "2E76",
        "text": "Ankylosis of joint, pelvic region or thigh"
    },
    {
        "id": "2E77",
        "text": "Ankylosis of joint, acromioclavicular joint"
    },
    {
        "id": "2E78",
        "text": "Ankylosis of joint, glenohumeral joint"
    },
    {
        "id": "2E79",
        "text": "Ankylosis of joint, sternoclavicular joint"
    },
    {
        "id": "2E7A",
        "text": "Effusion of joint containing blood, acromioclavicular joint"
    },
    {
        "id": "2E7B",
        "text": "Effusion of joint containing blood, glenohumeral joint"
    },
    {
        "id": "2E7C",
        "text": "Effusion of joint containing blood, sternoclavicular joint"
    },
    {
        "id": "2E7D",
        "text": "Effusion of joint containing blood, pelvic region or thigh"
    },
    {
        "id": "2E7E",
        "text": "Effusion of joint, ankle or foot"
    },
    {
        "id": "2E7F",
        "text": "Effusion of joint, pelvic region or thigh"
    },
    {
        "id": "2E7G",
        "text": "Effusion of joint, acromioclavicular joint"
    },
    {
        "id": "2E7H",
        "text": "Effusion of joint, glenohumeral joint"
    },
    {
        "id": "2E7I",
        "text": "Effusion of joint, sternoclavicular joint"
    },
    {
        "id": "2E7J",
        "text": "Fistula of joint, acromioclavicular joint"
    },
    {
        "id": "2E7K",
        "text": "Fistula of joint, glenohumeral joint"
    },
    {
        "id": "2E7L",
        "text": "Fistula of joint, sternoclavicular joint"
    },
    {
        "id": "2E7M",
        "text": "Fistula of joint, pelvic region or thigh"
    },
    {
        "id": "2E7N",
        "text": "Fistula of joint, ankle or foot"
    },
    {
        "id": "2E7O",
        "text": "Flail joint, pelvic region or thigh"
    },
    {
        "id": "2E7P",
        "text": "Flail joint, acromioclavicular joint"
    },
    {
        "id": "2E7Q",
        "text": "Flail joint, glenohumeral joint"
    },
    {
        "id": "2E7R",
        "text": "Flail joint, sternoclavicular joint"
    },
    {
        "id": "2E7S",
        "text": "Other instability of joint, acromioclavicular joint"
    },
    {
        "id": "2E7T",
        "text": "Other instability of joint, glenohumeral joint"
    },
    {
        "id": "2E7U",
        "text": "Other instability of joint, sternoclavicular joint"
    },
    {
        "id": "2E7V",
        "text": "Other instability of joint, ankle or foot"
    },
    {
        "id": "2E7W",
        "text": "Pain in joint, ankle or foot"
    },
    {
        "id": "2E7X",
        "text": "Pain in joint, pelvic region or thigh"
    },
    {
        "id": "2E7Y",
        "text": "Pain in joint, acromioclavicular joint"
    },
    {
        "id": "2E7Z",
        "text": "Pain in joint, glenohumeral joint"
    },
    {
        "id": "2E80",
        "text": "Pain in joint, sternoclavicular joint"
    },
    {
        "id": "2E81",
        "text": "Stiffness of joint, acromioclavicular joint"
    },
    {
        "id": "2E82",
        "text": "Stiffness of joint, glenohumeral joint"
    },
    {
        "id": "2E83",
        "text": "Stiffness of joint, sternoclavicular joint"
    },
    {
        "id": "2E84",
        "text": "Stiffness of joint, pelvic region or thigh"
    },
    {
        "id": "2E85",
        "text": "Stiffness of joint, ankle or foot"
    },
    {
        "id": "2E86",
        "text": "Osteophyte, ankle or foot"
    },
    {
        "id": "2E87",
        "text": "Osteophyte, pelvic region or thigh"
    },
    {
        "id": "2E88",
        "text": "Osteophyte, acromioclavicular joint"
    },
    {
        "id": "2E89",
        "text": "Osteophyte, glenohumeral joint"
    },
    {
        "id": "2E8A",
        "text": "Osteophyte, sternoclavicular joint"
    },
    {
        "id": "2E8B",
        "text": "Lipoid dermatoarthritis of the acromioclavicular joint"
    },
    {
        "id": "2E8C",
        "text": "Lipoid dermatoarthritis of the glenohumeral joint"
    },
    {
        "id": "2E8D",
        "text": "Lipoid dermatoarthritis of the sternoclavicular joint"
    },
    {
        "id": "2E8G",
        "text": "Valgus deformity, not elsewhere classified, acromioclavicular joint"
    },
    {
        "id": "2E8H",
        "text": "Valgus deformity, not elsewhere classified, glenohumeral joint"
    },
    {
        "id": "2E8I",
        "text": "Valgus deformity, not elsewhere classified, sternoclavicular joint"
    },
    {
        "id": "2E8J",
        "text": "Valgus deformity, not elsewhere classified, pelvic region or thigh"
    },
    {
        "id": "2E8K",
        "text": "Valgus deformity, not elsewhere classified, ankle or foot"
    },
    {
        "id": "2E8L",
        "text": "Varus deformity, not elsewhere classified, acromioclavicular joint"
    },
    {
        "id": "2E8M",
        "text": "Varus deformity, not elsewhere classified, glenohumeral joint"
    },
    {
        "id": "2E8N",
        "text": "Varus deformity, not elsewhere classified, sternoclavicular joint"
    },
    {
        "id": "2E8O",
        "text": "Varus deformity, not elsewhere classified, pelvic region or thigh"
    },
    {
        "id": "2E8P",
        "text": "Varus deformity, not elsewhere classified, ankle or foot"
    },
    {
        "id": "2E8Q",
        "text": "Flexion deformity, acromioclavicular joint"
    },
    {
        "id": "2E8R",
        "text": "Flexion deformity, glenohumeral joint"
    },
    {
        "id": "2E8S",
        "text": "Flexion deformity, sternoclavicular joint"
    },
    {
        "id": "2E8T",
        "text": "Flexion deformity, pelvic region or thigh"
    },
    {
        "id": "2E8U",
        "text": "Flexion deformity, ankle or foot"
    },
    {
        "id": "2E8V",
        "text": "Myositis ossificans traumatica, pelvic region or thigh"
    },
    {
        "id": "2E8W",
        "text": "Myositis ossificans traumatica, ankle or foot"
    },
    {
        "id": "2E8X",
        "text": "Paralytic calcification or ossification of muscle, ankle or foot"
    },
    {
        "id": "2E8Y",
        "text": "Paralytic calcification or ossification of muscle, pelvic region or thigh"
    },
    {
        "id": "2E8Z",
        "text": "Calcification or ossification of muscles associated with burns, pelvic region or thigh"
    },
    {
        "id": "2E90",
        "text": "Calcification or ossification of muscles associated with burns, ankle or foot"
    },
    {
        "id": "2E92",
        "text": "Fibrodysplasia ossificans progressiva, ankle or foot"
    },
    {
        "id": "2E91",
        "text": "Fibrodysplasia ossificans progressiva, pelvic region or thigh"
    },
    {
        "id": "2E93",
        "text": "Other calcification of muscle, pelvic region or thigh"
    },
    {
        "id": "2E94",
        "text": "Other calcification of muscle, ankle or foot"
    },
    {
        "id": "2E95",
        "text": "Other ossification of muscle, ankle or foot"
    },
    {
        "id": "2E96",
        "text": "Other ossification of muscle, pelvic region or thigh"
    },
    {
        "id": "2E97",
        "text": "Calcification or ossification of muscle, pelvic region or thigh"
    },
    {
        "id": "2E98",
        "text": "Calcification or ossification of muscle, ankle or foot"
    },
    {
        "id": "2E99",
        "text": "Diastasis of muscle, ankle or foot"
    },
    {
        "id": "2E9A",
        "text": "Diastasis of muscle, pelvic region or thigh"
    },
    {
        "id": "2E9B",
        "text": "Spontaneous rupture of muscle, pelvic region or thigh"
    },
    {
        "id": "2E9C",
        "text": "Spontaneous rupture of muscle, ankle or foot"
    },
    {
        "id": "2E9D",
        "text": "Ischaemic infarction of muscle, ankle or foot"
    },
    {
        "id": "2E9E",
        "text": "Ischaemic infarction of muscle, pelvic region or thigh"
    },
    {
        "id": "2E9F",
        "text": "Immobility syndrome, acromioclavicular joint"
    },
    {
        "id": "2E9G",
        "text": "Immobility syndrome, glenohumeral joint"
    },
    {
        "id": "2E9H",
        "text": "Immobility syndrome, sternoclavicular joint"
    },
    {
        "id": "2E9I",
        "text": "Contracture of muscle, pelvic region or thigh"
    },
    {
        "id": "2E9J",
        "text": "Contracture of muscle, ankle or foot"
    },
    {
        "id": "2E9K",
        "text": "Muscle wasting or atrophy, not elsewhere classified, ankle or foot"
    },
    {
        "id": "2E9L",
        "text": "Muscle wasting or atrophy, not elsewhere classified, pelvic region or thigh"
    },
    {
        "id": "2E9M",
        "text": "Muscle strain or sprain, pelvic region or thigh"
    },
    {
        "id": "2E9N",
        "text": "Muscle strain or sprain, ankle or foot"
    },
    {
        "id": "2E9O",
        "text": "Interstitial myositis, ankle or foot"
    },
    {
        "id": "2E9P",
        "text": "Interstitial myositis, pelvic region or thigh"
    },
    {
        "id": "2E9Q",
        "text": "Disorders of muscle, pelvic region or thigh"
    },
    {
        "id": "2E9R",
        "text": "Disorders of muscle, ankle or foot"
    },
    {
        "id": "2E9S",
        "text": "Calcific tendinitis, ankle or foot"
    },
    {
        "id": "2E9T",
        "text": "Calcific tendinitis, pelvic region or thigh"
    },
    {
        "id": "2E9U",
        "text": "Spontaneous rupture of flexor tendons, pelvic region or thigh"
    },
    {
        "id": "2E9V",
        "text": "Spontaneous rupture of flexor tendons, ankle or foot"
    },
    {
        "id": "2E9W",
        "text": "Spontaneous rupture of extensor tendon, ankle or foot"
    },
    {
        "id": "2E9X",
        "text": "Spontaneous rupture of extensor tendon, pelvic region or thigh"
    },
    {
        "id": "2E9Y",
        "text": "Calcium deposit in bursa, pelvic region or thigh"
    },
    {
        "id": "2E9Z",
        "text": "Calcium deposit in bursa, ankle or foot"
    },
    {
        "id": "2F00",
        "text": "Pseudosarcomatous fibromatosis, ankle or foot"
    },
    {
        "id": "2F01",
        "text": "Pseudosarcomatous fibromatosis, pelvic region or thigh"
    },
    {
        "id": "2F02",
        "text": "Foreign body granuloma of soft tissue, not elsewhere classified, pelvic region or thigh"
    },
    {
        "id": "2F03",
        "text": "Foreign body granuloma of soft tissue, not elsewhere classified, ankle or foot"
    },
    {
        "id": "2F04",
        "text": "Residual foreign body in soft tissue, ankle or foot"
    },
    {
        "id": "2F05",
        "text": "Residual foreign body in soft tissue, pelvic region or thigh"
    },
    {
        "id": "2F06",
        "text": "Myalgia, pelvic region or thigh"
    },
    {
        "id": "2F07",
        "text": "Myalgia, ankle or foot"
    },
    {
        "id": "2F08",
        "text": "Pain in limb, ankle or foot"
    },
    {
        "id": "2F09",
        "text": "Pain in limb, pelvic region or thigh"
    },
    {
        "id": "2F0A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2F0B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2F0D",
        "text": "Other specified soft tissue disorders, acromioclavicular joint"
    },
    {
        "id": "2F0E",
        "text": "Other specified soft tissue disorders, glenohumeral joint"
    },
    {
        "id": "2F0F",
        "text": "Other specified soft tissue disorders, sternoclavicular joint"
    },
    {
        "id": "2F0C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2F0G",
        "text": "COVID-19 vaccines, DNA based"
    },
    {
        "id": "2F0H",
        "text": "Juvenile osteochondritis of hip or pelvis"
    },
    {
        "id": "2F0I",
        "text": "Certain specified osteochondropathies"
    },
    {
        "id": "2F0J",
        "text": "Viral infection of joint, pelvic region or thigh"
    },
    {
        "id": "2F0K",
        "text": "Bacterial infection of joint, pelvic region or thigh"
    },
    {
        "id": "2F0L",
        "text": "Viral infection of joint, ankle or foot"
    },
    {
        "id": "2F0M",
        "text": "Fungal infection of joint, pelvic region or thigh"
    },
    {
        "id": "2F0N",
        "text": "Arthropathy following vaccination, pelvic region or thigh"
    },
    {
        "id": "2F0O",
        "text": "Arthropathy following vaccination, ankle or foot"
    },
    {
        "id": "2F0P",
        "text": "Arthropathy following genitourinary infection, pelvic region or thigh"
    },
    {
        "id": "2F0Q",
        "text": "Arthropathy following genitourinary infection, ankle or foot"
    },
    {
        "id": "2F0R",
        "text": "Arthropathy following gastrointestinal infection, pelvic region or thigh"
    },
    {
        "id": "2F0S",
        "text": "Arthropathy following gastrointestinal infection, ankle or foot"
    },
    {
        "id": "2F0T",
        "text": "Psoriatic arthritis, ankle or foot"
    },
    {
        "id": "2F0U",
        "text": "Juvenile psoriatic arthritis, pelvic region or thigh"
    },
    {
        "id": "2F0V",
        "text": "Juvenile psoriatic arthritis, ankle or foot"
    },
    {
        "id": "2F0W",
        "text": "Juvenile systemic arthritis, pelvic region or thigh"
    },
    {
        "id": "2F0X",
        "text": "Juvenile systemic arthritis, ankle or foot"
    },
    {
        "id": "2F0Y",
        "text": "Primary gout, pelvic region or thigh"
    },
    {
        "id": "2F0Z",
        "text": "Primary gout, ankle or foot"
    },
    {
        "id": "2F10",
        "text": "Chondrocalcinosis, pelvic region or thigh"
    },
    {
        "id": "2F11",
        "text": "Chondrocalcinosis, ankle or foot"
    },
    {
        "id": "2F13",
        "text": "Pigmented villonodular synovitis, pelvic region or thigh"
    },
    {
        "id": "2F14",
        "text": "Pigmented villonodular synovitis, ankle or foot"
    },
    {
        "id": "2F15",
        "text": "Recurrent instability of joint, acromioclavicular joint"
    },
    {
        "id": "2F16",
        "text": "Recurrent instability of joint, glenohumeral joint"
    },
    {
        "id": "2F17",
        "text": "Recurrent instability of joint, sternoclavicular joint"
    },
    {
        "id": "2F18",
        "text": "Recurrent instability of joint, pelvic region or thigh"
    },
    {
        "id": "2F19",
        "text": "Recurrent instability of joint, ankle or foot"
    },
    {
        "id": "2F1A",
        "text": "Effusion of joint containing blood, acromioclavicular joint"
    },
    {
        "id": "2F1B",
        "text": "Effusion of joint containing blood, glenohumeral joint"
    },
    {
        "id": "2F1C",
        "text": "Effusion of joint containing blood, sternoclavicular joint"
    },
    {
        "id": "2F1D",
        "text": "Other instability of joint"
    },
    {
        "id": "2F12",
        "text": "Dicalcium phosphate crystals arthritis"
    },
    {
        "id": "2F1E",
        "text": "Flatback syndrome, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1F",
        "text": "Postural kyphosis, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1G",
        "text": "Kinematic imbalance due to suboccipital strain"
    },
    {
        "id": "2F1H",
        "text": "Ankylosing hyperostosis, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1I",
        "text": "Kissing spine, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1J",
        "text": "Traumatic spondylopathy, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1K",
        "text": "Collapsed vertebra, not elsewhere classified, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1L",
        "text": "Spondylolysis, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1M",
        "text": "Spondylolisthesis, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1N",
        "text": "Spinal enthesitis, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1O",
        "text": "Ankylosing spondylitis, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1P",
        "text": "Certain specified inflammatory spondylopathies, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1Q",
        "text": "Secondary spondylopathy, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1R",
        "text": "Secondary collapsed vertebra, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1S",
        "text": "Spinal instabilities, sacral or sacrococcygeal region"
    },
    {
        "id": "2F1U",
        "text": "Paralytic calcification or ossification of muscle, shoulder region"
    },
    {
        "id": "2F1V",
        "text": "Paralytic calcification or ossification of muscle, glenohumeral joint"
    },
    {
        "id": "2F1W",
        "text": "Paralytic calcification or ossification of muscle, sternoclavicular joint"
    },
    {
        "id": "2F1X",
        "text": "Paralytic calcification or ossification of muscle, upper arm"
    },
    {
        "id": "2F1Y",
        "text": "Paralytic calcification or ossification of muscle, elbow joint"
    },
    {
        "id": "2F1Z",
        "text": "Paralytic calcification or ossification of muscle, forearm"
    },
    {
        "id": "2F3A",
        "text": "Fibrodysplasia ossificans progressiva, pelvic region or thigh"
    },
    {
        "id": "2F3B",
        "text": "Fibrodysplasia ossificans progressiva, ankle or foot"
    },
    {
        "id": "2F3C",
        "text": "Spontaneous rupture of muscle, pelvic region or thigh"
    },
    {
        "id": "2F3D",
        "text": "Spontaneous rupture of muscle, ankle or foot"
    },
    {
        "id": "2F3E",
        "text": "Immobility syndrome, acromioclavicular joint"
    },
    {
        "id": "2F3F",
        "text": "Immobility syndrome, glenohumeral joint"
    },
    {
        "id": "2F3G",
        "text": "Immobility syndrome, sternoclavicular joint"
    },
    {
        "id": "2F4A",
        "text": "Disorders of muscle, pelvic region or thigh"
    },
    {
        "id": "2F4B",
        "text": "Disorders of muscle, ankle or foot"
    },
    {
        "id": "2F4E",
        "text": "Spontaneous rupture of synovium, pelvic region or thigh"
    },
    {
        "id": "2F4F",
        "text": "Spontaneous rupture of synovium, ankle or foot"
    },
    {
        "id": "2F4G",
        "text": "Bursitis related to use, overuse or pressure"
    },
    {
        "id": "2F4C",
        "text": "Tenosynovitis"
    },
    {
        "id": "2F4H",
        "text": "Bursitis with unknown aetiology, pelvic region or thigh"
    },
    {
        "id": "2F4I",
        "text": "Bursitis with unknown aetiology, ankle or foot"
    },
    {
        "id": "2F4D",
        "text": "Traumatic amputation of shoulder or upper arm"
    },
    {
        "id": "2F4J",
        "text": "Postmenopausal osteoporosis, acromioclavicular joint"
    },
    {
        "id": "2F4K",
        "text": "Postmenopausal osteoporosis, glenohumeral joint"
    },
    {
        "id": "2F4L",
        "text": "Postmenopausal osteoporosis, sternoclavicular joint"
    },
    {
        "id": "2F4M",
        "text": "Osteoporosis due to malabsorption, pelvic region or thigh"
    },
    {
        "id": "2F4N",
        "text": "Osteoporosis due to malabsorption, ankle or foot"
    },
    {
        "id": "2F4O",
        "text": "Aluminium bone disease, pelvic region or thigh"
    },
    {
        "id": "2F4P",
        "text": "Aluminium bone disease, ankle or foot"
    },
    {
        "id": "2F49",
        "text": "Muscle wasting or atrophy, not elsewhere classified, vertebral column"
    },
    {
        "id": "2F48",
        "text": "Muscle wasting or atrophy, not elsewhere classified, neck"
    },
    {
        "id": "2F47",
        "text": "Muscle wasting or atrophy, not elsewhere classified, head"
    },
    {
        "id": "2F46",
        "text": "Muscle wasting or atrophy, not elsewhere classified, other joints in foot"
    },
    {
        "id": "2F45",
        "text": "Muscle wasting or atrophy, not elsewhere classified, ankle joint"
    },
    {
        "id": "2F44",
        "text": "Muscle wasting or atrophy, not elsewhere classified, toes"
    },
    {
        "id": "2F43",
        "text": "Muscle wasting or atrophy, not elsewhere classified, tarsus"
    },
    {
        "id": "2F42",
        "text": "Muscle wasting or atrophy, not elsewhere classified, metatarsus"
    },
    {
        "id": "2F41",
        "text": "Muscle wasting or atrophy, not elsewhere classified, ankle or foot"
    },
    {
        "id": "2F40",
        "text": "Muscle wasting or atrophy, not elsewhere classified, knee joint"
    },
    {
        "id": "2F3Z",
        "text": "Muscle wasting or atrophy, not elsewhere classified, lower leg"
    },
    {
        "id": "2F3Y",
        "text": "Muscle wasting or atrophy, not elsewhere classified, hip joint"
    },
    {
        "id": "2F3X",
        "text": "Muscle wasting or atrophy, not elsewhere classified, buttock"
    },
    {
        "id": "2F3W",
        "text": "Muscle wasting or atrophy, not elsewhere classified, pelvic region or thigh"
    },
    {
        "id": "2F3V",
        "text": "Muscle wasting or atrophy, not elsewhere classified, metacarpus"
    },
    {
        "id": "2F3U",
        "text": "Muscle wasting or atrophy, not elsewhere classified, fingers"
    },
    {
        "id": "2F3T",
        "text": "Muscle wasting or atrophy, not elsewhere classified, carpus"
    },
    {
        "id": "2F3S",
        "text": "Muscle wasting or atrophy, not elsewhere classified, hand"
    },
    {
        "id": "2F3R",
        "text": "Muscle wasting or atrophy, not elsewhere classified, wrist joint"
    },
    {
        "id": "2F3Q",
        "text": "Muscle wasting or atrophy, not elsewhere classified, forearm"
    },
    {
        "id": "2F3P",
        "text": "Muscle wasting or atrophy, not elsewhere classified, elbow joint"
    },
    {
        "id": "2F3O",
        "text": "Muscle wasting or atrophy, not elsewhere classified, humerus"
    },
    {
        "id": "2F3N",
        "text": "Muscle wasting or atrophy, not elsewhere classified, upper arm"
    },
    {
        "id": "2F3M",
        "text": "Muscle wasting or atrophy, not elsewhere classified, sternoclavicular joint"
    },
    {
        "id": "2F3L",
        "text": "Muscle wasting or atrophy, not elsewhere classified, glenohumeral joint"
    },
    {
        "id": "2F3K",
        "text": "Muscle wasting or atrophy, not elsewhere classified, acromioclavicular joint"
    },
    {
        "id": "2F3J",
        "text": "Muscle wasting or atrophy, not elsewhere classified, shoulder region"
    },
    {
        "id": "2F3I",
        "text": "Muscle wasting or atrophy, not elsewhere classified, multiple sites"
    },
    {
        "id": "2F3H",
        "text": "Muscle wasting or atrophy, not elsewhere classified"
    },
    {
        "id": "2F39",
        "text": "Calcification or ossification of muscles associated with burns, vertebral column"
    },
    {
        "id": "2F38",
        "text": "Calcification or ossification of muscles associated with burns, neck"
    },
    {
        "id": "2F37",
        "text": "Calcification or ossification of muscles associated with burns, head"
    },
    {
        "id": "2F36",
        "text": "Calcification or ossification of muscles associated with burns, other joints in foot"
    },
    {
        "id": "2F35",
        "text": "Calcification or ossification of muscles associated with burns, ankle joint"
    },
    {
        "id": "2F34",
        "text": "Calcification or ossification of muscles associated with burns, toes"
    },
    {
        "id": "2F33",
        "text": "Calcification or ossification of muscles associated with burns, tarsus"
    },
    {
        "id": "2F32",
        "text": "Calcification or ossification of muscles associated with burns, metatarsus"
    },
    {
        "id": "2F31",
        "text": "Calcification or ossification of muscles associated with burns, ankle or foot"
    },
    {
        "id": "2F30",
        "text": "Calcification or ossification of muscles associated with burns, knee joint"
    },
    {
        "id": "2F2Z",
        "text": "Calcification or ossification of muscles associated with burns, lower leg"
    },
    {
        "id": "2F2Y",
        "text": "Calcification or ossification of muscles associated with burns, hip joint"
    },
    {
        "id": "2F2X",
        "text": "Calcification or ossification of muscles associated with burns, buttock"
    },
    {
        "id": "2F2W",
        "text": "Calcification or ossification of muscles associated with burns, pelvic region or thigh"
    },
    {
        "id": "2F2V",
        "text": "Calcification or ossification of muscles associated with burns, metacarpus"
    },
    {
        "id": "2F2U",
        "text": "Calcification or ossification of muscles associated with burns, fingers"
    },
    {
        "id": "2F2T",
        "text": "Calcification or ossification of muscles associated with burns, carpus"
    },
    {
        "id": "2F2S",
        "text": "Calcification or ossification of muscles associated with burns, hand"
    },
    {
        "id": "2F2R",
        "text": "Calcification or ossification of muscles associated with burns, wrist joint"
    },
    {
        "id": "2F2Q",
        "text": "Calcification or ossification of muscles associated with burns, forearm"
    },
    {
        "id": "2F2P",
        "text": "Calcification or ossification of muscles associated with burns, elbow joint"
    },
    {
        "id": "2F2O",
        "text": "Calcification or ossification of muscles associated with burns, upper arm"
    },
    {
        "id": "2F2N",
        "text": "Calcification or ossification of muscles associated with burns, sternoclavicular joint"
    },
    {
        "id": "2F2M",
        "text": "Calcification or ossification of muscles associated with burns, glenohumeral joint"
    },
    {
        "id": "2F2L",
        "text": "Calcification or ossification of muscles associated with burns, acromioclavicular joint"
    },
    {
        "id": "2F2K",
        "text": "Calcification or ossification of muscles associated with burns, shoulder region"
    },
    {
        "id": "2F2J",
        "text": "Calcification or ossification of muscles associated with burns, multiple sites"
    },
    {
        "id": "2F2I",
        "text": "Paralytic calcification or ossification of muscle, vertebral column"
    },
    {
        "id": "2F2H",
        "text": "Paralytic calcification or ossification of muscle, neck"
    },
    {
        "id": "2F2G",
        "text": "Paralytic calcification or ossification of muscle, head"
    },
    {
        "id": "2F2F",
        "text": "Paralytic calcification or ossification of muscle, other joints in foot"
    },
    {
        "id": "2F2E",
        "text": "Paralytic calcification or ossification of muscle, ankle joint"
    },
    {
        "id": "2F2D",
        "text": "Paralytic calcification or ossification of muscle, toes"
    },
    {
        "id": "2F2C",
        "text": "Paralytic calcification or ossification of muscle, tarsus"
    },
    {
        "id": "2F2B",
        "text": "Paralytic calcification or ossification of muscle, metatarsus"
    },
    {
        "id": "2F2A",
        "text": "Paralytic calcification or ossification of muscle, ankle or foot"
    },
    {
        "id": "2F29",
        "text": "Paralytic calcification or ossification of muscle, knee joint"
    },
    {
        "id": "2F20",
        "text": "Paralytic calcification or ossification of muscle, wrist joint"
    },
    {
        "id": "2F28",
        "text": "Paralytic calcification or ossification of muscle, lower leg"
    },
    {
        "id": "2F27",
        "text": "Paralytic calcification or ossification of muscle, hip joint"
    },
    {
        "id": "2F26",
        "text": "Paralytic calcification or ossification of muscle, buttock"
    },
    {
        "id": "2F25",
        "text": "Paralytic calcification or ossification of muscle, pelvic region or thigh"
    },
    {
        "id": "2F24",
        "text": "Paralytic calcification or ossification of muscle, metacarpus"
    },
    {
        "id": "2F23",
        "text": "Paralytic calcification or ossification of muscle, fingers"
    },
    {
        "id": "2F22",
        "text": "Paralytic calcification or ossification of muscle, carpus"
    },
    {
        "id": "2F21",
        "text": "Paralytic calcification or ossification of muscle, hand"
    },
    {
        "id": "2F4Q",
        "text": "Adult osteomalacia due to malnutrition, pelvic region or thigh"
    },
    {
        "id": "2F4R",
        "text": "Adult osteomalacia due to malnutrition, ankle or foot"
    },
    {
        "id": "2F4S",
        "text": "Osteomalacia due to malabsorption, pelvic region or thigh"
    },
    {
        "id": "2F4T",
        "text": "Osteomalacia due to malabsorption, ankle or foot"
    },
    {
        "id": "2F4U",
        "text": "Drug-induced adult osteomalacia, pelvic region or thigh"
    },
    {
        "id": "2F4V",
        "text": "Drug-induced adult osteomalacia, ankle or foot"
    },
    {
        "id": "2F4W",
        "text": "Adult osteomalacia, ankle or foot"
    },
    {
        "id": "2F4X",
        "text": "Adult osteomalacia, pelvic region or thigh"
    },
    {
        "id": "2F4Y",
        "text": "Monostotic fibrous dysplasia, pelvic region or thigh"
    },
    {
        "id": "2F4Z",
        "text": "Monostotic fibrous dysplasia, ankle or foot"
    },
    {
        "id": "2F50",
        "text": "Skeletal fluorosis, pelvic region or thigh"
    },
    {
        "id": "2F51",
        "text": "Skeletal fluorosis, ankle or foot"
    },
    {
        "id": "2F52",
        "text": "Osteitis condensans, pelvic region or thigh"
    },
    {
        "id": "2F53",
        "text": "Osteitis condensans, ankle or foot"
    },
    {
        "id": "2F54",
        "text": "Malunion of fracture, pelvic region or thigh"
    },
    {
        "id": "2F55",
        "text": "Malunion of fracture, ankle or foot"
    },
    {
        "id": "2F56",
        "text": "Nonunion of fracture, pelvic region or thigh"
    },
    {
        "id": "2F57",
        "text": "Nonunion of fracture, ankle or foot"
    },
    {
        "id": "2F58",
        "text": "Delayed union of fracture, pelvic region or thigh"
    },
    {
        "id": "2F59",
        "text": "Delayed union of fracture, ankle or foot"
    },
    {
        "id": "2F5A",
        "text": "Stress fracture, not elsewhere classified, pelvic region or thigh"
    },
    {
        "id": "2F5B",
        "text": "Stress fracture, not elsewhere classified, ankle or foot"
    },
    {
        "id": "2F5C",
        "text": "Pathological fracture, pelvic region or thigh"
    },
    {
        "id": "2F5D",
        "text": "Pathological fracture, ankle or foot"
    },
    {
        "id": "2F5E",
        "text": "Disorder of continuity of bone, pelvic region or thigh"
    },
    {
        "id": "2F5F",
        "text": "Disorder of continuity of bone, ankle or foot"
    },
    {
        "id": "2F5G",
        "text": "Solitary bone cyst, pelvic region and thigh"
    },
    {
        "id": "2F5H",
        "text": "Solitary bone cyst, ankle or foot"
    },
    {
        "id": "2F5I",
        "text": "Aneurysmal bone cyst, ankle or foot"
    },
    {
        "id": "2F5J",
        "text": "Aneurysmal bone cyst, pelvic region or thigh"
    },
    {
        "id": "2F5K",
        "text": "Osteopathy in other diseases classified elsewhere, sternoclavicular joint"
    },
    {
        "id": "2F5L",
        "text": "Chondromalacia, acromioclavicular joint"
    },
    {
        "id": "2F5M",
        "text": "Juvenile osteochondrosis of spine, sacral or sacrococcygeal region"
    },
    {
        "id": "2F5N",
        "text": "Adult osteochondrosis of spine, sacral or sacrococcygeal region"
    },
    {
        "id": "2F5O",
        "text": "Juvenile osteochondritis of hip or pelvis"
    },
    {
        "id": "2F5P",
        "text": "Secondary periostitis, ankle or foot"
    },
    {
        "id": "2F5Q",
        "text": "Family history of diabetes mellitus"
    },
    {
        "id": "2F5R",
        "text": "Disorders of intellectual development"
    },
    {
        "id": "2F5S",
        "text": "Congenital ptosis, unilateral"
    },
    {
        "id": "2F5T",
        "text": "Structural developmental anomalies of ear ossicles"
    },
    {
        "id": "2F5U",
        "text": "Fusion of teeth"
    },
    {
        "id": "2F5V",
        "text": "Ankyloglossia"
    },
    {
        "id": "2F5W",
        "text": "Valgus deformity, not elsewhere classified, forearm"
    },
    {
        "id": "2F5X",
        "text": "Valgus deformity, not elsewhere classified, elbow joint"
    },
    {
        "id": "2F5Y",
        "text": "Acute haematogenous osteomyelitis, pelvic region or thigh"
    },
    {
        "id": "2F5Z",
        "text": "Acute haematogenous osteomyelitis, ankle or foot"
    },
    {
        "id": "2F60",
        "text": "Acute haematogenous osteomyelitis, ankle or foot"
    },
    {
        "id": "2F61",
        "text": "Other acute osteomyelitis, ankle or foot"
    },
    {
        "id": "2F62",
        "text": "Other acute osteomyelitis, pelvic region or thigh"
    },
    {
        "id": "2F63",
        "text": "Subacute osteomyelitis, ankle or foot"
    },
    {
        "id": "2F64",
        "text": "Subacute osteomyelitis, pelvic region or thigh"
    },
    {
        "id": "2F65",
        "text": "Chronic multifocal osteomyelitis, pelvic region or thigh"
    },
    {
        "id": "2F66",
        "text": "Chronic multifocal osteomyelitis, ankle or foot"
    },
    {
        "id": "2F67",
        "text": "Chronic osteomyelitis with draining sinus, ankle or foot"
    },
    {
        "id": "2F68",
        "text": "Chronic osteomyelitis with draining sinus, pelvic region or thigh"
    },
    {
        "id": "2F69",
        "text": "Other chronic haematogenous osteomyelitis, pelvic region or thigh"
    },
    {
        "id": "2F6A",
        "text": "Other chronic haematogenous osteomyelitis, ankle or foot"
    },
    {
        "id": "2F6B",
        "text": "Other chronic osteomyelitis, pelvic region or thigh"
    },
    {
        "id": "2F6C",
        "text": "Other chronic osteomyelitis, ankle or foot"
    },
    {
        "id": "2F6D",
        "text": "Hypertrophy of bone, metacarpus"
    },
    {
        "id": "2F6E",
        "text": "Bursitis of toe"
    },
    {
        "id": "2F6F",
        "text": "Calcification or ossification of muscle, metatarsus"
    },
    {
        "id": "2F6G",
        "text": "Benign neoplasm of choroid"
    },
    {
        "id": "2F6H",
        "text": "Paramedian facial cleft"
    },
    {
        "id": "2F6I",
        "text": "Structural developmental anomalies of the nose or cavum"
    },
    {
        "id": "2F6K",
        "text": "General adult medical examination"
    },
    {
        "id": "2F6L",
        "text": "Disorders of peroxisome biogenesis"
    },
    {
        "id": "2F6M",
        "text": "Zellweger syndrome"
    },
    {
        "id": "2F6N",
        "text": "Apparent mineralocorticoid excess"
    },
    {
        "id": "2F6O",
        "text": "Sepsis with septic shock"
    },
    {
        "id": "2F6P",
        "text": "Adrenal gland"
    },
    {
        "id": "2F6Q",
        "text": "Neurocognitive disorders"
    },
    {
        "id": "2F6R",
        "text": "Delirium"
    },
    {
        "id": "2F6U",
        "text": "Amnestic disorder"
    },
    {
        "id": "2F6S",
        "text": "Mild neurocognitive disorder"
    },
    {
        "id": "2F6W",
        "text": "Dementia"
    },
    {
        "id": "2F6X",
        "text": "SeSAME syndrome"
    },
    {
        "id": "2F6Y",
        "text": "Chronic instability of knee, medial collateral ligament or other or unspecified part of medial meniscus"
    },
    {
        "id": "2F6Z",
        "text": "Sepsis"
    },
    {
        "id": "2F70",
        "text": "Sepsis without septic shock"
    },
    {
        "id": "2F71",
        "text": "Inlet perimembranous ventricular septal defect with atrioventricular septal malalignment and without a common atrioventricular junction"
    },
    {
        "id": "2F73",
        "text": "Supplementary Chapter Traditional Medicine Conditions - Module I"
    },
    {
        "id": "2F74",
        "text": "Supplementary Chapter Traditional Medicine Conditions - Module I"
    },
    {
        "id": "2F75",
        "text": "Dementia due to Lewy body disease"
    },
    {
        "id": "2F76",
        "text": "Preterm labour with term delivery"
    },
    {
        "id": "2F77",
        "text": "Cystic meniscus, other or unspecified part of medial meniscus"
    },
    {
        "id": "2F78",
        "text": "Loose body in knee, medial collateral ligament or other or unspecified part of medial meniscus"
    },
    {
        "id": "2F79",
        "text": "Loose body in knee, other or unspecified part of lateral meniscus"
    },
    {
        "id": "2F7A",
        "text": "Prenatally acquired infections with neonatal skin manifestations"
    },
    {
        "id": "2F7B",
        "text": "Navigating"
    },
    {
        "id": "2F7D",
        "text": "Chronic instability of knee, medial collateral ligament or other or unspecified part of medial meniscus"
    },
    {
        "id": "2F7E",
        "text": "Hepatic encephalopathy, minimal"
    },
    {
        "id": "2F7F",
        "text": "Transient synovitis, hip joint"
    },
    {
        "id": "2F7H",
        "text": "Chronic cough"
    },
    {
        "id": "2F7I",
        "text": "Chronic cough"
    },
    {
        "id": "2F7J",
        "text": "Multisystem inflammatory syndrome associated with COVID-19"
    },
    {
        "id": "2F7K",
        "text": "Other or unspecified body system or function targets"
    },
    {
        "id": "2F7L",
        "text": "Secondary neurocognitive syndrome"
    },
    {
        "id": "2F7M",
        "text": "Secondary neurocognitive syndrome"
    },
    {
        "id": "2F7N",
        "text": "Disturbances of potassium balance of newborn"
    },
    {
        "id": "2F7O",
        "text": "Harlequin ichthyosis"
    },
    {
        "id": "2F7P",
        "text": "Doubly committed juxta-arterial ventricular septal defect with posteriorly malaligned fibrous outlet septum and muscular postero-inferior rim"
    },
    {
        "id": "2F7Q",
        "text": "Outlet perimembranous ventricular septal defect with posteriorly malaligned outlet septum"
    },
    {
        "id": "2F7R",
        "text": "Chronic instability of knee, other or unspecified lateral meniscus"
    },
    {
        "id": "2F7S",
        "text": "Descending thoracic or abdominal aortic coarctation"
    },
    {
        "id": "2F7T",
        "text": "Structural developmental anomalies of the diaphragm, abdominal wall or umbilical cord"
    },
    {
        "id": "2F7V",
        "text": "Streptococcal pharyngitis"
    },
    {
        "id": "2F7W",
        "text": "Malfunction or complication of external stoma of digestive organs"
    },
    {
        "id": "2F7X",
        "text": "Oesophageal fistula without atresia"
    },
    {
        "id": "2F7U",
        "text": "Tapered teeth"
    },
    {
        "id": "2F7Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2F80",
        "text": "Borderline pattern"
    },
    {
        "id": "2F81",
        "text": "Megacolon due to Clostridioides difficile"
    },
    {
        "id": "2F82",
        "text": "Glycine N-methyltransferase deficiency"
    },
    {
        "id": "2F83",
        "text": "Long chain acyl-CoA dehydrogenase deficiency"
    },
    {
        "id": "2F84",
        "text": "Component of conserved oligomeric Golgi complex 1 deficiency"
    },
    {
        "id": "2F85",
        "text": "Intestinal infections due to Clostridioides difficile"
    },
    {
        "id": "2F86",
        "text": "Exposure to extreme forces of nature"
    },
    {
        "id": "2F87",
        "text": "Structural developmental anomalies of spine or bony thorax"
    },
    {
        "id": "2F88",
        "text": "Congenital absence of upper arm or forearm with hand present"
    },
    {
        "id": "2F89",
        "text": "Humero-ulnar synostosis"
    },
    {
        "id": "2F8A",
        "text": "Porokeratotic eccrine ostial and dermal duct naevus"
    },
    {
        "id": "2F8C",
        "text": "Repair of coarctation of aortic arch"
    },
    {
        "id": "2F8F",
        "text": "Additional sensory functions"
    },
    {
        "id": "2F8G",
        "text": "General tasks and demands"
    },
    {
        "id": "2F8H",
        "text": "Congenital subglottic stenosis, grade 1"
    },
    {
        "id": "2F8I",
        "text": "Tracheal bronchus"
    },
    {
        "id": "2F8K",
        "text": "Tuberculosis of the respiratory system"
    },
    {
        "id": "2F8M",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2F8N",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2F8Q",
        "text": "Meningitis due to Streptococcus agalactiae"
    },
    {
        "id": "2F8S",
        "text": "Diseases of the nervous system"
    },
    {
        "id": "2F8T",
        "text": "Allantoic duct remnants or cysts"
    },
    {
        "id": "2F8U",
        "text": "Measuring upper limb"
    },
    {
        "id": "2D8N",
        "text": "Persistent Postural-Perceptual Dizziness"
    },
    {
        "id": "2F8X",
        "text": "Gastroenteritis or colitis without specification of origin"
    },
    {
        "id": "2F8Y",
        "text": "Hepatic veno-occlusive disease - immunodeficiency syndrome"
    },
    {
        "id": "2F8Z",
        "text": "Noninflammatory inclusion body myopathy"
    },
    {
        "id": "2F92",
        "text": "Drug-induced oral ulcer"
    },
    {
        "id": "2F93",
        "text": "Certain specified disorders of gingiva or edentulous alveolar ridge"
    },
    {
        "id": "2F94",
        "text": "Polycystic liver disease"
    },
    {
        "id": "2F95",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2F96",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2F97",
        "text": "Cystoisosporiasis"
    },
    {
        "id": "2F98",
        "text": "Pulmonary infection due to non-tuberculous mycobacterium"
    },
    {
        "id": "2F99",
        "text": "Haemophilus influenzae spinal meningitis"
    },
    {
        "id": "2F9A",
        "text": "Malignant neoplasms of male genital organs"
    },
    {
        "id": "2F9B",
        "text": "Adnexal carcinoma of vulva"
    },
    {
        "id": "2F9C",
        "text": "Congenital diverticulitis of small intestine, without complication"
    },
    {
        "id": "2F9F",
        "text": "Unintentionally cut or pierced by sharp object"
    },
    {
        "id": "2F9H",
        "text": "Structural developmental anomalies of pancreas"
    },
    {
        "id": "2F9I",
        "text": "Nonpneumonic Legionnaires' disease"
    },
    {
        "id": "2F9J",
        "text": "Pyogenic bacterial infections of the skin or subcutaneous tissues"
    },
    {
        "id": "2F9L",
        "text": "Pneumonic plague"
    },
    {
        "id": "2F9M",
        "text": "Tularaemia"
    },
    {
        "id": "2F9K",
        "text": "Glanders"
    },
    {
        "id": "2F9O",
        "text": "Carcinoma in situ of anal canal"
    },
    {
        "id": "2F9G",
        "text": "Neoplastic haemangioma"
    },
    {
        "id": "2F9P",
        "text": "Malignant mesenchymal neoplasms"
    },
    {
        "id": "2F9Q",
        "text": "Lissencephaly with cerebellar hypoplasia type D"
    },
    {
        "id": "2F9R",
        "text": "ICD Entity"
    },
    {
        "id": "2F9S",
        "text": "ICD Entity"
    },
    {
        "id": "2F91",
        "text": "Sequelae of malnutrition or certain specified nutritional deficiencies"
    },
    {
        "id": "2F90",
        "text": "Hypofunction or certain other specified disorders of pituitary gland"
    },
    {
        "id": "2F9T",
        "text": "Hoffmann syndrome"
    },
    {
        "id": "2F9U",
        "text": "Spondylometaphyseal dysplasia, Kozlowski type"
    },
    {
        "id": "2F9V",
        "text": "Oral-facial-digital syndrome"
    },
    {
        "id": "2F9W",
        "text": "Oral-facial-digital syndrome"
    },
    {
        "id": "2F9X",
        "text": "Oral-facial-digital syndrome"
    },
    {
        "id": "2F9Y",
        "text": "Physical maltreatment"
    },
    {
        "id": "2F9Z",
        "text": "Exposure to or harmful effects of undetermined intent of ciguatera fish poisoning"
    },
    {
        "id": "2G00",
        "text": "Exposure to or harmful effects of undetermined intent of scombroid fish poisoning"
    },
    {
        "id": "2G01",
        "text": "Curly hair \u2013 ankyloblepharon \u2013 nail dysplasia syndrome"
    },
    {
        "id": "2G02",
        "text": "Certain specified diseases of liver"
    },
    {
        "id": "2G04",
        "text": "Drug-induced anaphylaxis"
    },
    {
        "id": "2G05",
        "text": "Drug-induced bronchospasm"
    },
    {
        "id": "2G07",
        "text": "Oesophagotracheal fistula"
    },
    {
        "id": "2G08",
        "text": "Prurigo pigmentosa"
    },
    {
        "id": "2G0B",
        "text": "Ulcerative pancolitis"
    },
    {
        "id": "2G0D",
        "text": "Extension Codes"
    },
    {
        "id": "2G0E",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2G0C",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2G0G",
        "text": "Ageing associated decline in intrinsic capacity"
    },
    {
        "id": "2G0J",
        "text": "Cholera"
    },
    {
        "id": "2G0K",
        "text": "Cholera due to Vibrio cholerae O1, biovar eltor"
    },
    {
        "id": "2G0H",
        "text": "Cholera due to Vibrio cholerae O1, biovar cholerae"
    },
    {
        "id": "2G0L",
        "text": "Short bowel syndrome"
    },
    {
        "id": "2D0N",
        "text": "Certain diseases of the respiratory system"
    },
    {
        "id": "2G0M",
        "text": "Branchial cleft cyst"
    },
    {
        "id": "2G0N",
        "text": "Malignant neoplasms of thymus"
    },
    {
        "id": "2G0O",
        "text": "Limb body wall complex"
    },
    {
        "id": "2G0R",
        "text": "Combined disorders of mitral, aortic or tricuspid valves"
    },
    {
        "id": "2G0S",
        "text": "Thymic epithelial tumour"
    },
    {
        "id": "2G0T",
        "text": "Tuberculous pleurisy, not confirmed"
    },
    {
        "id": "2G0V",
        "text": "Sequelae of tuberculosis"
    },
    {
        "id": "2G0W",
        "text": "Encephalitis due to Listeria monocytogenes"
    },
    {
        "id": "2G0X",
        "text": "Berardinelli-Seip congenital lipodystrophy"
    },
    {
        "id": "2G0Y",
        "text": "Dandy-Walker malformation without hydrocephalus"
    },
    {
        "id": "2G0Z",
        "text": "Fetus or newborn affected by umbilical cord loop"
    },
    {
        "id": "2G10",
        "text": "Aniridia"
    },
    {
        "id": "2G11",
        "text": "Structural developmental anomalies of uterus, except cervix"
    },
    {
        "id": "2G12",
        "text": "Injury, poisoning or certain other consequences of external causes"
    },
    {
        "id": "2G14",
        "text": "Obesity hypoventilation syndrome with BMI 35.00-39.99"
    },
    {
        "id": "2G15",
        "text": "TARP - [Talipes equinovarus - atrial septal defect - Robin sequence - persistent left superior vena cava] syndrome"
    },
    {
        "id": "2G16",
        "text": "TARP - [Talipes equinovarus - atrial septal defect - Robin sequence - persistent left superior vena cava] syndrome"
    },
    {
        "id": "2G18",
        "text": "Intestinal infections due to Shigella"
    },
    {
        "id": "2G17",
        "text": "Intestinal infections due to Salmonella"
    },
    {
        "id": "2G19",
        "text": "Meningitis due to Streptococcus"
    },
    {
        "id": "2G1A",
        "text": "Meningitis due to Staphylococcus"
    },
    {
        "id": "2G1D",
        "text": "Deafness - intellectual deficit syndrome, Martin-Probst type"
    },
    {
        "id": "2G1E",
        "text": "Deep volar branch of ulnar artery"
    },
    {
        "id": "2G1F",
        "text": "Volar carpal network"
    },
    {
        "id": "2G1G",
        "text": "Dorsal carpal network"
    },
    {
        "id": "2G1H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G1I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G1J",
        "text": "Lowe-Kohn-Cohen syndrome"
    },
    {
        "id": "2G1Z",
        "text": "Other difficulties with micturition"
    },
    {
        "id": "2G20",
        "text": "Coma"
    },
    {
        "id": "2G21",
        "text": "Acalculia"
    },
    {
        "id": "2G22",
        "text": "Agraphia"
    },
    {
        "id": "2G23",
        "text": "Dyscalculia"
    },
    {
        "id": "2G24",
        "text": "Echolalia"
    },
    {
        "id": "2G25",
        "text": "Symptoms and signs of thought disorder"
    },
    {
        "id": "2G26",
        "text": "Disorganised thinking"
    },
    {
        "id": "2G27",
        "text": "Disorganised thinking"
    },
    {
        "id": "2G28",
        "text": "Attention seeking"
    },
    {
        "id": "2G29",
        "text": "Symptoms and signs concerning food and fluid intake"
    },
    {
        "id": "2G1V",
        "text": "Infantile neuroaxonal dystrophy"
    },
    {
        "id": "2G2H",
        "text": "Blastic plasmacytoid dendritic cell neoplasm"
    },
    {
        "id": "2G2I",
        "text": "Myeloid/lymphoid neoplasm associated with PDGFRA rearrangement"
    },
    {
        "id": "2G2J",
        "text": "Myeloid neoplasm associated with PDGFRB rearrangement"
    },
    {
        "id": "2G2N",
        "text": "Progressive muscular atrophy"
    },
    {
        "id": "2G2Q",
        "text": "Infantile spinal muscular atrophy, Type I"
    },
    {
        "id": "2G2R",
        "text": "Late infantile spinal muscular atrophy, Type II"
    },
    {
        "id": "2G2B",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2G2C",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2G2S",
        "text": "Pulmonary prosthetic valve regurgitation"
    },
    {
        "id": "2G2T",
        "text": "Periprosthetic pulmonary valve regurgitation"
    },
    {
        "id": "2G2U",
        "text": "Acquired left ventricular outflow tract obstruction"
    },
    {
        "id": "2G2V",
        "text": "Cerebral ischaemic stroke due to unspecified occlusion or stenosis of extracranial large artery"
    },
    {
        "id": "2G2W",
        "text": "Acquired coronary sinus abnormality"
    },
    {
        "id": "2G2X",
        "text": "Paratyphoid fever A"
    },
    {
        "id": "2G2Y",
        "text": "Thrombotic cardiac embolism from ventricular aneurysm"
    },
    {
        "id": "2G2Z",
        "text": "Thrombotic embolism from aneurysm of popliteal artery"
    },
    {
        "id": "2G30",
        "text": "Cytoplasmic vacuolation consistent with Fabry disease"
    },
    {
        "id": "2G2F",
        "text": "Classic pantothenate kinase associated neurodegeneration"
    },
    {
        "id": "2G31",
        "text": "Lupus glomerulonephritis"
    },
    {
        "id": "2G2E",
        "text": "Atypical pantothenate kinase associated neurodegeneration"
    },
    {
        "id": "2G1Q",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2G32",
        "text": "Erythrokeratodermia variabilis et progressiva"
    },
    {
        "id": "2G33",
        "text": "Erythrokeratodermia progressiva symmetrica"
    },
    {
        "id": "2G34",
        "text": "Erythrokeratodermia variabilis"
    },
    {
        "id": "2G1U",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2G2A",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2G1S",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2G1X",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2G2D",
        "text": "Pantothenate-kinase-associated neurodegeneration"
    },
    {
        "id": "2G2G",
        "text": "Neurodegeneration with brain iron accumulation due to C19orf12 mutation"
    },
    {
        "id": "2G35",
        "text": "Streptococcal tonsillitis"
    },
    {
        "id": "2G37",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G38",
        "text": "Tapered teeth"
    },
    {
        "id": "2G39",
        "text": "Haematoma of vocal cord"
    },
    {
        "id": "2G3A",
        "text": "Vocal Cord Paralysis Severity Scale Value"
    },
    {
        "id": "2G3B",
        "text": "Incomplete vocal cord paralysis"
    },
    {
        "id": "2G3C",
        "text": "Partial vocal cord paralysis"
    },
    {
        "id": "2G3D",
        "text": "Complete vocal cord paralysis"
    },
    {
        "id": "2G2L",
        "text": "Late infantile spinal muscular atrophy, Type II"
    },
    {
        "id": "2G2O",
        "text": "Adult onset spinal muscular atrophy, Type IV"
    },
    {
        "id": "2G2K",
        "text": "Infantile spinal muscular atrophy, Type I"
    },
    {
        "id": "2G2P",
        "text": "Dilated cardiomyopathy due to spinal muscular atrophy"
    },
    {
        "id": "2G2M",
        "text": "Localised spinal muscular atrophy"
    },
    {
        "id": "2G06",
        "text": "Short bowel syndrome"
    },
    {
        "id": "2G3F",
        "text": "Dystonia in neurodegeneration with brain iron accumulation type 1"
    },
    {
        "id": "2G3G",
        "text": "Complete trisomy 18"
    },
    {
        "id": "2G3H",
        "text": "Complete trisomy 18, translocation"
    },
    {
        "id": "2G3J",
        "text": "Substances"
    },
    {
        "id": "2G3K",
        "text": "17p duplication"
    },
    {
        "id": "2G3L",
        "text": "Open fracture or closed fracture"
    },
    {
        "id": "2G3M",
        "text": "Colitis due to paratyphoid fever B"
    },
    {
        "id": "2G3N",
        "text": "Colitis due to paratyphoid fever C"
    },
    {
        "id": "2G3O",
        "text": "Alopecia or hair loss"
    },
    {
        "id": "2G3P",
        "text": "Paratyphoid fever A"
    },
    {
        "id": "2G3R",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G3S",
        "text": "Typhoid enteritis"
    },
    {
        "id": "2G3T",
        "text": "Paratyphoid fever C"
    },
    {
        "id": "2G3U",
        "text": "Effusion of joint"
    },
    {
        "id": "2G3V",
        "text": "Paratyphoid fever B"
    },
    {
        "id": "2G3W",
        "text": "Salmonella enteritis"
    },
    {
        "id": "2G3Q",
        "text": "Undernutrition"
    },
    {
        "id": "2G41",
        "text": "Thyrotoxicosis with diffuse goitre"
    },
    {
        "id": "2G42",
        "text": "Balanced rearrangements or structural rearrangements"
    },
    {
        "id": "2G43",
        "text": "Turner syndrome"
    },
    {
        "id": "2G44",
        "text": "Tetrasomy X"
    },
    {
        "id": "2G45",
        "text": "AL amyloidosis"
    },
    {
        "id": "2G46",
        "text": "Type of sport or exercise activity, basketball"
    },
    {
        "id": "2G47",
        "text": "Injury, poisoning or certain other consequences of external causes"
    },
    {
        "id": "2G4B",
        "text": "Giardiasis"
    },
    {
        "id": "2G4C",
        "text": "Agnosia"
    },
    {
        "id": "2G4D",
        "text": "Acute graft-versus-host disease"
    },
    {
        "id": "2G4H",
        "text": "KCONVAC"
    },
    {
        "id": "2G4G",
        "text": "Sinopharm WIBP-CorV"
    },
    {
        "id": "2G4F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G4I",
        "text": "BBIBP-CorV"
    },
    {
        "id": "2G4J",
        "text": "MVC-COV1901"
    },
    {
        "id": "2G4L",
        "text": "Salmonella enteritis"
    },
    {
        "id": "2G4M",
        "text": "Congenital stenosis of ileum"
    },
    {
        "id": "2G4N",
        "text": "Short bowel syndrome in neonate"
    },
    {
        "id": "2G4X",
        "text": "Northern Philippine cobra snake venom"
    },
    {
        "id": "2G4Y",
        "text": "Scorpionfish venom"
    },
    {
        "id": "2G4Z",
        "text": "Asp viper snake venom"
    },
    {
        "id": "2G52",
        "text": "Phenol, nonmedicinal"
    },
    {
        "id": "2G53",
        "text": "Assault by exposure to or harmful effects of sedative, hypnotic drugs or other CNS depressants"
    },
    {
        "id": "2G54",
        "text": "Intentional self-harm by exposure to other and unspecified drug, medicament and biological substance"
    },
    {
        "id": "2G55",
        "text": "Unintentional exposure to ignition or melting of material"
    },
    {
        "id": "2G57",
        "text": "Novavax COVID-19 vaccines"
    },
    {
        "id": "2G58",
        "text": "Enteritis due to Enterobacter"
    },
    {
        "id": "2G59",
        "text": "Colitis due to methicillin resistant Staphylococcus aureus"
    },
    {
        "id": "2G5A",
        "text": "Enteritis due to Pseudomonas aeruginosa"
    },
    {
        "id": "2G5B",
        "text": "Intracerebrum"
    },
    {
        "id": "2G5C",
        "text": "Infections due to non-tuberculous mycobacteria"
    },
    {
        "id": "2G5D",
        "text": "Meningitis due to Staphylococcus aureus"
    },
    {
        "id": "2G5E",
        "text": "Bacterial meningitis due to methicillin resistant Staphylococcus aureus"
    },
    {
        "id": "2G5G",
        "text": "Infections of the lacrimal gland"
    },
    {
        "id": "2G5J",
        "text": "Human T-cell lymphotropic virus"
    },
    {
        "id": "2G5F",
        "text": "Zidovudine, Lamivudine and Nevirapine"
    },
    {
        "id": "2G5K",
        "text": "Respiratory syncytial virus vaccines"
    },
    {
        "id": "2G5L",
        "text": "Tests for thyroid function"
    },
    {
        "id": "2G5M",
        "text": "In situ neoplasms, except of lymphoid, haematopoietic, central nervous system or related tissues"
    },
    {
        "id": "2G4A",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "2G49",
        "text": "Complex regional pain syndrome"
    },
    {
        "id": "2G5N",
        "text": "Sequelae of protein-energy malnutrition"
    },
    {
        "id": "2G13",
        "text": "Obesity hypoventilation syndrome with BMI 30.00-34.99"
    },
    {
        "id": "2C06",
        "text": "Intestinal infections due to Clostridioides difficile"
    },
    {
        "id": "2F7C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G5O",
        "text": "Malignant neoplasms of thyroid gland"
    },
    {
        "id": "2G5P",
        "text": "Cyst or mucocele of nose or nasal sinus"
    },
    {
        "id": "2G5Q",
        "text": "Aneurysm or dissection of artery of upper extremity"
    },
    {
        "id": "2G5R",
        "text": "Aneurysm or dissection of carotid artery"
    },
    {
        "id": "2G5S",
        "text": "Aneurysm or dissection of renal artery"
    },
    {
        "id": "2G5T",
        "text": "Aneurysm or dissection of iliac artery"
    },
    {
        "id": "2G5U",
        "text": "Aneurysm or dissection of artery of lower extremity"
    },
    {
        "id": "2G5V",
        "text": "Aneurysm or dissection of vertebral artery"
    },
    {
        "id": "2G5W",
        "text": "Cataract due to ocular disease"
    },
    {
        "id": "2G5Y",
        "text": "ACTH-producing tumour"
    },
    {
        "id": "2G62",
        "text": "Aortic arch syndrome"
    },
    {
        "id": "2G63",
        "text": "Intra-abdominal or pelvic swelling, mass or lump"
    },
    {
        "id": "2G64",
        "text": "Aortic arch syndrome"
    },
    {
        "id": "2G66",
        "text": "Oropharynx"
    },
    {
        "id": "2G67",
        "text": "Nasopharynx"
    },
    {
        "id": "2G69",
        "text": "Superficial incisional site infection"
    },
    {
        "id": "2G6A",
        "text": "St Louis encephalitis"
    },
    {
        "id": "2G6B",
        "text": "Influenza due to identified seasonal influenza virus"
    },
    {
        "id": "2G6C",
        "text": "Influenza due to identified zoonotic or pandemic influenza virus"
    },
    {
        "id": "2G6D",
        "text": "Influenza, virus not identified"
    },
    {
        "id": "2G6J",
        "text": "HIV disease clinical stage 1 associated with tuberculosis"
    },
    {
        "id": "2G6K",
        "text": "HIV disease clinical stage 2 associated with tuberculosis"
    },
    {
        "id": "2G6L",
        "text": "HIV disease clinical stage 3 associated with tuberculosis"
    },
    {
        "id": "2G6M",
        "text": "HIV disease clinical stage 4 associated with tuberculosis"
    },
    {
        "id": "2G6F",
        "text": "Kaposi sarcoma associated with human immunodeficiency virus disease associated with tuberculosis"
    },
    {
        "id": "2G56",
        "text": "Vibrio cholerae"
    },
    {
        "id": "2G6N",
        "text": "HIV disease resulting in Pneumocystis jirovecii pneumonia"
    },
    {
        "id": "2G6O",
        "text": "Thyrohyoid ligament"
    },
    {
        "id": "2G6P",
        "text": "Erythrocytes"
    },
    {
        "id": "2G6Q",
        "text": "Burn of shoulder or arm, except wrist or hand"
    },
    {
        "id": "2G6R",
        "text": "Sequelae of fracture of lower leg, including ankle"
    },
    {
        "id": "2G6S",
        "text": "Chronic recurrent multifocal osteomyelitis"
    },
    {
        "id": "2G6T",
        "text": "Calcific tendinitis of shoulder"
    },
    {
        "id": "2G6U",
        "text": "Talma's disease"
    },
    {
        "id": "2G6V",
        "text": "Stress fracture, not elsewhere classified"
    },
    {
        "id": "2G6W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G6X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G6Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G6Z",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G70",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G71",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G72",
        "text": "Progressive osseous heteroplasia"
    },
    {
        "id": "2G73",
        "text": "Acromegaly or pituitary gigantism"
    },
    {
        "id": "2G74",
        "text": "Iliac vein thrombosis"
    },
    {
        "id": "2G76",
        "text": "Intra-abdominal or pelvic swelling, mass or lump"
    },
    {
        "id": "2G77",
        "text": "Hypertrophic osteoarthropathy"
    },
    {
        "id": "2G78",
        "text": "Pearl-worker's disease"
    },
    {
        "id": "2G79",
        "text": "Inflammatory arthropathies"
    },
    {
        "id": "2G7B",
        "text": "Personal history of malignant neoplasm of trachea, bronchus or lung"
    },
    {
        "id": "2G7C",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "2G7D",
        "text": "Congenital methaemoglobinaemia"
    },
    {
        "id": "2G7E",
        "text": "Chronic granulomatous disease"
    },
    {
        "id": "2G7F",
        "text": "Childhood granulomatous disorder"
    },
    {
        "id": "2G7G",
        "text": "Liquefied petroleum gas"
    },
    {
        "id": "2G7H",
        "text": "Recurrent Neisseria infections due to factor D deficiency"
    },
    {
        "id": "2G7I",
        "text": "Immunodeficiency with decay accelerating factor deficiency"
    },
    {
        "id": "2G7J",
        "text": "Neoplastic haemangioma"
    },
    {
        "id": "2G7L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G7K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G7A",
        "text": "Adenocarcinoma of pancreas"
    },
    {
        "id": "2G7M",
        "text": "Adenocarcinoma of cervix uteri"
    },
    {
        "id": "2G7N",
        "text": "Pelvic peritoneal adhesions of unknown or combined origin"
    },
    {
        "id": "2G7Q",
        "text": "Malignant neoplasm metastasis in lymph nodes"
    },
    {
        "id": "2G7R",
        "text": "Right bundle branch block"
    },
    {
        "id": "2G7S",
        "text": "Left bundle branch block"
    },
    {
        "id": "2G7T",
        "text": "Deep ovarian endometriosis"
    },
    {
        "id": "2G7V",
        "text": "Breast lump or mass female"
    },
    {
        "id": "2G81",
        "text": "Maternal care for known or suspected abnormality of pelvic organs"
    },
    {
        "id": "2G82",
        "text": "Uterine scar"
    },
    {
        "id": "2G83",
        "text": "Benign neoplasm of endocrine glands"
    },
    {
        "id": "2G84",
        "text": "Corneal opacity"
    },
    {
        "id": "2G85",
        "text": "Chordoma"
    },
    {
        "id": "2G86",
        "text": "Aortic arch syndrome"
    },
    {
        "id": "2G87",
        "text": "Acute sinusitis"
    },
    {
        "id": "2G88",
        "text": "Chronic rhinosinusitis"
    },
    {
        "id": "2G89",
        "text": "Aluminium phosphide"
    },
    {
        "id": "2G8A",
        "text": "Purpura fulminans"
    },
    {
        "id": "2G8B",
        "text": "Haemorrhage due to unfractionated heparin"
    },
    {
        "id": "2G8C",
        "text": "Thrombotic thrombocytopenic purpura"
    },
    {
        "id": "2G8D",
        "text": "Semiplastic anemia"
    },
    {
        "id": "2G8F",
        "text": "Displacement of intraocular lens"
    },
    {
        "id": "2G8G",
        "text": "Inborn malformations of the vascular system of the liver"
    },
    {
        "id": "2G8K",
        "text": "Congenital absence of portal vein"
    },
    {
        "id": "2G8I",
        "text": "Anomalous portal venous connection"
    },
    {
        "id": "2G8H",
        "text": "Anomalous portal venous connection"
    },
    {
        "id": "2G8S",
        "text": "Primary congenital hypothyroidism due to TSH receptor mutations"
    },
    {
        "id": "2G8T",
        "text": "Familial gestational hyperthyroidism"
    },
    {
        "id": "2G8U",
        "text": "Iron-refractory iron deficiency anaemia"
    },
    {
        "id": "2G8V",
        "text": "Congenital folate deficiency anaemia"
    },
    {
        "id": "2G8W",
        "text": "Haemolytic uraemic syndrome"
    },
    {
        "id": "2G8X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G8Y",
        "text": "Gyromitra mushroom"
    },
    {
        "id": "2G8Z",
        "text": "Plasmodium"
    },
    {
        "id": "2G90",
        "text": "Plasmodium"
    },
    {
        "id": "2G91",
        "text": "Lepiota josserandii mushroom"
    },
    {
        "id": "2G92",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G94",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G95",
        "text": "Swiss type autosomal recessive agammaglobulinaemia"
    },
    {
        "id": "2G96",
        "text": "Digestive system obstruction"
    },
    {
        "id": "2G97",
        "text": "Digestive system obstruction"
    },
    {
        "id": "2G98",
        "text": "Congenital scoliosis"
    },
    {
        "id": "2G99",
        "text": "Malignant neoplasm metastasis in ovary"
    },
    {
        "id": "2G9A",
        "text": "Sequelae of intracranial injury"
    },
    {
        "id": "2G9B",
        "text": "Tuberculoid leprosy"
    },
    {
        "id": "2G9C",
        "text": "Bird mite dermatitis"
    },
    {
        "id": "2G9D",
        "text": "Dystonia-plus"
    },
    {
        "id": "2G9G",
        "text": "Cerebral ischaemic stroke due to extracranial large artery atherosclerosis"
    },
    {
        "id": "2G9I",
        "text": "Cerebral ischaemic stroke due to intracranial large artery atherosclerosis"
    },
    {
        "id": "2G9K",
        "text": "Certain conditions originating in the perinatal period"
    },
    {
        "id": "2G9L",
        "text": "Cerebral aneurysm, nonruptured"
    },
    {
        "id": "2G9M",
        "text": "Person riding, operating or controlling a motorcycle or pedal cycle injured in transport related event"
    },
    {
        "id": "2G9N",
        "text": "Pyridoxal dependent epilepsy"
    },
    {
        "id": "2G9O",
        "text": "Epilepsy due to prenatal or perinatal vascular insults"
    },
    {
        "id": "2G9P",
        "text": "Epilepsy with mesial temporal sclerosis"
    },
    {
        "id": "2G9T",
        "text": "Drug-induced aplastic anaemia"
    },
    {
        "id": "2G9U",
        "text": "Interventions on mouth or pharynx"
    },
    {
        "id": "2G9V",
        "text": "Interventions on mouth or pharynx"
    },
    {
        "id": "2G9W",
        "text": "Late vomiting of pregnancy"
    },
    {
        "id": "2G9X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2G9Y",
        "text": "Nezelof syndrome"
    },
    {
        "id": "2G9Z",
        "text": "Pathological fracture"
    },
    {
        "id": "2H00",
        "text": "Reovirus"
    },
    {
        "id": "2H01",
        "text": "EpiVacCorona-N"
    },
    {
        "id": "2H02",
        "text": "Fusional maldevelopment nystagmus syndrome"
    },
    {
        "id": "2H03",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2H04",
        "text": "COVID-19 vaccines, non-replicating viral vector"
    },
    {
        "id": "2H05",
        "text": "Multiple epiphyseal dysplasia, Beighton type"
    },
    {
        "id": "2H06",
        "text": "Xq27.3q28 duplication"
    },
    {
        "id": "2H0A",
        "text": "Secondary periostitis, head"
    },
    {
        "id": "2H0B",
        "text": "Adenocarcinomas in situ"
    },
    {
        "id": "2H0C",
        "text": "Mixed adenomatous and hyperplastic polyp"
    },
    {
        "id": "2H0D",
        "text": "Pneumonia in ascariasis"
    },
    {
        "id": "2H0E",
        "text": "Adenomas, benign"
    },
    {
        "id": "2H0F",
        "text": "Chronic hepatitis B"
    },
    {
        "id": "2H0G",
        "text": "Transitory endocrine or metabolic disorders specific to fetus or newborn"
    },
    {
        "id": "2H0H",
        "text": "Nausea or vomiting"
    },
    {
        "id": "2H0I",
        "text": "Intentional self-harm by being struck by projectile from paintgun"
    },
    {
        "id": "2H0J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2H0K",
        "text": "Incorrect duration of administration or course of therapy without injury or harm"
    },
    {
        "id": "2H0L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2H0M",
        "text": "Admission for rehabilitation, not otherwise specified"
    },
    {
        "id": "2H0N",
        "text": "Shigellosis due to Shigella flexneri"
    },
    {
        "id": "2H0R",
        "text": "Neoplasm, malignant"
    },
    {
        "id": "2H0Q",
        "text": "Myelodysplastic syndromes"
    },
    {
        "id": "2H0S",
        "text": "Natural blood and blood products"
    },
    {
        "id": "2H0T",
        "text": "Systemic lupus erythematosus"
    },
    {
        "id": "2H0U",
        "text": "Autoimmune inflammatory disorders of the retina"
    },
    {
        "id": "2H0V",
        "text": "Malignant neoplasms of other or ill-defined digestive organs"
    },
    {
        "id": "2H0W",
        "text": "Malignant neoplasms of the spleen"
    },
    {
        "id": "2H0X",
        "text": "Gastrointestinal, abdominal, or abdominal wall procedure associated with injury or harm, endoscopic approach"
    },
    {
        "id": "2H0Y",
        "text": "Malignant neoplasm metastasis in digestive system"
    },
    {
        "id": "2H10",
        "text": "Aneurysm of interatrial septum due to atherosclerosis"
    },
    {
        "id": "2H11",
        "text": "Extension Codes"
    },
    {
        "id": "2H1C",
        "text": "Chemotherapy session for neoplasm"
    },
    {
        "id": "2H1G",
        "text": "Chronic rhinosinusitis"
    },
    {
        "id": "2H1J",
        "text": "Hypertrophy of tonsils with hypertrophy of adenoids"
    },
    {
        "id": "2H1K",
        "text": "Artery of lower extremity"
    },
    {
        "id": "2H1M",
        "text": "IgA vasculitis"
    },
    {
        "id": "2H1L",
        "text": "IgA vasculitis"
    },
    {
        "id": "2H1O",
        "text": "Aetiological considerations in sexual dysfunctions and sexual pain disorders"
    },
    {
        "id": "2H1N",
        "text": "Diabetes mellitus due to drug or chemical"
    },
    {
        "id": "2H1R",
        "text": "Hypothyroidism"
    },
    {
        "id": "2H1S",
        "text": "Secondary hyperthyroidism"
    },
    {
        "id": "2H1T",
        "text": "Resistance to thyrotropin-releasing hormone syndrome"
    },
    {
        "id": "2H12",
        "text": "Substances, chiefly nonmedicinal"
    },
    {
        "id": "2H1V",
        "text": "Medicaments"
    },
    {
        "id": "2H1U",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2H1W",
        "text": "Neoplasms"
    },
    {
        "id": "2C9D",
        "text": "Neuromyelitis optica myelin oligodendrocyte glycoprotein antibody-positive"
    },
    {
        "id": "2H1X",
        "text": "Gastritis due to Sch\u00f6nlein-Henoch purpura"
    },
    {
        "id": "2H1Y",
        "text": "Certain other viral diseases"
    },
    {
        "id": "2H1Z",
        "text": "Certain specified disorders of arteries or arterioles"
    },
    {
        "id": "2H21",
        "text": "Multiple sclerosis or other white matter disorders"
    },
    {
        "id": "2H22",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2H24",
        "text": "Congenital supravalvar mitral ring"
    },
    {
        "id": "2H25",
        "text": "Malignant neoplasm metastasis in other sites"
    },
    {
        "id": "2H26",
        "text": "Bronchopulmonary lymph node"
    },
    {
        "id": "2H27",
        "text": "Leucocytes"
    },
    {
        "id": "2G1Y",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2G1P",
        "text": "Neurodegeneration with brain iron accumulation"
    },
    {
        "id": "2H28",
        "text": "Follow-up examination after treatment for malignant neoplasms"
    },
    {
        "id": "2H29",
        "text": "Small cell non-keratinizing squamous cell carcinoma of lung"
    },
    {
        "id": "2H2A",
        "text": "Adenocarcinoma of bronchus or lung"
    },
    {
        "id": "2H2B",
        "text": "Unspecified malignant epithelial neoplasm of bronchus or lung"
    },
    {
        "id": "2H2E",
        "text": "Infantile neuroaxonal dystrophy"
    },
    {
        "id": "2H2F",
        "text": "Zoster without complications"
    },
    {
        "id": "2H23",
        "text": "Clostridioides difficile"
    },
    {
        "id": "2H2G",
        "text": "Abnormal diagnostic imaging of genitourinary organs"
    },
    {
        "id": "2H2H",
        "text": "Swiss type autosomal recessive agammaglobulinaemia"
    },
    {
        "id": "2H2J",
        "text": "Other secondary osteonecrosis"
    },
    {
        "id": "2H2K",
        "text": "Intracranial nontraumatic haemorrhage of fetus or newborn"
    },
    {
        "id": "2H2L",
        "text": "Intracerebral nontraumatic haemorrhage of fetus or newborn"
    },
    {
        "id": "2H2M",
        "text": "Gas exchanger component of medical device"
    },
    {
        "id": "2H2I",
        "text": "Suicide, not elsewhere classified"
    },
    {
        "id": "2H2N",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "2H2P",
        "text": "Sensation of pain"
    },
    {
        "id": "2H2O",
        "text": "Sensation of pain"
    },
    {
        "id": "2H2Q",
        "text": "Asteroid hyalosis"
    },
    {
        "id": "2H2R",
        "text": "Borderline intellectual functioning"
    },
    {
        "id": "298P",
        "text": "Cutaneous insect bite reactions"
    },
    {
        "id": "2H2S",
        "text": "Hazardous gambling or betting"
    },
    {
        "id": "2H2T",
        "text": "Hazardous gaming"
    },
    {
        "id": "2H2U",
        "text": "Unspecified malignant epithelial neoplasm of bronchus or lung"
    },
    {
        "id": "2H2V",
        "text": "Adenocarcinomas of caecum"
    },
    {
        "id": "2H2X",
        "text": "Maintaining a kneeling position"
    },
    {
        "id": "2H2Y",
        "text": "Kneeling"
    },
    {
        "id": "2H32",
        "text": "Hand and arm use"
    },
    {
        "id": "2H33",
        "text": "Hand and arm use"
    },
    {
        "id": "2H31",
        "text": "Symptomatic manifestations of primary psychotic disorders"
    },
    {
        "id": "2H34",
        "text": "Compulsive sexual behaviour disorder"
    },
    {
        "id": "2H35",
        "text": "Drug-induced liver hypersensitivity disease"
    },
    {
        "id": "2H36",
        "text": "Malignant neoplasms of independent, multiple primary sites"
    },
    {
        "id": "2H37",
        "text": "Moving around using equipment"
    },
    {
        "id": "2H38",
        "text": "Malignant neoplasm metastasis in brain"
    },
    {
        "id": "2H3A",
        "text": "Gastric malignant lymphoma"
    },
    {
        "id": "2D87",
        "text": "Traumatic spondylopathy"
    },
    {
        "id": "2B9O",
        "text": "Amelogenesis imperfecta"
    },
    {
        "id": "2H3B",
        "text": "Bacteria"
    },
    {
        "id": "2H3C",
        "text": "Virus"
    },
    {
        "id": "2H3D",
        "text": "Compression of brain in neonate"
    },
    {
        "id": "2H3E",
        "text": "Angiomyolipoma of liver"
    },
    {
        "id": "2H3F",
        "text": "Xq27.3q28 duplication"
    },
    {
        "id": "2H3H",
        "text": "Benign lipoma of intra-abdominal organs"
    },
    {
        "id": "2H3I",
        "text": "Deep internal or visceral lipoma"
    },
    {
        "id": "2H3J",
        "text": "Ethinyloestradiol"
    },
    {
        "id": "2H3K",
        "text": "Estrogen"
    },
    {
        "id": "2H3L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2H3M",
        "text": "Malignant neoplasms of bronchus or lung"
    },
    {
        "id": "2H3N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2H3O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2H3P",
        "text": "Influenza virus"
    },
    {
        "id": "2H3Q",
        "text": "Extravasation of urine"
    },
    {
        "id": "2H3R",
        "text": "Stiffness of joint"
    },
    {
        "id": "2H3T",
        "text": "Other carbapenem resistant Enterobacterales"
    },
    {
        "id": "2H3U",
        "text": "Other third-generation cephalosporin resistant Enterobacterales"
    },
    {
        "id": "2H3V",
        "text": "Extended-spectrum beta-lactamase producing Enterobacter"
    },
    {
        "id": "2H3W",
        "text": "Autointoxication"
    },
    {
        "id": "2H3X",
        "text": "Helminths"
    },
    {
        "id": "2H3Y",
        "text": "Virus"
    },
    {
        "id": "2H40",
        "text": "Fungi"
    },
    {
        "id": "2H45",
        "text": "Malignant renovascular hypertension"
    },
    {
        "id": "2H46",
        "text": "Malignant renovascular hypertension"
    },
    {
        "id": "2H49",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "2H41",
        "text": "Cardionephritis"
    },
    {
        "id": "2H48",
        "text": "Benign renovascular hypertension"
    },
    {
        "id": "2H47",
        "text": "Malignant renovascular hypertension"
    },
    {
        "id": "2H42",
        "text": "Cardiorenal arteriosclerotic disease"
    },
    {
        "id": "2D8I",
        "text": "Acute vestibular syndrome"
    },
    {
        "id": "2H4A",
        "text": "Polyp of large intestine"
    },
    {
        "id": "2H3Z",
        "text": "Type 1 diabetes mellitus"
    },
    {
        "id": "2C0D",
        "text": "Irritant contact dermatitis due to foods"
    },
    {
        "id": "2H4E",
        "text": "Bowen disease of skin"
    },
    {
        "id": "2H4F",
        "text": "Structural developmental anomalies of mouth or tongue"
    },
    {
        "id": "2H4G",
        "text": "Benign mesenchymal neoplasms"
    },
    {
        "id": "2H4H",
        "text": "Leiomyoma of other or unspecified sites"
    },
    {
        "id": "2H4K",
        "text": "Malignant neoplasms of stomach"
    },
    {
        "id": "2H4L",
        "text": "Malignant neoplasms of intestine"
    },
    {
        "id": "2H4M",
        "text": "Malignant neoplasms of oesophagus"
    },
    {
        "id": "2H4N",
        "text": "Pure red cell aplasia due to acute parvovirus B19 infection"
    },
    {
        "id": "2G5H",
        "text": "Left main coronary artery"
    },
    {
        "id": "2H3S",
        "text": "Special screening examination for neoplasm of breast"
    },
    {
        "id": "2H4P",
        "text": "Internet gaming disorder"
    },
    {
        "id": "2H4Q",
        "text": "Anal warts"
    },
    {
        "id": "2H4S",
        "text": "Neuroendocrine neoplasms of pancreas"
    },
    {
        "id": "2G0U",
        "text": "Skin infection classified elsewhere due to methicillin resistant Staphylococcus aureus"
    },
    {
        "id": "2H4T",
        "text": "Pulmonary dirofilariasis"
    },
    {
        "id": "2E8F",
        "text": "Parasitic cyst of iris or ciliary body"
    },
    {
        "id": "2H4U",
        "text": "Intestinal infections due to Clostridioides difficile"
    },
    {
        "id": "2D4R",
        "text": "Structural developmental anomalies of mouth or tongue"
    },
    {
        "id": "2H4V",
        "text": "Disorder of equilibrium"
    },
    {
        "id": "2G5I",
        "text": "Human T-cell lymphotropic virus type 1 associated infective dermatitis of childhood"
    },
    {
        "id": "2D8B",
        "text": "Multi organ failure"
    },
    {
        "id": "2H44",
        "text": "Hypertensive urgency"
    },
    {
        "id": "2H43",
        "text": "Hypertensive emergency"
    },
    {
        "id": "2H4W",
        "text": "Asymptomatic stenosis of extracranial vertebral artery"
    },
    {
        "id": "2H4X",
        "text": "Asymptomatic stenosis of basilar artery"
    },
    {
        "id": "2H4Y",
        "text": "Asymptomatic stenosis of anterior cerebral artery"
    },
    {
        "id": "2H4Z",
        "text": "Asymptomatic stenosis of middle cerebral artery"
    },
    {
        "id": "2H50",
        "text": "Asymptomatic stenosis of posterior cerebral artery"
    },
    {
        "id": "2D4Y",
        "text": "External causes of morbidity or mortality"
    },
    {
        "id": "2H51",
        "text": "Familial Mediterranean fever"
    },
    {
        "id": "2H54",
        "text": "Megacolon due to Clostridioides difficile"
    },
    {
        "id": "2H52",
        "text": "Primary congenital hypothyroidism due to TSH receptor mutations"
    },
    {
        "id": "2H55",
        "text": "Thyrotropin-releasing hormone deficiency"
    },
    {
        "id": "2H56",
        "text": "Byssinosis"
    },
    {
        "id": "2H58",
        "text": "Lethal restrictive dermopathy"
    },
    {
        "id": "2H57",
        "text": "Alopecia or hair loss"
    },
    {
        "id": "2H59",
        "text": "Connexin palmoplantar keratoderma with sensorineural deafness"
    },
    {
        "id": "2H5E",
        "text": "Primary neoplasm of meninges of unknown or unspecified type"
    },
    {
        "id": "2H5F",
        "text": "Blastic plasmacytoid dendritic cell neoplasm"
    },
    {
        "id": "2H5G",
        "text": "Tuberculosis of the nervous system"
    },
    {
        "id": "2H5I",
        "text": "Cornea guttata"
    },
    {
        "id": "2H5N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2H5J",
        "text": "Malignant neoplasms of the spleen"
    },
    {
        "id": "2H5P",
        "text": "Postprocedural disorders of ear or mastoid process"
    },
    {
        "id": "2H5Q",
        "text": "Additional descriptive information"
    },
    {
        "id": "2H5W",
        "text": "Maintaining body position"
    },
    {
        "id": "2H5O",
        "text": "Retinal vascular occlusions"
    },
    {
        "id": "2H5Z",
        "text": "Renal cell carcinoma of kidney, except renal pelvis"
    },
    {
        "id": "2H5Y",
        "text": "Renal cell carcinoma of kidney, except renal pelvis"
    },
    {
        "id": "2H60",
        "text": "Hereditary thrombophilia"
    },
    {
        "id": "2H62",
        "text": "Lentigo maligna"
    },
    {
        "id": "2H63",
        "text": "Actinic intraepidermal squamous cell carcinoma"
    },
    {
        "id": "2H65",
        "text": "AA amyloidosis"
    },
    {
        "id": "2H66",
        "text": "Oral focal infection affecting other body systems"
    },
    {
        "id": "2H67",
        "text": "Severe pre-eclampsia"
    },
    {
        "id": "2H5C",
        "text": "Tuberculosis of glottis confirmed bacteriologically or histologically"
    },
    {
        "id": "2H5B",
        "text": "Tuberculosis of tracheobronchial lymph nodes confirmed bacteriologically or histologically"
    },
    {
        "id": "2H68",
        "text": "Sexual pain disorders"
    },
    {
        "id": "2H69",
        "text": "Post COVID-19 condition"
    },
    {
        "id": "2H6A",
        "text": "Sustained horizontal conjugate gaze deviation"
    },
    {
        "id": "2H6B",
        "text": "Tuberculosis of the nervous system"
    },
    {
        "id": "2H6C",
        "text": "Vitreomacular Traction"
    },
    {
        "id": "2H6D",
        "text": "Compression of brain in neonate"
    },
    {
        "id": "2H6E",
        "text": "Burn of unspecified body region"
    },
    {
        "id": "2H6F",
        "text": "Malignant neoplasms of jejunum or ileum"
    },
    {
        "id": "2H6G",
        "text": "Patent arterial duct"
    },
    {
        "id": "2H6H",
        "text": "Bulla"
    },
    {
        "id": "2D0P",
        "text": "Mental, behavioural or neurodevelopmental disorders"
    },
    {
        "id": "2H5V",
        "text": "Reaching"
    },
    {
        "id": "2H5U",
        "text": "Reaching"
    },
    {
        "id": "2H5X",
        "text": "Changing basic body position"
    },
    {
        "id": "2H5S",
        "text": "Climbing"
    },
    {
        "id": "2H6J",
        "text": "Tobacco amblyopia"
    },
    {
        "id": "2H6L",
        "text": "Dysphagia"
    },
    {
        "id": "2H6M",
        "text": "Acquired unequal limb length"
    },
    {
        "id": "2H6N",
        "text": "Family history of eye or ear disorders"
    },
    {
        "id": "2H6O",
        "text": "Contracture of joint"
    },
    {
        "id": "2H6Q",
        "text": "Duodenitis due to Giardia lamblia"
    },
    {
        "id": "2H6R",
        "text": "Anal warts"
    },
    {
        "id": "2H6S",
        "text": "Filovirus"
    },
    {
        "id": "2H6T",
        "text": "Flavivirus"
    },
    {
        "id": "2H6U",
        "text": "Bunyavirus"
    },
    {
        "id": "2H6V",
        "text": "Flavivirus"
    },
    {
        "id": "2H6W",
        "text": "Gram Negative Bacteria"
    },
    {
        "id": "2H6Z",
        "text": "Bunyavirus"
    },
    {
        "id": "2H6X",
        "text": "Ankyloblepharon filiforme adnatum"
    },
    {
        "id": "2H71",
        "text": "Malignant neoplasms of ill-defined or unspecified primary sites"
    },
    {
        "id": "2H75",
        "text": "Neisseria meningitidis"
    },
    {
        "id": "2H76",
        "text": "Candida"
    },
    {
        "id": "2H73",
        "text": "Bunyavirus"
    },
    {
        "id": "2H77",
        "text": "Fungi"
    },
    {
        "id": "2H78",
        "text": "Orthobunyavirus"
    },
    {
        "id": "2H79",
        "text": "Virus"
    },
    {
        "id": "2H7A",
        "text": "Virus"
    },
    {
        "id": "2H7B",
        "text": "Yersinia"
    },
    {
        "id": "2H7C",
        "text": "Parasites"
    },
    {
        "id": "2H7D",
        "text": "Serratia spp"
    },
    {
        "id": "2H7E",
        "text": "Gram Negative Bacteria"
    },
    {
        "id": "2H7F",
        "text": "Alphavirus"
    },
    {
        "id": "2H7G",
        "text": "Cervical dystonia or spasmodic torticollis"
    },
    {
        "id": "2H7H",
        "text": "Mucor"
    },
    {
        "id": "2H7J",
        "text": "Staphylococcus"
    },
    {
        "id": "2H7K",
        "text": "Sarcoptes"
    },
    {
        "id": "2H74",
        "text": "Bunyavirus"
    },
    {
        "id": "2H7L",
        "text": "Gram Negative Bacteria"
    },
    {
        "id": "2H7M",
        "text": "Acinetobacter"
    },
    {
        "id": "2H7N",
        "text": "Providencia"
    },
    {
        "id": "2H7O",
        "text": "Helminths"
    },
    {
        "id": "2H7P",
        "text": "Burkholderia"
    },
    {
        "id": "2H7Q",
        "text": "Gram Negative Bacteria"
    },
    {
        "id": "2H7R",
        "text": "Orthobunyavirus"
    },
    {
        "id": "2H7S",
        "text": "Gram Negative Bacteria"
    },
    {
        "id": "2H7U",
        "text": "Mycobacterium"
    },
    {
        "id": "2H7V",
        "text": "Non-tuberculous mycobacterium"
    },
    {
        "id": "2H7X",
        "text": "Non-tuberculous mycobacterium"
    },
    {
        "id": "2H7Y",
        "text": "Tick-borne encephalitis virus"
    },
    {
        "id": "2H7Z",
        "text": "Encephalitis due to Flavivirus"
    },
    {
        "id": "2H80",
        "text": "Fasciola"
    },
    {
        "id": "2H81",
        "text": "Parvovirus"
    },
    {
        "id": "2H82",
        "text": "Metal"
    },
    {
        "id": "2H83",
        "text": "Gram Negative Bacteria"
    },
    {
        "id": "2H84",
        "text": "Stenotrophomonas"
    },
    {
        "id": "2H85",
        "text": "Gram Negative Bacteria"
    },
    {
        "id": "2H86",
        "text": "Gram Negative Bacteria"
    },
    {
        "id": "2H87",
        "text": "Chlamydia pneumoniae"
    },
    {
        "id": "2H88",
        "text": "Chlamydia psittaci"
    },
    {
        "id": "2H89",
        "text": "Fungi"
    },
    {
        "id": "2H8A",
        "text": "Fungi"
    },
    {
        "id": "2H8B",
        "text": "Factors influencing health status or contact with health services"
    },
    {
        "id": "2H8C",
        "text": "Post COVID-19 condition"
    },
    {
        "id": "2H8E",
        "text": "Certain disorders originating in the perinatal period"
    },
    {
        "id": "2G1B",
        "text": "HIV-associated lipodystrophy"
    },
    {
        "id": "2H8D",
        "text": "HIV-associated lipodystrophy"
    },
    {
        "id": "2H8I",
        "text": "Anal warts"
    },
    {
        "id": "2H8J",
        "text": "Genital warts"
    },
    {
        "id": "2H8K",
        "text": "Extragenital condylomata acuminata"
    },
    {
        "id": "2H8L",
        "text": "Carcinoma in situ of other or unspecified female genital organs"
    },
    {
        "id": "2H8M",
        "text": "Carcinoma in situ of other or unspecified male genital organs"
    },
    {
        "id": "2H8N",
        "text": "Benign neoplasm of other or unspecified female genital organs"
    },
    {
        "id": "2H8O",
        "text": "Diseases of the genitourinary system"
    },
    {
        "id": "2H8P",
        "text": "Autism spectrum disorder without disorder of intellectual development and with mild or no impairment of functional language"
    },
    {
        "id": "2H8Q",
        "text": "Disorders of autonomic nervous system"
    },
    {
        "id": "2G7O",
        "text": "Contact with health services for nonsurgical interventions not involving devices"
    },
    {
        "id": "2H8R",
        "text": "Malignant lesion involving overlapping sites of penis"
    },
    {
        "id": "2G8L",
        "text": "Anomalous portal venous connection"
    },
    {
        "id": "2H20",
        "text": "Squamous cell carcinoma of larynx, glottis"
    },
    {
        "id": "2H8S",
        "text": "Disorders of conjunctiva"
    },
    {
        "id": "2H8T",
        "text": "Rupture of pulmonary vessels"
    },
    {
        "id": "2H8U",
        "text": "External causes of morbidity or mortality"
    },
    {
        "id": "2H8V",
        "text": "Cloacogenic zone metastasis"
    },
    {
        "id": "2H8X",
        "text": "Primary congenital hypothyroidism due to a developmental anomaly"
    },
    {
        "id": "2H8Y",
        "text": "Systemic lupus erythematosus with encephalitis"
    },
    {
        "id": "2H8Z",
        "text": "Sarcoma, not elsewhere classified, primary site"
    },
    {
        "id": "2H90",
        "text": "Sacrococcygeal pilonidal sinus"
    },
    {
        "id": "2H91",
        "text": "Interdigital pilonidal sinus"
    },
    {
        "id": "2H93",
        "text": "Oral rehydration salts"
    },
    {
        "id": "2H97",
        "text": "Cardiac arrest"
    },
    {
        "id": "2H96",
        "text": "Cardiac arrest"
    },
    {
        "id": "2H94",
        "text": "Benzoic acid with salicylic acid"
    },
    {
        "id": "2H95",
        "text": "Artemether"
    },
    {
        "id": "2H92",
        "text": "Mineral salts and supplements, not elsewhere classified"
    },
    {
        "id": "2H99",
        "text": "Somatotroph adenoma"
    },
    {
        "id": "2H9B",
        "text": "Pericarditis in diseases classified elsewhere"
    },
    {
        "id": "2H9H",
        "text": "Automatic idioventricular rhythm"
    },
    {
        "id": "2H9I",
        "text": "Carcinoma in situ of bladder"
    },
    {
        "id": "2H9J",
        "text": "Personal history of major surgery"
    },
    {
        "id": "2H9K",
        "text": "Double outlet right ventricle with intact ventricular septum"
    },
    {
        "id": "2H9L",
        "text": "Double outlet right ventricle"
    },
    {
        "id": "2H9M",
        "text": "Pain in throat or chest"
    },
    {
        "id": "2H9N",
        "text": "Primary female infertility of tubal origin"
    },
    {
        "id": "2H9Q",
        "text": "Abnormal intrapericardial course of great arteries"
    },
    {
        "id": "2H9S",
        "text": "Sedative, hypnotic or anxiolytic dependence, sustained full remission"
    },
    {
        "id": "2H9T",
        "text": "Sequelae of respiratory or unspecified tuberculosis"
    },
    {
        "id": "2H9U",
        "text": "Systemic lupus erythematosus with skin involvement"
    },
    {
        "id": "2H9V",
        "text": "Chronic cutaneous lupus erythematosus"
    },
    {
        "id": "2H9W",
        "text": "Juvenile dermatomyositis"
    },
    {
        "id": "2H9X",
        "text": "Neonatal lupus erythematosus"
    },
    {
        "id": "2H9Y",
        "text": "Paraneoplastic polymyositis"
    },
    {
        "id": "2I00",
        "text": "Acroscleriasis"
    },
    {
        "id": "2I01",
        "text": "Aorta of thorax"
    },
    {
        "id": "2I02",
        "text": "Secondary disorders of arteries and arterioles"
    },
    {
        "id": "2H9Z",
        "text": "Diffuse systemic sclerosis"
    },
    {
        "id": "2I03",
        "text": "Systemic sclerosis"
    },
    {
        "id": "2I04",
        "text": "Autoimmune hepatitis"
    },
    {
        "id": "2I05",
        "text": "Primary sclerosing cholangitis with cirrhosis, without cholangiocarcinoma"
    },
    {
        "id": "2I06",
        "text": "Intracranial nontraumatic haemorrhage of fetus or newborn"
    },
    {
        "id": "2I07",
        "text": "Ventricular tachycardia and fibrillation cardiac arrest"
    },
    {
        "id": "2I09",
        "text": "Bradycardic cardiac arrest"
    },
    {
        "id": "2I0B",
        "text": "Asystolic cardiac arrest"
    },
    {
        "id": "2I0D",
        "text": "Cardiopulmonary arrest"
    },
    {
        "id": "2I0C",
        "text": "Cardiac arrest with pulseless electrical activity"
    },
    {
        "id": "2I0I",
        "text": "Endometriosis of the nervous system"
    },
    {
        "id": "2I0L",
        "text": "Salpingitis isthmica nodosa"
    },
    {
        "id": "2I0P",
        "text": "Postural orthostatic tachycardia syndrome"
    },
    {
        "id": "2I0Q",
        "text": "Conjunctival oedema"
    },
    {
        "id": "2I0R",
        "text": "Brain irradiation"
    },
    {
        "id": "2H98",
        "text": "Acute kidney failure"
    },
    {
        "id": "2H9E",
        "text": "Idiopathic pulmonary fibrosis"
    },
    {
        "id": "2I0E",
        "text": "Endometriosis"
    },
    {
        "id": "2I0Z",
        "text": "Mycobacterium leprae dacryoadenitis"
    },
    {
        "id": "2I10",
        "text": "Gas gangrene"
    },
    {
        "id": "2I11",
        "text": "Tabardillo"
    },
    {
        "id": "2I12",
        "text": "Streptobacillosis"
    },
    {
        "id": "2I14",
        "text": "Diseases of arteries or arterioles"
    },
    {
        "id": "2I13",
        "text": "Carcinoma in situ of antrum"
    },
    {
        "id": "2I15",
        "text": "Encephalitis due to Koutango virus"
    },
    {
        "id": "2I16",
        "text": "Other disorders of the nervous system"
    },
    {
        "id": "2I17",
        "text": "Viral meningitis due to Herpes simplex type 1"
    },
    {
        "id": "2I18",
        "text": "Viral meningitis due to Herpes simplex type 2"
    },
    {
        "id": "2I19",
        "text": "Maternal care related to placenta praevia or low lying placenta"
    },
    {
        "id": "2I1A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2I1C",
        "text": "Abdominal aortic aneurysm with perforation"
    },
    {
        "id": "2I1D",
        "text": "Ventricular septal defect as current complication following acute myocardial infarction"
    },
    {
        "id": "2I1E",
        "text": "Familial sick sinus syndrome"
    },
    {
        "id": "2I1G",
        "text": "Lobomycosis"
    },
    {
        "id": "2I1H",
        "text": "Tear of meniscus, current"
    },
    {
        "id": "2H6I",
        "text": "Manipulating"
    },
    {
        "id": "2I1I",
        "text": "Hand and arm use"
    },
    {
        "id": "2I1K",
        "text": "Late-onset pneumonia due to Klebsiella"
    },
    {
        "id": "2I1O",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2I1J",
        "text": "Fine hand use"
    },
    {
        "id": "2I1Q",
        "text": "Dermal nerve sheath myxoma"
    },
    {
        "id": "2I1P",
        "text": "Perineurioma"
    },
    {
        "id": "2I1R",
        "text": "Neoplasms of uncertain behaviour of nasal cavities"
    },
    {
        "id": "2I1N",
        "text": "Benign neoplasm of Meckel diverticulum"
    },
    {
        "id": "2I1M",
        "text": "Benign neoplasm of base of tongue"
    },
    {
        "id": "2I1S",
        "text": "Dabska tumour"
    },
    {
        "id": "2I1T",
        "text": "Other mixed or unspecified benign mesenchymal tumours"
    },
    {
        "id": "2D1B",
        "text": "Hazardous substance use"
    },
    {
        "id": "2I1U",
        "text": "Viral meningitis due to Herpes simplex type 1"
    },
    {
        "id": "2I1V",
        "text": "Viral meningitis due to Herpes simplex type 2"
    },
    {
        "id": "2I1W",
        "text": "Streptococcal pharyngitis"
    },
    {
        "id": "2I1X",
        "text": "Meningitis due to Streptococcus agalactiae"
    },
    {
        "id": "2I1Y",
        "text": "Necrotising fasciitis of the scrotum, penis or perineum"
    },
    {
        "id": "2I1Z",
        "text": "Small plaque parapsoriasis"
    },
    {
        "id": "2I20",
        "text": "Pneumonia"
    },
    {
        "id": "2A3V",
        "text": "Thrombophlebitis migrans"
    },
    {
        "id": "2I21",
        "text": "Recurrent idiopathic thrombophlebitis"
    },
    {
        "id": "2I22",
        "text": "Pneumonitis due to solids and liquids"
    },
    {
        "id": "2I23",
        "text": "Acquired neutropaenia"
    },
    {
        "id": "2I24",
        "text": "Other specified lymphocytosis"
    },
    {
        "id": "2I25",
        "text": "Dehydration"
    },
    {
        "id": "2I26",
        "text": "Glanders"
    },
    {
        "id": "2I28",
        "text": "Minor plague"
    },
    {
        "id": "2I2A",
        "text": "Septicaemic plague without mention of septic shock"
    },
    {
        "id": "2I2C",
        "text": "Oculoglandular tularaemia"
    },
    {
        "id": "2I2D",
        "text": "Brucellosis due to Brucella abortus"
    },
    {
        "id": "2I2E",
        "text": "Brucellosis due to Brucella suis"
    },
    {
        "id": "2I2F",
        "text": "Sepsis due to Erysipelothrix without mention of septic shock"
    },
    {
        "id": "2I2G",
        "text": "Candidosis"
    },
    {
        "id": "2I2I",
        "text": "Aneurysm or dissection of carotid artery"
    },
    {
        "id": "2I2J",
        "text": "Sinus bradycardia with ventricular escape"
    },
    {
        "id": "2I2K",
        "text": "Nodal arrhythmia"
    },
    {
        "id": "2I2L",
        "text": "Diagnosis timing"
    },
    {
        "id": "2I2M",
        "text": "Pulmonary anthrax"
    },
    {
        "id": "2I2N",
        "text": "Panendoscopy of digestive tract, via camera capsule, not elsewhere classified"
    },
    {
        "id": "2I2O",
        "text": "Intra-abdominal vascular shunt or bypass"
    },
    {
        "id": "2I2Q",
        "text": "Unintentional land transport event unknown whether traffic or nontraffic injuring an occupant of an all-terrain vehicle"
    },
    {
        "id": "2I2P",
        "text": "Unintentional land transport nontraffic event injuring a user of an all-terrain vehicle"
    },
    {
        "id": "2I2V",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2I2W",
        "text": "10q21q24 duplication"
    },
    {
        "id": "2I2X",
        "text": "Turner syndrome"
    },
    {
        "id": "2I2Y",
        "text": "Symptoms of blood, blood-forming organs, or the immune system"
    },
    {
        "id": "2I2S",
        "text": "Actinomycosis"
    },
    {
        "id": "2I30",
        "text": "Pulmonary actinomycosis"
    },
    {
        "id": "2I31",
        "text": "Certain specified fibrosis or cirrhosis of liver"
    },
    {
        "id": "2I32",
        "text": "Genetically-determined epidermolysis bullosa"
    },
    {
        "id": "2I35",
        "text": "Cervicofacial actinomycosis"
    },
    {
        "id": "2I36",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2I37",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2I39",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2I3A",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2I3B",
        "text": "Conditions related to sexual health"
    },
    {
        "id": "2I3C",
        "text": "Endocrine, nutritional or metabolic diseases"
    },
    {
        "id": "2I2R",
        "text": "Cystic hygroma in fetus"
    },
    {
        "id": "2I3F",
        "text": "Injury of eye or orbit"
    },
    {
        "id": "2I3G",
        "text": "Purulent endophthalmitis"
    },
    {
        "id": "2I3M",
        "text": "Condition after acute angle-closure glaucoma attack"
    },
    {
        "id": "2I3N",
        "text": "Developmental nonodontogenic cysts of oral region"
    },
    {
        "id": "2I3O",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2I3Q",
        "text": "Assault by other and unspecified firearm discharge"
    },
    {
        "id": "2I3S",
        "text": "Fat pelvis with disproportion, causing obstructed labour"
    },
    {
        "id": "2I3T",
        "text": "Transitional cell papillomas and carcinomas, benign"
    },
    {
        "id": "2I3W",
        "text": "Design, construction and building products and technology of buildings for public use"
    },
    {
        "id": "2I3X",
        "text": "Design, construction and building products and technology of buildings for private use"
    },
    {
        "id": "2I3Z",
        "text": "Abdominal compression, not elsewhere classified"
    },
    {
        "id": "2I40",
        "text": "Shielding failure"
    },
    {
        "id": "2I3Y",
        "text": "Monkeypox"
    },
    {
        "id": "2I41",
        "text": "Aspiration pneumonitis due to anaesthesia during labour or delivery"
    },
    {
        "id": "2I42",
        "text": "Chronic widespread pain"
    },
    {
        "id": "2I44",
        "text": "Curly hair \u2013 acral keratoderma \u2013 caries syndrome"
    },
    {
        "id": "2I4B",
        "text": "Monkeypox"
    },
    {
        "id": "2I4A",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I4C",
        "text": "Augmentation genioplasty"
    },
    {
        "id": "2I4D",
        "text": "Reduction genioplasty"
    },
    {
        "id": "2I3R",
        "text": "Monkeypox"
    },
    {
        "id": "2I4G",
        "text": "Replacement of hip joint device"
    },
    {
        "id": "2I4H",
        "text": "Complete sialoadenectomy"
    },
    {
        "id": "2I4I",
        "text": "Bone of lower leg"
    },
    {
        "id": "2I4J",
        "text": "Bone of foot or toe"
    },
    {
        "id": "2I4K",
        "text": "Ankle joint"
    },
    {
        "id": "2I4L",
        "text": "Closed reduction of elbow joint"
    },
    {
        "id": "2I4M",
        "text": "Reconstruction of larynx"
    },
    {
        "id": "2I4N",
        "text": "Reconstruction"
    },
    {
        "id": "2I4O",
        "text": "Partial excision of bladder"
    },
    {
        "id": "2I4P",
        "text": "Laparoscopic partial excision of bladder"
    },
    {
        "id": "2I4Q",
        "text": "Transurethral resection of bladder lesion"
    },
    {
        "id": "2I4S",
        "text": "Interventions on vertebral column"
    },
    {
        "id": "2I4Y",
        "text": "Monkeypox"
    },
    {
        "id": "2I4Z",
        "text": "Interventions on gastrointestinal tract"
    },
    {
        "id": "2I52",
        "text": "Interventions on gastrointestinal tract"
    },
    {
        "id": "2I54",
        "text": "Interventions on gastrointestinal tract"
    },
    {
        "id": "2I56",
        "text": "Interventions on lower respiratory tract"
    },
    {
        "id": "2I57",
        "text": "Partial excision of skin and subcutaneous cell tissue, not elsewhere classified"
    },
    {
        "id": "2I5A",
        "text": "Monkeypox"
    },
    {
        "id": "2I5B",
        "text": "Monkeypox"
    },
    {
        "id": "2I5D",
        "text": "Certain zoonotic viral diseases"
    },
    {
        "id": "2I5H",
        "text": "Monkeypox"
    },
    {
        "id": "2I5J",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I5Q",
        "text": "Interventions on musculoskeletal structures of head or neck"
    },
    {
        "id": "2I5R",
        "text": "Interventions on spinal cord structures"
    },
    {
        "id": "2I5S",
        "text": "Interventions on musculoskeletal structures of knee"
    },
    {
        "id": "2I5T",
        "text": "Interventions on musculoskeletal structures of lower leg"
    },
    {
        "id": "2I5W",
        "text": "Monkeypox"
    },
    {
        "id": "2I5V",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2I61",
        "text": "Monkeypox"
    },
    {
        "id": "2I64",
        "text": "Diseases of the skin"
    },
    {
        "id": "2I67",
        "text": "Monkeypox"
    },
    {
        "id": "2I69",
        "text": "Monkeypox"
    },
    {
        "id": "2I66",
        "text": "Monkeypox"
    },
    {
        "id": "2I65",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I6C",
        "text": "Diseases of the skin"
    },
    {
        "id": "2I6D",
        "text": "Monkeypox"
    },
    {
        "id": "2I6F",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I6H",
        "text": "Flegel disease"
    },
    {
        "id": "2I6J",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I6L",
        "text": "Monkeypox"
    },
    {
        "id": "2I6P",
        "text": "Monkeypox"
    },
    {
        "id": "2I6R",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I6T",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I6X",
        "text": "Monkeypox"
    },
    {
        "id": "2I6Z",
        "text": "Monkeypox"
    },
    {
        "id": "2I70",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I4W",
        "text": "Interventions on pleura or thoracic spaces"
    },
    {
        "id": "2I4X",
        "text": "Interventions on pleura or thoracic spaces"
    },
    {
        "id": "2I71",
        "text": "Monkeypox"
    },
    {
        "id": "2I4V",
        "text": "Interventions on musculoskeletal structures of shoulder"
    },
    {
        "id": "2I73",
        "text": "Gastrointestinal stromal tumour, primary site"
    },
    {
        "id": "2I74",
        "text": "Gestational thyrotoxicosis"
    },
    {
        "id": "2I76",
        "text": "Rheumatic fever myocarditis with active with chorea"
    },
    {
        "id": "2I78",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I79",
        "text": "Monkeypox"
    },
    {
        "id": "2I6V",
        "text": "Distortion of orbit"
    },
    {
        "id": "2I7D",
        "text": "Monkeypox"
    },
    {
        "id": "2I6W",
        "text": "Monkeypox"
    },
    {
        "id": "2I7E",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I7F",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I7M",
        "text": "Monkeypox"
    },
    {
        "id": "2I7Q",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I7R",
        "text": "Monkeypox"
    },
    {
        "id": "2I7S",
        "text": "Monkeypox"
    },
    {
        "id": "2I7O",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I7W",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I7X",
        "text": "Monkeypox"
    },
    {
        "id": "2I80",
        "text": "Monkeypox"
    },
    {
        "id": "2I86",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I87",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I8A",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I8D",
        "text": "Diseases of the skin"
    },
    {
        "id": "2I8F",
        "text": "Monkeypox"
    },
    {
        "id": "2I8I",
        "text": "Monkeypox"
    },
    {
        "id": "2I8L",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I8N",
        "text": "Monkeypox"
    },
    {
        "id": "2I8P",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I8J",
        "text": "Monkeypox"
    },
    {
        "id": "2I8W",
        "text": "Monkeypox"
    },
    {
        "id": "2I8X",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2I8V",
        "text": "Monkeypox"
    },
    {
        "id": "2I90",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I8Z",
        "text": "Monkeypox"
    },
    {
        "id": "2I93",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I95",
        "text": "Monkeypox"
    },
    {
        "id": "2I96",
        "text": "Monkeypox"
    },
    {
        "id": "2I98",
        "text": "Diseases of the skin"
    },
    {
        "id": "2I97",
        "text": "Diseases of the skin"
    },
    {
        "id": "2I99",
        "text": "Monkeypox"
    },
    {
        "id": "2I9A",
        "text": "Monkeypox"
    },
    {
        "id": "2I9B",
        "text": "Monkeypox"
    },
    {
        "id": "2I9C",
        "text": "Diseases of the skin"
    },
    {
        "id": "2I9D",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I9F",
        "text": "Diseases of the skin"
    },
    {
        "id": "2I9I",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I9L",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I9U",
        "text": "Monkeypox"
    },
    {
        "id": "2I9V",
        "text": "Monkeypox"
    },
    {
        "id": "2I9Y",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I9Z",
        "text": "Monkeypox"
    },
    {
        "id": "2J00",
        "text": "Monkeypox"
    },
    {
        "id": "2J02",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J01",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J05",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J06",
        "text": "Monkeypox"
    },
    {
        "id": "2J08",
        "text": "Monkeypox"
    },
    {
        "id": "2J0B",
        "text": "Monkeypox"
    },
    {
        "id": "2J0E",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J0F",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J0H",
        "text": "Monkeypox"
    },
    {
        "id": "2J0K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J0J",
        "text": "Monkeypox"
    },
    {
        "id": "2J0M",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J0O",
        "text": "Monkeypox"
    },
    {
        "id": "2J0P",
        "text": "Monkeypox"
    },
    {
        "id": "2J0S",
        "text": "Monkeypox"
    },
    {
        "id": "2J0U",
        "text": "Monkeypox"
    },
    {
        "id": "2J0V",
        "text": "Monkeypox"
    },
    {
        "id": "2J0W",
        "text": "Monkeypox"
    },
    {
        "id": "2J0N",
        "text": "Monkeypox"
    },
    {
        "id": "2I9H",
        "text": "Monkeypox"
    },
    {
        "id": "2J0Y",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J0Z",
        "text": "Allergic contact dermatitis due to allergenic haptens derived from plants or organic matter"
    },
    {
        "id": "2I7K",
        "text": "Monkeypox"
    },
    {
        "id": "2J11",
        "text": "Monkeypox"
    },
    {
        "id": "2J12",
        "text": "Diseases of the skin"
    },
    {
        "id": "2J14",
        "text": "Monkeypox"
    },
    {
        "id": "2J16",
        "text": "Injury or harm in therapeutic use by nicotinic acid derivatives"
    },
    {
        "id": "2J17",
        "text": "Retractor usage too long or incorrect"
    },
    {
        "id": "2J18",
        "text": "Monkeypox"
    },
    {
        "id": "2J1A",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J1B",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J1D",
        "text": "Incorrect substance, as mode of injury or harm"
    },
    {
        "id": "2J1F",
        "text": "Infections due to poxvirus"
    },
    {
        "id": "2J1G",
        "text": "Monkeypox"
    },
    {
        "id": "2J1J",
        "text": "Certain other viral diseases"
    },
    {
        "id": "2I48",
        "text": "Monkeypox"
    },
    {
        "id": "2J1L",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J1N",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J1P",
        "text": "Monkeypox"
    },
    {
        "id": "2J1S",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J1T",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J1W",
        "text": "Monkeypox"
    },
    {
        "id": "2J1X",
        "text": "Infections due to poxvirus"
    },
    {
        "id": "2I91",
        "text": "Monkeypox"
    },
    {
        "id": "2J1Z",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J20",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I47",
        "text": "Monkeypox"
    },
    {
        "id": "2J28",
        "text": "Samter syndrome"
    },
    {
        "id": "2J25",
        "text": "Monkeypox"
    },
    {
        "id": "2J0Q",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I8S",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J29",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J2D",
        "text": "Monkeypox"
    },
    {
        "id": "2J2F",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I88",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J2I",
        "text": "Monkeypox"
    },
    {
        "id": "2J2J",
        "text": "Infections due to poxvirus"
    },
    {
        "id": "2J2L",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J2K",
        "text": "Monkeypox"
    },
    {
        "id": "2J2M",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J2B",
        "text": "Monkeypox"
    },
    {
        "id": "2J0X",
        "text": "Monkeypox"
    },
    {
        "id": "2J2Q",
        "text": "Monkeypox"
    },
    {
        "id": "2J2R",
        "text": "Monkeypox"
    },
    {
        "id": "2J1U",
        "text": "Monkeypox"
    },
    {
        "id": "2J2S",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J2U",
        "text": "Monkeypox"
    },
    {
        "id": "2J2T",
        "text": "Adenocarcinomas of rectum"
    },
    {
        "id": "2J2V",
        "text": "Neuroendocrine neoplasms of rectum"
    },
    {
        "id": "2J2W",
        "text": "Monkeypox"
    },
    {
        "id": "2J2X",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J2Y",
        "text": "Monkeypox"
    },
    {
        "id": "2J03",
        "text": "Monkeypox"
    },
    {
        "id": "2J2Z",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J1C",
        "text": "Monkeypox"
    },
    {
        "id": "2J30",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J31",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J33",
        "text": "Monkeypox"
    },
    {
        "id": "2J3B",
        "text": "Chronic obstructive pulmonary disease with acute exacerbation, unspecified"
    },
    {
        "id": "2J0R",
        "text": "Monkeypox"
    },
    {
        "id": "2J3G",
        "text": "Pulmonary thromboembolism"
    },
    {
        "id": "2J3J",
        "text": "Bacterial infection of unspecified site"
    },
    {
        "id": "2J3K",
        "text": "Deep hemispheric haemorrhage"
    },
    {
        "id": "2J3M",
        "text": "Radiculopathy due to spondylosis"
    },
    {
        "id": "2J3N",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J37",
        "text": "Non-allergic asthma with exacerbation"
    },
    {
        "id": "2J34",
        "text": "Allergic asthma with exacerbation"
    },
    {
        "id": "2J36",
        "text": "Allergic asthma, uncomplicated"
    },
    {
        "id": "2J39",
        "text": "Non-allergic asthma, uncomplicated"
    },
    {
        "id": "2J3R",
        "text": "Pseudomonas mallei"
    },
    {
        "id": "2J3S",
        "text": "Pseudomonas pseudomallei"
    },
    {
        "id": "2J3T",
        "text": "Staphylococcus"
    },
    {
        "id": "2J3U",
        "text": "Staphylococcus"
    },
    {
        "id": "2J3V",
        "text": "Streptococcus viridans"
    },
    {
        "id": "2J3W",
        "text": "Streptococcus, group A"
    },
    {
        "id": "2J3X",
        "text": "Streptococcus, group B"
    },
    {
        "id": "2J3Y",
        "text": "Streptococcus, group D"
    },
    {
        "id": "2J3Z",
        "text": "Polyoma virus B19"
    },
    {
        "id": "2J40",
        "text": "Pediculus"
    },
    {
        "id": "2J41",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J42",
        "text": "Carbocaine nerve block"
    },
    {
        "id": "2J1I",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J3E",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J19",
        "text": "Monkeypox"
    },
    {
        "id": "2J43",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J45",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J46",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J48",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J49",
        "text": "Non-allergic asthma with status asthmaticus"
    },
    {
        "id": "2J4A",
        "text": "Monkeypox"
    },
    {
        "id": "2J4D",
        "text": "Orbital region"
    },
    {
        "id": "2J4H",
        "text": "Pleural effusion"
    },
    {
        "id": "2J4I",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J4J",
        "text": "Dislocation of lens"
    },
    {
        "id": "2J4K",
        "text": "Lifitegrast"
    },
    {
        "id": "2J4L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J4M",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J4N",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J4O",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J4Q",
        "text": "Suppurative otitis media"
    },
    {
        "id": "2J4T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J4U",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J4V",
        "text": "Agenesis of trachea"
    },
    {
        "id": "2J4W",
        "text": "Suicide, not elsewhere classified"
    },
    {
        "id": "2J4S",
        "text": "Diseases of the blood or blood-forming organs"
    },
    {
        "id": "2J4X",
        "text": "Monkeypox"
    },
    {
        "id": "2J4C",
        "text": "Monkeypox"
    },
    {
        "id": "2J24",
        "text": "Monkeypox"
    },
    {
        "id": "2J1Y",
        "text": "Monkeypox"
    },
    {
        "id": "2J0D",
        "text": "Monkeypox"
    },
    {
        "id": "2J0C",
        "text": "Monkeypox"
    },
    {
        "id": "2I92",
        "text": "Monkeypox"
    },
    {
        "id": "2I77",
        "text": "Monkeypox"
    },
    {
        "id": "2I68",
        "text": "Monkeypox"
    },
    {
        "id": "2I5U",
        "text": "Monkeypox"
    },
    {
        "id": "2J26",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I9Q",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I9X",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I4U",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I55",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I9J",
        "text": "Monkeypox virus"
    },
    {
        "id": "2I8T",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2J4Z",
        "text": "Anaemia complicating pregnancy, childbirth or the puerperium"
    },
    {
        "id": "2J50",
        "text": "Coronary atherosclerosis of native coronary artery"
    },
    {
        "id": "2J51",
        "text": "Coronary atherosclerosis"
    },
    {
        "id": "2J52",
        "text": "Coronary atherosclerosis of autologous bypass graft"
    },
    {
        "id": "2J53",
        "text": "Coronary atherosclerosis of non-autologous bypass graft"
    },
    {
        "id": "2J54",
        "text": "Physical medicine devices associated with injury or harm, surgical instruments, materials or devices"
    },
    {
        "id": "2J55",
        "text": "Injury or harm caused by tissue\u2010type plasminogen activator in therapeutic use"
    },
    {
        "id": "2I27",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J4R",
        "text": "Monkeypox"
    },
    {
        "id": "2J56",
        "text": "Diseases of the skin"
    },
    {
        "id": "2I45",
        "text": "Monkeypox"
    },
    {
        "id": "2I7Y",
        "text": "Monkeypox"
    },
    {
        "id": "2J5I",
        "text": "Primary hyperaldosteronism"
    },
    {
        "id": "2J5B",
        "text": "autosomal dominant Fabry disease"
    },
    {
        "id": "2J5D",
        "text": "autosomal recessive Fabry disease"
    },
    {
        "id": "2J5J",
        "text": "Chronic primary visceral pain"
    },
    {
        "id": "2J5F",
        "text": "Monkeypox"
    },
    {
        "id": "2J5A",
        "text": "Cutaneous and subcutaneous xanthomata"
    },
    {
        "id": "2J5C",
        "text": "Intentional self-harm by exposure to or harmful effects of skeletal muscle relaxants"
    },
    {
        "id": "2J5E",
        "text": "Monkeypox virus"
    },
    {
        "id": "2J8D",
        "text": "Diverticulum of duodenum"
    },
    {
        "id": "2J8E",
        "text": "Diverticulitis of small intestine with perforation and abscess"
    },
    {
        "id": "2J8F",
        "text": "Diverticulitis of large intestine with perforation and abscess"
    },
    {
        "id": "2J8G",
        "text": "Diverticulitis of unspecified part of intestine with perforation and abscess"
    },
    {
        "id": "2J8H",
        "text": "Proximal radioulnar joint"
    },
    {
        "id": "2J8J",
        "text": "Abortive outcome of pregnancy"
    },
    {
        "id": "2J8K",
        "text": "Infected hydrocele"
    },
    {
        "id": "2J8L",
        "text": "Certain specified diseases of pancreas"
    },
    {
        "id": "2J8M",
        "text": "Narcolepsy, Type 1"
    },
    {
        "id": "2J8Q",
        "text": "Idiopathic hypersomnia"
    },
    {
        "id": "2J8R",
        "text": "Monkeypox"
    },
    {
        "id": "2J8V",
        "text": "Unclassified pleomorphic sarcoma, primary site"
    },
    {
        "id": "2J8P",
        "text": "Chronic traumatic subdural haemorrhage"
    },
    {
        "id": "2J8O",
        "text": "Acute traumatic subdural haemorrhage"
    },
    {
        "id": "2J8X",
        "text": "Acute myeloid leukaemia, NOS"
    },
    {
        "id": "2J8Y",
        "text": "Myocarditis"
    },
    {
        "id": "2J8Z",
        "text": "Myocarditis"
    },
    {
        "id": "2J90",
        "text": "Lung infections"
    },
    {
        "id": "2J92",
        "text": "Elbow joint"
    },
    {
        "id": "2J91",
        "text": "Elbow joint"
    },
    {
        "id": "2J97",
        "text": "Fabry disease X-linked"
    },
    {
        "id": "2J8T",
        "text": "autosomal dominant Fabry disease"
    },
    {
        "id": "2J8U",
        "text": "autosomal recessive Fabry disease"
    },
    {
        "id": "2J98",
        "text": "Myeloid sarcoma"
    },
    {
        "id": "2J99",
        "text": "Malignant neoplasm metastasis in liver"
    },
    {
        "id": "2J9A",
        "text": "Malignant neoplasm metastasis in liver or intrahepatic bile duct"
    },
    {
        "id": "2J9B",
        "text": "Gastrointestinal stromal tumour of oesophagus"
    },
    {
        "id": "2J9C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J95",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J9E",
        "text": "Pulmonary lobe mass"
    },
    {
        "id": "2J9F",
        "text": "Pulmonary retroperitoneal mass"
    },
    {
        "id": "2J9H",
        "text": "Poxvirus"
    },
    {
        "id": "2J9G",
        "text": "Infections due to poxvirus"
    },
    {
        "id": "2J9I",
        "text": "Percutaneous lumbar fusion"
    },
    {
        "id": "2J9J",
        "text": "Endoscopic sclerotherapy of oesophageal varices"
    },
    {
        "id": "2J9K",
        "text": "Vitrectomy"
    },
    {
        "id": "2J9L",
        "text": "Interventions on eye, posterior segment structures"
    },
    {
        "id": "2J9M",
        "text": "Occlusion of abdominal or pelvic artery"
    },
    {
        "id": "2J9D",
        "text": "Monkeypox"
    },
    {
        "id": "2J9N",
        "text": "Osteoporosis in multiple myelomatosis, upper arm"
    },
    {
        "id": "2J9O",
        "text": "Fracture of other finger bone"
    },
    {
        "id": "2J9P",
        "text": "Medical observation or evaluation for suspected diseases or conditions, ruled out"
    },
    {
        "id": "2J9Q",
        "text": "Intestinal infections due to Clostridioides difficile"
    },
    {
        "id": "2J9R",
        "text": "Acquired Immune Deficiency Syndrome"
    },
    {
        "id": "2J9S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J9U",
        "text": "Metatarsal\u00a0bone"
    },
    {
        "id": "2J9V",
        "text": "Metatarsal\u00a0bone"
    },
    {
        "id": "2J9W",
        "text": "Metatarsal\u00a0bone"
    },
    {
        "id": "2J9X",
        "text": "Metatarsal\u00a0bone"
    },
    {
        "id": "2J9Y",
        "text": "Metatarsal\u00a0bone"
    },
    {
        "id": "2J9Z",
        "text": "Antineutrophil cytoplasmic antibody-associated vasculitis"
    },
    {
        "id": "2K00",
        "text": "Congenital urinary meatus stricture"
    },
    {
        "id": "2K01",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K03",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K02",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K04",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J5H",
        "text": "Monkeypox"
    },
    {
        "id": "2K05",
        "text": "Fetus or newborn affected by placental insufficiency"
    },
    {
        "id": "2J9T",
        "text": "Intestinal infections due to Clostridioides difficile"
    },
    {
        "id": "2K06",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K07",
        "text": "Certain specified enthesopathies"
    },
    {
        "id": "2K09",
        "text": "Initial stage of trachoma"
    },
    {
        "id": "2K0A",
        "text": "Active stage of trachoma"
    },
    {
        "id": "2K08",
        "text": "Large cell small cell carcinoma of unspecified site"
    },
    {
        "id": "2K0B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0E",
        "text": "Squamous cell carcinoma of bladder"
    },
    {
        "id": "2K0G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0J",
        "text": "Interventions on heart valves"
    },
    {
        "id": "2K0K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0M",
        "text": "Staphylococcal infection of unspecified site"
    },
    {
        "id": "2K0N",
        "text": "Pregnancy, childbirth or the puerperium"
    },
    {
        "id": "2K0P",
        "text": "Chronic ischaemic heart disease"
    },
    {
        "id": "2K0Q",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0S",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0T",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K0U",
        "text": "Ankylosis of joint"
    },
    {
        "id": "2K0F",
        "text": "Adenocarcinoma of bladder"
    },
    {
        "id": "2K0W",
        "text": "Obesity class III BMI greater than or equal to 40"
    },
    {
        "id": "2K0Y",
        "text": "Hailey-Hailey disease"
    },
    {
        "id": "2K0Z",
        "text": "Diffuse epidermolytic palmoplantar keratoderma"
    },
    {
        "id": "2K10",
        "text": "Keratolytic winter erythema"
    },
    {
        "id": "2K11",
        "text": "Punctate palmoplantar keratoderma, type 2"
    },
    {
        "id": "2K12",
        "text": "Assessment of spine, not elsewhere classified"
    },
    {
        "id": "2K13",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K15",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K16",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K14",
        "text": "Drug-induced osteonecrosis"
    },
    {
        "id": "2K18",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K19",
        "text": "Adenocarcinoma of bronchus or lung"
    },
    {
        "id": "2K1A",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1B",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1C",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1D",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1E",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1F",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1I",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1J",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2J96",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1K",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1L",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K1M",
        "text": "Varus deformity, not elsewhere classified"
    },
    {
        "id": "2K1O",
        "text": "Obesity class III BMI greater than or equal to 40"
    },
    {
        "id": "2K1Q",
        "text": "Thalamus"
    },
    {
        "id": "2K1R",
        "text": "Left lower lobe bronchus"
    },
    {
        "id": "2K1S",
        "text": "Traumatic rupture of ligament of finger at metacarpophalangeal or interphalangeal joint"
    },
    {
        "id": "2K1U",
        "text": "Neck"
    },
    {
        "id": "2K1W",
        "text": "Encephalopathy due to carbon monoxide"
    },
    {
        "id": "2K1X",
        "text": "Frontal fibrosing alopecia"
    },
    {
        "id": "2K1Y",
        "text": "Loose anagen syndrome"
    },
    {
        "id": "2K1Z",
        "text": "Traction alopecia"
    },
    {
        "id": "2K21",
        "text": "Telogen effluvium"
    },
    {
        "id": "2K22",
        "text": "Ophiasis"
    },
    {
        "id": "2K23",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K24",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K26",
        "text": "Mendelson syndrome"
    },
    {
        "id": "2K27",
        "text": "Infantile apnoea"
    },
    {
        "id": "2K28",
        "text": "Type II leprosy reaction"
    },
    {
        "id": "2K2A",
        "text": "Degenerative condition of spine"
    },
    {
        "id": "2K2C",
        "text": "Adrenal vein"
    },
    {
        "id": "2K2D",
        "text": "Alveolar wall"
    },
    {
        "id": "2K2F",
        "text": "Ligaments and joints of multiple sites"
    },
    {
        "id": "2K2G",
        "text": "Fetus or newborn affected by chorionic villous sampling"
    },
    {
        "id": "2K2H",
        "text": "Acute epiglottitis"
    },
    {
        "id": "2K2I",
        "text": "Disorders of the hair follicle"
    },
    {
        "id": "2K2K",
        "text": "Relational"
    },
    {
        "id": "2K2L",
        "text": "Nape of neck"
    },
    {
        "id": "2K2M",
        "text": "Interscapular region"
    },
    {
        "id": "2K2N",
        "text": "Upper back"
    },
    {
        "id": "2K2O",
        "text": "Mid back"
    },
    {
        "id": "2K2P",
        "text": "Sacral region"
    },
    {
        "id": "2K2Q",
        "text": "Perianal region"
    },
    {
        "id": "2K2J",
        "text": "Laterality"
    },
    {
        "id": "2K2U",
        "text": "Upper cheek"
    },
    {
        "id": "2K2V",
        "text": "Upper cheek"
    },
    {
        "id": "2K2T",
        "text": "Temporal scalp"
    },
    {
        "id": "2K2S",
        "text": "Occipital scalp"
    },
    {
        "id": "2K2R",
        "text": "Posterior surface of ankle"
    },
    {
        "id": "2K2W",
        "text": "Invasive carcinoma of breast"
    },
    {
        "id": "2K2X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K2Y",
        "text": "Cataract"
    },
    {
        "id": "2K30",
        "text": "Malignant neoplasm metastasis in lymph nodes of head, face or neck"
    },
    {
        "id": "2K31",
        "text": "Vulvovaginal candidosis"
    },
    {
        "id": "2K32",
        "text": "Anaphylaxis grade 3"
    },
    {
        "id": "2K33",
        "text": "Anaphylaxis grade 1"
    },
    {
        "id": "2K34",
        "text": "Congenital cardiac tumor, not otherwise specified"
    },
    {
        "id": "2K35",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K36",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K37",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K39",
        "text": "Unintentional exposure to thermal mechanism"
    },
    {
        "id": "2K38",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K3A",
        "text": "Fracture of index finger"
    },
    {
        "id": "2K3B",
        "text": "Fracture of middle finger"
    },
    {
        "id": "2K3C",
        "text": "Fracture of ring finger"
    },
    {
        "id": "2K3D",
        "text": "Fracture of little finger"
    },
    {
        "id": "2H5A",
        "text": "Effects of heat"
    },
    {
        "id": "2K3H",
        "text": "Procedure for transgender reassignment"
    },
    {
        "id": "2K3I",
        "text": "Traumatic spondylolysis without slippage"
    },
    {
        "id": "2K3K",
        "text": "Interspinous ligament syndrome"
    },
    {
        "id": "2K3F",
        "text": "Infectious encephalitis not elsewhere classified"
    },
    {
        "id": "2K3N",
        "text": "Acute myocardial infarction, without specification of ST elevation"
    },
    {
        "id": "2K3O",
        "text": "Acute myocardial infarction, without specification of ST elevation"
    },
    {
        "id": "2K3Q",
        "text": "Old myocardial infarction"
    },
    {
        "id": "2K3P",
        "text": "Old myocardial infarction"
    },
    {
        "id": "2K3R",
        "text": "Autoimmune limbic encephalitis, Neural autoantibody positive"
    },
    {
        "id": "2K3S",
        "text": "mosaicism 45, x; 46, xx"
    },
    {
        "id": "2K3T",
        "text": "Anatomy and topography"
    },
    {
        "id": "2K3U",
        "text": "Symptoms, signs or clinical findings, not elsewhere classified"
    },
    {
        "id": "2K3V",
        "text": "Certain infectious or parasitic diseases"
    },
    {
        "id": "2K3W",
        "text": "Respiratory diseases principally affecting the lung interstitium"
    },
    {
        "id": "2H2C",
        "text": "Metastatic breast cancer"
    },
    {
        "id": "2K3X",
        "text": "Congestive heart failure NYHA Class I"
    },
    {
        "id": "2K3Y",
        "text": "Decreased cardiac dullness"
    },
    {
        "id": "2K3Z",
        "text": "Crohn disease of large intestine"
    },
    {
        "id": "2K40",
        "text": "Cyst of iris or ciliary body"
    },
    {
        "id": "2K41",
        "text": "Pseudomembranous colitis"
    },
    {
        "id": "2K42",
        "text": "Degeneration of iris or ciliary body"
    },
    {
        "id": "2K43",
        "text": "Endometriosis in caesarean scar"
    },
    {
        "id": "2K44",
        "text": "Endometriosis in cutaneous scar"
    },
    {
        "id": "2K45",
        "text": "Invasive neonatal candidosis"
    },
    {
        "id": "2K46",
        "text": "Neonatal mucocutaneous candidosis"
    },
    {
        "id": "2K47",
        "text": "Refractory cytopenia of childhood"
    },
    {
        "id": "2K48",
        "text": "Failure to deliver flow"
    },
    {
        "id": "2K49",
        "text": "Failure to infuse"
    },
    {
        "id": "2K4A",
        "text": "Inability to irrigate"
    },
    {
        "id": "2K4B",
        "text": "Grade 1 Category 3: Severe claudication"
    },
    {
        "id": "2K4C",
        "text": "Endometrioid carcinoma of ovary"
    },
    {
        "id": "2K4D",
        "text": "Malignant neoplasms of colon"
    },
    {
        "id": "2K4I",
        "text": "Acute myelomonocytic leukaemia"
    },
    {
        "id": "2K4G",
        "text": "Acute myeloid leukaemia with minimal differentiation"
    },
    {
        "id": "2K4F",
        "text": "Acute myeloid leukaemia without maturation"
    },
    {
        "id": "2K4E",
        "text": "Acute myeloid leukaemia with maturation"
    },
    {
        "id": "2K4H",
        "text": "Acute erythroid leukaemia"
    },
    {
        "id": "2K4J",
        "text": "Acute megakaryoblastic leukaemia"
    },
    {
        "id": "2K4L",
        "text": "Portal hypertension"
    },
    {
        "id": "2K4M",
        "text": "Hypertrophy of breast"
    },
    {
        "id": "2K4N",
        "text": "Gynaecomastia"
    },
    {
        "id": "2D96",
        "text": "Allergic or hypersensitivity conditions"
    },
    {
        "id": "2K4P",
        "text": "Postmenopausal endometrium"
    },
    {
        "id": "2K4Q",
        "text": "Endometriosis in caesarean scar"
    },
    {
        "id": "2K4R",
        "text": "Erythromelalgia"
    },
    {
        "id": "2K4S",
        "text": "Contracture of joint"
    },
    {
        "id": "2K3L",
        "text": "Squashed nose"
    },
    {
        "id": "2K3J",
        "text": "Iatrogenic spondylolysis without slippage"
    },
    {
        "id": "2K4U",
        "text": "Neonatal mucocutaneous candidosis"
    },
    {
        "id": "2K4V",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K4W",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K4X",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K4Y",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K4Z",
        "text": "Triplet pregnancy, affecting fetus or newborn"
    },
    {
        "id": "2I3V",
        "text": "Hydrops fetalis with anaemia secondary to haemolytic process"
    },
    {
        "id": "2J3Q",
        "text": "Diphtheria, pertussis, poliomyelitis, tetanus, hepatitis B vaccines"
    },
    {
        "id": "2K50",
        "text": "Tobacco use"
    },
    {
        "id": "2K51",
        "text": "Neutropaenia"
    },
    {
        "id": "2K53",
        "text": "Shigellosis due to Shigella flexneri"
    },
    {
        "id": "2K52",
        "text": "Shigellosis due to Shigella dysenteriae"
    },
    {
        "id": "2K54",
        "text": "Cervicocranial syndrome"
    },
    {
        "id": "2K55",
        "text": "Sarcocystosis"
    },
    {
        "id": "2K57",
        "text": "Vaping related disorder"
    },
    {
        "id": "2K58",
        "text": "Unexpected sudden death of infant"
    },
    {
        "id": "2K3E",
        "text": "Monkeypox"
    },
    {
        "id": "2K5A",
        "text": "Hypoleukocytosis"
    },
    {
        "id": "2K5B",
        "text": "Acute hereditary circumscribed oedema"
    },
    {
        "id": "2K5C",
        "text": "Central sleep apnoea due to a medical condition without Cheyne-Stokes breathing"
    },
    {
        "id": "2K5D",
        "text": "Intracerebrum"
    },
    {
        "id": "2K5E",
        "text": "Enteropathogenic Escherichia coli infection"
    },
    {
        "id": "2K5F",
        "text": "Fetus or newborn affected by noxious influences transmitted via placenta or breast milk"
    },
    {
        "id": "2K5G",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K5H",
        "text": "!!! Proposal attached to an already retired entity"
    },
    {
        "id": "2K5J",
        "text": "Genital warts"
    },
    {
        "id": "2K5K",
        "text": "Anogenital warts"
    },
    {
        "id": "2K5L",
        "text": "Staphylococcal ecthyma"
    },
    {
        "id": "2K5M",
        "text": "Type II leprosy reaction"
    },
    {
        "id": "2K5N",
        "text": "Acute rheumatic fever"
    },
    {
        "id": "2K5O",
        "text": "Extreme prematurity of newborn, gestational age 22 completed weeks"
    },
    {
        "id": "2K5P",
        "text": "Extreme prematurity of newborn, gestational age less than 22 completed weeks"
    },
    {
        "id": "2K5Q",
        "text": "Yaws"
    },
    {
        "id": "2K5R",
        "text": "Tertiary yaws"
    },
    {
        "id": "2K5S",
        "text": "Infections due to Chlamydia psittaci"
    },
    {
        "id": "2K5T",
        "text": "Epidemic louse-borne typhus fever due to Rickettsia prowazekii"
    },
    {
        "id": "2K5U",
        "text": "Meningitis due to Cryptococcus neoformans"
    },
    {
        "id": "2K5V",
        "text": "Bacterial myelitis"
    },
    {
        "id": "2K5W",
        "text": "Infectious abscess of the central nervous system"
    },
    {
        "id": "2K5X",
        "text": "Multiple or widespread intracranial abscess"
    },
    {
        "id": "2K5Y",
        "text": "Dengue with warning signs"
    }
]


