import React, {useEffect, useState, useRef} from "react";
import MainContent from "../../ui/MainContent";
import { getFromServer, postToServer } from "../../global/requests";
import user from "../../../assets/img/user.png"
import Error from "../../ui/error";
import Success from "../../ui/success";

const birthdayTypeCode = 2;

const SmsCompose = () => {
  // variables
  const [patientList, setPatientList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [msgSending, setMsgSending] = useState(false);
  const [receiverPatients, setReceiverPatients] = useState({});
  const [msgType, setMsgType] = useState(0);
  const [birthday, setBirthday] = useState("");
  const [alert, setAlert] = useState({show: false, status: false});
  const message = useRef();
  const checkAll = useRef();

  useEffect(() => {
    loadPatients();
  }, [msgType, birthday]);

  const loadPatients = async () => {
    setPatientList([]);
    let url = `sms/patient-list?msg_type=${msgType}`;
    if (msgType == birthdayTypeCode) {
      if(birthday == '' || birthday == null) return false;
      url += `&birthday=${birthday}`;
    }
    setLoading(true);
    const result = await getFromServer(url);
    if(result.status) {
      setPatientList(result.data);
    }
    setLoading(false);
  }

  const updateReceiverPatients = (patient, checked) => {
    if(checked) {
      setReceiverPatients({...receiverPatients, [patient.id]: patient})
    } else {
      delete receiverPatients[patient.id];
      setReceiverPatients({...receiverPatients});
    }
  }

  const removeReceiverPatients = (patientId) => {
    delete receiverPatients[patientId];
    setReceiverPatients({...receiverPatients});
  }

  const changeMsgType = (e) => {
    setMsgType(e.target.value);
    resetComposedMsg();
  }
  const changeBirthday = (e) => {
    setBirthday(e.target.value);
  }

  const resetComposedMsg = () => {
    setReceiverPatients({});
    message.current.value = "";
  }

  const sendMessage = async () => {
    closeAlert();
    const patientIds = Object.keys(receiverPatients);
    const msg = message.current.value = message.current.value.trim();
    if(patientIds.length == 0) setAlert({show: true, status: false, msg: "Please select atleast a patient."});
    else if(!msg) setAlert({show: true, status: false, msg: "Please enter message in message box."});
    else {
      setMsgSending(true);
      const data = {type: msgType, msg: msg, patient_ids: patientIds};
      const result = await postToServer(`sms/send-patient-message`, data);
      if(result.status) {
        resetComposedMsg();
        setAlert({show: true, status: true, msg: "Message sent successfully."});
      } else setAlert({show: true, status: false, msg: "Something went wrong."});
      setMsgSending(false);
    }
  }

  const closeAlert = () => setAlert({show: false, status: false, msg: ""});
  const changeCheckAll = (e) => {
    setReceiverPatients({});
    if(e.target.checked) {
      const receiverList = {};
      patientList.forEach(patient => {
        if(patient.phonenumber.length > 9) { receiverList[patient.id] = patient; }
      });
      setReceiverPatients(receiverList);
    }
  }

  return (
    <MainContent>
      <div className="section-body">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <div className="card">
              <div className="body">
                <div></div>
                <div id="mail-nav">
                  <h5 className="b-b p-10 text-strong">
                    Patients
                    <span style={{float: "right"}} onClick={loadPatients}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-ccw"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>
                    </span>
                  </h5>
                  {loading
                    ? <div className="mx-4 mb-2">Loading...</div>
                    : <>
                      <div className="mx-4 mb-2">
                        <select onChange={changeMsgType} value={msgType} className="form-control">
                          <option value="0">General Message</option>
                          <option value="2">Birthday Message</option>
                        </select>
                      </div>
                      <div className="mx-4 mb-2" style={{display: msgType == birthdayTypeCode ? 'block': 'none'}}>
                        <input type="date" onChange={changeBirthday} value={birthday} className="form-control" />
                      </div>
                      <div className="ml-4 checkbox-list mb-2">
                        {patientList.map((patient) => {
                          const checked = receiverPatients[patient.id] ? true : false;
                          const disabled = patient.phonenumber.length > 9 ? false : true;
                          return (
                            <div className="check-row">
                              <input
                                type="checkbox"
                                id={patient.id}
                                onChange={(e) => updateReceiverPatients(patient, e.target.checked)}
                                checked={checked}
                                disabled={disabled}
                              />
                              <label class="form" htmlFor={patient.id}>
                               {patient.firstname} {patient.surname}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
            <div className="card">
              <div className="boxs mail_listing">
                <div className="inbox-center table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th colspan="1">
                          <div className="inbox-header">
                            Compose New Message
                          </div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="composeForm">
                      {alert.show && !alert.status && <Error close={closeAlert}>{alert.msg}</Error>}
                      {alert.show && alert.status && <Success close={closeAlert}>{alert.msg}</Success>}
                      <div>
                        <label class="form">Selected Patients</label>
                        <div style={{float: "right", display: "flex"}}>
                          <input style={{zoom: '1.5'}} id="check-all" ref={checkAll} onChange={changeCheckAll} type="checkbox" />
                          &nbsp;<label htmlFor="check-all" style={{marginBottom: 0}}>All Patients</label>
                        </div>
                        <div style={{minHeight: '50px', maxHeight: '300px', overflow: 'auto'}}>
                          {Object.keys(receiverPatients).map((key) => {
                            const patient = receiverPatients[key];
                            return (
                              <div class="chip">
                                <img src={user} alt="Patient" />
                                {patient.firstname} {patient.surname} - {patient.phonenumber}
                                &nbsp;<i class="fas fa-trash" onClick={() => removeReceiverPatients(patient.id)}></i>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div>
                        <label class="form" htmlFor="msg-box">Message</label>
                        <textarea
                          className="form-control"
                          placeholder="Message"
                          maxLength="1000"
                          id="msg-box"
                          ref={message}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="m-l-25 m-b-20">
                      <button
                        type="button"
                        className="btn btn-info btn-border-radius waves-effect"
                        disabled={msgSending}
                        onClick={sendMessage}
                      >
                        {msgSending ? 'Sending...' : 'Send'}
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-danger btn-border-radius waves-effect"
                        onClick={resetComposedMsg}
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default SmsCompose;
