import React, { useState, useRef } from "react";
import MainContent from "../../ui/MainContent";
import PatientHistoryDetail from "../Doctor/PatientHistoryDetail";
// import axios from "axios";
import Error from "../../ui/error";
import { useNavigate } from "react-router-dom";
import { getFromServer } from "../../global/requests";


const ReviewBills = () => {
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [temp, setTemp] = useState();

  const [detail, setDetail] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [error, setError] = useState(false);
  const searchText = useRef();

  const searchHandler = async (e) => {
    e.preventDefault();
    if (searchText.current.value.length > 0) {
      const result = await getFromServer(`billing/review-bill/${searchText.current.value}`);
      if(result.status) {
        setIsSearch(result.data)
        console.log(result.data)
      }
      // if (searchText.current.value.includes(" ")) {
      // } else {
      //   setError(true);
      // }
    }
  };

  const reviewPageHandler = (list) => {
    navigate("/review-bill-detail", { state: { list: list } });
  };

  const backHandler = () => {
    setDetail(false);
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          {!detail && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4>Search</h4>
                      {error && (
                        <Error>Try to input surname and firstname both</Error>
                      )}
                      <p style={{ color: "gray" }}>
                        Search Patient With Surname and Firstname or PID number
                      </p>
                      <form
                        onSubmit={searchHandler}
                        method="GET"
                        className="card-header-form"
                        style={{ display: "flex" }}
                      >
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Search"
                          required
                          ref={searchText}
                        />
                        <button
                          type="submit"
                          style={{ color: "white" }}
                          className="btn btn-info ml-3"
                        >
                          Search
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <table
            className="table table-bordered"
          >
            <thead>
              <tr className="text-center" >
                <th scope="col">
                  S/N
                </th>
                <th scope="col" >
                  No
                </th>
                <th scope="col" >
                  Image
                </th>
                <th scope="col" >
                  Name
                </th>
                <th scope="col" >
                  Company
                </th>
                <th scope="col">
                  Request Date
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isSearch &&
                isSearch.map((l, i) => {
                  return (
                    <tr key={l.id} className="text-center" >
                      <th className="text-center">{i + 1}</th>
                      <td className="text-center">{l.encounter.patient.pid}</td>
                      <td>
                      <img
                        alt="image"
                        width="100px"
                        height="100px"
                        src={
                          window.baseURLuploaded +
                          l.encounter.patient.captureimage
                        }
                        className="img-thumbnail rounded"
                      />
                      </td>
                      <td className="text-center">
                      {l.encounter.patient.title}. {l.encounter.patient.surname}{" "}
                        {l.encounter.patient.firstname}
                      </td>
                      <td className="text-center">{l.encounter.patient.company}</td>
                      <td className="text-center">{l.date}</td>
                      <td><button className="btn btn-primary btn-sm" onClick={()=>reviewPageHandler(l)}>View</button></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
            </>
          )}
          {detail && (
            <PatientHistoryDetail
              back={backHandler}
              encounter_pid={temp.pid}
              patient_pid={temp.patient.pid}
            />
          )}
        </div>
      </MainContent>
    </>
  );
};

export default ReviewBills;
