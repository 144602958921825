import React, { useState, useEffect } from "react";
import MainContent from "../../ui/MainContent";
import { useParams, useSearchParams } from "react-router-dom";
// import axios from "axios";
import AddBilling from "../../modals/FrontDesk/AddBilling";
import { Link, useNavigate } from "react-router-dom";
import Success from "../../ui/success";
import AppointmentModal from "../../modals/FrontDesk/ApponitmentModal";
import EditPatientModal from "../../modals/FrontDesk/EditPatient";
import "../../../assets/css/qrcodeprint.css";
import Loading from "../../ui/Loading";
import { getFromServer, deleteFromServer } from "../../global/requests";
import Error from "../../ui/error";

const PatientDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [patientDetail, setPatientDetail] = useState();
  const [billing, setBilling] = useState();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [load, setLoad] = useState(true);
  const [success, setSuccess] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);

  const [error, setError] = useState(null);

  const handleCloseAppoint = () => setShowAppointment(false);
  const handleShowAppoint = () => setShowAppointment(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const successHandler = (bool) => setSuccess(bool);
  const updateBillingHandler = (list) => setBilling(list);

  const updatePatientHandler = (list) => setPatientDetail(list);

  const editPatientHandler = () => {
    navigate("/edit-patient", { state: { list: patientDetail } });
  };

  const selectHandler = () => {
    const activate = patientDetail.activation;
    var date = new Date();
    if (activate.length != 0) {
      var date = new Date();
      var oldDate = activate[0].date.split("-");
      if (parseInt(oldDate[1]) >= date.getMonth() + 1) {
        if (parseInt(oldDate[2]) >= date.getDate()) {
          navigate(`/encounter/${params.pid}`, {
            state: { patient: patientDetail },
          });
        } else {
          navigate(`/activation/${params.pid}`, {
            state: { patient: patientDetail },
          });
        }
      } else {
        navigate(`/activation/${params.pid}`, {
          state: { patient: patientDetail },
        });
      }
    } else {
      navigate(`/activation/${params.pid}`, {
        state: { patient: patientDetail },
      });
    }
  };

  useEffect(() => {
    doInitialTasks()
  }, [del]);

  const doInitialTasks = async () => {
    let qstring = '';
    if(searchParams.get("reg_sms_check") && searchParams.get("reg_sms_check") == '1') {
      qstring = '?reg_sms_check=1';
    }
    const result = await getFromServer(`main/frontdesk/patient/detail/${params.pid}/none${qstring}`);
    if (result.status) {
      setPatientDetail(result.data.p[0]);
      setBilling(result.data.b);
      setLoad(false);
      console.log(result.data);
      if(result.data.sms_info && !result.data.sms_info.status) {
        setError(result.data.sms_info.error);
      }
    }
  };

  const deleteHandler = async (id) => {
    if (window.confirm("Do you want to delete this profile?")) {
      const result = await deleteFromServer(`main/frontdesk/billing/${id}`);
      if(result.status) setDel((current) => !current);
    }
  };

  window.onbeforeprint = () => {
    document.getElementById('maincontent').classList.add('hide')
    document.getElementById('print-code').classList.remove('hide')
  };

  window.onafterprint = () => {
    document.getElementById('maincontent').classList.remove('hide')
    document.getElementById('print-code').classList.add('hide')
  };


  const printHandler = () => {
    document.getElementById('maincontent').classList.add('hide')
    document.getElementById('print-code').classList.remove('hide')
    window.print();
  };
  return (
    <>
      {patientDetail && <div className="qrcode-print-container hide text-center" id="print-code">
        <div
          className="text-center mt-top-print"
          // style={{ width: "18rem" }}
          id="qrcode"
        >
          <div className="card-body">
            {/* <h5 className="card-title" style={{fontSize:'30px'}}>Qr Code</h5> */}
            <p className="card-title text-nowrap fw-bold" style={{ fontSize: "25px" }}>
              {patientDetail.surname} {patientDetail.firstname}
            </p>
            <p className="card-title mt-3 fw-bold" style={{ fontSize: "30px" }}>
              {patientDetail.pid}
            </p>
          </div>
          <img
            src={window.baseURL + patientDetail.qrcode}
            className="card-img-top "
            style={{ width: "20rem" }}
            alt=""
          />
        </div>
      </div>}

      <MainContent>
        <div className="section-body" id="maincontent">
          {load && <Loading />}
          {patientDetail && (
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div
                  className="card text-center"
                  style={{ width: "18rem" }}
                  id="qrcode"
                >
                  <div className="card-body">
                    {/* <h5 className="card-title" style={{fontSize:'30px'}}>Qr Code</h5> */}
                    <p
                      className="card-title text-nowrap"
                      style={{ fontSize: "25px" }}
                    >
                      {patientDetail.surname} {patientDetail.firstname}
                    </p>
                    <p className="card-title" style={{ fontSize: "30px" }}>
                      {patientDetail.pid}
                    </p>
                  </div>
                  <img
                    src={window.baseURL + patientDetail.qrcode}
                    className="card-img-top "
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-4 ">
                <button
                  className="btn btn-success pull-right"
                  onClick={printHandler}
                >
                  Print
                </button>
              </div>
            </div>
          )}

          {patientDetail && (
            <div className="card author-box card-primary">
              <div className="card-body">
                {error && <Error close={() => setError(null)}>Registration SMS not sent due to error "{error}".</Error>}
                <div className="row" style={{ paddingRight: "10px" }}>
                  <div className="col-md-5">
                    <img
                      alt="image"
                      width="250px"
                      height="250px"
                      src={window.baseURLuploaded + patientDetail.captureimage}
                      className="img-thumbnail rounded"
                    />
                    <div className="tablexflow">
                      <table
                        className="table table-md mt-4 table-bordered"
                        // style={{ fontSize: "13px" }}
                      >
                        <tbody>
                          <tr>
                            <th>PID No</th>
                            <td>{patientDetail.pid}</td>
                          </tr>
                          <tr>
                            <th>Hospital ID</th>
                            <td>{patientDetail.hospitalid}</td>
                          </tr>
                          <tr>
                            <th>Surname</th>
                            <td>{patientDetail.surname}</td>
                          </tr>
                          <tr>
                            <th>First Name</th>
                            <td>{patientDetail.firstname}</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>{patientDetail.phonenumber}</td>
                          </tr>
                          <tr>
                            <th>Kin</th>
                            <td>{patientDetail.nextofkin}</td>
                          </tr>
                          <tr>
                            <th>Address</th>
                            <td>{patientDetail.address}</td>
                          </tr>
                          <tr>
                            <th>Origin</th>
                            <td>{patientDetail.stateoforigin}</td>
                          </tr>
                          <tr>
                            <th>Title</th>
                            <td>{patientDetail.title}</td>
                          </tr>
                          <tr>
                            <th>DOB</th>
                            <td>{patientDetail.dob}</td>
                          </tr>
                          <tr>
                            <th>Gender</th>
                            <td>{patientDetail.sex}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">
                          Billing Info
                          <button
                            className="btn btn-sm btn-primary pd ml-5"
                            onClick={handleShow}
                          >
                            Add
                          </button>
                        </h5>
                        <div className="tablexflow">
                          <table className="table responsive">
                            <thead>
                              <tr>
                                <th scope="col" width="5%">
                                  #
                                </th>
                                <th scope="col">Company</th>
                                <th scope="col">Plan</th>
                                <th scope="col">Profile No.</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {billing &&
                                billing.map((a, index) => {
                                  return (
                                    <tr key={a.id}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{a.company}</td>
                                      <td>{a.plan}</td>
                                      <td>{patientDetail.companyidnumber}</td>
                                      <td>
                                        <button
                                          className="btn btn-sm btn-danger"
                                          onClick={() => deleteHandler(a.id)}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {success && (
                        <Success>
                          Appointment has been added Successfully. Click
                          <Link to="/appointments" style={{ color: "blue" }}>
                            {" "}
                            here{" "}
                          </Link>{" "}
                          to check all appointments.
                        </Success>
                      )}
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <button
                          className="btn btn-primary"
                          onClick={editPatientHandler}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-primary ml-3"
                          onClick={selectHandler}
                        >
                          Select
                        </button>
                        <button
                          className="btn ml-3 btn-primary"
                          onClick={handleShowAppoint}
                        >
                          Appointment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <div className="col-md-12">
            <div className="card author-box card-primary">
              <div className="card-body">
                <div className="author-box-details">
                  <h2 className="mt-3">Upload Info</h2>
                  <p>No Information Uploaded</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </MainContent>
      {show && (
        <AddBilling
          show={show}
          close={handleClose}
          billinginfo={updateBillingHandler}
          patient_id={patientDetail.id}
        />
      )}
      {showEdit && (
        <EditPatientModal
          show={showEdit}
          close={handleCloseEdit}
          updatePatient={updatePatientHandler}
          data={patientDetail}
        />
      )}
      <AppointmentModal
        show={showAppointment}
        close={handleCloseAppoint}
        success={successHandler}
        pid={params.pid}
      />
    </>
  );
};

export default PatientDetail;
