import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import ViewAdminister from "../../modals/AdmissionMgt/ViewAdminister";

import Success from "../../ui/success";

import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer, postToServer } from "../../global/requests";

const DrugChart = (props) => {
  const param = useLocation();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [temp, setTemp] = useState();
  const [showAdminister, setShowAdminister] = useState(false);
  const [p, setData] = useState();
  const [fluid, setFluid] = useState();
  const [temp_is_fluid, setTemp_is_fluid] = useState();

  const [encounter, setEncounter] = useState();

  useEffect(() => {
    console.log(param.state.pid);
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/doctor/prescription/drugchart/${param.state.pid}`);
    if(result.status) {
      setEncounter(result.data.encounter);

      if (result.data.drugchart.length > 0) {
        setData(result.data.drugchart);
      }

      if (result.data.fluid.length > 0) {
        setFluid(result.data.fluid);
      }
    }
  }

  const qtyHandler = (e, i) => {
    const newVal = { ...p };
    let value = e.target.value;
    if (value == null || value == undefined || value == "") value = 0;
    newVal[0].prescriptionDetails_set[i].administerQuantity = value;
    setData(newVal);
  };

  const nextDosageHandler = (e, i) => {
    const newVal = { ...p };
    let value = e.target.value;
    if (value == null || value == undefined || value == "") value = null;
    newVal[0].prescriptionDetails_set[i].administer_next_dosage = value;
    setData(newVal);
  };

  const qtyFluidHandler = (e, i) => {
    const newVal = { ...fluid };
    let value = e.target.value;
    if (value == null || value == undefined || value == "") value = 0;
    newVal[0].doctor_fluid[i].administerQuantity = value;
    console.log(newVal)
    setFluid(newVal);
  };

  const handleShowAdminister = (pres_detail_id, isFluid) => {
    setTemp(
      // `${window.baseURL}main/doctor/drugchart-administer-detail/${pres_detail_id}/${isFluid}`
      `main/doctor/drugchart-administer-detail/${pres_detail_id}/${isFluid}`
    );
    setShowAdminister(true);
  };


  const handleCloseAdminister = () => {
    setShowAdminister(false);
    setTemp("");
  };


  const dispenseHandler = async () => {

    let data = {}
    if (fluid && p){
      data = {
        dic:p,
        fluid:fluid
      };
    }
    else if (fluid && !p){
      data = {
        dic:null,
        fluid:fluid
      };
    }
    else{
      data = {
        fluid: null,
        dic:p,
      };
    }

    const result = await postToServer(`main/doctor/drugchart-administer`, { data });
    if(result.status) {
      console.log(result);
      setSuccess(true)
      // navigate("/injection");
    }
  };


  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            {encounter && (
              <PatientDetailCard list={encounter} isEncounter={true} />
            )}
            {success && <Success clos={()=>setSuccess(false)}>Administered Successfully</Success>}
            <div className="tablexflow">
              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    <th>{p && p[0].date}</th>
                    <th scope="col">Route</th>
                    <th scope="col">Dosage</th>
                    <th scope="col">Frequency</th>
                    <th scope="col">Period</th>
                    <th scope="col">Total</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Prescribed by</th>
                    <th scope="col">Next Dosage</th>
                    {/* <th scope="col">Dipensed Record</th> */}
                    <th scope="col">Administered Quantity</th>
                    {/* <th scope="col">Cost</th> */}
                    <th scope="col">Administered Record</th>
                  </tr>
                </thead>
                <tbody>
                  {p &&
                    p[0].prescriptionDetails_set.map((a, index) => {
                      return (
                        <tr key={a.id}>
                          <td>
                            {a.prescription_details_tag_set.map((t) => {
                              return <>{t.drugtags.brandname} -</>;
                            })}
                          </td>
                          <td>{a.route}</td>
                          <td>{a.dosage}</td>
                          <td>{a.frequency}</td>
                          <td>
                            {a.period}
                            {" / " + a.none}
                          </td>
                          <td>{a.total}</td>
                          <td>{a.comment}</td>
                          <td>{a.prescribed_by_first_name} {a.prescribed_by_last_name}</td>
                          
                          <td>
                            <input
                              type="datetime-local"
                              name="next_dosage"
                              className="form-control w-80"
                              value={a.administer_next_dosage && a.administer_next_dosage.replace("Z", "")}
                              onChange={(e) => nextDosageHandler(e, index)}
                            />
                          </td>

                          <td>
                            <input
                              type="text"
                              name="qty"
                              className="form-control w-50"
                              value={a.administerQuantity}
                              onChange={(e) => qtyHandler(e, index)}
                            />
                          </td>

                          <td>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              onClick={() => handleShowAdminister(a.id, 0)}
                            >
                              View Record
                            </button>
                          </td>


                          {/* <td>{a.dispensedquantity}</td> */}
                          {/* <td>
                            {a.prescription_details_tag_set[0].drugtags.cost}
                          </td> */}
                        </tr>
                      );
                    })}

                  {fluid &&
                    fluid[0].doctor_fluid.map((a, index) => {
                      return (
                        <tr>
                          <td>
                            {a.fluid.fluid}
                            {/* <span className="float-end">
                              {a.fluid.is_paid == true ? (
                                <button className="btn btn-success btn-sm">
                                  Confirmed
                                </button>
                              ) : (
                                <button className="btn btn-danger btn-sm">
                                  Not confirmed
                                </button>
                              )}
                            </span> */}
                          </td>
                          <td>{a.dosage}</td>
                          <td>{a.frequency}</td>
                          <td>
                            {a.period}
                            {" / " + a.none}
                          </td>
                          <td>{a.total}</td>
                          <td>{a.comment}</td>
                          <td></td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              onClick={() => handleShowAdminister(a.id, 1)}
                            >
                              View Record
                            </button>
                          </td>

                          <td>
                            <input
                              type="text"
                              name="qty"
                              className="form-control w-50"
                              value={a.administerQuantity}
                              onChange={(e) => qtyFluidHandler(e, index)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="text-center mt-3">
              {/* <button className="btn btn-primary" onClick={dispenseHandler}>
                Dispense
              </button> */}
            </div>
            <div className="text-center mt-3">
              <button className="btn btn-primary" onClick={dispenseHandler}>
                Administer
              </button>
            </div>
          </div>
        </div>
      </MainContent>

      {showAdminister && (
        <ViewAdminister
          show={showAdminister}
          close={handleCloseAdminister}
          url={temp}
        />
      )}
    </>
  );
};

export default DrugChart;
