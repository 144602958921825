import React, { useEffect, useState, useRef } from "react";
import MainContent from "../../ui/MainContent";
import { getFromServer, downloadFileFromUrl } from "../../global/requests";
import Loading from "../../ui/Loading";
import DateRangeWidget from "../../widgets/reports/DateRangeWidget";

const PaymentReport = () => {
  // variables
  const [rows, setRows] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const company = useRef();

  useEffect(() => {
    loadCompanies();
  }, []);

  const loadCompanies = async () => {
    const result = await getFromServer(`reports/company-list`);
    if (result.status) setCompanies(result.data.rows);
  }
  const loadListData = async () => {
    if(dateFrom != "" && dateTo != "") {
      const company_id = company.current.value;
      setIsLoading(true);
      const result = await getFromServer(`reports/payment?date_from=${dateFrom}&date_to=${dateTo}&company_id=${company_id}`);
      if (result.status) {
        setRows(result.data.rows);
        setColumns(result.data.columns);
      } else {
        alert("Something went wrong.");
        setRows([]);
      }
      setIsLoading(false);
    } else alert("Please pick Date From and Date To to generate report");
  };
  const exportToExcel = async () => {
    if(dateFrom != "" && dateTo != "") {
      const company_id = company.current.value;
      setDownloading(true);
      await downloadFileFromUrl(
        `reports/payment?download=1&date_from=${dateFrom}&date_to=${dateTo}&company_id=${company_id}`,
        "payment-report.xlsx"
      );
      setDownloading(false);
    } else alert("Please pick Date From and Date To to generate report");
  };

  const filterHandler = () => loadListData();

  return (
    <MainContent>
      <div className="section-body">
        <div className="row mb-2">
          <h3>Payment Report</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body row">
                <DateRangeWidget dateFrom={dateFrom} dateTo={dateTo} setDateFrom={setDateFrom} setDateTo={setDateTo} />
                <div className="col-3">
                  <label htmlFor="company">Company</label>
                  {companies ?
                  <select ref={company} id="company" className="form-control">
                    <option value="0">All companies</option>
                    {companies.map(record => <option value={record[0]}>{record[1]}</option>)}
                  </select>
                  : <select ref={company} id="company" className="form-control"></select>}
                </div>
                <div className="filter-btn-box">
                  <button className="btn btn-primary" onClick={filterHandler}>
                    Filter
                  </button>
                </div>
                <div className="filter-btn-box">
                  {downloading ? (
                    <button className="btn btn-primary" onClick={exportToExcel} disabled>
                      <i className="fas fa-spinner fa-spin"></i>
                      &nbsp;Exporting...
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={exportToExcel}>
                      Export
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {isLoading ? (
                  <div style={{ margin: "100px 0" }}>
                    <Loading />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-bordered table-md report">
                      <tr>
                        <th>#</th>
                        <th>{columns[1]}</th>
                        <th>{columns[2]}</th>
                        <th>{columns[3]}</th>
                        <th>{columns[4]}</th>
                        <th>{columns[5]}</th>
                        <th>{columns[6]}</th>
                        <th>{columns[7]}</th>
                        <th>{columns[8]}</th>
                        <th>{columns[9]}</th>
                      </tr>
                      {rows.map((row, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{row[1]}</td>
                          <td>{row[2]}</td>
                          <td>{row[3]}</td>
                          <td>{row[4]}</td>
                          <td>{row[5]}</td>
                          <td>{row[6]}</td>
                          <td>{row[7]}</td>
                          <td>{row[8]}</td>
                          <td>{row[9]}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                )}
              </div>
              {/* <div className="card-footer text-right"></div> */}
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  )
}

export default PaymentReport;