import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useNavigate, useLocation } from "react-router-dom";
import EditReviewBill from "../../modals/Billing/EditReviewBill";
import Auto from "./Auto/AutoItems";
import Loading from "../../ui/Loading";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer, postToServer, deleteFromServer } from "../../global/requests";


const ReviewDetail = () => {
  const param = useLocation();
  const navigate = useNavigate();
  const [p, setP] = useState();
  const [tempList, setTempList] = useState();
  const [tempname, setTempName] = useState();
  const [index, setIndex] = useState();
  const [total, setTotal] = useState();
  const [allItems, setAllItems] = useState();
  const [insurance, setInsurance] = useState();
  const [show, setShow] = useState(false);
  const [addItem, setAddItem] = useState(false);
  const [tags, setTags] = useState([]);
  const [save, setSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addQty = useRef();

  const getTags = (e) => {
    setTags(e);
  };

  useEffect(() => {
    setP(param.state.list);
    const cp = param.state.list.encounter.profile.split("=>");
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/all-items`);
    if(result.status) setAllItems(result.data);
  }

  setTimeout(() => {
    let elements = document.getElementsByClassName("total");
    let value = 0;
    for (let i = 0; i < elements.length; i++) {
      value += parseInt(elements[i].textContent);
    }
    setTotal(value);
    elements = document.getElementsByClassName("insurance");
    value = 0;
    for (let i = 0; i < elements.length; i++) {
      value += parseInt(elements[i].textContent);
    }
    setInsurance(value);
  }, 500);

  const updateHandler = (dic) => {
    setP(dic);
  };

  const submithandler = async (e) => {
    e.preventDefault();

    const data = {
      tag: tags,
      qty: addQty.current.value,
      encounter: p.encounter.id,
      cashierId: param.state.list.id,
    };
    const result = await postToServer(`billing/all-items`, { data });
    if(result.status) {
      setAddItem(false);
      setP(result.data);
      // setSuccess(true);
      // console.log(res.data)
    }
  };

  const editHandler = (list, name, i) => {
    setTempList(list);
    setTempName(name);
    setIndex(i);
    setShow(true);
  };

  const deleteHandler = async (list, name) => {
    if (window.confirm("Do you want to delete this item?")) {
      const data = name + "_" + p.id + "_" + list.id;
      const result = await deleteFromServer(`billing/review-bill/${data}`);
      if(result.status) setP(result.data);
    }
  };

  const printHandler = async () => {
    setIsLoading(true);
    const data = {
      list: p,
    };
    const result = await postToServer(`billing/cashier-print`, { data });
    if(result.status) {
      setIsLoading(false);
      navigate("/bill-page", { state: { list: result.data } });
      console.log(result.data);
    }
  };

  const viewEncounter = () => {};
  return (
    <>
      <MainContent>
        <div className="row">
          <h2 className="mb-4">Review Bills</h2>
          <div className="col-12">
            {p && (
              <div className="section-body">
              <PatientDetailCard list={p.encounter} isEncounter={true} />

                {isLoading && <Loading />}
                <div className="mb-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => setAddItem(!addItem)}
                  >
                    Add Items
                  </button>
                  <button
                    className="btn btn-primary ml-2"
                    onClick={printHandler}
                  >
                    View Bill
                  </button>
                  {addItem && (
                    <>
                      <div className="row">
                        <div className="col-md-4">
                          <label>Items</label>
                          <Auto
                            options={allItems}
                            tag={(e) => getTags(e)}
                            removeTag={save}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Qty</label>
                          <input
                            className="form-control"
                            type="number"
                            ref={addQty}
                          />
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={submithandler}
                      >
                        Add
                      </button>
                    </>
                  )}
                </div>
                <div>
                  <table className="table table-md table-bordered">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Sub-Total</th>
                        <th>Insurance</th>
                        <th>Sub-total less Ins.</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {p.service_cash &&
                        p.service_cash.map((s, i) => {
                          return (
                            <tr>
                              <td>{s.service.name}</td>
                              <td>{s.price}</td>
                              <td>{s.qty}</td>
                              <td className="total">{s.price * s.qty}</td>
                              <td className="insurance">
                                {s.qty *
                                  (s.price -
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100))}
                              </td>
                              <td>
                                {s.qty *
                                  (s.price -
                                    (s.price * s.planitem.pt_percentage) / 100)}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm mr-2"
                                  onClick={() => deleteHandler(s, "service")}
                                >
                                  Delete
                                </button>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => editHandler(s, "service", i)}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      {p.test_cash &&
                        p.test_cash.map((s, i) => {
                          return (
                            <tr>
                              <td>{s.test.testname}</td>
                              <td>{s.price}</td>
                              <td>{s.qty}</td>
                              <td className="total">{s.price * s.qty}</td>
                              <td className="insurance">
                                {s.qty *
                                  (s.price -
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100))}
                              </td>
                              <td>
                                {s.qty *
                                  (s.price -
                                    (s.price * s.planitem.pt_percentage) / 100)}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm mr-2"
                                  onClick={() => deleteHandler(s, "test")}
                                >
                                  Delete
                                </button>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => editHandler(s, "test", i)}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      {p.room_cash &&
                        p.room_cash.map((s, i) => {
                          return (
                            <tr>
                              <td>{s.room.room}</td>
                              <td>{s.price}</td>
                              <td>{s.qty}</td>
                              <td className="total">{s.price * s.qty}</td>
                              <td className="insurance">
                                {s.qty *
                                  (s.price -
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100))}
                              </td>
                              <td className="">
                                {s.qty *
                                  (s.price -
                                    (s.price * s.planitem.pt_percentage) / 100)}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm mr-2"
                                  onClick={() => deleteHandler(s, "room")}
                                >
                                  Delete
                                </button>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => editHandler(s, "room", i)}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      {p.procedure_cash &&
                        p.procedure_cash.map((s, i) => {
                          return (
                            <tr>
                              <td>{s.procedure.procedure}</td>
                              <td>{s.price}</td>
                              <td>{s.qty}</td>
                              <td className="total">{s.price * s.qty}</td>
                              <td className="insurance">
                                {s.qty *
                                  (s.price -
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100))}
                              </td>
                              <td className="">
                                {s.qty *
                                  (s.price -
                                    (s.price * s.planitem.pt_percentage) / 100)}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm mr-2"
                                  onClick={() => deleteHandler(s, "procedure")}
                                >
                                  Delete
                                </button>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => editHandler(s, "procedure", i)}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      {p.drug_cash &&
                        p.drug_cash.map((s, i) => {
                          return (
                            <tr>
                              <td>{s.drug.brandname}</td>
                              <td>{s.price}</td>
                              <td>{s.qty}</td>
                              <td className="total">{s.price * s.qty}</td>
                              <td className="insurance">
                                {s.qty *
                                  (s.price -
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100))}
                              </td>
                              <td className="">
                                {s.qty *
                                  (s.price -
                                    (s.price * s.planitem.pt_percentage) / 100)}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm mr-2"
                                  onClick={() => deleteHandler(s, "drug")}
                                >
                                  Delete
                                </button>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => editHandler(s, "drug", i)}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      {p.fluid_cash &&
                        p.fluid_cash.map((s, i) => {
                          return (
                            <tr>
                              <td>{s.fluid.fluid}</td>
                              <td>{s.price}</td>
                              <td>{s.qty}</td>
                              <td className="total">{s.price * s.qty}</td>
                              <td className="insurance">
                                {s.qty *
                                  (s.price -
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100))}
                              </td>
                              <td className="">
                                {s.qty *
                                  (s.price -
                                    (s.price * s.planitem.pt_percentage) / 100)}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm mr-2"
                                  onClick={() => deleteHandler(s, "fluid")}
                                >
                                  Delete
                                </button>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => editHandler(s, "fluid", i)}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      {p.consumable_cash &&
                        p.consumable_cash.map((s, i) => {
                          return (
                            <tr>
                              <td>{s.consumable.name}</td>
                              <td>{s.price}</td>
                              <td>{s.qty}</td>
                              <td className="total">{s.price * s.qty}</td>
                              <td className="insurance">
                                {s.qty *
                                  (s.price -
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100))}
                              </td>
                              <td className="">
                                {s.qty *
                                  (s.price -
                                    (s.price * s.planitem.pt_percentage) / 100)}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm mr-2"
                                  onClick={() => deleteHandler(s, "consumable")}
                                >
                                  Delete
                                </button>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    editHandler(s, "consumable", i)
                                  }
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      <tr>
                        <th colSpan="5">Total Sum</th>
                        <td>{total}</td>
                      </tr>
                      <tr>
                        <th colSpan="5">Insurance</th>
                        <td>{insurance}</td>
                      </tr>
                      <tr>
                        <th colSpan="5">Sum to Pay</th>
                        <td>{total - insurance}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </MainContent>
      {show && (
        <EditReviewBill
          show={show}
          close={handleClose}
          // success={successHandler}
          update={updateHandler}
          list={tempList}
          name={tempname}
          id={p.id}
        />
      )}
    </>
  );
};

export default ReviewDetail;
