import React, { useEffect, useRef, useState } from "react";
import MainContent from "../../ui/MainContent";
import { getFromServer, downloadFileFromUrl } from "../../global/requests";
import Loading from "../../ui/Loading";
import { genReportPagination } from "../../helpers/report-helpers";
import DateRangeWidget from "../../widgets/reports/DateRangeWidget";
import ReportHead from "../../widgets/reports/ReportHead";

var oldQueryString = "";
const WardReport = () => {
  // variables
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [search, setSearch] = useState("");
  const [noOfRow, setNoOfRow] = useState(25);
  const [pageNo, setPageNo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageBtns, setPageBtns] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [stations, setStations] = useState([]);
  const station = useRef();

  useEffect(() => {
    loadListData();
    loadStations();
  }, [noOfRow, pageNo]);

  const loadStations = async () => {
    const result = await getFromServer(`reports/nursing-station-list`);
    if (result.status) setStations(result.data.rows);
    console.log(result.data.rows);
  }

  const loadListData = async () => {
    const qString = `&search=${search}&no_of_row=${noOfRow}&nursing_Station=${station.current.value}`;
    if (oldQueryString != qString) {
      oldQueryString = qString;
      if (pageNo != 0) {
        setPageNo(0);
        return;
      }
    }
    setIsLoading(true);
    const result = await getFromServer(
      `reports/ward?offset=${pageNo * noOfRow}${qString}`
    );
    if (result.status) {
      setRows(result.data.rows);
      setColumns(result.data.columns);
      setCount(result.data.count);
      setPageBtns(genReportPagination(result.data.count, noOfRow, pageNo, setPageNo));
    }
    setIsLoading(false);
  };
  const exportToExcel = async () => {
    setDownloading(true);
    await downloadFileFromUrl(
      `reports/ward?download=1&search=${search}&nursing_Station=${station.current.value}`,
      "ward-report.xlsx"
    );
    setDownloading(false);
  };
  const filterHandler = () => loadListData();

  return (
    <MainContent>
      <div className="section-body">
        <div className="row mb-2">
          <h3>Ward Report</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body row">
                <div className="col-3">
                  <label htmlFor="station">Station</label>
                  {stations ?
                  <select ref={station} id="station" className="form-control">
                    <option value="0">All stations</option>
                    {stations.map(record => <option value={record[0]}>{record[1]}</option>)}
                  </select>
                  : <select ref={station} id="station" className="form-control"></select>}
                </div>
                <div className="filter-btn-box">
                  <button className="btn btn-primary" onClick={filterHandler}>
                    Filter
                  </button>
                </div>
                <div className="filter-btn-box">
                  {downloading ? (
                    <button className="btn btn-primary" onClick={exportToExcel} disabled>
                      <i className="fas fa-spinner fa-spin"></i>
                      &nbsp;Exporting...
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={exportToExcel}>
                      Export
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <ReportHead search={search} setSearch={setSearch} noOfRow={noOfRow} setNoOfRow={setNoOfRow} loadListData={loadListData}  />
              <div className="card-body">
                {isLoading ? (
                  <div style={{ margin: "100px 0" }}>
                    <Loading />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-bordered table-md report">
                      <tr>
                        <th>#</th>
                        <th>{columns[1]}</th>
                        <th>{columns[2]}</th>
                        <th>{columns[3]}</th>
                        <th>{columns[4]}</th>
                        <th>{columns[5]}</th>
                      </tr>
                      {rows.map((row, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{row[1]}</td>
                          <td>{row[2]}</td>
                          <td>{row[3]}</td>
                          <td>{row[4]}</td>
                          <td>{row[5]}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                )}
                <div className="table-pagination">
                  <p>
                    Showing {noOfRow * pageNo + 1} to{" "}
                    {noOfRow * pageNo + rows.length} of {count} entries
                  </p>
                  <nav className="d-inline-block">
                    <ul className="pagination mb-0">{pageBtns}</ul>
                  </nav>
                </div>
              </div>
              {/* <div className="card-footer text-right"></div> */}
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  )
}

export default WardReport;