import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, postToServer, deleteFromServer } from "../../global/requests";

const DrugCategoryType = (props) => {
  const [showCat, setShowCat] = useState(false);

  const [dcat, setDCat] = useState();

  const category = useRef();

  const handleShowCat = () => setShowCat((current) => !current);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/add/drugcategory`);
    if(result.status) setDCat(result.data);
  }

  const categorySubmitHandler = async () => {
    const data = {
      category: category.current.value,
    };

    const result = await postToServer(`pharmacy/drugmgt/add/drugcategory`, { data });
    if(result.status) {
      setShowCat(false);
      setDCat(result.data);
    }
  };

  const deleteCategoryHandler = async (id) => {
    if (window.confirm("Do you want to delete This Category?")) {
      const result = await deleteFromServer(`pharmacy/drugmgt/drugcategory/${id}`);
      if(result.status) setDCat(result.data);
    }
  };

  return (
    <>
      <button
        className="btn btn-sm btn-outline-success mb-3"
        onClick={props.back}
      >
        Back
      </button>
      <div className="row">
        <div className="col-12">
          <button className="btn btn-primary mb-4" onClick={handleShowCat}>
            Add Category
          </button>
          {showCat && (
            <div>
              <input
                type="text"
                className="form-control mb-2"
                ref={category}
                placeholder="Add Drugs Category"
              />
              <button
                className="btn btn-primary mb-2"
                onClick={categorySubmitHandler}
              >
                Add
              </button>
            </div>
          )}

          <table className="table responsive">
            <thead>
              <tr style={{ fontSize: "13px" }}>
                <th scope="col">#</th>
                <th scope="col">Category Name</th>
                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {dcat &&
                dcat.map((a, index) => {
                  return (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{a.category}</td>
                      <td className="text-center">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => deleteCategoryHandler(a.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DrugCategoryType;
