import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cookies from "js-cookie";

const TransferPatient = (props) => {
  const [department, setDepartment] = useState();
  const type = useRef();

  useEffect(() => {
    const url = `${window.baseURL}usermgt/useraccess-clinical`;
    axios.get(url).then((res) => {
      console.log(res);
      setDepartment(res.data);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      id: props.id,
      disengage: props.disengageid,
    };

    const url = `${window.baseURL}main/frontdesk/encounter-transfer/${type.current.value}`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        props.close();
      });
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Patient Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label>Departments</label>
                    <select className="form-select" ref={type}>
                      {department &&
                        department.map((u) => {
                          return (
                            <option key={u.id} value={u.department}>
                              {u.department}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Transfer
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransferPatient;
