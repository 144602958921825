import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import ObservationChart from "./ObservationChart";
import NurseReport from "./NurseReport";
import NurseCarePlan from "./NurseCarePlan";
import NursingAssessment from "./NursingAssessment";
import Note from "./Note";

import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer } from "../../global/requests";


const NursingCare = () => {
  const param = useLocation();
  const [p, setData] = useState();
  const [onservation, setObservation] = useState(false);
  const [assessment, setAssessment] = useState(false);
  const [report, setReport] = useState(false);
  const [careplan, setCareplan] = useState(false);
  const [note, setNote] = useState(false);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/frontdesk/encounter/${param.state.pid}`);
    if(result.status) setData(result.data);
  }

  return (
    <>
      <MainContent>
        {p && (
          <div className="section-body">
            <PatientDetailCard list={p} isEncounter={true} />

          </div>
        )}
        <div className="row">
          <div className="card ">
            <div className="card-header">
              <ul
                className="nav nav-tabs card-header-tabs pull-right"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="assessment-tab"
                    data-toggle="tab"
                    href="#assessment"
                    role="tab"
                    aria-selected="true"
                    onClick={() => setAssessment(true)}
                  >
                    Nursing Assessment Sheet
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="care-tab"
                    data-toggle="tab"
                    href="#care"
                    role="tab"
                    aria-selected="true"
                    onClick={() => setCareplan(true)}
                  >
                    Nursing Care Plan
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="report-tab"
                    data-toggle="tab"
                    href="#report"
                    role="tab"
                    aria-selected="true"
                    onClick={() => setReport(true)}
                  >
                    Daily Nursing Report
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="observation-tab"
                    data-toggle="tab"
                    href="#observation"
                    role="tab"
                    aria-selected="true"
                    onClick={() => setObservation(true)}
                  >
                    Observation Chart
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="note-tab"
                    data-toggle="tab"
                    href="#note"
                    role="tab"
                    aria-selected="true"
                    onClick={() => setNote(true)}
                  >
                    Note
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="assessment"
                role="tabpanel"
              >
                {assessment && <NursingAssessment encounter_pid={param.state.pid} />}
              </div>
              <div className="tab-pane fade" id="care" role="tabpanel">
                {careplan && <NurseCarePlan encounter_pid={param.state.pid} />}
              </div>
              <div className="tab-pane fade" id="report" role="tabpanel">
                {report && <NurseReport encounter_pid={param.state.pid} />}
              </div>
              <div className="tab-pane fade" id="observation" role="tabpanel">
                {onservation && (
                  <ObservationChart encounter_pid={param.state.pid} />
                )}
              </div>
              <div className="tab-pane fade" id="note" role="tabpanel">
                {note && <Note encounter_pid={param.state.pid} />}
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default NursingCare;
