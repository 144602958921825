import React from "react";

const PrintLabResults = (props) => {
  const list = props.list;
  var company = "";
  if (list) {
    company = list.encounter.profile.split("=")[0];
  }
  return (
    <>
      {list && (
        <div style={{ marginTop: "40px", padding: "0px" }} id="claim-form">
          <h1 className="text-center text-uppercase">
            
            {/* Hetta Medical Center*/ }
            <h1>TIP-TIP SOLUTION HOSPITAL</h1>
            <h5>5, Kwaru way, Custom Bus Stop, Abaranje Road, Ikotun - Lagos</h5>
            <h4 className="mt-4">With Laboratory & Denial Services</h4>
          </h1>
          {/* <div className="row mt-5 border border-dark">
            <div className="col-md-6">
              <h4>Patient Details</h4>
            </div>
            <div className="col-md-6 text-end">
              <h4>Service Provider Details</h4>
            </div>
          </div> */}
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4 text-nowrap">
                  <label>PATIENT NAME: </label>
                </div>
                <div className="col-md-1 text-nowrap"> </div>
                <div className="col-md-7 bottom-border">
                  <span>
                    {list.encounter.patient.surname}{" "}
                    {list.encounter.patient.firstname}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-nowrap">
                  <label>BATCH FILE NO.: </label>
                </div>
                <div className="col-md-1 text-nowrap"> </div>
                <div className="col-md-7 bottom-border">
                  <span></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-nowrap">
                  <label>{company}</label>
                </div>
                <div className="col-md-1 text-nowrap"> </div>
                <div className="col-md-7 bottom-border">
                  <span></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-nowrap">
                  <label>HOSPITAL NO:</label>
                </div>
                <div className="col-md-1 text-nowrap"> </div>
                <div className="col-md-7 bottom-border">
                  <span>{list.encounter.patient.hospitalid}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-nowrap">
                  <label>REQUEST CODE:</label>
                </div>
                <div className="col-md-1 text-nowrap"> </div>
                <div className="col-md-7 bottom-border">
                  <span>{list.id}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-nowrap">
                  <label>REQUEST MADE BY: </label>
                </div> 
                <div className="col-md-1 text-nowrap"> </div>
                <div className="col-md-7 bottom-border">
                  <span></span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5 text-nowrap">
                  <label>AGE: </label>
                </div>
                <div className="col-md-7 bottom-border">
                  <span></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 text-nowrap">
                  <label>SEX: </label>
                </div>
                <div className="col-md-7 bottom-border">
                  <span>{list.encounter.patient.sex}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 text-nowrap">
                  <label>DATE REQUESTED:</label>
                </div>
                <div className="col-md-7 bottom-border">
                  <span>{list.date}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 text-nowrap">
                  <label>DATE ANALYZED: </label>
                </div>
                <div className="col-md-7 bottom-border">
                  <span></span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 text-nowrap">
                  <label>RADIOGRAPHER: </label>
                </div>
                <div className="col-md-7 bottom-border">
                  <span></span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <table className="table table-sm" id="labresultprint" style={{border:"2px solid black"}}>
              <thead>
                <tr className="" style={{border:"1px solid black"}}>
                  <th style={{border:"1px solid black"}} width="5%">S/N</th>
                  <th style={{border:"1px solid black"}} colSpan="2">Investigation</th>
                  <th style={{border:"1px solid black"}}>Result</th>
                  {/* <th></th> */}
                  {/* <th>Reference Range</th> */}
                </tr>
              </thead>
              <tbody>
                {list.investigationlab.map((t, i) => {
                  return (
                    <>
                      {t.test.is_single_test ? (
                        <>
                          {t.status == "Done" ? (
                            <tr style={{border:"1px solid black"}}>
                              <td style={{border:"1px solid black"}}>{i + 1}</td>
                              <td style={{border:"1px solid black"}} colSpan="2">{t.test.testname}</td>
                              <td style={{border:"1px solid black"}} className="fw-bolder">
                                <div
                                  dangerouslySetInnerHTML={{ __html: t.value }}
                                />
                              </td>
                              {/* <td>{t.status}</td> */}
                            </tr>
                          ) : (
                            ""
                          )}{" "}
                        </>
                      ) : (
                        <>
                          {t.status == "Done" ? (
                            <>
                              <tr>
                                <td style={{border:"1px solid black"}}>{i + 1}</td>
                                <td style={{border:"1px solid black"}} colSpan="3">{t.test.testname}</td>
                                {/* <td>{t.status}</td> */}
                              </tr>
                              {t.investigationlabtest.map((ut, j) => {
                                return (
                                  <>
                                    <tr style={{border:"1px solid black"}}>
                                      <td style={{border:"1px solid black"}}></td>
                                      <td style={{border:"1px solid black"}}></td>
                                      <td style={{border:"1px solid black"}}>{ut.test.testname}</td>
                                      <td style={{border:"1px solid black"}} className="fw-bolder">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: ut.value,
                                          }}
                                        />
                                      </td>
                                      {/* <td></td> */}
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default PrintLabResults;
