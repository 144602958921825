import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import Success from "../../ui/success";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { postToServer } from "../../global/requests";


const Activation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const p = location.state.patient;
  const [success, setSuccess] = useState(false);

  const reason = useRef();
  const date = useRef();
  const activatedby = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      id: p.pid,
      reason: reason.current.value,
      date: date.current.value,
      activatedby: "Testing",
    };

    const result = await postToServer(`main/frontdesk/activation`, { data });
    if (result.status) setSuccess(true);
  };

  const encounterHandler = (e) => {
    e.preventDefault();
    navigate(`/encounter/${p.pid}`, {
      state: { patient: p },
    });
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
        <PatientDetailCard list={p} isEncounter={false} />

          {success && (
            <Success>
              Activation Successfull. Click
              <a style={{ color: "blue", cursor:"pointer" }} onClick={encounterHandler}>
                {" "}
                here{" "}
              </a>
              to go to Encounter Page.
            </Success>
          )}
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <label className="fw-bold">*Reason for Activation</label>
                  <select className="form-select" ref={reason} required>
                    <option value="Enrolle name was on the list">
                      Enrolle name was on the list
                    </option>
                    <option value="Renewal of card">Renewal of card</option>
                    <option value="New Registration">New Registration</option>
                    <option value="Fee for service">Fee for service</option>
                    <option value="Emergency">Emergency</option>
                  </select>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label
                      htmlFor="password"
                      className="col-form-label fw-bold"
                    >
                      *Activation valid date
                    </label>
                    <input
                      type="date"
                      name="password"
                      className="form-control"
                      required
                      ref={date}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label
                      htmlFor="password"
                      className="col-form-label fw-bold"
                    >
                      Activated by
                    </label>
                    <input
                      type="text"
                      name="password"
                      className="form-control"
                      required
                      value="Testing"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Activate
              </button>
            </div>
          </form>
        </div>
      </MainContent>
    </>
  );
};

export default Activation;
