import React, { useContext, useEffect, useState } from "react";
import { Notifications } from "../store/Context";
import { Link } from "react-router-dom";
import logo from "../../assets/img/ariamed.PNG";
import {
  FiMonitor,
  FiBriefcase,
  FiCommand,
  FiCopy,
  FiShoppingBag,
  FiLayout,
  FiGrid,
  FiImage,
  FiFlag,
  FiSliders,
  FiUserCheck,
  FiAlertTriangle,
  FiAnchor,
  FiMail,
} from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";
import { FaClinicMedical } from "react-icons/fa";

const Sidebar = (props) => {
  const ctx = useContext(Notifications);
  const [show, setShow] = useState(props.isLoggedIn)
  useEffect(()=>{
    setShow(props.isLoggedIn)
  },[props.isLoggedIn])
  return (
    <>
      <div className="main-sidebar sidebar-style-2" style={show ? {} : {display: 'none'}}>
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <a href="index#">
              {/* <img alt="image" src={logo} className="header-logo" /> */}
              {ctx.patientToVitals && (
                <span className="logo-name ml-2">AI HME</span>
              )}
            </a>
          </div>
          <ul className="sidebar-menu">
            <li className="menu-header">Main</li>
            <li className="dropdown active">
              <Link to="/" className="nav-link">
                <FiMonitor className="sidebar-icons" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiBriefcase className="sidebar-icons" />
                <span>Front-Desk</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/search-patient" className="nav-link">
                    Search Patient
                  </Link>
                </li>
                <li>
                  <Link to="/appointments" className="nav-link">
                    Appointment
                  </Link>
                </li>
                <li>
                  <Link to="/encounter-history" className="nav-link">
                    Encounter History
                  </Link>
                </li>
                <li>
                  <Link to="/upload" className="nav-link">
                    Upload File
                  </Link>
                </li>
                <li><Link to="/report/encounter-count">Patient Encounter count</Link></li>
                <li><Link to="/report/registeration">Registration Report</Link></li>
                <li><Link to="/report/ward">Ward Report</Link></li>
                <li><Link to="/report/encounter">Patient Encounter Report</Link></li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiCommand className="sidebar-icons" />
                <span>Nursing</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/vitals" className="nav-link">
                    Vitals
                  </Link>
                </li>
                <li>
                  <Link to="/injection" className="nav-link">
                    Injection
                  </Link>
                </li>
                <li>
                  <Link to="/dressing" className="nav-link">
                    Dressing/Procedure
                  </Link>
                </li>
                <li>
                  <Link to="/immunization" className="nav-link">
                    Immunization
                  </Link>
                </li>
                <li>
                  <Link to="/antenatal" className="nav-link">
                    Antenatal Clinics
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                  {/* <Link to="/nursing-requisition" className="nav-link"> */}
                    Requisition
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                  {/* <Link to="/nursing-inventory" className="nav-link"> */}
                    Nursing Inventory
                  </Link>
                </li>
                <li>
                  <Link to="/select-nursing-admission" className="nav-link">
                    Admission
                  </Link>
                </li>
                <li><Link to="/report/antenatal">Antenatal Report</Link></li>
                <li><Link to="/report/pregnancy">Pregnancy Report</Link></li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <TfiEmail className="sidebar-icons" />
                <span>Doctors / Department</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link className="nav-link" to="/outpatient">
                    Opd Clinics
                  </Link>
                </li>
                <li>
                  <Link to="/select-nursing-admission" className="nav-link">
                    Admission
                  </Link>
                </li>
                <li><Link to="/report/encounter">Patient Encounter Report</Link></li>
                <li><Link to="/report/diagnosis">Diagnosis Report</Link></li>
              </ul>
            </li>
            <li className="menu-header">Diagnosis Center</li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiCopy className="sidebar-icons" />
                <span>Laboratory</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#" className="nav-link">
                    Pending Request
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Laboratory Result
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Test Reporting Parameter
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Test Administration
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Test Category
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                  {/* <Link to="/diagnosis-requisition" className="nav-link"> */}
                    Consumables Requisition
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                  {/* <Link to="/diagnosis-inventory" className="nav-link"> */}
                    Inventory
                  </Link>
                </li>
                <li><Link to="/report/lab-test-count">Lab Test Count</Link></li>
                <li><Link to="/report/lab-result">Lab result</Link></li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiShoppingBag className="sidebar-icons" />
                <span>Radiology</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#" className="nav-link">
                    Pending Request (X-RAY)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Pending Request (CT SCAN)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Pending Request (ULTRASOUND)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Pending Request (ECG)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Test Result
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Test Reporting Parameter
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Test Administration
                  </Link>
                </li>
                {/* <li>
                  <a className="nav-link" href="#">
                    Radiology Report
                  </a>
                </li> */}
                <li><Link to="#">Radiology Test count</Link></li>
              </ul>
            </li>

            <li className="menu-header">Pharmarcy Department</li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiLayout className="sidebar-icons" />
                <span>Dispensary</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#" className="nav-link">
                    Opd Dispensary
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Inpatient Dispensary
                  </Link>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    POs
                  </a>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Requistion
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Dispensary Inventory
                  </Link>
                </li>
                <li><Link to="#">Dispensary Report</Link></li>
                {/* <li>
                  <a className="nav-link" href="#">
                    Dispensary Expiry Report
                  </a>
                </li> */}
              </ul>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiGrid className="sidebar-icons" />
                <span>Pharmacy Store</span>
              </a>
              <ul className="dropdown-menu">
              <li>
                  <Link to="/drug-management" className="nav-link">
                    Drug Management
                  </Link>
                </li>
                <li>
                  <Link to="/consumables" className="nav-link">
                    Consumables
                  </Link>
                </li>
                <li>
                  <Link to="/store-management" className="nav-link">
                    Store Management
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Requistion
                  </Link>
                </li>
                <li>
                  <Link to="/vendors" className="nav-link">
                    Vendors
                  </Link>
                </li>
                <li>
                  <Link to="/purchase-order" className="nav-link">
                    Make Purchase
                  </Link>
                </li>
                <li>
                  <Link to="/recieved-items" className="nav-link">
                    Confirm Received Drugs
                  </Link>
                </li>
                <li>
                  <Link to="/drug-expiry" className="nav-link">
                    Drug Expiry Report
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Inventory
                  </Link>
                </li>
                <li><Link to="/report/consumable-inventory">Consumbales Inventory</Link></li>
                <li><Link to="/report/purchase-order">Purchase Confirmation</Link></li>
              </ul>
            </li>
            <li className="menu-header">Billing Dept</li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiImage className="sidebar-icons" />
                <span>HMO Authorizaion</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#" className="nav-link">
                    Pending Authorization
                  </Link>
                </li>
                <li>
                  <Link to="#">Approval Report</Link>
                </li>
                <li>
                  <Link to="#">Bill Summary</Link>
                </li>
                <li><Link to="#">Payment Report</Link></li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiFlag className="sidebar-icons" />
                <span>Bills</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#" className="nav-link">
                    Company Management
                  </Link>
                </li>
                <li>
                  <Link to="#">Review Bill</Link>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Patient Bill Reports
                  </a>
                </li>
                <li><Link to="#">Admission Discharge</Link></li>
              </ul>
            </li>
            <li>
              <Link to="#" className="nav-link">
                <FiSliders className="sidebar-icons" />
                <span>Service</span>
              </Link>
            </li>
            <li className="menu-header">Accounting Dept</li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiUserCheck className="sidebar-icons" />
                <span>Cashier</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#">Cashier Point</Link>
                </li>
                <li>
                  <Link to="#">Deposite</Link>
                </li>
                {/* <li>
                  <a href="#">Refund</a>
                </li> */}
                <li>
                  <Link to="#">Expenses</Link>
                </li>
                <li>
                  <Link to="#">Daily Cashier Report</Link>
                </li>
                <li><Link to="#">Revenue Report</Link></li>
                <li><Link to="#">Payment Report</Link></li>
              </ul>
            </li>
            {/* <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiAlertTriangle className="sidebar-icons" />
                <span>Accountant</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="nav-link" href="#">
                    Financial Year
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Chart of Account
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Sub Account
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Predefined Accounts
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Opening Balance
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Debt Voucher
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Credit Voucher
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Contra Voucher
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Journal Voucher
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Bank Reconciliation
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Voucher Approval
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Account Report
                  </a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiAnchor className="sidebar-icons" />
                <span>Bank</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="nav-link" href="#">
                    Bank
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Add Bank
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    Bank List
                  </a>
                </li>
              </ul>
            </li> */}

            <li className="menu-header">Human Resource</li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiUserCheck className="sidebar-icons" />
                <span>HR & Payroll</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="#">Department</a>
                </li>
                <li>
                  <a href="#">Leave</a>
                </li>
                <li>
                  <a href="#">Payroll</a>
                </li>
                <ul className="">
                  <li>
                    <a href="#">Manage Employee Salary</a>
                  </li>
                </ul>
              </ul>
            </li>
            <li className="menu-header">Payroll</li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiUserCheck className="sidebar-icons" />
                <span>Salary Advance</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="#">View All Report</a>
                </li>
              </ul>
            </li>
            <li className="menu-header">Reports</li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiUserCheck className="sidebar-icons" />
                <span>Reports</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#">Lab Result</Link>
                </li>
                <li>
                  <Link to="#">Appointment</Link>
                </li>
                <li>
                  <Link to="#">Diagnosis</Link>
                </li>
                <li>
                  <Link to="#">Drug Inventory</Link>
                </li>
                <li>
                  <Link to="#">Consumable Inventory</Link>
                </li>
                <li>
                  <Link to="#">Admission Discharge</Link>
                </li>
                <li>
                  <Link to="#">Patient SMS History</Link>
                </li>
                <li><Link to="#">Payment</Link></li>
                <li><Link to="#">encounter</Link></li>
                <li><Link to="#">Dispensary</Link></li>
                <li><Link to="#">Revenue</Link></li>
                <li><Link to="#">Ward</Link></li>
                <li><Link to="#">Antenatal</Link></li>
                <li><Link to="#">Pregnancy</Link></li>
                <li><Link to="#">Birth</Link></li>
                <li><Link to="#">Immunization</Link></li>
                <li><Link to="#">Patient Encounter Count</Link></li>
                <li><Link to="#">Laboratory Test Count</Link></li>
                <li><Link to="#">Radiology Test Count</Link></li>
                <li><Link to="#">Registeration</Link></li>
                <li><Link to="#">Expenses</Link></li>
                <li><Link to="#">Purchase Confirmation</Link></li>
                <li><Link to="#">User Login Logs</Link></li>
              </ul>
            </li>
            <li className="menu-header">Settings</li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiUserCheck className="sidebar-icons" />
                <span>User Mgt</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#">Vaccine Management</Link>
                </li>
                <li>
                  <a href="#">Ward Management</a>
                </li>
                <li>
                  <a href="#">Bed Management</a>
                </li>
                <li>
                  <Link to="/user-management">User management</Link>
                </li>
                <li>
                  <Link to="/user-access-management">
                    User Access Management
                  </Link>
                </li>
                <li>
                  <a href="#">Configure Patient</a>
                </li>
              </ul>
              
              {/* <li className="dropdown">
                <Link to="/discharge-patient" className="nav-link">
                  <FaClinicMedical className="sidebar-icons" />
                  <span>Discharge Patient</span>
                </Link>
              </li> */}

              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown">
                  <FaClinicMedical className="sidebar-icons" />
                  <span>Discharge Patient</span>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/discharge-patient" className="nav-link">
                      Discharge Patient
                    </Link>
                  </li>
                  <li>
                    <Link to="/report/admission-discharge">Admission Discharge</Link>
                  </li>
                </ul>
              </li>
              
              <li className="dropdown">
                <Link to="/admission-mgt/select" className="nav-link">
                  <FaClinicMedical className="sidebar-icons" />
                  <span>Admission Mgt</span>
                </Link>
              </li>

              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown">
                  <FiCommand className="sidebar-icons" />
                  <span>Apps</span>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="nav-link" to="#">Chat</Link>
                  </li>
                  <li>
                    <a className="nav-link" href="#">
                      Upload Casenote
                    </a>
                  </li>
                  <li>
                    <Link to="#" className="nav-link">
                      Price Tariff
                    </Link>
                  </li>

                  <li>
                    <a className="nav-link" href="#">
                      Calendar
                    </a>
                  </li>
                  <li>
                    <Link to="#" className="nav-link">
                      Backup Database
                    </Link>
                  </li>
                </ul>
              </li>
            </li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiMail className="sidebar-icons" />
                <span>SMS</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#" className="nav-link">
                    Compose
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    History
                  </Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Settings
                  </Link>
                </li>
                <li><Link to="#">Sms report</Link></li>
              </ul>
            </li>
            <li className="menu-header">Administration</li>
            <li className="dropdown">
              <a href="#" className="menu-toggle nav-link has-dropdown">
                <FiUserCheck className="sidebar-icons" />
                <span>Admin Mgt</span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="#">Encounter Setup</Link>
                </li>
                <li>
                  <Link to="#" className="nav-link">
                    Backup Database
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </aside>
      </div>
    </>
  );
};

export default Sidebar;
