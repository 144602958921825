import React from "react";

const Error = (props) => {
  const closeHandler = () => props.close();
  return (
    <>
      <div className="toast-body">
        <div class="alert alert-danger" role="alert" style={{ color: "white" }}>
          {props.children}
          <span className="float-end closebox" onClick={closeHandler}>
            X
          </span>
        </div>
      </div>
    </>
  );
};

export default Error;
