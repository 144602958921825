import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const AddExpenses = (props) => {
  const [departmentList, setDepartmentList] = useState();
  const [serviceType, setServiceType] = useState();

  const purpose = useRef();
  const amount = useRef();
  const collectedby = useRef();
  const approvedby = useRef();

  //   useEffect(() => {
  //   }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      purpose: purpose.current.value,
      amount: amount.current.value,
      collectedby: collectedby.current.value,
      approvedby: approvedby.current.value,
      cashiername: "",
    };

    const result = await postToServer(`billing/expenses`, { data });
    if(result.status) {
      props.close();
      props.success(true);
      props.update(result.data);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Purpose</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={purpose}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="cost" className="col-form-label">
                      Amount
                    </label>
                    <input
                      type="number"
                      name="cost"
                      className="form-control"
                      required
                      ref={amount}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="cost" className="col-form-label">
                      Collected By
                    </label>
                    <input
                      type="text"
                      name="cost"
                      className="form-control"
                      required
                      ref={collectedby}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="cost" className="col-form-label">
                      Approved By
                    </label>
                    <input
                      type="text"
                      name="cost"
                      className="form-control"
                      required
                      ref={approvedby}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddExpenses;
