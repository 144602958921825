import React from "react";

const Success = (props) => {
  const closeHandler = () => props.close();
  return (
    <>
      <div className="toast-body">
        <div
          className="alert alert-success"
          role="alert"
          style={{ color: "white" }}
        >
          {props.children}
          <span className="float-end closebox" onClick={closeHandler}>
            X
          </span>
        </div>
      </div>
    </>
  );
};

export default Success;
