// import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import Cookies from "js-cookie";
import { getFromServer, postToServer } from "../../global/requests";

const AppointmentModal = (props) => {
  const [user, setUser] = useState();

  const [selectedOption, setSelectedOption] = useState("Online");

  const formIsNewRegisterHandler = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer("usermgt/useraccess/add/department");
    if (result.status) setUser(result.data);
  };
  

  const doctorname = useRef();
  const speciality = useRef();
  const department = useRef();
  const appointmentdate = useRef();
  const appointmenttime = useRef();
  const timeavilable = useRef();

  const formSubmission = async () => {
    const data = {
      pid: props.pid,
      doctorname: doctorname.current.value,
      speciality: speciality.current.value,
      department: department.current.value,
      appointmentdate: appointmentdate.current.value,
      appointmenttime: appointmenttime.current.value,
      timeavilable: timeavilable.current.value,
      appointmenttype: selectedOption,
    };

    const result = await postToServer(`main/frontdesk/appointment`, { data });
    // if (result.status) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(appointmenttime, selectedOption);
    formSubmission();
    props.close();
    props.success(true);
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Set Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="mb-3">
                <input
                  type="hidden"
                  name="patient_id"
                  className="form-control"
                />
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="image" className="col-form-label">
                      Doctor Name:
                    </label>
                    <input
                      type="text"
                      name="doctor_name"
                      className="form-control"
                      required
                      ref={doctorname}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="image" className="col-form-label">
                      Speciality:
                    </label>
                    <input
                      type="text"
                      name="speciality"
                      className="form-control"
                      required
                      ref={speciality}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Department</label>
                    <select className="form-select" ref={department}>
                      {user &&
                        user.map((u) => {
                          return (
                            <option id={u.id} value={u.department}>
                              {u.department}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="video" className="col-form-label">
                      Appointment Date:
                    </label>
                    <input
                      type="date"
                      name="appointment_date"
                      className="form-control"
                      required
                      ref={appointmentdate}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="video" className="col-form-label">
                      Appointment Time:
                    </label>
                    <input
                      type="time"
                      name="appointment_time"
                      className="form-control"
                      required
                      ref={appointmenttime}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="video" className="col-form-label">
                      Time Available:
                    </label>
                    <input
                      type="time"
                      name="time_available"
                      className="form-control"
                      required=""
                      ref={timeavilable}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="video" className="col-form-label">
                  Appointment Type:
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    value="Online"
                    type="radio"
                    name="appointment_type"
                    id="flexRadioDefault1"
                    onChange={formIsNewRegisterHandler}
                    checked={selectedOption === "Online"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Online
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    value="On-Site"
                    type="radio"
                    name="appointment_type"
                    id="flexRadioDefault2"
                    onChange={formIsNewRegisterHandler}
                    checked={selectedOption === "On-Site"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    On-Site
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AppointmentModal;
