import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useParams } from "react-router-dom";
import Success from "../../ui/success";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer } from "../../global/requests";


const ViewEncounter = (props) => {
  const [p, setPatient] = useState();
  const [e, setEncounter] = useState();
  const param = useParams();
  const [success, setSuccess] = useState(true);

  const closeHandler = () => setSuccess(false);
  useEffect(() => {
    console.log(param.pid, param.ppid);
    doInitialTasks();
    // url = `${window.baseURL}main/frontdesk/billing/${p.pid}`;
    // axios.get(url).then((res) => {
    //   setBilling(res.data);
    // });
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/frontdesk/patient-encounter/${param.pid}/${param.ppid}`);
    if (result.status) {
      setPatient(result.data.p);
      setEncounter(result.data.e);
    }
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            {p && (
              <PatientDetailCard list={e} isEncounter={true} />
            )}
          </div>
          {success && (
            <Success close={closeHandler}>
              Encounter Successful
            </Success>
          )}
          {e && (
            <div className="card author-box card-primary">
              <div className="card-body">
                <h5 className="card-title">ENCOUNTER RECORD</h5>
                <div className="row">
                  <div className="col-md-8">
                    <table
                      className="table table-sm mt-4 table-bordered"
                      style={{ fontSize: "13px" }}
                    >
                      <tbody>
                        <tr>
                          <th>Encounter No</th>
                          <td>{e.pid}</td>
                        </tr>
                        <tr>
                          <th>Encounter Type</th>
                          <td>{e.type}</td>
                        </tr>
                        <tr>
                          <th>Encounter Date</th>
                          <td>{e.date}</td>
                        </tr>
                        <tr>
                          <th>Billing Profile</th>
                          <td>{e.profile}</td>
                        </tr>
                        <tr>
                          <th>Registration</th>
                          <td>{e.registration}</td>
                        </tr>
                        <tr>
                          <th>Current Department</th>
                          <td>{e.department}</td>
                        </tr>
                        <tr>
                          <th>Consulting Room</th>
                          <td>{e.room}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </MainContent>
    </>
  );
};

export default ViewEncounter;
