import axios from "axios";
import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";

const AuthCode = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  const [approveDate, setApproveDate] = useState();
  const handleOnChangeCheck = () => {
    setIsChecked((current) => !current);
    if (!isChecked) {
      let date = new Date();
      console.log(date.getDate(), date.getMonth(), date.getFullYear());
      document.getElementById("date").innerHTML =
        String(date.getDate()) +
        "-" +
        String(date.getMonth()) +
        "-" +
        String(date.getFullYear());
    } else {
      document.getElementById("date").innerHTML = "";
    }
  };
  const uploadHandler = (e) => {
    e.preventDefault();
    // console.log(files.current.files[0]);
    // const formData = new FormData();
    // formData.append("file", files.current.files[0]);

    // const url = `${window.baseURL}main/frontdesk/patient/detail/${props.pid}/upload`;
    // const options = {
    //   method: "PUT",
    //   body: formData,
    //   processData: false,
    //   contentType: false,
    //   mimeType: "multipart/form-data",
    //   headers: {
    //     "X-CSRFToken": Cookies.get('csrftoken'),
    //   },
    // };
    // fetch(url, options);
    // props.close();
    // props.success(true);
  };

  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={props.close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Authorization Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table
            className="table table-responsive responsive"
            style={{ fontSize: "13px" }}
          >
            <thead>
              <tr>
                <th scope="col" width="30%">
                  Authorization Code
                </th>
                <th scope="col" width="10%" className="text-center">
                  Is Approved?
                </th>
                <th scope="col" width="30%" className="text-center">
                  Comment
                </th>
                <th scope="col" width="20%">
                  Approve Date
                </th>
                <th scope="col" width="5%">
                  Save
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>458795-8</td>
                <td className="text-center">
                  <div className="ml-4">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      checked={isChecked}
                      onChange={handleOnChangeCheck}
                    />
                  </div>
                </td>
                <td>
                  <div className="">
                    <input
                      type="text"
                      name="department"
                      className="form-control"
                      required
                    />
                  </div>
                </td>
                <td id="date"></td>
                <td>
                  <button
                    className="btn btn-sm btn-primary"
                    style={{ paddingBottom: "5px" }}
                  >
                    save
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuthCode;
