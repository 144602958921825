import React, { useState, useRef } from "react";
import MainContent from "../../ui/MainContent";
import PatientHistoryDetail from "../Doctor/PatientHistoryDetail";
// import axios from "axios";
import Error from "../../ui/error";
import {getFromServer} from "../../global/requests";

const EncounterHistory = () => {
  const [list, setList] = useState();
  const [temp, setTemp] = useState();

  const [detail, setDetail] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [error, setError] = useState(false);
  const searchText = useRef();

  const searchHandler = async (e) => {
    e.preventDefault();
    if (searchText.current.value.length > 0) {
      const result = await getFromServer(`main/frontdesk/patient/detail/${searchText.current.value}/none`);
      if(result.status) {
        const result2 = await getFromServer(`main/doctor/patient-history/${result.data.p[0].pid}`);
        if(result2.status) {
          setError(false);
          setList(result2.data);
          setIsSearch(true);
        }
      }
    }
  };
  const detailHandler = (singleList) => {
    setDetail(true);
    setTemp(singleList);
  };

  const backHandler = () => {
    setDetail(false);
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          {!detail && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4>Search</h4>
                      {error && (
                        <Error>Try to input surname and firstname both</Error>
                      )}
                      <p style={{ color: "gray" }}>
                        Search Patient With Surname and Firstname or PID number
                      </p>
                      <form
                        onSubmit={searchHandler}
                        method="GET"
                        className="card-header-form"
                        style={{ display: "flex" }}
                      >
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Search"
                          required
                          ref={searchText}
                        />
                        <button
                          type="submit"
                          style={{ color: "white" }}
                          className="btn btn-info ml-3"
                        >
                          Search
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {isSearch && (
                <div>
                  <table className="table table-bordered table-stripped table-md">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Type</th>
                        <th>Company Plans</th>
                        <th className="text-center">Detail</th>
                        {/* <th></th>
              <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {list &&
                        list.enc.map((l, i) => {
                          return (
                            <tr key={i}>
                              <th>{i + 1}</th>
                              <td>{l.date}</td>
                              <td>{l.patient.surname} {l.patient.firstname}</td>
                              <td>{l.type}</td>
                              <td>{l.department}</td>
                              <td>{l.profile}</td>
                              <td className="text-center">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => detailHandler(l)}
                                >
                                  Detail
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
          {detail && (
            <PatientHistoryDetail
              back={backHandler}
              encounter_pid={temp.pid}
              patient_pid={temp.patient.pid}
            />
          )}
        </div>
      </MainContent>
    </>
  );
};

export default EncounterHistory;
