import React, { useState, useRef, useEffect } from "react";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../ui/Loading";
import { getFromServer } from "../../global/requests";

const ApprovalReport = () => {
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [temp, setTemp] = useState();

  const [companyList, setCompanyList] = useState();
  const [detail, setDetail] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fromdate = useRef(null);
  const todate = useRef(null);
  const company = useRef();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/company`);
    if(result.status) setCompanyList(result.data);
  }

  const searchHandler = async (e) => {
    e.preventDefault();
    var date = fromdate.current.value;
    var date2 = todate.current.value;
    // var search = searchText.current.value;

    if (date == null || date == "" || date == undefined) {
      date = null;
    }
    if (date2 == null || date2 == "" || date2 == undefined) {
      date2 = null;
    }
    setIsLoading(true);
    const result = await getFromServer(`billing/approval-report/${date}/${date2}/${company.current.value}`);
    if(result.status) {
      console.log(result.data);
      setList(result.data);
      setIsSearch(true);
      setIsLoading(false);
    }
  };

  const clickHandler = async (id) => {
    const result = await getFromServer(`billing/hmo-authorization-bill-summary/${id}`);
    if(result.status) navigate("/authorization-detail", { state: { list: result.data } });
  };


  return (
    <>
      <MainContent>
        <div className="section-body">
          {!detail && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4>Filter</h4>
                      <div className="row">
                        <form
                          onSubmit={searchHandler}
                          method="GET"
                          className="card-header-form"
                          style={{ display: "flex" }}
                        >
                          <div className="col-md-4">
                            <p style={{ color: "gray" }}>From Date Time</p>
                            <input
                              type="date"
                              ref={fromdate}
                              className="form-control"
                              required
                            />
                            <button
                              type="submit"
                              style={{ color: "white" }}
                              className="btn btn-info mt-3"
                            >
                              Search
                            </button>
                          </div>
                          <div className="col-md-4">
                            <p style={{ color: "gray" }}>To Date Time</p>
                            <input
                              type="date"
                              ref={todate}
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <p style={{ color: "gray" }}>Company</p>
                            <select class="form-select" ref={company}>
                              <option value="All">All</option>
                              {companyList &&
                                companyList.map((c) => {
                                  return <option value={c.id} key={c.id}>{c.name}</option>;
                                })}
                            </select>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isLoading && (
                <>
                  <h4 className="text-center">
                    Generating Report Please Wait...
                  </h4>
                  <Loading />
                </>
              )}
              {isSearch && (
                <div>
                  {list.report.length > 0 && (
                    <table className="table table-bordered table-stripped table-md">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Company</th>
                          <th>Full Name</th>
                          {list.cat &&
                            list.cat.map((c) => {
                              return <th>{c.category}</th>;
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {list.report.map((r, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{r.company}</td>
                              <td onClick={()=>clickHandler(r.auth_id)} style={{color:'blue', cursor:'pointer'}}>
                                {r.enc.patient.surname}{" "}
                                {r.enc.patient.firstname}
                              </td>
                              {r.category.map((c) => {
                                return <td>{c.is_approved}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  {list.report.length < 1 && (
                    <h3 className="text-center">No Data Found</h3>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </MainContent>
    </>
  );
};

export default ApprovalReport;
