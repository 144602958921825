import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, postToServer } from "../../global/requests";

const AddSingleTest = (props) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const [testCategory, setTestCategory] = useState();
  const [valueBox, setValueBox] = useState("");
  const name = useRef();
  const type = useRef();
  const category = useRef();
  const parameter = useRef();
  const lowerlimit = useRef();
  const upperlimit = useRef();
  const unit = useRef();
  const value = useRef();

  useEffect(() => {
    if (props.rad != true) {
      doInitialTasks();
    }
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`diagnosis/test-category`);
    if(result.status) setTestCategory(result.data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    var lab = true;
    var url = "";
    var data = {};
    if (props.rad) {
      lab = false;
      url = `diagnosis/rad-single-test`;
      data = {
        testname: name.current.value,
        testtype: type.current.value,
        lowerlimit: lowerlimit.current.value,
        upperlimit: upperlimit.current.value,
        unit: unit.current.value,
        value: value.current.value,
        is_laboratory: lab,
        is_single_test: true,
        valuecontent: content,
      };
    } else {
      url = `diagnosis/single-test`;
      data = {
        testname: name.current.value,
        category: category.current.value,
        parametername: parameter.current.value,
        lowerlimit: lowerlimit.current.value,
        upperlimit: upperlimit.current.value,
        unit: unit.current.value,
        value: value.current.value,
        is_laboratory: lab,
        is_single_test: true,
        valuecontent: content,
      };
    }

    const result = await postToServer(url, { data });
    if(result.status) {
      props.success(true);
      props.update(result.data);
      props.close();
    }
  };

  const valueBoxHandler = (e) => {
    const val = e.target.value;
    if (val == "Text Box") setValueBox("Box");
    else if (val == "Pre Define") setValueBox("Box");
    else setValueBox("");
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Single Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Name of Test
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={name}
                    />
                  </div>
                </div>
                {props.rad && (
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="service" className="col-form-label">
                        Test Type
                      </label>
                      <select className="form-select" ref={type}>
                        <option value="X-ray">X-ray</option>
                        <option value="ECG">ECG</option>
                        <option value="CT-scan">CT scan</option>
                        <option value="Ultrasound">Ultrasound</option>
                      </select>
                    </div>
                  </div>
                )}
                {!props.rad && (
                  <>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="service" className="col-form-label">
                          Test Category
                        </label>
                        <select className="form-select" ref={category}>
                          {testCategory &&
                            testCategory.map((t) => {
                              return (
                                <option key={t.id} value={t.category}>
                                  {t.category}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="service" className="col-form-label">
                          Parameter Name
                        </label>
                        <input
                          type="text"
                          name="service"
                          className="form-control"
                          required
                          ref={parameter}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Lower Limit
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={lowerlimit}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Upper Limit
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={upperlimit}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Unit of Measurements
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={unit}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Value Type
                    </label>
                    <select
                      className="form-select"
                      ref={value}
                      onChange={valueBoxHandler}
                    >
                      <option value=""></option>
                      <option value="Text Box">Text Box</option>
                      <option value="Pre Define">Pre Define</option>
                      <option value="Figure">Figure</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {(() => {
              if (valueBox == "") {
                return;
              } else if (valueBox == "Box") {
                return (
                  <JoditEditor
                    ref={editor}
                    value={content}
                    tabIndex={1}
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {}}
                  />
                );
              }
            })()}
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSingleTest;
