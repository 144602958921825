import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const AddPLan = (props) => {

  const pname = useRef();
  const billgroup = useRef();
  const billtype = useRef();
  const price = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: props.id,
      name: pname.current.value,
      billgroup: billgroup.current.value,
      billtype: billtype.current.value,
      price: 0,
    };

    const url = `${window.baseURL}billing/plan`;
    props.close();
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        props.success(true);
        props.addplan(res.data);
      });
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Plan Name
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={pname}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label>Billing Group</label>
                    <select className="form-select" ref={billgroup}>
                      <option value="Private">Private</option>
                      <option value="HMO">HMO</option>
                      <option value="Retainership">Retainership</option>
                      <option value="NHIS">NHIS</option>
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label>Billing Type</label>
                    <select className="form-select" ref={billtype}>
                      <option value="Pre-paid">Pre-paid</option>
                      <option value="Post-paid">Post-paid</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPLan;
