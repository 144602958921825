import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cookies from "js-cookie";

const NurseNoteModel = (props) => {
  const edit = props.is_edit;
  const tempData = props.tempData;

  const assessment = useRef();
  const activities = useRef();
  const drugs = useRef();
  const diet = useRef();
  const role = useRef();
  const others = useRef();
  const home = useRef();

  useEffect(() => {
    if (edit && tempData) {
      document.getElementById("assessment").value = tempData.assessment;
      document.getElementById("activities").value = tempData.activities;
      document.getElementById("drugs").value = tempData.drugs;
      document.getElementById("diet").value = tempData.diet;
      document.getElementById("role").value = tempData.role;
      document.getElementById("others").value = tempData.others;
      document.getElementById("home").value = tempData.home;
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let id = null;
    if (edit) {
      id = tempData.id;
    }

    const data = {
      is_edit: edit,
      id: id,
      assessment: assessment.current.value,
      activities: activities.current.value,
      drugs: drugs.current.value,
      diet: diet.current.value,
      role: role.current.value,
      others: others.current.value,
      home: home.current.value,
    };

    const url = `${window.baseURL}usermgt/nurse-note/${props.encounter_pid}`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        props.success(true);
        props.close();
        props.update(res.data);
      });
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          {edit ? (
            <Modal.Title>Edit Nursing Note</Modal.Title>
          ) : (
            <Modal.Title>Add Nursing Note</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <form id="note_form" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-2">
                    <label className="col-form-label">
                      Assessment of Patient
                    </label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      required
                      ref={assessment}
                      id="assessment"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-2">
                    <label className="col-form-label">
                      Activites of daily living
                    </label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      required
                      ref={activities}
                      id="activities"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-2">
                    <label className="col-form-label">Drugs</label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      required
                      ref={drugs}
                      id="drugs"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-2">
                    <label className="col-form-label">Diet</label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      required
                      ref={diet}
                      id="diet"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-2">
                    <label className="col-form-label">Role of Family</label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      required
                      ref={role}
                      id="role"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-2">
                    <label className="col-form-label">
                      Others {"("}specify{")"}
                    </label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      required
                      ref={others}
                      id="others"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-2">
                    <label className="col-form-label">
                      Home Assessment Report
                    </label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      required
                      ref={home}
                      id="home"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer  mb-2">
              <button type="submit" className="btn btn-primary">
                {edit ? <>Update</> : <>Add</>}
              </button>
            </div>
            <div></div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NurseNoteModel;
