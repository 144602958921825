import React, { useState, useEffect, useRef } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddExpenses from "../../modals/Cashier/AddExpenses";
import Loading from "../../ui/Loading";

// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, deleteFromServer } from "../../global/requests";

const Expenses = () => {
  //   const [isemptyArray, setIsEmptyArray] = useState(false);
  const [total, setTotal] = useState();
  const [store, setStore] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fromdate = useRef(null);
  const todate = useRef(null);

  const updateHandler = (list) => setStore(list);
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, [load]);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/expenses`);
    if(result.status) setStore(result.data);
  }

  const searchHandler = async (e) => {
    e.preventDefault();
    var date = fromdate.current.value;
    var date2 = todate.current.value;
    // var search = searchText.current.value;

    if (date == null || date == "" || date == undefined || date2 == null || date2 == "" || date2 == undefined ) {
    } else {
      setIsLoading(true);
      const result = await getFromServer(`billing/expenses-search/${date}/${date2}`);
      if(result.status) {
        setStore(result.data.expenses);
        setIsSearch(true);
        setTotal(result.data.total)
        setIsLoading(false);
      }
    }
  };

  const clearfilterHandler = () => {
    setLoad(!load)
    setIsSearch(false)
    document.getElementById('from_date').value = ''
    document.getElementById('to_date').value = ''
  }

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const result = await deleteFromServer(`billing/expenses/${id}`);
      if(result.status) setStore(result.data);
      setSuccess(false);
    }
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={()=>setSuccess(false)}>
            Expense has been added Successfully
          </Success>
        )}

<div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4>Search</h4>
                <div className="row">
                  <form
                    onSubmit={searchHandler}
                    method="GET"
                    className="card-header-form"
                    style={{ display: "flex" }}
                  >
                    <div className="col-md-6">
                      <p style={{ color: "gray" }}>From Date Time</p>
                      <input
                        type="datetime-local"
                        ref={fromdate}
                        id="from_date"
                        className="form-control"
                      />
                      <button
                        type="submit"
                        style={{ color: "white" }}
                        className="btn btn-info mt-3"
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        style={{ color: "white" }}
                        className="btn btn-info mt-3 ml-3"
                        onClick={clearfilterHandler}
                      >
                        Clear Filter
                      </button>
                    </div>
                    <div className="col-md-6">
                      <p style={{ color: "gray" }}>To Date Time</p>
                      <input
                        type="datetime-local"
                        id="to_date"
                        ref={todate}
                        className="form-control"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading && <Loading />}


        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add New Expense
              </button>
            </div>
          </div>

          <div className="tablexflow">
            <table className="table table-md">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Purpose</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Collected by</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {store &&
                  store.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.date}</td>
                        <td>{a.purpose}</td>
                        <td>{a.amount}</td>
                        <td>{a.collectedby}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  {isSearch && 
                  <tr>
                  <th colSpan="3" className="text-end">Total amount</th>
                  <th colSpan="2">{total}</th>
                  </tr>
                  }
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddExpenses
          show={show}
          close={handleClose}
          success={successHandler}
          update={updateHandler}
        />
      )}
    </>
  );
};

export default Expenses;
