import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, postToServer } from "../../global/requests";

const AddTestReportingParameter = (props) => {
  const [testCategory, setTestCategory] = useState();

  const name = useRef();
  const parameter = useRef();
  const lowerlimit = useRef();
  const upperlimit = useRef();
  const unit = useRef();
  const value = useRef();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`diagnosis/single-test`);
    if(result.status) setTestCategory(result.data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    var lab = true;
    var url = "";

    if (props.rad == true) {
      lab = false;
      url = `diagnosis/rad-test-reporting-parameter`;
    } else {
      url = `diagnosis/lab-test-reporting-parameter`;
    }
    const data = {
      parametername: parameter.current.value,
      id: name.current.value,
      lowerlimit: lowerlimit.current.value,
      upperlimit: upperlimit.current.value,
      unit: unit.current.value,
      value: value.current.value,
      is_laboratory: lab,
    };

    const result = await postToServer(url, { data });
    if(result.status) {
      props.success(true);
      props.update(result.data);
      props.close();
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Test Parameter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Parameter Name
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={parameter}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Lower Limit
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={lowerlimit}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Upper Limit
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={upperlimit}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Unit of Measurements
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={unit}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Test Name
                    </label>
                    <select className="form-select" ref={name}>
                      {testCategory &&
                        testCategory.map((t) => {
                          return (
                            <option key={t.id} value={t.id}>
                              {t.testname}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Value Type
                    </label>
                    <select className="form-select" ref={value}>
                      <option value="Text Box">Text Box</option>
                      <option value="Pre Define">Pre Define</option>
                      <option value="Figure">Figure</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddTestReportingParameter;
