import React, { useEffect, useState, useRef } from "react";
import MainContent from "../../ui/MainContent";
import { getFromServer, downloadFileFromUrl } from "../../global/requests";
import Loading from "../../ui/Loading";
import { genReportPagination } from "../../helpers/report-helpers";
import DateRangeWidget from "../../widgets/reports/DateRangeWidget";
import ReportHead from "../../widgets/reports/ReportHead";
import { suggestions } from "../Doctor/Suggestions";

var oldQueryString = "";
const DiagnosisReport = () => {
  // variables
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [search, setSearch] = useState("");
  const [noOfRow, setNoOfRow] = useState(25);
  const [pageNo, setPageNo] = useState(0);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageBtns, setPageBtns] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const company = useRef();
  const diagnosisKey = useRef();
  const [doctors, setDoctors] = useState([]);
  const doctor = useRef();

  useEffect(() => {
    loadListData();
    loadCompanies();
    loadDoctors();
  }, [noOfRow, pageNo, company]);

  const loadCompanies = async () => {
    const result = await getFromServer(`reports/company-list`);
    if (result.status) setCompanies(result.data.rows);
  }

  const loadDoctors = async () => {
    const result = await getFromServer(`reports/user-list`);
    if (result.status) setDoctors(result.data.rows);
  }

  const loadListData = async () => {
    const company_id = company.current.value;
    const diagnosis_key = diagnosisKey.current.value;
    const doctor_id = doctor.current.value;
    const qString = `&search=${search}&date_from=${dateFrom}&date_to=${dateTo}&no_of_row=${noOfRow}&company_id=${company_id}&diagnosis_key=${diagnosis_key}&doctor_id=${doctor_id}`;
    if (oldQueryString != qString) {
      oldQueryString = qString;
      if (pageNo != 0) {
        setPageNo(0);
        return;
      }
    }
    setIsLoading(true);
    const result = await getFromServer(
      `reports/diagnosis?offset=${pageNo * noOfRow}${qString}`
    );
    if (result.status) {
      setData(result.data.data);
      setColumns(result.data.columns);
      setCount(result.data.count);
      setPageBtns(genReportPagination(result.data.count, noOfRow, pageNo, setPageNo));
    }
    setIsLoading(false);
  };
  const exportToExcel = async () => {
    const company_id = company.current.value;
    const diagnosis_key = diagnosisKey.current.value;
    const doctor_id = doctor.current.value;
    setDownloading(true);
    await downloadFileFromUrl(
      `reports/diagnosis?download=1&search=${search}&date_from=${dateFrom}&date_to=${dateTo}&company_id=${company_id}&diagnosis_key=${diagnosis_key}&doctor_id=${doctor_id}`,
      "diagnosis-report.xlsx"
    );
    setDownloading(false);
  };
  const filterHandler = () => loadListData();

  return (
    <MainContent>
      <div className="section-body">
        <div className="row mb-2">
          <h3>Diagnosis Report</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body row">
                <DateRangeWidget dateFrom={dateFrom} dateTo={dateTo} setDateFrom={setDateFrom} setDateTo={setDateTo} name="Diagnosis" size={2} />
                <div className="col-2">
                  <label htmlFor="company">Company</label>
                  {companies ?
                  <select ref={company} id="company" className="form-control">
                    <option value="0">All companies</option>
                    {companies.map(record => <option value={record[0]}>{record[1]}</option>)}
                  </select>
                  : <select ref={company} id="company" className="form-control"></select>}
                </div>
                <div className="col-2">
                  <label htmlFor="diagnosisKey">Diagnosis</label>
                  {companies ?
                  <select ref={diagnosisKey} id="diagnosisKey" className="form-control">
                    <option value="0">All Diagnosis</option>
                    {suggestions.map(suggestion => <option value={suggestion.id}>{suggestion.text}</option>)}
                  </select>
                  : <select ref={diagnosisKey} id="diagnosisKey" className="form-control"></select>}
                </div>
                <div className="col-2">
                  <label htmlFor="doctor">Doctor</label>
                  {companies ?
                  <select ref={doctor} id="doctor" className="form-control">
                    <option value="0">All doctors</option>
                    {doctors.map(doctor => <option value={doctor[0]}>{doctor[1]}</option>)}
                  </select>
                  : <select ref={doctor} id="doctor" className="form-control"></select>}
                </div>
                <div className="filter-btn-box">
                  <button className="btn btn-primary" onClick={filterHandler}>
                    Filter
                  </button>
                </div>
                <div className="filter-btn-box">
                  {downloading ? (
                    <button className="btn btn-primary" onClick={exportToExcel} disabled>
                      <i className="fas fa-spinner fa-spin"></i>
                      &nbsp;Exporting...
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={exportToExcel}>
                      Export
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <ReportHead search={search} setSearch={setSearch} noOfRow={noOfRow} setNoOfRow={setNoOfRow} loadListData={loadListData}  />
              <div className="card-body">
                {isLoading ? (
                  <div style={{ margin: "100px 0" }}>
                    <Loading />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-bordered table-md td-nowap report">
                      <tr>
                        <th>#</th>
                        <th>{columns[1]}</th>
                        <th>{columns[2]}</th>
                        <th>{columns[3]}</th>
                        <th>{columns[4]}</th>
                        <th>{columns[5]}</th>
                        <th>Diagnosis</th>
                        <th>Doctor</th>
                      </tr>
                      {data.map((record, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{record.row[1]}</td>
                          <td>{record.row[2]}</td>
                          <td>{record.row[3]}</td>
                          <td>{record.row[4]}</td>
                          <td>{record.row[5]}</td>
                          <td>{record.diagnosis.map((diagnosis_item, i) =><p>{i + 1}) {diagnosis_item[0]}</p>)}</td>
                          <td>{record.diagnosis.map((diagnosis_item, i) =><p>{i + 1}) {diagnosis_item[1]}</p>)}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                )}
                <div className="table-pagination">
                  <p>
                    Showing {noOfRow * pageNo + 1} to{" "}
                    {noOfRow * pageNo + data.length} of {count} entries
                  </p>
                  <nav className="d-inline-block">
                    <ul className="pagination mb-0">{pageBtns}</ul>
                  </nav>
                </div>
              </div>
              {/* <div className="card-footer text-right"></div> */}
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  )
}

export default DiagnosisReport;