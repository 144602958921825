import React, { useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const PregnancyBirthDetailForm = (props) => {
  const [selectedOption, setSelectedOption] = useState("Male");

  const formIsNewRegisterHandler = (e) => {
    setSelectedOption(e.target.value);
  };

  const baby = useRef();
  const apgarscore = useRef();
  const birthweight = useRef();
  const maturity = useRef();
  const conditionofbirth = useRef();
  const birthinjuriies = useRef();
  const resuscitation = useRef();
  const drugadministered = useRef();
  const mothercondition = useRef();
  const temp = useRef();
  const bp = useRef();
  const pulse = useRef();
  const urine = useRef();
  const uterus = useRef();

  const formSubmission = async () => {
    document.getElementById('add').disabled = true
    const data = {
      baby: baby.current.value,
      apgarscore: apgarscore.current.value,
      birthweight: birthweight.current.value,
      maturity: maturity.current.value,
      conditionofbirth: conditionofbirth.current.value,
      birthinjuriies: birthinjuriies.current.value,
      resuscitation: resuscitation.current.value,
      drugadministered: drugadministered.current.value,
      mothercondition: mothercondition.current.value,
      temp: temp.current.value,
      bp: bp.current.value,
      pulse: pulse.current.value,
      urine: urine.current.value,
      uterus: uterus.current.value,
      sex: selectedOption,
    };

    const result = await postToServer(`main/pregnancy-birth-detail/${props.encounter_pid}/${props.pregnancy_id}`, { data });
    if(result.status) {
      console.log(result.data);
      props.update(result.data);
      props.back();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    formSubmission();
  };
  return (
    <>
      <button className="btn btn-sm btn-success mb-2" onClick={props.back}>
        Go Back
      </button>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h4>Pregnancy</h4>
              <div className="py-3"></div>
            </div>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={submitHandler}
            >
              <div className="card-body pb-0">
                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Baby</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={baby}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*Apgar Score</label>
                    <input
                      type="text"
                      name="hospital_id"
                      className="form-control"
                      required
                      ref={apgarscore}
                    />
                  </div>

                  <div className="col-md-4">
                    <div>
                      <label>*Sex</label>
                      <br />
                      <div className="col">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Male"
                          name="isMale"
                          id="flexRadioDefault1"
                          onChange={formIsNewRegisterHandler}
                          checked={selectedOption === "Male"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Male
                        </label>
                        <br />
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Female"
                          name="isFemale"
                          id="flexRadioDefault2"
                          onChange={formIsNewRegisterHandler}
                          checked={selectedOption === "Female"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Female
                        </label>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Birth Weight</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={birthweight}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*Maturity</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={maturity}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>*Condition of Birth</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={conditionofbirth}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Birth Injuries</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={birthinjuriies}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*Resuscitation</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={resuscitation}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Drugs Administered</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={drugadministered}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Condition of Mother after Delivery</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={mothercondition}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*Temp</label>
                    <input
                      type="text"
                      name="state_of_origin"
                      className="form-control"
                      required
                      ref={temp}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>*B/P</label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      ref={bp}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Pulse</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      ref={pulse}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>*Urine</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={urine}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>*Uterus</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={uterus}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer pt-0">
                <button type="submit" className="btn btn-primary" id="add">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PregnancyBirthDetailForm;
