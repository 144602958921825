import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import MainContent from "../../ui/MainContent";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "../../ui/Loading";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer, postToServer } from "../../global/requests";


const ImmunizationRecord = () => {
  const para = useParams();
  const [list, setList] = useState();
  const [vaccineList, setVaccineList] = useState();
  const [load, setLoad] = useState(true);
  const [p, setEncounter] = useState();

  const vaccine = useRef();
  const value = useRef();
  const output = useRef();
  const appointment = useRef();
  const remarks = useRef();

  useEffect(() => {
    doInitialTasks();
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    var today = now.getFullYear() + "-" + month + "-" + day;
    document.getElementById("date").value = today;
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/immunization-record/${para.pid}`);
    if(result.status) {
      if (result.data != "") {
        setList(result.data.list);
        setEncounter(result.data.encounter);
        setVaccineList(result.data.vaccine);
        setLoad(false);
      }
    }
  }

  const saveHandler = async () => {
    const data = {
      vaccine: vaccine.current.value,
      value: value.current.value,
      output: output.current.value,
      appointment: appointment.current.value,
      remarks: remarks.current.value,
    };

    const result = await postToServer(`main/immunization-record/${para.pid}`, { data });
    if(result.status) {
      setList(result.data);
      setLoad(false);
    }
  };

  return (
    <>
      <MainContent>
        <div className="section-row">
          {p && (
            <div className="section-body">
             <PatientDetailCard list={p} isEncounter={true} />
            </div>
          )}

          <div className="card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {load && <Loading />}
                <table className="table responsive">
                  <thead>
                    <tr style={{ fontSize: "13px" }}>
                      <th scope="col">S/N</th>
                      <th scope="col">Recommendation Vaccine</th>
                      <th scope="col">Vaccine Value</th>
                      <th scope="col">Vaccine Output</th>
                      <th scope="col">Authorized Date</th>
                      <th scope="col">Next Appointment</th>
                      <th scope="col">Remarks</th>
                      <th scope="col" className="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.immunizationRecordDetail.map((t, index) => {
                        return (
                          <tr key={index}>
                            <th scope="col">{index + 1}</th>
                            <td scope="col">{t.vaccine}</td>
                            <td scope="col">{t.value}</td>
                            <td scope="col">{t.output}</td>
                            <td scope="col">{t.date}</td>
                            <td scope="col">{t.appointment}</td>
                            <td scope="col">{t.remarks}</td>
                            <td scope="col"></td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <table className="table responsive">
                  <thead>
                    <tr style={{ fontSize: "13px" }}>
                      <th scope="col">S/N</th>
                      <th scope="col">Recommendation Vaccine</th>
                      <th scope="col">Vaccine Value</th>
                      <th scope="col">Vaccine Output</th>
                      <th scope="col">Authorized Date</th>
                      <th scope="col">Next Appointment</th>
                      <th scope="col">Remarks</th>
                      <th scope="col" className="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>
                        <select className="form-select" ref={vaccine}>
                          {vaccineList &&
                            vaccineList.map((u) => {
                              return (
                                <option key={u.id} value={u.name}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          ref={value}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          ref={output}
                        />
                      </td>
                      <td>
                        <input
                          id="date"
                          type="text"
                          className="form-control"
                          disabled
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          ref={appointment}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          ref={remarks}
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={saveHandler}
                        >
                          save
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default ImmunizationRecord;
