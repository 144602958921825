import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cookies from "js-cookie";

const AddTests = (props) => {
  const [checkList, setCheckList] = useState([]);
  const type = useRef();

    useEffect(() => {
      console.log(props.options)
    }, []);

  const changeHandler = (e, i) => {
    if (e.target.checked) {
      setCheckList([...checkList, props.options[i]]);
    } else {
      const newval = checkList.filter((c) => {
        return c.id != props.options[i].id;
      });
      setCheckList(newval)
    }
  };
  const handleSubmit = (e, i) => {
    e.preventDefault();
    props.update(checkList)
    props.close()
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Select Lab Tests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                {props.options &&
                  props.options.map((t, i) => {
                    return (
                      <div className="col-md-4">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id={"test" + i}
                          onChange={(e) => changeHandler(e, i)}
                        />
                        <label style={{ marginLeft: "5px" }} htmlFor={"test" + i}>
                          {t.testname}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddTests;
