import React from "react";
import Modal from "react-bootstrap/Modal";

const ViewResults = (props) => {
  const list = props.list;
  var company = "";
  if (list) {
    company = list.encounter.profile.split("=")[0];
  }
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {list && (
            <div style={{ marginTop: "0", padding: "80px" }} id="claim-form">
              <h1 className="text-center text-uppercase">
                {company}
                {/* Hetta Medical Center */}
                <h4>With Laboratory & Denial Services</h4>
              </h1>
              {/* <div className="row mt-5 border border-dark">
            <div className="col-md-6">
              <h4>Patient Details</h4>
            </div>
            <div className="col-md-6 text-end">
              <h4>Service Provider Details</h4>
            </div>
          </div> */}
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-4 text-nowrap">
                      <label>PATIENT NAME: </label>
                    </div>
                    <div className="col-md-8 bottom-border">
                      <span>
                        {list.encounter.patient.surname}{" "}
                        {list.encounter.patient.firstname}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 text-nowrap">
                      <label>BATCH FILE NO.: </label>
                    </div>
                    <div className="col-md-8 bottom-border">
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 text-nowrap">
                      <label>HOSPITAL NO:</label>
                    </div>
                    <div className="col-md-8 bottom-border">
                      <span>{list.encounter.patient.hospitalid}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 text-nowrap">
                      <label>REQUEST CODE:</label>
                    </div>
                    <div className="col-md-8 bottom-border">
                      <span>{list.id}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 text-nowrap">
                      <label>REQUEST MADE BY: </label>
                    </div>
                    <div className="col-md-8 bottom-border">
                      <span></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-5 text-nowrap">
                      <label>AGE: </label>
                    </div>
                    <div className="col-md-7 bottom-border">
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 text-nowrap">
                      <label>SEX: </label>
                    </div>
                    <div className="col-md-7 bottom-border">
                      <span>{list.encounter.patient.sex}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 text-nowrap">
                      <label>DATE REQUESTED:</label>
                    </div>
                    <div className="col-md-7 bottom-border">
                      <span>{list.date}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 text-nowrap">
                      <label>DATE ANALYZED: </label>
                    </div>
                    <div className="col-md-7 bottom-border">
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 text-nowrap">
                      <label>RADIOGRAPHER: </label>
                    </div>
                    <div className="col-md-7 bottom-border">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <table className="table table-sm table-bordered">
                  <thead>
                    <tr className="text-center">
                      <th width="5%">S/N</th>
                      <th colSpan="2">Investigation</th>
                      <th>Result</th>
                      {/* <th></th> */}
                      {/* <th>Reference Range</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {list.investigationlab.map((t, i) => {
                      return (
                        <>
                          {t.test.is_single_test ? (
                            <>
                              {t.status == "Done" ? (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td colSpan="2">{t.test.testname}</td>
                                  <td className="fw-bolder">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: t.value,
                                      }}
                                    />
                                  </td>
                                  {/* <td>{t.status}</td> */}
                                </tr>
                              ) : (
                                ""
                              )}{" "}
                            </>
                          ) : (
                            <>
                              {t.status == "Done" ? (
                                <>
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td colSpan="3">{t.test.testname}</td>
                                    {/* <td>{t.status}</td> */}
                                  </tr>
                                  {t.investigationlabtest.map((ut, j) => {
                                    return (
                                      <>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>{ut.test.testname}</td>
                                          <td className="fw-bolder">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: ut.value,
                                              }}
                                            />
                                          </td>
                                          {/* <td></td> */}
                                        </tr>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewResults;
