import React, { useState, useEffect, useRef } from "react";
import { render } from "react-dom";
import axios from "axios";
import { suggestions } from "./Suggestions";
import Cookies from "js-cookie";
import AutProcedure from "./Auto/AutoProcedure";

const Procedure = (props) => {
  const pid = props.encounter_pid;
  const patient_pid = props.patient_pid;
  const [save, setSave] = useState(false);
  const [head, setHead] = useState(false);
  const [diag, setDiag] = useState();
  const [tableData, setTableData] = useState();
  const [tableDataHead, setTableDataHead] = useState([]);
  const [check, setCheck] = useState([]);

  const [tags, setTags] = useState([]);
  const [val, setVal] = useState([]);
  const [drug, setDrug] = useState([]);
  const [dosage, setDosage] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [period, setPeriod] = useState([]);
  const [none, setNone] = useState([]);
  const [total, setTotal] = useState([]);

  const handleAdd = () => {
    setHead(true);
    setVal([...val, []]);
    setDrug([...drug, []]);
    setDosage([...dosage, 1]);
    setFrequency([...frequency, 'Stat']);
    setPeriod([...period, 1]);
    setNone([...none, 7]);
    setTotal([...total, 1]);
  };

  const handleNote = (e, i) => {
    const inputDrug = [...drug];
    inputDrug[i] = e.target.value;
    setDrug(inputDrug);
    console.log(inputDrug);
  };
  const handleDosage = (e, i) => {
    const inputDrug = [...dosage];
    inputDrug[i] = e.target.value;
    setDosage(inputDrug);
    handleTotal(e, i, "dosage");
  };
  const handleFrequency = (e, i) => {
    const inputDrug = [...frequency];
    inputDrug[i] = e.target.value;
    setFrequency(inputDrug);
    handleTotal(e, i, "frequency");
  };
  const handlePeriod = (e, i) => {
    const inputDrug = [...period];
    inputDrug[i] = e.target.value;
    setPeriod(inputDrug);
    handleTotal(e, i, "period");
  };
  const handleNone = (e, i) => {
    const inputDrug = [...none];
    inputDrug[i] = e.target.value;
    setNone(inputDrug);
    handleTotal(e, i, "none");
  };
  const handleTotal = (e, i, c) => {
    let dos;
    let freq;
    let per;
    let non;
    if (c == "dosage") {
      dos = e.target.value;
      freq = frequency[i];
      per = period[i];
      non = none[i];
    } else if (c == "frequency") {
      dos = dosage[i];
      freq = e.target.value;
      per = period[i];
      non = none[i];
    } else if (c == "period") {
      dos = dosage[i];
      freq = frequency[i];
      per = e.target.value;
      non = none[i];
    } else if (c == "none") {
      dos = dosage[i];
      freq = frequency[i];
      per = period[i];
      non = e.target.value;
    }

    if (dos.length <= 0) dos = 1;
    if (freq.length <= 0) freq = 1;
    if (per.length <= 0) per = 1;
    if (non.length <= 0) non = 1;

    if (freq == "BD") freq = 2;
    else if (freq == "TDS") freq = 3;
    else if (freq == "QDS") freq = 4;
    else if (freq == "Nocte") freq = 1;
    else if (freq == "Mane") freq = 1;
    else if (freq == "WKLY") freq = 1;
    else if (freq == "AM") freq = 1;
    else if (freq == "PM") freq = 1;
    else if (freq == "DLY") freq = 1;
    else if (freq == "ALT DLY") freq = 1;
    else if (freq == "PRN") freq = 1;
    else if (freq == "Rectal") freq = 1;
    else if (freq == "Stat") freq = 1;
    if (non == "7") non = 1;
    else if (non == "52") non = 7;
    else if (non == "12") non = 30;
    let t=0
    if (
      dos.length <= 0 ||
      freq.length <= 0 ||
      per.length <= 0 ||
      non.length <= 0
    ) {
      console.log("none");
    } else {
      t = parseInt(dos) * parseInt(per) * parseInt(freq) * parseInt(non);
      document.getElementById(`prescription_total${i}`).value = t;
    }

    const inputDrug = [...total];
    inputDrug[i] = t
    setTotal(inputDrug);
  };

  const getTags = (e, i) => {
    const inputDrug = [...tags];
    inputDrug[i] = e;
    setTags(inputDrug);
    console.log(inputDrug);
  };

  useEffect(() => {
    setCheck([]);
    let url = `${window.baseURL}adminmgt/encounter/doctor/procedure/${pid}`;
    axios.get(url).then((res) => {
      setDiag(res.data);
    });

    url = `${window.baseURL}main/doctor/procedure/${patient_pid}`;
    axios.get(url).then((res) => {
      console.log("this is data", res.data);
      if (res.data.length != 0) {
        setTableData(res.data);
        console.log(res.data);
      }
      console.log(check);
    });
  }, []);

  const submitHandler = () => {
    if (window.confirm("Do you want to add procedure?")) {
      setSave(!save);
      const d = new Date();
      const date = d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear();
      const data = {
        encounter: props.encounter_pid,
        patient: props.patient_pid,
        date: date,
        tags: tags,
        note: drug,
        dosage: dosage,
        frequency: frequency,
        period: period,
        none: none,
        total: total,
      };
      // setTags([]);
      const url = `${window.baseURL}main/doctor/procedure`;
      axios
        .post(
          url,
          { data },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
          }
        )
        .then((res) => {
          console.log("inside prescription", res.data);
          // props.newStateDict(res.data);
          setTableData(res.data);
          setVal([]);
          setDrug([]);
          setDosage([]);
          setFrequency([]);
          setPeriod([]);
          setNone([]);
          setTotal([]);
          // props.reloading();
        });
    }
  };

  const deleteHandler = (detail_id, procedure_id) =>{
    if(window.confirm("Are you sure you want to delete this?")){
      const merge_id = props.patient_pid + '_' + detail_id + '_' + procedure_id
      const url = `${window.baseURL}main/doctor/procedure/${merge_id}`;
      axios
        .delete(
          url,
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
          }
        )
        .then((res) => {
          setTableData(res.data);
        });
    }
  }


  return (
    <div>
      <div>
        <div className="row">
          <table className="table responsive table-sm">
            <thead>
              {head && (
                <tr>
                  <th></th>
                  <th>Dosage</th>
                  <th>Frequency</th>
                  <th>Period</th>
                  <th></th>
                  <th>Total</th>
                  <th>Comment</th>
                </tr>
              )}
            </thead>
            <tbody>
              {val.map((data, i) => {
                return (
                  <>
                    <tr>
                      <td>
                        <AutProcedure
                          options={diag}
                          tag={(e) => getTags(e, i)}
                          removeTag={save}
                        />
                      </td>
                      <td width="5%">
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "100px" }}
                          id={"prescription_dosage" + i}
                          onChange={(e) => handleDosage(e, i)}
                        />
                      </td>
                      <td width="5%">
                        <select
                          className="form-select"
                          style={{ width: "100px" }}
                          id={"prescription_frequency" + i}
                          onChange={(e) => handleFrequency(e, i)}
                        >
                          <option value="Stat">Stat</option>
                          <option value="DLY">DLY</option>
                          <option value="ALT DLY">ALT DLY</option>
                          <option value="BD">BD</option>
                          <option value="Rectal">Rectal</option>
                          <option value="TDS">TDS</option>
                          <option value="QDS">QDS</option>
                          <option value="WKLY">WKLY</option>
                          <option value="AM">AM</option>
                          <option value="Mane">Mane</option>
                          <option value="PM">PM</option>
                          <option value="Nocte">Nocte</option>
                          <option value="PRN">PRN</option>
                        </select>
                      </td>
                      <td width="5%">
                        <select
                          className="form-select"
                          style={{ width: "100px" }}
                          onChange={(e) => handlePeriod(e, i)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                        </select>
                      </td>
                      <td width="5%">
                        <select
                          className="form-select"
                          style={{ width: "100px" }}
                          onChange={(e) => handleNone(e, i)}
                        >
                          <option value="7">7</option>
                          <option value="52">52</option>
                          <option value="12">12</option>
                        </select>
                      </td>
                      <td width="10%">
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "100px" }}
                          id={"prescription_total" + i}
                          onChange={(e) => handleTotal(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Any Note..."
                          onChange={(e) => handleNote(e, i)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>

        <div class="d-grid gap-2 d-md-block">
          <button class="btn btn-primary" type="button" onClick={handleAdd}>
            Add more
          </button>
          <button
            type="button"
            className="btn btn-primary mt-3 ml-2 mb-3"
            onClick={submitHandler}
          >
            save
          </button>
        </div>

        {tableData && (
          <table className="table responsive">
            {tableData &&
              tableData.map((a, index) => {
                return (
                  <>
                    <thead>
                      <tr style={{ fontSize: "13px" }}>
                        <th scope="col">S/N</th>
                        <th scope="col">
                          {a.date}
                          {" (" + a.encounter.pid + ")"}
                        </th>
                        {/* <th scope="col"></th> */}
                        <th scope="col">Comment</th>
                        <th>Dosage</th>
                        <th>Frequency</th>
                        <th>Period</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {a.ProcedureDetails.map((d, ind) => {
                        return (
                          <tr key={ind}>
                            <th scope="row">{ind + 1}</th>
                            <td>
                              {d.ProcedureDetailsTag.map((t) => {
                                return <>{t.procedure} -</>;
                              })}
                            </td>
                            <td>{d.note}</td>
                            <td>{d.dosage}</td>
                            <td>{d.frequency}</td>
                            <td>
                              {d.period}
                              {" / " + d.none}
                            </td>
                            <td>{d.total}</td>
                            <td>
                              <button class="btn btn-danger btn-sm ml-2" onClick={()=>deleteHandler(d.id,a.id)}>Delete</button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                );
              })}
          </table>
        )}
      </div>
    </div>
  );
};

export default Procedure;
