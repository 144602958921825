import React, { useState, useEffect } from "react";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, deleteFromServer, putToServer } from "../../global/requests";

const Appointments = () => {
  const [getData, setDataApponitment] = useState();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async() => {
    const result = await getFromServer('main/frontdesk/appointment');
    if(result.status) setDataApponitment(result.data);
  }

  const deleteHandler = async (id) => {
    const result = await deleteFromServer(`main/frontdesk/appointment/detail/${id}`);
    if(result.status) setDataApponitment(result.data);
  };

  const confirmHandler = async(id) => {
    const result = await putToServer(`main/frontdesk/appointment/detail/${id}`);
    if(result.status) setDataApponitment(result.data);
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <div className="col-md-12">
              <div className="card author-box card-primary">
                <div className="card-body ">
                  <h2 className="py-3">Appointments</h2>
                  <div className="tablexflow">
                    <table className="table responsive">
                      <thead>
                        <tr style={{ fontSize: "13px" }}>
                          <th scope="col">#</th>
                          <th scope="col">Patient Name</th>
                          <th scope="col">Doctor Name</th>
                          <th scope="col">Speciality</th>
                          <th scope="col">Department</th>
                          <th scope="col">Appointment Date</th>
                          <th scope="col">Appointment Time</th>
                          <th scope="col">Time Available</th>
                          <th scope="col">Appointment Type</th>
                          <th scope="col" className="text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getData &&
                          getData.map((a, index) => {
                            return (
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{a.patient.surname} {a.patient.firstname}</td>
                                <td>{a.doctorname}</td>
                                <td>{a.speciality}</td>
                                <td>{a.department}</td>
                                <td>{a.appointmentdate}</td>
                                <td>{a.appointmenttime}</td>
                                <td>{a.timeavilable}</td>
                                <td>{a.appointmenttype}</td>
                                {a.isconfirm ? (
                                  <td className="text-center">Confirmed</td>
                                ) : (
                                  <td className="text-nowrap text-center">
                                    <button
                                      className="btn btn-sm btn-primary"
                                      style={{ color: "white" }}
                                      onClick={() => confirmHandler(a.id)}
                                    >
                                      Confirm
                                    </button>
                                    <button
                                      className="btn btn-sm ml-1 btn-danger"
                                      style={{ color: "white" }}
                                      onClick={() => deleteHandler(a.id)}
                                    >
                                      Discard
                                    </button>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default Appointments;
