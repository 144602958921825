import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, postToServer } from "../../global/requests";

const AddService = (props) => {
  const [departmentList, setDepartmentList] = useState();
  const [serviceType, setServiceType] = useState();

  const name = useRef();
  const quantity = useRef();
  const type = useRef();
  const department = useRef();  

  useEffect(() => {
    loadDepartments();
    loadConsumableType();
  }, []);

  const loadDepartments = async () => {
    const result = await getFromServer(`usermgt/useraccess/add/department`);
    if(result.status) setDepartmentList(result.data);
  }
  const loadConsumableType = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/consumabletype`);
    if(result.status) setServiceType(result.data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: name.current.value,
      quantity: quantity.current.value,
      type: type.current.value,
      department: department.current.value,
    };

    const result = await postToServer(`pharmacy/drugmgt/consumable`, { data });
    if(result.status) {
      props.close();
      props.success(true);
      props.consumables(result.data);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Consumable</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Item Name
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={name}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="cost" className="col-form-label">
                      Quantity
                    </label>
                    <input
                      type="number"
                      name="cost"
                      className="form-control"
                      required
                      ref={quantity}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Department</label>
                    <select className="form-select" ref={department}>
                      {departmentList &&
                        departmentList.map((u) => {
                          return (
                            <option key={u.id} value={u.department}>
                              {u.department}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Type</label>
                    <select className="form-select" ref={type}>
                      {serviceType &&
                        serviceType.map((t) => {
                          return <option value={t.type}>{t.type}</option>;
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddService;
