// window.baseURL = "http://127.0.0.1:8000/";
// window.baseURLuploaded = "http://127.0.0.1:8000/static";
// window.baseWsURL = "ws://127.0.0.1:8000/ws/chat";


// window.baseURL = "https://ariamed.pythonanywhere.com/";
// window.baseURLuploaded = "https://ariamed.pythonanywhere.com/static";

window.baseURL = "/";
window.baseURLuploaded = "/static";
// window.baseWsURL = `ws://${window.location.host}/ws/chat`;


document.body.style.zoom = "85%";

const Global = () => {
  return;
};

export default Global;
