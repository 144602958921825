import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const ReturnItem = (props) => {
  const data = props.detail;
  const is_fluid = props.is_fluid;
  console.log(props.is_fluid)
  const [detail, setDetail] = useState(data);
  const [plan, setPlan] = useState();
  const billplan = useRef();
  const company = useRef();

  useEffect(() => {
    console.log(data);
    // const url = `${window.baseURL}billing/company`;
    // axios.get(url).then((res) => {
    //   setCompany(res.data);
    // });
  }, []);

  const changeReturnedQuantityHandler = (e) =>{
    const inputDrug = { ...detail };
    inputDrug.returnedquantity = e.target.value;
    setDetail(inputDrug);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    const data = {
        is_fluid, is_fluid,
        detail:detail,
        pres_id:props.pres_id
    };

    const result = await postToServer(`pharmacy/dispensary-return-update`, { data });
    if(result.status) {
      props.close();
        console.log(result.data)
        props.newPrescription(result.data);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Return Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <table className="table table-bordered table-stripped">
                    <thead>
                      <tr style={{ fontSize: "13px" }}>
                        <th scope="col">Item Name</th>
                        <th scope="col">Return Record</th>
                        <th scope="col">Total Quantity Returned</th>
                        <th scope="col">Returned Quantity</th>
                        <th scope="col">Store</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!is_fluid &&
                      <tr>
                        <td>
                          {detail &&
                            detail.prescription_details_tag_set.map((d) => {
                              return <>{d.drugtags.genericname} - </>;
                            })}
                        </td>
                        <td>{detail.returnrecord && detail.returnrecord}</td>
                        <td>{detail.totalreturnedquantity}</td>
                        <td>
                        <input
                              type="text"
                              name="dispensedquantity"
                              className="form-control w-50"
                              value={detail.returnedquantity}
                              onChange={(e) => changeReturnedQuantityHandler(e)}
                            />
                        </td>
                        <td>OPD</td>
                      </tr>}
                      {is_fluid &&
                      <tr>
                        <td>
                          {detail.fluid.fluid}
                        </td>
                        <td>{detail.returnrecord && detail.returnrecord}</td>
                        <td>{detail.totalreturnedquantity}</td>
                        <td>
                        <input
                              type="text"
                              name="dispensedquantity"
                              className="form-control w-50"
                              value={detail.returnedquantity}
                              onChange={(e) => changeReturnedQuantityHandler(e)}
                            />
                        </td>
                        <td>OPD</td>
                      </tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary text-center" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReturnItem;
