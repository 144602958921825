import React, { useEffect, useState } from "react";
import MainContent from "../../ui/MainContent";
import { getFromServer, downloadFileFromUrl } from "../../global/requests";
import Loading from "../../ui/Loading";
import LabResultReportDetails from "../../modals/reports/LabResultReportDetails";
import { genReportPagination } from "../../helpers/report-helpers";
import DateRangeWidget from "../../widgets/reports/DateRangeWidget";
import ReportHead from "../../widgets/reports/ReportHead";

var oldQueryString = "";
const LabResultReport = () => {
  // variables
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [search, setSearch] = useState("");
  const [noOfRow, setNoOfRow] = useState(25);
  const [pageNo, setPageNo] = useState(0);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageBtns, setPageBtns] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const [detailShow, setdetailShow] = useState(false);
  const [investigationId, setInvestigationId] = useState(null);

  useEffect(() => {
    loadListData();
  }, [noOfRow, pageNo]);

  const loadListData = async () => {
    const qString = `&search=${search}&date_from=${dateFrom}&date_to=${dateTo}&no_of_row=${noOfRow}`;
    if (oldQueryString != qString) {
      oldQueryString = qString;
      if (pageNo != 0) {
        setPageNo(0);
        return;
      }
    }
    setIsLoading(true);
    const result = await getFromServer(
      `reports/lab-result?offset=${pageNo * noOfRow}${qString}`
    );
    if (result.status) {
      setRows(result.data.rows);
      setColumns(result.data.columns);
      setCount(result.data.count);
      setPageBtns(genReportPagination(result.data.count, noOfRow, pageNo, setPageNo));
    }
    setIsLoading(false);
  };
  const exportToExcel = async () => {
    setDownloading(true);
    await downloadFileFromUrl(
      `reports/lab-result?download=1&search=${search}&date_from=${dateFrom}&date_to=${dateTo}`,
      "lab-result-report.xlsx"
    );
    setDownloading(false);
  };
  const filterHandler = () => loadListData();

  const detailHandler = (rowId) => {
    setdetailShow(true);
    setInvestigationId(rowId);
  };
  const handleDetailClose = () => {
    setdetailShow(false);
    setInvestigationId(null);
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row mb-2">
            <h3>Lab Result Report</h3>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body row">
                  <DateRangeWidget dateFrom={dateFrom} dateTo={dateTo} setDateFrom={setDateFrom} setDateTo={setDateTo} name="Encounter" />
                  <div className="filter-btn-box">
                    <button className="btn btn-primary" onClick={filterHandler}>
                      Filter
                    </button>
                  </div>
                  <div className="filter-btn-box">
                    {downloading ? (
                      <button
                        className="btn btn-primary"
                        onClick={exportToExcel}
                        disabled
                      >
                        <i className="fas fa-spinner fa-spin"></i>
                        &nbsp;Exporting...
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={exportToExcel}
                      >
                        Export
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <ReportHead search={search} setSearch={setSearch} noOfRow={noOfRow} setNoOfRow={setNoOfRow} loadListData={loadListData}  />
                <div className="card-body">
                  {isLoading ? (
                    <div style={{ margin: "100px 0" }}>
                      <Loading />
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-bordered table-md report">
                        <tr>
                          <th>#</th>
                          <th>{columns[1]}</th>
                          <th>{columns[2]}</th>
                          <th>{columns[3]}</th>
                          <th>{columns[4]}</th>
                          <th>{columns[5]}</th>
                          {/* <th>Status</th> */}
                          <th>Action</th>
                        </tr>
                        {rows.map((row, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{row[1]}</td>
                            <td>{row[2]}</td>
                            <td>{row[3]}</td>
                            <td>{row[4]}</td>
                            <td>{row[5]}</td>
                            {/* <td><div className="badge badge-success">Active</div></td> */}
                            <td>
                              <button
                                onClick={() => detailHandler(row[0])}
                                className="btn btn-primary"
                              >
                                Detail
                              </button>
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  )}
                  <div className="table-pagination">
                    <p>
                      Showing {noOfRow * pageNo + 1} to{" "}
                      {noOfRow * pageNo + rows.length} of {count} entries
                    </p>
                    <nav className="d-inline-block">
                      <ul className="pagination mb-0">{pageBtns}</ul>
                    </nav>
                  </div>
                </div>
                {/* <div className="card-footer text-right"></div> */}
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      {detailShow && (
        <LabResultReportDetails
          show={detailShow}
          close={handleDetailClose}
          investigationId={investigationId}
        />
      )}
    </>
  );
};

export default LabResultReport;
