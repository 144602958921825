import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../ui/Loading";
import { FaSearch } from "react-icons/fa";
import Success from "../../ui/success";
import { postToServer, getFromServer } from "../../global/requests";

const CashierDetail = () => {
  const param = useLocation();
  const navigate = useNavigate();
  const [p, setP] = useState();
  const [list, setList] = useState();

  const [total, setTotal] = useState();
  const [temptotal, settempTotal] = useState();
  const [insurance, setInsurance] = useState();
  const [sumtopay, setSumtopay] = useState();
  const [initialBalance, setInitialBalance] = useState();
  const [walletRemaining, setWalletRemaining] = useState();
  const [discount, setDiscount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);

  const [payment, setPayment] = useState(false);
  const [refundReload, setRefundReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const amount = useRef();
  const method = useRef();
  const searchText = useRef();

  useEffect(() => {
    setP(param.state.list);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const list = param.state.list;
      let elements = document.getElementsByClassName("total");
      let value = 0;
      for (let i = 0; i < elements.length; i++) {
        value += parseInt(elements[i].textContent);
      }
      setTotal(value);
      settempTotal(value);
      elements = document.getElementsByClassName("insurance");
      var value2 = 0;
      for (let i = 0; i < elements.length; i++) {
        value2 += parseFloat(elements[i].textContent);
      }
      setInsurance(value2);

      // setting sumtopay, initialdeposit and wallet balance 
      let total_sum = (value - value2) + list.encounter.patient.minuswallet
      let wallet_balance =  total_sum - list.encounter.patient.wallet;
      let extra_balance = 0
      
      if (wallet_balance < 0) {
        extra_balance = Math.abs(wallet_balance);
        setSumtopay(0)
        setInitialBalance(total_sum)
        setWalletRemaining(extra_balance)
      } else {
        setSumtopay(wallet_balance)
        setInitialBalance(total_sum - wallet_balance)
        setWalletRemaining(0)
      }
      
      
      let remainingwallet = list.encounter.patient.wallet - total_sum;
      var balance = 0.0;
      if (remainingwallet >= 0) {
        balance = parseFloat(list.encounter.patient.minuswallet);
        setCurrentBalance(remainingwallet)
      } else {
        balance =
          parseFloat(list.encounter.patient.minuswallet) +
          Math.abs(remainingwallet);
        setCurrentBalance(remainingwallet)
      }
      setBalance(balance);


    }, 500);

    setPayment(false);
  }, [refundReload]);

  const paidCheckboxhandler = (e, i, name) => {
    const check = e.target.checked;
    const newVal = { ...p };
    if (name == "service") {
      const a = newVal.service_cash[i];
      a.is_paid = !a.is_paid;
      if (check) {
        setTotal(total + a.price * a.qty);
        setInsurance(
          insurance +
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      } else {
        setTotal(total - a.price * a.qty);
        setInsurance(
          insurance -
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      }
    } else if (name == "drug") {
      const a = newVal.drug_cash[i];
      a.is_paid = !a.is_paid;
      if (check) {
        setTotal(total + a.price * a.qty);
        setInsurance(
          insurance +
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      } else {
        setTotal(total - a.price * a.qty);
        setInsurance(
          insurance -
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      }
    } else if (name == "vaccine") {
      const a = newVal.vaccine_cash[i];
      a.is_paid = !a.is_paid;
      if (check) {
        setTotal(total + a.price * a.qty);
        setInsurance(
          insurance +
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      } else {
        setTotal(total - a.price * a.qty);
        setInsurance(
          insurance -
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      }
    } else if (name == "consumable") {
      const a = newVal.consumable_cash[i];
      a.is_paid = !a.is_paid;
      if (check) {
        setTotal(total + a.price * a.qty);
        setInsurance(
          insurance +
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      } else {
        setTotal(total - a.price * a.qty);
        setInsurance(
          insurance -
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      }
    } else if (name == "room") {
      const a = newVal.room_cash[i];
      a.is_paid = !a.is_paid;
      if (check) {
        setTotal(total + a.price * a.qty);
        setInsurance(
          insurance +
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      } else {
        setTotal(total - a.price * a.qty);
        setInsurance(
          insurance -
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      }
    } else if (name == "fluid") {
      const a = newVal.fluid_cash[i];
      a.is_paid = !a.is_paid;
      if (check) {
        setTotal(total + a.price * a.qty);
        setInsurance(
          insurance +
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      } else {
        setTotal(total - a.price * a.qty);
        setInsurance(
          insurance -
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      }
    } else if (name == "test") {
      const a = newVal.test_cash[i];
      a.is_paid = !a.is_paid;
      if (check) {
        setTotal(total + a.price * a.qty);
        setInsurance(
          insurance +
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      } else {
        setTotal(total - a.price * a.qty);
        setInsurance(
          insurance -
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      }
    } else if (name == "procedure") {
      const a = newVal.procedure_cash[i];
      a.is_paid = !a.is_paid;
      if (check) {
        setTotal(total + a.price * a.qty);
        setInsurance(
          insurance +
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      } else {
        setTotal(total - a.price * a.qty);
        setInsurance(
          insurance -
            a.qty *
              (a.price - (a.price - (a.price * a.planitem.pt_percentage) / 100))
        );
      }
    }
    setP(newVal);
  };

  const paymentHandler = async () => {
    setIsLoading(true);
    let paidamount = parseFloat(amount.current.value);
    if (amount.current.value < 1 || amount.current.value == undefined) {
      paidamount = 0;
    }
    let currentWalletBalance = p.encounter.patient.wallet - (total - insurance);
    if (!p.is_pending) {
      currentWalletBalance = p.encounter.patient.wallet;
    }
    const data = {
      list: p,
      discount: discount,
      total: total,
      insurance: insurance,
      sumtopay: sumtopay,
      amount: paidamount,
      method: method.current.value,
      wallet: currentBalance,
      balance: currentBalance,
      minus: balance,
    };
    const result = await postToServer(`billing/cashier`, { data });
    if(result.status) {
      setIsLoading(false);
      navigate("/bill-page", { state: { list: result.data } });
      console.log(result.data);
    }
  };

  const discountHandler = (e) => {
    const value = e.target.value;
    const d = temptotal - temptotal * (value / 100);
    setTotal(d);
    setDiscount(value);
  };

  const refundHandler = async (id, name) => {
    if (window.confirm("Do you want to refund this?")) {
      const data = {
        name: name,
        page: "refund_cashier",
        id: id,
      };
      const result = await postToServer(`billing/review-bill/none`, { data });
      if(result.status) {
        setSuccess(true);
        // props.success(true);
      }
    }
  };

  const searchHandler = async (e) => {
    e.preventDefault();
    if (searchText.current.value.length > 0) {
      const result = await getFromServer(`billing/review-bill/${searchText.current.value}`);
      if(result.status) setIsSearch(result.data);
    }
  };

  const clickHandler = (l) => {
    setP(l);
    setRefundReload(!refundReload);
  };

  const printHandler = async () => {
    setIsLoading(true);
    const data = {
      list: p,
    };
    const result = await postToServer(`billing/cashier-print`, { data });
    if(result.status) {
      setIsLoading(false);
      navigate("/bill-page", { state: { list: result.data } });
      console.log(result.data);
    }
  };
  return (
    <>
      <MainContent>
        <div className="row">
          <h2 className="mb-4">Cashier Details</h2>
          <div className="col-md-9">
            {p && (
              <div className="section-body">
                <div className="card author-box card-primary">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2">
                        <div
                          className="author-box-left"
                          style={{ paddingRight: "10px" }}
                        >
                          <img
                            alt="image"
                            width="150px"
                            height="150px"
                            src={
                              window.baseURLuploaded +
                              p.encounter.patient.captureimage
                            }
                            className="img-thumbnail rounded"
                          />
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="author-box-details">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="author-box-name">
                                <b id="pid">PID No:</b>{" "}
                                {p.encounter.patient.pid}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="author-box-name">
                                <b>Name:</b> {p.encounter.patient.firstname}{" "}
                                {p.encounter.patient.surname}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="author-box-name">
                                <b>Phone:</b> {p.encounter.patient.phonenumber}
                              </div>
                            </div>

                            <div className="col-md-4 mt-3">
                              <div className="author-box-name">
                                <b>Address:</b> {p.encounter.patient.address}
                              </div>
                            </div>
                            <div className="col-md-4 mt-3">
                              <div className="author-box-name">
                                <b>Origin:</b>{" "}
                                {p.encounter.patient.stateoforigin}
                              </div>
                            </div>
                            <div className="col-md-4 mt-3">
                              <div className="author-box-name">
                                <b>Title:</b> {p.encounter.patient.title}
                              </div>
                            </div>

                            <div className="col-md-4 mt-3">
                              <div className="author-box-name">
                                <b>Hospital ID:</b>{" "}
                                {p.encounter.patient.hospitalid}
                              </div>
                            </div>
                            <div className="col-md-4 mt-3">
                              <div className="author-box-name">
                                <b>DOB:</b> {p.encounter.patient.dob}
                              </div>
                            </div>
                            <div className="col-md-4 mt-3">
                              <div className="author-box-name">
                                <b>Gender:</b> {p.encounter.patient.sex}
                              </div>
                            </div>
                            <div className="col-md-4 mt-3">
                              <div className="author-box-name">
                                <b>Company:</b>{" "}
                                {p.encounter.profile.split("=>")[0]}
                              </div>
                            </div>
                            <div className="col-md-4 mt-3">
                              <div className="author-box-name">
                                <b>Plan:</b>{" "}
                                {p.encounter.profile.split("=>")[1]}
                              </div>
                            </div>
                            <div className="col-md-4 mt-3 d-flex">
                              <div
                                className="author-box-name alert alert-danger"
                                style={{ color: "white" }}
                              >
                                <b>Own(-):</b> {p.encounter.patient.minuswallet}
                              </div>
                              <div
                                className="author-box-name alert alert-primary"
                                style={{ color: "white" }}
                              >
                                <b>Wallet:</b> {p.encounter.patient.wallet}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {success && (
                    <Success close={() => setSuccess(false)}>
                      Refunded Success
                    </Success>
                  )}
                  {!p.is_pending && (
                    <button
                      className="btn btn-primary mb-3"
                      onClick={printHandler}
                    >
                      View Bill
                    </button>
                  )}
                  <table className="table table-md table-bordered">
                    <thead>
                      <tr>
                        {payment && <th></th>}
                        <th>Item</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Sub-Total</th>
                        <th>Insurance</th>
                        <th colSpan={p.is_pending ? "2" : "1"}>
                          Sub-total less Ins.
                        </th>
                        {!p.is_pending && <th></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {p.service_cash &&
                        p.service_cash.map((s, i) => {
                          return (
                            <>
                              <tr key={s.id}>
                                {payment && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={s.is_paid}
                                      onChange={(e) =>
                                        paidCheckboxhandler(e, i, "service")
                                      }
                                    />
                                  </td>
                                )}
                                <td>{s.service.name}</td>
                                <td>{s.price}</td>
                                <td>{s.qty}</td>
                                <td className="total">{s.price * s.qty}</td>
                                <td className="insurance">
                                  {s.qty *
                                    (s.price -
                                      (s.price -
                                        (s.price * s.planitem.pt_percentage) /
                                          100))}
                                </td>
                                <td
                                  className="sumtopay"
                                  colSpan={p.is_pending ? "2" : "1"}
                                >
                                  {s.qty *
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100)}
                                </td>
                                {!p.is_pending && (
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        refundHandler(s.id, "service")
                                      }
                                    >
                                      Refund
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      {p.vaccine_cash &&
                        p.vaccine_cash.map((s, i) => {
                          return (
                            <>
                              <tr key={s.id}>
                                {payment && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={s.is_paid}
                                      onChange={(e) =>
                                        paidCheckboxhandler(e, i, "vaccine")
                                      }
                                    />
                                  </td>
                                )}
                                <td>{s.vaccine.name}</td>
                                <td>{s.price}</td>
                                <td>{s.qty}</td>
                                <td className="total">{s.price * s.qty}</td>
                                <td className="insurance">
                                  {s.qty *
                                    (s.price -
                                      (s.price -
                                        (s.price * s.planitem.pt_percentage) /
                                          100))}
                                </td>
                                <td
                                  className="sumtopay"
                                  colSpan={p.is_pending ? "2" : "1"}
                                >
                                  {s.qty *
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100)}
                                </td>
                                {!p.is_pending && (
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        refundHandler(s.id, "vaccine")
                                      }
                                    >
                                      Refund
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      {p.room_cash &&
                        p.room_cash.map((s, i) => {
                          return (
                            <>
                              <tr>
                                {payment && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={s.is_paid}
                                      onChange={(e) =>
                                        paidCheckboxhandler(e, i, "room")
                                      }
                                    />
                                  </td>
                                )}
                                <td>{s.room.room}</td>
                                <td>{s.price}</td>
                                <td>{s.qty}</td>
                                <td className="total">{s.price * s.qty}</td>
                                <td className="insurance">
                                  {s.qty *
                                    (s.price -
                                      (s.price -
                                        (s.price * s.planitem.pt_percentage) /
                                          100))}
                                </td>
                                <td
                                  className="sumtopay"
                                  colSpan={p.is_pending ? "2" : "1"}
                                >
                                  {s.qty *
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100)}
                                </td>
                                {!p.is_pending && (
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        refundHandler(s.id, "room")
                                      }
                                    >
                                      Refund
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      {p.procedure_cash &&
                        p.procedure_cash.map((s, i) => {
                          return (
                            <>
                              <tr>
                                {payment && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={s.is_paid}
                                      onChange={(e) =>
                                        paidCheckboxhandler(e, i, "procedure")
                                      }
                                    />
                                  </td>
                                )}
                                <td>{s.procedure.procedure}</td>
                                <td>{s.price}</td>
                                <td>{s.qty}</td>
                                <td className="total">{s.price * s.qty}</td>
                                <td className="insurance">
                                  {s.qty *
                                    (s.price -
                                      (s.price -
                                        (s.price * s.planitem.pt_percentage) /
                                          100))}
                                </td>
                                <td
                                  className="sumtopay"
                                  colSpan={p.is_pending ? "2" : "1"}
                                >
                                  {s.qty *
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100)}
                                </td>
                                {!p.is_pending && (
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        refundHandler(s.id, "procedure")
                                      }
                                    >
                                      Refund
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      {p.drug_cash &&
                        p.drug_cash.map((s, i) => {
                          return (
                            <>
                              <tr>
                                {payment && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={s.is_paid}
                                      onChange={(e) =>
                                        paidCheckboxhandler(e, i, "drug")
                                      }
                                    />
                                  </td>
                                )}
                                <td>{s.drug.brandname}</td>
                                <td>{s.price}</td>
                                <td>{s.qty}</td>
                                <td className="total">{s.price * s.qty}</td>
                                <td className="insurance">
                                  {s.qty *
                                    (s.price -
                                      (s.price -
                                        (s.price * s.planitem.pt_percentage) /
                                          100))}
                                </td>
                                <td
                                  className="sumtopay"
                                  colSpan={p.is_pending ? "2" : "1"}
                                >
                                  {s.qty *
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100)}
                                </td>
                                {!p.is_pending && (
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        refundHandler(s.id, "drug")
                                      }
                                    >
                                      Refund
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      {p.fluid_cash &&
                        p.fluid_cash.map((s, i) => {
                          return (
                            <>
                              <tr>
                                {payment && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={s.is_paid}
                                      onChange={(e) =>
                                        paidCheckboxhandler(e, i, "fluid")
                                      }
                                    />
                                  </td>
                                )}
                                <td>{s.fluid.fluid}</td>
                                <td>{s.price}</td>
                                <td>{s.qty}</td>
                                <td className="total">{s.price * s.qty}</td>
                                <td className="insurance">
                                  {s.qty *
                                    (s.price -
                                      (s.price -
                                        (s.price * s.planitem.pt_percentage) /
                                          100))}
                                </td>
                                <td
                                  className="sumtopay"
                                  colSpan={p.is_pending ? "2" : "1"}
                                >
                                  {s.qty *
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100)}
                                </td>
                                {!p.is_pending && (
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        refundHandler(s.id, "fluid")
                                      }
                                    >
                                      Refund
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      {p.test_cash &&
                        p.test_cash.map((s, i) => {
                          return (
                            <>
                              <tr>
                                {payment && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={s.is_paid}
                                      onChange={(e) =>
                                        paidCheckboxhandler(e, i, "test")
                                      }
                                    />
                                  </td>
                                )}
                                <td>{s.test.testname}</td>
                                <td>{s.price}</td>
                                <td>{s.qty}</td>
                                <td className="total">{s.price * s.qty}</td>
                                <td className="insurance">
                                  {s.qty *
                                    (s.price -
                                      (s.price -
                                        (s.price * s.planitem.pt_percentage) /
                                          100))}
                                </td>
                                <td
                                  className="sumtopay"
                                  colSpan={p.is_pending ? "2" : "1"}
                                >
                                  {s.qty *
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100)}
                                </td>
                                {!p.is_pending && (
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        refundHandler(s.id, "test")
                                      }
                                    >
                                      Refund
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      {p.consumable_cash &&
                        p.consumable_cash.map((s, i) => {
                          return (
                            <>
                              <tr>
                                {payment && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={s.is_paid}
                                      onChange={(e) =>
                                        paidCheckboxhandler(e, i, "consumable")
                                      }
                                    />
                                  </td>
                                )}
                                <td>{s.consumable.name}</td>
                                <td>{s.price}</td>
                                <td>{s.qty}</td>
                                <td className="total">{s.price * s.qty}</td>
                                <td className="insurance">
                                  {s.qty *
                                    (s.price -
                                      (s.price -
                                        (s.price * s.planitem.pt_percentage) /
                                          100))}
                                </td>
                                <td
                                  className="sumtopay"
                                  colSpan={p.is_pending ? "2" : "1"}
                                >
                                  {s.qty *
                                    (s.price -
                                      (s.price * s.planitem.pt_percentage) /
                                        100)}
                                </td>
                                {!p.is_pending && (
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        refundHandler(s.id, "consumable")
                                      }
                                    >
                                      Refund
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      <tr>
                        <th colSpan={payment ? "6" : "5"}>Total Sum</th>
                        <td colSpan={p.is_pending ? "1" : "2"}>{total}</td>
                        {p.is_pending && (
                          <td>
                            <input
                              className="form-control form-control-sm w-50"
                              type="number"
                              placeholder="Discount %"
                              onChange={discountHandler}
                            />
                          </td>
                        )}
                      </tr>
                      <tr>
                        <th colSpan={payment ? "6" : "5"}>Insurance</th>
                        <td colSpan="2">
                        {p.is_pending
                            ? insurance
                            : p.insurance}
                        </td>
                      </tr>
                      <tr>
                        <th colSpan={payment ? "6" : "5"}>Sum to Pay</th>
                        <td colSpan="2">
                        {p.is_pending
                            ? sumtopay
                            : p.sumtopay}
                        </td>
                      </tr>
                      <tr>
                        <th colSpan={payment ? "6" : "5"}>Initial Balance</th>
                        <td colSpan="2">
                          {p.is_pending
                            ? p.encounter.patient.wallet
                            : p.initialdeposit}
                        </td>
                      </tr>
                      <tr>
                        <th colSpan={payment ? "6" : "5"}>Current Balance</th>
                        <td colSpan="2">
                          {p.is_pending
                            ? currentBalance
                            : p.balance}
                        </td>
                      </tr>
                      {!p.is_pending && (
                        <tr>
                          <th colSpan="5">Amount Paid</th>
                          <td colSpan="2">{p.paidamount}</td>
                        </tr>
                      )}

                      <tr>
                        {/* {p.is_pending && (
                          <> */}
                        {!payment && (<>

                         {p.is_pending ? <th colSpan="7">
                            <button
                              className="btn btn-primary"
                              onClick={() => setPayment(true)}
                              >
                              Make Payment
                            </button>
                          </th> : 
                          <th></th>
                          }
                              </>
                        )}
                        {/* </>
                        )} */}
                        {payment && (
                          <td colSpan="2">
                            <label>Amount</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Amount"
                              ref={amount}
                            />
                            <label>Payment Method</label>
                            <select className="form-select" ref={method}>
                              <option value="Cash">Cash</option>
                              <option value="Bank Transfer">
                                Bank Transfer
                              </option>
                              <option value="Pos">Pos</option>
                              <option value="Cheque">Cheque</option>
                            </select>
                            <button
                              className="btn btn-primary mt-3"
                              onClick={paymentHandler}
                            >
                              Save
                            </button>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  {isLoading && <Loading />}
                </div>
              </div>
            )}
          </div>
          {p && (
            <div className="col-md-3">
              <div className="card card-primary">
                <div className="card-body">
                  <h4>Search</h4>
                  <form
                    onSubmit={searchHandler}
                    method="GET"
                    className="card-header-form"
                    style={{ display: "flex" }}
                  >
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Search"
                      required
                      ref={searchText}
                    />
                    <button
                      type="submit"
                      style={{ color: "white" }}
                      className="btn btn-info ml-1"
                    >
                      <FaSearch />
                    </button>
                  </form>
                  <table className="table table-bordered table-stripped table-sm mt-3">
                    <tbody>
                      {isSearch &&
                        isSearch.map((l) => {
                          return (
                            <tr>
                              <td>
                                <img
                                  alt="image"
                                  width="100px"
                                  height="100px"
                                  src={
                                    window.baseURLuploaded +
                                    l.encounter.patient.captureimage
                                  }
                                  className="img-thumbnail rounded"
                                />
                              </td>
                              <td
                                className="text-center"
                                onClick={() => clickHandler(l)}
                                style={{ color: "blue", cursor: "pointer" }}
                              >
                                {l.encounter.patient.title}.{" "}
                                {l.encounter.patient.surname}{" "}
                                {l.encounter.patient.firstname}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </MainContent>
    </>
  );
};

export default CashierDetail;
