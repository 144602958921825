import React, { useRef, useState, useEffect } from "react";
import MainContent from "../../ui/MainContent";
import Error from "../../ui/error";
// import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Loading from "../../ui/Loading";
import { getFromServer, postFormToServer } from "../../global/requests";

const EditPatient = () => {
  const location = useLocation();
  let navigate = useNavigate();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Yes");
  const [companies, setCompany] = useState();
  const [plan, setPlan] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [p, setP] = useState();

  const image = useRef();

  const formSubmission = async () => {
    document.getElementById("register").disabled = true;
    setIsLoading(true);
    const formData = new FormData();
    let img = "";
    if (image.current.files.length != 0) {
      img = image.current.files[0];
    }
    formData.append("id", p.id);
    formData.append("pid", p.pid);
    formData.append("surname", p.surname);
    formData.append("firstname", p.firstname);
    formData.append("hospitalid", p.hospitalid);
    formData.append("phonenumber", p.phonenumber);
    formData.append("companyidnumber", p.companyidnumber);
    formData.append("nextofkin", p.nextofkin);
    formData.append("address", p.address);
    formData.append("stateoforigin", p.stateoforigin);
    formData.append("title", p.title);
    formData.append("sex", p.sex);
    formData.append("dob", p.dob);
    formData.append("file", img);

    const result = await postFormToServer(`main/frontdesk/edit/patient`, formData);
    if(result.status) {
      setSuccess(true);
      window.scroll(0, 0);
      setIsLoading(false);
      navigate(`/patient-detail/${result.data}`);
    }
  };

  useEffect(() => {
    doInitialTasks();
    const newP = { ...location.state.list, company: "", plan: "" };
    setP(newP);
    console.log(newP);
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/company`);
    if (result.status) setCompany(result.data);
  };

  const changeHandler = (key, value) => {
    const newData = { ...p };
    newData[key] = value;
    setP(newData);
  };

  const companyChangeHandler = async (e) => {
    const newData = { ...p };
    newData.company = e.target.value;
    setP(newData);

    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");

    const result = await getFromServer(`billing/plan/${parseInt(option)}`);
    if (result.status) setPlan(result.data);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (image.current.files.length == 0) {
      formSubmission();
    } else {
      if (String(image.current.files[0].type).includes("image")) {
        setError(false);
        formSubmission();
        // console.log(sex.current.value, title.current.value, dob.current.value)
      } else {
        setError(true);
        window.scrollTo(0, 0);
      }
    }
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          {error && <Error error="Please Choose only image files !!!" />}
          {p && (
            <Link
              to={"/patient-detail/" + p.pid}
              className="btn btn-sm btn-success mb-2"
            >
              Go Back
            </Link>
          )}
          {p && (
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Edit Patient</h4>
                    <div className="py-3"></div>
                  </div>
                  <form
                    method="POST"
                    encType="multipart/form-data"
                    onSubmit={submitHandler}
                  >
                    <input type="hidden" name="_token" value="" />
                    <div className="card-body pb-0">
                      <div className="form-group row">
                        <div className="col-md-4">
                          <label>*Hospital ID</label>
                          <input
                            type="text"
                            name="hospital_id"
                            className="form-control"
                            required
                            value={p.hospitalid}
                            onChange={(event) =>
                              changeHandler("hospitalid", event.target.value)
                            }
                          />
                        </div>

                        <div className="col-md-4">
                          <label>*Title</label>
                          <select
                            className="form-select"
                            value={p.title}
                            onChange={(event) =>
                              changeHandler("title", event.target.value)
                            }
                          >
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Miss">Miss</option>
                            <option value="Master">Master</option>
                            <option value="Chief">Chief</option>
                            <option value="Alhaja">Alhaja</option>
                            <option value="Alhaji">Alhaji</option>
                            <option value="HRM">HRM</option>
                          </select>
                        </div>

                        <div className="col-md-4">
                          <label>*Surname</label>
                          <input
                            type="text"
                            name="surname"
                            className="form-control"
                            required
                            value={p.surname}
                            onChange={(event) =>
                              changeHandler("surname", event.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-md-4">
                          <label>*First Name</label>
                          <input
                            type="text"
                            name="first_name"
                            className="form-control"
                            required
                            value={p.firstname}
                            onChange={(event) =>
                              changeHandler("firstname", event.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <label>*DOB</label>
                          <input
                            type="date"
                            name="dob"
                            className="form-control"
                            required
                            value={p.dob}
                            onChange={(event) =>
                              changeHandler("dob", event.target.value)
                            }
                          />
                        </div>

                        <div className="col-md-4">
                          <label>*Sex</label>
                          <select
                            className="form-select"
                            value={p.sex}
                            onChange={(event) =>
                              changeHandler("sex", event.target.value)
                            }
                          >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        {/* <div className="col-md-4">
                          <label>*Company</label>
                          <select
                            className="form-select"
                            value={p.company}
                            onChange={(e) =>
                              companyChangeHandler(e)
                            }
                          >
                            {companies &&
                              companies.map((u) => {
                                return (
                                  <option id={u.id} value={u.name}>
                                    {u.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="col-md-4">
                          <label>*Company Plan</label>
                          <select
                            className="form-select"
                            value={p.billplan}
                            onChange={(event) =>
                              changeHandler("billplan", event.target.value)
                            }
                          >
                            {plan &&
                              plan.map((u) => {
                                return (
                                  <option id={u.id} value={u.name}>
                                    {u.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div> */}
                      </div>

                      <div className="form-group row">
                        <div className="col-md-4">
                          <label>*Company ID Number</label>
                          <input
                            type="text"
                            name="company_id_no"
                            className="form-control"
                            required
                            value={p.companyidnumber}
                            onChange={(event) =>
                              changeHandler(
                                "companyidnumber",
                                event.target.value
                              )
                            }
                          />
                        </div>

                        <div className="col-md-4">
                          <label>*Phone Number</label>
                          <input
                            type="text"
                            name="phone_number"
                            className="form-control"
                            required
                            value={p.phonenumber}
                            onChange={(event) =>
                              changeHandler("phonenumber", event.target.value)
                            }
                          />
                        </div>

                        <div className="col-md-4">
                          <label>*Address</label>
                          <input
                            name="address"
                            className="form-control"
                            required
                            value={p.address}
                            onChange={(event) =>
                              changeHandler("address", event.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                     

                        <div className="col-md-4">
                          <label>State Of Origin</label>
                          <input
                            type="text"
                            name="state_of_origin"
                            className="form-control"
                            value={p.stateoforigin}
                            onChange={(event) =>
                              changeHandler("stateoforigin", event.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Next Of Kin Ph. No.</label>
                          <input
                            type="text"
                            name="next_of_kin"
                            className="form-control"
                            value={p.nextofkin}
                            onChange={(event) =>
                              changeHandler("nextofkin", event.target.value)
                            }
                          />
                        </div>

                        <div className="col-md-4">
                          <label>Capture Image</label>
                          <input
                            type="file"
                            name="capture_image"
                            className="form-control"
                            accept="image/png,image/jpg,image/jpeg"
                            ref={image}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                       
                      </div>
                    </div>
                    {isLoading && <Loading />}
                    <div className="card-footer pt-0">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="register"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </MainContent>
    </>
  );
};

export default EditPatient;
