import React from "react";

const Loading = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>
    </>
  );
};

export default Loading;
