import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddSupplier from "../../modals/Pharmacy/AddSupplier";
// import axios from "axios";
import { Link } from "react-router-dom";
import { getFromServer } from "../../global/requests";

const Consumables = () => {
  //   const [isemptyArray, setIsEmptyArray] = useState(false);
  const [supplier, setSupplier] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const closehandler = () => setSuccess(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateSupplierHandler = (list) => setSupplier(list);
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/supplier`);
    if(result.status) setSupplier(result.data);
  }

  return (
    <>
      <MainContent>
        {success && (
          <Success close={closehandler}>
            Supplier has been added Successfully!!!
          </Success>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add Supplier
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <Link
                to="/purchase-order"
                className="mt-2 float-end"
                // style={{ paddingBottom: "5px" }}
              >
                Go Back
              </Link>
            </div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">S\N</th>
                  <th scope="col">Supplier Name</th>
                  <th scope="col">Address</th>
                  <th scope="col">Contact</th>
                </tr>
              </thead>
              <tbody>
                {supplier &&
                  supplier.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td>{a.address}</td>
                        <td>{a.contact}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddSupplier
          show={show}
          close={handleClose}
          success={successHandler}
          supplier={updateSupplierHandler}
        />
      )}
    </>
  );
};

export default Consumables;
