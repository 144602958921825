import React from "react";
import Modal from "react-bootstrap/Modal";

const AccessDenied = (props) => {
  return (
    <Modal show={props.show} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>Access Denied</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <p style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}>
            You don't have permission to perform this action.
          </p>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={props.close}>Ok</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AccessDenied;
