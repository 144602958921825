import React from "react";

const PatientDetailCard = (props) => {
  let patient = "";
  const isEncounter = props.isEncounter;
  if (isEncounter) patient = props.list.patient;
  else patient = props.list;
  return (
    <>
      {patient && (
        <div className="card author-box card-primary">
          <div className="card-body">
            <div className="row">
              <div className="col-md-2">
                <div
                  className="author-box-left"
                  style={{ paddingRight: "10px" }}
                >
                  <img
                    alt="Image not uploaded"
                    width="150px"
                    height="150px"
                    src={window.baseURLuploaded + patient.captureimage}
                    className="img-thumbnail rounded"
                  />
                </div>
              </div>
              <div className="col-md-10">
                <div className="author-box-details">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="author-box-name">
                        <b>PID No:</b> {patient.pid}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="author-box-name">
                        <b>Name:</b> {patient.surname} {patient.firstname}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="author-box-name">
                        <b>Phone:</b> {patient.phonenumber}
                      </div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <div className="author-box-name">
                        <b>Address:</b> {patient.address}
                      </div>
                    </div>
                    <div className="col-md-4 mt-3">
                      <div className="author-box-name">
                        <b>Origin:</b> {patient.stateoforigin}
                      </div>
                    </div>
                    <div className="col-md-4 mt-3">
                      <div className="author-box-name">
                        <b>Title:</b> {patient.title}
                      </div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <div className="author-box-name">
                        <b>Hospital ID:</b> {patient.hospitalid}
                      </div>
                    </div>
                    <div className="col-md-4 mt-3">
                      <div className="author-box-name">
                        <b>Date Of Birth:</b> {patient.dob}
                      </div>
                    </div>
                    <div className="col-md-4 mt-3">
                      <div className="author-box-name">
                        <b>Gender:</b> {patient.sex}
                      </div>
                    </div>
                    {isEncounter && (
                      <>
                        <div className="col-md-4 mt-3">
                          <div className="author-box-name">
                            <b>Company:</b> {props.list.profile.split("=>")[0]}
                          </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="author-box-name">
                            <b>Plan:</b> {props.list.profile.split("=>")[1]}
                          </div>
                        </div>
                        <div className="col-md-4 mt-3">
                          <div className="author-box-name">
                            {/* <b>Plan ID/Membership ID:</b> {props.list.plan} */}
                            <b>Plan ID/Membership ID:</b> {patient.companyidnumber}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientDetailCard;
