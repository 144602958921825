import React, { useState, useEffect } from "react";
import NurseReportModel from "../../modals/AdmissionMgt/NurseReportModel";
import axios from "axios";
import Success from "../../ui/success";
import Cookies from "js-cookie";
import { getFromServer, deleteFromServer } from "../../global/requests";


const NurseReport = (props) => {
  const [report, setReport] = useState(false);
  const [success, setSuccess] = useState(false);
  const [is_edit, setIsEdit] = useState(false);
  const [tempData, setTempData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/nurse-report/${props.encounter_pid}`);
    if(result.status) {
      setReport(result.data);
      console.log(result.data);
    }
  }

  const showHandler = (is_edit, data) => {
    setTempData(data);
    setIsEdit(is_edit);
    setShow(true);
  };


  const updateHandler = (list) => {
    setReport(list);
  };

  const deleteHandler = async (id) => {
    if (window.confirm("Do you want to delete this?")) {
      const merge_id = props.encounter_pid + "_" + id;
      const result = await deleteFromServer(`usermgt/nurse-report/${merge_id}`);
      if(result.status) setReport(result.data);
    }
  };

  return (
    <>
      <div className="mt-4  mb-2">
        <button className="btn btn-primary" onClick={() => showHandler(false, {})}>
          Add Record
        </button>
      </div>
      <div>
        {success && (
          <Success close={() => setSuccess(false)}>
            Data intialized Successfully
          </Success>
        )}
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              {/* <th>Report</th> */}
              <th>Nurse Name</th>
              <th>Operation</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {report &&
              report.map((c, i) => {
                return (
                  <tr key={c.id}>
                    <td>{i + 1}</td>
                    <td>{c.date}</td>
                    {/* <td>{c.report}</td> */}
                    <td>{c.nursename}</td>
                    <td>{c.operation}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => showHandler(true, c)}
                      >
                        Edit
                      </button>
                      <button className="btn btn-sm btn-danger ml-1" onClick={()=>deleteHandler(c.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {show && (
        <NurseReportModel
          show={show}
          close={() => setShow(false)}
          encounter_pid={props.encounter_pid}
          success={() => setSuccess(true)}
          update={updateHandler}
          is_edit={is_edit}
          tempData={tempData}
        />
      )}
    </>
  );
};

export default NurseReport;
