import React, { useRef, useState } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const PregnancyAntenatalDetailForm = (props) => {
  const [selectedOption, setSelectedOption] = useState("Male");

  const formIsNewRegisterHandler = (e) => {
    setSelectedOption(e.target.value);
  };

  const height = useRef();
  const weight = useRef();
  const pregnancynumber = useRef();
  const present = useRef();
  const relation = useRef();
  const foetal = useRef();
  const bp = useRef();
  const remarks = useRef();
  const nextvisit = useRef();
  const staffid = useRef();
  const date = useRef();

  const formSubmission = async () => {
    const data = {
      date: date.current.value,
      height: height.current.value,
      weight: weight.current.value,
      pregnancynumber: pregnancynumber.current.value,
      present: present.current.value,
      relation: relation.current.value,
      foetal: foetal.current.value,
      bp: bp.current.value,
      remarks: remarks.current.value,
      nextvisit: nextvisit.current.value,
      staffid: staffid.current.value,
    };

    const result = await postToServer(`main/pregnancy-antenatal-detail/${props.encounter_pid}/${props.pregnancy_id}`, { data });
    if(result.status) {
      console.log(result.data);
      props.update(result.data);
      props.back();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    formSubmission();
  };
  return (
    <>
      <button className="btn btn-sm btn-success mb-2" onClick={props.back}>
        Go Back
      </button>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="py-3"></div>
            </div>
            <form
              method="POST"
              encType="multipart/form-data"
              onSubmit={submitHandler}
            >
              <div className="card-body pb-0">
                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Height</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={height}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*Weight</label>
                    <input
                      type="text"
                      name="hospital_id"
                      className="form-control"
                      required
                      ref={weight}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>*Pregnancy number</label>
                    <input
                      type="text"
                      name="hospital_id"
                      className="form-control"
                      required
                      ref={pregnancynumber}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Visit date </label>
                    <input
                      type="date"
                      className="form-control"
                      required
                      ref={date}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*Present</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={present}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>*Relation</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={relation}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>*Foetal</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={foetal}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*BP</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={bp}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Remarks</label>
                    <input type="text" className="form-control" ref={remarks} />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label>Next visit</label>
                    <input
                      type="date"
                      className="form-control"
                      ref={nextvisit}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>*Staff Id</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={staffid}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer pt-0">
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PregnancyAntenatalDetailForm;
