import axios from "axios";
import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Cookies from 'js-cookie';
import {putFormToServer} from "../../global/requests";


const UploadFile = (props) => {
  const files = useRef();

  const uploadHandler = async (e) => {
    e.preventDefault();
    console.log(files.current.files[0]);
    const formData = new FormData();
    formData.append("file", files.current.files[0]);

    const result = await putFormToServer(`main/frontdesk/patient/detail/${props.pid}/upload`, formData);
    if(result.status) {
      props.close();
      props.success(true);
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={uploadHandler} encType="multipart/form-data">
            <input type="file" ref={files} />
            <button type="submit" className="btn btn-primary mr-2">
              Upload file
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadFile;
