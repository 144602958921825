import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import Loading from "../../ui/Loading";
import PregnancyBirthDetailForm from "./PregnancyBirthDetailForm";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { deleteFromServer, getFromServer } from "../../global/requests";


const PregnancyBirthDetail = () => {
  const navigate = useNavigate();
  const para = useParams();

  const encounter_pid = para.encounter_pid;
  const pregnancy_id = para.pregnancy_id;

  const [list, setList] = useState();
  const [vaccineList, setVaccineList] = useState();
  const [load, setLoad] = useState(true);
  const [addDelivery, setAddDelivery] = useState(false);
  const [p, setEncounter] = useState();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks =  async () => {
    const result = await getFromServer(`main/pregnancy-birth-detail/${encounter_pid}/${pregnancy_id}`);
    if(result.status) {
      if (result.data != "") {
        setList(result.data.list);
        setEncounter(result.data.encounter);
      }
      setLoad(false);
    }
  }

  const updateHandler = (list) => {
    setList(list);
  };

  const deleteHandler = async (id)=>{
    if (window.confirm("Do you want to delete these details?")){
      const result = await deleteFromServer(`main/pregnancy-birth-detail/${id}/${pregnancy_id}`);
      if(result.status) {
        if (result.data != "") {
          setList(result.data.list);
        }
        setLoad(false);
      }
    }
  }
  return (
    <>
      <MainContent>
        {!addDelivery && (
          <div className="section-row">
            {p && (
              <div className="section-body">
               <PatientDetailCard list={p} isEncounter={true} />
              </div>
            )}
            <div>
              <h2 className="mt-5 mb-1 ml-2 mb-5">Birth Details</h2>

              <button
                className="btn btn-primary mb-4"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
              <button
                className="btn btn-primary mb-4 pull-right"
                onClick={() => setAddDelivery(!addDelivery)}
              >
                Add new record
              </button>
            </div>
            <div className="card-body">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {load && <Loading />}
                  <table className="table responsive">
                    <thead>
                      <tr style={{ fontSize: "13px" }}>
                        <th scope="col">S/N</th>
                        <th scope="col">Date</th>
                        <th scope="col">Baby</th>
                        <th scope="col">Sex</th>
                        <th scope="col">Birth Weight</th>
                        <th scope="col">Maturity</th>
                        <th scope="col">Birth Condition</th>
                        <th scope="col">Birth Injuries</th>
                        <th scope="col">Mother Condition</th>
                        <th scope="col">Temp</th>
                        <th scope="col">BP</th>
                        <th scope="col">Pulse</th>
                        <th scope="col"></th>
                        {/* <th scope="col" className="text-center">
                          Actions
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {list &&
                        list.birthDetail.map((t, index) => {
                          return (
                            <tr key={index}>
                              <th scope="col">{index + 1}</th>
                              <td scope="col">{t.date}</td>
                              <td scope="col">{t.baby}</td>
                              <td scope="col">{t.sex}</td>
                              <td scope="col">{t.birthweight}</td>
                              <td scope="col">{t.maturity}</td>
                              <td scope="col">{t.conditionofbirth}</td>
                              <td scope="col">{t.birthinjuriies}</td>
                              <td scope="col">{t.mothercondition}</td>
                              <td scope="col">{t.temp}</td>
                              <td scope="col">{t.bp}</td>
                              <td scope="col">{t.pulse}</td>
                              <td scope="col">
                                <button className="btn btn-danger btn-sm" onClick={()=>deleteHandler(t.id)}>Delete</button>
                              </td>
                              {/* <td scope="col"></td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {addDelivery && (
          <PregnancyBirthDetailForm
            back={() => setAddDelivery(false)}
            update={updateHandler}
            encounter_pid={encounter_pid}
            pregnancy_id={pregnancy_id}
          />
        )}
      </MainContent>
    </>
  );
};

export default PregnancyBirthDetail;
