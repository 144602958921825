import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, postToServer } from "../../global/requests";

const AddPatienttoBed = (props) => {
  const [encounter, setEncounter] = useState();
  const type = useRef();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/frontdesk/encounter-admission/${props.station}`);
    if(result.status) setEncounter(result.data);
    console.log("Asds")
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      eId: type.current.value,
      bId: props.bedId,
      nId: props.nursingId,
    };

    const result = await postToServer(`usermgt/add-patient-to-bed`, { data });
    if(result.status) {
      props.success(true);
      props.data(result.data);
      props.close();
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Patient to Bed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label>Patient having encounters</label>
                    <select className="form-select" ref={type}>
                      {encounter &&
                        encounter.map((u) => {
                          return (
                            <option key={u.id} value={u.id}>
                              {u.patient.title}. {u.patient.surname}{" "}
                              {u.patient.firstname} {"("}
                              {u.pid}
                              {")"}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPatienttoBed;
