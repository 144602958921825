import React, { useState, useRef } from 'react';
import Cookies from 'js-cookie';
import Error from "../../ui/error";
import {setUserAccessToken, setPermission, setUserData, initiateAuth} from "../../global/auth";
import { useNavigate } from "react-router-dom";
import { loadChats, connectToWebSocket } from "../../global/ws";

const Login = (props) => {
  // variables
  const username = useRef();
  const password = useRef();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  // functions
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", username.current.value);
    formData.append("password", password.current.value);

    setError(false);

    const url = `${window.baseURL}usermgt/login`;
    const options = {
      method: "POST",
      body: formData,
      processData: false,
      contentType: false,
    //   mimeType: "multipart/form-data",
      headers: {
        "X-CSRFToken": Cookies.get('csrftoken'),
      },
    };
    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        if(data.token) {
          setUserAccessToken(data.token);
          data.permissions.forEach(permission => {
            setPermission(permission);
          });
          setPermission('no_permission');
          setUserData('user_id', data.user_data.user_id);
          setUserData('profile_pic', data.user_data.profile_pic);
          setUserData('first_name', data.user_data.first_name);
          setUserData('last_name', data.user_data.last_name);
          initiateAuth();
          props.setIsLoggedIn(true);
          loadChats(props.setChatLoading);
          // connectToWebSocket(props.setWsConnected);
          navigate("/");
        } else {
          setError(true);
        }
        // console.log("Success:", data);
        // props.close();
        // props.success(true);
        // props.users(data);
      });
  }
  
  return (
    <section className="section">
      <div className="mt-5">
        <div className="row container" style={{margin: "auto"}}>
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="card card-primary">
              <div className="card-header">
                <h4>Login</h4>
              </div>
              <div className="card-body">
                {error && <Error>Userid or password not matched</Error>}
                <form onSubmit={handleSubmit} className="needs-validation" novalidate="">
                  <div className="form-group">
                    <label htmlFor="username">User Id</label>
                    <input id="username" ref={username} type="text" className="form-control" name="username" tabindex="1" required autofocus />
                    <div className="invalid-feedback">
                      Please fill in your username
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-block">
                      <label htmlFor="password" className="control-label">Password</label>
                      <div className="float-right">
                        {/* <a href="auth-forgot-password.html" className="text-small">
                          Forgot Password?
                        </a> */}
                      </div>
                    </div>
                    <input id="password" ref={password} type="password" className="form-control" name="password" tabindex="2" required />
                    <div className="invalid-feedback">
                      please fill in your password
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" name="remember" className="custom-control-input" tabindex="3" id="remember-me" />
                      <label className="custom-control-label" htmlFor="remember-me">Remember Me</label>
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-lg btn-block" tabindex="4">
                      Login
                    </button>
                  </div>
                </form>
                {/* <div className="text-center mt-4 mb-3">
                  <div className="text-job text-muted">Login With Social</div>
                </div>
                <div className="row sm-gutters">
                  <div className="col-6">
                    <a className="btn btn-block btn-social btn-facebook">
                      <span className="fab fa-facebook"></span> Facebook
                    </a>
                  </div>
                  <div className="col-6">
                    <a className="btn btn-block btn-social btn-twitter">
                      <span className="fab fa-twitter"></span> Twitter
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="mt-5 text-muted text-center">
              Don't have an account? <a href="javascript:void(0);">Create One</a>
            </div> */}
          </div>
        </div>
        <div className='mt-5 pt-4'>
          <div className='scroll-div'>
            <h4>
              <span style={{color: "#6777ef"}}>Developed by</span>&nbsp;|&nbsp;
              <span style={{color: "#ffc107"}}>Muhammad Muqrrab</span>&nbsp;|&nbsp;
              <span style={{color: "#ffc107"}}>Muhammad Haseeb</span>&nbsp;|&nbsp;
              <span style={{color: "#ffc107"}}>Huzaifa Ahmad</span>
            </h4>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login