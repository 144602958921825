import React, { useState, useEffect, useRef} from "react";
import MainContent from "../../ui/MainContent";
import ViewItems from "../../modals/Billing/ViewItems";
import AuthCode from "../../modals/Billing/AuthCode";
// import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { getFromServer } from "../../global/requests";

const Authorization = () => {
  const navigate = useNavigate();
  const [showItems, setShowItems] = useState(false);
  const [showAuthCode, setShowAuthCode] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [reload, setReload] = useState(false);
  const [list, setList] = useState();
  const searchText = useRef();

  const handleCloseItems = () => setShowItems(false);
  const handleShowItems = () => setShowItems(true);

  const handleCloseAuthCode = () => setShowAuthCode(false);
  const handleShowAuthCode = () => setShowAuthCode(true);

  useEffect(() => {
    doInitialTasks();
  }, [reload]);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/hmo-authorization`);
    if(result.status) {
      console.log(result.data);
      setList(result.data);
      setIsSearch(null)
      document.getElementById('search').value = ''
      // setDepartment(result.data);
    }
  }

  const authPageHandler = (list) => {
    navigate("/authorization-detail", { state: { list: list } });
  };

  const searchHandler = async (e) => {
    e.preventDefault();
    if (searchText.current.value.length > 0) {
      const result = await getFromServer(`billing/hmo-authorization/${searchText.current.value}`);
      if(result.status) {
        setIsSearch(result.data);
        console.log(result.data);
      }
    }
  };


  return (
    <>
      <MainContent>
        <div className="section-body">
        <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4>Search</h4>
                  <p style={{ color: "gray" }}>
                    Search Patient With Surname and Firstname for all Authorized Patients
                  </p>
                  <form
                    onSubmit={searchHandler}
                    method="GET"
                    className="card-header-form"
                    style={{ display: "flex" }}
                  >
                    <input
                    id="search"
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Search"
                      required
                      ref={searchText}
                    />
                    <button
                      type="submit"
                      style={{ color: "white" }}
                      className="btn btn-info ml-3"
                    >
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {!isSearch && <h3>Pending Authorizations</h3>}
         {!isSearch && <table
            className="table table-responsive responsive mt-4 table-md"
            // style={{ fontSize: "12px" }}
          >
            <thead>
              <tr>
                <th scope="col" className="text-center" width="5%">
                  S/N
                </th>
                <th scope="col" className="text-center" width="10%">
                  Member No
                </th>
                <th scope="col" className="text-center" width="10%">
                  Member Name
                </th>
                <th scope="col" className="text-center" width="5%">
                  Request Date
                </th>
                <th scope="col" className="text-center" width="5%">
                  Approved Date
                </th>
                <th scope="col" className="text-center" width="10%">
                  TAT
                </th>
                <th scope="col" className="text-center" width="10%">
                  Request Type
                </th>
                <th scope="col" className="text-center" width="10%">
                  Remarks
                </th>
                <th scope="col" className="text-center" width="10%">
                  Comments
                </th>
                <th scope="col" className="text-center" width="10%">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((l, i) => {
                  return (
                    <tr key={l.id}>
                      <th className="text-center">{i + 1}</th>
                      <td className="text-center">{l.encounter.patient.pid}</td>
                      <td className="text-center" onClick={()=>authPageHandler(l)} style={{color:'blue', cursor:"pointer"}}>
                        {l.encounter.patient.surname}{" "}
                        {l.encounter.patient.firstname}
                      </td>
                      <td className="text-center text-nowrap">{l.date}</td>
                      <td className="text-center text-nowrap">{l.approveddate}</td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center">{l.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>}
          {isSearch && 
            <button className="btn btn-primary mb-3" onClick={()=>setReload(!reload)}>Pending Authorizations</button>
          }
         {isSearch && <table
            className="table table-responsive responsive table-md"
            // style={{ fontSize: "12px" }}
          >
            <thead>
              <tr>
                <th scope="col" className="text-center" width="5%">
                  S/N
                </th>
                <th scope="col" className="text-center" width="10%">
                  Member No
                </th>
                <th scope="col" className="text-center" width="10%">
                  Member Name
                </th>
                <th scope="col" className="text-center" width="5%">
                  Request Date
                </th>
                <th scope="col" className="text-center" width="5%">
                  Approved Date
                </th>
                <th scope="col" className="text-center" width="10%">
                  TAT
                </th>
                <th scope="col" className="text-center" width="10%">
                  Request Type
                </th>
                <th scope="col" className="text-center" width="10%">
                  Remarks
                </th>
                <th scope="col" className="text-center" width="10%">
                  Comments
                </th>
                <th scope="col" className="text-center" width="10%">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {isSearch &&
                isSearch.map((l, i) => {
                  return (
                    <tr key={l.id}>
                      <th className="text-center">{i + 1}</th>
                      <td className="text-center">{l.encounter.patient.pid}</td>
                      <td className="text-center" onClick={()=>authPageHandler(l)} style={{color:'blue', cursor:"pointer"}}>
                        {l.encounter.patient.surname}{" "}
                        {l.encounter.patient.firstname}
                      </td>
                      <td className="text-center text-nowrap">{l.date}</td>
                      <td className="text-center">{l.approveddate}</td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center">{l.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>}
        </div>
      </MainContent>
      <ViewItems show={showItems} close={handleCloseItems} />
      <AuthCode show={showAuthCode} close={handleCloseAuthCode} />
    </>
  );
};

export default Authorization;
