import axios from "axios";
import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";

const ViewItems = (props) => {
  const uploadHandler = (e) => {
    e.preventDefault();
    // console.log(files.current.files[0]);
    // const formData = new FormData();
    // formData.append("file", files.current.files[0]);

    // const url = `${window.baseURL}main/frontdesk/patient/detail/${props.pid}/upload`;
    // const options = {
    //   method: "PUT",
    //   body: formData,
    //   processData: false,
    //   contentType: false,
    //   mimeType: "multipart/form-data",
    //   headers: {
    //     "X-CSRFToken": Cookies.get('csrftoken'),
    //   },
    // };
    // fetch(url, options);
    // props.close();
    // props.success(true);
  };

  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={props.close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table
            className="table table-responsive responsive"
            style={{ fontSize: "13px" }}
          >
            <thead>
              <tr>
                <th scope="col" width="30%">
                  Items
                </th>
                <th scope="col" width="10%">
                  Quantity
                </th>
                <th scope="col" width="20%">
                  Department
                </th>
                <th scope="col" width="30%">
                  User
                </th>
                <th scope="col" width="5%">
                  Save
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Stensils with Tablets</td>
                <td className="text-center">25</td>
                <td>Radiology</td>
                <td>Bill Clinton Jamhori</td>
                <td>
                  <button
                    className="btn btn-sm btn-primary"
                    style={{ paddingBottom: "5px" }}
                  >
                    save
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewItems;
