import React, { useState, useEffect, useRef } from "react";
import MainContent from "../../ui/MainContent";
import ViewItems from "../../modals/Billing/ViewItems";
import AuthCode from "../../modals/Billing/AuthCode";
// import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Loading from "../../ui/Loading";
import { getFromServer } from "../../global/requests";

const CashierPoint = () => {
  const navigate = useNavigate();
  const [showItems, setShowItems] = useState(false);
  const [showAuthCode, setShowAuthCode] = useState(false);
  const [list, setList] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleCloseItems = () => setShowItems(false);
  const handleShowItems = () => setShowItems(true);

  const handleCloseAuthCode = () => setShowAuthCode(false);
  const handleShowAuthCode = () => setShowAuthCode(true);

  const searchText = useRef();

  const searchHandler = async (e) => {
    e.preventDefault();
    if (searchText.current.value.length > 0) {
      const result = await getFromServer(`billing/review-bill/${searchText.current.value}`);
      if(result.status) {
        setIsSearch(result.data);
        console.log(result.data);
      }
      // if (searchText.current.value.includes(" ")) {
      // } else {
      //   setError(true);
      // }
    }
  };

  useEffect(() => {
    // const url = `${window.baseURL}billing/daily-cashier-report`;
    // axios.get(url).then((res) => {
    //   console.log(res.data);
    // });
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/cashier`);
    if(result.status) {
      console.log(result.data);
      setList(result.data);
      setIsLoading(false);
    }
  }

  const authPageHandler = (list) => {
    navigate("/cashier-detail", { state: { list: list } });
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <div className="col-md-4">
              <h2 className="mb-3">Pending Cashier</h2>
            </div>
            <div className="col-md-4">
              
            </div>
            <div className="col-md-4 text-right">
            <button
                className="btn btn-primary"
                onClick={() => setIsSearch(false)}
              >
                Only Pending Cashiers
              </button>
            </div>
          </div>
          {isLoading && <Loading />}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4>Search</h4>
                  <p style={{ color: "gray" }}>
                    Search Patient With Surname and Firstname for all Cashiers
                  </p>
                  <form
                    onSubmit={searchHandler}
                    method="GET"
                    className="card-header-form"
                    style={{ display: "flex" }}
                  >
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Search"
                      required
                      ref={searchText}
                    />
                    <button
                      type="submit"
                      style={{ color: "white" }}
                      className="btn btn-info ml-3"
                    >
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  S/N
                </th>
                <th scope="col" className="text-center">
                  No
                </th>
                <th scope="col" className="text-center">
                  Image
                </th>
                <th scope="col" className="text-center">
                  Name
                </th>
                <th scope="col" className="text-center">
                  Request Date
                </th>
              </tr>
            </thead>
            {!isSearch && (
              <tbody>
                {list &&
                  list.map((l, i) => {
                    return (
                      <tr key={l.id} className="text-center">
                        <th className="text-center">{i + 1}</th>
                        <td className="text-center">
                          {l.encounter.pid}
                        </td>
                        <td>
                          <img
                            alt="image"
                            width="100px"
                            height="100px"
                            src={
                              window.baseURLuploaded +
                              l.encounter.patient.captureimage
                            }
                            className="img-thumbnail rounded"
                          />
                        </td>
                        <td
                          className="text-center"
                          onClick={() => authPageHandler(l)}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          {l.encounter.patient.title}.{" "}
                          {l.encounter.patient.surname}{" "}
                          {l.encounter.patient.firstname}
                        </td>
                        <td className="text-center">{l.date}</td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
            {isSearch && (
              <tbody>
                {isSearch.map((l, i) => {
                  return (
                    <tr key={l.id} className="text-center">
                      <th className="text-center">{i + 1}</th>
                      <td className="text-center">{l.encounter.pid}</td>
                      <td>
                        <img
                          alt="image"
                          width="100px"
                          height="100px"
                          src={
                            window.baseURLuploaded +
                            l.encounter.patient.captureimage
                          }
                          className="img-thumbnail rounded"
                        />
                      </td>
                      <td
                        className="text-center"
                        onClick={() => authPageHandler(l)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {l.encounter.patient.title}.{" "}
                        {l.encounter.patient.surname}{" "}
                        {l.encounter.patient.firstname}
                      </td>
                      <td className="text-center">{l.date}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </MainContent>
      <ViewItems show={showItems} close={handleCloseItems} />
      <AuthCode show={showAuthCode} close={handleCloseAuthCode} />
    </>
  );
};

export default CashierPoint;
