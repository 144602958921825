import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddService from "../../modals/Billing/AddService";
import AddServiceType from "../../modals/Billing/AddServiceType";
// import axios from "axios";
// import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getFromServer, deleteFromServer } from "../../global/requests";

const Service = () => {
  //   const [isemptyArray, setIsEmptyArray] = useState(false);
  const navigate = useNavigate();
  const [service, setService] = useState();
  const [type, setType] = useState();
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successType, setSuccessType] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseType = () => setShowType(false);
  const handleShowType = () => setShowType(true);

  const updateServiceHandler = (list) => setService(list);
  const typeHandler = (list) => setType(list);
  const successHandler = (bool) => setSuccess(bool);
  const successTypeHandler = (bool) => setSuccessType(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/service`);
    if(result.status) setService(result.data);
  }

  const deleteHandler = async (id) => {
    const result = await deleteFromServer(`billing/service/${id}`);
    if(result.status) setService(result.data);
    setSuccess(false);
    setSuccessType(false);
  };

  const handleShowBilling = () => {
    navigate("/billing-category");
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={() => setSuccess(false)}>
            Service has been added Successfully
          </Success>
        )}
        {successType && (
          <Success>Service Type has been added Successfully</Success>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-md-8">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add New Service
              </button>
              <button
                className="btn btn-primary mb-4 ml-3"
                onClick={handleShowType}
              >
                Add Service Type
              </button>
              <button
                className="btn btn-warning mb-4 ml-3"
                onClick={handleShowBilling}
              >
                Add Billing Category
              </button>
            </div>
            <div className="col-md-4"></div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">#</th>
                  <th scope="col">Service Name</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Service Type</th>
                  <th scope="col">Department</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {service &&
                  service.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td>{a.cost}</td>
                        <td>{a.type}</td>
                        <td>{a.department}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddService
          show={show}
          close={handleClose}
          success={successHandler}
          services={updateServiceHandler}
          stype={type}
        />
      )}
      {showType && (
        <AddServiceType
          show={showType}
          close={handleCloseType}
          success={successTypeHandler}
          types={typeHandler}
        />
      )}
    </>
  );
};

export default Service;
