import React, { useState, useEffect } from "react";
import ObservationChartModel from "../../modals/AdmissionMgt/ObservationChartEdit";
// import axios from "axios";
import Success from "../../ui/success";
// import Cookies from "js-cookie";
import { getFromServer, deleteFromServer } from "../../global/requests";

const ObservationChart = (props) => {
  const [observation, setObservation] = useState(false);
  const [success, setSuccess] = useState(false);
  const [is_edit, setIsEdit] = useState(false);
  const [tempData, setTempData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/observation-chart/${props.encounter_pid}`);
    if(result.status) {
      setObservation(result.data);
      console.log(result.data);
    }
  }

  
  const showHandler = (is_edit, data) => {
    setTempData(data);
    setIsEdit(is_edit);
    setShow(true);
  };

  const updateHandler = (list) => {
    setObservation(list);
  };

  const deleteHandler = async (id) => {
    if (window.confirm("Do you want to delete this?")) {
      const merge_id = props.encounter_pid + "_" + id;
      const result = await deleteFromServer(`usermgt/observation-chart/${merge_id}`);
      if(result.status) setObservation(result.data);
    }
  };

  return (
    <>
     <div className="mt-4  mb-2">
        {success && (
          <Success close={() => setSuccess(false)}>
            Data Initialized Successfully
          </Success>
        )}
        <button className="btn btn-primary" onClick={() => setShow(true)}>
          Add Record
        </button>
      </div>
      <div>
     
      {/* <div className="mt-4 mb-2">
        <button className="btn btn-warning" onClick={() => setShow(true)}>
          Edit
        </button>
      </div> */}
      <div>
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>Date</th>
              <th>
                Temp{" "}
                <span style={{ color: "red" }}>
                  <sup>o</sup>C
                </span>
              </th>
              <th>
                Resp{" "}
                <span style={{ color: "red" }}>
                  {"("}BPM{")"}
                </span>
              </th>
              <th>
                Pulse{" "}
                <span style={{ color: "red" }}>
                  {"("}bts/m{")"}
                </span>
              </th>
              <th>
                Systolic BP{" "}
                <span style={{ color: "red" }}>
                  {"("}mmHg{")"}
                </span>
              </th>
              <th>
                Diastolic BP{" "}
                <span style={{ color: "red" }}>
                  {"("}mmHg{")"}
                </span>
              </th>
              <th>
                FBS{" "}
                <span style={{ color: "red" }}>
                  {"("}mg/dl{")"}
                </span>
              </th>
              <th>
                RBS{" "}
                <span style={{ color: "red" }}>
                  {"("}mg/dl{")"}
                </span>
              </th>
              <th>
                SPO2{" "}
                <span style={{ color: "red" }}>
                  {"("}%{")"}
                </span>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {observation &&
              observation.map((c) => {
                return (
                  <tr key={c.id}>
                    <td>{c.date}</td>
                    <td>{c.temp}</td>
                    <td>{c.resp}</td>
                    <td>{c.pulse}</td>
                    <td>{c.systolicbp}</td>
                    <td>{c.diastolicbp}</td>
                    <td>{c.fbs}</td>
                    <td>{c.rbs}</td>
                    <td>{c.spo2}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => showHandler(true, c)}
                      >
                        Edit
                      </button>
                      <button className="btn btn-sm btn-danger ml-1" onClick={()=>deleteHandler(c.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {show && (
        <ObservationChartModel
          show={show}
          close={() => setShow(false)}
          encounter_pid={props.encounter_pid}
          success={() => setSuccess(true)}
          update={updateHandler}
          is_edit={is_edit}
          tempData={tempData}
        />
      )}
      </div>
    </>
  );
};

export default ObservationChart;
