import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// import axios from "axios";
// import Cookies from "js-cookie";
import { FaBed } from "react-icons/fa";
import { ImBoxRemove } from "react-icons/im";
import { BiTransfer } from "react-icons/bi";
import { AiFillFolderOpen } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import AttendtoPatient from "./AttendtoPatient";
import AdmissionPatient from "../../modals/Doctor/AdmissionPatient";
import TransferPatient from "../../modals/Doctor/TransferPatient";
import Success from "../../ui/success";
import { FaSearch } from "react-icons/fa";
import { deleteFromServer, getFromServer, postToServer } from "../../global/requests";

const OutpatientAppoint = (props) => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchList, setSearchList] = useState();
  const [archieve, setArchieve] = useState(false);
  const [successAttend, setSuccessAttend] = useState(false);
  const [showall, setShowAll] = useState(false);
  const [showAdmission, setShowAdmission] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [waitinglist, setWaitinglist] = useState();
  const [takeover, setTakeover] = useState();
  const [taken, setTaken] = useState([]);
  const [attendpatient, setAttendPatient] = useState([]);
  const [tempPatientPID, setTempPatientPID] = useState();
  const [reloading, setReloading] = useState(false);

  const [tempId, setTempId] = useState();
  const [tempDisengageId, setTempDisengageId] = useState();

  const searchInput = useRef();
  const handleClose = () => setShow(false);
  const handleCloseAdmission = () => setShowAdmission(false);
  const handleCloseTransfer = () => {
    setShowTransfer(false);
    setReloading(!reloading);
  };

  const searchBtnHandler = () => {
    setSearch(true);
    setArchieve(false);
  };
  const archieveBtnHandler = () => {
    setSearch(false);
    setArchieve(true);
  };
  const handleShow = () => setShow(true);
  const successHandler = () => setSuccess(true);

  const dept = props.dept;

  const refreshHandler = () => {
    console.log("first");
    setReloading(!reloading);
    attendpatient([]);
  };

  const searchHandler = async () => {
    const result = await getFromServer(`main/doctor/search/waiting-list/${dept}/${searchInput.current.value}`);
    if(result.status) {
      setSearchList(result.data);
      console.log("search", result.data);
    }
  };

  const dischargeHandler = async (id) => {
    if (window.confirm("Are you sure you want to discharge this patient")) {
      const result = await deleteFromServer(`main/frontdesk/encounter-discharge/${id}`);
      if(result.status) setReloading(!reloading);
    }
  };

  useEffect(() => {
    doInitialTasks();
    setShowAdmission(false)
    setShowTransfer(false)
  }, [reloading]);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/frontdesk/doctor/waitinglist/${dept}`);
    if(result.status) {
      setWaitinglist(result.data.e);
      setTakeover(result.data.takeover);
      setTaken(result.data.d);
      console.log(result.data);
    }
  }

  const reloadHome = () => {
    // const url = `${window.baseURL}main/frontdesk/doctor/waitinglist/${dept}`;
    // axios.get(url).then((res) => {
    //   setWaitinglist(res.data.e);
    //   setTakeover(res.data.takeover);
    //   setTaken(res.data.d);
    // });
  };

  const data_comingback_from_Attend = (res) => {
    console.log("inside outpatient", res);
    setWaitinglist(res.data.e);
    setTakeover(res.data.takeover);
    setTaken(res.data.d);
  };

  const patientTakeoverHandler = async (pid, eid) => {
    if (window.confirm("Do you want to take over patient?")) {
      const old = waitinglist.filter((w) => {
        if (eid != w.id) {
          return { ...w };
        }
      });
      setWaitinglist(old);
      const data = {
        department: dept,
        eid: eid,
        pid: pid,
      };

      const result = await postToServer(`main/frontdesk/doctor/patient/takeover`, { data });
      if(result.status) {
        setShow(false);
        console.log(result.data);
        setTaken(result.data);
      }
    }
  };

  const searchPatientHandler = (enc, pid, eid) => {
    // const check = attendpatient.filter((a) => {
    //   return a == eid;
    // });
    // if (!check[0]) {
    //   setAttendPatient([...attendpatient, eid]);
    //   console.log("checking", [...attendpatient, eid]);
    // }


    if (attendpatient.includes(eid)) {
      setAttendPatient(attendpatient.filter(item => item !== eid));
    } else {
      setAttendPatient([...attendpatient, eid]);
    }



    setTempPatientPID(pid);
    setSuccessAttend(true);
    // console.log([enc]);

    if (taken.includes(enc)) {
      setTaken(taken.filter(item => item !== enc));
    } else {
      setTaken([...taken, enc]);
    }
    // setTaken([enc]);
    // setTimeout(() => {
    //   const element = document.getElementById(`${eid}-tab`);
    //   element.click()
    // }, 200);
  };

  const attendPatientHandler = (encounter_pid, patient_pid) => {
    const check = attendpatient.filter((a) => {
      return a == encounter_pid;
    });
    if (!check[0]) {
      setAttendPatient([...attendpatient, encounter_pid]);
    }
    setTempPatientPID(patient_pid);
    setSuccessAttend(true);
    setTimeout(() => {
      const element = document.getElementById(`${encounter_pid}-tab`);
      element.click();
    }, 200);
  };

  const transferHandler = (id, disengageid) => {
    setShowTransfer(true);
    setTempId(id);
    setTempDisengageId(disengageid);
  };

  const admissionHandler = (id, disengageid) => {
    setShowAdmission(true);
    setTempId(id);
    setTempDisengageId(disengageid);
  };

  const attendPatientDeleteHandler = (pid) => {
    const check = attendpatient.filter((a) => {
      return a != pid;
    });
    setAttendPatient(check);
  };

  return (
    <>
      <div className="row">
        {success && (
          <Success close={() => setSuccess(false)}>
            Patient Admit Sucessfully.
          </Success>
        )}
        {successAttend && (
          <Success close={() => setSuccessAttend(false)}>
            Attend to Patient Folder is Opened.
          </Success>
        )}
        <div className="col-md-4">
          <h4>{dept}</h4>
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4 ">
          <Button
            variant="warning"
            onClick={handleShow}
            className="float-end mb-3"
          >
            View Waiting List
          </Button>
          <Button
            variant="primary"
            onClick={props.back}
            className="float-end mb-3 mr-2"
          >
            Back
          </Button>
        </div>
      </div>

      <div className="card ">
        <div className="card-header">
          <ul
            className="nav nav-tabs card-header-tabs pull-right"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={reloadHome}
              >
                Home
              </a>
            </li>
            {attendpatient &&
              attendpatient.map((a) => {
                return (
                  <li
                    key={a}
                    className="nav-item"
                    style={{ backgroundColor: "#54CA68", color: "white" }}
                  >
                    <a
                      className="nav-link"
                      id={a + "-tab"}
                      data-toggle="tab"
                      href={"#a" + a + "a"}
                      role="tab"
                      aria-controls={"" + a}
                      aria-selected="false"
                      style={{ backgroundColor: "#54CA68", color: "white" }}
                    >
                      {a}{" "}
                      <span
                        style={{
                          position: "relative",
                          top: "-10px",
                          color: "black",
                        }}
                        onClick={() => attendPatientDeleteHandler(a)}
                      >
                        x
                      </span>
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <table className="table responsive">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    <th scope="col">S/N</th>
                    <th scope="col">Sex</th>
                    <th scope="col">Surname</th>
                    <th scope="col">Firstname</th>
                    <th scope="col">Encounter No</th>
                    <th scope="col">Encounter Type</th>
                    <th scope="col">Hospital No</th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {taken &&
                    taken.map((t, index) => {
                      return (
                        <tr key={index}>
                          <th scope="col">{index + 1}</th>
                          <td scope="col">{t.patient.sex}</td>
                          <td scope="col">{t.patient.surname}</td>
                          <td scope="col">{t.patient.firstname}</td>
                          <td scope="col">{t.pid}</td>
                          <td scope="col">{t.type}</td>
                          <td scope="col">{t.patient.hospitalid}</td>
                          <td
                            scope="col"
                            style={{ fontSize: "20px" }}
                            className="text-center"
                          >
                            <AiFillFolderOpen
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Attend to Patient"
                              style={{ color: "green" }}
                              className="mr-1"
                              onClick={() =>
                                attendPatientHandler(t.pid, t.patient.pid)
                              }
                            />
                            <BiTransfer
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Transfer Patient"
                              className="mr-2"
                              onClick={() => transferHandler(t.id, t.id)}
                            />
                            <FaBed
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Admission"
                              className="mr-2"
                              onClick={() => admissionHandler(t.id, t.id)}
                            />
                            <ImBoxRemove
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Discharge Patient"
                              onClick={() => dischargeHandler(t.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {attendpatient &&
              attendpatient.map((a) => {
                return (
                  <div
                    key={a}
                    className="tab-pane fade"
                    id={"a" + a + "a"}
                    role="tabpanel"
                  >
                    <AttendtoPatient
                      pid={a}
                      patient_pid={tempPatientPID}
                      list={taken}
                      reloads={refreshHandler}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <Offcanvas
        placement="end"
        className="mt-5 h-75"
        show={show}
        onHide={handleClose}
        backdrop={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{dept}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <span style={{ color: "blue" }}>{">>"} waiting list</span>
          <div>
            <ul className="list-group">
              {waitinglist &&
                waitinglist.map((e) => {
                  return (
                    <li
                      onClick={() =>
                        patientTakeoverHandler(e.patient.pid, e.id)
                      }
                      className="list-group-item"
                      style={{ cursor: "pointer" }}
                    >
                      <span style={{ color: "darkgreen" }}>
                        <BsCircleFill />{" "}
                      </span>{" "}
                      {e.patient.title}. {e.patient.surname}{" "}
                      {e.patient.firstname} {e.date.split(" ")[0]}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="mt-4">
            <span style={{ color: "blue" }}>{">>"} In Clinic List</span>
            <div>
              {!showall && (
                <ul className="list-group">
                  {takeover &&
                    takeover.slice(0, 5).map((e) => {
                      return (
                        <li
                          className="list-group-item"
                          onClick={() =>
                            searchPatientHandler(e, e.patient.pid, e.pid)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <span style={{ color: "darkgreen" }}>
                            <BsCircleFill />{" "}
                          </span>{" "}
                          {e.patient.title}. {e.patient.surname}{" "}
                          {e.patient.firstname} {e.date}
                        </li>
                      );
                    })}
                </ul>
              )}
              {showall && (
                <ul className="list-group">
                  {takeover &&
                    takeover.map((e) => {
                      return (
                        <li
                          className="list-group-item"
                          onClick={() =>
                            searchPatientHandler(e, e.patient.pid, e.pid)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <span style={{ color: "darkgreen" }}>
                            <BsCircleFill />{" "}
                          </span>{" "}
                          {e.patient.title}. {e.patient.surname}{" "}
                          {e.patient.firstname} {e.date}
                        </li>
                      );
                    })}
                </ul>
              )}
              <div className="text-center mt-3">
                {!showall && (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => setShowAll(true)}
                  >
                    show all
                  </button>
                )}
                {showall && (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => setShowAll(false)}
                  >
                    Hide
                  </button>
                )}
              </div>
            </div>
          </div>
          <table
            className="table table-bordered table-md mt-2 text-center"
            style={{ cursor: "pointer" }}
          >
            <thead>
              <tr>
                <th onClick={searchBtnHandler}>Vikas</th>
                <th onClick={archieveBtnHandler}>Archieve</th>
              </tr>
            </thead>
          </table>
          {search && (
            <div className="mt-3">
              <div className="row">
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    ref={searchInput}
                  />
                </div>
                <div className="col-md-4">
                  <button className="btn btn-primary" onClick={searchHandler}>
                    <FaSearch />
                  </button>
                </div>
              </div>
              <div>
                <ul className="list-group mt-2">
                  {searchList &&
                    searchList.map((e) => {
                      return (
                        <li
                          onClick={() =>
                            searchPatientHandler(e, e.patient.pid, e.pid)
                          }
                          className="list-group-item"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={
                              e.isDischarged
                                ? { color: "red" }
                                : { color: "darkgreen" }
                            }
                          >
                            <BsCircleFill />
                          </span>{" "}
                          {e.patient.title}. {e.patient.surname}{" "}
                          {e.patient.firstname} {e.date}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
          {archieve && (
            <div className="mt-3">
              <div className="row">
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    ref={searchInput}
                  />
                </div>
                <div className="col-md-4">
                  <button className="btn btn-primary" onClick={searchHandler}>
                    <FaSearch />
                  </button>
                </div>
              </div>
              <div>
                <ul className="list-group mt-2">
                  {searchList &&
                    searchList.map((e) => {
                      return (
                        <li
                          onClick={() =>
                            searchPatientHandler(e, e.patient.pid, e.pid)
                          }
                          className="list-group-item"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={
                              e.isDischarged
                                ? { color: "red" }
                                : { color: "darkgreen" }
                            }
                          >
                            <BsCircleFill />{" "}
                          </span>{" "}
                          {e.patient.title}. {e.patient.surname}{" "}
                          {e.patient.firstname} {e.date}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      {showTransfer && (
        <TransferPatient
          show={showTransfer}
          close={handleCloseTransfer}
          success={successHandler}
          id={tempId}
          disengageid={tempDisengageId}
        />
      )}
      {showAdmission && (
        <AdmissionPatient
          show={showAdmission}
          close={handleCloseAdmission}
          success={successHandler}
          id={tempId}
          disengageid={tempDisengageId}
        />
      )}
    </>
  );
};

export default OutpatientAppoint;
