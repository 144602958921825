import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer } from "../../global/requests";

const ViewAdminister = (props) => {
  const [administerDetail, setAdministerDetail] = useState();

  useEffect(() => {
    const url = props.url;    
    doInitialTasks(url);
  }, []);

  const doInitialTasks = async (url) => {
    const result = await getFromServer(url);
    if(result.status) {
      setAdministerDetail(result.data);
    }
  }

  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Administer Records</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {administerDetail && (
            <table class="table table-bordered table-md">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Date / Time</th>
                  <th>Administer Quantity</th>
                </tr>
              </thead>
              <tbody>
                {administerDetail.map((d, i) => {
                  return (
                    <tr>
                      <td>{i+1}</td>
                      <td>{d.administerRecord}</td>
                      <td>{d.administerQuantity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewAdminister;
