import React, { useState, useEffect } from "react";
import AppointmentModal from "../../modals/FrontDesk/ApponitmentModal";
import Success from "../../ui/success";
import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";

const PatientList = (props) => {
  let navigate = useNavigate();
  const [isemptyArray, setIsEmptyArray] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tempId, setTempId] = useState();
  const patient = props.data;

  const handleClose = () => setShowAppointment(false);
  const handleShow = (pid) => {
    setShowAppointment(true);
    setTempId(pid);
  };
  const successHandler = (bool) => setSuccess(bool);
  const viewbtndetailHandler = (pid) => {
    navigate(`/patient-detail/${pid}`);
  };

  const selectHandler = (pid, i) => {
    const patientDetail = patient[i]
    const activate = patientDetail.activation;
    var date = new Date();
    if (activate.length != 0) {
      var date = new Date();
      var oldDate = activate[0].date.split("-");
      if (parseInt(oldDate[1]) >= date.getMonth() + 1) {
        if (parseInt(oldDate[2]) >= date.getDate()) {
          navigate(`/encounter/${pid}`, {
            state: { patient: patientDetail },
          });
        } else {
          navigate(`/activation/${pid}`, {
            state: { patient: patientDetail },
          });
        }
      } else {
        navigate(`/activation/${pid}`, {
          state: { patient: patientDetail },
        });
      }
    } else {
      navigate(`/activation/${pid}`, {
        state: { patient: patientDetail },
      });
    }



    // if (!patient[i].isactivated) {
    //   navigate(`/activation/${pid}`, {
    //     state: { patient: patient[i] },
    //   });
    // } else {
    //   navigate(`/encounter/${pid}`, {
    //     state: { patient: patient[i] },
    //   });
    // }
  };

  useEffect(() => {
    if (patient.length <= 0) {
      setIsEmptyArray(true);
    } else {
      setIsEmptyArray(false);
    }
    setSuccess(false);
  }, [patient]);

  return (
    <>
      <div className="col-md-12">
        {success && (
          <Success>
            Appointment has been added Successfully. Click
            <Link to="/appointments" style={{ color: "blue" }}>
              {" "}
              here{" "}
            </Link>{" "}
            to check all appointments.
          </Success>
        )}
        <div className="card">
          <div className="card-body">
            <h4>Search Result</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Photo</th>
                  <th>PID</th>
                  <th>Name</th>
                  <th>Sex</th>
                  <th>Hospital Id</th>
                  <th>Phone No.</th>
                  {/* <th>Company</th> */}
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {isemptyArray && <h4>No Data Found</h4>}
                {patient &&
                  patient.map((p, index) => {
                    return (
                      <tr>
                        <td>
                          <img
                            alt="Image"
                            className="mr-3 rounded-circle"
                            width="50"
                            src={window.baseURLuploaded + p.captureimage}
                          />
                        </td>
                        <td>{p.pid}</td>
                        <td>
                          {p.surname} {p.firstname}
                        </td>
                        <td>{p.sex}</td>
                        <td>{p.hospitalid}</td>
                        <td>{p.phonenumber}</td>
                        {/* <td>{p.company}</td> */}
                        <td className="text-center">
                          <button
                            href="#"
                            className="btn btn-info mr-2"
                            style={{ color: "white" }}
                            onClick={() => selectHandler(p.pid, index)}
                          >
                            Select
                          </button>

                          <button
                            type="button"
                            style={{ color: "white" }}
                            className="btn btn-info upload mr-2"
                            onClick={() => handleShow(p.pid)}
                          >
                            APPOINTMENT
                          </button>

                          <button
                            className="btn btn-info"
                            style={{ color: "white" }}
                            onClick={() => viewbtndetailHandler(p.pid)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AppointmentModal
        show={showAppointment}
        close={handleClose}
        success={successHandler}
        pid={tempId}
      />
    </>
  );
};

export default PatientList;
