import React, { useEffect, useState } from "react";
import MainContent from "../../ui/MainContent";
import { getFromServer } from "../../global/requests";
import PatientSmsDetail from "../../modals/sms/PatientSmsDetail";

const msgTypes = {0: 'General', 1: 'Registration', 2: 'Birthday'};
const msgStatus = {0: 'Pending', 1: 'Sent', 2: 'Failed'};

const SmsHistory = () => {
  const [smsListRows, setSmsListRows] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [msgId, setMsgId] = useState(0);
  useEffect(() => {
    loadSmsHistory();
  }, []);

  const loadSmsHistory = async () => {
    // setLoading(true);
    const result = await getFromServer(`sms/patient-sms-history`);
    if (result.status) {
      setSmsListRows(result.data);
    }
    // setLoading(false);
  };

  const closeSmsDetail = () => {
    setShowDetail(false);
    setMsgId(0);
  }
  const openSmsDetail = (id) => {
    setShowDetail(true);
    setMsgId(id);
  }

  return (
    <>
    <MainContent>
      <div className="section-body">
        <div className="row">
          <div className="col-12">
            <div className="card" style={{ minHeight: "85vh" }}>
              <div className="card-header">
                <h4>SMS History</h4>
                {/* <div className="card-header-form">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      <div className="input-group-btn">
                        <button className="btn btn-primary">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div> */}
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th className="text-center">#</th>
                        <th>Message Type</th>
                        <th>Status</th>
                        <th>Receivers</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                        {smsListRows && smsListRows.map(row =>
                          <tr>
                            <td className="p-0 text-center">1</td>
                            <td>{msgTypes[row[1]]}</td>
                            <td>
                              <div className={row[2] == 1 ? 'badge badge-success' : 'badge badge-danger'}>
                                {msgStatus[row[2]]}
                              </div>
                            </td>
                            <td>{row[3]}</td>
                            <td>{row[4]}</td>
                            <td>
                            <button onClick={() => openSmsDetail(row[0])} className="btn btn-primary">
                                Detail
                            </button>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
    {showDetail && <PatientSmsDetail show={showDetail} close={closeSmsDetail} id={msgId} />}
    </>
  );
};

export default SmsHistory;
