import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import ProgressBar from 'react-bootstrap/ProgressBar';

const FileProgressModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.close}>
      <Modal.Header>
        <Modal.Title>Uploading File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar animated now={props.fileData.percent} />
        <p style={{textAlign: "center", marginTop: "10px"}}><b>Uploading {props.fileData.name}</b></p>
      </Modal.Body>
    </Modal>
  );
};

export default FileProgressModal;
