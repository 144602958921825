import React, { useState, useEffect, useRef } from "react";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
import Loading from "../../ui/Loading"
// import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getFromServer, postToServer } from "../../global/requests";

const Dressing = () => {
  const navigate = useNavigate();
  const [disengage, setDisengage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [searchList, setSearchList] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const search = useRef();
  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async() => {
    const result = await getFromServer(`pharmacy/nursing-dressing`);
    if(result.status) {
      setDisengage(result.data);
      console.log(result.data);
      setIsLoading(false);
    }
  }

  const backHandler = () =>{
    setIsSearch(false)
    document.getElementById('search').value = ""
  }
  const detailHandler = (list) => {
    navigate("/dressing-detail", { state: { list: list } });
  };

  const searchHandler = async () => {
    const data = {
      search: search.current.value,
    };
    const result = await postToServer(`pharmacy/nursing-dressing`, { data });
    if(result.status) {
      console.log(result.data)
      setSearchList(result.data);
      setIsSearch(true)
      document.getElementById('search').value = ""
    }
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <div className="col-md-12">
              <div className="card author-box card-primary">
                <div className="card-body ">
                  <h2 className="py-3">Dressing / Procedure</h2>
                  <div className="row">
                    <div className="col-md-4 mb-4 d-flex">
                      <input
                        type="text"
                        className="form-control"
                        id="search"
                        placeholder="Search using PID no. or name for all Procedures"
                        ref={search}
                      />
                      <button
                        type="button"
                        className="btn btn-primary ml-3"
                        onClick={searchHandler}
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                  </div>
                  {isLoading && <Loading />}

                  {!isSearch && (
                    <div className="tablexflow">
                      <table className="table responsive">
                        <thead>
                          <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Company</th>
                            <th scope="col">Hospital Id</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {disengage &&
                            disengage.map((a, index) => {
                              return (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                     {a.patient.surname} {a.patient.firstname}
                                  </td>
                                  <td>{a.encounter.profile.split('=>')[0]}</td>
                                  <td>{a.patient.hospitalid}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-sm btn-primary"
                                      style={{ color: "white" }}
                                      onClick={() => detailHandler(a)}
                                    >
                                      Detail
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {isSearch && (
                    <div>
                      <div>
                        <button className="btn btn-primary mb-3" onClick={backHandler}>Back to Pending Procedures</button>
                      </div>
                    <div className="tablexflow">
                      <table className="table responsive">
                        <thead>
                        <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Company</th>
                            <th scope="col">Hospital Id</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchList &&
                            searchList.flat().map((a, index) => {
                              return (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    {a.patient.surname} {a.patient.firstname}
                                  </td>
                                  <td>{a.encounter.profile.split('=>')[0]}</td>
                                  <td>{a.patient.hospitalid}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-sm btn-primary"
                                      style={{ color: "white" }}
                                      onClick={() => detailHandler(a)}
                                    >
                                      Detail
                                    </button>
                                  </td>
                              </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default Dressing;
