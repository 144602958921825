import React, { useState, useRef } from "react";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
import AddDeposit from "../../modals/Cashier/AddDeposit";
import Success from "../../ui/success";
import { getFromServer } from "../../global/requests";

const Deposit = () => {
  const [list, setList] = useState();
  const [temp, setTemp] = useState();
  const [index, setIndex] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const searchText = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateHandler = (dic) => {
    const newVal = [...list];
    newVal[index] = dic;
    setList(newVal);
  };
  const successHandler = (bool) => setSuccess(bool);

  const searchHandler = async (e) => {
    e.preventDefault();
    if (searchText.current.value.length > 0) {
      const result = await getFromServer(`main/patient-wallet/${searchText.current.value}`);
      if(result.status) {
        setList(result.data);
        console.log(result.data);
      }
    }
  };

  const depositHandler = (id, i) => {
    setTemp(id);
    setIndex(i);
    setShow(true);
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4>Search</h4>
                  <p style={{ color: "gray" }}>
                    Search Patient With Surname and Firstname or PID number
                  </p>
                  <form
                    onSubmit={searchHandler}
                    method="GET"
                    className="card-header-form"
                    style={{ display: "flex" }}
                  >
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Search"
                      required
                      ref={searchText}
                    />
                    <button
                      type="submit"
                      style={{ color: "white" }}
                      className="btn btn-info ml-3"
                    >
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {success && (
            <Success close={() => setSuccess(false)}>
              Wallet Balance added Successfully
            </Success>
          )}
          {list && (
            <div>
              <table className="table table-bordered table-stripped table-md">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Sur Name</th>
                    <th>First Name</th>
                    <th>Owned (-)</th>
                    <th>Wallet</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((l, i) => {
                    return (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        <td>
                          <img
                            alt="image"
                            width="100px"
                            height="100px"
                            src={window.baseURLuploaded + l.captureimage}
                            className="img-thumbnail rounded"
                          />
                        </td>
                        <td>{l.title}</td>
                        <td>{l.surname}</td>
                        <td>{l.firstname}</td>
                        <td>
                          <span class="badge badge-danger fs-5">{l.minuswallet}</span>
                        </td>
                        <td>
                          <span class="badge badge-primary fs-5">{l.wallet}</span>
                        </td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => depositHandler(l.id, i)}
                          >
                            Add Balance
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </MainContent>
      {show && (
        <AddDeposit
          show={show}
          close={handleClose}
          update={updateHandler}
          success={successHandler}
          id={temp}
        />
      )}
    </>
  );
};

export default Deposit;
