import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import AutoLabTest from "./AutoLabTest";
import { getFromServer, postToServer } from "../../global/requests";

const AddGroupTest = (props) => {
  const [testCategory, setTestCategory] = useState();
  const [singleTest, setSingleTest] = useState();
  const [tags, setTags] = useState([]);
  const [save, setSave] = useState(false);

  const name = useRef();
  const type = useRef();
  const category = useRef();

  const getTags = (e) => {
    setTags(e);
  };

  useEffect(() => {
    var url = "";
    if (props.rad) {
      url = `diagnosis/rad-single-test`;
    } else {
      url = `diagnosis/test-category`;
      loadCats(url);
      url = `diagnosis/single-test`;
    }
    loadTests(url);
  }, []);

  const loadCats = async (url) => {
    const result = await getFromServer(url);
    if(result.status) setTestCategory(result.data);
  }
  const loadTests = async (url) => {
    const result = await getFromServer(url);
    if(result.status) setSingleTest(result.data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    var lab = true;
    var url = "";
    var data = {}
    if (props.rad) {
      lab = false;
      url = `diagnosis/rad-group-test`;
      data = {
        testname: name.current.value,
        testtype: type.current.value,
        tags: tags,
        is_laboratory: lab,
        is_single_test: false,
      };
    } else {
      url = `diagnosis/group-test`;
      data = {
        testname: name.current.value,
        category: category.current.value,
        tags: tags,
        is_laboratory: lab,
        is_single_test: false,
      };
    }

    const result = await postToServer(url, { data });
    if(result.status) {
      props.success(true);
      props.update(result.data);
      props.close();
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Group Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Name of Test
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={name}
                    />
                  </div>
                </div>
                {props.rad && (
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="service" className="col-form-label">
                        Test Type
                      </label>
                      <select className="form-select" ref={type}>
                        <option value="X-ray">X-ray</option>
                        <option value="ECG">ECG</option>
                        <option value="CT-scan">CT scan</option>
                        <option value="Ultrasound">Ultrasound</option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Tests
                    </label>
                    {singleTest && (
                      <AutoLabTest
                        options={singleTest}
                        tag={(e) => getTags(e)}
                        removeTag={save}
                        type="Drug"
                      />
                    )}
                  </div>
                </div>
                {!props.rad && (
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="service" className="col-form-label">
                        Test Category
                      </label>
                      <select className="form-select" ref={category}>
                        {testCategory &&
                          testCategory.map((t) => {
                            return (
                              <option key={t.id} value={t.category}>
                                {t.category}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddGroupTest;
