import React, { useState, useEffect, useRef } from "react";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Cookies from "js-cookie";
// import ClaimForm from "./PrintClaimForm";
import "../../../assets/css/claimform.css";

import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer, postToServer } from "../../global/requests";

const AuthPage = () => {
  const para = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [complain, setComplain] = useState();
  const [diagnosis, setDiagnosis] = useState();
  const [count, setCount] = useState(1);
  const [claimForm, setclaimForm] = useState(false);

  useEffect(() => {
    loadHMODetails();
    loadHMOAuth();
  }, []);

  const loadHMODetails = async () => {
    const result = await getFromServer(`main/hmo-detail/${para.state.list.encounter.pid}`);
    if(result.status) {
      setComplain(result.data.complain);
      setDiagnosis(result.data.diagnosis);
    }
  }
  const loadHMOAuth = async () => {
    const result = await getFromServer(`billing/single-hmo-authorization/${para.state.list.id}`);
    if(result.status) setList(result.data);
  }

  // window.onbeforeprint = (event) => {
  //   document.getElementById("maincontent").classList.add("hide");
  //   document.getElementById("maincontent2").classList.add("hide");
  //   document.getElementById("print-claim-form").classList.remove("hide");
  // };

  // window.onafterprint = (event) => {
  //   document.getElementById("maincontent").classList.remove("hide");
  //   document.getElementById("maincontent2").classList.remove("hide");
  //   document.getElementById("print-claim-form").classList.add("hide");
  // };

  const printHandler = () => {
    // document.getElementById("maincontent").classList.add("hide");
    // document.getElementById("maincontent2").classList.add("hide");
    // document.getElementById("print-claim-form").classList.remove("hide");
    // window.print();
    
    window.open(`${window.baseURL}main/hmo-form-print/${para.state.list.id}`, '_blank')

  };

  const unitCostHandler = (i, cost, itemName) => {
    if (itemName == "drug") {
      const newData = { ...list };
      newData.drug_auth[i].unitcost = cost;
      setList(newData);
    } else if (itemName == "procedure") {
      const newData = { ...list };
      newData.procedure_auth[i].unitcost = cost;
      setList(newData);
    } else if (itemName == "service") {
      const newData = { ...list };
      newData.service_auth[0].unitcost = cost;
      setList(newData);
    } else if (itemName == "room") {
      const newData = { ...list };
      newData.room_auth[0].unitcost = cost;
      setList(newData);
    } else if (itemName == "test") {
      const newData = { ...list };
      newData.test_auth[i].unitcost = cost;
      setList(newData);
    } else if (itemName == "fluid") {
      const newData = { ...list };
      newData.fluid_auth[i].unitcost = cost;
      setList(newData);
    } else if (itemName == "vaccine") {
      const newData = { ...list };
      newData.vaccine_auth[i].unitcost = cost;
      setList(newData);
    }
  };

  const authCodeHandler = (i, code, itemName) => {
    if (itemName == "drug") {
      const newData = { ...list };
      newData.drug_auth[i].authcode = code;
      setList(newData);
    } else if (itemName == "procedure") {
      const newData = { ...list };
      newData.procedure_auth[i].authcode = code;
      setList(newData);
    } else if (itemName == "service") {
      const newData = { ...list };
      newData.service_auth[0].authcode = code;
      setList(newData);
    } else if (itemName == "room") {
      const newData = { ...list };
      newData.room_auth[0].authcode = code;
      setList(newData);
    } else if (itemName == "test") {
      const newData = { ...list };
      newData.test_auth[i].authcode = code;
      setList(newData);
    } else if (itemName == "fluid") {
      const newData = { ...list };
      newData.fluid_auth[i].authcode = code;
      setList(newData);
    } else if (itemName == "vaccine") {
      const newData = { ...list };
      newData.vaccine_auth[i].authcode = code;
      setList(newData);
    }
  };

  const validityHandler = (i, validity, itemName) => {
    if (itemName == "drug") {
      const newData = { ...list };
      newData.drug_auth[i].validity = validity;
      setList(newData);
      console.log(newData);
    } else if (itemName == "procedure") {
      const newData = { ...list };
      newData.procedure_auth[i].validity = validity;
      setList(newData);
    } else if (itemName == "service") {
      const newData = { ...list };
      newData.service_auth[0].validity = validity;
      setList(newData);
    } else if (itemName == "room") {
      const newData = { ...list };
      newData.room_auth[0].validity = validity;
      setList(newData);
    } else if (itemName == "test") {
      const newData = { ...list };
      newData.test_auth[i].validity = validity;
      setList(newData);
    } else if (itemName == "fluid") {
      const newData = { ...list };
      newData.fluid_auth[i].validity = validity;
      setList(newData);
    } else if (itemName == "vaccine") {
      const newData = { ...list };
      newData.vaccine_auth[i].validity = validity;
      setList(newData);
    }
  };

  const authHandler = (i, auth, itemName) => {
    if (itemName == "drug") {
      const newData = { ...list };
      newData.drug_auth[i].auth = auth;
      setList(newData);
    } else if (itemName == "procedure") {
      const newData = { ...list };
      newData.procedure_auth[i].auth = auth;
      setList(newData);
    } else if (itemName == "service") {
      const newData = { ...list };
      newData.service_auth[0].auth = auth;
      setList(newData);
    } else if (itemName == "room") {
      const newData = { ...list };
      newData.room_auth[0].auth = auth;
      setList(newData);
    } else if (itemName == "test") {
      const newData = { ...list };
      newData.test_auth[i].auth = auth;
      setList(newData);
    } else if (itemName == "fluid") {
      const newData = { ...list };
      newData.fluid_auth[i].auth = auth;
      setList(newData);
    } else if (itemName == "vaccine") {
      const newData = { ...list };
      newData.vaccine_auth[i].auth = auth;
      setList(newData);
    }
  };
  
  
  const notAuthHandler = (i, auth, itemName) => {
    if (itemName == "drug") {
      const newData = { ...list };
      newData.drug_auth[i].notauth = auth;
      setList(newData);
    } else if (itemName == "procedure") {
      const newData = { ...list };
      newData.procedure_auth[i].notauth = auth;
      setList(newData);
    } else if (itemName == "service") {
      const newData = { ...list };
      newData.service_auth[0].notauth = auth;
      setList(newData);
    } else if (itemName == "room") {
      const newData = { ...list };
      newData.room_auth[0].notauth = auth;
      setList(newData);
    } else if (itemName == "test") {
      const newData = { ...list };
      newData.test_auth[i].notauth = auth;
      setList(newData);
    } else if (itemName == "fluid") {
      const newData = { ...list };
      newData.fluid_auth[i].notauth = auth;
      setList(newData);
    } else if (itemName == "vaccine") {
      const newData = { ...list };
      newData.vaccine_auth[i].notauth = auth;
      setList(newData);
    }
  };

  const paymentHandler = (i, payment, itemName) => {
    if (itemName == "drug") {
      const newData = { ...list };
      newData.drug_auth[i].payment = payment;
      setList(newData);
    } else if (itemName == "procedure") {
      const newData = { ...list };
      newData.procedure_auth[i].payment = payment;
      setList(newData);
    } else if (itemName == "service") {
      const newData = { ...list };
      newData.service_auth[0].payment = payment;
      setList(newData);
    } else if (itemName == "room") {
      const newData = { ...list };
      newData.room_auth[0].payment = payment;
      setList(newData);
    } else if (itemName == "test") {
      const newData = { ...list };
      newData.test_auth[i].payment = payment;
      setList(newData);
    } else if (itemName == "fluid") {
      const newData = { ...list };
      newData.fluid_auth[i].payment = payment;
      setList(newData);
    } else if (itemName == "vaccine") {
      const newData = { ...list };
      newData.vaccine_auth[i].payment = payment;
      setList(newData);
    }
  };

  const commentHandler = (i, comment, itemName) => {
    if (itemName == "drug") {
      const newData = { ...list };
      newData.drug_auth[i].comment = comment;
      setList(newData);
    } else if (itemName == "procedure") {
      const newData = { ...list };
      newData.procedure_auth[i].comment = comment;
      setList(newData);
    } else if (itemName == "service") {
      const newData = { ...list };
      newData.service_auth[0].comment = comment;
      setList(newData);
    } else if (itemName == "room") {
      const newData = { ...list };
      newData.room_auth[0].comment = comment;
      setList(newData);
    } else if (itemName == "test") {
      const newData = { ...list };
      newData.test_auth[i].comment = comment;
      setList(newData);
    } else if (itemName == "fluid") {
      const newData = { ...list };
      newData.fluid_auth[i].comment = comment;
      setList(newData);
    } else if (itemName == "vaccine") {
      const newData = { ...list };
      newData.vaccine_auth[i].comment = comment;
      setList(newData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      list: list,
    };
    console.log(data);
    const result = await postToServer(`billing/hmo-authorization-detail`, { data });
    if(result.status) navigate("/authorization");
  };
  return (
    <>
    {/* {!claimForm && (
        <div
          id="print-claim-form"
          className="claimform-print-container hide"
          onbeforePrint="as"
        >
          <ClaimForm list={list} complain={complain} diagnosis={diagnosis}/>
        </div>
      )} */}
      {!claimForm && (
        <MainContent>
          {list && (
            <div className="section-row" id="maincontent">
              <h1>Authorization Details</h1>
              <div className="row mt-4">  
              <PatientDetailCard list={list.encounter} isEncounter={true} />
              <table className="table table-borderless">
                <tbody>
                  <tr className="mt-5">
                    <th width="70px">Complains</th>
                    <td colSpan="6">
                      {complain != null ? complain.ComplainDetails.map((c) => {
                        return (
                          <>
                            {c.ComplainDetailsTag.map((d) => {
                              return <>{d.complain + " , "}</>;
                            })}
                          </>
                        );
                      }):<>Nothing Found</>}
                    </td>
                  </tr>
                  <tr className="mt-5">
                    <th width="70px">Diagnosis</th>
                    <td colSpan="6">
                      {diagnosis != null ? diagnosis.diagnosisDetail.map((d) => {
                        return <>{d.title + " , "}</>
                      }) : <>Nothing Found</>}
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <table className="table table-sm table-bordered table-striped">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    {/* <th width="5">S/N</th> */}
                    <th width="10">Item Name</th>
                    <th width="5">Total</th>
                    <th width="10">Unit Cost</th>
                    <th width="10">Initial Status</th>
                    <th width="10">Authorization Status</th>
                    <th width="10">Created by</th>
                    <th width="5" className="text-center">
                      Auth
                    </th>
                    <th width="5" className="text-center">
                      Not Auth
                    </th>
                    <th width="10">Authorization Code</th>
                    <th width="10">Validity</th>
                    <th width="5" className="text-center">
                      Request Payment
                    </th>
                    <th width="10">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {list.test_auth &&
                    list.test_auth.map((d, i) => {
                      return (
                        <tr key={i}>
                          {/* <td width="5"></td> */}
                          <td>{d.test.testname}</td>
                          <td width="5">1</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.unitcost}
                              onChange={(event) =>
                                unitCostHandler(i, event.target.value, "test")
                              }
                            />
                          </td>
                          <td></td>
                          <td>{d.status}</td>
                          <td></td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.auth}
                              onChange={(event) =>
                                authHandler(i, event.target.checked, "test")
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.notauth}
                              onChange={(event) =>
                                notAuthHandler(i, event.target.checked, "test")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.authcode}
                              onChange={(event) =>
                                authCodeHandler(i, event.target.value, "test")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(event) =>
                                validityHandler(i, event.target.value, "test")
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.payment}
                              onChange={(event) =>
                                paymentHandler(i, event.target.checked, "test")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Comment"
                              className="form-control"
                              value={d.comment}
                              onChange={(event) =>
                                commentHandler(i, event.target.value, "test")
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  {list.drug_auth &&
                    list.drug_auth.map((d, i) => {
                      return (
                        <tr key={i}>
                          {/* <td width="5"></td> */}
                          {/* <td width="10"></td> */}
                          <td width="60">
                            {d.drug.prescriptiondetails.prescription_details_tag_set.map(
                              (t) => {
                                return <>{t.drugtags.brandname}</>;
                              }
                            )}
                          </td>
                          <td width="5">{d.drug.prescriptiondetails.total}</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.unitcost}
                              onChange={(event) =>
                                unitCostHandler(i, event.target.value, "drug")
                              }
                            />
                          </td>
                          <td></td>
                          <td>{d.status}</td>
                          <td></td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.auth}
                              onChange={(event) =>
                                authHandler(i, event.target.checked, "drug")
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.notauth}
                              onChange={(event) =>
                                notAuthHandler(i, event.target.checked, "drug")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.authcode}
                              onChange={(event) =>
                                authCodeHandler(i, event.target.value, "drug")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(event) =>
                                validityHandler(i, event.target.value, "drug")
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.payment}
                              onChange={(event) =>
                                paymentHandler(i, event.target.checked, "drug")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Comment"
                              className="form-control"
                              value={d.comment}
                              onChange={(event) =>
                                commentHandler(i, event.target.value, "drug")
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                    
                  {list.vaccine_auth &&
                    list.vaccine_auth.map((d, i) => {
                      return (
                        <tr key={i}>
                          {/* <td width="5"></td> */}
                          {/* <td width="10"></td> */}
                          <td width="60">
                            {d.vaccine.name}
                          </td>
                          <td width="5">1</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.unitcost}
                              onChange={(event) =>
                                unitCostHandler(i, event.target.value, "vaccine")
                              }
                            />
                          </td>
                          <td></td>
                          <td>{d.status}</td>
                          <td></td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.auth}
                              onChange={(event) =>
                                authHandler(i, event.target.checked, "vaccine")
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.notauth}
                              onChange={(event) =>
                                notAuthHandler(i, event.target.checked, "vaccine")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.authcode}
                              onChange={(event) =>
                                authCodeHandler(i, event.target.value, "vaccine")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(event) =>
                                validityHandler(i, event.target.value, "vaccine")
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.payment}
                              onChange={(event) =>
                                paymentHandler(i, event.target.checked, "vaccine")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Comment"
                              className="form-control"
                              value={d.comment}
                              onChange={(event) =>
                                commentHandler(i, event.target.value, "vaccine")
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                    {list.fluid_auth &&
                    list.fluid_auth.map((d, i) => {
                      return (
                        <tr key={i}>
                          {/* <td width="5"></td> */}
                          {/* <td width="10"></td> */}
                          <td width="60">
                            {d.fluid.fluid}
                          </td>
                          <td width="5">{d.qty}</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.unitcost}
                              onChange={(event) =>
                                unitCostHandler(i, event.target.value, "fluid")
                              }
                            />
                          </td>
                          <td></td>
                          <td>{d.status}</td>
                          <td></td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.auth}
                              onChange={(event) =>
                                authHandler(i, event.target.checked, "fluid")
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.notauth}
                              onChange={(event) =>
                                notAuthHandler(i, event.target.checked, "fluid")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.authcode}
                              onChange={(event) =>
                                authCodeHandler(i, event.target.value, "fluid")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(event) =>
                                validityHandler(i, event.target.value, "fluid")
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.payment}
                              onChange={(event) =>
                                paymentHandler(i, event.target.checked, "fluid")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Comment"
                              className="form-control"
                              value={d.comment}
                              onChange={(event) =>
                                commentHandler(i, event.target.value, "fluid")
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  {list.procedure_auth &&
                    list.procedure_auth.map((d, i) => {
                      return (
                        <tr>
                          {/* <td width="5"></td> */}
                          {/* <td width="10"></td> */}
                          <td width="60">
                            {d.procedure.procedureDetailFK.ProcedureDetailsTag.map(
                              (t) => {
                                return <>{t.procedure}</>;
                              }
                            )}
                          </td>
                          <td width="5">
                            {d.procedure.procedureDetailFK.total}
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.unitcost}
                              onChange={(event) =>
                                unitCostHandler(
                                  i,
                                  event.target.value,
                                  "procedure"
                                )
                              }
                            />
                          </td>
                          <td></td>
                          <td>{d.status}</td>
                          <td></td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.auth}
                              onChange={(event) =>
                                authHandler(
                                  i,
                                  event.target.checked,
                                  "procedure"
                                )
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.notauth}
                              onChange={(event) =>
                                notAuthHandler(i, event.target.checked, "procedure")
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={d.authcode}
                              onChange={(event) =>
                                authCodeHandler(
                                  i,
                                  event.target.value,
                                  "procedure"
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              className="form-control"
                              onChange={(event) =>
                                validityHandler(
                                  i,
                                  event.target.value,
                                  "procedure"
                                )
                              }
                            />
                          </td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              checked={d.payment}
                              onChange={(event) =>
                                paymentHandler(
                                  i,
                                  event.target.checked,
                                  "procedure"
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Comment"
                              className="form-control"
                              value={d.comment}
                              onChange={(event) =>
                                commentHandler(
                                  i,
                                  event.target.value,
                                  "procedure"
                                )
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  {list.service_auth[0] && (
                    <tr>
                      {/* <td></td> */}
                      {/* <td></td> */}
                      <td>{list.service_auth[0].service.name}</td>
                      <td>1</td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={list.service_auth[0].unitcost}
                          onChange={(event) =>
                            unitCostHandler(
                              "no need of i",
                              event.target.value,
                              "service"
                            )
                          }
                        />
                      </td>
                      <td></td>
                      <td>{list.service_auth[0].status}</td>
                      <td></td>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={list.service_auth[0].auth}
                          onChange={(event) =>
                            authHandler(
                              "no need of i",
                              event.target.checked,
                              "service"
                            )
                          }
                        />
                      </td>
                      <td className="text-center">
                            <input
                              type="checkbox"
                              checked={list.service_auth[0].notauth}
                              onChange={(event) =>
                                notAuthHandler("no need of i", event.target.checked, "service")
                              }
                            />
                          </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={list.service_auth[0].authcode}
                          onChange={(event) =>
                            authCodeHandler(
                              "no need of i",
                              event.target.value,
                              "service"
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(event) =>
                            validityHandler(
                              "no need of i",
                              event.target.value,
                              "service"
                            )
                          }
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={list.service_auth[0].payment}
                          onChange={(event) =>
                            paymentHandler(
                              "no need of i",
                              event.target.checked,
                              "service"
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="Comment"
                          className="form-control"
                          value={list.service_auth[0].comment}
                          onChange={(event) =>
                            commentHandler(
                              "no need of i",
                              event.target.value,
                              "service"
                            )
                          }
                        />
                      </td>
                    </tr>
                  )}
                  {list.room_auth[0] && (
                    <tr>
                      {/* <td></td> */}
                      {/* <td></td> */}
                      <td>{list.room_auth[0].room.room}</td>
                      <td>1</td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={list.room_auth[0].unitcost}
                          onChange={(event) =>
                            unitCostHandler(
                              "no need of i",
                              event.target.value,
                              "room"
                            )
                          }
                        />
                      </td>
                      <td></td>
                      <td>{list.room_auth[0].status}</td>
                      <td></td>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={list.room_auth[0].auth}
                          onChange={(event) =>
                            authHandler(
                              "no need of i",
                              event.target.checked,
                              "room"
                            )
                          }
                        />
                      </td>
                      <td className="text-center">
                            <input
                              type="checkbox"
                              checked={list.room_auth[0].notauth}
                              onChange={(event) =>
                                notAuthHandler("no need of i", event.target.checked, "room")
                              }
                            />
                          </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={list.room_auth[0].authcode}
                          onChange={(event) =>
                            authCodeHandler(
                              "no need of i",
                              event.target.value,
                              "room"
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(event) =>
                            validityHandler(
                              "no need of i",
                              event.target.value,
                              "room"
                            )
                          }
                        />
                      </td>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={list.room_auth[0].payment}
                          onChange={(event) =>
                            paymentHandler(
                              "no need of i",
                              event.target.checked,
                              "room"
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="Comment"
                          className="form-control"
                          value={list.room_auth[0].comment}
                          onChange={(event) =>
                            commentHandler(
                              "no need of i",
                              event.target.value,
                              "room"
                            )
                          }
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <div className="mt-5 text-center mb-4" id="maincontent2">
            <button className="btn btn-primary" onClick={printHandler}>
              Print claim form
            </button>
            <button className="btn btn-primary ml-2" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </MainContent>
      )}
      
    </>
  );
};

export default AuthPage;
