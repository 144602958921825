import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Success from "../../ui/success";
import { getFromServer, postToServer } from "../../global/requests";

const MedicalHistory = (props) => {
  const [medical, setMedical] = useState();
  const [success, setSuccess] = useState(false);

  const [medicalData, setMedicalDAta] = useState([]);
  useEffect(() => {
    const url = `${window.baseURL}adminmgt/encounter/medical-history`;
    axios.get(url).then((res) => {
      setMedical(res.data);
      // console.log("******", res.data);
    });
    getMedicalHistory();
  }, []);

  const getMedicalHistory = async () => {
    const url = `adminmgt/encounter/data-medical-history/${props.encounter_pid}`;
    const result = await getFromServer(url);
    if (result.status) {
      console.log(result.data);
      setMedicalDAta(result.data);
    } else {
      console.log("something Went Wrong");
    }
  };

  const clear = () => {
    const  checks = document.querySelectorAll(".allCheckBox input[type=radio]");
    checks.forEach(check => {
      check.checked = false;
    });
  }

  const saveHandler = async() => {
    let title = [];
    let question = [];
    for (var i in medical) {
      let t = document.getElementById(`title${i}`).innerText;
      for (var j in medical[i].medicalQuestions) {
        let q = document.getElementById(`question${j}${i}`).innerText;
        try {
          let value = document.querySelector(
            `input[name="flexRadioDefault${j}${i}"]:checked`
          ).value;
          question.push({ q: q, status: value });
        } catch {}
      }
      title.push({ t: t, tq: question });
      question = [];
    }
    for (var i in title) {
      if (title[i].tq.length <= 0) {
        title.splice(i, 1);
      }
    }

    const data = {
      encounter: props.encounter_pid,
      list: title,
    };
    const url = `main/doctor/medical-history`;
      const result = await postToServer(url, { data });
      if (result.status){
        setSuccess(true);
        getMedicalHistory();
        clear();
      }

  };
  return (
    <>
      <div className="accordion accordion-flush" id="accordionFlushExample">
        {success && (
          <Success close={() => setSuccess(false)}>
            Medical History Saved Successfully
          </Success>
        )}
        {medical &&
          medical.map((m, i) => {
            return (
              <div className="accordion-item" key={m.id}>
                <h2 className="accordion-header" id={"flush-headingOne" + i}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target={"#flush-collapseOne" + i}
                    aria-expanded="false"
                    aria-controls={"flush-collapseOne" + i}
                    id={"title" + i}
                  >
                    {m.title}
                  </button>
                </h2>
                <div
                  id={"flush-collapseOne" + i}
                  className="accordion-collapse collapse"
                  aria-labelledby={"flush-headingOne" + i}
                  data-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <table className="table table-bordered table-md">
                      <thead></thead>
                      <tbody className = "allCheckBox">
                        {m.medicalQuestions.map((q, j) => {
                          return (
                            <tr key={j}>
                              <td width="40%" id={"question" + j + i}>
                                {q.question}
                              </td>

                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={"flexRadioDefault" + j + i}
                                    id={"flexRadioDefault1" + j + i}
                                    value="Yes"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"flexRadioDefault1" + j + i}
                                  >
                                    Yes
                                  </label>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={"flexRadioDefault" + j + i}
                                    id={"flexRadioDefault2" + j + i}
                                    value="No"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"flexRadioDefault2" + j + i}
                                  >
                                    No
                                  </label>
                                </div>
                              </td>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={"flexRadioDefault" + j + i}
                                    id={"flexRadioDefault3" + j + i}
                                    value="None"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"flexRadioDefault3" + j + i}
                                  >
                                    None
                                  </label>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="text-center">
        <button className="btn btn-primary" onClick={saveHandler}>
          save
        </button>
      </div>
      <table className="table responsive">
        <thead>
          <tr style={{ fontSize: "13px" }}>
            <th scope="col">S/N</th>
            <th scope="col">Date</th>
            <th scope="col">Doctor</th>
            <th scope="col">Group/Title</th>
            <th scope="col">Quation</th>
            <th scope="col">State</th>
            {/* <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {medicalData.map((qus, i) => (
            <tr>
              <th scope="row">{i + 1}</th>
              <td>{qus["date"]}</td>
              <td>{qus["review_by_first_name"]}  {qus["review_by_last_name"]}</td>
              <td>{qus["title"]}</td>
              <td>{qus["question"]}</td>
              <td>{qus["status"]}</td>
              {/* <td>
              <button className="btn btn-danger btn-sm">Delete</button>
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default MedicalHistory;
