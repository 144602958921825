import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cookies from "js-cookie";

const NurseCarePlanModel = (props) => {
  const edit = props.is_edit
  const tempData = props.tempData

  const diagnosis = useRef();
  const objectives = useRef();
  const order = useRef();
  const evaluation = useRef();
  const nursename = useRef();

  useEffect(()=>{
    if(edit && tempData){
      document.getElementById('nursing-diagnosis').value = tempData.diagnosis
      document.getElementById('objectives').value = tempData.objectives
      document.getElementById('nursing-orders').value = tempData.order
      document.getElementById('evaluation').value = tempData.evaluation
      document.getElementById('nurse-name').value = tempData.nursename
    }
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();

    let id = null
    if(edit){
      id = tempData.id
    }
    
    const data = {
      is_edit: edit,
      id:id,
      diagnosis: diagnosis.current.value,
      objectives: objectives.current.value,
      order: order.current.value,
      evaluation: evaluation.current.value,
      nursename: nursename.current.value,
    };

    const url = `${window.baseURL}usermgt/nurse-care-plan/${props.encounter_pid}`;
    
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        props.success(true);
        props.close();
        props.update(res.data);
      });
  };

  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          {edit ? 
          <Modal.Title>Edit Nursing Care Plan</Modal.Title> :
          <Modal.Title>Add Nursing Care Plan</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Nursing Diagnosis</label>
                    <input
                      id="nursing-diagnosis"
                      type="text"
                      className="form-control"
                      required
                      ref={diagnosis}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Objectives</label>
                    <input
                      id="objectives"
                      type="text"
                      className="form-control"
                      required
                      ref={objectives}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Nursing Order</label>
                    <input
                      id="nursing-orders"
                      type="text"
                      className="form-control"
                      required
                      ref={order}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Evaluation</label>
                    <input
                      id="evaluation"
                      type="text"
                      className="form-control"
                      required
                      ref={evaluation}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Nurse Name</label>
                    <input
                      id="nurse-name"
                      type="text"
                      className="form-control"
                      required
                      ref={nursename}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
              {edit ? <>Update</>:<>Add</>} 
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NurseCarePlanModel;
