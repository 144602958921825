import Reactm,{useState,useRef} from "react";
import MainContent from "../../ui/MainContent";
import PatientListUpload from "./PatientListUpload";
import axios from "axios";
import { getFromServer } from "../../global/requests";

const Uplaod = () => {
  const [getPatient, setGetPatient] = useState();
  const searchText = useRef();

  const searchHandler = async (e) => {
    e.preventDefault();
    if (searchText.current.value.length <= 0) {
    } else {
      const result = await getFromServer(`main/frontdesk/patient/detail/${searchText.current.value}/none`);
      if(result.status) setGetPatient(result.data.p);
      else setGetPatient([]);
    }
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4>Search</h4>
                  <p style={{ color: "gray" }}>
                    Search Patient With Surname or Firstname or PID number
                  </p>
                  <form
                    onSubmit={searchHandler}
                    method="GET"
                    className="card-header-form"
                    style={{ display: "flex" }}
                  >
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Search"
                      required
                      ref={searchText}
                    />
                    <button
                      type="submit"
                      style={{ color: "white" }}
                      className="btn btn-info ml-3"
                    >
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </div>
            {getPatient && <PatientListUpload data={getPatient} />}
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default Uplaod;
