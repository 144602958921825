import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import Success from "../../ui/success";
import ReturnItem from "../../modals/Dispensary/ReturnItem";
import PatientDetailCard from "../../ui/PatientDetailCard";
import ShowDetail from "../ShowDetails";
import { postToServer } from "../../global/requests";

const DispenseDetails = (props) => {
  const param = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [temp, setTemp] = useState();

  const [p, setData] = useState();
  const [fluid, setFluid] = useState();
  const [temp_is_fluid, setTemp_is_fluid] = useState();
  const [success, setSuccess] = useState(false);

  const updatedState = (list) => {
    setData(list);
  };

  const handleClose = () => {
    setShow(false);
    setTemp("");
    setTemp_is_fluid(null)
  };
  const handleShow = (data, isFluid ) => {
    setTemp_is_fluid(isFluid)
    setTemp(data);
    setShow(true);
  };
  const closeHandler = () => setSuccess(false);

  const dispenseHandler = async () => {
    let data = {}
    if (fluid && p){
      data = {
        prescription_id: p.id,
        details: p.prescriptionDetails_set,
        fluid_id: fluid.id,
        fluid_details: fluid.doctor_fluid,
      };
    }
    else if (fluid && !p){
      data = {
        fluid_id: fluid.id,
        fluid_details: fluid.doctor_fluid,
        prescription_id: null,
      };
    }
    else{
      data = {
        fluid_id: null,
        prescription_id: p.id,
        details: p.prescriptionDetails_set,
      };
    }

    const result = await postToServer(`pharmacy/dispensary-details`, { data });
    if(result.status) {
      console.log(result);
      navigate("/opd-dispensary");
    }
  };

  useEffect(() => {
    if (Object.keys(param.state.list.drug).length > 0) setData(param.state.list.drug);
    if (Object.keys(param.state.list.fluid).length > 0) setFluid(param.state.list.fluid);
  }, []);

  const changeDispensedHandler = (e, i) => {
    const inputDrug = { ...p };
    inputDrug.prescriptionDetails_set[i].dispensedquantity = e.target.value;
    setData(inputDrug);
  };

  const changeTotalHandler = (e, i) => {
    const inputDrug = { ...p };
    inputDrug.prescriptionDetails_set[i].total = e.target.value;
    setData(inputDrug);
  };

  const changeFluidDispensedHandler = (e, i) => {
    const inputDrug = { ...fluid };
    inputDrug.doctor_fluid[i].dispensedquantity = e.target.value;
    setData(inputDrug);
  };

  const changeFluidTotalHandler = (e, i) => {
    const inputDrug = { ...fluid };
    inputDrug.doctor_fluid[i].total = e.target.value;
    setData(inputDrug);
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            {success && (
              <Success close={closeHandler}>Encounter Successful</Success>
            )}
            {p && (
              <>
                <PatientDetailCard list={p.encounter} isEncounter={true} />
                <ShowDetail encounter_pid={p.encounter.pid} />
              </>
            )}

            <div className="tablexflow">
              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    <th scope="col">
                      Date{"("}
                      {p && p.encounter.pid}
                      {")"}
                    </th>
                    <th scope="col">Dosage</th>
                    <th scope="col">Frequency</th>
                    <th scope="col">Period</th>
                    <th scope="col">Total</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Prescribed by</th>
                    <th scope="col">Return Record</th>
                    <th scope="col">Dipensed Record</th>
                    {/* <th scope="col">Total Dipensed</th> */}
                    <th scope="col">Dipensed Quantity</th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {p &&
                    p.prescriptionDetails_set.map((a, index) => {
                      return (
                        <tr>
                          <td>
                            {a.prescription_details_tag_set.map((t) => {
                              return (
                                <>
                                  {t.drugtags.brandname}
                                  {" (" + t.drugtags.type + ")"} -
                                  <span className="float-end">
                                    {t.drugtags.is_paid == true ? (
                                      <button className="btn btn-success btn-sm">
                                        Confirmed
                                      </button>
                                    ) : (
                                      <button className="btn btn-danger btn-sm">
                                        Not confirmed
                                      </button>
                                    )}
                                  </span>
                                </>
                              );
                            })}
                          </td>
                          <td>{a.dosage}</td>
                          <td>{a.frequency}</td>
                          <td>{a.period}</td>
                          <td>
                            <input
                              type="text"
                              name="dispensedquantity"
                              className="form-control w-50"
                              value={a.total}
                              onChange={(e) => changeTotalHandler(e, index)}
                            />
                          </td>
                          <td>{a.comment}</td>
                          <td>{a.prescribed_by_first_name} {a.prescribed_by_last_name}</td>
                          <td>{a.returnrecord}</td>
                          <td>
                            {a.dispensedrecord && (
                              <>
                                {a.dispensedrecord} <br />
                                <strong>Qty:</strong>
                                {a.updateddispensedquantity}{" "}
                                <span
                                  onClick={() => handleShow(a, false)}
                                  style={{ color: "blue", cursor: "pointer" }}
                                >
                                  Return
                                </span>
                              </>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              name="dispensedquantity"
                              className="form-control w-50"
                              value={a.dispensedquantity}
                              onChange={(e) => changeDispensedHandler(e, index)}
                            />
                          </td>
                          {/* <td>
                            {Object.prototype.hasOwnProperty.call(a.prescription_details_tag_set[0], "drugtags") && a.prescription_details_tag_set[0].drugtags.cost}
                          </td> */}
                          <td>
                            <button className="btn btn-sm btn-primary">
                              Patient Stock
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                  {fluid &&
                    fluid.doctor_fluid.map((a, index) => {
                      return (
                        <tr>
                          <td>
                            {a.fluid.fluid}
                            <span className="float-end">
                              {a.fluid.is_paid == true ? (
                                <button className="btn btn-success btn-sm">
                                  Confirmed
                                </button>
                              ) : (
                                <button className="btn btn-danger btn-sm">
                                  Not confirmed
                                </button>
                              )}
                            </span>
                          </td>
                          <td>{a.dosage}</td>
                          <td>{a.frequency}</td>
                          <td>{a.period}</td>
                          <td>
                            <input
                              type="text"
                              name="dispensedquantity"
                              className="form-control w-50"
                              value={a.total}
                              onChange={(e) => changeFluidTotalHandler(e, index)}
                            />
                          </td>
                          <td>{a.comment}</td>
                          <td></td>
                          <td>{a.returnrecord}</td>
                          <td>
                            {a.dispensedrecord && (
                              <>
                                {a.dispensedrecord} <br />
                                <strong>Qty:</strong>
                                {a.updateddispensedquantity}{" "}
                                <span
                                  onClick={() => handleShow(a, true)}
                                  style={{ color: "blue", cursor: "pointer" }}
                                >
                                  Return
                                </span>
                              </>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              name="dispensedquantity"
                              className="form-control w-50"
                              value={a.dispensedquantity}
                              onChange={(e) => changeFluidDispensedHandler(e, index)}
                            />
                          </td>
                          {/* <td>
                            {Object.prototype.hasOwnProperty.call(a.prescription_details_tag_set[0], "drugtags") && a.prescription_details_tag_set[0].drugtags.cost}
                          </td> */}
                          <td>
                            <button className="btn btn-sm btn-primary">
                              Patient Stock
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="text-center mt-3">
              <button className="btn btn-primary" onClick={dispenseHandler}>
                Dispense
              </button>
            </div>
          </div>
        </div>
      </MainContent>
      {temp && (
        <ReturnItem
          show={show}
          close={handleClose}
          detail={temp}
          pres_id={p.id}
          newPrescription={updatedState}
          is_fluid={temp_is_fluid}
        />
      )}
    </>
  );
};

export default DispenseDetails;
