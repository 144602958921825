import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cookies from "js-cookie";
import { getFromServer } from "../../global/requests";

const AddBilling = (props) => {
  const [companies, setCompany] = useState();
  const [plan, setPlan] = useState();
  const billplan = useRef();
  const company = useRef();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/company`);
    if (result.status) setCompany(result.data);
  };

  const companyChangeHandler = async (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");

    const result = await getFromServer(`billing/plan/${parseInt(option)}`);
    if (result.status) setPlan(result.data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      patient_id: props.patient_id,
      plan: billplan.current.value,
      company: company.current.value,
    };
    console.log(data);
    const url = `${window.baseURL}main/frontdesk/billing`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        props.close();
        props.billinginfo(res.data);
      });
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Billign Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label>*Company</label>
                    <select
                      className="form-select"
                      ref={company}
                      onChange={companyChangeHandler}
                    >
                      {companies &&
                        companies.map((u) => {
                          return (
                            <option key={u.id} id={u.id} value={u.name}>
                              {u.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label>*Company Plan</label>
                    <select className="form-select" ref={billplan}>
                      {plan &&
                        plan.map((u) => {
                          return (
                            <option key={u.id} id={u.id} value={u.name}>
                              {u.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBilling;
