import React, { useEffect, useRef, useState } from "react";
import MainContent from "../../ui/MainContent";
import axios from "axios";
import Cookies from "js-cookie";
import AddingTags from "../../modals/Nursing/AddingTags";
import Success from "../../ui/success";

const EncounterSetup = () => {
  const [addComplain, setAddComplain] = useState(false);
  const [addProcedure, setAddProcedure] = useState(false);
  const [addMedical, setAddMedical] = useState(false);
  const [addExamination, setAddExamination] = useState(false);
  const [addReview, setAddReview] = useState(false);
  const [addFluid, setAddFluid] = useState(false);

  const [isMedical, setIsMedical] = useState(false);
  const [isExamination, setIsExamination] = useState(false);
  const [isReview, setIsReview] = useState(false);

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const [tempId, setTempId] = useState();
  const [tempName, setTempName] = useState();

  const handleClose = () => setShow(false);
  const successClose = () => setSuccess(false);
  const handleShow = (id, name) => {
    setShow(true);
    setTempId(id);
    setTempName(name);
  };

  const [complainList, setComplainList] = useState();
  const [procedureList, setProcedureList] = useState();
  const [medicalList, setMedicalList] = useState();
  const [reviewList, setReviewList] = useState();
  const [examinationList, setExaminationList] = useState();
  const [fluidList, setFluidList] = useState();

  const complain = useRef();
  const fluid = useRef();
  const procedure = useRef();
  const medicalTitle = useRef();
  const medicalhistory = useRef();
  const reviewTitle = useRef();
  const reviewhistory = useRef();
  const examinationTitle = useRef();
  const examinationhistory = useRef();

  useEffect(() => {
    const url = `${window.baseURL}adminmgt/encounter`;
    axios.get(url).then((res) => {
      setComplainList(res.data.complain);
      setProcedureList(res.data.procedure);
      setMedicalList(res.data.medical);
      setReviewList(res.data.review);
      setExaminationList(res.data.examination);
      setFluidList(res.data.fluid);
    });
  }, []);

  const tagsSubmitHandler = (list) => {
    const data = {
      question: list,
    };

    let url = "";
    if (tempName == "medical") {
      url = `${window.baseURL}adminmgt/encounter/medical-history-question/${tempId}`;
    } else {
      url = `${window.baseURL}adminmgt/encounter/review-system-question/${tempId}`;
    }
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setSuccess(true);
      });
  };

  const handleOnChangeMedical = () => {
    setIsMedical((current) => !current);
  };

  const handleOnChangeReview = () => {
    setIsReview((current) => !current);
  };

  const handleOnChangeExamination = () => {
    setIsExamination((current) => !current);
  };

  const addComplainHandler = () => {
    setAddComplain(!addComplain);
  };

  const addFluidHandler = () => {
    setAddFluid(!addFluid);
  };

  const addProcedureHandler = () => {
    setAddProcedure(!addProcedure);
  };

  const addMedicalHandler = () => {
    setAddMedical(!addMedical);
  };

  const addReviewHandler = () => {
    setAddReview(!addReview);
  };

  const addExaminationHandler = () => {
    setAddExamination(!addExamination);
  };

  const complainSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      complain: complain.current.value,
    };

    const url = `${window.baseURL}adminmgt/encounter/complain`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setAddComplain(false);
        console.log(res.data);
        setComplainList(res.data);
      });
  };

  const fluidSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      fluid: fluid.current.value,
    };

    const url = `${window.baseURL}adminmgt/encounter/fluid`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setAddFluid(false);
        console.log(res.data);
        setFluidList(res.data);
      });
  };

  const procedureSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      procedure: procedure.current.value,
    };

    const url = `${window.baseURL}adminmgt/encounter/procedure`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setAddProcedure(false);
        console.log(res.data);
        setProcedureList(res.data);
      });
  };

  const medicalSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      title: medicalTitle.current.value,
      history: medicalhistory.current.value,
      isDoctor: isMedical,
    };

    const url = `${window.baseURL}adminmgt/encounter/medical-history`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setAddMedical(false);
        console.log(res.data);
        setMedicalList(res.data);
      });
  };

  const reviewSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      title: reviewTitle.current.value,
      history: reviewhistory.current.value,
      isDoctor: isReview,
    };

    const url = `${window.baseURL}adminmgt/encounter/review-system`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setAddReview(false);
        console.log(res.data);
        setReviewList(res.data);
      });
  };

  const examinationSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      title: examinationTitle.current.value,
      history: examinationhistory.current.value,
      isDoctor: isExamination,
    };

    const url = `${window.baseURL}adminmgt/encounter/examination-history`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setAddExamination(false);
        console.log(res.data);
        setExaminationList(res.data);
      });
  };

  const deleteComplainHandler = (id) => {
    if (window.confirm("Do you want to delete this Complain?")) {
      const url = `${window.baseURL}adminmgt/encounter/complain/${id}`;
      axios
        .delete(url, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          setComplainList(res.data);
        });
    }
  };

  const deleteFluidHandler = (id) => {
    if (window.confirm("Do you want to delete this Fluid?")) {
      const url = `${window.baseURL}adminmgt/encounter/fluid/${id}`;
      axios
        .delete(url, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          setFluidList(res.data);
        });
    }
  };

  const deleteProcedureHandler = (id) => {
    if (window.confirm("Do you want to delete this Procedure?")) {
      const url = `${window.baseURL}adminmgt/encounter/procedure/${id}`;
      axios
        .delete(url, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          setProcedureList(res.data);
        });
    }
  };

  const deleteMedicalHandler = (id) => {
    if (window.confirm("Do you want to delete this Medical History?")) {
      const url = `${window.baseURL}adminmgt/encounter/medical-history/${id}`;
      axios
        .delete(url, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          setMedicalList(res.data);
        });
    }
  };

  const deleteReviewHandler = (id) => {
    if (window.confirm("Do you want to delete this Review of System?")) {
      const url = `${window.baseURL}adminmgt/encounter/review-system/${id}`;
      axios
        .delete(url, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          setReviewList(res.data);
        });
    }
  };

  const deleteExaminationHandler = (id) => {
    if (window.confirm("Do you want to delete this Examination History?")) {
      const url = `${window.baseURL}adminmgt/encounter/examination-history/${id}`;
      axios
        .delete(url, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          setExaminationList(res.data);
        });
    }
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            <div className="col-md-6">
              <button className="btn btn-primary" onClick={addComplainHandler}>
                Add Complains
              </button>
              {addComplain && (
                <form>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Complains..."
                      required
                      ref={complain}
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={complainSubmitHandler}
                    >
                      Add
                    </button>
                  </div>
                </form>
              )}
              <div className="accordion mt-3" id="complain">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingComplain">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseComplain"
                      aria-expanded="true"
                      aria-controls="collapseComplain"
                    >
                      View Complains
                    </button>
                  </h2>
                  <div
                    id="collapseComplain"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingComplain"
                    data-parent="#complain"
                  >
                    <div className="accordion-body">
                      <table className="table table-sm responsive">
                        <thead>
                          <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Complains</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {complainList &&
                            complainList.map((c, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{c.complain}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() =>
                                        deleteComplainHandler(c.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <button className="btn btn-primary" onClick={addProcedureHandler}>
                Add Procedures
              </button>
              {addProcedure && (
                <form>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Procedures..."
                      required
                      ref={procedure}
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={procedureSubmitHandler}
                    >
                      Add
                    </button>
                  </div>
                </form>
              )}
              <div className="accordion mt-3" id="proc">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingProc">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseProc"
                      aria-expanded="true"
                      aria-controls="collapseProc"
                    >
                      View Procedures
                    </button>
                  </h2>
                  <div
                    id="collapseProc"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingProc"
                    data-bs-parent="#proc"
                  >
                    <div className="accordion-body">
                      <table className="table table-sm responsive">
                        <thead>
                          <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Complains</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {procedureList &&
                            procedureList.map((c, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{c.procedure}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() =>
                                        deleteProcedureHandler(c.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            {success && (
              <Success close={successClose}>
                Questions has been added Successfully
              </Success>
            )}

            <div className="col-md-6">
              <button className="btn btn-primary" onClick={addMedicalHandler}>
                Add Medical History
              </button>
              {addMedical && (
                <form>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      required
                      ref={medicalTitle}
                    />
                  </div>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="History"
                      required
                      ref={medicalhistory}
                    />
                  </div>
                  <div className="ml-4">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      checked={isMedical}
                      onChange={handleOnChangeMedical}
                    />
                    <label class="form" htmlFor="flexCheckChecked">
                      Add to Doctor Page?
                    </label>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={medicalSubmitHandler}
                    >
                      Add
                    </button>
                  </div>
                </form>
              )}
              <div className="accordion mt-3" id="medical">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingMedical">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseMedical"
                      aria-expanded="true"
                      aria-controls="collapseMedical"
                    >
                      View Medical History
                    </button>
                  </h2>
                  <div
                    id="collapseMedical"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingMedical"
                    data-parent="#medical"
                  >
                    <div className="accordion-body">
                      <table className="table table-sm responsive">
                        <thead>
                          <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">History</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {medicalList &&
                            medicalList.map((c, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{c.title}</td>
                                  <td>{c.history}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-primary btn-sm mr-1"
                                      onClick={() =>
                                        handleShow(c.id, "medical")
                                      }
                                    >
                                      Add
                                    </button>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => deleteMedicalHandler(c.id)}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-primary"
                onClick={addExaminationHandler}
              >
                Add Examination
              </button>
              {addExamination && (
                <form>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      required
                      ref={examinationTitle}
                    />
                  </div>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="History"
                      required
                      ref={examinationhistory}
                    />
                  </div>
                  <div className="ml-4">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      checked={isExamination}
                      onChange={handleOnChangeExamination}
                    />
                    <label class="form" htmlFor="flexCheckChecked">
                      Add to Doctor Page?
                    </label>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={examinationSubmitHandler}
                    >
                      Add
                    </button>
                  </div>
                </form>
              )}
              <div className="accordion mt-3" id="examination">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingExamination">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseExamination"
                      aria-expanded="true"
                      aria-controls="collapseExamination"
                    >
                      View Examination
                    </button>
                  </h2>
                  <div
                    id="collapseExamination"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingExamination"
                    data-bs-parent="#examination"
                  >
                    <div className="accordion-body">
                      <table className="table table-sm responsive">
                        <thead>
                          <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">History</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {examinationList &&
                            examinationList.map((c, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{c.title}</td>
                                  <td>{c.history}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() =>
                                        deleteExaminationHandler(c.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <button className="btn btn-primary" onClick={addReviewHandler}>
                Add Review of System
              </button>
              {addReview && (
                <form>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      required
                      ref={reviewTitle}
                    />
                  </div>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="History"
                      required
                      ref={reviewhistory}
                    />
                  </div>
                  <div className="ml-4">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckCheckedreview"
                      checked={isReview}
                      onChange={handleOnChangeReview}
                    />
                    <label class="form" htmlFor="flexCheckCheckedreview">
                      Add to Doctor Page?
                    </label>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={reviewSubmitHandler}
                    >
                      Add
                    </button>
                  </div>
                </form>
              )}
              <div className="accordion mt-3" id="review">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingReview">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseReview"
                      aria-expanded="true"
                      aria-controls="collapseReview"
                    >
                      View Review of System
                    </button>
                  </h2>
                  <div
                    id="collapseReview"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingReview"
                    data-bs-parent="#review"
                  >
                    <div className="accordion-body">
                      <table className="table table-sm responsive">
                        <thead>
                          <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">History</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reviewList &&
                            reviewList.map((c, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{c.title}</td>
                                  <td>{c.history}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-primary btn-sm mr-1"
                                      onClick={() => handleShow(c.id, "review")}
                                    >
                                      Add
                                    </button>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => deleteReviewHandler(c.id)}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <button className="btn btn-primary" onClick={addFluidHandler}>
                Add Fluids
              </button>
              {addFluid && (
                <form>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Fluid..."
                      required
                      ref={fluid}
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={fluidSubmitHandler}
                    >
                      Add
                    </button>
                  </div>
                </form>
              )}
              <div className="accordion mt-3" id="fluid">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFluid">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFluid"
                      aria-expanded="true"
                      aria-controls="collapseFluid"
                    >
                      View Fluid
                    </button>
                  </h2>
                  <div
                    id="collapseFluid"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFluid"
                    data-parent="#fluid"
                  >
                    <div className="accordion-body">
                      <table className="table table-sm responsive">
                        <thead>
                          <tr style={{ fontSize: "13px" }}>
                            <th scope="col">#</th>
                            <th scope="col">Fluids</th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fluidList &&
                            fluidList.map((c, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{c.fluid}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => deleteFluidHandler(c.id)}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      <AddingTags show={show} close={handleClose} getTags={tagsSubmitHandler} />
    </>
  );
};

export default EncounterSetup;
