import React, { useState, useRef, useEffect } from "react";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../ui/Loading";
import { getFromServer } from "../../global/requests";

const BillSummary = () => {
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [temp, setTemp] = useState();

  const [companyList, setCompanyList] = useState();
  const [detail, setDetail] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fromdate = useRef(null);
  const todate = useRef(null);
  const company = useRef();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/company`);
    if(result.status) setCompanyList(result.data);
  }


  const searchHandler = async (e) => {
    e.preventDefault();
    var date = fromdate.current.value;
    var date2 = todate.current.value;
    // var search = searchText.current.value;

    if (date == null || date == "" || date == undefined || date2 == null || date2 == "" || date2 == undefined ) {
    } else {
      setIsLoading(true);
      const result = await getFromServer(`billing/bill-summary/${date}/${date2}/${company.current.value}`);
      if(result.status) {
        console.log(result.data)
        setList(result.data);
        setIsSearch(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          {!detail && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4>Search</h4>
                      <div className="row">
                        <form
                          onSubmit={searchHandler}
                          method="GET"
                          className="card-header-form"
                          style={{ display: "flex" }}
                        >
                          <div className="col-md-4">
                            <p style={{ color: "gray" }}>From Date Time</p>
                            <input
                              type="datetime-local"
                              ref={fromdate}
                              className="form-control"
                              required
                            />
                            <button
                              type="submit"
                              style={{ color: "white" }}
                              className="btn btn-info mt-3"
                            >
                              Search
                            </button>
                          </div>
                          <div className="col-md-4">
                            <p style={{ color: "gray" }}>To Date Time</p>
                            <input
                              type="datetime-local"
                              ref={todate}
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <p style={{ color: "gray" }}>Company</p>
                            <select class="form-select" ref={company}>
                              <option value="All">All</option>
                              {companyList &&
                                companyList.map((c) => {
                                  return <option value={c.id} key={c.id}>{c.name}</option>;
                                })}
                            </select>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isLoading && (
                <>
                  <h4 className="text-center">
                    Generating Report Please Wait...
                  </h4>
                  <Loading />
                </>
              )}
              {isSearch && (
                <div>
                 {list.report.length > 0 && <table className="table table-bordered table-stripped table-md">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Company</th>
                        <th>Full Name</th>
                        {list.cat &&
                          list.cat.map((c) => {
                            return <th>{c.category}</th>;
                          })}
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.report.map((r, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{r.company}</td>
                              <td>{r.enc.patient.surname} {r.enc.patient.firstname}</td>
                              {r.category.map(c=>{
                                return(
                                  <td>{c.cal}</td>
                                )
                              })}
                              <td>{r.total}</td>
                            </tr>
                          );
                        })}
                      <tr style={{ height: "50px" }}></tr>
                      <tr>
                        <th colSpan="3">Total</th>
                        {list.col_total &&
                          list.col_total.map((c) => {
                            return <td>{c}</td>;
                          })}
                      </tr>
                      <tr style={{ height: "100px" }}></tr>
                      {list && <><tr>
                        <td
                          colSpan={list.report[0].category.length + 7}
                          className="text-center fw-bolder"
                          style={{ color: "red" }}
                        >
                          Payment Summary
                        </td>
                      </tr>
                      <tr>
                        <th
                          colSpan={(list.report[0].category.length + 7) / 2}
                          className="text-end"
                        >
                          Total Payment
                        </th>
                        <td colSpan={(list.report[0].category.length + 7) / 2}>
                          {list.col_total[list.col_total.length - 1]}
                        </td>
                      </tr>
                      
                      </>}
                    </tbody>
                  </table>}
                  {list.report.length < 1 && <h3 className="text-center">No Data Found</h3> }
                </div>
              )}
            </>
          )}
        </div>
      </MainContent>
    </>
  );
};

export default BillSummary;
