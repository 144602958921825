import { disconnectToWebSocket } from "./ws";

const TIME_ALLOWED = 15 * 60 * 1000; // 15 min in miliseconds

const getUserAccessToken = () => {
  return localStorage.getItem("ariamed_user_access_token");
};

const getUserAccessTime = () => {
  return localStorage.getItem("ariamed_user_access_time");
};

const setUserAccessToken = (token) => {
  localStorage.setItem("ariamed_user_access_token", token);
  localStorage.setItem("ariamed_user_access_time", Date.now());
};

const removeUserAccessToken = (token) => {
  localStorage.removeItem("ariamed_user_access_token");
  localStorage.removeItem("ariamed_user_access_time");
};

const clearSession = (token) => {
  localStorage.clear();
};

const getPermission = (key) => {
  const value = localStorage.getItem(`ariamed_permission_${key}`);
  return value === "1";
};

const setPermission = (key) => {
  localStorage.setItem(`ariamed_permission_${key}`, "1");
};

const logoutUser = () => {
  localStorage.clear();
  const urlpath = window.location.pathname;
  try {
    if(urlpath != "/login") window.reactNavigate("/login");
    window.setIsLoggedIn(false);
  } catch (error) {
    if(urlpath != "/login") window.location.href = "/login";
  }
  // disconnectToWebSocket();
};

const getUserData = (key) => {
  return localStorage.getItem(`ariamed_user_data_${key}`);
};

const setUserData = (key, value) => {
  localStorage.setItem(`ariamed_user_data_${key}`, value);
};

const initiateAuth = () => {
  window.userInfo = {
    user_id: parseInt(getUserData('user_id')),
    first_name: getUserData('first_name'),
    last_name: getUserData('last_name'),
    profile_pic: getUserData('profile_pic')
  };
}

const checkUserAccessTime = () => {
  if(getUserAccessToken()) {
    const oldTime = parseInt(getUserAccessTime());
    if(oldTime && ((Date.now() - oldTime) < TIME_ALLOWED)) {
      return true;
    } 
  }
  logoutUser();
  return false;
}

const updateUserAccessTime = () => {
  if(checkUserAccessTime) {
    localStorage.setItem("ariamed_user_access_time", Date.now());
    return true;
  }
  return false
};

// 👇️ named exports
export {
  getUserAccessToken,
  setUserAccessToken,
  removeUserAccessToken,
  clearSession,
  getPermission,
  setPermission,
  logoutUser,
  getUserData,
  setUserData,
  initiateAuth,
  updateUserAccessTime,
};

// --------------- timer for managing timer for login session ---------------
setInterval(() => {
  // logout user when access time greater than 5 min
  checkUserAccessTime();
  console.log("checking timing......");
}, 10000)
checkUserAccessTime();

window.addEventListener("click", () => { updateUserAccessTime(); });

