import React, { useState, useEffect } from "react";
import MainContent from "../../ui/MainContent";
import AddDepartment from "../../modals/UserMgt/AddDepartment";
import AddAntenatal from "../../modals/UserMgt/AddAntenatal";
import AddImmunization from "../../modals/UserMgt/AddImmunization";
import Success from "../../ui/success";
import ViewPermissions from "../../modals/UserMgt/ViewPermissions";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, deleteFromServer } from "../../global/requests";

const UserAccessMgt = () => {
  const [user, setUser] = useState();
  const [tempId, setTempId] = useState();
  const [show, setShow] = useState(false);
  const [showAnt, setShowAnt] = useState(false);
  const [showImun, setShowImun] = useState(false);
  const [showPermission, setShowPermission] = useState(false);
  const [success, setSuccess] = useState(false);

  const [departEditId, setDepartEditId] = useState(null);
  const [antEditId, setAntEditId] = useState(null);
  const [imunEditId, setImunEditId] = useState(null);

  const handleClose = () => {
    setDepartEditId(null);
    setShow(false);
  }
  const handleShow = () => {
    setDepartEditId(null);
    setShow(true);
  }
  const handleCloseAnt = () => {
    setAntEditId(null);
    setShowAnt(false);
  }
  const handleShowAnt = () => {
    setAntEditId(null);
    setShowAnt(true);
  }
  const handleCloseImun = () => {
    setImunEditId(null);
    setShowImun(false);
  }
  const handleShowImun = () => {
    setImunEditId(null);
    setShowImun(true);
  }

  const permissionhandleClose = () => setShowPermission(false);
  const permissionhandleShow = () => setShowPermission(true);

  const updateUsersHandler = (list) => setUser(list);
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/useraccess/add/department`);
    if(result.status) setUser(result.data);
  }

  const deleteHandler = async (id) => {
    const result = await deleteFromServer(`usermgt/useraccess/${id}`);
    if(result.status) setUser(result.data);
    setSuccess(false);
  };

  const viewPermissionsHandler = (id) => {
    permissionhandleShow(true);
    setTempId(id);
    setSuccess(false);
  };
  const editHandler = (info) => {
    if(info.isAntenatal) {
      setAntEditId(info.id);
      setShowAnt(true);
    } else if(info.isImmunization) {
      setImunEditId(info.id);
      setShowImun(true);
    } else {
      setDepartEditId(info.id);
      setShow(true);
    }
  }
  return (
    <>
      <MainContent>
        {success && (
          <Success close={() => setSuccess(false)}>
            Data has been added Successfully
          </Success>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Department
              </button>
              <button
                className="btn btn-primary mb-4 ml-2"
                onClick={handleShowAnt}
              >
                Antenatal
              </button>
              <button
                className="btn btn-primary mb-4 ml-2"
                onClick={handleShowImun}
              >
                Immunization
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
        </div>
        <div className="tablexflow">
          <table className="table responsive">
            <thead>
              <tr style={{ fontSize: "13px" }}>
                <th scope="col">#</th>
                <th scope="col">Department</th>
                <th scope="col" className="text-center">
                  User Access
                </th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {user &&
                user.map((a, index) => {
                  return (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{a.department}</td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() => viewPermissionsHandler(a.id)}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => editHandler({id: a.id, isAntenatal: a.isAntenatal, isImmunization: a.isImmunization})}
                        >
                          Edit
                        </button>
                        &nbsp;&nbsp;
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => deleteHandler(a.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </MainContent>
      {show && (
        <AddDepartment
          show={show}
          close={handleClose}
          departEditId={departEditId}
          success={successHandler}
          users={updateUsersHandler}
        />
      )}
      {showAnt && (
        <AddAntenatal
          show={showAnt}
          close={handleCloseAnt}
          antEditId={antEditId}
          success={successHandler}
          users={updateUsersHandler}
        />
      )}
      {showImun && (
        <AddImmunization
          show={showImun}
          close={handleCloseImun}
          imunEditId={imunEditId}
          success={successHandler}
          users={updateUsersHandler}
        />
      )}{" "}
      {showPermission && (
        <ViewPermissions
          show={showPermission}
          close={permissionhandleClose}
          id={tempId}
        />
      )}
    </>
  );
};

export default UserAccessMgt;
