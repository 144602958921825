import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const EditReviewBill = (props) => {
  const [quantity, setQuantity] = useState();
  const [list, setList] = useState(props.list);

  useEffect(() => {
    setQuantity(list.qty);
  }, []);


  const qtyChangeHandler = (e) => {
    setQuantity(e.target.value);
  };

  const priceChangeHandler = (e) => {
    setList({ ...list, price: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      arr: list,
      name: props.name,
      qty: quantity,
      id:props.id,
      page:'edit_review_bill',
    };

    const result = await postToServer(`billing/review-bill/none`, { data });
    if(result.status) {
      props.update(result.data)
      props.close();
      // props.success(true);
    }
  };
  return (
    <>
      {list && (
        <Modal show={props.show} onHide={props.close}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <input type="hidden" name="_token" value="" />
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="col-form-label">Qty</label>
                      <input
                        value={quantity}
                        type="text"
                        className="form-control"
                        required
                        onChange={qtyChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="col-form-label">Price</label>
                      <input
                        value={list.price}
                        type="number"
                        className="form-control"
                        required
                        onChange={priceChangeHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default EditReviewBill;
