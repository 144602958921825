import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { postToServer, getFromServer } from "../../global/requests";

const AddService = (props) => {
  const [departmentList, setDepartmentList] = useState();
  const [serviceType, setServiceType] = useState();
  const [isChecked, setIsChecked] = useState(true);

  const service = useRef();
  const cost = useRef();
  const type = useRef();
  const department = useRef();

  useEffect(() => {
    loadDepartments();
    loadServerTypes();
  }, []);

  const loadDepartments = async () => {
    const result = await getFromServer(`usermgt/useraccess/add/department`);
    if(result.status) setDepartmentList(result.data);
  }
  const loadServerTypes = async () => {
    const result = await getFromServer(`billing/servicetype`);
    if(result.status) setServiceType(result.data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: service.current.value,
      cost: cost.current.value,
      type: type.current.value,
      department: department.current.value,
      show: isChecked,
    };

    const result = await postToServer(`billing/service`, { data });
    if(result.status) {
      props.close();
      props.success(true);
      props.services(result.data);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Serice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Name of service
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={service}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="cost" className="col-form-label">
                      Cost
                    </label>
                    <input
                      type="number"
                      name="cost"
                      className="form-control"
                      required
                      ref={cost}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Department</label>
                    <select className="form-select" ref={department}>
                      {departmentList &&
                        departmentList.map((u) => {
                          return (
                            <option key={u.id} value={u.department}>
                              {u.department}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Serice Type</label>
                    <select className="form-select" ref={type}>
                      {serviceType &&
                        serviceType.map((t) => {
                          return <option value={t.type}>{t.type}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ml-4">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    <label class="form" htmlFor="flexCheckChecked">
                      Show on Encounter Page?
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddService;
