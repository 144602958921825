import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import Success from "../../ui/success";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { tab } from "@testing-library/user-event/dist/tab";
import { getFromServer, postToServer, deleteFromServer } from "../../global/requests";

const FluidChart = (props) => {
  const param = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [addFluid, setAddFluid] = useState(false);
  const [addOutput, setAddOutput] = useState(false);
  const [temp, setTemp] = useState();

  const [p, setData] = useState();
  const [fluidList, setFluid] = useState();
  const [success, setSuccess] = useState(false);

  const [fluidchart, setFluidChart] = useState();
  const [output, setOutput] = useState();

  const fluid = useRef();
  const quantity = useRef();
  const username = useRef();
  const outputtype = useRef();

  const handleClose = () => {
    setShow(false);
    setTemp("");
  };
  const handleShow = (data) => {
    setTemp(data);
    setShow(true);
  };
  const closeHandler = () => setSuccess(false);

  useEffect(() => {
    loadFluidChartData();
    loadOutputData();
  }, []);

  const loadFluidChartData = async () => {
    const result = await getFromServer(`main/doctor/encounter/fluidchart/${param.state.pid}`);
    if(result.status) {
      setData(result.data.encounter);
      setFluid(result.data.fluid);
      setFluidChart(result.data.fluidchart);
    }
  }
  const loadOutputData = async () => {
    const result = await getFromServer(`usermgt/output/${param.state.pid}`);
    if(result.status) setOutput(result.data);
  }

  const fluidSubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      eid: param.state.pid,
      fid: fluid.current.value,
      quantity: quantity.current.value,
    };
    const result = await postToServer(`usermgt/fluidchart`, { data });
    if(result.status) {
      console.log(result.data);
      setSuccess(true);
      setAddFluid(false);
      setFluidChart(result.data);
    }
  };

  const outputSubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      eid: param.state.pid,
      outputtype: outputtype.current.value,
      quantity: quantity.current.value,
    };
    const result = await postToServer(`usermgt/output/none`, { data });
    if(result.status) {
      console.log(result.data);
      setSuccess(true);
      setAddOutput(false);
      setAddFluid(false);
      setOutput(result.data);
    }
  };

  const addFluidHandler = () => {
    setAddFluid(!addFluid);
    setAddOutput(false);
  };

  const addOutputHandler = () => {
    setAddFluid(false);
    setAddOutput(!addOutput);
  };

  const deleteHandler = async (id, table) => {
    if (window.confirm("Do you really want to delete this?")) {
      const result = await deleteFromServer(`usermgt/${table}/${id}/${param.state.pid}`);
      if(result.status) {
        setAddOutput(false);
        setAddFluid(false);
        if (table == "output") setOutput(result.data);
        else setFluidChart(result.data);
      }
    }
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            {success && (
              <Success close={closeHandler}>Data added Successfully</Success>
            )}
            {p && <PatientDetailCard list={p} isEncounter={true} />}
            <div className="mt-3 mb-3">
              <button className="btn btn-primary" onClick={addFluidHandler}>
                Add Fluid
              </button>
              <button
                className="btn btn-primary ml-2"
                onClick={addOutputHandler}
              >
                Add Output
              </button>
              {addFluid && (
                <form className="mt-3" onSubmit={fluidSubmitHandler}>
                  <div className="row w-50">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <select className="form-select" ref={fluid}>
                          {fluidList &&
                            fluidList.map((u) => {
                              return (
                                <option key={u.id} value={u.id}>
                                  {u.fluid}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Quantity"
                        ref={quantity}
                      />
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-primary" type="submit">
                      Add
                    </button>
                  </div>
                </form>
              )}
              {addOutput && (
                <form className="mt-3" onSubmit={outputSubmitHandler}>
                  <div className="row w-75">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Output Type"
                          ref={outputtype}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Quantity"
                          ref={quantity}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        ref={username}
                      />
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-primary" type="submit">
                      Add
                    </button>
                  </div>
                </form>
              )}
            </div>

            <div className="accordion mt-3" id="Fluid">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFluid">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFluid"
                    aria-expanded="true"
                    aria-controls="collapseFluid"
                  >
                    View Fluids
                  </button>
                </h2>
                <div
                  id="collapseFluid"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFluid"
                  data-parent="#Fluid"
                >
                  <div className="tablexflow">
                    {fluidchart && (
                      <>
                        <table className="table table-bordered table-md">
                          <thead>
                            <tr style={{ fontSize: "13px" }}>
                              <th scope="col">#</th>
                              <th scope="col">Date</th>
                              <th scope="col">Fluid</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">User</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fluidchart.map((f, i) => {
                              return (
                                <tr key={i}>
                                  <th>{i + 1}</th>
                                  <td>{f.date}</td>
                                  <td>{f.fluid.fluid}</td>
                                  <td>{f.quantity}</td>
                                  <td>{f.user}</td>
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-danger btn-sm"
                                      onClick={() =>
                                        deleteHandler(f.id, "fluidchart")
                                      }
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion mt-3" id="output">
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading-output">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapse-output"
                    aria-expanded="true"
                    aria-controls="collapse-output"
                  >
                    View Outputs
                  </button>
                </h2>
                <div
                  id="collapse-output"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading-output"
                  data-parent="#output"
                >
                  <div className="tablexflow">
                    {output && (
                      <>
                        <table className="table table-bordered table-md">
                          <thead>
                            <tr style={{ fontSize: "13px" }}>
                              <th scope="col">#</th>
                              <th scope="col">Date</th>
                              <th scope="col">Output Type</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">User</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {output.map((f, i) => {
                              return (
                                <tr key={i}>
                                  <th>{i + 1}</th>
                                  <td>{f.date}</td>
                                  <td>{f.outputtype}</td>
                                  <td>{f.quantity}</td>
                                  <td>{f.user}</td>
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-danger btn-sm"
                                      onClick={() =>
                                        deleteHandler(f.id, "output")
                                      }
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default FluidChart;
