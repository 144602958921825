import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddStore from "../../modals/Pharmacy/AddStore";
import AddStoreType from "../../modals/Pharmacy/AddStoreType";

// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, deleteFromServer } from "../../global/requests";

const StoreManagement = () => {
  //   const [isemptyArray, setIsEmptyArray] = useState(false);
  const [store, setStore] = useState();
  const [type, setType] = useState();
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successType, setSuccessType] = useState(false);

  const closehandler = () => setSuccess(false);
  const closeTypehandler = () => setSuccessType(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseType = () => setShowType(false);
  const handleShowType = () => setShowType(true);

  const updateStoreHandler = (list) => setStore(list);
  const typeHandler = (list) => setType(list);
  const successHandler = (bool) => setSuccess(bool);
  const successTypeHandler = (bool) => setSuccessType(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/storemgt`);
    if(result.status) setStore(result.data);
  }

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const result = await deleteFromServer(`pharmacy/storemgt/${id}`);
      if(result.status) setStore(result.data);
      setSuccess(false);
      setSuccessType(false);
    }
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={closehandler}>
            Store has been added Successfully
          </Success>
        )}
        {successType && (
          <Success close={closeTypehandler}>
            Store Type has been added Successfully
          </Success>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add New Store
              </button>
              {/* <button
                className="btn btn-warning mb-4 ml-3"
                onClick={handleShowType}
              >
                Add Store Type
              </button> */}
            </div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">#</th>
                  <th scope="col">Store Name</th>
                  <th scope="col">Store Type</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {store &&
                  store.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td>{a.type}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddStore
          show={show}
          close={handleClose}
          success={successHandler}
          storemgt={updateStoreHandler}
        />
      )}
      {showType && (
        <AddStoreType
          show={showType}
          close={handleCloseType}
          success={successTypeHandler}
        />
      )}
    </>
  );
};

export default StoreManagement;
