import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddSingleTest from "../../modals/Laboratory/AddSingleTest";
import AddGroupTest from "../../modals/Laboratory/AddGroupTest";
import { getFromServer, deleteFromServer } from "../../global/requests";

// import axios from "axios";
// import Cookies from "js-cookie";

const TestAdmin = () => {
  const [testAdmin, setTestAdmin] = useState();
  const [search, setSearch] = useState();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [success, setSuccess] = useState(false);

  const closehandler = () => setSuccess(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const updateTestCategoryHandler = (list) => {
    setTestAdmin(list);
    setSearch(list);
  };
  const successHandler = (bool) => setSuccess(bool);

  if (success) {
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    // sharing data for both single and group test, as same model for both
    const result = await getFromServer(`diagnosis/single-test`);
    if(result.status) {
      setTestAdmin(result.data);
      setSearch(result.data);
    }
  }

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const result = await deleteFromServer(`diagnosis/test-admin/${id}`);
      if(result.status) {
        setTestAdmin(result.data);
        setSearch(result.data);
      }
      setSuccess(false);
    }
  };

  const searchHandler = (value) => {
    const newValue = search.filter((v) => {
      return v.testname.toLocaleLowerCase().includes(value.toLocaleLowerCase());
    });
    setTestAdmin(newValue);
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={closehandler}>
            Test has been added Successfully
          </Success>
        )}
        <div className="section-body">
          <h2 className="mb-4">Test Administration</h2>
          <div className="row">
            <div className="col-md-8">
              <button
                className="btn btn-primary mb-4 mr-3"
                onClick={handleShow}
              >
                Add Single Test
              </button>
              <button className="btn btn-primary mb-4" onClick={handleShow2}>
                Add Group Test
              </button>
            </div>
            <div className="col-md-4 mt-2 mb-2 mt-md-0 text-end">
              <input
                type="text"
                placeholder="Type to search"
                onChange={(e) => searchHandler(e.target.value)}
                className="form-control"
              />
            </div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">#</th>
                  <th scope="col">Test Name</th>
                  <th scope="col">Test Category</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {testAdmin &&
                  testAdmin.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.testname}</td>
                        <td>{a.category.category}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddSingleTest
          show={show}
          close={handleClose}
          success={successHandler}
          update={updateTestCategoryHandler}
          rad={false}
        />
      )}
      {show2 && (
        <AddGroupTest
          show={show2}
          close={handleClose2}
          success={successHandler}
          update={updateTestCategoryHandler}
          rad={false}
        />
      )}
    </>
  );
};

export default TestAdmin;
