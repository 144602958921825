import React from "react";

const MainContent = (props) => {
  return (
    <>
      <div class="main-content">
        <section class="section">{props.children}</section>
      </div>
    </>
  );
};

export default MainContent;
