import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import Loading from "../../ui/Loading";
import { getFromServer, deleteFromServer, postToServer } from "../../global/requests";

const DischargePatient = () => {
  const [patient, setPatient] = useState();
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/permanent-discharge/all`);
    if(result.status) {
      setPatient(result.data);
      setLoad(false);
    }
  }

  const dischargeHandler = async () => {
    setLoad(true);
    const result = await deleteFromServer(`main/permanent-discharge/all`);
    if(result.status) {
      setPatient(result.data);
      setLoad(false);
      setSuccess(true);
    }
  };
    const inpatientDischargeHandler = async () => {
    setLoad(true);
    const result = await postToServer(`main/permanent-inpatient-discharge/all`);
    if(result.status) {
      setPatient(result.data);
      setLoad(false);
      setSuccess(true);
    }
  };

  const dischargeSinglePatientHandler = async (pid) => {
    if(window.confirm('Are you sure you want to discharge this patient?')){
      setLoad(true);
      const result = await postToServer(`main/permanent-discharge/${pid}`);
      if(result.status) {
        setPatient(result.data);
        setLoad(false);
        setSuccess(true);
      }
    }
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={() => setSuccess(false)}>
            Patient(s) Discharge Succesfully
          </Success>
        )}
        <div className="section-body">
          {load && <Loading />}
          <h4>Discharge Patient</h4>
          <button
            className="btn btn-primary mt-3 mb-4"
            onClick={dischargeHandler}
          >
            Discharge OPD Patients
          </button>
           <button
            className="btn btn-primary mt-3 mb-4 ml-3"
            onClick={inpatientDischargeHandler}
          >
            Discharge InPatients
          </button>
          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col">Surname</th>
                  <th scope="col">Firstname</th>
                  <th scope="col">Sex</th>
                  <th scope="col">Company / Plan</th>
                  <th scope="col">Encounter No</th>
                  <th scope="col">Encounter Type</th>
                  <th scope="col">Hospital No</th>
                  <th scope="col">Discharge</th>
                </tr>
              </thead>
              <tbody>
                {patient &&
                  patient.map((t, index) => {
                    return (
                      <tr key={index}>
                        <th scope="col">{index + 1}</th>
                        <td scope="col">{t.patient.surname}</td>
                        <td scope="col">{t.patient.firstname}</td>
                        <td scope="col">{t.patient.sex}</td>
                        <td scope="col">{t.profile}</td>
                        <td scope="col">{t.pid}</td>
                        <td scope="col">{t.type}</td>
                        <td scope="col">{t.patient.hospitalid}</td>
                        <td>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => dischargeSinglePatientHandler(t.patient.pid)}
                          >
                            Discharge
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default DischargePatient;
