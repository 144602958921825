import React, { useState } from "react";
import Select from "react-select";
import { symptomsSelect, symptoms } from "./DoctorData";
import { postToServer } from "../../global/requests";
import Success from "../../ui/success";

function DiseaseDetection({ patient_pid, encounter_pid }) {
  const [selectedDisease, setSelectedDisease] = useState(null);
  const [data, setData] = useState(symptoms);
  const [result, setResult] = useState(null);

  // Handle selection
  const handleChange = (selectedOption) => {
    setSelectedDisease(selectedOption);
    let selectedSymtoms = {};
    selectedOption?.forEach((element) => {
      selectedSymtoms[element.value] = 1;
    });
    const newData = { ...symptoms, ...selectedSymtoms };
    setData(newData);
  };

  const handleSubmit = async () => {
    const result = await postToServer("modal/disease-detection", { data });
    if (result.status) {
      setResult(result.data.disease?.[0]);
    } else {
    }
  };

  return (
    <div className="mb-5">
      {result && (
        <Success close={() => setResult(null)}>
          Your detected disease is: <span style={{fontSize:'20px'}} className="fw-bolder">{result}</span>
        </Success>
      )}
      <h5>Detect Disease</h5>
      <Select
        isMulti={true}
        value={selectedDisease}
        onChange={handleChange}
        options={symptomsSelect}
        placeholder="Select a Disease"
        isClearable={true}
        isSearchable={true}
      />
      {/* Render other parts of your component here */}
      <button className="btn btn-primary mt-3" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
}

export default DiseaseDetection;
