import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import AutoInvestigationLab2 from "../Auto/AutoInvestigationLab2";
import ViewResults from "../../../modals/Doctor/ViewResults";
import { getFromServer, postToServer, deleteFromServer } from "../../../global/requests";

const InvestigationLab = (props) => {
  const [save, setSave] = useState(false);

  const [tags, setTags] = useState([]);
  const [investigation, setInvestigation] = useState();
  const [test, setTest] = useState();
  const [temp, setTemp] = useState();
  const [show, setShow] = useState();

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const getTags = (tag) => {
    setTags(tag);
  };

  useEffect(() => {
    loadLabs();
    loadLabTests();
  }, []);

  const loadLabs = async () => {
    const result = await getFromServer(`main/doctor/investigation/${props.patient_pid}/Lab`);
    if (result.status) {
      setInvestigation(result.data);
      setTemp(result.data[0]);
      console.log(result.data);
    }
  };
  const loadLabTests = async () => {
    const result = await getFromServer(`diagnosis/doctor/lab-test/${props.pid}`);
    if (result.status) setTest(result.data);
  };

  const submitHandler = async () => {
    if (tags.length > 0) {
      setSave(!save);

      const data = {
        encounter: props.pid,
        patient: props.patient_pid,
        test: tags,
        addedby: "",
      };
      const result = await postToServer(`main/doctor/investigation/none/Lab`, { data });
      if(result.status) {
        setInvestigation(result.data);
        setTags([]);
      }
    }
  };


  const resultHandler = (list) => {
    setTemp(list);
    setShow(true)
  };

  const deleteHandler = async (detail_id, investigation_id) =>{
    if(window.confirm("Are you sure you want to delete this?")){
      const merge_id = props.patient_pid + '_' + detail_id + '_' + investigation_id

      const result = await deleteFromServer(`main/doctor/investigation/${merge_id}/Lab`);
      if(result.status) setInvestigation(result.data);
    }
  }


  return (
    <div id="maincontent">
      <div>
        <div className="row">
          <div className="col-md-6 mt-4">
            {test && (
              <AutoInvestigationLab2
                options={test}
                tag={getTags}
                removeTag={save}
              />
            )}
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-primary mt-4 mb-3"
              onClick={submitHandler}
            >
              save
            </button>
          </div>
        </div>

        <table className="table responsive">
          {investigation &&
            investigation.map((a, index) => {
              return (
                <>
                  <thead>
                    <tr style={{ fontSize: "13px" }}>
                      <th scope="col">S/N</th>
                      <th scope="col">
                        Date{"("}
                        {a.encounter.pid}
                        {")"}
                      </th>
                      <th scope="col">Tests</th>
                      {/* <th scope="col">Actions</th> */}
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {a.investigationlab.map((d, j) => {
                      return (
                        <tr key={j}>
                          <th scope="row">{j + 1}</th>
                          <td>{a.date}</td>
                          <td>{d.test.testname}</td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => resultHandler(a)}
                            >
                              view results
                            </button>
                            <button class="btn btn-danger btn-sm ml-2" onClick={()=>deleteHandler(d.id,a.id)}>Delete</button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              );
            })}
        </table>
      </div>
      {show && <ViewResults show={show} list={temp} close={handleHide} />}
    </div>
  );
};

export default InvestigationLab;
