import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddCompany from "../../modals/Billing/AddCompany";
// import axios from "axios";
import { Link } from "react-router-dom";
// import Cookies from "js-cookie";
import { getFromServer, deleteFromServer } from "../../global/requests";

const CompanyMgt = () => {
  //   const [isemptyArray, setIsEmptyArray] = useState(false);
  const [company, setCompany] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateCompanyHandler = (list) => setCompany(list);
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/company`);
    if(result.status) setCompany(result.data);
  }

  const deleteHandler = async (id) => {
    const result = await deleteFromServer(`billing/company/${id}`);
    if(result.status) setCompany(result.data);
    setSuccess(false);
  };

  return (
    <>
      <MainContent>
        {success && <Success>Company has been added Successfully</Success>}
        <div className="section-body">
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add New Company
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Company Name</th>
                  <th scope="col">Address</th>
                  <th scope="col">PLan</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {company &&
                  company.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td>{a.address}</td>
                        <td>
                          <Link
                            to={"/company-management/plan/" + a.id}
                            className="btn btn-sm btn-primary"
                            style={{ paddingBottom: "5px" }}
                          >
                            view
                          </Link>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddCompany
          show={show}
          close={handleClose}
          success={successHandler}
          companies={updateCompanyHandler}
        />
      )}
    </>
  );
};

export default CompanyMgt;
