import React, { useState, useRef } from "react";
import MainContent from "../../ui/MainContent";
// import PatientHistoryDetail from "../Doctor/PatientHistoryDetail";
// import axios from "axios";
import Error from "../../ui/error";
import { useNavigate } from "react-router-dom";
import { getFromServer } from "../../global/requests";

const LaboratoryResult = () => {
  const navigate = useNavigate()
  const [list, setList] = useState();
  const [temp, setTemp] = useState();

  const [detail, setDetail] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [error, setError] = useState(false);
  const searchText = useRef();
  const date = useRef();

  const searchHandler = async (e) => {
    e.preventDefault();
    setError(false);

    var dates = date.current.value;
    var search = searchText.current.value;

    if (date.current.value.length <= 0) {
      dates = "none";
    }
    if (searchText.current.value.length <= 0) {
      search = "none";
    }

    if (searchText.current.value.length > 0) {
      const result = await getFromServer(`main/lab-result/${search}/${dates}/lab`);
      if(result.status) {
        setList(result.data);
        setIsSearch(true);
      }
    } else {
      if (date.current.value.length > 0) {
        const result = await getFromServer(`main/lab-result/${search}/${dates}/lab`);
        if(result.status) {
          setList(result.data);
          setIsSearch(true);
        }
      }
    }
  };
  const detailHandler = (singleList) => {
    // setDetail(true);
    navigate('/edit-result', {state:singleList})
    setTemp(singleList);
    console.log(singleList);
  };

  const backHandler = () => {
    setDetail(false);
  };

  return (
    <>
      <MainContent>
        <div className="section-body">
          {!detail && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4>Search</h4>
                      {error && (
                        <Error>Try to input surname and firstname both</Error>
                      )}
                      <div className="row">
                        <form
                          onSubmit={searchHandler}
                          method="GET"
                          className="card-header-form"
                          style={{ display: "flex" }}
                        >
                          <div className="col-md-6">
                            <p style={{ color: "blue" }}>
                              Search Patient With Surname and Firstname
                            </p>
                            <input
                              type="text"
                              name="search"
                              className="form-control"
                              placeholder="Search"
                              //   required
                              ref={searchText}
                            />
                            <button
                              type="submit"
                              style={{ color: "white" }}
                              className="btn btn-info mt-3"
                            >
                              Search
                            </button>
                          </div>
                          <div className="col-md-6">
                            <p style={{ color: "gray" }}>
                              Search Patient With Encounter Date
                            </p>
                            <input
                              type="date"
                              ref={date}
                              className="form-control"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isSearch && (
                <div>
                  <table className="table table-bordered table-stripped table-md">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Gender</th>
                        <th>DOB</th>
                        <th>Request Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {list &&
                        list.map((a, i) => {
                          return (
                            <>
                              {a.map((l, j) => {
                                return (
                                  <tr key={i}>
                                    <th>{i + j + 1}</th>
                                    <td>
                                      <img
                                        alt="image"
                                        width="100px"
                                        height="100px"
                                        src={
                                          window.baseURLuploaded +
                                          l.patient.captureimage
                                        }
                                        className="img-thumbnail rounded"
                                      />
                                    </td>
                                    <td>
                                    {l.patient.title}. {l.patient.surname} {l.patient.firstname}
                                    </td>
                                    <td>{l.patient.sex}</td>
                                    <td>{l.patient.dob}</td>
                                    <td>{l.date}</td>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => detailHandler(l)}
                                      >
                                        Edit
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </MainContent>
    </>
  );
};

export default LaboratoryResult;
