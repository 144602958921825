import React, { useState, useEffect } from "react";
import NurseCarePlanModel from "../../modals/AdmissionMgt/NurseCarePlanModel";
import axios from "axios";
import Success from "../../ui/success";
import Cookies from "js-cookie";


const NurseCarePlan = (props) => {
  const [careplan, setCarePlan] = useState(false);
  const [success, setSuccess] = useState(false);
  const [is_edit, setIsEdit] = useState(false);
  const [tempData, setTempData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    const url = `${window.baseURL}usermgt/nurse-care-plan/${props.encounter_pid}`;
    axios.get(url).then((res) => {
      setCarePlan(res.data);
      console.log(res.data);
    });
  }, []);

  const updateHandler = (list) => {
    setCarePlan(list);
  };

  const showHandler = (is_edit, data) => {
    setTempData(data);
    setIsEdit(is_edit);
    setShow(true);
  };

  const deleteHandler = (id) => {
    if (window.confirm("Do you want to delete this?")) {
      const merge_id = props.encounter_pid + "_" + id;
      const url = `${window.baseURL}usermgt/nurse-care-plan/${merge_id}`;
      axios
        .delete(url, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          setCarePlan(res.data);
        });
    }
  };

  return (
    <>
      <div className="mt-4  mb-2">
        <button
          className="btn btn-primary"
          onClick={() => showHandler(false, {})}
        >
          Add Record
        </button>
      </div>
      <div>
        {success && (
          <Success close={() => setSuccess(false)}>
            Data Initialized Successfully
          </Success>
        )}
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Nursing Diagnosis</th>
              <th>Objectives</th>
              <th>Nursing Order</th>
              <th>Evaluation</th>
              <th>Nurse Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {careplan &&
              careplan.map((c, i) => {
                return (
                  <tr key={c.id}>
                    <td>{i + 1}</td>
                    <td>{c.date}</td>
                    <td>{c.diagnosis}</td>
                    <td>{c.objectives}</td>
                    <td>{c.order}</td>
                    <td>{c.evaluation}</td>
                    <td>{c.nursename}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => showHandler(true, c)}
                      >
                        Edit
                      </button>
                      <button className="btn btn-sm btn-danger ml-1" onClick={()=>deleteHandler(c.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {show && (
        <NurseCarePlanModel
          show={show}
          close={() => setShow(false)}
          encounter_pid={props.encounter_pid}
          success={() => setSuccess(true)}
          update={updateHandler}
          is_edit={is_edit}
          tempData={tempData}
        />
      )}
    </>
  );
};

export default NurseCarePlan;
