import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddUser from "../../modals/UserMgt/AddUser";
// import axios from "axios";
// import Cookies from 'js-cookie'
import { getFromServer, deleteFromServer } from "../../global/requests";
import userPic from "../../../assets/img/user.png";

const UserMgt = () => {
  //   const [isemptyArray, setIsEmptyArray] = useState(false);
  const [user, setUser] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editUserId, setEditUserId] = useState(null);

  const updateUsersHandler = (list) => setUser(list);
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/usermgt/add/user`);
    if (result.status) setUser(result.data);
  };

  const editUser = (userId) => {
    setEditUserId(userId);
    setShow(true)
    setSuccess(false);
  }
  const closeUserPopup = () => {
    setShow(false);
    setEditUserId(null);
  }
  const addNewUser = () => {
    setShow(true);
    setSuccess(false);
  }

  return (
    <>
      <MainContent>
        {success && <Success close={() => setSuccess(false)}>User has been saved Successfully</Success>}
        <div className="section-body">
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-primary mb-4" onClick={addNewUser}>
                Add New User
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">#</th>
                  <th scope="col">User Id</th>
                  <th scope="col">Image</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Department</th>
                  <th scope="col">Permission</th>
                  <th scope="col" className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {user &&
                  user.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.username}</td>
                        <td className="">
                          {a.image ? (
                            <img
                              alt="Image"
                              className="rounded-circle"
                              width="40"
                              src={window.baseURLuploaded + a.image}
                            />
                          ) : (
                            <img
                              alt="Image"
                              className="rounded-circle"
                              width="40"
                              src={userPic}
                            />
                          )}
                        </td>
                        <td>{a.first_name}</td>
                        <td>{a.last_name}</td>
                        <td>{a.department}</td>
                        <td>{a.permission}</td>
                        <td>
                          <button className="btn btn-sm btn-success"
                            onClick={() => editUser(a.id)}
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddUser
          show={show}
          close={closeUserPopup}
          success={successHandler}
          users={updateUsersHandler}
          editUserId={editUserId}
        />
      )}
    </>
  );
};

export default UserMgt;
