export const symptoms = {
  itching: 0,
  skin_rash: 0,
  nodal_skin_eruptions: 0,
  continuous_sneezing: 0,
  shivering: 0,
  chills: 0,
  joint_pain: 0,
  stomach_pain: 0,
  acidity: 0,
  ulcers_on_tongue: 0,
  muscle_wasting: 0,
  vomiting: 0,
  burning_micturition: 0,
  "spotting_ urination": 0,
  fatigue: 0,
  weight_gain: 0,
  anxiety: 0,
  cold_hands_and_feets: 0,
  mood_swings: 0,
  weight_loss: 0,
  restlessness: 0,
  lethargy: 0,
  patches_in_throat: 0,
  irregular_sugar_level: 0,
  cough: 0,
  high_fever: 0,
  sunken_eyes: 0,
  breathlessness: 0,
  sweating: 0,
  dehydration: 0,
  indigestion: 0,
  headache: 0,
  yellowish_skin: 0,
  dark_urine: 0,
  nausea: 0,
  loss_of_appetite: 0,
  pain_behind_the_eyes: 0,
  back_pain: 0,
  constipation: 0,
  abdominal_pain: 0,
  diarrhoea: 0,
  mild_fever: 0,
  yellow_urine: 0,
  yellowing_of_eyes: 0,
  acute_liver_failure: 0,
  fluid_overload: 0,
  swelling_of_stomach: 0,
  swelled_lymph_nodes: 0,
  malaise: 0,
  blurred_and_distorted_vision: 0,
  phlegm: 0,
  throat_irritation: 0,
  redness_of_eyes: 0,
  sinus_pressure: 0,
  runny_nose: 0,
  congestion: 0,
  chest_pain: 0,
  weakness_in_limbs: 0,
  fast_heart_rate: 0,
  pain_during_bowel_movements: 0,
  pain_in_anal_region: 0,
  bloody_stool: 0,
  irritation_in_anus: 0,
  neck_pain: 0,
  dizziness: 0,
  cramps: 0,
  bruising: 0,
  obesity: 0,
  swollen_legs: 0,
  swollen_blood_vessels: 0,
  puffy_face_and_eyes: 0,
  enlarged_thyroid: 0,
  brittle_nails: 0,
  swollen_extremeties: 0,
  excessive_hunger: 0,
  extra_marital_contacts: 0,
  drying_and_tingling_lips: 0,
  slurred_speech: 0,
  knee_pain: 0,
  hip_joint_pain: 0,
  muscle_weakness: 0,
  stiff_neck: 0,
  swelling_joints: 0,
  movement_stiffness: 0,
  spinning_movements: 0,
  loss_of_balance: 0,
  unsteadiness: 0,
  weakness_of_one_body_side: 0,
  loss_of_smell: 0,
  bladder_discomfort: 0,
  "foul_smell_of urine": 0,
  continuous_feel_of_urine: 0,
  passage_of_gases: 0,
  internal_itching: 0,
  "toxic_look_(typhos)": 0,
  depression: 0,
  irritability: 0,
  muscle_pain: 0,
  altered_sensorium: 0,
  red_spots_over_body: 0,
  belly_pain: 0,
  abnormal_menstruation: 0,
  "dischromic _patches": 0,
  watering_from_eyes: 0,
  increased_appetite: 0,
  polyuria: 0,
  family_history: 0,
  mucoid_sputum: 0,
  rusty_sputum: 0,
  lack_of_concentration: 0,
  visual_disturbances: 0,
  receiving_blood_transfusion: 0,
  receiving_unsterile_injections: 0,
  coma: 0,
  stomach_bleeding: 0,
  distention_of_abdomen: 0,
  history_of_alcohol_consumption: 0,
  "fluid_overload.1": 0,
  blood_in_sputum: 0,
  prominent_veins_on_calf: 0,
  palpitations: 0,
  painful_walking: 0,
  pus_filled_pimples: 0,
  blackheads: 0,
  scurring: 0,
  skin_peeling: 0,
  silver_like_dusting: 0,
  small_dents_in_nails: 0,
  inflammatory_nails: 0,
  blister: 0,
  red_sore_around_nose: 0,
  yellow_crust_ooze: 0,
};

export const symptomsSelect = [
  { label: "itching", value: "itching" },
  { label: "skin_rash", value: "skin_rash" },
  { label: "nodal_skin_eruptions", value: "nodal_skin_eruptions" },
  { label: "continuous_sneezing", value: "continuous_sneezing" },
  { label: "shivering", value: "shivering" },
  { label: "chills", value: "chills" },
  { label: "joint_pain", value: "joint_pain" },
  { label: "stomach_pain", value: "stomach_pain" },
  { label: "acidity", value: "acidity" },
  { label: "ulcers_on_tongue", value: "ulcers_on_tongue" },
  { label: "muscle_wasting", value: "muscle_wasting" },
  { label: "vomiting", value: "vomiting" },
  { label: "burning_micturition", value: "burning_micturition" },
  { label: '"spotting_ urination', value: '"spotting_ urination' },
  { label: "fatigue", value: "fatigue" },
  { label: "weight_gain", value: "weight_gain" },
  { label: "anxiety", value: "anxiety" },
  { label: "cold_hands_and_feets", value: "cold_hands_and_feets" },
  { label: "mood_swings", value: "mood_swings" },
  { label: "weight_loss", value: "weight_loss" },
  { label: "restlessness", value: "restlessness" },
  { label: "lethargy", value: "lethargy" },
  { label: "patches_in_throat", value: "patches_in_throat" },
  { label: "irregular_sugar_level", value: "irregular_sugar_level" },
  { label: "cough", value: "cough" },
  { label: "high_fever", value: "high_fever" },
  { label: "sunken_eyes", value: "sunken_eyes" },
  { label: "breathlessness", value: "breathlessness" },
  { label: "sweating", value: "sweating" },
  { label: "dehydration", value: "dehydration" },
  { label: "indigestion", value: "indigestion" },
  { label: "headache", value: "headache" },
  { label: "yellowish_skin", value: "yellowish_skin" },
  { label: "dark_urine", value: "dark_urine" },
  { label: "nausea", value: "nausea" },
  { label: "loss_of_appetite", value: "loss_of_appetite" },
  { label: "pain_behind_the_eyes", value: "pain_behind_the_eyes" },
  { label: "back_pain", value: "back_pain" },
  { label: "constipation", value: "constipation" },
  { label: "abdominal_pain", value: "abdominal_pain" },
  { label: "diarrhoea", value: "diarrhoea" },
  { label: "mild_fever", value: "mild_fever" },
  { label: "yellow_urine", value: "yellow_urine" },
  { label: "yellowing_of_eyes", value: "yellowing_of_eyes" },
  { label: "acute_liver_failure", value: "acute_liver_failure" },
  { label: "fluid_overload", value: "fluid_overload" },
  { label: "swelling_of_stomach", value: "swelling_of_stomach" },
  { label: "swelled_lymph_nodes", value: "swelled_lymph_nodes" },
  { label: "malaise", value: "malaise" },
  {
    label: "blurred_and_distorted_vision",
    value: "blurred_and_distorted_vision",
  },
  { label: "phlegm", value: "phlegm" },
  { label: "throat_irritation", value: "throat_irritation" },
  { label: "redness_of_eyes", value: "redness_of_eyes" },
  { label: "sinus_pressure", value: "sinus_pressure" },
  { label: "runny_nose", value: "runny_nose" },
  { label: "congestion", value: "congestion" },
  { label: "chest_pain", value: "chest_pain" },
  { label: "weakness_in_limbs", value: "weakness_in_limbs" },
  { label: "fast_heart_rate", value: "fast_heart_rate" },
  {
    label: "pain_during_bowel_movements",
    value: "pain_during_bowel_movements",
  },
  { label: "pain_in_anal_region", value: "pain_in_anal_region" },
  { label: "bloody_stool", value: "bloody_stool" },
  { label: "irritation_in_anus", value: "irritation_in_anus" },
  { label: "neck_pain", value: "neck_pain" },
  { label: "dizziness", value: "dizziness" },
  { label: "cramps", value: "cramps" },
  { label: "bruising", value: "bruising" },
  { label: "obesity", value: "obesity" },
  { label: "swollen_legs", value: "swollen_legs" },
  { label: "swollen_blood_vessels", value: "swollen_blood_vessels" },
  { label: "puffy_face_and_eyes", value: "puffy_face_and_eyes" },
  { label: "enlarged_thyroid", value: "enlarged_thyroid" },
  { label: "brittle_nails", value: "brittle_nails" },
  { label: "swollen_extremeties", value: "swollen_extremeties" },
  { label: "excessive_hunger", value: "excessive_hunger" },
  { label: "extra_marital_contacts", value: "extra_marital_contacts" },
  { label: "drying_and_tingling_lips", value: "drying_and_tingling_lips" },
  { label: "slurred_speech", value: "slurred_speech" },
  { label: "knee_pain", value: "knee_pain" },
  { label: "hip_joint_pain", value: "hip_joint_pain" },
  { label: "muscle_weakness", value: "muscle_weakness" },
  { label: "stiff_neck", value: "stiff_neck" },
  { label: "swelling_joints", value: "swelling_joints" },
  { label: "movement_stiffness", value: "movement_stiffness" },
  { label: "spinning_movements", value: "spinning_movements" },
  { label: "loss_of_balance", value: "loss_of_balance" },
  { label: "unsteadiness", value: "unsteadiness" },
  { label: "weakness_of_one_body_side", value: "weakness_of_one_body_side" },
  { label: "loss_of_smell", value: "loss_of_smell" },
  { label: "bladder_discomfort", value: "bladder_discomfort" },
  { label: '"foul_smell_of urine', value: '"foul_smell_of urine' },
  { label: "continuous_feel_of_urine", value: "continuous_feel_of_urine" },
  { label: "passage_of_gases", value: "passage_of_gases" },
  { label: "internal_itching", value: "internal_itching" },
  { label: '"toxic_look_(typhos', value: '"toxic_look_(typhos' },
  { label: "depression", value: "depression" },
  { label: "irritability", value: "irritability" },
  { label: "muscle_pain", value: "muscle_pain" },
  { label: "altered_sensorium", value: "altered_sensorium" },
  { label: "red_spots_over_body", value: "red_spots_over_body" },
  { label: "belly_pain", value: "belly_pain" },
  { label: "abnormal_menstruation", value: "abnormal_menstruation" },
  { label: '"dischromic _patches', value: '"dischromic _patches' },
  { label: "watering_from_eyes", value: "watering_from_eyes" },
  { label: "increased_appetite", value: "increased_appetite" },
  { label: "polyuria", value: "polyuria" },
  { label: "family_history", value: "family_history" },
  { label: "mucoid_sputum", value: "mucoid_sputum" },
  { label: "rusty_sputum", value: "rusty_sputum" },
  { label: "lack_of_concentration", value: "lack_of_concentration" },
  { label: "visual_disturbances", value: "visual_disturbances" },
  {
    label: "receiving_blood_transfusion",
    value: "receiving_blood_transfusion",
  },
  {
    label: "receiving_unsterile_injections",
    value: "receiving_unsterile_injections",
  },
  { label: "coma", value: "coma" },
  { label: "stomach_bleeding", value: "stomach_bleeding" },
  { label: "distention_of_abdomen", value: "distention_of_abdomen" },
  {
    label: "history_of_alcohol_consumption",
    value: "history_of_alcohol_consumption",
  },
  { label: '"fluid_overload.1', value: '"fluid_overload.1' },
  { label: "blood_in_sputum", value: "blood_in_sputum" },
  { label: "prominent_veins_on_calf", value: "prominent_veins_on_calf" },
  { label: "palpitations", value: "palpitations" },
  { label: "painful_walking", value: "painful_walking" },
  { label: "pus_filled_pimples", value: "pus_filled_pimples" },
  { label: "blackheads", value: "blackheads" },
  { label: "scurring", value: "scurring" },
  { label: "skin_peeling", value: "skin_peeling" },
  { label: "silver_like_dusting", value: "silver_like_dusting" },
  { label: "small_dents_in_nails", value: "small_dents_in_nails" },
  { label: "inflammatory_nails", value: "inflammatory_nails" },
  { label: "blister", value: "blister" },
  { label: "red_sore_around_nose", value: "red_sore_around_nose" },
  { label: "yellow_crust_ooze", value: "yellow_crust_ooze" },
];
