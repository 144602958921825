import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { postFormToServer, getFromServer } from "../../global/requests";
import Error from "../../ui/error";

const AddUser = (props) => {
  const [departments, setDepartments] = useState();
  const [permission, setPermission] = useState();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong.");
  const [departmentId, setDepartmentId] = useState(0);

  const username = useRef();
  const password = useRef();
  const first_name = useRef();
  const last_name = useRef();
  // const designation = useRef();
  const permissions = useRef();
  const image = useRef();
  const credential = useRef();
  // const grade = useRef();

  useEffect(() => {
    doInitialTasks();
    if (props.editUserId) loadUserDetails();
  }, []);

  const updateError = (status = false, msg = null) => {
    setErrorMsg(msg ? msg : "Something went wrong.");
    setError(status);
  }

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/useraccess/add/department`);
    if (result.status) setDepartments(result.data);
    else updateError(true);
  };
  const loadUserDetails = async () => {
    const result = await getFromServer(
      `usermgt/usermgt/user/${props.editUserId}`
    );
    if (result.status) {
      username.current.value = result.data.username;
      first_name.current.value = result.data.first_name;
      last_name.current.value = result.data.last_name;
      setDepartmentId(result.data.useraccess);
    } else updateError(true);
  };

  const permissionChangeHandler = async (e) => {
    setDepartmentId(e.target.value);
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    const result = await getFromServer(
      `usermgt/useraccess/${parseInt(option)}`
    );
    if (result.status) setPermission(result.data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", username.current.value);
    formData.append("password", password.current.value);
    formData.append("first_name", first_name.current.value);
    formData.append("last_name", last_name.current.value);
    formData.append("department", departmentId);
    formData.append("permission", permissions.current.value);
    formData.append("image", image.current.files[0]);
    formData.append("credentials", credential.current.files[0]);
    setSaving(true);
    updateError(false);
    const result = await postFormToServer(
      props.editUserId ? `usermgt/usermgt/user/${props.editUserId}` : `usermgt/usermgt/add/user`,
      formData
    );
    setSaving(false);
    if (result.status) {
      if(result.data.error) updateError(true, result.data.error);
      else {
        console.log("Success:", result.data);
        props.close();
        props.success(true);
        props.users(result.data);
      }
    } else updateError(true, result.data.msg);
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              {error && <Error close={() => updateError()}>{errorMsg}</Error>}
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="id" className="col-form-label">
                      User Id:
                    </label>
                    <input
                      type="text"
                      name="id"
                      className="form-control"
                      required
                      ref={username}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="password" className="col-form-label">
                      Password:
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      required={props.editUserId ? false : true}
                      ref={password}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="first_name" className="col-form-label">
                      First Name:
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      required
                      ref={first_name}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="last_name" className="col-form-label">
                      Last Name:
                    </label>
                    <input
                      type="text"
                      name="deparlast_nametment"
                      className="form-control"
                      required
                      ref={last_name}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  {/* <label>Designation</label>
                  <select className="form-select" ref={designation}>
                    <option value="Doctor">Doctor</option>
                    <option value="Pharmacist">Pharmacist</option>
                    <option value="Nurse">Nurse</option>
                    <option value="Radiologist">Radiologist</option>
                    <option value="Receptionist">Receptionist</option>
                    <option value="Laboratory Manager">
                      Laboratory Manager
                    </option>
                    <option value="Hospital Administration">
                      Hospital Administration
                    </option>
                  </select> */}
                  <label>Department</label>
                  <select
                    className="form-select"
                    onChange={permissionChangeHandler}
                    value={departmentId}
                  >
                    {departments &&
                      departments.map((u) => {
                        return (
                          <option id={u.id} value={u.id}>
                            {u.department}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="col-md-6">
                  <label>Permissions</label>
                  <select className="form-select" ref={permissions}>
                    {permission &&
                      permission.map((u) => {
                        return <option value={u.key}>{u.key}</option>;
                      })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>Choose profile pic</label>
                  <input
                    type="file"
                    name="capture_image"
                    className="form-control"
                    // required
                    accept="image/png,image/jpg,image/jpeg"
                    ref={image}
                  />
                </div>
                <div className="col-md-6">
                  <label>Upload Credentials</label>
                  <input
                    type="file"
                    name="capture_image"
                    className="form-control"
                    // required
                    ref={credential}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label>Select Grade</label>
                  <input
                    type="text"
                    name="capture_image"
                    className="form-control"
                    // ref={image}
                  />
                </div>
                <div className="col-md-6 mt-3">
                  <div className="modal-footer">
                    {saving ? (
                      <button
                        type="submit"
                        disabled
                        className="btn btn-primary"
                      >
                        <span>
                          <i
                            style={{ fontSize: "20px" }}
                            className="fas fa-spinner fa-spin"
                          ></i>
                          &nbsp;
                        </span>
                        Saving ...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div> */}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUser;
