import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import Loading from "../../ui/Loading";
import MainContent from "../../ui/MainContent";
import { useParams, useNavigate } from "react-router-dom";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer, postToServer } from "../../global/requests";

const LabPendingRequest = () => {
  const param = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [p, setP] = useState();
  const [list, setList] = useState();

  useEffect(() => {
    console.log(param.page);
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/investigation/Lab`);
    if(result.status) {
      console.log(result.data);
      setList(result.data);
      setP(result.data[0]);
      setIsLoading(false);
    }
  }

  const resultHandler = () => {
    navigate("/test-report", { state: p });
  };

  const clickHandler = (i) => {
    setP(list[i]);
  };

  const clearAllHandler = async () => {
    if (
      window.confirm(
        "Do you want to clear the patient waiting list?\nCaution: It will clear all. but you can again search it from results page."
      )
    ) {
      setIsLoading(true);

      const result = await postToServer(`main/investigation/Lab`);
      if(result.status) {
        setIsLoading(false);
        setP(null);
        // setShow(false);
      }
    }
  };

  return (
    <>
      <MainContent>
        <div className="row">
          <div className="col-md-9">
            <div class="row">
              <div class="col-md-6">
                <h2 className="mb-4">Laboratory Pending Requests</h2>
              </div>
              <div class="col-md-6 text-end">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={clearAllHandler}
                >
                  Clear List
                </button>
              </div>
            </div>

            {!p && (
              <div className="text-center mt-5">
                <h2>Nothing Found</h2>
              </div>
            )}
            {isLoading && <Loading />}
            {p && (
              <div className="section-body">
                <PatientDetailCard list={p.encounter} isEncounter={true} />
                <div>
                  <table className="table table-md table-bordered">
                    <thead>
                      <tr>
                        <th colSpan="2">Tests</th>
                      </tr>
                    </thead>
                    <tbody>
                      {p.investigationlab.map((t) => {
                        return (
                          <tr>
                            <td>{t.test.testname}</td>
                            <td>
                              {t.test.is_paid == true ? (
                                <button className="btn btn-success btn-sm">
                                  Payment Confirmed
                                </button>
                              ) : (
                                <button className="btn btn-danger btn-sm">
                                  Payment not confirmed
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <button className="btn btn-primary" onClick={resultHandler}>
                  Enter Results
                </button>
              </div>
            )}
          </div>
          {p && (
            <div className="col-md-3">
              <div className="card card-primary">
                <div className="card-body">
                  <ul className="list-group">
                    {list &&
                      list.map((e, i) => {
                        return (
                          <li
                            style={{ cursor: "pointer" }}
                            className="list-group-item"
                            onClick={() => clickHandler(i)}
                          >
                            {/* <span style={{ color: "darkgreen" }}></span> */}
                            {e.patient.title}. {e.patient.surname}{" "}
                            {e.patient.firstname}{" "}
                            {e.encounter.date.split(" ")[0]}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </MainContent>
    </>
  );
};

export default LabPendingRequest;
