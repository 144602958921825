import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddTestCategory from "../../modals/Laboratory/AddTestCategory";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, deleteFromServer } from "../../global/requests";

const TestCategory = () => {
  const [testCategory, setTestCategory] = useState();
  const [search, setSearch] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const closehandler = () => setSuccess(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateTestCategoryHandler = (list) => {
    setTestCategory(list);
    setSearch(list);
  };
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`diagnosis/test-category`);
    if(result.status) {
      setTestCategory(result.data);
      setSearch(result.data);
    }
  }

  if (success) {
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  }

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const result = await deleteFromServer(`diagnosis/test-category/${id}`);
      if(result.status) {
        setTestCategory(result.data);
        setSearch(result.data);
        setSuccess(false);
      }
    }
  };

  const searchHandler = (value) => {
    const newValue = search.filter((v) => {
      return v.category.toLocaleLowerCase().includes(value.toLocaleLowerCase());
    });
    setTestCategory(newValue);
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={closehandler}>
            Test Category has been added Successfully
          </Success>
        )}
        <div className="section-body">
          <h2 className="mb-4">Test Category</h2>
          <div className="row">
            <div className="col-md-8">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add New Test Category
              </button>
            </div>
            <div className="col-md-4 mt-2 mb-2 mt-md-0 text-end">
              <input
                type="text"
                placeholder="Type to search"
                onChange={(e) => searchHandler(e.target.value)}
                className="form-control"
              />
            </div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">#</th>
                  <th scope="col">Test Category</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {testCategory &&
                  testCategory.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.category}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddTestCategory
          show={show}
          close={handleClose}
          success={successHandler}
          update={updateTestCategoryHandler}
        />
      )}
    </>
  );
};

export default TestCategory;
