import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { getFromServer, postToServer } from "../../global/requests";

const AddRequisition = (props) => {
  const [departmentList, setDepartmentList] = useState([]);
  const [consumableList, setconsumableList] = useState([]);
  const [store, setStore] = useState([]);



  const from = useRef();
  const to = useRef();
  const items = useRef();
  const qty = useRef();
  const note = useRef();

  useEffect(() => {
    initiateDepartments();
    initiateConsumables();
    initiateStore();
    doInitialTasks();
  }, []);

  const initiateDepartments = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/add/drug`);
    if(result.status) setDepartmentList(result.data);
  }

  const initiateConsumables = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/consumable`);
    if(result.status) setconsumableList(result.data);
  }

  const initiateStore = async () => {
    const result = await getFromServer(`pharmacy/storemgt`);
    if(result.status) setStore(result.data);
  }

  const doInitialTasks = async () => {
    if (props.type != "Add") {
      const result = await getFromServer(`nursing/requisition/${props.type}`);
      if(result.status) {
        const data = result.data;
        document.getElementById("from").value = data.froms.id;
        document.getElementById("to").value = data.to.id;
        document.getElementById("item").value = data.item;
        document.getElementById("qty").value = data.quantity;
        document.getElementById("note").value = data.note;
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      // froms: from.current.value,
      // to: to.current.value,
      froms: 'Dispensary Store',
      to: 'Nursing Store',
      item: items.current.value,
      qty: qty.current.value,
      note: note.current.value,
    };
    let url = "";
    if (props.type == "Add") {
      url = `nursing/requisition`;
    } else {
      url = `nursing/requisition/${props.type}`;
    }

    const result = await postToServer(url, { data });
    if(result.status) {
      props.close();
      // props.success(true);
      props.requisition(result.data);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="lg">
        <Modal.Header closeButton>
          {props.type == "Add" ? (
            <Modal.Title>Add Requisition</Modal.Title>
          ) : (
            <Modal.Title>Edit Requisition</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">From</label>
                    {/* <select className="form-select" ref={from} id="item">
                      {store &&
                        store.map((u) => {
                          return (
                            <option key={u.id} value={u.id}>
                              {u.name}
                            </option>
                          );
                        })}
                    </select> */}
                    <input type="text" className="form-control" disabled value="Dispensary Store"></input>

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">To</label>
                    {/* <select className="form-select" ref={to} id="item">
                      {store &&
                        store.map((u) => {
                          return (
                            <option key={u.id} value={u.id}>
                              {u.name}
                            </option>
                          );
                        })}
                    </select> */}
                    <input type="text" className="form-control" disabled value="Nursing Store"></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label>Items</label>
                    <select className="form-select" ref={items} id="item">
                      {departmentList &&
                        departmentList.map((u) => {
                          return (
                            <option key={u.id} value={u.id + "_" + u.brandname + "_drug"}>
                              {u.brandname}
                            </option>
                          );
                        })}
                         {consumableList &&
                        consumableList.map((u) => {
                          return (
                            <option key={u.id} value={u.id + "_" + u.name + "_consumable"}>
                              {u.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Quantity</label>
                    <input
                      type="number"
                      id="qty"
                      className="form-control"
                      required
                      ref={qty}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">Note</label>
                    <input
                      type="text"
                      className="form-control"
                      ref={note}
                      id="note"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                {props.type == "Add" ? "Add" : "Update"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddRequisition;
