import React, { useState, useRef, useEffect } from "react";
import Diagnosis from "./Diagnosis";
import Prescription from "./Prescription";
import Complain from "./Complain";
import Procedure from "./Procedure";
import MedicalHistory from "./MedicalHistory";
import ReviewSystem from "./ReviewofSystem";
import ManualWriteup from "./ManualWriteUp";
import PatientHistory from "./PatientHistory";
import Examination from "./Examination";
import Plan from "./Plan";
import Investigation from "./Investigation";
import PatientDetailCard from "../../ui/PatientDetailCard";
import DiseaseDetection from "./DiseaseDetection";


const AttendtoPatient = (props) => {
  const [success, setSuccess] = useState(false);
  const [list, setList] = useState(props.list);
  const [diagnosis, setDiagnosis] = useState(false);
  const [prescription, setPrescription] = useState(false);
  const [procedure, setProcedure] = useState(false);
  const [complain, setComplain] = useState(false);
  const [medical, setMedical] = useState(false);
  const [review, setReview] = useState(false);
  const [manual, setManual] = useState(false);
  const [patienthistory, setPatientHistory] = useState(false);
  const [examination, setExamination] = useState(false);
  const [diseaseDetection, setDiseaseDetection] = useState(false);
  const [plan, setPlan] = useState(false);
  const [investigation, setInvestigation] = useState(true);

  const diagnosisHandler = () => {
    setDiagnosis(true);
    setPrescription(false);
  };
  const procedureHandler = () => setProcedure(true);
  const prescriptionHandler = () => setPrescription(true);

  useEffect(() => {
    const newState = list.filter((l) => {
      return l.pid == props.pid;
    });
    setList(newState);
    console.log(newState);
  }, []);

  const refreshHandler2 = () => {
    props.reloads();
  };

  const data_comingback_from_prescription = (dictionary) => {
    console.log("inside attend to patient", dictionary);
    props.newStateDict(dictionary);
  };

  return (
    <>
      {props.pid && (
        <div className="row">
          <div className="col-md-12">
            <div className=" author-box card-primary">
              {/* <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div
                    className="author-box-left"
                    style={{ paddingRight: "10px" }}
                  >
                    {list[0] && (
                      <img
                        alt="image"
                        width="100px"
                        height="100px"
                        src={
                          window.baseURLuploaded +
                          list[0].patient.captureimage
                        }
                        className="img-thumbnail rounded"
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="author-box-details">
                    <div className="row">
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              <PatientDetailCard list={list[0].patient} isEncounter={false} />

              {!list[0].vital.length > 0 && <h4 className="text-center">Vitals not found. Add them from Vital page</h4>}

              <table className="table table-bordered table-md mt-4">
                {list[0].vital &&
                  list[0].vital.map((l) => {
                    return (
                      <>
                        <thead>
                          <tr>
                            <th scope="col">Weight</th>
                            <th scope="col">Height</th>
                            <th scope="col">Body Temperature</th>
                            <th scope="col">Pulse Rate</th>
                            <th scope="col">Blood Pressure</th>
                            <th scope="col">Respiration</th>
                            <th scope="col">Oxygen Level</th>
                            <th scope="col">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {l.vitaldata.map((d, j) => {
                            return (
                              <tr key={j}>
                                <td scope="col">{d.weight} kg</td>
                                <td scope="col">{d.height} m</td>
                                <td scope="col">
                                  {d.temperature} <sup>o</sup>C
                                </td>
                                <td scope="col">{d.pulserate} bpm</td>
                                <td scope="col">{d.bloodpressure} mmHg</td>
                                <td scope="col">{d.respiration}</td>
                                <td scope="col">{d.oxygenlevel}</td>
                                <td scope="col">{d.remarks}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </>
                    );
                  })}
              </table>

              <div className="row">
               
                  <div className="" >
                    <ul
                      className="nav nav-tabs card-header-tabs mt-4 mb-4"
                      id="myTab"
                      role="tablist"
                      // style={{backgroundColor:"#F5F5F5"}}
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"patienthistory-tab" + props.pid}
                          data-toggle="tab"
                          href={"#patienthistory" + props.pid}
                          role="tab"
                          aria-selected="true"
                          onClick={() => {
                            setPatientHistory(true);
                          }}
                        >
                          Patient History
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"complain-tab" + props.pid}
                          data-toggle="tab"
                          href={"#complain" + props.pid}
                          role="tab"
                          aria-selected="true"
                          onClick={() => {
                            setComplain(true);
                          }}
                        >
                          Complain
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"diagnosis-tab" + props.pid}
                          data-toggle="tab"
                          href={"#diagnosis" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={diagnosisHandler}
                        >
                          Diagnosis
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"investigation-tab" + props.pid}
                          data-toggle="tab"
                          href={"#investigation" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={() => setInvestigation(true)}
                        >
                          Investigation
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"procedure-tab" + props.pid}
                          data-toggle="tab"
                          href={"#procedure" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={procedureHandler}
                        >
                          Procedure
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"manual-tab" + props.pid}
                          data-toggle="tab"
                          href={"#manual" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={() => setManual(true)}
                        >
                          Manual Write-Up
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"review-tab" + props.pid}
                          data-toggle="tab"
                          href={"#review" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={() => setReview(true)}
                        >
                          Review Of System
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"medicalhistory-tab" + props.pid}
                          data-toggle="tab"
                          href={"#medicalhistory" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={() => setMedical(true)}
                        >
                          Medical History
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"examination-tab" + props.pid}
                          data-toggle="tab"
                          href={"#examination" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={() => setExamination(true)}
                        >
                          Examination
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"plan-tab" + props.pid}
                          data-toggle="tab"
                          href={"#plan" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={() => setPlan(true)}
                        >
                          Plan
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"prescription-tab" + props.pid}
                          data-toggle="tab"
                          href={"#prescription" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={prescriptionHandler}
                        >
                          Prescription
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"disease-tab" + props.pid}
                          data-toggle="tab"
                          href={"#disease" + props.pid}
                          role="tab"
                          aria-selected="false"
                          onClick={() => setDiseaseDetection(true)}
                        >
                          Detect Disease
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="card-body">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id={"patienthistory" + props.pid}
                        role="tabpanel"
                      >
                        {patienthistory && (
                          <PatientHistory patient_pid={props.patient_pid} />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"complain" + props.pid}
                        role="tabpanel"
                      >
                        {complain && (
                          <Complain
                            encounter_pid={props.pid}
                            patient_pid={props.patient_pid}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"diagnosis" + props.pid}
                        role="tabpanel"
                      >
                        {diagnosis && (
                          <Diagnosis
                            pid={props.pid}
                            patient_pid={props.patient_pid}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"investigation" + props.pid}
                        role="tabpanel"
                      >
                        {/* {investigation && ( */}
                        <Investigation
                          pid={props.pid}
                          patient_pid={props.patient_pid}
                        />
                        {/* )} */}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"procedure" + props.pid}
                        role="tabpanel"
                      >
                        {procedure && (
                          <Procedure
                            encounter_pid={props.pid}
                            patient_pid={props.patient_pid}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"manual" + props.pid}
                        role="tabpanel"
                      >
                        {manual && (
                          <ManualWriteup
                            encounter_pid={props.pid}
                            patient_pid={props.patient_pid}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"review" + props.pid}
                        role="tabpanel"
                      >
                        {review && <ReviewSystem encounter_pid={props.pid} />}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"medicalhistory" + props.pid}
                        role="tabpanel"
                      >
                        {medical && (
                          <MedicalHistory encounter_pid={props.pid} />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"examination" + props.pid}
                        role="tabpanel"
                      >
                        {examination && (
                          <Examination encounter_pid={props.pid} />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"plan" + props.pid}
                        role="tabpanel"
                      >
                        {plan && (
                          <Plan
                            encounter_pid={props.pid}
                            patient_pid={props.patient_pid}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"prescription" + props.pid}
                        role="tabpanel"
                      >
                        {prescription && (
                          <Prescription
                            // newStateDict={data_comingback_from_prescription}
                            encounter_pid={props.pid}
                            patient_pid={props.patient_pid}
                            // reloading={refreshHandler2}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"disease" + props.pid}
                        role="tabpanel"
                      >
                        {diseaseDetection && (
                          <DiseaseDetection
                            // newStateDict={data_comingback_from_prescription}
                            encounter_pid={props.pid}
                            patient_pid={props.patient_pid}
                            // reloading={refreshHandler2}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AttendtoPatient;
