import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import Success from "../../ui/success";
import { getFromServer, postToServer } from "../../global/requests";


const Examination = (props) => {
  const param = useLocation();
  const navigate = useNavigate();
  const [medical, setMedical] = useState();
  const [medical1, setMedical1] = useState();
  const [medical2, setMedical2] = useState();
  const [medical3, setMedical3] = useState();
  const [medical4, setMedical4] = useState();
  const [medical5, setMedical5] = useState();
  const [success, setSuccess] = useState(false);
  const [generalQuestionData, setGeneralQuestionData] = useState([]);
  const [abdomenQuestionData, setabdomenQuestionData] = useState([]);
  const [chestQuestionData, setChestQuestionData] = useState([]);
  const [cvsQuestionData, setCVSQuestionData] = useState([]);
  const [dentalQuestionData, setDentalQuestionData] = useState([]);
  const [cnsQuestionData, setCNSQuestionData] = useState([]);

  useEffect(() => {
    const url = `${window.baseURL}adminmgt/encounter/general-examination`;
    const url1 = `${window.baseURL}adminmgt/encounter/abdomen-examination`;
    const url2 = `${window.baseURL}adminmgt/encounter/chest-examination`;
    const url3 = `${window.baseURL}adminmgt/encounter/CVS-examination`;
    const url4 = `${window.baseURL}adminmgt/encounter/dental-examination`;
    const url5 = `${window.baseURL}adminmgt/encounter/CNS-examination`;
    axios.get(url).then((res) => {setMedical(res.data);});
    axios.get(url1).then((res) => {setMedical1(res.data);});
    axios.get(url2).then((res) => {setMedical2(res.data);});
    axios.get(url3).then((res) => {setMedical3(res.data);});
    axios.get(url4).then((res) => {setMedical4(res.data);});
    axios.get(url5).then((res) => {setMedical5(res.data);});
    getGeneralExamination();
    getAbdomenExamination();
    getChestExamination();
    getCVSExamination();
    getDentalExamination();
    getCNSExamination();

   
  }, []);

  const pregnancyPageHandler = () => {
    navigate(`/pregnancy/pid=${props.encounter_pid}`);
  };

  const clear = () => {
    const  checks = document.querySelectorAll(".allCheckBox input[type=radio]");
    checks.forEach(check => {
      check.checked = false;
    });
  }

  const getGeneralExamination = async () => {
    const url = `adminmgt/encounter/data-general-examination/${props.encounter_pid}`;
    const result = await getFromServer(url);
    if (result.status) {
      console.log(result.data);
      setGeneralQuestionData(result.data);
    } else {
      console.log("something Went Wrong");
    }
  };

  const getAbdomenExamination = async () => {
    const url = `adminmgt/encounter/data-abdomen-examination/${props.encounter_pid}`;
    const result = await getFromServer(url);
    if (result.status) {
      console.log(result.data);
      setabdomenQuestionData(result.data);
    } else {
      console.log("something Went Wrong");
    }
  };
  const getChestExamination = async () => {
    const url = `adminmgt/encounter/data-chest-examination/${props.encounter_pid}`;
    const result = await getFromServer(url);
    if (result.status) {
      console.log(result.data);
      setChestQuestionData(result.data);
    } else {
      console.log("something Went Wrong");
    }
  };
  const getCVSExamination = async () => {
    const url = `adminmgt/encounter/data-CVS-examination/${props.encounter_pid}`;
    const result = await getFromServer(url);
    if (result.status) {
      console.log(result.data);
      setCVSQuestionData(result.data);
    } else {
      console.log("something Went Wrong");
    }
  };

  const getDentalExamination = async () => {
    const url = `adminmgt/encounter/data-dental-examination/${props.encounter_pid}`;
    const result = await getFromServer(url);
    if (result.status) {
      console.log(result.data);
      setDentalQuestionData(result.data);
    } else {
      console.log("something Went Wrong");
    }
  };

  const getCNSExamination = async () => {
    const url = `adminmgt/encounter/data-CNS-examination/${props.encounter_pid}`;
    const result = await getFromServer(url);
    if (result.status) {
      console.log(result.data);
      setCNSQuestionData(result.data);
    } else {
      console.log("something Went Wrong");
    }
  };

  const GeneralSaveHandler = async() => {
    let title = [];
    let question = [];
    for (var i in medical) {
      let t = document.getElementById(`title${i}`).innerText;
      for (var j in medical[i].medicalQuestions) {
        let q = document.getElementById(`question${j}${i}`).innerText;
        try {
          let value = document.querySelector(
            `input[name="flexRadioDefault${j}${i}"]:checked`
          ).value;
          question.push({ q: q, status: value });
        } catch {}
      }
      title.push({ t: t, tq: question });
      question = [];
    }
    for (var i in title) {
      if (title[i].tq.length <= 0) {
        title.splice(i, 1);
      }
    }

    const data = {
      encounter: props.encounter_pid,
      list: title,
    };
    const url = `main/doctor/general-examination`;
      const result = await postToServer(url, { data });
      if (result.status){
        setSuccess(true);
        getGeneralExamination();
        clear();
      }

  };
  const GeneralSaveHandlerAbdomen = async() => {
    let title = [];
    let question = [];
    for (var i in medical1) {
      let t = document.getElementById(`title-Abdomen${i}`).innerText;
      for (var j in medical1[i].medicalQuestions) {
        let q = document.getElementById(`question-Abdomen${j}${i}`).innerText;
        try {
          let value = document.querySelector(
            `input[name="flexRadioDefault-Abdomen${j}${i}"]:checked`
          ).value;
          question.push({ q: q, status: value });
        } catch {}
      }
      title.push({ t: t, tq: question });
      question = [];
    }
    for (var i in title) {
      if (title[i].tq.length <= 0) {
        title.splice(i, 1);
      }
    }

    const data = {
      encounter: props.encounter_pid,
      list: title,
    };
    const url = `main/doctor/Abdomen-examination`;
      const result = await postToServer(url, { data });
      if (result.status){
        setSuccess(true);
        getAbdomenExamination();
        clear();
      }

  };

  const ChestSaveHandler = async() => {
    let title = [];
    let question = [];
    for (var i in medical2) {
      let t = document.getElementById(`title-chest${i}`).innerText;
      for (var j in medical2[i].medicalQuestions) {
        let q = document.getElementById(`question-chest${j}${i}`).innerText;
        try {
          let value = document.querySelector(
            `input[name="flexRadioDefault-Chest${j}${i}"]:checked`
          ).value;
          question.push({ q: q, status: value });
        } catch {}
      }
      title.push({ t: t, tq: question });
      question = [];
    }
    for (var i in title) {
      if (title[i].tq.length <= 0) {
        title.splice(i, 1);
      }
    }

    const data = {
      encounter: props.encounter_pid,
      list: title,
    };
    console.log(data)
    const url = `main/doctor/chest-examination`;
      const result = await postToServer(url, { data });
      if (result.status){
        setSuccess(true);
        getChestExamination();
        clear();
      }

  };

  const CVSSaveHandler = async() => {
    let title = [];
    let question = [];
    for (var i in medical3) {
      let t = document.getElementById(`title-CVS${i}`).innerText;
      for (var j in medical3[i].medicalQuestions) {
        let q = document.getElementById(`question-CVS${j}${i}`).innerText;
        try {
          let value = document.querySelector(
            `input[name="flexRadioDefault-CVS${j}${i}"]:checked`
          ).value;
          question.push({ q: q, status: value });
        } catch {}
      }
      title.push({ t: t, tq: question });
      question = [];
    }
    for (var i in title) {
      if (title[i].tq.length <= 0) {
        title.splice(i, 1);
      }
    }

    const data = {
      encounter: props.encounter_pid,
      list: title,
    };
    console.log(data)
    const url = `main/doctor/CVS-examination`;
      const result = await postToServer(url, { data });
      if (result.status){
        setSuccess(true);
        getCVSExamination();
        clear();
      }

  };

  const DentalSaveHandler = async() => {
    let title = [];
    let question = [];
    for (var i in medical4) {
      let t = document.getElementById(`title-dental${i}`).innerText;
      for (var j in medical4[i].medicalQuestions) {
        let q = document.getElementById(`question-dental${j}${i}`).innerText;
        try {
          let value = document.querySelector(
            `input[name="flexRadioDefault-dental${j}${i}"]:checked`
          ).value;
          question.push({ q: q, status: value });
        } catch {}
      }
      title.push({ t: t, tq: question });
      question = [];
    }
    for (var i in title) {
      if (title[i].tq.length <= 0) {
        title.splice(i, 1);
      }
    }

    const data = {
      encounter: props.encounter_pid,
      list: title,
    };
    console.log(data)
    const url = `main/doctor/dental-examination`;
      const result = await postToServer(url, { data });
      if (result.status){
        setSuccess(true);
        getDentalExamination();
        clear();
      }

  };

  const CNSSaveHandler = async() => {
    let title = [];
    let question = [];
    for (var i in medical5) {
      let t = document.getElementById(`title-CNS${i}`).innerText;
      for (var j in medical5[i].medicalQuestions) {
        let q = document.getElementById(`question-CNS${j}${i}`).innerText;
        try {
          let value = document.querySelector(
            `input[name="flexRadioDefault-CNS${j}${i}"]:checked`
          ).value;
          question.push({ q: q, status: value });
        } catch {}
      }
      title.push({ t: t, tq: question });
      question = [];
    }
    for (var i in title) {
      if (title[i].tq.length <= 0) {
        title.splice(i, 1);
      }
    }

    const data = {
      encounter: props.encounter_pid,
      list: title,
    };
    console.log(data)
    const url = `main/doctor/CNS-examination`;
      const result = await postToServer(url, { data });
      if (result.status){
        setSuccess(true);
        getCNSExamination();
        clear();
      }

  };

  return (
    <>
      <div className=" ">
        <div style={{ justifyContent: "center" }} className="card-header">
          <ul
            className="nav nav-tabs card-header-tabs pull-right"
            id="myTab"
            role="tablist"
            style={{ backgroundColor: "lightgrey" }}
          >
            <li className="nav-item">
              <a
                style={{ color: "black" }}
                className="nav-link active"
                id="general-tab"
                data-toggle="tab"
                href="#general"
                role="tab"
                aria-selected="true"
              >
                General Examination
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ color: "black" }}
                className="nav-link"
                id="abdomen-tab"
                data-toggle="tab"
                href="#abdomen"
                role="tab"
                aria-selected="true"
              >
                Abdomen
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ color: "black" }}
                className="nav-link"
                id="chest-tab"
                data-toggle="tab"
                href="#chest"
                role="tab"
                aria-selected="true"
              >
                Chest
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ color: "black" }}
                className="nav-link"
                id="cvs-tab"
                data-toggle="tab"
                href="#cvs"
                role="tab"
                aria-selected="true"
              >
                CVS
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ color: "black" }}
                className="nav-link"
                id="cns-tab"
                data-toggle="tab"
                href="#cns"
                role="tab"
                aria-selected="true"
              >
                CNS
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ color: "black" }}
                className="nav-link"
                id="dental-tab"
                data-toggle="tab"
                href="#dental"
                role="tab"
                aria-selected="true"
              >
                Dental
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ color: "black" }}
                className="nav-link"
                id="antenatal-tab"
                data-toggle="tab"
                href="#antenatal"
                role="tab"
                aria-selected="true"
              >
                Antenatal
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                style={{ color: "black" }}
                className="nav-link"
                id="others-tab"
                data-toggle="tab"
                href="#others"
                role="tab"
                aria-selected="true"
              >
                Others
              </a>
            </li> */}
          </ul>
        </div>
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="general"
            role="tabpanel"
          >
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {success && (
                <Success close={() => setSuccess(false)}>
                  Medical History Saved Successfully
                </Success>
              )}
              {medical &&
                medical.map((m, i) => {
                  return (
                    <div className="accordion-item" key={m.id}>
                      <h2
                        className="accordion-header"
                        id={"flush-headingOne" + i}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target={"#flush-collapseOne" + i}
                          aria-expanded="false"
                          aria-controls={"flush-collapseOne" + i}
                          id={"title" + i}
                        >
                          {m.title}
                        </button>
                      </h2>
                      <div
                        id={"flush-collapseOne" + i}
                        className="accordion-collapse collapse"
                        aria-labelledby={"flush-headingOne" + i}
                        data-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <table className="table table-bordered table-md">
                            <thead></thead>
                            <tbody className="allCheckBox">
                              {m.medicalQuestions.map((q, j) => {
                                return (
                                  <tr key={j}>
                                    <td width="40%" id={"question" + j + i}>
                                      {q.question}
                                    </td>

                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault" + j + i}
                                          id={"flexRadioDefault1" + j + i}
                                          value="Yes"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault1" + j + i}
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault" + j + i}
                                          id={"flexRadioDefault2" + j + i}
                                          value="No"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault2" + j + i}
                                        >
                                          No
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault" + j + i}
                                          id={"flexRadioDefault3" + j + i}
                                          value="None"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault3" + j + i}
                                        >
                                          None
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="text-center">
            <button className="btn btn-primary" onClick={GeneralSaveHandler}>
              save
            </button>
      </div>
      <table className="table responsive">
        <thead>
          <tr style={{ fontSize: "13px" }}>
            <th scope="col">S/N</th>
            <th scope="col">Date</th>
            <th scope="col">Doctor</th>
            <th scope="col">Group/Title</th>
            <th scope="col">Quation</th>
            <th scope="col">State</th>
            {/* <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {generalQuestionData.map((qus, i) => (
            <tr>
              <th scope="row">{i + 1}</th>
              <td>{qus["date"]}</td>
              <td>{qus["review_by_first_name"]}  {qus["review_by_last_name"]}</td>
              <td>{qus["title"]}</td>
              <td>{qus["question"]}</td>
              <td>{qus["status"]}</td>
              {/* <td>
              <button className="btn btn-danger btn-sm">Delete</button>
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
          </div>
          <div className="tab-pane fade" id="abdomen" role="tabpanel">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {success && (
                <Success close={() => setSuccess(false)}>
                  Abdomen Examination Saved Successfully
                </Success>
              )}
              {medical1 &&
                medical1.map((m, i) => {
                  return (
                    <div className="accordion-item" key={m.id}>
                      <h2
                        className="accordion-header"
                        id={"flush-headingOne" + i}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target={"#flush-collapseOne-Abdomen" + i}
                          aria-expanded="false"
                          aria-controls={"flush-collapseOne" + i}
                          id={"title-Abdomen" + i}
                        >
                          {m.title}
                        </button>
                      </h2>
                      <div
                        id={"flush-collapseOne-Abdomen" + i}
                        className="accordion-collapse collapse"
                        aria-labelledby={"flush-headingOne" + i}
                        data-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <table className="table table-bordered table-md">
                            <thead></thead>
                            <tbody className="allCheckBox">
                              {m.medicalQuestions.map((q, j) => {
                                return (
                                  <tr key={j}>
                                    <td width="40%" id={"question-Abdomen" + j + i}>
                                      {q.question}
                                    </td>

                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-Abdomen" + j + i}
                                          id={"flexRadioDefault1-Abdomen" + j + i}
                                          value="Yes"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault1-Abdomen" + j + i}
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-Abdomen" + j + i}
                                          id={"flexRadioDefault2-Abdomen" + j + i}
                                          value="No"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault2-Abdomen" + j + i}
                                        >
                                          No
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-Abdomen" + j + i}
                                          id={"flexRadioDefault3-Abdomen" + j + i}
                                          value="None"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault3-Abdomen" + j + i}
                                        >
                                          None
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
                
            </div>
            <div className="text-center">
            <button className="btn btn-primary" onClick={GeneralSaveHandlerAbdomen}>
              save
            </button>
      </div>
      <table className="table responsive">
        <thead>
          <tr style={{ fontSize: "13px" }}>
            <th scope="col">S/N</th>
            <th scope="col">Date</th>
            <th scope="col">Doctor</th>
            <th scope="col">Group/Title</th>
            <th scope="col">Quation</th>
            <th scope="col">State</th>
            {/* <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {abdomenQuestionData.map((qus, i) => (
            <tr>
              <th scope="row">{i + 1}</th>
              <td>{qus["date"]}</td>
              <td>{qus["review_by_first_name"]}  {qus["review_by_last_name"]}</td>
              <td>{qus["title"]}</td>
              <td>{qus["question"]}</td>
              <td>{qus["status"]}</td>
              {/* <td>
              <button className="btn btn-danger btn-sm">Delete</button>
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
            
            
          </div>
          <div className="tab-pane fade" id="chest" role="tabpanel">
          <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {success && (
                <Success close={() => setSuccess(false)}>
                  Abdomen Examination Saved Successfully
                </Success>
              )}
              {medical2 &&
                medical2.map((m, i) => {
                  return (
                    <div className="accordion-item" key={m.id}>
                      <h2
                        className="accordion-header"
                        id={"flush-headingOne" + i}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target={"#flush-collapseOne-Chest" + i}
                          aria-expanded="false"
                          aria-controls={"flush-collapseOne" + i}
                          id={"title-chest" + i}
                        >
                          {m.title}
                        </button>
                      </h2>
                      <div
                        id={"flush-collapseOne-Chest" + i}
                        className="accordion-collapse collapse"
                        aria-labelledby={"flush-headingOne" + i}
                        data-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <table className="table table-bordered table-md">
                            <thead></thead>
                            <tbody className="allCheckBox">
                              {m.medicalQuestions.map((q, j) => {
                                return (
                                  <tr key={j}>
                                    <td width="40%" id={"question-chest" + j + i}>
                                      {q.question}
                                    </td>

                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-Chest" + j + i}
                                          id={"flexRadioDefault1-Chest" + j + i}
                                          value="Yes"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault1-Chest" + j + i}
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-Chest" + j + i}
                                          id={"flexRadioDefault2-Chest" + j + i}
                                          value="No"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault2-Chest" + j + i}
                                        >
                                          No
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-Chest" + j + i}
                                          id={"flexRadioDefault3-Chest" + j + i}
                                          value="None"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault3-Chest" + j + i}
                                        >
                                          None
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
                
            </div>
            <div className="text-center">
            <button className="btn btn-primary" onClick={ChestSaveHandler}>
              save this
            </button>
      </div>
      <table className="table responsive">
        <thead>
          <tr style={{ fontSize: "13px" }}>
            <th scope="col">S/N</th>
            <th scope="col">Date</th>
            <th scope="col">Doctor</th>
            <th scope="col">Group/Title</th>
            <th scope="col">Quation</th>
            <th scope="col">State</th>
            {/* <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {chestQuestionData.map((qus, i) => (
            <tr>
              <th scope="row">{i + 1}</th>
              <td>{qus["date"]}</td>
              <td>{qus["review_by_first_name"]}  {qus["review_by_last_name"]}</td>
              <td>{qus["title"]}</td>
              <td>{qus["question"]}</td>
              <td>{qus["status"]}</td>
              {/* <td>
              <button className="btn btn-danger btn-sm">Delete</button>
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
            
            
          </div>
          <div className="tab-pane fade" id="cvs" role="tabpanel">
          <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {success && (
                <Success close={() => setSuccess(false)}>
                  CVS Examination Saved Successfully
                </Success>
              )}
              {medical3 &&
                medical3.map((m, i) => {
                  return (
                    <div className="accordion-item" key={m.id}>
                      <h2
                        className="accordion-header"
                        id={"flush-headingOne" + i}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target={"#flush-collapseOne-CVS" + i}
                          aria-expanded="false"
                          aria-controls={"flush-collapseOne" + i}
                          id={"title-CVS" + i}
                        >
                          {m.title}
                        </button>
                      </h2>
                      <div
                        id={"flush-collapseOne-CVS" + i}
                        className="accordion-collapse collapse"
                        aria-labelledby={"flush-headingOne" + i}
                        data-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <table className="table table-bordered table-md">
                            <thead></thead>
                            <tbody className="allCheckBox">
                              {m.medicalQuestions.map((q, j) => {
                                return (
                                  <tr key={j}>
                                    <td width="40%" id={"question-CVS" + j + i}>
                                      {q.question}
                                    </td>

                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-CVS" + j + i}
                                          id={"flexRadioDefault1-CVS" + j + i}
                                          value="Yes"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault1-CVS" + j + i}
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-CVS" + j + i}
                                          id={"flexRadioDefault2-CVS" + j + i}
                                          value="No"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault2-CVS" + j + i}
                                        >
                                          No
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-CVS" + j + i}
                                          id={"flexRadioDefault3-CVS" + j + i}
                                          value="None"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault3-CVS" + j + i}
                                        >
                                          None
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
                
            </div>
            <div className="text-center">
            <button className="btn btn-primary" onClick={CVSSaveHandler}>
              Save CVS
            </button>
      </div>
      <table className="table responsive">
        <thead>
          <tr style={{ fontSize: "13px" }}>
            <th scope="col">S/N</th>
            <th scope="col">Date</th>
            <th scope="col">Doctor</th>
            <th scope="col">Group/Title</th>
            <th scope="col">Quation</th>
            <th scope="col">State</th>
            {/* <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {cvsQuestionData.map((qus, i) => (
            <tr>
              <th scope="row">{i + 1}</th>
              <td>{qus["date"]}</td>
              <td>{qus["review_by_first_name"]}  {qus["review_by_last_name"]}</td>
              <td>{qus["title"]}</td>
              <td>{qus["question"]}</td>
              <td>{qus["status"]}</td>
              {/* <td>
              <button className="btn btn-danger btn-sm">Delete</button>
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
            
          </div>
          <div className="tab-pane fade" id="cns" role="tabpanel">
          <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {success && (
                <Success close={() => setSuccess(false)}>
                  CNS Examination Saved Successfully
                </Success>
              )}
              {medical5 &&
                medical5.map((m, i) => {
                  return (
                    <div className="accordion-item" key={m.id}>
                      <h2
                        className="accordion-header"
                        id={"flush-headingOne" + i}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target={"#flush-collapseOne-CNS" + i}
                          aria-expanded="false"
                          aria-controls={"flush-collapseOne" + i}
                          id={"title-CNS" + i}
                        >
                          {m.title}
                        </button>
                      </h2>
                      <div
                        id={"flush-collapseOne-CNS" + i}
                        className="accordion-collapse collapse"
                        aria-labelledby={"flush-headingOne" + i}
                        data-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <table className="table table-bordered table-md">
                            <thead></thead>
                            <tbody className="allCheckBox">
                              {m.medicalQuestions.map((q, j) => {
                                return (
                                  <tr key={j}>
                                    <td width="40%" id={"question-CNS" + j + i}>
                                      {q.question}
                                    </td>

                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-CNS" + j + i}
                                          id={"flexRadioDefault1-CNS" + j + i}
                                          value="Yes"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault1-CNS" + j + i}
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-CNS" + j + i}
                                          id={"flexRadioDefault2-CNS" + j + i}
                                          value="No"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault2-CNS" + j + i}
                                        >
                                          No
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-CNS" + j + i}
                                          id={"flexRadioDefault3-CNS" + j + i}
                                          value="None"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault3-CNS" + j + i}
                                        >
                                          None
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
                
            </div>
            <div className="text-center">
            <button className="btn btn-primary" onClick={CNSSaveHandler}>
              Save CNS
            </button>
      </div>
      <table className="table responsive">
        <thead>
          <tr style={{ fontSize: "13px" }}>
            <th scope="col">S/N</th>
            <th scope="col">Date</th>
            <th scope="col">Doctor</th>
            <th scope="col">Group/Title</th>
            <th scope="col">Quation</th>
            <th scope="col">State</th>
            {/* <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {cnsQuestionData.map((qus, i) => (
            <tr>
              <th scope="row">{i + 1}</th>
              <td>{qus["date"]}</td>
              <td>{qus["review_by_first_name"]}  {qus["review_by_last_name"]}</td>
              <td>{qus["title"]}</td>
              <td>{qus["question"]}</td>
              <td>{qus["status"]}</td>
              {/* <td>
              <button className="btn btn-danger btn-sm">Delete</button>
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
            
          </div>
          <div className="tab-pane fade" id="dental" role="tabpanel">
          <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {success && (
                <Success close={() => setSuccess(false)}>
                  CVS Examination Saved Successfully
                </Success>
              )}
              {medical4 &&
                medical4.map((m, i) => {
                  return (
                    <div className="accordion-item" key={m.id}>
                      <h2
                        className="accordion-header"
                        id={"flush-headingOne" + i}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target={"#flush-collapseOne-dental" + i}
                          aria-expanded="false"
                          aria-controls={"flush-collapseOne" + i}
                          id={"title-dental" + i}
                        >
                          {m.title}
                        </button>
                      </h2>
                      <div
                        id={"flush-collapseOne-dental" + i}
                        className="accordion-collapse collapse"
                        aria-labelledby={"flush-headingOne" + i}
                        data-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <table className="table table-bordered table-md">
                            <thead></thead>
                            <tbody className="allCheckBox">
                              {m.medicalQuestions.map((q, j) => {
                                return (
                                  <tr key={j}>
                                    <td width="40%" id={"question-dental" + j + i}>
                                      {q.question}
                                    </td>

                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-dental" + j + i}
                                          id={"flexRadioDefault1-dental" + j + i}
                                          value="Yes"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault1-dental" + j + i}
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-dental" + j + i}
                                          id={"flexRadioDefault2-dental" + j + i}
                                          value="No"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault2-dental" + j + i}
                                        >
                                          No
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"flexRadioDefault-dental" + j + i}
                                          id={"flexRadioDefault3-dental" + j + i}
                                          value="None"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"flexRadioDefault3-dental" + j + i}
                                        >
                                          None
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
                
            </div>
            <div className="text-center">
            <button className="btn btn-primary" onClick={DentalSaveHandler}>
              Save dental
            </button>
      </div>
      <table className="table responsive">
        <thead>
          <tr style={{ fontSize: "13px" }}>
            <th scope="col">S/N</th>
            <th scope="col">Date</th>
            <th scope="col">Doctor</th>
            <th scope="col">Group/Title</th>
            <th scope="col">Quation</th>
            <th scope="col">State</th>
            {/* <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {dentalQuestionData.map((qus, i) => (
            <tr>
              <th scope="row">{i + 1}</th>
              <td>{qus["date"]}</td>
              <td>{qus["review_by_first_name"]}  {qus["review_by_last_name"]}</td>
              <td>{qus["title"]}</td>
              <td>{qus["question"]}</td>
              <td>{qus["status"]}</td>
              {/* <td>
              <button className="btn btn-danger btn-sm">Delete</button>
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
            
          </div>
          <div className="tab-pane fade" id="antenatal" role="tabpanel">
            <div className="text-center mt-5 mb-3">
              <button
                className="btn btn-primary"
                onClick={pregnancyPageHandler}
              >
                Move to Antenatal Page
              </button>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Examination;
