import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const ViewTextBoxValue = (props) => {
  console.log(props.data);
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>View Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div dangerouslySetInnerHTML={{ __html: props.data }} />
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={props.close}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewTextBoxValue;
