import React, { useState, useEffect } from "react";
// import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { getFromServer } from "../../global/requests";

const ViewPermissions = (props) => {
  const [permission, setPermission] = useState([]);

  useEffect(() => {
    doInitialTasks();
  },[]);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/useraccess/${props.id}`);
    if(result.status) {
      console.log(result.data);
      setPermission(result.data);
    }
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Allowed Permissions 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {permission &&
            permission.map((p) => {
              return (
                <div className="row">
                  <div className="col-12">{p.title}</div>
                  {/* <div className="col-md-6"></div> */}
                </div>
              );
            })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewPermissions;
