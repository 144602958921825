import React from 'react';
import MainContent from "./MainContent";

const AccessDenied = () => {
  return (
    <>
      <MainContent>
        <div className="section-body">
            <h2 className="text-center">403 Access Denied</h2>
        </div>
      </MainContent>
    </>
  )
}

export default AccessDenied;