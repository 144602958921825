import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import Loading from "../../ui/Loading";
import PregnancyAntenatalDetailForm from "./PregnancyAntenatalDetailForm";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { getFromServer } from "../../global/requests";


const PregnancyAntenatalDetail = () => {
  const navigate = useNavigate();
  const para = useParams();

  const encounter_pid = para.encounter_pid;
  const pregnancy_id = para.pregnancy_id;

  const [list, setList] = useState();
  const [vaccineList, setVaccineList] = useState();
  const [load, setLoad] = useState(true);
  const [addDelivery, setAddDelivery] = useState(false);
  const [p, setEncounter] = useState();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/pregnancy-antenatal-detail/${encounter_pid}/${pregnancy_id}`);
    if(result.status) {
      if (result.data != "") {
        setList(result.data.list);
        console.log(result.data.list)
        setEncounter(result.data.encounter);
        setLoad(false);
      }
    }
  }

  const updateHandler = (list) => {
    setList(list);
  };

  return (
    <>
      <MainContent>
        {!addDelivery && (
          <div className="section-row">
            {p && (
             <PatientDetailCard list={p} isEncounter={true} />
            )}
            <div>
              <h2 className="mt-5 mb-1 ml-2 mb-5">Antenatal Details</h2>

              <button
                className="btn btn-primary mb-4"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
              <button
                className="btn btn-primary mb-4 pull-right"
                onClick={() => setAddDelivery(!addDelivery)}
              >
                Add new record
              </button>
            </div>
            <div className="card-body">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {load && <Loading />}
                  <table className="table responsive">
                    <thead>
                      <tr style={{ fontSize: "13px" }}>
                        <th scope="col">S/N</th>
                        <th scope="col">Height</th>
                        <th scope="col">Weight</th>
                        <th scope="col">Pregnancy No.</th>
                        <th scope="col">Visit Date</th>
                        <th scope="col">Present</th>
                        <th scope="col">Relation</th>
                        <th scope="col">Foetal</th>
                        <th scope="col">BP</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Next Visit</th>
                        {/* <th scope="col" className="text-center">
                          Actions
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {list &&
                        list.map((t, index) => {
                          return (
                            <tr key={index}>
                              <th scope="col">{index + 1}</th>
                              <td scope="col">{t.height}</td>
                              <td scope="col">{t.weight}</td>
                              <td scope="col">{t.pregnancynumber}</td>
                              <td scope="col">{t.date}</td>
                              <td scope="col">{t.present}</td>
                              <td scope="col">{t.relation}</td>
                              <td scope="col">{t.foetal}</td>
                              <td scope="col">{t.bp}</td>
                              <td scope="col">{t.remarks}</td>
                              <td scope="col">{t.nextvisit}</td>
                              {/* <td scope="col"></td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {addDelivery && (
          <PregnancyAntenatalDetailForm
            back={() => setAddDelivery(false)}
            update={updateHandler}
            encounter_pid={encounter_pid}
            pregnancy_id={pregnancy_id}
          />
        )}
      </MainContent>
    </>
  );
};

export default PregnancyAntenatalDetail;
