import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import Success from "../../ui/success";
import ReturnItem from "../../modals/Dispensary/ReturnItem";
import ViewAdminister from "../../modals/Nursing/ViewAdminister";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { postToServer } from "../../global/requests";


const InjectionDetails = (props) => {
  const param = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showAdminister, setShowAdminister] = useState(false);
  const [temp, setTemp] = useState();

  const [p, setData] = useState();
  const [success, setSuccess] = useState(false);

  const updatedState = (list) => {
    // setData({...p, });
    console.log(list);
  };

  const handleClose = () => {
    setShow(false);
    setTemp("");
  };
  const handleShow = (data) => {
    setTemp(data);
    setShow(true);
  };

  const handleCloseAdminister = () => {
    setShowAdminister(false);
    setTemp("");
  };
  const handleShowAdminister = (pres_detail_id) => {
    setTemp(`main/doctor/injection-administer-detail/${pres_detail_id}`);
    setShowAdminister(true);
  };


  const closeHandler = () => setSuccess(false);

  const dispenseHandler = async () => {
    const data = {
      dic:p
    };
    const result = await postToServer(`main/doctor/injection-administer`, { data });
    if(result.status) {
      // console.log(result);
      navigate("/injection");
    }
  };

  useEffect(() => {
    const l = { ...param.state.list };
    let list = [];
    for (var i in l.prescriptionDetails_set) {
      const temp = { ...l.prescriptionDetails_set[i], isCheck: true };
      list.push(temp);
    }
    setData({ ...param.state.list, prescriptionDetails_set: list });
    console.log({ ...param.state.list, prescriptionDetails_set: list });
  }, []);

  const changeDispensedHandler = (e, i) => {
    const inputDrug = { ...p };
    inputDrug.prescriptionDetails_set[i].dispensedquantity = e.target.value;
    setData(inputDrug);
  };

  const changeTotalHandler = (e, i) => {
    const inputDrug = { ...p };
    inputDrug.prescriptionDetails_set[i].total = e.target.value;
    setData(inputDrug);
  };

  const checkBoxHandler = (i) => {
    const newVal = { ...p };
    newVal.prescriptionDetails_set[i].isCheck =
      !newVal.prescriptionDetails_set[i].isCheck;
    setData(newVal);
  };

  const qtyHandler = (e, i) => {
    const newVal = { ...p };
    let value = e.target.value;
    if (value == null || value==undefined || value == '') value = 0
    newVal.prescriptionDetails_set[i].administerQuantity = value;
    setData(newVal);
  };
  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            {success && <Success close={closeHandler}>Successful</Success>}
            {p && (
             <PatientDetailCard list={p.encounter} isEncounter={true} />
            )}

            <div className="tablexflow">
              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    <th></th>
                    <th scope="col">
                      Date{"("}
                      {p && p.encounter.pid}
                      {")"}
                    </th>
                    <th scope="col">Injection</th>
                    <th scope="col">Dosage</th>
                    <th scope="col">Frequency</th>
                    <th scope="col">Period</th>
                    <th scope="col">Total</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Prescribed by</th>
                    <th scope="col">Administered Record</th>
                    {/* <th scope="col">Total Administered</th> */}
                    {/* <th scope="col">Dipensed Record</th> */}
                    {/* <th scope="col">Total Dipensed</th> */}
                    <th scope="col">Administered Quantity</th>
                    {/* <th scope="col">Cost</th> */}
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {p &&
                    p.prescriptionDetails_set.map((d, i) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={d.isCheck}
                                onChange={() => checkBoxHandler(i)}
                              />
                            </td>
                            <td>{p.date.split(" ")[0]}</td>
                            <td>
                              {d.prescription_details_tag_set.map((t) => {
                                return <>
                                {t.drugtags.brandname} -
                                <span className="float-end">
                              {t.drugtags.is_paid == true ? (
                                <button className="btn btn-success btn-sm">
                                   Confirmed
                                </button>
                              ) : (
                                <button className="btn btn-danger btn-sm">
                                   Not confirmed
                                </button>
                              )}</span>
                                </>;
                              })}
                              
                            </td>
                            <td>{d.dosage}</td>
                            <td>{d.frequency}</td>
                            <td>
                              {d.period}
                              {" / " + d.none}
                            </td>
                            <td>
                              {/* <input
                              type="text"
                              name="dispensedquantity"
                              className="form-control w-50"
                              value={a.total}
                              onChange={(e) => changeTotalHandler(e, index)}
                            /> */}
                              {d.total}
                            </td>
                            <td>{d.comment}</td>
                            <td></td>
                            <td>
                              <button type="button" class="btn btn-primary btn-sm" onClick={()=>handleShowAdminister(d.id)}>View Record</button>
                            </td>

                            {d.isCheck == false ? (
                              <td>{d.administerQuantity}</td>
                            ) : (
                              <td>
                                <input
                                  type="text"
                                  name="qty"
                                  className="form-control w-50"
                                  value={d.administerQuantity}
                                  onChange={(e) => qtyHandler(e, i)}
                                />
                              </td>
                            )}

                            {/* <td>
                            {d.dispensedrecord && (
                              <>
                                {d.dispensedrecord} <br />
                                <strong>Qty:</strong>
                                {d.updateddispensedquantity}{" "}
                                <span
                                  onClick={() => handleShow(p)}
                                  style={{ color: "blue", cursor: "pointer" }}
                                >
                                  Return
                                </span>
                              </>
                            )}
                          </td> */}
                            {/* <td>
                            <input
                              type="text"
                              name="dispensedquantity"
                              className="form-control w-50"
                              value={a.dispensedquantity}
                              onChange={(e) => changeDispensedHandler(e, index)}
                            />
                          </td> */}
                            <td>
                              <button className="btn btn-sm btn-primary">
                                Patient Stock
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="text-center mt-3">
              <button className="btn btn-primary" onClick={dispenseHandler}>
                Administer
              </button>
            </div>
          </div>
        </div>
      </MainContent>
      {showAdminister && (
        <ViewAdminister
          show={showAdminister}
          close={handleCloseAdminister}
          url={temp}
        />
      )}

      {show && (
        <ReturnItem
          show={show}
          close={handleClose}
          detail={temp}
          pres_id={p.id}
          newPrescription={updatedState}
        />
      )}
    </>
  );
};

export default InjectionDetails;
