import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
// import axios from "axios";
// import Cookies from "js-cookie";
import Loading from "../../ui/Loading";
import AddVaccine from "../../modals/UserMgt/AddVaccine";
import { getFromServer, deleteFromServer } from "../../global/requests";

const VaccineMgt = () => {
  //   const [isemptyArray, setIsEmptyArray] = useState(false);
  const [vaccine, setVaccine] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateHandlerHandler = (list) => setVaccine(list);
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/vaccine-mgt`);
    if(result.status) {
      setVaccine(result.data);
      setLoad(false);
    }
  }

  const deleteHandler = async (id) => {
    if (window.confirm("Do you want to delete this Vaccine?")) {
      const result = await deleteFromServer(`usermgt/vaccine-mgt/${id}`);
      if(result.status) setVaccine(result.data);
      setSuccess(false);
    }
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={() => setSuccess(false)}>
            Vaccine has been added Successfully
          </Success>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add New Vaccine
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
          {load && <Loading />}
          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">#</th>
                  <th scope="col">Vaccine Name</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {vaccine &&
                  vaccine.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.name}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddVaccine
          show={show}
          close={handleClose}
          success={successHandler}
          update={updateHandlerHandler}
        />
      )}
    </>
  );
};

export default VaccineMgt;
