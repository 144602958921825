const genReportPagination = (count, noOfRow, pageNo, setPageNo) => {
  const floatDiv = count / noOfRow;
  const intDiv = parseInt(floatDiv);
  const noOfPages = floatDiv - intDiv > 0 ? intDiv + 1 : intDiv;
  const tempPages = [];
  tempPages.push(
    <li className={pageNo == 0 ? "page-item disabled" : "page-item"}>
      <button className="page-link" tabindex="-1" onClick={() => setPageNo(pageNo - 1)}>
        <i className="fas fa-chevron-left"></i>
      </button>
    </li>
  );
  if (noOfPages < 11) {
    for (let i = 0; i < noOfPages; i++) {
      tempPages.push(
        <li className={pageNo == i ? "page-item active" : "page-item"}>
          <button onClick={() => setPageNo(i)} className="page-link">
            {i + 1}
          </button>
        </li>
      );
    }
  } else {
    // -------------------
    const dividedVal = noOfPages / 2;
    const dividedIintVal = parseInt(dividedVal);
    let midFrom = dividedIintVal; let midTo = dividedIintVal;
    if(dividedVal - dividedIintVal == 0) {
      midFrom = dividedIintVal - 1; midTo = dividedIintVal;
    }
    if((pageNo >= 3 && pageNo <= midFrom) || (pageNo >= midTo && pageNo <= (noOfPages - 4))) {
      midFrom = pageNo - 2; midTo = pageNo + 2;
    }
    if(midFrom <= 3) { midFrom = 4; midTo += 2; }
    if(midTo >= noOfPages - 4) { midTo = noOfPages - 5; midFrom -= 2 }
    // -------------------
    for (let i = 0; i < 4; i++) {
      tempPages.push(
        <li className={pageNo == i ? "page-item active" : "page-item"}>
          <button onClick={() => setPageNo(i)} className="page-link">
            {i + 1}
          </button>
        </li>
      );
    }
    tempPages.push(<li style={{fontSize: '25px'}}>&nbsp;---&nbsp;</li>)
    // -------------------
    for (let i = midFrom; i <= midTo; i++) {
      tempPages.push(
        <li className={pageNo == i ? "page-item active" : "page-item"}>
          <button onClick={() => setPageNo(i)} className="page-link">
            {i + 1}
          </button>
        </li>
      );
    }
    // -------------------
    tempPages.push(<li style={{fontSize: '25px'}}>&nbsp;---&nbsp;</li>)
    for (let i = noOfPages - 4; i < noOfPages; i++) {
      tempPages.push(
        <li className={pageNo == i ? "page-item active" : "page-item"}>
          <button onClick={() => setPageNo(i)} className="page-link">
            {i + 1}
          </button>
        </li>
      );
    }
  }
  tempPages.push(
    <li className={pageNo == noOfPages - 1 ? "page-item disabled" : "page-item"}>
      <button className="page-link" onClick={() => setPageNo(pageNo + 1)}>
        <i className="fas fa-chevron-right"></i>
      </button>
    </li>
  );
  return tempPages;
};

export { genReportPagination };
