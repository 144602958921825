import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { suggestions } from "./Suggestions";
import Cookies from "js-cookie";
import Auto from "./Auto/Auto";
import { postToServer } from "../../global/requests";

const Diagnosis = (props) => {
  const comment = useRef();
  const none = useRef();
  const period = useRef();
  const pid = props.pid;
  const [save, setSave] = useState(false);

  const [tags, setTags] = useState([]);
  const [diag, setDiag] = useState();

  const getTags = (tag) => {
    setTags(tag);
  };


  useEffect(() => {
    const url = `${window.baseURL}main/doctor/diagnosis/${props.patient_pid}`;
    axios.get(url).then((res) => {
      setDiag(res.data);
      console.log(res.data);
    });
  }, []);

  const submitHandler = async () => {
    if (tags.length > 0) {
      setSave(!save);
      const d = new Date();
      const date =
        d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();

      const data = {
        dpid: props.pid,
        ppid: props.patient_pid,
        tag: tags,
        date: date,
        comment: comment.current.value,
        period: period.current.value,
        none: none.current.value,
        addedby: "",
      };

      const result = await postToServer(`main/doctor/diagnosis`, { data });
      if(result.status) {
        console.log(result.data);
        setDiag(result.data);
        setTags([]);
        document.getElementById("none").value = "";
        document.getElementById("period").value = "";
        document.getElementById("note").value = "";
      }

    }
  };

  const deleteHandler = (detail_id, diagnosis_id) =>{
    if(window.confirm("Are you sure you want to delete this?")){
      const merge_id = props.patient_pid + '_' + detail_id + '_' + diagnosis_id
      const url = `${window.baseURL}main/doctor/diagnosis/${merge_id}`;
      axios
        .delete(
          url,
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
          }
        )
        .then((res) => {
          setDiag(res.data);
        });
    }
  }


  return (
    <div>
      <div>
        <div className="row">
          <table className="table responsive table-sm">
            <thead>
              <tr>
                <th>Diagnosis</th>
                <th>Period</th>
                <th></th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="40%">
                  <Auto options={suggestions} tag={getTags} removeTag={save} />
                  <span className="fw-bolder p-1" style={{backgroundColor:"#FFC107"}}>Type, Search or Press Enter to Add your own Diagnosis</span>
                </td>
                <td>
                  <select
                    className="form-select"
                    style={{ width: "100px" }}
                    ref={period}
                    id="period"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                  </select>
                </td>
                <td>
                  <select
                    className="form-select"
                    style={{ width: "100px" }}
                    ref={none}
                    id="none"
                  >
                    <option value="7">7</option>
                    <option value="52">52</option>
                    <option value="12">12</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    ref={comment}
                    placeholder="Any Note"
                    id="note"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <button className="btn btn-primary mt-4 mb-3" onClick={submitHandler}>
          save
        </button>

        <table className="table responsive">
          {diag &&
            diag.map((a, index) => {
              return (
                <>
                  <thead>
                    <tr style={{ fontSize: "13px" }}>
                      <th scope="col">S/N</th>
                      <th scope="col">
                        Date{"("}
                        {a.encounter.pid}
                        {")"}
                      </th>
                      <th scope="col">Diagnosis</th>
                      <th scope="col">Period</th>
                      <th scope="col">comment</th>
                      <th scope="col">Diagnosed by</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {a.diagnosisDetail.map((d, j) => {
                      return (
                        <tr key={j}>
                          <th scope="row">{j + 1}</th>
                          <td>{a.date}</td>
                          <td>{d.title}</td>
                          <td>
                            {d.period}
                            {" / " + d.none}
                          </td>
                          <td>{d.comment}</td>
                          <td>{d.addedby_first_name} {d.addedby_last_name}</td>
                          <td>
                              <button class="btn btn-danger btn-sm" onClick={()=>deleteHandler(d.id,a.id)}>Delete</button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              );
            })}
        </table>
      </div>
    </div>
  );
};

export default Diagnosis;
