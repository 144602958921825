import React, {useState, useEffect} from 'react';
import MainContent from "../../ui/MainContent";
import Error from "../../ui/error";
import Success from "../../ui/success";
import { getFromServer, postToServer } from "../../global/requests";

const SmsSettings = () => {
  const [alert, setAlert] = useState({show: false, status: false});
  const [saving, setSaving] = useState(false);
  const [messages, setMessages] = useState({});
  const [msgTypes, setMsgTypes] = useState([]);
  const [smsSenderName, setSmsSenderName] = useState('');
  const [smsAutoBirthday, setSmsAutoBirthday] = useState('0');

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`sms/patient-sms-settings`);
    if (result.status) {
      setMsgTypes(result.data.msg_types);
      setMessages(result.data.messages);
      setSmsSenderName(result.data.sms_sender_name);
      setSmsAutoBirthday(result.data.sms_auto_birthday);
    }
  };
  const saveSettings = async () => {
    closeAlert();
    setSaving(true);
    const result = await postToServer(`sms/patient-sms-settings`, {
      messages: messages, sms_sender_name: smsSenderName, sms_auto_birthday: smsAutoBirthday
    });
    if(result.status) {
      setAlert({show: true, status: true});
    } else setAlert({show: true, status: false});
    setSaving(false);
    doInitialTasks();
  }
  const changeMessage = (e) => setMessages({...messages, [e.target.name]: e.target.value});

  const changeSender = (e) => setSmsSenderName(e.target.value);
  const changeSmsAutoBirthday = (e) => setSmsAutoBirthday(e.target.value);

  const closeAlert = () => setAlert({show: false, status: false});

  return (
    <MainContent>
      <div className="section-body">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="boxs mail_listing">
                <div className="inbox-center table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th colspan="1">
                          <div className="inbox-header">
                            SMS settings
                          </div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="composeForm">
                      {alert.show && !alert.status && <Error close={closeAlert}>Something went wrong.</Error>}
                      {alert.show && alert.status && <Success close={closeAlert}>Message sent successfully.</Success>}
                      <div className="row" style={{marginBottom: "20px"}}>
                        <div  className="col-lg-6">
                          <label class="form" htmlFor="sms_sender">Sender name</label>
                          <input type="text" maxLength={11} value={smsSenderName} onChange={changeSender} className="form-control" />
                        </div>
                        <div  className="col-lg-6">
                          <label class="form" htmlFor="sms_auto_birthday">Send Automatic Birthday</label>
                          <select id="sms_auto_birthday" value={smsAutoBirthday} onChange={changeSmsAutoBirthday} className="form-control">
                            <option value="0">OFF</option>
                            <option value="1">ON</option>
                          </select>
                        </div>
                      </div>
                      {msgTypes && msgTypes.map(msgType =>
                        <div style={{marginBottom: "20px"}}>
                          <label class="form" htmlFor={msgType[0]}>{msgType[1]} Message</label>
                          <textarea
                          className="form-control"
                          placeholder={msgType[1] + ' Message'}
                          maxLength="1000"
                          id={msgType[0]}
                          name={msgType[0]}
                          value={messages[msgType[0]]}
                          onChange={changeMessage}
                          ></textarea>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12" style={{marginTop: "-20px"}}>
                    <div className="m-l-25 m-b-20">
                      <button
                        type="button"
                        className="btn btn-info btn-border-radius waves-effect"
                        disabled={saving}
                        onClick={saveSettings}
                      >
                        {saving ? 'Saving...' : 'Save'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  )
}

export default SmsSettings;