import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddOrder from "../../modals/Pharmacy/AddOrder";
// import Cookies from "js-cookie";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getFromServer, putToServer, deleteFromServer } from "../../global/requests";

const StatusHandler = (props) => {
  const status = props.status;
  if (status == "Pending")
    return <div className="badge badge-warning">{status}</div>;
  else if (status == "Confirmed")
    return <div className="badge badge-success">{status}</div>;
  else return <div className="badge badge-danger">{status}</div>;
};

const Consumables = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const closehandler = () => setSuccess(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateOrderHandler = (list) => setOrder(list);
  const successHandler = (bool) => setSuccess(bool);

  const handleSuplier = () => {
    navigate("/supplier");
  };

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`pharmacy/purchase-order`);
    if(result.status) {
      setOrder(result.data);
      console.log(result.data);
    }
  }

  const confirmHandler = async (id, status) => {
    if (status == "Pending") {
      const result = await putToServer(`pharmacy/purchase-order/${id}`);
      if(result.status) setOrder(result.data);
    }
  };

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const result = await deleteFromServer(`pharmacy/purchase-order/${id}`);
      if(result.status) setOrder(result.data);
    }
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={closehandler}>
            Order has been Placed Successfully!!!
          </Success>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Place Order
              </button>
              <button
                className="btn btn-warning mb-4 ml-3"
                onClick={handleSuplier}
              >
                Add Supplier
              </button>
            </div>
          </div>

          <div className="tablexflow">
            <table className="table table-md">
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th scope="col">S\N</th>
                  <th scope="col">Supplier</th>
                  <th scope="col">Request Date</th>
                  <th scope="col">Note</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Received Qty</th>
                  <th scope="col">Requested by</th>
                  <th scope="col">Status</th>
                  <th scope="col">Confirmed by</th>
                  <th scope="col">Store</th>
                  <th scope="col" className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {order &&
                  order.map((a, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{a.supplier.name}</td>
                        <td>{a.date}</td>
                        <td>{a.note}</td>
                        <td>{a.quantity}</td>
                        <td>{a.received_qty}</td>
                        <td>{a.requestedby_first_name} {a.requestedby_last_name}</td>
                        <td><StatusHandler status={a.status} /></td>
                        <td>{a.confirmedby_first_name} {a.confirmedby_last_name}</td>
                        <td>{a.type}</td>
                        <td className=" text-center">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => confirmHandler(a.id, a.status)}
                          >
                            Confirm
                          </button>
                          <button
                            className="btn btn-sm btn-primary ml-1"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>

      <AddOrder
        show={show}
        close={handleClose}
        success={successHandler}
        orders={updateOrderHandler}
      />
    </>
  );
};

export default Consumables;
