import React, { useState, useEffect } from "react";
import NurseCarePlanModel from "../../modals/AdmissionMgt/NurseCarePlanModel";
import axios from "axios";
import Success from "../../ui/success";
import ReactQuill from "react-quill";
import Cookies from "js-cookie";


const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
const mic = new SpeechRecognition()
mic.continuous = true;
mic.interimResults = true;
mic.lang = 'en-US';



const NursingAssessment = (props) => {
  const [listening, setIsListening] = useState(false);

  const [value, setValue] = useState("");
  const [list, setList] = useState();


  useEffect(() => {
    handleListen()
  }, [listening]);
  
  const handleListen = () =>{
    let existingText = '';
    let element = '';

    if(listening){
      mic.start()
      mic.onend = () =>{
        mic.start()
      }
      mic.onstart = () => {
        const id = document.getElementById('manualwriteup')
        element = id.getElementsByClassName("ql-editor")[0]
        existingText = element.textContent;
      }

      mic.onresult = event =>{
        const transcript = Array.from(event.results).map(result=>result[0]).map(result=>result.transcript).join('')
        element.textContent = existingText + ' ' + transcript
        
      } 
    }else{
      mic.stop()
      mic.onend = () =>{
        mic.stop()
        setIsListening(false)
      }
    }

      
  }



  useEffect(() => {
    const url = `${window.baseURL}usermgt/nurse-assessment/${props.encounter_pid}`;
    axios.get(url).then((res) => {
      console.log(res.data)
      setList(res.data);
    });
  }, []);


  const saveHandler = () => {
    console.log(value);
    const data = {
      encounter: props.encounter_pid,
      text: value,
    };
    const url = `${window.baseURL}usermgt/nurse-assessment/${props.encounter_pid}`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        setList(res.data);
        setValue("")
        // resetTranscript()
      });
  };



  const deleteHandler = (id) => {
    if (window.confirm("Do you want to delete this?")) {
      const merge_id = props.encounter_pid + "_" + id;
      const url = `${window.baseURL}usermgt/nurse-assessment/${merge_id}`;
      axios
        .delete(url, {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        })
        .then((res) => {
          setList(res.data);
        });
    }
  };

  return (
    <>
      <div className="mt-4  mb-2">
       
      <div className="text-center mb-3">
        <p>
          Microphone:{" "}
          <span className={`badge badge-${listening ? "danger" : "primary"}`}>
            {listening ? "on" : "off"}
          </span>
        </p>
    
        <button
          className="btn btn-success"
          onClick={()=>setIsListening(!listening)}
        >
          Start/Stop
        </button>
        {/* <button onClick={SpeechRecognition.stopListening}>Stop</button> */}
        {/* <button onClick={resetTranscript}>Reset</button> */}
        {/* <p>{transcript}</p> */}
      </div>
      <div id="manualwriteup">

      <ReactQuill theme="snow" value={value} onChange={setValue} />
      </div>

      <div className="text-center mt-3">
        <button type="button" className="btn btn-primary" onClick={saveHandler}>
          Save
        </button>
      </div>


        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Assessment</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((c, i) => {
                return (
                  <tr key={c.id}>
                    <td>{i + 1}</td>
                    <td>{c.date}</td>
                    <td><div dangerouslySetInnerHTML={{__html:c.text}}/></td>
                    <td>
                      <button className="btn btn-sm btn-danger ml-1" onClick={()=>deleteHandler(c.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      
    </>
  );
};

export default NursingAssessment;
