import Loading from "../../ui/Loading"
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// import axios from "axios";
// import Cookies from "js-cookie";
import { BsCircleFill } from "react-icons/bs";
import MainContent from "../../ui/MainContent";
import { useNavigate } from "react-router-dom";
import { getFromServer, postToServer } from "../../global/requests";

const Immunization = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [waitinglist, setWaitinglist] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [takeover, setTakeover] = useState();
  const [taken, setTaken] = useState();
  const [tempPatientPID, setTempPatientPID] = useState();

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`main/immunization/waitinglist/`);
    if(result.status) {
      setWaitinglist(result.data.encounter);
      setTaken(result.data.takeover);
      setIsLoading(false);
      console.log(result.data);
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const patientTakeoverHandler = async (pid, eid, dept) => {
    if (window.confirm("Do you want to take over patient?")) {
      const old = waitinglist.filter((w) => {
        if (eid != w.id) {
          return { ...w };
        }
      });
      setWaitinglist(old);
      const data = {
        department: dept,
        eid: eid,
        pid: pid,
      };

      const result = await postToServer(`main/immunization/takeover/`, { data });
      if(result.status) {
        setShow(false);
        console.log(result.data);
        console.log(result);
        setTaken(result.data);
      }
    }
  };

  const yesHandler = (id) => {
    navigate(`/immunization-record/pid=${id}`);
  };


  const clearTakeOverHandler = async () => {
    if (window.confirm("Do you want to clear the patient take over list?")) {
      setIsLoading(true);
      const result = await getFromServer(`main/immunization/takeover/`);
      if (result.status) {
        setTaken(null);
        setIsLoading(false);
      }
    }
  };

  const clearWaitingListHandler = async () => {
    if (window.confirm("Do you want to clear the patient waiting list?")) {
      setIsLoading(true);
      const result = await postToServer(`main/immunization/waitinglist/`);
      if(result.status) {
        setIsLoading(false);
        setWaitinglist(null);
        // setShow(false);
      }
    }
  };


  return (
    <>
      <MainContent>
        <div className="section-row">
          <div className="row">
            <div className="col-md-4">
              <h4>Immunization</h4>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4 text-end">
              <Button
                variant="primary"
                onClick={clearTakeOverHandler}
                className="mb-3"
              >
                Clear List
              </Button>
              <Button
                variant="warning"
                onClick={handleShow}
                className=" mb-3 ml-2"
              >
                View Waiting List
              </Button>
            </div>
          </div>
        </div>
        {isLoading && <Loading />}

        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <table className="table responsive">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    <th scope="col">S/N</th>
                    <th scope="col">Sex</th>
                    <th scope="col">Surname</th>
                    <th scope="col">Firstname</th>
                    <th scope="col">Encounter No</th>
                    <th scope="col">Encounter Type</th>
                    <th scope="col">Hospital No</th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {taken &&
                    taken.map((t, index) => {
                      return (
                        <tr key={index}>
                          <th scope="col">{index + 1}</th>
                          <td scope="col">{t.encounter.patient.sex}</td>
                          <td scope="col">{t.encounter.patient.surname}</td>
                          <td scope="col">{t.encounter.patient.firstname}</td>
                          <td scope="col">{t.encounter.pid}</td>
                          <td scope="col">{t.encounter.type}</td>
                          <td scope="col">{t.encounter.patient.hospitalid}</td>
                          <td
                            scope="col"
                            style={{ fontSize: "20px" }}
                            className="text-center"
                          >
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => yesHandler(t.encounter.pid)}
                            >
                              Yes This One
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {/* {attendpatient &&
              attendpatient.map((a) => {
                return (
                  <div
                    key={a}
                    className="tab-pane fade"
                    id={"a" + a + "a"}
                    role="tabpanel"
                  >
                    <AttendtoPatient
                      pid={a}
                      patient_pid={tempPatientPID}
                      list={taken}
                      reloads={refreshHandler}
                    />
                  </div>
                );
              })} */}
          </div>
        </div>

        <Offcanvas
          placement="end"
          className="mt-5 h-75"
          show={show}
          onHide={handleClose}
          backdrop={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Immunization</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <div class="row">
              <div class="col-md-6">
                <span style={{ color: "blue" }}>{">>"} waiting list</span>
              </div>
              <div class="col-md-6 text-end">
                <Button
                  variant="primary"
                  onClick={clearWaitingListHandler}
                  className="mb-3"
                  size="md"
                >
                  Clear List
                </Button>
              </div>
            </div>
            <div>
              <ul className="list-group">
                {waitinglist &&
                  waitinglist.map((e) => {
                    return (
                      <li
                        onClick={() =>
                          patientTakeoverHandler(
                            e.patient.pid,
                            e.id,
                            e.department
                          )
                        }
                        className="list-group-item"
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "darkgreen" }}>
                          <BsCircleFill />{" "}
                        </span>{" "}
                        {e.patient.title}. {e.patient.firstname}{" "}
                        {e.patient.surname} {e.date}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="mt-4">
              {/* <span style={{ color: "blue" }}>{">>"} In Clinic List</span> */}
              <div>
                <ul className="list-group">
                  {/* {takeover &&
                    takeover.map((e) => {
                      return (
                        <li className="list-group-item">
                          <span style={{ color: "darkgreen" }}>
                            <BsCircleFill />{" "}
                          </span>{" "}
                          {e.patient.title}. {e.patient.firstname}{" "}
                          {e.patient.surname} {e.encounter.date}
                        </li>
                      );
                    })} */}
                </ul>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </MainContent>
    </>
  );
};

export default Immunization;
