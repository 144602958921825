import React, { useState, useEffect } from "react";
import MainContent from "../../ui/MainContent";
import Edit from "../../modals/Nursing/EditInventory";
import Success from "../../ui/success";
// import axios from "axios";
import { getFromServer } from "../../global/requests";

const NursingInventory = () => {
  const [effectChange, setEffectChange] = useState(false);
  const [tempData, setTempData] = useState();

  const [consumable, setConsumable] = useState();
  const [drug, setDrug] = useState();
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    loadConsumables();
    loadDrugs();
  }, [effectChange]);

  const loadConsumables = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/consumable`);
    if (result.status) setConsumable(result.data);
  };
  const loadDrugs = async () => {
    const result = await getFromServer(`pharmacy/drugmgt/add/drug`);
    if (result.status) setDrug(result.data);
  };

  const editHandler = (id, name, quanity, model) => {
    setTempData({
      id: id,
      name: name,
      quantity: quanity,
      model: model,
    });
    handleShow();
  };
  const closehandler = () => setSuccess(false);
  return (
    <>
      <MainContent>
        <div className="section-body">
          <h4 className="mb-2">Inventory</h4>
          {success && (
            <Success close={closehandler}>
              Inventory updated successfully!!!
            </Success>
          )}
          <table className="table responsive table-bordered table-stripped">
            <thead>
              <tr style={{ fontSize: "13px" }}>
                <th scope="col">S/N</th>
                <th scope="col">Name</th>
                <th scope="col">Quantity</th>
                <th scope="col">Reorder Level</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan="4">Consumables</th>
              </tr>
              {consumable &&
                consumable.map((a, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{a.name}</td>
                      <td>{a.nursinginventory}</td>
                      <td>{a.reorder}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() =>
                            editHandler(a.id, a.name, a.nursinginventory, "consumeable")
                          }
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th colSpan="4">Drugs</th>
              </tr>
              {drug &&
                drug.map((a, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{a.genericname}</td>
                      <td>{a.nursinginventory}</td>
                      <td>{a.reorder}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() =>
                            editHandler(
                              a.pid,
                              a.genericname,
                              a.nursinginventory,
                              "drug"
                            )
                          }
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </MainContent>
      {show && (
        <Edit
          show={show}
          close={handleClose}
          success={successHandler}
          data={tempData}
          effect={() => setEffectChange((current) => !current)}
        />
      )}
    </>
  );
};

export default NursingInventory;
