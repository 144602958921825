import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
import { useLocation, useNavigate } from "react-router-dom";
import Success from "../../ui/success";
import ReturnItem from "../../modals/Dispensary/ReturnItem";
import ViewAdminister from "../../modals/Nursing/ViewAdminister";
import PatientDetailCard from "../../ui/PatientDetailCard";
import { postToServer } from "../../global/requests";


const DressingDetails = (props) => {
  const param = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showAdminister, setShowAdminister] = useState(false);
  const [temp, setTemp] = useState();

  const [p, setData] = useState();
  const [success, setSuccess] = useState(false);

  const updatedState = (list) => {
    setData(list);
  };

  const handleClose = () => {
    setShow(false);
    setTemp("");
  };

  const handleShow = (data) => {
    setTemp(data);
    setShow(true);
  };

  const handleCloseAdminister = () => {
    setShowAdminister(false);
    setTemp("");
  };
  const handleShowAdminister = (pres_detail_id) => {
    setTemp(`main/doctor/procedure-administer-detail/${pres_detail_id}`);
    setShowAdminister(true);
  };

  const closeHandler = () => setSuccess(false);

  const dispenseHandler = async () => {
    const data = {
      dic:p
    };
    const result = await postToServer(`main/doctor/procedure-administer`, { data });
    if(result.status) {
      console.log(result);
      navigate("/dressing");
    }
  };

  useEffect(() => {
    const l = { ...param.state.list };
    let list = [];
    for (var i in l.ProcedureDetails) {
      const temp = { ...l.ProcedureDetails[i], isCheck: true };
      list.push(temp);
    }
    setData({ ...param.state.list, ProcedureDetails: list });
    console.log(p);
    // setData(param.state.list);
  }, []);

  const checkBoxHandler = (i) => {
    const newVal = { ...p };
    p.ProcedureDetails[i].isCheck =
      !p.ProcedureDetails[i].isCheck;
    setData(newVal);
  };

  const qtyHandler = (e, i) => {
    const newVal = { ...p };
    p.ProcedureDetails[i].administerQuantity = e.target.value;
    setData(newVal);
  };


  return (
    <>
      <MainContent>
        <div className="section-body">
          <div className="row">
            {success && <Success close={closeHandler}>Successfull</Success>}
            {p && (
             <PatientDetailCard list={p.encounter} isEncounter={true} />
            )}

            <div className="tablexflow">
              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "13px" }}>
                    <th></th>
                    <th scope="col">
                      Date{"("}
                      {p && p.encounter.pid}
                      {")"}
                    </th>
                    <th scope="col">Drug</th>
                    <th scope="col">Dosage</th>
                    <th scope="col">Frequency</th>
                    <th scope="col">Period</th>
                    <th scope="col">Total</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Prescribed by</th>
                    <th scope="col">Administered Record</th>
                    {/* <th scope="col">Total Administered</th> */}
                    {/* <th scope="col">Dipensed Record</th> */}
                    {/* <th scope="col">Total Dipensed</th> */}
                    <th scope="col">Administered Quantity</th>
                    {/* <th scope="col">Cost</th> */}
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {p &&
                    p.ProcedureDetails.map((a, i) => {
                      return (
                        <tr>
                          <td>
                          <input
                                type="checkbox"
                                checked={a.isCheck}
                                onChange={() => checkBoxHandler(i)}
                              />
                          </td>
                          <td>{p.date}</td>
                          <td>
                            {a.ProcedureDetailsTag.map((t) => {
                              return <>{t.procedure} -</>;
                            })}
                          </td>
                          <td>{a.dosage}</td>
                          <td>{a.frequency}</td>
                          <td>
                            {a.period}
                            {" " + a.none}
                          </td>
                          <td>
                            {/* <input
                              type="text"
                              name="dispensedquantity"
                              className="form-control w-50"
                              value={a.total}
                              onChange={(e) => changeTotalHandler(e, index)}
                            /> */}
                            {a.total}
                          </td>
                          <td>{a.note}</td>
                          <td></td>
                          <td>
                            <button type="button" class="btn btn-primary btn-sm" onClick={()=>handleShowAdminister(a.id)}>View Record</button>
                          </td>
                          {a.isCheck == false ? (
                              <td>{a.administerQuantity}</td>
                            ) : (
                              <td>
                                <input
                                  type="text"
                                  name="qty"
                                  className="form-control w-50"
                                  value={a.administerQuantity}
                                  onChange={(e) => qtyHandler(e, i)}
                                />
                              </td>
                            )}
                          <td>
                            <button className="btn btn-sm btn-primary">
                              Patient Stock
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="text-center mt-3">
              <button className="btn btn-primary" onClick={dispenseHandler}>
                Administer
              </button>
            </div>
          </div>
        </div>
      </MainContent>
      {showAdminister && (
        <ViewAdminister
          show={showAdminister}
          close={handleCloseAdminister}
          url={temp}
        />
      )}
      {show && (
        <ReturnItem
          show={show}
          close={handleClose}
          detail={temp}
          pres_id={p.id}
          newPrescription={updatedState}
        />
      )}
    </>
  );
};

export default DressingDetails;
