import userPic from "../../assets/img/user.png";
import chatSound from "../../assets/sounds/chat.mp3"
import notiSound from "../../assets/sounds/noti.mp3"
import { fromatToDateTime } from "./common";
import { getFromServer, postToServer } from "./requests";
import { useState } from "react";
console.log("Websocket file loaded successfully");
// import {fromatToDateTime} from "../../global/common";

// const wsUrl = `ws://127.0.0.1:8000/ws/chat`;

var chatList = {};
var chatUsers = {};
const chatAttachType = [1, 2];
var chatUserId = 0;
var liveUsers = {};
var newChats = {};

// --------------- creating websocket connection and it's event handlers ---------------
var ws = null;
var wsConnectInterval = null;
const connectToWebSocket = async (setWsConnected) => {
  if(ws) {
    ws.onclose = (event) => {};
    ws.close();
    console.log("==> Connection closed for new connection.");
    setWsConnected(false);
  }
  const result = await postToServer(`communication/new-connection-req`);
  if(result.status) {
    // do things after successfull request;
  } else {
    return false;
  }
  ws = null;
  ws = new WebSocket(`${window.baseWsURL}?req_id=${result.data.data.req_id}&key=${result.data.data.one_time_key}`);
  ws.onopen = (event) => {
    console.log("WebSocket connected...");
    console.log(event);
    setWsConnected(true);
    if(wsConnectInterval) {
      clearInterval(wsConnectInterval);
      wsConnectInterval = null;
    }
  };
  ws.onmessage = (event) => {
    console.log("WebSocket message recieved...");
    console.log(event);
    const data = JSON.parse(event.data);
    if (data.event_type === "chat") {
      chattingHandler(data);
    } else if (data.event_type === "noti") {
      // js handling function for notification should place here.
      console.log(data.msg)
      const notifiList = document.getElementById("notification-list")
      if(notifiList){
        const html = `
            <a href="#" class="dropdown-item dropdown-item-unread">
            
            <span class="dropdown-item-icon bg-primary text-white">
              
              <i
                class="fas
                  fa-code"
              ></i>
            </span>
            <span class="dropdown-item-desc">
              
              ${data.msg}
              <span class="time">${fromatToDateTime(data.time)}</span>
            </span>
          </a>
            `;
      notifiList.insertAdjacentHTML("beforeend", html);
      
     
      let number  = document.getElementById("notification-list").childElementCount;
      document.getElementById("notiCount").innerHTML = number;  


      const popups =  document.getElementById("popups");

      console.log("*******************************")
      console.log(popups)
      if (popups){
        const popHtml = `
        <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-header">
            <img src="/assets/img/ok.png" class="rounded me-2" alt="...">
            <strong class="me-auto">Notification</strong>
            <small class="text-muted">just now</small>
            <button type="button" class="btn-close" onclick="this.parentNode.parentNode.remove();"; aria-label="Close"></button>
          </div>
          <div class="toast-body">
            ${data.msg}
          </div>
        </div>
        
        `;
        popups.insertAdjacentHTML("beforeend", popHtml);
      }
      // document.getElementById("pop-msg").innerHTML = data.msg;
        
      playSoundNoti();
      }




    } else if(data.event_type === "live_users") {
      liveUsers = {};
      data.users.forEach(wsUserId => {
        liveUsers[wsUserId] = true;
        updateUserStatus(wsUserId, true);
      });
    } else if(data.event_type === "user_connected") {
      liveUsers[data.user_id] = true;
      updateUserStatus(data.user_id, true);
    } else if(data.event_type === "user_disconnected") {
      liveUsers[data.user_id] = false;
      updateUserStatus(data.user_id, false);
    }
  };
  ws.onerror = (event) => {
    console.log("WebSocket error occured...");
    console.log(event);
    setWsConnected(false);
  };
  ws.onclose = (event) => {
    console.log("WebSocket connection closed...");
    console.log(event);
    setWsConnected(false);
    // reConnectToWebSocket(setWsConnected);
  };
}

const disconnectToWebSocket = () => {
  if(ws) {
    ws.onclose = (event) => {};
    ws.close();
    console.log("Connection closed.");
    setTimeout(() => ws = null, 500);
    if(window.setWsConnected) window.setWsConnected(false);
  }
}

const reConnectToWebSocket = (setWsConnected) => {
  if(wsConnectInterval) return false;
  wsConnectInterval = setInterval(() => {
    console.log("==> new websocket reconnection request executed")
    connectToWebSocket(setWsConnected);
  }, 30000)
}
// --------------- helper functions ---------------
const sendWsChat = (toUser, msg_type, msg) => {
  const data = JSON.stringify({event_type: "chat", to: toUser, msg_type: msg_type, msg: msg});
  ws.send(data);
};

const strMsgToHtmlMsg = (msg) => msg.replace(/(?:\r\n|\r|\n)/g, '<br />');
const chatAudio = new Audio(chatSound);
const notiAudio = new Audio(notiSound);
const playSound = () => chatAudio.play().catch(function(error) {console.log(error.message);});
const playSoundNoti = () => notiAudio.play().catch(function(error) {console.log(error.message);});


const chattingHandler = (data) => {
  let file_path = null;
  let msg = null;
  if(chatAttachType.includes(data.msg_type)) file_path = data.msg;
  else msg = data.msg;
  renderChat(data);
  let chatUid = data.to;
  if(window.userInfo && window.userInfo.user_id && window.userInfo.user_id != data.from) {
    playSound();
    if(window.setNewChat && window.location.pathname != '/chat-room') window.setNewChat(++window.newChat);
    chatUid = data.from;
  }
  chatList[chatUid].push({
    type: data.msg_type, created_on: data.datetime, sender: data.from,
    file_path:file_path, msg:msg, receiver: data.to
  })
  if (chatUid !== chatUserId) {
    if (newChats[chatUid]) newChats[chatUid]++;
    else newChats[chatUid] = 1;
    if(window.location.pathname == '/chat-room') showNewBadge(chatUid);
  }
}

const renderChat = (data) => {
  if (window.location.pathname === "/chat-room" && window.userInfo && window.userInfo.user_id) {
    let chatUid = data.to;
    if(window.userInfo.user_id != data.from) chatUid = data.from;

    const chatCont = document.getElementById(`chat-cont-u${chatUid}`);
    if (chatCont) {
      const user_id = window.userInfo.user_id;
      let text = '';
      if(data.msg_type === 0) text = strMsgToHtmlMsg(data.msg);
      else if(data.msg_type === 1) text = `<img src="${window.baseURLuploaded}/images/${data.msg}" />`;
      else if(data.msg_type === 2) {
        text = `
          <a href="${window.baseURLuploaded}/images/${data.msg}" target="_blank">
            ${data.msg.split("/").slice(-1)}
          </a>
        `;
      }
      let senderPic = userPic;
      if((user_id === data.from) && window.userInfo.profile_pic) {
        senderPic = `${window.baseURLuploaded}${window.userInfo.profile_pic}`;
      } else if(chatUsers[chatUid] && chatUsers[chatUid].image) {
        senderPic = `${window.baseURLuploaded}/images/${chatUsers[chatUid].image}`;
      }
      const html = `
        <div class="chat-item chat-${user_id === data.from ? 'right' : 'left'}" style="">
          <img src=${senderPic}>
          <div class="chat-details">
            <div class="chat-text">${text}</div>
            <div class="chat-time">${fromatToDateTime(data.datetime)}</div>
          </div>
        </div>
      `;
      chatCont.insertAdjacentHTML("beforeend", html);
      chatCont.scrollTop = chatCont.scrollHeight;
    }
  }
};

const loadChats = async (setChatLoading) => {
  if(setChatLoading) setChatLoading(true);
  chatList = {};
  chatUsers = {};
  const result = await getFromServer(`communication/chat-list`);
  if(result.status) {
    chatList = result.data.chats;
    chatUsers = result.data.users;
  }
  if(setChatLoading) setChatLoading(false);
}

const renderChatList = () => {
  if (window.location.pathname === "/chat-room") {
    const chatUserCont = document.getElementById("chat-user-cont");
    const chatCont = document.getElementById("chat-cont");
    let firstRow = true;
    if(chatUserCont && chatCont && chatUsers) {
      chatUserCont.innerHTML = '';
      chatCont.innerHTML = '';
      let html = '';
      let firstChatUser = null;
      for (const key in chatUsers) {
        const chatUser = chatUsers[key];
        if(firstRow) {
          firstRow = false;
          firstChatUser = chatUser.id;
        }
        const status = liveUsers[chatUser.id] ? 'online' : 'offline';
        html = `
          <li class="clearfix" onClick="window.changeChatUser(${chatUser.id})" id="chat-user-${chatUser.id}">
            <img src=${chatUser.image ? `${window.baseURLuploaded}/images/${chatUser.image}` : userPic} alt="avatar" />
            <div class="about">
              <div class="name">${chatUser.first_name}${chatUser.last_name}</div>
              <div class="head">
                <div class="status"><i class="fas fa-circle ${status}"></i> ${status} </div>
                <div class="new">${newChats[chatUser.id] ? `<span class="badge badge-danger">${newChats[chatUser.id]}</span>` : ''}</div>
              </div>
            </div>
          </li>
        `;
        chatUserCont.insertAdjacentHTML("beforeend", html);
        html = `
          <div class="card-body chat-content" id="chat-cont-u${chatUser.id}"></div>
        `;
        chatCont.insertAdjacentHTML("beforeend", html);
        if (chatList && chatList[chatUser.id]) {
          chatList[chatUser.id].forEach(chat => {
            renderChat({
              msg_type: chat.type, datetime: chat.created_on, from: chat.sender,
              msg: chatAttachType.includes(chat.type) ? chat.file_path : chat.msg,
              to: chat.receiver
            });
          });
        }
      }
      if(firstChatUser) {
        const lastChatUserId = sessionStorage.getItem("last_chat_user_id");
        if (lastChatUserId) window.changeChatUser(parseInt(lastChatUserId));
      }
    }
  }
}

const updateUserStatus = (id, userStatus) => {
  const statusEl = document.querySelector(`#chat-user-${id} div.status`);
  if(statusEl) {
    const status = userStatus ? 'online' : 'offline';
    statusEl.innerHTML = `<i class="fas fa-circle ${status}"></i> ${status} `;
  }
}

const showNewBadge = (user_id) => {
  const newEl = document.querySelector(`#chat-user-${user_id} div.new`);
  if(newEl) newEl.innerHTML = `<span class="badge badge-danger">${newChats[user_id]}</span>`;
}

const hideNewBadge = (user_id) => {
  const newEl = document.querySelector(`#chat-user-${user_id} div.new`);
  if(newEl) newEl.innerHTML = '';
}

window.changeChatUser = (user_id) => {
  // console.log(user_id);
  const chatContOld = document.getElementById(`chat-cont-u${chatUserId}`);
  const chatUserOld = document.getElementById(`chat-user-${chatUserId}`);
  if(chatContOld) chatContOld.style.display = 'none';
  if(chatUserOld) chatUserOld.classList.remove('active');

  chatUserId = user_id;
  sessionStorage.setItem("last_chat_user_id", `${user_id}`);

  if(user_id == 0) {
    window.setChatUser({});
  } else {
    window.setChatUser(chatUsers[user_id]);
    const chatContNew = document.getElementById(`chat-cont-u${user_id}`);
    const chatUserNew = document.getElementById(`chat-user-${user_id}`);
    if(chatContNew) {
      chatContNew.style.display = 'block';
      chatContNew.scrollTop = chatContNew.scrollHeight;
    }
    if(chatUserNew) chatUserNew.classList.add('active');
    if (newChats[user_id]) delete newChats[user_id];
    hideNewBadge(user_id);
  }
}

export { sendWsChat, loadChats, renderChatList, connectToWebSocket, disconnectToWebSocket};
