import axios from "axios";
import { getUserAccessToken, clearSession, updateUserAccessTime } from "./auth";
import Cookies from "js-cookie";

// --------------- get request ---------------
const getFromServer = async (urlPath) => {
  if(!updateUserAccessTime()) return false;
  try {
    const res = await axios.get(`${window.baseURL}${urlPath}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${getUserAccessToken()}`,
      },
    });
    if (res.status == 403 || res.status == 401) {
      return handleDeniedResponse(res.status);
    } else return { status: true, data: res.data };
  } catch (error) {
    if (error.response.status == 403 || error.response.status == 401) {
      return handleDeniedResponse(error.response.status);
    } else return { status: false, data: {} };
  }
};

// --------------- post request ---------------
const postToServer = async (urlPath, data = {}) => {
  if(!updateUserAccessTime()) return false;
  try {
    const res = await axios.post(`${window.baseURL}${urlPath}`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"),
        Authorization: `Token ${getUserAccessToken()}`,
      },
    });
    if (res.status == 403 || res.status == 401) {
      return handleDeniedResponse(res.status);
    } else return { status: true, data: res.data };
  } catch (error) {
    if (error.response.status == 403 || error.response.status == 401) {
      return handleDeniedResponse(error.response.status);
    } else return { status: false, data: {} };
  }
};

// --------------- post form request ---------------
const postFormToServer = async (urlPath, data = new FormData()) => {
  if(!updateUserAccessTime()) return false;
  try {
    const res = await axios.post(`${window.baseURL}${urlPath}`, data, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Authorization: `Token ${getUserAccessToken()}`,
      },
      mimeType: "multipart/form-data",
    });
    if (res.status == 403 || res.status == 401) {
      return handleDeniedResponse(res.status);
    } else return { status: true, data: res.data };
  } catch (error) {
    if (error.response.status == 403 || error.response.status == 401) {
      return handleDeniedResponse(error.response.status);
    } else return { status: false, data: {} };
  }
};

// --------------- put form request ---------------
const putFormToServer = async (urlPath, data = new FormData()) => {
  if(!updateUserAccessTime()) return false;
  try {
    const res = await axios.put(`${window.baseURL}${urlPath}`, data, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Authorization: `Token ${getUserAccessToken()}`,
      },
      mimeType: "multipart/form-data",
    });
    if (res.status == 403 || res.status == 401) {
      return handleDeniedResponse(res.status);
    } else return { status: true, data: res.data };
  } catch (error) {
    if (error.response.status == 403 || error.response.status == 401) {
      return handleDeniedResponse(error.response.status);
    } else return { status: false, data: {} };
  }
};

// --------------- delete request ---------------
const deleteFromServer = async (urlPath) => {
  if(!updateUserAccessTime()) return false;
  try {
    const res = await axios.delete(`${window.baseURL}${urlPath}`, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"),
        Authorization: `Token ${getUserAccessToken()}`,
      },
    });
    if (res.status == 403 || res.status == 401) {
      return handleDeniedResponse(res.status);
    } else return { status: true, data: res.data };
  } catch (error) {
    if (error.response.status == 403 || error.response.status == 401) {
      return handleDeniedResponse(error.response.status);
    } else return { status: false, data: {} };
  }
};

// --------------- post request ---------------
const putToServer = async (urlPath, data = {}) => {
  if(!updateUserAccessTime()) return false;
  try {
    const res = await axios.put(`${window.baseURL}${urlPath}`, data, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"),
        Authorization: `Token ${getUserAccessToken()}`,
      },
    });
    if (res.status == 403 || res.status == 401) {
      return handleDeniedResponse(res.status);
    } else return { status: true, data: res.data };
  } catch (error) {
    if (error.response.status == 403 || error.response.status == 401) {
      return handleDeniedResponse(error.response.status);
    } else return { status: false, data: {} };
  }
};

// --------------- file uploader with progress ---------------
const uploadFileForm = (urlPath, formdata, progressHandler, completeHandler, errorHandler, abortHandler) => {
  const ajax = new XMLHttpRequest();
  ajax.upload.addEventListener("progress", progressHandler, false);
  ajax.addEventListener("load", completeHandler, false);
  ajax.addEventListener("error", errorHandler, false);
  ajax.addEventListener("abort", abortHandler, false);
  ajax.open("POST", `${window.baseURL}${urlPath}`);
  ajax.setRequestHeader("X-CSRFToken", Cookies.get("csrftoken"));
  ajax.setRequestHeader("Authorization", `Token ${getUserAccessToken()}`);
  ajax.send(formdata);
}


// --------------- handling denied request ---------------
const handleDeniedResponse = (statusCode = 401) => {
  if(statusCode == 403) {
    window.openAccessDenied();
  } else {
    clearSession();
    try {
      window.reactNavigate("/login");
      window.setIsLoggedIn(false);
    } catch (error) {
      window.location.href = "/login";
    }
  }
  return { status: false, data: {} };
};

// --------------- download file ---------------
const downloadFileFromUrl = async (urlPath, filename) => {
  if(!updateUserAccessTime()) return false;
  try {
    const res = await fetch(`${window.baseURL}${urlPath}`, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: `Token ${getUserAccessToken()}` },
    });
    if (res.status == 403 || res.status == 401) {
      return handleDeniedResponse(res.status);
    } else {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([await res.blob()]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);

      return { status: true, data: res.data };
    }
  } catch (error) {
    if (error.response.status == 403 || error.response.status == 401) {
      return handleDeniedResponse(error.response.status);
    } else return { status: false, data: {} };
  }
}
const downloadFileFromUrl2 = (urlPath, filename) => {
  fetch(`${window.baseURL}${urlPath}`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
      Authorization: `Token ${getUserAccessToken()}`,
    },
  })
  .then((response) => response.blob())
  .then((blob) => {
    
  });
}

export {
  getFromServer,
  postToServer,
  postFormToServer,
  deleteFromServer,
  putToServer,
  putFormToServer,
  uploadFileForm,
  downloadFileFromUrl,
};
