import React from "react";

const ReportHead = ({search, setSearch, noOfRow, setNoOfRow, loadListData}) => {
  const changeSearch = (e) => setSearch(e.target.value);
  const changeNoOfRow = (e) => setNoOfRow(parseInt(e.target.value));
  const searchHandler = () => loadListData();
  return (
    <div className="card-header">
      <h4>
        {noOfRow &&
        <select
          className="form-control-sm"
          value={noOfRow}
          onChange={changeNoOfRow}
        >
          <option value="10">10 Rows</option>
          <option value="25">25 Rows</option>
          <option value="50">50 Rows</option>
          <option value="100">100 Rows</option>
        </select>
        }
      </h4>
      <div className="card-header-form report-serach">
        {setSearch &&
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={changeSearch}
          />
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={searchHandler}>
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default ReportHead;
