import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cookies from "js-cookie";

const ObservationChartModel = (props) => {
  const edit = props.is_edit
  const tempData = props.tempData

  const temp = useRef();
  const resp = useRef();
  const pulse = useRef();
  const systolicbp = useRef();
  const diastolicbp = useRef();
  const fbs = useRef();
  const rbs = useRef();
  const spo2 = useRef();


  useEffect(()=>{
    if(edit && tempData){
      document.getElementById('temp').value = tempData.temp
      document.getElementById('resp').value = tempData.resp
      document.getElementById('pulse').value = tempData.pulse
      document.getElementById('systolicbp').value = tempData.systolicbp
      document.getElementById('fbs').value = tempData.fbs
      document.getElementById('diastolicbp').value = tempData.diastolicbp
      document.getElementById('rbs').value = tempData.rbs
      document.getElementById('spo2').value = tempData.spo2
    }
  },[])


  const handleSubmit = (e) => {
    e.preventDefault();
    let id = null
    if(edit){
      id = tempData.id
    }

    const data = {
      is_edit: edit,
      id:id,
      temp: temp.current.value,
      resp: resp.current.value,
      pulse: pulse.current.value,
      systolicbp: systolicbp.current.value,
      diastolicbp: diastolicbp.current.value,
      fbs: fbs.current.value,
      rbs: rbs.current.value,
      spo2: spo2.current.value,
    };

    const url = `${window.baseURL}usermgt/observation-chart/${props.encounter_pid}`;
    axios
      .post(
        url,
        { data },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        }
      )
      .then((res) => {
        props.success(true);
        props.close();
        props.update(res.data)
      });
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
        {edit ? 
          <Modal.Title>Edit Observations</Modal.Title> :
          <Modal.Title>Add Observations</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Temp <sup>o</sup>C
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={temp}
                      id='temp'
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Resp{" "}
                      <span style={{ color: "red" }}>
                        {"("}BPM{")"}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={resp}
                      id='resp'
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Pulse{" "}
                      <span style={{ color: "red" }}>
                        {"("}bts/m{")"}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={pulse}
                      id="pulse"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Systolic BP{" "}
                      <span style={{ color: "red" }}>
                        {"("}mmHg{")"}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={systolicbp}
                      id="systolicbp"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Diastolic BP{" "}
                      <span style={{ color: "red" }}>
                        {"("}mmHg{")"}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={diastolicbp}
                      id="diastolicbp"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">
                      FBS{" "}
                      <span style={{ color: "red" }}>
                        {"("}mg/dl{")"}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={fbs}
                      id="fbs"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">
                      RBS{" "}
                      <span style={{ color: "red" }}>
                        {"("}mg/dl{")"}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={rbs}
                      id="rbs"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="col-form-label">
                      SPO2{" "}
                      <span style={{ color: "red" }}>
                        {"("}%{")"}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      ref={spo2}
                      id="spo2"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                {edit ? <>Update</>:<>Add</>}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ObservationChartModel;
