import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cookies from "js-cookie";

const AddLabTests = (props) => {
  const [checkList, setCheckList] = useState([]);
  const type = useRef();

  useEffect(() => {
    console.log(props.options);
  }, []);

  const changeHandler = (e, i, j) => {
    if (e.target.checked) {
      setCheckList([...checkList, props.options[i].labtestcategory[j]]);
    } else {
      const newval = checkList.filter((c) => {
        return c.id != props.options[i].labtestcategory[j].id;
      });
      setCheckList(newval);
    }
  };
  const handleSubmit = (e, i) => {
    e.preventDefault();
    props.update(checkList);
    props.close();
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Select Lab Tests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <table className="table table-bordered table-stripped table-md">
                  {props.options &&
                    props.options.map((t, i) => {
                      return (
                        <>
                          <thead>
                            <tr className="text-center">
                              <th>{t.category}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <div className="row" style={{marginLeft:"10px"}}>
                            {t.labtestcategory &&
                              t.labtestcategory.map((a, j) => {
                                return (<>
                                    <div className="col-md-4 ">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id={"test" + i + j}
                                        onChange={(e) => changeHandler(e, i, j)}
                                      />
                                      <label
                                        style={{ marginLeft: "5px" }}
                                        htmlFor={"test" + i + j}
                                      >
                                        {a.testname}
                                      </label>
                                    </div>
                                    </>
                                );
                              })}
                              </div>
                              </tr>
                          </tbody>
                        </>
                      );
                    })}
                </table>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddLabTests;
