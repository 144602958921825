import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
import MainContent from "../../ui/MainContent";
// import AdmssionMgt from "./AdmissionMgt";
import Success from "../../ui/success";
import { useNavigate } from "react-router-dom";
import { getFromServer, postToServer, deleteFromServer } from "../../global/requests";

const AdmissionMgtSearch = () => {
  const navigate = useNavigate();
  const [getNursing, setGetNursing] = useState();
  const [isSearched, setIsSearched] = useState(false);
  const [addNursing, setAddNursing] = useState(false);
  const [success, setSuccess] = useState(false);

  const successClose = () => setSuccess(false);

  const nursingStation = useRef();
  const nursing = useRef();
  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`usermgt/nursing-station`);
    if(result.status) {
      // console.log(res);
      setGetNursing(result.data);
    }
  }

  const addNursingHandler = () => {
    setAddNursing(!addNursing);
  };
  const nursingSubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      station: nursingStation.current.value,
    };

    const result = await postToServer(`usermgt/nursing-station`, { data });
    if(result.status) {
      // console.log(result.data);
      setAddNursing(false);
      setSuccess(true);
      setGetNursing(result.data);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    navigate("/admission-mgt", { state: { nursing: nursing.current.value } });
  };

  const stationDeletetHandler = async (id) =>{
    if(window.confirm("Do you really want to delete this Station?")){
      const result = await deleteFromServer(`usermgt/nursing-station/${id}`);
      if(result.status) setGetNursing(result.data.nursing);
    }
  }
  return (
    <>
      <MainContent>
        {success && (
          <Success close={successClose}>
            Data has been added Successfully
          </Success>
        )}
        <div className="section-body">
          {!isSearched && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4>Select Nursing Station</h4>
                      {getNursing && (
                        <form
                          method="GET"
                          onSubmit={submitHandler}
                          className="card-header-form"
                          style={{ display: "flex" }}
                        >
                          <select className="form-select" ref={nursing}>
                            {getNursing &&
                              getNursing.map((u) => {
                                return (
                                  <option
                                    key={u.id}
                                    id={u.id}
                                    value={u.id + "_" + u.station}
                                  >
                                    {u.station}
                                  </option>
                                );
                              })}
                          </select>
                          <button
                            type="submit"
                            style={{ color: "white" }}
                            className="btn btn-info ml-3"
                          >
                            Select
                          </button>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <button className="btn btn-primary" onClick={addNursingHandler}>
                Add Nursing Station
              </button>
              {addNursing && (
                <form>
                  <div className="mb-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nursing Department"
                      required
                      ref={nursingStation}
                    />
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={nursingSubmitHandler}
                    >
                      Add
                    </button>
                  </div>
                </form>
              )}
            </>
          )}
          <table class="table table-md table-bordered mt-3">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {getNursing &&
                getNursing.map((u,i) => {
                  return (
                    <tr key={u.id}>
                      <td>{i+1}</td>
                      <td>{u.station}</td>
                      <td>
                        <button type="button" class="btn btn-danger btn-sm" onClick={()=>stationDeletetHandler(u.id)}>Delete</button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {/* {isSearched && <AdmssionMgt nursing={nursing.current.value} back={()=>setIsSearched(false)}/>} */}
      </MainContent>
    </>
  );
};

export default AdmissionMgtSearch;
