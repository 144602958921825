import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const AddBillingCategory = (props) => {
  const cat = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
        category: cat.current.value,
    };

    const result = await postToServer(`billing/billing-category`, { data });
    if(result.status) {
      props.close();
      props.success(true);
      props.bills(result.data)
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Billing Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Category Name
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={cat}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBillingCategory;
