import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import Cookies from "js-cookie";
import { postToServer } from "../../global/requests";

const AddCompany = (props) => {

  const cname = useRef();
  const address = useRef();

  const handleSubmit =  async(e) => {
    e.preventDefault();
    const data = {
      name: cname.current.value,
      address: address.current.value,
    };
    const result = await postToServer(`billing/company`, { data });
    if(result.status) {
      props.close();
      props.success(true);
      props.companies(result.data);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="_token" value="" />
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="service" className="col-form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="service"
                      className="form-control"
                      required
                      ref={cname}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="address" className="col-form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      required
                      ref={address}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCompany;
