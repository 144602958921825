import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MainContent from "../../ui/MainContent";
import '../../../assets/css/billform.css'

const BillPage = (props) => {
  const param = useLocation();

  const l = param.state.list.cashier;
  const p = param.state.list.amount;

  var cp = "";
  if (l) {
    console.log(l);
    cp = l.encounter.profile.split("=>");
  }

  const printHandler = () =>{
    window.print()
  }
  return (
    <>
      <MainContent>
        {l && (
          <>
          <div style={{paddingLeft:"50px"}} className="row ">
            <div className="col-md-8">
              <table className="table table-bordered table-stripped table-sm" style={{border:"2px solid black"}}>
                <tbody >
                  <tr>
                    <th className="border-black"  style={{border:"2px solid black"}}>Surname</th>
                    <td className="border-black"  style={{border:"2px solid black"}}>{l.encounter.patient.surname}</td>
                  </tr>
                  <tr>
                    <th className="border-black"  style={{border:"2px solid black"}}>First Name</th>
                    <td className="border-black"  style={{border:"2px solid black"}}>{l.encounter.patient.firstname}</td>
                  </tr>
                  <tr>
                    <th className="border-black"  style={{border:"2px solid black"}}>Company</th>
                    <td className="border-black"  style={{border:"2px solid black"}}>{cp[0]}</td>
                  </tr>
                  <tr>
                    <th className="border-black"  style={{border:"2px solid black"}}>Plan</th>
                    <td className="border-black"  style={{border:"2px solid black"}}>{cp[1]}</td>
                  </tr>
                  <tr>
                    <th className="border-black"  style={{border:"2px solid black"}}>Bill Nr</th>
                    <td className="border-black"  style={{border:"2px solid black"}}>{l.id}</td>
                  </tr>
                  <tr>
                    <th className="border-black"  style={{border:"2px solid black", paddingTop:"20px"}} height='50px'>Item</th>
                    <th className="border-black"  style={{border:"2px solid black", paddingTop:"20px"}} height='50px'>Price</th>
                  </tr>
                  {p.map((a,i)=>{
                    return(
                      <tr key={i}>
                        <td className="border-black"  style={{border:"2px solid black"}}>{a.category}</td>
                        <td className="border-black"  style={{border:"2px solid black"}}>{a.amount}</td>
                      </tr>
                    )
                  })}

                  {/* {l.service_cash &&
                    l.service_cash.map((s, i) => {
                      return (
                        <tr>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.service.name}</td>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.price}</td>
                        </tr>
                      );
                    })}
                  {l.room_cash &&
                    l.room_cash.map((s, i) => {
                      return (
                        <tr>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.room.room}</td>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.price}</td>
                        </tr>
                      );
                    })}
                  {l.procedure_cash &&
                    l.procedure_cash.map((s, i) => {
                      return (
                        <tr>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.procedure.procedure}</td>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.price}</td>
                        </tr>
                      );
                    })}
                  {l.drug_cash &&
                    l.drug_cash.map((s, i) => {
                      return (
                        <tr>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.drug.brandname}</td>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.price}</td>
                        </tr>
                      );
                    })}
                  {l.test_cash &&
                    l.test_cash.map((s, i) => {
                      return (
                        <tr>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.test.testname}</td>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.price}</td>
                        </tr>
                      );
                    })}
                  {l.fluid_cash &&
                    l.fluid_cash.map((s, i) => {
                      return (
                        <tr>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.fluid.fluid}</td>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.price}</td>
                        </tr>
                      );
                    })}
                  {l.consumable_cash &&
                    l.consumable_cash.map((s, i) => {
                      return (
                        <tr>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.consumable.name}</td>
                          <td className="border-black"  style={{border:"2px solid black"}}>{s.price}</td>
                        </tr>
                      );
                    })} */}
                    <tr>
                          <th className="border-black"  style={{border:"2px solid black"}}>Total Sum</th>
                          <td className="border-black"  style={{border:"2px solid black"}}>{l.totalsum}</td>
                        </tr>
                        <tr>
                          <th className="border-black"  style={{border:"2px solid black"}}>Initial Balance</th>
                          <td className="border-black"  style={{border:"2px solid black"}}>{l.initialdeposit}</td>
                        </tr>
                        <tr>
                          <th className="border-black"  style={{border:"2px solid black"}}>Insurance</th>
                          <td className="border-black"  style={{border:"2px solid black"}}>{l.insurance}</td>
                        </tr>
                        <tr>
                          <th className="border-black"  style={{border:"2px solid black"}}>Discount</th>
                          <td className="border-black"  style={{border:"2px solid black"}}>{l.discount}</td>
                        </tr>
                        <tr>
                          <th className="border-black"  style={{border:"2px solid black"}}>Sum to Pay</th>
                          <td className="border-black"  style={{border:"2px solid black"}}>{l.sumtopay}</td>
                        </tr>
                        <tr>
                          <th className="border-black"  style={{border:"2px solid black"}}>Amount Paid</th>
                          <td className="border-black"  style={{border:"2px solid black"}}>{l.paidamount}</td>
                        </tr>
                        <tr>
                          <th className="border-black"  style={{border:"2px solid black"}}>Current Balance</th>
                          <td className="border-black"  style={{border:"2px solid black"}}>{l.encounter.patient.wallet}</td>
                        </tr>
                </tbody>
              </table>
              <button className="btn btn-primary mb-3 mt-3" onClick={printHandler}>Print</button>
            </div>
          </div>

            <table className="table table-bordered table-stripped table-sm" style={{border:"2px solid black", visibility:'hidden'}} id="claimformBill-print-container">
              <tbody >
                <tr>
                  <th className="border-black" >Surname</th>
                  <td className="border-black" >{l.encounter.patient.surname}</td>
                </tr>
                <tr>
                  <th className="border-black"  style={{border:"2px solid black"}}>First Name</th>
                  <td className="border-black"  style={{border:"2px solid black"}}>{l.encounter.patient.firstname}</td>
                </tr>
                <tr>
                  <th className="border-black"  style={{border:"2px solid black"}}>Company</th>
                  <td className="border-black"  style={{border:"2px solid black"}}>{cp[0]}</td>
                </tr>
                <tr>
                  <th className="border-black"  style={{border:"2px solid black"}}>Plan</th>
                  <td className="border-black"  style={{border:"2px solid black"}}>{cp[1]}</td>
                </tr>
                <tr>
                  <th className="border-black"  style={{border:"2px solid black"}}>Bill Nr</th>
                  <td className="border-black"  style={{border:"2px solid black"}}>{l.id}</td>
                </tr>
                <tr>
                  <th className="border-black"  style={{border:"2px solid black", paddingTop:"20px"}} height='50px'>Item</th>
                  <th className="border-black"  style={{border:"2px solid black", paddingTop:"20px"}} height='50px'>Price</th>
                </tr>
                {p.map((a,i)=>{
                  return(
                    <tr key={i}>
                      <td className="border-black"  style={{border:"2px solid black"}}>{a.category}</td>
                      <td className="border-black"  style={{border:"2px solid black"}}>{a.amount}</td>
                    </tr>
                  )
                })}

              
                  <tr>
                        <th className="border-black"  style={{border:"2px solid black"}}>Total Sum</th>
                        <td className="border-black"  style={{border:"2px solid black"}}>{l.totalsum}</td>
                      </tr>
                      <tr>
                        <th className="border-black"  style={{border:"2px solid black"}}>Initial Balance</th>
                        <td className="border-black"  style={{border:"2px solid black"}}>{l.initialdeposit}</td>
                      </tr>
                      <tr>
                        <th className="border-black"  style={{border:"2px solid black"}}>Insurance</th>
                        <td className="border-black"  style={{border:"2px solid black"}}>{l.insurance}</td>
                      </tr>
                      <tr>
                        <th className="border-black"  style={{border:"2px solid black"}}>Discount</th>
                        <td className="border-black"  style={{border:"2px solid black"}}>{l.discount}</td>
                      </tr>
                      <tr>
                        <th className="border-black"  style={{border:"2px solid black"}}>Sum to Pay</th>
                        <td className="border-black"  style={{border:"2px solid black"}}>{l.sumtopay}</td>
                      </tr>
                      <tr>
                        <th className="border-black"  style={{border:"2px solid black"}}>Amount Paid</th>
                        <td className="border-black"  style={{border:"2px solid black"}}>{l.paidamount}</td>
                      </tr>
                      <tr>
                        <th className="border-black"  style={{border:"2px solid black"}}>Current Balance</th>
                        <td className="border-black"  style={{border:"2px solid black"}}>{l.encounter.patient.wallet}</td>
                      </tr>
              </tbody>
            </table>
           
          </>
        )}
          
      </MainContent>
    </>
  );
};

export default BillPage;
