import React, { useState, useEffect } from "react";
import Success from "../../ui/success";
import MainContent from "../../ui/MainContent";
import AddBillingCategory from "../../modals/Billing/AddBillingCategory";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import Cookies from "js-cookie";
import { getFromServer, deleteFromServer } from "../../global/requests";

const BillingCategory = () => {
  //   const [isemptyArray, setIsEmptyArray] = useState(false);
  const [billing, setBilling] = useState();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateCompanyHandler = (list) => setBilling(list);
  const successHandler = (bool) => setSuccess(bool);

  useEffect(() => {
    doInitialTasks();
  }, []);

  const doInitialTasks = async () => {
    const result = await getFromServer(`billing/billing-category`);
    if(result.status) setBilling(result.data);
  }

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const result = await deleteFromServer(`billing/billing-category/${id}`);
      if(result.status) setBilling(result.data);
      setSuccess(false);
    }
  };

  return (
    <>
      <MainContent>
        {success && (
          <Success close={() => setSuccess(false)}>
            Billing Category has been added Successfully
          </Success>
        )}
        <div className="section-body">
          <div className="row">
            <div className="col-md-4">
              <button className="btn btn-primary mb-4" onClick={handleShow}>
                Add New Category
              </button>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>

          <div className="tablexflow">
            <table className="table responsive">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Billing Category</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {billing &&
                  billing.map((a, index) => {
                    return (
                      <tr key={a.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{a.category}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteHandler(a.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
      {show && (
        <AddBillingCategory
          show={show}
          close={handleClose}
          success={successHandler}
          bills={updateCompanyHandler}
        />
      )}
    </>
  );
};

export default BillingCategory;
