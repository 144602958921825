import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";

const AddingTags = (props) => {
  const [tags, setTags] = useState([]);
  const input = useRef();

  const tagsHandler = () => {
    setTags([...tags, input.current.value]);
    document.getElementById("input").value = "";
  };

  const removeTags = (id) => {
    const newState = tags.filter((t) => {
      return t != id;
    });
    setTags(newState);
  };

  const submitHandler = () => {
    props.getTags(tags);
    setTags([]);
    props.close();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Questions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="col-12">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="input"
                placeholder="Add Questions and Click on Add button to add them"
                ref={input}
              />
              <div class="input-group-text">
                <button className="btn btn-transparent" onClick={tagsHandler}>
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2 d-grid gap-2 d-md-block">
            {tags &&
              tags.map((t, i) => {
                return (
                  <button
                    key={i}
                    type="button"
                    className="btn btn-sm btn-primary ml-2 mb-2"
                  >
                    {t}{" "}
                    <span className="closebox" onClick={() => removeTags(t)}>
                      X
                    </span>
                  </button>
                );
              })}
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary float-end btn-sm mt-3"
              onClick={submitHandler}
            >
              Submit all questions
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddingTags;
